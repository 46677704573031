import {LeafletVectorSource} from '../classes/leaflet-vector-source';
import {LeafletMapLayer} from '../classes/leaflet-map-layer';
import {LeafletFeature} from '../classes/leaflet-feature';
import * as L from 'leaflet';
import {FeatureStyle} from '../interfaces/style.interface';
import {RRCCLocation} from '../../management/classes/rrcc-location';
import {Subject} from 'rxjs';
import {PopoverElement} from '../../../classes/popover-element';
import {PhoneNumberPipe} from '../../../../shared/pipes/phone-number.pipe';

export class RrccSource extends LeafletVectorSource<RRCCLocation> {
    static redrawTrigger = new Subject<RRCCLocation>();
    private phoneNumberPipe = new PhoneNumberPipe();

    constructor(layerInfo: LeafletMapLayer) {
        super(layerInfo);
    }

    processFeatures(features: RRCCLocation[]): void {
        const leafletFeatures = features
            .map((f) => {
                const feature = new LeafletFeature<RRCCLocation>().convert(f);
                const status = feature.properties.deployments.length > 0 ? 'Deployed' : 'Not Deployed';

                const personnel =
                    feature.properties.deployments.length !== 0
                        ? feature.properties.deployments.map((d) => d.personnel.name).join(', ')
                        : 'N/A';
                const emails =
                    feature.properties.deployments.length !== 0
                        ? feature.properties.deployments.map((d) => d.personnel.emails.map((e) => e.information).join(', ')).join(', ')
                        : 'N/A';
                const phoneNumbers =
                    feature.properties.deployments.length !== 0
                        ? feature.properties.deployments
                              .map((d) => d.personnel.phones.map((p) => this.phoneNumberPipe.transform(p.information)).join(', '))
                              .join(', ')
                        : 'N/A';

                const data = [
                    new PopoverElement().setTitle().setValue(feature.properties.name),
                    new PopoverElement().setLabel('Deployment Status').setValue(status),
                    new PopoverElement().setLabel('Personnel').setValue(personnel),
                    new PopoverElement().setLabel('Email').setValue(emails),
                    new PopoverElement().setLabel('Phone').setValue(phoneNumbers),
                ];

                if (!!feature.properties.notes) {
                    data.push(new PopoverElement().setLabel('Notes').setValue(feature.properties.notes));
                }

                feature.subFeatures = [
                    {
                        id: feature.properties.id,
                        popoverData: data,
                    },
                ];

                return feature;
            })
            .filter((f) => !!f);

        const style = (feature: LeafletFeature<RRCCLocation>, latlng: L.LatLng): L.Layer => {
            return (L as any).shapeMarker(latlng, getStyle(feature.properties));
        };

        const config = {
            pointToLayer: style,
            onEachFeature: this.initializePopoverInteractions.bind(this),
        };

        this.source = L.geoJSON(leafletFeatures as any, config as any);
    }

    public redrawFeature(updatedFeature?: RRCCLocation) {
        const features = this.getLeafletFeatures();
        features.forEach((feature) => {
            if (feature.properties.id === updatedFeature.id) {
                feature.properties = updatedFeature;
            }
        });

        super.redraw(features);
    }
}

function getStyle(feature: RRCCLocation): any {
    const color = feature.deployments.length > 0 ? '#8056FF' : '#747474';

    return {
        shape: 'triangle',
        radius: 7,
        fillColor: color,
        stroke: false,
        fillOpacity: 1,
    } as any;
}
