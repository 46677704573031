import {State} from 'frontend/src/app/modules/outage/classes/state';
import * as moment from 'moment';

export class DashboardFilters {
    public locations: State[] = [];
    public date: moment.Moment = moment();

    constructor(filters?: DashboardFilters) {
        if (filters) {
            this.locations = filters.locations;
            this.date = filters.date.clone();
        }
    }
}
