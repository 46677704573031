import {GenPlanetSense} from '../../../../../generated/serverModels/GenPlanetSense';
import {ApplicationConfig} from '../../../classes/application-config';

export class Planetsense extends GenPlanetSense {
    public twitterUrl: string;
    public media: any;
    public mediaUrl: string;

    constructor(props?: Planetsense) {
        super(props);

        if (props) {
            this.twitterUrl = `https://twitter.com/${this.twitterName.replace(/"/g, '')}/status/${this.tweetId}`;
            this.tweet = props.fullTweet || props.tweet;
            this.twitterName = `${this.twitterName.replace(/"/g, '')}`;

            if (this.profileImageUrl) {
                this.profileImageUrl = `${ApplicationConfig.proxyPrefix}${this.profileImageUrl.replace(/"/g, '')}`;
            }

            this.getMediaUrl();
        }
    }

    private getMediaUrl(): void {
        if (this.media?.value) {
            const parsed = JSON.parse((this.media as any).value);
            this.mediaUrl = `${ApplicationConfig.proxyPrefix}${parsed[0].media_url_https}`;
        }
    }
}
