<mat-form-field class="filter-select active" appearance="outline" [class.multiple-inputs]="showTime">
    <mat-label *ngIf="startPlaceholder">{{ startPlaceholder }}</mat-label>
    <input
        matInput
        class="date-selector clickable"
        (click)="toggleDatepicker($event)"
        [matDatepicker]="startDatepicker"
        [max]="maxDate"
        [min]="minDate"
        [formControl]="dateControl"
        readonly />

    <mat-error>
        <span *ngIf="dateControl?.hasError('minDate')">{{ dateControl.getError('minDate') }}</span>
        <span *ngIf="dateControl?.hasError('maxDate')">{{ dateControl.getError('maxDate') }}</span>
    </mat-error>

    <mat-datepicker-toggle matSuffix [for]="startDatepicker"></mat-datepicker-toggle>
    <mat-datepicker #startDatepicker></mat-datepicker>

    <mat-select *ngIf="showTime" class="time-selector" [compareWith]="timeComparison" [formControl]="timeControl">
        <mat-option *ngFor="let step of timeSteps" [value]="step" [disabled]="!isValidStep(step)">
            {{ step.display }}
        </mat-option>
    </mat-select>
</mat-form-field>
