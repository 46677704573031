
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import { GenUrbanNetFemaType } from './GenUrbanNetFemaType';

export class GenUrbanNetEffect {

    id: number;
    disruptionId: number;
    femaType: GenUrbanNetFemaType;
    femaTypeCount: number;
    geometry: string;
    name: string;
    nodeId: string;
    properties: string;

    constructor();
    constructor( jsonObj: any );
    constructor( genUrbanNetEffect: GenUrbanNetEffect);
    constructor( id: number );

    constructor( input?: GenUrbanNetEffect|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            this.disruptionId = input.disruptionId; // int [number]
            this.femaType = (typeof input.femaType === 'string') ? GenUrbanNetFemaType.forName(<string> input.femaType) : <any> input.femaType; // GenUrbanNetFemaType
            this.femaTypeCount = input.femaTypeCount; // int [number]
            this.geometry = input.geometry; // string [string]
            this.name = input.name; // string [string]
            this.nodeId = input.nodeId; // string [string]
            this.properties = input.properties; // string [string]
       }
    }

    static jsonResponseToList(arr: any[]): GenUrbanNetEffect[] {
        if (arr && arr.map) {
          return arr.map(a => new GenUrbanNetEffect(a));
        } else {
          return [];
        }
    }
}

