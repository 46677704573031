<div fxLayout="row" fxLayoutAlign="space-between center" class="dialog-header">
    <span>Oil Refinery Alert Info</span>
    <mat-icon [inline]="true" fontSet="fas" fontIcon="fa-times" style="color: #c9c9c9" (click)="dialogRef.close()"></mat-icon>
</div>

<div #content class="dialog-content">
    <div fxLayout="column">
        <span class="tiny-header">Description</span>
        <span class="val">{{ alertData.description }}</span>
    </div>

    <div fxLayout="column">
        <span class="tiny-header">Facility</span>
        <span class="val">{{ alertData.facility_name }}</span>
    </div>

    <div fxLayout="column">
        <span class="tiny-header">Product Class</span>
        <span class="val">{{ alertData.product_class }}</span>
    </div>

    <div fxLayout="column">
        <span class="tiny-header">Date</span>
        <span class="val">{{ alertData.distributed_datetime | momentDate }}</span>
    </div>
</div>

<div fxLayout="row" fxLayoutAlign="end" class="dialog-actions">
    <button mat-stroked-button (click)="dialogRef.close()">Close</button>
</div>
