<span class="customize-text" *ngIf="customizingLayout">CUSTOMIZING DASHBOARD</span>

<div *ngIf="useMobileLayout() && selectedType === layoutTypes.ELECTRIC">
    <ng-container [ngTemplateOutlet]="templateList"></ng-container>
</div>

<div class="layout-container">
    <div *ngIf="!customizingLayout" class="layout-filter-bar ignore-last">
        <mat-button-toggle-group class="light" [value]="selectedType" (change)="updateLayoutType($event)">
            <mat-button-toggle
                matTooltip="Electric Dashboard"
                matTooltipPosition="above"
                matTooltipShowDelay="250"
                [value]="layoutTypes.ELECTRIC">
                <mat-icon fontSet="fas" fontIcon="fa-plug"></mat-icon>
            </mat-button-toggle>
            <mat-button-toggle matTooltip="Oil Dashboard" matTooltipPosition="above" matTooltipShowDelay="250" [value]="layoutTypes.OIL">
                <mat-icon fontSet="fas" fontIcon="fa-burn"></mat-icon>
            </mat-button-toggle>
            <mat-button-toggle
                matTooltip="Natural Gas Dashboard"
                matTooltipPosition="above"
                matTooltipShowDelay="250"
                [value]="layoutTypes.NATURAL_GAS">
                <mat-icon fontSet="fas" fontIcon="fa-fire-alt"></mat-icon>
            </mat-button-toggle>
        </mat-button-toggle-group>

        <button mat-stroked-button (click)="toggleCustomize()" class="open-customize" *ngIf="allowCustomization()">
            <img src="/dist/images/icons/layout-dash.svg" alt="Open Custom Menu" />
        </button>
    </div>

    <!-- Customized Layout-->
    <div *ngIf="customizingLayout" class="customizing-layout">
        <div class="close">
            <mat-icon (click)="toggleCustomize()" class="close-customize clickable" fontSet="fas" fontIcon="fa-times"></mat-icon>
            <span *ngIf="currentLayout" class="title">{{ currentLayout.name }}</span>
        </div>
        <div class="options">
            <ng-container [ngTemplateOutlet]="templateList"></ng-container>

            <button mat-stroked-button class="add-widget" (click)="openAddWidgetModal()">
                <img src="/dist/images/icons/add-widget.svg" alt="Custom Layout Icon" />
            </button>
        </div>
    </div>

    <gridster [options]="options" class="gird">
        <gridster-item [item]="item" *ngFor="let item of dashboard">
            <div [ngSwitch]="item.widgetName.name" [class.invisible]="item.loading">
                <ng-container *ngSwitchCase="'COMMUNITY_LIST'">
                    <ng-container [ngTemplateOutlet]="community" [ngTemplateOutletContext]="{item: item}"></ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="'OUTAGE_MAP'">
                    <ng-container [ngTemplateOutlet]="outageMap" [ngTemplateOutletContext]="{item: item}"></ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="'REPORT_LIST'">
                    <ng-container [ngTemplateOutlet]="report" [ngTemplateOutletContext]="{item: item}"></ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="'STATS'">
                    <ng-container [ngTemplateOutlet]="stats" [ngTemplateOutletContext]="{item: item}"></ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="'UTILITIES_IMPACTED'">
                    <ng-container [ngTemplateOutlet]="utilitiesImpacted" [ngTemplateOutletContext]="{item: item}"></ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="'OUTAGE_CHART'">
                    <ng-container [ngTemplateOutlet]="chart" [ngTemplateOutletContext]="{item: item}"></ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="'OUTAGE_SUMMARY'">
                    <ng-container [ngTemplateOutlet]="outageSummary" [ngTemplateOutletContext]="{item: item}"></ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="'DAILY_POWER_ESTIMATE'">
                    <ng-container [ngTemplateOutlet]="powerEstimate" [ngTemplateOutletContext]="{item: item}"></ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="'GAS_BURN'">
                    <ng-container [ngTemplateOutlet]="gasBurn" [ngTemplateOutletContext]="{item: item}"></ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="'OIL_STORAGE'">
                    <ng-container [ngTemplateOutlet]="oilStorage" [ngTemplateOutletContext]="{item: item}"></ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="'NG_NOTICES'">
                    <ng-container [ngTemplateOutlet]="ngNotices" [ngTemplateOutletContext]="{item: item}"></ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="'OIL_REFINERY_STATUS'">
                    <ng-container [ngTemplateOutlet]="refineryStatus" [ngTemplateOutletContext]="{item: item}"></ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="'OIL_BARREL_FLOW'">
                    <ng-container [ngTemplateOutlet]="oilBarrelFlow" [ngTemplateOutletContext]="{item: item}"></ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="'OIL_REFINERY_ALERT'">
                    <ng-container [ngTemplateOutlet]="oilRefineryAlert" [ngTemplateOutletContext]="{item: item}"></ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="'EIA_PETROLEUM_REPORT'">
                    <ng-container [ngTemplateOutlet]="eaiWeeklyPetroleumReport" [ngTemplateOutletContext]="{item: item}"></ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="'OIL_PLANNED_OUTAGES'">
                    <ng-container [ngTemplateOutlet]="oilPlannedOutage" [ngTemplateOutletContext]="{item: item}"></ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="'DERIVED_STORAGE'">
                    <ng-container [ngTemplateOutlet]="derivedStorage" [ngTemplateOutletContext]="{item: item}"></ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="'UNDERGROUND_STORAGE'">
                    <ng-container [ngTemplateOutlet]="undergroundStorage" [ngTemplateOutletContext]="{item: item}"></ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="'OIL_FLOW_TABLE'">
                    <ng-container [ngTemplateOutlet]="oilFlowTable" [ngTemplateOutletContext]="{item: item}"></ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="'NG_NOMINATION'">
                    <ng-container [ngTemplateOutlet]="ngNomination" [ngTemplateOutletContext]="{item: item}"></ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="'NG_RECEIPT_DELIVERY'">
                    <ng-container [ngTemplateOutlet]="ngReceiptDelivery" [ngTemplateOutletContext]="{item: item}"></ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="'NG_OPERATIONAL_FLOW'">
                    <ng-container [ngTemplateOutlet]="ngOperationalFlow" [ngTemplateOutletContext]="{item: item}"></ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="'SYSTEM_NEWS'">
                    <ng-container [ngTemplateOutlet]="systemNews" [ngTemplateOutletContext]="{item: item}"></ng-container>
                </ng-container>
            </div>

            <div class="auto-mask" *ngIf="item.loading">
                <div class="mask-message">Loading...</div>
            </div>

            <div *ngIf="customizingLayout || loading" class="widget-mask draggable">
                <span class="mask-text">{{ item.widgetName | widgetName }}</span>
                <mat-icon class="drag-icon" fontSet="fas" fontIcon="fa-arrows-alt"></mat-icon>
                <mat-icon class="remove-icon" fontSet="fas" fontIcon="fa-trash-alt" (mousedown)="removeItem(item)"></mat-icon>
                <mat-icon
                    *ngIf="item.configurable"
                    class="setting-icon"
                    fontSet="fas"
                    fontIcon="fa-cog"
                    (click)="openWidgetSettingModal(item)"></mat-icon>
            </div>
        </gridster-item>
    </gridster>

    <div class="action-buttons" *ngIf="customizingLayout">
        <button mat-stroked-button (click)="closeCustomization(true)">Cancel</button>
        <button mat-stroked-button (click)="toggleCustomize()">Close</button>
        <button mat-flat-button color="primary" (click)="saveLayout()">Save Layout</button>
    </div>
</div>

<ng-template #community let-item="item">
    <eaglei-community-widget class="ignore-content" [item]="item" (loading)="item.loading = $event"></eaglei-community-widget>
</ng-template>

<ng-template #report let-item="item">
    <eaglei-report-widget class="ignore-content" [item]="item" (loading)="item.loading = $event"></eaglei-report-widget>
</ng-template>

<ng-template #outageMap let-item="item">
    <eaglei-outage-map-widget class="ignore-content" [item]="item" (loading)="item.loading = $event"></eaglei-outage-map-widget>
</ng-template>

<ng-template #stats let-item="item">
    <eaglei-stat-widget class="ignore-content" [item]="item" (loading)="item.loading = $event"></eaglei-stat-widget>
</ng-template>

<ng-template #utilitiesImpacted let-item="item">
    <eaglei-utilities-impacted-widget
        class="ignore-content"
        [item]="item"
        (loading)="item.loading = $event"></eaglei-utilities-impacted-widget>
</ng-template>

<ng-template #chart let-item="item">
    <eaglei-chart-widget class="ignore-content" [item]="item" (loading)="item.loading = $event"></eaglei-chart-widget>
</ng-template>

<ng-template #outageSummary let-item="item">
    <eaglei-summary-widget class="ignore-content" [item]="item" (loading)="item.loading = $event"></eaglei-summary-widget>
</ng-template>

<ng-template #oilBarrelFlow let-item="item">
    <eaglei-oil-barrel-flow-widget class="ignore-content" [item]="item" (loading)="item.loading = $event"></eaglei-oil-barrel-flow-widget>
</ng-template>

<ng-template #powerEstimate let-item="item">
    <eaglei-power-estimate-widget class="ignore-content" [item]="item" (loading)="item.loading = $event"></eaglei-power-estimate-widget>
</ng-template>

<ng-template #refineryStatus let-item="item">
    <eaglei-refinery-status-widget class="ignore-content" [item]="item" (loading)="item.loading = $event"></eaglei-refinery-status-widget>
</ng-template>

<ng-template #oilRefineryAlert let-item="item">
    <eaglei-oil-refinery-alert-widget
        class="ignore-content"
        [item]="item"
        (loading)="item.loading = $event"></eaglei-oil-refinery-alert-widget>
</ng-template>

<ng-template #eaiWeeklyPetroleumReport let-item="item">
    <eaglei-eia-weekly-petroleum-report-widget
        class="ignore-content"
        [item]="item"
        (loading)="item.loading = $event"></eaglei-eia-weekly-petroleum-report-widget>
</ng-template>

<ng-template #oilPlannedOutage let-item="item">
    <eaglei-oil-planned-outage-widget
        class="ignore-content"
        [item]="item"
        (loading)="item.loading = $event"></eaglei-oil-planned-outage-widget>
</ng-template>

<ng-template #undergroundStorage let-item="item">
    <eaglei-underground-storage-widget
        class="ignore-content"
        [item]="item"
        (loading)="item.loading = $event"></eaglei-underground-storage-widget>
</ng-template>

<ng-template #derivedStorage let-item="item">
    <eaglei-derived-storage-widget class="ignore-content" [item]="item" (loading)="item.loading = $event"></eaglei-derived-storage-widget>
</ng-template>

<ng-template #ngNotices let-item="item">
    <eaglei-ng-notices-widget class="ignore-content" [item]="item" (loading)="item.loading = $event"></eaglei-ng-notices-widget>
</ng-template>

<ng-template #oilStorage let-item="item">
    <eaglei-oil-storage-widget class="ignore-content" [item]="item" (loading)="item.loading = $event"></eaglei-oil-storage-widget>
</ng-template>

<ng-template #gasBurn let-item="item">
    <eaglei-gas-burn-widget class="ignore-content" [item]="item" (loading)="item.loading = $event"></eaglei-gas-burn-widget>
</ng-template>

<ng-template #ngNomination let-item="item">
    <eaglei-ng-nomination-widget class="ignore-content" [item]="item" (loading)="item.loading = $event"></eaglei-ng-nomination-widget>
</ng-template>

<ng-template #oilFlowTable let-item="item">
    <eaglei-oil-flow-table-widget class="ignore-content" [item]="item" (loading)="item.loading = $event"></eaglei-oil-flow-table-widget>
</ng-template>

<ng-template #systemNews let-item="item">
    <eaglei-system-news-widget class="ignore-content" [item]="item" (loading)="item.loading = $event"></eaglei-system-news-widget>
</ng-template>

<ng-template #ngReceiptDelivery let-item="item">
    <eaglei-ng-receipt-delivery-widget
        class="ignore-content"
        [item]="item"
        (loading)="item.loading = $event"></eaglei-ng-receipt-delivery-widget>
</ng-template>

<ng-template #ngOperationalFlow let-item="item">
    <eaglei-ng-operational-flow-widget
        class="ignore-content"
        [item]="item"
        (loading)="item.loading = $event"></eaglei-ng-operational-flow-widget>
</ng-template>

<ng-template #templateList>
    <mat-form-field appearance="outline" class="filter-select">
        <mat-select placeholder="Templates" (selectionChange)="loadLayout($event.value)">
            <mat-select-trigger>Templates</mat-select-trigger>
            <mat-option (click)="openLayoutMangerModal($event)" class="manage-option"> Manage Templates </mat-option>
            <mat-optgroup label="User Templates" *ngIf="showTemplateGroup(userLayouts)">
                <ng-container *ngFor="let layout of userLayouts">
                    <mat-option *ngIf="!matchesCurrentLayout(layout)" [value]="layout">
                        {{ layout.name }}
                    </mat-option>
                </ng-container>
            </mat-optgroup>
            <mat-optgroup label="System Templates" *ngIf="showTemplateGroup(templateLayouts)">
                <mat-option *ngFor="let layout of templateLayouts" [value]="layout">
                    {{ layout.name }}
                </mat-option>
            </mat-optgroup>
        </mat-select>
    </mat-form-field>
</ng-template>
