<div class="system-news">
    <div class="filter-bar">
        <mat-form-field class="filter-select" appearance="outline" [class.active]="isSearchTextActive()">
            <mat-label *ngIf="isSearchTextActive()">{{ filterPlaceholder }}</mat-label>
            <input matInput [placeholder]="filterPlaceholder" [(ngModel)]="searchText" (keyup)="filterNews()" />
            <mat-icon matSuffix class="icon input-search-icon" fontSet="fas" fontIcon="fa-search"></mat-icon>
        </mat-form-field>

        <eaglei-date-range
            *ngIf="!onlyUseFaq"
            [showTime]="false"
            [newDesign]="true"
            [start]="startDate"
            [end]="endDate"
            [min]="minDate"
            [gap]="'0px'"
            (change)="updateDateRange($event)"></eaglei-date-range>

        <mat-button-toggle-group name="status" [value]="sortDirection" class="text-list">
            <mat-button-toggle [value]="-1" (change)="sortSystemNews(-1)">NEWEST FIRST</mat-button-toggle>
            <mat-button-toggle [value]="1" (change)="sortSystemNews(1)">OLDEST FIRST</mat-button-toggle>
        </mat-button-toggle-group>

        <button
            mat-stroked-button
            (click)="createSystemNews()"
            class="action-icon"
            *ngIf="isReporter"
            [matTooltip]="createText"
            matTooltipShowDelay="250"
            matTooltipPosition="above">
            <img class="create-img" src="/dist/images/icons/add-new.svg" alt="Create System News" />
        </button>
    </div>

    <div class="news-list">
        <ng-container *ngIf="filteredNews.length !== 0; else noDataMask">
            <div *ngFor="let news of getPaginatorValues()" class="news-item" (click)="selectNews(news)">
                <div [class.selected]="news === selectedNews">
                    <ng-container *ngIf="!news.editing; else editingTitle">
                        <p class="text-color news-title">{{ news.title }}</p>
                    </ng-container>
                    <ng-template #editingTitle>
                        <mat-form-field (click)="$event.stopPropagation()">
                            <input
                                [maxLength]="100"
                                class="text-color"
                                type="text"
                                matInput
                                [(ngModel)]="updatedNews.title"
                                [formControl]="titleControl" />
                            <mat-hint align="end">
                                <span *ngIf="updatedNews && updatedNews.title">{{ updatedNews.title.length }}/100</span>
                            </mat-hint>
                            <mat-error *ngIf="titleControl.errors">
                                <ng-container *ngIf="titleControl.errors.required"> A title is required </ng-container>
                            </mat-error>
                        </mat-form-field>
                    </ng-template>

                    <div class="news-date">
                        <span>Posted </span> <b>{{ news.createDate | momentDate }} </b>
                    </div>
                </div>

                <div class="news-content" [class.show-content]="selectedNews === news">
                    <ng-container *ngIf="!news.editing; else editingDescription">
                        <div class="message-content text-color" [innerHtml]="news.value | sanitize"></div>
                    </ng-container>
                    <ng-template #editingDescription>
                        <mat-form-field (click)="$event.stopPropagation()">
                            <textarea
                                matInput
                                matTextareaAutosize
                                matAutosizeMaxRows="5"
                                matAutosizeMinRows="5"
                                [(ngModel)]="updatedNews.value">
                            </textarea>
                        </mat-form-field>
                    </ng-template>

                    <div *ngIf="isReporter" class="news-actions">
                        <ng-container *ngIf="news.editing; else notEditing">
                            <button mat-stroked-button (click)="cancelUpdate(news, $event)">Cancel</button>
                            <button mat-flat-button color="primary" (click)="updateNews(news)">Save</button>
                        </ng-container>
                        <ng-template #notEditing>
                            <button mat-stroked-button (click)="deleteSystemNews(news, $event)">Delete</button>
                            <button mat-flat-button color="primary" (click)="editNews(news, $event)">Edit</button>
                        </ng-template>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-template #noDataMask>
            <div class="auto-mask">
                <span class="mask-message text-color">{{ getMaskText() }}</span>
            </div>
        </ng-template>
    </div>

    <mat-paginator [pageSizeOptions]="[25, 50, 75, 100]" showFirstLastButtons></mat-paginator>
</div>
