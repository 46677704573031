<mat-card>
    <div class="filter-bar">
        <div class="status-links">
            <div
                class="community-status"
                *ngFor="let state of communityStates"
                [class.current-status]="state.id === currentState"
                (click)="setCurrentState(state)">
                <mat-icon fontSet="fas" [fontIcon]="state.icon" inline></mat-icon>
                <span class="section-title"> {{ state.display }}</span>
            </div>
        </div>

        <mat-form-field appearance="outline" class="search-input" [class.active]="isSearchTextActive()">
            <mat-label>Search Community Name</mat-label>
            <input matInput placeholder="Search Community Name" (keyup)="searchCommunities($event.target.value)" />
            <mat-icon matSuffix fontSet="fas" fontIcon="fa-search"></mat-icon>
        </mat-form-field>
    </div>
</mat-card>

<mat-card>
    <mat-card-content>
        <div *ngIf="filteredCommunities.length === 0">
            <div class="info-message">
                <p>{{ getMaskMessage() }}</p>
            </div>
        </div>

        <div class="community-list">
            <div *ngFor="let community of filteredCommunities">
                <div class="container" (click)="goToCommunity(community)">
                    <div class="community">
                        <div class="wide">
                            <p class="name">{{ community.name }}</p>
                            <p class="description multiline-ellipsis">{{ community.description }}</p>
                        </div>

                        <div class="community-actions">
                            <button
                                mat-stroked-button
                                *ngIf="!community.isMember(user.id) && community.publicAccess"
                                (click)="requestAccess(community, $event)">
                                Request to Join
                            </button>

                            <button mat-stroked-button *ngIf="community.isUserPending(user.id)" disabled>Pending</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>
