import {AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatRadioChange} from '@angular/material/radio';
import {GenOutageAggregationLevel} from 'frontend/generated/serverModels/GenOutageAggregationLevel';
import {ApplicationConfig} from 'frontend/src/app/classes/application-config';
import {DataService} from 'frontend/src/app/services/data.service';
import {throttleTime} from 'rxjs/operators';
import {MapLiteComponent} from '../../components/map-lite/map-lite.component';
import {State} from '../../../outage/classes/state';
import {LeafletNomFilter} from '../../../layer/filters/leaflet-nom-filter';

@Component({
    selector: 'eaglei-filter-lite',
    templateUrl: './filter-lite.component.html',
    styleUrls: ['./filter-lite.component.scss'],
})
export class FilterLiteComponent implements OnInit, AfterViewInit {
    @ViewChild('filters') filters: ElementRef;

    private liteMap: MapLiteComponent;

    public selectedAggLevel: GenOutageAggregationLevel = GenOutageAggregationLevel.state;
    public selectedStates: State[] = [];
    public states = DataService.states.getValue();

    constructor(public dialogRef: MatDialogRef<FilterLiteComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
        this.liteMap = data.map;

        const filters: LeafletNomFilter = data.filters;

        this.selectedAggLevel = filters.aggregationLevel;
        this.selectedStates = filters.states;
    }

    ngOnInit() {}

    public ngAfterViewInit(): void {
        this.setModalHeight();
        ApplicationConfig.resizeEvent.pipe(throttleTime(250)).subscribe(() => this.setModalHeight());
    }

    private setModalHeight(): void {
        if (ApplicationConfig.onPhone()) {
            (this.filters.nativeElement as HTMLElement).style.maxHeight = document.body.clientHeight * 0.55 + 'px';
        }
    }

    public updateAggLevel(event: MatRadioChange): void {
        this.selectedAggLevel = event.value;
    }

    public isStateSelected(state: State): boolean {
        return this.selectedStates.findIndex((s) => state.id === s.id) !== -1;
    }

    public updateSelectedFilters(value: State, selected: boolean): void {
        const activeFilter = this.selectedStates;
        const index = activeFilter.findIndex((val) => val.id === value.id);

        if (selected && index === -1) {
            activeFilter.push(value as any);
        } else if (!selected && index !== -1) {
            activeFilter.splice(index, 1);
        }
    }

    public changeAllFilters(selected: boolean): void {
        if (this.selectedAggLevel === GenOutageAggregationLevel.state || this.selectedAggLevel === GenOutageAggregationLevel.county) {
            this.selectedStates = selected ? this.states.slice() : [];
        }
    }

    private updateFilter(): void {
        // this.liteMap.updateFilter(this.selectedAggLevel, this.selectedStates);
    }

    public getAggValue(agg: string): GenOutageAggregationLevel {
        switch (agg) {
            case 'state':
                return GenOutageAggregationLevel.state;
            case 'county':
                return GenOutageAggregationLevel.county;
        }
    }

    public apply(): void {
        this.updateFilter();
        this.dialogRef.close();
    }

    public cancel(): void {
        this.dialogRef.close();
    }
}
