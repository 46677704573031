
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import { GenCommunityPermission } from './GenCommunityPermission';
import { GenFilter } from './GenFilter';
import { GenLegend } from './GenLegend';
import { GenMapLayerPopover } from './GenMapLayerPopover';
import { GenRoleDefinition } from './GenRoleDefinition';
import { GenServiceType } from './GenServiceType';

export class GenMapLayer {

    id: number;
    active: boolean;
    attributionTitle: string;
    attributionUrl: string;
    dataAccess: GenCommunityPermission[];
    defaultActive: boolean;
    description: string;
    displayName: string;
    filters: GenFilter[];
    layerGroupId: number;
    legend: GenLegend;
    legendUrl: string;
    ordering: number;
    orderingUpdated: boolean;
    params: string;
    popoverData: GenMapLayerPopover[];
    publicAccess: boolean;
    queryable: boolean;
    roles: GenRoleDefinition[];
    servicelayer: string;
    servicetype: GenServiceType;
    serviceurl: string;
    uiHandle: string;
    visible: boolean;

    constructor();
    constructor( jsonObj: any );
    constructor( genMapLayer: GenMapLayer);
    constructor( id: number );

    constructor( input?: GenMapLayer|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            this.active = input.active; // boolean [boolean]
            this.attributionTitle = input.attributionTitle; // string [string]
            this.attributionUrl = input.attributionUrl; // string [string]
            if ( input.dataAccess && input.dataAccess.map ) {
                this.dataAccess = input.dataAccess.map( ( x: GenCommunityPermission ) => {
                    return (typeof x === 'string') ? GenCommunityPermission.forName(<string> x) : <any> x; // GenCommunityPermission
                } );
            } else {
                this.dataAccess = input.dataAccess; // GenCommunityPermission
            }
            this.defaultActive = input.defaultActive; // boolean [boolean]
            this.description = input.description; // string [string]
            this.displayName = input.displayName; // string [string]
            if ( input.filters && input.filters.map ) { // list [GenFilter]
                this.filters = input.filters.map( ( x: GenFilter ) => {
                    return new GenFilter( x );
                } );
            } else {
                this.filters = input.filters;
            }
            this.layerGroupId = input.layerGroupId; // int [number]
            if( input.legend ) {
              this.legend = new GenLegend( input.legend );
            }
            this.legendUrl = input.legendUrl; // string [string]
            this.ordering = input.ordering; // int [number]
            this.orderingUpdated = input.orderingUpdated; // boolean [boolean]
            this.params = input.params; // string [string]
            if ( input.popoverData && input.popoverData.map ) { // list [GenMapLayerPopover]
                this.popoverData = input.popoverData.map( ( x: GenMapLayerPopover ) => {
                    return new GenMapLayerPopover( x );
                } );
            } else {
                this.popoverData = input.popoverData;
            }
            this.publicAccess = input.publicAccess; // boolean [boolean]
            this.queryable = input.queryable; // boolean [boolean]
            if ( input.roles && input.roles.map ) {
                this.roles = input.roles.map( ( x: GenRoleDefinition ) => {
                    return (typeof x === 'string') ? GenRoleDefinition.forName(<string> x) : <any> x; // GenRoleDefinition
                } );
            } else {
                this.roles = input.roles; // GenRoleDefinition
            }
            this.servicelayer = input.servicelayer; // string [string]
            this.servicetype = (typeof input.servicetype === 'string') ? GenServiceType.forName(<string> input.servicetype) : <any> input.servicetype; // GenServiceType
            this.serviceurl = input.serviceurl; // string [string]
            this.uiHandle = input.uiHandle; // string [string]
            this.visible = input.visible; // boolean [boolean]
       }
    }

    static jsonResponseToList(arr: any[]): GenMapLayer[] {
        if (arr && arr.map) {
          return arr.map(a => new GenMapLayer(a));
        } else {
          return [];
        }
    }
}

