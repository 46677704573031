<div fxLayout="row" fxLayoutAlign="space-between center" class="dialog-header">
    <div>
        <span class="filter-header">{{ location.name }}</span>
        <p class="sub-title" *ngIf="location.subtitle">({{ location.subtitle }})</p>
    </div>
    <mat-icon fontSet="fas" fontIcon="fa-times" inline class="clickable" (click)="ref.close()"></mat-icon>
</div>

<div class="dialog-content" #content>
    <div fxLayout="column">
        <span><b>Location Information</b></span>
        <div *ngFor="let desk of location.desks" fxLayout="row">
            <!--    E-mails-->
            <div>
                <div *ngFor="let email of desk.emails" class="list-item" fxLayout="row">
                    <mat-icon fontSet="fas" fontIcon="fa-envelope" inline></mat-icon>
                    <a
                        href="mailto:{{ email.information }}"
                        class="ellipsis"
                        [matTooltip]="email.information"
                        matTooltipPosition="above"
                        matTooltipShowDelay="250"
                        >{{ email.information }}</a
                    >
                </div>
            </div>
            <!--    Phone numbers-->
            <div>
                <div *ngFor="let phone of desk.phones" class="list-item" fxLayout="row">
                    <mat-icon fontSet="fas" fontIcon="fa-phone-alt" inline></mat-icon>
                    <a
                        href="tel:{{ phone.information }}"
                        class="ellipsis"
                        [matTooltip]="phone.information"
                        matTooltipPosition="above"
                        matTooltipShowDelay="250"
                        >{{ phone.information | phoneNumber }}</a
                    >
                </div>
            </div>
        </div>

        <span><b>Deployments</b></span>
        <div *ngFor="let deploy of deployments" fxLayout="row">
            <!--    Personnel-->
            <div>
                <div class="list-item" fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon fontSet="fas" fontIcon="fa-user" inline></mat-icon>
                    <span
                        class="ellipsis"
                        matTooltip="{{ deploy.personnel.name }} ({{ getShiftLabel(deploy.shiftId) }})"
                        matTooltipPosition="above"
                        matTooltipShowDelay="250">
                        {{ deploy.personnel.name }} <i>({{ getShiftLabel(deploy.shiftId) }})</i>
                    </span>
                </div>
            </div>
            <!--    E-mails-->
            <div>
                <div *ngFor="let email of deploy.personnel.emails" class="list-item" fxLayout="row">
                    <mat-icon fontSet="fas" fontIcon="fa-envelope" inline></mat-icon>
                    <a
                        href="mailto:{{ email.information }}"
                        class="ellipsis"
                        [matTooltip]="email.information"
                        matTooltipPosition="above"
                        matTooltipShowDelay="250"
                        >{{ email.information }}</a
                    >
                </div>
            </div>
            <!--    Phone numbers-->
            <div>
                <div *ngFor="let phone of deploy.personnel.phones" class="list-item" fxLayout="row">
                    <mat-icon fontSet="fas" fontIcon="fa-phone-alt" inline></mat-icon>
                    <a
                        href="tel:{{ phone.information }}"
                        class="ellipsis"
                        [matTooltip]="phone.information"
                        matTooltipPosition="above"
                        matTooltipShowDelay="250"
                        >{{ phone.information | phoneNumber }}</a
                    >
                </div>
            </div>
        </div>
    </div>
</div>
