
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import * as moment from 'moment';

export class GenRestoration {

    actualCustomers: number;
    countyId: number;
    countyName: string;
    coveredCustomers: number;
    femaRegion: number;
    maxNewOutageCount: number;
    maxOutageCount: number;
    maxRestorationCount: number;
    newOutageCount: number;
    outageCount: number;
    outageRunStartTime: moment.Moment;
    previousOutageCount: number;
    restorationCount: number;
    state: string;

    constructor();
    constructor( jsonObj: any );
    constructor( genRestoration: GenRestoration);

    constructor( input?: GenRestoration ) {
        if ( input && typeof input !== 'number' ) {
            this.actualCustomers = input.actualCustomers; // int [number]
            this.countyId = input.countyId; // int [number]
            this.countyName = input.countyName; // string [string]
            this.coveredCustomers = input.coveredCustomers; // int [number]
            this.femaRegion = input.femaRegion; // int [number]
            this.maxNewOutageCount = input.maxNewOutageCount; // int [number]
            this.maxOutageCount = input.maxOutageCount; // int [number]
            this.maxRestorationCount = input.maxRestorationCount; // int [number]
            this.newOutageCount = input.newOutageCount; // int [number]
            this.outageCount = input.outageCount; // int [number]
            if ( input.outageRunStartTime && typeof input.outageRunStartTime === 'string' ) {
                this.outageRunStartTime = moment(input.outageRunStartTime).utc();
            } else {
                this.outageRunStartTime = input.outageRunStartTime;
            }
            this.previousOutageCount = input.previousOutageCount; // int [number]
            this.restorationCount = input.restorationCount; // int [number]
            this.state = input.state; // string [string]
       }
    }

    static jsonResponseToList(arr: any[]): GenRestoration[] {
        if (arr && arr.map) {
          return arr.map(a => new GenRestoration(a));
        } else {
          return [];
        }
    }
}

