import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {ApplicationConfig} from 'frontend/src/app/classes/application-config';
import * as moment from 'moment';
import {debounceTime} from 'rxjs/operators';
import {TimeSliderEvent} from '../../interfaces/time-slider-event.interface';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {LockTimeService} from '../../../app/services/lock-time.service';
import {Subscription} from 'rxjs';
import {AutoUpdate} from '../../classes/auto-update';
import {TimeSliderSection} from '../../interfaces/time-slider-section.interface';

type DotPosition = 'start' | 'end' | 'center';

@Component({
    selector: 'eaglei-time-slider',
    templateUrl: './time-slider.component.html',
    styleUrls: ['./time-slider.component.scss'],
})
export class TimeSliderComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
    // HTML Elements
    @ViewChild('slideLine') slideLine: ElementRef<HTMLElement>;
    @ViewChild('sectionArea') sectionArea: ElementRef<HTMLElement>;
    @ViewChild('dragArea') dragArea: ElementRef<HTMLElement>;
    @ViewChild('colorLine') colorLine: ElementRef<HTMLElement>;
    @ViewChild('alertArea') alertArea: ElementRef<HTMLElement>;

    @ViewChild('currentDot') currentDot: ElementRef<HTMLElement>;

    @Input() stepSize: number = 15;
    @Input() stepResolution: moment.unitOfTime.DurationConstructor = 'minutes';
    @Input() initialMultiplier: number = 1000;

    @Input() followMiddleDot: boolean = true;
    @Input() allowFutureStep: boolean = false;

    @Input() sliderStart: moment.Moment = moment().startOf('day');
    @Input() sliderEnd: moment.Moment = moment().endOf('day');

    /**
     * If set to true, the date change icon will be available with the min and max dates, if false the date icon will be
     * removed and the min and max inputs will be ignored. Defaults to true
     */
    @Input() allowDateChange: boolean = true;

    @Input() start: moment.Moment = this.sliderStart.clone();
    @Input() current: moment.Moment = ApplicationConfig.roundMinute();
    @Input() end: moment.Moment = this.sliderEnd.clone();

    @Input() min?: Date;
    @Input() max?: Date = ApplicationConfig.roundMinute().add(2, 'days').toDate();

    @Input() sections?: TimeSliderSection[];

    @Output() dateEvent: EventEmitter<TimeSliderEvent> = new EventEmitter<TimeSliderEvent>();

    public isPlaying = false;
    public isLive = false;

    /**
     * If the live text should be visible. if the slider end is current date or the future, the text will be shown and
     * clickable. If the slider end is in the past the text will be hidden.
     */
    public showLiveButton: boolean = true;

    private emitHandle: any;
    public animationIntervalHandle;
    public subscriptions: Subscription = new Subscription();

    private autoUpdate = new AutoUpdate(this.autoUpdateSlider.bind(this)).setAutoUpdate(true);

    constructor() {
        const resizeSub = ApplicationConfig.resizeEvent.pipe(debounceTime(100)).subscribe(() => {
            setTimeout(() => {
                this.adjustToResize();
            }, 400);
        });

        this.subscriptions.add(resizeSub);
    }

    ngOnInit(): void {}

    ngAfterViewInit(): void {
        this.adjustToResize();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes?.max) {
            this.max = changes.max.currentValue ? changes.max.currentValue : moment().toDate();
        }

        // Updates the slider range, when the start and end are change, the range is adjusted. the current value is set
        // to now if the end date is in the future, or the start of the event if the end date is in the past.
        if (changes.hasOwnProperty('sliderEnd') && changes.hasOwnProperty('sliderStart')) {
            this.sliderStart = changes.sliderStart.currentValue || ApplicationConfig.roundMinute().subtract(1, 'day').startOf('day');
            this.sliderEnd = changes.sliderEnd.currentValue || ApplicationConfig.roundMinute();
            this.current = this.sliderEnd.isSameOrAfter(ApplicationConfig.roundMinute(), 'minute')
                ? ApplicationConfig.roundMinute()
                : this.sliderStart.clone();
            this.checkLiveDataStatus();
            this.adjustToResize();
            this.emitDates();

            this.showLiveButton = this.sliderEnd.isSameOrAfter(ApplicationConfig.roundMinute(), 'minute');
        }

        if (changes.hasOwnProperty('sections') && this.sectionArea) {
            this.drawSectionsOnSlider();
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
        this.autoUpdate.clear();
    }

    private autoUpdateSlider() {
        if (this.isLive) {
            this.current = ApplicationConfig.roundMinute();
            this.moveToPixel(this.momentToPx(this.current));
            this.emitDates();
        }
    }

    public adjustToResize(): void {
        this.currentDot.nativeElement.style.left = `${this.momentToPx(this.current)}px`;

        if (this.followMiddleDot) {
            const lineMax = (this.currentDot.nativeElement as HTMLElement).offsetLeft;
            const rect = (this.slideLine.nativeElement as HTMLElement).getBoundingClientRect();
            this.colorLine.nativeElement.style.right = `${rect.width - lineMax}px`;
        }
    }

    public emitDates(): void {
        if (this.emitHandle) {
            clearTimeout(this.emitHandle);
        }

        this.emitHandle = setTimeout(() => {
            const currentSections = this.sections
                .filter((section) => this.current.isBetween(section.startTime, section.endTime, 'minute', '[]'))
                .map((section) => section.name);

            const event: TimeSliderEvent = {
                start: this.start,
                current: this.current,
                end: this.end,
                isPlaying: this.isPlaying,
                sections: currentSections,
            };

            this.dateEvent.emit(event);
        }, 250);
    }

    // Mouse event handling methods
    public onMouseDown(element: HTMLElement, position: DotPosition) {
        const me = this;

        this.dragArea.nativeElement.style.zIndex = '100';
        this.dragArea.nativeElement.style.pointerEvents = 'all';

        const mouseUp = () => {
            this.dragArea.nativeElement.style.zIndex = '0';
            this.dragArea.nativeElement.style.pointerEvents = 'none';

            if (position === 'start') {
                this.start = this.pxToMoment(element.offsetLeft);
            } else if (position === 'center') {
                const newValue = this.pxToMoment(element.offsetLeft);
                if (this.allowFutureStep || newValue.isSameOrBefore(ApplicationConfig.roundMinute(), 'minute')) {
                    this.current = newValue;
                }
            } else if (position === 'end') {
                this.end = this.pxToMoment(element.offsetLeft);
            }

            me.dragArea.nativeElement.removeEventListener('mousemove', mouseMove);
            me.dragArea.nativeElement.removeEventListener('mouseup', mouseUp);
            this.emitDates();
        };

        const mouseMove = (event: MouseEvent) => {
            const pixelValue = event.offsetX;

            if (position === 'start') {
                me.colorLine.nativeElement.style.left = `${pixelValue}px`;
            } else if (position === 'center') {
                const nextValue = this.pxToMoment(pixelValue);
                if (!this.allowFutureStep && nextValue.isAfter(ApplicationConfig.roundMinute(), 'minute')) {
                    return;
                }

                if (this.followMiddleDot) {
                    this.updateColorLineEndPosition(pixelValue);
                }
                this.current = nextValue;
            } else if (position === 'end') {
                if (!this.followMiddleDot) {
                    this.updateColorLineEndPosition(pixelValue);
                }
            }

            element.style.left = `${this.momentToPx(this.current)}px`;
        };

        me.dragArea.nativeElement.addEventListener('mousemove', mouseMove);
        me.dragArea.nativeElement.addEventListener('mouseup', mouseUp);
    }

    /**
     * Converts a pixel position to the moment representation
     * @param xPos The pixel to be converted to a moment value.
     * @private
     */
    private pxToMoment(xPos: number): moment.Moment {
        const lineWidth = (this.slideLine.nativeElement as HTMLElement).getBoundingClientRect().width;
        const timeDiff = this.sliderEnd.valueOf() - this.sliderStart.valueOf();

        const percentOfLine = xPos / lineWidth;
        const valueToAdd = percentOfLine * timeDiff;

        let ret = this.sliderStart.clone().add(valueToAdd, 'milliseconds');

        if (ret.isBefore(this.sliderStart)) {
            ret = this.sliderStart.clone();
        } else if (ret.isAfter(this.sliderEnd)) {
            ret = this.sliderEnd.clone();
        }

        return ret;
    }

    /**
     * Converts a moment to a pixel position on the time slider
     * @param time The moment to be converted to a pixel location.
     * @private
     */
    private momentToPx(time: moment.Moment): number {
        const lineWidth = (this.slideLine.nativeElement as HTMLElement).getBoundingClientRect().width;
        const rangeInMs = this.sliderEnd.valueOf() - this.sliderStart.valueOf();

        const msStep = lineWidth / rangeInMs;
        const diff = time.diff(this.sliderStart);
        return diff * msStep;
    }

    public moveDot(element: HTMLDivElement, event: MouseEvent) {
        const pixelValue = event.offsetX;

        let tmpMoment = this.pxToMoment(pixelValue);

        const newMinute = this.stepSize - (tmpMoment.minute() % this.stepSize);
        tmpMoment.add(newMinute, 'minutes');

        if (!this.allowFutureStep && tmpMoment.isAfter(ApplicationConfig.roundMinute(), 'minute')) {
            tmpMoment = ApplicationConfig.roundMinute();
        }

        this.moveToPixel(this.momentToPx(tmpMoment));
        this.emitDates();
    }

    public moveToPixel(pixelValue: number) {
        this.currentDot.nativeElement.style.left = `${pixelValue}px`;

        if (this.followMiddleDot) {
            this.updateColorLineEndPosition(pixelValue);
        }

        this.current = this.pxToMoment(pixelValue);
        this.checkLiveDataStatus();
    }

    private updateColorLineEndPosition(pixel: number) {
        const rect = (this.dragArea.nativeElement as HTMLElement).getBoundingClientRect();
        this.colorLine.nativeElement.style.right = `${rect.width - pixel}px`;
    }

    /**
     * Callback function that updates the start date of the picker, then adjusts the locations of the current dot
     * @param event The datepicker event that fires the callback
     */
    public updateStartDate(event: MatDatepickerInputEvent<unknown>) {
        this.sliderStart = moment(event.value);

        this.start = this.sliderStart.clone();
        const position = Number(this.currentDot.nativeElement.style.left.slice(0, -2));

        let newDate = this.pxToMoment(position);
        if (!this.allowFutureStep && newDate.isAfter(ApplicationConfig.roundMinute(), 'minute')) {
            newDate = ApplicationConfig.roundMinute();
            this.moveToPixel(this.momentToPx(newDate));
        }

        this.current = newDate.clone();
        this.end = this.sliderEnd.clone();

        this.checkLiveDataStatus();
        this.emitDates();
    }

    /**
     * Callback function that updates the end date of the picker, then adjusts the locations of the current dot
     * @param event The datepicker event that fires the callback
     */
    public updateEndDate(event: MatDatepickerInputEvent<unknown>): void {
        this.sliderEnd = moment(event.value);

        this.end = this.sliderEnd.clone();

        const currentDotPosition = Number(this.currentDot.nativeElement.style.left.slice(0, -2));
        let currentDotDate = this.pxToMoment(currentDotPosition);

        const isCurrentAfterNow = currentDotDate.isAfter(ApplicationConfig.roundMinute(), 'minute');

        if (!this.allowFutureStep && isCurrentAfterNow) {
            currentDotDate = ApplicationConfig.roundMinute();
            this.moveToPixel(this.momentToPx(currentDotDate));
        }

        this.current = currentDotDate.clone();

        this.checkLiveDataStatus();
        this.emitDates();
    }

    /**
     *  Moves the slider backwards one step
     */
    public stepBackwards(): void {
        const tmpMoment = this.current.clone();

        tmpMoment.subtract(this.stepSize, 'minutes');

        if (tmpMoment.isBefore(this.sliderStart)) {
            return;
        }

        this.current = tmpMoment;
        this.moveToPixel(this.momentToPx(this.current));
        this.emitDates();
    }

    /**
     * Moves the slider forward one step
     */
    public stepForward(): void {
        const tmpMoment = this.current.clone();

        tmpMoment.add(this.stepSize, 'minutes');

        // This checks if the slider allows future dates, if it does not, the updated step is then check against the current time
        const currentTimeCheck = !this.allowFutureStep && tmpMoment.isAfter(ApplicationConfig.roundMinute(), 'minute');
        if (currentTimeCheck || tmpMoment.isAfter(this.sliderEnd)) {
            this.pause();
            return;
        }

        this.current = tmpMoment;
        this.moveToPixel(this.momentToPx(this.current));
        this.emitDates();
    }

    /**
     * Sets the timeslider to update every 5 seconds to the next step value for the slider.
     */
    public play(): void {
        this.isPlaying = true;
        this.animationIntervalHandle = setInterval(() => {
            this.stepForward();
        }, 5_000);
        this.stepForward();
    }

    /**
     * Pause the stepper animation.
     */
    public pause(): void {
        clearInterval(this.animationIntervalHandle);
        this.animationIntervalHandle = undefined;
        this.isPlaying = false;
    }

    /**
     * Checks to see if the current slider value is the same as the current application time.
     * @private
     */
    private checkLiveDataStatus(): void {
        this.isLive = this.current.isSame(ApplicationConfig.roundMinute(), 'minute');
    }

    /**
     * Moves the current time dot to the most recent time step
     */
    public goToCurrentDate(): void {
        this.current = ApplicationConfig.roundMinute();
        this.moveToPixel(this.momentToPx(this.current));
        this.checkLiveDataStatus();
        this.emitDates();
    }

    /**
     * Draws the sections on the slider.
     * @param clearCurrentSections Defaults to true: if true, the current sections will be removed. if false the current
     * sections will remain on the slider.
     * @private
     */
    private drawSectionsOnSlider(clearCurrentSections = true): void {
        const rect = (this.slideLine.nativeElement as HTMLElement).getBoundingClientRect();
        if (clearCurrentSections) {
            while (this.sectionArea.nativeElement.firstChild) {
                this.sectionArea.nativeElement.removeChild(this.sectionArea.nativeElement.firstChild);
            }
        }

        this.sections
            .sort((a, b) => {
                if (a.startTime.isBefore(b.startTime)) return -1;
                else if (a.startTime.isAfter(b.startTime)) return 1;
                return 0;
            })
            .forEach((section, index) => {
                const div = document.createElement('div');
                const startPx = this.momentToPx(section.startTime);
                const endPx = this.momentToPx(section.endTime);

                // Setting the style here because classes do not apply when set in the component because of view
                // encapsulation
                div.style.backgroundColor = section.color;
                div.style.left = `${startPx}px`;
                div.style.right = `${rect.width - endPx}px`;
                div.style.position = 'absolute';
                div.style.height = '100%';
                div.style.pointerEvents = 'none';

                if (index === 0) {
                    div.style.borderBottomLeftRadius = '8px';
                    div.style.borderTopLeftRadius = '8px';
                }

                if (index === this.sections.length - 1) {
                    div.style.borderBottomRightRadius = '8px';
                    div.style.borderTopRightRadius = '8px';
                }

                this.sectionArea.nativeElement.appendChild(div);
            });
    }
}
