<div #content class="widget-content">
    <div>
        <div class="orders">
            <p>{{ orders }}</p>
        </div>

        <span
            >Operational <br />
            Flow Orders</span
        >
    </div>
    <div>
        <p>{{ majeure }}</p>
        <span>Force <br />Majeure</span>
    </div>
    <div>
        <p>{{ constraints }}</p>
        <span>Capacity <br />Constraints</span>
    </div>
    <eaglei-info-icon [attributionModalText]="attributionModalText" [widgetName]="widgetName" class="icon"></eaglei-info-icon>
</div>
