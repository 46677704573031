import {Pipe, PipeTransform} from '@angular/core';

/**
 * Determines whether a location value is a Fema region
 */
@Pipe({
    name: 'isFemaRegion',
})
export class IsFemaRegionPipe implements PipeTransform {
    transform(value: unknown): boolean {
        return typeof value === 'number';
    }
}
