<eaglei-leaflet-layer-header [layer]="this" (activeToggled)="toggleLayer($event)" [hideLegend]="true"></eaglei-leaflet-layer-header>

<ng-container *ngIf="isActive">
    <div #legendElement>
        <div class="vertical-legend">
            <div>
                <span class="header">Oil Refinery Status</span>
                <p class="sub-header">All Units are online</p>
                <div class="circle-container">
                    <ng-container [ngTemplateOutlet]="greenCircle"></ng-container>
                    <span class="label"> Online</span>
                </div>
                <p class="sub-header">Some Units are online, Some Units are offline</p>
                <div class="circle-container">
                    <ng-container [ngTemplateOutlet]="yellowCircle"></ng-container>
                    <span class="label"> Reduced Capacity</span>
                </div>
                <p class="sub-header">All Units are offline</p>
                <div class="circle-container">
                    <ng-container [ngTemplateOutlet]="redCircle"></ng-container>
                    <span class="label"> Offline</span>
                </div>
            </div>

            <div>
                <span class="header">Point Size Based On Available Capacity</span>
                <p class="sub-header">Smaller Point = Smaller Capacity</p>
                <div class="circle-container">
                    <ng-container [ngTemplateOutlet]="greenCircle" [ngTemplateOutletContext]="{size: 'small'}"></ng-container>
                    <div class="h-divider"></div>
                    <ng-container [ngTemplateOutlet]="yellowCircle" [ngTemplateOutletContext]="{size: 'small'}"></ng-container>
                    <div class="h-divider"></div>
                    <ng-container [ngTemplateOutlet]="redCircle" [ngTemplateOutletContext]="{size: 'small'}"></ng-container>
                </div>

                <p class="sub-header">Larger Point = Larger Capacity</p>
                <div class="circle-container">
                    <ng-container [ngTemplateOutlet]="greenCircle"></ng-container>
                    <div class="h-divider"></div>
                    <ng-container [ngTemplateOutlet]="yellowCircle"></ng-container>
                    <div class="h-divider"></div>
                    <ng-container [ngTemplateOutlet]="redCircle"></ng-container>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #greenCircle let-size="size">
    <div class="status online" [ngClass]="{'small-circle': size === 'small'}">
        <mat-icon fontSet="fas" fontIcon="fa-check"></mat-icon>
    </div>
</ng-template>

<ng-template #redCircle let-size="size">
    <div class="status offline" [ngClass]="{'small-circle': size === 'small'}">
        <mat-icon fontSet="fas" fontIcon="fa-ban"></mat-icon>
    </div>
</ng-template>

<ng-template #yellowCircle let-size="size">
    <div class="status impacted" [ngClass]="{'small-circle': size === 'small'}">
        <mat-icon fontSet="fas" fontIcon="fa-exclamation-triangle"></mat-icon>
    </div>
</ng-template>
