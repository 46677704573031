<mat-card>
    <mat-card-title>
        <span>Port Status</span>
        <button mat-icon-button (click)="close()">
            <mat-icon fontSet="fas" fontIcon="fa-times"></mat-icon>
        </button>
    </mat-card-title>
    <mat-card-content #content class="column-layout">
        <span>
            {{ data.name | titlecase }}
            <a [href]="data.url" target="_blank">
                <mat-icon inline class="externalLink" fontSet="fas" fontIcon="fa-external-link-alt"></mat-icon>
            </a>
        </span>

        <div class="sub-port-status">
            <table>
                <tr>
                    <th>Port</th>
                    <th>Status</th>
                    <th>Last Updated</th>
                    <th>Comments</th>
                </tr>
                <tr *ngFor="let item of data.ports">
                    <td class="ellipsis" [title]="item.name | titlecase">{{ item.name | titlecase }}</td>

                    <td class="status-cell">
                        <div>
                            <ng-container [ngSwitch]="item.status">
                                <ng-container *ngSwitchCase="1">
                                    <mat-icon inline class="open" fontSet="fas" fontIcon="fa-circle"></mat-icon>
                                    <span>Open</span>
                                </ng-container>
                                <ng-container *ngSwitchCase="2">
                                    <mat-icon inline class="restricted" fontSet="fas" fontIcon="fa-circle"></mat-icon>
                                    <span>Restrictions</span>
                                </ng-container>
                                <ng-container *ngSwitchCase="3">
                                    <mat-icon inline class="closed" fontSet="fas" fontIcon="fa-circle"></mat-icon>
                                    <span>Closed</span>
                                </ng-container>
                            </ng-container>
                        </div>
                    </td>

                    <td>{{ item.lastModDate | momentDate : 'MM/DD/YYYY' : false : true }}</td>

                    <td
                        #statusComment
                        class="ellipsis"
                        [title]="item.comments"
                        [matTooltip]="item.comments"
                        matTooltipShowDelay="250"
                        matTooltipPosition="above"
                        [matTooltipDisabled]="!hasEllipsis(statusComment)">
                        {{ item.comments == 'null' ? ' ' : item.comments }}
                    </td>
                </tr>
            </table>
        </div>
    </mat-card-content>
</mat-card>
