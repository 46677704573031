<div class="report-widget">
    <div #header class="widget-header">
        <p class="title">My Reports</p>
    </div>

    <div #content class="widget-content">
        <ng-container *ngIf="reports.length !== 0; else noReports">
            <mat-card *ngFor="let report of reports" class="clickable report" (click)="gotoReport(report)">
                <p class="ellipsis">{{ report.displayName }}</p>
            </mat-card>
        </ng-container>

        <ng-template #noReports>
            <div class="no-report">
                <p>
                    You do not have any pinned reports.
                    <br />
                    <br />
                    to pin a report, go to the <a routerLink="/app/reports">reports page</a>.
                </p>
            </div>
        </ng-template>
    </div>

    <div #footer class="widget-footer">
        <div class="widget-action" *ngIf="reports.length !== 0">
            <button mat-stroked-button (click)="gotoReport()">All Reports</button>
        </div>
    </div>
</div>
