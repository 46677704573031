
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!


export class GenPointOfContact {

    id: number;
    address1: string;
    address2: string;
    city: string;
    department: string;
    email: string;
    name: string;
    organization: string;
    postalCode: string;
    state: string;
    telephone: string;
    title: string;
    userId: number;

    constructor();
    constructor( jsonObj: any );
    constructor( genPointOfContact: GenPointOfContact);
    constructor( id: number );

    constructor( input?: GenPointOfContact|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            this.address1 = input.address1; // string [string]
            this.address2 = input.address2; // string [string]
            this.city = input.city; // string [string]
            this.department = input.department; // string [string]
            this.email = input.email; // string [string]
            this.name = input.name; // string [string]
            this.organization = input.organization; // string [string]
            this.postalCode = input.postalCode; // string [string]
            this.state = input.state; // string [string]
            this.telephone = input.telephone; // string [string]
            this.title = input.title; // string [string]
            this.userId = input.userId; // int [number]
       }
    }

    static jsonResponseToList(arr: any[]): GenPointOfContact[] {
        if (arr && arr.map) {
          return arr.map(a => new GenPointOfContact(a));
        } else {
          return [];
        }
    }
}

