import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {GenSystemEvent} from 'frontend/generated/serverModels/GenSystemEvent';
import {GenOutageSummaryEvent} from 'frontend/generated/serverModels/GenOutageSummaryEvent';
import * as moment from 'moment';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ResponseWrapper} from '../../../classes/response-wrapper';
import {RecentEvent} from '../classes/recent-event';
import {RecentEventSource} from '../classes/recent-event-source';

@Injectable({
    providedIn: 'root',
})
export class EventService {
    private readonly eventSourceUrl = '/api/recentevent/sources';
    private readonly recentEventUrl = '/api/recentevent';
    // private readonly systemEventUrl = '/api/event';

    constructor(private http: HttpClient) {}

    public getNewsfeedSourcesData(): Observable<RecentEventSource[]> {
        return this.http.get<RecentEventSource[]>(`${this.recentEventUrl}/datasource`);
    }

    public getNewsfeedSources(): Observable<RecentEventSource[]> {
        return this.http
            .get<RecentEventSource[]>(this.eventSourceUrl)
            .pipe(map((sources: RecentEventSource[]) => sources.map((source) => new RecentEventSource(source))));
    }

    public getEvents(start: moment.Moment, end: moment.Moment, limit?: number): Observable<RecentEvent[]> {
        let url = `${this.recentEventUrl}/events?start=${start.format()}&end=${end.format()}`;
        if (limit !== undefined) {
            url += `&limit=${limit}`;
        }
        return this.http.get<ResponseWrapper<RecentEvent>>(url).pipe(map((res) => res.data.map((event) => new RecentEvent(event))));
    }

    public getEvent(id: number): Observable<RecentEvent> {
        const url = `${this.recentEventUrl}/event?eventId=${id}`;
        return this.http.get<any>(url).pipe(map((res) => new RecentEvent(res)));
    }
}
