import {Component} from '@angular/core';
import {BaseModal} from '../../../../classes/base-modal';
import {MatDialogRef} from '@angular/material/dialog';
import * as moment from 'moment';
import {TimeStep} from '../../../../../shared/classes/time-step';
import {ApplicationConfig} from '../../../../classes/application-config';
import {MapService} from '../../services/map.service';
import {AutoUpdate} from '../../../../../shared/classes/auto-update';
import {IDateRange} from '../../../../interfaces/date-range.interface';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';

@Component({
    selector: 'eaglei-date-range-modal',
    templateUrl: './date-range-modal.component.html',
    styleUrls: ['./date-range-modal.component.scss'],
})
export class DateRangeModalComponent extends BaseModal {
    private currentDate = ApplicationConfig.roundMinute();
    private autoRefresh = new AutoUpdate(() => (this.currentDate = ApplicationConfig.roundMinute())).setAutoUpdate(true);

    public startDate: Date;
    public endDate: Date;
    public startTime: TimeStep;
    public endTime: TimeStep;

    public minDate: Date = moment().subtract(7, 'day').toDate();
    public maxDate: Date = this.currentDate.toDate();

    public timeSteps: TimeStep[] = TimeStep.createTimeSteps();

    constructor(private dialogRef: MatDialogRef<DateRangeModalComponent>, private mapService: MapService) {
        super();

        const initialDates = mapService.mapDateRange.getValue();
        this.startDate = initialDates.startDate.local().toDate();
        this.endDate = initialDates.endDate.local().toDate();

        this.timeSteps.forEach((ts) => {
            if (ts.matchesTime(initialDates.startDate)) {
                this.startTime = ts;
            }

            if (ts.matchesTime(initialDates.endDate)) {
                this.endTime = ts;
            }
        });
    }

    afterInit() {}

    public updateFilters(): void {
        const newDates: IDateRange = {
            startDate: this.convertToMoment(this.startDate, this.startTime),
            endDate: this.convertToMoment(this.endDate, this.endTime),
        };

        this.mapService.mapDateRange.next(newDates);
        this.close();
    }

    /**
     * Closed the modal with no changes.
     */
    public close(): void {
        this.autoRefresh.clear();
        this.dialogRef.close();
    }

    public changeStartTime(value: TimeStep) {
        this.startTime = value;
    }

    public changeEndTime(value: TimeStep) {
        this.endTime = value;
    }

    public validStartTime(step: TimeStep): boolean {
        if (this.sameDate(this.startDate, this.endDate)) {
            return step.id <= this.endTime.id;
        } else if (this.startDate.getTime() < this.endDate.getTime()) {
            return true;
        }
        return false;
    }

    public validEndTime(step: TimeStep): boolean {
        // less than now
        let lessThanNow = false;
        if (this.sameDate(this.endDate, this.currentDate.toDate())) {
            lessThanNow =
                step.hour < this.currentDate.hour() || (step.hour === this.currentDate.hour() && step.minute <= this.currentDate.minute());
        } else if (this.endDate < this.currentDate.toDate()) {
            lessThanNow = true;
        }

        // greater than start
        let afterStart = false;
        if (this.sameDate(this.startDate, this.endDate)) {
            afterStart = step.id > this.startTime.id;
        } else if (this.endDate > this.startDate) {
            afterStart = true;
        }

        return lessThanNow && afterStart;
    }

    // noinspection JSMethodCanBeStatic
    private sameDate(a: Date, b: Date): boolean {
        return a.getFullYear() === b.getFullYear() && a.getMonth() === b.getMonth() && a.getDate() === b.getDate();
    }

    setStartDate(event: MatDatepickerInputEvent<Date>) {
        this.startDate = event.value;
        if (this.startTime.id > this.endTime.id) {
            this.startTime = this.endTime;
        }
    }

    setEndDate(event: MatDatepickerInputEvent<Date>) {
        this.endDate = event.value;

        if (this.startTime.id > this.endTime.id) {
            this.endTime = this.startTime;
        }

        if (this.convertToMoment(this.endDate, this.endTime).isAfter(moment())) {
            const now = ApplicationConfig.roundMinute();
            this.endTime = this.timeSteps.find((ts) => ts.matchesTime(now));
        }
    }

    // noinspection JSMethodCanBeStatic
    private convertToMoment(date: Date, time: TimeStep): moment.Moment {
        return moment(date).hour(time.hour).minute(time.minute).second(0);
    }
}
