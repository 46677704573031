import {GenOutageDataCounty} from '../../../../../generated/serverModels/GenOutageDataCounty';
import {HistoricalOutageData} from '../../layer/classes/historical-outage-data';

export class CountyOutage extends GenOutageDataCounty {
    constructor(outage?: CountyOutage) {
        super(outage);
    }

    public toHistoricalOutage(): HistoricalOutageData {
        const historical = new HistoricalOutageData();
        historical.countyName = this.countyName;
        historical.currentOutage = this.customersOut;
        historical.currentOutageHasOverrideData = this.hasOverrideData;
        historical.currentOutageRunStartTime = this.timeStamp;
        historical.countyFIPSCode = this.fipsCode;
        historical.stateName = this.stateAbbreviation;
        historical.coveredCustomers = this.coveredCustomers;
        historical.actualCustomers = this.actualCustomers;
        historical.collectedCount = this.collectedCount;
        historical.modelCount = this.modelCount;
        return historical.updateName();
    }
}
