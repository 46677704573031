<div class="container">
    <div>
        <!--Auto complete-->
        <div class="header">
            <header>Add User(s)</header>
            <form [formGroup]="formGroup">
                <mat-form-field appearance="outline" class="wide">
                    <input
                        type="text"
                        matInput
                        placeholder="Search User Name or Role"
                        [matAutocomplete]="autoGroup"
                        formControlName="autocomplete" />
                    <mat-autocomplete #autoGroup="matAutocomplete">
                        <mat-optgroup *ngFor="let opt of opts | async" [label]="opt.name">
                            <mat-option *ngFor="let val of opt.values" [value]="val" (click)="addToNewUserList(val)">
                                {{ autocompleteDisplay(val) }}
                            </mat-option>
                        </mat-optgroup>
                    </mat-autocomplete>
                </mat-form-field>
            </form>
        </div>

        <!--Added By Username-->
        <ng-container *ngIf="addedByUsername.length != 0">
            <div class="added-group">
                <div class="new-user-header">
                    <mat-icon
                        fontSet="fas"
                        [fontIcon]="byUserExpanded ? 'fa-chevron-up' : 'fa-chevron-down'"
                        class="clickable"
                        (click)="byUserExpanded = !byUserExpanded"></mat-icon>
                    <span>Added by Username</span>
                    <mat-chip (click)="removeAddedByUsername()">Clear All</mat-chip>
                </div>

                <div class="user-list" [class.show]="byUserExpanded">
                    <div *ngFor="let user of addedByUsername">
                        <mat-chip
                            class="added-user"
                            [ngClass]="{selected: !isCurrentlyPending(user), 'pending-user': isCurrentlyPending(user)}">
                            <div class="user-info">
                                <span>{{ user.firstName }} {{ user.lastName }} ({{ user.email }}) </span>
                                <mat-icon
                                    fontSet="fas"
                                    fontIcon="fa-times-circle"
                                    class="clickable"
                                    inline
                                    (click)="removeUserFromList(user, addedByUsername)"></mat-icon>
                            </div>
                        </mat-chip>
                        <span [class.invisible]="!isCurrentlyPending(user)" class="pending-help-text"> *Pending Community Access </span>
                    </div>
                </div>
            </div>
        </ng-container>

        <!--Added By role-->
        <ng-container *ngIf="addedByRole.length != 0">
            <div class="added-group">
                <div class="new-user-header">
                    <mat-icon
                        fontSet="fas"
                        [fontIcon]="byRoleExpanded ? 'fa-chevron-up' : 'fa-chevron-down'"
                        class="clickable"
                        inline
                        (click)="byRoleExpanded = !byRoleExpanded"></mat-icon>
                    <span>Added by Role</span>
                    <mat-chip (click)="removeAddedByByRole()">Clear All</mat-chip>
                </div>

                <div class="role-group user-list" [class.show]="byRoleExpanded">
                    <div *ngFor="let roleGroup of addedByRole">
                        <ng-container *ngIf="roleGroup.users.length != 0">
                            <div class="new-user-header sub-header">
                                <mat-icon
                                    fontSet="fas"
                                    [fontIcon]="roleGroup.expanded ? 'fa-chevron-up' : 'fa-chevron-down'"
                                    class="clickable"
                                    (click)="roleGroup.expanded = !roleGroup.expanded"></mat-icon>
                                <span>{{ roleGroup.name }}</span>
                                <mat-chip class="clickable" (click)="removeRoleGroup(roleGroup)">Clear All</mat-chip>
                            </div>

                            <div class="user-list sub-list" [class.show]="roleGroup.expanded">
                                <div *ngFor="let user of getVisibleUsers(roleGroup.users, roleGroup.usersExpanded)">
                                    <mat-chip
                                        class="added-user"
                                        [ngClass]="{selected: !isCurrentlyPending(user), 'pending-user': isCurrentlyPending(user)}">
                                        <div class="user-info">
                                            <span>{{ user.firstName }} {{ user.lastName }} ({{ user.email }}) </span>
                                            <mat-icon
                                                fontSet="fas"
                                                fontIcon="fa-times-circle"
                                                class="clickable"
                                                inline
                                                (click)="removeUserFromList(user, roleGroup.users)"></mat-icon>
                                        </div>
                                    </mat-chip>
                                    <span [class.invisible]="!isCurrentlyPending(user)" class="pending-help-text"
                                        >*Pending Community Access</span
                                    >
                                </div>

                                <mat-chip
                                    class="selected clickable"
                                    *ngIf="!roleGroup.usersExpanded && roleGroup.users.length > numUsersVisible"
                                    (click)="roleGroup.usersExpanded = true">
                                    + {{ roleGroup.users.length - numUsersVisible }}
                                </mat-chip>

                                <mat-chip *ngIf="roleGroup.usersExpanded" class="clickable" (click)="roleGroup.usersExpanded = false">
                                    Show Less
                                </mat-chip>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>

    <div class="actions">
        <button mat-stroked-button (click)="addedUsers.emit()">Cancel</button>
        <button mat-flat-button color="primary" (click)="addUsers()">Add Users</button>
    </div>
</div>
