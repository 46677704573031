import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {MapRoutingModule} from './map-routing.module';
import {MapLayoutComponent} from './components/map-layout/map-layout.component';
import {SharedModule} from '../../../shared/shared.module';
import {CoordinateDataModalComponent} from './modals/coordinate-data-modal/coordinate-data-modal.component';
import {MapService} from './services/map.service';
import {LayerModule} from '../layer/layer.module';
import {MapChartsComponent} from './components/map-charts/map-charts.component';
import {MapperChartComponent} from './components/mapper-chart/mapper-chart.component';
import {PopoverDataModalComponent} from './modals/popover-data-modal/popover-data-modal.component';
import {CoveredUtilityModalComponent} from './modals/covered-utility-modal/covered-utility-modal.component';
import {EilDownloadModalComponent} from './modals/eil-download-modal/eil-download-modal.component';
import {EilMetadataModalComponent} from './modals/eil-metadata-modal/eil-metadata-modal.component';
import {LeafletMapComponent} from './components/leaflet-map/leaflet-map.component';
import {LeafletSidebarComponent} from './components/leaflet-sidebar/leaflet-sidebar.component';
import {DateRangeModalComponent} from './components/date-range-modal/date-range-modal.component';
import {UtilityLookupModalComponent} from './modals/utility-lookup-modal/utility-lookup-modal.component';
import {NomChartComponent} from '../../../shared/components/nom-chart/nom-chart.component';

@NgModule({
    declarations: [
        MapLayoutComponent,
        CoordinateDataModalComponent,
        MapChartsComponent,
        MapperChartComponent,
        PopoverDataModalComponent,
        CoveredUtilityModalComponent,
        EilDownloadModalComponent,
        EilMetadataModalComponent,
        LeafletMapComponent,
        LeafletSidebarComponent,
        DateRangeModalComponent,
        UtilityLookupModalComponent,
    ],
    imports: [CommonModule, MapRoutingModule, SharedModule, LayerModule, NomChartComponent],
    providers: [MapService],
    exports: [LeafletMapComponent, LeafletSidebarComponent],
})
export class MapModule {}
