import {Component, OnDestroy} from '@angular/core';
import {GenLegendType} from 'frontend/generated/serverModels/GenLegendType';
import {HttpInterceptorService} from 'frontend/src/app/services/http-interceptor.service';
import {tap} from 'rxjs/operators';
import {EagleILayer} from '../../classes/eagle-i-layer';
import {CustomPopover} from '../../enum/custom-popover';
import {LayerService} from '../../services/layer.service';
import {OngNominationsSource} from '../../sources/ong-nominations-source';
import {OngService} from '../../../../services/ong.service';

@Component({
    selector: 'eaglei-ong-nominations',
    templateUrl: './ong-nominations.component.html',
    styleUrls: ['../layers.scss', './ong-nominations.component.scss'],
})
export class OngNominationsComponent extends EagleILayer<OngNominationsSource> implements OnDestroy {
    constructor(private layerService: LayerService, private ongService: OngService) {
        super();
    }

    public afterViewInit(): void {
        this.mapLayer.customPopoverId = CustomPopover.ONG_NOMINATIONS;
        this.mapLayer.legend.type = GenLegendType.HARD_CODED;
    }

    ngOnDestroy() {
        this.layerSource?.layerInfo.endLoading();
    }

    public toggleLayer(activeState: boolean): void {
        if (activeState) {
            if (!this.layerSource) {
                this.layerSource = new OngNominationsSource(this.mapLayer);
            }

            this.getNominations();
        } else {
            HttpInterceptorService.clearInterceptor(this.mapLayer.uiHandle);
            this.layerSource.removeFromMap();
        }
    }

    private getNominations(): void {
        this.mapLayer.startLoading();
        HttpInterceptorService.clearInterceptor(this.mapLayer.uiHandle);
        HttpInterceptorService.pendingRequests[this.mapLayer.uiHandle] = this.ongService
            .getNominations()
            .pipe(
                tap(() => {
                    HttpInterceptorService.deleteFromInterceptor(this.mapLayer.uiHandle);
                    this.mapLayer.endLoading();
                })
            )
            .subscribe((res) => {
                this.layerSource.processFeatures(res);
                this.layerSource.addToMap();
            });
    }
}
