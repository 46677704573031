
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import { GenEventResult } from './GenEventResult';

export class GenEventDetail {

    id: number;
    boundary: string;
    description: string;
    eventResults: GenEventResult[];
    eventTypeId: number;
    headoutEventId: number;
    name: string;

    constructor();
    constructor( jsonObj: any );
    constructor( genEventDetail: GenEventDetail);
    constructor( id: number );

    constructor( input?: GenEventDetail|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            this.boundary = input.boundary; // string [string]
            this.description = input.description; // string [string]
            if ( input.eventResults && input.eventResults.map ) { // list [GenEventResult]
                this.eventResults = input.eventResults.map( ( x: GenEventResult ) => {
                    return new GenEventResult( x );
                } );
            } else {
                this.eventResults = input.eventResults;
            }
            this.eventTypeId = input.eventTypeId; // int [number]
            this.headoutEventId = input.headoutEventId; // int [number]
            this.name = input.name; // string [string]
       }
    }

    static jsonResponseToList(arr: any[]): GenEventDetail[] {
        if (arr && arr.map) {
          return arr.map(a => new GenEventDetail(a));
        } else {
          return [];
        }
    }
}

