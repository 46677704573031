
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import { GenRoleDefinition } from './GenRoleDefinition';

export class GenDataProvider {

    id: number;
    active: boolean;
    attributionTitle: string;
    attributionUrl: string;
    dataProviderGroupId: number;
    description: string;
    displayName: string;
    dynamic: boolean;
    externalAttribution: boolean;
    iconUrl: string;
    ordering: number;
    params: string;
    pinned: boolean;
    roles: GenRoleDefinition[];
    serviceurl: string;
    style: string;
    uiHandle: string;
    visible: boolean;

    constructor();
    constructor( jsonObj: any );
    constructor( genDataProvider: GenDataProvider);
    constructor( id: number );

    constructor( input?: GenDataProvider|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            this.active = input.active; // boolean [boolean]
            this.attributionTitle = input.attributionTitle; // string [string]
            this.attributionUrl = input.attributionUrl; // string [string]
            this.dataProviderGroupId = input.dataProviderGroupId; // int [number]
            this.description = input.description; // string [string]
            this.displayName = input.displayName; // string [string]
            this.dynamic = input.dynamic; // boolean [boolean]
            this.externalAttribution = input.externalAttribution; // boolean [boolean]
            this.iconUrl = input.iconUrl; // string [string]
            this.ordering = input.ordering; // int [number]
            this.params = input.params; // string [string]
            this.pinned = input.pinned; // boolean [boolean]
            if ( input.roles && input.roles.map ) {
                this.roles = input.roles.map( ( x: GenRoleDefinition ) => {
                    return (typeof x === 'string') ? GenRoleDefinition.forName(<string> x) : <any> x; // GenRoleDefinition
                } );
            } else {
                this.roles = input.roles; // GenRoleDefinition
            }
            this.serviceurl = input.serviceurl; // string [string]
            this.style = input.style; // string [string]
            this.uiHandle = input.uiHandle; // string [string]
            this.visible = input.visible; // boolean [boolean]
       }
    }

    static jsonResponseToList(arr: any[]): GenDataProvider[] {
        if (arr && arr.map) {
          return arr.map(a => new GenDataProvider(a));
        } else {
          return [];
        }
    }
}

