import {GenDataProvider} from '../../../../../generated/serverModels/GenDataProvider';
import {MomentDatePipe} from '../../../../shared/pipes/moment-date.pipe';
import {EagleINumberPipe} from '../../../../shared/pipes/eaglei-number.pipe';
import {PercentPipe} from '@angular/common';
import {ApplicationConfig} from '../../../classes/application-config';
import {MatTableDataSource} from '@angular/material/table';
import {BasePageComponent} from '../../../components/base-page/base-page.component';
import {LockTimeService} from '../../../services/lock-time.service';
import {skip} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {Directive, OnDestroy} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Directive()
export class Report<T = any> extends GenDataProvider implements OnDestroy {
    static readonly momentPipe = new MomentDatePipe();
    static readonly numberPipe = new EagleINumberPipe();
    static readonly percentPipe = new PercentPipe('en-US');
    protected destroy$ = new Subject();
    public readonly resetScrollClass: string;

    public dataSource: MatTableDataSource<T>;

    protected readonly interceptorKey: string;

    constructor(report?: Report, updateOnSystemTime?: boolean) {
        super(report);

        this.resetScrollClass = ApplicationConfig.resetScrollClass;

        this.attributionTitle = this.attributionTitle === undefined ? 'N/A' : this.attributionTitle;
        this.attributionUrl = this.attributionUrl === undefined ? 'N/A' : this.attributionUrl;
        this.interceptorKey = `${this.uiHandle}_report`;

        if (updateOnSystemTime) {
            LockTimeService.currentApplicationTime
                .pipe(
                    skip(1), // Skipping the initialization of the Behaviour subject
                    takeUntilDestroyed()
                )
                .subscribe(() => this.onApplicationTimeChange());
        }
    }

    ngOnDestroy() {
        this.destroy$.complete();
        this.destroy$.unsubscribe();
    }

    protected initializeReportInfo(report: Report): void {
        this.displayName = report.displayName;
        this.attributionUrl = report.attributionUrl;
        this.attributionTitle = report.attributionTitle;

        // Doing a timeout to have it wait until after the router event is fired.
        setTimeout(() => {
            BasePageComponent.updatePageTitle.next(this.displayName);
        }, 0);
    }

    public onMobile(): boolean {
        return ApplicationConfig.useMobileLayout();
    }

    /**
     * @Deprecated IE is no longer supported
     */
    public onIE(): boolean {
        return ApplicationConfig.onIE();
    }

    public hasData(): boolean {
        return this.dataSource && this.dataSource.filteredData.length !== 0;
    }

    /**
     * This method should be overwritten in any component that will try to use it. It will only be fired from the lock time update
     */
    onApplicationTimeChange(): void {
        alert(`Override 'onApplicationTimeChange'`);
    }
}
