import {Component} from '@angular/core';
import {EagleILayer} from '../../classes/eagle-i-layer';
import {NgPipelineNoticeSource} from '../../sources/ng-pipeline-notice-source';
import {map, tap} from 'rxjs/operators';
import {LayerService} from '../../services/layer.service';
import {CustomPopover} from '../../enum/custom-popover';
import {OngPipeline} from '../../classes/ong-pipeline';
import {IFilterChange} from '../../interfaces/filter-change.interface';
import {ISelectOption} from '../../interfaces/select-option.interface';
import {HttpInterceptorService} from '../../../../services/http-interceptor.service';
import {GenLegendType} from '../../../../../../generated/serverModels/GenLegendType';
import {OngService} from '../../../../services/ong.service';
import {NaturalGasPipelineNoticeSeverity} from '../../../../types/ong.types';

@Component({
    selector: 'eaglei-ng-pipeline-notice-layer',
    templateUrl: './ng-pipeline-notice-layer.component.html',
    styleUrls: ['./ng-pipeline-notice-layer.component.scss'],
})
export class NgPipelineNoticeLayerComponent extends EagleILayer<NgPipelineNoticeSource> {
    private types: NaturalGasPipelineNoticeSeverity[];

    constructor(private layerService: LayerService, private ongService: OngService) {
        super();
    }

    afterViewInit(): void {
        this.mapLayer.customPopoverId = CustomPopover.ONG_CRITICAL_NOTICES;
        this.mapLayer.legend.type = GenLegendType.HARD_CODED;
    }

    toggleLayer(activeState: boolean, zoomToFeatures?: boolean | undefined): void {
        if (activeState) {
            if (!this.layerSource) {
                this.layerSource = new NgPipelineNoticeSource(this.mapLayer);
                this.types = this.mapLayer.filters
                    .find((f) => f.filterProperty === 'priority')
                    .getParams()
                    .map((p) => p.value);
            }

            this.getNotices();
        } else {
            HttpInterceptorService.clearInterceptor(this.mapLayer.uiHandle);
            this.layerSource.clearPoints();
            this.layerSource.removeFromMap();
        }
    }

    private getNotices(): void {
        this.mapLayer.startLoading();
        HttpInterceptorService.clearInterceptor(this.mapLayer.uiHandle);
        HttpInterceptorService.pendingRequests[this.mapLayer.uiHandle] = this.ongService
            .getNgNotices(this.types, false, true)
            .pipe(
                tap(() => {
                    HttpInterceptorService.deleteFromInterceptor(this.mapLayer.uiHandle);
                    this.mapLayer.endLoading();
                }),
                map((collection: any) => {
                    collection.features.forEach((feature) => {
                        feature.properties = new OngPipeline(feature.properties);
                    });
                    return collection;
                })
            )
            .subscribe((res) => {
                const filtered = res.features.filter((feature) => !!feature.geometry);

                this.layerSource.processFeatures(filtered);
                this.layerSource.addToMap();
            });
    }

    public updateFilterQuery(event: IFilterChange) {
        switch (event.filter.filterProperty) {
            case 'priority':
                if ((event.value as ISelectOption[]).length !== 0) {
                    this.types = (event.value as ISelectOption[]).map((v) => v.value as NaturalGasPipelineNoticeSeverity);
                } else {
                    this.types = ['none'];
                }

                this.getNotices();
                break;
            case 'name':
                this.layerSource.nameFilter = event.value as string;
                this.layerSource.addToMap();
                break;
        }
    }
}
