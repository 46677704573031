
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import * as moment from 'moment';

export class GenOutageError {

    id: number;
    cacheError: number;
    connectionError: number;
    countyName: string;
    customersOut: number;
    databaseError: number;
    developerError: number;
    duplicateCount: number;
    duplicateEnd: moment.Moment;
    duplicateStart: moment.Moment;
    eagleiId: number;
    errorText: string;
    errorTypeId: number;
    locationError: number;
    locationId: string;
    notificationSent: boolean;
    outageCountTotal: number;
    parentErrorType: string;
    parserError: number;
    resolution: string;
    scraperError: number;
    specificErrorType: string;
    state: string;
    subUtilityError: number;
    subUtilityId: number;
    timeoutError: number;
    timestamp: moment.Moment;
    totalCustomers: number;
    totalError: number;
    unknownError: number;
    utilityName: string;
    utilityNumber: number;
    utilityStatus: string;

    constructor();
    constructor( jsonObj: any );
    constructor( genOutageError: GenOutageError);
    constructor( id: number );

    constructor( input?: GenOutageError|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            this.cacheError = input.cacheError; // int [number]
            this.connectionError = input.connectionError; // int [number]
            this.countyName = input.countyName; // string [string]
            this.customersOut = input.customersOut; // int [number]
            this.databaseError = input.databaseError; // int [number]
            this.developerError = input.developerError; // int [number]
            this.duplicateCount = input.duplicateCount; // int [number]
            if ( input.duplicateEnd && typeof input.duplicateEnd === 'string' ) {
                this.duplicateEnd = moment(input.duplicateEnd).utc();
            } else {
                this.duplicateEnd = input.duplicateEnd;
            }
            if ( input.duplicateStart && typeof input.duplicateStart === 'string' ) {
                this.duplicateStart = moment(input.duplicateStart).utc();
            } else {
                this.duplicateStart = input.duplicateStart;
            }
            this.eagleiId = input.eagleiId; // int [number]
            this.errorText = input.errorText; // string [string]
            this.errorTypeId = input.errorTypeId; // int [number]
            this.locationError = input.locationError; // int [number]
            this.locationId = input.locationId; // string [string]
            this.notificationSent = input.notificationSent; // boolean [boolean]
            this.outageCountTotal = input.outageCountTotal; // int [number]
            this.parentErrorType = input.parentErrorType; // string [string]
            this.parserError = input.parserError; // int [number]
            this.resolution = input.resolution; // string [string]
            this.scraperError = input.scraperError; // int [number]
            this.specificErrorType = input.specificErrorType; // string [string]
            this.state = input.state; // string [string]
            this.subUtilityError = input.subUtilityError; // int [number]
            this.subUtilityId = input.subUtilityId; // int [number]
            this.timeoutError = input.timeoutError; // int [number]
            if ( input.timestamp && typeof input.timestamp === 'string' ) {
                this.timestamp = moment(input.timestamp).utc();
            } else {
                this.timestamp = input.timestamp;
            }
            this.totalCustomers = input.totalCustomers; // int [number]
            this.totalError = input.totalError; // int [number]
            this.unknownError = input.unknownError; // int [number]
            this.utilityName = input.utilityName; // string [string]
            this.utilityNumber = input.utilityNumber; // int [number]
            this.utilityStatus = input.utilityStatus; // string [string]
       }
    }

    static jsonResponseToList(arr: any[]): GenOutageError[] {
        if (arr && arr.map) {
          return arr.map(a => new GenOutageError(a));
        } else {
          return [];
        }
    }
}

