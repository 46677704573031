
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import { GenRRCCDesk } from './GenRRCCDesk';
import { GenRRCCLocation } from './GenRRCCLocation';
import { GenRRCCPersonnel } from './GenRRCCPersonnel';
import * as moment from 'moment';

export class GenRRCCDeployment {

    id: number;
    desk: GenRRCCDesk;
    deskId: number;
    endTime: moment.Moment;
    location: GenRRCCLocation;
    locationId: number;
    personnel: GenRRCCPersonnel;
    personnelId: number;
    shiftId: number;
    startTime: moment.Moment;

    constructor();
    constructor( jsonObj: any );
    constructor( genRRCCDeployment: GenRRCCDeployment);
    constructor( id: number );

    constructor( input?: GenRRCCDeployment|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            if( input.desk ) {
              this.desk = new GenRRCCDesk( input.desk );
            }
            this.deskId = input.deskId; // int [number]
            if ( input.endTime && typeof input.endTime === 'string' ) {
                this.endTime = moment(input.endTime).utc();
            } else {
                this.endTime = input.endTime;
            }
            if( input.location ) {
              this.location = new GenRRCCLocation( input.location );
            }
            this.locationId = input.locationId; // int [number]
            if( input.personnel ) {
              this.personnel = new GenRRCCPersonnel( input.personnel );
            }
            this.personnelId = input.personnelId; // int [number]
            this.shiftId = input.shiftId; // int [number]
            if ( input.startTime && typeof input.startTime === 'string' ) {
                this.startTime = moment(input.startTime).utc();
            } else {
                this.startTime = input.startTime;
            }
       }
    }

    static jsonResponseToList(arr: any[]): GenRRCCDeployment[] {
        if (arr && arr.map) {
          return arr.map(a => new GenRRCCDeployment(a));
        } else {
          return [];
        }
    }
}

