import {Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {EagleILayer} from '../../classes/eagle-i-layer';
import {GenLayerFilterType} from '../../../../../../generated/serverModels/GenLayerFilterType';
import {combineLatest, Subject} from 'rxjs';
import {MapService} from '../../../map/services/map.service';
import {GenServiceType} from '../../../../../../generated/serverModels/GenServiceType';
import {IdentifyService} from '../../../map/services/identify.service';
import {LeafletLegendService} from '../../services/leaflet-legend.service';
import {debounceTime, filter, takeUntil} from 'rxjs/operators';
import {ApplicationConfig} from 'frontend/src/app/classes/application-config';

@Component({
    selector: 'eaglei-leaflet-layer-header',
    templateUrl: './leaflet-layer-header.component.html',
    styleUrls: ['./leaflet-layer-header.component.scss'],
})
export class LeafletLayerHeaderComponent implements OnInit, OnDestroy {
    public mapService: MapService = inject(MapService);
    private identifyService: IdentifyService = inject(IdentifyService);

    @Input() layer: EagleILayer;
    @Input() hideLegend: boolean;
    @Input() hideToggle: boolean = false;
    @Input() hideOpacityBar: boolean = false;

    @Output() activeToggled = new EventEmitter<boolean>();
    @Output() updateOpacity = new EventEmitter<number>();
    @Output() dateRangeUpdate = new EventEmitter<void>();

    public readonly filterTypes = GenLayerFilterType;
    public readonly serviceType = GenServiceType;
    public loadingState: any;

    public identifyActive: boolean;
    public readonly onLiteMap = ApplicationConfig.onLite();

    private destroy$ = new Subject();

    constructor() {}

    ngOnInit() {
        this.layer.mapLayer.loadingState.pipe(takeUntil(this.destroy$)).subscribe((state) => {
            this.loadingState = state;

            if (!state.loading) {
                this.layer.changeOpacity();
            }
        });

        this.layer.mapLayer.reset
            .pipe(
                filter(() => this.layer.isActive),
                takeUntil(this.destroy$)
            )
            .subscribe(() => {
                this.layer.isActive = false;
                this.toggleLayer(false);
            });

        combineLatest([this.mapService.reloadLayerData, this.mapService.mapDateRange])
            .pipe(
                debounceTime(100),
                filter(() => this.layer.isActive),
                takeUntil(this.destroy$)
            )
            .subscribe((res) => {
                if (res[0]) {
                    this.toggleLayer(true);
                    return;
                }
                this.dateRangeUpdate.emit();
            });
    }

    ngOnDestroy() {
        this.destroy$.next(undefined);
        this.destroy$.complete();
    }

    protected hasEllipsis(element: HTMLElement) {
        return ApplicationConfig.hasEllipsis(element);
    }

    /**
     * Fires and event to the parent component that the slide toggle has been changed
     * @param checked The new value of the slide toggle.
     */
    public toggleLayer(checked: boolean) {
        this.layer.isActive = checked;

        this.activeToggled.emit(checked);

        const mapKey = this.layer.mapLayer.displayName;
        const legendMap = LeafletLegendService.legends.getValue();
        const index = legendMap.findIndex((ll) => ll.name === mapKey);

        if (checked) {
            if (index === -1) {
                legendMap.push({name: mapKey, legends: this.layer.legend});
            }
        } else {
            if (index !== -1) {
                legendMap.splice(index, 1);
            }
            this.toggleIdentify(false);
            this.layer.opacity = 80;
        }
        LeafletLegendService.legends.next(legendMap);
    }

    public changeOpacity(event: number): void {
        this.layer.changeOpacity(event);
        this.updateOpacity.emit(event);
    }

    toggleIdentify(force?: boolean) {
        // Remove pointer events when identify is active
        this.identifyActive = force !== undefined ? force : !this.identifyActive;

        const handle = this.layer.mapLayer.uiHandle;
        const index = this.mapService.activeIdentifyLayers.indexOf(handle);

        if (!this.identifyActive) {
            this.identifyService.removeFeatures(this.layer.mapLayer.uiHandle);
        }

        if (this.identifyActive && index === -1) {
            this.mapService.activeIdentifyLayers.push(handle);
        } else if (!this.identifyActive && index !== -1) {
            this.mapService.activeIdentifyLayers.splice(index, 1);
        }

        MapService.getEagleiLayers()
            .filter((layer) => layer.eagleiLayer.servicetype === GenServiceType.VECTOR)
            .forEach((layer: any) => {
                if (!layer.setInteractive) {
                    console.error(`setInteractive is not defined on ${this.layer.mapLayer.displayName}`);
                    return;
                }
                layer.setInteractive(this.mapService.activeIdentifyLayers.length === 0);
            });
    }

    public isOnMobile(): boolean {
        return ApplicationConfig.useMobileLayout();
    }

    public isLite(): boolean {
        return ApplicationConfig.onLite();
    }

    public showPopoverError(): boolean {
        return (
            this.layer.isActive &&
            this.mapService.activeIdentifyLayers.length > 0 &&
            (this.layer.mapLayer.popoverData.length > 0 || this.layer.mapLayer.customPopoverId !== undefined) &&
            this.layer.mapLayer.servicetype === this.serviceType.VECTOR
        );
    }
}
