<div class="dialog-header">
    <span>Request Archived Data</span>
    <mat-icon [inline]="true" class="clickable" (click)="ref.close()" fontSet="fas" fontIcon="fa-times"></mat-icon>
</div>

<div *ngIf="events$ | async as events" class="dialog-content event-selection">
    <mat-form-field>
        <mat-label>Event</mat-label>
        <mat-select [formControl]="systemEvent">
            <mat-option>None</mat-option>
            <ng-container *ngFor="let group of events | keyvalue">
                <mat-optgroup *ngIf="group.value?.length" [label]="group.key | titlecase">
                    <mat-option *ngFor="let event of group.value; trackBy: _trackEventId" [value]="event">{{ event.name }}</mat-option>
                </mat-optgroup>
            </ng-container>
        </mat-select>
    </mat-form-field>

    <mat-divider></mat-divider>
</div>

<form class="dialog-content" [formGroup]="optionGroup" #content>
    <eaglei-date-range
        showTime
        [start]="optionGroup.controls['dates'].value?.startDate"
        [end]="optionGroup.controls['dates'].value?.endDate"
        [max]="optionGroup.controls['dates'].value?.endDate"
        [min]="minDate"
        [ignoreMinDate]="true"
        (change)="updateDates($event)">
    </eaglei-date-range>

    <div class="split-section">
        <mat-form-field class="filter-select" [class.active]="optionGroup.controls.aggregation.value">
            <mat-label>Aggregation Level</mat-label>
            <mat-select formControlName="aggregation">
                <mat-option *ngFor="let level of aggregationLevels" [value]="level">{{ level | aggregationLevel }}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="filter-select" [class.active]="optionGroup.controls.hourInterval.value !== undefined">
            <mat-label>Outage Interval</mat-label>
            <mat-select formControlName="hourInterval">
                <mat-option [value]="0">15 Minutes</mat-option>
                <mat-option [value]="1">1 Hour</mat-option>
                <mat-option [value]="4">4 Hours</mat-option>
                <mat-option [value]="8">8 Hours</mat-option>
                <mat-option [value]="12">12 Hours</mat-option>
                <mat-option [value]="24">24 Hours</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <eaglei-location-filter [defaultSelection]="states$ | async" (locations)="updateLocations($event)"></eaglei-location-filter>

    <div class="hide" [class.show]="showCountySelector">
        <ng-container *ngIf="optionGroup.controls.locations.value?.length !== 0; else noLocations">
            <mat-tab-group>
                <mat-tab *ngFor="let state of optionGroup.controls.locations.value" [label]="state.name">
                    <div class="county-container">
                        <div class="select-clear">
                            <button mat-flat-button color="primary" (click)="selectAllCounties(state.id)">Select All</button>
                            <button mat-flat-button (click)="clearAllCounties(state.id)">Clear All</button>
                        </div>
                        <div class="county-list">
                            <mat-checkbox
                                *ngFor="let county of countyByState.get(state.id)"
                                [checked]="county.selected"
                                (change)="county.selected = $event.checked">
                                {{ county.name }}
                            </mat-checkbox>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </ng-container>
        <ng-template #noLocations>
            <p class="no-location-text">Please select state(s) to select individual counties.</p>
        </ng-template>
    </div>

    <mat-form-field floatLabel="always" class="wide filter-select" [class.active]="optionGroup.controls.reason.value">
        <mat-label>Reason</mat-label>
        <textarea matInput placeholder="Reason" formControlName="reason" cdkTextareaAutosize cdkAutosizeMaxRows="4" cdkAutosizeMinRows="4">
        </textarea>
        <mat-error>
            <span *ngIf="optionGroup.controls.reason.hasError('required')"> A reason is required. </span>
        </mat-error>
    </mat-form-field>
</form>

<div class="dialog-actions">
    <button mat-flat-button color="primary" [disabled]="optionGroup.invalid" (click)="sendRequest()">Send Request</button>
</div>
