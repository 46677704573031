<eaglei-leaflet-layer-header [layer]="this" (activeToggled)="toggleLayer($event)" [hideLegend]="true"></eaglei-leaflet-layer-header>

<div class="layer-actions hide" [class.show]="isActive">
    <ng-container *ngIf="isActive">
        <div #legendElement>
            <div class="vertical-legend">
                <span class="legend-label">Electricity-Dependent Scale: </span>
                <div *ngFor="let ele of dependentLegend; let i = index">
                    <div class="legend-row">
                        <div [class]="'area-color-' + i" class="legend-box"></div>
                        <span class="sml-text">
                            {{ ele[filterGroup.value.aggregation.toString()] }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <form class="aggregation" [formGroup]="filterGroup">
        <mat-form-field class="wide">
            <mat-label>Show Locations By</mat-label>
            <mat-select formControlName="aggregation">
                <mat-option [value]="level" *ngFor="let level of aggregationLevels">
                    {{ level | aggregationLevel }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </form>

    <div class="filters">
        <div class="error-message" *ngIf="loadingMask$()">
            <p>{{ filterGroup.value.aggregation.toString() === 'county' ? layerSource.COUNTY_MASK_TEXT : layerSource.ZIP_MASK_TEXT }}</p>
        </div>

        <div class="clickable" (click)="filtersExpanded = !filtersExpanded">
            <mat-icon fontSet="fas" fontIcon="fa-filter" inline></mat-icon>
            <span class="label">Filter Locations</span>
            <mat-icon fontSet="fas" [fontIcon]="filtersExpanded ? 'fa-chevron-up' : 'fa-chevron-down'" inline></mat-icon>
        </div>

        <div class="hide" [class.show]="filtersExpanded">
            <eaglei-location-chip-filter (change)="filterStates($event)"></eaglei-location-chip-filter>
        </div>
    </div>
</div>
