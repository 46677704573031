import {inject, Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {SystemEvent} from '../../modules/system-event/classes/system-event';
import {map, switchMap} from 'rxjs/operators';
import {HeadoutEventDetail} from './classes/headout-event-detail';
import {SystemEventType} from '../../modules/system-event/classes/system-event-type';
import {HeadoutEventResultCustomerDetail} from './classes/headout-event-result-customer-detail';

@Injectable({
    providedIn: 'root',
})
export class HeadoutService {
    private httpClient = inject(HttpClient);
    private readonly baseUrl = `api/headout`;

    constructor() {}

    public getEvents(): Observable<SystemEvent[]> {
        const url = `${this.baseUrl}/event-new`;
        return this.httpClient.get<any[]>(url).pipe(map((events) => events.map((event) => new SystemEvent(event))));
    }

    public getMostRecentEvent(): Observable<SystemEvent> {
        return this.getEvents().pipe(
            switchMap((events) => {
                events.sort((a, b) => {
                    if (a.eventStart.isBefore(b.eventStart)) return 1;
                    else if (a.eventStart.isAfter(b.eventStart)) return -1;
                    return 0;
                });

                return of(events[0]);
            })
        );
    }

    public getHeadoutEventsByType(type: SystemEventType): Observable<SystemEvent[]> {
        const url = `${this.baseUrl}/events-by-type/${type.id}`;
        return this.httpClient.get<any[]>(url).pipe(map((events) => events.map((event) => new SystemEvent(event))));
    }

    public getHeadoutEventDetails(event: SystemEvent): Observable<HeadoutEventDetail> {
        const params = new HttpParams().set('systemEventId', event.id);
        const url = `${this.baseUrl}/event-detail`;
        return this.httpClient.get<any>(url, {params}).pipe(map((res) => new HeadoutEventDetail(res)));
    }

    public getHeadoutCustomerInfo(systemEventId: number, resultId: number): Observable<HeadoutEventResultCustomerDetail[]> {
        const params = new HttpParams().set('systemEventId', systemEventId).set('resultId', resultId);

        const url = `${this.baseUrl}/customer-info`;
        return this.httpClient
            .get<any[]>(url, {params})
            .pipe(map((details) => details.map((detail) => new HeadoutEventResultCustomerDetail(detail))));
    }

    public getHeadoutInfrastructureInfo(event: SystemEvent, resultId: number): Observable<any> {
        const params = new HttpParams().set('systemEventId', event.id).set('resultId', resultId);

        const url = `${this.baseUrl}/infrastructure-info`;
        return this.httpClient.get<any>(url, {params});
    }
}
