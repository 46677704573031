import {Component, ElementRef, OnInit} from '@angular/core';
import {User} from '../../../user/classes/user';
import {AuthenticationService} from '../../../../services/authentication.service';
import {Router} from '@angular/router';
import {WidgetService} from '../../services/widget.service';
import {Report} from '../../../report/classes/report';
import {BaseWidget} from '../../classes/base-widget';
import {filter, takeUntil} from 'rxjs/operators';

@Component({
    selector: 'eaglei-report-widget',
    templateUrl: './report-widget.component.html',
    styleUrls: ['./report-widget.component.scss'],
})
export class ReportWidgetComponent extends BaseWidget implements OnInit {
    private user: User;
    public reports: Report[] = [];

    constructor(
        private widgetService: WidgetService,
        private auth: AuthenticationService,
        private router: Router,
        private widgetElement: ElementRef
    ) {
        super(widgetElement);
    }

    ngOnInit() {
        this.user = this.auth.authenticatedUser.getValue();
        this.widgetService.getPinnedReports().subscribe((res) => {
            this.reports = res;
        });

        WidgetService.resize
            .pipe(
                filter((widget) => this.item.x === widget.x && this.item.y === widget.y),
                takeUntil(this.destroy$)
            )
            .subscribe(() => this.resize());
    }

    destroyWidget(): void {}

    /**
     * Navigates to the selected community
     * @param [report] The report that will be redirected to. If no report is given, it will navigate to the report landing page
     */
    public gotoReport(report?: Report): void {
        const commands: any[] = ['app/reports/'];

        if (report) {
            commands.push(report.serviceurl);
        }

        // noinspection JSIgnoredPromiseFromCall
        this.router.navigate(commands);
    }
}
