import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {BehaviorSubject, Observable, of, ReplaySubject} from 'rxjs';
// noinspection JSDeprecatedSymbols
import {catchError, flatMap, map, tap} from 'rxjs/operators';
import {User} from '../modules/user/classes/user';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ApplicationConfig} from '../classes/application-config';
import {UserPreference} from '../classes/user-preferences';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    public authenticatedUser: BehaviorSubject<User | undefined>;
    private readonly userUrl = '/api/security/user';
    private readonly loginUrl = '/login';
    private loginHeaders = new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('X-Requested-With', 'XMLHttpRequest');
    private headers = new HttpHeaders().set('Content-Type', 'application/json');

    /**
     * Creates an instance of AuthenticationService.
     *
     * @param http The http provider
     * @param router the angular router
     *
     * @param popup the matSnackBar for displaying updates to the user/
     * @memberOf AuthenticationService
     */
    constructor(private http: HttpClient, private router: Router, private popup: MatSnackBar) {
        this.authenticatedUser = new BehaviorSubject<User | undefined>(undefined);
        this.testAuthentication().subscribe();
    }

    /**
     * Will authenticate username and password and create a session.
     * If username and password are not provided, it test to see if a session with the server exist.
     * Will return user object that is authenticated after call returns.
     *
     * @param username optional user name to test
     * @param password optional password to test
     * @returns Observable<boolean> user that is currently authenticated after call completes
     *
     * @memberOf AuthenticationService
     */
    public testAuthentication(username?: string, password?: string): Observable<boolean> {
        const ret = new ReplaySubject<boolean | undefined>();
        const errorHandler = (error?: any) => {
            this.authenticatedUser.next(undefined);
            ret.next(error);
            ret.complete();
            ApplicationConfig.currentUserPreferences.next(undefined);
            return of(undefined);
        };
        const successHandler = (response: User): boolean => {
            let returnValue = false;
            if (response) {
                try {
                    const user = new User(response);
                    this.authenticatedUser.next(user);
                    ApplicationConfig.currentUserPreferences.next(
                        user.userPreferences ? new UserPreference(user.userPreferences) : undefined
                    );
                    returnValue = true;
                } catch (e) {
                    console.error(e);
                    returnValue = false;
                }
            }
            ret.next(returnValue);
            ret.complete();
            return returnValue;
        };

        if (!username || !password) {
            return this.http.get<User>(`${this.userUrl}/me`).pipe(
                catchError(errorHandler),
                map((user) => successHandler(user as User))
            );
        } else {
            const body = `username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`;
            this.http.post<User>(this.loginUrl, body, {headers: this.loginHeaders}).subscribe((res) => {
                this.authenticatedUser.next(new User(res));
                ret.next(true);
            }, errorHandler);
            return ret.asObservable() as Observable<boolean>;
        }
    }

    public ssoLogin(): void {
        // noinspection JSIgnoredPromiseFromCall
        this.router.navigate(['/login']);
    }

    public getAuthenticated(): Observable<User> {
        const me = this;
        // noinspection JSDeprecatedSymbols
        return me.testAuthentication().pipe(flatMap(() => me.authenticatedUser as Observable<User>));
    }

    /**
     *
     * @memberOf AuthenticationService
     */
    public impersonate(target: User): Observable<User> {
        const options: any = {
            responseType: 'text',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        };

        const successHandler = () => {
            this.popup.open('Impersonation Successful, Refreshing....', '', {panelClass: 'success'});
            this.router.navigateByUrl('/app/landing').then(() => window.location.reload());
        };

        const errorHandler = (e) => {
            this.popup.open('Impersonation Failed', '', {panelClass: 'failure'});
            throw e;
        };

        // noinspection JSDeprecatedSymbols
        return this.http.post(`/admin/impersonate`, `username=${target.username}`, options).pipe(
            tap(successHandler, errorHandler),
            flatMap(() => this.getAuthenticated())
        );
    }

    public unimpersonate(): Observable<User> {
        const me = this;
        const ret = this.http.post('/admin/unimpersonate', '', {responseType: 'text'});
        ret.subscribe(
            () => {
                this.popup.open('Unimpersonate Successful, Refreshing....', '', {panelClass: 'success'});
                setTimeout(() => window.location.reload(), 2000);
            },
            (e) => {
                this.popup.open('Unimpersonate Failed', '', {panelClass: 'failure'});
                throw e;
            }
        );

        // noinspection JSDeprecatedSymbols
        return ret.pipe(flatMap(() => me.getAuthenticated()));
    }

    /**
     * Will attempt to login in to the server with the username and password. If the user is already authenticated,
     * they will be logged out first.
     *
     * @param username the username
     * @param password the users password
     * @returns Observable<boolean>
     *
     * @memberOf AuthenticationService
     */
    public login(username: string, password: string): Observable<boolean> {
        return this.testAuthentication(username, password);
    }

    /**
     * Checks for appropriate password complexity
     *
     * @param password the password that's complexity is checked
     */
    public checkPasswordComplexity(password: string): Observable<string> {
        const url = `/api/security/user/check-password-complexity`;
        return this.http.post(url, password, {headers: this.headers, responseType: 'text'});
    }

    /**
     * logs user out
     * @memberOf AuthenticationService
     */
    public logout(): void {
        const form = document.createElement('form');
        form.method = 'POST';
        form.action = '/logout';
        document.body.appendChild(form);
        form.submit();
    }

    /**
     * Start a setInterval timer to hit the server every N milliseconds to keep the session alive.
     * @param frequencyMilliseconds how ofter (in MS) the server will be hit to keep the session alive*/
    public checkSession(frequencyMilliseconds: number = 1000 * 60 * 5): Observable<HttpResponse<Object>> {
        let lastSuccess: Date | null = null;
        const status = new BehaviorSubject<HttpResponse<Object>>(new HttpResponse<Object>());
        window.setInterval(
            () => {
                if (!lastSuccess || new Date().getTime() - lastSuccess.getTime() > frequencyMilliseconds) {
                    this.http.get('/api/security/user/session', {observe: 'response'}).subscribe(
                        (v) => {
                            if (!status.getValue().ok) {
                                status.next(v);
                            }
                            lastSuccess = new Date();
                        },
                        (e) => {
                            if (status.getValue() && this.authenticatedUser.getValue()) {
                                lastSuccess = null;
                                status.next(e);
                            }
                        }
                    );
                }
            },
            Math.min(5000, frequencyMilliseconds) // Interval
        );

        return status;
    }

    requestPasswordReset(usernameOrEmail: string): Observable<boolean> {
        let url: string;
        if (usernameOrEmail.indexOf('@') !== -1) {
            url = `/api/security/user/request-password-reset?email=${usernameOrEmail}`;
        } else {
            url = `/api/security/user/request-password-reset?username=${usernameOrEmail}`;
        }
        return this.http.post<boolean>(url, '');
    }

    changePassword(password: string): Observable<boolean> {
        return this.http.post<boolean>('/api/security/user/change-password', password).pipe(map(() => true));
    }

    requestAccount(user: User): Observable<User> {
        return this.http
            .post<User>('/api/security/user/request-account', JSON.stringify(user), {headers: this.headers})
            .pipe(map((res) => new User(res)));
    }

    checkEmailExists(email: string): Observable<boolean> {
        return this.http.post<boolean>('/api/security/user/check-email-exists', email);
    }

    checkUsernameExists(username: string) {
        return this.http.post('/api/security/user/check-username-exists', username, {responseType: 'text'});
    }

    checkUsernameEmailMatching(username: string, email: string): Observable<boolean> {
        const url = '/api/security/user/check-username-email';
        const body = {
            username: username,
            email: email,
        };

        return this.http.post<boolean>(url, body);
    }

    checkForExistingReactivationRequest(usernameOrEmail) {
        const url = `/api/security/user/pending-reactivation?email-or-username=${usernameOrEmail}`;
        return this.http.get(url);
    }

    /**
     * Navigates back to the login page
     */
    public navigateToLogin(): void {
        // noinspection JSIgnoredPromiseFromCall
        this.router.navigateByUrl('/login');
    }

    public requestReactivation(userInfo: User): Observable<any> {
        const url = '/api/security/user/request-reactivation';
        return this.http.post(url, userInfo);
    }
}
