import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {ApplicationConfig} from 'frontend/src/app/classes/application-config';
import {FileDownload} from 'frontend/src/app/classes/file-download';
import * as moment from 'moment';
import {RefineryStatus} from '../../../report/classes/refinery-status';
import {BaseWidget} from '../../classes/base-widget';
import {Router} from '@angular/router';
import {IReportNavigationConfig} from '../../../../interfaces/report-navigation-config.interface';
import {ReportFilter} from '../../../report/enums/report-filter.enum';
import {OngService} from '../../../../services/ong.service';
import {EventSummaryFilters} from '../../../system-event/classes/event-summary-filters';
import {GenWidgetSettingType} from '../../../../../../generated/serverModels/GenWidgetSettingType';
import {State} from '../../../outage/classes/state';
import {oilRefineryStatus} from '../../../../../integrations/ong/classes/ong-attributions-text';
import {Feature} from '@turf/turf';

@Component({
    selector: 'eaglei-refinery-status-widget',
    templateUrl: './refinery-status-widget.component.html',
    styleUrls: ['./refinery-status-widget.component.scss'],
})
export class RefineryStatusWidgetComponent extends BaseWidget implements AfterViewInit {
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    public readonly columnNames: string[] = ['status', 'name', 'capacity', 'date'];
    public dataSource: MatTableDataSource<RefineryStatus>;

    public selectedStatus: string = 'all';
    public startDate: moment.Moment = ApplicationConfig.roundMinute().startOf('day');
    private states: State[];

    constructor(private widgetElement: ElementRef, protected ongService: OngService, private router: Router) {
        super(widgetElement);
        this.attributionUrl = 'https://www.woodmac.com/';
        this.attributionText = 'Genscape: A Wood Mackenzie Business';
    }

    protected handleEventFilterChange(eventFilters: EventSummaryFilters) {
        const statusFilter = this.item.detail.settings.find((s) => s.type === GenWidgetSettingType.ONG_REFINERY_STATUS);
        if (statusFilter) {
            this.selectedStatus = statusFilter.value;
        }

        this.states = eventFilters.locations;
        this.startDate = eventFilters.date.clone();
        this.getRefineryStatus();
    }

    public ngAfterViewInit(): void {
        if (!this.eventDashboard) {
            this.getUserPreferences();
            this.states = this.userPreferences.getStates();
            this.getRefineryStatus();
        }
        this.attributionModalText = oilRefineryStatus;
        this.widgetName = 'Oil Refinery Status';
    }

    destroyWidget(): void {}

    private getRefineryStatus(): void {
        this.ongService.getRefineryStatus(this.startDate, this.states).subscribe((res) => {
            this.initData(res.features.map((d: Feature<any, RefineryStatus>) => new RefineryStatus(d.properties)));
        });
    }

    private initData(data: RefineryStatus[]): void {
        if (this.dataSource) {
            this.dataSource.data = data;
        } else {
            this.dataSource = new MatTableDataSource<RefineryStatus>(data);
            this.dataSource.filterPredicate = this.filterPredicate.bind(this);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sortingDataAccessor = this.dataAccessor.bind(this);
            this.dataSource.sort = this.sort;
        }

        this.dataSource.filter = ' ';
    }

    // noinspection JSMethodCanBeStatic
    private dataAccessor(data: RefineryStatus, header: string): string | number {
        switch (header) {
            case 'name':
                return data.name.toLowerCase();
            case 'capacity':
                return data.total_capacity;
            case 'date':
                return data.units[0].measurementDate.valueOf();
            default:
                return '';
        }
    }

    private filterPredicate(data: RefineryStatus): boolean {
        return this.selectedStatus === 'online'
            ? data.status === 'Online'
            : this.selectedStatus === 'offline'
            ? data.status === 'Offline'
            : this.selectedStatus === 'impacted'
            ? data.status === 'Reduced Capacity'
            : true;
    }

    public updateStatus(status: string): void {
        this.selectedStatus = status;
        this.dataSource.filter = ' ';
    }

    public exportTable(): void {
        let data = '';
        const headerColumns = ['Status', 'Name', 'Capacity (bpd)', 'Date'];

        data += headerColumns
            .slice(0)
            .map((val) => {
                return val[0].toUpperCase() + val.slice(1);
            })
            .join();
        data += '\n';

        this.dataSource._orderData(this.dataSource.filteredData).forEach((val) => {
            const values = [
                FileDownload.formatCsvCell(val.status),
                FileDownload.formatCsvCell(val.name),
                FileDownload.formatCsvCell(val.total_capacity),
                FileDownload.formatCsvCell(val.units[0].measurementDate.format('M/D/YYYY')),
            ];

            data += values.join();
            data += '\n';
        });

        FileDownload.downloadCSV('oilRefineryStatusWidget', data, this.attributionUrl);
    }

    public hasEllipsis(ele: HTMLElement): boolean {
        return ApplicationConfig.hasEllipsis(ele);
    }

    public goToReport(): void {
        const filters: IReportNavigationConfig[] = [
            {type: ReportFilter.PRIORITY, value: this.selectedStatus},
            {type: ReportFilter.START_DATE, value: this.startDate.format()},
        ];
        // noinspection JSIgnoredPromiseFromCall
        this.router.navigate(['app/reports/refineryStatus'], {state: {filters}});
    }

    public updateDateRange(date: moment.Moment) {
        this.startDate = date;
        this.getRefineryStatus();
    }
}
