import {Component} from '@angular/core';
import {HttpInterceptorService} from 'frontend/src/app/services/http-interceptor.service';
import {tap} from 'rxjs/operators';
import {EagleILayer} from '../../classes/eagle-i-layer';
import {LayerService} from '../../services/layer.service';
import {LeafletLegendService} from '../../services/leaflet-legend.service';
import {RiverFloodingSource} from '../../sources/river-flooding-source';

@Component({
    selector: 'eaglei-river-flooding-layer',
    templateUrl: './river-flooding-layer.component.html',
    styleUrls: ['./river-flooding-layer.component.scss'],
})
export class RiverFloodingLayerComponent extends EagleILayer<RiverFloodingSource> {
    constructor(private layerService: LayerService, private legendService: LeafletLegendService) {
        super();
    }

    afterViewInit(): void {}

    /**
     * Toggles the Layer on or off
     * @param activeState State of the layer
     * @param zoomToFeatures should zoom to feautes
     */
    public toggleLayer(activeState: boolean, zoomToFeatures?: boolean): void {
        this.isActive = activeState;
        if (activeState) {
            if (!this.layerSource) {
                this.layerSource = new RiverFloodingSource(this.mapLayer);
                this.legendService.getLegend(this).subscribe((legend) => (this.legend = legend));
            }

            this.getRiverFlooding();
        } else {
            HttpInterceptorService.clearInterceptor(this.mapLayer.interceptorKey);
            this.layerSource.removeFromMap();
        }
    }

    /**
     * Gets the River Flooding Data
     */
    private getRiverFlooding(): void {
        this.mapLayer.startLoading();

        const params = {
            f: 'geojson',
            where: `status IN ('low_threshold', 'action', 'minor', 'moderate', 'major')`,
            outFields: '*',
        };

        const paramStr = Object.keys(params)
            .map((key) => `${key}=${params[key]}`)
            .join('&');
        const dataUrl = `${this.mapLayer.serviceurl}/${this.mapLayer.servicelayer}/query?${paramStr}`;

        HttpInterceptorService.clearInterceptor(this.mapLayer.interceptorKey);
        HttpInterceptorService.pendingRequests[this.mapLayer.interceptorKey] = this.layerService
            .getJsonFromUrl(dataUrl, true)
            .pipe(tap(() => HttpInterceptorService.deleteFromInterceptor(this.mapLayer.interceptorKey)))
            .subscribe((res) => {
                this.layerSource.processFeatures(res.features);
                this.layerSource.addToMap();
                this.mapLayer.endLoading();
            });
    }
}
