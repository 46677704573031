<!-- Report Filters -->
<div class="filter-bar ignore-last">
    <section class="filters">
        <mat-form-field class="filter-select" [class.active]="!!level">
            <mat-label>Show Outages by</mat-label>
            <mat-select [(value)]="level" (selectionChange)="updateLevel()">
                <mat-option [value]="'state'">State/Territory</mat-option>
                <mat-option [value]="'county'">County</mat-option>
            </mat-select>
        </mat-form-field>

        <eaglei-location-filter (locations)="updateRegionFilter($event)" [defaultSelection]="selectedStates"></eaglei-location-filter>

        <mat-form-field class="filter-select" [class.active]="!!interval">
            <mat-label>Time Increments</mat-label>
            <mat-select [(value)]="interval" (selectionChange)="getOutages()">
                <mat-option [value]="6">Every 6 Hours</mat-option>
                <mat-option [value]="12">Every 12 Hours</mat-option>
                <mat-option [value]="24">Every 24 Hours</mat-option>
            </mat-select>
            <mat-icon matSuffix class="icon clock" fontSet="fas" fontIcon="fa-clock"></mat-icon>
        </mat-form-field>

        <mat-form-field class="filter-select datepicker" [class.active]="!!eDate">
            <mat-label>End Date</mat-label>
            <input
                matInput
                [matDatepicker]="dp"
                readonly
                [(ngModel)]="eDate"
                (click)="dp.opened ? dp.close() : dp.open()"
                style="cursor: pointer"
                [min]="minDate"
                [max]="maxDate"
                (dateChange)="updateDate($event.value)" />
            <mat-datepicker-toggle matSuffix [for]="dp" class="icon calendar"></mat-datepicker-toggle>
            <mat-datepicker #dp [disabled]="false"></mat-datepicker>
        </mat-form-field>
    </section>

    <div class="actions">
        <button
            mat-stroked-button
            [matMenuTriggerFor]="menu"
            [disabled]="showMask"
            [matTooltip]="getExportTooltip()"
            matTooltipPosition="above"
            matTooltipShowDelay="250">
            <mat-icon fontSet="fas" fontIcon="fa-file-export" inline></mat-icon>
        </button>

        <button
            mat-stroked-button
            (click)="restorationInfo()"
            matTooltip="Restoration Info"
            matTooltipPosition="above"
            matTooltipShowDelay="250">
            <mat-icon fontSet="fas" fontIcon="fa-info-circle" inline></mat-icon>
        </button>
    </div>
</div>

<div class="filter-chips">
    <span class="filter-text">Filters: </span>
    <eaglei-filter-chip-list [states]="selectedStates" (change)="changeSelectedChips($event)"></eaglei-filter-chip-list>
</div>

<!-- Report Layout -->
<div class="report-data">
    <ng-container [ngTemplateOutlet]="lineChart"></ng-container>
    <ng-container [ngTemplateOutlet]="reportTable"></ng-container>
</div>

<!-- Chart -->
<ng-template #lineChart>
    <mat-card class="chart">
        <mat-card-subtitle>
            <svg #legend width="375" height="15">
                <rect width="15" height="15" x="0" rx="5" ry="5" style="fill: #97cffd"></rect>
                <text x="20" y="12" style="font-size: 14px">Total Outages</text>

                <rect width="15" height="15" x="120" rx="5" ry="5" style="fill: #7cfa17"></rect>
                <text x="140" y="12" style="font-size: 14px">Restoration</text>

                <rect width="15" height="15" x="230" rx="5" ry="5" style="fill: #9d81de"></rect>
                <text x="250" y="12" style="font-size: 14px">New Outages</text>
            </svg>
        </mat-card-subtitle>

        <mat-card-content [class.invisible]="showMask || isLoading">
            <div class="chart-target">
                <div #chartTarget class="chart-target"></div>
            </div>
        </mat-card-content>

        <div class="auto-mask hide-mask" *ngIf="showMask || isLoading">
            <span class="mask-message">
                <span>{{ getMaskText() }}</span>
                <div>
                    <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
                </div>
            </span>
        </div>
    </mat-card>
</ng-template>

<!-- Table -->
<ng-template #reportTable>
    <mat-card>
        <mat-card-content>
            <div class="table-container">
                <table
                    #materialTable
                    mat-table
                    class="test-design"
                    [dataSource]="dataSource"
                    matSort
                    [matSortDisableClear]="true"
                    matSortActive="percentRestored"
                    matSortDirection="desc">
                    <ng-container matColumnDef="fema">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            FEMA Region
                            <eaglei-table-sort-header [columnName]="'fema'" [sort]="sort"> </eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let outage">
                            {{ outage.femaRegion | numeral : true }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="state">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="state">
                            State/Territory
                            <eaglei-table-sort-header [columnName]="'state'" [sort]="sort"> </eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let outage" class="state">
                            {{ outage.state }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="county">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            County
                            <eaglei-table-sort-header [columnName]="'county'" [sort]="sort"> </eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let outage">
                            {{ outage.countyName }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="totalCustomers">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Total Customers
                            <eaglei-table-sort-header [columnName]="'coveredCustomers'" [sort]="sort"> </eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let outage">
                            {{ outage.numCustomers | eagleiNumber }}
                            <span class="county-customer"> {{ getCountyCustomerText(outage) }}</span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="outageCount">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Outage Count
                            <eaglei-table-sort-header [columnName]="'outageCount'" [sort]="sort"> </eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let outage">
                            {{ outage.outageCountTotal | eagleiNumber }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="restorationCount">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Restoration Count
                            <eaglei-table-sort-header [columnName]="'restorationCount'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let outage">
                            {{ outage.restorationTotal | eagleiNumber }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="percentRestored">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Percent Restored
                            <eaglei-table-sort-header [columnName]="'percentRestored'" [sort]="sort"> </eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let outage">
                            <div *ngIf="outage.knownPercent">{{ outage.percentRestored | eagleiNumber }}%</div>
                            <div *ngIf="!outage.knownPercent">Not Available</div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="trend">
                        <th mat-header-cell *matHeaderCellDef>Trending</th>
                        <td mat-cell *matCellDef="let outage">
                            <mat-icon
                                class="trend"
                                [class.up]="outage.trendIcon === 'trending_up'"
                                [class.down]="outage.trendIcon === 'trending_down'"
                                >{{ outage.trendIcon }}</mat-icon
                            >
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="maxOutage">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Max Outage
                            <eaglei-table-sort-header [columnName]="'maxOutage'" [sort]="sort"> </eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let outage">
                            {{ outage.maxOutageCount | eagleiNumber }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="maxRestoration">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Max Restoration
                            <eaglei-table-sort-header [columnName]="'maxRestoration'" [sort]="sort"> </eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let outage">
                            {{ outage.maxRestorationCount | eagleiNumber }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="lastTimestamp">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Last Timestamp
                            <eaglei-table-sort-header [columnName]="'lastTimestamp'" [sort]="sort"> </eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let outage">
                            {{ outage.timestamp | momentDate : 'MM/DD/YYYY' }}
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
                    <tr mat-row *matRowDef="let row; columns: columnNames"></tr>
                </table>

                <div class="auto-mask" *ngIf="showMask">
                    <span class="mask-message">
                        <span>{{ getMaskText() }}</span>
                        <div>
                            <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
                        </div>
                    </span>
                </div>

                <mat-paginator class="test-design" [pageSizeOptions]="[25, 50, 75, 100]" showFirstLastButtons> </mat-paginator>
            </div>

            <div class="table-attribution">
                <a (click)="reportService.openAttribution(attributionUrl)" [innerHTML]="attributionTitle | sanitize"></a>
            </div>
        </mat-card-content>
    </mat-card>
</ng-template>

<!-- Export Menu -->
<mat-menu #menu="matMenu" overlapTrigger>
    <button (click)="exportTable()" mat-menu-item>Export Table As CSV</button>
    <button (click)="exportChartAsCsv()" mat-menu-item>Export Chart As CSV</button>
    <div
        matTooltip="This feature is not available for this report in IE"
        [matTooltipDisabled]="!onIE()"
        matTooltipPosition="before"
        matTooltipShowDelay="250">
        <button (click)="exportChart()" mat-menu-item [disabled]="onIE()">Export Chart As PNG</button>
    </div>
</mat-menu>
