<div class="dialog-header">
    <span>Restoration Info</span>
    <button mat-icon-button mat-dialog-close>
        <mat-icon fontSet="fas" fontIcon="fa-times"></mat-icon>
    </button>
</div>

<mat-dialog-content #content>
    <span>
        <b>Restoration Data</b>
        <p>
            This report aims to display recovery from an event by graphing <b>total outages</b>, <b>new outages</b>, and restored outages
            (termed <b>restoration</b> data) for the selected state/territory(s) over a 7-day period by 6-hour, 12-hour, or 24-hour
            increments
        </p>
    </span>

    <ul>
        <li>
            <span class="item">
                <div class="legend outages"></div>
                <p>
                    <b>Total outage</b> count is a summation of the maximum number of outages reported by all utilities in each county for
                    the selected time increment (6-hr, 12-hr, 24-hr). While outage data is collected every 15 minutes, this report selects
                    the maximum 15-minute amount that occurred within the selected time increment (6-hr, 12-hr, 24-hr) for each county. The
                    maximums from all counties within the selected state/territory(s) are then added together to generate a data point for
                    this report. Note that since summations of the maximum total number of outages from a particular 15-min collection for
                    each county within the selected time increments are used for this report, the total outage numbers may not match the
                    data in the EAGLE-I Mapper exactly.
                </p>
            </span>
        </li>
        <li>
            <span class="item">
                <div class="legend new-outages"></div>
                <p>
                    <b>New outages</b> are when the number of total outages for a county at a specific time increment are greater than the
                    number of total outages in the previous time increment for that county. The differences (Total outages for time 2 –
                    Total outages for time 1) for each county within the selected state/territory(s) are summed within the selected time
                    increment (6-hr, 12-hr, 24-hr) to generate a data point for this report. Note that a county with restoration data will
                    always have a new outage count of 0.
                </p>
            </span>
        </li>
        <li>
            <span class="item">
                <div class="legend restoration"></div>
                <div>
                    <b>Restoration</b> data are when the total outages for a county at a specific time increment are less than the number of
                    total outages in the previous time increment for that county. The differences (Total outages for time 1 – Total outages
                    for time 2) for each county within the selected state/territory(s) are summed within the selected time increment (6-hr,
                    12-hr, 24-hr) to generate a data point for this report. Note that a county with new outages will always have a
                    restoration count of 0.
                </div>
            </span>
        </li>
    </ul>
    <br />
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-flat-button mat-dialog-close color="primary">Close</button>
</mat-dialog-actions>
