import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {LegendElement} from '../classes/legend-element';
import {LeafletLayerLegend} from '../classes/leaflet-layer-legend';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {GenLegendType} from '../../../../../generated/serverModels/GenLegendType';
import {ApplicationConfig} from '../../../classes/application-config';
import {EagleILayer} from '../classes/eagle-i-layer';
import {ILegendList} from '../interfaces/legend-list.interface';

@Injectable({
    providedIn: 'root',
})
export class LeafletLegendService {
    static legends = new BehaviorSubject<ILegendList[]>([]);

    constructor(private http: HttpClient) {}

    public getActiveWatchTypes(url: string, layerId: number): Observable<string[]> {
        const params = {
            where: '1=1',
            outFields: 'prod_type',
            returnGeometry: 'false',
            f: 'pjson',
        };

        const paramStr = Object.keys(params)
            .map((key) => `${key}=${params[key]}`)
            .join('&');
        const requestUrl = `${url}${layerId}/query?${paramStr}`;
        return this.http.get<any>(requestUrl).pipe(
            map((res) => {
                return res.features.map((f) => f.attributes.prod_type);
            })
        );
    }

    public getLegend(layer: EagleILayer, htmlElement?: HTMLElement): Observable<LeafletLayerLegend[]> {
        if (!layer.mapLayer.legend) {
            console.warn(`Legend is not defined on maplayer ${layer.mapLayer.displayName}`);
            return;
        }

        switch (layer.mapLayer.legend.type) {
            case GenLegendType.GEOSERVER:
                return this.getGeoserverLegend(layer);
            case GenLegendType.IMAGE:
                return this.getImageLegend(layer);
            case GenLegendType.ARCGIS:
                return this.getArcgisLegend(layer);
            case GenLegendType.MANUAL:
                return this.getManualLegend(layer);
            case GenLegendType.HARD_CODED:
                return this.getHardCoded(layer, htmlElement);
            default:
                console.warn(`Legend type is not ${layer.mapLayer.legend.type} implemented yet`);
                return of([]);
        }
    }

    private getArcgisLegend(layer: EagleILayer): Observable<LeafletLayerLegend[]> {
        const mapLayer = layer.mapLayer;
        const layerLegend = mapLayer.legend;
        const url = layerLegend.urls[0];
        const values = layerLegend.parseValues();
        const layerIds = values.layerIds.split(',').map((v) => parseInt(v));

        const mf = (res: any) => {
            const filtered: any[] = res.layers.filter((responseLayer) => layerIds.includes(responseLayer.layerId));
            return filtered.map((responseLayer) => {
                const legendDisplay = new LeafletLayerLegend(mapLayer.id);
                if (layerIds.length > 1) {
                    legendDisplay.layerName = (responseLayer.layerName as string).match(/[A-Z][a-z]+|[0-9]+/g).join(' ');
                }

                let legends: any[] = responseLayer.legend;
                const filters = values?.filters ? values.filters[responseLayer.layerId] : undefined;

                if (filters) {
                    legends = legends.filter((l) => (l.values as string[]).some((val) => filters.includes(val)));
                }

                legendDisplay.elements = legends.map((legend) => {
                    const element = new LegendElement();
                    element.label = legend.label || responseLayer.layerName;
                    element.imageUrl = `data:${legend.contentType};base64,${legend.imageData}`;
                    element.height = legend.height;
                    element.width = legend.width;
                    return element;
                });

                return legendDisplay;
            });
        };

        return this.http.get<any>(url).pipe(map(mf));
    }

    private getGeoserverLegend(layer: EagleILayer): Observable<LeafletLayerLegend[]> {
        const mapLayer = layer.mapLayer;

        const params = {
            REQUEST: 'GetLegendGraphic',
            VERSION: '1.0.0',
            FORMAT: 'image/png',
            LAYER: mapLayer.servicelayer,
            legend_options: 'forceLabels:on;fontName:Roboto;fontSize:14;',
        };

        Object.assign(params, mapLayer.legend.parseValues());

        const paramStr = Object.keys(params)
            .map((key) => `${key}=${params[key]}`)
            .join('&');

        const layerLegend = new LeafletLayerLegend(mapLayer.id, mapLayer.displayName);
        layerLegend.elements = [new LegendElement().setImageUrl(`${ApplicationConfig.proxyPrefix}${mapLayer.legend.urls[0]}?${paramStr}`)];

        return of([layerLegend]);
    }

    private getManualLegend(layer: EagleILayer): Observable<LeafletLayerLegend[]> {
        const values = layer.mapLayer.legend.parseValues();
        const legend = new LeafletLayerLegend(layer.mapLayer.id, layer.mapLayer.displayName);

        if (values.orientation) {
            legend.orientation = values.orientation;
        }

        legend.elements = values.elements.map((ele) => {
            const element = new LegendElement(ele.label).setFill(ele.fillColor).setWidth(ele.width).setHeight(ele.height);

            element.textSize = ele.textSize || 18; // 18px is the default for the application
            element.labelPosition = ele.labelPosition;
            element.labelColor = ele.labelColor || '#000000';
            element.shape = ele.shape || 'rect';

            return element;
        });

        return of([legend]);
    }

    private getHardCoded(layer: EagleILayer, htmlElement: HTMLElement): Observable<LeafletLayerLegend[]> {
        const legend = new LeafletLayerLegend(layer.mapLayer.id);
        const legendHtmlElement = new LegendElement();

        legendHtmlElement.html = htmlElement.innerHTML;
        legend.elements = [legendHtmlElement];

        return of([legend]);
    }

    private getImageLegend(layer: EagleILayer): Observable<LeafletLayerLegend[]> {
        const legend = new LeafletLayerLegend(layer.mapLayer.id, layer.mapLayer.displayName);

        legend.elements = layer.mapLayer.legend.urls.map((url) => {
            return new LegendElement().setImageUrl(url);
        });
        return of([legend]);
    }
}
