
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import * as moment from 'moment';

export class GenTreasuryFacility {

    id: number;
    active: boolean;
    address: string;
    agencyCode: number;
    agencyName: string;
    area: number;
    boundary: string;
    building: boolean;
    buildingName: string;
    buildingNumber: string;
    buildingType: string;
    city: string;
    district: number;
    fpsFsl: number;
    fscDo: string;
    fscDoAgency: string;
    fscDoEmail: string;
    fscDoPhone: string;
    jurisdiction: string;
    latitude: number;
    longitude: number;
    ownership: string;
    population: number;
    qaEffectiveDate: moment.Moment;
    qaExpirationDate: moment.Moment;
    region: number;
    rentableSqft: number;
    state: string;
    zip: string;

    constructor();
    constructor( jsonObj: any );
    constructor( genTreasuryFacility: GenTreasuryFacility);
    constructor( id: number );

    constructor( input?: GenTreasuryFacility|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            this.active = input.active; // boolean [boolean]
            this.address = input.address; // string [string]
            this.agencyCode = input.agencyCode; // int [number]
            this.agencyName = input.agencyName; // string [string]
            this.area = input.area; // int [number]
            this.boundary = input.boundary; // string [string]
            this.building = input.building; // boolean [boolean]
            this.buildingName = input.buildingName; // string [string]
            this.buildingNumber = input.buildingNumber; // string [string]
            this.buildingType = input.buildingType; // string [string]
            this.city = input.city; // string [string]
            this.district = input.district; // int [number]
            this.fpsFsl = input.fpsFsl; // int [number]
            this.fscDo = input.fscDo; // string [string]
            this.fscDoAgency = input.fscDoAgency; // string [string]
            this.fscDoEmail = input.fscDoEmail; // string [string]
            this.fscDoPhone = input.fscDoPhone; // string [string]
            this.jurisdiction = input.jurisdiction; // string [string]
            this.latitude = input.latitude; // float [number]
            this.longitude = input.longitude; // float [number]
            this.ownership = input.ownership; // string [string]
            this.population = input.population; // int [number]
            if ( input.qaEffectiveDate && typeof input.qaEffectiveDate === 'string' ) {
                this.qaEffectiveDate = moment(input.qaEffectiveDate).utc();
            } else {
                this.qaEffectiveDate = input.qaEffectiveDate;
            }
            if ( input.qaExpirationDate && typeof input.qaExpirationDate === 'string' ) {
                this.qaExpirationDate = moment(input.qaExpirationDate).utc();
            } else {
                this.qaExpirationDate = input.qaExpirationDate;
            }
            this.region = input.region; // int [number]
            this.rentableSqft = input.rentableSqft; // float [number]
            this.state = input.state; // string [string]
            this.zip = input.zip; // string [string]
       }
    }

    static jsonResponseToList(arr: any[]): GenTreasuryFacility[] {
        if (arr && arr.map) {
          return arr.map(a => new GenTreasuryFacility(a));
        } else {
          return [];
        }
    }
}

