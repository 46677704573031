import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {SystemNewsRoutingModule} from './system-news-routing.module';
import {SystemNewsFeedComponent} from './components/system-news-feed/system-news-feed.component';
import {CreateSystemNewsComponent} from './modals/create-system-news/create-system-news.component';
import {SystemNewsService} from './services/system-news.service';
import {SharedModule} from '../../../shared/shared.module';
import {SystemNewsInfoModalComponent} from './modals/system-news-info-modal/system-news-info-modal.component';

@NgModule({
    declarations: [SystemNewsFeedComponent, CreateSystemNewsComponent, SystemNewsInfoModalComponent],
    providers: [SystemNewsService],
    imports: [CommonModule, SystemNewsRoutingModule, SharedModule],
    exports: [SystemNewsInfoModalComponent],
})
export class SystemNewsModule {}
