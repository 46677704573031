
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import { GenGenNewsfeedAdditional } from './GenGenNewsfeedAdditional';
import { GenGenNewsfeedKeyword } from './GenGenNewsfeedKeyword';
import { GenGenNewsfeedMedia } from './GenGenNewsfeedMedia';
import { GenRoleDefinition } from './GenRoleDefinition';
import * as moment from 'moment';

export class GenNewsfeed {

    id: number;
    additional: GenGenNewsfeedAdditional;
    boundary: string;
    collectionDate: moment.Moment;
    collectionTime: number;
    content: string;
    eventId: number;
    keywords: GenGenNewsfeedKeyword[];
    lat: number;
    location: string;
    lon: number;
    media: GenGenNewsfeedMedia[];
    newsDate: moment.Moment;
    roles: GenRoleDefinition[];
    source: number;
    sourceId: string;
    sourceTypeId: number;
    stamp: number;
    title: string;

    constructor();
    constructor( jsonObj: any );
    constructor( genNewsfeed: GenNewsfeed);
    constructor( id: number );

    constructor( input?: GenNewsfeed|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            if( input.additional ) {
              this.additional = new GenGenNewsfeedAdditional( input.additional );
            }
            this.boundary = input.boundary; // string [string]
            if ( input.collectionDate && typeof input.collectionDate === 'string' ) {
                this.collectionDate = moment(input.collectionDate).utc();
            } else {
                this.collectionDate = input.collectionDate;
            }
            this.collectionTime = input.collectionTime; // int [number]
            this.content = input.content; // string [string]
            this.eventId = input.eventId; // int [number]
            if ( input.keywords && input.keywords.map ) { // list [GenGenNewsfeedKeyword]
                this.keywords = input.keywords.map( ( x: GenGenNewsfeedKeyword ) => {
                    return new GenGenNewsfeedKeyword( x );
                } );
            } else {
                this.keywords = input.keywords;
            }
            this.lat = input.lat; // float [number]
            this.location = input.location; // string [string]
            this.lon = input.lon; // float [number]
            if ( input.media && input.media.map ) { // list [GenGenNewsfeedMedia]
                this.media = input.media.map( ( x: GenGenNewsfeedMedia ) => {
                    return new GenGenNewsfeedMedia( x );
                } );
            } else {
                this.media = input.media;
            }
            if ( input.newsDate && typeof input.newsDate === 'string' ) {
                this.newsDate = moment(input.newsDate).utc();
            } else {
                this.newsDate = input.newsDate;
            }
            if ( input.roles && input.roles.map ) {
                this.roles = input.roles.map( ( x: GenRoleDefinition ) => {
                    return (typeof x === 'string') ? GenRoleDefinition.forName(<string> x) : <any> x; // GenRoleDefinition
                } );
            } else {
                this.roles = input.roles; // GenRoleDefinition
            }
            this.source = input.source; // int [number]
            this.sourceId = input.sourceId; // string [string]
            this.sourceTypeId = input.sourceTypeId; // int [number]
            this.stamp = input.stamp; // int [number]
            this.title = input.title; // string [string]
       }
    }

    static jsonResponseToList(arr: any[]): GenNewsfeed[] {
        if (arr && arr.map) {
          return arr.map(a => new GenNewsfeed(a));
        } else {
          return [];
        }
    }
}

