import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'numeral',
})
export class NumeralPipe implements PipeTransform {
    private romanNumerals: string[] = ['0', 'I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX', 'X'];

    transform(value: string | number, addPrefix: boolean = false, checkAsNumeral?: boolean): string {
        let val;

        if (checkAsNumeral && this.romanNumerals.indexOf(`${value}`) !== -1) {
            val = value;
        } else {
            val = this.romanNumerals[value];
        }

        if (val) {
            return !addPrefix ? `${val}` : `FEMA ${val}`;
        } else {
            return `${value}`;
        }
    }
}
