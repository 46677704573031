<!--suppress HtmlFormInputWithoutLabel -->
<div class="new-design">
    <div class="page-title">
        <header>Log In</header>
        <p>New User? <a tabindex="3" [routerLink]="['account-request']">Sign Up</a></p>
    </div>

    <div class="login-form">
        <form [formGroup]="formGroup">
            <mat-form-field appearance="outline">
                <mat-label>Username</mat-label>
                <input type="text" name="username" matInput autofocus formControlName="username" (keyup.enter)="login()" />
                <mat-error *ngIf="formGroup.controls['username'].errors">
                    <span class="invalid">Required</span>
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Password</mat-label>
                <input
                    [type]="showPassword ? 'text' : 'password'"
                    name="password"
                    matInput
                    formControlName="password"
                    (keyup.enter)="login()" />
                <mat-icon matSuffix (click)="showPassword = !showPassword">
                    {{ showPassword ? 'visibility_off' : 'visibility' }}
                </mat-icon>
                <mat-error *ngIf="formGroup.controls['password'].errors">
                    <span class="invalid">Required</span>
                </mat-error>
            </mat-form-field>
        </form>

        <div class="response-message">
            <div *ngIf="errorCode === 423" class="failure-text">
                Account Inactive -- Your account request is currently being reviewed contact
                <a href="mailto:eagle-i@ornl.gov">eagle-i@ornl.gov</a> for questions.
            </div>

            <div *ngIf="errorCode === 403" class="failure-text">
                <i class="fa fa-exclamation failure-text"></i> Your account has been disabled.
                <a (click)="goToReactivationPage()">Request account reactivation</a>.
            </div>

            <div *ngIf="errorCode === 401">
                <p class="failure-text">Your username or password is incorrect.</p>
            </div>

            <div *ngIf="errorCode === 400">
                <p class="failure-text">
                    Please click on the link that has been sent to your email account to verify your email and wait for an account approval
                    email before attempting to access your account. Contact <a href="mailto:eagle-i@ornl.gov">eagle-i@ornl.gov</a> for
                    questions
                </p>
            </div>
        </div>

        <div class="reset-password">
            <a tabindex="4" [routerLink]="['reset-password']">Reset Password</a>
        </div>
        <p class="term-of-use" tabindex="5">By logging in, you agree to our <a target="_blank" routerLink="/termsOfUse">Terms of Use</a></p>
    </div>

    <div class="actions">
        <button mat-flat-button color="primary" class="wide" tabindex="2" (click)="login()" [disabled]="formGroup.invalid">Log In</button>
        <a class="email-issue" tabindex="6" href="mailto:eagle-i@ornl.gov">Trouble Logging In?</a>
    </div>
</div>
