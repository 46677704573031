export class HssData {
    state: string;
    county?: string;
    zipCode?: string;
    fipsCode?: string;
    beneficiaries: number;
    energyDependant: number;
    name: string;

    // We may want a partial interface for this?
    constructor(data: any) {
        this.state = data.STATE || data.State;
        this.county = data.COUNTY || data.County;
        this.zipCode = data.Zip_Code;
        this.fipsCode = data.FIPS_Code || data.State_FIPS_Code;
        this.beneficiaries = data.Medicare_Benes;
        this.energyDependant = data.Power_Dependent_Devices_DME;

        if (this.zipCode) {
            this.name = this.zipCode;
        } else if (this.county) {
            this.name = `${this.county} (${this.state})`;
        } else if (this.state) {
            this.name = this.state;
        } else {
            this.name = 'Not Available';
        }
    }
}
