<div class="filter-bar ignore-last">
    <section class="filters">
        <mat-form-field class="filter-select" [class.active]="selectedRegions.length !== 0">
            <mat-label>Region</mat-label>
            <mat-select multiple placeholder="Regions" [value]="selectedRegions" (selectionChange)="updateRegions($event.value)">
                <mat-option *ngFor="let region of portRegions" [value]="region">
                    <span>{{ region }}</span>
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="filter-select" [class.active]="selectedStatus.length !== 0">
            <mat-label>Port Status</mat-label>
            <mat-select multiple placeholder="Port Status" [value]="selectedStatus" (selectionChange)="updateStatus($event.value)">
                <mat-option *ngFor="let status of portStatuses" [value]="status">
                    <span>{{ statusStrings[status] }}</span>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </section>

    <button mat-stroked-button matTooltip="Export" matTooltipPosition="above" matTooltipShowDelay="250" [matMenuTriggerFor]="menu">
        <mat-icon fontSet="fas" fontIcon="fa-file-export" inline></mat-icon>
    </button>
</div>

<!--Map-->
<ng-template #portMap>
    <section class="map-section">
        <mat-card class="map">
            <mat-card-content>
                <eaglei-leaflet-map [mapOptions]="mapOptions"></eaglei-leaflet-map>
            </mat-card-content>
            <mat-card-footer>
                <div class="legend">
                    <span class="label legend-label"> Port Status: </span>

                    <div class="legend-item">
                        <div class="status open"></div>
                        <span class="label"> Open</span>
                    </div>
                    <div class="legend-item">
                        <div class="status closed"></div>
                        <span class="label"> Closed</span>
                    </div>
                    <div class="legend-item">
                        <div class="status restricted"></div>
                        <span class="label"> Restrictions</span>
                    </div>
                    <div class="legend-item">
                        <div class="status both"></div>
                        <span class="label"> Closed & Restrictions</span>
                    </div>
                    <div class="legend-item">
                        <div class="status none"></div>
                        <span class="label"> 0 Subports open</span>
                    </div>
                </div>
            </mat-card-footer>
        </mat-card>
    </section>
</ng-template>

<div class="report-data">
    <ng-container [ngTemplateOutlet]="portMap"></ng-container>
    <ng-container [ngTemplateOutlet]="table"></ng-container>
</div>

<!--Table-->
<ng-template #table>
    <div class="table-section">
        <mat-card [class.invisible]="!hasData()">
            <div class="table-container">
                <table
                    mat-table
                    [dataSource]="dataSource"
                    multiTemplateDataRows
                    matSort
                    [matSortDisableClear]="true"
                    matSortActive="date"
                    matSortDirection="desc">
                    <ng-container matColumnDef="region">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Region
                            <eaglei-table-sort-header [columnName]="'region'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let info"
                            class="title"
                            [matTooltip]="info.name"
                            matTooltipPosition="right"
                            matTooltipShowDelay="250">
                            <p class="ellipsis">{{ info.name }}</p>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="port">
                        <th mat-header-cell *matHeaderCellDef>
                            Port
                            <eaglei-table-sort-header [columnName]="'port'"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let info"></td>
                    </ng-container>

                    <ng-container matColumnDef="portStatus">
                        <th mat-header-cell *matHeaderCellDef>
                            Port Status
                            <eaglei-table-sort-header [columnName]="'portStatus'"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let info">
                            <span *ngFor="let status of info.status">
                                <mat-icon *ngIf="status === 1" inline class="open" fontSet="fas" fontIcon="fa-circle"></mat-icon>
                                <mat-icon *ngIf="status === 3" inline class="closed" fontSet="fas" fontIcon="fa-circle"></mat-icon>
                                <mat-icon *ngIf="status === 2" inline class="restricted" fontSet="fas" fontIcon="fa-circle"></mat-icon>
                            </span>
                            <span *ngFor="let status of info.status">
                                <span>{{ statusStrings[status] }} </span>
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="subports">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Number of Subports
                            <eaglei-table-sort-header [columnName]="'subports'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let info">{{ info.ports.length }}</td>
                    </ng-container>

                    <ng-container matColumnDef="comments">
                        <th mat-header-cell *matHeaderCellDef>
                            Comments
                            <eaglei-table-sort-header [columnName]="'comments'"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let info"></td>
                    </ng-container>

                    <ng-container matColumnDef="lastUpdated">
                        <th mat-header-cell *matHeaderCellDef>
                            Last Updated
                            <eaglei-table-sort-header [columnName]="'lastUpdated'"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let info"></td>
                    </ng-container>

                    <ng-container matColumnDef="zonePage">
                        <th mat-header-cell *matHeaderCellDef>
                            Zone Page
                            <eaglei-table-sort-header [columnName]="'zonePage'"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let info">
                            <a [href]="info.url" target="_blank" class="text-center">
                                <mat-icon inline class="externalLink" fontSet="fas" fontIcon="fa-external-link-alt"></mat-icon>
                            </a>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="toggle">
                        <th mat-header-cell *matHeaderCellDef>
                            <eaglei-table-sort-header [columnName]="'toggle'"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let info">
                            <mat-icon
                                inline
                                class="externalLink"
                                fontSet="fas"
                                fontIcon="fa-chevron-down"
                                *ngIf="!isActiveEvent(info)"></mat-icon>
                            <mat-icon
                                inline
                                class="externalLink"
                                fontSet="fas"
                                fontIcon="fa-chevron-up"
                                *ngIf="isActiveEvent(info)"></mat-icon>
                        </td>
                    </ng-container>

                    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                    <ng-container [matColumnDef]="'expandedDetail'">
                        <td mat-cell *matCellDef="let info" [attr.colspan]="columns.length" class="expandedDetail">
                            <table mat-table [dataSource]="portDataSource" multiTemplateDataRows *ngIf="isActiveEvent(info)">
                                <tr mat-row *matRowDef="let row; columns: columns"></tr>

                                <ng-container matColumnDef="region">
                                    <td mat-cell *matCellDef="let port" class="title">
                                        <!--                                  <p class="ellipsis">{{info.name}}</p>-->
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="port">
                                    <td mat-cell *matCellDef="let port">
                                        {{ port.name }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="portStatus">
                                    <td mat-cell *matCellDef="let port">
                                        <span>
                                            <mat-icon
                                                *ngIf="port.status === 1"
                                                inline
                                                class="open"
                                                fontSet="fas"
                                                fontIcon="fa-circle"></mat-icon>
                                            <mat-icon
                                                *ngIf="port.status === 3"
                                                inline
                                                class="closed"
                                                fontSet="fas"
                                                fontIcon="fa-circle"></mat-icon>
                                            <mat-icon
                                                *ngIf="port.status === 2"
                                                inline
                                                class="restricted"
                                                fontSet="fas"
                                                fontIcon="fa-circle"></mat-icon>
                                        </span>
                                        <span>
                                            <span>{{ statusStrings[port.status] }} </span>
                                        </span>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="subports">
                                    <td mat-cell *matCellDef="let port"></td>
                                </ng-container>

                                <ng-container matColumnDef="comments">
                                    <td mat-cell *matCellDef="let port">
                                        {{ port.comments === 'null' ? ' ' : port.comments }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="lastUpdated">
                                    <td mat-cell *matCellDef="let port">
                                        {{ port.lastModDate | momentDate : 'M/D/YYYY' : false : true }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="zonePage">
                                    <td mat-cell *matCellDef="let port"></td>
                                </ng-container>

                                <ng-container matColumnDef="toggle">
                                    <td mat-cell *matCellDef="let port"></td>
                                </ng-container>
                            </table>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="columns"></tr>
                    <tr
                        mat-row
                        *matRowDef="let row; columns: columns; let odd = odd"
                        (click)="toggleActiveReport(row)"
                        class="clickable hoverable"
                        [class.selected-row]="isActiveEvent(row)"></tr>
                    <tr
                        mat-row
                        *matRowDef="let row; columns: ['expandedDetail']"
                        class="expandable-row"
                        [class.expanded]="isActiveEvent(row)"></tr>
                </table>
                <mat-paginator [pageSizeOptions]="[25, 50, 75, 100]" showFirstLastButtons></mat-paginator>
            </div>
            <div class="table-attribution">
                <a (click)="reportService.openAttribution(attributionUrl)" [innerHTML]="attributionTitle | sanitize"></a>
            </div>
        </mat-card>
        <div class="auto-mask hide-mask" *ngIf="showMask || ports.length === 0">
            <span class="mask-message" [class.no-data]="dataLoaded">{{ getMaskText() }}</span>
        </div>
    </div>
</ng-template>

<mat-menu #menu="matMenu" overlapTrigger>
    <button (click)="exportTable()" mat-menu-item>Export Table As CSV</button>
</mat-menu>
