
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import { GenOutageSnapshot } from './GenOutageSnapshot';
import * as moment from 'moment';

export class GenOutageSnapshotsForDate {

    outageSnapshotList: GenOutageSnapshot[];
    runStartTime: moment.Moment;

    constructor();
    constructor( jsonObj: any );
    constructor( genOutageSnapshotsForDate: GenOutageSnapshotsForDate);

    constructor( input?: GenOutageSnapshotsForDate ) {
        if ( input && typeof input !== 'number' ) {
            if ( input.outageSnapshotList && input.outageSnapshotList.map ) { // list [GenOutageSnapshot]
                this.outageSnapshotList = input.outageSnapshotList.map( ( x: GenOutageSnapshot ) => {
                    return new GenOutageSnapshot( x );
                } );
            } else {
                this.outageSnapshotList = input.outageSnapshotList;
            }
            if ( input.runStartTime && typeof input.runStartTime === 'string' ) {
                this.runStartTime = moment(input.runStartTime).utc();
            } else {
                this.runStartTime = input.runStartTime;
            }
       }
    }

    static jsonResponseToList(arr: any[]): GenOutageSnapshotsForDate[] {
        if (arr && arr.map) {
          return arr.map(a => new GenOutageSnapshotsForDate(a));
        } else {
          return [];
        }
    }
}

