import {Component} from '@angular/core';
import {BasePopover} from '../base-popover';
import {OngOilOutage} from '../../classes/ong-oil-outage';

@Component({
    selector: 'eaglei-ong-oil-outage-popover',
    templateUrl: './ong-oil-outage-popover.component.html',
    styleUrls: ['./ong-oil-outage-popover.component.scss', '../layer-popover.scss'],
})
export class OngOilOutagePopoverComponent extends BasePopover {
    public currentOutageIndex: number;
    public currentOutage: OngOilOutage;
    public currentOutageType: string;

    constructor() {
        super();
    }

    onInit() {}

    updateData() {
        if (this.data.planned_outages.length > 0) {
            this.currentOutageIndex = 0;
            this.currentOutage = new OngOilOutage(this.data.planned_outages[0]);

            switch (this.currentOutage.status) {
                case 'Planned':
                case 'Rescheduled':
                case 'Specified': {
                    this.currentOutageType = 'Future';
                    break;
                }
                case 'In Progress': {
                    this.currentOutageType = 'Active';
                    break;
                }
                case 'Complete': {
                    this.currentOutageType = 'Past';
                    break;
                }
                default: {
                    this.currentOutageType = 'Other';
                    break;
                }
            }
        }
    }

    public isOutageFirst(): boolean {
        return this.currentOutageIndex === 0;
    }

    public isOutageLast(): boolean {
        return this.currentOutageIndex === this.data?.planned_outages?.length - 1;
    }

    public nextOutage(): void {
        this.currentOutageIndex += 1;
        this.currentOutage = this.data.planned_outages[this.currentOutageIndex];
    }

    public previousOutage(): void {
        this.currentOutageIndex -= 1;
        this.currentOutage = this.data.planned_outages[this.currentOutageIndex];
    }

    public isEllipsis(element: HTMLElement): boolean {
        return element.offsetWidth < element.scrollWidth;
    }

    afterInit() {}
}
