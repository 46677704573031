import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Report} from '../../../app/modules/report/classes/report';
import {ResizeService} from '../../services/resize.service';
import {MapChartsComponent} from '../../../app/modules/map/components/map-charts/map-charts.component';
import {filter} from 'rxjs/operators';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {ColumnDef} from '../../classes/column-def';
import {FileDownload} from '../../../app/classes/file-download';
import {MapService} from '../../../app/modules/map/services/map.service';

@Component({
    selector: 'eaglei-data-table',
    templateUrl: './data-table.component.html',
    styleUrls: ['./data-table.component.scss'],
})
export class DataTableComponent extends Report implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

    // Table Properties
    public columnNames: string[] = [];
    public dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
    public columnDefs: ColumnDef[] = [];
    public defaultSortDirection: 'asc' | 'desc' = 'desc';
    public defaultSort: string = '';

    constructor(private resizeService: ResizeService) {
        super();
    }

    ngOnInit() {
        MapChartsComponent.data.pipe(filter((d) => !!d)).subscribe((data) => {
            this.attributionUrl = data.attributionUrl;

            this.columnDefs = data.columnDefs;
            this.columnNames = this.columnDefs.map((cd) => cd.columnName);

            const defaultSort = this.columnDefs.find((cd) => cd.defaultSort);

            if (defaultSort) {
                this.defaultSortDirection = defaultSort.sortDirection;
                this.defaultSort = defaultSort.columnName;
            }

            this.initializeData(data.tableData);
        });
    }

    ngAfterViewInit(): void {}

    ngOnDestroy(): void {}

    private initializeData(data: any[]): void {
        this.dataSource = new MatTableDataSource<any>(data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sortingDataAccessor = this.sortingLogic.bind(this);

        // Using timeout to separate the instruction so the default sort is fired
        setTimeout(() => {
            this.dataSource.sort = this.sort;
        }, 0);
    }

    private sortingLogic(data: any, header: string): string | number {
        const cd = this.columnDefs.find((c) => c.columnName === header);
        return cd.sortValue(data);
    }

    public exportTable(): void {
        let data = '';

        data = this.columnDefs.map((cd) => cd.displayName).join();
        data += '\n';

        this.dataSource._orderData(this.dataSource.filteredData).forEach((val) => {
            data += this.columnDefs.map((cd) => FileDownload.formatCsvCell(cd.getValue(val).toString())).join();
            data += '\n';
        });

        FileDownload.downloadCSV('layerInfo', data, this.attributionUrl);
    }

    public close() {
        this.resizeService.closeResizeElement.next(undefined);
        MapService.mapRef.invalidateSize(true);
    }
}
