import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {ApplicationConfig} from 'frontend/src/app/classes/application-config';
import {FileDownload} from 'frontend/src/app/classes/file-download';
import {LandingFilters} from 'frontend/src/app/landing/classes/landing-filters';
import {CustomLandingLayoutComponent} from 'frontend/src/app/landing/components/custom-landing-layout/custom-landing-layout.component';
import {MomentDatePipe} from 'frontend/src/shared/pipes/moment-date.pipe';
import * as moment from 'moment';
import {filter, takeUntil} from 'rxjs/operators';
import {State} from '../../../outage/classes/state';
import {BaseWidget} from '../../classes/base-widget';
import {WidgetService} from '../../services/widget.service';
import {OngService} from '../../../../services/ong.service';
import {EventSummaryFilters} from '../../../system-event/classes/event-summary-filters';
import {dailyPowerEstimateWidget, derivedStorageWidget} from '../../../../../integrations/ong/classes/ong-attributions-text';

interface SummaryList {
    collection_date: moment.Moment;
    country: string;
    county: string;
    gas_date: moment.Moment;
    id: number;
    inventory: number;
    inventory_change: number;
    location_name: string;
    location_role_id: number;
    meter: string;
    quality_rating: number;
    role: string;
    state: string;
}

@Component({
    selector: 'eaglei-derived-storage-widget',
    templateUrl: './derived-storage-widget.component.html',
    styleUrls: ['./derived-storage-widget.component.scss'],
})
export class DerivedStorageWidgetComponent extends BaseWidget implements AfterViewInit {
    private momentPipe = new MomentDatePipe();

    // HTML Element Properties
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

    // Filters
    public selectedStates: State[] = [];
    public searchText: string;

    // Summary List
    public summaryList: SummaryList[] = [];
    public filteredSummaryList: SummaryList[] = [];
    private filters: LandingFilters;

    // Summary Items Configurations
    private limitOfItems: number = 0;
    private alignment = 'space-around space-between';
    public isLoading = false;
    private now = moment();

    constructor(private widgetService: WidgetService, private ongService: OngService, private widgetElement: ElementRef) {
        super(widgetElement);
        this.attributionUrl = 'https://www.woodmac.com/';
        this.attributionText = 'Genscape: A Wood Mackenzie Business';

        this.getPreferences();
    }

    protected handleEventFilterChange(eventFilters: EventSummaryFilters): void {
        this.updateState(eventFilters.locations);
    }

    public ngAfterViewInit(): void {
        CustomLandingLayoutComponent.filterChange.pipe(takeUntil(this.destroy$)).subscribe((filters) => {
            this.filters = filters;
            this.buildSummary();
        });

        WidgetService.resize
            .pipe(
                filter((widget) => this.item.x === widget.x && this.item.y === widget.y),
                takeUntil(this.destroy$)
            )
            .subscribe((item) => {
                this.resize();

                setTimeout(() => {
                    this.alignment =
                        item.cols - (ApplicationConfig.onPhone() ? 2 : 1) >= 2 ? 'space-around space-around' : 'space-around space-between';
                    this.limitOfItems = ApplicationConfig.onPhone() ? 2 : Math.max(item.rows - 1, 1) * Math.max(item.cols - 2, 1);
                    this.paginator.pageSize = ApplicationConfig.onPhone() ? 2 : this.limitOfItems;
                }, 0);
            });
        this.attributionModalText = derivedStorageWidget;
        this.widgetName = 'Derived Storage';
        WidgetService.renderWidget.pipe(takeUntil(this.destroy$)).subscribe(() => this.buildSummary());
    }

    destroyWidget(): void {}

    private buildSummary(): void {
        this.summaryList = [];
        this.isLoading = true;
        this.buildStorageSummary();
    }

    /**
     * Builds a Storage Summary List
     */
    private buildStorageSummary(): void {
        this.ongService.getDerivedStorage().subscribe((res) => {
            this.summaryList = res.filter((x) => x.state != null); // remove null states
            this.summaryList = this.summaryList.filter(
                (x) => this.momentPipe.transform(x.gas_date, 'M/D/YYYY') === this.now.format('M/D/YYYY')
            ); // only show today's data
            this.summaryList = this.summaryList.sort((a, b) => (a.location_name.toLowerCase() > b.location_name.toLowerCase() ? 1 : -1));

            this.filterSummaryList();

            this.isLoading = false;
        });
    }

    // Filter Methods
    /**
     * Called when the states are updated, filters data.
     * @param states The states array
     */
    public updateState(states: State[]): void {
        this.selectedStates = states;
        this.filterSummaryList();
    }

    private getPreferences() {
        const preferences = ApplicationConfig.currentUserPreferences.getValue();
        this.selectedStates = preferences.getStates();
    }

    /**
     * Checks to see if there is text in the search field
     */
    public isSearchTextActive(): boolean {
        return this.summaryList && this.searchText && this.searchText.trim().length > 0;
    }

    /**
     * Called when the searched bar is changed. Updates the filter property on the dataSource
     * @param text The text being searched for.
     */
    public filterTitles(text: string): void {
        text = text === '' ? ' ' : text;
        this.searchText = text;
        this.filterSummaryList();
    }

    /**
     * Updates the table and chart data based on the filters slected
     * Should be called after each filter is changed
     */
    public filterSummaryList(): void {
        let data = this.summaryList;

        if (this.searchText && this.searchText.trim().length > 0) {
            data = this.summaryList.filter((e) => e.location_name.toLowerCase().includes(this.searchText.toLowerCase()));
        }

        // tslint:disable-next-line:prefer-conditional-expression
        if (this.summaryList && this.selectedStates.length > 0) {
            this.filteredSummaryList = data.filter((e) => this.selectedStates.find((s) => s.name === e.state));
        } else {
            this.filteredSummaryList = [];
        }

        this.paginator.length = this.filteredSummaryList.length;
        this.paginator.firstPage();
    }

    /**
     * Checks the direction of the given list object trend
     * @param listObject The List object to check the trend of
     * @param direction The direction of the trend to check
     */
    public checkTrend(listObject: any, direction: 'up' | 'down'): boolean {
        if (direction === 'up') {
            return Math.sign(listObject.inventory_change) === 1 || listObject.inventory_change === 0;
        } else if (direction === 'down') {
            return Math.sign(listObject.inventory_change) === -1;
        }

        return false;
    }

    /**
     * Creates the table pagination
     */
    public getPaginatorValues(): any[] {
        if (!this.paginator) {
            console.warn('paginator not initialized');
        }

        const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
        const endIndex = Math.min(startIndex + this.paginator.pageSize, this.filteredSummaryList.length);

        return this.filteredSummaryList.slice(startIndex, endIndex);
    }

    /**
     * Exports the table as a CSV File
     */
    public exportTable(): void {
        let data: string = ['Location', 'Inventory Change (BTU)', 'Inventory (BTU)', 'County', 'State', 'Quality Rating'].join() + '\n';
        this.filteredSummaryList.forEach((alert) => {
            const info = [
                FileDownload.formatCsvCell(alert.location_name),
                FileDownload.formatCsvCell(alert.inventory_change),
                FileDownload.formatCsvCell(alert.inventory),
                FileDownload.formatCsvCell(alert.county),
                FileDownload.formatCsvCell(alert.state),
                FileDownload.formatCsvCell(alert.quality_rating),
            ];
            data += `${info.join()}\n`;
        });

        FileDownload.downloadCSV('derivedStorageWidget', data, this.attributionUrl);
    }

    /**
     * Only shows tooltip if element is ellipsis.
     */
    public isEllipsis(element: HTMLElement): boolean {
        return element.offsetWidth < element.scrollWidth;
    }
}
