
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

export class GenSystemEventAnalytic {
    static readonly HEADOUT: GenSystemEventAnalytic = new GenSystemEventAnalytic('HEADOUT');
    static readonly WAO: GenSystemEventAnalytic = new GenSystemEventAnalytic('WAO');
    static readonly URBANNET: GenSystemEventAnalytic = new GenSystemEventAnalytic('URBANNET');
    static readonly PNNL: GenSystemEventAnalytic = new GenSystemEventAnalytic('PNNL');

    public readonly name: string;

    private constructor(name: string) {
        this.name = name;
    }

    static forName(name: string) {
        if (name === 'HEADOUT') return GenSystemEventAnalytic.HEADOUT;
        if (name === 'WAO') return GenSystemEventAnalytic.WAO;
        if (name === 'URBANNET') return GenSystemEventAnalytic.URBANNET;
        if (name === 'PNNL') return GenSystemEventAnalytic.PNNL;
        throw new Error('Unexpected value for GenSystemEventAnalytic:' + name);
    }

    static values(): GenSystemEventAnalytic[] {
        return [
            GenSystemEventAnalytic.HEADOUT,
            GenSystemEventAnalytic.WAO,
            GenSystemEventAnalytic.URBANNET,
            GenSystemEventAnalytic.PNNL,
        ];
    }

    toJSON(): string {
        return this.name;
    }

    toString(): string {
        return this.name;
    }
}

