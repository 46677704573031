import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'replaceCharacter',
})
export class ReplaceCharacterPipe implements PipeTransform {
    transform(value: string, strToReplace: string = '_', replacementStr: string = ' '): string {
        if (!strToReplace) {
            strToReplace = '_'; // default
        }

        if (!replacementStr) {
            replacementStr = ' '; // default
        }

        return value ? value.replace(new RegExp(strToReplace, 'g'), replacementStr) : value;
    }
}
