import {Component, ViewChild} from '@angular/core';
import {EagleILayer} from '../../classes/eagle-i-layer';
import {LeafletNomSource} from '../../sources/leaflet-nom-source';
import {LayerService} from '../../services/layer.service';
import {LeafletNomFilter} from '../../filters/leaflet-nom-filter';
import {GenOutageAggregationLevel} from '../../../../../../generated/serverModels/GenOutageAggregationLevel';
import {tap} from 'rxjs/operators';
import {HistoricalOutageData} from '../../classes/historical-outage-data';
import {ApplicationConfig} from '../../../../classes/application-config';
import {MapService} from '../../../map/services/map.service';
import {ResizeService} from '../../../../../shared/services/resize.service';
import {ColumnDef} from '../../../../../shared/classes/column-def';
import {DecimalPipe} from '@angular/common';
import {IResizerData} from '../../../map/interfaces/resizer-data.interface';
import {NomLegendComponent} from '../../../../../shared/components/nom-legend/nom-legend.component';
import {LegendElement} from '../../classes/legend-element';
import {LeafletLayerLegend} from '../../classes/leaflet-layer-legend';
import {MapChartsComponent} from '../../../map/components/map-charts/map-charts.component';
import {HttpInterceptorService} from '../../../../services/http-interceptor.service';
import {State} from '../../../outage/classes/state';
import {MatSelectChange} from '@angular/material/select';
import {NomChartConfig} from '../../../../../shared/interfaces/nom-chart-config.interface';

@Component({
    selector: 'eaglei-leaflet-nom-layer',
    templateUrl: './leaflet-nom-layer.component.html',
    styleUrls: ['./leaflet-nom-layer.component.scss'],
})
export class LeafletNomLayerComponent extends EagleILayer<LeafletNomSource> {
    constructor(
        private layerService: LayerService,
        // private dataService: DataService,
        private mapService: MapService,
        private resizeService: ResizeService // private chartDataService: ChartDataService
    ) {
        super();
        this.filters.runStartTime = ApplicationConfig.roundMinute();

        this.getUserPreferences();
    }

    @ViewChild(NomLegendComponent) nomLegendComponent: NomLegendComponent;

    private readonly chartInterceptorKey = 'nomLayerChartCall';

    public readonly aggregationLevels = GenOutageAggregationLevel.values().filter(
        (l) => ![GenOutageAggregationLevel.fema, GenOutageAggregationLevel.utility].includes(l)
    );
    public filters: LeafletNomFilter = new LeafletNomFilter();
    public filtersExpanded: boolean;
    public selectedStates: State[];

    public currentResizerData: IResizerData<HistoricalOutageData> = {
        tableData: [],
        columnDefs: [],
        chartConfig: undefined,
    };

    private numberPipe = new DecimalPipe('en-us');
    // private percentPipe = new PercentPipe('en-us');
    // private momentPipe = new MomentDatePipe();
    // private femaPipe = new NumeralPipe();

    protected readonly GenOutageAggregationLevel = GenOutageAggregationLevel;

    afterViewInit() {}

    private getLegend(): void {
        if (this.legend.length === 0) {
            const ele = new LegendElement();
            const legend = new LeafletLayerLegend(this.mapLayer.id, this.mapLayer.displayName);
            ele.html = this.nomLegendComponent.ele.nativeElement.innerHTML;
            legend.elements = [ele];
            this.legend = [legend];
        }
    }

    public toggleLayer(activeState: boolean, zoomToFeatures: boolean = false): void {
        if (activeState) {
            if (!this.layerSource) {
                this.layerSource = new LeafletNomSource(this.mapLayer);
                // we always want to zoom on first load.
                zoomToFeatures = true;
            }

            this.getOutageData(zoomToFeatures);
        } else {
            HttpInterceptorService.clearInterceptor(this.mapLayer.uiHandle);
            HttpInterceptorService.clearInterceptor(this.chartInterceptorKey);
            // This will need to be updated to only close when NOM is active in the resizer
            if (this.resizeService.resizeElementOpen.getValue()) {
                this.resizeService.closeResizeElement.next(undefined);
            }
            this.layerSource.removeFromMap();
        }
    }

    // API Calls
    private getOutageData(zoomToFeatures: boolean = true): void {
        this.mapLayer.startLoading();

        this.filters.runStartTime = this.mapService.mapDateRange.getValue().endDate;

        // If we are looking at the zipcode, we want to refetch the tile data instead of the outage data
        if (this.filters.aggregationLevel === GenOutageAggregationLevel.zip) {
            this.layerSource.fetchNomTiles(this.mapService.mapDateRange.getValue().endDate);
            this.resizeService.closeResizeElement.next(undefined);
            return;
        }

        HttpInterceptorService.clearInterceptor(this.mapLayer.uiHandle);
        HttpInterceptorService.pendingRequests[this.mapLayer.uiHandle] = this.layerService
            .getHistoricalOutageData(this.filters)
            .pipe(
                tap({
                    next: () => HttpInterceptorService.deleteFromInterceptor(this.mapLayer.uiHandle),
                    error: () => HttpInterceptorService.deleteFromInterceptor(this.mapLayer.uiHandle),
                })
            )
            .subscribe({
                next: (outageData: HistoricalOutageData[]) => {
                    this.layerSource.processFeatures(outageData);
                    this.updateLocationFilter(zoomToFeatures);
                    this.getLegend();
                    this.mapLayer.endLoading();
                    this.mapService.nomLoadedData.next(this.layerSource.getFeatures());
                    this.layerSource.addToMap();
                },
                error: (error: any) => {
                    const message = error?.error?.userMessage || 'Nom layer not loaded';
                    this.mapLayer.endLoading(true);
                    console.error(message, error);
                },
            });
    }

    // Aggregation Level Methods
    public updateAggregation(change: MatSelectChange) {
        this.filters.aggregationLevel = change.value;

        this.layerSource.removeFromMap();
        this.getOutageData(false);
    }

    private updateLocationFilter(zoomToFeatures: boolean = true): void {
        this.mapService.nomActiveStates.next(this.selectedStates);

        this.layerSource.updateLocationFilter(this.selectedStates, zoomToFeatures);

        this.mapService.nomLoadedData.next(this.layerSource.getFeatures());
        if (this.resizeService.resizeElementOpen.getValue()) {
            this.toggleChart(true);
        }
    }

    // User Preference Methods
    private getUserPreferences(): void {
        const preferences = ApplicationConfig.currentUserPreferences.getValue();
        this.filters.aggregationLevel = preferences.getOutageAggregationLevel();
        this.selectedStates = preferences.getStates();
    }

    public toggleChart(ignoreOpen: boolean = false) {
        if (!ignoreOpen) {
            this.resizeService.openResizeElement.next(undefined);
        }
        this.processResizerTable();
        this.processResizerChart();
    }

    private emitResizeData() {
        MapChartsComponent.data.next(this.currentResizerData);
    }

    private processResizerChart(): void {
        const dates = this.mapService.mapDateRange.getValue();

        // noinspection UnnecessaryLocalVariableJS
        const chartConfig: NomChartConfig = {
            aggregationLevel: this.filters.aggregationLevel,
            startDate: dates.startDate,
            endDate: dates.endDate,
            states: this.selectedStates,
        };

        this.currentResizerData.chartConfig = chartConfig;
        this.currentResizerData.attributionUrl = this.mapLayer.attributionUrl;
        this.currentResizerData.attributionText = this.mapLayer.attributionTitle;
        this.emitResizeData();
    }

    private processResizerTable(): void {
        const data = this.layerSource.getFeatures();

        const locationName =
            this.filters.aggregationLevel === GenOutageAggregationLevel.county
                ? 'County'
                : this.filters.aggregationLevel === GenOutageAggregationLevel.zip
                ? 'Zip'
                : 'State';

        const getName = (val: HistoricalOutageData) => {
            return val.countyName ? `${val.countyName} (${val.stateName})` : val.title;
        };

        const outageDisplay = (outage: number) => {
            return isNaN(outage) ? 'Not Available' : this.numberPipe.transform(outage);
        };

        const columnDefs: ColumnDef[] = [];
        columnDefs.push(new ColumnDef(locationName, getName).sort((val: HistoricalOutageData) => getName(val).toLowerCase()));
        columnDefs.push(
            new ColumnDef('Customers Out', (val: HistoricalOutageData) => `${outageDisplay(val.currentOutage).toString()}`)
                .sort((val: HistoricalOutageData) => val.currentOutage)
                .setDefaultSort()
                .setCellColor((val: HistoricalOutageData) =>
                    val.currentOutageHasOverrideData && ApplicationConfig.showOverrideColor.getValue()
                        ? ApplicationConfig.currentUserPreferences.getValue().getOverrideColor()
                        : ''
                )
        );
        columnDefs.push(
            new ColumnDef('Last Hour Max Outage', (val: HistoricalOutageData) => `${outageDisplay(val.maxOutage1).toString()}`)
                .sort((val: HistoricalOutageData) => val.maxOutage1)
                .setCellColor((val: HistoricalOutageData) =>
                    val.maxOutage1HasOverrideData && ApplicationConfig.showOverrideColor.getValue()
                        ? ApplicationConfig.currentUserPreferences.getValue().getOverrideColor()
                        : ''
                )
        );
        columnDefs.push(
            new ColumnDef('Last Day Max Outage', (val: HistoricalOutageData) => `${outageDisplay(val.maxOutage24).toString()}`)
                .sort((val: HistoricalOutageData) => val.maxOutage24)
                .setCellColor((val: HistoricalOutageData) =>
                    val.maxOutage24HasOverrideData && ApplicationConfig.showOverrideColor.getValue()
                        ? ApplicationConfig.currentUserPreferences.getValue().getOverrideColor()
                        : ''
                )
        );

        this.currentResizerData.tableData = data;
        this.currentResizerData.columnDefs = columnDefs;
    }

    public useMobileLayout(): boolean {
        return ApplicationConfig.useMobileLayout();
    }

    public onLite(): boolean {
        return ApplicationConfig.onLite();
    }

    public filterStates(states: State[]) {
        this.selectedStates = states;
        this.updateLocationFilter();
    }

    public getCountyCustomerText(info: any): string {
        return !!info.totalCustomers || !!info.total ? `(${info.modelCount > 0 ? 'Modeled' : 'Collected'})` : '';
    }
}
