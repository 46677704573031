import {Component} from '@angular/core';
import {FormGroup, FormControl} from '@angular/forms';
import {GenLegendType} from 'frontend/generated/serverModels/GenLegendType';
import {HttpInterceptorService} from 'frontend/src/app/services/http-interceptor.service';
import {tap} from 'rxjs/operators';
import {EagleILayer} from '../../classes/eagle-i-layer';
import {LayerService} from '../../services/layer.service';
import {PoleDetectionSource} from '../../sources/pole-detection-source';

@Component({
    selector: 'eaglei-pole-detection-layer',
    templateUrl: './pole-detection-layer.component.html',
    styleUrls: ['../layers.scss', './pole-detection-layer.component.scss'],
})
export class PoleDetectionLayerComponent extends EagleILayer<PoleDetectionSource> {
    // Filter Properties
    public filterControlGroup: FormGroup = new FormGroup({
        infrastructure: new FormControl('all'),
        status: new FormControl('all'),
    });

    private allFeatures: any[] = [];

    constructor(private layerService: LayerService) {
        super();
    }

    afterViewInit(): void {
        this.mapLayer.legend.type = GenLegendType.HARD_CODED;
    }

    public toggleLayer(activeState: boolean, zoomToFeatures?: boolean): void {
        this.isActive = activeState;

        if (activeState) {
            if (!this.layerSource) {
                this.layerSource = new PoleDetectionSource(this.mapLayer);
            }

            this.getPoleDetection();
        } else {
            HttpInterceptorService.clearInterceptor(this.mapLayer.uiHandle);
            this.layerSource.removeFromMap();
        }
    }

    /**
     * Gets the Pole Detection Data and loads onto map
     */
    private getPoleDetection(): void {
        const url = this.mapLayer.serviceurl;

        this.mapLayer.startLoading();
        HttpInterceptorService.clearInterceptor(this.mapLayer.uiHandle);
        HttpInterceptorService.pendingRequests[this.mapLayer.uiHandle] = this.layerService
            .getJsonFromUrl(url)
            .pipe(
                tap(() => {
                    HttpInterceptorService.deleteFromInterceptor(this.mapLayer.uiHandle);
                    this.mapLayer.endLoading();
                })
            )
            .subscribe((poles) => {
                this.allFeatures = [...poles];
                this.layerSource.processFeatures(poles);
                this.layerSource.addToMap();
            });
    }

    /**
     * Updates the Filter to display poles
     */
    public updateFilter(): void {
        const filtered = this.allFeatures.filter((f) => {
            const infra = this.filterControlGroup.get('infrastructure');
            const status = this.filterControlGroup.get('status');
            const infraCheck =
                infra.value === 'pole'
                    ? f.infrastructure === 'UTILITY_POLE'
                    : infra.value === 'substation'
                    ? f.infrastructure === 'SUBSTATION'
                    : true;
            const damageCheck = status.value === 'damaged' ? f.damaged === true : status.value === 'undamaged' ? f.damaged === false : true;

            return infraCheck && damageCheck;
        });

        this.layerSource.processFeatures(filtered);
        this.layerSource.addToMap(true);
    }
}
