<div mat-dialog-title class="header">
    <span class="title">Add Documents</span>
    <button mat-icon-button matDialogClose>
        <mat-icon fontSet="fas" fontIcon="fa-times"></mat-icon>
    </button>
</div>

<mat-dialog-content>
    <div class="table-container">
        <table
            mat-table
            class="test-design"
            [dataSource]="dataSource"
            matSort
            [matSortDisableClear]="true"
            matSortActive="change"
            matSortDirection="desc"
            multiTemplateDataRows>
            <ng-container matColumnDef="expand">
                <th mat-header-cell class="icon-column" *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let info" class="icon-column">
                    <mat-icon fontSet="fas" [fontIcon]="isExpanded(info) ? 'fa-chevron-up' : 'fa-chevron-down'" inline></mat-icon>
                </td>
            </ng-container>

            <ng-container matColumnDef="filename">
                <th mat-header-cell *matHeaderCellDef class="custom-cell filename">File Name</th>
                <td mat-cell *matCellDef="let info" class="custom-cell filename">
                    <p class="ellipsis">{{ info.filename }}</p>
                </td>
            </ng-container>

            <ng-container matColumnDef="displayName">
                <th *matHeaderCellDef mat-header-cell>Name</th>
                <td mat-cell *matCellDef="let info">
                    <div class="td-container">
                        <mat-form-field class="full-size" (click)="$event.stopPropagation()" (keyup)="validateFileName(info)">
                            <input matInput [(ngModel)]="info.displayName" />
                        </mat-form-field>
                        <mat-icon
                            fontSet="fas"
                            fontIcon="fa-info-circle"
                            class="invalid"
                            inline
                            *ngIf="info.hasErrors || info.sizeError"
                            [matTooltip]="getErrorMessage(info)"
                            matTooltipPosition="above"></mat-icon>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="event">
                <th *matHeaderCellDef mat-header-cell>Event</th>
                <td mat-cell *matCellDef="let info">
                    <div class="td-container">
                        <mat-form-field class="full-size filter-select" (click)="$event.stopPropagation()">
                            <mat-select multiple placeholder="Events" (selectionChange)="setEvents(info, $event)">
                                <mat-option *ngFor="let event of systemEventService.currentEvents | async" [value]="event">
                                    {{ event.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="remove">
                <th class="icon-column" *matHeaderCellDef mat-header-cell></th>
                <td class="icon-column text-right" mat-cell *matCellDef="let info">
                    <mat-icon
                        fontSet="fas"
                        fontIcon="fa-trash-alt"
                        [inline]="true"
                        class="invalid clickable"
                        (click)="removeDocument(info)"></mat-icon>
                </td>
            </ng-container>

            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let info" [attr.colspan]="columnNames.length">
                    <div class="hide-expanded-row" [class.show-expanded-row]="info === expandedDocument">
                        <div class="td-container">
                            <mat-form-field>
                                <textarea
                                    matInput
                                    matTextareaAutosize
                                    matAutosizeMaxRows="7"
                                    matAutosizeMinRows="7"
                                    placeholder="Description"
                                    [(ngModel)]="info.description">
                                </textarea>
                            </mat-form-field>
                        </div>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
            <tr mat-row *matRowDef="let row; columns: columnNames" (click)="toggleExpansion(row)"></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: ['expandedDetail']"
                class="expandable-row"
                [class.expanded]="row === expandedDocument"></tr>
        </table>

        <mat-paginator
            class="test-design"
            [hidden]="true"
            [pageSize]="10"
            [pageSizeOptions]="[25, 50, 75, 100]"
            showFirstLastButtons></mat-paginator>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-stroked-button matDialogClose>Cancel</button>
    <button mat-flat-button color="primary" (click)="saveDocuments()" [disabled]="!areFilesValid()">Save</button>
</mat-dialog-actions>
