import {Component, inject} from '@angular/core';
import {HeadoutSource} from '../../sources/headout-source';
import {LayerStyleService} from '../../services/layer-style.service';
import {EagleILayer} from '../../classes/eagle-i-layer';
import {GenLegendType} from '../../../../../../generated/serverModels/GenLegendType';
import {MatSliderChange} from '@angular/material/slider';
import {HeadoutMapControls} from '../../../../integrations/headout/classes/headout-base';
import {HeadoutWeatherSource} from '../../../../integrations/headout/classes/headout-weather-source';
import {SourceDisplayInfo} from '../../../../integrations/headout/interfaces/source-display-info.interface';
import {HeadoutService} from '../../../../integrations/headout/headout.service';
import {SystemEventService} from '../../../system-event/services/system-event.service';
import {SystemEventType} from '../../../system-event/classes/system-event-type';

@Component({
    selector: 'eaglei-headout-layer',
    templateUrl: './headout-layer.component.html',
    styleUrls: ['./headout-layer.component.scss'],
})
export class HeadoutLayerComponent extends EagleILayer<HeadoutSource> {
    private headoutService = inject(HeadoutService);
    public systemEventService = inject(SystemEventService);
    public styleService = inject(LayerStyleService);

    public eventTypes: SystemEventType[] = [];

    public mapControls = new HeadoutMapControls();

    // Filter Properties

    constructor() {
        super();
    }

    afterViewInit(): void {
        this.mapLayer.legend.type = GenLegendType.HARD_CODED;
    }

    toggleLayer(activeState: boolean, zoomToFeatures?: boolean): void {
        if (activeState) {
            if (!this.layerSource) {
                this.getEventTypes();

                this.mapControls.mapLayer = this.mapLayer;
                this.layerSource = new HeadoutSource(this.mapLayer, this.styleService);
                this.mapControls.layerSource = this.layerSource;

                this.headoutService.getMostRecentEvent().subscribe((event) => {
                    this.mapControls.setInitialEvent(event);
                });
            } else {
                this.mapControls.filterGroup.controls['advisory'].updateValueAndValidity();
            }
        } else {
            this.mapControls.resetForm(false, false);
            this.layerSource.removeFromMap();
        }
    }

    public getEventTypes(): void {
        this.systemEventService.getEventTypes().subscribe((types) => {
            this.eventTypes = types;
        });
    }

    public changeSubLayerOpacity(event: MatSliderChange, subLayer: string): void {
        const source = this.mapControls.infrastructureSources[subLayer];

        if (source) {
            source.layerInfo.opacity = event.value;
            source.changeOpacity(event.value / 100);
        }
    }

    public toggleInfrastructureLayer(checked: boolean, layerInfo: any) {
        layerInfo.value.active = checked;

        const active = Object.keys(this.mapControls.eventInfrastructures)
            .filter((key) => this.mapControls.eventInfrastructures[key].active)
            .map((key) => {
                return {key, value: this.mapControls.eventInfrastructures[key]};
            });

        this.mapControls.filterGroup.controls.infrastructure.setValue(active);
    }

    public subLayerOpacity(subLayer: string): number {
        const source = this.mapControls.infrastructureSources[subLayer];

        if (source) {
            return source.layerInfo.opacity;
        }

        return 0.8;
    }

    toggleWeatherLayer(checked: boolean, item: SourceDisplayInfo<HeadoutWeatherSource>) {
        item.active = checked;
        const currentlyActive = this.mapControls.weatherSources.filter((info) => info.active);
        this.mapControls.filterGroup.controls.weather.setValue(currentlyActive);
    }

    changeWeatherLayerOpacity(event: MatSliderChange, item: SourceDisplayInfo<HeadoutWeatherSource>) {
        item.source.layerInfo.opacity = event.value;
        item.source.changeOpacity(event.value / 100);
    }
}
