import {HeadoutTableData} from '../interfaces/headout-table-data.interface';
import {StateNamePipe} from '../../../../shared/pipes/state-name.pipe';

export const headoutTableColumnNames: string[] = ['state', 'county', 'customersOut', 'totalCustomers', 'percentOut'];

export const HeadoutTableSort = (data: HeadoutTableData, header: string) => {
    const statePipe = new StateNamePipe();
    switch (header) {
        case 'state':
            return statePipe.transform(data.state).toLowerCase();
        case 'county':
            return data.county.toLowerCase();
        case 'customersOut':
            return data.customersOut;
        case 'totalCustomers':
            return data.totalCustomers;
        case 'percentOut':
            return data.percentOut;
        default:
            return '';
    }
};
