import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AbstractControl, AsyncValidatorFn, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators} from '@angular/forms';
import {BaseModal} from '../../../../classes/base-modal';
import {of} from 'rxjs';
import {map} from 'rxjs/operators';
import {AuthenticationService} from '../../../../services/authentication.service';
import {UserService} from '../../services/user.service';
import {User} from '../../classes/user';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'eaglei-password-reset-modal',
    templateUrl: './password-reset-modal.component.html',
    styleUrls: ['./password-reset-modal.component.scss'],
})
export class PasswordResetModalComponent extends BaseModal implements OnInit {
    public passwordGroup: UntypedFormGroup;

    constructor(
        private dialogRef: MatDialogRef<PasswordResetModalComponent>,
        @Inject(MAT_DIALOG_DATA) private user: User,
        private auth: AuthenticationService,
        private userService: UserService,
        private popup: MatSnackBar
    ) {
        super();
    }

    ngOnInit() {
        const passwordControls = {
            password: new UntypedFormControl('', {
                updateOn: 'blur',
                validators: [Validators.required],
                asyncValidators: [this.newPasswordValid()],
            }),
            confirm: new UntypedFormControl('', {updateOn: 'change', validators: [Validators.required, this.passwordsMatch()]}),
        };
        this.passwordGroup = new UntypedFormGroup(passwordControls);
    }

    /**
     * Resets the users password and closes the modal on success
     */
    public resetPassword(): void {
        const success = () => {
            this.popup.open('Your password has been changed.', 'Okay', {duration: 5000, panelClass: 'dialog-success'});
            this.dialogRef.close(true);
        };

        const failure = (error: any) => {
            const text = error.error.userMessage || 'Failed to change password.';
            this.popup.open(text, 'Okay', {duration: 5000, panelClass: 'dialog-failure'});
        };
        this.userService.changePassword(this.user.id, this.passwordGroup.controls['password'].value).subscribe(success, failure);
    }

    /**
     * Checks to see if all the field in the form are valid.
     */
    public isFormValid(): boolean {
        return this.passwordGroup.valid;
    }

    /**
     * Async Validator Method that checkes the complexity of a users password as they type it in.
     */
    private newPasswordValid(): AsyncValidatorFn {
        return (control: AbstractControl) => {
            if (this.passwordGroup) {
                this.passwordGroup.controls['confirm'].updateValueAndValidity();
            }

            if (control.value) {
                return this.auth.checkPasswordComplexity(control.value).pipe(map((fault) => (fault ? {complexity: fault} : null)));
            }
            return of(null);
        };
    }

    /**
     * Validator method that returns null if the passwords match and an error if they do not.
     */
    private passwordsMatch(): ValidatorFn {
        return (control: AbstractControl) => {
            const password = this.passwordGroup ? this.passwordGroup.controls['password'].value : undefined;
            if (!password) {
                return null;
            }

            if (control.value !== undefined) {
                return control.value === password ? null : {match: 'New passwords do not match'};
            }
            return null;
        };
    }

    // From Parent class
    afterInit() {}
}
