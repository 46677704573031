import {GenWidgetDetail} from '../../../../../generated/serverModels/GenWidgetDetail';
import {WidgetSetting} from './widget-setting';
import {Widget} from './widget';
import {LandingGridItem} from '../../../landing/classes/landing-grid-item';
import {GenWidgetSettingType} from '../../../../../generated/serverModels/GenWidgetSettingType';
import {GenCommunityPermission} from '../../../../../generated/serverModels/GenCommunityPermission';

export class WidgetDetail extends GenWidgetDetail {
    public settings: WidgetSetting[];
    public widget: Widget;

    constructor(widgetDetail?: WidgetDetail) {
        super(widgetDetail);

        if (widgetDetail) {
            this.settings = !!widgetDetail.settings ? widgetDetail.settings.map((s) => new WidgetSetting(s)) : [];

            if (widgetDetail.widget) {
                this.widget = new Widget(widgetDetail.widget);
            }
        } else {
            this.settings = [];
        }
    }

    public setWidget(widget: Widget): WidgetDetail {
        this.widget = widget;
        return this;
    }

    public setCommunityAccess(permissions: GenCommunityPermission[]): WidgetDetail {
        if (!this.widget) {
            this.setWidget(new Widget());
        }

        this.widget.setCommunityPermissions(permissions);

        return this;
    }

    public setWidgetSettings(settings: WidgetSetting | WidgetSetting[]): WidgetDetail {
        this.settings = Array.isArray(settings) ? settings : [settings];
        return this;
    }

    public fromGridItem(item: LandingGridItem): WidgetDetail {
        this.xPos = item.x;
        this.yPos = item.y;
        this.height = item.rows;
        this.width = item.cols;

        this.widget.minWidth = item.minItemCols;
        this.widget.maxWidth = item.maxItemCols;
        this.widget.minHeight = item.minItemRows;
        this.widget.maxHeight = item.maxItemRows;
        this.widget.name = item.widgetName;

        this.setWidget(item.detail.widget);

        return this;
    }

    public toGridItem(): LandingGridItem {
        return {
            x: this.xPos,
            y: this.yPos,
            rows: this.height,
            cols: this.width,

            minItemCols: this.widget.minWidth,
            maxItemCols: this.widget.maxWidth,
            minItemRows: this.widget.minHeight,
            maxItemRows: this.widget.maxHeight,
            widgetName: this.widget.name,

            detail: this,
            configurable: this.widget.configurable,
        };
    }

    public getSettingByType(type: GenWidgetSettingType, defaultValue: any): WidgetSetting {
        let setting = this.settings.find((s) => s.type === type);
        if (setting) {
            return setting;
        }

        setting = new WidgetSetting();
        setting.type = type;
        setting.value = defaultValue;
        return setting;
    }
}
