import {Component, ElementRef, ViewChild} from '@angular/core';
import {BasePopover} from '../base-popover';
import * as echarts from 'echarts';
import * as moment from 'moment';
import {DataPoint} from '../../../../classes/data-point';
import {PopoverElement} from 'frontend/src/app/classes/popover-element';
import {Report} from '../../../report/classes/report';
import {EagleiBaseChart} from 'frontend/src/app/classes/charts/base-chart';
import {IGasBurn} from 'frontend/src/app/interfaces/ong/gas-burn.interface';

interface LineChartData<T = any> {
    points: DataPoint<T>[];
}

@Component({
    selector: 'eaglei-ong-gas-burn-popover',
    templateUrl: './ong-gas-burn-popover.component.html',
    styleUrls: ['./ong-gas-burn-popover.component.scss', '../layer-popover.scss'],
})
export class OngGasBurnPopoverComponent extends BasePopover<IGasBurn> {
    public popoverData: PopoverElement[] = [];

    @ViewChild('gasChartTarget') set gasChartTarget(target: ElementRef<HTMLDivElement>) {
        if (target) {
            this.initChart(target);
            this.renderChart();
        }
    }

    @ViewChild('popoverContainerElement') popoverContainerElement: ElementRef<HTMLElement>;

    public baseChart = new EagleiBaseChart<any, any, any>();
    private readonly chartColor = [
        {offset: 0, color: 'rgba(157,18,11,0.25)'},
        {offset: 1, color: 'rgb(255, 255, 255)'},
    ];

    constructor(private ele: ElementRef<HTMLElement>) {
        super();
    }

    onInit() {}

    public updateData(initialData): void {
        if (!initialData) {
            this.renderChart();
        }
    }

    private initChart(target: ElementRef<HTMLDivElement>): void {
        this.baseChart.interactivePopover = false;
        this.baseChart.initializeEChart(target.nativeElement);

        const mouseOver = (event) => {
            const [x, y] = [event.event.offsetX, event.event.event.clientY - this.ele.nativeElement.getBoundingClientRect().y];
            const top = y + 10;

            this.popoverData = event.data.popoverData.map((ele) => new PopoverElement().copyFrom(ele));

            this.popoverContainerElement.nativeElement.style.position = 'absolute';
            this.popoverContainerElement.nativeElement.style.zIndex = '1001';
            this.popoverContainerElement.nativeElement.style.top = top + 'px';

            requestAnimationFrame(() => {
                const width = this.popoverContainerElement.nativeElement.getBoundingClientRect().width / 2;

                let left = x - width;

                const bodyRect = document.body.getBoundingClientRect();

                const overflowRight = x + width - bodyRect.width;
                const overflowLeft = x - width;
                if (overflowRight > 0) {
                    left = left - overflowRight - 5;
                } else if (overflowLeft < 0) {
                    left = left + Math.abs(overflowLeft) + 5;
                }

                this.popoverContainerElement.nativeElement.style.left = left + 'px';
                this.popoverContainerElement.nativeElement.classList.remove('invisible');
            });
        };

        const mouseOut = () => {
            this.popoverContainerElement.nativeElement.classList.add('invisible');
        };

        (this.baseChart.eChart as any).on('mouseover', 'series', mouseOver);
        (this.baseChart.eChart as any).on('mouseout', 'series', mouseOut);

        const opts = {
            grid: {
                top: 10,
                bottom: 60,
                left: 50,
                right: 30,
            },
            xAxis: {
                type: 'time',
                boundaryGap: false,
                splitLine: {
                    show: false,
                },
                axisLine: {
                    lineStyle: {color: '#808080'},
                },
                axisLabel: {
                    formatter: (date) => {
                        return moment(date).format('M/D h A');
                    },
                },
            },
            yAxis: {
                type: 'value',
                axisLine: {
                    lineStyle: {color: '#808080'},
                },
                axisLabel: {
                    formatter: (value) => {
                        return value;
                    },
                },
            },
        };

        Object.assign(this.baseChart.eChartOptions, opts);
    }

    private renderChart(): void {
        const details = this.data?.gas_burns;
        const line: LineChartData = {
            points: [],
        };

        const getPopoverData = (data: any) => {
            return [
                new PopoverElement('Title', moment(data.flow_timestamp).format('M/D/YY h A')),
                new PopoverElement('Flow (Mcf)', Report.numberPipe.transform(data.hourly_flow).toString()),
            ];
        };

        line.points = details
            .sort((a, b) => (moment(a.flow_timestamp).isBefore(moment(b.flow_timestamp)) ? -1 : 1))
            .map((d) => {
                const val = d.hourly_flow;

                return new DataPoint({
                    x: moment(d.flow_timestamp).valueOf(),
                    y: val,
                    color: '#9D120B',
                    popoverData: getPopoverData(d),
                });
            });

        this.baseChart.eChartOptions.xAxis['data'] = line.points.map((dp) => moment(dp.data.x).valueOf());

        const lineData = line.points.map((point) => {
            return {
                value: [point.data.x, point.data.y],
                popoverData: point.data.popoverData,
            };
        });
        this.baseChart.eChartOptions.series = [
            {
                name: 'Hourly Flow',
                type: 'line',
                // showSymbol: false,
                smooth: 0.5,
                lineStyle: {
                    color: '#9D120B',
                },
                areaStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0.5, 0, 1, this.chartColor) as any,
                },
                data: lineData,
            },
        ];
        this.baseChart.eChart.setOption(this.baseChart.eChartOptions, true);
    }

    afterInit() {}
}
