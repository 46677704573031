
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!


export class GenAlertHistory {

    activeInd: boolean;
    alertName: string;
    alertStart: string;
    alertType: string;
    customersOut: number;
    hoursActive: number;
    rollUp: boolean;

    constructor();
    constructor( jsonObj: any );
    constructor( genAlertHistory: GenAlertHistory);

    constructor( input?: GenAlertHistory ) {
        if ( input && typeof input !== 'number' ) {
            this.activeInd = input.activeInd; // boolean [boolean]
            this.alertName = input.alertName; // string [string]
            this.alertStart = input.alertStart; // string [string]
            this.alertType = input.alertType; // string [string]
            this.customersOut = input.customersOut; // int [number]
            this.hoursActive = input.hoursActive; // int [number]
            this.rollUp = input.rollUp; // boolean [boolean]
       }
    }

    static jsonResponseToList(arr: any[]): GenAlertHistory[] {
        if (arr && arr.map) {
          return arr.map(a => new GenAlertHistory(a));
        } else {
          return [];
        }
    }
}

