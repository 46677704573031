<div class="dialog-header">
    <span>Covered Utilities</span>
    <mat-icon fontSet="fas" fontIcon="fa-times" inline class="clickable" (click)="dialogRef.close()"></mat-icon>
</div>

<div class="dialog-content" #content>
    <div class="header">
        <mat-form-field appearance="outline">
            <mat-label>Filter Utility Names</mat-label>
            <input matInput (keyup)="filterUtilities($event.target.value)" />
            <mat-icon matSuffix class="fa fa-search"></mat-icon>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Coverage Status</mat-label>
            <mat-select [(ngModel)]="coverageStatus" (selectionChange)="filterUtilities(dataSource.filter)">
                <mat-option value="all">All</mat-option>
                <mat-option value="covered">Covered</mat-option>
                <mat-option value="notCovered">Not Covered</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="table-container" [class.invisible]="loading">
        <table
            #materialTable
            mat-table
            [dataSource]="dataSource"
            matSort
            [matSortDisableClear]="true"
            matSortActive="utilityName"
            matSortDirection="asc">
            <ng-container matColumnDef="utilityName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Utility Name
                    <eaglei-table-sort-header [columnName]="'utilityName'" [sort]="sort"></eaglei-table-sort-header>
                </th>
                <td mat-cell *matCellDef="let info">{{ info.utilityName }}</td>
            </ng-container>

            <ng-container matColumnDef="utilityNumber">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Utility Number
                    <eaglei-table-sort-header [columnName]="'utilityNumber'" [sort]="sort"></eaglei-table-sort-header>
                </th>
                <td mat-cell *matCellDef="let info">{{ info.utilityNumber }}</td>
            </ng-container>

            <ng-container matColumnDef="state">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    State / Territory
                    <eaglei-table-sort-header [columnName]="'state'" [sort]="sort"></eaglei-table-sort-header>
                </th>
                <td mat-cell *matCellDef="let info">{{ info.state }}</td>
            </ng-container>

            <ng-container matColumnDef="customerCovered">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Customers Covered
                    <eaglei-table-sort-header [columnName]="'customerCovered'" [sort]="sort"></eaglei-table-sort-header>
                </th>
                <td mat-cell *matCellDef="let info">{{ info.eiaTotalCustomers | number }}</td>
            </ng-container>

            <ng-container matColumnDef="covered">
                <th mat-header-cell *matHeaderCellDef>Coverage Status</th>
                <td mat-cell *matCellDef="let info">{{ info.coverageStatus }}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
            <tr mat-row *matRowDef="let row; columns: columnNames; let odd = odd"></tr>
        </table>
    </div>

    <mat-paginator [pageSizeOptions]="[25, 50, 75, 100]" showFirstLastButtons></mat-paginator>

    <div *ngIf="loading" class="loading-mask">
        <span>Loading Utilities...</span>
    </div>
</div>

<div class="dialog-actions">
    <button mat-raised-button color="primary" (click)="exportCsv()">Export</button>
</div>
