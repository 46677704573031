<form>
    <mat-form-field appearance="outline">
        <input
            #filter
            matInput
            type="text"
            aria-label="Number"
            [placeholder]="placeholder"
            [formControl]="control"
            [matAutocomplete]="auto"
            (keyup.enter)="addToList()" />
        <mat-hint *ngIf="helpHint">{{ helpHint }}</mat-hint>

        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFunction" (optionSelected)="selected($event)">
            <mat-option *ngFor="let option of filteredValues | async; let odd = odd" [value]="option">
                {{ displayFunction(option) }}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</form>

<mat-chip-listbox *ngIf="!hideChips" [multiple]="true">
    <mat-chip-option
        *ngFor="let element of selectedElements"
        [selected]="true"
        (click)="removeChip(element)"
        matTooltip="Remove"
        matTooltipShowDelay="250">
        {{ displayFunction(element) }}
    </mat-chip-option>
</mat-chip-listbox>
