import {GenWidgetName} from '../../../../../../../generated/serverModels/GenWidgetName';
import {WidgetDetail} from '../../../../widget/classes/widget-detail';
import {GenWidgetSettingType} from '../../../../../../../generated/serverModels/GenWidgetSettingType';

export const ngWidgets = [
    {
        x: 0,
        y: 0,
        cols: 2,
        rows: 1,
        widgetName: GenWidgetName.NG_NOTICES,
        detail: new WidgetDetail().setWidgetSettings({
            type: GenWidgetSettingType.NOTICES,
            value: 'critical',
        } as any),
    },
    {
        x: 2,
        y: 0,
        cols: 2,
        rows: 1,
        widgetName: GenWidgetName.NG_NOTICES,
        detail: new WidgetDetail().setWidgetSettings({
            type: GenWidgetSettingType.NOTICES,
            value: 'non_critical,planned_service_outage',
        } as any),
    },
    {
        x: 4,
        y: 0,
        rows: 1,
        cols: 2,
        widgetName: GenWidgetName.NG_RECEIPT_DELIVERY,
        detail: new WidgetDetail(),
    },
    {
        x: 6,
        y: 0,
        rows: 1,
        cols: 2,
        widgetName: GenWidgetName.NG_OPERATIONAL_FLOW,
        detail: new WidgetDetail(),
    },
    {
        x: 0,
        y: 1,
        rows: 3,
        cols: 8,
        minItemRows: 3,
        widgetName: GenWidgetName.NG_NOMINATION,
        detail: new WidgetDetail(),
    },
];
