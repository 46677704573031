import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    booleanAttribute,
} from '@angular/core';
import * as moment from 'moment';
import {AuthenticationService} from '../../../app/services/authentication.service';
import {GenRoleDefinition} from '../../../../generated/serverModels/GenRoleDefinition';
import {CardFilters} from '../../classes/card-filters';
import {AbstractControl, UntypedFormControl, ValidatorFn} from '@angular/forms';
import {MatDatepicker} from '@angular/material/datepicker';
import {MatFormFieldAppearance} from '@angular/material/form-field';
import {TimeStep} from '../../classes/time-step';
import {ApplicationConfig} from '../../../app/classes/application-config';

@Component({
    selector: 'eaglei-date-range',
    templateUrl: './date-range.component.html',
    styleUrls: ['./date-range.component.scss'],
})
export class DateRangeComponent implements OnInit, OnChanges {
    get maxEndDate(): Date {
        return this._maxEndDate;
    }

    private _maxEndDate = moment().toDate();
    private currentDates: CardFilters = new CardFilters();

    public startDate: Date;
    public endDate: Date;
    public minDate: Date;
    public timeSteps: TimeStep[] = [];

    public startTime: TimeStep;
    public endTime: TimeStep;

    public dateInFuture = false;

    public basis = 'calc(50% - var(--gap))';
    private readonly isAdmin: boolean = false;
    public localShowTime: boolean;
    public appearance: MatFormFieldAppearance = 'outline';

    public startControl: UntypedFormControl;
    public endControl: UntypedFormControl;

    @ViewChild('content') content: ElementRef;
    @ViewChild('startDatepicker') startDatepicker: MatDatepicker<any>;
    @ViewChild('endDatepicker') endDatepicker: MatDatepicker<any>;

    @Input() start: moment.Moment = moment().subtract(1, 'days').startOf('day');
    @Input() end: moment.Moment = moment();
    @Input() min: moment.Moment;
    // @Input() min: moment.Moment = moment('2014-11-20'); // This is a global min. from azm
    @Input() max: moment.Moment;
    @Input() allowFutureEndDate: boolean = false;
    @Input() showApplyButton: boolean = false;
    @Input({transform: booleanAttribute}) showTime = false;
    @Input() buttonText = 'Apply Date Range';

    @Input() startPlaceholder = 'Start Date';
    @Input() endPlaceholder = 'End Date';
    @Input() singlePicker: boolean;

    @Input() layout: 'row' | 'column' = 'row';
    @Input() gap = '10px';
    @Input() newDesign: boolean = true;
    @Input() ignoreMinDate: boolean = false;
    @Input() active: boolean = true;
    @Output() change: EventEmitter<any> = new EventEmitter<any>();
    @Output() invalidDate: EventEmitter<void> = new EventEmitter<void>();

    constructor(private auth: AuthenticationService) {
        const user = this.auth.authenticatedUser.value;
        this.isAdmin = user === undefined ? false : user.hasRole(GenRoleDefinition.ROLE_ADMIN);
    }

    ngOnInit() {
        // Doing this to stop checked expression error (Tried ngAfterContentChecked and ngAfterViewChecked)
        this.localShowTime = this.showTime;
        if (this.localShowTime) {
            this.timeSteps = TimeStep.createTimeSteps();
        }

        if (this.singlePicker && !this.allowFutureEndDate) {
            this.end = moment().add(15, 'minutes');
        } else if (this.singlePicker && this.allowFutureEndDate) {
            this.end = moment(this.max) || moment().add(2, 'months');
        }

        this.start = ApplicationConfig.roundMinute(this.start);
        this.end = ApplicationConfig.roundMinute(this.end);

        this.startDate = this.start.toDate();
        this.endDate = this.end.toDate();

        if (this.min !== undefined) {
            this.minDate = this.min.toDate();
        } else if (!this.ignoreMinDate) {
            this.minDate = moment().subtract(7, 'days').toDate();
        }

        if (this.allowFutureEndDate && this.max !== undefined) {
            this._maxEndDate = this.max.toDate();
        } else if (this.allowFutureEndDate && this.max === undefined) {
            this._maxEndDate = moment().add(2, 'months').toDate();
        }

        [this.startDate, this.startTime] = this.initializeDates(this.start);

        [this.endDate, this.endTime] = this.initializeDates(this.end);

        this.startControl = new UntypedFormControl('', [this.isValidStartDate()]);
        this.endControl = new UntypedFormControl('', [this.isValidEndDate()]);

        this.startControl.markAsTouched();
        this.endControl.markAsTouched();

        if (this.singlePicker) {
            this.basis = '100%';
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.start && !changes.start.isFirstChange()) {
            [this.startDate, this.startTime] = this.initializeDates(this.start);
        }
        if (changes.end && !changes.end.isFirstChange()) {
            // Not sure why this line was here, if a bug appears about end date, check here
            [this.endDate, this.endTime] = this.initializeDates(this.end);
        }

        if (changes.min && !changes.min.isFirstChange()) {
            this.minDate = this.min.toDate();

            if (this.minDate.valueOf() > this.startDate.valueOf()) {
                [this.startDate, this.startTime] = this.initializeDates(this.min);
            }
        }

        if (changes.max && !this.allowFutureEndDate) {
            if (changes.max.currentValue.isAfter(moment())) {
                this._maxEndDate = moment().toDate();
            } else {
                this._maxEndDate = (changes.max.currentValue as moment.Moment).toDate();
            }
        }
    }

    private isValidStartDate(): ValidatorFn {
        return (control: AbstractControl) => {
            if (control && !this.allowFutureEndDate && this.showTime) {
                return moment(control.value).isAfter(moment()) ? {invalid: true} : null;
            }
            return null;
        };
    }

    private isValidEndDate(): ValidatorFn {
        return (control: AbstractControl) => {
            let ret = null;
            if (control && this.showTime && !this.allowFutureEndDate) {
                const cm = moment(control.value);

                if (cm.isBefore(moment(this.startControl.value))) {
                    ret = {beforeStart: true};
                } else if (cm.isAfter(moment())) {
                    ret = {invalid: true};
                }
            }
            return ret;
        };
    }

    private initializeDates(date: moment.Moment): [Date, TimeStep] {
        date = ApplicationConfig.roundMinute(date).local();
        const step: TimeStep = this.timeSteps.find((s) => s.matchesTime(date));

        return [date.toDate(), step];
    }

    public updateTime(step: TimeStep, date: Date, applyChange: boolean = false, type: 'start' | 'end'): void {
        date.setHours(step.hour, step.minute, 0);

        this.dateInFuture = date.valueOf() > moment().valueOf();

        if (type === 'start') {
            this.startControl.setValue(date);
        } else {
            this.endControl.setValue(date);
        }

        if (applyChange && !this.showApplyButton) {
            this.applyChanges();
        }
    }

    private validTimeCheck() {
        const diffInDays = moment(this.endDate).diff(moment(this.startDate), 'days');
        if (diffInDays > 0) {
            return true;
        } else if (moment(this.endDate).day() !== moment(this.startDate).day()) {
            return true;
        }
        return false;
    }

    public isValidStartTime(start: TimeStep): boolean {
        return this.validTimeCheck() || start.id < this.endTime.id;
    }

    public isValidEndTime(end: TimeStep): boolean {
        const t = moment(this.endDate).local().toDate();
        t.setHours(end.hour, end.minute, 0);
        return (
            (moment(t).isSameOrBefore() || this.allowFutureEndDate) &&
            (this.validTimeCheck() || this.startTime.id < end.id) &&
            moment(t).isSameOrBefore(moment(this.maxEndDate))
        );
    }

    public updateDateRange(type: string, date: Date, updateTimeOnly: boolean = false): void {
        if (type === 'start') {
            this.startDate = date;
        } else {
            if (this.allowFutureEndDate) {
                this.endDate = date;
            } else {
                if (moment(this.endDate).isSame(moment(), 'day')) {
                    this.endDate = moment().toDate();
                } else {
                    this.endDate = moment(this.endDate).endOf('day').toDate();
                }
            }
        }
        if (this.localShowTime) {
            if (type === 'start') {
                this.updateTime(this.startTime, this.startDate, false, 'start');
            } else {
                this.updateTime(this.endTime, this.endDate, false, 'end');
            }
        }

        if (!this.showApplyButton && !updateTimeOnly) {
            this.applyChanges();
        }
    }

    private updateDates(): void {
        this.updateDateRange('start', this.startDate, true);
        this.updateDateRange('end', this.endDate, true);
    }

    public applyChanges(): void {
        this.updateDates();

        this.start = moment(this.startDate);
        this.end = moment(this.endDate);

        this.currentDates.startDate = moment(this.startDate);
        this.currentDates.endDate = moment(this.endDate);

        this.startControl.updateValueAndValidity();
        this.endControl.updateValueAndValidity();

        if (this.areDatesValid()) {
            this.change.emit(this.singlePicker ? this.currentDates.startDate : this.currentDates);
        } else {
            this.invalidDate.emit();
        }
    }

    public reinitalizeDates(start: moment.Moment, end: moment.Moment): void {
        [this.startDate, this.startTime] = this.initializeDates(start);
        [this.endDate, this.endTime] = this.initializeDates(end);
    }

    public areDatesValid(): boolean {
        const c1 = this.startControl.valid;
        let c2 = true;

        if (!this.singlePicker) {
            c2 = this.endControl.valid;
        }

        return c1 && c2;
    }

    public toggleStartDatepicker(event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();
        if (this.startDatepicker.opened) {
            this.startDatepicker.close();
        } else {
            this.startDatepicker.open();
        }
    }

    public toggleEndDatepicker(event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();
        if (this.endDatepicker.opened) {
            this.endDatepicker.close();
        } else {
            this.endDatepicker.open();
        }
    }
}
