import {AfterViewInit, Component, ElementRef, Inject, ViewChild} from '@angular/core';
import {ApplicationConfig} from '../../../../classes/application-config';
import {throttleTime} from 'rxjs/operators';
import {ModalConfig} from '../../../../classes/modal-config';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AbstractControl, UntypedFormControl, ValidatorFn, Validators} from '@angular/forms';
import {Layout} from '../../classes/layout';

interface DialogData {
    defaultLayout: Layout;
    layout: Layout;
    isUserLayout: boolean;
    userLayouts: Layout[];
    hasDetails: boolean;
}

@Component({
    selector: 'eaglei-save-layout-modal',
    templateUrl: './save-layout-modal.component.html',
    styleUrls: ['./save-layout-modal.component.scss'],
})
export class SaveLayoutModalComponent implements AfterViewInit {
    @ViewChild('content', {static: true}) content: ElementRef;

    public isDefault: boolean = false;
    public defaultLayout: Layout;
    private userLayouts: Layout[] = [];

    public duplicateName: boolean;

    public nameControl = new UntypedFormControl('', {
        updateOn: 'change',
        validators: [Validators.required, this.duplicateNameValidator()],
    });

    constructor(public dialogRef: MatDialogRef<SaveLayoutModalComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {
        this.defaultLayout = data.defaultLayout;
        this.userLayouts = data.userLayouts;
    }

    ngAfterViewInit() {
        this.nameControl.markAsTouched();
        this.nameControl.setErrors(null);
        ModalConfig.setModalHeight(this.content);
        ApplicationConfig.resizeEvent.pipe(throttleTime(250)).subscribe(() => ModalConfig.setModalHeight(this.content));
    }

    private duplicateNameValidator(): ValidatorFn {
        return (control: AbstractControl) => {
            if (control.value) {
                this.duplicateName = false;
                const formatted = (control.value as string).trim();
                const index = this.userLayouts.findIndex((ul) => ul.name.trim() === formatted);
                if (index !== -1) {
                    this.duplicateName = true;
                    return null;
                }
            }
            return null;
        };
    }

    /**
     * Creates a layout from the information input and closes the modal to save.
     */
    public saveLayout(): void {
        const layout = this.userLayouts.find((ul) => ul.name.trim() === (this.nameControl.value as string).trim()) || new Layout();
        layout.name = this.nameControl.value;
        layout.defaultLayout = this.isDefault;

        this.dialogRef.close(layout);
    }
}
