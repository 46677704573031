import {EilData} from '../../../classes/eil-data';
import {LayerStyleService} from '../services/layer-style.service';
import {LegendElement} from '../classes/legend-element';
import {LeafletVectorSource} from '../classes/leaflet-vector-source';
import {LeafletMapLayer} from '../classes/leaflet-map-layer';
import * as L from 'leaflet';
import {LeafletFeature} from '../classes/leaflet-feature';
import {GenDatapointType} from '../../../../../generated/serverModels/GenDatapointType';

export class EilSource extends LeafletVectorSource<EilData> {
    private filteredType: GenDatapointType;

    constructor(layerInfo: LeafletMapLayer) {
        super(layerInfo);
    }

    private getStyle(point: EilData): any {
        const key = `eilDatapoint-${point.active}`;
        let style = LayerStyleService.layerStyles.get(key);
        if (!style) {
            const fillColor = point.active ? '#CCE7FD' : '#f3615a';

            style = {
                radius: 6,
                fillColor,
                color: '#000',
                weight: 1,
                opacity: 1,
                fillOpacity: 0.8,
            };
            LayerStyleService.layerStyles.set(key, style);

            const ele = new LegendElement();
            ele.name = point.active ? 'Active' : 'Inactive';
            ele.fillColor = fillColor;
            ele.radius = 3;
            ele.width = 20;
            ele.height = 20;
        }
        return style;
    }

    processFeatures(features: EilData[]): void {
        // Adding a sort to ensure that polygons are always drawn under the points
        const leafletFeatures = features
            .map((v) => new LeafletFeature<EilData>().convert(v).buildPopover(this.layerInfo))
            .sort((a, b) => {
                if (a.getGeometryType() > b.getGeometryType()) return -1;
                else if (a.getGeometryType() < b.getGeometryType()) return 1;
                return 0;
            });

        const pointStyle = (geoJsonPoint: any, latlng: L.LatLng): L.Layer => {
            return L.circleMarker(latlng, this.getStyle(geoJsonPoint.properties));
        };

        const config: any = {
            pointToLayer: pointStyle,
            style: (feature: LeafletFeature<EilData>) => {
                return {
                    fillColor: feature.properties.active ? '#CCE7FD' : '#f3615a',
                    color: '#000',
                    weight: 1,
                    opacity: 1,
                    fillOpacity: 0.8,
                };
            },
            onEachFeature: this.initializePopoverInteractions.bind(this),
            features: leafletFeatures,
            filter: (feature: any) => {
                const point: EilData = feature.properties;
                return this.filteredType === undefined ? true : point.type === this.filteredType;
            },
        };

        this.source = L.geoJSON(leafletFeatures as any, config);
    }

    public applyFilter(type: GenDatapointType): void {
        const jsonObj = {
            type: 'FeatureCollection',
            features: this.source.options['features'],
        };

        this.source.clearLayers();
        this.filteredType = type;
        this.source.addData(jsonObj as any);
    }
}
