
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import { GenAggregationLevel } from './GenAggregationLevel';
import * as moment from 'moment';

export class GenEagleiCsvDownloadInfo {

    aggregationLevel: GenAggregationLevel;
    allCounties: boolean;
    endDate: moment.Moment;
    fileType: string;
    filename: string;
    fipsCodes: string[];
    fullFilename: string;
    hourInterval: number;
    reason: string;
    startDate: moment.Moment;

    constructor();
    constructor( jsonObj: any );
    constructor( genEagleiCsvDownloadInfo: GenEagleiCsvDownloadInfo);

    constructor( input?: GenEagleiCsvDownloadInfo ) {
        if ( input && typeof input !== 'number' ) {
            this.aggregationLevel = (typeof input.aggregationLevel === 'string') ? GenAggregationLevel.forName(<string> input.aggregationLevel) : <any> input.aggregationLevel; // GenAggregationLevel
            this.allCounties = input.allCounties; // boolean [boolean]
            if ( input.endDate && typeof input.endDate === 'string' ) {
                this.endDate = moment(input.endDate).utc();
            } else {
                this.endDate = input.endDate;
            }
            this.fileType = input.fileType; // string [string]
            this.filename = input.filename; // string [string]
            this.fipsCodes = input.fipsCodes; // list [string]
            this.fullFilename = input.fullFilename; // string [string]
            this.hourInterval = input.hourInterval; // int [number]
            this.reason = input.reason; // string [string]
            if ( input.startDate && typeof input.startDate === 'string' ) {
                this.startDate = moment(input.startDate).utc();
            } else {
                this.startDate = input.startDate;
            }
       }
    }

    static jsonResponseToList(arr: any[]): GenEagleiCsvDownloadInfo[] {
        if (arr && arr.map) {
          return arr.map(a => new GenEagleiCsvDownloadInfo(a));
        } else {
          return [];
        }
    }
}

