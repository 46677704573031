import {Pipe, PipeTransform} from '@angular/core';

/**
 * Returns a count for the county suffix.
 * Since the "select all" option adds (when selected) an undefined value to the array of values,
 * it'll count towards the total. This pipe removes that extra value from the total selected count.'
 */
@Pipe({
    name: 'suffixCount',
})
export class SuffixCountPipe implements PipeTransform {
    transform(selected: number, total: number): number {
        return selected === total ? selected - 2 : selected - 1;
    }
}
