import {Component, OnInit} from '@angular/core';
import {Utility} from 'frontend/src/app/modules/outage/classes/utility';
import {LandingService} from 'frontend/src/app/landing/services/landing.service';
import {BaseWidget} from '../../../classes/base-widget';

@Component({
    selector: 'eaglei-utilities-impacted-widget',
    templateUrl: './utilities-impacted-widget.component.html',
    styleUrls: ['./utilities-impacted-widget.component.scss'],
})
export class UtilitiesImpactedWidgetComponent extends BaseWidget implements OnInit {
    public isLoading = false;

    public utilList: Utility[] = [];

    constructor(private landingService: LandingService) {
        super();
    }

    ngOnInit() {
        this.isLoading = true;
        this.landingService.getSummaryUtilities().subscribe((res) => {
            this.utilList = res.filter((util) => util.customersOut > 0).sort((a, b) => b.customersOut - a.customersOut);
            this.isLoading = false;
        });
    }

    destroyWidget(): void {}
}
