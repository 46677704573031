import {LeafletWmsSource} from '../../../modules/layer/classes/leaflet-wms-source';
import {HeadoutWeatherSourceConfig} from '../interfaces/headout-weather-source-config.interface';
import {LeafletMapLayer} from '../../../modules/layer/classes/leaflet-map-layer';
import {GenServiceType} from '../../../../../generated/serverModels/GenServiceType';
import {TitleCasePipe} from '@angular/common';

export class HeadoutWeatherSource extends LeafletWmsSource {
    static titlePipe = new TitleCasePipe();

    get styleName(): string {
        return this._styleName;
    }

    private readonly _styleName: string;
    private readonly _layerName: string;

    constructor(geoserverName: string) {
        // creating a base layerInfo since it is not generated from the DB.
        super(
            new LeafletMapLayer({
                displayName: HeadoutWeatherSource.titlePipe.transform(`Headout ${geoserverName.replace(/_/g, ' ')}`),
                serviceurl: 'EI_GEOSERVER/geoserver/eagle-i/wms',
                servicelayer: 'eagle-i:event_result_condition',
                servicetype: GenServiceType.WMS,
                uiHandle: `headout_weather_${geoserverName}`,
                attributionTitle: '',
                attributionUrl: '',
            } as any)
        );

        let id = -1;
        switch (geoserverName) {
            case 'QUANTITATIVE_PRECIPITATION':
                id = 10_000;
                break;
            case 'WIND_GUST':
                id = 10_001;
                break;
            case 'WIND_SPEED':
                id = 10_002;
                break;
        }
        this.layerInfo.id = id;

        this._layerName = geoserverName.toUpperCase();
        this._styleName = `eagle-i:headout_${geoserverName.toLowerCase()}`;
    }

    public updateSource(config: HeadoutWeatherSourceConfig) {
        const filter = `event_id = ${config.details.headoutEventId} AND result_id = ${config.advisory.id} AND condition_id = '${this._layerName}'`;

        this.fetchImages(this.styleName);
        this.applyFilter(filter);

        this.addToMap();
        this.changeOpacity(0.8);
    }
}
