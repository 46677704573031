import {AfterViewInit, Component, ElementRef, inject, ViewChild, ViewChildren} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import * as moment from 'moment';
import {Report} from '../../classes/report';
import {ReportService} from '../../services/report.service';
import {OngService} from '../../../../services/ong.service';
import {FileDownload} from '../../../../classes/file-download';
import {CardFilters} from '../../../../../shared/classes/card-filters';
import {FormControl, FormGroup} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {EagleINumberPipeArgs} from '../../../../../shared/pipes/eaglei-number.pipe';
import {EagleiBaseChart} from '../../../../classes/charts/base-chart';
import {ScotfordIntelData, ScotfordMeasurementData, UtilizationIntelData, UtilizationMeasurementData} from '../../classes/refinery-intel';

@Component({
    selector: 'eaglei-refinery-intelligence-report',
    templateUrl: './refinery-intelligence-report.component.html',
    styleUrls: ['../reports.scss', './refinery-intelligence-report.component.scss'],
})
export class RefineryIntelligenceReportComponent extends Report<UtilizationIntelData> implements AfterViewInit {
    @ViewChild(MatSort) sort: MatSort;
    @ViewChildren('chart') charts: ElementRef<HTMLDivElement>[];
    public chartColors = {
        primary: 'blue',
        light: 'black',
        middle: 'green',
        heavy: 'orange',
        bitSecondary: 'orange',
        bitPrimary: 'blue',
    };
    public datePickerStartDate = moment().subtract(1, 'week');
    public bpdTooltip = 'Barrels Per Day';
    public weeklyChangePipeArgs: EagleINumberPipeArgs = {
        postfix: '%',
        noZero: true,
        notAvailable: '-',
        specifier: ',.2f',
    };
    public bpdPipeArgs: EagleINumberPipeArgs = {
        specifier: ',.0f',
    };
    public utilPipeArgs: EagleINumberPipeArgs = {
        specifier: ',.2f',
        postfix: '%',
    };
    public readonly groupColumnNames: string[] = ['filler', 'primary', 'light', 'middle', 'heavy'];
    public readonly columnNames: string[] = [
        'region',
        'p-utilization',
        'p-offline',
        'p-change',
        'l-utilization',
        'l-offline',
        'l-change',
        'm-utilization',
        'm-offline',
        'm-change',
        'h-utilization',
        'h-offline',
        'h-change',
    ];
    public readonly scotfordGroupColumnNames: string[] = ['filler', 'secondary', 'primary'];
    public readonly scotfordColumnNames: string[] = [
        'region',
        'p-utilization',
        'p-offline',
        'p-change',
        's-utilization',
        's-offline',
        's-change',
    ];

    public filterForm = new FormGroup({
        startDate: new FormControl(moment().subtract(1, 'week')),
        endDate: new FormControl(moment()),
    });

    public utilizationSelectedRow: UtilizationIntelData;
    public expandedUtilizationDataSource: MatTableDataSource<UtilizationMeasurementData> =
        new MatTableDataSource<UtilizationMeasurementData>();

    public scotfordDataSource: MatTableDataSource<ScotfordIntelData>;
    public scotfordSelectedRow: ScotfordIntelData;
    public scotfordExpandedDataSource: MatTableDataSource<ScotfordMeasurementData> = new MatTableDataSource<ScotfordMeasurementData>();
    public baseCharts = {
        Canada: new EagleiBaseChart(),
        East_Coast: new EagleiBaseChart(),
        Gulf_Coast: new EagleiBaseChart(),
        'Mid-Continent': new EagleiBaseChart(),
        West_US: new EagleiBaseChart(),
        Scotford_Upgrader: new EagleiBaseChart(),
    };

    protected ongService = inject(OngService);

    constructor(public reportService: ReportService) {
        super();
    }

    public ngAfterViewInit(): void {
        this.reportService
            .getReportData()
            .pipe(takeUntil(this.destroy$))
            .subscribe((r) => {
                this.initializeReportInfo(r);
            });

        this.getRefineryIntelReport();

        this.charts.forEach((chart) => {
            const id = chart.nativeElement.id;
            this.initializeChart(chart.nativeElement, id);
        });
    }

    public getRefineryIntelReport(): void {
        const {startDate, endDate} = this.filterForm.getRawValue();

        this.ongService
            .getOilRefineryIntelligence(startDate, endDate)
            .pipe(takeUntil(this.destroy$))
            .subscribe((res) => {
                this.initData(res);
            });
    }

    private initData(data: {utilization: UtilizationIntelData[]; scotford: ScotfordIntelData[]}): void {
        if (this.dataSource) {
            this.dataSource.data = data.utilization;
            this.scotfordDataSource.data = data.scotford;
        } else {
            this.dataSource = new MatTableDataSource<UtilizationIntelData>(data.utilization);
            this.scotfordDataSource = new MatTableDataSource<ScotfordIntelData>(data.scotford);
        }
        this.createCharts();
    }

    public updateDateRange(dates: CardFilters) {
        this.filterForm.patchValue({
            startDate: dates.startDate,
            endDate: dates.endDate,
        });

        this.getRefineryIntelReport();
    }

    public togglePrimaryExpandedRow(row: UtilizationIntelData): void {
        const isRowSelected = this.utilizationSelectedRow === row;
        this.utilizationSelectedRow = isRowSelected ? undefined : row;
        this.expandedUtilizationDataSource.data = isRowSelected ? [] : row.measurements_data;
    }

    public toggleScotfordExpandedRow(row: ScotfordIntelData): void {
        const isRowSelected = this.scotfordSelectedRow === row;
        this.scotfordSelectedRow = isRowSelected ? undefined : row;
        this.scotfordExpandedDataSource.data = isRowSelected ? [] : row.measurements_data;
    }

    private format(val, args: EagleINumberPipeArgs): string {
        return FileDownload.formatCsvCell(Report.numberPipe.transform(val, args));
    }

    private initializeChart(chart: HTMLDivElement, id: string) {
        this.baseCharts[id].initializeEChart(chart, true, 'Date', 'Utilization %');
    }

    private createCharts() {
        this.charts.forEach((chart) => {
            const id = chart.nativeElement.id;
            if (id === 'Scotford Upgrader') {
                this.createChart(chart, this.scotfordDataSource.data as ScotfordIntelData[], id);
            } else {
                this.createChart(chart, this.dataSource.data as UtilizationIntelData[], id);
            }
        });
    }

    private createChart(chart, data: UtilizationIntelData[] | ScotfordIntelData[], region: string) {
        const xValues: string[] = data[0].measurements_data.map((d) => moment(d.date).format('MM-DD-YYYY'));
        const yValues = [];
        const primary = [];
        const light = [];
        const middle = [];
        const heavy = [];
        const bitPrimary = [];
        const bitSecondary = [];

        let regionData: UtilizationIntelData | ScotfordIntelData;
        if (region === 'Scotford_Upgrader') {
            regionData = this.scotfordDataSource.data[0];

            (regionData as ScotfordIntelData).measurements_data.forEach((date) => {
                bitPrimary.push({
                    value: date.bitumen_primary_utilization,
                });
                bitSecondary.push({
                    value: date.bitumen_secondary_utilization,
                });
            });

            yValues.push({
                type: 'line',
                data: bitPrimary,
                lineStyle: {
                    color: this.chartColors.bitPrimary,
                },
            });
            yValues.push({
                type: 'line',
                data: bitSecondary,
                lineStyle: {
                    color: this.chartColors.bitSecondary,
                },
            });
        } else {
            regionData = (data as UtilizationIntelData[]).find((d) => d.region === region.replace('_', ' '));

            (regionData as UtilizationIntelData).measurements_data.forEach((date) => {
                primary.push({
                    value: date.primary_utilization,
                });
                light.push({
                    value: date.light_utilization,
                });
                middle.push({
                    value: date.middle_utilization,
                });
                heavy.push({
                    value: date.heavy_utilization,
                });
            });

            yValues.push({
                type: 'line',
                data: primary,
                lineStyle: {
                    color: this.chartColors.primary,
                },
            });
            yValues.push({
                type: 'line',
                data: light,
                lineStyle: {
                    color: this.chartColors.light,
                },
            });
            yValues.push({
                type: 'line',
                data: middle,
                lineStyle: {
                    color: this.chartColors.middle,
                },
            });
            yValues.push({
                type: 'line',
                data: heavy,
                lineStyle: {
                    color: this.chartColors.heavy,
                },
            });
        }
        this.baseCharts[region].eChartOptions.yAxis['scale'] = true;
        this.baseCharts[region].eChartOptions.yAxis['max'] = 100;
        this.baseCharts[region].eChartOptions.xAxis = {
            data: xValues,
        };
        this.baseCharts[region].eChartOptions.series = yValues;
        this.baseCharts[region].eChart.setOption(this.baseCharts[region].eChartOptions);
    }

    public exportTable(): void {
        const header = [
            '',
            '',
            'Primary Processing',
            '',
            '',
            'Light Products',
            '',
            '',
            'Middle Distillates',
            '',
            '',
            'Heavy Products',
            '\n',
        ].join();
        let data = [
            'Region',
            'Measurement Date',
            'Utilization %',
            'BPD Offline',
            'Week to Week % Change',
            'Utilization %',
            'BPD Offline',
            'Week to Week % Change',
            'Utilization %',
            'BPD Offline',
            'Week to Week % Change',
            'Utilization %',
            'BPD Offline',
            'Week to Week % Change',
            '\n',
        ].join();

        this.dataSource.data.forEach((val) => {
            let values = [
                FileDownload.formatCsvCell(val.region),
                'Average',
                this.format(val.primary_utilization_avg, this.utilPipeArgs),
                this.format(val.primary_offline_avg, this.bpdPipeArgs),
                this.format(val.primary_change_avg, this.weeklyChangePipeArgs),
                this.format(val.light_utilization_avg, this.utilPipeArgs),
                this.format(val.light_offline_avg, this.bpdPipeArgs),
                this.format(val.light_change_avg, this.weeklyChangePipeArgs),
                this.format(val.middle_utilization_avg, this.utilPipeArgs),
                this.format(val.middle_offline_avg, this.bpdPipeArgs),
                this.format(val.middle_change_avg, this.weeklyChangePipeArgs),
                this.format(val.heavy_utilization_avg, this.utilPipeArgs),
                this.format(val.heavy_offline_avg, this.bpdPipeArgs),
                this.format(val.heavy_change_avg, this.weeklyChangePipeArgs),
            ];

            data += values.join();
            data += '\n';

            val.measurements_data.forEach((m) => {
                values = [
                    '',
                    FileDownload.formatCsvCell(Report.momentPipe.transform(m.date)),
                    this.format(m.primary_utilization, this.utilPipeArgs),
                    this.format(m.primary_offline, this.bpdPipeArgs),
                    this.format(m.primary_change, this.weeklyChangePipeArgs),
                    this.format(m.light_utilization, this.utilPipeArgs),
                    this.format(m.light_offline, this.bpdPipeArgs),
                    this.format(m.light_change, this.weeklyChangePipeArgs),
                    this.format(m.middle_utilization, this.utilPipeArgs),
                    this.format(m.middle_offline, this.bpdPipeArgs),
                    this.format(m.middle_change, this.weeklyChangePipeArgs),
                    this.format(m.heavy_utilization, this.utilPipeArgs),
                    this.format(m.heavy_offline, this.bpdPipeArgs),
                    this.format(m.heavy_change, this.weeklyChangePipeArgs),
                ];

                data += values.join();
                data += '\n';
            });

            data += '\n';
        });

        const scotfordHeader = ['', '', 'Bitumen Secondary Refining', '', '', 'Primary Bitumen Processing', '', '', '\n'].join();
        let scotfordData = [
            'Region',
            'Measurement Date',
            'Utilization %',
            'BPD Offline',
            'Week to Week % Change',
            'Utilization %',
            'BPD Offline',
            'Week to Week % Change',
            '\n',
        ].join();

        this.scotfordDataSource.data.forEach((val) => {
            let values = [
                FileDownload.formatCsvCell(val.region),
                'Average',
                this.format(val.bitumen_secondary_utilization_avg, this.utilPipeArgs),
                this.format(val.bitumen_secondary_offline_avg, this.bpdPipeArgs),
                this.format(val.bitumen_secondary_change_avg, this.weeklyChangePipeArgs),
                this.format(val.bitumen_primary_utilization_avg, this.utilPipeArgs),
                this.format(val.bitumen_primary_offline_avg, this.bpdPipeArgs),
                this.format(val.bitumen_primary_change_avg, this.weeklyChangePipeArgs),
            ];

            scotfordData += values.join();
            scotfordData += '\n';

            val.measurements_data.forEach((m) => {
                values = [
                    '',
                    FileDownload.formatCsvCell(Report.momentPipe.transform(m.date)),
                    this.format(m.bitumen_secondary_utilization, this.utilPipeArgs),
                    this.format(m.bitumen_secondary_offline, this.bpdPipeArgs),
                    this.format(m.bitumen_secondary_change, this.weeklyChangePipeArgs),
                    this.format(m.bitumen_primary_utilization, this.utilPipeArgs),
                    this.format(m.bitumen_primary_offline, this.bpdPipeArgs),
                    this.format(m.bitumen_primary_change, this.weeklyChangePipeArgs),
                ];

                scotfordData += values.join();
                scotfordData += '\n';
            });

            scotfordData += '\n';
        });

        const finalData = header + data + scotfordHeader + scotfordData;

        FileDownload.downloadCSV('refinery_intelligence_report', finalData, this.attributionUrl);
    }
}
