/* tslint:disable:component-class-suffix */
import {PopoverConfig} from '../../../classes/popover-config';
import {Component, Inject, OnInit} from '@angular/core';
import {BaseModal} from '../../../classes/base-modal';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {BehaviorSubject} from 'rxjs';

@Component({
    template: '',
})
export abstract class BasePopover<D = any> extends BaseModal implements OnInit {
    public data: D;
    public data$: BehaviorSubject<D> = new BehaviorSubject<D>(undefined);

    // We need to use the @Inject when we have multi level constructors with primitive values
    protected constructor(@Inject(Number) maxPercentNumber?: number) {
        super(maxPercentNumber);
        PopoverConfig.rawData.pipe(takeUntilDestroyed()).subscribe((data) => {
            const hasOldData = !!this.data;
            this.data = data;
            this.updateData(!hasOldData);
            this.data$.next(data);
        });
    }

    ngOnInit() {
        this.onInit();
    }

    public close(): void {
        setTimeout(() => {
            PopoverConfig.hideNewPopover();
            PopoverConfig.customPopover = undefined;
        }, 0);
    }

    public hasEllipsis(htmlElement: HTMLTableCellElement): boolean {
        return (window as any).elementHasEllipsis(htmlElement);
    }

    /**
     * Notifier that the data has changed.
     * @param initialData Whether the notification is for the initial data.
     */
    abstract updateData(initialData?: boolean);

    abstract onInit();
}
