import {Component, ElementRef, ViewChild} from '@angular/core';
import {BasePopover} from '../base-popover';
import {OngPipelineNotice} from '../../classes/ong-pipeline-notice';
import {OngPipeline} from '../../classes/ong-pipeline';

@Component({
    selector: 'eaglei-ong-pipeline-popover',
    templateUrl: './ong-pipeline-popover.component.html',
    styleUrls: ['./ong-pipeline-popover.component.scss', '../layer-popover.scss'],
})
export class OngPipelinePopoverComponent extends BasePopover<OngPipeline> {
    @ViewChild('noticeDescription', {static: false}) set descriptionElement(target: ElementRef<HTMLDivElement>) {
        if (target) {
            this.element = target;
            this.showExpandButton = target.nativeElement.getBoundingClientRect().height > this.collapsedDescriptionSizeInPx;
        }
    }

    set selectedNotice(value: OngPipelineNotice) {
        if (this._selectedNotice) {
            this._selectedNotice.expanded = false;
        }

        this._selectedNotice = value;

        if (this.element) {
            // the timeout is need to make sure it checks the correct value
            setTimeout(() => {
                this.showExpandButton = this.element.nativeElement.getBoundingClientRect().height > this.collapsedDescriptionSizeInPx;
            }, 0);
        }
    }

    get selectedNotice(): OngPipelineNotice {
        return this._selectedNotice;
    }

    private element: ElementRef<HTMLDivElement>;
    private _selectedNotice: OngPipelineNotice;
    public readonly collapsedDescriptionSizeInPx: number = 150;
    public selectedIndex: number = 0;
    public showExpandButton: boolean;

    constructor() {
        super();
    }

    onInit() {}

    updateData() {
        if (this.data?.notices?.length !== 0) {
            this.selectedNotice = this.data.notices[0];
        }
    }

    public isNoticeFirst(): boolean {
        return this.selectedIndex === 0;
    }

    public isNoticeLast(): boolean {
        return this.selectedIndex === this.data?.notices?.length - 1;
    }

    public nextNotice(): void {
        this.selectedIndex += 1;
        this.selectedNotice = this.data.notices[this.selectedIndex];
    }

    public previousNotice(): void {
        this.selectedIndex -= 1;
        this.selectedNotice = this.data.notices[this.selectedIndex];
    }

    public formatPriority(): string {
        return this.selectedNotice?.priority?.replace(/_/g, ' ') || 'N/A';
    }

    public isEllipsis(element: HTMLElement): boolean {
        return element.offsetWidth < element.scrollWidth;
    }

    afterInit() {}
}
