<!--This is used for $event.target.value since target is a generic type but we know it is an HTMLElement-->
<!--suppress TypeScriptUnresolvedVariable -->

<div class="new-design widthLimiter">
    <div class="filter-bar ignore-last">
        <section class="filters">
            <mat-form-field class="filter-select" [class.active]="hasSearchText">
                <mat-label *ngIf="hasSearchText">Search Reports</mat-label>

                <input matInput placeholder="Search Reports" (keyup)="searchReports($event.target.value)" />

                <mat-icon matSuffix class="icon input-search-icon" fontSet="fas" fontIcon="fa-search"></mat-icon>
            </mat-form-field>
        </section>

        <div class="icon-list">
            <mat-icon
                fontSet="fas"
                fontIcon="fa-sort-alpha-down"
                class="clickable"
                (click)="sortReports()"
                [class.sorted]="sorted"></mat-icon>

            <mat-button-toggle-group class="group-container" [value]="filteredGroupId">
                <mat-button-toggle
                    *ngFor="let group of reportGroups"
                    (change)="filterToGroup($event.value)"
                    [value]="group.id"
                    [matTooltip]="group.name"
                    matTooltipPosition="above"
                    matTooltipShowDelay="250">
                    <mat-icon fontSet="fas" [fontIcon]="group.iconUrl"></mat-icon>
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
    </div>

    <ng-container *ngIf="pinnedReports.length > 0">
        <span class="title">Pinned Reports</span>
        <ng-container [ngTemplateOutlet]="reportList" [ngTemplateOutletContext]="{reports: pinnedReports}"></ng-container>
    </ng-container>

    <ng-container>
        <span class="title"> {{ filteredGroupId !== undefined || hasSearchText ? 'Filtered' : 'All' }} Reports </span>
        <ng-container [ngTemplateOutlet]="reportList" [ngTemplateOutletContext]="{reports: unpinnedReports}"></ng-container>
    </ng-container>
</div>

<ng-template #reportList let-reports="reports">
    <div class="report-container">
        <mat-card class="clickable" *ngFor="let report of reports" (click)="goToReport(report)">
            <mat-card-content class="report-card">
                <mat-icon fontSet="fas" [fontIcon]="report.iconUrl" class="group-icon" inline></mat-icon>
                <div class="report-info">
                    <p class="report-name ellipsis">{{ report.displayName }}</p>
                    <p class="sub-title ellipsis">{{ report.description }}</p>
                </div>

                <ng-container *ngIf="report.pinned; else notPinned">
                    <mat-icon
                        class="pin-icon pinned"
                        fontSet="fas"
                        fontIcon="fa-thumbtack"
                        inline
                        matTooltip="Unpin Report"
                        matTooltipPosition="above"
                        matTooltipShowDelay="250"
                        (click)="unpinReport(report, $event)"></mat-icon>
                </ng-container>
                <ng-template #notPinned>
                    <mat-icon
                        class="pin-icon"
                        fontSet="fas"
                        fontIcon="fa-thumbtack"
                        inline
                        matTooltip="Pin Report"
                        matTooltipPosition="above"
                        matTooltipShowDelay="250"
                        (click)="pinReport(report, $event)"></mat-icon>
                </ng-template>
            </mat-card-content>
        </mat-card>
    </div>
</ng-template>
