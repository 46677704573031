import * as moment from 'moment';
import {MomentDatePipe} from '../../../../shared/pipes/moment-date.pipe';
import {GenRoleDefinition} from '../../../../../generated/serverModels/GenRoleDefinition';
import {GenUser} from '../../../../../generated/serverModels/GenUser';
import {GenCommunityPermission} from '../../../../../generated/serverModels/GenCommunityPermission';
import {Poc} from './poc';
import {ApiKey} from '../../api-key/classes/api-key';

export class User extends GenUser {
    static nonPocEmailExtension = ['.gov', '.mil'];
    static momentPipe = new MomentDatePipe();

    get fullName(): string {
        return `${this.firstName} ${this.lastName}`;
    }

    get joinedValue(): number {
        return this._joinedValue;
    }

    get lastLoginValue(): number {
        return this._lastLoginValue;
    }

    get needsPoc(): boolean {
        if (this.email) {
            return !User.nonPocEmailExtension.some((exe) => {
                return this.email.toLowerCase().includes(exe.toLowerCase());
            });
        }
        return false;
    }

    get daysSincePasswordReset(): number {
        return this._daysSincePasswordReset;
    }

    public oldPassword: string;
    public initials: string;
    public apiKeys: ApiKey[] = [];

    public poc: Poc;

    constructor(user?: User) {
        super(user);

        if (user) {
            if (user.lastLoginDate !== undefined) {
                this._lastLoginValue = moment(user.lastLoginDate).valueOf();
            }

            if (user.joinDate !== undefined) {
                this._joinedValue = moment(user.joinDate).valueOf();
            }

            this._needsPoc = !User.nonPocEmailExtension.some((exe) => {
                return this.email.toLowerCase().includes(exe.toLowerCase());
            });

            if (this.lastPasswordReset) {
                this.updateDaysSincePasswordReset();
            }

            if (user.apiKeys) {
                this.apiKeys = user.apiKeys.map((key) => new ApiKey(key));
            }

            if (this.needsPoc) {
                this.poc = new Poc(user.poc);
            }

            this.dataAccess = this.dataAccess || [];
            this.pinnedReports = this.pinnedReports || [];
            this.initials = `${this.firstName.charAt(0)}${this.lastName.charAt(0)}`.toUpperCase();
        } else {
            this._needsPoc = true;
        }
    }

    private readonly _needsPoc: boolean;
    private readonly _lastLoginValue: number;
    private readonly _joinedValue: number;
    public _daysSincePasswordReset: number;

    protected userReportFields = {
        Username: () => {
            return this.username;
        },
        'First Name': () => {
            return this.firstName;
        },
        'Last Name': () => {
            return this.lastName;
        },
        Email: () => {
            return this.email;
        },
        Title: () => {
            return this.title;
        },
        Department: () => {
            return this.department;
        },
        Organization: () => {
            return this.organization;
        },
        Telephone: () => {
            return this.telephone;
        },
        State: () => {
            return this.state;
        },
        City: () => {
            return this.city;
        },
        'Postal Code': () => {
            return this.postalCode;
        },
        'EEAC State': () => {
            return this.eeacState;
        },

        Enabled: () => {
            return this.enabled;
        },

        Approved: () => {
            return this.approved;
        },

        'Join Date': () => {
            return User.momentPipe.transform(this.joinDate);
        },
        'Last Login Date': () => {
            return User.momentPipe.transform(this.lastLoginDate);
        },

        'Days Since Password Reset': () => {
            return this.daysSincePasswordReset;
        },

        Office: () => {
            return this.office;
        },

        Reason: () => {
            return this.reason;
        },

        'State Emergency Management Agency': () => {
            return this.stateEmergencyManagementAgency;
        },
        'State Emergency Operations Enter': () => {
            return this.stateEmergencyOperationsAenter;
        },
        'State Energy Office': () => {
            return this.stateEnergyOffice;
        },
        'State Governors Office': () => {
            return this.stateGovernorsOffice;
        },
        'State Utility Commission': () => {
            return this.stateUtilityCommission;
        },
        'POC Name': () => {
            return this.poc?.name || 'N/A';
        },
        'POC Email': () => {
            return this.poc?.email || 'N/A';
        },
        'POC Organization': () => {
            return this.poc?.organization || 'N/A';
        },
    };

    public isAdmin(): boolean {
        return this.hasRole(GenRoleDefinition.ROLE_ADMIN);
    }

    public hasRole(role: GenRoleDefinition, ignoreAdmin: boolean = false): boolean {
        if (!ignoreAdmin && this.roles.findIndex((r) => r === GenRoleDefinition.ROLE_ADMIN) !== -1) {
            return true;
        }

        return this.roles.some((userRole: GenRoleDefinition) => {
            return userRole === role;
        });
    }

    public hasAnyRole(roles: GenRoleDefinition[]): boolean {
        if (roles.length === 0) {
            return true;
        }

        if (this.hasRole(GenRoleDefinition.ROLE_ADMIN)) {
            return true;
        }

        return roles.some((role: GenRoleDefinition) => {
            return this.hasRole(role);
        });
    }

    public hasDataAccess(access: GenCommunityPermission, ignoreAdmin: boolean = false): boolean {
        if (!ignoreAdmin && this.hasRole(GenRoleDefinition.ROLE_ADMIN)) {
            return true;
        }

        return this.dataAccess.some((userPermission: GenCommunityPermission) => {
            return userPermission === access;
        });
    }

    public hasAnyDataAccess(access: GenCommunityPermission[]): boolean {
        return access.some((a) => {
            return this.hasDataAccess(a);
        });
    }

    tmpGetUserReportData(formatFunction: (s: string) => string) {
        let columns: any[];
        columns = Object.keys(this.userReportFields).map((key) => formatFunction(this.userReportFields[key]()));
        columns = columns.concat(GenRoleDefinition.values().map((grd) => this.hasRole(grd)));
        return columns;
    }

    getUserReportColumnNames() {
        let columns: string[];
        columns = Object.keys(this.userReportFields);
        columns = columns.concat(GenRoleDefinition.values().map((grd) => grd.toString().replace(/_/g, ' ')));
        return columns;
    }

    public isEnabled(): boolean {
        return this.accountEnabled && this.approved;
    }

    public isValid(): boolean {
        const userInfo = this.getPersonalInfo();

        const userValid: boolean = Object.keys(userInfo).every((key) => !!userInfo[key]);
        const pocValid = this.needsPoc ? this.poc.isValid() : true;

        return userValid && pocValid;
    }

    public updateDaysSincePasswordReset(): void {
        this._daysSincePasswordReset = moment().diff(this.lastPasswordReset, 'days');
    }

    public getPersonalInfo(): User {
        const user = new User();
        user.id = this.id;
        user.firstName = this.firstName;
        user.lastName = this.lastName;
        user.username = this.username;
        user.title = this.title;
        user.department = this.department;
        user.organization = this.organization;
        user.city = this.city;
        user.state = this.state;
        user.postalCode = this.postalCode;
        user.email = this.email;
        user.telephone = this.telephone;
        user.reason = this.reason;
        return user;
    }

    public setPersonalInfo(user: User): void {
        this.firstName = user.firstName;
        this.lastName = user.lastName;
        this.username = user.username;
        this.title = user.title;
        this.department = user.department;
        this.organization = user.organization;
        this.city = user.city;
        this.state = user.state;
        this.postalCode = user.postalCode;
        this.email = user.email;
        this.telephone = user.telephone;
        this.reason = user.reason;
    }

    public getFederalPoc(): Poc {
        return new Poc(this.poc);
    }

    public setFederalPoc(poc: Poc): void {
        const userPoc = this.poc || new Poc();
        userPoc.name = poc.name;
        userPoc.title = poc.title;
        userPoc.department = poc.department;
        userPoc.organization = poc.organization;
        userPoc.email = poc.email;
        userPoc.telephone = poc.telephone;
    }
}
