import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ApiKeyRoutingModule} from './api-key-routing.module';
import {ApiKeyManagementComponent} from './components/api-key-management/api-key-management.component';
import {ApiKeyInfoComponent} from './components/api-key-info/api-key-info.component';
import {ApiKeyRequestListComponent} from './components/api-key-request-list/api-key-request-list.component';
import {SharedModule} from '../../../shared/shared.module';
import {RequestApiKeyModalComponent} from './modals/request-api-key-modal/request-api-key-modal.component';
import {DesignModuleModule} from '../../../design-module/design-module.module';

@NgModule({
    declarations: [ApiKeyManagementComponent, ApiKeyInfoComponent, ApiKeyRequestListComponent, RequestApiKeyModalComponent],
    imports: [CommonModule, ApiKeyRoutingModule, DesignModuleModule, SharedModule],
    exports: [RequestApiKeyModalComponent],
})
export class ApiKeyModule {}
