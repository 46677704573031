
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

export class GenOutageAggregationLevel {
    static readonly fema: GenOutageAggregationLevel = new GenOutageAggregationLevel('fema');
    static readonly state: GenOutageAggregationLevel = new GenOutageAggregationLevel('state');
    static readonly county: GenOutageAggregationLevel = new GenOutageAggregationLevel('county');
    static readonly utility: GenOutageAggregationLevel = new GenOutageAggregationLevel('utility');
    static readonly zip: GenOutageAggregationLevel = new GenOutageAggregationLevel('zip');

    public readonly name: string;

    private constructor(name: string) {
        this.name = name;
    }

    static forName(name: string) {
        if (name === 'fema') return GenOutageAggregationLevel.fema;
        if (name === 'state') return GenOutageAggregationLevel.state;
        if (name === 'county') return GenOutageAggregationLevel.county;
        if (name === 'utility') return GenOutageAggregationLevel.utility;
        if (name === 'zip') return GenOutageAggregationLevel.zip;
        throw new Error('Unexpected value for GenOutageAggregationLevel:' + name);
    }

    static values(): GenOutageAggregationLevel[] {
        return [
            GenOutageAggregationLevel.fema,
            GenOutageAggregationLevel.state,
            GenOutageAggregationLevel.county,
            GenOutageAggregationLevel.utility,
            GenOutageAggregationLevel.zip,
        ];
    }

    toJSON(): string {
        return this.name;
    }

    toString(): string {
        return this.name;
    }
}

