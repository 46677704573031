import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CommunityLandingComponent} from './components/community-landing/community-landing.component';
import {CommunityComponent} from './components/community/community.component';
import {CommunityGuard} from './guards/community.guard';

const routes: Routes = [
    {
        path: '',
        component: CommunityLandingComponent,
        data: {
            useNewDesign: true,
        },
    },
    {
        path: ':id',
        canActivate: [CommunityGuard],
        component: CommunityComponent,
        data: {
            useNewDesign: true,
            ignoreTitle: true,
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class CommunityRoutingModule {}
