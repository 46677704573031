<div class="dialog-header">
    <span>Manage API-Key</span>
    <mat-icon fontSet="fas" fontIcon="fa-times" inline class="clickable" (click)="ref.close()"> </mat-icon>
</div>

<div #content class="dialog-content api-key-modal">
    <div>
        <p class="section-header header-space">API-KEY</p>
        <span>{{ apiKey.key }}</span>
    </div>

    <form [formGroup]="requestGroup">
        <mat-form-field appearance="outline" class="wide ignore-style">
            <mat-label>Reason for Request</mat-label>
            <textarea
                matInput
                matTextareaAutosize
                matAutosizeMaxRows="3"
                matAutosizeMinRows="3"
                name="reason"
                maxlength="500"
                formControlName="reason">
            </textarea>
            <mat-hint align="end">{{ requestGroup?.controls?.reason?.value?.length || 0 }} / 500</mat-hint>
            <mat-error>
                <span>A reason is required</span>
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="half-width ignore-style">
            <mat-label>Number of Users Supported</mat-label>
            <input
                matInput
                eagleiNumberInput
                [allowNegative]="false"
                [allowDecimal]="false"
                name="supportedUsers"
                formControlName="supportedUsers" />
            <mat-hint>
                If support is needed for more than 10,000 users, please contact
                <a href="mailto:eagle-i@ornl.gov">eagle-i@ornl.gov</a>
            </mat-hint>

            <mat-error>
                <span *ngIf="requestGroup.controls.supportedUsers.hasError('pattern')"> Input is not a valid number </span>
                <span *ngIf="requestGroup.controls.supportedUsers.hasError('required')"> A number of users supported is required </span>
                <span *ngIf="requestGroup.controls.supportedUsers.hasError('max')">
                    supporting more than 10,000 users is not permitted, please contact
                    <a href="mailto:eagle-i@ornl.gov">eagle-i@ornl.gov</a>
                </span>
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="wide ignore-style system-input">
            <mat-label>Supported System</mat-label>
            <input matInput formControlName="system" />
            <mat-error>
                <span>A system name is required.</span>
            </mat-error>
        </mat-form-field>
    </form>

    <div>
        <p class="section-header">USE CASES</p>
        <form [formGroup]="requestGroup">
            <mat-checkbox formControlName="dataService">For access to Data Services</mat-checkbox>
            <br />
            <mat-checkbox formControlName="ogcService">For access to Open Geospatial Consortium (OGC) Services</mat-checkbox>
        </form>
    </div>

    <div>
        <p class="section-header header-space">RENEW API-KEY</p>
        <button mat-flat-button class="renew" (click)="renewKey()">Renew API-Key For 1 Year</button>
    </div>
</div>

<div class="dialog-actions">
    <button mat-stroked-button (click)="ref.close()">Close</button>
    <button mat-raised-button color="primary" (click)="submit()" [disabled]="!isFormValid()">Update</button>
</div>
