<div class="filter-chip-container">
    <div class="select-clear-actions">
        <mat-chip (click)="toggleAll(true)">Select All</mat-chip>
        <mat-chip (click)="toggleAll(false)">Clear All</mat-chip>
    </div>

    <div>
        <mat-button-toggle-group [value]="showStatesByRegion">
            <mat-button-toggle [value]="true" (change)="showStatesByRegion = true">
                <img src="dist/images/icons/group.svg" alt="" />
            </mat-button-toggle>
            <mat-button-toggle [value]="false" (change)="showStatesByRegion = false">
                <img src="dist/images/icons/ungroup.svg" alt="" />
            </mat-button-toggle>
        </mat-button-toggle-group>
    </div>

    <ng-container *ngIf="showStatesByRegion" [ngTemplateOutlet]="byRegion"></ng-container>
    <ng-container *ngIf="!showStatesByRegion" [ngTemplateOutlet]="byState"></ng-container>
</div>

<ng-template #byRegion>
    <div *ngFor="let region of regionOptions" class="region-section">
        <span class="label">{{ region.regionId.toString() | numeral : true }}</span>

        <div class="state-chips" [ngClass]="'region-' + region.regionId">
            <mat-chip-listbox multiple>
                <mat-chip-option *ngFor="let state of region.states" (click)="toggleState(state)" [selected]="state.selected">
                    <p
                        #stateName
                        class="ellipsis"
                        [matTooltipDisabled]="!showEllipsis(stateName)"
                        [matTooltip]="state.state.name"
                        matTooltipPosition="above"
                        matTooltipShowDelay="250">
                        {{ state.state.name }}
                    </p>
                </mat-chip-option>
            </mat-chip-listbox>
        </div>
        <div class="select-clear-group-container">
            <mat-chip (click)="toggleRegion(region, true)"> Select all </mat-chip>
            <mat-chip (click)="toggleRegion(region, false)"> Clear all </mat-chip>
        </div>
    </div>
</ng-template>
<ng-template #byState>
    <div class="fema-regions">
        <span class="label">FEMA REGIONS</span>
        <mat-chip-listbox multiple class="region-chips">
            <mat-chip-option *ngFor="let region of regionOptions" (click)="toggleRegion(region)" [selected]="region.selected">
                <p>{{ region.regionId.toString() | numeral : true }}</p>
            </mat-chip-option>
        </mat-chip-listbox>
    </div>

    <div class="states">
        <span class="label">STATES</span>
        <mat-chip-listbox multiple class="state-chips">
            <mat-chip-option *ngFor="let state of stateOptions" (click)="toggleState(state)" [selected]="state.selected">
                <p
                    #stateName
                    class="ellipsis"
                    [matTooltipDisabled]="!showEllipsis(stateName)"
                    [matTooltip]="state.state.name"
                    matTooltipPosition="above"
                    matTooltipShowDelay="250">
                    {{ state.state.name }}
                </p>
            </mat-chip-option>
        </mat-chip-listbox>
    </div>
</ng-template>
