import {AfterViewInit, Component, ElementRef, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Widget} from '../../../modules/widget/classes/widget';
import {ApplicationConfig} from '../../../classes/application-config';
import {throttleTime} from 'rxjs/operators';
import {ModalConfig} from '../../../classes/modal-config';
import {WidgetDetail} from '../../../modules/widget/classes/widget-detail';

class AddedWidget extends Widget {
    constructor(widget) {
        super(widget);
    }

    public numberToAdd: number = 0;
    public selected: boolean = false;
}

@Component({
    selector: 'eaglei-add-widget-modal',
    templateUrl: './add-widget-modal.component.html',
    styleUrls: ['./add-widget-modal.component.scss'],
})
export class AddWidgetModalComponent implements AfterViewInit {
    @ViewChild('content', {static: true}) content: ElementRef;

    public readonly widgetList: AddedWidget[];

    constructor(public ref: MatDialogRef<AddWidgetModalComponent>, @Inject(MAT_DIALOG_DATA) private widgets: Widget[]) {
        this.widgetList = widgets.map((w) => new AddedWidget(w)).filter((w) => !this.limitReached(w));
    }

    ngAfterViewInit() {
        ModalConfig.setModalHeight(this.content);
        ApplicationConfig.resizeEvent.pipe(throttleTime(250)).subscribe(() => ModalConfig.setModalHeight(this.content));
    }

    public toggleSelected(widget: AddedWidget): void {
        widget.selected = !widget.selected;
        if (widget.selected) {
            widget.numberToAdd = widget.numberToAdd || 1;
        }
    }

    public addWidget(widget: AddedWidget) {
        if (this.limitReached(widget)) {
            return;
        }
        widget.numberToAdd += 1;
    }

    public removeWidget(widget: AddedWidget) {
        if (widget.numberToAdd === 1) {
            return;
        }
        widget.numberToAdd -= 1;
    }

    public limitReached(widget: AddedWidget): boolean {
        return widget.numberInLayout + widget.numberToAdd === widget.numberAllowed;
    }

    public isFormValid(): boolean {
        return this.widgetList.some((w) => w.selected);
    }

    public finalizeWidgets(): void {
        const details = [];

        this.widgetList
            .filter((widget) => widget.selected)
            .forEach((widget) => {
                for (let i = 0; i < widget.numberToAdd; i++) {
                    details.push(new WidgetDetail().setWidget(widget));
                }
            });

        this.ref.close(details);
    }

    public getButtonText(): string {
        const total = this.widgetList.filter((w) => w.selected).reduce((p, c) => (p += c.numberToAdd), 0);

        return total === 1 ? 'Add Widget' : 'Add Widgets';
    }

    public getTooltipText(widget: AddedWidget, type: string) {
        return `Unable to ${type} more widgets`;
    }
}
