
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import * as moment from 'moment';

export class GenDoeFacility {

    id: number;
    address: string;
    boundary: string;
    citystzip: string;
    facility: string;
    gid: number;
    latitude: number;
    longitude: number;
    name: string;
    program: string;
    siteShortName: string;
    source: string;
    sourceDate: moment.Moment;

    constructor();
    constructor( jsonObj: any );
    constructor( genDoeFacility: GenDoeFacility);
    constructor( id: number );

    constructor( input?: GenDoeFacility|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // float [number]
            this.address = input.address; // string [string]
            this.boundary = input.boundary; // string [string]
            this.citystzip = input.citystzip; // string [string]
            this.facility = input.facility; // string [string]
            this.gid = input.gid; // int [number]
            this.latitude = input.latitude; // float [number]
            this.longitude = input.longitude; // float [number]
            this.name = input.name; // string [string]
            this.program = input.program; // string [string]
            this.siteShortName = input.siteShortName; // string [string]
            this.source = input.source; // string [string]
            if ( input.sourceDate && typeof input.sourceDate === 'string' ) {
                this.sourceDate = moment(input.sourceDate).utc();
            } else {
                this.sourceDate = input.sourceDate;
            }
       }
    }

    static jsonResponseToList(arr: any[]): GenDoeFacility[] {
        if (arr && arr.map) {
          return arr.map(a => new GenDoeFacility(a));
        } else {
          return [];
        }
    }
}

