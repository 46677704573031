
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!


export class GenOutageErrorParent {

    connectionErrors: number;
    databaseErrors: number;
    developerErrors: number;
    informationalErrors: number;
    locationErrors: number;
    scraperErrors: number;
    state: string;
    subUtilityErrors: number;
    systemFailureErrors: number;
    totalCustomers: number;
    totalErrors: number;
    unknownErrors: number;
    utilityId: number;
    utilityName: string;
    utilityStatus: string;

    constructor();
    constructor( jsonObj: any );
    constructor( genOutageErrorParent: GenOutageErrorParent);

    constructor( input?: GenOutageErrorParent ) {
        if ( input && typeof input !== 'number' ) {
            this.connectionErrors = input.connectionErrors; // int [number]
            this.databaseErrors = input.databaseErrors; // int [number]
            this.developerErrors = input.developerErrors; // int [number]
            this.informationalErrors = input.informationalErrors; // int [number]
            this.locationErrors = input.locationErrors; // int [number]
            this.scraperErrors = input.scraperErrors; // int [number]
            this.state = input.state; // string [string]
            this.subUtilityErrors = input.subUtilityErrors; // int [number]
            this.systemFailureErrors = input.systemFailureErrors; // int [number]
            this.totalCustomers = input.totalCustomers; // int [number]
            this.totalErrors = input.totalErrors; // int [number]
            this.unknownErrors = input.unknownErrors; // int [number]
            this.utilityId = input.utilityId; // int [number]
            this.utilityName = input.utilityName; // string [string]
            this.utilityStatus = input.utilityStatus; // string [string]
       }
    }

    static jsonResponseToList(arr: any[]): GenOutageErrorParent[] {
        if (arr && arr.map) {
          return arr.map(a => new GenOutageErrorParent(a));
        } else {
          return [];
        }
    }
}

