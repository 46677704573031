import {ApplicationConfig} from 'frontend/src/app/classes/application-config';
import {LeafletEagleiSource} from '../classes/leaflet-eaglei-source';
import {LeafletMapLayer} from '../classes/leaflet-map-layer';
import * as L from 'leaflet';
import 'leaflet.vectorgrid';
import {MapService} from '../../map/services/map.service';

export class LeafletVectortileSource extends LeafletEagleiSource {
    // TODO - Add filtering
    // TODO - Add event listener
    // TODO - Add styling

    private retryTileMap: Map<string, number>;

    constructor(layerInfo: LeafletMapLayer) {
        super(layerInfo);
    }

    /**
     * Fetch the tiles from the tile server of the given layer service url
     */
    public fetchTiles(): void {
        this.retryTileMap = new Map<string, number>();
        const url = `${ApplicationConfig.proxyPrefix}${this.layerInfo.serviceurl}/${this.layerInfo.servicelayer}/{z}/{x}/{y}.pbf`;

        const vectorStyles = {};

        if (this.layerInfo.uiHandle.startsWith('training')) {
            Object.assign(vectorStyles, this.layerInfo.getParams());
        }

        const options = {
            vectorTileLayerStyles: vectorStyles,
            subdomains: '0123',
            interactive: true,
            pane: MapService.layerPaneName,
        };

        this.source = (L as any).vectorGrid.protobuf(url, options);

        this.source.on('loading', () => {
            this.layerInfo.startLoading();
        });

        this.source.on('load', () => {
            this.layerInfo.endLoading();
        });

        // TODO: Figure out why this isn't triggering
        this.source.on('tileerror', (event) => {
            // console.log('event error', event);
            // this.failedTiles += 1;
            // const tileUrl = this.source.getTileUrl(event.coords);
            // let retryAttempts = this.retryTileMap.get(tileUrl) || 0;
            // if (retryAttempts < this.MAX_RETRY_ATTEMPTS) {
            //     setTimeout(() => {
            //         retryAttempts++;
            //         event.tile.src = `${tileUrl}`;
            //         this.retryTileMap.set(tileUrl, retryAttempts);
            //         console.info(`retry attempt #${retryAttempts}`, tileUrl);
            //     }, 1_000);
            // }
        });
    }

    changeOpacity(opacity: number): void {
        this.source?.setOpacity(opacity);
    }
}
