import {Component, EventEmitter, OnDestroy, OnInit, Output, inject} from '@angular/core';
import {FormControl, FormGroup, UntypedFormBuilder, Validators} from '@angular/forms';
import {Observable, Subject, filter, takeUntil, withLatestFrom} from 'rxjs';
import {UtilityLookupService} from '../../../map/services/utility-lookup.service';
import {IPoint} from '../../interfaces/point.interface';

@Component({
    selector: 'eaglei-address-lookup-input',
    templateUrl: './address-lookup-input.component.html',
    styleUrls: ['./address-lookup-input.component.scss'],
})
export class AddressLookupInputComponent implements OnInit, OnDestroy {
    @Output() addressEvent: EventEmitter<IPoint> = new EventEmitter<IPoint>();

    public utilityLookupService = inject(UtilityLookupService);

    private destroyed$ = new Subject();
    public loading: Observable<boolean> = this.utilityLookupService.searching$;

    public addressForm: FormGroup<{
        address: FormControl<string>;
    }>;
    public errMessage: string = '';

    constructor(private formBuilder: UntypedFormBuilder) {}

    public ngOnInit() {
        this.addressForm = this.formBuilder.group({
            address: ['', Validators.required],
        });
        this.utilityLookupService.point$
            .pipe(
                takeUntil(this.destroyed$),
                filter((latLon) => latLon && this.addressForm.value.address.length <= 0)
            )
            .subscribe((latLon) => {
                this.addressForm.setValue({
                    address: `${latLon.latitude}, ${latLon.longitude}`,
                });
            });
        this.utilityLookupService.addressInformation$
            .pipe(withLatestFrom(this.utilityLookupService.searchTerm$), takeUntil(this.destroyed$))
            .subscribe(([search, term]) => {
                if (!term || term.length <= 0) {
                    return;
                }

                this.errMessage = '';
                if (search.candidates.length > 0) {
                    const location = search.candidates[0].location;
                    const latLon: IPoint = {latitude: location.y, longitude: location.x};
                    this.addressEvent.emit(latLon);
                } else {
                    this.errMessage = 'Invalid Address. Could not find location address';
                    this.addressEvent.emit(null);
                }
            });
    }

    public ngOnDestroy(): void {
        this.destroyed$.complete();
        this.destroyed$.unsubscribe();
    }

    public submitAddress(): void {
        this.errMessage = '';
        const value = this.addressForm.value;
        // Check if just lat/long
        const latLonArray = value.address.split(',');
        if (latLonArray.length === 2 && !Number.isNaN(+latLonArray[0]) && !Number.isNaN(+latLonArray[1])) {
            const latLon: IPoint = {latitude: +latLonArray[0], longitude: +latLonArray[1]};
            this.addressEvent.emit(latLon);
        } else if (value.address.length > 0) {
            this.utilityLookupService.textSearchForUtilities(value.address);
        }
    }
}
