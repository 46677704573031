<div mat-dialog-title class="header">
    <span class="title">{{ header }}</span>
    <button mat-icon-button matDialogClose>
        <mat-icon fontSet="fas" color="primary" fontIcon="fa-times"></mat-icon>
    </button>
</div>

<mat-dialog-content>
    <form>
        <mat-form-field>
            <mat-label>Subject</mat-label>
            <input matInput required name="subject" [(ngModel)]="subject" placeholder="Email Subject" />
            <mat-error>Email subject is required</mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Email Body</mat-label>
            <textarea
                matInput
                required
                name="body"
                matTextareaAutosize
                matAutosizeMinRows="5"
                matAutosizeMaxRows="5"
                [(ngModel)]="body"
                placeholder="Email Body">
            </textarea>
            <mat-error>Email body is required</mat-error>
        </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-flat-button [disabled]="!checkInput() || pending" (click)="sendEmailToAllUsers()" color="primary">
        {{ buttonText }}
    </button>
</mat-dialog-actions>
