import {Component} from '@angular/core';
import {EagleILayer} from '../../classes/eagle-i-layer';
import {LeafletVectortileSource} from '../../classes/leaflet-vectortile-source';

@Component({
    selector: 'eaglei-leaflet-vectortile-layer',
    templateUrl: './leaflet-vectortile-layer.component.html',
    styleUrls: ['./leaflet-vectortile-layer.component.scss'],
})
export class LeafletVectortileLayerComponent extends EagleILayer<LeafletVectortileSource> {
    constructor() {
        super();
    }

    afterViewInit(): void {}

    toggleLayer(activeState: boolean): void {
        if (activeState) {
            if (!this.layerSource) {
                this.layerSource = new LeafletVectortileSource(this.mapLayer);
            }

            this.layerSource.fetchTiles();
            this.layerSource.addToMap();
        } else {
            this.layerSource.removeFromMap();
        }
    }
}
