
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!


export class GenEOCCountyCurrentOverride {

    countyId: number;
    countyName: string;
    countyOverrideCount: number;
    utilityOverrideCount: number;

    constructor();
    constructor( jsonObj: any );
    constructor( genEOCCountyCurrentOverride: GenEOCCountyCurrentOverride);

    constructor( input?: GenEOCCountyCurrentOverride ) {
        if ( input && typeof input !== 'number' ) {
            this.countyId = input.countyId; // int [number]
            this.countyName = input.countyName; // string [string]
            this.countyOverrideCount = input.countyOverrideCount; // int [number]
            this.utilityOverrideCount = input.utilityOverrideCount; // int [number]
       }
    }

    static jsonResponseToList(arr: any[]): GenEOCCountyCurrentOverride[] {
        if (arr && arr.map) {
          return arr.map(a => new GenEOCCountyCurrentOverride(a));
        } else {
          return [];
        }
    }
}

