import {Component, inject} from '@angular/core';
import {BaseModal} from '../../../app/classes/base-modal';
import {EmailService} from '../../../app/services/email.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {GenOutageAggregationLevel} from '../../../../generated/serverModels/GenOutageAggregationLevel';
import {CardFilters} from '../../classes/card-filters';
import {ApplicationConfig} from '../../../app/classes/application-config';
import {MatDialogRef} from '@angular/material/dialog';
import {DataService} from '../../../app/services/data.service';
import {State} from '../../../app/modules/outage/classes/state';
import {GenTrainingAccessRequest} from '../../../../generated/serverModels/GenTrainingAccessRequest';

@Component({
    selector: 'eaglei-training-access-request',
    templateUrl: './training-access-request.component.html',
    styleUrls: ['./training-access-request.component.scss'],
})
export class TrainingAccessRequestComponent extends BaseModal {
    private emailService = inject(EmailService);
    private snackbar = inject(MatSnackBar);

    public optionGroup: FormGroup;
    public countyByState = new Map<number, any[]>();

    public readonly minDate = ApplicationConfig.roundMinute();
    public defaultDates = new CardFilters()
        .setStartDate(ApplicationConfig.roundMinute().add(1, 'days'))
        .setEndDate(ApplicationConfig.roundMinute().add(1, 'week').startOf('day'));

    constructor(public ref: MatDialogRef<TrainingAccessRequestComponent>) {
        super();
        this.initializeControls();
    }

    afterInit() {}

    private initializeControls(): void {
        ApplicationConfig.geometries
            .get(GenOutageAggregationLevel.county)
            .sort((a, b) => {
                if (a.name > b.name) return 1;
                else if (a.name < b.name) return -1;
                return 0;
            })
            .forEach((county) => {
                const value = this.countyByState.get(county.stateId) || [];
                const minimalCounty = {
                    fipsCode: county.fipsCode,
                    name: county.name.split(DataService.geometryNameSeparator)[1],
                    stateId: county.stateId,
                    countyId: county.countyId,
                    selected: true,
                };
                value.push(minimalCounty);
                this.countyByState.set(county.stateId, value);
            });

        const controls = {
            dates: new FormControl<CardFilters>(this.defaultDates, {validators: [Validators.required]}),
            aggregation: new FormControl<GenOutageAggregationLevel>(GenOutageAggregationLevel.county, {validators: [Validators.required]}),
            locations: new FormControl<[]>(null, {validators: [Validators.required]}),
            reason: new FormControl<string>('', {validators: [Validators.required]}),
        };

        this.optionGroup = new FormGroup(controls);
    }

    public updateDates(event: CardFilters): void {
        this.optionGroup.patchValue({
            dates: event,
        });
    }

    public updateLocations(event: State[]): void {
        this.optionGroup.patchValue({
            locations: event,
        });
    }

    public sendRequest(): void {
        const countyIds = this.optionGroup.controls.locations.value
            .map((state) => this.countyByState.get(state.id))
            .reduce((selected, counties) => selected.concat(...counties.filter((county) => county.selected)), [])
            .map((county) => county.countyId);

        const requestAccess = new GenTrainingAccessRequest({
            startDate: this.optionGroup.controls.dates.value.startDate,
            endDate: this.optionGroup.controls.dates.value.endDate,
            aggregationLevel: this.optionGroup.controls.aggregation.value,
            locationIds: countyIds,
            reason: this.optionGroup.controls.reason.value,
        });

        const responseHandlers = {
            next: () => {
                this.snackbar.open('Your request has been sent', 'x', {panelClass: 'dialog-success', duration: 3_000});
                this.ref.close();
            },
            error: (error: any) => {
                console.error(error);
                this.snackbar.open('Failed to send your request', 'x', {panelClass: 'dialog-failure', duration: 3_000});
            },
        };

        this.emailService.requestTrainingAccess(requestAccess).subscribe(responseHandlers);
    }

    public selectAllCounties(stateId: number) {
        this.countyByState.get(stateId).forEach((county) => (county.selected = true));
    }

    public clearAllCounties(stateId: number) {
        this.countyByState.get(stateId).forEach((county) => (county.selected = false));
    }
}
