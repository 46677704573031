import {GenUrbanNetEffect} from '../../../../generated/serverModels/GenUrbanNetEffect';
import {getUrbannetAssetProperties} from '../functions/get-urbannet-properties';
import {PopoverElement} from '../../../app/classes/popover-element';
import {getUrbannetAssetPopover} from '../functions/get-urbannet-asset-popover';

export class UrbannetEffect extends GenUrbanNetEffect {
    public layerType: string;
    public parsedProperties: any;
    public popoverData: PopoverElement[];

    constructor(effect?: UrbannetEffect) {
        super(effect);

        if (effect) {
            this.layerType = effect.nodeId.split(':')[0];
            this.parsedProperties = getUrbannetAssetProperties(effect);
            this.popoverData = getUrbannetAssetPopover(effect);
        }
    }
}
