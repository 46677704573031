import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {from, Observable} from 'rxjs';
import {Router} from '@angular/router';
import {Report} from '../classes/report';
import {MatDialog} from '@angular/material/dialog';
import {ReportGroup} from '../classes/report-group';
import {ResponseWrapper} from '../../../classes/response-wrapper';
import * as moment from 'moment';
import {Coverage} from '../classes/coverage';
import {ApiKey} from '../../api-key/classes/api-key';
import {UtilityFeed} from '../classes/utility-feed';
import {StateCoverage} from '../classes/state-coverage';
import {MaxUtility} from '../classes/max-utility';
import {AlertFeed} from '../classes/alert-feed';
import {RecentEvent} from '../../recent-events/classes/recent-event';
import {DataInfoComponent} from '../../../../shared/modals/data-info/data-info.component';
import {UtilityOutage} from '../classes/utility-outage';
import {UtilityError} from '../classes/utility-error';
import {Subutility} from '../../management/classes/subutility';
import {OutageSummary} from '../../management/classes/outage-summary';
import {GenEOCOverride} from 'frontend/generated/serverModels/GenEOCOverride';
import {GenEOCCountyWithUtilities} from 'frontend/generated/serverModels/GenEOCCountyWithUtilities';
import {GenEOCUtilityWithCounties} from 'frontend/generated/serverModels/GenEOCUtilityWithCounties';
import {GenEOCOverrideMassUpdate} from 'frontend/generated/serverModels/GenEOCOverrideMassUpdate';
import {GenDataQuality} from 'frontend/generated/serverModels/GenDataQuality';
import {OutageComparison} from '../classes/outage-comparison';
import {GenEOCStateCollection} from 'frontend/generated/serverModels/GenEOCStateCollection';
import {OverrideUser} from '../../management/classes/override-user';
import {RecentEventSource} from '../../recent-events/classes/recent-event-source';
import {ModalConfig} from 'frontend/src/app/classes/modal-config';
import {GenOutageAggregationLevel} from '../../../../../generated/serverModels/GenOutageAggregationLevel';
import {OutageSnapshotDate} from '../classes/outage-snapshot-date';
import {GenSnapshotRequest} from '../../../../../generated/serverModels/GenSnapshotRequest';
import {State} from '../../outage/classes/state';
import {PortStatus} from '../../layer/classes/port-status';
import {GenSystemEvent} from '../../../../../generated/serverModels/GenSystemEvent';
import {ApplicationConfig} from 'frontend/src/app/classes/application-config';
import {GenReportRequestGroup} from '../../../../../generated/serverModels/GenReportRequestGroup';
import {UtilityErrorParent} from '../classes/utility-error-parent';
import {SystemEventType} from '../../system-event/classes/system-event-type';
import {User} from '../../user/classes/user';
import {GenCommunityPermission} from '../../../../../generated/serverModels/GenCommunityPermission';
import {SystemEvent} from '../../system-event/classes/system-event';
import {GenEOCStateOverride} from '../../../../../generated/serverModels/GenEOCStateOverride';
import {GenEOCCountyCurrentOverride} from '../../../../../generated/serverModels/GenEOCCountyCurrentOverride';

@Injectable({
    providedIn: 'root',
})
export class ReportService {
    static reports: {[key: string]: Report} = {};

    constructor(private httpClient: HttpClient, private dialog: MatDialog, private router: Router) {}

    private readonly baseReportUrl = 'api/provider-group';
    private readonly feedUrl = '/api/outage/feed';
    private readonly recentEventUrl = '/api/recentevent';
    private readonly coverageUrl = '/api/coverage/history';
    private readonly utilityFeedUrl = '/api/outages/error/duplicates';
    private readonly utilityErrorUrl = '/api/outages/error/status';
    private utilitySummary = 'api/outagesummary/utilitymax24hoursummary';
    private readonly eocEndpoint = 'api/eocoverride';
    private readonly eventEndpoint = 'api/event';
    private readonly restorationEndpoint = 'api/outage/restoration';
    private readonly portStatusUrl = 'api/ports';

    private readonly utilityChartUrl: string = 'api/outagesummary/utilityoutagedata';

    private fillReportData(data: ReportGroup[]) {
        data.forEach((group) => {
            (group.dataProviders as Report[]).forEach((report) => {
                ReportService.reports[report.serviceurl] = report;
            });
        });
    }

    getAllReports(showInactive: boolean = false): Observable<ReportGroup[]> {
        const url = `${this.baseReportUrl}?showinactive=${showInactive}`;

        return this.httpClient.get<ResponseWrapper<ReportGroup>>(url).pipe(
            map((res: ResponseWrapper<ReportGroup>) => {
                this.fillReportData(res.data);
                return res.data.map((group) => new ReportGroup(group));
            })
        );
    }

    getReportData(): Observable<Report> {
        const segmentedUrl: string[] = this.router.url.split('/');
        const endpoint: string = segmentedUrl[segmentedUrl.length - 1];

        const mf = (res: ResponseWrapper<ReportGroup>) => {
            this.fillReportData(res.data);
            return ReportService.reports[endpoint];
        };

        if (Object.keys(ReportService.reports).length === 0) {
            return this.httpClient.get<ResponseWrapper<ReportGroup>>(`${this.baseReportUrl}?type=Report`).pipe(map(mf));
        } else {
            return from([ReportService.reports[endpoint]]);
        }
    }

    public getFeedData(start: moment.Moment, end: moment.Moment): Observable<UtilityFeed[]> {
        const url = `${this.feedUrl}?start=${start.startOf('day').format()}&end=${end.endOf('day').format()}`;
        return this.httpClient.get<ResponseWrapper<UtilityFeed>>(url).pipe(map((res) => res.data));
    }

    public getAlertFeed(start: moment.Moment, end: moment.Moment, includeAllActive: boolean = true): Observable<AlertFeed[]> {
        const url = `api/alert/alerthistory?start=${start.startOf('day').format()}&end=${end
            .endOf('day')
            .format()}&includeAllActive=${includeAllActive}`;
        return this.httpClient.get<ResponseWrapper<AlertFeed>>(url).pipe(map((res) => res.data.map((alert) => new AlertFeed(alert))));
    }

    public getRecentEventData(sourceId: number, start: moment.Moment, end: moment.Moment): Observable<ResponseWrapper<RecentEvent>> {
        const url = `${this.recentEventUrl}/events?start=${start.startOf('day').format()}&end=${end
            .endOf('day')
            .format()}&sourceId=${sourceId}`;
        const mf = (rw: ResponseWrapper<any>) => {
            rw.data = rw.data.map((event) => new RecentEvent(event));
            return rw;
        };
        return this.httpClient.get<ResponseWrapper<RecentEvent>>(url).pipe(map(mf));
    }

    public getRecentEventAttribution(sourceId: number): Observable<RecentEventSource[]> {
        const url = `${this.recentEventUrl}/datasource?sourceType=${sourceId}`;
        return this.httpClient.get<RecentEventSource[]>(url).pipe(map((res) => res.map((s) => new RecentEventSource(s))));
    }

    public getRecentEventSourceData(sourceId: number, name: string, width: number = 1000) {
        this.getRecentEventAttribution(sourceId).subscribe((res) => {
            width = Math.min(1000, width);
            this.dialog.open(DataInfoComponent, {
                width: `${width}px`,
                data: {sources: res, hasType: false, name},
            });
        });
    }

    public getStateCoverage(start: moment.Moment, end: moment.Moment): Observable<StateCoverage[]> {
        const url = `api/coverage/statehistory?start=${start.format()}&end=${end.format()}`;
        return this.httpClient.get<ResponseWrapper<StateCoverage>>(url).pipe(map((res) => res.data.map((c) => new StateCoverage(c))));
    }

    public getCoverage(start: moment.Moment, end: moment.Moment): Observable<Coverage[]> {
        const url = `${this.coverageUrl}?start=${start.startOf('day').format()}&end=${end.endOf('day').format()}`;

        const mf = (res: ResponseWrapper<Coverage>) => {
            const sorted = res.data
                .map((c) => new Coverage(c))
                .sort((a: Coverage, b: Coverage) => moment(b.coverageDate).valueOf() - moment(a.coverageDate).valueOf());

            sorted.forEach((c: Coverage, i: number) => {
                if (i !== sorted.length - 1) {
                    const difference = sorted[i + 1].coveredCustomers - c.coveredCustomers;
                    if (difference === 0) {
                        return;
                    }
                    c.trendDirection = difference < 0 ? 'Up' : 'Down';
                }
            });
            return sorted;
        };

        return this.httpClient.get<ResponseWrapper<Coverage>>(url).pipe(map(mf));
    }

    public getUtilityFeed(start: moment.Moment, end: moment.Moment, threshold: number): Observable<UtilityError[]> {
        const url = `${this.utilityFeedUrl}?start=${start.startOf('day').format()}&end=${end.endOf('day').format()}&limit=${threshold}`;
        return this.httpClient.get<ResponseWrapper<UtilityError>>(url).pipe(map((res) => res.data.map((e) => new UtilityError(e))));
    }

    public getUtilityStatusErrors(start: moment.Moment, end: moment.Moment): Observable<UtilityErrorParent[]> {
        const url = `${this.utilityErrorUrl}?start=${start.format()}&end=${end.format()}`;
        return this.httpClient
            .get<ResponseWrapper<UtilityErrorParent>>(url)
            .pipe(map((res) => res.data.map((d) => new UtilityErrorParent(d))));
    }

    public getUtilityErrors(eagleiId: number, start: moment.Moment, end: moment.Moment): Observable<ResponseWrapper<any>> {
        const url = `api/outages/error/utilityerrors?eagleiid=${eagleiId}&start=${start.format()}&end=${end.format()}`;
        return this.httpClient.get<any>(url);
    }

    public getIntervaledErrors(start: moment.Moment, end: moment.Moment): Observable<ResponseWrapper<any>> {
        const url = `api/outages/error/intervalederrors?start=${start.format()}&end=${end.format()}`;
        return this.httpClient.get<any>(url);
    }

    public getTableUtilities(
        date: moment.Moment,
        aggregation: GenOutageAggregationLevel = GenOutageAggregationLevel.state
    ): Observable<MaxUtility[]> {
        let url = `${this.utilitySummary}?start=${date.format()}`;
        if (aggregation === GenOutageAggregationLevel.county) {
            url += '&showByCounty=true';
        }

        const mf = (res: ResponseWrapper<MaxUtility>) => {
            return res.data
                .map((u) => new MaxUtility(u))
                .sort((a, b) => {
                    const valA = a.currentOutage !== undefined ? a.currentOutage : -1;
                    const valB = b.currentOutage !== undefined ? b.currentOutage : -1;
                    if (valA === valB) {
                        return 0;
                    }
                    return valA > valB ? -1 : 1;
                });
        };

        return this.httpClient.get<ResponseWrapper<MaxUtility>>(url).pipe(map(mf));
    }

    // The ignore locked time is used for the UCOOT chart mdal
    public getChartUtilities(
        start: moment.Moment,
        end: moment.Moment,
        aggregation: GenOutageAggregationLevel = GenOutageAggregationLevel.state
    ): Observable<ResponseWrapper<UtilityOutage>> {
        let url = `${this.utilityChartUrl}?start=${start.format()}&end=${end.format()}&usebreaks=true`;
        if (aggregation === GenOutageAggregationLevel.county) {
            url += '&showByCounty=true';
        }

        return this.httpClient.get<ResponseWrapper<UtilityOutage>>(url).pipe(
            map((res) => {
                const rw = new ResponseWrapper(res);
                rw.data = rw.data.map((u) => new UtilityOutage(u));
                return rw;
            })
        );
    }

    public getApiKeys(): Observable<ApiKey[]> {
        const url = 'api/apikey';
        return this.httpClient.get<ApiKey[]>(url).pipe(map((keys) => keys.map((key) => new ApiKey(key))));
    }

    public updateReport(report: Report): Observable<any> {
        const url = '/api/dataprovider/report';
        return this.httpClient.put(url, report);
    }

    public deleteReport(report: Report): Observable<any> {
        const url = `/api/dataprovider/report/${report.id}`;
        return this.httpClient.delete(url);
    }

    public getSubUtilities(): Observable<Subutility[]> {
        return this.httpClient.get<Subutility[]>(`/api/utility/subutility`);
    }

    public updateSubUtility(utility: Subutility): Observable<Subutility> {
        return this.httpClient.put<Subutility>(`/api/utility/subutility`, utility);
    }

    public getOutageData(startDate: moment.Moment, endDate: moment.Moment): Observable<OutageSummary[]> {
        return this.httpClient.get<OutageSummary[]>(
            `/api/outagesummary/outageSummaryErrorByDate?start=${startDate.format()}&end=${endDate.format()}`
        );
    }

    // EOC APIS
    public getEOCCounties(stateAbbreviation: string): Observable<GenEOCCountyWithUtilities[]> {
        return this.httpClient.get<GenEOCCountyWithUtilities[]>(`${this.eocEndpoint}/countyutilitycoverage?state=${stateAbbreviation}`);
    }

    public getEOCUtilitiesMultiState(states: string[]): Observable<GenEOCUtilityWithCounties[]> {
        let url = `${this.eocEndpoint}/utilitycountycoverage?`;
        states.forEach((s) => (url += 'state=' + s + '&'));
        return this.httpClient.get<GenEOCUtilityWithCounties[]>(url);
    }

    public updateEOCOverride(eocOverride: GenEOCOverride): any {
        return this.httpClient.post(this.eocEndpoint, eocOverride);
    }

    public updateEOCOverrideMassUpdate(eocOverrides: GenEOCOverrideMassUpdate): any {
        return this.httpClient.post(`${this.eocEndpoint}/massupdate`, eocOverrides);
    }

    public updateEOCOverrideDetails(eocOverride: GenEOCOverride): any {
        return this.httpClient.post(`${this.eocEndpoint}/details`, eocOverride);
    }

    public getEOCOverride(
        start: moment.Moment,
        end: moment.Moment,
        userId: number,
        stateAbbreviation?: string
    ): Observable<GenEOCOverride[]> {
        let url = `${this.eocEndpoint}/getOverridesForUser?startTime=${start.format()}&endTime=${end.format()}&userId=${userId}`;
        url = stateAbbreviation ? url + `&state=${stateAbbreviation}` : url;
        return this.httpClient.get<GenEOCOverride[]>(url);
    }

    public getOverridesForEvent(event: GenSystemEvent): Observable<GenEOCOverride[]> {
        const start = event.eventStart;
        const end = event?.eventEnd || ApplicationConfig.roundMinute().add(3, 'days');
        const url = `${this.eocEndpoint}?startTime=${start.format()}&endTime=${end.format()}&eventId=${event.id}`;

        return this.httpClient.get<GenEOCOverride[]>(url);
    }

    public deleteEOCOverride(eocOverride: GenEOCOverride): any {
        return this.httpClient.delete(`${this.eocEndpoint}/${eocOverride.id}`);
    }

    public deleteEOCOverrides(eocOverrides: GenEOCOverride[]): any {
        const options = {
            body: eocOverrides,
        };

        return this.httpClient.delete(`${this.eocEndpoint}/batch`, options);
    }

    public getEOCEvents(): Observable<ResponseWrapper<SystemEvent>> {
        return this.httpClient.get<ResponseWrapper<any>>(`${this.eventEndpoint}/by-date`).pipe(
            map((res) => {
                res.data = res.data.map((event) => new SystemEvent(event));
                return res;
            })
        );
    }

    public getEOCEventsByDate(startTime: moment.Moment, endTime: moment.Moment): Observable<SystemEvent[]> {
        const url = `${this.eventEndpoint}/by-date?start=${startTime.format()}&end=${endTime.format()}`;
        return this.httpClient.get<ResponseWrapper<any>>(url).pipe(map((res) => res.data.map((event) => new SystemEvent(event))));
    }

    public updateEOCEvent(event: GenSystemEvent): Observable<number> {
        return this.httpClient.put<number>(`${this.eventEndpoint}`, event);
    }

    public endSystemEvent(event: SystemEvent, endDate: moment.Moment = ApplicationConfig.roundMinute()): Observable<any> {
        const url = `api/event/stop/${event.id}?endDate=${endDate.format()}`;
        return this.httpClient.put(url, null);
    }

    public getCommunityUsers(permission: GenCommunityPermission): Observable<User[]> {
        const url = `api/permission/community/users?permission-name=${permission.toString()}`;
        return this.httpClient.get<any[]>(url).pipe(map((users) => users.map((user) => new User(user))));
    }

    public getTrainedOverrideUsers(): Observable<OverrideUser[]> {
        return this.httpClient
            .get<ResponseWrapper<any>>(`${this.eocEndpoint}/overridetrainedusers`)
            .pipe(map((res) => res.data.map((user) => new OverrideUser(user))));
    }

    public getOutageComparisons(start: moment.Moment, end: moment.Moment): Observable<OutageComparison[]> {
        const url = `${this.eocEndpoint}/utility?start=${start.format()}&end=${end.format()}`;
        return this.httpClient.get<ResponseWrapper<OutageComparison>>(url).pipe(
            map((res) => {
                return res.data.map((comparison) => new OutageComparison(comparison));
            })
        );
    }

    public deleteEvent(eventId: number): any {
        return this.httpClient.delete(`${this.eventEndpoint}/${eventId}`);
    }

    /**
     * @Deprecated use SystemEventService.getEventTypes
     */
    public getOutageEventTypes(): Observable<SystemEventType[]> {
        return this.httpClient
            .get<any[]>(`${this.eventEndpoint}/types`)
            .pipe(map((types) => types.map((type) => new SystemEventType(type))));
    }

    public getActiveHOEvents(): Observable<any> {
        return this.httpClient.get(`api/headout/event?active=true`);
    }

    public getHOEventTypes(): Observable<any> {
        return this.httpClient.get(`api/headout/event-types-headout`);
    }

    public createHOEvent(
        headoutID: string,
        systemEventId: number,
        eventName: string,
        hoType: number,
        active: boolean,
        start: moment.Moment,
        end: moment.Moment
    ): Observable<any> {
        let params = `headoutEventId=${headoutID}&systemEventId=${systemEventId}&name=${eventName}&type=${hoType}&active=${active}&start=${start.format()}`;
        if (!!end) {
            params += `&end${end.format()}`;
        }
        return this.httpClient.get(`api/headout/create-event-headout?${params}`);
    }

    public assignHOEvent(hoEventId: number, systemEventId: number): Observable<any> {
        return this.httpClient.get(`api/headout/assign-headout-system-event-id?headoutEventId=${hoEventId}&systemEventId=${systemEventId}`);
    }

    public unassignHOEvent(hoEventId: number): Observable<any> {
        return this.httpClient.get(`api/headout/clear-headout-system-event-id?headoutEventId=${hoEventId}`);
    }

    public assignUserEvent(userid: number, eventid: number, accessLevel: string): Observable<any> {
        return this.httpClient.post(`${this.eventEndpoint}/user?userId=${userid}&eventId=${eventid}&accessLevel=${accessLevel}`, '');
    }

    public removeUserEvent(userid: number, eventid: number, accessLevel: string): Observable<any> {
        return this.httpClient.delete(`${this.eventEndpoint}/user?userId=${userid}&eventId=${eventid}&accessLevel=${accessLevel}`);
    }

    public assignCountyEvent(countyid: number, eventid: number): Observable<any> {
        return this.httpClient.post(`${this.eventEndpoint}/county?countyId=${countyid}&eventId=${eventid}`, '');
    }

    public removeCountyEvent(countyid: number, eventid: number): Observable<any> {
        return this.httpClient.delete(`${this.eventEndpoint}/county?countyId=${countyid}&eventId=${eventid}`);
    }

    public getStateCollection(): Observable<GenEOCStateCollection[]> {
        return this.httpClient
            .get<any[]>(`${this.eocEndpoint}/statecollection`)
            .pipe(map((collections) => collections.map((collection) => new GenEOCStateCollection(collection))));
    }

    public getStateOverrides(collection: GenEOCStateCollection, includeClosed?: boolean): Observable<GenEOCStateOverride[]> {
        const params = new HttpParams().set('stateId', collection.stateId);
        return this.httpClient
            .get<any[]>(`${this.eocEndpoint}/stateoverride`, {params})
            .pipe(map((overrides) => overrides.map((override) => new GenEOCStateOverride(override))));
    }

    public getCurrentCountyOverrides(collection: GenEOCStateCollection): Observable<GenEOCCountyCurrentOverride[]> {
        const params = new HttpParams().set('stateId', collection.stateId);
        return this.httpClient
            .get<any[]>(`${this.eocEndpoint}/countycurrentoverridebystate`, {params})
            .pipe(map((overrides) => overrides.map((override) => new GenEOCCountyCurrentOverride(override))));
    }

    public toggleCollectionActive(collection: GenEOCStateCollection): Observable<any> {
        const status = collection.active ? 'activate' : 'deactivate';
        const params = new HttpParams().set('status', status).set('stateCollectionId', collection.id);

        return this.httpClient.put(`${this.eocEndpoint}/statecollection`, undefined, {params});
    }

    public getRestorationData(
        start: moment.Moment,
        end: moment.Moment,
        level: string,
        filterList?: (string | number)[],
        hours?: number
    ): Observable<ResponseWrapper<any>> {
        let filterStr = '';
        filterList.forEach((s) => (filterStr += `&${level}=${s}`));
        return this.httpClient.get<ResponseWrapper<any>>(
            `${this.restorationEndpoint}?start=${start.format()}&end=${end.format()}${filterStr}&hours=${hours}`
        );
    }

    public openAttribution(attribution: string): void {
        if (attribution.split(',').length > 1) {
            this.dialog.open(DataInfoComponent, {
                data: {
                    sources: [
                        ...attribution.split(',').map((attr) => {
                            return {attributionUrl: attr};
                        }),
                    ],
                },
                width: ModalConfig.getModalWidth(),
            });
        } else {
            console.info('attribution', attribution);
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.target = '_blank';
            a.href = attribution;

            setTimeout(() => {
                a.click();
                a.remove();
            }, 0);
        }
    }

    /**
     * Pins a report to the homepage
     * @param reportId The id of the report to be pinned.
     */
    public pinReport(reportId: number): Observable<any> {
        const url = `api/dataprovider/pin/${reportId}`;
        return this.httpClient.put(url, undefined);
    }

    /**
     * Unpins a report from the homepage
     * @param reportId The id of the report to be unpinned.
     */
    public unpinReport(reportId: number): Observable<any> {
        const url = `api/dataprovider/unpin/${reportId}`;
        return this.httpClient.put(url, undefined);
    }

    /**
     * @deprecated use OngService.getNgNotices()
     */
    public naturalGasData(startDate: moment.Moment, endDate: moment.Moment): Observable<any> {
        const url = `api/mapservices/proxy?url=ONG_URL/natural-gas/pipeline-notices?return_count=false&return_geom=false&start_date=${startDate.format(
            'MM-DD-YYYY'
        )}&end_date=${endDate.format('MM-DD-YYYY')}`;
        const opts = {
            headers: new HttpHeaders().set('X-Api-Key', 'ONG_KEY'),
        };
        return this.httpClient.get(url, opts);
    }

    public portStatusData(returnGeom: boolean = true, eventId?: number): Observable<PortStatus[]> {
        const url = this.portStatusUrl;
        let params: HttpParams = new HttpParams().set('returnGeometry', returnGeom);
        if (eventId) {
            params = params.append('eventId', eventId);
        }

        return this.httpClient.get<any[]>(url, {params}).pipe(map((ports) => ports.map((port) => new PortStatus(port))));
    }

    public mappingDataFilter(mappable: boolean = true): Observable<PortStatus[]> {
        const url = `${this.portStatusUrl}?mappableGeometry=${mappable}`;
        return this.httpClient.get<any[]>(url);
    }

    public getDataQuality(startDate: moment.Moment, endDate: moment.Moment): Observable<ResponseWrapper<GenDataQuality>> {
        const url = `api/utility/data-quality?start=${startDate.format()}&end=${endDate.format()}`;
        return this.httpClient.get<ResponseWrapper<GenDataQuality>>(url);
    }

    public getOutageSnapshot(selectedFilters: any): Observable<OutageSnapshotDate[]> {
        const url = `api/outage/snapshot`;

        const body = new GenSnapshotRequest();
        body.aggregationLevel = selectedFilters.aggregationLevel;
        body.startDate = selectedFilters.startDate.utc();
        body.endDate = selectedFilters.endDate.utc();
        body.hourInterval = selectedFilters.interval;
        body.stateIds = (selectedFilters.states as State[]).map((s) => s.id);

        return this.httpClient
            .post<ResponseWrapper<any>>(url, body)
            .pipe(map((res) => res.data.map((snapshot) => new OutageSnapshotDate(snapshot))));
    }

    public createReportEmailRequests(group: GenReportRequestGroup): Observable<any> {
        const url = 'api/systemdata/create-report-request';
        return this.httpClient.post(url, group);
    }

    public getMaintenanceEvents(active_date: moment.Moment): Observable<any> {
        const url = `${ApplicationConfig.proxyPrefix}ONG_URL/natural-gas/pipeline-maintenance?return_geom=false&active_date=${moment(
            active_date
        ).format('M-D-YYYY')}`;
        const opts = {
            headers: new HttpHeaders().set('X-Api-Key', 'ONG_KEY'),
        };
        return this.httpClient.get(url, opts);
    }
}
