<div class="dialog-header">
    Create New Alert
    <button mat-icon-button (click)="dialogRef.close()">
        <mat-icon fontSet="fas" fontIcon="fa-times"></mat-icon>
    </button>
</div>

<mat-dialog-content class="dialog-content" #content>
    <div [formGroup]="alertControls">
        <ng-container *ngFor="let group of alertControls.controls | keyvalue; let formCount = count; trackBy: trackByKey">
            <form class="alert-form" [formGroupName]="group.key">
                <mat-form-field>
                    <mat-label>Location</mat-label>
                    <mat-select formControlName="location" (selectionChange)="updateLocation($event, group.key)">
                        <mat-optgroup label="FEMA Regions">
                            <mat-option *ngFor="let region of femaRegions" [value]="region">
                                <span>{{ region | numeral : true }}</span>
                                <span class="customer-number">({{ femaNumbers[region] | shortNumber }})</span>
                            </mat-option>
                        </mat-optgroup>

                        <mat-optgroup label="States/Territories">
                            <mat-option *ngFor="let state of states; trackBy: trackByStateId" [value]="state">
                                <span>{{ state.name }}</span>
                                <span class="customer-number">({{ stateNumbers[state.id] | shortNumber }})</span>
                            </mat-option>
                        </mat-optgroup>
                    </mat-select>
                </mat-form-field>

                <mat-form-field [class.hide]="!selectedState[group.key]">
                    <mat-label>County</mat-label>
                    <mat-select multiple #county="matSelect" formControlName="county" placeholder="Select County" [disable]="disableCounty">
                        <mat-select-trigger class="county-select-trigger">
                            <!-- Handles filtering out select-all's undefined value (value[0]) -->
                            <span>{{ (county.value?.[0] ?? county.value?.[1])?.name ?? '' }}</span>
                            <span *ngIf="(county.value?.length || 0) > 1">
                                (+{{ county.value?.length || 0 | suffixCount : county.options.length }})
                            </span>
                        </mat-select-trigger>
                        <mat-option selectAll [allOptions]="countyCache[selectedState[group.key]]">Select All</mat-option>
                        <mat-option *ngFor="let county of countyCache[selectedState[group.key]]" [value]="county">
                            <span>{{ county.name }}</span>
                            <span class="customer-number">({{ countyNumbers[county.id] | shortNumber }})</span>
                        </mat-option>
                    </mat-select>

                    <mat-hint>Optional</mat-hint>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Type</mat-label>
                    <mat-select formControlName="thresholdType">
                        <mat-option *ngIf="showPercentOption(group.key)" [value]="'percent'">Percentage</mat-option>
                        <mat-option value="outage">Outage</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Threshold</mat-label>
                    <input
                        matInput
                        placeholder="Outage Threshold"
                        formControlName="threshold"
                        min="0"
                        name="thresholdValue"
                        type="number" />
                    <span *ngIf="group.value.value.thresholdType === 'percent'" matSuffix>%</span>
                    <mat-error
                        >{{ group.value.controls.threshold.errors?.['min'] ? 'Value cannot be negative' : 'A threshold value is required.' }}</mat-error
                    >
                </mat-form-field>

                <button mat-icon-button type="button" [class.hide]="formCount < 2" (click)="removeForm(group.key)">
                    <mat-icon fontSet="fas" fontIcon="fa-trash"></mat-icon>
                </button>
            </form>

            <mat-checkbox
                color="primary"
                [class.hide]="!(group.value.value.location | isFemaRegion)"
                [formControl]="group.value.controls.applyToStates">
                Apply threshold individually to the states within the FEMA region
            </mat-checkbox>
        </ng-container>
    </div>

    <div class="another-location">
        <mat-icon inline fontSet="fas" fontIcon="fa-plus" class="clickable" (click)="addLocation()"></mat-icon>
        <span class="clickable" (click)="addLocation()">ADD ANOTHER LOCATION</span>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end" class="dialog-actions">
    <button [disabled]="alertControls.pristine || alertControls.invalid" mat-flat-button color="primary" type="submit" (click)="addAlert()">
        Add New Alert
    </button>
</mat-dialog-actions>

<ng-template #invalidDialog let-data>
    <div class="dialog-header">
        Alert{{ data.invalid.length > 1 ? 's' : '' }} Not Created
        <button mat-icon-button (click)="invalidRef?.close()">
            <mat-icon fontSet="fas" fontIcon="fa-times"></mat-icon>
        </button>
    </div>

    <mat-dialog-content>
        Due to preexisting subscription{{ data.invalid.length > 1 ? 's' : '' }}, unable to create alert{{
            data.invalid.length > 1 ? 's' : ''
        }}
        for the following:
        <mat-list>
            <ng-container *ngFor="let item of data.invalid; let last = last">
                <mat-list-item>
                    <span matListItemTitle>{{ item.description ?? item.locationName }}</span>
                    <span matListItemMeta [ngSwitch]="item.thresholdType">
                        <ng-container *ngSwitchCase="'percent'">{{ item.threshold }}%</ng-container>
                        <ng-container *ngSwitchDefault>{{ item.threshold | shortNumber }}</ng-container>
                    </span>
                    <span *ngIf="item.description" matListItemLine>{{ item.locationName }}</span>
                </mat-list-item>
                <mat-divider *ngIf="!last"></mat-divider>
            </ng-container>
        </mat-list>
    </mat-dialog-content>
</ng-template>
