import {AfterViewInit, Component, ElementRef, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {RecentEventSource} from '../../../app/modules/recent-events/classes/recent-event-source';
import {GenDataSource} from '../../../../generated/serverModels/GenDataSource';
import {FileDownload} from '../../../app/classes/file-download';
import {BaseModal} from '../../../app/classes/base-modal';

@Component({
    selector: 'eaglei-data-info',
    templateUrl: './data-info.component.html',
    styleUrls: ['./data-info.component.scss'],
})
export class DataInfoComponent extends BaseModal implements AfterViewInit {
    // HTML Elements
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild('content', {static: true}) content: ElementRef;

    // DataSource Properties
    public dataSource: MatTableDataSource<GenDataSource | RecentEventSource>;
    public columnNames = ['sourceName', 'attributionUrl', 'type'];
    private readonly hasTypeField: boolean;

    // Source Properties
    public name: string;

    // The Array syntax is needed here because of the union type
    // tslint:disable-next-line:array-type
    private readonly sources: Array<GenDataSource | RecentEventSource> = [];

    constructor(public dialogRef: MatDialogRef<DataInfoComponent>, public element: ElementRef, @Inject(MAT_DIALOG_DATA) public data: any) {
        super();
        this.sources = data.sources;
        this.name = data.name || 'Data';
        this.hasTypeField = data.hasType;
        if (!data.hasType) {
            this.columnNames.pop();
        }

        if (this.sources.every((s: any) => s.description === undefined && s.name === undefined)) {
            this.columnNames.splice(0, 1);
        }
    }

    afterInit() {
        this.initializeTable(this.sources);
    }

    /**
     * Initializes The dataSource for the table
     * @param data The data that will be in the table.
     */
    private initializeTable(data: any[]) {
        this.dataSource = new MatTableDataSource<GenDataSource | RecentEventSource>(data);
        this.dataSource.sortingDataAccessor = this.sortDataAccessor;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
    }

    // noinspection JSMethodCanBeStatic
    /**
     * Determines how the data is sorted in the tabled based on the sort direction and the column name
     * @param data The row being sorted.
     * @param sortHeaderId The column name that is being sorted on.
     */
    private sortDataAccessor(data: GenDataSource | RecentEventSource, sortHeaderId: string): string | number {
        switch (sortHeaderId) {
            case 'sourceName':
                if ((data as GenDataSource).name) {
                    return (data as GenDataSource).name.toLowerCase();
                }
                if ((data as RecentEventSource).description) {
                    return (data as RecentEventSource).description.toLowerCase();
                }
                return '';
            case 'attributionUrl':
                return data.attributionUrl ? data.attributionUrl.toLowerCase() : 'n/a';
            case 'type':
                return (data as GenDataSource).type.toString();
            default:
                return '';
        }
    }

    /**
     * Exports the table data as a CSV file
     */
    public exportData(): void {
        const filename: string = `${this.name}SourceInformation`.replace(' ', '');
        let data: string = ['Name', 'Attribution URL'].join();
        if (this.hasTypeField) {
            data += ',Type';
        }
        data += '\n';

        this.dataSource._orderData(this.dataSource.filteredData).forEach((s) => {
            const values = [
                FileDownload.formatCsvCell((s as GenDataSource).name || (s as RecentEventSource).description),
                FileDownload.formatCsvCell(s.attributionUrl),
            ];
            if (this.hasTypeField) {
                values.push(FileDownload.formatCsvCell((s as GenDataSource).type.toString()));
            }
            data += values.join() + '\n';
        });
        FileDownload.downloadCSV(filename, data);
    }
}
