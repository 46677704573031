import {Component, inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BaseModal} from '../../../app/classes/base-modal';

@Component({
    selector: 'eaglei-info-modal',
    templateUrl: './info-modal.component.html',
    styleUrls: ['./info-modal.component.scss'],
})
export class InfoModalComponent extends BaseModal {
    public name: string;
    public attributionModalText: string;
    public widgetName: string;
    public dialogRef = inject(MatDialogRef<InfoModalComponent>);
    public data: any = inject(MAT_DIALOG_DATA);

    constructor() {
        super();
        this.widgetName = this.data.name;
        this.attributionModalText = this.data.message;
    }

    afterInit() {}
}
