import {LeafletVectorSource} from '../../../modules/layer/classes/leaflet-vector-source';
import {LeafletMapLayer} from '../../../modules/layer/classes/leaflet-map-layer';
import {LeafletFeature} from '../../../modules/layer/classes/leaflet-feature';
import * as L from 'leaflet';
import {MapService} from '../../../modules/map/services/map.service';
import {FeatureStyle} from '../../../modules/layer/interfaces/style.interface';

export class HeadoutInfrastructureSource extends LeafletVectorSource {
    constructor(layerInfo: LeafletMapLayer) {
        super(layerInfo);
        this.showAllPopoverData = false;
    }

    processFeatures(features: any[]): void {
        this.leafletFeatures = features
            ?.map((v) => {
                const feature = new LeafletFeature().convert(v, 'geom');
                if (feature) {
                    feature.buildPopover(this.layerInfo);
                }
                return feature;
            })
            .filter((feature) => !!feature);

        const config: any = {
            pane: MapService.layerPaneName,
            onEachFeature: this.initializePopoverInteractions.bind(this),
        };

        switch (this.layerInfo.uiHandle) {
            case 'headout_substations':
                config.pointToLayer = (feature: LeafletFeature, latlng: L.LatLng) =>
                    (L as any).shapeMarker(latlng, this.getSubstationStyle());
                break;
            case 'headout_powerPlants':
                config.pointToLayer = (feature: LeafletFeature, latlng: L.LatLng) =>
                    // @ts-ignore
                    L.circleMarker(latlng, this.getPowerPlantStyle(feature.properties));
                break;
            case 'headout_transmissionLines':
                config.style = (feature: LeafletFeature) => this.getTransmissionLineStyle(feature.properties);
                break;
        }

        this.source = L.geoJSON(this.leafletFeatures as any, config);
    }

    // noinspection JSMethodCanBeStatic
    private getSubstationStyle(): any {
        return {
            shape: 'square',
            radius: 3,
            fillColor: '#808080',
            stroke: false,
            fillOpacity: 1,
        };
    }

    // noinspection JSMethodCanBeStatic
    private getPowerPlantStyle(properties: any): FeatureStyle {
        let color: string;
        switch (properties.naics_desc) {
            case 'NUCLEAR ELECTRIC POWER GENERATION':
                color = '#ff0000';
                break;
            case 'FOSSIL FUEL ELECTRIC POWER GENERATION':
                color = '#994d00';
                break;
            case 'BIOMASS ELECTRIC POWER GENERATION':
                color = '#00b33c';
                break;
            case 'HYDROELECTRIC POWER GENERATION':
                color = '#7bafd4';
                break;
            case 'SOLAR ELECTRIC POWER GENERATION':
                color = '#ffff00';
                break;
            case 'WIND ELECTRIC POWER GENERATION':
                color = '#b3b3b3';
                break;
            default:
                color = '#808080';
        }
        return {
            radius: 3,
            fillColor: color,
            stroke: false,
            fillOpacity: 1,
        };
    }

    // noinspection JSMethodCanBeStatic
    private getTransmissionLineStyle(properties: any): FeatureStyle {
        let color: string;
        switch (properties.volt_class) {
            case 'DC':
                color = '#000000';
                break;
            case '735 AND ABOVE':
                color = '#004eff';
                break;
            case '500':
                color = '#ff0000';
                break;
            case '345':
                color = '#ff9b00';
                break;
            case '230-287':
                color = '#2c9e00';
                break;
            case '100-161':
                color = '#00b7ff';
                break;
            case 'UNDER 100':
                color = '#bbd18a';
                break;
            default:
                color = '#000000';
        }
        return {
            color,
            weight: 2,
            stroke: true,
            fillOpacity: 1,
        };
    }
}
