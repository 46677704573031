<div class="dialog-header">
    <span>Request Training Access</span>
    <mat-icon [inline]="true" class="clickable" (click)="ref.close()" fontSet="fas" fontIcon="fa-times"></mat-icon>
</div>

<form class="dialog-content" [formGroup]="optionGroup" #content>
    <!--
       - I updated the show time to false, I doubt people will get into the 15 minute increments for a training session
       - Removed the max date
       - Added a min date of today
       - added the binding on allowFutureEndDate
       -->
    <eaglei-date-range
        [showTime]="false"
        [allowFutureEndDate]="true"
        [start]="defaultDates.startDate"
        [end]="defaultDates.endDate"
        [min]="minDate"
        (change)="updateDates($event)">
    </eaglei-date-range>

    <eaglei-location-filter (locations)="updateLocations($event)"></eaglei-location-filter>

    <div>
        <ng-container *ngIf="optionGroup.controls?.locations?.value?.length !== 0; else noLocations">
            <mat-tab-group>
                <mat-tab *ngFor="let state of optionGroup.controls?.locations?.value" [label]="state.name">
                    <div class="county-container">
                        <div class="select-clear">
                            <button mat-flat-button color="primary" (click)="selectAllCounties(state.id)">Select All</button>
                            <button mat-flat-button (click)="clearAllCounties(state.id)">Clear All</button>
                        </div>
                        <div class="county-list">
                            <mat-checkbox
                                *ngFor="let county of countyByState?.get(state.id)"
                                [checked]="county?.selected"
                                (change)="county.selected = $event.checked">
                                {{ county.name }}
                            </mat-checkbox>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </ng-container>
        <ng-template #noLocations>
            <p class="no-location-text">Please select state(s) to select individual counties.</p>
        </ng-template>
    </div>

    <mat-form-field
        appearance="outline"
        floatLabel="always"
        class="wide filter-select"
        [class.active]="optionGroup.controls?.reason?.value">
        <mat-label>Reason</mat-label>
        <textarea matInput placeholder="Reason" formControlName="reason" cdkTextareaAutosize cdkAutosizeMaxRows="4" cdkAutosizeMinRows="4">
        </textarea>
        <mat-error>
            <span *ngIf="optionGroup.controls?.reason?.hasError('required')"> A reason is required. </span>
        </mat-error>
    </mat-form-field>
</form>

<div class="dialog-actions">
    <button mat-flat-button color="primary" [disabled]="optionGroup?.invalid" (click)="sendRequest()">Send Request</button>
</div>
