import {Component, Input, OnInit} from '@angular/core';
import {LeafletLayerLegend} from '../../classes/leaflet-layer-legend';

@Component({
    selector: 'eaglei-legend',
    templateUrl: './legend.component.html',
    styleUrls: ['./legend.component.scss'],
})
export class LegendComponent implements OnInit {
    @Input() legends: LeafletLayerLegend[] = [];
    @Input() inline: boolean = true;

    constructor() {}

    ngOnInit() {}

    public hasEllipsis(element: HTMLElement): boolean {
        return (window as any).elementHasEllipsis(element);
    }
}
