import {MatDialogConfig} from '@angular/material/dialog';

export class PopoverElement {
    get onClickListener(): () => any {
        return this._onClickListener;
    }

    get isTitle(): boolean {
        return this._isTitle;
    }

    get hideInLocationSearch(): boolean {
        return this._hideInLocationSearch;
    }

    get dialogConfig(): MatDialogConfig {
        return this._dialogConfig;
    }

    get align(): string {
        return this._align;
    }

    get isModal(): boolean {
        return this._isModal;
    }

    get isLink(): boolean {
        return this._isLink;
    }

    get dialogRef(): any {
        return this._dialogRef;
    }

    public label = '';
    public value = 'Not Available';
    public subValue = '';
    public subValClass: string = '';

    private _isLink: boolean = false;
    private _isModal: boolean = false;
    private _align: string;
    private _hideInLocationSearch: boolean;
    private _isTitle: boolean;

    private _dialogRef: any;
    private _dialogConfig: MatDialogConfig;
    private _onClickListener: () => any;

    constructor(label?: string, value?: string, isLink?: boolean, isModal?: boolean, align?: string) {
        this.label = label;
        if (value !== 'null') {
            this.value = value || 'Not Available';
        }
        this._isLink = isLink;
        this._align = align;
        this._isModal = isModal;
    }

    /**
     * Creates a html link from the url given. If label is given the link will have that label
     *
     * @param  url the url of the link
     * @param  [label] the labled that will be diplayed, if none is given it defaults to the url
     * @returns string
     *
     * @memberOf PopoverElement
     */
    public createLink(url: string, label?: string): string {
        const linkText = label ? label : url;
        return `<a href="${url}" target="_blank">${linkText}</a>`;
    }

    public setDialogRef(ref: any, config?: MatDialogConfig): PopoverElement {
        this._isModal = true;
        this._dialogRef = ref;

        if (config) {
            this._dialogConfig = config;
        }

        return this;
    }

    public setOnClick(func: () => any): PopoverElement {
        this._onClickListener = func;
        return this;
    }

    public setTitle(): PopoverElement {
        this._isTitle = true;
        return this;
    }

    public setIsLink(isLink: boolean = true): PopoverElement {
        this._isLink = isLink;
        return this;
    }

    public setIsModal(isModal: boolean = true): PopoverElement {
        this._isModal = isModal;
        return this;
    }

    public setValue(value: string): PopoverElement {
        this.value = value;
        return this;
    }

    /**
     * Sub value is a unit value to have after the given value. Sub values can be given a custom css class.
     * @param subVal Value to set as the sub Value
     * @returns This Popover element
     */
    public setSubValue(subVal: string): PopoverElement {
        this.subValue = subVal;
        return this;
    }

    public setSubValueClass(cssClass: string): PopoverElement {
        this.subValClass = cssClass;
        return this;
    }

    public setLabel(label: string): PopoverElement {
        this.label = label;
        return this;
    }

    public setAlignment(alignment: 'left' | 'right' | 'center'): PopoverElement {
        this._align = alignment;
        return this;
    }

    public setHideInLocationSearch(value: boolean = true): PopoverElement {
        this._hideInLocationSearch = value;
        return this;
    }

    public copyFrom(element: PopoverElement): PopoverElement {
        this.label = element.label;
        this.value = element.value;
        this.subValue = element.subValue;
        this.subValClass = element.subValClass;

        this._isTitle = element._isTitle;
        this._isModal = element._isModal;
        this._isLink = element._isLink;
        this._align = element._align;

        this._hideInLocationSearch = element._hideInLocationSearch;
        this._dialogRef = element._dialogRef;
        this._dialogConfig = element._dialogConfig;
        this._onClickListener = element._onClickListener;
        return this;
    }
}
