import {AfterViewInit, Component, ElementRef} from '@angular/core';
import {BaseWidget} from '../../classes/base-widget';
import {OngService} from '../../../../services/ong.service';
import {EventSummaryFilters} from '../../../system-event/classes/event-summary-filters';
import {LockTimeService} from '../../../../services/lock-time.service';
import {operationalFlowWidget} from '../../../../../integrations/ong/classes/ong-attributions-text';

@Component({
    selector: 'eaglei-ng-operational-flow-widget',
    templateUrl: './ng-operational-flow-widget.component.html',
    styleUrls: ['./ng-operational-flow-widget.component.scss'],
})
export class NgOperationalFlowWidgetComponent extends BaseWidget implements AfterViewInit {
    public orders: number = 0;
    public majeure: number = 0;
    public constraints: number = 0;

    public eventFilters: EventSummaryFilters = new EventSummaryFilters();
    constructor(private widgetElement: ElementRef, private ongService: OngService) {
        super(widgetElement, true);
    }

    onApplicationTimeChange() {
        const date = LockTimeService.currentApplicationTime.getValue();
        this.handleEventFilterChange(new EventSummaryFilters().setDate(date));
    }

    protected handleEventFilterChange(eventFilters: EventSummaryFilters): void {
        this.eventFilters = eventFilters;

        this.getNgReceiptDelivery();
    }

    ngAfterViewInit(): void {
        this.getNgReceiptDelivery();
        this.attributionModalText = operationalFlowWidget;
        this.widgetName = 'Operational Flow';
    }

    private getNgReceiptDelivery() {
        const locations = this.eventDashboard ? this.eventFilters.locations : this.userPreferences.getStates();
        const endDate = this.eventFilters?.date;

        this.ongService.getNgOperationalFlow(true, false, locations, undefined, endDate).subscribe((res) => {
            this.orders = res[0]['Operational Flow Order Counts'];
            this.majeure = res[0]['Force Majeure Counts'];
            this.constraints = res[0]['Capacity Constraint Counts'];
        });
    }

    destroyWidget(): void {}
}
