import {UrbannetDisruption} from '../classes/urbannet-disruption';
import {UrbannetEffect} from '../classes/urbannet-effect';

export const getUrbannetAssetProperties = (asset: UrbannetDisruption | UrbannetEffect): any => {
    let res;
    try {
        res = JSON.parse(asset.properties);
    } catch (e) {
        console.error(`Unable to parse properties for URBAN-NET asset ${asset.nodeId}`, e);
    }
    return res;
};
