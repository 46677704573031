import {SystemEvent} from '../../app/modules/system-event/classes/system-event';

export class FileUpload {
    public id: number;
    public displayName: string;
    public filename: string;
    public description: string;
    public size: number;
    public sizeError: boolean;
    public hasErrors: boolean;
    public errorMessage: string;
    public events: SystemEvent[];
    public file: File;

    constructor(id: number, file: File) {
        this.id = id;
        this.displayName = file.name;
        this.filename = file.name;
        this.description = '';
        this.size = file.size;
        this.file = file;

        if (this.size > 50_000_000) {
            this.sizeError = true;
        }
    }
}
