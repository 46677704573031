import {LeafletEagleiSource} from './leaflet-eaglei-source';
import {LeafletMapLayer} from './leaflet-map-layer';
import * as L from 'leaflet';
import {LeafletFeature} from './leaflet-feature';
import {PopoverElement} from '../../../classes/popover-element';
import {DataInjector} from '../../../services/data.service';
import {MapService} from '../../map/services/map.service';
import {PopoverConfig} from '../../../classes/popover-config';
import {LocationSearchService} from '../../map/services/location-search.service';
import {ILayer} from '../interfaces/layer.interface';
import {ApplicationConfig} from '../../../classes/application-config';
import {IPoint} from '../interfaces/point.interface';

export abstract class LeafletVectorSource<X = any> extends LeafletEagleiSource<L.GeoJSON> {
    public handle: any;
    public showAllPopoverData = true;
    public isTileMap = false;
    protected leafletFeatures: LeafletFeature<X>[];

    protected constructor(layerInfo: LeafletMapLayer) {
        super(layerInfo);
    }

    public changeOpacity(opacity: number): void {
        if (!this.source) {
            return;
        }
        this.layerInfo.opacity = opacity;
        if (this.isTileMap) {
            (this.source as any).setOpacity(opacity);
        } else {
            this.source.setStyle({opacity, fillOpacity: opacity});
        }
    }

    public getFeatures(): X[] {
        const features: X[] = [];

        // if the eachLayer method does not exist, we are looking at tileserver data, so the features are just set
        // to an empty array for now
        if (!this.source.eachLayer) {
            return [];
        }

        this.source?.eachLayer((l: any) => {
            features.push(l.feature.properties);
        });

        return features;
    }

    public getLeafletFeatures(): LeafletFeature<X>[] {
        const features: LeafletFeature<X>[] = [];
        this.source?.eachLayer((l: any) => {
            features.push(l.feature);
        });
        return features;
    }

    public fitToFeatures(layers?: L.Layer[]): void {
        const features = layers || this.source.getLayers();

        if (features.length === 0) {
            return;
        }

        const group = new L.FeatureGroup(features);
        MapService.mapRef.fitBounds(group.getBounds());
    }

    public fitToConus(): void {
        MapService.mapRef.fitBounds(ApplicationConfig.conus.getBounds());
    }

    public initializePopoverInteractions(
        feature: LeafletFeature<X>,
        layer: L.Layer,
        fireOn: 'hover' | 'click' = 'click',
        createOnHover: boolean = false,
        overrideData?: PopoverElement[],
        rawData?: any,
        forceInteractivity: boolean = false
    ): void {
        let data: PopoverElement[];

        const showPopover = () => {
            return (
                DataInjector.get(MapService).activeIdentifyLayers.length === 0 &&
                !DataInjector.get(LocationSearchService).drawing &&
                !DataInjector.get(LocationSearchService).pointSelection &&
                !ApplicationConfig.onPhone() &&
                !ApplicationConfig.onLite()
            );
        };

        if (fireOn === 'click') {
            layer.on('mouseover', () => {
                if (createOnHover || !data) {
                    data = overrideData || this.layerInfo?.createPopover(feature.properties, this.showAllPopoverData);
                }
            });

            layer.on('click', (event: L.LeafletMouseEvent) => {
                const point: IPoint = {
                    latitude: event.latlng.lat,
                    longitude: event.latlng.lng,
                };

                DataInjector.get(LocationSearchService).selectedPoint.next(point);

                // MapService.popoverOverlay.detach();
                // Stops the event from propigating to the map click event, because popovers close on map click.
                if (!DataInjector.get(LocationSearchService).pointSelection) {
                    L.DomEvent.stopPropagation(event);
                }

                if (showPopover()) {
                    const ops = {
                        data,
                        rawData: rawData || feature.properties,
                        interactive: true,
                        popoverId: this.layerInfo.customPopoverId,
                        x: event.originalEvent.x,
                        y: event.originalEvent.y,
                    };

                    PopoverConfig.showNewPopover(ops);
                }
            });
        } else if (fireOn === 'hover') {
            layer.on('mousemove', (event: L.LeafletMouseEvent) => {
                // Set in for Tile popovers to get data from the event
                if (!feature) {
                    data = this.layerInfo.createPopover((event as any).layer.properties, this.showAllPopoverData);
                }

                if (createOnHover || !data) {
                    data = overrideData || this.layerInfo?.createPopover(feature?.properties, this.showAllPopoverData);
                }

                if (showPopover()) {
                    const ops = {
                        data,
                        rawData: rawData || feature?.properties || (event as any).layer.properties,
                        popoverId: this.layerInfo.customPopoverId,
                        x: event.originalEvent.x,
                        y: event.originalEvent.y,
                    };

                    PopoverConfig.showNewPopover(ops);
                }
            });

            layer.on('mouseout', () => {
                PopoverConfig.hideNewPopover();
            });
        }
    }

    /**
     * Updates the data in a found layer in the map. The source is updated so filtering is still possible on the layer.
     * @param layer The map layer to be updated
     */
    private updateLayer(layer: ILayer): void {
        const features = this.leafletFeatures?.length === 0 ? this.getLeafletFeatures() : this.leafletFeatures;

        const jsonObj = {
            type: 'FeatureCollection',
            features,
        };

        (layer as any).clearLayers();
        (layer as any).addData(jsonObj as any);

        this.source = layer as any;

        this.changeOpacity(this.layerInfo.opacity);
    }

    public addToMap(ignoreUpdate: boolean = false) {
        const layer = MapService.getLayer(this.layerInfo.uiHandle, this.mapRef);

        if (layer && !ignoreUpdate) {
            this.updateLayer(layer);
        } else {
            super.addToMap();
        }
    }

    redraw(features?: LeafletFeature<X>[]) {
        const jsonObj = {
            type: 'FeatureCollection',
            features: features || this.getLeafletFeatures(),
        };

        this.source.clearLayers();
        this.source.addData(jsonObj as any);
        this.changeOpacity(this.layerInfo.opacity);
    }

    abstract processFeatures(features?: X | X[]): void;
}
