import {AfterViewInit, Component, Output} from '@angular/core';
import {Router} from '@angular/router';
import {filter, takeUntil} from 'rxjs/operators';
import {BaseWidget} from '../../classes/base-widget';
import {WidgetService} from '../../services/widget.service';
import {IReportNavigationConfig} from '../../../../interfaces/report-navigation-config.interface';
import {ReportFilter} from '../../../report/enums/report-filter.enum';
import {OngService} from '../../../../services/ong.service';
import {NaturalGasPipelineNoticeSeverity} from '../../../../types/ong.types';
import {GenWidgetSettingType} from '../../../../../../generated/serverModels/GenWidgetSettingType';
import {HttpInterceptorService} from '../../../../services/http-interceptor.service';
import {EventSummaryFilters} from '../../../system-event/classes/event-summary-filters';
import {LockTimeService} from '../../../../services/lock-time.service';
import * as moment from 'moment';
import {MatDialog} from '@angular/material/dialog';
import {
    ngCriticalNotices,
    ngNonCriticalNotices,
    receiptDeliveryPointsOverCapacity,
} from '../../../../../integrations/ong/classes/ong-attributions-text';

@Component({
    selector: 'eaglei-ng-notices-widget',
    templateUrl: './ng-notices-widget.component.html',
    styleUrls: ['./ng-notices-widget.component.scss'],
})
export class NgNoticesWidgetComponent extends BaseWidget implements AfterViewInit {
    public type: NaturalGasPipelineNoticeSeverity = 'critical';
    public notices: number = 0;
    public eventFilters: EventSummaryFilters = new EventSummaryFilters();

    constructor(private widgetService: WidgetService, private ongService: OngService, private router: Router) {
        super();
        this.updateOnSystemTime = true;
    }

    onApplicationTimeChange() {
        this.eventFilters = new EventSummaryFilters();
        this.eventFilters.date = LockTimeService.currentApplicationTime.getValue();
        this.handleEventFilterChange(this.eventFilters);
    }

    protected handleEventFilterChange(eventFilters: EventSummaryFilters): void {
        this.eventFilters = eventFilters;

        this.getNGNotices();
    }

    public ngAfterViewInit(): void {
        WidgetService.updatedSettings
            .pipe(
                filter((widget) => !!widget),
                takeUntil(this.destroy$)
            )
            .subscribe((widget) => {
                if (this.item.x === widget.x && this.item.y === widget.y) {
                    this.type = widget.detail.settings.find((s) => s.type === GenWidgetSettingType.NOTICES).value;
                    this.getNGNotices();
                }
            });

        if (!!this.item && !!this.item.detail.settings.find((s) => s.type === GenWidgetSettingType.NOTICES)) {
            this.type = this.item.detail.settings.find((s) => s.type === GenWidgetSettingType.NOTICES).value as any;
        }

        WidgetService.renderWidget.pipe(takeUntil(this.destroy$)).subscribe(() => this.getNGNotices());

        this.getNGNotices();
        this.widgetName = this.type === 'critical' ? 'Critical' : 'Non-Critical';
        this.attributionModalText = this.type === 'critical' ? ngCriticalNotices : ngNonCriticalNotices;
    }

    destroyWidget(): void {}

    private getNGNotices(): void {
        const locations = this.eventDashboard ? this.eventFilters.locations : this.userPreferences.getStates();
        const endDate = this.eventFilters?.date;

        this.attributionModalText = this.type === 'critical' ? ngCriticalNotices : ngNonCriticalNotices;
        HttpInterceptorService.clearInterceptor(this.widgetInterceptorKey);
        HttpInterceptorService.pendingRequests[this.widgetInterceptorKey] = this.ongService
            .getNgNotices(this.type, true, false, locations, undefined, endDate)
            .subscribe((res) => {
                delete HttpInterceptorService.pendingRequests[this.widgetInterceptorKey];
                this.notices = res.count;
            });
    }

    public getNoticeTitle(): string {
        return this.type === 'critical' ? 'CRITICAL' : 'NON-CRITICAL';
    }

    public getNoticeIcon(): string {
        return this.type === 'critical' ? 'fa-exclamation-triangle' : 'fa-circle';
    }

    public viewReport(): void {
        // Added Start Check in case not on event dashboard
        const start = !!this.eventFilters.event
            ? this.eventFilters.event.eventStart.valueOf()
            : moment(this.eventFilters.date).subtract(1, 'day').valueOf();
        const filters: IReportNavigationConfig[] = [
            {
                type: ReportFilter.PRIORITY,
                value: this.type === 'critical' ? 'critical' : 'non critical, planned service outage',
            },
            {type: ReportFilter.START_DATE, value: start},
            {type: ReportFilter.END_DATE, value: this.eventFilters.date.valueOf()},
            {type: ReportFilter.STATE, value: this.eventFilters.locations},
        ];
        // noinspection JSIgnoredPromiseFromCall
        this.router.navigate(['app/reports/naturalGas'], {state: {filters}});
    }
}
