import {GenGenOutageCoverageHistory} from '../../../../../generated/serverModels/GenGenOutageCoverageHistory';
import * as moment from 'moment';

export class Coverage extends GenGenOutageCoverageHistory {
    get coverage_Date(): number {
        return this._coverageDate;
    }

    get percent(): number {
        return this._percent;
    }

    private readonly _coverageDate: number;
    private readonly _percent: number;

    public trendDirection = '-';

    constructor(coverage?: Coverage) {
        super(coverage);

        if (coverage) {
            if (coverage.coverageDate !== undefined) {
                this._coverageDate = moment(coverage.coverageDate).valueOf();
            }

            if (this.coveredCustomers !== undefined && this.totalCustomers !== 0) {
                this._percent = this.coveredCustomers / this.totalCustomers;
            }

            this.trendDirection = coverage.trendDirection;
        }
    }
}
