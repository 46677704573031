<eaglei-leaflet-layer-header [layer]="this" (activeToggled)="toggleLayer($event)" [hideLegend]="true"></eaglei-leaflet-layer-header>

<ng-container *ngIf="isActive">
    <div #legendElement>
        <div class="vertical-legend">
            <div>
                <div class="circle-container">
                    <div class="status fire-icon" [ngClass]="{'small-circle': size === 'small'}">
                        <mat-icon fontSet="fas" fontIcon="fa-fire"></mat-icon>
                    </div>
                    <span class="label"> Gas Burn</span>
                </div>
            </div>
        </div>
    </div>
</ng-container>
