<div class="popover-container" *ngIf="data">
    <div class="popover-title">
        <div>{{ data[0].value }}</div>
        <mat-icon
            *ngIf="onLite()"
            align="end"
            class="icon-padding"
            inline
            fontSet="fas"
            fontIcon="fa-times"
            (click)="closePopover()"></mat-icon>
    </div>

    <table class="wide">
        <tbody>
            <tr *ngFor="let ele of data | slice : 1; let odd = odd; let i = index" class="row-padding">
                <ng-container *ngIf="!ele.isLink && !ele.isModal; else linkLayout">
                    <td class="popover-label extra-padding">{{ ele.label }}</td>
                    <td
                        class="popover-data"
                        [class.no-wrap]="ele.value && ele.value.length < 50"
                        [style.text-align]="ele.align ? ele.align : 'right'">
                        {{ ele.value || 'Not Available' }}
                        <span [ngClass]="ele.subValClass">{{ ele.subValue }}</span>
                    </td>
                </ng-container>
                <ng-template #linkLayout>
                    <td colspan="2" class="center">
                        <a *ngIf="ele.isModal" (click)="openContentModal(i + 1)">
                            {{ ele.label }}
                        </a>
                        <a *ngIf="ele.isLink" [href]="ele.value" target="_blank">
                            {{ ele.label }}
                        </a>
                    </td>
                </ng-template>
            </tr>
        </tbody>
    </table>
</div>
