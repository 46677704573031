<mat-card>
    <mat-card-title>
        <span
            #locationName
            class="location-name ellipsis"
            [matTooltip]="data.name"
            [matTooltipDisabled]="!isEllipsis(locationName)"
            matTooltipPosition="above"
            matTooltipShowDelay="250">
            {{ data.name }}
        </span>

        <button mat-icon-button (click)="close()">
            <mat-icon fontSet="fas" fontIcon="fa-times"></mat-icon>
        </button>
    </mat-card-title>

    <mat-card-content #content class="column-layout">
        <!-- Deployment status -->
        <div class="deployment-status">
            <mat-icon inline fontSet="fas" fontIcon="fa-circle" [class.deployed]="data.deployments.length > 0"></mat-icon>
            <span class="contact-info">{{ data.deployments.length > 0 ? 'Deployed' : 'No Deployment' }}</span>
        </div>

        <div class="desk-container" *ngIf="data.desks.length > 0">
            <!-- Desk Info -->
            <h4 class="title">Desk Info</h4>

            <div *ngFor="let desk of data.desks">
                <h5>{{ desk.description }}</h5>
                <div class="contact-info">
                    <mat-icon fontSet="fas" fontIcon="fa-envelope"></mat-icon>
                    <ul>
                        <li *ngFor="let email of desk.emails">
                            <a href="mailto:{{ email.information }}">{{ email.information }}</a>
                        </li>
                    </ul>
                </div>

                <div class="contact-info">
                    <mat-icon fontSet="fas" fontIcon="fa-phone-alt"></mat-icon>
                    <ul>
                        <li *ngFor="let phone of desk.phones">
                            <a href="tel:{{ phone.information }}">{{ phone.information | phoneNumber }}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="deployment-container" *ngIf="data.desks.length > 0">
            <!-- Desk Info -->
            <h4 class="title">Deployments</h4>

            <div *ngFor="let deployment of data.deployments">
                <div class="personnel-name">
                    <mat-icon fontSet="fas" fontIcon="fa-user"></mat-icon>
                    <span>{{ getPersonnelText(deployment) }}</span>
                </div>

                <div class="contact-info">
                    <mat-icon fontSet="fas" fontIcon="fa-envelope"></mat-icon>
                    <ul>
                        <li *ngFor="let email of deployment.personnel.emails">
                            <a href="mailto:{{ email.information }}">{{ email.information }}</a>
                        </li>
                    </ul>
                </div>

                <div class="contact-info">
                    <mat-icon fontSet="fas" fontIcon="fa-phone-alt"></mat-icon>
                    <ul>
                        <li *ngFor="let phone of deployment.personnel.phones">
                            <a href="tel:{{ phone.information }}">{{ phone.information | phoneNumber }}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>
