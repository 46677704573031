
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!


export class GenEOCOverrideTrainedUser {

    email: string;
    firstName: string;
    isAdmin: boolean;
    isManager: boolean;
    lastName: string;
    userId: number;
    userName: string;

    constructor();
    constructor( jsonObj: any );
    constructor( genEOCOverrideTrainedUser: GenEOCOverrideTrainedUser);

    constructor( input?: GenEOCOverrideTrainedUser ) {
        if ( input && typeof input !== 'number' ) {
            this.email = input.email; // string [string]
            this.firstName = input.firstName; // string [string]
            this.isAdmin = input.isAdmin; // boolean [boolean]
            this.isManager = input.isManager; // boolean [boolean]
            this.lastName = input.lastName; // string [string]
            this.userId = input.userId; // int [number]
            this.userName = input.userName; // string [string]
       }
    }

    static jsonResponseToList(arr: any[]): GenEOCOverrideTrainedUser[] {
        if (arr && arr.map) {
          return arr.map(a => new GenEOCOverrideTrainedUser(a));
        } else {
          return [];
        }
    }
}

