import {Injectable} from '@angular/core';
import * as L from 'leaflet';
import * as turf from '@turf/turf';
import intersect from '@turf/intersect';
import booleanContains from '@turf/boolean-contains';
import lineIntersects from '@turf/line-intersect';
// tslint:disable-next-line:no-implicit-dependencies
import flatten from '@turf/flatten';
import {MapService} from './map.service';
import {ILayer} from '../../layer/interfaces/layer.interface';
import {GenServiceType} from '../../../../../generated/serverModels/GenServiceType';
import {LeafletFeature} from '../../layer/classes/leaflet-feature';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {tap} from 'rxjs/operators';
import {DrawType} from '../types/draw-type';
import {CoordinateFeature} from '../classes/coordinate-feature';
import {IPoint} from '../../layer/interfaces/point.interface';
import {PopoverElement} from '../../../classes/popover-element';
import {DecimalPipe} from '@angular/common';

@Injectable({
    providedIn: 'root',
})
export class LocationSearchService {
    get numberOfCalls(): number {
        return this._numberOfCalls;
    }

    set numberOfCalls(value: number) {
        this._numberOfCalls = value;
        this.loading = value !== 0;
    }

    public intersectingFeatures = new BehaviorSubject<CoordinateFeature[]>([]);
    public drawShape = new BehaviorSubject<DrawType>(undefined);
    public selectedPoint = new BehaviorSubject<IPoint>(undefined);
    public drawPoint = new BehaviorSubject<IPoint>(undefined);
    public executeBuffer: boolean = true;
    public routingState: boolean = false;
    public loading: boolean;
    private _numberOfCalls: number = 0;

    private numberPipe = new DecimalPipe('en-US');

    public drawing: boolean;

    public get pointSelection(): boolean {
        return this._pointSelection.value;
    }
    public set pointSelection(pointSelection: boolean) {
        this._pointSelection.next(pointSelection);
    }
    private _pointSelection = new BehaviorSubject<boolean>(false);
    public pointSelection$ = this._pointSelection.asObservable();
    public updateMapInteractions = new Subject();

    public searchLayer = L.geoJSON(undefined, {
        pane: 'tools',
    });

    public routeLayer = L.geoJSON(undefined, {
        pane: 'tools',
    });

    public bufferInMiles = 10;

    constructor(private http: HttpClient) {
        this.searchLayer.setZIndex(1000);
        this.routeLayer.setZIndex(1000);
    }

    public hasSearchFeatures(): boolean {
        return this.searchLayer.getLayers().length > 0;
    }

    public convertBufferToPolygon(lat: number, lon: number, radius?: number, units: string = 'miles'): any {
        const circleRadius = radius || this.bufferInMiles;
        const circleCenter = [lon, lat];
        const circleOptions = {steps: 60, units};

        return turf.circle(circleCenter, circleRadius, circleOptions as any);
    }

    public getFeaturesIntersectingLayer(layer: L.GeoJSON): void {
        this.numberOfCalls = 0;
        this.intersectingFeatures.next([]);

        const layerFeatures = (layer.toGeoJSON() as any).features;
        const searchFeature = layerFeatures[layerFeatures.length - 1];

        MapService.mapRef.fitBounds(layer.getBounds());

        const tapSuccess = () => {
            this.numberOfCalls = this.numberOfCalls - 1;
        };

        const tapFailure = (error: any) => {
            console.error(error);
            this.numberOfCalls = this.numberOfCalls - 1;
            this.addFeatures([]);
        };

        const layers = MapService.getEagleiLayers();

        layers.forEach((l: ILayer) => {
            let features: any[];
            switch (l.eagleiLayer.servicetype) {
                case GenServiceType.VECTOR:
                    features = (l as any)
                        .getLayers()
                        .map((mapLayer) => {
                            return mapLayer.feature;
                        })
                        .filter((layerFeature) => this.checkIntersection(searchFeature, layerFeature))
                        .map((f: LeafletFeature) => {
                            if (f.subFeatures) {
                                return f.subFeatures.map((v) => {
                                    const subTitle = v.popoverData.find((p) => p.isTitle)?.value || 'N/A';
                                    return new CoordinateFeature(
                                        subTitle,
                                        l.eagleiLayer.displayName,
                                        v.popoverData.filter((p) => !p.isTitle)
                                    );
                                });
                            }

                            const popoverData =
                                l.eagleiLayer.popoverData.length === 0 ? f.popoverData : l.eagleiLayer.createPopover(f.properties);
                            const title = popoverData.find((p) => p.isTitle)?.value || 'N/A';
                            return new CoordinateFeature(
                                title,
                                l.eagleiLayer.displayName,
                                popoverData.filter((p) => !p.isTitle)
                            );
                        });

                    if (features[0] instanceof Array) {
                        features = features.reduce((prev, cur) => prev.concat(...cur), []);
                    }

                    this.addFeatures(features);

                    break;
                case GenServiceType.VECTORTILE:
                    break;
                case GenServiceType.WMS:
                    if (l.eagleiLayer.uiHandle === 'landscanGlobal') {
                        this.numberOfCalls = this.numberOfCalls + 1;
                        this.http.post('api/maplayer/popcount', searchFeature).subscribe((res: number) => {
                            const popoverData = [new PopoverElement('Population', this.numberPipe.transform(res))];
                            const feature = new CoordinateFeature(l.eagleiLayer.displayName, l.eagleiLayer.displayName, popoverData);

                            this.addFeatures([feature]);
                            this.numberOfCalls = this.numberOfCalls - 1;
                        });
                        return;
                    }

                    const url = this.formatQuery(l as any, layer);
                    this.numberOfCalls = this.numberOfCalls + 1;
                    this.http
                        .get<any>(url)
                        .pipe(tap(tapSuccess, tapFailure))
                        .subscribe((res) => {
                            features = (res.features as any[])
                                .filter((layerFeature) => this.checkIntersection(searchFeature, layerFeature))
                                .map((f) => {
                                    const popoverData = l.eagleiLayer.createPopover(f.properties);
                                    const title = popoverData.find((p) => p.isTitle)?.value || 'N/A';
                                    return new CoordinateFeature(
                                        title,
                                        l.eagleiLayer.displayName,
                                        popoverData.filter((p) => !p.isTitle)
                                    );
                                });

                            this.addFeatures(features);
                        });
                    break;
                case GenServiceType.ARCGIS:
                    console.warn('not yet implemented');
                    this.addFeatures([]);
            }
        });
    }

    private addFeatures(features: CoordinateFeature[]): void {
        const current = this.intersectingFeatures.getValue();
        current.push(...features);

        this.intersectingFeatures.next(current);
    }

    private formatQuery(layer: L.TileLayer.WMS, searchLayer: L.GeoJSON): string {
        const sw = L.CRS.EPSG3857.project(searchLayer.getBounds().getSouthWest());
        const ne = L.CRS.EPSG3857.project(searchLayer.getBounds().getNorthEast());
        const bbox = `${sw.x},${sw.y},${ne.x},${ne.y}`;

        const geomFilter = `BBOX(geom, ${bbox})`;
        let filters = layer.wmsParams['CQL_FILTER'];

        // tslint:disable-next-line:prefer-conditional-expression
        if (filters) {
            filters = `${filters} AND ${geomFilter}`;
        } else {
            filters = geomFilter;
        }

        const params = {
            service: 'WFS',
            version: '2.0.0',
            request: 'GetFeature',
            typenames: layer.wmsParams.layers.split(':')[1],
            srsName: 'EPSG:4326',
            CQL_FILTER: filters,
            // CQL_FILTER: layer.wmsParams['CQL_FILTER'],
            // bbox: `${searchLayer.getBounds().toBBoxString()},EPSG:4326`,
            outputFormat: 'application/json',
        };

        const paramStr = Object.keys(params)
            .map((key) => `${key}=${params[key]}`)
            .join('&');
        return `${(layer as any)._url.replace(/wms/g, 'wfs')}?${paramStr}`;
    }

    // noinspection JSMethodCanBeStatic
    private checkIntersection(searchFeature: any, layerFeature: LeafletFeature): boolean {
        // p1 = searchFeature
        // p2 = layerFeature

        const geomType = layerFeature?.geometry?.type;
        switch (geomType) {
            case 'MultiPolygon':
            case 'Polygon':
                return intersect(searchFeature, layerFeature as any) !== null;
            case 'MultiLineString':
                const collection = lineIntersects(searchFeature, layerFeature as any);

                const tmpFeature = {
                    type: 'Feature',
                    geometry: layerFeature.geometry,
                    properties: {},
                };

                const flat = flatten(tmpFeature as any);
                const line = turf.helpers.lineString(flat.features[0].geometry.coordinates as any);

                return collection.features.length > 0 || booleanContains(searchFeature, line);
            case 'MultiPoint':
            case 'Point':
                return booleanContains(searchFeature, layerFeature as any);
            default:
                console.info('Geom type of ' + layerFeature?.getGeometryType() + ' not implemented yet');
                return false;
        }
    }

    /**
     * Calls the ESRI Routing to find the route between the two given points with a possible barrier
     * @param start Starting Point
     * @param stop Stoping Point
     * @param barrier Possible Barrier between the two points
     * @returns Complete Response Body with route detail
     */
    public runEsriRouting(start: IPoint, stop: IPoint, barrier: L.GeoJSON): Observable<any> {
        const url = 'api/esri/routing';
        const resBody = {
            stops: {
                spatialReference: {
                    wkid: 4326,
                },
                features: [
                    {
                        geometry: {
                            x: start.longitude,
                            y: start.latitude,
                        },
                    },
                    {
                        geometry: {
                            x: stop.longitude,
                            y: stop.latitude,
                        },
                    },
                ],
            },
        };

        // Add the Polygon Barriers
        if (barrier?.getLayers()?.length > 0) {
            (resBody as any).polygonBarriers = {
                spatialReference: {
                    wkid: 4326,
                },
                features: [
                    {
                        geometry: {
                            rings: [...(barrier.getLayers()[0] as any).feature.geometry.coordinates],
                        },
                    },
                ],
            };
        }

        return this.http.post(url, resBody, {observe: 'response'});
    }
}
