
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import { GenUrbanNetEffect } from './GenUrbanNetEffect';

export class GenUrbanNetDisruption {

    id: number;
    effects: GenUrbanNetEffect[];
    geometry: string;
    nodeId: string;
    properties: string;
    reportId: number;
    type: string;

    constructor();
    constructor( jsonObj: any );
    constructor( genUrbanNetDisruption: GenUrbanNetDisruption);
    constructor( id: number );

    constructor( input?: GenUrbanNetDisruption|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            if ( input.effects && input.effects.map ) { // list [GenUrbanNetEffect]
                this.effects = input.effects.map( ( x: GenUrbanNetEffect ) => {
                    return new GenUrbanNetEffect( x );
                } );
            } else {
                this.effects = input.effects;
            }
            this.geometry = input.geometry; // string [string]
            this.nodeId = input.nodeId; // string [string]
            this.properties = input.properties; // string [string]
            this.reportId = input.reportId; // int [number]
            this.type = input.type; // string [string]
       }
    }

    static jsonResponseToList(arr: any[]): GenUrbanNetDisruption[] {
        if (arr && arr.map) {
          return arr.map(a => new GenUrbanNetDisruption(a));
        } else {
          return [];
        }
    }
}

