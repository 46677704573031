
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import * as moment from 'moment';

export class GenUtilityOutageOverride {

    countyId: number;
    countyName: string;
    femaRegion: number;
    outageCount: number;
    overrideCount: number;
    runStartTime: moment.Moment;
    state: string;
    stateId: number;
    subUtilityId: number;
    utilityId: number;
    utilityName: string;

    constructor();
    constructor( jsonObj: any );
    constructor( genUtilityOutageOverride: GenUtilityOutageOverride);

    constructor( input?: GenUtilityOutageOverride ) {
        if ( input && typeof input !== 'number' ) {
            this.countyId = input.countyId; // int [number]
            this.countyName = input.countyName; // string [string]
            this.femaRegion = input.femaRegion; // int [number]
            this.outageCount = input.outageCount; // int [number]
            this.overrideCount = input.overrideCount; // int [number]
            if ( input.runStartTime && typeof input.runStartTime === 'string' ) {
                this.runStartTime = moment(input.runStartTime).utc();
            } else {
                this.runStartTime = input.runStartTime;
            }
            this.state = input.state; // string [string]
            this.stateId = input.stateId; // int [number]
            this.subUtilityId = input.subUtilityId; // int [number]
            this.utilityId = input.utilityId; // int [number]
            this.utilityName = input.utilityName; // string [string]
       }
    }

    static jsonResponseToList(arr: any[]): GenUtilityOutageOverride[] {
        if (arr && arr.map) {
          return arr.map(a => new GenUtilityOutageOverride(a));
        } else {
          return [];
        }
    }
}

