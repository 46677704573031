<h2 mat-dialog-title>Confirm:</h2>

<mat-dialog-content>
    <p class="modal-text">{{ confirmationMessage }}</p>

    <mat-form-field *ngIf="submitComments">
        <textarea matInput placeholder="Comments" rows="1" [(ngModel)]="comments"></textarea>
    </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button color="warn" [mat-dialog-close]="false">No</button>
    <button mat-raised-button color="primary" [mat-dialog-close]="true">Yes</button>
</mat-dialog-actions>
