
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import { GenLegendType } from './GenLegendType';

export class GenLegend {

    id: number;
    layerId: number;
    type: GenLegendType;
    urls: string[];
    values: string;

    constructor();
    constructor( jsonObj: any );
    constructor( genLegend: GenLegend);
    constructor( id: number );

    constructor( input?: GenLegend|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            this.layerId = input.layerId; // int [number]
            this.type = (typeof input.type === 'string') ? GenLegendType.forName(<string> input.type) : <any> input.type; // GenLegendType
            this.urls = input.urls; // list [string]
            this.values = input.values; // string [string]
       }
    }

    static jsonResponseToList(arr: any[]): GenLegend[] {
        if (arr && arr.map) {
          return arr.map(a => new GenLegend(a));
        } else {
          return [];
        }
    }
}

