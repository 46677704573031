import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {User} from '../classes/user';
import {map} from 'rxjs/operators';
import {UserPreference} from '../../../classes/user-preferences';
import {LegendStyle} from '../classes/legend-style';
import {GenEmailChangeRequest} from '../../../../../generated/serverModels/GenEmailChangeRequest';
import {Poc} from '../classes/poc';
import {GenReportRequest} from 'frontend/generated/serverModels/GenReportRequest';
import {GenRoleDefinition} from '../../../../../generated/serverModels/GenRoleDefinition';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    private readonly userUrl: string = '/api/security/user';
    private readonly enabledUserUrl: string = '/api/security/user?active=true';
    private readonly preferenceUrl: string = 'api/user/preference';
    private readonly systemDataUrl: string = 'api/systemdata';

    public onUserChange: BehaviorSubject<User[]>;
    private headers = new HttpHeaders().set('Content-Type', 'application/json');

    constructor(private http: HttpClient) {
        this.onUserChange = new BehaviorSubject<User[]>([]);
    }

    public getUsers(groupConstraint?: string): Observable<User[]> {
        let params = new HttpParams();

        const mf = (users: User[]) => {
            return users.map((user) => new User(user));
        };

        if (groupConstraint) {
            params = new HttpParams().append('groupConstraint', groupConstraint);
        }

        return this.http.get<User[]>(this.userUrl, {params}).pipe(map((users) => users.map((user) => new User(user))));
    }

    public getMinimalUsers(): Observable<User[]> {
        const url = `${this.userUrl}/management`;
        return this.http.get<User[]>(url).pipe(map((users) => users.map((user) => new User(user))));
    }

    public getEnabledUsers(): Observable<User[]> {
        const mf = (users: User[]) => {
            return users.map((user) => new User(user));
        };
        return this.http.get<User[]>(this.enabledUserUrl).pipe(map(mf));
    }

    public getUserById(id: number): Observable<User> {
        const url = `${this.userUrl}/${id || 0}`;
        return this.http.get<any>(url).pipe(map((res) => new User(res)));
    }

    public getUsersByRoles(roles: GenRoleDefinition[]): Observable<User[]> {
        const url = `api/security/user/by-role`;
        const params = roles.map((role) => `roles=${role.toString()}`).join('&');

        return this.http.get<any[]>(`${url}?${params}`).pipe(map((res) => res.map((user) => new User(user))));
    }

    public updateUser(user: User): Observable<User> {
        const url = `${this.userUrl}/${user.id}`;
        const body = JSON.stringify(user);
        return this.http.put<any>(url, body, {headers: this.headers}).pipe(map((res) => new User(res)));
    }

    public partiallyUpdateUser(user: User): Observable<any> {
        const url = `${this.userUrl}/updatepart/${user.id}`;
        return this.http.put<any>(url, user);
    }

    public updatePocInfo(info: Poc): Observable<any> {
        const url = `${this.userUrl}/updatepoc`;
        return this.http.put<any>(url, info);
    }

    public approveUser(user: User): Observable<User> {
        const url = `${this.userUrl}/${user.id}/approve`;
        return this.http.put<any>(url, '').pipe(map((res) => new User(res)));
    }

    public denyUser(user: User, reason: string): Observable<boolean> {
        const url = `${this.userUrl}/${user.id}/deny`;
        return this.http.put<any>(url, [reason]).pipe(map(() => true));
    }

    public enableUser(user: User): Observable<User> {
        const url = `${this.userUrl}/${user.id}/enable`;
        return this.http.put<any>(url, '').pipe(map((res) => new User(res)));
    }

    public disableUser(user: User): Observable<User> {
        const url = `${this.userUrl}/${user.id}/disable`;
        return this.http.put<any>(url, '').pipe(map((res) => new User(res)));
    }

    public resendVerificationEmail(userId: number): Observable<any> {
        const url = `${this.userUrl}/send-email-verification?userId=${userId}`;
        return this.http.get(url, {responseType: 'text'});
    }

    public forceVerificationEmail(userId: number): Observable<User> {
        const url = `${this.userUrl}/force-email-verification?userId=${userId}`;
        return this.http.get<User>(url);
    }

    public getLegendOptions(): Observable<LegendStyle[]> {
        const url = `${this.preferenceUrl}/legend`;
        return this.http.get<any[]>(url).pipe(map((styles) => styles.map((style) => new LegendStyle(style))));
    }

    public savePreferences(preference: UserPreference): Observable<any> {
        const p = new UserPreference(preference);

        if (p.aggregationLevel.id < 1) {
            p.aggregationLevel = undefined;
        }
        if (!p.hasLegendStyle()) {
            p.legendStyle = undefined;
        }

        return this.http.post(this.preferenceUrl, p);
    }

    /**
     * Changes the password for a user. will throw an error if the password does not meet the complexity requirements.
     * @param userId The user being updated
     * @param newPassword the value the password will be set to.
     */
    public changePassword(userId: number, newPassword: string): Observable<any> {
        const url = `${this.userUrl}/changeuserpassword/${userId}`;
        return this.http.post(url, newPassword);
    }

    //  Email Change Endpoints
    public requestEmailChange(changeRequest: GenEmailChangeRequest): Observable<any> {
        const url = `api/security/user/requestemailchange`;
        return this.http.post(url, changeRequest);
    }

    public getEmailChangeRequest(userId: number): Observable<GenEmailChangeRequest> {
        const url = `api/security/user/requestemailchange?userId=${userId}`;
        return this.http.get(url).pipe(map((req) => (req ? new GenEmailChangeRequest(req) : undefined)));
    }

    public approveEmailChangeRequest(request: GenEmailChangeRequest): Observable<any> {
        const url = `api/security/user/requestemailchange/approve/${request.id}`;
        return this.http.put(url, '');
    }

    public denyEmailChangeRequest(request: GenEmailChangeRequest, reason: string): Observable<any> {
        const url = `api/security/user/requestemailchange/deny/${request.id}`;
        return this.http.put(url, reason);
    }

    public updateRoles(user: User): Observable<any> {
        const url = `${this.userUrl}/updateroles`;
        return this.http.put(url, user);
    }

    public getUserDefinedReports(): Observable<any> {
        const url = `${this.systemDataUrl}/report-management`;
        return this.http.get(url);
    }

    public getReportRequest(): Observable<any> {
        const url = `${this.systemDataUrl}/report-requests`;
        return this.http.get(url);
    }

    public deleteUserDefinedReport(reportId: number): Observable<any> {
        const url = `${this.systemDataUrl}/delete-report-request?reportRequestId=${reportId}`;
        return this.http.delete(url, {responseType: 'text'});
    }

    public removeSubscribersFromReport(reportGroupId: number, subscribers: string[]): Observable<any> {
        let url = `${this.systemDataUrl}/remove-subscribers-from-report?reportRequestGroupId=${reportGroupId}`;
        subscribers.forEach((sub) => {
            url += `&subscriber=${sub}`;
        });

        return this.http.delete(url, {responseType: 'text'});
    }

    public updateReportRequest(reportRequests: GenReportRequest[]): Observable<any> {
        const url = `${this.systemDataUrl}/update-report-request`;
        return this.http.post(url, reportRequests);
    }
}
