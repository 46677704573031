import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {User} from '../../user/classes/user';
import {AuthenticationService} from '../../../services/authentication.service';
import {ResponseWrapper} from '../../../classes/response-wrapper';
import {AlertSubscription} from '../classes/alert-subscription';
import * as moment from 'moment';
import {SystemAlert} from '../classes/system-alert';
import {GenUtilityCountyCustomer} from '../../../../../generated/serverModels/GenUtilityCountyCustomer';
import {GenOutageAggregationLevel} from '../../../../../generated/serverModels/GenOutageAggregationLevel';
import {UserAlert} from '../classes/user-alert';
import {GenAlert} from 'frontend/generated/serverModels/GenAlert';

@Injectable({
    providedIn: 'root',
})
export class AlertService {
    private currentSubscriptionUrl = '/api/alert/subscriptions';
    private currentUser: User | undefined;

    constructor(private http: HttpClient, private auth: AuthenticationService) {
        this.auth.authenticatedUser.pipe(filter((user) => user !== undefined)).subscribe((user) => (this.currentUser = user));
    }

    getCurrentSubscriptions(getAllUsers: boolean = false): Observable<AlertSubscription[]> {
        let url = `${this.currentSubscriptionUrl}`;
        if (!getAllUsers && this.currentUser) {
            url += `?userId=${this.currentUser.id}`;
        }
        return this.http.get<any[]>(url).pipe(map((res) => res.map((sub) => new AlertSubscription(sub))));
    }

    public getUserAlerts(): Observable<SystemAlert[]> {
        return this.http.get<any[]>(`api/alert/alerts`).pipe(map((alerts) => alerts.map((alert) => new SystemAlert(alert))));
    }

    public createUserAlert(alert: GenAlert): Observable<{data: UserAlert; metadata: unknown}> {
        return this.http.post<{data: UserAlert; metadata: unknown}>(`api/alert/create-alert`, alert);
    }

    /** Returns the provided `alertId` on success */
    public createUserAlertDetail(alertId: number, locationIds: number[]): Observable<number> {
        return this.http.put<void>(`api/alert/detail-alert/${alertId}`, locationIds).pipe(map(() => alertId));
    }

    public updateUserAlert(alert: any): Observable<any> {
        return this.http.put(`api/alert/update-alert/${alert.id}`, alert);
    }

    public subscribeUserAlert(alertId: number, subscribers: number[]): Observable<void> {
        return this.http.put<void>(`api/alert/subscribe/${alertId}`, subscribers);
    }

    public subscribeToAlertsByUserId(userId: number, alerts: SystemAlert[]): Observable<any> {
        return this.http.post(`api/alert/subscribe-by-user/${userId}`, alerts);
    }

    public unsubscribeUserAlert(alertId: number, subscribers: number[]): Observable<void> {
        return this.http.put<void>(`api/alert/unsubscribe/${alertId}`, subscribers);
    }

    public getCustomersByAggregation(groupBy: GenOutageAggregationLevel): Observable<GenUtilityCountyCustomer[]> {
        const url = `api/utility/utility-county-customer`;
        const params = new HttpParams().set('groupBy', groupBy.toString());

        return this.http.get<ResponseWrapper>(url, {params}).pipe(
            map((res) => {
                return res.data.map((entry) => new GenUtilityCountyCustomer(entry));
            })
        );
    }

    // public getStateNumbers(): Observable<any> {
    //     return this.http.get(
    //         `api/outagesummary/statesummary?start=${moment().subtract(30, 'minutes').format()}&end=${moment()
    //             .subtract(15, 'minutes')
    //             .format()}`
    //     );
    // }
    //
    // public getCountyNumbers(): Observable<any> {
    //     return this.http.get(`api/outagesummary/county?start=${moment().format()}`);
    // }
}
