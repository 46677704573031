import {ClusterSource} from '../classes/cluster-source';
import {LeafletMapLayer} from '../classes/leaflet-map-layer';
import {LeafletFeature} from '../classes/leaflet-feature';
import * as L from 'leaflet';
import {LayerStyleService} from '../services/layer-style.service';
import {Survey123Feature} from '../../../classes/survey123-feature';

export class Survey123Source extends ClusterSource<Survey123Feature> {
    constructor(layerInfo: LeafletMapLayer) {
        super(layerInfo);
    }

    /**
     * Creates geojson features from the data passed in. Once the features are
     * created the points are styled, the cluster is styled then the points are
     * added to the cluster.
     * @param features a list of features to convert into GeoJSON features
     */
    processFeatures(features: any[]): void {
        this.leafletFeatures = features.map((f) => new LeafletFeature<Survey123Feature>(f));

        const style = (feature: any, latlng: L.LatLng): L.Layer => {
            return L.marker(latlng, {
                icon: getStyle(),
            });
        };

        const singleFeaturePopover = (feature: LeafletFeature<Survey123Feature>, l) => {
            this.initializePopoverInteractions(feature, l, 'click', false, undefined);
        };

        const config = {
            pointToLayer: style,
            onEachFeature: singleFeaturePopover,
        };

        const clusterConfig: L.MarkerClusterGroupOptions = {
            iconCreateFunction: getClusterStyle.bind(this),
            chunkedLoading: true,
        };

        const cluster = new L.MarkerClusterGroup(clusterConfig);
        cluster.addLayer(L.geoJSON(this.leafletFeatures as any, config as any));

        this.source = cluster as any;
    }
}

function getStyle(): any {
    const key = 'survey123';
    let style = LayerStyleService.layerStyles.get(key);
    if (!style) {
        const color = '#2c7fb8';
        const options = {
            icon: 'circle',
            backgroundColor: color,
            borderColor: color,
            innerIconStyle: `color:${color}; position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); margin-top: 0px;`,
            iconSize: [10, 10],
        };

        style = (L as any).BeautifyIcon.icon(options);

        LayerStyleService.layerStyles.set(key, style);
    }
    return style;
}

function getClusterStyle(cluster: L.MarkerCluster): any {
    const color = '#2c7fb8';
    const options = {
        icon: 'circle',
        backgroundColor: color,
        borderColor: color,
        innerIconStyle: `position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); margin-top: 0px; text`,
        iconSize: [30, 30],
        isAlphaNumericIcon: true,
        text: `${cluster.getChildCount()}`,
        textColor: 'white',
    };

    return (L as any).BeautifyIcon.icon(options);
}
