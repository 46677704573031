import {GenSystemNews} from '../../../../../generated/serverModels/GenSystemNews';

export class SystemNews extends GenSystemNews {
    protected isTextVisible = false;
    public canDelete = true;
    public editing = false;
    public emailUsers = false;

    constructor(news?: SystemNews) {
        super(news);
    }
}
