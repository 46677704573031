import {Component} from '@angular/core';
import {BasePopover} from '../base-popover';

@Component({
    selector: 'eaglei-ong-refinery-popover',
    templateUrl: './ong-refinery-popover.component.html',
    styleUrls: ['./ong-refinery-popover.component.scss', '../layer-popover.scss'],
})
export class OngRefineryPopoverComponent extends BasePopover {
    public currentSelectionIndex: number;
    public currentSelection: any;

    constructor() {
        super();
    }

    onInit() {}

    updateData() {
        if (this.data.units.length > 0) {
            this.currentSelectionIndex = 0;
            this.currentSelection = this.data.units[0];
        }
    }

    public previousSelection(): void {
        this.currentSelectionIndex -= 1;
        this.currentSelection = this.data.units[this.currentSelectionIndex - 1];
    }

    public nextSelection(): void {
        this.currentSelectionIndex += 1;
        this.currentSelection = this.data.units[this.currentSelectionIndex - 1];
    }

    afterInit() {}
}
