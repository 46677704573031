import * as moment from 'moment';
import {GenRRCCLocation} from '../../../../../generated/serverModels/GenRRCCLocation';
import {RRCCContact} from '../../../classes/rrcc-contact';
import {RRCCPersonnel} from '../../../classes/rrcc-personnel';
import {SystemEvent} from '../../system-event/classes/system-event';
import {RRCCDeployment} from './rrcc-deployment';
import {RRCCDesk} from './rrcc-desk';

export class RRCCLocation extends GenRRCCLocation {
    public expanded: boolean;
    public editing: boolean;
    public status: string;
    public startDate: moment.Moment;
    public endDate: moment.Moment;
    public subtitle: string;

    // Contact Data
    public contacts: RRCCContact[];
    public emails: RRCCContact[] = [];
    public phoneNumbers: RRCCContact[] = [];

    public personnel: RRCCPersonnel[];

    public eventIds: number[] = [];

    public prevLocation: RRCCLocation;

    public updatedDeployment: boolean = false;

    public desks: RRCCDesk[] = [];
    public deployments: RRCCDeployment[] = [];
    public pastDeployments: RRCCDeployment[] = [];
    public events: SystemEvent[] = [];

    constructor(location?: RRCCLocation) {
        super(location);

        if (location) {
            this.deployments = location.deployments.map((d) => new RRCCDeployment(d));
            this.pastDeployments = location.pastDeployments.map((d) => new RRCCDeployment(d));
            this.desks = location.desks.map((d) => new RRCCDesk(d as any));
            this.events = location.events.map((d) => new SystemEvent(d));

            if (location.city && location.stateName) {
                this.subtitle = `${location.city}, ${location.stateName}`;
            } else if (location.city) {
                this.subtitle = `${location.city}`;
            } else if (location.stateName) {
                this.subtitle = `${location.stateName}`;
            }

            this.eventIds = location?.events?.map((e) => e.id) || [];

            this.notes = location?.notes;
            this.createdBy = location?.createdBy;
        }
    }

    public createUpdatedLocation(): RRCCLocation {
        const newLocation = new RRCCLocation(this);

        newLocation.contacts = this.contacts
            .filter((c) => c.information !== c.tmpValue)
            .map((c) => {
                const contact = new RRCCContact(c as any);
                contact.information = c.tmpValue;
                return contact;
            });

        newLocation.personnel = this.personnel
            .filter((p) => p.name !== p.tmpValue)
            .map((p) => {
                const personnel = new RRCCPersonnel(p as any);
                personnel.name = p.tmpValue;
                return personnel;
            });

        return newLocation;
    }
}
