import {GenFemaRegion} from '../../../../../generated/serverModels/GenFemaRegion';

export class FemaRegion extends GenFemaRegion {
    static NUMERAL_MAP = new Map<number, string>([
        [1, 'I'],
        [2, 'II'],
        [3, 'III'],
        [4, 'IV'],
        [5, 'V'],
        [6, 'VI'],
        [7, 'VII'],
        [8, 'VIII'],
        [9, 'IX'],
        [10, 'X'],
    ]);

    public readonly name: string;

    constructor(femaRegion?: FemaRegion) {
        super(femaRegion);
        this.name = `FEMA ${FemaRegion.NUMERAL_MAP.get(femaRegion.dotregion)}`;
    }
}
