import {PopoverElement} from './popover-element';
import {CustomPopover} from '../modules/layer/enum/custom-popover';
import {BehaviorSubject} from 'rxjs';
import {ElementRef} from '@angular/core';

enum Quadrant {
    TOP_LEFT = 'top-left',
    TOP_RIGHT = 'top-right',
    BOTTOM_LEFT = 'bottom-left',
    BOTTOM_RIGHT = 'bottom-right',
}

export interface ShowPopoverConfig {
    x: number;
    y: number;
    data: PopoverElement[];
    rawData?: any;
    interactive?: boolean;
    popoverId?: number;
    element?: ElementRef<HTMLElement>;
}

export class PopoverConfig {
    static status: string;
    static data: PopoverElement[] = [];
    static customPopover: CustomPopover;
    static inPopover: boolean;
    static rawData = new BehaviorSubject<any>(undefined);
    static handle: string;
    static offset: number = document.body.querySelector('.below-banner-text') !== null ? 23 : 0;

    static rootElement: ElementRef<HTMLElement>;

    static showNewPopover(config: ShowPopoverConfig): void {
        const popoverElement = config.element || PopoverConfig.rootElement;

        const getQuadrant = () => {
            const bounds = document.body.getBoundingClientRect();
            const midpoint = {x: bounds.width / 2, y: bounds.height / 2};

            if (config.y <= midpoint.y) {
                return config.x <= midpoint.x ? Quadrant.TOP_LEFT : Quadrant.TOP_RIGHT;
            } else {
                return config.x <= midpoint.x ? Quadrant.BOTTOM_LEFT : Quadrant.BOTTOM_RIGHT;
            }
        };

        if (config.interactive) {
            popoverElement.nativeElement.classList.add('interactive');
        } else {
            popoverElement.nativeElement.classList.remove('interactive');
        }

        this.customPopover = config.popoverId;
        PopoverConfig.data = config.data;
        PopoverConfig.rawData.next(config.rawData);
        popoverElement.nativeElement.style.position = 'absolute';

        requestAnimationFrame(() => {
            const popoverRect = popoverElement.nativeElement.getBoundingClientRect();
            const bodyRect = document.body.getBoundingClientRect();

            const top = config.y - PopoverConfig.offset + (config.interactive ? -5 : 10);
            const bottom = config.y - (config.interactive ? -5 : 0);

            const topOverflow = top + popoverRect.height - bodyRect.height;
            const adjustedTop = topOverflow > 0 ? top - topOverflow - 25 : top;

            const bottomOverflow = bottom - popoverRect.height;
            const adjustedBottom =
                bottomOverflow < PopoverConfig.offset ? bottom + Math.abs(bottomOverflow) + 25 + PopoverConfig.offset : bottom;

            switch (getQuadrant()) {
                case Quadrant.TOP_LEFT:
                    popoverElement.nativeElement.style.left = `${config.x}px`;
                    popoverElement.nativeElement.style.top = `${adjustedTop}px`;
                    popoverElement.nativeElement.style.bottom = null;
                    break;
                case Quadrant.TOP_RIGHT:
                    popoverElement.nativeElement.style.left = `${config.x - popoverRect.width}px`;
                    popoverElement.nativeElement.style.top = `${adjustedTop}px`;
                    popoverElement.nativeElement.style.bottom = null;
                    break;
                case Quadrant.BOTTOM_LEFT:
                    popoverElement.nativeElement.style.left = `${config.x}px`;
                    popoverElement.nativeElement.style.top = null;
                    popoverElement.nativeElement.style.bottom = `${bodyRect.height - adjustedBottom}px`;
                    break;
                case Quadrant.BOTTOM_RIGHT:
                    popoverElement.nativeElement.style.left = `${config.x - popoverRect.width}px`;
                    popoverElement.nativeElement.style.top = null;
                    popoverElement.nativeElement.style.bottom = `${bodyRect.height - adjustedBottom}px`;
                    break;
            }
            popoverElement.nativeElement.classList.remove('invisible');
        });
    }

    static hideNewPopover(popoverElement?: ElementRef<HTMLElement>): void {
        popoverElement = popoverElement || PopoverConfig.rootElement;
        popoverElement.nativeElement.classList.add('invisible');
    }
}
