
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import { GenReportRequest } from './GenReportRequest';
import * as moment from 'moment';

export class GenReportRequestGroup {

    id: number;
    endDate: moment.Moment;
    frequency: number;
    name: string;
    requestOwnerId: number;
    requests: GenReportRequest[];
    startDate: moment.Moment;

    constructor();
    constructor( jsonObj: any );
    constructor( genReportRequestGroup: GenReportRequestGroup);
    constructor( id: number );

    constructor( input?: GenReportRequestGroup|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            if ( input.endDate && typeof input.endDate === 'string' ) {
                this.endDate = moment(input.endDate).utc();
            } else {
                this.endDate = input.endDate;
            }
            this.frequency = input.frequency; // int [number]
            this.name = input.name; // string [string]
            this.requestOwnerId = input.requestOwnerId; // int [number]
            if ( input.requests && input.requests.map ) { // list [GenReportRequest]
                this.requests = input.requests.map( ( x: GenReportRequest ) => {
                    return new GenReportRequest( x );
                } );
            } else {
                this.requests = input.requests;
            }
            if ( input.startDate && typeof input.startDate === 'string' ) {
                this.startDate = moment(input.startDate).utc();
            } else {
                this.startDate = input.startDate;
            }
       }
    }

    static jsonResponseToList(arr: any[]): GenReportRequestGroup[] {
        if (arr && arr.map) {
          return arr.map(a => new GenReportRequestGroup(a));
        } else {
          return [];
        }
    }
}

