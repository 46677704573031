// Not generating class since it is created from DataPage API
import * as moment from 'moment';
import * as marked from 'marked';

export class ReleaseNote {
    public released: moment.Moment;
    public notes: string;
    public majorVersion: number;
    public minorVersion: number;
    public buildIdentifier: string;

    constructor(note?: ReleaseNote) {
        if (note) {
            this.released = moment(note.released);
            this.minorVersion = note.minorVersion;
            this.majorVersion = note.majorVersion;
            this.buildIdentifier = note.buildIdentifier;
            this.notes = marked(note.notes);
        }
    }
}
