import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {LayerStyleService} from '../../../app/modules/layer/services/layer-style.service';
import {ApplicationConfig} from '../../../app/classes/application-config';
import {LegendMetric} from '../../enums/legend-metric.enum';
import {UserPreference} from '../../../app/classes/user-preferences';
import {LegendStyle} from '../../../app/modules/user/classes/legend-style';

@Component({
    selector: 'eaglei-nom-legend',
    templateUrl: 'nom-legend.component.html',
    styleUrls: ['nom-legend.component.scss'],
})
export class NomLegendComponent implements AfterViewInit, OnInit {
    set metric(value: LegendMetric) {
        this._metric = value;
        this.getLegend();
    }

    set range(value: string[]) {
        this._range = value;
        this.getLegend();
    }

    set style(value: LegendStyle) {
        this._style = value;
        this._range = value.colors;
        ApplicationConfig.updateOverrideColor(this.overrideHash, this.overrideText, value.overrideColor);
        this.getLegend();
    }

    @ViewChild('overrideText') overrideText: ElementRef;
    @ViewChild('overrideHash') overrideHash: ElementRef;

    @Input() disableHideOverrides: boolean;
    @Input() multiLine: boolean;
    @Input() preferences: UserPreference;

    @Input() hideOverrideIcon: boolean;

    @Output() hideOverrides = new EventEmitter<boolean>();

    private _range: string[];
    private _metric: LegendMetric;
    private _style: LegendStyle;

    public values: string[] = [];
    public colors: string[] = [];

    get showOverrides(): boolean {
        return ApplicationConfig.showOverrideColor.value;
    }

    constructor(public styleService: LayerStyleService, public ele: ElementRef<HTMLElement>) {}

    ngOnInit() {
        const preferences = this.preferences ? this.preferences : ApplicationConfig.currentUserPreferences.getValue();

        this._metric = LegendMetric.from(preferences.legendMetric) || LegendMetric.CUSTOMERS_OUT;
        this._range = preferences.hasLegendStyle() ? preferences.legendStyle.colors : LayerStyleService.nomRange;
    }

    ngAfterViewInit(): void {
        ApplicationConfig.updateOverrideColor(this.overrideHash, this.overrideText);
        this.getLegend();
    }

    public updateLegend(): void {
        const preferences = this.preferences ? this.preferences : ApplicationConfig.currentUserPreferences.getValue();

        this._metric = LegendMetric.from(preferences.legendMetric) || LegendMetric.CUSTOMERS_OUT;
        this._range = preferences.hasLegendStyle() ? preferences.legendStyle.colors : LayerStyleService.nomRange;
        ApplicationConfig.updateOverrideColor(this.overrideHash, this.overrideText);
        this.getLegend();
    }

    private getLegend(): void {
        this.colors = this._range;

        if (this._metric === LegendMetric.CUSTOMERS_OUT) {
            this.values = LayerStyleService.nomOutageLegend.map((v) => v.display);
        } else if (this._metric === LegendMetric.PERCENTAGE) {
            this.values = LayerStyleService.nomPercentLegend.map((v) => v.display);
        }
    }

    public getHtml(): ElementRef {
        return this.ele;
    }

    public getTextColor(hexColor: string): string {
        const r = parseInt(hexColor.slice(1, 3), 16);
        const g = parseInt(hexColor.slice(3, 5), 16);
        const b = parseInt(hexColor.slice(5), 16);
        const ret = r * 0.299 + g * 0.587 + b * 0.114;

        return ret > 126 ? '#000' : '#fff';
    }

    public getOverrideColor(): string {
        return ApplicationConfig.overrideColor;
    }

    public toggleShowOverrides(): void {
        if (this.disableHideOverrides) {
            return;
        }
        ApplicationConfig.showOverrideColor.next(!this.showOverrides);
        this.hideOverrides.emit(!this.showOverrides);
    }
}
