import {Component} from '@angular/core';
import {EagleILayer} from '../../classes/eagle-i-layer';
import {LeafletWmsSource} from '../../classes/leaflet-wms-source';
import {LeafletLegendService} from '../../services/leaflet-legend.service';
import {GenLayerFilterType} from '../../../../../../generated/serverModels/GenLayerFilterType';
import {ISelectOption} from '../../interfaces/select-option.interface';
import {IFilterChange} from '../../interfaces/filter-change.interface';
import {IdentifyService} from '../../../map/services/identify.service';
import {LeafletMapLayer} from '../../classes/leaflet-map-layer';

@Component({
    selector: 'eaglei-leaflet-wms-layer',
    templateUrl: './leaflet-wms-layer.component.html',
    styleUrls: ['./leaflet-wms-layer.component.scss'],
})
export class LeafletWmsLayerComponent extends EagleILayer<LeafletWmsSource> {
    private filterMap = new Map<string, string>();

    constructor(private legendService: LeafletLegendService, private identifyService: IdentifyService) {
        super();
    }
    afterViewInit() {}

    toggleLayer(activeState: boolean): void {
        if (activeState) {
            if (!this.layerSource) {
                this.layerSource = new LeafletWmsSource(this.mapLayer);
                if (this.mapLayer.legend) {
                    this.legendService.getLegend(this).subscribe((legend) => (this.legend = legend));
                }
            }

            this.layerSource.fetchImages();
            this.layerSource.addToMap();
        } else {
            this.layerSource.removeFromMap();
        }
    }

    /**
     * Method that is called from a filter firing.
     * it wil check the filter type and build the correct CQL filter string, then it will pass that string to the WMS source to
     * redraw the layer with the correct feature.
     * @param change The change received from the filter container.
     */
    public updateFilterQuery(change: IFilterChange): void {
        const filterValue: any = change.value;
        const filter = change.filter;

        let filterString: string;
        // Using the type to get the correct filter function for CQL filter
        // GenFilterType.TEXT_SEARCH: strToLowerCase
        // GenFilterType.MULTI_SELECT: IN(p1,p2)
        // GenFilterType.NUMERIC_RANGE: BETWEEN(value, low, high)
        // GenFilterType.SINGLE_SELECT: =
        switch (filter.type) {
            case GenLayerFilterType.TEXT_SEARCH:
                filterString = `strToLowerCase(${filter.filterProperty}) like '%${filterValue.toLowerCase()}%'`;
                break;
            case GenLayerFilterType.NUMERIC_RANGE:
                const min = filterValue.min;
                const max = filterValue.max;
                if (isNaN(min) && isNaN(max)) {
                    // if both are NaN, don't filter
                    filterString = undefined;
                } else {
                    filterString = `${filter.filterProperty}`;
                    if (isNaN(min)) {
                        // min isn't set but max is
                        filterString += ` < '${filterValue.max}'`;
                    } else if (isNaN(max)) {
                        // max set but min isn't
                        filterString += ` > '${filterValue.min}'`;
                    } else {
                        // both are numbers
                        filterString += ` BETWEEN '${filterValue.min}' AND '${filterValue.max}'`;
                    }
                }
                break;
            case GenLayerFilterType.MULTI_SELECT:
            case GenLayerFilterType.SINGLE_SELECT: // list with 1 entry is still a list.
                const list = filterValue as ISelectOption[];

                if (list.length === 1) {
                    const val = list[0].value; // got to handle the freaking all case
                    if (list[0].override) {
                        filterString = `${filter.filterProperty} ${list[0].override}`;
                    } else {
                        filterString = val ? `${filter.filterProperty} = '${val}' ` : ``;
                    }
                } else if (list.length) {
                    const containsOverride = (filter.getParams() as ISelectOption[]).some((f) => f.override);

                    if (containsOverride) {
                        filterString =
                            '(' +
                            list
                                .map((ele) => {
                                    return ele.override
                                        ? `${filter.filterProperty} ${ele.override.replace(/'/g, '')}`
                                        : `${filter.filterProperty} = ${ele.value}`;
                                })
                                .join(' or ') +
                            ')';
                    } else {
                        const inList = list.map((o) => `'${o.value}'`).join(',');
                        filterString = `${filter.filterProperty} IN (${inList})`;
                    }
                } else {
                    // This is to remove all features when no checkboxes are selected
                    filterString = '1 = -1';
                }
                break;
            default:
                console.warn('filter type of ' + filter.type.toString() + ' is not configured');
        }

        // Using the property and type as the key, in case we want multiple of the same filter tpes
        const key = `${filter.filterProperty}-${filter.type.toString()}`;

        this.filterMap.set(key, filterString);

        // Building filter string for multiple properties
        const fullLayerFilter = Array.from(this.filterMap.values())
            .filter((v) => !!v)
            .join(' AND ');

        this.layerSource.applyFilter(fullLayerFilter);

        this.identifyService.identifyGroup.getLayers().forEach((layer: any) => {
            if ((layer.feature.eagleiLayer as LeafletMapLayer).uiHandle === this.mapLayer.uiHandle) {
                this.identifyService.identifyGroup.removeLayer(layer);
            }
        });
    }
}
