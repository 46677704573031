import {Component} from '@angular/core';
import {NewsfeedSource} from '../../sources/newsfeed-source';
import {MapService} from '../../../map/services/map.service';
import {LayerService} from '../../services/layer.service';
import {EagleILayer} from '../../classes/eagle-i-layer';
import {filter, map, tap} from 'rxjs/operators';
import {IDateRange} from '../../../../interfaces/date-range.interface';
import {AuthenticationService} from '../../../../services/authentication.service';
import {LeafletLayerLegend} from '../../classes/leaflet-layer-legend';
import {LegendElement} from '../../classes/legend-element';
import {TitleCasePipe} from '@angular/common';
import {Newsfeed} from '../../classes/newsfeed';

@Component({
    selector: 'eaglei-newsfeed-layer',
    templateUrl: './newsfeed-layer.component.html',
    styleUrls: ['./newsfeed-layer.component.scss'],
})
export class NewsfeedLayerComponent extends EagleILayer<NewsfeedSource> {
    private filters: IDateRange;
    private titlePipe = new TitleCasePipe();

    constructor(private layerService: LayerService, private mapService: MapService, private authenticationService: AuthenticationService) {
        super();
    }

    afterViewInit(): void {}

    toggleLayer(activeState: boolean): void {
        if (activeState) {
            if (!this.layerSource) {
                this.layerSource = new NewsfeedSource(this.mapLayer, this.authenticationService.authenticatedUser.getValue());

                const legend: LeafletLayerLegend = new LeafletLayerLegend(this.mapLayer.id, this.mapLayer.displayName);
                legend.elements = Object.keys(this.layerSource.legendIcons)
                    .filter((key) => this.layerSource.legendIcons[key].active)
                    .map((key) => {
                        return new LegendElement(this.titlePipe.transform(key))
                            .setImageUrl(this.layerSource.legendIcons[key].imagePath)
                            .setHeight(48)
                            .setWidth(48);
                    });

                this.legend = [legend];
            }

            this.getNewsfeedEvents();
        } else {
            this.layerSource.removeFromMap();
        }
    }

    private getNewsfeedEvents(): void {
        this.mapLayer.startLoading();
        this.filters = this.mapService.mapDateRange.getValue();
        const url = `${this.mapLayer.serviceurl}?start=${this.filters.startDate.format()}&end=${this.filters.endDate.format()}`;
        this.layerService
            .getJsonFromUrl(url, true)
            .pipe(
                map((res: any[]) => res.map((newsfeed) => new Newsfeed(newsfeed))),
                tap(() => this.mapLayer.endLoading())
            )
            .subscribe((res) => {
                this.layerSource.processFeatures(res);
                this.layerSource.addToMap();
            });
    }

    //
    // public fixLegendName(name: string): string {
    //   return name.replace(/( |^)[a-z]/g, (s) => s.toUpperCase());
    // }
}
