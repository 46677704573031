<div class="dialog-header">
    <span>Summary Update</span>
    <button mat-icon-button (click)="ref.close()">
        <mat-icon fontSet="fas" fontIcon="fa-times"></mat-icon>
    </button>
</div>

<div class="dialog-content" #content>
    <eaglei-date-range
        [start]="startDate"
        [end]="endDate"
        [min]="minDate"
        [showTime]="true"
        [layout]="'column'"
        (change)="updateDates($event)">
    </eaglei-date-range>
</div>

<div class="dialog-actions">
    <button mat-raised-button color="primary" (click)="updateSummaryData()">Update</button>
</div>
