import {Injectable} from '@angular/core';
import {MapLayerPopover} from '../classes/map-layer-popover';
import {MatDialogConfig} from '@angular/material/dialog';
import {ModalConfig} from '../../../classes/modal-config';
import {EilDownloadModalComponent} from '../../map/modals/eil-download-modal/eil-download-modal.component';
import {EilData} from '../../../classes/eil-data';
import {EilMetadataModalComponent} from '../../map/modals/eil-metadata-modal/eil-metadata-modal.component';
import {PopoverDataModalComponent} from '../../map/modals/popover-data-modal/popover-data-modal.component';

@Injectable({
    providedIn: 'root',
})
export class PopoverService {
    constructor() {}

    static getDialogInfo(row: MapLayerPopover, data: any): any {
        switch (row.propertyName) {
            case 'eilDocumentModal':
                if ((data as EilData)?.documents?.length === 0) {
                    return undefined;
                }

                const fileConfig: MatDialogConfig = {
                    data,
                    width: ModalConfig.getModalWidth(),
                    autoFocus: false,
                    disableClose: true,
                };

                return {config: fileConfig, ref: EilDownloadModalComponent};
            default:
                const modalData = {
                    content: data[row.propertyName],
                };

                const config = {
                    width: ModalConfig.getModalWidth(),
                    data: modalData,
                    autoFocus: false,
                    defaultConfig: true,
                };

                return {config, ref: PopoverDataModalComponent};
        }
    }
}
