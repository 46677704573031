import {PopoverElement} from 'frontend/src/app/classes/popover-element';
import * as L from 'leaflet';
import {LeafletFeature} from '../classes/leaflet-feature';
import {LeafletMapLayer} from '../classes/leaflet-map-layer';
import {LeafletVectorSource} from '../classes/leaflet-vector-source';
import {FeatureStyle} from '../interfaces/style.interface';
import {LeafletEagleiSource} from '../classes/leaflet-eaglei-source';
import {ReplaceCharacterPipe} from 'frontend/src/shared/pipes/replace-character.pipe';
import {TitleCasePipe} from '@angular/common';

export class RiverFloodingSource extends LeafletVectorSource {
    // Popover Pipes
    private replacePipe = new ReplaceCharacterPipe();
    private titleCasePipe = new TitleCasePipe();

    constructor(layerInfo: LeafletMapLayer) {
        super(layerInfo);
    }

    /**
     * Process the features into Leaflet Features to display on the map
     * @param features Features to display on map
     */
    processFeatures(features: any[]): void {
        const leafletFeatures = features
            .map((f) => {
                const feature = new LeafletFeature(f);

                const properties = feature.properties;
                const data = [
                    new PopoverElement().setTitle().setValue('River Flooding Forecast'),
                    new PopoverElement().setLabel('Location').setValue(properties.location),
                    new PopoverElement().setLabel('Waterbody').setValue(properties.waterbody),
                    new PopoverElement().setLabel('Action Threshold').setValue(`${properties.action} ${properties.units}`),
                    new PopoverElement().setLabel('Forecast').setValue(`${properties.forecast} ${properties.units}`),
                    new PopoverElement()
                        .setLabel('Status')
                        .setValue(this.titleCasePipe.transform(this.replacePipe.transform(properties.status))),
                    new PopoverElement().setLabel('Forecast Time').setValue(LeafletEagleiSource.momentPipe.transform(properties.fcsttime)),
                ];

                feature.popoverData = data;
                feature.subFeatures = [
                    {
                        id: properties.objectid,
                        popoverData: data,
                    },
                ];

                return feature;
            })
            .filter((f) => !!f);

        const style = (feature: LeafletFeature, latlng: L.LatLng): L.Layer => {
            return (L as any).shapeMarker(latlng, getStyle(feature));
        };

        const layerPopover = (f: LeafletFeature, l) => {
            this.initializePopoverInteractions(f, l, 'click', false, f.popoverData);
        };

        const config = {
            pointToLayer: style,
            onEachFeature: layerPopover,
            features: this.leafletFeatures,
        };

        this.source = L.geoJSON(leafletFeatures as any, config as any);
    }
}

/**
 * Determines how a features should look when displayed
 * @param feature Feature to get the style for
 * @returns Feature style
 */
function getStyle(feature: any): any {
    const status = feature.properties.status;
    let color = '#00FF00';

    switch (status) {
        case 'major':
            color = '#CC33FF';
            break;
        case 'moderate':
            color = '#FF0000';
            break;
        case 'minor':
            color = '#FF9900';
            break;
        case 'action':
            color = '#FFFF00';
            break;
        case 'low_threshold':
            color = '#906320';
            break;
    }

    return {
        shape: 'circle',
        radius: 5,
        fillColor: color,
        stroke: true,
        color: '#000',
        weight: 1,
        fillOpacity: 1,
    } as any;
}
