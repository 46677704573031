import {Component, OnInit} from '@angular/core';
import {LayerService} from '../../services/layer.service';
import {map} from 'rxjs/operators';
import {CoordinateDataModalComponent} from '../../../map/modals/coordinate-data-modal/coordinate-data-modal.component';
import {ModalConfig} from '../../../../classes/modal-config';
import {MatDialog} from '@angular/material/dialog';
import {LeafletFeature} from '../../classes/leaflet-feature';
import * as L from 'leaflet';
import {LocationSearchService} from '../../../map/services/location-search.service';
import {Filter} from '../../classes/filter';
import {GenLayerFilterType} from '../../../../../../generated/serverModels/GenLayerFilterType';
import {IFilterChange} from '../../interfaces/filter-change.interface';

@Component({
    selector: 'eaglei-hurricane-cone-search',
    templateUrl: './hurricane-cone-search.component.html',
    styleUrls: ['./hurricane-cone-search.component.scss'],
})
export class HurricaneConeSearchComponent implements OnInit {
    private readonly url =
        'https://nowcoast.noaa.gov/arcgis/rest/services/nowcoast/wwa_meteocean_tropicalcyclones_trackintensityfcsts_time/MapServer';
    public stormFeatures: LeafletFeature[] = [];
    public showConfig: boolean;
    public selectedStorm: string;
    public filter: Filter;

    constructor(private layerService: LayerService, private dialog: MatDialog, private locationSearchService: LocationSearchService) {
        this.filter = new Filter();
        this.filter.type = GenLayerFilterType.SINGLE_SELECT;
        this.filter.title = 'Show Affected Areas';
    }

    ngOnInit(): void {
        this.getActiveStorms();
    }

    public getConeUrl(): string {
        const coneId = 6;
        const x = {
            where: 'stormname IS NOT null',
            geometryType: 'esriGeometryEnvelope',
            spatialRel: 'esriSpatialRelIntersects',
            outFields: '*',
            returnGeometry: true,
            returnTrueCurves: false,
            f: 'geojson',
        };

        const q = Object.keys(x)
            .map((key) => `${key}=${x[key]}`)
            .join('&');
        return `${this.url}/${coneId}/query?${q}`;
    }

    private getActiveStorms(): void {
        const url = this.getConeUrl();
        const values: any[] = [];
        const mf = (res: any) => {
            return res.features.map((feature) => {
                const f = new LeafletFeature(feature);
                values.push({display: f.properties.stormname, value: f.properties.stormname});
                return f;
            });
        };

        this.layerService
            .getJsonFromUrl(url)
            .pipe(map(mf))
            .subscribe((stormFeatures: LeafletFeature[]) => {
                stormFeatures.sort((a, b) => (a.properties.stormname > b.properties.stormname ? 1 : -1));
                this.filter.values = JSON.stringify(values);
                this.stormFeatures = stormFeatures;
            });
    }

    public selectStorm(change: IFilterChange): void {
        const stormName = change.value[0].value;
        const feature = this.stormFeatures.find((f) => f.properties.stormname === stormName);
        const layer = L.geoJSON(feature as any);

        this.locationSearchService.getFeaturesIntersectingLayer(layer);

        this.dialog.open(CoordinateDataModalComponent, {
            width: ModalConfig.getModalWidth(),
            data: {
                initialLoad: true,
                headerText: `Affected areas for ${stormName}`,
            },
        });
    }
}
