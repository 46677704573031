interface IShowOptions {
    sidebar?: boolean;
    refresh?: boolean;
    export?: boolean;
    coverage?: boolean;
    coordinate?: boolean;
    panControl?: boolean;
    zoom?: boolean;
    zoomToHome?: boolean;
}

export class MapOptions {
    get show(): IShowOptions {
        return this._show;
    }

    set show(value: IShowOptions) {
        this._show = Object.assign(this._show, value);
    }

    public defaultZoom: number;
    public minZoom: number = 3;
    public defaultCenter: [number, number];
    public onlyManualZoom: boolean;

    private _show: IShowOptions = {
        sidebar: true,
        refresh: true,
        export: true,
        coverage: true,
        coordinate: true,
        zoom: true,
        panControl: false,
        zoomToHome: true,
    };

    constructor() {
        this.defaultCenter = [39.8282, -98.5795];
        this.defaultZoom = 5;
    }

    public setZoom(zoom: number, minZoom?: number): MapOptions {
        this.defaultZoom = zoom;
        if (minZoom !== undefined) {
            this.minZoom = minZoom;
        }
        return this;
    }

    public setCenter(lat: number, lon: number): MapOptions {
        this.defaultCenter = [lat, lon];
        return this;
    }
}
