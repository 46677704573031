import {Pipe, PipeTransform} from '@angular/core';
import {LandingType} from '../../app/landing/enums/landing-type.enum';

@Pipe({
    name: 'dashboardType',
})
export class DashboardTypePipe implements PipeTransform {
    transform(value: number): string {
        switch (value) {
            case LandingType.ELECTRIC:
                return 'Electric Situational Awareness';
            case LandingType.NATURAL_GAS:
                return 'Natural Gas';
            case LandingType.OIL:
                return 'Oil';
            case LandingType.ANALYTIC:
                return 'Analytics';
            case LandingType.ADMIN:
                return 'Associated Documents/Status';
            default:
                return `${value} is not a valid LandingType`;
        }
    }
}
