import {LeafletFeature} from '../classes/leaflet-feature';
import {LeafletMapLayer} from '../classes/leaflet-map-layer';
import {LeafletVectorSource} from '../classes/leaflet-vector-source';
import * as L from 'leaflet';
import {LayerStyleService} from '../services/layer-style.service';
import {FeatureStyle} from '../interfaces/style.interface';
import {MapService} from '../../map/services/map.service';
import {PopoverElement} from 'frontend/src/app/classes/popover-element';
import {PortStatus} from '../classes/port-status';

export class PortStatusSource extends LeafletVectorSource {
    private fillColor: string = '#84da7d';
    private spaceColor: string = '#4f88b5';
    private patternName: string = 'noPattern';
    public readonly statusStrings: string[] = ['Default', 'Open', 'Restrictions', 'Closed'];

    constructor(layerInfo: LeafletMapLayer) {
        super(layerInfo);
    }

    processFeatures(features: PortStatus[]): void {
        const leafletFeatures = features.map((f) => {
            const feature = new LeafletFeature().convert(f, 'geom').buildPopover(this.layerInfo);

            if (feature) {
                feature.subFeatures = feature?.properties?.ports?.map((port) => {
                    const data = [
                        new PopoverElement().setTitle().setValue(port.name),
                        new PopoverElement().setLabel('Region').setValue(feature.properties.name),
                        new PopoverElement().setLabel('Port Status').setValue(this.statusStrings[port.status]),
                        new PopoverElement()
                            .setLabel('Last Updated')
                            .setValue(LeafletVectorSource.momentPipe.transform(port.lastModDate, 'MM/DD/YYYY')),
                        new PopoverElement().setLabel('Comments').setValue(port.comments === 'null' ? 'Not Available' : port.comments),
                    ];
                    // {{port.comments == 'null' ? 'Not Available' : port.comments}}

                    return {
                        id: port.id,
                        popoverData: data,
                    };
                });
            }

            return feature;
        });

        const featureStyle = (feature: LeafletFeature<PortStatus>) => {
            return this.getFeatureStyle(feature.properties);
        };

        const config = {
            pane: MapService.layerPaneName,
            style: featureStyle,
            onEachFeature: this.initializePopoverInteractions.bind(this),
            features: leafletFeatures,
        };

        this.source = L.geoJSON(leafletFeatures as any, config as any);
    }

    private getFeatureStyle(feature: PortStatus): FeatureStyle {
        // Get port summary
        this.getPortStatusStyle(feature.ports);

        // Form the patterns
        const stripeOptions = {angle: 120, weight: 5, color: this.fillColor, spaceColor: this.spaceColor, spaceOpacity: 1};
        const hiddenOptions = {angle: 0, weight: 20, color: this.fillColor, spaceColor: this.fillColor, spaceOpacity: 1};

        const stripe = new (L as any).StripePattern(stripeOptions);
        const noPattern = new (L as any).StripePattern(hiddenOptions);

        noPattern.addTo(MapService.mapRef);
        stripe.addTo(MapService.mapRef);

        const key = `port-status-${this.patternName}`;
        const pattern = this.patternName === 'stripe' ? stripe : noPattern;
        const style = {
            color: '#ffffff',
            opacity: 1,
            weight: 1,
            fillPattern: pattern,
            fillOpacity: 0.9,
        };

        LayerStyleService.layerStyles.set(key, style);

        return style;
    }

    private getPortStatusStyle(ports): void {
        const legendArray = [];
        this.patternName = 'noPattern';
        this.fillColor = '#84da7d';

        for (const item of ports) {
            legendArray.push(item['status']);
        }

        if (legendArray.indexOf(1) === -1) {
            // no ports.ts open
            this.fillColor = '#c8c8c8';
        }
        if (legendArray.indexOf(3) !== -1) {
            // closed
            this.spaceColor = '#4f88b5';
            this.patternName = 'stripe';
        }
        if (legendArray.indexOf(2) !== -1) {
            // Restrictions
            this.spaceColor = '#A4561B';
            this.patternName = 'stripe';
        }
        if (legendArray.indexOf(2) !== -1 && legendArray.indexOf(3) !== -1) {
            // closed & Restrictions
            this.spaceColor = '#6C00E0';
            this.patternName = 'stripe';
        }
    }

    public changeOpacity(opacity: number): void {
        if (!this.source) {
            return;
        }
        this.layerInfo.opacity = opacity;
        this.source.setStyle({opacity, fillOpacity: opacity});
    }

    public clearMap(): void {
        this.source.clearLayers();
    }
}
