import {Component} from '@angular/core';
import {EarthquakeSource} from '../../sources/earthquake-source';
import {MapService} from '../../../map/services/map.service';
import {tap} from 'rxjs/operators';
import {LayerService} from '../../services/layer.service';
import {EagleILayer} from '../../classes/eagle-i-layer';
import {IDateRange} from '../../../../interfaces/date-range.interface';
import {LeafletLegendService} from '../../services/leaflet-legend.service';

@Component({
    selector: 'eaglei-earthquake-layer',
    templateUrl: './earthquake-layer.component.html',
    styleUrls: ['./earthquake-layer.component.scss'],
})
export class EarthquakeLayerComponent extends EagleILayer<EarthquakeSource> {
    private dateRange: IDateRange;

    constructor(private mapService: MapService, private layerService: LayerService, private legendService: LeafletLegendService) {
        super();
    }

    afterViewInit(): void {}

    toggleLayer(activeState: boolean, zoomToFeatures?: boolean): void {
        if (activeState) {
            if (!this.layerSource) {
                this.layerSource = new EarthquakeSource(this.mapLayer);
                this.legendService.getLegend(this).subscribe((legend) => (this.legend = legend));
            }

            this.getEarthquakeInfo();
        } else {
            this.layerSource.removeFromMap();
        }
    }

    private getEarthquakeInfo(): void {
        this.dateRange = this.mapService.mapDateRange.getValue();

        const p = {
            format: 'geojson',
            starttime: this.dateRange.startDate.format(),
            endtime: this.dateRange.endDate.format(),
            minmagnitude: 2.5,
        };
        const params = Object.keys(p)
            .map((key) => `${key}=${p[key]}`)
            .join('&');

        const url = `${this.mapLayer.serviceurl}/query?${params}`;

        this.mapLayer.startLoading();
        this.layerService
            .getJsonFromUrl(url)
            .pipe(tap(() => this.mapLayer.endLoading()))
            .subscribe((res) => {
                this.layerSource.processFeatures(res.features);
                this.layerSource.removeFromMap();
                this.layerSource.addToMap();
            });
    }
}
