
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import { GenContactType } from './GenContactType';

export class GenRRCCContact {

    id: number;
    deskId: number;
    information: string;
    permanent: boolean;
    personnelId: number;
    type: GenContactType;

    constructor();
    constructor( jsonObj: any );
    constructor( genRRCCContact: GenRRCCContact);
    constructor( id: number );

    constructor( input?: GenRRCCContact|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            this.deskId = input.deskId; // int [number]
            this.information = input.information; // string [string]
            this.permanent = input.permanent; // boolean [boolean]
            this.personnelId = input.personnelId; // int [number]
            this.type = (typeof input.type === 'string') ? GenContactType.forName(<string> input.type) : <any> input.type; // GenContactType
       }
    }

    static jsonResponseToList(arr: any[]): GenRRCCContact[] {
        if (arr && arr.map) {
          return arr.map(a => new GenRRCCContact(a));
        } else {
          return [];
        }
    }
}

