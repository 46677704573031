import {Component, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {AuthenticationService} from '../../../services/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormControl, Validators} from '@angular/forms';

@Component({
    selector: 'eaglei-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnDestroy {
    public nameControl: UntypedFormControl;

    public passwordRequestSuccessful: boolean;

    public responseCode: number;

    private sub: Subscription;

    constructor(private authenticationService: AuthenticationService, private router: Router, private route: ActivatedRoute) {
        this.nameControl = new UntypedFormControl('', {
            validators: [Validators.required],
        });
        this.sub = this.route.params.subscribe((params) => {
            this.nameControl.setValue(params['username']);
        });
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    /**
     * Tries to send the request for a password change email.
     * if the user is disabled it will prompt to send an email to the admin.
     * if the username or email is invalid, it will show an error to the user.
     */
    public requestPasswordReset(): void {
        const success = () => {
            this.passwordRequestSuccessful = true;
        };

        const failure = (error: any) => {
            this.responseCode = error.status;
        };

        this.responseCode = undefined;
        this.authenticationService.requestPasswordReset(this.nameControl.value).subscribe(success, failure);
    }

    /**
     * navigates back to the login page
     */
    public goBack(): void {
        // noinspection JSIgnoredPromiseFromCall
        this.router.navigateByUrl('/login');
    }

    public goToReactivation(): void {
        const state = {};
        state[this.nameControl.value.includes('@') ? 'email' : 'username'] = this.nameControl.value;

        // noinspection JSIgnoredPromiseFromCall
        this.router.navigate(['login/account-reactivation'], {state});
    }
}
