import {GenUtilityOutageOverride} from '../../../../../generated/serverModels/GenUtilityOutageOverride';

export class OutageComparison extends GenUtilityOutageOverride {
    get id(): string {
        return this._id;
    }

    get outageDifference(): number {
        return this._outageDifference;
    }

    // public readonly stateInfo: State;
    private readonly _id: string;
    private readonly _outageDifference: number;

    constructor(comparison?: OutageComparison) {
        super(comparison);

        this.utilityName = this.utilityName || '';

        if (comparison) {
            this._id = `${this.utilityId}:${this.stateId}:${this.countyId}:${this.subUtilityId}`;
            this._outageDifference = Math.abs(this.outageCount - this.overrideCount);
        }
    }
}
