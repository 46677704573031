import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {UrbannetReport} from '../classes/urbannet-report';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {SystemEvent} from '../../../app/modules/system-event/classes/system-event';
import {UrbannetDisruption} from '../classes/urbannet-disruption';
import {UrbannetEffect} from '../classes/urbannet-effect';

@Injectable({
    providedIn: 'root',
})
export class UrbannetService {
    static zoomToAsset = new BehaviorSubject<UrbannetDisruption | UrbannetEffect>(undefined);
    public assetTableClosed$ = new Subject();
    private readonly baseUrl = `api/urbannet`;

    constructor(private http: HttpClient) {}

    public getReports(): Observable<UrbannetReport[]> {
        const url = `${this.baseUrl}/reports?showDisruption=true`;
        return this.http.get<any[]>(url).pipe(
            map((res) => {
                return res.map((report) => new UrbannetReport(report));
            })
        );
    }

    public getEvents(): Observable<SystemEvent[]> {
        const url = `${this.baseUrl}/events-with-reports`;
        return this.http.get<any[]>(url).pipe(map((res) => res.map((event) => new SystemEvent(event))));
    }

    public getReportsByEvent(eventId: number): Observable<UrbannetReport[]> {
        const url = `${this.baseUrl}/report/by-event/${eventId}?showDisruption=true&showEffect=false`;
        return this.http.get<any[]>(url).pipe(
            map((res) => {
                return res.map((report) => new UrbannetReport(report));
            })
        );
    }

    public getDisruptions(reportId: number): Observable<UrbannetDisruption[]> {
        const url = `${this.baseUrl}/disruptions/${reportId}`;
        return this.http.get<any[]>(url).pipe(map((disruptions) => disruptions.map((disruption) => new UrbannetDisruption(disruption))));
    }

    public uploadFile(event: SystemEvent, file: File): Observable<boolean> {
        const url = `${this.baseUrl}/event-file/${event.id}`;
        const formData = new FormData();

        formData.append('file', file);
        return this.http.post<any>(url, formData);
    }
}
