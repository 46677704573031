import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, inject, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CommunityService} from '../../services/community.service';
import {Community} from '../../classes/community';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatDrawer, MatSidenav} from '@angular/material/sidenav';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CommunityFile} from '../../classes/community-file';
import {CommunityUser} from '../../classes/community-user';
import {AuthenticationService} from '../../../../services/authentication.service';
import {ConfirmationComponent} from '../../../../../shared/modals/confirmation/confirmation.component';
import {filter} from 'rxjs/operators';
import {DenyOptionsComponent} from '../../../../../shared/modals/deny-options/deny-options.component';
import {GenRoleDefinition} from '../../../../../../generated/serverModels/GenRoleDefinition';
import {User} from '../../../user/classes/user';
import {BulkEmailComponent} from '../../../../../shared/modals/bulk-email/bulk-email.component';
import {EmailData} from '../../../../classes/email-data';
import {FileDownload} from '../../../../classes/file-download';
import {ModalConfig} from '../../../../classes/modal-config';
import {ApplicationConfig} from '../../../../classes/application-config';
import {FileUploadModalComponent} from '../../../../../shared/modals/file-upload-modal/file-upload-modal.component';
import {SingleManagerLeaveModalComponent} from '../../modals/single-manager-leave-modal/single-manager-leave-modal.component';
import {GenCommunityUserStatus} from 'frontend/generated/serverModels/GenCommunityUserStatus';
import {GenCommunityPermission} from '../../../../../../generated/serverModels/GenCommunityPermission';

@Component({
    selector: 'eaglei-community',
    templateUrl: './community.component.html',
    styleUrls: ['./community.component.scss'],
})
export class CommunityComponent implements AfterViewInit {
    @ViewChild('communityFileUpload', {static: true}) communityFileUpload: ElementRef;
    @ViewChild(MatDrawer, {static: true}) sidebar: MatSidenav;

    @ViewChild('userPaginator') userPaginator: MatPaginator;
    @ViewChild('documentPaginator') documentPaginator: MatPaginator;
    @ViewChild('requestPaginator') requestPaginator: MatPaginator;

    public community: Community = new Community();
    public managers: CommunityUser[] = [];
    public isManager = false;
    private user: User;

    private _canLinkFilesToEvents: boolean;

    public editCommunity: boolean;

    public selectedTab = 'document';
    public readonly denyOptions: string[] = ['Insufficient Justification', 'Data sharing requirements prohibit access to this community'];

    public userSearchText = '';
    public fileSearchText = '';
    public filteredDocuments: CommunityFile[] = [];
    public filteredUsers: CommunityUser[] = [];

    public tmpCommunity: Community;

    private activatedRoute = inject(ActivatedRoute);
    private communityService = inject(CommunityService);
    private auth = inject(AuthenticationService);
    private router = inject(Router);
    private dialog = inject(MatDialog);
    private popup = inject(MatSnackBar);
    private change = inject(ChangeDetectorRef);

    ngAfterViewInit() {
        this.getCommunity();
    }

    /**
     * Gets a community by the id passed in the url parameter.
     */
    private getCommunity(): void {
        this.communityService.getCommunityById(this.activatedRoute.snapshot.params.id).subscribe({
            next: (community: Community) => {
                this.community = community;
                this.getManagers();
                this.searchDocuments(this.fileSearchText);
                this.searchUsers();

                this._canLinkFilesToEvents = [
                    GenCommunityPermission.FEDERAL_SITUATION_REPORT,
                    GenCommunityPermission.ESF_FIELD_PHOTO,
                ].includes(this.community.dataAccessHandle);
            },
            error: (e: Error) => {
                console.error(e);
            },
        });
    }

    /**
     * Gets A list of all the community managers
     */
    private getManagers(): void {
        this.managers = this.community.users
            .filter((u) => u.manager && u.status === GenCommunityUserStatus.APPROVED)
            .sort((a, b) => {
                if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                else if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                return 0;
            });

        this.user = this.auth.authenticatedUser.getValue();
        this.isManager =
            this.user.hasRole(GenRoleDefinition.ROLE_COMMUNITY_MANAGER) ||
            this.managers.findIndex((manager) => {
                return this.user.id === manager.userId;
            }) !== -1;
    }

    /**
     * Programmatically clicks the hidden file input.
     */
    public clickFileUpload(): void {
        (this.communityFileUpload.nativeElement as HTMLInputElement).click();
    }

    // File Methods

    /**
     * Uploads files to the community
     */
    public uploadFile(): void {
        const files: FileList = this.communityFileUpload.nativeElement.files;

        const config: MatDialogConfig = {
            width: ModalConfig.getModalWidth(),
            autoFocus: false,
            disableClose: true,
            data: {
                linkToEvents: this._canLinkFilesToEvents,
                showDescription: true,
                files,
            },
        };

        this.dialog
            .open(FileUploadModalComponent, config)
            .afterClosed()
            .pipe(filter((val) => !!val))
            .subscribe((filesToUpload) => {
                const communityFiles: CommunityFile[] = [];

                for (const item of filesToUpload) {
                    const file = new CommunityFile();
                    file.file = item.file;
                    file.filename = item.filename;
                    file.displayName = item.displayName;
                    file.path = this.community.id.toString();
                    file.events = item.events;
                    communityFiles.push(file);
                }

                const emailUploadInfo = ApplicationConfig.emailDocumentUpload(communityFiles.map((cf) => cf.file));

                if (emailUploadInfo) {
                    const popupConfig = {panelClass: 'dialog-success', duration: 5000};
                    this.popup.open(`You will receive an email when your files are uploaded.`, 'Okay', popupConfig);
                }

                this.communityService
                    .saveFilesToCommunity(this.community.id, communityFiles)
                    .pipe(filter(() => !emailUploadInfo))
                    .subscribe({
                        next: (res: CommunityFile[]) => {
                            const popupConfig = {panelClass: 'dialog-success', duration: 5000};
                            this.popup.open(`${res.length} ${res.length === 1 ? 'file' : 'files'} uploaded`, '', popupConfig);
                            this.community.files = this.community.files.concat(res);
                            this.searchDocuments();
                        },
                        error: (e: Error) => {
                            console.error(e);
                            this.popup.open(`Failed to upload files`, 'Okay', {panelClass: 'dialog-failure'});
                        },
                    });
            });
    }

    /**
     * Downloads the selected file
     */
    public downloadFile(file: CommunityFile): void {
        const url = `/api/permission/community/${this.community.id}/downloadfile/${file.filename}`;
        const onError = () => {
            this.popup.open('There was and error downloading your file', 'Okay', {panelClass: 'dialog-failure'});
        };
        FileDownload.downloadDocument(url, file.filename, onError);
    }

    /**
     * Removes a selected file from the community
     * @param file The file to be deleted
     */
    public deleteFile(file: CommunityFile): void {
        const config: MatDialogConfig = {
            autoFocus: false,
            disableClose: true,
            data: {
                message: `Are you sure you want to delete ${file.displayName}`,
            },
        };
        this.dialog
            .open(ConfirmationComponent, config)
            .afterClosed()
            .pipe(filter((choice) => !!choice))
            .subscribe(() => {
                this.communityService.deleteFile(file, this.community.id).subscribe(() => {
                    this.popup.open('Removed File', '', {duration: 5000, panelClass: 'dialog-success'});

                    const index = this.community.files.findIndex((f) => f.id === file.id);
                    this.community.files.splice(index, 1);
                    this.searchDocuments();
                });
            });
    }

    /**
     * Renames a file within a community
     * @param file The file to be renamed
     */
    public renameFile(file: CommunityFile): void {
        file.tmpName = file.displayName.slice();
        file.editing = true;
    }

    /**
     * Changes the display name of a file
     * @param file the File that will be changed.
     */
    public changeFilename(file: CommunityFile): void {
        const newFile = new CommunityFile(file);
        newFile.displayName = file.tmpName;
        this.communityService.renameFile(newFile).subscribe(() => {
            const index = this.community.files.findIndex((f) => f.id === newFile.id);
            if (index !== -1) {
                this.community.files.splice(index, 1, newFile);
            }
            this.filteredDocuments = this.community.files;
            this.searchDocuments();

            this.popup.open('File name changed!', '', {panelClass: 'dialog-success', duration: 5000});
        });
    }

    // User Methods

    /**
     * opens the sidebar for adding users to community
     */
    public openAddUserModel(): void {
        // noinspection JSIgnoredPromiseFromCall
        this.sidebar.open();
    }

    /**
     * Removes a user from the community
     * @param user The user to be removed
     */
    public removeUser(user: CommunityUser): void {
        this.communityService.deleteUserFromCommunity(this.community.id, user.userId).subscribe({
            next: () => {
                this.popup.open(`${user.name} removed from community`, '', {panelClass: 'dialog-success', duration: 5000});
                const index = this.community.users.findIndex((u) => u.userId === user.userId);
                this.community.users.splice(index, 1);
                this.community.updateUserList();
                this.getManagers();
                this.searchUsers();
            },
            error: (error: any) => {
                const text = error.error.userMessage || 'Unable to remove user from community';
                this.popup.open(text, 'Okay', {panelClass: 'dialog-failure'});
            },
        });
    }

    /**
     * Updates the manager status of a selected user
     * @param user The user being updated.
     * @param manager the new manager status.
     */
    public changeManagerPrivilege(user: CommunityUser, manager: boolean): void {
        user.manager = manager;

        this.communityService.updateUser(user).subscribe({
            next: (res: CommunityUser) => {
                this.popup.open('Updated Manager Access', '', {panelClass: 'dialog-success', duration: 5000});
                const index = this.community.users.findIndex((u) => u.userId === res.userId);
                this.community.users.splice(index, 1, res);
                this.getManagers();
                this.searchUsers();
            },
            error: (error: any) => {
                const text = error.error.userMessage || 'Unable to change manager status';
                this.popup.open(text, 'Okay', {panelClass: 'dialog-failure'});
                user.manager = !user.manager;
            },
        });
    }

    /**
     * Approves the user for community access
     * @param user The user to be approved.
     */
    public approveUser(user: CommunityUser): void {
        this.communityService.approveUser(user).subscribe({
            next: (res: CommunityUser) => {
                this.popup.open('User Approved', 'Okay', {panelClass: 'dialog-success', duration: 5000});

                const index = this.community.users.findIndex((cu) => cu.userId === res.userId);
                if (index !== -1) {
                    this.community.users.splice(index, 1, res);
                }
                this.community.updateUserList();
                this.searchUsers();
            },
            error: (e: Error) => {
                this.popup.open('There was an error approving the user.', 'Okay', {panelClass: 'dialog-failure'});
                console.error(e);
            },
        });
    }

    /**
     * Denies the user for community access
     * @param user The user to be denied.
     * @param reason The reason the request is being denied
     * @param useCreationModal If a modal should be opened for a user entered reason
     */
    public denyUser(user: CommunityUser, reason: string, useCreationModal: boolean = false): void {
        const success = (res: CommunityUser) => {
            this.popup.open('User Denied', 'Okay', {panelClass: 'dialog-success', duration: 5000});

            const index = this.community.users.findIndex((cu) => cu.userId === res.userId);
            if (index !== -1) {
                this.community.users.splice(index, 1, res);
            }

            this.community.updateUserList();
            this.searchUsers(this.userSearchText);
        };

        const error = (e: any) => {
            const message = e.userMessage || 'There was an error denying the user.';
            this.popup.open(message, 'Okay', {panelClass: 'dialog-failure'});
            console.error(e);
        };

        if (useCreationModal) {
            const config: MatDialogConfig = {
                width: ModalConfig.getModalWidth(500),
            };

            this.dialog
                .open(DenyOptionsComponent, config)
                .afterClosed()
                .pipe(filter((res) => !!res))
                .subscribe((res) => {
                    this.communityService.denyUser(user, res).subscribe({
                        next: success,
                        error,
                    });
                });
        } else {
            this.communityService.denyUser(user, reason).subscribe({
                next: success,
                error,
            });
        }
    }

    // Utility Methods

    public useMobileLayout(checkPhone?: boolean): boolean {
        if (checkPhone) {
            return ApplicationConfig.onPhone();
        }
        return ApplicationConfig.useMobileLayout();
    }

    /**
     * Changes the active tab page
     * @param tabName the name of the active tab
     */
    public changeTab(tabName: string): void {
        this.selectedTab = tabName as any;

        this.change.detectChanges();
        if (tabName === 'user') {
            this.userPaginator.length = this.filteredUsers.length;
        } else if (tabName === 'request') {
            this.requestPaginator.length = this.community.pendingUsers.length;
        } else if (tabName === 'document') {
            this.documentPaginator.length = this.filteredDocuments.length;
        }
    }

    /**
     * Navigates to community landing page
     */
    public goToLandingPage(): void {
        // noinspection JSIgnoredPromiseFromCall
        this.router.navigate(['/app/communities/']);
    }

    /**
     * Searches the community documents by filename
     * @param text The filename being searched for
     */
    public searchDocuments(text?: string): void {
        this.fileSearchText = text || this.fileSearchText;

        // Solr search
        this.communityService.searchSolrDocuments(this.community.id, text).subscribe((solrFiles) => {
            this.filteredDocuments = this.community.files
                .filter((file) => solrFiles.findIndex((sf) => sf.id === file.id) !== -1)
                .sort((a, b) => (a.uploadDate.isBefore(b.uploadDate) ? 1 : -1));

            if (this.documentPaginator) {
                this.documentPaginator.firstPage();
                this.documentPaginator.length = this.filteredDocuments.length;
            }
        });
    }

    /**
     * Searches the community users by username or first and last name
     * @param text The text to be searched for
     */
    public searchUsers(text?: string): void {
        this.userSearchText = text || this.userSearchText;
        this.filteredUsers = this.community.approvedUsers
            .filter((user) => {
                return text ? user.name.toLowerCase().includes(this.userSearchText.toLowerCase()) : true;
            })
            .sort((a, b) => (a.lastName.toLowerCase() > b.lastName.toLowerCase() ? 1 : -1));

        if (this.userPaginator) {
            this.userPaginator.firstPage();
            this.userPaginator.length = this.filteredUsers.length;
        }
    }

    /**
     * Removes the user from the community.
     */
    public leaveCommunity(): void {
        const isOnlyManager = this.community.isManager(this.user.id) && this.managers.length === 1;
        const modalRef: any = isOnlyManager ? SingleManagerLeaveModalComponent : ConfirmationComponent;

        const config: MatDialogConfig = {
            disableClose: true,
            width: ModalConfig.getModalWidth(500),
            data: {
                message: 'Are you sure you want to leave this community?',
                users: this.community.approvedUsers.filter((user) => user.userId !== this.user.id),
            },
        };
        this.dialog
            .open(modalRef, config)
            .afterClosed()
            .pipe(filter((res) => !!res))
            .subscribe(() => {
                // Remove the user from the community
                this.communityService.deleteUserFromCommunity(this.community.id, this.user.id).subscribe(() => {
                    this.goToLandingPage();
                });
            });
    }

    /**
     * sets the community info to be editable, this feature is only available to managers or admins
     */
    public toggleCommunityEdit(): void {
        this.tmpCommunity = new Community(this.community);
        this.editCommunity = true;
    }

    /**
     * Submits the updates made to the community by the manager or admin
     */
    public updateCommunity(): void {
        this.communityService.updateCommunity(this.tmpCommunity).subscribe({
            next: (res) => {
                this.editCommunity = false;
                this.tmpCommunity = undefined;
                this.community.name = res.name;
                this.community.description = res.description;
                this.popup.open('Community Updated', '', {duration: 5000, panelClass: 'dialog-success'});
            },
            error: () => {
                this.popup.open('Community Update Failed', '', {duration: 5000, panelClass: 'dialog-failure'});
            },
        });
    }

    // Paginator Methods
    /**
     * gets the current page of a paginator
     * @param paginator The paginator to read the values from
     * @param list The list if items to be sliced at the page indexes
     */
    public getPaginatorValues(paginator: MatPaginator, list: any[]): any[] {
        if (!paginator) {
            console.warn('paginator not initialized');
        }

        const startIndex = paginator.pageIndex * paginator.pageSize;
        const endIndex = Math.min(startIndex + paginator.pageSize, list.length);

        return list.slice(startIndex, endIndex);
    }

    /**
     * Emails all the APPROVED users in a community
     */
    public emailAllUsers(): void {
        let ref: MatDialogRef<BulkEmailComponent>;
        const send = (emailData: EmailData) => {
            this.communityService.emailAllUsers(this.community.id, emailData).subscribe({
                next: () => {
                    this.popup.open('Emailed all community users.', 'Okay', {duration: 5000, panelClass: 'dialog-success'});
                    ref.close();
                },
                error: (error: any) => {
                    const message = error?.error?.userMessage || 'There was an error emailing users.';
                    this.popup.open(message, 'Okay', {duration: 5000, panelClass: 'dialog-failure'});
                },
            });
        };

        const config: MatDialogConfig = {
            width: ModalConfig.getModalWidth(500),
            disableClose: true,
            data: {
                header: 'Email Community Members',
                sendMethod: send,
            },
        };

        ref = this.dialog.open(BulkEmailComponent, config);
    }

    public addBulkUsers(users?: CommunityUser[]): void {
        // noinspection JSIgnoredPromiseFromCall
        this.sidebar.close();

        if (!users) return;

        this.popup.open('Added Users To Community', '', {panelClass: 'dialog-success', duration: 5000});

        this.community.users = users;
        this.getManagers();
        this.community.updateUserList();
        this.searchUsers();
    }
}
