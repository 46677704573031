<mat-card>
    <mat-card-title>
        <span>{{ data.location.name }} Status</span>
        <button mat-icon-button (click)="close()">
            <mat-icon fontSet="fas" fontIcon="fa-times"></mat-icon>
        </button>
    </mat-card-title>

    <mat-card-content #content>
        <ng-container *ngIf="data">
            <div>
                <span>Available Capacity:</span>
                <span>{{ data.available_capacity | number : '1.0-0' }} (MMBTU)</span>
            </div>

            <div>
                <span>Scheduled Capacity:</span>
                <span>{{ data.scheduled_capacity | number : '1.0-0' }} (MMBTU)</span>
            </div>

            <div>
                <span>Operational Capacity:</span>
                <span>{{ data.operational_capacity | number : '1.0-0' }} (MMBTU)</span>
            </div>

            <div>
                <span>Design Capacity:</span>
                <span>{{ data.design_capacity | number : '1.0-0' }} (MMBTU)</span>
            </div>

            <div>
                <span>Best Flow Location:</span>
                <span>{{ data.best_flow_location }}</span>
            </div>

            <div>
                <span>Measurement Date:</span>
                <span>{{ data.gas_day | momentDate }}</span>
            </div>
        </ng-container>
    </mat-card-content>
</mat-card>
