import * as moment from 'moment';
import {GenApiKey} from '../../../../../generated/serverModels/GenApiKey';

export class ApiKey extends GenApiKey {
    public expirationDateInMs = 0;
    public expirationInDays;
    public expired = false;

    constructor(apiKey?: ApiKey) {
        super(apiKey);
        if (this.expirationDate) {
            this.expirationDateInMs = moment(this.expirationDate).valueOf();
            const now = moment();

            this.expirationInDays = Math.abs(this.expirationDate.diff(now, 'days'));

            if (this.expirationDateInMs < now.valueOf()) {
                this.expired = true;
            } else if (Math.abs(now.diff(moment(this.expirationDate, 'days'))) < 1) {
                setTimeout(() => {
                    this.expired = true;
                }, Math.abs(now.valueOf() - this.expirationDateInMs));
            }
        }
    }
}
