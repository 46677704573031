import {ApplicationConfig} from 'frontend/src/app/classes/application-config';
import {LeafletMapLayer} from '../classes/leaflet-map-layer';
import {LeafletVectorSource} from '../classes/leaflet-vector-source';
import * as esri from 'esri-leaflet';
import {LeafletEagleiSource} from '../classes/leaflet-eaglei-source';

export class WindVelocitySource extends LeafletEagleiSource<esri.DynamicMapLayer> {
    private failedTiles: number = 0;

    constructor(layerInfo: LeafletMapLayer) {
        super(layerInfo);
    }

    public fetchImage(ids: number[]): void {
        const opts = {
            url: this.layerInfo.serviceurl,
            layers: ids,
            format: 'PNG32',
            f: 'image',
            proxy: ApplicationConfig.proxyPrefix,
        };

        Object.assign(opts, this.layerInfo.getParams());

        this.failedTiles = 0;

        this.source = esri.dynamicMapLayer(opts);

        this.source.on('loading', () => {
            this.layerInfo.startLoading();
        });
        this.source.on('load', () => {
            const message = this.failedTiles > 0 ? `Failed to load ${this.failedTiles} tiles` : undefined;
            this.layerInfo.endLoading(this.failedTiles !== 0, message);
        });

        this.source.on('requesterror', () => {
            this.failedTiles += 1;
        });

        this.source.on('requeststart', () => {});

        this.source.on('requestend', (e) => {});
    }

    public changeOpacity(opacity: number): void {
        this.source.setOpacity(opacity);
    }
}
