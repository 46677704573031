
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!


export class GenCounty {

    id: number;
    affgeoid: string;
    aland: number;
    awater: number;
    boundary: string;
    countyfp: string;
    countyns: string;
    dotregion: number;
    geoid: string;
    lsad: string;
    name: string;
    stateAbbreviation: string;
    stateId: number;

    constructor();
    constructor( jsonObj: any );
    constructor( genCounty: GenCounty);
    constructor( id: number );

    constructor( input?: GenCounty|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            this.affgeoid = input.affgeoid; // string [string]
            this.aland = input.aland; // float [number]
            this.awater = input.awater; // float [number]
            this.boundary = input.boundary; // string [string]
            this.countyfp = input.countyfp; // string [string]
            this.countyns = input.countyns; // string [string]
            this.dotregion = input.dotregion; // int [number]
            this.geoid = input.geoid; // string [string]
            this.lsad = input.lsad; // string [string]
            this.name = input.name; // string [string]
            this.stateAbbreviation = input.stateAbbreviation; // string [string]
            this.stateId = input.stateId; // int [number]
       }
    }

    static jsonResponseToList(arr: any[]): GenCounty[] {
        if (arr && arr.map) {
          return arr.map(a => new GenCounty(a));
        } else {
          return [];
        }
    }
}

