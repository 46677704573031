
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!


export class GenFemaRegion {

    id: number;
    boundary: string;
    cntRegion: number;
    display: string;
    dotregion: number;
    objectid: number;
    region: string;
    shapeArea: number;
    shapeLength: number;
    website: string;

    constructor();
    constructor( jsonObj: any );
    constructor( genFemaRegion: GenFemaRegion);
    constructor( id: number );

    constructor( input?: GenFemaRegion|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            this.boundary = input.boundary; // string [string]
            this.cntRegion = input.cntRegion; // int [number]
            this.display = input.display; // string [string]
            this.dotregion = input.dotregion; // int [number]
            this.objectid = input.objectid; // int [number]
            this.region = input.region; // string [string]
            this.shapeArea = input.shapeArea; // float [number]
            this.shapeLength = input.shapeLength; // float [number]
            this.website = input.website; // string [string]
       }
    }

    static jsonResponseToList(arr: any[]): GenFemaRegion[] {
        if (arr && arr.map) {
          return arr.map(a => new GenFemaRegion(a));
        } else {
          return [];
        }
    }
}

