<mat-card>
    <mat-card-title>
        <span>
            {{ data.name }} <span class="notice-count" *ngIf="data.notices.length > 1">{{ data.notices.length }}</span>
        </span>
        <button mat-icon-button (click)="close()">
            <mat-icon fontSet="fas" fontIcon="fa-times"></mat-icon>
        </button>
    </mat-card-title>

    <mat-card-content #content class="column-layout">
        <div>
            <span
                #noticeSubject
                class="ellipsis"
                [matTooltip]="selectedNotice.subject"
                [matTooltipDisabled]="!isEllipsis(noticeSubject)"
                matTooltipPosition="above"
                matTooltipShowDelay="250">
                {{ selectedNotice.subject }}
            </span>

            <div class="navigation-icons">
                <div *ngIf="!isNoticeFirst()" (click)="previousNotice()" class="navigation-button clickable">
                    <mat-icon fontSet="fas" fontIcon="fa-chevron-left" inline></mat-icon>
                    <span>Previous Notice</span>
                </div>

                <div *ngIf="!isNoticeLast()" (click)="nextNotice()" class="navigation-button clickable">
                    <span>Next Notice</span>
                    <mat-icon fontSet="fas" fontIcon="fa-chevron-right" inline></mat-icon>
                </div>
            </div>
        </div>

        <div class="metric-container">
            <div>
                <span class="sub-title">Priority:</span>
                <p class="value">{{ formatPriority() | titlecase }}</p>
            </div>
            <div>
                <span class="sub-title">Pipeline ID:</span>
                <p class="value">{{ data.pipeline_id || 'N/A' }}</p>
            </div>
            <div>
                <span class="sub-title">Status:</span>
                <p class="value">{{ selectedNotice.status || 'N/A' }}</p>
            </div>

            <div>
                <span class="sub-title">Type:</span>
                <p class="value">{{ selectedNotice.type || 'N/A' }}</p>
            </div>
            <div>
                <span class="sub-title">Timestamp:</span>
                <p class="value">{{ selectedNotice.effective_date | momentDate }}</p>
            </div>

            <div>
                <span class="sub-title">End Date:</span>
                <p class="value">{{ selectedNotice.end_date | momentDate }}</p>
            </div>
        </div>

        <div>
            <span class="sub-title">Description</span>

            <div class="value notice-description" [class.expanded]="selectedNotice.expanded">
                <p #noticeDescription>{{ selectedNotice.body }}</p>
            </div>

            <p class="expand-text clickable" *ngIf="showExpandButton" (click)="selectedNotice.expanded = !selectedNotice.expanded">
                {{ selectedNotice.expanded ? 'View Less' : 'View More' }}
            </p>
        </div>
    </mat-card-content>
</mat-card>
