<div class="container">
    <div #header class="widget-header large">
        <p class="title">
            <span>Derived Storage</span>
            <eaglei-info-icon [attributionModalText]="attributionModalText" [widgetName]="widgetName"></eaglei-info-icon>
        </p>

        <div class="widget-filters">
            <mat-form-field class="filter-select text-search" appearance="outline" [class.active]="isSearchTextActive()">
                <!--suppress TypeScriptUnresolvedVariable -->
                <input matInput placeholder="Search Facilities" (keyup)="filterTitles($event.target.value)" />
                <mat-icon matSuffix class="icon input-search-icon" fontSet="fas" fontIcon="fa-search"></mat-icon>
            </mat-form-field>

            <eaglei-location-filter
                [class.hide]="eventDashboard"
                [newDesign]="true"
                [defaultSelection]="selectedStates"
                [selectAllOption]="true"
                (locations)="updateState($event)"
                [hideFemaRegions]="true">
            </eaglei-location-filter>

            <div class="btn-container">
                <button mat-icon-button matTooltip="Export" matTooltipPosition="above" matTooltipShowDelay="250" [matMenuTriggerFor]="menu">
                    <mat-icon fontSet="fas" fontIcon="fa-file-export"></mat-icon>
                </button>
            </div>
        </div>
    </div>

    <div #content class="widget-content">
        <div class="summary-list">
            <div class="summary-item" *ngFor="let summary of getPaginatorValues()">
                <div class="summary" *ngIf="!isLoading">
                    <div class="header-info">
                        <div class="row">
                            <p
                                #locationName
                                class="title ellipsis"
                                [matTooltip]="summary.location_name"
                                matTooltipPosition="above"
                                [matTooltipDisabled]="!isEllipsis(locationName)">
                                {{ summary.location_name }}
                            </p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="inventory">
                            <p class="sub-text">Inventory Change</p>
                            <p class="info-number">
                                {{ summary.inventory_change | number }} BTU
                                <mat-icon class="trend up" *ngIf="checkTrend(summary, 'up')">trending_up</mat-icon>
                                <mat-icon class="trend down" *ngIf="checkTrend(summary, 'down')">trending_down</mat-icon>
                            </p>
                        </div>

                        <div class="inventory">
                            <p class="sub-text">Inventory</p>
                            <p class="info-number">{{ summary.inventory | number }} BTU</p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="inventory">
                            <p class="sub-text">County <span class="spacing-dot"></span> State</p>
                            <p class="info-number" *ngIf="summary.county">
                                {{ summary.county }} <span class="spacing-dot"></span> {{ summary.state | stateName : 'true' }}
                            </p>
                            <p class="info-number" *ngIf="!summary.county">Not Available</p>
                        </div>

                        <div class="inventory">
                            <p class="sub-text">Rating</p>
                            <p class="info-number">{{ summary.quality_rating }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="isLoading">
                <span>Loading ...</span>
            </div>

            <div *ngIf="!isLoading && getPaginatorValues().length <= 0">
                <span>No Data Available</span>
            </div>
        </div>
    </div>

    <div #footer>
        <mat-paginator [pageSize]="6" hidePageSize showFirstLastButtons></mat-paginator>
    </div>
</div>

<mat-menu #menu="matMenu" overlapTrigger>
    <button (click)="exportTable()" mat-menu-item>Export Table As CSV</button>
</mat-menu>
