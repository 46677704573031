<form [formGroup]="mapControls.filterGroup" class="filter-bar ignore-last">
    <section class="filters">
        <mat-form-field class="filter-select" [class.active]="mapControls.filterGroup.controls.type.value">
            <mat-label>Event Type</mat-label>
            <mat-select [compareWith]="systemEventService.typeCompareFunction" placeholder="Event Type" formControlName="type">
                <mat-option *ngFor="let eventType of eventTypes" [value]="eventType">
                    {{ eventType.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <ng-container [ngTemplateOutlet]="hurricaneFilters"></ng-container>
    </section>

    <button mat-stroked-button matTooltip="Export" matTooltipPosition="above" matTooltipShowDelay="250" [matMenuTriggerFor]="menu">
        <mat-icon fontSet="fas" fontIcon="fa-file-export" inline></mat-icon>
    </button>
</form>

<div class="report-data">
    <ng-container [ngTemplateOutlet]="map"></ng-container>
    <ng-container [ngTemplateOutlet]="table"></ng-container>
</div>

<!--Templates-->

<ng-template #hurricaneFilters>
    <form [formGroup]="mapControls.filterGroup" class="data-filters">
        <mat-form-field class="filter-select" [class.active]="mapControls.filterGroup.controls.name.value">
            <mat-label>Event Name</mat-label>
            <mat-select placeholder="Event name" formControlName="name">
                <mat-optgroup *ngFor="let group of mapControls.eventGroups | keyvalue" [label]="group.key | titlecase">
                    <mat-option *ngFor="let event of mapControls.eventGroups[group.key]" [value]="event">{{ event.name }} </mat-option>
                </mat-optgroup>
            </mat-select>
        </mat-form-field>

        <ng-container *ngIf="mapControls.selectedDetail">
            <mat-form-field class="filter-select" [class.active]="mapControls.filterGroup.controls.advisory.value">
                <mat-label>Advisory</mat-label>
                <mat-select placeholder="Advisory" formControlName="advisory">
                    <mat-option *ngFor="let result of mapControls.selectedDetail?.eventResults" [value]="result">
                        {{ result.resultId }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </ng-container>

        <ng-container *ngIf="mapControls.selectedDetail">
            <mat-form-field class="filter-select" [class.active]="mapControls.filterGroup.controls.weather.value">
                <mat-label>Weather Layers</mat-label>
                <mat-select placeholder="Weather Layers" formControlName="weather" multiple>
                    <!--suppress TypeScriptValidateTypes -->
                    <mat-option *ngFor="let item of mapControls.weatherSources" [value]="item">
                        {{ item.display | titlecase }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </ng-container>

        <ng-container *ngIf="mapControls.eventInfrastructures">
            <mat-form-field
                class="filter-select"
                [class.active]="
                    mapControls.filterGroup.controls.infrastructure.value &&
                    mapControls.filterGroup.controls.infrastructure.value.length > 0
                ">
                <mat-label>Infrastructure Layers</mat-label>
                <mat-select placeholder="Infrastructure Layers" formControlName="infrastructure" multiple>
                    <mat-option *ngFor="let item of mapControls.eventInfrastructures | keyvalue" [value]="item">{{
                        item.value.display | titlecase
                    }}</mat-option>
                </mat-select>
            </mat-form-field>
        </ng-container>
    </form>
</ng-template>

<ng-template #windFilters></ng-template>

<ng-template #map>
    <section class="map-section">
        <mat-card class="map" [class.invisible]="maskOptions.showMask">
            <mat-card-content>
                <eaglei-leaflet-map [mapOptions]="mapControls.mapOptions"></eaglei-leaflet-map>
            </mat-card-content>
            <mat-card-footer>
                <div class="outage-legend">
                    <div class="outage-blocks">
                        <span class="legend-title">Predicted Outage Percentages:</span>
                        <div
                            class="color-blocks"
                            *ngFor="let percentage of styleService.percentOutDomain; let first = first; let last = last"
                            [class.first-block]="first"
                            [class.last-block]="last"
                            [style.background-color]="percentage.color"
                            [style.color]="getFontColor(percentage.color)">
                            <span class="text">{{ percentage.value }}</span>
                        </div>
                    </div>

                    <div class="advisory">
                        <span class="legend-title">Advisory:</span>
                        <div class="color-dot" style="background-color: #ff0000"></div>
                    </div>

                    <div class="advisory">
                        <span class="legend-title">Selected Advisory:</span>
                        <div class="color-dot" style="background-color: #003366"></div>
                    </div>
                </div>

                <div class="outage-legend">
                    <!--suppress TypeScriptValidateTypes -->
                    <div class="outage-colors" *ngFor="let item of mapControls.eventInfrastructures | keyvalue">
                        <ng-container *ngIf="item.value.active && item.value.legend">
                            <span class="legend-title">{{ item.value.display | titlecase }}: </span>
                            <!--suppress TypeScriptValidateTypes -->
                            <eaglei-legend [legends]="[item.value.legend]"></eaglei-legend>
                        </ng-container>
                    </div>
                </div>

                <div class="outage-legend">
                    <!--suppress TypeScriptValidateTypes -->
                    <div class="outage-colors" *ngFor="let item of mapControls.weatherSources">
                        <ng-container *ngIf="item.active && item.legend">
                            <span class="legend-title">
                                {{ item.display | titlecase }} <span *ngIf="item.units">({{ item.units }})</span> :
                            </span>
                            <!--suppress TypeScriptValidateTypes -->
                            <eaglei-legend [legends]="[item.legend]"></eaglei-legend>
                        </ng-container>
                    </div>
                </div>
            </mat-card-footer>
        </mat-card>

        <div class="auto-mask hide-mask" *ngIf="maskOptions.showMask">
            <span class="mask-message">{{ maskOptions.text }}</span>
        </div>
    </section>
</ng-template>

<ng-template #table>
    <mat-card>
        <mat-card-content>
            <div class="table-container">
                <table
                    mat-table
                    [dataSource]="dataSource"
                    matSort
                    [matSortDisableClear]="true"
                    matSortActive="customersOut"
                    matSortDirection="desc">
                    <ng-container matColumnDef="state">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            State/Territory
                            <eaglei-table-sort-header [columnName]="'state'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let info">{{ info.state | stateName }}</td>
                    </ng-container>
                    <ng-container matColumnDef="county">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            County
                            <eaglei-table-sort-header [columnName]="'county'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let info">{{ info.county }}</td>
                    </ng-container>
                    <ng-container matColumnDef="customersOut">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef>
                            Predicted Customers Out
                            <eaglei-table-sort-header [columnName]="'customersOut'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let info">{{ info.customersOut | eagleiNumber }}</td>
                    </ng-container>
                    <ng-container matColumnDef="totalCustomers">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef>
                            Total Customers
                            <eaglei-table-sort-header [columnName]="'totalCustomers'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let info">{{ info.totalCustomers | eagleiNumber }}</td>
                    </ng-container>
                    <ng-container matColumnDef="percentOut">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef>
                            Predicted Percent Out
                            <eaglei-table-sort-header [columnName]="'percentOut'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let info">{{ info.percentOut | percent : '1.2-2' }}</td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="columns"></tr>
                    <tr mat-row *matRowDef="let row; columns: columns; let odd = odd"></tr>
                </table>
                <mat-paginator [pageSizeOptions]="[25, 50, 75, 100]" showFirstLastButtons></mat-paginator>
            </div>

            <div class="table-attribution">
                <a (click)="reportService.openAttribution(attributionUrl)" [innerHTML]="attributionTitle | sanitize"></a>
            </div>
        </mat-card-content>
    </mat-card>
</ng-template>

<!--Export Menu-->
<mat-menu #menu="matMenu" overlapTrigger>
    <button [disabled]="dataSource?.data.length === 0" mat-menu-item (click)="exportTable()">Export Table As CSV</button>
</mat-menu>
