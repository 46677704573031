<div #content class="widget-content">
    <div class="outage-container">
        <p class="outage-number">{{ outages }}</p>
        <span class="outage-title">{{ getImpactedTitle() }}</span>
    </div>
    <div class="view-map" *ngIf="!onMobile()">
        <a target="_blank" (click)="goToMap()" class="icon-link">
            <mat-icon fontSet="fas" fontIcon="fa-globe-americas" inline></mat-icon>
            <p>VIEW MAP</p>
        </a>
    </div>
    <eaglei-info-icon [attributionModalText]="attributionModalText" [widgetName]="widgetName"></eaglei-info-icon>
</div>
