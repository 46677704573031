import {GenOutageError} from '../../../../../generated/serverModels/GenOutageError';

export class UtilityError extends GenOutageError {
    public percentRepeated: number;

    constructor(input?: UtilityError) {
        super(input);
        if (input) {
            this.percentRepeated = input.duplicateCount / input.outageCountTotal;
        }
    }
}
