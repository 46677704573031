import {ElementRef} from '@angular/core';
import {MatDialogConfig} from '@angular/material/dialog';

export class ModalConfig {
    static defaultConfig: MatDialogConfig = {
        width: ModalConfig.getModalWidth(),
        disableClose: true,
    };

    static setModalHeight(element: ElementRef, maxPercent?: number) {
        (element.nativeElement as HTMLElement).style.maxHeight = ModalConfig.getMaxModalHeight(maxPercent) + 'px';
    }

    static getMaxModalHeight(maxPercent?: number): number {
        const percent = maxPercent ? maxPercent / 100 : 0.75;
        return document.body.clientHeight * percent;
    }

    static getModalWidth(optMaxWidth: number = 1000, usePageWidth?: boolean): string {
        if (usePageWidth) {
            return `${document.body.getBoundingClientRect().width}px`;
        }

        const clientWidth = document.body.getBoundingClientRect().width * 0.8;
        return Math.min(clientWidth, optMaxWidth) + 'px';
    }
}
