import {GenPlanetSenseLocation} from '../../../../../generated/serverModels/GenPlanetSenseLocation';
import {Planetsense} from './planetsense';

export class PlanetsenseLocation extends GenPlanetSenseLocation {
    public tweets: Planetsense[];

    constructor(location?: PlanetsenseLocation) {
        super(location);

        if (location) {
            this.tweets = this.tweets.map((t) => new Planetsense(t));
        }
    }
}
