import {GenAlert} from '../../../../../generated/serverModels/GenAlert';
import {State} from '../../outage/classes/state';
import {County} from '../../outage/classes/county';
import {FemaRegion} from '../../outage/classes/fema-region';

export class SystemAlert extends GenAlert {
    public locationName: string;
    public locationTotalCustomers: number;
    public thresholdPercent: number;

    public location: FemaRegion | State | County;

    constructor(alert?: SystemAlert) {
        super(alert);
    }
}
