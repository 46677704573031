<div class="hide-overflow">
    <div #header class="widget-header large">
        <p class="title">
            <span>Oil Refinery Status</span>
            <eaglei-info-icon [attributionModalText]="attributionModalText" [widgetName]="widgetName"></eaglei-info-icon>
        </p>

        <div class="header-filters">
            <eaglei-date-range
                *ngIf="!eventDashboard"
                [singlePicker]="true"
                [showTime]="false"
                [startPlaceholder]="'Measurement Date'"
                [min]="ongService.minDate"
                [start]="startDate"
                (change)="updateDateRange($event)">
            </eaglei-date-range>

            <mat-form-field class="filter-select active state-filter" appearance="outline">
                <mat-label>Status</mat-label>
                <mat-select placeholder="Status" [value]="selectedStatus" (selectionChange)="updateStatus($event.value)">
                    <mat-option [value]="'all'">All Statuses</mat-option>
                    <mat-option [value]="'online'">Online</mat-option>
                    <mat-option [value]="'impacted'">Reduced Capacity</mat-option>
                    <mat-option [value]="'offline'">Offline</mat-option>
                </mat-select>
            </mat-form-field>

            <div class="export">
                <button mat-icon-button matTooltip="Export" matTooltipPosition="above" matTooltipShowDelay="250" [matMenuTriggerFor]="menu">
                    <mat-icon fontSet="fas" fontIcon="fa-file-export"></mat-icon>
                </button>
            </div>
        </div>
    </div>

    <div #content class="widget-content">
        <div class="table-container" [class.invisible]="dataSource?.filteredData?.length === 0">
            <table mat-table [dataSource]="dataSource" matSort [matSortDisableClear]="true">
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef class="small-col">Status</th>
                    <td mat-cell *matCellDef="let info" class="small-col">
                        <div>
                            <mat-icon
                                class="status-icon"
                                fontSet="fas"
                                [class.offline]="info.status === 'Offline'"
                                [class.impacted]="info.status === 'Reduced Capacity'"
                                [fontIcon]="
                                    info.status === 'Online'
                                        ? 'fa-check'
                                        : info.status === 'Reduced Capacity'
                                        ? 'fa-exclamation-triangle'
                                        : 'fa-ban'
                                ">
                            </mat-icon>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Name
                        <eaglei-table-sort-header [columnName]="'name'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let info"
                        class="ellipsis"
                        #statusName
                        [matTooltipDisabled]="!hasEllipsis(statusName)"
                        [matTooltip]="info.name"
                        matTooltipPosition="above"
                        matTooltipShowDelay="250">
                        {{ info.name }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="capacity">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Capacity (bpd)
                        <eaglei-table-sort-header [columnName]="'capacity'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let info">{{ info.total_capacity | eagleiNumber }} bpd</td>
                </ng-container>

                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Measurement Date
                        <eaglei-table-sort-header [columnName]="'date'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let info">
                        {{ info.units[0].measurementDate | momentDate : 'M/D/YYYY' }}
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
                <tr mat-row *matRowDef="let row; columns: columnNames"></tr>
            </table>
        </div>

        <div class="auto-mask hide-mask" *ngIf="dataSource?.filteredData?.length === 0">
            <p class="mask-message">No Refineries match your current filters.</p>
        </div>
    </div>

    <div #footer class="widget-footer">
        <mat-paginator [pageSize]="5" hidePageSize showFirstLastButtons></mat-paginator>
        <button mat-stroked-button class="view-map" (click)="goToReport()">
            <mat-icon class="icon" fontSet="fas" fontIcon="fa-file-alt" inline></mat-icon>
            <span>View Report</span>
        </button>
    </div>
</div>

<mat-menu #menu="matMenu" overlapTrigger>
    <button (click)="exportTable()" mat-menu-item>Export Table As CSV</button>
</mat-menu>
