import {GenSystemEventUser} from '../../../../../generated/serverModels/GenSystemEventUser';
import {User} from '../../user/classes/user';

export class SystemEventUser extends GenSystemEventUser {
    static fromUser(user: User, isManager: boolean = false): SystemEventUser {
        const systemEventUser = new SystemEventUser();
        systemEventUser.userId = user.id;
        systemEventUser.username = user.username;
        systemEventUser.firstName = user.firstName;
        systemEventUser.lastName = user.lastName;
        systemEventUser.fullName = user.fullName;
        systemEventUser.email = user.email;
        systemEventUser.accessLevel = isManager ? 'MANAGER' : 'USER';
        return systemEventUser;
    }

    public fullName: string;

    constructor(user?: SystemEventUser) {
        super(user);
    }
}
