import {Pipe, PipeTransform} from '@angular/core';
import {LegendMetric} from '../enums/legend-metric.enum';

@Pipe({
    name: 'legendMetric',
})
export class LegendMetricPipe implements PipeTransform {
    transform(value: number | LegendMetric, ...args: any[]): string {
        let ret;
        switch (value) {
            case LegendMetric.PERCENTAGE:
                ret = 'Percentage';
                break;
            case LegendMetric.CUSTOMERS_OUT:
                ret = 'Customers Out';
                break;
            default:
                ret = value.toString();
        }

        return ret;
    }
}
