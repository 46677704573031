import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
    booleanAttribute,
} from '@angular/core';
import {State} from '../../../app/modules/outage/classes/state';
import {DataService} from '../../../app/services/data.service';
import {MatSelect} from '@angular/material/select';
import {MatFormFieldAppearance} from '@angular/material/form-field';

@Component({
    selector: 'eaglei-location-filter',
    templateUrl: './location-filter.component.html',
    styleUrls: ['./location-filter.component.scss'],
})
export class LocationFilterComponent implements OnChanges, AfterViewInit {
    @Output() locations: EventEmitter<State[]> = new EventEmitter();
    @Input() defaultSelection: State[] = [];
    @Input() disableStates: boolean;
    @Input() restrictedFemaList: number[] = [];
    @Input() allInitiallySelected: boolean;
    @Input({transform: booleanAttribute}) selectAllOption: boolean = true;

    /**
     * @Deprecated
     */
    @Input() newDesign: boolean = true;

    @Input({transform: booleanAttribute}) hideFemaRegions = false;
    @Input() allowDisableSelection: boolean = false;
    @Input() allowedStateSelection: State[] = [];

    @ViewChild('select', {static: true}) select: MatSelect;

    public locationMap: Map<number, State[]> = new Map<number, State[]>();
    public femaRegions: number[] = [];
    public states: State[] = [];
    public selectedLocations: any[] = [];
    public allSelected: boolean = true;
    public isIndeterminate: boolean;

    constructor(private cd: ChangeDetectorRef) {}

    ngAfterViewInit(): void {
        this.updateStateList();
        if (this.allInitiallySelected) {
            this.selectedLocations = this.femaRegions.concat(this.states.map((s) => s.name) as any[]);
        } else {
            this.updateValues(this.defaultSelection);
        }
        this.checkIndeterminateState();

        // Resolves ChangedAfterCheckedErrors due to changes ocurring in updateValues() & checkIndeterminateState()
        this.cd.detectChanges();
    }

    ngOnChanges(changes: SimpleChanges): void {
        const change = changes.defaultSelection;
        const allowedStateChange = changes.allowedStateSelection;

        if (allowedStateChange) {
            this.updateStateList();
        }

        if (change && !change.isFirstChange()) {
            this.updateValues(change.currentValue);
        }
    }

    public checkIndeterminateState(): void {
        const statesSelected = this.getValue();
        this.checkAllSelectedState(statesSelected);

        // tslint:disable-next-line:prefer-conditional-expression
        if (!this.allSelected) {
            this.isIndeterminate = !(statesSelected.length === 0 || statesSelected.length === DataService.states.getValue().length);
        } else {
            this.isIndeterminate = false;
        }
    }

    public checkAllSelectedState(states?: string[]): void {
        const statesSelected = states || this.getValue();
        this.allSelected = statesSelected.length === DataService.states.getValue().length;
    }

    public updateValues(states: State[]): void {
        const stateIds = states?.map((s) => s.id);
        const completeFemaRegions = [];

        this.locationMap.forEach((val, key) => {
            if (val.every((s) => stateIds?.includes(s.id))) {
                completeFemaRegions.push(key);
            }
        });

        this.selectedLocations = completeFemaRegions.concat(
            states?.map((state) => state.name),
            [this.allSelected]
        );

        this.allSelected =
            this.selectedLocations.filter((location) => typeof location !== 'number' && typeof location !== 'boolean').length ===
            this.states.length;
    }

    public selectAll(): void {
        this.allSelected = !this.allSelected;
        this.updateValues(this.allSelected ? [...this.states] : []);
    }

    public femaRegionChanged(region: number): void {
        const states = this.locationMap
            .get(region)
            .map((s) => s.name)
            .filter((s) =>
                this.allowDisableSelection ? this.allowedStateSelection.map((stateSelect) => stateSelect.name).includes(s) : true
            );

        this.selectedLocations = this.selectedLocations.filter((abbv) => states.indexOf(abbv) === -1);
        const index = this.selectedLocations.findIndex((val) => val === region);
        if (index !== -1) {
            this.selectedLocations = this.selectedLocations.concat(states);
        }
        this.checkIndeterminateState();
    }

    public stateChanged(state: State): void {
        const regionStates = this.locationMap.get(state.dotregion);
        const regionIndex = this.selectedLocations.findIndex((v) => state.dotregion === v);
        const allSelected = regionStates.every((s) => this.selectedLocations.indexOf(s.name) !== -1);

        if (allSelected && regionIndex === -1) {
            this.selectedLocations.push(state.dotregion);
        } else if (regionIndex !== -1) {
            this.selectedLocations.splice(regionIndex, 1);
        }

        this.checkIndeterminateState();

        // updates the select binding to show in the html
        this.select.writeValue(this.selectedLocations);
    }

    public getValue(): string[] {
        return this.selectedLocations
            .filter((f) => {
                return typeof f === 'string';
            })
            .sort((a, b) => (a > b ? 1 : -1));
    }

    public panelStatus(open: boolean): void {
        if (!open) {
            const states = this.getValue().map((name) => {
                return this.states.find((s) => s.name === name);
            });
            this.locations.emit(states);
        }
    }

    public updateStateList(): void {
        this.locationMap = new Map<number, State[]>();
        const stateList = this.allowDisableSelection ? [...this.allowedStateSelection] : DataService.states.value;
        this.states = stateList.filter((s) => (this.restrictedFemaList.length > 0 ? this.restrictedFemaList.includes(s.dotregion) : true));
        this.states
            .sort((a, b) => {
                if (a.name > b.name) return 1;
                else if (a.name < b.name) return -1;
                return 0;
            })
            .forEach((state) => {
                const value = this.locationMap.get(state.dotregion) || [];
                value.push(state);
                this.locationMap.set(state.dotregion, value);
            });

        this.femaRegions = Array.from(this.locationMap.keys()).sort((a, b) => a - b);
        this.cd.detectChanges();
    }

    public disableStateSelection(state: State): boolean {
        if (!this.allowDisableSelection || !this.allowedStateSelection) {
            return false;
        }

        return !this.allowedStateSelection.map((s) => s.id).includes(state.id);
    }

    public hasAllowedState(region: number): boolean {
        if (!this.allowDisableSelection || !this.allowedStateSelection) {
            return false;
        }

        return !this.allowedStateSelection.map((s) => s.dotregion).includes(region);
    }
}
