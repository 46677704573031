import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IFilterComponentInterface} from '../../interfaces/filter.interface';

@Component({
    selector: 'eaglei-text-search-filter',
    templateUrl: './text-search-filter.component.html',
    styleUrls: ['./text-search-filter.component.scss'],
})
export class TextSearchFilterComponent implements IFilterComponentInterface {
    @Input() title: string = 'placeholder';
    @Input() filterText: string = '';
    @Output() filterChange = new EventEmitter<string>();

    @Input() debounceTime: number = 250;
    private timeoutHandle: any;

    constructor() {}

    public fireEvent(): void {
        if (this.timeoutHandle) {
            clearTimeout(this.timeoutHandle);
            this.timeoutHandle = undefined;
        }

        this.timeoutHandle = setTimeout(() => {
            this.filterChange.emit(this.filterText);
        }, this.debounceTime);
    }
}
