<div class="dialog-header">
    <span>Add Entry</span>
    <mat-icon fontSet="fas" fontIcon="fa-times" inline (click)="dialogRef.close()"></mat-icon>
</div>

<div class="dialog-content" #content>
    <form [formGroup]="newsGroup">
        <span class="placeholder">Title</span>
        <mat-form-field class="highlight-focus">
            <input matInput required name="title" type="text" [(ngModel)]="news.title" formControlName="title" [maxLength]="100" />
            <mat-hint align="end">{{ news.title.length }}/100</mat-hint>
            <mat-error>Title is Required</mat-error>
        </mat-form-field>

        <span class="placeholder">Message</span>
        <mat-form-field class="highlight-focus">
            <textarea
                matInput
                required
                name="newsValue"
                formControlName="message"
                matTextareaAutosize
                matAutosizeMaxRows="5"
                matAutosizeMinRows="5"
                [(ngModel)]="news.value">
            </textarea>
            <mat-error>Message is Required</mat-error>
        </mat-form-field>
    </form>
</div>

<div class="dialog-actions">
    <button mat-stroked-button (click)="dialogRef.close()">Cancel</button>
    <button mat-flat-button color="primary" (click)="createSystemNews()" [disabled]="newsGroup.invalid">Add Entry</button>
</div>
