import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'eaglei-honor-preference-indicator',
    template: `
        <div class="preferences-group">
            <mat-icon fontSet="fas" fontIcon="fa-exclamation-circle" inline></mat-icon>
            <p class="does-not-honor">Does Not Honor User Preferences</p>
        </div>
    `,
    styles: [
        `
            :host {
                display: block;
            }
            .preferences-group {
                display: block;
                margin: 0;
                padding: 0;
            }

            mat-icon[fontIcon='fa-exclamation-circle'] {
                margin: 0 auto;
                display: inline;
                padding: 2px;
                color: #5eb3f7;
                font-size: 12px;
            }

            .does-not-honor {
                display: inline;
                height: 14px;
                width: 167px;
                color: #5eb3f7;
                font-family: 'Roboto', sans-serif;
                letter-spacing: -0.43px;
                font-size: 12px;
            }
        `,
    ],
})
export class HonorPreferenceIndicatorComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
