<div class="dialog-header">
    <span>{{ widgetName }} Information</span>
    <mat-icon [inline]="true" class="clickable" (click)="this.dialogRef.close()" fontSet="fas" fontIcon="fa-times"></mat-icon>
</div>

<div class="dialog-content" #content>
    <p class="modal-text">{{ attributionModalText }}</p>
</div>

<div class="dialog-actions">
    <p class="attribution">Data Provided by Genscape a Wood Mackenzie Business</p>
</div>
