<div class="hide-overflow">
    <div class="widget-header">
        <p class="title">EIL Documents</p>

        <form [formGroup]="filterForm">
            <mat-form-field appearance="outline" class="active filter-select">
                <mat-label>Filter by name</mat-label>
                <input matInput formControlName="name" placeholder="Filter by name" />
            </mat-form-field>
        </form>
    </div>

    <div class="widget-content table-container">
        <table mat-table [dataSource]="dataSource" matSort matSortActive="displayName" matSortDirection="asc" multiTemplateDataRows>
            <ng-container *ngIf="dataSource.filteredData.length">
                <tr mat-header-row *matHeaderRowDef="columns"></tr>
            </ng-container>
            <tr mat-row *matRowDef="let row; columns: columns" class="clickable" (click)="toggleExpandedRow(row)"></tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="expandable-row"></tr>

            <ng-container *matNoDataRow>
                <td class="no-documents">{{ noDocumentsText }}</td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
                <td mat-cell *matCellDef="let doc" class="ellipsis">
                    {{ doc.name }}
                </td>
            </ng-container>

            <ng-container matColumnDef="expand-icon">
                <th mat-header-cell *matHeaderCellDef class="ei-icon-column"></th>
                <td mat-cell *matCellDef="let info" class="ei-icon-column">
                    <mat-icon inline fontSet="fas" [fontIcon]="info === selectedRow ? 'fa-chevron-down' : 'fa-chevron-right'"></mat-icon>
                </td>
            </ng-container>

            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let doc">
                    <div class="hide-expanded-row" [class.show-expanded-row]="selectedRow === doc">
                        <div class="document" *ngFor="let document of doc.documents">
                            {{ document.displayName }}

                            <button mat-icon-button color="primary" title="Download" (click)="downloadDocument(document)">
                                <mat-icon fontSet="fas" fontIcon="fa-download"></mat-icon>
                            </button>
                        </div>
                    </div>
                </td>
            </ng-container>
        </table>
    </div>

    <div class="widget-footer">
        <mat-paginator hidePageSize showFirstLastButtons [pageSize]="8"></mat-paginator>
    </div>
</div>
