import {GenSystemEvent} from 'frontend/generated/serverModels/GenSystemEvent';
import {SystemEventUser} from './system-event-user';
import {SystemEventPhase} from './system-event-phase';
import {County} from '../../outage/classes/county';
import {State} from '../../outage/classes/state';
import {DataService} from '../../../services/data.service';

export class SystemEvent extends GenSystemEvent {
    public users: SystemEventUser[] = [];
    public phases: SystemEventPhase[] = [];
    public counties: County[] = [];
    public states: State[] = [];

    constructor(systemEvent?: SystemEvent) {
        super(systemEvent);

        if (systemEvent) {
            this.users = systemEvent?.users?.map((user) => new SystemEventUser(user));
            this.phases = systemEvent?.phases?.map((phase) => new SystemEventPhase(phase));
            this.counties = systemEvent?.counties?.map((county) => new County(county));
            const stateIds = new Set(this.counties.map((c) => c.stateAbbreviation));
            this.states = DataService.states.getValue().filter((state) => stateIds.has(state.stateAbbreviation));
        }
        if (!this.analytics) {
            this.analytics = [];
        }
    }
}
