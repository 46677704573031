import {GenAlertHistory} from '../../../../../generated/serverModels/GenAlertHistory';
import {DataService} from 'frontend/src/app/services/data.service';

export class AlertFeed extends GenAlertHistory {
    /*
  static femaPipe = new FemaNamePipe();

  public readonly formattedDisplayName: string;

  constructor(feed?: AlertFeed) {
    super(feed);

    if (this.alertType.toLowerCase().includes('fema')) {
      this.formattedDisplayName = this.location.split(',')
        .map(v => AlertFeed.femaPipe.transform(v.trim(), false, true))
        .join(' and ');
    } else {
      const state = DataService.states.value.find(s => s.abbreviation === this.location);
      this.formattedDisplayName = (!!state) ? state.name : this.location;
    }
  }
   */
}
