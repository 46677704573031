import {Component, inject} from '@angular/core';
import {EagleILayer} from '../../classes/eagle-i-layer';
import {FormControl, FormGroup} from '@angular/forms';
import {SystemEventService} from '../../../system-event/services/system-event.service';
import {SystemEvent} from '../../../system-event/classes/system-event';
import {GenRadrUpdateNotice} from '../../../../../../generated/serverModels/GenRadrUpdateNotice';
import {distinctUntilChanged, filter, switchMap, takeUntil, tap} from 'rxjs/operators';
import {GenSystemEventAnalytic} from '../../../../../../generated/serverModels/GenSystemEventAnalytic';
import {LeafletWmsSource} from '../../classes/leaflet-wms-source';
import {ApplicationConfig} from '../../../../classes/application-config';
import {GenLegendType} from '../../../../../../generated/serverModels/GenLegendType';

@Component({
    selector: 'eaglei-pnnl-radr-layer',
    templateUrl: './pnnl-radr-layer.component.html',
    styleUrls: ['./pnnl-radr-layer.component.scss'],
})
export class PnnlRadrLayerComponent extends EagleILayer<LeafletWmsSource> {
    public filterGroup: FormGroup = new FormGroup({
        event: new FormControl<SystemEvent>(undefined),
        images: new FormControl<GenRadrUpdateNotice[]>(undefined),
    });

    public tiles: GenRadrUpdateNotice[] = [];

    public systemEventService = inject(SystemEventService);
    public radrEvents;

    constructor() {
        super();
    }

    afterViewInit() {
        this.mapLayer.legend.type = GenLegendType.HARD_CODED;
    }

    private initializeControls(): void {
        this.filterGroup
            .get('event')
            .valueChanges.pipe(
                filter((value) => !!value),
                switchMap((event) => this.systemEventService.getRadrImages(event)),
                takeUntil(this.destroy$)
            )
            .subscribe((value) => {
                this.tiles = value.sort((a, b) => {
                    if (a.collectedAt.isBefore(b.collectedAt)) return 1;
                    else if (a.collectedAt.isAfter(b.collectedAt)) return -1;
                    return 0;
                });

                if (this.tiles.length === 0) {
                    this.filterGroup.get('images').reset();
                    return;
                }
                this.filterGroup.get('images').setValue([this.tiles[0]]);
            });

        this.filterGroup
            .get('images')
            .valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((value: GenRadrUpdateNotice[]) => {
                // Checks to see if the value has been reset
                if (value === null) {
                    this.layerSource.removeFromMap();
                    return;
                }

                if (value.length !== 0) {
                    const url = `${ApplicationConfig.proxyPrefix}${value[0].wmsEndpoint}`;
                    this.layerSource.source.setUrl(url, true);
                }

                const layers = value.map((image) => image.wmsLayerId).join();
                this.layerSource.source.setParams({layers});
                this.layerSource.addToMap();
            });

        this.systemEventService.getGroupedEvents().subscribe((groupedEvents) => {
            this.radrEvents = Object.keys(groupedEvents).reduce((prev: any, cur: string) => {
                prev[cur] = groupedEvents[cur]
                    .filter((event) => event.analytics.includes(GenSystemEventAnalytic.PNNL))
                    .sort((a: SystemEvent, b: SystemEvent) => {
                        if (a.eventStart.isBefore(b.eventStart)) return 1;
                        else if (a.eventStart.isAfter(b.eventStart)) return -1;
                        return 0;
                    });
                return prev;
            }, {});

            const current = this.radrEvents['active'][0] || this.radrEvents['inactive'][0];

            if (!current) {
                this.radrEvents = undefined;
                return;
            }

            this.filterGroup.get('event').setValue(current);
        });
    }

    toggleLayer(activeState: boolean, zoomToFeatures?: boolean) {
        this.isActive = activeState;
        if (activeState) {
            if (!this.layerSource) {
                this.layerSource = new LeafletWmsSource(this.mapLayer);
                this.layerSource.fetchImages();

                if (this.mapLayer.legend) {
                    // this.legendService.getLegend(this).subscribe((legend) => (this.legend = legend));
                }
                this.initializeControls();
                this.layerSource.addToMap();
            } else {
                this.layerSource.addToMap();
            }
        } else {
            this.layerSource.removeFromMap();
        }
    }

    public eventCompareFunction(v1: SystemEvent, v2: SystemEvent): boolean {
        return v1.id === v2.id;
    }

    public RadrImageCompareFunction(v1: GenRadrUpdateNotice, v2: GenRadrUpdateNotice): boolean {
        return v1.id === v2.id;
    }
}
