import {GenWidgetName} from '../../../../../../../generated/serverModels/GenWidgetName';
import {WidgetDetail} from '../../../../widget/classes/widget-detail';
import {GenWidgetSettingType} from '../../../../../../../generated/serverModels/GenWidgetSettingType';

export const oilWidgets = [
    {
        x: 0,
        y: 0,
        rows: 1,
        cols: 2,
        widgetName: GenWidgetName.OIL_PLANNED_OUTAGES,
        detail: new WidgetDetail().setWidgetSettings({
            type: GenWidgetSettingType.ONG_PLANNED_OUTAGE,
            value: 'future',
        } as any),
    },
    {
        x: 2,
        y: 0,
        rows: 1,
        cols: 2,
        widgetName: GenWidgetName.OIL_PLANNED_OUTAGES,
        detail: new WidgetDetail().setWidgetSettings({
            type: GenWidgetSettingType.ONG_PLANNED_OUTAGE,
            value: 'past',
        } as any),
    },
    {
        x: 4,
        y: 0,
        rows: 1,
        cols: 2,
        widgetName: GenWidgetName.OIL_PLANNED_OUTAGES,
        detail: new WidgetDetail(),
    },
    {
        x: 6,
        y: 0,
        rows: 1,
        cols: 2,
        widgetName: GenWidgetName.EIA_PETROLEUM_REPORT,
        detail: new WidgetDetail(),
    },
    {
        x: 0,
        y: 1,
        rows: 3,
        cols: 4,
        widgetName: GenWidgetName.OIL_REFINERY_STATUS,
        detail: new WidgetDetail(),
    },
    {
        x: 4,
        y: 1,
        rows: 3,
        cols: 4,
        widgetName: GenWidgetName.OIL_REFINERY_ALERT,
        detail: new WidgetDetail(),
    },
];
