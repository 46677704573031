
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!


export class GenUserPreferenceAggregationLevel {

    id: number;
    name: string;

    constructor();
    constructor( jsonObj: any );
    constructor( genUserPreferenceAggregationLevel: GenUserPreferenceAggregationLevel);
    constructor( id: number );

    constructor( input?: GenUserPreferenceAggregationLevel|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            this.name = input.name; // string [string]
       }
    }

    static jsonResponseToList(arr: any[]): GenUserPreferenceAggregationLevel[] {
        if (arr && arr.map) {
          return arr.map(a => new GenUserPreferenceAggregationLevel(a));
        } else {
          return [];
        }
    }
}

