import * as moment from 'moment';

export class LegendElement {
    get error(): boolean {
        return this._error;
    }
    public name: string;

    public label: string;
    public labelPosition: string;
    public labelColor: string = '#000000';

    public fillColor: string;

    public strokeWidth: number;
    public strokeColor: string;

    public shape: 'circle' | 'rect' = 'circle';

    public imageUrl: string;
    public radius: number;
    public value: string;

    public height: number;
    public width: number;

    public html: string;

    public textSize: string;

    private _error: boolean;
    private _retryAttempted: boolean;

    constructor(label?: string) {
        this.label = label;
    }

    public setFill(color: string): LegendElement {
        this.fillColor = color;
        return this;
    }

    public setStroke(color: string, width: number = 1): LegendElement {
        this.strokeColor = color;
        this.strokeWidth = width;
        return this;
    }

    public setName(name: string): LegendElement {
        this.name = name;
        return this;
    }

    public setImageUrl(url: string): LegendElement {
        this.imageUrl = url;
        return this;
    }

    public setHeight(height: number): LegendElement {
        this.height = height;
        return this;
    }

    public setWidth(width: number): LegendElement {
        this.width = width;
        return this;
    }

    public setRadius(radius: number): LegendElement {
        this.radius = radius;
        return this;
    }

    public setValue(value: string): LegendElement {
        this.value = value;
        return this;
    }

    public retryImgLegend(event: Event) {
        const retryFunc = () => {
            const img: HTMLImageElement = event.target as any;
            img.src = `${this.imageUrl}&cacheBuster=${moment().format()}`;
            this._retryAttempted = true;
        };

        if (this._retryAttempted) {
            this._error = true;
            return;
        }
        setTimeout(() => retryFunc(), 2000);
    }
}
