import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'eaglei-verify-email',
    template: `
        <div class="new-design">
            <div class="login-form">
                <p fxFlex="initial" class="success-text">
                    <i class="fa fa-check"></i>
                    Your email has been verified, and your account request has been forwarded to the site admin for approval!
                </p>
            </div>
        </div>
    `,
    styles: [
        `
            .success {
                font-size: 14px;
            }
        `,
    ],
})
export class VerifyEmailComponent {}
