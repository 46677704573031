import {HeadoutEventDetail} from '../classes/headout-event-detail';
import {PopoverElement} from '../../../classes/popover-element';
import {LeafletEagleiSource} from '../../../modules/layer/classes/leaflet-eaglei-source';
import {HeadoutEventResultCustomerDetail} from '../classes/headout-event-result-customer-detail';
import {GenEventTypeEnum} from '../../../../../generated/serverModels/GenEventTypeEnum';
import {HeadoutEventResult} from '../classes/headout-event-result';

export const baseCustomerPopover: (detail: HeadoutEventResultCustomerDetail) => PopoverElement[] = (
    detail: HeadoutEventResultCustomerDetail
) => {
    return [
        new PopoverElement('title', `${detail.county.name} - ${detail.county.stateAbbreviation}`).setTitle(),
        new PopoverElement('Predicted Customers Out', `${LeafletEagleiSource.numberPipe.transform(detail.households)}`),
        new PopoverElement('Predicted Percent Out', `${LeafletEagleiSource.percentPipe.transform(detail.percent, '1.2-2')}`),
        new PopoverElement('Total Customers', `${LeafletEagleiSource.numberPipe.transform(detail.totalCustomers)}`),
    ];
};

export const getCustomerDetailPopover = (
    event: HeadoutEventDetail,
    outageInfo: HeadoutEventResultCustomerDetail,
    currentAdvisory?: HeadoutEventResult
) => {
    const baseFields = baseCustomerPopover(outageInfo);
    switch (event.eventTypeId) {
        case 1:
            return [
                ...baseFields,
                new PopoverElement('Hurricane', event.name),
                new PopoverElement('NHC Advisory', currentAdvisory?.resultId),
            ];
        default:
            return baseFields;
    }
};
