
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

export class GenApiKeyRequestStatus {
    static readonly PENDING: GenApiKeyRequestStatus = new GenApiKeyRequestStatus('PENDING');
    static readonly APPROVED: GenApiKeyRequestStatus = new GenApiKeyRequestStatus('APPROVED');
    static readonly DENIED: GenApiKeyRequestStatus = new GenApiKeyRequestStatus('DENIED');

    public readonly name: string;

    private constructor(name: string) {
        this.name = name;
    }

    static forName(name: string) {
        if (name === 'PENDING') return GenApiKeyRequestStatus.PENDING;
        if (name === 'APPROVED') return GenApiKeyRequestStatus.APPROVED;
        if (name === 'DENIED') return GenApiKeyRequestStatus.DENIED;
        throw new Error('Unexpected value for GenApiKeyRequestStatus:' + name);
    }

    static values(): GenApiKeyRequestStatus[] {
        return [
            GenApiKeyRequestStatus.PENDING,
            GenApiKeyRequestStatus.APPROVED,
            GenApiKeyRequestStatus.DENIED,
        ];
    }

    toJSON(): string {
        return this.name;
    }

    toString(): string {
        return this.name;
    }
}

