import {Component, Input, OnInit} from '@angular/core';
import {MatSort} from '@angular/material/sort';

@Component({
    selector: 'eaglei-table-sort-header',
    templateUrl: './table-sort-header.component.html',
    styleUrls: ['./table-sort-header.component.scss'],
})
export class TableSortHeaderComponent implements OnInit {
    @Input() columnName: string;
    @Input() sort: MatSort;

    constructor() {}

    ngOnInit() {}
}
