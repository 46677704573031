
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import * as moment from 'moment';

export class GenGenOutageCoverageHistory {

    id: number;
    coverageDate: moment.Moment;
    coveredCustomers: number;
    numberUtiilties: number;
    totalCustomers: number;

    constructor();
    constructor( jsonObj: any );
    constructor( genGenOutageCoverageHistory: GenGenOutageCoverageHistory);
    constructor( id: number );

    constructor( input?: GenGenOutageCoverageHistory|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            if ( input.coverageDate && typeof input.coverageDate === 'string' ) {
                this.coverageDate = moment(input.coverageDate).utc();
            } else {
                this.coverageDate = input.coverageDate;
            }
            this.coveredCustomers = input.coveredCustomers; // int [number]
            this.numberUtiilties = input.numberUtiilties; // int [number]
            this.totalCustomers = input.totalCustomers; // int [number]
       }
    }

    static jsonResponseToList(arr: any[]): GenGenOutageCoverageHistory[] {
        if (arr && arr.map) {
          return arr.map(a => new GenGenOutageCoverageHistory(a));
        } else {
          return [];
        }
    }
}

