
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import { GenDatapointType } from './GenDatapointType';
import { GenKeyWordDocumentSearchResult } from './GenKeyWordDocumentSearchResult';
import * as moment from 'moment';

export class GenKeyWordDatapointSearchResult {

    id: number;
    description: string;
    documentSearchResults: GenKeyWordDocumentSearchResult[];
    geom: Object;
    key: number;
    matchReason: string;
    name: string;
    type: GenDatapointType;
    updatedDate: moment.Moment;

    constructor();
    constructor( jsonObj: any );
    constructor( genKeyWordDatapointSearchResult: GenKeyWordDatapointSearchResult);
    constructor( id: number );

    constructor( input?: GenKeyWordDatapointSearchResult|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            this.description = input.description; // string [string]
            if ( input.documentSearchResults && input.documentSearchResults.map ) { // list [GenKeyWordDocumentSearchResult]
                this.documentSearchResults = input.documentSearchResults.map( ( x: GenKeyWordDocumentSearchResult ) => {
                    return new GenKeyWordDocumentSearchResult( x );
                } );
            } else {
                this.documentSearchResults = input.documentSearchResults;
            }
            if( input.geom ) {
              this.geom = new Object( input.geom );
            }
            this.key = input.key; // int [number]
            this.matchReason = input.matchReason; // string [string]
            this.name = input.name; // string [string]
            this.type = (typeof input.type === 'string') ? GenDatapointType.forName(<string> input.type) : <any> input.type; // GenDatapointType
            if ( input.updatedDate && typeof input.updatedDate === 'string' ) {
                this.updatedDate = moment(input.updatedDate).utc();
            } else {
                this.updatedDate = input.updatedDate;
            }
       }
    }

    static jsonResponseToList(arr: any[]): GenKeyWordDatapointSearchResult[] {
        if (arr && arr.map) {
          return arr.map(a => new GenKeyWordDatapointSearchResult(a));
        } else {
          return [];
        }
    }
}

