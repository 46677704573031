import {Component, ElementRef, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BaseModal} from '../../../../classes/base-modal';

@Component({
    selector: 'eaglei-popover-data-modal',
    templateUrl: './popover-data-modal.component.html',
    styleUrls: ['./popover-data-modal.component.scss'],
})
export class PopoverDataModalComponent extends BaseModal {
    public info: any;
    @ViewChild('content') content: ElementRef;

    constructor(public dialogRef: MatDialogRef<PopoverDataModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
        super();
        this.info = data;
    }

    public close() {
        this.dialogRef.close();
    }

    afterInit() {}
}
