import {AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {UtilityCoverageData} from '../../../layer/classes/utility-coverage-data';
import {ApplicationConfig} from '../../../../classes/application-config';
import {throttleTime} from 'rxjs/operators';
import {MapService} from '../../services/map.service';
import {FileDownload} from '../../../../classes/file-download';
import * as moment from 'moment';
import {ModalConfig} from '../../../../classes/modal-config';
import {State} from '../../../outage/classes/state';

@Component({
    selector: 'eaglei-covered-utility-modal',
    templateUrl: './covered-utility-modal.component.html',
    styleUrls: ['./covered-utility-modal.component.scss'],
})
export class CoveredUtilityModalComponent implements OnInit, AfterViewInit {
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild('content') content: ElementRef;

    public filters: State[] = [];
    public loading = true;

    // Table Properties
    public columnNames = ['utilityName', 'utilityNumber', 'state', 'customerCovered', 'covered'];
    public dataSource: MatTableDataSource<UtilityCoverageData>;
    public coverageStatus: 'all' | 'covered' | 'notCovered' = 'all';

    constructor(
        public dialogRef: MatDialogRef<CoveredUtilityModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: State[],
        private mapService: MapService
    ) {
        this.filters = data;

        this.mapService.getUtilitiesCovered().subscribe((res) => {
            const filtered =
                this.filters.length > 0
                    ? res.filter((u) => {
                          return this.filters.find((s) => s.abbreviation === u.state);
                      })
                    : res;
            this.initializeData(filtered);
        });
    }

    ngOnInit() {}

    ngAfterViewInit() {
        this.setModalHeight();
        ApplicationConfig.resizeEvent.pipe(throttleTime(250)).subscribe(() => {
            this.setModalHeight();
        });
    }

    private setModalHeight() {
        (this.content.nativeElement as HTMLElement).style.maxHeight = ModalConfig.getMaxModalHeight() + 'px';
    }

    private initializeData(data: UtilityCoverageData[]): void {
        this.dataSource = new MatTableDataSource<UtilityCoverageData>(data);
        this.dataSource.filterPredicate = this.filterPredicate.bind(this);
        this.dataSource.sortingDataAccessor = this.sortAccessor;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.loading = false;
    }

    private filterPredicate(data: UtilityCoverageData, text: string): boolean {
        let coverageCheck = true;
        if (this.coverageStatus !== 'all') {
            coverageCheck = this.coverageStatus === 'covered' ? data.etlScript : !data.etlScript;
        }
        const nameCheck = data.utilityName.trim().toLowerCase().includes(text.trim().toLowerCase());

        return nameCheck && coverageCheck;
    }

    // noinspection JSMethodCanBeStatic
    private sortAccessor(data: UtilityCoverageData, header: string): string | number {
        switch (header) {
            case 'utilityName':
                return data.utilityName.toLowerCase().trim();
            case 'utilityNumber':
                return data.utilityNumber;
            case 'state':
                return data.state.toLowerCase();
            case 'customerCovered':
                return data.eiaTotalCustomers;
            default:
                return '';
        }
    }

    public filterUtilities(text: any) {
        text = text === '' ? ' ' : text;
        this.dataSource.filter = text;
    }

    public exportCsv(): void {
        let data = ['Utility Name', 'Utility Number', 'State/Territory', 'Customers Covered', 'Coverage Status'].join() + '\n';
        this.dataSource._orderData(this.dataSource.filteredData).forEach((u) => {
            const info = [
                FileDownload.formatCsvCell(u.utilityName),
                FileDownload.formatCsvCell(u.utilityNumber.toString()),
                FileDownload.formatCsvCell(u.state),
                FileDownload.formatCsvCell(u.eiaTotalCustomers.toString()),
                FileDownload.formatCsvCell(u.coverageStatus),
            ];
            data += `${info.join()}\n`;
        });

        FileDownload.downloadCSV('covered_utilities', data);
    }
}
