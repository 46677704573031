<div class="dialog-header">
    <span>{{ name }} Source Information</span>
    <button mat-icon-button mat-dialog-close>
        <mat-icon fontSet="fas" fontIcon="fa-times"></mat-icon>
    </button>
</div>

<div class="dialog-content" #content>
    <div class="table-container">
        <table mat-table [dataSource]="dataSource" matSort [matSortDisableClear]="true" matSortActive="sourceName" matSortDirection="asc">
            <ng-container matColumnDef="sourceName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="med-col">
                    Name
                    <eaglei-table-sort-header [columnName]="'sourceName'" [sort]="sort"></eaglei-table-sort-header>
                </th>
                <td mat-cell *matCellDef="let source" class="med-col">
                    <p>{{ source.name || source.description }}</p>
                </td>
            </ng-container>

            <ng-container matColumnDef="attributionUrl">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="med-col">
                    Attribution URL
                    <eaglei-table-sort-header [columnName]="'attributionUrl'" [sort]="sort"></eaglei-table-sort-header>
                </th>
                <td
                    mat-cell
                    *matCellDef="let source"
                    class="med-col"
                    [matTooltip]="source.attributionUrl"
                    matTooltipPosition="after"
                    matTooltipShowDelay="250">
                    <p class="attribution ellipsis">
                        <a *ngIf="source.attributionUrl; else noAttribution" [href]="source.attributionUrl" target="_blank">{{
                            source.attributionUrl
                        }}</a>
                        <ng-template #noAttribution>
                            <span>Not Available</span>
                        </ng-template>
                    </p>
                </td>
            </ng-container>

            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Type
                    <eaglei-table-sort-header [columnName]="'type'" [sort]="sort"></eaglei-table-sort-header>
                </th>
                <td mat-cell *matCellDef="let source">
                    <p>{{ source.type }}</p>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
            <tr mat-row *matRowDef="let row; columns: columnNames; let odd = odd"></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[5, 10, 25]" showFirstLastButtons></mat-paginator>
    </div>
</div>

<div class="dialog-actions">
    <button mat-flat-button color="primary" (click)="exportData()">Export</button>
</div>
