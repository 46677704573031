import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {FileDownload} from 'frontend/src/app/classes/file-download';
import * as moment from 'moment';
import {Report} from '../../../report/classes/report';
import {BaseWidget} from '../../classes/base-widget';
import {OngService} from '../../../../services/ong.service';
import {Observable, Subject} from 'rxjs';
import {tap} from 'rxjs/operators';
import {transportWeeklyRailFlowDaily} from '../../../../../integrations/ong/classes/ong-attributions-text';

@Component({
    selector: 'eaglei-oil-flow-table-widget',
    templateUrl: './oil-flow-table-widget.component.html',
    styleUrls: ['./oil-flow-table-widget.component.scss'],
})
export class OilFlowTableWidgetComponent extends BaseWidget implements AfterViewInit {
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    public showMask = new Subject<boolean>();

    public columnNames: string[] = ['region', 'flow', 'date'];
    public dataSource: MatTableDataSource<any>;

    public selectedTable: 'transportation' | 'rail' = 'transportation';
    public selectedDate: moment.Moment = moment().subtract(7, 'days');

    constructor(private widgetElement: ElementRef, protected ongService: OngService) {
        super(widgetElement);
    }

    public ngAfterViewInit(): void {
        this.getTableDate();
        this.attributionModalText = transportWeeklyRailFlowDaily;
        this.widgetName = 'Transportation Weekly';
    }

    destroyWidget(): void {}

    private getTableDate(): void {
        const call: Observable<any> =
            this.selectedTable === 'transportation'
                ? this.ongService.getTransportationWeekly(this.selectedDate)
                : this.ongService.getRailFlow(this.selectedDate);

        call.pipe(tap((res) => this.showMask.next(res.length === 0))).subscribe((res) => this.initData(res));
    }

    private initData(data: any): void {
        if (this.dataSource) {
            this.dataSource.data = data;
        } else {
            this.dataSource = new MatTableDataSource<any>(data);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sortingDataAccessor = this.dataAccessor.bind(this);
            this.dataSource.sort = this.sort;
        }

        this.dataSource.filter = ' ';
    }

    // noinspection JSMethodCanBeStatic
    private dataAccessor(data: any, header: string): string | number {
        switch (header) {
            case 'region':
                return data.region.toLowerCase();
            case 'terminal':
                return data.terminal.toLowerCase();
            case 'flow':
                return data.flow;
            case 'capacity':
                return data.capacity;
            case 'loading_type':
                return data.loading_type;
            case 'date':
                return moment(data.report_date).valueOf();
            default:
                return '';
        }
    }

    public updateTable(type: 'transportation' | 'rail'): void {
        this.selectedTable = type;
        this.columnNames =
            this.selectedTable === 'transportation'
                ? ['region', 'flow', 'date']
                : ['region', 'terminal', 'flow', 'capacity', 'loading_type', 'date'];
        this.getTableDate();
    }

    public updateDate(date: moment.Moment): void {
        this.selectedDate = moment(date);

        this.dataSource.filter = ' ';
        this.getTableDate();
    }

    public getTableTitle(): string {
        return this.selectedTable === 'transportation' ? 'Transportation Weekly' : 'Rail Flow Daily';
    }

    public exportTable(): void {
        let data: string =
            (this.selectedTable === 'transportation'
                ? ['Region', 'Flow (bpd)', 'Date']
                : ['Region', 'Terminal', 'Flow (bpd)', 'Capacity (bpd)', 'Loading Type', 'Date']
            ).join() + '\n';
        this.dataSource.filteredData.forEach((d) => {
            const values = [FileDownload.formatCsvCell(d.region)];

            if (this.selectedTable === 'transportation') {
                values.push(FileDownload.formatCsvCell(Report.numberPipe.transform(d.flow)));
            } else if (this.selectedTable === 'rail') {
                const add = [
                    FileDownload.formatCsvCell(d.terminal),
                    FileDownload.formatCsvCell(Report.numberPipe.transform(d.flow)),
                    FileDownload.formatCsvCell(Report.numberPipe.transform(d.capacity)),
                    FileDownload.formatCsvCell(d.loading_type),
                ];

                values.push(...add);
            }

            values.push(FileDownload.formatCsvCell(Report.momentPipe.transform(d.report_date)));

            data += `${values.join()}\n`;
        });

        const filename = this.selectedTable === 'transportation' ? 'transportationWeeklyWidget' : 'railFlowDailyWidget';
        FileDownload.downloadCSV(filename, data, 'www.genscape.com');
    }
}
