import {GenPointOfContact} from '../../../../../generated/serverModels/GenPointOfContact';

export class Poc extends GenPointOfContact {
    constructor(poc?: Poc) {
        super(poc);
    }

    public isValid(): boolean {
        return [this.name, this.organization, this.email, this.telephone].every((field) => field !== undefined);
    }
}
