
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

export class GenUrbanNetFemaType {
    static readonly COMMUNICATIONS: GenUrbanNetFemaType = new GenUrbanNetFemaType('COMMUNICATIONS');
    static readonly FOOD_WATER_SHELTER: GenUrbanNetFemaType = new GenUrbanNetFemaType('FOOD_WATER_SHELTER');
    static readonly OTHER: GenUrbanNetFemaType = new GenUrbanNetFemaType('OTHER');
    static readonly ENERGY: GenUrbanNetFemaType = new GenUrbanNetFemaType('ENERGY');
    static readonly SAFETY_AND_SECURITY: GenUrbanNetFemaType = new GenUrbanNetFemaType('SAFETY_AND_SECURITY');
    static readonly HEALTH_AND_MEDICAL: GenUrbanNetFemaType = new GenUrbanNetFemaType('HEALTH_AND_MEDICAL');
    static readonly TRANSPORTATION: GenUrbanNetFemaType = new GenUrbanNetFemaType('TRANSPORTATION');
    static readonly HAZARDOUS_MATERIAL: GenUrbanNetFemaType = new GenUrbanNetFemaType('HAZARDOUS_MATERIAL');
    static readonly UNKNOWN: GenUrbanNetFemaType = new GenUrbanNetFemaType('UNKNOWN');

    public readonly name: string;

    private constructor(name: string) {
        this.name = name;
    }

    static forName(name: string) {
        if (name === 'COMMUNICATIONS') return GenUrbanNetFemaType.COMMUNICATIONS;
        if (name === 'FOOD_WATER_SHELTER') return GenUrbanNetFemaType.FOOD_WATER_SHELTER;
        if (name === 'OTHER') return GenUrbanNetFemaType.OTHER;
        if (name === 'ENERGY') return GenUrbanNetFemaType.ENERGY;
        if (name === 'SAFETY_AND_SECURITY') return GenUrbanNetFemaType.SAFETY_AND_SECURITY;
        if (name === 'HEALTH_AND_MEDICAL') return GenUrbanNetFemaType.HEALTH_AND_MEDICAL;
        if (name === 'TRANSPORTATION') return GenUrbanNetFemaType.TRANSPORTATION;
        if (name === 'HAZARDOUS_MATERIAL') return GenUrbanNetFemaType.HAZARDOUS_MATERIAL;
        if (name === 'UNKNOWN') return GenUrbanNetFemaType.UNKNOWN;
        throw new Error('Unexpected value for GenUrbanNetFemaType:' + name);
    }

    static values(): GenUrbanNetFemaType[] {
        return [
            GenUrbanNetFemaType.COMMUNICATIONS,
            GenUrbanNetFemaType.FOOD_WATER_SHELTER,
            GenUrbanNetFemaType.OTHER,
            GenUrbanNetFemaType.ENERGY,
            GenUrbanNetFemaType.SAFETY_AND_SECURITY,
            GenUrbanNetFemaType.HEALTH_AND_MEDICAL,
            GenUrbanNetFemaType.TRANSPORTATION,
            GenUrbanNetFemaType.HAZARDOUS_MATERIAL,
            GenUrbanNetFemaType.UNKNOWN,
        ];
    }

    toJSON(): string {
        return this.name;
    }

    toString(): string {
        return this.name;
    }
}

