import {Component, inject, Inject} from '@angular/core';
import {BaseModal} from '../../../../classes/base-modal';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CommunityUser} from '../../classes/community-user';
import {CommunityService} from '../../services/community.service';
import {FormControl, Validators} from '@angular/forms';

interface ModalData {
    users: CommunityUser[];
}

@Component({
    selector: 'eaglei-single-manager-leave-modal',
    templateUrl: './single-manager-leave-modal.component.html',
    styleUrls: ['./single-manager-leave-modal.component.scss'],
})
export class SingleManagerLeaveModalComponent extends BaseModal {
    public users: CommunityUser[];

    protected dialogRef = inject(MatDialogRef<SingleManagerLeaveModalComponent>);
    private communityService = inject(CommunityService);

    protected selectionControl = new FormControl<CommunityUser>(null, {validators: Validators.required});

    constructor(@Inject(MAT_DIALOG_DATA) public data: ModalData) {
        super(50);

        this.users = data.users.filter((u) => u.accountEnabled);
    }

    afterInit() {}

    public submit() {
        const user = this.selectionControl.value;
        user.manager = true;
        this.communityService.updateUser(user).subscribe(() => {
            this.dialogRef.close(true);
        });
    }
}
