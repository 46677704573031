<eaglei-leaflet-layer-header [layer]="this" (activeToggled)="toggleLayer($event)"></eaglei-leaflet-layer-header>

<ng-container *ngIf="isEilManager">
    <div class="hide" [class.show]="isActive">
        <eaglei-filter-container
            *ngIf="mapLayer.filters"
            [filters]="mapLayer.filters"
            (filterChange)="updateFilter($event)"></eaglei-filter-container>
    </div>
</ng-container>
