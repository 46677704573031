import {Injectable, OnDestroy} from '@angular/core';
import {Client, StompConfig} from '@stomp/stompjs';
import * as SockJS from 'sockjs-client';
import {BehaviorSubject, Subscription} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {SystemNotification} from '../../app/classes/system-notification';
import {LockTimeService} from '../../app/services/lock-time.service';
import * as moment from 'moment';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root',
})
export class SocketConnectionService implements OnDestroy {
    public newSystemNotification: BehaviorSubject<SystemNotification> = new BehaviorSubject<SystemNotification>(undefined);
    public recentEventNotifications = new BehaviorSubject<SystemNotification>(undefined);
    public outageSummaryStatus = new BehaviorSubject<string>(undefined);

    private configUrl = 'api/config/websocket';
    private stompClient: Client;
    private subscriptions: Subscription = new Subscription();

    constructor(private http: HttpClient, private snackbar: MatSnackBar) {
        this.createSocketConnection();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
        this.stompClient.deactivate();
    }

    private createSocketConnection(): void {
        const responseHandlers = {
            next: (config) => {
                this.initializeStompClient(config);
            },
            error: (error: any) => {
                console.error('There was an error getting the websocket config. Unable to create connection');
                console.error(error);
            },
        };

        this.http.get(this.configUrl).subscribe(responseHandlers);
    }

    private initializeStompClient(config: any) {
        const clientConfig: StompConfig = {
            webSocketFactory: () => new SockJS(config.serverEndpoints[0]),
            reconnectDelay: 5000,
        };

        this.stompClient = new Client(clientConfig);
        this.stompClient.onConnect = () => this.initializeConnections();
        this.stompClient.activate();
    }

    private initializeConnections(): void {
        const systemNewsSub = this.stompClient.subscribe('/notification/system-news', (message) => {
            const notification = new SystemNotification(JSON.parse(message.body));
            this.newSystemNotification.next(notification);
        });

        const recentEventSub = this.stompClient.subscribe('/notification/recent-event', (message) => {
            const notification = new SystemNotification(JSON.parse(message.body));
            console.info('Received recentEventSub notification');
            this.recentEventNotifications.next(notification);
        });

        const communityRequestSub = this.stompClient.subscribe('/user/notification/community-requests', (message) => {
            const notification = new SystemNotification(JSON.parse(message.body));
            console.info('Received communityRequestSub notification');
            this.newSystemNotification.next(notification);
        });

        const passwordResetSub = this.stompClient.subscribe('/user/notification/password-reset', (message) => {
            const notification = new SystemNotification(JSON.parse(message.body));
            console.info('Received passwordResetSub notification');
            this.newSystemNotification.next(notification);
        });

        const apiKeySub = this.stompClient.subscribe('/user/notification/api-key-expiration', (message) => {
            const notification = new SystemNotification(JSON.parse(message.body));
            console.info('Received apiKeySub notification');
            this.newSystemNotification.next(notification);
        });

        const lockTimeSub = this.stompClient.subscribe('/training/system-time/lock', (message) => {
            const notification = JSON.parse(message.body);
            console.info('Received lock time notification: ' + moment(notification).format());
            LockTimeService.currentApplicationTime.next(moment(notification));
        });

        const unlockTimeSub = this.stompClient.subscribe('/training/system-time/unlock', (message) => {
            if (!LockTimeService.currentApplicationTime) {
                console.error(`Unable to unlock time since it has not been locked`);
                return;
            }
            console.warn('Received unlock time notification');

            LockTimeService.currentApplicationTime.next(undefined);
        });

        const testSub = this.stompClient.subscribe('/system-data/test', (message) => {
            console.info('Received Test Socket message');
            this.snackbar.open('Received Test Message', 'Okay', {duration: 5_000});
        });

        const hourMaxCalculationsSub = this.stompClient.subscribe('/system/hour-max-calculations', (message) => {
            console.info('Received hour max calculation notification: ' + message.body);
            let status: string;

            switch (message.body) {
                case 'calculatingBoth':
                case 'calculatingToday':
                case 'calculatingOther':
                    status = 'Calculating Max Outage Summary';
                    break;
                default:
                    status = undefined;
            }

            this.outageSummaryStatus.next(status);
        });

        this.subscriptions.add(systemNewsSub);
        this.subscriptions.add(passwordResetSub);
        this.subscriptions.add(recentEventSub);
        this.subscriptions.add(communityRequestSub);
        this.subscriptions.add(apiKeySub);
        this.subscriptions.add(lockTimeSub);
        this.subscriptions.add(unlockTimeSub);
        this.subscriptions.add(testSub);
        this.subscriptions.add(hourMaxCalculationsSub);
    }
}
