
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import { GenUser } from './GenUser';

export class GenEmailData {

    body: string;
    sendTo: GenUser[];
    subject: string;

    constructor();
    constructor( jsonObj: any );
    constructor( genEmailData: GenEmailData);

    constructor( input?: GenEmailData ) {
        if ( input && typeof input !== 'number' ) {
            this.body = input.body; // string [string]
            if ( input.sendTo && input.sendTo.map ) { // list [GenUser]
                this.sendTo = input.sendTo.map( ( x: GenUser ) => {
                    return new GenUser( x );
                } );
            } else {
                this.sendTo = input.sendTo;
            }
            this.subject = input.subject; // string [string]
       }
    }

    static jsonResponseToList(arr: any[]): GenEmailData[] {
        if (arr && arr.map) {
          return arr.map(a => new GenEmailData(a));
        } else {
          return [];
        }
    }
}

