import {Directive, HostListener, Input, OnInit} from '@angular/core';

@Directive({
    selector: '[eagleiNumberInput]',
})
export class NumberInputDirective implements OnInit {
    @Input() allowNegative: boolean = true;
    @Input() allowDecimal: boolean = true;

    private regex: RegExp;

    @HostListener('keypress', ['$event']) onKeyPress(event: KeyboardEvent) {
        const test = this.regex.test(event.key);

        if (!test) {
            event.preventDefault();
        }
    }

    constructor() {}

    ngOnInit() {
        if (this.allowDecimal && this.allowNegative) {
            this.regex = /\d|-|\./;
        } else if (this.allowDecimal && !this.allowNegative) {
            this.regex = /\d|\./;
        } else if (!this.allowDecimal && this.allowNegative) {
            this.regex = /\d|-/;
        } else {
            this.regex = /\d/;
        }
    }
}
