export class LegendMetric {
    public static PERCENTAGE = 1;
    public static CUSTOMERS_OUT = 2;

    public static values(): LegendMetric[] {
        return [LegendMetric.PERCENTAGE, LegendMetric.CUSTOMERS_OUT];
    }

    public static from(id: number): LegendMetric {
        switch (id) {
            case 1:
                return LegendMetric.PERCENTAGE;
            case 2:
                return LegendMetric.CUSTOMERS_OUT;
            default:
                return undefined;
        }
    }
}
