import {GenSource} from '../../../../../generated/serverModels/GenSource';

export class RecentEventSource extends GenSource {
    public selected = false;

    constructor(source?: RecentEventSource) {
        super(source);
        if (this.source) {
            this.source = this.source.replace(/&trade;/g, '™');
        }
    }
}
