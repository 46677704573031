
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!


export class GenFeedback {

    description: string;
    feedbackType: string;
    summary: string;

    constructor();
    constructor( jsonObj: any );
    constructor( genFeedback: GenFeedback);

    constructor( input?: GenFeedback ) {
        if ( input && typeof input !== 'number' ) {
            this.description = input.description; // string [string]
            this.feedbackType = input.feedbackType; // string [string]
            this.summary = input.summary; // string [string]
       }
    }

    static jsonResponseToList(arr: any[]): GenFeedback[] {
        if (arr && arr.map) {
          return arr.map(a => new GenFeedback(a));
        } else {
          return [];
        }
    }
}

