<div class="hide-overflow">
    <div #header class="widget-header" ngClass.lt-md="ignore-header">
        <p class="title">Outage Snapshot</p>

        <div class="btn-container">
            <button mat-stroked-button (click)="goToReport()">
                <mat-icon class="icon" fontSet="fas" fontIcon="fa-file-alt" inline></mat-icon>
                <span>View Report</span>
            </button>
        </div>
    </div>

    <div #content class="widget-content">
        <div class="container">
            <!-- <div *ngFor="let i of numberOfSnapshots; index as index">
                <mat-card class="map-container" [class.hide-snapshot]="getSnapshot(index) === undefined">
                    <ng-container
                        *ngIf="dataLoaded; else loadingMasks"
                        [ngTemplateOutlet]="maps"
                        [ngTemplateOutletContext]="{snapshot: getSnapshot(index)}"></ng-container>
                </mat-card>
            </div> -->

            <mat-card class="map-container" [class.hide-snapshot]="getSnapshot('start') === undefined">
                <ng-container
                    *ngIf="dataLoaded; else loadingMasks"
                    [ngTemplateOutlet]="maps"
                    [ngTemplateOutletContext]="{snapshot: getSnapshot('start'), isStart: true}"></ng-container>
            </mat-card>

            <mat-card class="map-container" [class.hide-snapshot]="getSnapshot('now') === undefined">
                <ng-container
                    *ngIf="dataLoaded; else loadingMasks"
                    [ngTemplateOutlet]="maps"
                    [ngTemplateOutletContext]="{snapshot: getSnapshot('now'), isStart: false}"></ng-container>
            </mat-card>

            <!-- TODO: Find a way to render the second map without a hidden thrid map -->
            <mat-card class="map-container hide" [class.hide-snapshot]="getSnapshot('now') === undefined">
                <ng-container
                    *ngIf="dataLoaded; else loadingMasks"
                    [ngTemplateOutlet]="maps"
                    [ngTemplateOutletContext]="{snapshot: getSnapshot('now'), isStart: false}"></ng-container>
            </mat-card>
        </div>

        <div class="auto-mask click-through" *ngIf="showMask">
            <p class="mask-message" [class.no-data]="this.dataLoaded && this.snapshots.length === 0">{{ getMaskText() }}</p>
        </div>
    </div>

    <div #footer class="widget-footer">
        <div class="legend" [class.hide-snapshot]="!dataLoaded">
            <!-- <mat-paginator hidePageSize showFirstLastButtons (page)="pageSnapshots($event)"></mat-paginator> -->
            <eaglei-nom-legend [hideOverrideIcon]="true"></eaglei-nom-legend>
        </div>
    </div>
</div>

<ng-template #maps let-snapshot="snapshot" let-isStart="isStart">
    <mat-card-subtitle>
        <span class="time-title">{{ isStart ? 'Start of Event' : 'Current' }}</span>
        <span>{{ snapshot?.runStartTime | momentDate : 'ddd M/D/YY, h:mm A z' }}</span>
    </mat-card-subtitle>
    <mat-card-content>
        <eaglei-leaflet-map (mapRef)="setMapRef(snapshot, $event)" [mapOptions]="mapOptions" [singleBaseLayer]="true"></eaglei-leaflet-map>
    </mat-card-content>
    <!-- <mat-card-footer>
        <eaglei-nom-legend [hideOverrideIcon]="true"></eaglei-nom-legend>
    </mat-card-footer> -->
</ng-template>

<ng-template #loadingMasks>
    <mat-card-content class="wide tall">
        <img class="loading-image" src="/dist/images/icons/map-mask.png" alt="Map Loading Mask" />
    </mat-card-content>
</ng-template>
