
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import { GenCommunityPermission } from './GenCommunityPermission';
import { GenLayoutType } from './GenLayoutType';
import { GenWidgetName } from './GenWidgetName';

export class GenWidget {

    id: number;
    communityPermissions: GenCommunityPermission[];
    configurable: boolean;
    description: string;
    honorUserPreference: boolean;
    iconName: string;
    maxHeight: number;
    maxWidth: number;
    minHeight: number;
    minWidth: number;
    name: GenWidgetName;
    numberAllowed: number;
    types: GenLayoutType[];

    constructor();
    constructor( jsonObj: any );
    constructor( genWidget: GenWidget);
    constructor( id: number );

    constructor( input?: GenWidget|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            if ( input.communityPermissions && input.communityPermissions.map ) {
                this.communityPermissions = input.communityPermissions.map( ( x: GenCommunityPermission ) => {
                    return (typeof x === 'string') ? GenCommunityPermission.forName(<string> x) : <any> x; // GenCommunityPermission
                } );
            } else {
                this.communityPermissions = input.communityPermissions; // GenCommunityPermission
            }
            this.configurable = input.configurable; // boolean [boolean]
            this.description = input.description; // string [string]
            this.honorUserPreference = input.honorUserPreference; // boolean [boolean]
            this.iconName = input.iconName; // string [string]
            this.maxHeight = input.maxHeight; // int [number]
            this.maxWidth = input.maxWidth; // int [number]
            this.minHeight = input.minHeight; // int [number]
            this.minWidth = input.minWidth; // int [number]
            this.name = (typeof input.name === 'string') ? GenWidgetName.forName(<string> input.name) : <any> input.name; // GenWidgetName
            this.numberAllowed = input.numberAllowed; // int [number]
            if ( input.types && input.types.map ) { // list [GenLayoutType]
                this.types = input.types.map( ( x: GenLayoutType ) => {
                    return new GenLayoutType( x );
                } );
            } else {
                this.types = input.types;
            }
       }
    }

    static jsonResponseToList(arr: any[]): GenWidget[] {
        if (arr && arr.map) {
          return arr.map(a => new GenWidget(a));
        } else {
          return [];
        }
    }
}

