import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {UtilityCoverageData} from '../../layer/classes/utility-coverage-data';
import {ResponseWrapper} from '../../../classes/response-wrapper';
import {filter, map, skip} from 'rxjs/operators';
import {State} from '../../outage/classes/state';
import {ApplicationConfig} from '../../../classes/application-config';
import * as L from 'leaflet';
import {IDateRange} from '../../../interfaces/date-range.interface';
import {LeafletMapLayer} from '../../layer/classes/leaflet-map-layer';
import {StateCoverage} from '../../report/classes/state-coverage';
import {GenDataSource} from '../../../../../generated/serverModels/GenDataSource';
import {ILayer} from '../../layer/interfaces/layer.interface';
import {HistoricalOutageData} from '../../layer/classes/historical-outage-data';
import {LockTimeService} from '../../../services/lock-time.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Injectable({
    providedIn: 'root',
})
export class MapService {
    get layersLoading(): number {
        return this.currentlyLoading.size;
    }

    static inSessionLayers: LeafletMapLayer[] = [];

    // 200 is the default set by leaflet, so we just need to be above that
    static globalLayerZIndex = 200;
    static readonly layerPaneName = 'layers';
    static alaskaBounds = new L.LatLngBounds(new L.LatLng(49.5, -179), new L.LatLng(70.5, -128));

    static addLayerLoading = new BehaviorSubject<string>(undefined);
    static removeLayerLoading = new BehaviorSubject<string>(undefined);
    static mapRef: L.Map;

    static getEagleiLayers(mapRef?: L.Map): ILayer[] {
        const layers = [];
        const mapReference = mapRef || MapService.mapRef;
        mapReference.eachLayer((layer: any) => {
            if (layer.eagleiLayer) {
                layers.push(layer);
            }
        });
        return layers;
    }

    static getLayer(handle: string, mapRef?: L.Map): ILayer {
        return MapService.getEagleiLayers(mapRef).find((layer) => layer.eagleiLayer.uiHandle === handle);
    }

    public baseLayer: BehaviorSubject<string> = new BehaviorSubject<string>('Topographic');
    public zoomToHome = new Subject<any>();

    public reloadLayerData = new BehaviorSubject(false);

    private currentlyLoading = new Set<string>();

    public mapDateRange: BehaviorSubject<IDateRange> = new BehaviorSubject<IDateRange>({
        startDate: ApplicationConfig.roundMinute().subtract(1, 'days').startOf('day'),
        endDate: ApplicationConfig.roundMinute(),
    });

    public lastRefreshTime = ApplicationConfig.roundMinute();

    public activeIdentifyLayers: string[] = [];

    public coverageData: StateCoverage[] = [];
    public coveragePercent: number;
    public nomActiveStates: BehaviorSubject<State[]> = new BehaviorSubject<State[]>([]);

    public nomLoadedData: BehaviorSubject<HistoricalOutageData[]> = new BehaviorSubject<HistoricalOutageData[]>(undefined);

    constructor(private http: HttpClient) {
        this.getCoverageData();

        MapService.addLayerLoading.pipe(filter((handle) => !!handle)).subscribe((handle) => {
            this.currentlyLoading.add(handle);
        });

        MapService.removeLayerLoading.pipe(filter((handle) => this.layersLoading > 0 && !!handle)).subscribe((handle) => {
            this.currentlyLoading.delete(handle);
        });

        // TODO: We should update the mapDateRange to just be a stream that can be listened to
        LockTimeService.currentApplicationTime.pipe(skip(1), takeUntilDestroyed()).subscribe(() => {
            this.mapDateRange.next({
                startDate: ApplicationConfig.roundMinute().subtract(1, 'days').startOf('day'),
                endDate: ApplicationConfig.roundMinute(),
            });
        });

        this.nomActiveStates.subscribe(() => {
            this.calculateCoverage();
        });
    }

    // Coverage Methods
    public getCoverageData(): void {
        this.http
            .get('api/coverage/state')
            .pipe(map((res: ResponseWrapper<StateCoverage>) => res.data.map((sc) => new StateCoverage(sc))))
            .subscribe((ret) => (this.coverageData = ret));
    }

    public calculateCoverage(): void {
        const filters = this.nomActiveStates.getValue().map((state) => state.abbreviation);

        if (filters.length === 0) {
            this.coveragePercent = 0;
            return;
        }

        let total = 0;
        let covered = 0;

        this.coverageData
            .filter((coverage) => filters.includes(coverage.state))
            .forEach((d) => {
                total += d.eiaTotalCustomers;
                covered += d.coveredCustomersBestCase;
            });

        this.coveragePercent = covered / total;
    }

    public getSourceData(): Observable<GenDataSource[]> {
        // noinspection SpellCheckingInspection
        return this.http.get<any[]>('api/maplayer/dataurls').pipe(map((sources) => sources.map((source) => new GenDataSource(source))));
    }

    public getUtilitiesCovered(): Observable<UtilityCoverageData[]> {
        // noinspection SpellCheckingInspection
        const url = 'api/coverage/utility?excludeuseradded=false';
        return this.http.get<ResponseWrapper<UtilityCoverageData>>(url).pipe(map((res) => res.data.map((u) => new UtilityCoverageData(u))));
    }
}
