import {Component} from '@angular/core';
import {BasePopover} from '../base-popover';
import {Nomination} from '../../../../classes/ong/nomination';

@Component({
    selector: 'eaglei-ong-nominations-popover',
    templateUrl: './ong-nominations-popover.component.html',
    styleUrls: ['./ong-nominations-popover.component.scss', '../layer-popover.scss'],
})
export class OngNominationsPopoverComponent extends BasePopover<Nomination> {
    constructor() {
        super();
    }

    onInit() {}

    updateData() {}

    afterInit() {}
}
