<mat-form-field class="filter-select no-bottom-padding" [class.active]="selectedLocations.length > 0" appearance="outline">
    <mat-label>Filter Locations</mat-label>
    <mat-select #select multiple placeholder="Filter Locations" [(ngModel)]="selectedLocations" (openedChange)="panelStatus($event)">
        <mat-select-trigger>{{ getValue() }}</mat-select-trigger>

        <mat-checkbox
            class="mat-option left"
            *ngIf="selectAllOption"
            color="primary"
            [checked]="allSelected"
            [indeterminate]="isIndeterminate"
            (change)="selectAll()"
            >Select All</mat-checkbox
        >

        <mat-optgroup label="FEMA Regions" *ngIf="!hideFemaRegions">
            <mat-option
                *ngFor="let region of femaRegions"
                [value]="region"
                (click)="femaRegionChanged(region)"
                [disabled]="hasAllowedState(region)">
                {{ region.toString() | numeral : true }}
            </mat-option>
        </mat-optgroup>
        <mat-optgroup label="States">
            <mat-option
                *ngFor="let state of states"
                [value]="state.name"
                (click)="stateChanged(state)"
                [disabled]="disableStateSelection(state)">
                {{ state.name }}
            </mat-option>
        </mat-optgroup>
    </mat-select>
</mat-form-field>
