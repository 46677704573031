import {Component} from '@angular/core';
import {WildfirePerimeterSource} from '../../sources/wildfire-perimeter-source';
import {LayerService} from '../../services/layer.service';
import {EagleILayer} from '../../classes/eagle-i-layer';
import {tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {LeafletLegendService} from '../../services/leaflet-legend.service';

@Component({
    selector: 'eaglei-wildfire-perimeter-layer',
    templateUrl: './wildfire-perimeter-layer.component.html',
    styleUrls: ['./wildfire-perimeter-layer.component.scss'],
})
export class WildfirePerimeterLayerComponent extends EagleILayer<WildfirePerimeterSource> {
    constructor(private layerService: LayerService, private legendService: LeafletLegendService) {
        super();
    }

    afterViewInit() {}

    public toggleLayer(activeState: boolean, zoomToFeatures?: boolean): void {
        if (activeState) {
            if (!this.layerSource) {
                this.layerSource = new WildfirePerimeterSource(this.mapLayer);
                this.legendService.getLegend(this).subscribe((legend) => (this.legend = legend));
            }
            this.getWildfirePerimeters().subscribe(() => this.layerSource.addToMap());
        } else {
            this.layerSource.removeFromMap();
        }
    }

    private getWildfirePerimeters(): Observable<any> {
        this.mapLayer.startLoading();

        const p = this.mapLayer.getParams();
        const opts = Object.keys(p)
            .map((key) => `${key}=${encodeURIComponent(p[key])}`)
            .join('&');
        const dataUrl = `${this.mapLayer.serviceurl}/${this.mapLayer.servicelayer}/query?${opts}`;

        return this.layerService.getJsonFromUrl(dataUrl).pipe(
            tap((res) => {
                this.layerSource.processFeatures(res.features);
                this.mapLayer.endLoading();
            })
        );
    }
}
