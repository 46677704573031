<div class="dialog-content" #content>
    <div class="report-filters" fxLayout="column" fxLayoutGap="25px">
        <div class="aggregation-container">
            <span>Outages by</span>
            <mat-radio-group [(ngModel)]="selectedAggLevel" (change)="updateAggLevel($event)" fxLayout="row">
                <mat-radio-button [value]="getAggValue('state')">State/Territory</mat-radio-button>
                <mat-radio-button [value]="getAggValue('county')">County</mat-radio-button>
            </mat-radio-group>
        </div>

        <div class="filters" #filters>
            <eaglei-select-clear (select)="changeAllFilters(true)" (clear)="changeAllFilters(false)"></eaglei-select-clear>

            <div class="state-container" fxLayout="row wrap" *ngIf="selectedAggLevel !== getAggValue('fema')">
                <div *ngFor="let state of states" fxFlex="0 1 25%">
                    <mat-checkbox
                        class="state-check"
                        [checked]="isStateSelected(state)"
                        (change)="updateSelectedFilters(state, $event.checked)">
                        {{ state.abbreviation }}
                    </mat-checkbox>
                </div>
            </div>
        </div>

        <div class="action-container">
            <div fxLayoutGap="20px" fxLayoutAlign="space-between center">
                <button mat-raised-button color="primary" fxFlex="gorw" (click)="apply()">Apply Changes</button>
                <button mat-raised-button color="warn" fxFlex="gorw" (click)="cancel()">Cancel Changes</button>
            </div>
        </div>
    </div>
</div>
