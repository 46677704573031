<mat-card>
    <div class="interaction-buttons">
        <div class="buttons">
            <mat-icon fontSet="fas" fontIcon="fa-backward-step" (click)="stepBackwards()"></mat-icon>
            <mat-icon fontSet="fas" fontIcon="fa-play" *ngIf="!isPlaying" (click)="play()"></mat-icon>
            <mat-icon fontSet="fas" fontIcon="fa-pause" *ngIf="isPlaying" (click)="pause()"></mat-icon>
            <mat-icon fontSet="fas" fontIcon="fa-forward-step" (click)="stepForward()"></mat-icon>
        </div>

        <div (click)="goToCurrentDate()" *ngIf="showLiveButton" class="clickable live-data" [class.live-data]="isLive">
            <div class="circle"></div>
            <span>LIVE</span>
        </div>
    </div>

    <mat-card-subtitle>
        <div class="slider-element">
            <ng-container [ngTemplateOutlet]="slider"></ng-container>
        </div>

        <span class="range-info">
            <span class="range-dates start-date">
                {{ sliderStart | momentDate : 'MM/DD/YYYY' }}
                <ng-container *ngIf="allowDateChange">
                    <mat-icon
                        class="clickable"
                        fontSet="far"
                        fontIcon="fa-calendar"
                        inline
                        matTooltip="Change Start Date"
                        matTooltipPosition="above"
                        (click)="startDatePicker.open()">
                    </mat-icon>
                    <mat-form-field class="example-full-width start-date-picker">
                        <input
                            matInput
                            [matDatepicker]="startDatePicker"
                            [min]="min"
                            [max]="max"
                            placeholder="Choose a date"
                            (dateChange)="updateStartDate($event)" />
                        <mat-datepicker #startDatePicker [startAt]="start.toDate()"></mat-datepicker>
                    </mat-form-field>
                </ng-container>
            </span>
            <div *ngIf="sections?.length !== 0" class="section-legend">
                <div *ngFor="let section of sections" class="legend-entry">
                    <div class="section-color" [style.background-color]="section.color"></div>
                    <span class="section-text">{{ section.display }}</span>
                </div>
            </div>
            <span class="range-dates end-date">
                {{ sliderEnd | momentDate : 'MM/DD/YYYY' }}
                <ng-container *ngIf="allowDateChange">
                    <mat-icon
                        class="clickable"
                        fontSet="far"
                        fontIcon="fa-calendar"
                        inline
                        matTooltip="Change End Date"
                        matTooltipPosition="above"
                        (click)="endDatePicker.open()"></mat-icon>
                    <mat-form-field class="example-full-width start-date-picker">
                        <input
                            matInput
                            [matDatepicker]="endDatePicker"
                            [min]="min"
                            [max]="max"
                            placeholder="Choose a date"
                            (dateChange)="updateEndDate($event)" />
                        <mat-datepicker #endDatePicker [startAt]="start.toDate()"></mat-datepicker>
                    </mat-form-field> </ng-container
            ></span>
        </span>
    </mat-card-subtitle>
</mat-card>

<ng-template #slider>
    <div class="slider-container">
        <div #sectionArea class="section-area"></div>
        <div #slideLine class="slider-line" (click)="moveDot(currentDot, $event)">
            <div #colorLine class="color-line"></div>

            <div
                #currentDot
                class="current-dot"
                [attr.data-date]="current.valueOf().toString()"
                [matTooltip]="current | momentDate"
                matTooltipPosition="above"
                matTooltipShowDelay="250"
                (mousedown)="onMouseDown(currentDot, 'center')"></div>

            <div #dragArea class="drag-area"></div>
        </div>
    </div>
</ng-template>
