
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import * as moment from 'moment';

export class GenApiKey {

    id: number;
    comments: string;
    dataService: boolean;
    email: string;
    expirationDate: moment.Moment;
    fullName: string;
    key: string;
    ogcCount: number;
    ogcService: boolean;
    reason: string;
    system: string;
    useCount: number;
    userId: number;
    username: string;
    usersSupported: number;

    constructor();
    constructor( jsonObj: any );
    constructor( genApiKey: GenApiKey);
    constructor( id: number );

    constructor( input?: GenApiKey|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            this.comments = input.comments; // string [string]
            this.dataService = input.dataService; // boolean [boolean]
            this.email = input.email; // string [string]
            if ( input.expirationDate && typeof input.expirationDate === 'string' ) {
                this.expirationDate = moment(input.expirationDate).utc();
            } else {
                this.expirationDate = input.expirationDate;
            }
            this.fullName = input.fullName; // string [string]
            this.key = input.key; // string [string]
            this.ogcCount = input.ogcCount; // int [number]
            this.ogcService = input.ogcService; // boolean [boolean]
            this.reason = input.reason; // string [string]
            this.system = input.system; // string [string]
            this.useCount = input.useCount; // int [number]
            this.userId = input.userId; // int [number]
            this.username = input.username; // string [string]
            this.usersSupported = input.usersSupported; // int [number]
       }
    }

    static jsonResponseToList(arr: any[]): GenApiKey[] {
        if (arr && arr.map) {
          return arr.map(a => new GenApiKey(a));
        } else {
          return [];
        }
    }
}

