import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Community} from '../classes/community';
import {Observable} from 'rxjs';
import {CommunityFile} from '../classes/community-file';
import {CommunityUser} from '../classes/community-user';
import {GenCommunityUserStatus} from '../../../../../generated/serverModels/GenCommunityUserStatus';
import {User} from '../../user/classes/user';
import {EmailData} from '../../../classes/email-data';

@Injectable({
    providedIn: 'root',
})
export class CommunityService {
    private readonly url = `api/permission/community`;

    constructor(private http: HttpClient) {}

    public getAllCommunities(): Observable<Community[]> {
        return this.http.get<any[]>(this.url).pipe(map((communities) => communities.map((community) => new Community(community))));
    }

    public getUserCommunities(userId: number, status: GenCommunityUserStatus): Observable<Community[]> {
        const url = `${this.url}?userId=${userId}&status=${status.toString()}`;
        return this.http.get<any[]>(url).pipe(map((communities) => communities.map((community) => new Community(community))));
    }

    public getCommunityById(communityId: number): Observable<Community> {
        const url = `${this.url}/${communityId}`;

        const mf = (community: Community) => {
            return new Community(community);
        };

        return this.http.get<any>(url).pipe(map(mf));
    }

    public updateCommunity(community: Community): Observable<Community> {
        const url = `${this.url}/${community.id}`;
        return this.http.put<any>(url, community).pipe(map((res) => new Community(res)));
    }

    // File Methods
    public saveFilesToCommunity(communityId: number, files: CommunityFile[]): Observable<CommunityFile[]> {
        const url = `${this.url}/file/${communityId}`;
        const formData = new FormData();

        files.forEach((file) => {
            formData.append('file', file.file);
        });

        formData.append('fileData', JSON.stringify(files));

        return this.http
            .post<any[]>(url, formData)
            .pipe(map((communityFiles) => (communityFiles ? communityFiles.map((communityFile) => new CommunityFile(communityFile)) : [])));
    }

    public updateFile(file: CommunityFile): Observable<CommunityFile> {
        const url = `${this.url}/file/${file.id}`;
        return this.http.put<any>(url, file).pipe(
            map((ret) => {
                const updatedFile = new CommunityFile(ret);
                updatedFile.events = file.events;
                return updatedFile;
            })
        );
    }

    public renameFile(file: CommunityFile): Observable<any> {
        const url = `${this.url}/file/rename/${file.id}`;
        return this.http.put<any>(url, file.displayName);
    }

    // User Access Methods
    public requestAccess(communityUser: CommunityUser): Observable<CommunityUser> {
        const url = `${this.url}/requestaccess`;
        return this.http.post<any>(url, communityUser).pipe(map((response) => new CommunityUser(response)));
    }

    public approveUser(communityUser: CommunityUser): Observable<CommunityUser> {
        const url = `${this.url}/${communityUser.communityId}/approve/${communityUser.userId}`;
        return this.http.put<any>(url, null).pipe(map((user) => new CommunityUser(user)));
    }

    public denyUser(communityUser: CommunityUser, reason: string): Observable<CommunityUser> {
        const url = `${this.url}/${communityUser.communityId}/deny/${communityUser.userId}`;
        return this.http.put<any>(url, reason).pipe(map((user) => new CommunityUser(user)));
    }

    public deleteFile(file: CommunityFile, communityId: number): Observable<any> {
        const url = `${this.url}/${communityId}/file/${file.id}`;
        return this.http.delete(url);
    }

    public deleteUserFromCommunity(communityId: number, userId: number): Observable<any> {
        const url = `${this.url}/${communityId}/user/${userId}`;
        return this.http.delete(url);
    }

    public updateUser(user: CommunityUser): Observable<CommunityUser> {
        const url = `${this.url}/user/${user.userId}`;
        return this.http.put<any>(url, user).pipe(map((u) => new CommunityUser(u)));
    }

    public addUsersAsManager(communityId: number, users: User[]): Observable<CommunityUser[]> {
        const url = `${this.url}/${communityId}/addusers`;
        return this.http.put<any[]>(url, users).pipe(map((communityUsers) => communityUsers.map((user) => new CommunityUser(user))));
    }

    public emailAllUsers(communityId: number, emailData: EmailData): Observable<any> {
        const url = `${this.url}/email/${communityId}`;
        return this.http.post(url, emailData);
    }

    public searchSolrDocuments(communityId: number, searchText: string): Observable<CommunityFile[]> {
        const url = `${this.url}/search/${communityId}`;
        return this.http.post<any[]>(url, searchText).pipe(map((res) => res.map((file) => new CommunityFile(file))));
    }

    public getUsers(communityId: number): Observable<User[]> {
        const url = `${this.url}/${communityId}/getuserlist`;
        return this.http.get<any[]>(url).pipe(map((users) => users.map((user) => new User(user))));
    }
}
