import {Component, inject, Input} from '@angular/core';
import {InfoModalComponent} from '../../modals/info-modal/info-modal.component';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {ModalConfig} from '../../../app/classes/modal-config';

@Component({
    selector: 'eaglei-info-icon',
    templateUrl: './info-icon.component.html',
    styleUrls: ['./info-icon.component.scss'],
})
export class InfoIconComponent {
    private dialog = inject(MatDialog);
    @Input() attributionModalText: string;
    @Input() widgetName: string;
    public openInfo(): void {
        const config: MatDialogConfig = {
            ...ModalConfig.defaultConfig,
            data: {
                name: this.widgetName,
                message: this.attributionModalText,
            },
        };
        this.dialog.open(InfoModalComponent, config);
    }
}
