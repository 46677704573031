
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import { GenCounty } from './GenCounty';
import { GenEventType } from './GenEventType';
import { GenSystemEventAnalytic } from './GenSystemEventAnalytic';
import { GenSystemEventPhase } from './GenSystemEventPhase';
import { GenSystemEventUser } from './GenSystemEventUser';
import * as moment from 'moment';

export class GenSystemEvent {

    id: number;
    analytics: GenSystemEventAnalytic[];
    boundary: string;
    counties: GenCounty[];
    description: string;
    eventEnd: moment.Moment;
    eventStart: moment.Moment;
    eventTypeId: number;
    name: string;
    phases: GenSystemEventPhase[];
    type: GenEventType;
    users: GenSystemEventUser[];

    constructor();
    constructor( jsonObj: any );
    constructor( genSystemEvent: GenSystemEvent);
    constructor( id: number );

    constructor( input?: GenSystemEvent|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            if ( input.analytics && input.analytics.map ) {
                this.analytics = input.analytics.map( ( x: GenSystemEventAnalytic ) => {
                    return (typeof x === 'string') ? GenSystemEventAnalytic.forName(<string> x) : <any> x; // GenSystemEventAnalytic
                } );
            } else {
                this.analytics = input.analytics; // GenSystemEventAnalytic
            }
            this.boundary = input.boundary; // string [string]
            if ( input.counties && input.counties.map ) { // list [GenCounty]
                this.counties = input.counties.map( ( x: GenCounty ) => {
                    return new GenCounty( x );
                } );
            } else {
                this.counties = input.counties;
            }
            this.description = input.description; // string [string]
            if ( input.eventEnd && typeof input.eventEnd === 'string' ) {
                this.eventEnd = moment(input.eventEnd).utc();
            } else {
                this.eventEnd = input.eventEnd;
            }
            if ( input.eventStart && typeof input.eventStart === 'string' ) {
                this.eventStart = moment(input.eventStart).utc();
            } else {
                this.eventStart = input.eventStart;
            }
            this.eventTypeId = input.eventTypeId; // int [number]
            this.name = input.name; // string [string]
            if ( input.phases && input.phases.map ) { // list [GenSystemEventPhase]
                this.phases = input.phases.map( ( x: GenSystemEventPhase ) => {
                    return new GenSystemEventPhase( x );
                } );
            } else {
                this.phases = input.phases;
            }
            if( input.type ) {
              this.type = new GenEventType( input.type );
            }
            if ( input.users && input.users.map ) { // list [GenSystemEventUser]
                this.users = input.users.map( ( x: GenSystemEventUser ) => {
                    return new GenSystemEventUser( x );
                } );
            } else {
                this.users = input.users;
            }
       }
    }

    static jsonResponseToList(arr: any[]): GenSystemEvent[] {
        if (arr && arr.map) {
          return arr.map(a => new GenSystemEvent(a));
        } else {
          return [];
        }
    }
}

