import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatTableDataSource} from '@angular/material/table';
import {GenCommunityPermission} from 'frontend/generated/serverModels/GenCommunityPermission';
import {FileDownload} from 'frontend/src/app/classes/file-download';
import * as moment from 'moment';
import {CommunityFile} from '../../../community/classes/community-file';
import {CommunityService} from '../../../community/services/community.service';
import {EventSummaryFilters} from '../../../system-event/classes/event-summary-filters';
import {BaseWidget} from '../../classes/base-widget';

@Component({
    selector: 'eaglei-situation-widget',
    templateUrl: './situation-widget.component.html',
    styleUrls: ['./situation-widget.component.scss'],
})
export class SituationWidgetComponent extends BaseWidget implements AfterViewInit {
    @ViewChild(MatPaginator) paginator: MatPaginator;

    public files: CommunityFile[] = [];
    public showingFiles: CommunityFile[] = [];

    private sitRepCommunityId: number;

    public eventFilters: EventSummaryFilters = new EventSummaryFilters();

    constructor(private widgetElement: ElementRef, private communityService: CommunityService, private popup: MatSnackBar) {
        super(widgetElement);
    }

    protected handleEventFilterChange(eventFilters: EventSummaryFilters): void {
        this.eventFilters = eventFilters;

        this.getSitRep();
    }

    public ngAfterViewInit(): void {
        this.paginator.pageSize = 5;

        this.communityService.getAllCommunities().subscribe((res) => {
            this.sitRepCommunityId = res.find((c) => c.dataAccessHandle === GenCommunityPermission.FEDERAL_SITUATION_REPORT).id;

            this.getSitRep();
        });
    }

    public destroyWidget(): void {}

    private getSitRep(): void {
        if (!this.sitRepCommunityId) {
            console.warn('Have not found the Situation Report Community');
            return;
        }

        this.communityService.getCommunityById(this.sitRepCommunityId).subscribe((res) => {
            this.files = res.files.filter((f) => f.events.map((e) => e.id).includes(this.eventFilters.event.id));
            this.files.sort((a, b) => moment(b.uploadDate).valueOf() - moment(a.uploadDate).valueOf());

            this.paginator.length = this.files.length;
            this.paginator.firstPage();

            const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
            const endIndex = this.paginator.pageSize * (this.paginator.pageIndex + 1);
            this.showingFiles = this.files.slice(startIndex, endIndex);
        });
    }

    public pageFiles(pageEvent: PageEvent): void {
        const startIndex = pageEvent.pageIndex * pageEvent.pageSize;
        const endIndex = pageEvent.pageSize * (pageEvent.pageIndex + 1);

        this.showingFiles = this.files.slice(startIndex, endIndex);
    }

    public downloadFile(file: CommunityFile): void {
        const url = `/api/permission/community/${this.sitRepCommunityId}/downloadfile/${file.filename}`;
        const onError = () => {
            this.popup.open('There was and error downloading your file', 'Okay', {panelClass: 'dialog-failure'});
        };
        FileDownload.downloadDocument(url, file.filename, onError);
    }
}
