<div class="data-table">
    <div class="table-header">
        <p class="title">Data From Chart</p>
        <div class="action-icons">
            <mat-icon
                fontSet="fas"
                fontIcon="fa-download"
                inline
                matTooltip="Export Table"
                matTooltipPosition="above"
                (click)="exportTable()"></mat-icon>
            <mat-icon fontSet="fas" fontIcon="fa-times" inline (click)="close()"></mat-icon>
        </div>
    </div>

    <div>
        <div class="table-container">
            <table
                #materialTable
                mat-table
                [dataSource]="dataSource"
                matSort
                [matSortDisableClear]="true"
                [matSortActive]="defaultSort"
                [matSortDirection]="defaultSortDirection">
                <ng-container *ngFor="let column of columnDefs" [matColumnDef]="column.columnName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [class.no-click]="column.sortValue === undefined">
                        {{ column.displayName }}
                        <eaglei-table-sort-header
                            *ngIf="column.sortValue !== undefined"
                            [columnName]="column.columnName"
                            [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let info">
                        <span [style.color]="column.cellColor ? column.cellColor(info) : '#707070'">{{ column.getValue(info) }}</span>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
                <tr mat-row *matRowDef="let row; columns: columnNames; let odd = odd"></tr>
            </table>
        </div>
        <mat-paginator [pageSize]="10" [pageSizeOptions]="[25, 50, 75, 100]" showFirstLastButtons></mat-paginator>
    </div>
</div>
