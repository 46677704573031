import {AfterViewInit, Component, ElementRef, inject, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {EmailService} from '../../../app/services/email.service';
import {EmailData} from '../../../app/classes/email-data';
import {ApplicationConfig} from '../../../app/classes/application-config';
import {throttleTime} from 'rxjs/operators';
import {ModalConfig} from '../../../app/classes/modal-config';

interface DialogData {
    successText: string;
    failureText: string;
    header: string;
    sendMethod: (emailData: EmailData) => void;
}

@Component({
    selector: 'eaglei-bulk-email',
    templateUrl: './bulk-email.component.html',
    styleUrls: ['../shared-modals.scss', './bulk-email.component.scss'],
})
export class BulkEmailComponent {
    private landingService: EmailService = inject(EmailService);
    private popup: MatSnackBar = inject(MatSnackBar);
    private data: DialogData = inject(MAT_DIALOG_DATA);
    private readonly sendMethod: (emailData: EmailData) => void;

    public dialogRef: MatDialogRef<BulkEmailComponent> = inject(MatDialogRef);
    public buttonText: string = 'Send';
    public header = this.data?.header || 'E-mail All Users';
    public successText = this.data?.successText || 'Email Sent';
    public failedText = this.data?.failureText || 'Failed Sending Email';
    public subject = '';
    public body = '';
    public pending: boolean;

    constructor() {
        this.sendMethod = this.data?.sendMethod;
    }

    public sendEmailToAllUsers(): void {
        if (this.checkInput()) {
            if (this.sendMethod === undefined) {
                this.sendEmail();
            } else {
                const emailData = new EmailData();
                emailData.subject = this.subject;
                emailData.body = this.body;
                this.sendMethod(emailData);
            }
        } else {
            this.popup.open('Check required inputs', 'Close', {panelClass: ['failure']});
        }
    }

    private sendEmail(): void {
        const me = this;
        const currentText = this.buttonText;
        this.pending = true;
        this.buttonText = 'Emailing Users';

        this.landingService.sendEmail(this.subject, this.body).subscribe({
            next: () => {
                me.popup.open(me.successText, 'Close', {duration: 5000, panelClass: ['success']});
                me.dialogRef.close();
            },
            error: () => {
                me.popup.open(me.failedText, 'Close', {panelClass: ['failure']});
                this.buttonText = currentText;
                this.pending = false;
            },
        });
    }

    public checkInput(): boolean {
        return !!(this.subject && this.body);
    }
}
