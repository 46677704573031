
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

export class GenLayerFilterType {
    static readonly TEXT_SEARCH: GenLayerFilterType = new GenLayerFilterType('TEXT_SEARCH');
    static readonly MULTI_SELECT: GenLayerFilterType = new GenLayerFilterType('MULTI_SELECT');
    static readonly NUMERIC_RANGE: GenLayerFilterType = new GenLayerFilterType('NUMERIC_RANGE');
    static readonly SINGLE_SELECT: GenLayerFilterType = new GenLayerFilterType('SINGLE_SELECT');
    static readonly DATE: GenLayerFilterType = new GenLayerFilterType('DATE');
    static readonly DATE_RANGE: GenLayerFilterType = new GenLayerFilterType('DATE_RANGE');
    static readonly LOCATION_SELECTION: GenLayerFilterType = new GenLayerFilterType('LOCATION_SELECTION');

    public readonly name: string;

    private constructor(name: string) {
        this.name = name;
    }

    static forName(name: string) {
        if (name === 'TEXT_SEARCH') return GenLayerFilterType.TEXT_SEARCH;
        if (name === 'MULTI_SELECT') return GenLayerFilterType.MULTI_SELECT;
        if (name === 'NUMERIC_RANGE') return GenLayerFilterType.NUMERIC_RANGE;
        if (name === 'SINGLE_SELECT') return GenLayerFilterType.SINGLE_SELECT;
        if (name === 'DATE') return GenLayerFilterType.DATE;
        if (name === 'DATE_RANGE') return GenLayerFilterType.DATE_RANGE;
        if (name === 'LOCATION_SELECTION') return GenLayerFilterType.LOCATION_SELECTION;
        throw new Error('Unexpected value for GenLayerFilterType:' + name);
    }

    static values(): GenLayerFilterType[] {
        return [
            GenLayerFilterType.TEXT_SEARCH,
            GenLayerFilterType.MULTI_SELECT,
            GenLayerFilterType.NUMERIC_RANGE,
            GenLayerFilterType.SINGLE_SELECT,
            GenLayerFilterType.DATE,
            GenLayerFilterType.DATE_RANGE,
            GenLayerFilterType.LOCATION_SELECTION,
        ];
    }

    toJSON(): string {
        return this.name;
    }

    toString(): string {
        return this.name;
    }
}

