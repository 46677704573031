import {Component, signal} from '@angular/core';
import {GenLegendType} from 'frontend/generated/serverModels/GenLegendType';
import {GenOutageAggregationLevel} from 'frontend/generated/serverModels/GenOutageAggregationLevel';
import {ApplicationConfig} from 'frontend/src/app/classes/application-config';
import {HttpInterceptorService} from 'frontend/src/app/services/http-interceptor.service';
import {debounceTime, filter, map, tap} from 'rxjs/operators';
import {State} from '../../../outage/classes/state';
import {EagleILayer} from '../../classes/eagle-i-layer';
import {LayerService} from '../../services/layer.service';
import {EmPowerSource} from '../../sources/emPower-source';
import {FormControl, FormGroup} from '@angular/forms';
import {HssData} from '../../../report/classes/HssData';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Component({
    selector: 'eaglei-empower-layer',
    templateUrl: './emPowerLayer.component.html',
    styleUrls: ['./emPowerLayer.component.scss'],
})
export class EmPowerLayerComponent extends EagleILayer<EmPowerSource> {
    public readonly aggregationLevels = GenOutageAggregationLevel.values().filter(
        (l) => ![GenOutageAggregationLevel.fema, GenOutageAggregationLevel.utility].includes(l)
    );

    protected filterGroup = new FormGroup({
        aggregation: new FormControl<GenOutageAggregationLevel>(undefined),
        states: new FormControl<State[]>([]),
    });

    protected filtersExpanded: boolean;

    public readonly dependentLegend = [
        {zip: '0', county: '0', state: '24 - 20,553'},
        {zip: '1 - 133', county: '1 - 255', state: '20,554 - 45,704'},
        {zip: '134 - 237', county: '256 - 976', state: '45,705 - 77,199'},
        {zip: '238 - 396', county: '977 - 7,571', state: '77,200 - 122,538'},
        {zip: '397 - 1,366', county: '> 7,572', state: '> 122,539'},
    ];
    protected loadingMask$ = signal(false);

    constructor(private layerService: LayerService) {
        super();

        this.filterGroup.valueChanges
            .pipe(
                filter(() => this.isActive),
                debounceTime(100),
                takeUntilDestroyed()
            )
            .subscribe((value) => {
                const regionsSelected = new Set(value.states.map((state) => state.dotregion));
                const countyCheck = regionsSelected.size > 1 && value.aggregation === GenOutageAggregationLevel.county;
                const zipCheck = value.states.length > 1 && value.aggregation === GenOutageAggregationLevel.zip;

                this.loadingMask$.set(countyCheck || zipCheck);
                if (countyCheck || zipCheck) {
                    return;
                }

                this.getLayerData();
            });

        this.getUserPreferences();
    }

    afterViewInit(): void {
        this.mapLayer.legend.type = GenLegendType.HARD_CODED;
    }

    public toggleLayer(activeState: boolean): void {
        this.isActive = activeState;
        if (activeState) {
            if (!this.layerSource) {
                this.layerSource = new EmPowerSource(this.mapLayer);
            }

            this.filterGroup.updateValueAndValidity();
        } else {
            HttpInterceptorService.clearInterceptor(this.mapLayer.interceptorKey);
            this.layerSource.removeFromMap();
        }
    }

    private getLayerData(): void {
        const url = this.layerSource.buildUrl(this.filterGroup.value.aggregation, this.filterGroup.value.states);
        this.mapLayer.startLoading();
        HttpInterceptorService.clearInterceptor(this.mapLayer.interceptorKey);
        HttpInterceptorService.pendingRequests[this.mapLayer.interceptorKey] = this.layerService
            .getJsonFromUrl(url, true)
            .pipe(
                tap(() => {
                    HttpInterceptorService.deleteFromInterceptor(this.mapLayer.interceptorKey);
                    this.mapLayer.endLoading();
                }),
                map((fc) => {
                    return fc.features.map((feature: {properties: HssData}) => {
                        feature.properties = new HssData(feature.properties);
                        return feature;
                    });
                })
            )
            .subscribe((f) => {
                this.layerSource.processFeatures(f, this.filterGroup.value.aggregation);
                this.layerSource.changeOpacity(0.8);
                this.layerSource.removeFromMap();
                this.layerSource.addToMap();

                this.layerSource.fitToFeatures();
            });
    }

    private getUserPreferences(): void {
        const preferences = ApplicationConfig.currentUserPreferences.getValue();

        this.filterGroup.patchValue({
            aggregation: preferences.getOutageAggregationLevel(),
            states: preferences.getStates(),
        });
    }

    public filterStates(event: State[]): void {
        this.filterGroup.patchValue({
            states: event,
        });
    }
}
