export enum CustomPopover {
    ONG_REFINERIES,
    ONG_CRITICAL_NOTICES,
    ONG_NOMINATIONS,
    ONG_OIL_OUTAGES,
    ONG_GAS_BURN,
    SOCIAL_MEDIA,
    RRCC,
    PORT_STATUS,
    GAS_BUDDY_REPORT,
    OIL_BARREL_FLOW,
    SURVEY_123,
}
