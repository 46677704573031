import {Component} from '@angular/core';
import {GenLegendType} from 'frontend/generated/serverModels/GenLegendType';
import {HttpInterceptorService} from 'frontend/src/app/services/http-interceptor.service';
import {tap} from 'rxjs/operators';
import {EagleILayer} from '../../classes/eagle-i-layer';
import {CustomPopover} from '../../enum/custom-popover';
import {LayerService} from '../../services/layer.service';
import {OngOilOutageSource} from '../../sources/ong-oil-outage-source';
import {OngService} from '../../../../services/ong.service';
import {forkJoin} from 'rxjs';

@Component({
    selector: 'eaglei-ong-oil-outage-layer',
    templateUrl: './ong-oil-outage-layer.component.html',
    styleUrls: ['../layers.scss', './ong-oil-outage-layer.component.scss'],
})
export class OngOilOutageLayerComponent extends EagleILayer<OngOilOutageSource> {
    constructor(private layerService: LayerService, private ongService: OngService) {
        super();
    }

    afterViewInit(): void {
        this.mapLayer.customPopoverId = CustomPopover.ONG_OIL_OUTAGES;
        this.mapLayer.legend.type = GenLegendType.HARD_CODED;
    }

    public toggleLayer(activeState: boolean): void {
        if (activeState) {
            if (!this.layerSource) {
                this.layerSource = new OngOilOutageSource(this.mapLayer);
            }

            this.getOutages();
        } else {
            HttpInterceptorService.clearInterceptor(this.mapLayer.uiHandle);
            this.layerSource.removeFromMap();
        }
    }

    private getOutages(): void {
        const url = this.mapLayer.serviceurl;

        const apiCalls = [
            this.ongService.getOilPlannedOutages('past', undefined, undefined, undefined, true),
            this.ongService.getOilPlannedOutages('active', undefined, undefined, undefined, true),
            this.ongService.getOilPlannedOutages('future', undefined, undefined, undefined, true),
        ];

        this.mapLayer.startLoading();
        HttpInterceptorService.clearInterceptor(this.mapLayer.uiHandle);
        HttpInterceptorService.pendingRequests[this.mapLayer.uiHandle] = forkJoin(apiCalls)
            .pipe(
                tap(() => {
                    HttpInterceptorService.deleteFromInterceptor(this.mapLayer.uiHandle);
                    this.mapLayer.endLoading();
                })
            )
            .subscribe((res) => {
                const mergedFeatures = [...res[0].data.features, ...res[1].data.features, ...res[2].data.features];
                this.layerSource.processFeatures(mergedFeatures);
                this.layerSource.removeFromMap();
                this.layerSource.addToMap();
            });
    }
}
