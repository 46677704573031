import {GenStateCoverage} from '../../../../../generated/serverModels/GenStateCoverage';

export class StateCoverage extends GenStateCoverage {
    get percentage(): number {
        return this._percentage;
    }

    private readonly _percentage: number;
    public trend: -1 | 0 | 1 = 0;

    constructor(coverage?: StateCoverage) {
        super(coverage);

        this._percentage =
            this.eiaTotalCustomers === 0 || !this.coveredCustomersBestCase ? 0 : this.coveredCustomersBestCase / this.eiaTotalCustomers;
    }
}
