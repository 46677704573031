import {PercentPipe} from '@angular/common';
import {PopoverElement} from 'frontend/src/app/classes/popover-element';
import * as L from 'leaflet';
import {LeafletFeature} from '../classes/leaflet-feature';
import {LeafletMapLayer} from '../classes/leaflet-map-layer';
import {LeafletVectorSource} from '../classes/leaflet-vector-source';
import {GenPole} from '../../../../../generated/serverModels/GenPole';
import {LeafletEagleiSource} from '../classes/leaflet-eaglei-source';
import {ClusterSource} from '../classes/cluster-source';

export class PoleDetectionSource extends ClusterSource {
    private percentPipe: PercentPipe = new PercentPipe('en-US');

    constructor(layerInfo: LeafletMapLayer) {
        super(layerInfo);
    }

    processFeatures(features?: any): void {
        const leafletFeatures = features
            .map((f) => {
                const feature = new LeafletFeature<GenPole>().convert(f);

                const data = [
                    new PopoverElement().setTitle().setValue('Pole Detection'),
                    new PopoverElement()
                        .setLabel('Flight Date')
                        .setValue(LeafletEagleiSource.momentPipe.transform(feature.properties.flightDate)),
                    new PopoverElement().setLabel('Infrastructure').setValue(feature.properties.infrastructure),
                    new PopoverElement().setLabel('Type').setValue(feature.properties.type),
                    new PopoverElement().setLabel('Call Sign').setValue(feature.properties.callSign),
                    new PopoverElement().setLabel('Learning Model').setValue(feature.properties.learningModel),
                    new PopoverElement().setLabel('Status').setValue(feature.properties.damaged ? 'Damaged' : 'Undamaged'),
                    new PopoverElement()
                        .setLabel('Confidence')
                        .setValue(this.percentPipe.transform(feature.properties.confidence, '0.0-2')),
                    new PopoverElement().setLabel('Number of Detections').setValue(feature.properties?.detections?.toString()),
                ];

                feature.popoverData = data;
                feature.subFeatures = [
                    {
                        id: feature.properties.id,
                        popoverData: data,
                    },
                ];

                return feature;
            })
            .filter((f) => !!f);

        const featureStyle = (feature: LeafletFeature<any>) => {
            return this.getFeatureStyle(feature.properties);
        };

        const layerPopover = (f: LeafletFeature, l) => {
            this.initializePopoverInteractions(f, l, 'click', false, f.popoverData);
        };

        const config = {
            pointToLayer: featureStyle,
            onEachFeature: layerPopover,
            features: this.leafletFeatures,
        };

        const clusterConfig: L.MarkerClusterGroupOptions = {
            iconCreateFunction: getClusterStyle,
            chunkedLoading: true,
        };

        const cluster = new L.MarkerClusterGroup(clusterConfig);
        cluster.addLayer(L.geoJSON(leafletFeatures as any, config as any));

        this.source = cluster as any;
    }

    /**
     * Gets the Style of a feature to display on map
     * @param feature Feature to style
     * @returns Style of feature
     */
    private getFeatureStyle(feature: any): any {
        const type = feature.infrastructure === 'UTILITY_POLE' ? 'pole' : 'substation';
        const damaged = feature.damaged ? 'red' : 'green';

        return L.marker([feature.latitude, feature.longitude], {
            icon: L.icon({iconUrl: `/dist/images/pole-detection/${type}_circle_${damaged}.svg`, iconSize: [20, 20]}),
        });
    }
}

function getClusterStyle(cluster: L.MarkerCluster): any {
    const options = {
        icon: 'circle',
        backgroundColor: '#d95f0e',
        borderColor: '#d95f0e',
        innerIconStyle: `position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); margin-top: 0px; text`,
        iconSize: [30, 30],
        isAlphaNumericIcon: true,
        text: `${cluster.getChildCount()}`,
        textColor: 'white',
    };

    return (L as any).BeautifyIcon.icon(options);
}
