import * as moment from 'moment';

interface NominationLocation {
    country: string;
    county: string;
    location_id: number;
    meter: string;
    name: string;
    role: string;
    state: string;
}

export class Nomination {
    static fromApiResponse(response): Nomination[] {
        const allNominations: Nomination[] = [];
        const features: any[] = response.features;
        features.forEach((feature) => {
            const nominations = feature.properties.nominations.map((nomination) => {
                const nom = new Nomination(nomination);
                nom.boundary = JSON.stringify(feature.geometry);
                nom.location = {
                    country: feature.properties.country,
                    county: feature.properties.county,
                    location_id: feature.properties.location_id,
                    meter: feature.properties.meter,
                    name: feature.properties.name,
                    role: feature.properties.role,
                    state: feature.properties.state,
                };
                return nom;
            });
            allNominations.push(...nominations);
        });
        return allNominations;
    }

    // Properties pulled from location
    public location: NominationLocation;

    public available_capacity: number;
    public best_flow_location: boolean;
    public design_capacity: number;
    public gas_day: moment.Moment;
    public id: number;
    public location_role_id: number;
    public operational_capacity: number;
    public scheduled_capacity: number;
    public update_timestamp: moment.Moment;
    public utilization_capacity: number;
    public boundary: string;
    public pipeline_name: string;
    public facility: string;

    public utilization_status: 'low' | 'medium' | 'high' | 'unknown' = 'unknown';
    public overCapacity: boolean;

    constructor(nomination?: Nomination) {
        if (nomination) {
            this.available_capacity = nomination.available_capacity;
            this.best_flow_location = nomination.best_flow_location;
            this.design_capacity = nomination.design_capacity;
            this.gas_day = moment(nomination.gas_day);
            this.id = nomination.id;
            this.location_role_id = nomination.location_role_id;
            this.operational_capacity = nomination.operational_capacity;
            this.scheduled_capacity = nomination.scheduled_capacity;
            this.update_timestamp = moment(nomination.update_timestamp);
            this.utilization_capacity = nomination.utilization_capacity;
            this.overCapacity = this.overCapacity || nomination.scheduled_capacity > nomination.operational_capacity;
            this.pipeline_name = nomination.pipeline_name;
            this.facility = nomination.facility;

            if (!isNaN(nomination.utilization_capacity)) {
                const wholeNumberPercent = nomination.utilization_capacity * 100;

                if (wholeNumberPercent <= 50) {
                    this.utilization_status = 'low';
                } else if (wholeNumberPercent > 50 && wholeNumberPercent <= 74) {
                    this.utilization_status = 'medium';
                } else if (wholeNumberPercent > 74) {
                    this.utilization_status = 'high';
                }
            }
        }
    }
}
