
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import { GenRoleDefinition } from './GenRoleDefinition';
import * as moment from 'moment';

export class GenSource {

    id: number;
    active: boolean;
    apidomain: string;
    attributionUrl: string;
    description: string;
    lastTimestamp: number;
    lastUpdateDate: moment.Moment;
    roles: GenRoleDefinition[];
    source: string;
    sourceTypeId: number;

    constructor();
    constructor( jsonObj: any );
    constructor( genSource: GenSource);
    constructor( id: number );

    constructor( input?: GenSource|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            this.active = input.active; // boolean [boolean]
            this.apidomain = input.apidomain; // string [string]
            this.attributionUrl = input.attributionUrl; // string [string]
            this.description = input.description; // string [string]
            this.lastTimestamp = input.lastTimestamp; // int [number]
            if ( input.lastUpdateDate && typeof input.lastUpdateDate === 'string' ) {
                this.lastUpdateDate = moment(input.lastUpdateDate).utc();
            } else {
                this.lastUpdateDate = input.lastUpdateDate;
            }
            if ( input.roles && input.roles.map ) {
                this.roles = input.roles.map( ( x: GenRoleDefinition ) => {
                    return (typeof x === 'string') ? GenRoleDefinition.forName(<string> x) : <any> x; // GenRoleDefinition
                } );
            } else {
                this.roles = input.roles; // GenRoleDefinition
            }
            this.source = input.source; // string [string]
            this.sourceTypeId = input.sourceTypeId; // int [number]
       }
    }

    static jsonResponseToList(arr: any[]): GenSource[] {
        if (arr && arr.map) {
          return arr.map(a => new GenSource(a));
        } else {
          return [];
        }
    }
}

