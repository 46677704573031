import {AfterViewInit, Component, ElementRef, inject, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {EventSummaryFilters} from '../../../system-event/classes/event-summary-filters';
import {BaseWidget} from '../../classes/base-widget';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {Subject} from 'rxjs';
import {distinctUntilChanged, filter, map, switchMap, takeUntil} from 'rxjs/operators';
import {MatSort} from '@angular/material/sort';
import {FormControl, FormGroup} from '@angular/forms';
import {UrbannetService} from '../../../../../integrations/urbannet/services/urbannet.service';
import {femaLifelineTooltipInfo} from '../../../report/components/urbannet-report/fema-lifeline-tooltip-text';
import {GenUrbanNetFemaType} from '../../../../../../generated/serverModels/GenUrbanNetFemaType';
import {UrbannetDisruption} from '../../../../../integrations/urbannet/classes/urbannet-disruption';
import {ApplicationConfig} from '../../../../classes/application-config';
import {UrbannetReport} from '../../../../../integrations/urbannet/classes/urbannet-report';

@Component({
    selector: 'eaglei-urbannet-widget',
    templateUrl: './urbannet-widget.component.html',
    styleUrls: ['./urbannet-widget.component.scss'],
})
export class UrbanNetWidgetComponent extends BaseWidget implements AfterViewInit {
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatTable) table: MatTable<UrbannetReport>;

    private urbanNetService = inject(UrbannetService);
    private dataStream = new Subject<number>();
    private dataLoading = false;
    private get selectedTypes(): string[] {
        return this.filterForm.get('type').value;
    }
    private get filteredName(): string {
        return this.filterForm.get('name').value;
    }

    public disruptionTypes: string[] = [];
    public eventFilters: EventSummaryFilters = new EventSummaryFilters();
    public columns = [
        'name',
        'type',
        'total',
        'energy',
        'communications',
        'food_water_shelter',
        'safety_and_security',
        'health_and_medical',
        'transportation',
        'hazardous_material',
        'other',
    ];

    public dataSource = new MatTableDataSource<UrbannetDisruption>();
    public tooltipMap: {[key: string]: string} = femaLifelineTooltipInfo;
    public femaTypes = GenUrbanNetFemaType;
    public eventId!: number;
    public filterForm = new FormGroup({
        name: new FormControl<string>(''),
        type: new FormControl<string[]>([]),
    });

    reportControl = new FormControl<UrbannetReport>(undefined);
    reports = new Array<UrbannetReport>();

    constructor(private widgetElement: ElementRef) {
        super(widgetElement);
    }

    ngAfterViewInit() {
        this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.filterPredicate = (data: UrbannetDisruption, filterString: string): boolean => {
            if (!filterString) {
                return true;
            }
            let nameValid = true;
            if (this.filteredName) {
                nameValid = new RegExp(this.filteredName.trim().toLowerCase()).test(data.name.toLowerCase());
            }

            let typeValid = true;
            if (this.selectedTypes.length) {
                typeValid = this.selectedTypes.includes(data.type);
            }
            return nameValid && typeValid;
        };

        this.filterForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(({name, type}) => {
            // trigger it this way to pass internal distinct checks
            this.dataSource.filter = type.join(',') + name;
        });

        this.reportControl.valueChanges
            .pipe(
                filter((report) => !!report),
                switchMap((report: UrbannetReport) => {
                    return this.urbanNetService.getDisruptions(report.id).pipe(
                        map((disruptions: UrbannetDisruption[]) => {
                            report.disruptions = disruptions;
                            return report;
                        })
                    );
                }),
                takeUntil(this.destroy$)
            )
            .subscribe((report) => this.setControls(report));

        this.dataStream
            .pipe(
                distinctUntilChanged(),
                switchMap((eventId) => {
                    this.dataSource.data = [];
                    this.dataLoading = true;
                    this.eventId = eventId;
                    return this.urbanNetService.getReportsByEvent(eventId);
                }),
                takeUntil(this.destroy$)
            )
            .subscribe((reports) => {
                this.reports = reports;
                this.reportControl.setValue(reports[0]);
            });
    }

    /**
     * Handles setting up the table data & filter values.
     * @param report The current report from which to extract values
     */
    private setControls(report?: UrbannetReport) {
        const disruptions = report?.disruptions?.map((disruption) => new UrbannetDisruption(disruption)) ?? [];

        if (disruptions) {
            this.disruptionTypes = Array.from(new Set(disruptions.map((disruption) => disruption.type)).values()).sort();
        }

        if (this.dataSource) {
            this.dataSource.data = disruptions;
        } else {
            this.dataSource = new MatTableDataSource<UrbannetDisruption>(disruptions);
            this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
        }

        // Update the filter forms based on if there are any disruptions
        this.filterForm.reset({
            type: Array.from(new Set(disruptions.map((d) => d.type))),
            name: '',
        });

        if (disruptions.length) {
            this.filterForm.enable();
        } else {
            this.filterForm.disable();
        }
    }

    private sortingDataAccessor(data: UrbannetDisruption, columnName: string) {
        switch (columnName) {
            case 'name':
                return data.name.toLowerCase();
            case 'type':
                return data.type;
            case 'energy':
                return data.counts[GenUrbanNetFemaType.ENERGY.toString()];
            case 'communications':
                return data.counts[GenUrbanNetFemaType.COMMUNICATIONS.toString()];
            case 'food_water_shelter':
                return data.counts[GenUrbanNetFemaType.FOOD_WATER_SHELTER.toString()];
            case 'safety_and_security':
                return data.counts[GenUrbanNetFemaType.SAFETY_AND_SECURITY.toString()];
            case 'health_and_medical':
                return data.counts[GenUrbanNetFemaType.HEALTH_AND_MEDICAL.toString()];
            case 'transportation':
                return data.counts[GenUrbanNetFemaType.TRANSPORTATION.toString()];
            case 'hazardous_material':
                return data.counts[GenUrbanNetFemaType.HAZARDOUS_MATERIAL.toString()];
            case 'other':
                return data.counts[GenUrbanNetFemaType.OTHER.toString()];
            case 'total':
                return data.counts['TOTAL'];
        }
    }

    protected handleEventFilterChange(eventFilters: EventSummaryFilters): void {
        this.eventFilters = eventFilters;

        if (eventFilters.event) {
            this.dataStream.next(eventFilters.event.id);
        }
    }

    public isEllipsis(element: HTMLElement): boolean {
        return ApplicationConfig.hasEllipsis(element);
    }

    public destroyWidget(): void {}
}
