import {AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {GenOutageAggregationLevel} from 'frontend/generated/serverModels/GenOutageAggregationLevel';
import {GenWidgetSettingType} from 'frontend/generated/serverModels/GenWidgetSettingType';
import {ApplicationConfig} from 'frontend/src/app/classes/application-config';
import {ModalConfig} from 'frontend/src/app/classes/modal-config';
import {throttleTime} from 'rxjs/operators';
import {WidgetSetting} from '../../classes/widget-setting';
import {LandingGridItem} from '../../../../landing/classes/landing-grid-item';

@Component({
    selector: 'eaglei-configure-widget-summary-modal',
    templateUrl: './configure-widget-summary-modal.component.html',
    styleUrls: ['./configure-widget-summary-modal.component.scss'],
})
export class ConfigureWidgetSummaryModalComponent implements OnInit, AfterViewInit {
    // HTML Selector
    @ViewChild('content', {static: true}) content: ElementRef;

    // Aggregation Levels List
    public aggregationLevels = GenOutageAggregationLevel.values().filter(
        (l) => ![GenOutageAggregationLevel.fema, GenOutageAggregationLevel.zip].includes(l)
    );

    // Selected Option
    // TODO: Update how the default configure option is set
    public selectedAggregation: 'state' | 'county' | 'utility' = 'state';

    constructor(
        public dialogRef: MatDialogRef<ConfigureWidgetSummaryModalComponent>,
        @Inject(MAT_DIALOG_DATA) private item: LandingGridItem
    ) {}

    public ngOnInit(): void {
        if (!!this.item.detail.settings.find((s) => s.type === GenWidgetSettingType.AGGREGATION_LEVEL)) {
            this.selectedAggregation = this.item.detail.settings.find((s) => s.type === GenWidgetSettingType.AGGREGATION_LEVEL)
                .value as any;
        }
    }

    public ngAfterViewInit(): void {
        ModalConfig.setModalHeight(this.content);
        ApplicationConfig.resizeEvent.pipe(throttleTime(250)).subscribe(() => ModalConfig.setModalHeight(this.content));
    }
    /**
     * Updates the selected level of aggregation
     * @param level Aggregation Level user selected
     */
    public changeAggregationLevel(level: GenOutageAggregationLevel): void {
        this.selectedAggregation = level.name as any;
    }

    /**
     * Disables the update button if no valid aggregation has been selected
     */
    public disableUpdate(): boolean {
        return !this.aggregationLevels.map((l) => l.name).includes(this.selectedAggregation);
    }

    /**
     * Updates the Widget with the selected setting options
     */
    public configureWidget(): void {
        if (this.item.detail.settings.find((s) => s.type === GenWidgetSettingType.AGGREGATION_LEVEL)) {
            this.item.detail.settings.find((s) => s.type === GenWidgetSettingType.AGGREGATION_LEVEL).value = this.selectedAggregation;
        } else {
            const setting = new WidgetSetting({
                type: GenWidgetSettingType.AGGREGATION_LEVEL,
                value: this.selectedAggregation,
            } as any);
            this.item.detail.settings.push(setting);
        }

        this.dialogRef.close(this.item);
    }
}
