import {ChangeDetectorRef, Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {AbstractControl, UntypedFormControl, ValidatorFn, Validators} from '@angular/forms';
import {MatAccordion} from '@angular/material/expansion';
import {MatDialog} from '@angular/material/dialog';
import {MatSidenav} from '@angular/material/sidenav';
import {MatSnackBar} from '@angular/material/snack-bar';
import {filter} from 'rxjs/operators';
import {GenRoleDefinition} from '../../../../../../generated/serverModels/GenRoleDefinition';
import {AutocompleteComponent} from '../../../../../shared/components/autocomplete/autocomplete.component';
import {ConfirmationComponent} from '../../../../../shared/modals/confirmation/confirmation.component';
import {RoleDefinitionPipe} from '../../../../../shared/pipes/role-definition.pipe';
import {SidebarService} from '../../../map/services/sidebar.service';
import {MapLayerGroup} from '../../classes/map-layer-group';
import {LayerService} from '../../services/layer.service';
import {CommunityService} from '../../../community/services/community.service';
import {Community} from '../../../community/classes/community';
import {LeafletMapLayer} from '../../classes/leaflet-map-layer';

@Component({
    selector: 'eaglei-layer-management',
    templateUrl: './layer-management.component.html',
    styleUrls: ['./layer-management.component.scss'],
})
export class LayerManagementComponent implements OnInit {
    // DOM Elements
    @ViewChild('edit', {static: true}) edit: MatSidenav;
    private autocompleteRoles: AutocompleteComponent;

    @ViewChild('autocompleteRoles', {static: false}) set setAutocompleteRoles(auto: AutocompleteComponent) {
        if (!auto) {
            return;
        }
        this.autocompleteRoles = auto;

        this.autocompleteRoles.displayFunction = this.displayRoleAutoComplete.bind(this);
        this.autocompleteRoles.filterFunction = this.filterRoleAutoComplete.bind(this);
        this.autocompleteRoles.selectedElements = this.tmpLayer.roles;
    }

    private autocompleteCommunities: AutocompleteComponent;

    @ViewChild('autocompleteCommunities', {static: false}) set setAutocompleteCommunities(auto: AutocompleteComponent) {
        if (!auto) {
            return;
        }
        this.autocompleteCommunities = auto;

        this.autocompleteCommunities.displayFunction = this.displayCommunityAutoComplete.bind(this);
        this.autocompleteCommunities.filterFunction = this.filterCommunityAutoComplete.bind(this);
        this.autocompleteCommunities.selectedElements = this.communities.filter((comm) =>
            this.tmpLayer.dataAccess?.includes(comm.dataAccessHandle)
        );
    }

    @ViewChildren(MatAccordion) groupList: QueryList<MatAccordion>;

    // Layer Arrays Properties
    public layerGroups: MapLayerGroup[] = [];
    public filteredGroups: MapLayerGroup[] = [];
    public activeLayerGroups: MapLayerGroup[] = [];

    // Role Properties
    private readonly rolePipe = new RoleDefinitionPipe();
    public roles: GenRoleDefinition[] = GenRoleDefinition.values().filter((role) => {
        return [GenRoleDefinition.ROLE_RESETTING_PASSWORD, GenRoleDefinition.ROLE_APPROVED_USER].indexOf(role) === -1;
    });
    public tmpLayer: LeafletMapLayer = new LeafletMapLayer();
    public selectedGroup: MapLayerGroup;
    public tmpLayerGroupId: number;
    public areGroupsExpanded = true;
    private searchString: string = '';
    public communities: Community[] = [];

    private manageCategories: boolean = false;

    private layerNames: string[] = [];
    private selectedLayerName: string;

    public layerNameControl: UntypedFormControl;
    public attributionUrlControl: UntypedFormControl;
    filteredLayers: LeafletMapLayer[] = [];

    constructor(
        private layerService: LayerService,
        private communityService: CommunityService,
        private sidebarService: SidebarService,
        private popup: MatSnackBar,
        private dialog: MatDialog,
        private cd: ChangeDetectorRef
    ) {
        this.layerNameControl = new UntypedFormControl('', [Validators.required, Validators.maxLength(120), this.layerNameValidator()]);

        this.attributionUrlControl = new UntypedFormControl('', [
            Validators.required,
            this.patternValidator(new RegExp('^(http://|https://).*', 'i')),
        ]);
    }

    public ngOnInit(): void {
        this.layerService.getLayerGroups(false).subscribe((res) => {
            this.layerGroups = res
                .filter((lg) => !['baseLayer', 'imported'].includes(lg.uiHandle))
                .sort((a, b) => (a.ordering > b.ordering ? 1 : -1));

            this.layerGroups.forEach((group, i) => {
                group.ordering = i + 1;
                group.layers.forEach((layer, j) => (layer.ordering = j + 1));
            });
            this.getActiveGroups();
        });

        this.communityService.getAllCommunities().subscribe((res) => {
            this.communities = res;
        });

        if (this.autocompleteRoles) {
            this.autocompleteRoles.displayFunction = this.displayRoleAutoComplete.bind(this);
            this.autocompleteRoles.filterFunction = this.filterRoleAutoComplete.bind(this);
        }

        if (this.autocompleteCommunities) {
            this.autocompleteCommunities.displayFunction = this.displayCommunityAutoComplete.bind(this);
            this.autocompleteCommunities.filterFunction = this.filterCommunityAutoComplete.bind(this);
        }

        this.roles.sort();
    }

    /**
     * Displays Method for autocomplete
     * @param role Role to display
     */
    private displayRoleAutoComplete(role: GenRoleDefinition): string {
        return role ? this.rolePipe.transform(role) : '';
    }

    /**
     * Filter method for autocomplete
     * @param role Role to check for filter
     * @param textFilter search string
     */
    private filterRoleAutoComplete(role: GenRoleDefinition, textFilter: string): boolean {
        return this.rolePipe.transform(role).toLowerCase().includes(textFilter.toLowerCase());
    }

    /**
     * Displays Method for autocomplete
     * @param community Community to display
     */
    public displayCommunityAutoComplete(community: Community): string {
        return community ? community.name : '';
    }

    /**
     * Filter method for autocomplete
     * @param community Community to check for filter
     * @param textFilter search string
     */
    public filterCommunityAutoComplete(community: Community, textFilter: string): boolean {
        return community.name.toLowerCase().includes(textFilter.toLowerCase());
    }

    /**
     * Sets active layer groups
     */
    private getActiveGroups(): void {
        this.activeLayerGroups = this.layerGroups
            .filter((group) => {
                return group.layers.length > 0;
            })
            .sort((a, b) => (a.ordering > b.ordering ? 1 : -1));
    }

    /**
     * Searches layers
     * @param search Search string
     */
    public searchLayers(search: string): void {
        this.searchString = search.toLowerCase();
        this.filteredGroups = this.layerGroups.filter((g) => g.displayName.toLowerCase().includes(search.toLowerCase()));
    }

    /**
     * Filters layers in a given group
     * @param group Group to filter
     */
    public filteredGroup(group: MapLayerGroup): LeafletMapLayer[] {
        return group.layers.filter((l) => l.displayName.toLowerCase().includes(this.searchString.toLowerCase()));
    }

    /**
     * Opens drawer to edit a given layer
     * @param layer Layer to edit
     * @param group Group of layer being edited
     */
    public editLayer(layer: LeafletMapLayer, group: MapLayerGroup) {
        this.manageCategories = false;
        this.cd.detectChanges();

        this.selectedLayerName = layer.displayName.toLowerCase();
        this.tmpLayer = new LeafletMapLayer(layer);
        this.tmpLayer.attributionTitle = this.tmpLayer.attributionTitle?.replace(/&trade;/g, '{TRADEMARK}');

        this.selectedGroup = group;
        this.tmpLayerGroupId = group.id;

        this.autocompleteCommunities.selectedElements = this.communities.filter((comm) =>
            this.tmpLayer.dataAccess?.includes(comm.dataAccessHandle)
        );
        this.autocompleteRoles.selectedElements = this.tmpLayer.roles;

        this.edit.toggle().then(() => (this.layerNames = this.getAllLayerNames()));

        this.layerNameControl.markAsTouched();
        this.attributionUrlControl.markAsTouched();
        this.layerNameControl.updateValueAndValidity();
        this.attributionUrlControl.updateValueAndValidity();
    }

    /**
     * Updates altered layer
     */
    public updateLayer(): void {
        this.tmpLayer.roles = this.autocompleteRoles.selectedElements;
        this.tmpLayer.dataAccess = this.autocompleteCommunities.selectedElements.map((comm) => comm.dataAccessHandle);
        this.tmpLayer.layerGroupId = this.selectedGroup.id;
        this.tmpLayer.attributionTitle = this.tmpLayer.attributionTitle.replace(/{TRADEMARK}/g, '&trade;');

        // We are setting it to undefined so we do not update the legend, since we do not allow legend manipulation from the layer management
        this.tmpLayer.legend = undefined;

        this.layerService.updateMapLayer(this.tmpLayer).subscribe(
            () => {
                this.popup.open('Layer Updated Successfully', '', {duration: 1000, panelClass: ['success']});
                this.updateLayerGroup(true);
                this.edit.toggle().then(() => (this.selectedLayerName = undefined as any));
            },
            (error: string) => {
                console.error(error);
                this.popup.open('Layer Failed to Update', 'OK', {panelClass: ['failure']});
            }
        );
    }

    /**
     * Deletes layer after confirmation
     */
    public deleteLayer(): void {
        this.dialog
            .open(ConfirmationComponent, {data: {message: 'Are you sure you want to Delete this Layer?'}})
            .afterClosed()
            .pipe(filter((choice) => !!choice))
            .subscribe(() => {
                this.layerService.deleteMapLayer(this.tmpLayer).subscribe(
                    () => {
                        this.popup.open('Layer Deleted Successfully', '', {duration: 1000, panelClass: ['success']});
                        this.updateLayerGroup(false);
                        this.edit.toggle().then(() => (this.selectedLayerName = undefined as any));
                    },
                    (error: string) => {
                        console.error(error);
                        this.popup.open('Layer Failed to Delete', 'OK', {panelClass: ['failure']});
                    }
                );
            });
    }

    /**
     * Updates the layer group
     * @param replace should the group be replaced
     */
    public updateLayerGroup(replace: boolean): void {
        // Change the Layer Group
        if (this.tmpLayer.layerGroupId !== this.tmpLayerGroupId) {
            const prevGroupIndex = this.layerGroups.findIndex((group) => group.id === this.tmpLayerGroupId);
            const changeLayerIndex = this.layerGroups[prevGroupIndex].layers.findIndex((layer) => layer.id === this.tmpLayer.id);
            const newGroupIndex = this.layerGroups.findIndex((group) => group.id === this.tmpLayer.layerGroupId);

            this.layerGroups[prevGroupIndex].layers.splice(changeLayerIndex, 1);

            // Updating ordering of both groups
            this.layerGroups[prevGroupIndex].layers.forEach((layer, i) => (layer.ordering = i + 1));

            this.tmpLayer.ordering = this.layerGroups[newGroupIndex].layers.length + 1;

            this.layerGroups[newGroupIndex].layers.push(this.tmpLayer);
        }

        const groupIndex = this.layerGroups.findIndex((group) => group.id === this.tmpLayer.layerGroupId);
        const layerIndex = this.layerGroups[groupIndex].layers.findIndex((layer) => layer.id === this.tmpLayer.id);
        replace
            ? this.layerGroups[groupIndex].layers.splice(layerIndex, 1, this.tmpLayer)
            : this.layerGroups[groupIndex].layers.splice(layerIndex, 1);

        this.getActiveGroups();
    }

    /**
     * Moves layer
     * @param event triggered event
     * @param group Group of layer to move
     * @param layer Layer to be moved
     * @param direction Direction of move
     */
    public moveLayer(event: MouseEvent, group: MapLayerGroup, layer: LeafletMapLayer, direction: 'up' | 'down') {
        event.stopPropagation();

        const current = layer.ordering;
        const layerToChange = group.layers[group.layers.findIndex((l) => l.id === layer.id) + (direction === 'up' ? -1 : 1)];
        const newOrder = layerToChange.ordering;

        layerToChange.ordering = current;
        layer.ordering = newOrder;

        layer.orderingUpdated = layerToChange.orderingUpdated = true;

        group.layers.sort((a, b) => {
            if (a.ordering > b.ordering) {
                return 1;
            } else if (a.ordering < b.ordering) {
                return -1;
            } else {
                return 0;
            }
        });
    }

    /**
     * Moves group
     * @param group Group to move
     * @param direction Direction of move
     * @param event triggered event
     */
    public moveGroup(group: MapLayerGroup, direction: 'up' | 'down', event: MouseEvent): void {
        event.stopPropagation();
        const newOrdering = group.ordering + (direction === 'up' ? -1 : 1);
        const currentOrder = group.ordering;
        const swapped = this.activeLayerGroups.find((g) => g.ordering === newOrdering) as MapLayerGroup;

        swapped.ordering = currentOrder;
        group.ordering = newOrdering;

        group.orderChanged = swapped.orderChanged = true;

        this.layerGroups.sort((a, b) => (a.ordering > b.ordering ? 1 : -1));
    }

    /**
     * Saves layer and group ordering
     */
    public saveOrdering(): void {
        const groupsToUpdate = this.layerGroups.filter((group) => {
            const groupChanged = group.orderChanged;
            const layerChanged = group.layers.some((layer) => layer.orderingUpdated);
            return groupChanged || layerChanged;
        });

        this.sidebarService.updateOrder(groupsToUpdate).subscribe(
            () => {
                this.popup.open('Order Updated.', '', {duration: 1000, panelClass: 'success'});
            },
            (error) => {
                this.popup.open('Order Failed To Updated.', '', {duration: 1000, panelClass: 'failure'});
                throw error;
            }
        );
    }

    /**
     * Gets all layer names
     */
    private getAllLayerNames(): string[] {
        return this.layerGroups
            .map((group) => {
                return group.layers.map((layer) => layer.displayName.toLowerCase());
            })
            .reduce((prev, cur) => prev.concat(cur), []);
    }

    /**
     * Validator for layer names
     */
    private layerNameValidator(): ValidatorFn {
        return (control: AbstractControl) => {
            if (!control.value || control.value.toLowerCase() === this.selectedLayerName) {
                return null;
            }

            const name = (control.value as string).trim().toLowerCase();
            if (name.length === 0) {
                return {onlySpace: 'Layer name can not only be a space'};
            } else if (this.layerNames.indexOf(name) !== -1) {
                return {nameExists: 'Layer name already exists'};
            }
            return null;
        };
    }

    /**
     * Validator for a given pattern
     * @param regex Pattern to look for
     */
    private patternValidator(regex: RegExp): ValidatorFn {
        return (control: AbstractControl) => {
            if (!control.value) {
                return null;
            }
            if (!regex.test(control.value)) {
                return {pattern: 'URL must begin with http:// or https://'};
            }
            return null;
        };
    }

    /**
     * Checks if layer is enabled
     * @param layer Layer to check
     */
    public isEnabled(layer: LeafletMapLayer): boolean {
        return layer.active;
    }

    /**
     * Toggles all group expansion
     */
    public toggleExpansion(): void {
        this.areGroupsExpanded = !this.areGroupsExpanded;

        this.groupList.forEach((gl) => (this.areGroupsExpanded ? gl.openAll() : gl.closeAll()));
    }
}
