import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ReportRoutingModule} from './report-routing.module';
import {ReportManagementComponent} from './components/report-management/report-management.component';
import {SharedModule} from '../../../shared/shared.module';
import {ReportListComponent} from './components/report-list/report-list.component';
import {UserReportComponent} from './components/user-report/user-report.component';
import {CoverageReportComponent} from './components/coverage-report/coverage-report.component';
import {ApiKeyReportComponent} from './components/api-key-report/api-key-report.component';
import {StateCoverageReportComponent} from './components/state-coverage-report/state-coverage-report.component';
import {AlertFeedReportComponent} from './components/alert-feed-report/alert-feed-report.component';
import {AlertSubscriptionReportComponent} from './components/alert-subscription-report/alert-subscription-report.component';
import {AlertModule} from '../alert/alert.module';
import {NaturalGasReportComponent} from './components/natural-gas-report/natural-gas-report.component';
import {OeReportComponent} from './components/oe-report/oe-report.component';
import {IsoNoticesReportComponent} from './components/iso-notices-report/iso-notices-report.component';
import {RecentEventsModule} from '../recent-events/recent-events.module';
import {ReportService} from './services/report.service';
import {EcootReportComponent} from './components/ecoot-report/ecoot-report.component';
import {MapModule} from '../map/map.module';
import {OutageReportComponent} from './components/outage-report/outage-report.component';
import {UtilityRepeatedOutageReportComponent} from './components/utility-repeated-outage-report/utility-repeated-outage-report.component';
import {UtilityIntegrityReportComponent} from './components/utility-integrity-report/utility-integrity-report.component';
import {UtilityErrorCountReportComponent} from './components/utility-error-count-report/utility-error-count-report.component';
import {UtilityErrorModalComponent} from './modals/utility-error-modal/utility-error-modal.component';
import {UtilityLinkModalComponent} from './modals/utility-link-modal/utility-link-modal.component';
import {OutageComparisonReportComponent} from './components/outage-comparison-report/outage-comparison-report.component';
import {OutageComparisonModalComponent} from './modals/outage-comparison-modal/outage-comparison-modal.component';
import {LayerModule} from '../layer/layer.module';
import {StateCoverageModalComponent} from './modals/state-coverage-modal/state-coverage-modal.component';
import {RestorationReportComponent} from './components/restoration-report/restoration-report.component';
import {LiteModule} from '../lite/lite.module';
import {DesignModuleModule} from 'frontend/src/design-module/design-module.module';
import {RestorationInfoModalComponent} from './modals/restoration-info-modal/restoration-info-modal.component';
import {OutageSnapshotReportComponent} from './components/outage-snapshot-report/outage-snapshot-report.component';
import {WidgetModule} from '../widget/widget.module';
import {RefineryStatusReportComponent} from './components/refinery-status-report/refinery-status-report.component';
import {HeadoutReportComponent} from './components/headout-report/headout-report.component';
import {PortStatusReportComponent} from './components/port-status-report/port-status-report.component';
import {NominationReportComponent} from './components/nomination-report/nomination-report.component';
import {MaintenanceEventReportComponent} from './components/maintenance-event-report/maintenance-event-report.component';
import {MaintenanceInfoModalComponent} from './modals/maintenance-info-modal/maintenance-info-modal.component';
import {EmpowerReportComponent} from './components/empower-report/empower-report.component';
import {UrbannetReportComponent} from './components/urbannet-report/urbannet-report.component';
import {UrbannetMetadataModalComponent} from './modals/urbannet-metadata-modal/urbannet-metadata-modal.component';
import {AffectedAssetTableComponent} from '../../integrations/urbannet/components/affected-asset-table/affected-asset-table.component';
import {UrbannetAssetInfoModalComponent} from '../../integrations/urbannet/modals/urbannet-asset-info-modal/urbannet-asset-info-modal.component';
import {OngModule} from '../../../integrations/ong/ong.module';
import {PoleDetectionReportComponent} from './components/pole-detection-report/pole-detection-report.component';
import {OilPipelineAlertsReportComponent} from './components/oil-pipeline-alerts-report/oil-pipeline-alerts-report.component';
import {WaoReportComponent} from './components/wao-report/wao-report.component';
import {RefineryIntelligenceReportComponent} from './components/refinery-intel-report/refinery-intelligence-report.component';
import {DieselStockReportComponent} from './components/diesel-stock-report/diesel-stock-report.component';
import {NomChartComponent} from '../../../shared/components/nom-chart/nom-chart.component';
import {MatSliderModule} from '@angular/material/slider';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
    declarations: [
        ReportManagementComponent,
        ReportListComponent,
        UserReportComponent,
        CoverageReportComponent,
        ApiKeyReportComponent,
        StateCoverageReportComponent,
        AlertFeedReportComponent,
        AlertSubscriptionReportComponent,
        NaturalGasReportComponent,
        OeReportComponent,
        IsoNoticesReportComponent,
        EcootReportComponent,
        OutageReportComponent,
        UtilityRepeatedOutageReportComponent,
        UtilityIntegrityReportComponent,
        UtilityErrorCountReportComponent,
        UtilityErrorModalComponent,
        UtilityLinkModalComponent,
        OutageComparisonReportComponent,
        OutageComparisonModalComponent,
        StateCoverageModalComponent,
        RestorationReportComponent,
        RestorationInfoModalComponent,
        OutageSnapshotReportComponent,
        RefineryStatusReportComponent,
        PortStatusReportComponent,
        HeadoutReportComponent,
        NominationReportComponent,
        HeadoutReportComponent,
        MaintenanceEventReportComponent,
        MaintenanceInfoModalComponent,
        EmpowerReportComponent,
        UrbannetReportComponent,
        UrbannetMetadataModalComponent,
        AffectedAssetTableComponent,
        UrbannetAssetInfoModalComponent,
        PoleDetectionReportComponent,
        OilPipelineAlertsReportComponent,
        WaoReportComponent,
        RefineryIntelligenceReportComponent,
        DieselStockReportComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        ReportRoutingModule,
        AlertModule,
        RecentEventsModule,
        MapModule,
        LiteModule,
        DesignModuleModule,
        LayerModule,
        WidgetModule,
        OngModule,
        NomChartComponent,
        MatSliderModule,
        MatIconModule,
    ],
    providers: [ReportService],
    exports: [UserReportComponent],
})
export class ReportModule {}
