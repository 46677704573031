import {Component} from '@angular/core';
import {GenLegendType} from 'frontend/generated/serverModels/GenLegendType';
import {HttpInterceptorService} from 'frontend/src/app/services/http-interceptor.service';
import {tap} from 'rxjs/operators';
import {WidgetService} from '../../../widget/services/widget.service';
import {EagleILayer} from '../../classes/eagle-i-layer';
import {CustomPopover} from '../../enum/custom-popover';
import {OilBarrelFlowSource} from '../../sources/oil-barrel-flow-source';
import {OngService} from '../../../../services/ong.service';

@Component({
    selector: 'eaglei-oil-barrel-flow',
    templateUrl: './oil-barrel-flow.component.html',
    styleUrls: ['../layers.scss', './oil-barrel-flow.component.scss'],
})
export class OilBarrelFlowComponent extends EagleILayer<OilBarrelFlowSource> {
    constructor(private widgetService: WidgetService, private ongService: OngService) {
        super();
    }

    afterViewInit(): void {
        this.mapLayer.customPopoverId = CustomPopover.OIL_BARREL_FLOW;
        this.mapLayer.legend.type = GenLegendType.HARD_CODED;

        // TODO: Update in database when updating more than just the name
        this.mapLayer.displayName = 'Oil Barrel Daily Flows';
    }

    public toggleLayer(activeState: boolean): void {
        if (activeState) {
            if (!this.layerSource) {
                this.layerSource = new OilBarrelFlowSource(this.mapLayer);
            }

            this.getData();
        } else {
            HttpInterceptorService.clearInterceptor(this.mapLayer.uiHandle);
            this.layerSource.removeFromMap();
        }
    }

    private getData(): void {
        this.mapLayer.startLoading();
        HttpInterceptorService.clearInterceptor(this.mapLayer.uiHandle);
        HttpInterceptorService.pendingRequests[this.mapLayer.uiHandle] = this.ongService
            .getOilBarrelFlow()
            .pipe(
                tap(() => {
                    HttpInterceptorService.deleteFromInterceptor(this.mapLayer.uiHandle);
                    this.mapLayer.endLoading();
                })
            )
            .subscribe((res) => {
                const features = res.features.filter((f) => !!f.geometry);
                this.layerSource.processFeatures(features);
                this.layerSource.removeFromMap();
                this.layerSource.addToMap();
            });
    }
}
