import {Component} from '@angular/core';
import {EagleILayer} from '../../classes/eagle-i-layer';
import {SocialMediaSource} from '../../sources/social-media-source';
import {LayerService} from '../../services/layer.service';
import {tap} from 'rxjs/operators';
import {CustomPopover} from '../../enum/custom-popover';
import {State} from '../../../outage/classes/state';
import {ApplicationConfig} from '../../../../classes/application-config';
import {MapService} from '../../../map/services/map.service';
import {LeafletLegendService} from '../../services/leaflet-legend.service';
import {PlanetsenseLocation} from '../../classes/planetsense-location';

@Component({
    selector: 'eaglei-social-media-layer',
    templateUrl: './social-media-layer.component.html',
    styleUrls: ['./social-media-layer.component.scss'],
})
export class SocialMediaLayerComponent extends EagleILayer<SocialMediaSource> {
    public filtersExpanded: boolean;
    public errorMessage: string;

    constructor(private layerService: LayerService, private mapService: MapService, private legendService: LeafletLegendService) {
        super();
    }

    afterViewInit(): void {
        this.mapLayer.customPopoverId = CustomPopover.SOCIAL_MEDIA;
    }

    toggleLayer(activeState: boolean, zoomToFeatures?: boolean | undefined): void {
        this.isActive = activeState;
        if (activeState) {
            this.legendService.getLegend(this).subscribe((legend) => (this.legend = legend));
            this.getTweets();
        } else {
            this.layerSource.removeFromMap();
        }
    }

    private getTweets(): void {
        const dates = {
            start: this.mapService.mapDateRange.getValue().startDate.local(),
            end: this.mapService.mapDateRange.getValue().endDate.local(),
        };

        const url = `${this.mapLayer.serviceurl}?start=${dates.start.format()}&end=${dates.end.format()}`;

        this.layerService
            .getJsonFromUrl(url)
            .pipe(
                tap(() => {
                    if (!this.layerSource) {
                        this.layerSource = new SocialMediaSource(this.mapLayer);
                        this.filterStates(ApplicationConfig.currentUserPreferences.getValue().getStates());
                    }
                })
            )
            .subscribe((res: PlanetsenseLocation[]) => {
                if (res.length === 0) {
                    this.errorMessage = `No social media posts found for ${dates.start.format('M/D/YYYY hh:mm A')} - ${dates.end.format(
                        'M/D/YYYY hh:mm A'
                    )}`;
                } else if (this.errorMessage && res.length !== 0) {
                    this.errorMessage = undefined;
                }

                this.layerSource.processFeatures(res);
                this.layerSource.addToMap();
            });
    }

    public filterStates(states: State[]): void {
        this.layerSource.updateLocationFilter(states);
    }
}
