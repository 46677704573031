<form [formGroup]="filterForm" minMax>
    <div class="layer-filter-title">{{ title }}</div>
    <div class="input-container">
        <mat-form-field appearance="outline" [class.active]="isMinActive">
            <mat-label>Min</mat-label>
            <input
                matInput
                formControlName="minValue"
                eagleiNumberInput
                [allowNegative]="params.allowNegative"
                [allowDecimal]="params.allowDecimal" />
            <mat-error *ngIf="filterForm.get('minValue').invalid">Number Required</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" [class.active]="isMaxActive">
            <mat-label>Max</mat-label>
            <input
                matInput
                formControlName="maxValue"
                eagleiNumberInput
                [allowNegative]="params.allowNegative"
                [allowDecimal]="params.allowDecimal" />
            <mat-error *ngIf="filterForm.get('maxValue').invalid">Number Required</mat-error>
        </mat-form-field>
    </div>
    <mat-error *ngIf="filterForm.invalid">Min value must be less than max</mat-error>
</form>
