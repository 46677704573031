import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import * as moment from 'moment';
import {CardFilters} from '../../../../../shared/classes/card-filters';
import {DataInfoComponent} from '../../../../../shared/modals/data-info/data-info.component';
import {RecentEvent} from '../../classes/recent-event';
import {RecentEventSource} from '../../classes/recent-event-source';
import {EventService} from '../../services/event.service';
import {MatPaginator} from '@angular/material/paginator';
import {ApplicationConfig} from '../../../../classes/application-config';
import {HttpInterceptorService} from '../../../../services/http-interceptor.service';

@Component({
    selector: 'eaglei-event-feed',
    templateUrl: './event-feed.component.html',
    styleUrls: ['./event-feed.component.scss'],
})
export class EventFeedComponent implements OnInit {
    // HTML Properties
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

    // Event Properties
    public allEvents: RecentEvent[] = [];
    public activeEvents: RecentEvent[] = [];
    public selectedEvent: RecentEvent;
    public sources: RecentEventSource[] = [];

    // Filter Properties
    public activeSources: RecentEventSource[] = [];
    public sortDirection: 1 | -1 = -1;
    public searchText: string = '';
    public startDate: moment.Moment = moment().subtract(1, 'days').startOf('day');
    public endDate: moment.Moment = moment();

    private loaded = false;
    private handle: any;

    constructor(private dialog: MatDialog, private eventService: EventService, private cd: ChangeDetectorRef) {
        this.getRecentEventSources();
        this.getRecentEvents();
    }

    public ngOnInit(): void {}

    // Data Methods
    private getRecentEvents(): void {
        HttpInterceptorService.clearInterceptor('eventFeedEvent');
        HttpInterceptorService['eventFeedEvent'] = this.eventService.getEvents(this.startDate, this.endDate).subscribe((events) => {
            HttpInterceptorService.deleteFromInterceptor('eventFeedEvent');

            this.allEvents = events;
            this.activeEvents = this.allEvents.slice();
            this.loaded = true;
            this.filterEvents();
            this.sortEvents();
        });
    }

    private getRecentEventSources(): void {
        HttpInterceptorService.clearInterceptor('eventFeedSource');
        HttpInterceptorService['eventFeedSource'] = this.eventService.getNewsfeedSources().subscribe((sources) => {
            HttpInterceptorService.deleteFromInterceptor('eventFeedSource');
            this.sources = sources;
            this.activeSources = this.sources.slice();
        });
    }

    // Filter Methods
    public changeDateRange(dates: CardFilters): void {
        this.startDate = dates.startDate;
        this.endDate = dates.endDate;
        this.getRecentEvents();
    }

    public isSourceSelected(source: RecentEventSource): boolean {
        return this.activeSources.map((as) => as.id).includes(source.id);
    }

    public toggleSourceSelected(source: RecentEventSource): void {
        const index = this.activeSources.findIndex((s) => s.id === source.id);
        if (index === -1) {
            this.activeSources.push(source);
        } else {
            this.activeSources.splice(index, 1);
        }
        this.filterEvents();
    }

    public sortEvents(direction?: 1 | -1): void {
        this.sortDirection = direction || this.sortDirection;
        this.activeEvents = this.activeEvents.sort((a, b) => {
            const valA = a.newsDate.valueOf();
            const valB = b.newsDate.valueOf();

            if (valA === valB) {
                return 0;
            }
            return this.sortDirection * (valA > valB ? 1 : -1);
        });

        this.cd.detectChanges();
    }

    public filterEvents(): void {
        const filter = () => {
            const sourceIds = this.activeSources.map((as) => as.id);
            this.activeEvents = this.allEvents.filter((event) => {
                const fields: string[] = [event.title, event.content];
                const sourceCheck = sourceIds.includes(event.sourceType.id);
                const textCheck = fields.join().toLowerCase().includes(this.searchText.toLowerCase().trim());
                return sourceCheck && textCheck;
            });
            this.paginator.firstPage();
            this.paginator.length = this.activeEvents.length;
        };

        if (this.handle) {
            clearTimeout(this.handle);
            this.handle = undefined;
        }

        this.handle = setTimeout(() => {
            filter();
            this.sortEvents();
        }, 250);
    }

    public getSourceData(): void {
        this.eventService.getNewsfeedSourcesData().subscribe((sources) => {
            this.dialog.open(DataInfoComponent, {data: {sources: sources, name: 'Event'}, width: '1000px'});
        });
    }

    public isSearchTextActive(): boolean {
        return this.searchText && this.searchText.trim().length > 0;
    }

    public selectEvent(event: RecentEvent): void {
        if (this.selectedEvent && this.selectedEvent.id === event.id) {
            this.selectedEvent = undefined;
        } else {
            this.selectedEvent = event;
        }
    }

    public getPaginatorValues(): RecentEvent[] {
        if (!this.paginator) {
            console.warn('paginator not initialized');
        }

        const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
        const endIndex = Math.min(startIndex + this.paginator.pageSize, this.activeEvents.length);

        return this.activeEvents.slice(startIndex, endIndex);
    }

    public getMaskText(): string {
        let text = 'Select a source to see data on recent events';
        if (this.activeSources.length !== 0) {
            text = 'There are no recent events matching the filter selection.';
        }
        return text;
    }

    // Helper Methods
    public onPhone(): boolean {
        return ApplicationConfig.onPhone();
    }
}
