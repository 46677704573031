<div class="dialog-header">
    <span>Download Outage Data CSV</span>
    <mat-icon [inline]="true" class="clickable" (click)="ref.close()" fontSet="fas" fontIcon="fa-times"></mat-icon>
</div>

<div class="dialog-content" #content>
    <mat-horizontal-stepper linear #stepper>
        <!--Date Range-->
        <mat-step>
            <form class="date-range">
                <ng-template matStepLabel>Select Date Range</ng-template>

                <div class="filters">
                    <eaglei-date-range
                        [newDesign]="true"
                        [start]="downloadInfo.startDate"
                        [end]="downloadInfo.endDate"
                        [min]="minDate"
                        [max]="maxDate"
                        [showTime]="true"
                        (change)="updateDateRange($event)"
                        (invalidDate)="invalidDate = true">
                    </eaglei-date-range>

                    <mat-form-field appearance="outline">
                        <mat-label>Outage Interval</mat-label>
                        <mat-select (selectionChange)="updateOutageInterval($event)" [value]="downloadInfo.hourInterval">
                            <mat-option *ngFor="let option of intervalOptions" [value]="option.value">
                                {{ option.display }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <p class="info-text">Currently data can be requested a max of 7 days from the end date.</p>
                <p class="info-text">
                    Outage data is saved for 30 days. If previous data is required please fill out a Request for Archived Data. Navigate to
                    the Database Menu > Request Archive Data.
                </p>

                <div class="stepper-actions">
                    <button mat-flat-button color="primary" matStepperNext [disabled]="invalidDate">Next</button>
                </div>
            </form>
        </mat-step>

        <!--Aggregation Level-->
        <mat-step [stepControl]="aggregationGroup">
            <form [formGroup]="aggregationGroup">
                <ng-template matStepLabel>Select Aggregation Level</ng-template>

                <mat-form-field class="filter-select" appearance="outline">
                    <mat-label *ngIf="aggregationGroup.controls['level'].touched && !aggregationGroup.controls['level'].invalid">
                        Aggregation Level
                    </mat-label>
                    <mat-select formControlName="level" placeholder="Aggregation Level">
                        <mat-option *ngFor="let level of aggregationLevels" [value]="level">{{ level | aggregationLevel }} </mat-option>
                    </mat-select>
                    <mat-error> An aggregation level is required.</mat-error>
                </mat-form-field>

                <mat-form-field *ngIf="showStateSelect" class="filter-select" appearance="outline">
                    <mat-label *ngIf="aggregationGroup.controls['state'].touched && !aggregationGroup.controls['state'].invalid">
                        State/Territory
                    </mat-label>
                    <mat-select formControlName="state" placeholder="State/Territory" multiple [(ngModel)]="statesForCounty">
                        <mat-option *ngFor="let state of states" [value]="state">{{ state.name }}</mat-option>
                    </mat-select>
                    <mat-error> A state is required for county selection.</mat-error>
                </mat-form-field>

                <mat-slide-toggle
                    *ngIf="this.aggregationGroup?.get('level')?.value === outageAggregationLevels.county"
                    position="after"
                    [checked]="includeZeroOutages"
                    (change)="toggleZeroOutages($event.checked)">
                    <mat-label>Include Counties with zero outages</mat-label>
                </mat-slide-toggle>

                <div class="stepper-actions">
                    <button mat-stroked-button matStepperPrevious>Previous</button>
                    <button mat-flat-button color="primary" matStepperNext [disabled]="aggregationGroup.invalid">Next</button>
                </div>
            </form>
        </mat-step>

        <!--Locations-->
        <mat-step [stepControl]="locationGroup">
            <form class="location">
                <ng-template matStepLabel>Select Locations</ng-template>

                <div class="select-clear">
                    <mat-chip (click)="selectAllLocations()">Select All</mat-chip>
                    <mat-chip (click)="clearAllLocations()">Clear All</mat-chip>
                </div>

                <div class="regions" *ngIf="!showStateSelect">
                    <div class="county-header">FEMA Regions</div>
                    <div class="fema-list">
                        <mat-chip-listbox multiple class="wide">
                            <mat-chip-option
                                *ngFor="let region of femaRegions"
                                class="location-chip"
                                [selected]="region.selected"
                                (click)="toggleFemaState(region)">
                                {{ region.name }}
                            </mat-chip-option>
                        </mat-chip-listbox>
                    </div>
                </div>

                <div class="chip-list" *ngIf="!showStateSelect">
                    <div class="county-header">States/Territories</div>
                    <div class="state-list">
                        <mat-chip-listbox multiple>
                            <mat-chip-option
                                class="location-chip"
                                *ngFor="let location of locations"
                                [selected]="downloadInfo.fipsCodes.indexOf(location.fipsCode) !== -1"
                                (click)="toggleLocation(location.fipsCode)">
                                {{ location.name }}
                            </mat-chip-option>
                        </mat-chip-listbox>
                    </div>
                </div>

                <div *ngIf="showStateSelect">
                    <div *ngFor="let entry of counties | keyvalue">
                        <div class="county-header">{{ entry.key | stateName }}</div>

                        <div class="county-list">
                            <mat-chip-listbox multiple>
                                <mat-chip-option
                                    class="location-chip"
                                    *ngFor="let location of entry.value"
                                    [selected]="downloadInfo.fipsCodes.indexOf(location.fipsCode) !== -1"
                                    (click)="toggleLocation(location.fipsCode)"
                                    [matTooltip]="location.name"
                                    matTooltipPosition="below"
                                    matTooltipShowDelay="250">
                                    {{ location.name }}
                                </mat-chip-option>
                            </mat-chip-listbox>
                        </div>
                    </div>
                </div>
            </form>

            <div class="stepper-actions">
                <button mat-stroked-button matStepperPrevious>Previous</button>
                <button mat-flat-button color="primary" matStepperNext (click)="createFilename()" [disabled]="locationGroup.invalid">
                    Next
                </button>
            </div>
        </mat-step>

        <!--Filename-->
        <mat-step [stepControl]="filenameGroup">
            <form [formGroup]="filenameGroup">
                <ng-template matStepLabel>Edit Filename</ng-template>

                <mat-form-field appearance="outline" class="filter-select">
                    <mat-label *ngIf="downloadInfo.filename.trim().length > 0">Filename</mat-label>
                    <input
                        matInput
                        type="text"
                        placeholder="Filename"
                        formControlName="name"
                        [(ngModel)]="downloadInfo.filename"
                        maxlength="170" />
                    <mat-hint align="end">{{ downloadInfo.filename.trim().length }} / 170</mat-hint>
                    <mat-error>
                        <span>A filename is required.</span>
                    </mat-error>
                </mat-form-field>

                <div class="info-text">
                    The choices you have made will result in a maximum of
                    {{ possibleRowsPerTimestamp$ | async | eagleiNumber }} rows
                </div>
            </form>

            <div class="stepper-actions">
                <button mat-stroked-button matStepperPrevious>Previous</button>
                <button mat-flat-button color="primary" matStepperNext (click)="downloadFile()" [disabled]="filenameGroup.invalid">
                    Send Data
                </button>
            </div>
        </mat-step>
    </mat-horizontal-stepper>
</div>
