import {GenOutageDataUtility} from '../../../../../generated/serverModels/GenOutageDataUtility';
import * as moment from 'moment';
import {DataService} from '../../../services/data.service';

export class UtilityOutage extends GenOutageDataUtility {
    get reportUtilityId(): string {
        return this._reportUtilityId;
    }

    get timestampValue(): number {
        return this._timestampValue;
    }

    get stateName(): string {
        return this._stateName;
    }

    private readonly _timestampValue: number;
    private readonly _reportUtilityId: string;
    private readonly _stateName: string;

    constructor(utility?: UtilityOutage) {
        super(utility);

        if (utility) {
            this._reportUtilityId = `${utility.eagleiId}-${utility.stateAbbreviation}-${utility.subUtilityId}-${utility.countyId}`;
            this._timestampValue = moment(utility.timeStamp).valueOf();
            this._stateName = DataService.states.getValue().find((s) => s.abbreviation === this.stateAbbreviation).name;
        }
    }
}
