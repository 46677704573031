import {Component} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {IResizerData} from '../../interfaces/resizer-data.interface';

@Component({
    selector: 'eaglei-map-charts',
    templateUrl: './map-charts.component.html',
    styleUrls: ['./map-charts.component.scss'],
})
export class MapChartsComponent {
    static data: BehaviorSubject<IResizerData> = new BehaviorSubject<IResizerData>(undefined);
}
