import {Component, ElementRef, inject, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CommunityUser} from '../../classes/community-user';
import {take} from 'rxjs/operators';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Community} from '../../classes/community';
import {AuthenticationService} from '../../../../services/authentication.service';
import {BaseModal} from '../../../../classes/base-modal';
import {CommunityService} from '../../services/community.service';
import {GenCommunityUserStatus} from '../../../../../../generated/serverModels/GenCommunityUserStatus';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'eaglei-community-access-modal',
    templateUrl: './community-access-modal.component.html',
    styleUrls: ['./community-access-modal.component.scss'],
})
export class CommunityAccessModalComponent extends BaseModal {
    @ViewChild('content') content: ElementRef;

    private communityService = inject(CommunityService);
    private authService = inject(AuthenticationService);
    private popup = inject(MatSnackBar);
    protected dialogRef = inject(MatDialogRef<CommunityAccessModalComponent>);

    public requestGroup = new FormGroup({
        justification: new FormControl<string>('', {nonNullable: true, validators: [Validators.required, Validators.maxLength(500)]}),
    });

    constructor(@Inject(MAT_DIALOG_DATA) public community: Community) {
        super();
    }

    public makeRequest(): void {
        const user = CommunityUser.fromUser(this.authService.authenticatedUser.getValue());
        user.manager = false;
        user.status = GenCommunityUserStatus.PENDING;
        user.communityId = this.community.id;
        user.justification = this.requestGroup.getRawValue().justification;

        this.communityService
            .requestAccess(user)
            .pipe(take(1))
            .subscribe({
                next: (communityUser) => {
                    this.dialogRef.close(communityUser);
                },
                error: (error) => {
                    const customErrorMessage = error.error.userMessage;
                    if (customErrorMessage === 'no_enabled_managers') {
                        const numberOfManagers = this.community.getManagers().length;
                        this.popup.open(
                            `Unable to send request email to the community ${
                                numberOfManagers === 1 ? 'manager' : 'managers'
                            }, please contact the admin`,
                            'Okay',
                            {duration: 5_000, panelClass: 'dialog-failure'}
                        );
                    }

                    console.error(error);
                },
            });
    }

    afterInit() {}
}
