import {LeafletVectorSource} from '../classes/leaflet-vector-source';
import {LeafletMapLayer} from '../classes/leaflet-map-layer';
import {GenNewsfeed} from '../../../../../generated/serverModels/GenNewsfeed';
import {LeafletFeature} from '../classes/leaflet-feature';
import * as L from 'leaflet';
import {LatLng, Layer} from 'leaflet';
import {MapService} from '../../map/services/map.service';
import {User} from '../../user/classes/user';
import {Newsfeed} from '../classes/newsfeed';

export class NewsfeedSource extends LeafletVectorSource<Newsfeed> {
    public readonly legendIcons: {[alertType: string]: {imagePath: string; active: boolean}} = {
        fire: {imagePath: '/dist/images/newsfeed_icons/fire.svg', active: true},
        flood: {imagePath: '/dist/images/newsfeed_icons/flood.svg', active: true},
        storm: {imagePath: '/dist/images/newsfeed_icons/storm.svg', active: true},
        spill: {imagePath: '/dist/images/newsfeed_icons/spill.svg', active: true},
        other: {imagePath: '/dist/images/newsfeed_icons/other.svg', active: true},
        infect: {imagePath: '/dist/images/newsfeed_icons/infection.svg', active: true},
        hijack: {imagePath: '/dist/images/newsfeed_icons/hijack.svg', active: true},
        police: {imagePath: '/dist/images/newsfeed_icons/police2.svg', active: true},
        bomb: {imagePath: '/dist/images/newsfeed_icons/bomb.svg', active: true},
        unknown: {imagePath: '/dist/images/layers/media-icon.svg', active: false},
        fema: {imagePath: '/dist/images/newsfeed_icons/default.svg', active: false},
        state: {imagePath: '/dist/images/newsfeed_icons/default.svg', active: false},
        county: {imagePath: '/dist/images/newsfeed_icons/default.svg', active: false},
        default: {imagePath: '/dist/images/newsfeed_icons/default.svg', active: true},
    };

    private user: User;
    private markers: L.Marker[] = [];

    constructor(layerInfo: LeafletMapLayer, user: User) {
        super(layerInfo);
        this.user = user;
    }

    processFeatures(features: Newsfeed[]): void {
        this.markers = [];
        this.leafletFeatures = features
            .filter((n) => this.user.isAdmin() || this.user.hasAnyRole(n.roles))
            .map((v) => new LeafletFeature<Newsfeed>().convert(v));

        const layerPopover = (f, l) => {
            this.initializePopoverInteractions(f, l);
        };

        const style = (feature: any, latlng: LatLng): Layer => {
            const newsfeed: GenNewsfeed = feature.properties;

            const keyword = newsfeed.keywords.find((k) => this.legendIcons[k.keyword]);
            const key = keyword ? this.legendIcons[keyword.keyword] : this.legendIcons['default'];
            const myIcon = L.icon({
                iconUrl: key.imagePath,
                iconSize: [25, 25], // width and height of the image in pixels
                shadowSize: [35, 20], // width, height of optional shadow image
                iconAnchor: [12, 12], // point of the icon which will correspond to marker's location
                shadowAnchor: [12, 6], // anchor point of the shadow. should be offset
                popupAnchor: [0, 0], // point from which the popup should open relative to the iconAnchor
            });

            const marker = L.marker(latlng, {icon: myIcon});
            this.markers.push(marker);
            return marker;
        };

        const config: any = {
            pane: MapService.layerPaneName,
            features: this.leafletFeatures,
            pointToLayer: style,
            onEachFeature: layerPopover,
        };

        this.source = L.geoJSON(this.leafletFeatures as any, config);
    }

    changeOpacity(opacity: number) {
        this.markers.forEach((marker) => marker.setOpacity(opacity));
    }
}
