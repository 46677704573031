import {Component, EventEmitter, inject, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup, UntypedFormGroup} from '@angular/forms';
import {User} from '../../../user/classes/user';
import {Observable} from 'rxjs';
import {RoleDefinitionPipe} from '../../../../../shared/pipes/role-definition.pipe';
import {GenRoleDefinition} from '../../../../../../generated/serverModels/GenRoleDefinition';
import {CommunityService} from '../../services/community.service';
import {Community} from '../../classes/community';
import {debounceTime, filter, map, startWith} from 'rxjs/operators';
import {CommunityUser} from '../../classes/community-user';

interface GroupOption {
    name: string;
    values: GenRoleDefinition[] | User[];
}

interface UserGroup {
    name: string;
    users: User[];
    expanded: boolean;
    usersExpanded: boolean;
}

@Component({
    selector: 'eaglei-add-community-user',
    templateUrl: './add-community-user.component.html',
    styleUrls: ['./add-community-user.component.scss'],
})
export class AddCommunityUserComponent implements OnChanges {
    private communityService = inject(CommunityService);

    @Input() community: Community;
    @Output() addedUsers = new EventEmitter<CommunityUser[]>();

    // Add User Properties
    protected formGroup: UntypedFormGroup = new FormGroup<any>({
        autocomplete: new FormControl<string>(''),
    });
    private autocompleteUsers: User[] = [];

    private rolesToSearch = [GenRoleDefinition.ROLE_DOE, GenRoleDefinition.ROLE_FEDERAL, GenRoleDefinition.ROLE_STATE];

    protected opts: Observable<GroupOption[]>;
    private groups: GroupOption[] = [];

    // Autocomplete Properties
    private selectedUserIdSet: Set<number> = new Set<number>();
    private rolePipe: RoleDefinitionPipe = new RoleDefinitionPipe();
    protected addedByUsername: User[] = [];
    protected addedByRole: UserGroup[] = [];
    protected byUserExpanded = true;
    protected byRoleExpanded = true;
    protected readonly numUsersVisible = 6;

    ngOnChanges(changes: SimpleChanges) {
        if (changes.community.currentValue.id) {
            this.initAutocompleteData();
        }
    }

    /**
     * Applies a transform function to a user or role in the autocomplete for display
     * @param val The role or user having the transform function applied
     */
    public autocompleteDisplay(val: GenRoleDefinition | User): string {
        if (val instanceof User) {
            return `${val.lastName}, ${val.firstName}  (${val.email}) `;
        } else if (val instanceof GenRoleDefinition) {
            return this.rolePipe.transform(val);
        }
        return '';
    }

    /**
     * Adds a user to a list of users that will have member status in the community
     * @param val If a roleDefinition, it will add all users with that role to the list, if a user, it will only add that user
     */
    public addToNewUserList(val: GenRoleDefinition | User): void {
        this.formGroup.controls.autocomplete.setValue('');
        let usersToAdd: User[] = [];

        if (val instanceof GenRoleDefinition) {
            const key = this.rolePipe.transform(val);
            usersToAdd = this.autocompleteUsers.filter((u) => u.hasRole(val, true) && !this.selectedUserIdSet.has(u.id));

            if (usersToAdd.length === 0) {
                return;
            }

            let group: UserGroup = this.addedByRole.find((g) => g.name === key);

            if (!group) {
                group = {
                    name: key,
                    users: [],
                    expanded: true,
                    usersExpanded: false,
                };
                this.addedByRole.push(group);
                this.addedByRole.sort((a, b) => (a > b ? 1 : -1));
            }

            group.users = group.users.concat(usersToAdd);
            usersToAdd.forEach((u) => {
                this.selectedUserIdSet.add(u.id);
            });
        } else {
            if (!this.selectedUserIdSet.has(val.id)) {
                this.addedByUsername.push(val);
                this.selectedUserIdSet.add(val.id);
            }
        }
    }

    /**
     * Sets the users to have member status.
     */
    public addUsers(): void {
        const users = this.addedByRole
            .map((group) => group.users)
            .reduce((p, c) => p.concat(c), [])
            .concat(this.addedByUsername);

        this.communityService.addUsersAsManager(this.community.id, users).subscribe((res) => {
            this.addedUsers.emit(res);
        });
    }

    /**
     * Controls how many user chips are shown in the autocomplete before the plus chip is added.
     * @param users a list of users
     * @param usersExpanded if true, this will show all user, false only show numUsersVisible
     */
    public getVisibleUsers(users: User[], usersExpanded: boolean): User[] {
        return usersExpanded ? users : users.slice(0, this.numUsersVisible);
    }

    /**
     * Removes a user from the list of users being added to the community
     * @param user the user being removed
     * @param list the list that the user is in for autocomplete display
     */
    public removeUserFromList(user: User, list: User[]): void {
        const index = list.findIndex((u) => u.id === user.id);

        if (index !== -1) {
            list.splice(index, 1);
            this.selectedUserIdSet.delete(user.id);
        }
    }

    /**
     * Removes a full role group from being added to the community
     * @param roleGroup the role group to be removed
     */
    public removeRoleGroup(roleGroup: UserGroup): void {
        const index = this.addedByRole.findIndex((rg) => rg.name === roleGroup.name);
        if (index !== -1) {
            roleGroup.users.forEach((u) => this.selectedUserIdSet.delete(u.id));
            this.addedByRole.splice(index, 1);
        }
    }

    /**
     * Removes all users added by username
     */
    public removeAddedByUsername(): void {
        this.addedByUsername.forEach((u) => this.selectedUserIdSet.delete(u.id));
        this.addedByUsername = [];
    }

    /**
     * remove all users added by roles
     */
    public removeAddedByByRole(): void {
        this.addedByRole.forEach((g) => {
            g.users.forEach((u) => this.selectedUserIdSet.delete(u.id));
        });

        this.addedByRole = [];
    }

    /**
     * Returns if the given user is a pending user for the community
     * @param user The user being checked
     */
    public isCurrentlyPending(user: User): boolean {
        return this.community.pendingUsers.findIndex((pu) => pu.userId === user.id) !== -1;
    }

    // Autocomplete methods
    /**
     * Gets the user and roles for the add user auto complete
     */
    private initAutocompleteData(): void {
        this.communityService.getUsers(this.community.id).subscribe((res) => {
            const communityUserIds = this.community.users.map((u) => u.userId);
            this.autocompleteUsers = res
                .filter((user) => user.accountEnabled && !communityUserIds.includes(user.id))
                .sort((a, b) => (a.lastName.toLowerCase() > b.lastName.toLowerCase() ? 1 : -1));

            this.groups = [
                {name: 'Roles', values: GenRoleDefinition.values().filter((r) => this.rolesToSearch.includes(r))},
                {name: 'Users', values: this.autocompleteUsers},
            ];

            this.opts = this.formGroup.get('autocomplete').valueChanges.pipe(
                startWith(''),
                filter((value) => typeof value === 'string'),
                debounceTime(100),
                map((value) => {
                    // return this.groups;

                    if (value) {
                        return this.groups.map((group) => {
                            const f = (group.values as any[]).filter((val) => {
                                if (val instanceof User) {
                                    return `${val.firstName} ${val.lastName} ${val.email}`.toLowerCase().includes(value.toLowerCase());
                                }

                                if (val instanceof GenRoleDefinition) {
                                    const nameCheck = val.name.toLowerCase().includes(value.toLowerCase());
                                    const displayCheck = this.rolePipe.transform(val).toLowerCase().includes(value.toLowerCase());

                                    return displayCheck || nameCheck;
                                }

                                console.error('value is not a User or Role definition');
                                return undefined;
                            });

                            return {name: group.name, values: f};
                        });
                    } else {
                        return this.groups;
                    }
                })
            );
        });
    }
}
