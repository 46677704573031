<form class="filters filter-bar" [formGroup]="filterForm">
    <eaglei-date-range (change)="updateDateRange($event)" [min]="ongService.minDate" [start]="datePickerStartDate"> </eaglei-date-range>

    <button mat-stroked-button [matMenuTriggerFor]="menu" matTooltip="Export" matTooltipPosition="above" matTooltipShowDelay="250">
        <mat-icon inline fontSet="fas" fontIcon="fa-file-export"></mat-icon>
    </button>
</form>

<div>
    <div class="table-section">
        <mat-card>
            <mat-card-title> Refinery Utilization </mat-card-title>

            <mat-card-content>
                <div class="table-container" [class.invisible]="dataSource?.data?.length === 0">
                    <table mat-table [dataSource]="dataSource" multiTemplateDataRows>
                        <ng-container matColumnDef="filler">
                            <th mat-header-cell *matHeaderCellDef class="processing-class"></th>
                        </ng-container>

                        <ng-container matColumnDef="primary">
                            <th mat-header-cell *matHeaderCellDef colspan="3" class="processing-class">Primary Processing</th>
                        </ng-container>

                        <ng-container matColumnDef="region">
                            <th mat-header-cell *matHeaderCellDef class="ei-md-column">Region</th>
                            <td mat-cell *matCellDef="let info" class="ei-md-column">
                                {{ info.region }}

                                <mat-icon
                                    inline
                                    fontSet="fas"
                                    [fontIcon]="info === utilizationSelectedRow ? 'fa-chevron-down' : 'fa-chevron-right'"></mat-icon>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="p-utilization">
                            <th mat-header-cell *matHeaderCellDef class="ei-sm-column">Utilization %</th>
                            <td mat-cell *matCellDef="let info" class="ei-sm-column">
                                {{ info.primary_utilization_avg | eagleiNumber : utilPipeArgs }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="p-offline">
                            <th mat-header-cell *matHeaderCellDef class="ei-sm-column">
                                BPD Offline
                                <mat-icon inline fontSet="fas" fontIcon="fa-info-circle" [matTooltip]="bpdTooltip"></mat-icon>
                            </th>
                            <td mat-cell *matCellDef="let info" class="ei-sm-column">
                                {{ info.primary_offline_avg | eagleiNumber : bpdPipeArgs }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="p-change">
                            <th mat-header-cell *matHeaderCellDef class="ei-sm-column">Week to Week % Change</th>
                            <td mat-cell *matCellDef="let info" class="ei-sm-column">
                                {{ info.primary_change_avg | eagleiNumber : weeklyChangePipeArgs }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="light">
                            <th mat-header-cell *matHeaderCellDef colspan="3" class="processing-class">Light Products</th>
                        </ng-container>

                        <ng-container matColumnDef="l-utilization">
                            <th mat-header-cell *matHeaderCellDef class="ei-sm-column">Utilization %</th>
                            <td mat-cell *matCellDef="let info" class="ei-sm-column">
                                {{ info.light_utilization_avg | eagleiNumber : utilPipeArgs }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="l-offline">
                            <th mat-header-cell *matHeaderCellDef class="ei-sm-column">
                                BPD Offline <mat-icon inline fontSet="fas" fontIcon="fa-info-circle" [matTooltip]="bpdTooltip"></mat-icon>
                            </th>
                            <td mat-cell *matCellDef="let info" class="ei-sm-column">
                                {{ info.light_offline_avg | eagleiNumber : bpdPipeArgs }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="l-change">
                            <th mat-header-cell *matHeaderCellDef class="ei-sm-column">Week to Week % Change</th>
                            <td mat-cell *matCellDef="let info" class="ei-sm-column">
                                {{ info.light_change_avg | eagleiNumber : weeklyChangePipeArgs }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="middle">
                            <th mat-header-cell *matHeaderCellDef colspan="3" class="processing-class">Middle Distillates</th>
                        </ng-container>

                        <ng-container matColumnDef="m-utilization">
                            <th mat-header-cell *matHeaderCellDef class="ei-sm-column">Utilization %</th>
                            <td mat-cell *matCellDef="let info" class="ei-sm-column">
                                {{ info.middle_utilization_avg | eagleiNumber : utilPipeArgs }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="m-offline">
                            <th mat-header-cell *matHeaderCellDef class="ei-sm-column">
                                BPD Offline <mat-icon inline fontSet="fas" fontIcon="fa-info-circle" [matTooltip]="bpdTooltip"></mat-icon>
                            </th>
                            <td mat-cell *matCellDef="let info" class="ei-sm-column">
                                {{ info.middle_offline_avg | eagleiNumber : bpdPipeArgs }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="m-change">
                            <th mat-header-cell *matHeaderCellDef class="ei-sm-column">Week to Week % Change</th>
                            <td mat-cell *matCellDef="let info" class="ei-sm-column">
                                {{ info.middle_change_avg | eagleiNumber : weeklyChangePipeArgs }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="heavy">
                            <th mat-header-cell *matHeaderCellDef colspan="3" class="processing-class">Heavy Products</th>
                        </ng-container>

                        <ng-container matColumnDef="h-utilization">
                            <th mat-header-cell *matHeaderCellDef class="ei-sm-column">Utilization %</th>
                            <td mat-cell *matCellDef="let info" class="ei-sm-column">
                                {{ info.heavy_utilization_avg | eagleiNumber : utilPipeArgs }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="h-offline">
                            <th mat-header-cell *matHeaderCellDef class="ei-sm-column">
                                BPD Offline <mat-icon inline fontSet="fas" fontIcon="fa-info-circle" [matTooltip]="bpdTooltip"></mat-icon>
                            </th>
                            <td mat-cell *matCellDef="let info" class="ei-sm-column">
                                {{ info.heavy_offline_avg | eagleiNumber : bpdPipeArgs }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="h-change">
                            <th mat-header-cell *matHeaderCellDef class="ei-sm-column">Week to Week % Change</th>
                            <td mat-cell *matCellDef="let info" class="ei-sm-column">
                                {{ info.heavy_change_avg | eagleiNumber : weeklyChangePipeArgs }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="expandedDetail">
                            <td mat-cell *matCellDef="let info" colspan="13">
                                <div class="hide-expanded-row" [class.show-expanded-row]="utilizationSelectedRow === info">
                                    <table mat-table [dataSource]="expandedUtilizationDataSource">
                                        <ng-container matColumnDef="region">
                                            <td mat-cell *matCellDef="let info" class="ei-md-column">
                                                {{ info.date | momentDate }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="p-utilization">
                                            <td mat-cell *matCellDef="let info" class="ei-sm-column">
                                                {{ info.primary_utilization | eagleiNumber : utilPipeArgs }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="p-offline">
                                            <td mat-cell *matCellDef="let info" class="ei-sm-column">
                                                {{ info.primary_offline | eagleiNumber : bpdPipeArgs }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="p-change">
                                            <td mat-cell *matCellDef="let info" class="ei-sm-column">
                                                {{ info.primary_change | eagleiNumber : weeklyChangePipeArgs }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="l-utilization">
                                            <th mat-header-cell *matHeaderCellDef class="ei-sm-column">Utilization %</th>
                                            <td mat-cell *matCellDef="let info" class="ei-sm-column">
                                                {{ info.light_utilization | eagleiNumber : utilPipeArgs }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="l-offline">
                                            <th mat-header-cell *matHeaderCellDef class="ei-sm-column">
                                                BPD Offline
                                                <mat-icon
                                                    inline
                                                    fontSet="fas"
                                                    fontIcon="fa-info-circle"
                                                    [matTooltip]="bpdTooltip"></mat-icon>
                                            </th>
                                            <td mat-cell *matCellDef="let info" class="ei-sm-column">
                                                {{ info.light_offline | eagleiNumber : bpdPipeArgs }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="l-change">
                                            <td mat-cell *matCellDef="let info" class="ei-sm-column">
                                                {{ info.light_change | eagleiNumber : weeklyChangePipeArgs }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="m-utilization">
                                            <td mat-cell *matCellDef="let info" class="ei-sm-column">
                                                {{ info.middle_utilization | eagleiNumber : utilPipeArgs }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="m-offline">
                                            <td mat-cell *matCellDef="let info" class="ei-sm-column">
                                                {{ info.middle_offline | eagleiNumber : bpdPipeArgs }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="m-change">
                                            <td mat-cell *matCellDef="let info" class="ei-sm-column">
                                                {{ info.middle_change | eagleiNumber : weeklyChangePipeArgs }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="h-utilization">
                                            <td mat-cell *matCellDef="let info" class="ei-sm-column">
                                                {{ info.heavy_utilization | eagleiNumber : utilPipeArgs }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="h-offline">
                                            <td mat-cell *matCellDef="let info" class="ei-sm-column">
                                                {{ info.heavy_offline | eagleiNumber : bpdPipeArgs }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="h-change">
                                            <td mat-cell *matCellDef="let info" class="ei-sm-column">
                                                {{ info.heavy_change | eagleiNumber : weeklyChangePipeArgs }}
                                            </td>
                                        </ng-container>

                                        <tr
                                            mat-row
                                            *matRowDef="let row; columns: columnNames; let odd = odd"
                                            [class.striped-row]="odd"></tr>
                                    </table>
                                </div>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="groupColumnNames"></tr>
                        <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
                        <tr
                            mat-row
                            *matRowDef="let row; columns: columnNames; let odd = odd"
                            class="clickable"
                            (click)="togglePrimaryExpandedRow(row)"></tr>
                        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="expandable-row"></tr>
                    </table>
                </div>

                <div class="table-attribution" [class.invisible]="dataSource?.data?.length === 0">
                    <a (click)="reportService.openAttribution(attributionUrl)" [innerHTML]="attributionTitle | sanitize"></a>
                </div>

                <div class="auto-mask" *ngIf="dataSource?.data?.length === 0">
                    <p class="mask-message">No data matches your current filters.</p>
                </div>
            </mat-card-content>
        </mat-card>

        <mat-card>
            <mat-card-title> Scotford Upgrader Utilization </mat-card-title>
            <mat-card-content>
                <div class="table-container" [class.invisible]="scotfordDataSource?.data?.length === 0">
                    <table mat-table [dataSource]="scotfordDataSource" multiTemplateDataRows>
                        <ng-container matColumnDef="filler">
                            <th mat-header-cell *matHeaderCellDef class="processing-class"></th>
                        </ng-container>

                        <ng-container matColumnDef="primary">
                            <th mat-header-cell *matHeaderCellDef colspan="3" class="processing-class">Primary Bitumen Refining</th>
                        </ng-container>

                        <ng-container matColumnDef="region">
                            <th mat-header-cell *matHeaderCellDef class="ei-md-column">Region</th>
                            <td mat-cell *matCellDef="let info" class="ei-md-column">
                                {{ info.region }}

                                <mat-icon
                                    inline
                                    fontSet="fas"
                                    [fontIcon]="info === scotfordSelectedRow ? 'fa-chevron-down' : 'fa-chevron-right'"></mat-icon>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="p-utilization">
                            <th mat-header-cell *matHeaderCellDef class="ei-sm-column">Utilization %</th>
                            <td mat-cell *matCellDef="let info" class="ei-sm-column">
                                {{ info.bitumen_primary_utilization_avg | eagleiNumber : utilPipeArgs }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="p-offline">
                            <th mat-header-cell *matHeaderCellDef class="ei-sm-column">
                                BPD Offline <mat-icon inline fontSet="fas" fontIcon="fa-info-circle" [matTooltip]="bpdTooltip"></mat-icon>
                            </th>
                            <td mat-cell *matCellDef="let info" class="ei-sm-column">
                                {{ info.bitumen_primary_offline_avg | eagleiNumber : bpdPipeArgs }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="p-change">
                            <th mat-header-cell *matHeaderCellDef class="ei-sm-column">Week to Week % Change</th>
                            <td mat-cell *matCellDef="let info" class="ei-sm-column">
                                {{ info.bitumen_primary_change_avg | eagleiNumber : weeklyChangePipeArgs }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="secondary">
                            <th mat-header-cell *matHeaderCellDef colspan="3" class="processing-class">Bitumen Secondary Refining</th>
                        </ng-container>

                        <ng-container matColumnDef="s-utilization">
                            <th mat-header-cell *matHeaderCellDef class="ei-sm-column">Utilization %</th>
                            <td mat-cell *matCellDef="let info" class="ei-sm-column">
                                {{ info.bitumen_secondary_utilization_avg | eagleiNumber : utilPipeArgs }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="s-offline">
                            <th mat-header-cell *matHeaderCellDef class="ei-sm-column">
                                BPD Offline <mat-icon inline fontSet="fas" fontIcon="fa-info-circle" [matTooltip]="bpdTooltip"></mat-icon>
                            </th>
                            <td mat-cell *matCellDef="let info" class="ei-sm-column">
                                {{ info.bitumen_secondary_offline_avg | eagleiNumber : bpdPipeArgs }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="s-change">
                            <th mat-header-cell *matHeaderCellDef class="ei-sm-column">Week to Week % Change</th>
                            <td mat-cell *matCellDef="let info" class="ei-sm-column">
                                {{ info.bitumen_secondary_change_avg | eagleiNumber : weeklyChangePipeArgs }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="expandedDetail">
                            <td mat-cell *matCellDef="let info" colspan="13">
                                <div class="hide-expanded-row" [class.show-expanded-row]="scotfordSelectedRow === info">
                                    <table mat-table [dataSource]="scotfordExpandedDataSource">
                                        <ng-container matColumnDef="region">
                                            <td mat-cell *matCellDef="let info" class="ei-md-column">
                                                {{ info.date | momentDate }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="p-utilization">
                                            <td mat-cell *matCellDef="let info" class="ei-sm-column">
                                                {{ info.bitumen_primary_utilization | eagleiNumber : utilPipeArgs }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="p-offline">
                                            <td mat-cell *matCellDef="let info" class="ei-sm-column">
                                                {{ info.bitumen_primary_offline | eagleiNumber : bpdPipeArgs }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="p-change">
                                            <td mat-cell *matCellDef="let info" class="ei-sm-column">
                                                {{ info.bitumen_primary_change | eagleiNumber : weeklyChangePipeArgs }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="s-utilization">
                                            <td mat-cell *matCellDef="let info" class="ei-sm-column">
                                                {{ info.bitumen_secondary_utilization | eagleiNumber : utilPipeArgs }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="s-offline">
                                            <td mat-cell *matCellDef="let info" class="ei-sm-column">
                                                {{ info.bitumen_secondary_offline | eagleiNumber : bpdPipeArgs }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="s-change">
                                            <td mat-cell *matCellDef="let info" class="ei-sm-column">
                                                {{ info.bitumen_secondary_change | eagleiNumber : weeklyChangePipeArgs }}
                                            </td>
                                        </ng-container>

                                        <tr
                                            mat-row
                                            *matRowDef="let row; columns: scotfordColumnNames; let odd = odd"
                                            [class.striped-row]="odd"></tr>
                                    </table>
                                </div>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="scotfordGroupColumnNames"></tr>
                        <tr mat-header-row *matHeaderRowDef="scotfordColumnNames"></tr>
                        <tr
                            mat-row
                            *matRowDef="let row; columns: scotfordColumnNames; let odd = odd"
                            class="clickable"
                            (click)="toggleScotfordExpandedRow(row)"></tr>
                        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="expandable-row"></tr>
                    </table>
                </div>

                <div class="table-attribution" [class.invisible]="scotfordDataSource?.data?.length === 0">
                    <a (click)="reportService.openAttribution(attributionUrl)" [innerHTML]="attributionTitle | sanitize"></a>
                </div>

                <div class="auto-mask" *ngIf="scotfordDataSource?.data?.length === 0">
                    <p class="mask-message">No data matches your current filters.</p>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>

<div class="chart-group">
    <mat-card class="chart">
        <mat-card-title>Canada</mat-card-title>

        <mat-card-content>
            <ng-container [ngTemplateOutlet]="chartLegend"></ng-container>

            <div class="chart-target">
                <div #chart id="Canada" class="chart-target"></div>
            </div>
        </mat-card-content>
    </mat-card>

    <mat-card class="chart">
        <mat-card-title>East Coast</mat-card-title>

        <mat-card-content>
            <ng-container [ngTemplateOutlet]="chartLegend"></ng-container>

            <div class="chart-target">
                <div #chart id="East_Coast" class="chart-target"></div>
            </div>
        </mat-card-content>
    </mat-card>
</div>

<div class="chart-group">
    <mat-card class="chart">
        <mat-card-title>Gulf Coast</mat-card-title>

        <mat-card-content>
            <ng-container [ngTemplateOutlet]="chartLegend"></ng-container>
            <div class="chart-target">
                <div #chart id="Gulf_Coast" class="chart-target"></div>
            </div>
        </mat-card-content>
    </mat-card>

    <mat-card class="chart">
        <mat-card-title>Mid-Continent</mat-card-title>
        <mat-card-content>
            <ng-container [ngTemplateOutlet]="chartLegend"></ng-container>
            <div class="chart-target">
                <div #chart id="Mid-Continent" class="chart-target"></div>
            </div>
        </mat-card-content>
    </mat-card>
</div>

<div class="chart-group">
    <mat-card class="chart">
        <mat-card-title>West US</mat-card-title>

        <mat-card-content>
            <ng-container [ngTemplateOutlet]="chartLegend"></ng-container>
            <div class="chart-target">
                <div #chart id="West_US" class="chart-target"></div>
            </div>
        </mat-card-content>
    </mat-card>

    <mat-card class="chart">
        <mat-card-title>Scotford Upgrader</mat-card-title>
        <mat-card-content>
            <ng-container *ngTemplateOutlet="chartLegend; context: {scotford: true}"></ng-container>

            <div class="chart-target">
                <div #chart id="Scotford_Upgrader" class="chart-target"></div>
            </div>
        </mat-card-content>
    </mat-card>
</div>

<!-- Export Menu -->
<mat-menu #menu="matMenu" overlapTrigger>
    <button (click)="exportTable()" mat-menu-item [disabled]="dataSource?.data?.length === 0">Export Table As CSV</button>
</mat-menu>

<ng-template #chartLegend let-isScotford="scotford">
    <div class="chart-legend" *ngIf="!isScotford">
        <div class="item">
            <span>Primary:</span>
            <span class="color" [style.border-color]="chartColors.primary"></span>
        </div>
        <div class="item">
            <span>Light:</span>
            <span class="color" [style.border-color]="chartColors.light"></span>
        </div>
        <div class="item">
            <span>Middle:</span>
            <span class="color" [style.border-color]="chartColors.middle"></span>
        </div>
        <div class="item">
            <span>Heavy:</span>
            <span class="color" [style.border-color]="chartColors.heavy"></span>
        </div>
    </div>

    <div class="chart-legend" *ngIf="isScotford">
        <div class="item">
            <span>Bitumen Primary Refining:</span>
            <span class="color" [style.border-color]="chartColors.bitPrimary"></span>
        </div>
        <div class="item">
            <span>Bitumen Secondary Refining:</span>
            <span class="color" [style.border-color]="chartColors.bitSecondary"></span>
        </div>
    </div>
</ng-template>
