<div class="container">
    <div class="content">
        <div class="widget-container">
            <div class="info">
                <span *ngIf="!isLoading && !unknownStatType" class="impacted">{{ statImpacted | eagleiNumber }}</span>
                <span *ngIf="isLoading" class="impacted">Loading...</span>
                <span *ngIf="!unknownStatType" class="sub-text">{{ getImpactedTitle() }}</span>
                <span *ngIf="unknownStatType" class="unknown-text">Configure Aggregation for Stat</span>
            </div>

            <div *ngIf="!unknownStatType && !maxStat" class="trend-container">
                <mat-icon class="trend" [class.up]="trendLine === 'trending_up'" [class.down]="trendLine === 'trending_down'">{{
                    trendLine
                }}</mat-icon>
                <span class="sub-percent">{{ trendPercent | eagleiNumber }}</span>
            </div>
        </div>
    </div>
</div>
