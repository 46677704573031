import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {Router} from '@angular/router';
import {HttpInterceptorService} from 'frontend/src/app/services/http-interceptor.service';
import {tap} from 'rxjs/operators';
import {SubportStatus} from '../../../layer/classes/subports-status';
import {ReportService} from '../../../report/services/report.service';
import {EventSummaryFilters} from '../../../system-event/classes/event-summary-filters';
import {BaseWidget} from '../../classes/base-widget';
import {UscgPortStatus} from '../../../../../shared/enums/uscg-port-status.enum';
import {IReportNavigationConfig} from '../../../../interfaces/report-navigation-config.interface';
import {ReportFilter} from '../../../report/enums/report-filter.enum';

@Component({
    selector: 'eaglei-port-widget',
    templateUrl: './port-widget.component.html',
    styleUrls: ['./port-widget.component.scss'],
})
export class PortWidgetComponent extends BaseWidget implements AfterViewInit {
    @ViewChild(MatPaginator) paginator: MatPaginator;

    private readonly PAGE_SIZE: number = 8;

    private regions: string[] = [];

    public ports: SubportStatus[] = [];
    public showingPorts: SubportStatus[] = [];

    public eventFilters: EventSummaryFilters = new EventSummaryFilters();

    constructor(private widgetElement: ElementRef, private reportService: ReportService, private router: Router) {
        super(widgetElement);
    }

    protected handleEventFilterChange(eventFilters: EventSummaryFilters): void {
        this.eventFilters = eventFilters;

        this.getPorts();
    }

    public ngAfterViewInit(): void {
        this.paginator.pageSize = this.PAGE_SIZE;

        if (!this.eventDashboard) {
            this.getPorts();
        }
    }

    public destroyWidget(): void {}

    private getPorts(): void {
        const eventId = this.eventFilters?.event?.id;
        HttpInterceptorService.clearInterceptor(this.widgetInterceptorKey);
        HttpInterceptorService.pendingRequests[this.widgetInterceptorKey] = this.reportService
            .portStatusData(false, eventId)
            .pipe(tap(() => HttpInterceptorService.deleteFromInterceptor(this.widgetInterceptorKey)))
            .subscribe((res) => {
                this.regions = res.map((portCaptain) => portCaptain.name);
                this.ports = res
                    .reduce((prev: SubportStatus[], cur) => prev.concat(cur.ports), [])
                    .filter((port) => {
                        return [UscgPortStatus.RESTRICTED, UscgPortStatus.CLOSED].includes(port.status);
                    })
                    .sort((a, b) => b.status - a.status);

                this.paginator.length = this.ports.length;
                this.paginator.firstPage();

                const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
                const endIndex = this.paginator.pageSize * (this.paginator.pageIndex + 1);
                this.showingPorts = this.ports.slice(startIndex, endIndex);
            });
    }

    public getPortStatus(port: SubportStatus): string {
        switch (port.status) {
            case UscgPortStatus.OPEN:
                return 'Open';
            case UscgPortStatus.RESTRICTED:
                return 'Restricted';
            case UscgPortStatus.CLOSED:
                return 'Closed';
            default:
                return 'UNKNOWN';
        }
    }

    public pagePorts(pageEvent: PageEvent): void {
        const startIndex = pageEvent.pageIndex * pageEvent.pageSize;
        const endIndex = pageEvent.pageSize * (pageEvent.pageIndex + 1);

        this.showingPorts = this.ports.slice(startIndex, endIndex);
    }

    /**
     * Navigates to the Port Status Report and sends the current filters that are applied by the widget
     */
    public goToReport(): void {
        let filters: IReportNavigationConfig[] = [
            {type: ReportFilter.TEXT_SEARCH, value: `${UscgPortStatus.RESTRICTED},${UscgPortStatus.CLOSED}`},
        ];

        if (this.eventDashboard) {
            filters = filters.concat([
                {type: ReportFilter.EVENT_ID, value: this.eventFilters.event.id},
                {type: ReportFilter.STATE, value: this.regions.join()},
                {type: ReportFilter.START_DATE, value: this.eventFilters.event.eventStart.valueOf()},
                {type: ReportFilter.END_DATE, value: this.eventFilters.event.eventEnd.valueOf()},
            ]);
        }

        // noinspection JSIgnoredPromiseFromCall
        this.router.navigate(['app/reports/portStatus'], {state: filters});
    }
}
