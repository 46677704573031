
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!


export class GenReportRequestParameter {

    name: string;
    value: string;

    constructor();
    constructor( jsonObj: any );
    constructor( genReportRequestParameter: GenReportRequestParameter);

    constructor( input?: GenReportRequestParameter ) {
        if ( input && typeof input !== 'number' ) {
            this.name = input.name; // string [string]
            this.value = input.value; // string [string]
       }
    }

    static jsonResponseToList(arr: any[]): GenReportRequestParameter[] {
        if (arr && arr.map) {
          return arr.map(a => new GenReportRequestParameter(a));
        } else {
          return [];
        }
    }
}

