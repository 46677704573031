import {GenMapLayer} from '../../../../../generated/serverModels/GenMapLayer';
import {MapService} from '../../map/services/map.service';
import {BehaviorSubject, Subject} from 'rxjs';
import {Filter} from './filter';
import {MapLayerPopover} from './map-layer-popover';
import {PopoverElement} from '../../../classes/popover-element';
import {DecimalPipe, PercentPipe, TitleCasePipe} from '@angular/common';
import {MomentDatePipe} from '../../../../shared/pipes/moment-date.pipe';
import {GenPopoverDataType} from '../../../../../generated/serverModels/GenPopoverDataType';
import {LayerLegend} from './legend';
import {PopoverService} from '../services/popover.service';
import {GenPopoverPipeType} from '../../../../../generated/serverModels/GenPopoverPipeType';
import {NumeralPipe} from '../../../../shared/pipes/numeral.pipe';
import {StateNamePipe} from '../../../../shared/pipes/state-name.pipe';
import {CustomPopover} from '../enum/custom-popover';
import {ShortNumberPipe} from '../../../../shared/pipes/short-number.pipe';

interface LoadingState {
    error: boolean;
    errorMessage?: string;
    loading: boolean;
}

export class LeafletMapLayer extends GenMapLayer {
    get interceptorKey(): string {
        return this._interceptorKey;
    }

    static numeralPipe = new NumeralPipe();
    static statePipe = new StateNamePipe();
    static shortNumberPipe = new ShortNumberPipe();

    static formatPopoverText(element: MapLayerPopover, data: any): string {
        let ret: string;
        switch (element.pipe) {
            case GenPopoverPipeType.NUMERAL:
                ret = LeafletMapLayer.numeralPipe.transform(data[element.propertyName], false);
                break;
            case GenPopoverPipeType.STATE:
                ret = LeafletMapLayer.statePipe.transform(data[element.propertyName]);
                break;
            default:
                ret = data[element.propertyName] || 'Not Available';
        }

        if (element.title) {
            return element.display ? `${element.display} ${ret}` : ret;
        }
        return ret;
    }

    // This is used for user layer creation.
    public tmpLayerName: string;
    public userAdded: boolean;

    public filters: Filter[];
    public popoverData: MapLayerPopover[] = [];
    public legend: LayerLegend;
    public identifiable: boolean;
    public opacity: number;
    public reset = new Subject();

    public customPopoverId: CustomPopover;

    private readonly _interceptorKey: string;

    public opacityEnabled: boolean = true;

    public loadingState = new BehaviorSubject<LoadingState>({
        error: false,
        loading: false,
    });

    constructor(layer?: LeafletMapLayer) {
        super(layer);

        if (layer?.filters?.length > 0) {
            this.filters = layer.filters.map((f) => new Filter(f)).sort((a, b) => (a.ordering > b.ordering ? 1 : -1));
        }

        if (layer) {
            this._interceptorKey = `${layer.uiHandle}_layer_key`;
            if (layer.popoverData) {
                this.popoverData = layer.popoverData.map((d) => new MapLayerPopover(d));
            }

            this.legend = new LayerLegend(layer.legend);

            this.identifiable = this.popoverData.length > 0;
        } else {
            // Default properties
            this.description = '';
            this.defaultActive = false;
        }

        this.opacity = layer?.opacity || 80;
    }

    public startLoading(): void {
        const opts: LoadingState = {
            error: false,
            errorMessage: undefined,
            loading: true,
        };
        this.loadingState.next(opts);
        MapService.addLayerLoading.next(this.uiHandle);
    }

    public endLoading(error: boolean = false, message?: string): any {
        const opts: LoadingState = {
            error,
            errorMessage: message || this.loadingState.getValue().errorMessage,
            loading: false,
        };
        this.loadingState.next(opts);
        MapService.removeLayerLoading.next(this.uiHandle);
    }

    public getAttributionLink(): string {
        if (this.attributionUrl && this.attributionUrl.includes('mailto:')) {
            return `<a href="${this.attributionUrl}">${this.attributionTitle}</a>`;
        }
        return `<a href="${this.attributionUrl}" target="_blank">${this.attributionTitle}</a>`;
    }

    public createPopover(properties, includeAll: boolean = true): PopoverElement[] {
        let data: PopoverElement[];

        if (this.popoverData.length > 0) {
            const filterCallback = (p: MapLayerPopover) => {
                const validCheck = includeAll ? true : properties[p.propertyName] !== undefined;
                return !p.title && validCheck;
            };

            const mapCallback = (p: MapLayerPopover) => {
                const suffix = properties[p.suffix] || p.suffix || '';
                const numberPipe = new DecimalPipe('en-us');
                const percentPipe = new PercentPipe('en-us');
                const titlePipe = new TitleCasePipe();
                const datePipe = new MomentDatePipe();
                const displayName = p.display || titlePipe.transform(p.propertyName);

                let formattedValue: string;
                switch (p.type) {
                    case GenPopoverDataType.NUMBER:
                        if (p?.pipe === GenPopoverPipeType.SHORT_NUMBER) {
                            formattedValue = LeafletMapLayer.shortNumberPipe.transform(properties[p.propertyName]);
                            break;
                        }
                        formattedValue = numberPipe.transform(properties[p.propertyName], p.valueFormat || '1.2-2');
                        break;
                    case GenPopoverDataType.PERCENT:
                        formattedValue = percentPipe.transform(properties[p.propertyName], p.valueFormat || '1.2-2');
                        break;
                    case GenPopoverDataType.DATE:
                        formattedValue = datePipe.transform(properties[p.propertyName], p.valueFormat || undefined);
                        break;
                    case GenPopoverDataType.LINK:
                        let url = LeafletMapLayer.formatPopoverText(p, properties);

                        // Geoserver wms responses sometimes have NOT AVAILABLE as the URL.
                        if (url.toUpperCase().includes('NOT AVAILABLE')) {
                            return undefined;
                        }

                        if (!url.startsWith('http')) {
                            url = `http://${url}`;
                        }

                        formattedValue = url;
                        break;
                    case GenPopoverDataType.MODAL:
                        formattedValue = properties[p.propertyName];
                        break;
                    default:
                        formattedValue = LeafletMapLayer.formatPopoverText(p, properties);
                }

                if (p.textFormat) {
                    formattedValue = p.textFormat.replace('%d', formattedValue) + suffix;
                } else {
                    formattedValue += suffix;
                }

                const ele = new PopoverElement(displayName, formattedValue, p.isLink());

                if (p.isModal()) {
                    ele.setHideInLocationSearch();
                    const modalInfo = PopoverService.getDialogInfo(p, properties);
                    if (!modalInfo) {
                        return;
                    } else {
                        if (modalInfo.config.defaultConfig) {
                            modalInfo.config.data.title = titleRow.value;
                        }
                        ele.setDialogRef(modalInfo.ref, modalInfo.config);
                    }
                }

                return ele;
            };

            const title = this.popoverData.find((p) => p.title);
            let titleRow = new PopoverElement('', this.displayName).setTitle();
            if (title) {
                const text = LeafletMapLayer.formatPopoverText(title, properties);
                titleRow = new PopoverElement('', text).setTitle();
            }

            const dataRows = this.popoverData
                .filter(filterCallback)
                .sort((a, b) => (a.ordering > b.ordering ? 1 : -1))
                .map(mapCallback)
                .filter((row) => !!row);

            data = [titleRow].concat(dataRows);
        } else {
            data = [new PopoverElement('', this.displayName).setTitle(), new PopoverElement('', ' ')];
        }

        return data;
    }

    public getParams(): any {
        let ret;
        try {
            ret = JSON.parse(this.params);
        } catch (e) {
            ret = this.params || {};
        }

        return ret;
    }
}
