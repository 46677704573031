<div class="dialog-header">
    <span>Date Range</span>
    <mat-icon [inline]="true" fontSet="fas" fontIcon="fa-times" class="clickable" (click)="close()"></mat-icon>
</div>
<div class="dialog-content" #content>
    <div
        class="grid"
        gdAreas="startDate startTime | endDate endTime"
        gdAreas.lt-sm="startDate | startTime | endDate | endTime"
        gdRows="auto auto"
        gdColumns="auto auto"
        gdGap="25px">
        <div gdArea="startDate">
            <mat-form-field class="filter-select wide" appearance="outline">
                <mat-label>Start Date</mat-label>
                <input
                    matInput
                    [matDatepicker]="startDatepicker"
                    [(ngModel)]="startDate"
                    (dateChange)="setStartDate($event)"
                    readonly
                    [min]="minDate"
                    [max]="endDate" />
                <mat-datepicker-toggle matSuffix [for]="startDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #startDatepicker [startAt]="startDate" disabled="false"></mat-datepicker>
            </mat-form-field>
        </div>
        <div gdArea="startTime">
            <mat-form-field appearance="outline" class="wide">
                <mat-select (selectionChange)="changeStartTime($event.value)" [(ngModel)]="startTime">
                    <mat-option *ngFor="let step of timeSteps" [value]="step" [disabled]="!validStartTime(step)"
                        >{{ step.display }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div gdArea="endDate">
            <mat-form-field appearance="outline" class="wide">
                <mat-label>End Date</mat-label>
                <input
                    matInput
                    [matDatepicker]="endDatepicker"
                    [(ngModel)]="endDate"
                    (dateChange)="setEndDate($event)"
                    readonly
                    [min]="startDate"
                    [max]="maxDate" />
                <mat-datepicker-toggle matSuffix [for]="endDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #endDatepicker disabled="false"></mat-datepicker>
            </mat-form-field>
        </div>
        <div gdArea="endTime">
            <mat-form-field appearance="outline" class="wide">
                <mat-select (selectionChange)="changeEndTime($event.value)" [(ngModel)]="endTime">
                    <mat-option *ngFor="let step of timeSteps" [value]="step" [disabled]="!validEndTime(step)"
                        >{{ step.display }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</div>

<div class="dialog-actions">
    <button mat-flat-button color="primary" (click)="updateFilters()">Update</button>
</div>
