import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSelectChange} from '@angular/material/select';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {GenDataQuality} from 'frontend/generated/serverModels/GenDataQuality';
import {GenOutageData} from 'frontend/generated/serverModels/GenOutageData';
import {ChartBounds} from 'frontend/src/app/classes/chart-bounds';
import {DataService} from 'frontend/src/app/services/data.service';
import {HttpInterceptorService} from 'frontend/src/app/services/http-interceptor.service';
import * as moment from 'moment';
import {filter, throttleTime} from 'rxjs/operators';
import {CardFilters} from '../../../../../shared/classes/card-filters';
import {ChartDataService} from '../../../../../shared/services/chart-data.service';
import {ApplicationConfig} from '../../../../classes/application-config';
import {FileDownload} from '../../../../classes/file-download';
import {ModalConfig} from '../../../../classes/modal-config';
import {PopoverElement} from '../../../../classes/popover-element';
import {State} from '../../../outage/classes/state';
import {Report} from '../../classes/report';
import {UtilityIntegrity} from '../../classes/utility-integrity';
import {UtilityLinkModalComponent} from '../../modals/utility-link-modal/utility-link-modal.component';
import {ReportService} from '../../services/report.service';
import {DataPoint} from '../../../../classes/data-point';
import {EagleiBaseChart} from 'frontend/src/app/classes/charts/base-chart';

@Component({
    selector: 'eaglei-utility-integrity-report',
    templateUrl: './utility-integrity-report.component.html',
    styleUrls: ['../reports.scss', './utility-integrity-report.component.scss'],
})
export class UtilityIntegrityReportComponent extends Report implements OnInit, AfterViewInit {
    // HTML Elements
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild('paginator') paginator: MatPaginator;
    @ViewChild('chartTarget') chartTarget: ElementRef;

    // Table Properties
    public readonly columnNames: string[] = [
        'eagle_id',
        'utility_name',
        'acronym',
        'covered_customers',
        'state',
        'last_outage_date',
        'script_status',
        'resolution',
        'url',
        'twitter_link',
    ];

    // Filter Properties
    private startDate: moment.Moment = moment().subtract(1, 'days').startOf('day');
    private endDate: moment.Moment = moment();
    public showOverrideColor: boolean;
    public expanded: GenDataQuality;
    public selectedStates: State[] = [];
    public selectedStatus: 'all' | 'enabled' | 'disabled' = 'all';

    // Chart Properties
    public baseChart = new EagleiBaseChart<any, any, GenOutageData>();
    private chartData: GenOutageData[] = [];

    public loading = true;
    public showMask = true;
    public readonly overrideColor: string = ApplicationConfig.overrideColor;

    constructor(
        public reportService: ReportService,
        private chartService: ChartDataService,
        private dialog: MatDialog,
        private cd: ChangeDetectorRef
    ) {
        super();

        this.overrideColor = ApplicationConfig.currentUserPreferences.getValue().getOverrideColor();
    }

    public ngOnInit(): void {
        this.reportService.getReportData().subscribe((r) => this.initializeReportInfo(r));

        this.showOverrideColor = ApplicationConfig.showOverrideColor.getValue();
        this.selectedStates = DataService.states.getValue();
    }

    public ngAfterViewInit(): void {
        this.initializeLineChart();
        this.getUtilityIntegrity();

        ApplicationConfig.resizeEvent
            .pipe(
                filter(() => !!this.baseChart.eChart),
                throttleTime(500)
            )
            .subscribe(() => {
                this.baseChart.eChart.resize();
                this.cd.detectChanges();
            });
    }

    /**
     * API call to get the utility table and chart data
     */
    private getUtilityIntegrity(unselectRow: boolean = true): void {
        this.loading = true;
        if (unselectRow) {
            this.expanded = undefined as any;
        }
        this.showMask = true;

        this.clearInterceptor();

        HttpInterceptorService.pendingRequests['utility'] = this.reportService
            .getDataQuality(moment(this.startDate), this.endDate)
            .subscribe((res) => {
                delete HttpInterceptorService.pendingRequests['utility'];

                this.initializeData(res.data.map((d) => new UtilityIntegrity(d)));
            });
    }

    /**
     * Initialize the data source for the table and connect the table data to chart data
     * @param data Data to add for the table
     */
    private initializeData(data: GenDataQuality[]): void {
        if (!data) {
            return;
        }

        if (this.dataSource) {
            this.dataSource.data = data;
        } else {
            this.dataSource = new MatTableDataSource<GenDataQuality>(data);
            this.dataSource.sortingDataAccessor = this.dataAccessor;
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.dataSource.filterPredicate = this.filterPredicate.bind(this);
        }

        this.filterData(this.dataSource.filter);
        this.expanded = this.dataSource.data.find(
            (d) =>
                d.utilityId === this.expanded?.utilityId &&
                d.subUtilityId === this.expanded?.subUtilityId &&
                d.stateId === this.expanded?.stateId
        );
        if (!!this.expanded) {
            this.chartData = this.expanded.outages;
            this.renderChart();
        }
        this.loading = false;
    }

    /**
     * Checks each utility to filter
     * @param data Utility to filter
     * @param text Search string to check
     */
    private filterPredicate(data: GenDataQuality, text: string): boolean {
        const scriptCheck =
            this.selectedStatus === 'all' ? true : this.selectedStatus === 'enabled' ? data.activeStatus : !data.activeStatus;
        const nameCheck = [data.utilityName, data.utilityId, data.acronym]
            .join(',')
            .trim()
            .toLowerCase()
            .includes(text.trim().toLowerCase());
        const stateCheck = this.selectedStates.map((s) => s.abbreviation).includes(data.stateAbbreviation);

        const filterCheck = nameCheck && stateCheck && scriptCheck;

        if (
            !!this.expanded &&
            this.expanded.utilityId === data.utilityId &&
            this.expanded.subUtilityId === data.subUtilityId &&
            !filterCheck
        ) {
            this.expanded = undefined;
            this.showMask = true;
        }

        return filterCheck;
    }

    /**
     * Checks each utility to be sorted
     * @param data Utility to be sorted
     * @param column Column being sorted
     */
    public dataAccessor(data: GenDataQuality, column: string): string | number {
        switch (column) {
            case 'eagle_id':
                return +data.utilityId;
            case 'utility_name':
                return data.utilityName.toLowerCase();
            case 'covered_customers':
                return data.coveredCustomers;
            case 'actual_customers':
                return data.actualCustomers;
            case 'state':
                return data.stateAbbreviation;
            case 'last_outage_date':
                return data.lastOutage?.valueOf();
            case 'script_status':
                return data.activeStatus ? 0 : 1;
            case 'resolution':
                return data.resolution;
            case 'acronym':
                return data.acronym;
            default:
                return '';
        }
    }

    public changeSelectedLocations(states: State[]): void {
        this.selectedStates = [...states];
        this.dataSource.filter = this.dataSource.filter.length > 0 ? this.dataSource.filter : ' ';
    }

    /**
     * Toggles a given row to expand or collapse
     * @param row Row to expand
     */
    public toggleExpansion(row: GenDataQuality): void {
        this.expanded = this.expanded === row ? (undefined as any) : row;
        this.chartData = row.outages;

        if (this.expanded && row.outages.length > 0) {
            this.renderChart();
        } else {
            this.showMask = true;
        }
    }

    /**
     * Opens the modal to view Utility's links
     * @param util Utility to view
     */
    public viewUtilScripts(util: GenDataQuality): void {
        this.dialog.open(UtilityLinkModalComponent, {
            data: util,
            width: ModalConfig.getModalWidth(),
        });
    }

    /**
     * Stops propagation
     * @param event Triggered event
     */
    public stopPropagation(event: KeyboardEvent): void {
        event.stopPropagation();
    }

    /**
     * Updates the dates and gets the new data
     * @param dates Dates to update to
     */
    public updateDates(dates: CardFilters): void {
        this.startDate = dates.startDate;
        this.endDate = dates.endDate;
        this.getUtilityIntegrity(false);
        this.showMask = !!!this.expanded;
    }

    /**
     * Filters the data to the search string
     * @param value Search string
     */
    public filterData(value: string): void {
        this.dataSource.filter = value === '' ? ' ' : value;
    }

    /**
     * Creates the line chart and sets default options
     */
    private initializeLineChart(): void {
        this.baseChart.interactivePopover = false;
        this.baseChart.initializeEChart(this.chartTarget.nativeElement, true, 'Date', 'Customers Out');

        this.baseChart.getYAxis().nameTextStyle = {
            padding: [0, 0, 0, 0],
        };

        this.baseChart.setOptions();
    }

    /**
     * Summarize the data to display on the chart
     */
    private summarizeChartData(): ChartBounds<any> {
        const getKey = (o: GenOutageData) => moment(o.timeStamp).valueOf();

        const createDataPoint = (o: GenOutageData) => {
            const d = {
                x: moment(o.timeStamp).valueOf(),
                y: 0,
                timeStamp: moment(o.timeStamp),
                hasOverride: o.hasOverrideData,
            };
            return new DataPoint(d);
        };

        const updateDataPoint = (dp: DataPoint, utility: GenOutageData) => {
            dp.data.y += utility.customersOut || 0;
            dp.data.hasOverride = dp.data.hasOverride || utility.hasOverrideData;
        };

        let data = this.chartData;

        const bounds = new ChartBounds<DataPoint, GenOutageData>();

        let resolutionInMs: number;
        const diffInDays: number = this.endDate.diff(this.startDate, 'days');
        if (diffInDays >= 2) {
            if (diffInDays < 7) {
                resolutionInMs = 1000 * 60 * 60;
                data = this.groupChartData(this.chartData, resolutionInMs);
            } else {
                resolutionInMs = 1000 * 60 * 60 * 24;
                data = this.groupChartData(this.chartData, resolutionInMs);
            }
        }

        bounds.rawData = this.chartData;
        bounds.data = this.chartService.getTimeBars(data, getKey, createDataPoint, updateDataPoint, resolutionInMs);

        return bounds;
    }

    /**
     * Groups the given chart data based on the step interval
     * @param data Chart data to group
     * @param step Step interval to base the grouping off of
     * @returns Refined List of the chart data
     */
    private groupChartData(data: GenOutageData[], step: number): GenOutageData[] {
        const roundStep = step >= 1000 * 60 * 60 * 24 ? 'day' : 'hour';

        // Groups data based on round step
        const groupedData = [];
        data.forEach((d) => {
            const dateValue = moment(d.timeStamp).startOf(roundStep).valueOf();

            if (!groupedData[dateValue]) {
                groupedData[dateValue] = [];
            }

            groupedData[dateValue].push(d);
        });

        // Refines the data to something the chart can read
        const refinedData = [];
        Object.entries(groupedData).forEach((gd) => {
            const values = gd[1];

            const newData = new GenOutageData({customersOut: 0});
            values.forEach((d) => {
                newData.customersOut = Math.max(newData.customersOut, d.customersOut);
                // newData.customersOut += d.customersOut;
                newData.timeStamp = moment(d.timeStamp).startOf(roundStep);
            });

            refinedData.push(newData);
        });

        return refinedData;
    }

    /**
     * Renders the line chart
     */
    private renderChart(): void {
        this.loading = true;
        const chartBounds = this.summarizeChartData();

        if (!chartBounds || !(chartBounds.data.length > 0)) {
            this.showMask = true;
            this.loading = false;
            return;
        }

        const transform = (val) => Report.momentPipe.transform(val);

        // const dataPointsMap: Map<number, any[]> = new Map();
        // let mapIndexer = 0;
        // const stepSizeMs: number = (1000 * 60 * 15);
        // for (let i = 0; i < chartBounds.data.length; i++) {
        //   const dp1 = chartBounds.data[i];
        //   const dp2 = chartBounds.data[i + 1];
        //   if (i + 1 === chartBounds.data.length) {
        //     break;
        //   }
        //   const diffInMs = Math.abs(moment(dp1.data.x).diff(moment(dp2.data.x)));
        //   const numberOfSteps = diffInMs / stepSizeMs;
        //   if (numberOfSteps > 1) {
        //     mapIndexer++;
        //     // TODO: create an empty line from these two points
        //   } else {
        //     if (dataPointsMap.has(mapIndexer)) {
        //       dataPointsMap.get(mapIndexer).push(dp1);
        //     } else {
        //       dataPointsMap.set(mapIndexer, [dp1]);
        //     }
        //   }
        // }

        const getxValues = chartBounds.data.map((dp) => moment(dp.data.x).valueOf());
        const getYValues = chartBounds.data.map((dp) => {
            return {
                value: dp.data.y,
                itemStyle: {
                    color: ApplicationConfig.chartLineColor,
                },
                popoverData: [
                    new PopoverElement('Title', `${transform(dp.data.x)}`),
                    new PopoverElement('Max Customers Out', `${Report.numberPipe.transform(dp.data.y)}`),
                ],
                exportData: {
                    xValue: moment(dp.data.x).format(),
                    yValue: dp.data.y,
                },
            };
        });

        this.baseChart.eChartOptions.xAxis['data'] = getxValues;
        this.baseChart.eChartOptions.xAxis['name'] = this.getSubtext();

        this.baseChart.eChartOptions.series = [
            {
                type: 'bar',
                data: getYValues,
            },
        ];

        this.baseChart.eChart.setOption(this.baseChart.eChartOptions);
        this.loading = false;
        this.showMask = false;
    }

    /**
     * Get the subtext for the chart
     */
    private getSubtext(): string {
        const diffInDays: number = this.endDate.diff(this.startDate, 'days');

        let interval = '15 minutes';
        if (diffInDays >= 7) {
            interval = '1 day';
        } else if (diffInDays >= 2) {
            interval = '1 hour';
        }
        return `Outages reported in ${interval} intervals`;
    }

    /**
     * Gets the text for the mask
     */
    public getMaskText(): string {
        let text = 'Loading...';
        if (!this.expanded && !this.loading) {
            text = 'No Utility Selected';
        } else if (!this.loading && this.chartData.length === 0) {
            text = 'No Data Available';
        }

        return text;
    }

    public getCountyCustomerText(info: any): string {
        return !!info.coveredCustomers ? `(${info.modelCount > 0 ? 'Modeled' : 'Collected'})` : '';
    }

    // Exports
    /**
     * Exports the data from the table into an CSV
     */
    public exportTable(): void {
        const columns = [
            'EagleI ID',
            'Utility Name',
            'Acronym',
            'Covered Customers',
            'State/Territory',
            'Last Outage Date',
            'Script Status',
            'Resolution',
            'Outage Map URL',
            'Twitter Link',
        ];
        let data = `${this.startDate.format()} - ${this.endDate.format()}\n\n`;
        data += columns.join() + '\n';

        this.dataSource._orderData(this.dataSource.filteredData).forEach((util) => {
            data += [
                FileDownload.formatCsvCell(util.utilityId),
                FileDownload.formatCsvCell(util.utilityName),
                FileDownload.formatCsvCell(util.acronym),
                FileDownload.formatCsvCell(util.coveredCustomers),
                FileDownload.formatCsvCell(util.stateAbbreviation),
                FileDownload.formatCsvCell(!!util.lastOutage ? moment(util.lastOutage).format() : 'Not Available'),
                FileDownload.formatCsvCell(util.activeStatus ? 'Enabled' : 'Disabled'),
                FileDownload.formatCsvCell(this.getResolution(util.resolution)),
                FileDownload.formatCsvCell(this.getOutageURL(util)),
                FileDownload.formatCsvCell(util.twitterURL),
            ].join();
            data += '\n';
        });

        FileDownload.downloadCSV('utilityIntegrity', data);
    }

    /**
     * Exports chart as a PNG
     */
    public exportChart(): void {
        const title = `Utility Data Quality For ${this.expanded.utilityName} (${Report.momentPipe.transform(
            this.startDate
        )} - ${Report.momentPipe.transform(this.endDate)})`;

        FileDownload.exportChartAsPNG(this.baseChart.eChart, title, title, this.attributionUrl);
    }

    /**
     * Gets the resolution formatted properly for the utility
     * @param res Resolution to format
     */
    public getResolution(res: string): string {
        return res ? res.replace(/_/gm, ' ') : 'UNKNOWN';
    }

    /**
     * Gets the correct outage map url
     * @param res Utility to find correct outage map url
     */
    public getOutageURL(res: GenDataQuality): string {
        const url = res?.mapURL;
        return url ? url : undefined;
    }

    public updateStatusFilter(status: MatSelectChange): void {
        this.selectedStatus = status.value;

        this.filterData(this.dataSource.filter);
    }

    /**
     * Clear the HTTP Interceptor
     */
    public clearInterceptor(): void {
        if (HttpInterceptorService.pendingRequests['utility']) {
            HttpInterceptorService.pendingRequests['utility'].unsubscribe();
        }
    }
}
