
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import { GenOutageAggregationLevel } from './GenOutageAggregationLevel';
import * as moment from 'moment';

export class GenTrainingAccessRequest {

    aggregationLevel: GenOutageAggregationLevel;
    endDate: moment.Moment;
    locationIds: number[];
    reason: string;
    startDate: moment.Moment;

    constructor();
    constructor( jsonObj: any );
    constructor( genTrainingAccessRequest: GenTrainingAccessRequest);

    constructor( input?: GenTrainingAccessRequest ) {
        if ( input && typeof input !== 'number' ) {
            this.aggregationLevel = (typeof input.aggregationLevel === 'string') ? GenOutageAggregationLevel.forName(<string> input.aggregationLevel) : <any> input.aggregationLevel; // GenOutageAggregationLevel
            if ( input.endDate && typeof input.endDate === 'string' ) {
                this.endDate = moment(input.endDate).utc();
            } else {
                this.endDate = input.endDate;
            }
            this.locationIds = input.locationIds; // list [number]
            this.reason = input.reason; // string [string]
            if ( input.startDate && typeof input.startDate === 'string' ) {
                this.startDate = moment(input.startDate).utc();
            } else {
                this.startDate = input.startDate;
            }
       }
    }

    static jsonResponseToList(arr: any[]): GenTrainingAccessRequest[] {
        if (arr && arr.map) {
          return arr.map(a => new GenTrainingAccessRequest(a));
        } else {
          return [];
        }
    }
}

