<form [formGroup]="filterGroup" class="filter-bar ignore-last">
    <mat-button-toggle-group class="light" formControlName="type" multiple>
        <mat-button-toggle
            *ngIf="dashboards.electric"
            matTooltip="Electric Dashboard"
            matTooltipPosition="above"
            matTooltipShowDelay="250"
            [value]="layoutTypes.ELECTRIC">
            <mat-icon fontSet="fas" fontIcon="fa-plug"></mat-icon>
        </mat-button-toggle>
        <mat-button-toggle
            *ngIf="dashboards.oil"
            matTooltip="Oil Dashboard"
            matTooltipPosition="above"
            matTooltipShowDelay="250"
            [value]="layoutTypes.OIL">
            <mat-icon fontSet="fas" fontIcon="fa-burn"></mat-icon>
        </mat-button-toggle>
        <mat-button-toggle
            *ngIf="dashboards.ng"
            matTooltip="Natural Gas Dashboard"
            matTooltipPosition="above"
            matTooltipShowDelay="250"
            [value]="layoutTypes.NATURAL_GAS">
            <mat-icon fontSet="fas" fontIcon="fa-fire-alt"></mat-icon>
        </mat-button-toggle>
        <mat-button-toggle
            *ngIf="dashboards.analytic"
            matTooltip="Analytic Dashboard"
            matTooltipPosition="above"
            matTooltipShowDelay="250"
            [value]="layoutTypes.ANALYTIC">
            <mat-icon fontSet="fas" fontIcon="fa-magnifying-glass-chart"></mat-icon>
        </mat-button-toggle>
        <mat-button-toggle
            *ngIf="dashboards.admin"
            matTooltip="Associated Documents/Status"
            matTooltipPosition="above"
            matTooltipShowDelay="250"
            [value]="layoutTypes.ADMIN">
            <mat-icon fontSet="fas" fontIcon="fa-file-import"></mat-icon>
        </mat-button-toggle>
    </mat-button-toggle-group>

    <mat-form-field class="filter-select active" appearance="outline">
        <mat-label>Event</mat-label>
        <mat-select formControlName="event">
            <mat-optgroup *ngFor="let item of events | keyvalue" [label]="item.key | titlecase">
                <mat-option *ngFor="let event of item.value" [value]="event">{{ event.name }}</mat-option>
            </mat-optgroup>
        </mat-select>
    </mat-form-field>

    <eaglei-location-filter
        [allowedStateSelection]="eventStates"
        [defaultSelection]="filterGroup.controls.locations.value"
        [allowDisableSelection]="true"
        (locations)="filterGroup.controls.locations.setValue($event)">
    </eaglei-location-filter>
</form>

<eaglei-time-slider
    [sliderStart]="filterGroup.controls.event.value?.eventStart"
    [sliderEnd]="filterGroup.controls.event.value?.eventEnd"
    [sections]="filterGroup.controls.event.value?.phases"
    [allowDateChange]="false"
    [allowFutureStep]="isTrainingEnvironment"
    (dateEvent)="updateDate($event)">
</eaglei-time-slider>

<ng-container *ngFor="let name of filterGroup.controls.type.value">
    <mat-card>
        <mat-card-subtitle>{{ name | dashboardType }}</mat-card-subtitle>
        <mat-card-content [ngSwitch]="name">
            <div *ngSwitchCase="layoutTypes.ELECTRIC" class="electric-container">
                <eaglei-widget-list [dashboard]="dashboards.electric.widgets"></eaglei-widget-list>
            </div>
            <div *ngSwitchCase="layoutTypes.NATURAL_GAS" class="ng-container">
                <eaglei-widget-list [dashboard]="dashboards.ng.widgets"></eaglei-widget-list>
            </div>
            <div *ngSwitchCase="layoutTypes.OIL" class="oil-container">
                <eaglei-widget-list [dashboard]="dashboards.oil.widgets"></eaglei-widget-list>
            </div>
            <div *ngSwitchCase="layoutTypes.ANALYTIC" class="analytic-container">
                <eaglei-widget-list
                    [gridOptions]="{compactType: 'compactUp&Left'}"
                    [dashboard]="dashboards.analytic.widgets"></eaglei-widget-list>
            </div>
            <div *ngSwitchCase="layoutTypes.ADMIN" class="admin-container">
                <eaglei-widget-list
                    [gridOptions]="{compactType: 'compactUp&Left'}"
                    [dashboard]="dashboards.admin.widgets"></eaglei-widget-list>
            </div>
        </mat-card-content>
    </mat-card>
</ng-container>
