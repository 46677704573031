<div #content class="widget-content">
    <div class="container">
        <div class="title-points">
            <div class="points">
                <p>{{ points }}</p>
            </div>
            <span>Receipt/Delivery Points <br />Over Capacity</span>
        </div>
        <div class="clickable" (click)="viewReport()">
            <mat-icon inline fontSet="fas" fontIcon="fa-file-invoice" class="icon"></mat-icon>
            <span class="sub-text center-spacing view-report">View Report</span>
        </div>
        <eaglei-info-icon [attributionModalText]="attributionModalText" [widgetName]="widgetName" class="icon"></eaglei-info-icon>
    </div>
</div>
