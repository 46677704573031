
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!


export class GenEOCStateCollection {

    id: number;
    active: boolean;
    stateId: number;
    stateName: string;
    url: string;
    utilityId: number;

    constructor();
    constructor( jsonObj: any );
    constructor( genEOCStateCollection: GenEOCStateCollection);
    constructor( id: number );

    constructor( input?: GenEOCStateCollection|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            this.active = input.active; // boolean [boolean]
            this.stateId = input.stateId; // int [number]
            this.stateName = input.stateName; // string [string]
            this.url = input.url; // string [string]
            this.utilityId = input.utilityId; // int [number]
       }
    }

    static jsonResponseToList(arr: any[]): GenEOCStateCollection[] {
        if (arr && arr.map) {
          return arr.map(a => new GenEOCStateCollection(a));
        } else {
          return [];
        }
    }
}

