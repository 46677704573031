<eaglei-leaflet-layer-header [layer]="this" (activeToggled)="toggleLayer($event)" [hideLegend]="true"></eaglei-leaflet-layer-header>

<ng-container *ngIf="isActive">
    <div #legendElement>
        <div class="vertical-legend">
            <div>
                <span class="triangle deployed">&#9650;</span>
                <span>Deployed</span>
            </div>
            <div>
                <span class="triangle not-deployed">&#9650;</span>
                <span>Not Deployed</span>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="isActive && canEdit">
    <div class="management-link" (click)="editLocations()">
        <mat-icon fontSet="fas" fontIcon="fa-external-link-alt"></mat-icon>
        <span>Edit Location Information</span>
    </div>
</ng-container>
