import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SystemNewsFeedComponent} from './components/system-news-feed/system-news-feed.component';

const routes: Routes = [
    {
        path: '',
        component: SystemNewsFeedComponent,
        data: {
            useNewDesign: true,
        },
    },
    {
        path: 'faq',
        component: SystemNewsFeedComponent,
        data: {
            title: 'Frequently Asked Questions',
            onlyFaq: true,
            useNewDesign: true,
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class SystemNewsRoutingModule {}
