import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReportWidgetComponent} from './components/report-widget/report-widget.component';
import {MaterialModule} from '../../material.module';
import {FlexLayoutModule} from '@angular/flex-layout';
import {StatWidgetComponent} from './components/summary-widgets/stat-widget/stat-widget.component';
import {SharedModule} from 'frontend/src/shared/shared.module';
import {UtilitiesImpactedWidgetComponent} from './components/summary-widgets/utilities-impacted-widget/utilities-impacted-widget.component';
import {ChartWidgetComponent} from './components/chart-widget/chart-widget.component';
import {SaveLayoutModalComponent} from './modals/save-layout-modal/save-layout-modal.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {OutageMapWidgetComponent} from './components/outage-map-widget/outage-map-widget.component';
import {SummaryWidgetComponent} from './components/summary-data/summary-widget.component';
import {ConfigureWidgetStatModalComponent} from './modals/configure-widget-stat-modal/configure-widget-stat-modal.component';
import {ConfigureWidgetSummaryModalComponent} from './modals/configure-widget-summary-modal/configure-widget-summary-modal.component';
import {RouterModule} from '@angular/router';
import {MapModule} from '../map/map.module';
import {CommunityWidgetComponent} from './components/community-widget/community-widget.component';
import {OilRefineryAlertWidgetComponent} from './components/oil-refinery-alert-widget/oil-refinery-alert-widget.component';
import {EiaWeeklyPetroleumReportWidgetComponent} from './components/eia-weekly-petroleum-report-widget/eia-weekly-petroleum-report-widget.component';
import {OilPlannedOutageWidgetComponent} from './components/oil-planned-outage-widget/oil-planned-outage-widget.component';
import {OilStorageWidgetComponent} from './components/oil-storage-widget/oil-storage-widget.component';
import {OilBarrelFlowWidgetComponent} from './components/oil-barrel-flow-widget/oil-barrel-flow-widget.component';
import {RefineryStatusWidgetComponent} from './components/refinery-status-widget/refinery-status-widget.component';
import {PowerEstimateWidgetComponent} from './components/power-estimate-widget/power-estimate-widget.component';
import {NgNoticesWidgetComponent} from './components/ng-notices-widget/ng-notices-widget.component';
import {ConfigureWidgetNoticesModalComponent} from './modals/configure-widget-notices-modal/configure-widget-notices-modal.component';
import {DerivedStorageWidgetComponent} from './components/derived-storage-widget/derived-storage-widget.component';
import {UndergroundStorageWidgetComponent} from './components/underground-storage-widget/underground-storage-widget.component';
import {ConfigureWidgetOngPlannedOutagesModalComponent} from './modals/configure-widget-ong-planned-outages-modal/configure-widget-ong-planned-outages-modal.component';
import {GasBurnWidgetComponent} from './components/gas-burn-widget/gas-burn-widget.component';
import {OilFlowTableWidgetComponent} from './components/oil-flow-table-widget/oil-flow-table-widget.component';
import {NgNominationWidgetComponent} from './components/ng-nomination-widget/ng-nomination-widget.component';
import {OilRefineryAlertInfoModalComponent} from './modals/oil-refinery-alert-info-modal/oil-refinery-alert-info-modal.component';
import {NgReceiptDeliveryWidgetComponent} from './components/ng-receipt-delivery-widget/ng-receipt-delivery-widget.component';
import {NgOperationalFlowWidgetComponent} from './components/ng-operational-flow-widget/ng-operational-flow-widget.component';
import {HonorPreferenceIndicatorComponent} from './components/honor-preference-indicator/honor-preference-indicator.component';
import {RestorationWidgetComponent} from './components/restoration-widget/restoration-widget.component';
import {HeadoutTableWidgetComponent} from './components/headout-table-widget/headout-table-widget.component';
import {WidgetListComponent} from './components/widget-list/widget-list.component';
import {GridsterModule} from 'angular-gridster2';
import {CommunityModule} from '../community/community.module';
import {LayerModule} from '../layer/layer.module';
import {OutageModule} from '../outage/outage.module';
import {OilCapacityWidgetComponent} from './components/oil-capacity-widget/oil-capacity-widget.component';
import {SnapshotWidgetComponent} from './components/snapshot-widget/snapshot-widget.component';
import {PortWidgetComponent} from './components/port-widget/port-widget.component';
import {SituationWidgetComponent} from './components/situation-widget/situation-widget.component';
import {RrccWidgetComponent} from './components/rrcc-widget/rrcc-widget.component';
import {RrccLocationInfoModalComponent} from './modals/rrcc-location-info-modal/rrcc-location-info-modal.component';
import {DocumentWidgetComponent} from './components/eil-document-widget/document-widget.component';
import {SystemNewsWidgetComponent} from './components/system-news-widget/system-news-widget.component';
import {SystemNewsModule} from '../system-news/system-news.module';
import {PoleDetectionWidgetComponent} from './components/pole-detection-widget/pole-detection-widget.component';
import {PnnlRadrWidgetComponent} from './components/pnnl-radr-widget/pnnl-radr-widget.component';
import {WaoWidgetComponent} from './components/wao-widget/wao-widget.component';
import {UrbanNetWidgetComponent} from './components/urbannet-widget/urbannet-widget.component';
import {NomChartComponent} from '../../../shared/components/nom-chart/nom-chart.component';

@NgModule({
    declarations: [
        ReportWidgetComponent,
        ChartWidgetComponent,
        StatWidgetComponent,
        UtilitiesImpactedWidgetComponent,
        SaveLayoutModalComponent,
        OutageMapWidgetComponent,
        SummaryWidgetComponent,
        ConfigureWidgetStatModalComponent,
        ConfigureWidgetSummaryModalComponent,
        CommunityWidgetComponent,
        OilRefineryAlertWidgetComponent,
        EiaWeeklyPetroleumReportWidgetComponent,
        OilPlannedOutageWidgetComponent,
        OilBarrelFlowWidgetComponent,
        GasBurnWidgetComponent,
        OilStorageWidgetComponent,
        NgNoticesWidgetComponent,
        ConfigureWidgetNoticesModalComponent,
        OilRefineryAlertWidgetComponent,
        EiaWeeklyPetroleumReportWidgetComponent,
        UndergroundStorageWidgetComponent,
        DerivedStorageWidgetComponent,
        ConfigureWidgetOngPlannedOutagesModalComponent,
        OilFlowTableWidgetComponent,
        RefineryStatusWidgetComponent,
        PowerEstimateWidgetComponent,
        NgNominationWidgetComponent,
        OilRefineryAlertInfoModalComponent,
        NgReceiptDeliveryWidgetComponent,
        NgOperationalFlowWidgetComponent,
        HonorPreferenceIndicatorComponent,
        RestorationWidgetComponent,
        HeadoutTableWidgetComponent,
        WidgetListComponent,
        OilCapacityWidgetComponent,
        SnapshotWidgetComponent,
        PortWidgetComponent,
        SituationWidgetComponent,
        RrccWidgetComponent,
        RrccLocationInfoModalComponent,
        DocumentWidgetComponent,
        SystemNewsWidgetComponent,
        PoleDetectionWidgetComponent,
        PnnlRadrWidgetComponent,
        WaoWidgetComponent,
        UrbanNetWidgetComponent,
    ],
    imports: [
        CommonModule,
        MaterialModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        FormsModule,
        SharedModule,
        RouterModule,
        MapModule,
        GridsterModule,
        // Needed for Some widgets to run
        LayerModule,
        CommunityModule,
        OutageModule,
        SystemNewsModule,
        NomChartComponent,
    ],
    exports: [
        ReportWidgetComponent,
        ChartWidgetComponent,
        StatWidgetComponent,
        UtilitiesImpactedWidgetComponent,
        OutageMapWidgetComponent,
        SummaryWidgetComponent,
        CommunityWidgetComponent,
        GasBurnWidgetComponent,
        PowerEstimateWidgetComponent,
        OilBarrelFlowWidgetComponent,
        OilRefineryAlertWidgetComponent,
        EiaWeeklyPetroleumReportWidgetComponent,
        OilPlannedOutageWidgetComponent,
        UndergroundStorageWidgetComponent,
        DerivedStorageWidgetComponent,
        RefineryStatusWidgetComponent,
        NgNoticesWidgetComponent,
        OilStorageWidgetComponent,
        OilFlowTableWidgetComponent,
        NgNominationWidgetComponent,
        NgReceiptDeliveryWidgetComponent,
        NgOperationalFlowWidgetComponent,
        RestorationWidgetComponent,
        HeadoutTableWidgetComponent,
        WidgetListComponent,
        SituationWidgetComponent,
        DocumentWidgetComponent,
        SystemNewsWidgetComponent,
        UrbanNetWidgetComponent,
    ],
})
export class WidgetModule {}
