<div #content [class.onPhone]="onPhone()" class="content new-design">
    <div #mapContainer class="map-container" [class.isFullscreen]="liteMap?.isFullscreen">
        <eaglei-map-lite #mapTarget></eaglei-map-lite>
    </div>

    <div #tableContainer *ngIf="!liteMap?.isFullscreen" class="table-container">
        <div style="overflow: auto">
            <table
                #materialTable
                mat-table
                [dataSource]="dataSource"
                matSort
                [matSortDisableClear]="true"
                matSortActive="customers_out"
                matSortDirection="desc">
                <ng-container matColumnDef="name" sticky>
                    <th mat-header-cell *matHeaderCellDef class="name-column">Name</th>
                    <td mat-cell *matCellDef="let outage" class="name-column">
                        {{ outage.title | stateName }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="customers_out">
                    <th mat-header-cell *matHeaderCellDef>Customers Out</th>
                    <td mat-cell *matCellDef="let outage">{{ outage.currentOutage | eagleiNumber }}</td>
                </ng-container>

                <ng-container matColumnDef="total_customers">
                    <th mat-header-cell *matHeaderCellDef>Total Customers</th>
                    <td mat-cell *matCellDef="let outage">{{ outage.coveredCustomers | eagleiNumber }}</td>
                </ng-container>

                <ng-container matColumnDef="percent_out">
                    <th mat-header-cell *matHeaderCellDef>Percent Out</th>
                    <td mat-cell *matCellDef="let outage">
                        <div *ngIf="validPercent(outage.percentOut)">{{ outage.percentOut | percent: '1.2-2' }}</div>
                        <div *ngIf="!validPercent(outage.percentOut)">Not Available</div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="last_hour">
                    <th mat-header-cell *matHeaderCellDef>Last Hour Max</th>
                    <td mat-cell *matCellDef="let outage">
                        <span>{{ outage.maxOutage1 | eagleiNumber }}</span>
                        <br />
                        <span class="hint-date">{{ outage.maxOutage1RunStartTime | momentDate }}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="last_day">
                    <th mat-header-cell *matHeaderCellDef>Last Day Max</th>
                    <td mat-cell *matCellDef="let outage">
                        <span>{{ outage.maxOutage24 | eagleiNumber }}</span>
                        <br />
                        <span class="hint-date">{{ outage.maxOutage24RunStartTime | momentDate }}</span>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
                <tr mat-row *matRowDef="let row; columns: columnNames; let odd = odd"></tr>
            </table>
        </div>

        <div fxLayout="row">
            <div *ngIf="liteMap?.isLoading" fxFlex="grow" class="table-mask">
                <b>Loading ...</b>
            </div>

            <div *ngIf="dataSource && dataSource.data.length <= 0 && !liteMap?.isLoading" fxFlex="grow" class="table-mask">
                <b>No Data Available</b>
            </div>
        </div>
    </div>
</div>

<eaglei-leaflet-sidebar
    #sidebar
    *ngIf="liteMap?.mapOptions?.show?.sidebar"
    class="sidebar"
    (isOpen)="sidebarChanged($event)"
    [isLiteSidebar]="true"></eaglei-leaflet-sidebar>
