<div class="hide-overflow">
    <div class="widget-header">
        <p class="title">System News</p>
    </div>

    <div class="widget-content table-container">
        <table mat-table [dataSource]="dataSource" matSort matSortActive="displayName" matSortDirection="asc" multiTemplateDataRows>
            <ng-container *ngIf="dataSource.filteredData.length">
                <tr mat-header-row *matHeaderRowDef="columns"></tr>
            </ng-container>
            <tr mat-row *matRowDef="let row; columns: columns" class="clickable" (click)="toggleExpandedRow(row)"></tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="expandable-row"></tr>

            <ng-container *matNoDataRow>
                <td class="no-news">{{ noDocumentsText }}</td>
            </ng-container>

            <ng-container matColumnDef="title">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
                <td
                    #newsTitle
                    mat-cell
                    *matCellDef="let news"
                    class="ellipsis"
                    [matTooltip]="news.title"
                    [matTooltipDisabled]="!isEllipsis(newsTitle)">
                    {{ news.title }}
                </td>
            </ng-container>

            <ng-container matColumnDef="createDate">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Created</th>
                <td mat-cell *matCellDef="let news" class="ellipsis">
                    {{ news.createDate | momentDate: 'M/D/YY' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="expand-icon">
                <th mat-header-cell *matHeaderCellDef class="ei-icon-column"></th>
                <td mat-cell *matCellDef="let info" class="ei-icon-column">
                    <mat-icon inline fontSet="fas" [fontIcon]="info === selectedRow ? 'fa-chevron-down' : 'fa-chevron-right'"></mat-icon>
                </td>
            </ng-container>

            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let news">
                    <div class="hide-expanded-row" [class.show-expanded-row]="selectedRow === news">
                        <div class="hidden-content" [innerHTML]="news.value | sanitize"></div>
                    </div>
                </td>
            </ng-container>
        </table>
    </div>

    <div class="widget-footer">
        <mat-paginator hidePageSize showFirstLastButtons [pageSize]="8"></mat-paginator>
    </div>
</div>
