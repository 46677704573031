import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {State} from '../../../app/modules/outage/classes/state';
import {IStateOption} from '../../../app/interfaces/state-option.interface';
import {IRegionOption} from '../../../app/interfaces/region-option.interface';
import {DataService} from '../../../app/services/data.service';
import {ApplicationConfig} from '../../../app/classes/application-config';

@Component({
    selector: 'eaglei-location-chip-filter',
    templateUrl: './location-chip-filter.component.html',
    styleUrls: ['./location-chip-filter.component.scss'],
})
export class LocationChipFilterComponent implements OnInit {
    @Input() states: State[];
    @Input() showStatesByRegion: boolean = false;
    @Output() change = new EventEmitter<State[]>();

    // We have to create a new copy of the region objects, or every place it is used will show the same results.
    public regionOptions: IRegionOption[] = this.dataService.regionOptions.map((ro) => {
        return {
            regionId: ro.regionId,
            states: ro.states.map((so) => {
                return {
                    state: so.state,
                    selected: false,
                };
            }),
            selected: ro.selected,
        };
    });

    public stateOptions: IStateOption[] = this.regionOptions
        .reduce((prev, cur) => prev.concat(cur.states), [])
        .sort((a, b) => (a.state.name.toLowerCase() > b.state.name.toLowerCase() ? 1 : -1));

    constructor(private dataService: DataService) {}

    ngOnInit(): void {
        let initialStates;
        if (this.states === undefined) {
            initialStates = ApplicationConfig.currentUserPreferences.getValue().getStates().slice();
        } else {
            initialStates = this.states.slice();
        }

        this.stateOptions.forEach((so) => {
            so.selected = initialStates.findIndex((s) => s.id === so.state.id) !== -1;
        });

        this.checkRegionStatus();
    }

    public toggleRegion(region: any, value?: boolean): void {
        region.selected = value !== undefined ? value : !region.selected;
        region.states.forEach((so) => (so.selected = region.selected));

        this.emitStates();
    }

    public toggleState(state: any): void {
        state.selected = !state.selected;
        this.checkRegionStatus();
        this.emitStates();
    }

    private checkRegionStatus(): void {
        this.regionOptions.forEach((ro) => {
            ro.selected = ro.states.every((so) => so.selected);
        });
    }

    public toggleAll(active: boolean): void {
        this.stateOptions.forEach((so) => (so.selected = active));
        this.checkRegionStatus();
        this.emitStates();
    }

    private emitStates() {
        const value = this.stateOptions.filter((so) => so.selected).map((so) => so.state);
        this.change.emit(value);
    }

    public showEllipsis(element: HTMLElement): boolean {
        return element.offsetWidth < element.scrollWidth;
    }
}
