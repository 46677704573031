import {Pipe, PipeTransform} from '@angular/core';
import {GenDatapointType} from '../../../generated/serverModels/GenDatapointType';

@Pipe({
    name: 'datapointType',
})
export class DatapointTypePipe implements PipeTransform {
    transform(value: GenDatapointType, returnOriginal: boolean = false): any {
        let userFriendlyName: string;

        if (value === GenDatapointType.INSITE) {
            userFriendlyName = 'Insite';
        } else if (value === GenDatapointType.OTHER) {
            userFriendlyName = 'Other';
        } else if (value === GenDatapointType.TEARSHEET) {
            userFriendlyName = 'Tearsheet';
        } else {
            if (returnOriginal) {
                return value.toString();
            }

            // console.warn( 'unexpected role in RoleDefinition pipe ' + role );
            return value.name
                .substr(5)
                .toLowerCase()
                .replace(/_/g, ' ')
                .replace(/\b[a-z]/g, (l) => {
                    return l.toUpperCase();
                });
        }

        return userFriendlyName;
    }
}
