import {AfterViewInit, Component, computed, ElementRef, OnDestroy, Signal, signal, ViewChild, WritableSignal} from '@angular/core';
import {WidgetService} from '../../services/widget.service';
import {ResponseWrapper} from '../../../../classes/response-wrapper';
import {filter, map, takeUntil, throttleTime} from 'rxjs/operators';
import {CustomLandingLayoutComponent} from '../../../../landing/components/custom-landing-layout/custom-landing-layout.component';
import {LandingFilters} from '../../../../landing/classes/landing-filters';
import {HttpClient} from '@angular/common/http';
import {ApplicationConfig} from '../../../../classes/application-config';
import {StateCoverage} from '../../../report/classes/state-coverage';
import {BaseWidget} from '../../classes/base-widget';
import {EventSummaryFilters} from '../../../system-event/classes/event-summary-filters';
import {NomChartComponent} from '../../../../../shared/components/nom-chart/nom-chart.component';
import {toSignal} from '@angular/core/rxjs-interop';
import {GenOutageAggregationLevel} from '../../../../../../generated/serverModels/GenOutageAggregationLevel';

@Component({
    selector: 'eaglei-chart-widget',
    templateUrl: './chart-widget.component.html',
    styleUrls: ['./chart-widget.component.scss'],
})
export class ChartWidgetComponent extends BaseWidget implements AfterViewInit, OnDestroy {
    @ViewChild(NomChartComponent, {static: false}) nomChartComponent: NomChartComponent;

    // Filter Inputs

    public filters: LandingFilters;
    public attribution: string;

    // Signals
    private coverage: Signal<StateCoverage[]> = toSignal(
        this.http.get<any>('api/coverage/state').pipe(
            map((res: ResponseWrapper<StateCoverage>) => {
                this.attribution = res.metadata.url + '/attribution/national-outage-map';
                return res.data.map((d) => new StateCoverage(d));
            })
        )
    );

    protected signalFilters: WritableSignal<LandingFilters> = signal(
        new LandingFilters({
            date: ApplicationConfig.roundMinute(),
            aggregationLevel: ApplicationConfig.currentUserPreferences.getValue().getOutageAggregationLevel(),
            locations: ApplicationConfig.currentUserPreferences.getValue().getStates(),
            usePreferences: false,
        } as any)
    );

    protected chartEndDate = computed(() => {
        return this.signalFilters().date;
    });
    protected chartLocations = computed(() => {
        return this.signalFilters().locations;
    });

    protected chartStartDate = computed(() => {
        return this.chartEndDate().clone().subtract(1, 'day');
    });

    protected coveragePercent = computed(() => {
        if (this.coverage()) {
            let total = 0;
            let covered = 0;
            const selectedStates = this.chartLocations().map((state) => state.abbreviation);
            this.coverage()
                .filter((stateCoverage) => selectedStates.includes(stateCoverage.state))
                .forEach((coverage) => {
                    total += coverage.eiaTotalCustomers;
                    covered += coverage.coveredCustomersBestCase;
                });
            return covered / total;
        }
        return 0;
    });

    constructor(private widgetElement: ElementRef, private http: HttpClient) {
        super(widgetElement, true);
    }

    protected handleEventFilterChange(eventFilters: EventSummaryFilters): void {
        this.signalFilters.update((value) => {
            value.aggregationLevel = GenOutageAggregationLevel.county;
            value.date = ApplicationConfig.roundMinute(eventFilters.date);
            value.locations = eventFilters.locations.slice();
            return value;
        });
    }

    ngAfterViewInit(): void {
        CustomLandingLayoutComponent.filterChange
            .pipe(
                filter(() => !this.eventDashboard),
                takeUntil(this.destroy$)
            )
            .subscribe((filters) => {
                this.signalFilters.update((value) => {
                    return value.setDate(ApplicationConfig.roundMinute());
                });
            });

        WidgetService.resize
            .pipe(
                takeUntil(this.destroy$),
                filter((widget) => this.item.x === widget.x && this.item.y === widget.y)
            )
            .subscribe(() => this.nomChartComponent.baseChart.eChart.resize());

        WidgetService.renderWidget.pipe(takeUntil(this.destroy$), throttleTime(500)).subscribe(() => {
            this.signalFilters.update((value) => {
                return value.setDate(ApplicationConfig.roundMinute());
            });
        });
    }

    destroyWidget() {}

    onApplicationTimeChange() {
        this.signalFilters.update((value) => {
            return value.setDate(ApplicationConfig.roundMinute());
        });
    }
}
