import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatStepper} from '@angular/material/stepper';
import * as moment from 'moment';
import {filter, map, take, takeUntil} from 'rxjs/operators';
import {GenOutageAggregationLevel} from '../../../../../../generated/serverModels/GenOutageAggregationLevel';
import {CardFilters} from '../../../../../shared/classes/card-filters';
import {AggregationLevelPipe} from '../../../../../shared/pipes/aggregation-level.pipe';
import {AuthenticationService} from '../../../../services/authentication.service';
import {DataService} from '../../../../services/data.service';
import {CsvDownloadInfo} from '../../classes/csv-download-info';
import {OutageService} from '../../services/outage.service';
import {BaseModal} from '../../../../classes/base-modal';
import {MatSelectChange} from '@angular/material/select';
import {State} from '../../classes/state';
import {NumeralPipe} from 'frontend/src/shared/pipes/numeral.pipe';
import {Observable, of} from 'rxjs';

interface LocationInfo {
    name: string;
    fipsCode: string;
    selected: boolean;
    indeterminateState?: boolean;
}

@Component({
    selector: 'eaglei-csv-download-modal',
    templateUrl: './csv-download-modal.component.html',
    styleUrls: ['./csv-download-modal.component.scss'],
})
export class CsvDownloadModalComponent extends BaseModal implements OnInit {
    // HTML Properties
    @ViewChild('content') content: ElementRef;
    @ViewChild('stepper') stepper: MatStepper;

    // Misc Properties
    public femaPipe = new NumeralPipe();
    public downloadInfo: CsvDownloadInfo = new CsvDownloadInfo();

    // Form Groups
    public aggregationGroup: UntypedFormGroup;
    public filenameGroup: UntypedFormGroup;
    public locationGroup: UntypedFormGroup;

    // Date Range Properties
    public maxDate: moment.Moment = moment();
    public minDate: moment.Moment = moment().subtract(1, 'month');

    // Aggregation Properties
    public showStateSelect: boolean;
    public statesForCounty: LocationInfo[] = [];
    public outageAggregationLevels = GenOutageAggregationLevel;
    public readonly aggregationLevels = GenOutageAggregationLevel.values().filter((l) => ![GenOutageAggregationLevel.fema].includes(l));

    // Location Properties
    public locations: LocationInfo[] = [];
    private readonly locationStepIndex = 2;
    public counties: Map<string, LocationInfo[]>;

    public readonly states: LocationInfo[] = DataService.states
        .getValue()
        .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
        .map((s) => {
            return {name: s.name, fipsCode: s.statefp, selected: false};
        });

    public readonly femaRegions: LocationInfo[] = DataService.femaRegions.getValue().map((s) => {
        return {
            name: this.femaPipe.transform(s.id.toString(), true),
            fipsCode: s.id.toString(),
            indeterminateState: false,
            selected: false,
        };
    });

    // Math Properties
    public possibleTimestamps = Math.ceil(this.downloadInfo.endDate.diff(this.downloadInfo.startDate, 'minutes') / 15);
    public possibleRowsPerTimestamp$: Observable<number> = of(1);
    public invalidDate: boolean;
    public includeZeroOutages: boolean = false;

    public intervalOptions = [
        {display: '15 Minutes', value: 0},
        {display: '1 Hour', value: 1},
        {display: '4 Hours', value: 4},
        {display: '8 Hours', value: 8},
        {display: '12 Hours', value: 12},
        {display: '24 Hours', value: 24},
    ];

    constructor(
        public ref: MatDialogRef<CsvDownloadModalComponent>,
        private outageService: OutageService,
        private authService: AuthenticationService,
        private popup: MatSnackBar
    ) {
        super();
        const aggregationControls = {
            level: new FormControl<GenOutageAggregationLevel>(null, [Validators.required]),
            state: new FormControl<State[]>([], [Validators.required]),
        };

        const filenameControls = {
            name: new FormControl<string>('', [Validators.required]),
        };

        const locationControls = {
            selected: new UntypedFormControl('', [this.selectedLocation()]),
        };

        this.filenameGroup = new UntypedFormGroup(filenameControls);
        this.aggregationGroup = new UntypedFormGroup(aggregationControls);
        this.locationGroup = new UntypedFormGroup(locationControls);

        this.downloadInfo.startDate = moment().subtract(1, 'day').startOf('day');
        this.downloadInfo.endDate = moment();
    }

    afterInit() {
        this.stepper.selectionChange
            .pipe(
                filter((r) => r.selectedIndex === this.locationStepIndex),
                takeUntil(this.destroy$)
            )
            .subscribe(() => this.getLocations());
    }

    public ngOnInit(): void {
        this.aggregationGroup
            .get('level')
            .valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((val) => {
                this.translateAggregationLevel(val);

                this.downloadInfo.aggregationLevel = val;
                this.showStateSelect = this.isCountySelect(val);
                const control = this.aggregationGroup.get('state');
                control.setErrors(null);

                if (this.showStateSelect) {
                    control.setValidators(Validators.required);
                    control.setErrors(null);
                } else {
                    control.clearValidators();
                }
            });
    }

    /**
     * Updates the Date range
     * @param event Dates to update to
     */
    public updateDateRange(event: CardFilters): void {
        this.invalidDate = false;

        const diff = event.endDate.diff(event.startDate, 'days');
        if (diff > 7) {
            event.endDate = event.startDate.clone().add(7, 'days');
        }

        this.downloadInfo.endDate = event.endDate;
        this.downloadInfo.startDate = event.startDate;

        this.maxDate = this.downloadInfo.startDate.clone().add(7, 'days');

        this.possibleTimestamps = event.endDate.diff(event.startDate, 'minutes') / 15 || 1;
    }

    /**
     * Updates locations depending on the aggregation level
     * @param nextLevel Aggregation Level to change to
     */
    private translateAggregationLevel(nextLevel: GenOutageAggregationLevel) {
        const currentLevel = this.downloadInfo.aggregationLevel;

        if (!currentLevel) {
            return;
        }

        if (this.isStateSelect(currentLevel)) {
            if (this.isCountySelect(nextLevel)) {
                this.statesForCounty = this.states.filter((s) => this.downloadInfo.fipsCodes.indexOf(s.fipsCode) !== -1);
                this.clearAllLocations();
            } else {
                this.clearAllLocations();
            }
        } else {
            this.clearAllLocations();
        }
    }

    /**
     * Get locations based on selected aggregation level
     */
    public getLocations(): void {
        const level = this.downloadInfo.aggregationLevel;

        this.locations = [];

        if (this.isStateSelect(level)) {
            this.locations = this.states;
        } else if (this.isCountySelect(level)) {
            const states = DataService.states.value.filter((s) => this.statesForCounty.findIndex((sfc) => sfc.name === s.name) !== -1);
            this.outageService.getCountiesForStates(states).subscribe((res) => {
                this.counties = new Map<string, LocationInfo[]>();
                res.sort((a, b) => (a.name > b.name ? 1 : -1)).forEach((county) => {
                    const val = this.counties.get(county.stateAbbreviation) || [];
                    const li: LocationInfo = {
                        name: county.name,
                        fipsCode: county.geoid,
                        selected: false,
                    };
                    val.push(li);
                    this.counties.set(county.stateAbbreviation, val);
                });
            });
        }
    }

    /**
     * Toggles the selected location
     * @param location location to toggle
     */
    public toggleLocation(location: string): void {
        const index = this.downloadInfo.fipsCodes.indexOf(location);

        if (index === -1) {
            this.downloadInfo.fipsCodes.push(location);
        } else if (index !== -1) {
            this.downloadInfo.fipsCodes.splice(index, 1);
        }

        if (this.isStateSelect(this.downloadInfo.aggregationLevel)) {
            const regionId = DataService.states.getValue().find((s) => s.statefp === location).dotregion;
            const statesInRegion = DataService.statesByFema.value.get(regionId);
            const region = this.femaRegions.find((fr) => fr.fipsCode === regionId.toString());
            const numStatesActive = this.downloadInfo.fipsCodes.filter((code) => {
                return statesInRegion.findIndex((s) => s.statefp === code) !== -1;
            });

            region.indeterminateState = numStatesActive.length > 0 && numStatesActive.length < statesInRegion.length;
            if (numStatesActive.length === 0) {
                region.selected = false;
            }
            if (numStatesActive.length === statesInRegion.length) {
                region.selected = true;
            }
        }

        this.locationGroup.get('selected').updateValueAndValidity();
    }

    /**
     * Updates the possibleRowsPerTimestamp field based on the aggregation level
     */
    private updatePossibleRowsPerTimestamp(): void {
        if ([GenOutageAggregationLevel.state, GenOutageAggregationLevel.county].includes(this.downloadInfo.aggregationLevel)) {
            this.possibleRowsPerTimestamp$ = of(this.downloadInfo.fipsCodes.length * this.possibleTimestamps);
            return;
        }

        this.possibleRowsPerTimestamp$ = this.outageService
            .getCsvDownloadRowCount(this.downloadInfo.aggregationLevel, this.downloadInfo.fipsCodes)
            .pipe(
                map((rowCount) => rowCount * this.possibleTimestamps),
                take(1)
            );
    }

    /**
     * Toggles FEMA region selected status
     * @param region Region to toggle
     */
    public toggleFemaState(region: LocationInfo): void {
        const statesInRegion = DataService.statesByFema.value.get(Number(region.fipsCode));
        region.selected = !region.selected;
        if (region.selected) {
            statesInRegion
                .filter((state) => this.downloadInfo.fipsCodes.indexOf(state.statefp) === -1)
                .forEach((state) => this.downloadInfo.fipsCodes.push(state.statefp));
        } else {
            this.downloadInfo.fipsCodes = this.downloadInfo.fipsCodes.filter((fips) => {
                return statesInRegion.findIndex((state) => state.statefp === fips) === -1;
            });
        }

        this.locationGroup.get('selected').updateValueAndValidity();
    }

    /**
     * Selects all locations
     */
    public selectAllLocations(): void {
        this.downloadInfo.fipsCodes = this.locations.map((l) => l.fipsCode);

        if (this.isCountySelect(this.downloadInfo.aggregationLevel)) {
            this.downloadInfo.fipsCodes = Array.from(this.counties.values())
                .reduce((prev, cur) => prev.concat(cur), [])
                .map((li) => li.fipsCode);
        }

        this.femaRegions.forEach((fr) => {
            fr.selected = true;
            fr.indeterminateState = false;
        });

        this.locationGroup.get('selected').updateValueAndValidity();
    }

    /**
     * Clears all selected locations
     */
    public clearAllLocations(): void {
        this.downloadInfo.fipsCodes = [];
        this.femaRegions.forEach((fr) => {
            fr.selected = false;
            fr.indeterminateState = false;
        });
        this.locationGroup.get('selected').updateValueAndValidity();
    }

    /**
     * Creates the filename for the download
     */
    public createFilename(): void {
        const aggPipe = new AggregationLevelPipe();

        this.downloadInfo.filename =
            `${this.downloadInfo.startDate.format('YYYYMMDDTHHMMSS')} ` +
            `${this.downloadInfo.endDate.format('YYYYMMDDTHHMMSS')} ` +
            `${aggPipe.transform(this.downloadInfo.aggregationLevel).toLowerCase()} ` +
            `outage data`;

        this.downloadInfo.filename = this.downloadInfo.filename.replace(/ /g, '_');
        this.updatePossibleRowsPerTimestamp();
    }

    public toggleZeroOutages(checked: boolean): void {
        this.includeZeroOutages = checked;
        this.downloadInfo.allCounties = this.includeZeroOutages;
    }

    /**
     * Sends the download email to user
     */
    public downloadFile(): void {
        this.outageService.sendCsvDownloadEmail(this.downloadInfo).subscribe({
            next: () => {
                this.popup.open('An email will be sent to you shortly with the status of your requested data.', '', {
                    duration: 5000,
                    panelClass: 'success',
                });
                this.ref.close();
            },
            error: (error: any) => {
                this.popup.open('There was an error processing your request. PLease contact an administrator', '', {
                    duration: 5000,
                    panelClass: 'failure',
                });
                throw error;
            },
        });
    }

    /**
     * Selected location validator
     */
    private selectedLocation(): ValidatorFn {
        return () => {
            if (this.downloadInfo.fipsCodes.length !== 0) {
                return null;
            } else {
                return {invalid: ''};
            }
        };
    }

    public updateOutageInterval(event: MatSelectChange) {
        this.downloadInfo.hourInterval = event.value;
    }

    private isCountySelect(aggregationLevel: GenOutageAggregationLevel): boolean {
        return [GenOutageAggregationLevel.county].includes(aggregationLevel);
    }

    private isStateSelect(aggregationLevel: GenOutageAggregationLevel): boolean {
        return [GenOutageAggregationLevel.state, GenOutageAggregationLevel.zip, GenOutageAggregationLevel.utility].includes(
            aggregationLevel
        );
    }
}
