import {GenLegend} from '../../../../../generated/serverModels/GenLegend';

interface LegendValue {
    layerIds?: string;
    filters?: {[key: string]: string[]};
    orientation?: string;
    elements?: any[];
}

export class LayerLegend extends GenLegend {
    constructor(legend?: LayerLegend) {
        super(legend);
    }

    public parseValues(): LegendValue {
        if (!this.values) {
            return {};
        }

        let values = {};
        try {
            values = JSON.parse(this.values);
        } catch (e) {
            console.warn('Parsing legend values');
            console.error(e);
        }
        return values;
    }
}
