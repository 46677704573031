<div class="filter-bar ignore-last">
    <section class="filters">
        <mat-form-field class="filter-select" [class.active]="isSearchTextActive()">
            <mat-label *ngIf="isSearchTextActive()">Search Titles</mat-label>
            <!--suppress TypeScriptUnresolvedVariable -->
            <input matInput placeholder="Search Titles" (keyup)="filterData($event.target.value)" />
            <mat-icon matSuffix class="icon input-search-icon" fontSet="fas" fontIcon="fa-search"></mat-icon>
        </mat-form-field>

        <eaglei-date-range [start]="startDate" [end]="endDate" [newDesign]="true" (change)="updateDateRange($event)"></eaglei-date-range>
    </section>

    <div>
        <button
            mat-button
            matTooltipPosition="above"
            matTooltipShowDelay="250"
            class="source-info"
            matTooltip="Where does the data come from?"
            (click)="getSourceData()">
            <mat-icon>help_outline</mat-icon>
        </button>

        <button mat-stroked-button matTooltip="Export" matTooltipPosition="above" matTooltipShowDelay="250" [matMenuTriggerFor]="menu">
            <mat-icon fontSet="fas" fontIcon="fa-file-export" inline></mat-icon>
        </button>
    </div>
</div>

<div class="report-data">
    <ng-container [ngTemplateOutlet]="table"></ng-container>
</div>

<!--Table-->
<ng-template #table>
    <mat-card [class.invisible]="!hasData()">
        <div class="table-container">
            <table
                mat-table
                [dataSource]="dataSource"
                multiTemplateDataRows
                matSort
                [matSortDisableClear]="true"
                matSortActive="date"
                matSortDirection="desc">
                <ng-container matColumnDef="title">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Title
                        <eaglei-table-sort-header [columnName]="'title'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let info"
                        class="title"
                        [matTooltip]="info.title"
                        matTooltipPosition="right"
                        matTooltipShowDelay="250">
                        <p>{{ info.title }}</p>
                    </td>
                </ng-container>

                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Report Date
                        <eaglei-table-sort-header [columnName]="'date'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let info">{{ info.newsDate | momentDate }}</td>
                </ng-container>

                <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let info" [attr.colspan]="columns.length">
                        <div class="hide-expanded-row" [class.show-expanded-row]="isActiveEvent(info)">
                            <eaglei-recent-event-info [event]="info"></eaglei-recent-event-info>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columns"></tr>
                <tr
                    mat-row
                    *matRowDef="let row; columns: columns; let odd = odd"
                    (click)="toggleActiveReport(row)"
                    class="clickable hoverable"
                    [class.selected-row]="isActiveEvent(row)"></tr>
                <tr
                    mat-row
                    *matRowDef="let row; columns: ['expandedDetail']"
                    class="expandable-row"
                    [class.expanded]="isActiveEvent(row)"></tr>
            </table>

            <mat-paginator [pageSizeOptions]="[25, 50, 75, 100]" showFirstLastButtons></mat-paginator>
        </div>
        <div class="table-attribution">
            <span>Multiple Sources Click <mat-icon [inline]="true">help_outline</mat-icon> to View</span>
        </div>
    </mat-card>

    <div class="auto-mask hide-mask" *ngIf="!hasData()">
        <span class="mask-message">
            <span>No notices found matching filters</span>
        </span>
    </div>
</ng-template>

<mat-menu #menu="matMenu" overlapTrigger>
    <button (click)="exportTable()" mat-menu-item>Export Table As CSV</button>
</mat-menu>
