import {Component, ElementRef, OnInit} from '@angular/core';
import {AuthenticationService} from '../../../../services/authentication.service';
import {User} from '../../../user/classes/user';
import {GenCommunityUserStatus} from '../../../../../../generated/serverModels/GenCommunityUserStatus';
import {Router} from '@angular/router';
import {filter, takeUntil} from 'rxjs/operators';
import {BaseWidget} from '../../classes/base-widget';
import {Community} from '../../../community/classes/community';
import {CommunityService} from '../../../community/services/community.service';
import {WidgetService} from '../../services/widget.service';

@Component({
    selector: 'eaglei-community-widget',
    templateUrl: './community-widget.component.html',
    styleUrls: ['./community-widget.component.scss'],
})
export class CommunityWidgetComponent extends BaseWidget implements OnInit {
    private user: User;
    public communities: Community[] = [];

    constructor(
        private widgetElement: ElementRef,
        private communityService: CommunityService,
        private auth: AuthenticationService,
        private router: Router
    ) {
        super(widgetElement);
    }

    ngOnInit() {
        this.user = this.auth.authenticatedUser.getValue();
        this.communityService.getUserCommunities(this.user.id, GenCommunityUserStatus.APPROVED).subscribe((res) => {
            this.communities = res;
        });

        WidgetService.resize
            .pipe(
                filter((widget) => this.item.x === widget.x && this.item.y === widget.y),
                takeUntil(this.destroy$)
            )
            .subscribe(() => this.resize());
    }

    destroyWidget(): void {}

    /**
     * Navigates to the selected community
     * @param [community] The community that will be redirected to. If no community is given, it will navigate to the community landing page
     */
    public gotoCommunity(community?: Community): void {
        const commands: any[] = ['app/communities/'];

        if (community) {
            commands.push(community.id);
        }

        // noinspection JSIgnoredPromiseFromCall
        this.router.navigate(commands);
    }
}
