import {Component, ElementRef, ViewChild} from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {RrccService} from 'frontend/src/app/services/rrcc.service';
import {RRCCLocation} from '../../../management/classes/rrcc-location';
import {EventSummaryFilters} from '../../../system-event/classes/event-summary-filters';
import {BaseWidget} from '../../classes/base-widget';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {RrccLocationInfoModalComponent} from '../../modals/rrcc-location-info-modal/rrcc-location-info-modal.component';
import {ModalConfig} from '../../../../classes/modal-config';
import * as moment from 'moment';
import {ApplicationConfig} from 'frontend/src/app/classes/application-config';

@Component({
    selector: 'eaglei-rrcc-widget',
    templateUrl: './rrcc-widget.component.html',
    styleUrls: ['./rrcc-widget.component.scss'],
})
export class RrccWidgetComponent extends BaseWidget {
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

    public eventFilters: EventSummaryFilters = new EventSummaryFilters();

    public deployedLocations: RRCCLocation[] = [];

    public readonly pageSize = 7;
    public startIndex = 0;
    public endIndex = this.pageSize;

    constructor(private widgetElement: ElementRef, private rrccService: RrccService, private dialog: MatDialog) {
        super(widgetElement);
    }

    protected handleEventFilterChange(eventFilters: EventSummaryFilters): void {
        this.eventFilters = eventFilters;
        this.getRRCCData();
    }

    destroyWidget(): void {}

    private getRRCCData(): void {
        this.rrccService.getRRCCLocations().subscribe((res) => {
            this.deployedLocations = res
                .filter((l) => {
                    const eventCheck = l.deployments.length > 0 && l.events.map((e) => e.id).includes(this.eventFilters.event.id);
                    const dateCheck =
                        l.deployments.filter((d) => this.eventFilters.date.isBetween(d.startTime, d.endTime, 'day', '[]')).length > 0;
                    return eventCheck && dateCheck;
                })
                .map((l) => new RRCCLocation(l));

            this.paginator.length = this.deployedLocations.length;
        });
    }

    public openDetailModal(location: RRCCLocation) {
        const config: MatDialogConfig = {
            ...ModalConfig.defaultConfig,
            autoFocus: false,
            data: {location, dateForDeployments: this.eventFilters.date},
        };

        this.dialog.open(RrccLocationInfoModalComponent, config);
    }

    public pageFiles(pageEvent: PageEvent): void {
        this.startIndex = pageEvent.pageIndex * pageEvent.pageSize;
        this.endIndex = pageEvent.pageSize * (pageEvent.pageIndex + 1);
    }

    public hasEllipsis(element: HTMLElement): boolean {
        return ApplicationConfig.hasEllipsis(element);
    }
}
