import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'legendName',
})
export class LegendNamePipe implements PipeTransform {
    transform(value: string, args?: any): any {
        switch (value.toLowerCase()) {
            case 'default':
                return 'System Default';
            case 'colorblind':
                return 'Colorblind Friendly';
            default:
                return 'N/A';
        }
    }
}
