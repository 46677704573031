import {DashboardFilters} from 'frontend/src/shared/classes/dashboard-filters';
import {SystemEvent} from './system-event';
import {GenSystemEventPhaseType} from '../../../../../generated/serverModels/GenSystemEventPhaseType';
import * as moment from 'moment';

export class EventSummaryFilters extends DashboardFilters {
    public event: SystemEvent;
    public temporalControl: GenSystemEventPhaseType = GenSystemEventPhaseType.PRE;

    constructor(filters?: EventSummaryFilters) {
        super(filters);

        if (filters) {
            this.event = filters.event;
            this.temporalControl = filters.temporalControl;
        }
    }

    public setDate(date: moment.Moment): EventSummaryFilters {
        this.date = date;
        return this;
    }
}
