
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import { GenRRCCContact } from './GenRRCCContact';
import * as moment from 'moment';

export class GenRRCCPersonnel {

    id: number;
    contacts: GenRRCCContact[];
    endDate: moment.Moment;
    name: string;
    shiftId: number;
    startDate: moment.Moment;
    status: string;
    userId: number;

    constructor();
    constructor( jsonObj: any );
    constructor( genRRCCPersonnel: GenRRCCPersonnel);
    constructor( id: number );

    constructor( input?: GenRRCCPersonnel|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            if ( input.contacts && input.contacts.map ) { // list [GenRRCCContact]
                this.contacts = input.contacts.map( ( x: GenRRCCContact ) => {
                    return new GenRRCCContact( x );
                } );
            } else {
                this.contacts = input.contacts;
            }
            if ( input.endDate && typeof input.endDate === 'string' ) {
                this.endDate = moment(input.endDate).utc();
            } else {
                this.endDate = input.endDate;
            }
            this.name = input.name; // string [string]
            this.shiftId = input.shiftId; // int [number]
            if ( input.startDate && typeof input.startDate === 'string' ) {
                this.startDate = moment(input.startDate).utc();
            } else {
                this.startDate = input.startDate;
            }
            this.status = input.status; // string [string]
            this.userId = input.userId; // int [number]
       }
    }

    static jsonResponseToList(arr: any[]): GenRRCCPersonnel[] {
        if (arr && arr.map) {
          return arr.map(a => new GenRRCCPersonnel(a));
        } else {
          return [];
        }
    }
}

