import {AfterViewInit, Component, ElementRef} from '@angular/core';
import {GenWidgetSettingType} from 'frontend/generated/serverModels/GenWidgetSettingType';
import {ApplicationConfig} from 'frontend/src/app/classes/application-config';
import {filter, takeUntil} from 'rxjs/operators';
import {BaseWidget} from '../../classes/base-widget';
import {WidgetService} from '../../services/widget.service';
import {LayerService} from '../../../layer/services/layer.service';
import {HttpInterceptorService} from '../../../../services/http-interceptor.service';
import {LeafletMapLayer} from '../../../layer/classes/leaflet-map-layer';
import {OngService} from '../../../../services/ong.service';
import {PlannedOutage} from '../../../../types/ong.types';
import {EventSummaryFilters} from '../../../system-event/classes/event-summary-filters';
import * as moment from 'moment';
import {
    activeOilPlannedOutages,
    futureOilPlannedOutages,
    pastOilPlannedOutages,
} from '../../../../../integrations/ong/classes/ong-attributions-text';

@Component({
    selector: 'eaglei-oil-planned-outage-widget',
    templateUrl: './oil-planned-outage-widget.component.html',
    styleUrls: ['./oil-planned-outage-widget.component.scss'],
})
export class OilPlannedOutageWidgetComponent extends BaseWidget implements AfterViewInit {
    // The maplayer is static because there can be multiple instances of the widget.
    static mapLayer: LeafletMapLayer;
    public outages: number = 0;
    private readonly layerHandle = 'ongOilOutage';

    // Stat Options
    private type: 'future' | 'active' | 'past';

    // Landing Page Filters
    private filters: EventSummaryFilters;

    constructor(
        private widgetElement: ElementRef,
        private widgetService: WidgetService,
        private ongService: OngService,
        private layerService: LayerService
    ) {
        super(widgetElement);
    }

    public ngAfterViewInit(): void {
        WidgetService.updatedSettings
            .pipe(
                filter((widget) => !!widget),
                takeUntil(this.destroy$)
            )
            .subscribe((widget) => {
                if (this.item.x === widget.x && this.item.y === widget.y) {
                    this.type = widget.detail.settings.find((s) => s.type === GenWidgetSettingType.ONG_PLANNED_OUTAGE).value;
                    this.getImpactedStat();
                }
            });

        HttpInterceptorService.clearInterceptor(this.widgetInterceptorKey);
        HttpInterceptorService.pendingRequests[this.widgetInterceptorKey] = this.layerService
            .getLayerByHandle(this.layerHandle)
            .subscribe((layer) => {
                HttpInterceptorService.deleteFromInterceptor(this.widgetInterceptorKey);
                OilPlannedOutageWidgetComponent.mapLayer = layer;
            });

        WidgetService.renderWidget.pipe(takeUntil(this.destroy$)).subscribe(() => this.getImpactedStat());

        this.type =
            !!this.item && !!this.item.detail.settings.find((s) => s.type === GenWidgetSettingType.ONG_PLANNED_OUTAGE)
                ? (this.item.detail.settings.find((s) => s.type === GenWidgetSettingType.ONG_PLANNED_OUTAGE).value as any)
                : (this.type = 'active');

        if (!this.eventDashboard) {
            this.getImpactedStat();
        }
    }

    protected handleEventFilterChange(eventFilters: EventSummaryFilters) {
        this.filters = eventFilters;
        this.getImpactedStat();
    }

    destroyWidget(): void {}

    private getPlannedOutages(type: PlannedOutage) {
        const locations = this.filters?.locations || this.userPreferences.getStates();

        // Sets dates similar to how the API handles no dates
        let start;
        let end;

        if (this.eventDashboard) {
            start = type === 'future' ? moment(this.filters.date) : undefined;

            end = type !== 'future' ? moment(this.filters.date) : undefined;
        }

        // const end = this.eventDashboard ? moment(this.filters.date) : undefined;
        this.ongService.getOilPlannedOutages(type, locations, start, end, false, true).subscribe((res) => {
            this.outages = res.data?.count;
        });
    }

    /**
     * Gets the Impacted Stat based on the Stat Type
     */
    private getImpactedStat(): void {
        this.getPlannedOutages(this.type);
    }

    /**
     * Gets the Impacted title based on type
     */
    public getImpactedTitle(): string {
        switch (this.type) {
            case 'future':
                this.widgetName = 'Future Oil Planned Outages';
                this.attributionModalText = futureOilPlannedOutages;
                return 'FUTURE OIL PLANNED OUTAGES';
            case 'active':
                this.widgetName = 'Active Oil Planned Outages';
                this.attributionModalText = activeOilPlannedOutages;
                return 'ACTIVE OIL PLANNED OUTAGES';
            case 'past':
                this.widgetName = 'Past Oil Planned Outages';
                this.attributionModalText = pastOilPlannedOutages;
                return 'PAST OIL PLANNED OUTAGES';
            default:
                return 'UNKNOWN STAT';
        }
    }

    public goToMap() {
        this.layerService.navigateToMap([OilPlannedOutageWidgetComponent.mapLayer]);
    }

    public onMobile() {
        return ApplicationConfig.onMobile();
    }
}
