import {GenOutageSnapshotsForDate} from '../../../../../generated/serverModels/GenOutageSnapshotsForDate';
import {HistoricalOutageData} from '../../layer/classes/historical-outage-data';

import {ApplicationConfig} from '../../../classes/application-config';
import {GenOutageAggregationLevel} from '../../../../../generated/serverModels/GenOutageAggregationLevel';
import * as moment from 'moment';

export class OutageSnapshotDate extends GenOutageSnapshotsForDate {
    public historicalData: HistoricalOutageData[] = [];

    constructor(data?: OutageSnapshotDate) {
        super(data);
        if (data) {
            this.convertToHistoricalOutage();
        }
    }

    public convertToHistoricalOutage(): void {
        const useCounty = this.outageSnapshotList[0]?.countyFIPSCode !== undefined;

        const geometries = useCounty
            ? ApplicationConfig.geometries.get(GenOutageAggregationLevel.county)
            : ApplicationConfig.geometries.get(GenOutageAggregationLevel.state);

        this.historicalData = this.outageSnapshotList.map((snapshot) => {
            const data = new HistoricalOutageData();
            data.currentOutageHasOverrideData = snapshot.hasOverrideData;
            data.currentOutage = snapshot.outages;
            data.currentOutageRunStartTime = moment(snapshot.runStartTime);
            data.coveredCustomers = snapshot.coveredCustomers;

            data.countyId = snapshot.countyId;
            data.countyName = snapshot.countyName;

            data.stateId = snapshot.stateId;
            data.stateName = snapshot.stateAbbreviation; // Setting it this way because the nom source layer uses stateAbbreviation to match on

            data.countyFIPSCode = snapshot.countyFIPSCode;
            data.stateFIPSCode = snapshot.stateFIPSCode;

            data.collectedCount = snapshot.collectedCount;
            data.modelCount = snapshot.modelCount;

            data.updateName();

            data.boundary = geometries.find((geom) => geom.fipsCode === data.geometryKey())?.boundary;
            return data;
        });
    }
}
