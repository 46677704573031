import {GenEventResultCustomerDetail} from '../../../../../generated/serverModels/GenEventResultCustomerDetail';
import {HeadoutTableData} from '../interfaces/headout-table-data.interface';

export class HeadoutEventResultCustomerDetail extends GenEventResultCustomerDetail {
    static ConvertToReportData(detail: HeadoutEventResultCustomerDetail): HeadoutTableData {
        return {
            state: detail.county.stateAbbreviation,
            county: detail.county.name,
            customersOut: detail.households,
            totalCustomers: detail.totalCustomers,
            percentOut: detail.percent,
        };
    }

    constructor(detail?: HeadoutEventResultCustomerDetail) {
        super(detail);
        if (detail) {
            this.percent = this.households / this.totalCustomers;
        }
    }
}
