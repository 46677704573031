
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import * as moment from 'moment';

export class GenPlanetSense {

    accountName: string;
    createdAt: moment.Moment;
    data: Object;
    fullTweet: string;
    media: Object;
    profileImageUrl: string;
    tweet: string;
    tweetId: string;
    twitterName: string;
    utilityId: number;

    constructor();
    constructor( jsonObj: any );
    constructor( genPlanetSense: GenPlanetSense);

    constructor( input?: GenPlanetSense ) {
        if ( input && typeof input !== 'number' ) {
            this.accountName = input.accountName; // string [string]
            if ( input.createdAt && typeof input.createdAt === 'string' ) {
                this.createdAt = moment(input.createdAt).utc();
            } else {
                this.createdAt = input.createdAt;
            }
            if( input.data ) {
              this.data = new Object( input.data );
            }
            this.fullTweet = input.fullTweet; // string [string]
            if( input.media ) {
              this.media = new Object( input.media );
            }
            this.profileImageUrl = input.profileImageUrl; // string [string]
            this.tweet = input.tweet; // string [string]
            this.tweetId = input.tweetId; // string [string]
            this.twitterName = input.twitterName; // string [string]
            this.utilityId = input.utilityId; // int [number]
       }
    }

    static jsonResponseToList(arr: any[]): GenPlanetSense[] {
        if (arr && arr.map) {
          return arr.map(a => new GenPlanetSense(a));
        } else {
          return [];
        }
    }
}

