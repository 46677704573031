import {Component} from '@angular/core';
import {EagleILayer} from '../../classes/eagle-i-layer';
import {RrccSource} from '../../sources/rrcc-source';
import {LayerService} from '../../services/layer.service';
import {map, tap} from 'rxjs/operators';
import {CustomPopover} from '../../enum/custom-popover';
import {RRCCLocation} from '../../../management/classes/rrcc-location';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../../../services/authentication.service';
import {GenCommunityPermission} from '../../../../../../generated/serverModels/GenCommunityPermission';
import {LeafletLegendService} from '../../services/leaflet-legend.service';
import {GenLegendType} from '../../../../../../generated/serverModels/GenLegendType';
import {ApplicationConfig} from '../../../../classes/application-config';

@Component({
    selector: 'eaglei-rrcc-layer',
    templateUrl: './rrcc-layer.component.html',
    styleUrls: ['../layers.scss', './rrcc-layer.component.scss'],
})
export class RrccLayerComponent extends EagleILayer<RrccSource> {
    public canEdit: boolean;

    constructor(
        private layerService: LayerService,
        private legendService: LeafletLegendService,
        private authService: AuthenticationService,
        private router: Router
    ) {
        super();
        this.canEdit =
            this.authService.authenticatedUser.getValue().hasDataAccess(GenCommunityPermission.RRCC) && !ApplicationConfig.onPhone();
    }

    afterViewInit(): void {
        this.mapLayer.customPopoverId = CustomPopover.RRCC;
        this.mapLayer.legend.type = GenLegendType.HARD_CODED;
    }

    toggleLayer(activeState: boolean): void {
        this.isActive = activeState;
        if (activeState) {
            this.getRrccLocations();
        } else {
            this.layerSource.removeFromMap();
        }
    }

    private getRrccLocations(): void {
        const url = this.mapLayer.serviceurl;

        const tapSuccess = () => {
            if (!this.layerSource) {
                this.layerSource = new RrccSource(this.mapLayer);
                RrccSource.redrawTrigger.subscribe((updatedFeature) => {
                    this.layerSource.redrawFeature(updatedFeature);
                });
            }
        };

        this.layerService
            .getJsonFromUrl(url)
            .pipe(
                tap(tapSuccess),
                map((locations: any[]) => locations.map((location) => new RRCCLocation(location)))
            )
            .subscribe((locations) => {
                this.layerSource.processFeatures(locations);
                this.layerSource.addToMap();
            });
    }

    public editLocations(): void {
        this.router.navigateByUrl('/app/management/rrcc-data');
    }
}
