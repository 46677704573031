
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!


export class GenSourceType {

    id: number;
    description: string;
    source: string;

    constructor();
    constructor( jsonObj: any );
    constructor( genSourceType: GenSourceType);
    constructor( id: number );

    constructor( input?: GenSourceType|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            this.description = input.description; // string [string]
            this.source = input.source; // string [string]
       }
    }

    static jsonResponseToList(arr: any[]): GenSourceType[] {
        if (arr && arr.map) {
          return arr.map(a => new GenSourceType(a));
        } else {
          return [];
        }
    }
}

