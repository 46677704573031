
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

export class GenWidgetName {
    static readonly COMMUNITY_LIST: GenWidgetName = new GenWidgetName('COMMUNITY_LIST');
    static readonly REPORT_LIST: GenWidgetName = new GenWidgetName('REPORT_LIST');
    static readonly OUTAGE_MAP: GenWidgetName = new GenWidgetName('OUTAGE_MAP');
    static readonly OUTAGE_CHART: GenWidgetName = new GenWidgetName('OUTAGE_CHART');
    static readonly OUTAGE_SUMMARY: GenWidgetName = new GenWidgetName('OUTAGE_SUMMARY');
    static readonly STATS: GenWidgetName = new GenWidgetName('STATS');
    static readonly OIL_PLANNED_OUTAGES: GenWidgetName = new GenWidgetName('OIL_PLANNED_OUTAGES');
    static readonly OIL_STORAGE: GenWidgetName = new GenWidgetName('OIL_STORAGE');
    static readonly OIL_REFINERY_STATUS: GenWidgetName = new GenWidgetName('OIL_REFINERY_STATUS');
    static readonly OIL_REFINERY_ALERT: GenWidgetName = new GenWidgetName('OIL_REFINERY_ALERT');
    static readonly OIL_REFINERY_CAPACITY: GenWidgetName = new GenWidgetName('OIL_REFINERY_CAPACITY');
    static readonly OIL_BARREL_FLOW: GenWidgetName = new GenWidgetName('OIL_BARREL_FLOW');
    static readonly OIL_FLOW_TABLE: GenWidgetName = new GenWidgetName('OIL_FLOW_TABLE');
    static readonly DERIVED_STORAGE: GenWidgetName = new GenWidgetName('DERIVED_STORAGE');
    static readonly EIA_PETROLEUM_REPORT: GenWidgetName = new GenWidgetName('EIA_PETROLEUM_REPORT');
    static readonly DAILY_POWER_ESTIMATE: GenWidgetName = new GenWidgetName('DAILY_POWER_ESTIMATE');
    static readonly GAS_BURN: GenWidgetName = new GenWidgetName('GAS_BURN');
    static readonly NG_PIPELINE_STATUS: GenWidgetName = new GenWidgetName('NG_PIPELINE_STATUS');
    static readonly NG_NOTICES: GenWidgetName = new GenWidgetName('NG_NOTICES');
    static readonly NG_NOMINATION: GenWidgetName = new GenWidgetName('NG_NOMINATION');
    static readonly UNDERGROUND_STORAGE: GenWidgetName = new GenWidgetName('UNDERGROUND_STORAGE');
    static readonly NG_RECEIPT_DELIVERY: GenWidgetName = new GenWidgetName('NG_RECEIPT_DELIVERY');
    static readonly NG_OPERATIONAL_FLOW: GenWidgetName = new GenWidgetName('NG_OPERATIONAL_FLOW');
    static readonly EVENT_RESTORATION: GenWidgetName = new GenWidgetName('EVENT_RESTORATION');
    static readonly EVENT_HEADOUT: GenWidgetName = new GenWidgetName('EVENT_HEADOUT');
    static readonly EVENT_SNAPSHOT: GenWidgetName = new GenWidgetName('EVENT_SNAPSHOT');
    static readonly EVENT_PORTS: GenWidgetName = new GenWidgetName('EVENT_PORTS');
    static readonly SITUATION_REPORTS: GenWidgetName = new GenWidgetName('SITUATION_REPORTS');
    static readonly EVENT_RRCC: GenWidgetName = new GenWidgetName('EVENT_RRCC');
    static readonly EIL_DOCUMENTS: GenWidgetName = new GenWidgetName('EIL_DOCUMENTS');
    static readonly SYSTEM_NEWS: GenWidgetName = new GenWidgetName('SYSTEM_NEWS');
    static readonly UAS_POLE_DETECTION: GenWidgetName = new GenWidgetName('UAS_POLE_DETECTION');
    static readonly PNNL_RADR: GenWidgetName = new GenWidgetName('PNNL_RADR');
    static readonly WAO_TABLE: GenWidgetName = new GenWidgetName('WAO_TABLE');
    static readonly URBAN_NET: GenWidgetName = new GenWidgetName('URBAN_NET');

    public readonly name: string;

    private constructor(name: string) {
        this.name = name;
    }

    static forName(name: string) {
        if (name === 'COMMUNITY_LIST') return GenWidgetName.COMMUNITY_LIST;
        if (name === 'REPORT_LIST') return GenWidgetName.REPORT_LIST;
        if (name === 'OUTAGE_MAP') return GenWidgetName.OUTAGE_MAP;
        if (name === 'OUTAGE_CHART') return GenWidgetName.OUTAGE_CHART;
        if (name === 'OUTAGE_SUMMARY') return GenWidgetName.OUTAGE_SUMMARY;
        if (name === 'STATS') return GenWidgetName.STATS;
        if (name === 'OIL_PLANNED_OUTAGES') return GenWidgetName.OIL_PLANNED_OUTAGES;
        if (name === 'OIL_STORAGE') return GenWidgetName.OIL_STORAGE;
        if (name === 'OIL_REFINERY_STATUS') return GenWidgetName.OIL_REFINERY_STATUS;
        if (name === 'OIL_REFINERY_ALERT') return GenWidgetName.OIL_REFINERY_ALERT;
        if (name === 'OIL_REFINERY_CAPACITY') return GenWidgetName.OIL_REFINERY_CAPACITY;
        if (name === 'OIL_BARREL_FLOW') return GenWidgetName.OIL_BARREL_FLOW;
        if (name === 'OIL_FLOW_TABLE') return GenWidgetName.OIL_FLOW_TABLE;
        if (name === 'DERIVED_STORAGE') return GenWidgetName.DERIVED_STORAGE;
        if (name === 'EIA_PETROLEUM_REPORT') return GenWidgetName.EIA_PETROLEUM_REPORT;
        if (name === 'DAILY_POWER_ESTIMATE') return GenWidgetName.DAILY_POWER_ESTIMATE;
        if (name === 'GAS_BURN') return GenWidgetName.GAS_BURN;
        if (name === 'NG_PIPELINE_STATUS') return GenWidgetName.NG_PIPELINE_STATUS;
        if (name === 'NG_NOTICES') return GenWidgetName.NG_NOTICES;
        if (name === 'NG_NOMINATION') return GenWidgetName.NG_NOMINATION;
        if (name === 'UNDERGROUND_STORAGE') return GenWidgetName.UNDERGROUND_STORAGE;
        if (name === 'NG_RECEIPT_DELIVERY') return GenWidgetName.NG_RECEIPT_DELIVERY;
        if (name === 'NG_OPERATIONAL_FLOW') return GenWidgetName.NG_OPERATIONAL_FLOW;
        if (name === 'EVENT_RESTORATION') return GenWidgetName.EVENT_RESTORATION;
        if (name === 'EVENT_HEADOUT') return GenWidgetName.EVENT_HEADOUT;
        if (name === 'EVENT_SNAPSHOT') return GenWidgetName.EVENT_SNAPSHOT;
        if (name === 'EVENT_PORTS') return GenWidgetName.EVENT_PORTS;
        if (name === 'SITUATION_REPORTS') return GenWidgetName.SITUATION_REPORTS;
        if (name === 'EVENT_RRCC') return GenWidgetName.EVENT_RRCC;
        if (name === 'EIL_DOCUMENTS') return GenWidgetName.EIL_DOCUMENTS;
        if (name === 'SYSTEM_NEWS') return GenWidgetName.SYSTEM_NEWS;
        if (name === 'UAS_POLE_DETECTION') return GenWidgetName.UAS_POLE_DETECTION;
        if (name === 'PNNL_RADR') return GenWidgetName.PNNL_RADR;
        if (name === 'WAO_TABLE') return GenWidgetName.WAO_TABLE;
        if (name === 'URBAN_NET') return GenWidgetName.URBAN_NET;
        throw new Error('Unexpected value for GenWidgetName:' + name);
    }

    static values(): GenWidgetName[] {
        return [
            GenWidgetName.COMMUNITY_LIST,
            GenWidgetName.REPORT_LIST,
            GenWidgetName.OUTAGE_MAP,
            GenWidgetName.OUTAGE_CHART,
            GenWidgetName.OUTAGE_SUMMARY,
            GenWidgetName.STATS,
            GenWidgetName.OIL_PLANNED_OUTAGES,
            GenWidgetName.OIL_STORAGE,
            GenWidgetName.OIL_REFINERY_STATUS,
            GenWidgetName.OIL_REFINERY_ALERT,
            GenWidgetName.OIL_REFINERY_CAPACITY,
            GenWidgetName.OIL_BARREL_FLOW,
            GenWidgetName.OIL_FLOW_TABLE,
            GenWidgetName.DERIVED_STORAGE,
            GenWidgetName.EIA_PETROLEUM_REPORT,
            GenWidgetName.DAILY_POWER_ESTIMATE,
            GenWidgetName.GAS_BURN,
            GenWidgetName.NG_PIPELINE_STATUS,
            GenWidgetName.NG_NOTICES,
            GenWidgetName.NG_NOMINATION,
            GenWidgetName.UNDERGROUND_STORAGE,
            GenWidgetName.NG_RECEIPT_DELIVERY,
            GenWidgetName.NG_OPERATIONAL_FLOW,
            GenWidgetName.EVENT_RESTORATION,
            GenWidgetName.EVENT_HEADOUT,
            GenWidgetName.EVENT_SNAPSHOT,
            GenWidgetName.EVENT_PORTS,
            GenWidgetName.SITUATION_REPORTS,
            GenWidgetName.EVENT_RRCC,
            GenWidgetName.EIL_DOCUMENTS,
            GenWidgetName.SYSTEM_NEWS,
            GenWidgetName.UAS_POLE_DETECTION,
            GenWidgetName.PNNL_RADR,
            GenWidgetName.WAO_TABLE,
            GenWidgetName.URBAN_NET,
        ];
    }

    toJSON(): string {
        return this.name;
    }

    toString(): string {
        return this.name;
    }
}

