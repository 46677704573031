import * as moment from 'moment';

export class OngPipelineNotice {
    // Values from API Response
    public body: string;
    public effective_date: moment.Moment;
    public end_date: moment.Moment;
    public id: string;
    public priority: string;
    public status: string;
    public subject: string;
    public type: string;

    // Addition Properties
    public expanded: boolean;

    constructor(notice?: OngPipelineNotice) {
        if (notice) {
            this.body = notice.body;
            this.effective_date = moment(notice.effective_date);
            this.end_date = moment(notice.end_date);
            this.id = notice.id;
            this.priority = notice.priority;
            this.status = notice.status;
            this.subject = notice.subject;
            this.type = notice.type;
        }
    }
}
