import {Component} from '@angular/core';
import {LayerService} from '../../services/layer.service';
import {TreasuriesSource} from '../../sources/treasuries-source';
import {EagleILayer} from '../../classes/eagle-i-layer';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {GenTreasuryFacility} from '../../../../../../generated/serverModels/GenTreasuryFacility';
import {LeafletLegendService} from '../../services/leaflet-legend.service';

@Component({
    selector: 'eaglei-treasuries-layer',
    templateUrl: './treasuries-layer.component.html',
    styleUrls: ['./treasuries-layer.component.scss'],
})
export class TreasuriesLayerComponent extends EagleILayer<TreasuriesSource> {
    constructor(private layerService: LayerService, private legendService: LeafletLegendService) {
        super();
    }

    afterViewInit(): void {}

    toggleLayer(activeState: boolean, zoomToFeatures?: boolean): void {
        if (activeState) {
            if (!this.layerSource) {
                this.layerSource = new TreasuriesSource(this.mapLayer);
                this.legendService.getLegend(this).subscribe((legend) => (this.legend = legend));
            }

            this.getTreasuryData().subscribe(() => this.layerSource.addToMap());
        } else {
            this.layerSource.removeFromMap();
        }
    }

    private getTreasuryData(): Observable<any> {
        this.mapLayer.startLoading();
        const url = `${this.mapLayer.serviceurl}`;

        const mf = (res: any[]) => {
            const facilities = res.map((f) => new GenTreasuryFacility(f));
            this.layerSource.processFeatures(facilities);
        };

        return this.layerService.getJsonFromUrl(url).pipe(
            map(mf),
            tap(() => this.mapLayer.endLoading())
        );
    }
}
