import {AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {GenWidgetSettingType} from 'frontend/generated/serverModels/GenWidgetSettingType';
import {ApplicationConfig} from 'frontend/src/app/classes/application-config';
import {ModalConfig} from 'frontend/src/app/classes/modal-config';
import {throttleTime} from 'rxjs/operators';
import {LandingGridItem} from '../../../../landing/classes/landing-grid-item';
import {WidgetSetting} from '../../classes/widget-setting';

@Component({
    selector: 'eaglei-configure-widget-stat-modal',
    templateUrl: './configure-widget-stat-modal.component.html',
    styleUrls: ['./configure-widget-stat-modal.component.scss'],
})
export class ConfigureWidgetStatModalComponent implements OnInit, AfterViewInit {
    // HTML Selector
    @ViewChild('content', {static: true}) content: ElementRef;

    // Aggregation Levels List
    public aggregationLevels = ['customers', 'county', 'utility'];

    // Selected Option
    public selectedAggregation: 'customers' | 'county' | 'utility' = 'utility';

    constructor(
        public dialogRef: MatDialogRef<ConfigureWidgetStatModalComponent>,
        @Inject(MAT_DIALOG_DATA) private item: LandingGridItem
    ) {}

    public ngOnInit(): void {
        if (!!this.item.detail.settings.find((s) => s.type === GenWidgetSettingType.IMPACTED)) {
            this.selectedAggregation = this.item.detail.settings.find((s) => s.type === GenWidgetSettingType.IMPACTED).value as any;
        }
    }

    public ngAfterViewInit(): void {
        ModalConfig.setModalHeight(this.content);
        ApplicationConfig.resizeEvent.pipe(throttleTime(250)).subscribe(() => ModalConfig.setModalHeight(this.content));
    }

    /**
     * Updates the selected level of aggregation
     * @param level Aggregation Level user selected
     */
    public changeAggregationLevel(level: 'customers' | 'county' | 'utility'): void {
        this.selectedAggregation = level;
    }

    /**
     * Disables the update button if no valid aggregation has been selected
     */
    public disableUpdate(): boolean {
        return !this.aggregationLevels.includes(this.selectedAggregation);
    }

    /**
     * Updates the Widget with the selected setting options
     */
    public configureWidget(): void {
        if (this.item.detail.settings.find((s) => s.type === GenWidgetSettingType.IMPACTED)) {
            this.item.detail.settings.find((s) => s.type === GenWidgetSettingType.IMPACTED).value = this.selectedAggregation;
        } else {
            const setting = new WidgetSetting({
                type: GenWidgetSettingType.IMPACTED,
                value: this.selectedAggregation,
            } as any);
            this.item.detail.settings.push(setting);
        }

        this.dialogRef.close(this.item);
    }
}
