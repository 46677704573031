<eaglei-leaflet-layer-header [layer]="this" (activeToggled)="toggleLayer($event)" [hideLegend]="true"></eaglei-leaflet-layer-header>

<div class="layer-actions hide" [class.show]="isActive">
    <div class="aggregation">
        <div>
            <mat-form-field class="filter-select" appearance="outline">
                <mat-label>Accumulation Hours</mat-label>
                <mat-select (selectionChange)="updateData($event.value)" [(ngModel)]="defaultSelection">
                    <mat-option *ngFor="let info of layerInfo" [value]="info">
                        {{ info.value }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <ng-container *ngIf="isActive">
        <div>
            <span>Precipitation Accumulation (inches)</span>
            <eaglei-legend [legends]="legend"></eaglei-legend>
        </div>
    </ng-container>
</div>
