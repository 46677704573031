<mat-form-field *ngIf="showFemaFilter" appearance="outline" class="filter-select">
    <mat-select [(value)]="_selectedFemas" placeholder="FEMA Region" [multiple]="multipleSelection" [disabled]="_femaDisable">
        <mat-select-trigger>
            {{ getFemaNames() }}
        </mat-select-trigger>
        <mat-option *ngIf="multipleSelection" (click)="selectAll()" [value]="true">All</mat-option>
        <mat-option *ngFor="let fema of activeFemaList" (click)="filterFema()" [value]="fema">
            {{ fema | numeral : true }}
        </mat-option>
    </mat-select>
</mat-form-field>

<mat-form-field *ngIf="showStateFilter" appearance="outline" class="filter-select">
    <mat-select [(value)]="_selectedStates" placeholder="State/Territory" [multiple]="multipleSelection" [disabled]="_stateDisable">
        <mat-select-trigger>
            {{ getStateNames() }}
        </mat-select-trigger>
        <mat-option *ngIf="multipleSelection" (click)="selectAll()" [value]="true">All</mat-option>
        <mat-option *ngFor="let state of activeStateList" (click)="filterState()" [value]="state">{{ state.name }} </mat-option>
    </mat-select>
</mat-form-field>
