import * as moment from 'moment';

// TODO make this an interface
export class CardFilters {
    public startDate: moment.Moment = moment().subtract(1, 'days').startOf('day');
    public endDate: moment.Moment = moment();

    constructor() {}

    public setStartDate(start: moment.Moment): CardFilters {
        this.startDate = start.clone();
        return this;
    }

    public setEndDate(end: moment.Moment): CardFilters {
        this.endDate = end.clone();
        return this;
    }
}
