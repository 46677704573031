import {Pipe, PipeTransform} from '@angular/core';
import {DataService} from '../../app/services/data.service';

@Pipe({
    name: 'stateName',
})
export class StateNamePipe implements PipeTransform {
    transform(value: string, getAbbreviation: boolean = false): string {
        const state = DataService.states.getValue().find((s) => {
            return s.name === value || s.abbreviation === value;
        });

        if (state) {
            return getAbbreviation ? state.abbreviation : state.name;
        } else {
            return value;
        }
    }
}
