import {State} from '../../../app/modules/outage/classes/state';
import * as moment from 'moment';
import {DataService} from '../../../app/services/data.service';

export class RefineryStatusUnit {
    static fromApi(data: any): RefineryStatusUnit {
        const unit = new RefineryStatusUnit();
        unit.capacity = data.capacity;
        unit.category = data.category;
        unit.id = data.id;
        unit.measurementDate = moment(data.measurement_date);
        unit.online = data.online;
        unit.outageType = data.outage_type;
        unit.processingClass = data.processing_class;
        unit.region = data.region;
        unit.state = DataService.states.getValue().find((state) => state.abbreviation.toLowerCase() === data.state.toLowerCase());
        unit.unitId = data.unit_id;
        unit.name = data.unit_name;
        return unit;
    }

    public capacity: number;
    public category: string;
    public id: number;
    public measurementDate: moment.Moment;
    public online: boolean;
    public outageType: string;
    public processingClass: string;
    public region: string;
    public state: State;
    public unitId: string;
    public name: string;

    constructor() {}
}
