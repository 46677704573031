import {Component} from '@angular/core';
import {EagleILayer} from '../../classes/eagle-i-layer';
import {LeafletLegendService} from '../../services/leaflet-legend.service';
import {QuantitativePrecipSource} from '../../sources/quantitative-precip-source';

interface LayerInfo {
    id: number;
    value: string;
    layerId: number;
}

@Component({
    selector: 'eaglei-quantitative-precip-layer',
    templateUrl: './quantitative-precip-layer.component.html',
    styleUrls: ['./quantitative-precip-layer.component.scss'],
})
export class QuantitativePrecipLayerComponent extends EagleILayer<QuantitativePrecipSource> {
    public showAccumulation: boolean = false;
    public defaultSelection: LayerInfo;
    public layerInfo: LayerInfo[] = [
        {id: 1, value: '1-hr', layerId: 3},
        {id: 2, value: '3-hr', layerId: 7},
        {id: 3, value: '6-hr', layerId: 11},
        {id: 4, value: '12-hr', layerId: 15},
        {id: 5, value: '24-hr', layerId: 19},
        {id: 6, value: '48-hr', layerId: 23},
        {id: 7, value: '72-hr', layerId: 27},
    ];

    constructor(private legendService: LeafletLegendService) {
        super();
        this.defaultSelection = this.layerInfo[0];
    }

    afterViewInit(): void {}

    public toggleLayer(activeState: boolean): void {
        if (activeState) {
            if (!this.layerSource) {
                this.layerSource = new QuantitativePrecipSource(this.mapLayer);

                this.legendService.getLegend(this).subscribe((legend) => (this.legend = legend));
            }
            this.defaultSelection = this.layerInfo[0];
            this.updateData(this.defaultSelection);
        } else {
            this.layerSource.removeFromMap();
        }
    }

    public updateData(itemClickedOn: LayerInfo): void {
        this.layerSource.fetchImage([itemClickedOn.layerId]);
        this.layerSource.addToMap();
    }
}
