import {Injectable} from '@angular/core';
import {scaleLinear} from 'd3-scale';
import {ApplicationConfig} from '../../../classes/application-config';
import {LegendMetric} from '../../../../shared/enums/legend-metric.enum';
import {HistoricalOutageData} from '../classes/historical-outage-data';
import {FeatureStyle} from '../interfaces/style.interface';

interface LegendInfo {
    display: string;
    compareValue: number;
}

@Injectable({
    providedIn: 'root',
})
export class LayerStyleService {
    constructor() {
        this.incidentCountScale = scaleLinear()
            .domain([0, 10])
            .range(['rgba(0,255,0,.9)', 'rgba(255,0,0,.9)'] as any);
        this.earthquakeScale = scaleLinear()
            .domain([0, 10])
            .range(['#fb6a4a', '#67000d'] as any);

        this.voltageScale = scaleLinear().domain([0, 1000000]).range([1, 5]);

        this.percentOutDomain.push({value: 2, color: this.colorByPercent(2)});

        for (let i = 20; i <= 100; i += 10) {
            const item = {value: i, color: this.colorByPercent(i)};
            this.percentOutDomain.push(item);
        }
    }

    static layerStyles: Map<string, any> = new Map<string, any>();

    static isoZoneColors = {
        'CALIFORNIA INDEPENDENT SYSTEM OPERATOR': '#C68278',
        'ERCOT ISO': '#7595B3',
        'ISO NEW ENGLAND INC.': '#BB7ACD',
        'MIDWEST INDEPENDENT SYSTEM OPERATOR': '#D380B0',
        'NEW YORK INDEPENDENT SYSTEM OPERATOR': '#95B767',
        'PJM INTERCONNECTION': '#7AC189',
        'SOUTHWEST POWER POOL': '#D3CB84',
    };

    static nomRange = ['#7bf816', '#cee915', '#f8f016', '#f5852a', '#ef271d'];
    static nomColorblindRange = ['#008837', '#a6dba0', '#f7f7f7', '#c2a5cf', '#7b3294'];

    static readonly nomOutageLegend: LegendInfo[] = [
        {display: '1k', compareValue: 1000},
        {display: '10k', compareValue: 10000},
        {display: '50k', compareValue: 50000},
        {display: '200k', compareValue: 200000},
        {display: '>200k', compareValue: 200000},
    ];

    static readonly nomPercentLegend: LegendInfo[] = [
        {display: '2%', compareValue: 0.02},
        {display: '5%', compareValue: 0.05},
        {display: '10%', compareValue: 0.1},
        {display: '20%', compareValue: 0.2},
        {display: '>20%', compareValue: 0.2},
    ];

    // TODO: Refactor
    static colorOutage(outageData: HistoricalOutageData): string {
        const preferences = ApplicationConfig.currentUserPreferences.getValue();
        let value = preferences.getLegendMetric() === LegendMetric.CUSTOMERS_OUT ? outageData.currentOutage : outageData.percentOut;

        // setting the color to grey anytime covered customers is 0 or undefined
        const invalidPercent = !outageData.currentOutageHasOverrideData && !outageData.coveredCustomers;
        if (preferences.getLegendMetric() === LegendMetric.PERCENTAGE && invalidPercent) {
            value = -1;
        }

        return LayerStyleService.colorByCount(value);
    }

    // TODO: Refactor
    static colorByCount(value: number | string, colorRange?: string[]): string {
        const preferences = ApplicationConfig.currentUserPreferences.getValue();
        const metric = LegendMetric.from(preferences.legendMetric);
        const legendPreference = preferences.legendStyle;

        const legend = metric === LegendMetric.CUSTOMERS_OUT ? LayerStyleService.nomOutageLegend : LayerStyleService.nomPercentLegend;
        const values = legend.map((v) => v.compareValue);
        let range = LayerStyleService.nomRange;
        // debugger;

        if (legendPreference && legendPreference.colors) {
            range = legendPreference.colors;
        }

        if (colorRange !== undefined) {
            range = colorRange;
        }

        if (+value < 0) {
            return '#808080';
        } else if (+value <= values[0]) {
            return range[0];
        } else if (+value <= values[1]) {
            return range[1];
        } else if (+value <= values[2]) {
            return range[2];
        } else if (+value <= values[3]) {
            return range[3];
        } else if (+value > values[4]) {
            return range[4];
        } else {
            return '#808080';
        }
    }

    private readonly earthquakeScale: any;
    private readonly incidentCountScale: any;
    public voltageScale: any;
    public readonly radarDomain = [-25, -20, -15, -10, -5, 0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75].reverse();

    public readonly percentOutDomain: any[] = [];

    private styleMap = new Map<string, FeatureStyle[]>();

    public getLayerStyle(key: string, createStyle?: () => FeatureStyle[]): FeatureStyle[] {
        let value: FeatureStyle[] | undefined;
        if (!this.styleMap.has(key) && createStyle) {
            const style = createStyle();
            this.styleMap.set(key, style);
        }
        value = this.styleMap.get(key);
        return value ? value : [];
    }

    public colorScale(value: number) {
        if (value <= 2) {
            return '#0a994d';
        } else if (value <= 20) {
            return '#a5db63';
        } else if (value <= 30) {
            return '#ffffe5';
        } else if (value <= 40) {
            return '#fff7bc';
        } else if (value <= 50) {
            return '#fee391';
        } else if (value <= 60) {
            return '#fec44f';
        } else if (value <= 70) {
            return '#fe9929';
        } else if (value <= 80) {
            return '#f66d3b';
        } else if (value <= 90) {
            return '#d92e1d';
        } else if (value >= 100) {
            return '#b11506';
        } else {
            return '#90230b';
        }
    }

    public colorByPercent(percent: number): string {
        let color: string;
        if (percent > 100) {
            percent = 100;
        }

        color = percent >= 0 ? this.colorScale(Number(percent)) : 'rgba(0,0,0,.5)';

        return color;
    }

    public weatherLegend(value: number): string {
        if (value < -20) {
            return '#e8dada';
        } else if (value < -15) {
            return '#fbf8f8';
        } else if (value < -10) {
            return '#fefefe';
        } else if (value < -5) {
            return '#fafdfe';
        } else if (value < 0) {
            return '#f7f7f7';
        } else if (value < 5) {
            return '#E2E1E1';
        } else if (value < 10) {
            return '#85EBE7';
        } else if (value < 15) {
            return '#4F95F4';
        } else if (value < 20) {
            return '#0000F8';
        } else if (value < 25) {
            return '#91FB00';
        } else if (value < 30) {
            return '#6FC300';
        } else if (value < 35) {
            return '#4D8A00';
        } else if (value < 40) {
            return '#FFFF00';
        } else if (value < 45) {
            return '#DEBF00';
        } else if (value < 50) {
            return '#E89A00';
        } else if (value < 55) {
            return '#DE3800';
        } else if (value < 60) {
            return '#BA2D00';
        } else if (value < 65) {
            return '#A42700';
        } else if (value < 70) {
            return '#DB34FE';
        } else if (value < 75) {
            return '#8B55C6';
        } else if (value >= 75) {
            return '#FDFEFE';
        } else {
            return 'rgba(0,0,0,.5)';
        }
    }
}
