<div class="event-header">
    From <b [innerHTML]="event.sourceType.description | sanitize"></b> at <b>{{ event.newsDate | momentDate }}</b>
    <div *ngIf="event.headerData">
        <span *ngIf="event.headerData.length > 0">
            (
            <span *ngFor="let infoElement of event.headerData; let i = index">
                <b>{{ infoElement.name }}</b
                >: {{ infoElement.display }}
                <span *ngIf="i !== event.headerData.length - 1">, </span>
            </span>
            )
        </span>
    </div>
</div>

<mat-chip-listbox [selectable]="false">
    <mat-chip-option *ngFor="let keyword of event.keywords" class="keyword"
        ><i class="fa fa-tag"></i> {{ keyword.keyword }}
    </mat-chip-option>
</mat-chip-listbox>

<p class="event-content" [innerHTML]="event.content | sanitize"></p>

<ng-container *ngIf="event.media && event.media.length > 0">
    <div class="additional-info">Associated Media</div>
    <ul>
        <li *ngFor="let media of event.media" (click)="$event.stopPropagation()">
            <a [href]="media.link" target="_blank">{{ media.source }}</a>
        </li>
    </ul>
</ng-container>

<ng-container *ngIf="event.bodyData.length > 0">
    <div class="additional-info">Additional Information</div>
    <div *ngFor="let infoElement of event.bodyData">
        <span class="html-text" *ngIf="event.source === 1" [innerHTML]="infoElement.display | sanitize"> </span>
        <span *ngIf="event.source !== 1">{{ infoElement.name }}: {{ infoElement.display }}</span>
    </div>
</ng-container>
