
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

export class GenContactType {
    static readonly EMAIL: GenContactType = new GenContactType('EMAIL');
    static readonly PHONE_NUMBER: GenContactType = new GenContactType('PHONE_NUMBER');

    public readonly name: string;

    private constructor(name: string) {
        this.name = name;
    }

    static forName(name: string) {
        if (name === 'EMAIL') return GenContactType.EMAIL;
        if (name === 'PHONE_NUMBER') return GenContactType.PHONE_NUMBER;
        throw new Error('Unexpected value for GenContactType:' + name);
    }

    static values(): GenContactType[] {
        return [
            GenContactType.EMAIL,
            GenContactType.PHONE_NUMBER,
        ];
    }

    toJSON(): string {
        return this.name;
    }

    toString(): string {
        return this.name;
    }
}

