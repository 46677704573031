import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ApplicationConfig} from 'frontend/src/app/classes/application-config';
import * as moment from 'moment';
import {CardFilters} from '../../classes/card-filters';

interface TimeSegment {
    hour: number;
    minute: number;
    period: string;
}

interface TimeStep {
    time: string;
    index: number;
    valid: boolean;
}

@Component({
    selector: 'eaglei-eoc-date-picker',
    templateUrl: './eoc-date-picker.component.html',
    styleUrls: ['./eoc-date-picker.component.scss'],
})
export class EocDatePickerComponent implements OnInit, OnChanges {
    public startDate: Date;
    public endDate: Date;
    private currentDates: CardFilters = new CardFilters();
    public now: moment.Moment = moment();
    public minStart: moment.Moment = moment().subtract(3, 'days');
    public maxEnd: moment.Moment = moment().add(14, 'days');

    public startTime: TimeStep;
    public endTime: TimeStep;
    public startTimeSteps: TimeStep[] = [];
    public endTimeSteps: TimeStep[] = [];

    @Input() singlePicker: boolean;
    @Input() ongoing: boolean;
    @Input() isStartTime: boolean;
    @Input() timeSteps: TimeStep[] = [];
    @Input() start: moment.Moment = moment();
    @Input() end: moment.Moment = moment().add(1, 'day');
    @Input() pickerName = 'Date';
    @Input() showTinyApply: boolean;
    @Input() tinyButtonText = 'Apply to fields below';
    @Input() hasDarkBackground: boolean;
    @Input() isCurrent: boolean;
    @Input() disabled: boolean;
    @Output() change: EventEmitter<any> = new EventEmitter<any>();

    constructor(private cd: ChangeDetectorRef) {}

    ngOnInit() {
        this.timeSteps = this.timeSteps.length > 0 ? this.timeSteps : this.createTimeSteps();
        this.startTimeSteps = this.createTimeSteps();
        this.endTimeSteps = this.createTimeSteps();

        this.start = moment(this.start);
        this.end = moment(this.end);

        this.roundMinute(this.now);
        this.roundMinute(this.start);
        this.roundMinute(this.end);

        this.startDate = this.start.toDate();
        this.endDate = this.end.toDate();

        [this.startDate, this.startTime] = this.initializeDates(this.start, true);
        [this.endDate, this.endTime] = this.initializeDates(this.end, false);

        this.startTimeSteps.forEach((step) => (step.valid = this.isValidStartTime(step)));
        this.endTimeSteps.forEach((step) => (step.valid = this.isValidEndTime(step)));

        this.cd.detectChanges();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.start && !changes.start.isFirstChange()) {
            [this.startDate, this.startTime] = this.initializeDates(this.start, true);
        }
        if (changes.end && !changes.end.isFirstChange()) {
            [this.endDate, this.endTime] = this.initializeDates(this.end, false);
        }
    }

    private initializeDates(date: moment.Moment, isStart: boolean): [Date, TimeStep] {
        this.roundMinute(date.local());
        const formatted: string = date.format('h:mm A');
        const step: TimeStep | undefined = (isStart ? this.startTimeSteps : this.endTimeSteps).find((s) => s.time === formatted);
        const ts = step || {
            time: formatted,
            index: 0,
            valid: true,
        };

        return [date.toDate(), ts];
    }

    public roundMinute(d: moment.Moment) {
        const minute = Math.floor(d?.minute() / 15) * 15;
        d.minute(minute).second(0);
    }

    public createTimeSteps(): TimeStep[] {
        let index = 0;
        const steps: TimeStep[] = [];
        for (let i = 0; i <= 23; i++) {
            for (let j = 0; j < 4; j++) {
                let hour = i || 12;
                if (hour > 12) {
                    hour -= 12;
                }

                steps.push({
                    time: `${hour}:${j === 0 ? '00' : j * 15} ${i < 12 ? 'AM' : 'PM'}`,
                    index,
                    valid: true,
                });
                index++;
            }
        }
        return steps;
    }

    public parseTime(step: TimeStep): TimeSegment {
        const split: string[] = step.time.split(' ');
        const times: string[] = split[0].split(':');
        const ret: TimeSegment = {
            hour: +times[0],
            minute: +times[1],
            period: split[1],
        };

        if (ret.period === 'PM' && ret.hour !== 12) {
            ret.hour += 12;
        } else if (ret.period === 'AM' && ret.hour === 12) {
            ret.hour = 0;
        }

        return ret;
    }

    public updateTime(step: TimeStep, date: Date, applyChange: boolean = false): void {
        const parsedTime = this.parseTime(step);
        date.setHours(parsedTime.hour, parsedTime.minute, 0);

        if (applyChange && !this.showTinyApply) {
            this.applyChanges();
        }
    }

    public updateDateRange(type: string, date: Date, updateTimeOnly: boolean = false): void {
        if (type === 'start') {
            this.startDate = date;
            this.updateTime(this.startTime, this.startDate);
        } else {
            this.endDate = date;
            this.updateTime(this.endTime, this.endDate);
        }

        this.startTimeSteps.forEach((step) => (step.valid = this.isValidStartTime(step)));
        if (!this.singlePicker) {
            this.endTimeSteps.forEach((step) => (step.valid = this.isValidEndTime(step)));
        }

        if (this.endDate < this.startDate) {
            this.startTime = this.endTime;
        }

        if (!this.showTinyApply && !updateTimeOnly) {
            this.applyChanges();
        }
    }

    private updateDates(): void {
        this.updateDateRange('start', this.startDate, true);
        this.updateDateRange('end', this.endDate, true);

        this.startTimeSteps.forEach((step) => (step.valid = this.isValidStartTime(step)));
        this.endTimeSteps.forEach((step) => (step.valid = this.isValidEndTime(step)));
    }

    public applyChanges(): void {
        this.start = moment(this.startDate);
        this.end = moment(this.endDate);

        if (this.start.isSameOrAfter(this.end)) {
            this.end = moment(this.start).add(15, 'minutes');
            [this.startDate, this.startTime] = this.initializeDates(this.start, true);
            [this.endDate, this.endTime] = this.initializeDates(this.end, false);
            this.updateDates();
        }

        if (this.singlePicker && !this.isStartTime) {
            if (this.start.isSameOrBefore(this.now)) {
                this.start = moment(this.now).add(15, 'minutes');
                [this.startDate, this.startTime] = this.initializeDates(this.start, true);
                this.updateDates();
            }
        }

        this.currentDates.startDate = moment(this.startDate);
        this.currentDates.endDate = moment(this.endDate);

        this.change.emit(this.singlePicker ? this.currentDates.startDate : this.currentDates);
    }

    private findEndTimeStep(): TimeStep {
        if (this.singlePicker) {
            return (this.isStartTime ? this.startTimeSteps : this.endTimeSteps).find((ts) => ts.time === this.now.format('LT'));
        }

        return this.timeSteps[0];
    }

    private validTimeCheck(isStart: boolean = false) {
        const diffInDays = Math.abs((this.singlePicker || isStart ? this.now : moment(this.endDate)).diff(moment(this.startDate), 'days'));
        if (diffInDays > 0) {
            return true;
        } else if ((this.singlePicker || isStart ? this.now : moment(this.endDate)).day() !== moment(this.startDate).day()) {
            return true;
        }
        return false;
    }

    private isValidStartTime(start: TimeStep): boolean {
        const endTime = this.ongoing
            ? this.startTimeSteps.find((ts) => ts.time === ApplicationConfig.roundMinute(this.now).format('LT'))
            : this.endTime;
        return (
            this.validTimeCheck(true) ||
            (this.singlePicker
                ? this.isStartTime
                    ? start.index <= this.findEndTimeStep().index
                    : start.index > this.findEndTimeStep().index
                : start.index <= endTime.index)
        );
    }

    private isValidEndTime(end: TimeStep): boolean {
        const p = this.parseTime(end);
        const t = moment(this.endDate).toDate();
        t.setHours(p.hour, p.minute, 0);
        return this.validTimeCheck() || this.startTime.index < end.index;
    }
}
