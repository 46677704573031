
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import * as moment from 'moment';

export class GenEventResult {

    id: number;
    advdate: moment.Moment;
    area: Object;
    areaList: number[];
    boundary: string;
    conditions: string[];
    customers: number;
    eventId: number;
    forecastAdvnum: string;
    forecastText: string;
    forecastTo: moment.Moment;
    infrastructureMap: Object;
    infrastructures: string[];
    key: string;
    latitude: number;
    longitude: number;
    pubAdvnum: string;
    resultId: string;
    resultTimestamp: moment.Moment;
    windgust: number;
    windspeed: number;

    constructor();
    constructor( jsonObj: any );
    constructor( genEventResult: GenEventResult);
    constructor( id: number );

    constructor( input?: GenEventResult|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            if ( input.advdate && typeof input.advdate === 'string' ) {
                this.advdate = moment(input.advdate).utc();
            } else {
                this.advdate = input.advdate;
            }
            if( input.area ) {
              this.area = new Object( input.area );
            }
            this.areaList = input.areaList; // list [number]
            this.boundary = input.boundary; // string [string]
            this.conditions = input.conditions; // list [string]
            this.customers = input.customers; // int [number]
            this.eventId = input.eventId; // int [number]
            this.forecastAdvnum = input.forecastAdvnum; // string [string]
            this.forecastText = input.forecastText; // string [string]
            if ( input.forecastTo && typeof input.forecastTo === 'string' ) {
                this.forecastTo = moment(input.forecastTo).utc();
            } else {
                this.forecastTo = input.forecastTo;
            }
            if( input.infrastructureMap ) {
              this.infrastructureMap = new Object( input.infrastructureMap );
            }
            this.infrastructures = input.infrastructures; // list [string]
            this.key = input.key; // string [string]
            this.latitude = input.latitude; // float [number]
            this.longitude = input.longitude; // float [number]
            this.pubAdvnum = input.pubAdvnum; // string [string]
            this.resultId = input.resultId; // string [string]
            if ( input.resultTimestamp && typeof input.resultTimestamp === 'string' ) {
                this.resultTimestamp = moment(input.resultTimestamp).utc();
            } else {
                this.resultTimestamp = input.resultTimestamp;
            }
            this.windgust = input.windgust; // int [number]
            this.windspeed = input.windspeed; // int [number]
       }
    }

    static jsonResponseToList(arr: any[]): GenEventResult[] {
        if (arr && arr.map) {
          return arr.map(a => new GenEventResult(a));
        } else {
          return [];
        }
    }
}

