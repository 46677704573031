import {PopoverElement} from '../../../classes/popover-element';

export class CoordinateFeature {
    public name: string;
    public layerName: string;
    public data: PopoverElement[];

    constructor(name: string, type: string, data: PopoverElement[]) {
        this.name = name;
        this.layerName = type;
        this.data = data;
    }
}
