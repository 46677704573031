import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MapLayoutComponent} from './components/map-layout/map-layout.component';
import {LeafletMapComponent} from './components/leaflet-map/leaflet-map.component';
// import { MapLiteComponent } from '../lite/components/map-lite/map-lite.component';

export const mapRoutes: Routes = [
    {
        path: '',
        redirectTo: 'view',
        pathMatch: 'full',
    },
    {
        path: 'view',
        component: MapLayoutComponent,
    },
    {
        path: 'leaf',
        component: LeafletMapComponent,
        data: {
            useNewDesign: true,
        },
    },
    // {
    //   path: 'lite',
    //   component: MapLiteComponent
    // }
];

@NgModule({
    imports: [RouterModule.forChild(mapRoutes)],
    exports: [RouterModule],
})
export class MapRoutingModule {}
