
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

export class GenServiceType {
    static readonly VECTOR: GenServiceType = new GenServiceType('VECTOR');
    static readonly WMS: GenServiceType = new GenServiceType('WMS');
    static readonly ARCGIS: GenServiceType = new GenServiceType('ARCGIS');
    static readonly VECTORTILE: GenServiceType = new GenServiceType('VECTORTILE');

    public readonly name: string;

    private constructor(name: string) {
        this.name = name;
    }

    static forName(name: string) {
        if (name === 'VECTOR') return GenServiceType.VECTOR;
        if (name === 'WMS') return GenServiceType.WMS;
        if (name === 'ARCGIS') return GenServiceType.ARCGIS;
        if (name === 'VECTORTILE') return GenServiceType.VECTORTILE;
        throw new Error('Unexpected value for GenServiceType:' + name);
    }

    static values(): GenServiceType[] {
        return [
            GenServiceType.VECTOR,
            GenServiceType.WMS,
            GenServiceType.ARCGIS,
            GenServiceType.VECTORTILE,
        ];
    }

    toJSON(): string {
        return this.name;
    }

    toString(): string {
        return this.name;
    }
}

