import {GenContactType} from 'frontend/generated/serverModels/GenContactType';
import {GenRRCCDesk} from 'frontend/generated/serverModels/GenRRCCDesk';
import {RRCCContact} from 'frontend/src/app/classes/rrcc-contact';

export class RRCCDesk extends GenRRCCDesk {
    public contacts: RRCCContact[] = [];
    public emails: RRCCContact[] = [];
    public phones: RRCCContact[] = [];

    constructor(desk?: RRCCDesk) {
        super(desk);

        if (desk) {
            this.contacts = desk.contactInfo?.map((c) => new RRCCContact(c as any)) || [];
            this.contacts.forEach((c) => {
                switch (c.type) {
                    case GenContactType.EMAIL:
                        this.emails.push(c);
                        return;
                    case GenContactType.PHONE_NUMBER:
                        this.phones.push(c);
                        return;
                }
            });
        }
    }
}
