import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {Router} from '@angular/router';
import {GenAggregationLevel} from 'frontend/generated/serverModels/GenAggregationLevel';
import {GenPopoverDataType} from 'frontend/generated/serverModels/GenPopoverDataType';
import {HttpInterceptorService} from 'frontend/src/app/services/http-interceptor.service';
import * as moment from 'moment';
import {LeafletMapLayer} from '../../../layer/classes/leaflet-map-layer';
import {LayerService} from '../../../layer/services/layer.service';
import {LeafletNomSource} from '../../../layer/sources/leaflet-nom-source';
import {MapOptions} from '../../../map/classes/map-options';
import {OutageSnapshotDate} from '../../../report/classes/outage-snapshot-date';
import {ReportService} from '../../../report/services/report.service';
import {EventSummaryFilters} from '../../../system-event/classes/event-summary-filters';
import {BaseWidget} from '../../classes/base-widget';

@Component({
    selector: 'eaglei-snapshot-widget',
    templateUrl: './snapshot-widget.component.html',
    styleUrls: ['./snapshot-widget.component.scss'],
})
export class SnapshotWidgetComponent extends BaseWidget implements AfterViewInit {
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

    public showMask: boolean;

    private sources: LeafletNomSource[] = [];

    // Data Properties
    public snapshots: OutageSnapshotDate[] = [];
    public numberOfSnapshots: number[] = [0, 1];

    public nomLayer: LeafletMapLayer;

    // Map Properties
    public mapOptions: MapOptions;
    public dataLoaded: boolean;

    public eventFilters: EventSummaryFilters = new EventSummaryFilters();

    constructor(
        private widgetElement: ElementRef,
        private reportService: ReportService,
        private layerService: LayerService,
        private router: Router
    ) {
        super(widgetElement);

        this.layerService.getLayerByHandle().subscribe((layer) => {
            this.nomLayer = layer;

            layer.popoverData = layer.popoverData.filter((pd) => pd.type !== GenPopoverDataType.DATE && !pd.display?.startsWith('Last'));
        });

        this.mapOptions = new MapOptions().setZoom(3, 1).setCenter(37.2, -92);
        this.mapOptions.onlyManualZoom = true;
        this.mapOptions.show = {
            sidebar: false,
            refresh: false,
            export: false,
            coverage: false,
            coordinate: false,
            zoom: true,
            panControl: false,
            zoomToHome: false,
        };
    }

    protected handleEventFilterChange(eventFilters: EventSummaryFilters): void {
        this.eventFilters = eventFilters;

        // Setting a delay to not fight over the MapRef with Outage Map Widget
        setTimeout(() => this.getOutageSnapshots(), 5000);
    }

    public ngAfterViewInit(): void {
        // this.paginator.pageSize = 2;
        // Setting a delay to not fight over the MapRef with Outage Map Widget
        // setTimeout(() => this.getOutageSnapshots(), 2500);
    }

    destroyWidget(): void {}

    private getOutageSnapshots(): void {
        this.showMask = true;
        this.dataLoaded = false;

        const filters = {
            aggregationLevel: GenAggregationLevel.county,
            startDate: moment(this.eventFilters?.event?.eventStart),
            endDate: moment(this.eventFilters?.date),
            interval: 12,
            states: this.eventFilters.locations,
        };

        HttpInterceptorService.clearInterceptor(this.widgetInterceptorKey);
        HttpInterceptorService.pendingRequests[this.widgetInterceptorKey] = this.reportService
            .getOutageSnapshot(filters)
            .subscribe((res) => {
                HttpInterceptorService.deleteFromInterceptor(this.widgetInterceptorKey);
                this.sources = [];
                this.snapshots = res;
                this.dataLoaded = true;
                this.showMask = res.length === 0;

                this.snapshots.sort((a, b) => {
                    return a.runStartTime.valueOf() - b.runStartTime.valueOf();
                });

                // this.paginator.length = this.snapshots.length;
                // this.paginator.firstPage();
            });
    }

    public setMapRef(snapshot: OutageSnapshotDate, map: any) {
        const nomSource = new LeafletNomSource(this.nomLayer);
        map.dragging.disable();
        nomSource.mapRef = map;
        // nomSource.showAllPopoverData = false;

        nomSource.updateLocationFilter(this.eventFilters.locations);
        nomSource.processFeatures(snapshot?.historicalData);

        nomSource.addToMap();
        nomSource.changeOpacity(0.8);

        this.sources.forEach((source) => {
            // @ts-ignore
            source.mapRef.sync(nomSource.mapRef);
            // @ts-ignore
            nomSource.mapRef.sync(source.mapRef);
        });

        nomSource.fitToFeatures();
        this.sources.push(nomSource);
    }

    // TODO: Remove cool paging stuff if not needed for Widget
    public getPaginatorValues(): OutageSnapshotDate[] {
        if (!this.paginator) {
            console.warn('paginator not initialized');
        }

        const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
        const endIndex = this.paginator.pageSize * (this.paginator.pageIndex + 1);
        return this.snapshots.slice(startIndex, endIndex);
    }

    public getSnapshot(value: 'start' | 'now'): OutageSnapshotDate {
        // Get the values for the paginator
        // const values = this.getPaginatorValues();
        const snapshot = value === 'start' ? this.snapshots[0] : this.snapshots[this.snapshots.length - 1];

        return snapshot;
    }

    public pageSnapshots(page: PageEvent): void {
        const values = this.getPaginatorValues();

        // Removes the current outage data on all maps
        this.sources.forEach((s) => {
            s.mapRef.removeLayer(s.source);
        });

        // Adds new outage data on all the maps
        this.sources.forEach((s, i) => {
            const snapshot = values[i];

            if (snapshot !== undefined) {
                s.processFeatures(snapshot?.historicalData);
                s.redraw();
                s.addToMap();
            }
        });
    }

    public getMaskText(): string {
        return this.dataLoaded && this.snapshots.length === 0 ? 'No Snapshots Available' : 'Loading...';
    }

    public goToReport(): void {
        // noinspection JSIgnoredPromiseFromCall
        this.router.navigate(['app/reports/outagesnapshot']);
    }
}
