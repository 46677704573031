import {Injectable} from '@angular/core';
import {HttpClient, HttpHandler, HttpRequest} from '@angular/common/http';
import {Observable, Subject, Subscription, throwError} from 'rxjs';
import {catchError, publishReplay, refCount, takeUntil, tap} from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class HttpInterceptorService extends HttpClient {
    static unsubscribeToAll = new Subject<void>();
    static pendingRequests: {[name: string]: Subscription} = {};

    static clearInterceptor(key: string): void {
        if (HttpInterceptorService.pendingRequests[key]) {
            HttpInterceptorService.pendingRequests[key].unsubscribe();
            HttpInterceptorService.deleteFromInterceptor(key);
        }
    }

    static deleteFromInterceptor(key: string): void {
        delete HttpInterceptorService.pendingRequests[key];
    }

    private cache: Map<string, Observable<any>>;
    private cacheTimeStamps: Map<string, Date>;
    private _cacheDuration: number;

    get cacheDuration(): number {
        return this._cacheDuration;
    }

    set cacheDuration(milliseconds: number) {
        this._cacheDuration = milliseconds;
    }

    constructor(handler: HttpHandler) {
        super(handler);

        this.cacheDuration = 1000;
        this.cache = new Map<string, Observable<any>>();
        this.cacheTimeStamps = new Map<string, Date>();
    }

    private setCache(key: string, res: Observable<any>): void {
        this.cache.set(key, res);
        this.cacheTimeStamps.set(key, new Date());
    }

    private clearCache(key: string): void {
        this.cache.delete(key);
        this.cacheTimeStamps.delete(key);
    }

    private getCache(key: string, age = -1): Observable<any> | undefined {
        let ret = this.cache.get(key);
        if (ret && age > 0) {
            const now: number = new Date().valueOf();
            const val: Date | undefined = this.cacheTimeStamps.get(key);
            let then = 0;
            if (val !== undefined) {
                then = val.valueOf();
            }
            if (now - then > age) {
                ret = undefined;
            }
        }

        return ret;
    }

    request(first: string | HttpRequest<any>, url?: string, options: any = {}): Observable<any> {
        const key = JSON.stringify(first) + JSON.stringify(url) + JSON.stringify(options);
        let ret: Observable<any> | undefined;
        const self = this;

        if (options.interceptorKey) {
            HttpInterceptorService.clearInterceptor(options.interceptorKey);
        }

        let req: HttpRequest<any>;

        if (typeof first === 'string') {
            req = new HttpRequest(first as any, url || '', options);
        } else {
            req = first;
        }

        // cloning to update url
        // req = req.clone( { url: `${ServerConfig.SERVER_ADDRESS}${req.url}`, } );

        const shouldCache = req.method === 'GET';
        let cacheDuration: number;

        if (shouldCache) {
            const cacheDurationHeader = req.headers.get('eagleiCacheDuration');
            const cacheAgeHeader = req.headers.get('eagleiCacheAge');

            if (cacheDurationHeader !== null && cacheAgeHeader !== null) {
                cacheDuration = isNaN(+cacheDurationHeader) ? self.cacheDuration : +cacheDurationHeader;
                const cacheAge = isNaN(+cacheAgeHeader) ? undefined : +cacheAgeHeader;
                ret = self.getCache(key, cacheAge);
            }
        }
        if (!ret) {
            const catchErrorFunc = (res: any) => {
                self.cache.delete(key);
                if (res.status === 401 || res.status === 403) {
                    console.debug('Authorization Failure: ' + res.status + ' : ' + res.url);
                }
                return throwError(res);
            };

            const tapFunc = () => {
                if (shouldCache) {
                    setTimeout(() => {
                        self.clearCache(key);
                    }, cacheDuration);
                }
                if (options.interceptorKey) {
                    HttpInterceptorService.deleteFromInterceptor(options.interceptorKey);
                }
            };

            ret = super
                .request(req.method, req.url, options)
                .pipe(
                    catchError(catchErrorFunc),
                    tap(tapFunc),
                    takeUntil(HttpInterceptorService.unsubscribeToAll),
                    publishReplay(1),
                    refCount()
                );

            if (shouldCache) {
                self.setCache(key, ret);
            }
        }
        return ret;
    }
}
