import {GroupedHeadoutEvent} from '../interfaces/grouped-headout-event';
import {SystemEvent} from '../../../modules/system-event/classes/system-event';
import * as moment from 'moment';

export const groupHeadoutEventByStatus = (events: SystemEvent[]) => {
    const grouping: GroupedHeadoutEvent = {
        active: [],
        inactive: [],
    };

    const now = moment();
    events.forEach((event) => {
        const active = now.isBetween(event.eventStart, event.eventEnd, null, '[]');

        if (active) {
            grouping.active.push(event);
        } else {
            grouping.inactive.push(event);
        }
    });

    return grouping;
};
