<div>
    <div class="dialog-header">
        <span class="title">EIL Location Files</span>
        <mat-icon [inline]="true" class="clickable" fontSet="fas" fontIcon="fa-times" (click)="dialogRef.close()"></mat-icon>
    </div>

    <div class="dialog-content" #content>
        <div>
            <div *ngFor="let document of datapoint.documents">
                <a (click)="downloadDocument(document, $event)">{{ document.filename }}</a>
                <div>
                    <p class="clickable sub-title" (click)="document.viewDescription = !document.viewDescription">
                        {{ document.viewDescription ? 'Hide Description' : 'Show Description' }}
                    </p>
                    <p class="hide sub-title" [class.show]="document.viewDescription">{{ document.description }}</p>
                </div>
            </div>
        </div>
    </div>

    <div class="dialog-actions">
        <button mat-stroked-button (click)="downloadAll()">Download All</button>
    </div>
</div>
