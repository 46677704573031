
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import { GenCounty } from './GenCounty';

export class GenEventResultCustomerDetail {

    id: number;
    county: GenCounty;
    countyId: number;
    customers: number;
    eventId: number;
    households: number;
    percent: number;
    resultId: number;
    totalCustomers: number;

    constructor();
    constructor( jsonObj: any );
    constructor( genEventResultCustomerDetail: GenEventResultCustomerDetail);
    constructor( id: number );

    constructor( input?: GenEventResultCustomerDetail|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            if( input.county ) {
              this.county = new GenCounty( input.county );
            }
            this.countyId = input.countyId; // int [number]
            this.customers = input.customers; // int [number]
            this.eventId = input.eventId; // int [number]
            this.households = input.households; // int [number]
            this.percent = input.percent; // float [number]
            this.resultId = input.resultId; // int [number]
            this.totalCustomers = input.totalCustomers; // int [number]
       }
    }

    static jsonResponseToList(arr: any[]): GenEventResultCustomerDetail[] {
        if (arr && arr.map) {
          return arr.map(a => new GenEventResultCustomerDetail(a));
        } else {
          return [];
        }
    }
}

