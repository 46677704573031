import {GenLayout} from '../../../../../generated/serverModels/GenLayout';
import {WidgetDetail} from './widget-detail';
import {LandingGridItem} from '../../../landing/classes/landing-grid-item';

export class Layout extends GenLayout {
    static fromDashboard(dashboard: LandingGridItem[]): Layout {
        const layout = new Layout();
        layout.details = dashboard.map((ele) => {
            return new WidgetDetail(ele.detail);
        });
        return layout;
    }

    // Override for generated property
    public details: WidgetDetail[];
    public editing: boolean;

    constructor(layout?: Layout) {
        super(layout);

        if (!!this.details) {
            this.details = layout.details.map((d) => new WidgetDetail(d));
        }
    }
}
