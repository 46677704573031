
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import { GenOutageData } from './GenOutageData';
import { GenUtilityUrl } from './GenUtilityUrl';
import * as moment from 'moment';

export class GenDataQuality {

    acronym: string;
    activeStatus: boolean;
    actualCustomers: number;
    coveredCustomers: number;
    lastOutage: moment.Moment;
    mapURL: string;
    outageURLs: GenUtilityUrl[];
    outages: GenOutageData[];
    resolution: string;
    stateAbbreviation: string;
    stateId: number;
    subUtilityId: number;
    twitterURL: string;
    utilityId: number;
    utilityName: string;

    constructor();
    constructor( jsonObj: any );
    constructor( genDataQuality: GenDataQuality);

    constructor( input?: GenDataQuality ) {
        if ( input && typeof input !== 'number' ) {
            this.acronym = input.acronym; // string [string]
            this.activeStatus = input.activeStatus; // boolean [boolean]
            this.actualCustomers = input.actualCustomers; // int [number]
            this.coveredCustomers = input.coveredCustomers; // int [number]
            if ( input.lastOutage && typeof input.lastOutage === 'string' ) {
                this.lastOutage = moment(input.lastOutage).utc();
            } else {
                this.lastOutage = input.lastOutage;
            }
            this.mapURL = input.mapURL; // string [string]
            if ( input.outageURLs && input.outageURLs.map ) { // list [GenUtilityUrl]
                this.outageURLs = input.outageURLs.map( ( x: GenUtilityUrl ) => {
                    return new GenUtilityUrl( x );
                } );
            } else {
                this.outageURLs = input.outageURLs;
            }
            if ( input.outages && input.outages.map ) { // list [GenOutageData]
                this.outages = input.outages.map( ( x: GenOutageData ) => {
                    return new GenOutageData( x );
                } );
            } else {
                this.outages = input.outages;
            }
            this.resolution = input.resolution; // string [string]
            this.stateAbbreviation = input.stateAbbreviation; // string [string]
            this.stateId = input.stateId; // int [number]
            this.subUtilityId = input.subUtilityId; // int [number]
            this.twitterURL = input.twitterURL; // string [string]
            this.utilityId = input.utilityId; // int [number]
            this.utilityName = input.utilityName; // string [string]
       }
    }

    static jsonResponseToList(arr: any[]): GenDataQuality[] {
        if (arr && arr.map) {
          return arr.map(a => new GenDataQuality(a));
        } else {
          return [];
        }
    }
}

