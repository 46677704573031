import * as moment from 'moment';

export class OilStorage {
    static fromApiResponse(data: any): OilStorage {
        const res = new OilStorage();
        res.id = parseInt(data.id);
        res.amount = parseInt(data.amount);
        res.capacityUtilization = parseFloat(data.capacity_utilization);
        res.fieldType = data.field_type;
        res.product = data.product;
        res.region = data.region;
        res.reportDate = moment(data.report_date);
        res.week = parseInt(data.week);
        res.year = parseInt(data.year);

        return res;
    }

    public id: number;
    public amount: number;
    public capacityUtilization: number;
    public fieldType: string;
    public product: string;
    public region: string;
    public reportDate: moment.Moment;
    public week: number;
    public year: number;

    constructor(storage?: OilStorage) {
        if (storage) {
            this.id = storage.id;
            this.amount = storage.amount;
            this.capacityUtilization = storage.capacityUtilization;
            this.fieldType = storage.fieldType;
            this.product = storage.product;
            this.region = storage.region;
            this.reportDate = storage.reportDate;
            this.week = storage.week;
            this.year = storage.year;
        }
    }
}
