import {GenMax24HourCounty} from '../../../../../generated/serverModels/GenMax24HourCounty';
import {GenMax24HourZip} from '../../../../../generated/serverModels/GenMax24HourZip';

export class HistoricalOutageData extends GenMax24HourCounty {
    get percentOut(): number {
        return this._percentOut;
    }

    public title: string;
    public numericFips: number;
    public zipInfo: GenMax24HourZip;
    private _percentOut: number;
    private _countyData: boolean;
    public modeled: boolean;

    constructor(data?: HistoricalOutageData) {
        super(data);

        if (data) {
            this.updateName(data);
        }
    }

    private getPercent(): void {
        if (this.currentOutage === undefined || !this.coveredCustomers) {
            this._percentOut = undefined;
        } else {
            const percentOut = this.currentOutage / this.coveredCustomers;
            this._percentOut = isNaN(percentOut) ? undefined : percentOut;
        }
    }

    public updateName(data: HistoricalOutageData = this): HistoricalOutageData {
        this._countyData = data.countyFIPSCode !== undefined;
        const code =
            (data._countyData ? data.countyFIPSCode : data.zipInfo !== undefined ? data.zipInfo.zipCode : data.stateFIPSCode) ||
            (data as any).fipsCode;
        this.numericFips = parseInt(code);

        if (isNaN(this.numericFips)) {
            this.numericFips = -1;
        }

        const hadOverride = data.currentOutageHasOverrideData || data.maxOutage1HasOverrideData || data.maxOutage24HasOverrideData;
        if (this.coveredCustomers === 0 && !hadOverride) {
            this.coveredCustomers = undefined;
        }

        // Setting Model count as covered customers as that's the only customer count given
        if (data.zipInfo !== undefined) {
            this.coveredCustomers = data.zipInfo.modelCount;
        }

        // If there are current outages and not historical outages. The historical outages should be set to the current outage
        // since that is a valid outage number.
        if (!isNaN(data.currentOutage)) {
            if (isNaN(data.maxOutage1)) {
                this.maxOutage1 = data.currentOutage;
                this.maxOutage1RunStartTime = data.maxOutage1RunStartTime;
            }
            if (isNaN(data.maxOutage24)) {
                this.maxOutage24 = data.currentOutage;
                this.maxOutage24RunStartTime = data.maxOutage24RunStartTime;
            }
        }

        this.getPercent();

        if (data.countyName !== undefined) {
            this.title = data.countyName;
        } else if (this.zipInfo !== undefined) {
            this.title = this.zipInfo.zipCode;
        } else {
            this.title = data.stateName;
        }

        if (this.coveredCustomers !== undefined) {
            this.modeled = this.modelCount > 0;
        }

        return this;
    }

    public geometryKey(): string {
        return this._countyData ? this.countyFIPSCode : this.zipInfo !== undefined ? this.zipInfo.zipCode : this.stateFIPSCode;
    }
}
