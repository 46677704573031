<div class="container">
    <div class="content">
        <div class="widget-content">
            <div class="utilities">
                <span *ngIf="!isLoading" class="impacted">{{ utilList.length | eagleiNumber }}</span>
                <span *ngIf="isLoading" class="impacted">Loading...</span>
                <span class="sub-text">UTILITIES IMPACTED</span>
            </div>

            <div class="trend">
                <mat-icon class="trend-up">trending_up</mat-icon>
                <span class="sub-percent">0.02%</span>
            </div>
        </div>
    </div>
</div>
