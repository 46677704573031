
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import { GenPlanetSense } from './GenPlanetSense';

export class GenPlanetSenseLocation {

    boundary: string;
    fipsCodes: string[];
    latitude: number;
    longitude: number;
    stateId: number;
    tweets: GenPlanetSense[];
    utilityId: number;
    utilityName: string;

    constructor();
    constructor( jsonObj: any );
    constructor( genPlanetSenseLocation: GenPlanetSenseLocation);

    constructor( input?: GenPlanetSenseLocation ) {
        if ( input && typeof input !== 'number' ) {
            this.boundary = input.boundary; // string [string]
            this.fipsCodes = input.fipsCodes; // list [string]
            this.latitude = input.latitude; // float [number]
            this.longitude = input.longitude; // float [number]
            this.stateId = input.stateId; // int [number]
            if ( input.tweets && input.tweets.map ) { // list [GenPlanetSense]
                this.tweets = input.tweets.map( ( x: GenPlanetSense ) => {
                    return new GenPlanetSense( x );
                } );
            } else {
                this.tweets = input.tweets;
            }
            this.utilityId = input.utilityId; // int [number]
            this.utilityName = input.utilityName; // string [string]
       }
    }

    static jsonResponseToList(arr: any[]): GenPlanetSenseLocation[] {
        if (arr && arr.map) {
          return arr.map(a => new GenPlanetSenseLocation(a));
        } else {
          return [];
        }
    }
}

