import {GenCommunity} from '../../../../../generated/serverModels/GenCommunity';
import {CommunityFile} from './community-file';
import {CommunityUser} from './community-user';

export class Community extends GenCommunity {
    public files: CommunityFile[] = [];
    public users: CommunityUser[] = [];

    public approvedUsers: CommunityUser[] = [];
    public pendingUsers: CommunityUser[] = [];

    constructor(community?: Community) {
        super(community);

        if (community) {
            this.files = community.files.map((file) => new CommunityFile(file));
            this.users = community.users.map((user) => new CommunityUser(user));

            this.updateUserList();
        }
    }

    public isManager(userId: number): boolean {
        const user = this.users.find((u) => u.userId === userId);
        return user !== undefined ? user.manager : false;
    }

    public isMember(userId: number): boolean {
        const user = this.users.find((u) => u.userId === userId);
        return user !== undefined && !user.isDenied();
    }

    public isActiveMember(userId: number): boolean {
        const user = this.users.find((u) => u.userId === userId);
        return user !== undefined && user.isApproved();
    }

    public isUserPending(userId: number): boolean {
        const user = this.users.find((u) => u.userId === userId);
        return user !== undefined && user.isPending();
    }

    public getPendingRequests(): CommunityUser[] {
        return this.users.filter((user) => {
            return user.isPending();
        });
    }

    public updateUserList(): void {
        this.approvedUsers = this.users.filter((u) => u.isApproved());
        this.pendingUsers = this.users.filter((u) => u.isPending());
    }

    public getManagers(): CommunityUser[] {
        return this.approvedUsers.filter((communityUser) => communityUser.manager);
    }
}
