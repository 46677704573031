<div #header class="widget-header">
    <p class="title">WAO Nighttime Lights</p>

    <div class="btn-container">
        <button mat-stroked-button (click)="linkToReport()">View Report</button>
    </div>
</div>

<div #content class="widget-content table-container">
    <table mat-table [dataSource]="dataSource" matSort [matSortDisableClear]="true">
        <ng-container matColumnDef="state">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                State
                <eaglei-table-sort-header [columnName]="'state'" [sort]="sort"></eaglei-table-sort-header>
            </th>
            <td mat-cell *matCellDef="let info">
                {{ info.state.name }}
            </td>
        </ng-container>

        <ng-container matColumnDef="county">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                County
                <eaglei-table-sort-header [columnName]="'county'" [sort]="sort"></eaglei-table-sort-header>
            </th>
            <td mat-cell *matCellDef="let info">
                {{ info.countyName }}
            </td>
        </ng-container>

        <ng-container matColumnDef="structure">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Structure Count
                <eaglei-table-sort-header [columnName]="'structure'" [sort]="sort"></eaglei-table-sort-header>
            </th>
            <td mat-cell *matCellDef="let info">
                {{ info.structureCount }}
            </td>
        </ng-container>

        <ng-container matColumnDef="light">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Light Status
                <eaglei-table-sort-header [columnName]="'light'" [sort]="sort"></eaglei-table-sort-header>
            </th>
            <td mat-cell *matCellDef="let info">
                {{ getLightStatus(info.lightStatus) }}
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
        <tr mat-row *matRowDef="let row; columns: columnNames"></tr>
    </table>

    <div class="auto-mask hide-mask" *ngIf="dataSource?.filteredData?.length === 0">
        <p class="mask-message">No WAO Data for the selected date</p>
    </div>
</div>

<div #footer class="widget-footer">
    <mat-paginator [pageSize]="7" hidePageSize showFirstLastButtons></mat-paginator>
</div>
