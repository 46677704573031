import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {Report} from '../../report/classes/report';
import {map} from 'rxjs/operators';
import {Widget} from '../classes/widget';
import {Layout} from '../classes/layout';
import {ResponseWrapper} from 'frontend/src/app/classes/response-wrapper';
import * as moment from 'moment';
import {GenOutageSummaryTrend} from '../../../../../generated/serverModels/GenOutageSummaryTrend';
import {LandingGridItem} from '../../../landing/classes/landing-grid-item';
import {State} from '../../outage/classes/state';
import {GenMaxImpact} from '../../../../../generated/serverModels/GenMaxImpact';

@Injectable({
    providedIn: 'root',
})
export class WidgetService {
    // redraw Triggers
    static redrawChart = new BehaviorSubject<void>(undefined);
    static redrawMap = new BehaviorSubject<void>(undefined);
    static redrawSummary = new BehaviorSubject<any>(undefined);
    static resize = new Subject<LandingGridItem>();
    static domEvent = new Subject<MouseEvent>();

    // Setting Trigger
    static updatedSettings = new BehaviorSubject<any>(undefined);

    // Data Trigger
    static renderWidget = new Subject<void>();

    // Endpoint Urls
    private readonly reportUrl = 'api/dataprovider/reports';
    private readonly layoutUrl = `api/layout`;

    constructor(private http: HttpClient) {}

    public getPinnedReports(): Observable<Report[]> {
        const url = `${this.reportUrl}?pinnedOnly=true`;
        return this.http.get<any[]>(url).pipe(map((res) => res.map((report) => new Report(report))));
    }

    public getWidgets(): Observable<Widget[]> {
        const url = `${this.layoutUrl}/widgets`;
        return this.http.get<any[]>(url).pipe(map((widgets) => widgets.map((w) => new Widget(w))));
    }

    public saveLayout(layout: Layout): Observable<Layout> {
        const url = `${this.layoutUrl}/landing`;

        layout.details.forEach((detail) => {
            detail.id = undefined;
            detail.settings.forEach((setting) => (setting.id = undefined));
        });

        return this.http.post<any>(url, layout).pipe(map((l) => new Layout(l)));
    }

    public updateLayout(layout: Layout): Observable<Layout> {
        const url = `${this.layoutUrl}/landing/${layout.id}`;
        return this.http.put<any>(url, layout).pipe(map((l) => new Layout(l)));
    }

    public setLayoutAsDefault(layout: Layout): Observable<any> {
        const url = `${this.layoutUrl}/landing/default/${layout.id}`;
        return this.http.put(url, undefined);
    }

    public clearDefaultLayout(): Observable<any> {
        const url = `${this.layoutUrl}/landing/default/clear`;
        return this.http.put(url, undefined);
    }

    public loadLayouts(): Observable<Layout[]> {
        const url = `${this.layoutUrl}/landing`;
        return this.http.get<any[]>(url).pipe(map((layouts) => layouts.map((layout) => new Layout(layout))));
    }

    public getWidgetOutageSummary(level: string, end: moment.Moment, start?: moment.Moment): Observable<GenOutageSummaryTrend[]> {
        const url = `api/outagesummary/outagesummarytrend?level=${level}&end=${end.format()}&showZero=false${
            !!start ? `&start=${start.format()}` : ''
        }`;
        return this.http.get<ResponseWrapper<any>>(url).pipe(map((res) => res.data.map((trend) => new GenOutageSummaryTrend(trend))));
    }

    public getWidgetMaxImpacted(eventId: number, end: moment.Moment, states: State[]): Observable<GenMaxImpact> {
        let url = `api/event/impact?eventId=${eventId}&end=${end.format()}&`;
        url += states.length > 0 ? states.map((s) => `state=${s.abbreviation}`).join('&') : 'state=';
        return this.http.get<any>(url).pipe(map((res) => new GenMaxImpact(res)));
    }

    public bulkDelete(layoutsToDelete: Layout[]): Observable<any> {
        const url = `${this.layoutUrl}/landing/bulkdelete`;
        return this.http.post(url, layoutsToDelete);
    }
}
