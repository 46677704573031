import {Component} from '@angular/core';
import {HttpInterceptorService} from 'frontend/src/app/services/http-interceptor.service';
import {tap} from 'rxjs/operators';
import {EagleILayer} from '../../classes/eagle-i-layer';
import {LayerService} from '../../services/layer.service';
import {LeafletLegendService} from '../../services/leaflet-legend.service';
import {ActiveFiresSource} from '../../sources/active-fires-source';
import {Subject} from 'rxjs';

@Component({
    selector: 'eaglei-active-fires-layer',
    templateUrl: './active-fires-layer.component.html',
    styleUrls: ['./active-fires-layer.component.scss'],
})
export class ActiveFiresLayerComponent extends EagleILayer<ActiveFiresSource> {
    public error = new Subject<string>();

    constructor(private layerService: LayerService, private legendService: LeafletLegendService) {
        super();
    }

    afterViewInit(): void {}

    public toggleLayer(activeState: boolean, zoomToFeatures?: boolean): void {
        this.isActive = activeState;
        if (activeState) {
            if (!this.layerSource) {
                this.layerSource = new ActiveFiresSource(this.mapLayer);
                this.legendService.getLegend(this).subscribe((legend) => (this.legend = legend));
            }

            this.getActiveFires();
        } else {
            HttpInterceptorService.clearInterceptor(this.mapLayer.interceptorKey);
            this.layerSource.removeFromMap();
        }
    }

    private getActiveFires(): void {
        this.mapLayer.startLoading();

        const params = this.mapLayer.getParams();

        const paramStr = Object.keys(params)
            .map((key) => `${key}=${params[key]}`)
            .join('&');
        const dataUrl = `${this.mapLayer.serviceurl}/${this.mapLayer.servicelayer}/query?${paramStr}`;

        HttpInterceptorService.clearInterceptor(this.mapLayer.interceptorKey);
        HttpInterceptorService.pendingRequests[this.mapLayer.interceptorKey] = this.layerService
            .getJsonFromUrl(dataUrl, true)
            .pipe(tap(() => HttpInterceptorService.deleteFromInterceptor(this.mapLayer.interceptorKey)))
            .subscribe((res) => {
                if (!res.features) {
                    this.error.next('Unable to load active fires');
                    this.layerSource.removeFromMap();
                    return;
                }

                this.error.next(undefined);
                this.layerSource.processFeatures(res.features);
                this.layerSource.addToMap();
                this.mapLayer.endLoading();
            });
    }
}
