
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

export class GenLegendType {
    static readonly GEOSERVER: GenLegendType = new GenLegendType('GEOSERVER');
    static readonly ARCGIS: GenLegendType = new GenLegendType('ARCGIS');
    static readonly IMAGE: GenLegendType = new GenLegendType('IMAGE');
    static readonly MANUAL: GenLegendType = new GenLegendType('MANUAL');
    static readonly HARD_CODED: GenLegendType = new GenLegendType('HARD_CODED');

    public readonly name: string;

    private constructor(name: string) {
        this.name = name;
    }

    static forName(name: string) {
        if (name === 'GEOSERVER') return GenLegendType.GEOSERVER;
        if (name === 'ARCGIS') return GenLegendType.ARCGIS;
        if (name === 'IMAGE') return GenLegendType.IMAGE;
        if (name === 'MANUAL') return GenLegendType.MANUAL;
        if (name === 'HARD_CODED') return GenLegendType.HARD_CODED;
        throw new Error('Unexpected value for GenLegendType:' + name);
    }

    static values(): GenLegendType[] {
        return [
            GenLegendType.GEOSERVER,
            GenLegendType.ARCGIS,
            GenLegendType.IMAGE,
            GenLegendType.MANUAL,
            GenLegendType.HARD_CODED,
        ];
    }

    toJSON(): string {
        return this.name;
    }

    toString(): string {
        return this.name;
    }
}

