import * as moment from 'moment';
import {GenEagleiCsvDownloadInfo} from '../../../../../generated/serverModels/GenEagleiCsvDownloadInfo';

export class CsvDownloadInfo extends GenEagleiCsvDownloadInfo {
    constructor(info?: CsvDownloadInfo) {
        super(info);

        if (!info) {
            this.startDate = moment().subtract(1, 'days').startOf('day');
            this.endDate = moment();
            this.fipsCodes = [];
            this.filename = '';
            this.hourInterval = 0;
        }
    }
}
