import {Component, ElementRef, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {CompactType, DisplayGrid, GridsterConfig, GridType} from 'angular-gridster2';
import {GenWidgetName} from '../../../../../generated/serverModels/GenWidgetName';
import {GenOutageAggregationLevel} from '../../../../../generated/serverModels/GenOutageAggregationLevel';
import * as moment from 'moment';
import {WidgetService} from '../../../modules/widget/services/widget.service';
import {Widget} from '../../../modules/widget/classes/widget';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {SaveLayoutModalComponent} from '../../../modules/widget/modals/save-layout-modal/save-layout-modal.component';
import {ModalConfig} from '../../../classes/modal-config';
import {delay, filter} from 'rxjs/operators';
import {Layout} from '../../../modules/widget/classes/layout';
import {MatSnackBar} from '@angular/material/snack-bar';
import {LoadingMaskOptions} from '../../../classes/loading-mask-options';
import {ApplicationConfig} from '../../../classes/application-config';
import {LandingFilters} from '../../classes/landing-filters';
import {BehaviorSubject} from 'rxjs';
import {AddWidgetModalComponent} from '../../modals/add-widget-modal/add-widget-modal.component';
import {ConfigureWidgetStatModalComponent} from 'frontend/src/app/modules/widget/modals/configure-widget-stat-modal/configure-widget-stat-modal.component';
import {ConfigureWidgetSummaryModalComponent} from 'frontend/src/app/modules/widget/modals/configure-widget-summary-modal/configure-widget-summary-modal.component';
import {LayoutManagementModalComponent} from '../../modals/layout-management-modal/layout-management-modal.component';
import {WidgetDetail} from '../../../modules/widget/classes/widget-detail';
import {LandingGridItem} from '../../classes/landing-grid-item';
import {AutoUpdate} from '../../../../shared/classes/auto-update';
import {AuthenticationService} from '../../../services/authentication.service';
import {MatButtonToggleChange} from '@angular/material/button-toggle';
import {ConfigureWidgetNoticesModalComponent} from 'frontend/src/app/modules/widget/modals/configure-widget-notices-modal/configure-widget-notices-modal.component';
import {LandingType} from '../../enums/landing-type.enum';
import {BasePageComponent} from '../../../components/base-page/base-page.component';
import {ConfigureWidgetOngPlannedOutagesModalComponent} from 'frontend/src/app/modules/widget/modals/configure-widget-ong-planned-outages-modal/configure-widget-ong-planned-outages-modal.component';

interface DateFilter {
    display: string;
    value: number;
}

@Component({
    selector: 'eaglei-custom-landing-layout',
    templateUrl: './custom-landing-layout.component.html',
    styleUrls: ['./custom-landing-layout.component.scss'],
})
export class CustomLandingLayoutComponent implements OnInit, OnDestroy {
    get dashboard(): LandingGridItem[] {
        return this._dashboard.filter((item) => {
            return item.detail.widget.types.map((t) => t.id).includes(this.selectedType);
        });
    }

    set dashboard(value: LandingGridItem[]) {
        this._dashboard = value;
    }

    get templateLayouts(): Layout[] {
        return this._templateLayouts.filter((l) => l.typeId === this.selectedType);
    }

    get userLayouts(): Layout[] {
        return this._userLayouts.filter((l) => l.typeId === this.selectedType);
    }

    static filterChange = new BehaviorSubject<LandingFilters>(undefined);

    // Gridstr change methods
    static itemResize(item, itemComponent) {
        WidgetService.resize.next(item);

        if (itemComponent.item.widgetName === GenWidgetName.OUTAGE_CHART) {
            WidgetService.redrawChart.next();
        }

        if (
            itemComponent.item.widgetName === GenWidgetName.OUTAGE_MAP ||
            itemComponent.item.widgetName === GenWidgetName.OIL_BARREL_FLOW ||
            itemComponent.item.widgetName === GenWidgetName.NG_NOMINATION
        ) {
            WidgetService.redrawMap.next();
        }

        if (itemComponent.item.widgetName === GenWidgetName.OUTAGE_SUMMARY) {
            WidgetService.redrawSummary.next(itemComponent.item);
        }
    }

    private isAdmin: boolean;

    // Gridstr Properties
    public options: GridsterConfig;
    private _dashboard: LandingGridItem[] = [];

    // Multiple Dashboard Properties
    public selectedType: LandingType = LandingType.ELECTRIC;

    // Filter bar properties
    private filters = new LandingFilters();
    private dates: DateFilter[] = [
        {value: 1, display: 'TODAY'},
        {value: 2, display: '2D'},
        {value: 3, display: '3D'},
        {value: 4, display: '4D'},
        {value: 5, display: '5D'},
        {value: 6, display: '6D'},
        {value: 7, display: '7D'},
    ];

    private timeoutHandle: any;
    private dateOffset: number = 0;
    public customizingLayout = false;

    // Custom Layout Properties
    public currentLayout: Layout;
    private widgets: Widget[] = [];
    private _userLayouts: Layout[] = [];
    private _templateLayouts: Layout[] = [];
    public loading: boolean;
    public readonly layoutTypes = LandingType;

    public dashboardState: Layout;

    private changeHandle: any;

    // Widget Updater
    private autoUpdate = new AutoUpdate(this.updateWidgets).setAutoUpdate(true);

    private domEvents = {
        mousemove: (event) => WidgetService.domEvent.next(event),
    };

    private itemChange() {
        if (this.changeHandle) {
            clearTimeout(this.changeHandle);
            this.changeHandle = undefined;
        }

        this.changeHandle = setTimeout(() => {
            this.sortDashboard();
        }, 100);
    }

    constructor(
        private ele: ElementRef<HTMLElement>,
        private widgetService: WidgetService,
        private dialog: MatDialog,
        private popup: MatSnackBar,
        private authenticationService: AuthenticationService,
        private renderer: Renderer2
    ) {
        this.getUserPreferences();
        this.emitFilterChange();
        this.showMask();
        this.getWidgets();
        this.getLayouts();

        Object.keys(this.domEvents).forEach((key) => {
            document.body.addEventListener(key, this.domEvents[key]);
        });
    }

    /**
     * Sorts the dashboard widgets by x,y position to ensure mobile widget order matches desktop widget order
     */
    private sortDashboard(): void {
        this.dashboard.sort((a, b) => {
            if (a.y > b.y) {
                return 1;
            } else if (a.y < b.y) {
                return -1;
            } else {
                if (a.x > b.x) {
                    return 1;
                } else if (a.x < b.x) {
                    return -1;
                } else {
                    return 0;
                }
            }
        });
    }

    ngOnInit() {
        this.isAdmin = this.authenticationService.authenticatedUser.getValue().isAdmin();
        this.changeDateOffset(this.dates[0]);
        this.options = {
            itemChangeCallback: this.itemChange.bind(this),
            itemResizeCallback: CustomLandingLayoutComponent.itemResize,
            gridType: GridType.Fixed,
            compactType: CompactType.None,
            // margin: 10,
            // outerMargin: true,
            // outerMarginTop: null,
            // outerMarginRight: null,
            // outerMarginBottom: null,
            // outerMarginLeft: null,
            // useTransformPositioning: true,
            mobileBreakpoint: 600,
            // minCols: 12,
            // maxCols: 5,
            // minRows: 12,
            // maxRows: 5,
            // maxItemCols: 10,
            // minItemCols: 1,
            // maxItemRows: 12,
            // minItemRows: 1,
            // maxItemArea: 2500,
            // minItemArea: 1,
            // defaultItemCols: 1,
            // defaultItemRows: 1,
            fixedColWidth: 160,
            fixedRowHeight: 160,
            // keepFixedHeightInMobile: false,
            // keepFixedWidthInMobile: false,
            // scrollSensitivity: 10,
            // scrollSpeed: 20,
            // enableEmptyCellClick: false,
            // enableEmptyCellContextMenu: false,
            // enableEmptyCellDrop: false,
            // enableEmptyCellDrag: false,
            // enableOccupiedCellDrop: false,
            // emptyCellDragMaxCols: 10,
            // emptyCellDragMaxRows: 10,
            // ignoreMarginInRow: false,
            draggable: {
                enabled: true,
                dragHandleClass: 'draggable',
                ignoreContent: true,
                ignoreContentClass: 'ignore-content',
            },
            resizable: {
                enabled: false,
            },
            swap: true,
            pushItems: true,
            // disablePushOnDrag: false,
            // disablePushOnResize: false,
            // pushDirections: {north: true, east: true, south: true, west: true},
            // pushResizeItems: false,
            displayGrid: DisplayGrid.OnDragAndResize,
            // disableWindowResize: false,
            // disableWarnings: false,
            // scrollToNewItems: false
        };
    }

    ngOnDestroy(): void {
        this.autoUpdate.clear();

        Object.keys(this.domEvents).forEach((key) => {
            document.body.removeEventListener(key, this.domEvents[key]);
        });
    }

    // Filter bar methods
    // noinspection JSUnusedLocalSymbols
    /**
     * Sets the landing page filter for the aggregation level
     * @param level The aggregation level that will be active
     */
    private changeAggregationLevel(level: GenOutageAggregationLevel): void {
        this.filters.aggregationLevel = level;
        this.emitFilterChange();
    }

    /**
     * Changes the date the widgets use for their data.
     * @param date the new date that will be used for data.
     */
    private changeDateOffset(date: DateFilter) {
        this.dateOffset = date.value;
        this.filters.date = moment().subtract(this.dateOffset * 24, 'hours');
        this.emitFilterChange();
    }

    // noinspection JSUnusedLocalSymbols
    /**
     * Tells widgets if they should user the default filter selection or the user preferences
     * @param usePreferences if true the user preferences will be used, else use the full state or fema list
     */
    private changePreferenceUsage(usePreferences: boolean): void {
        this.filters.usePreferences = usePreferences;
        this.emitFilterChange();
    }

    /**
     * Fires the filter behavior subject to tell the widgets to update their filters
     */
    private emitFilterChange() {
        if (this.timeoutHandle) {
            clearTimeout(this.timeoutHandle);
        }
        this.timeoutHandle = setTimeout(() => {
            CustomLandingLayoutComponent.filterChange.next(this.filters);
        }, 100);
    }

    // Custom Layout Methods
    /**
     * Toggles the customize layout for the user to add/remove widgets.
     */
    public toggleCustomize(): void {
        if (this.useMobileLayout()) {
            console.warn('can not customize layout on mobile');
            return;
        }

        this.customizingLayout = !this.customizingLayout;
        if (this.customizingLayout) {
            this.dashboardState = Layout.fromDashboard(this.dashboard);
            this.ele.nativeElement.classList.add('customize-layout');
            this.options.resizable.enabled = true;
        } else {
            this.ele.nativeElement.classList.remove('customize-layout');
            this.options.resizable.enabled = false;
        }
        this.options.api.optionsChanged();
    }

    /**
     * Closes the customization screen and will clear the last changes if clearChanges is true
     * @param clearChanges if true, return to last saved layout else, just close
     */
    public closeCustomization(clearChanges: boolean = false): void {
        this.customizingLayout = false;

        if (clearChanges) {
            this._dashboard = this.dashboardState.details.map((d) => d.toGridItem());
        }

        this.ele.nativeElement.classList.remove('customize-layout');
        this.options.resizable.enabled = false;

        this.options.api.optionsChanged();
    }

    /**
     * removes a widget from the layout
     * @param item The widget to be removed
     */
    public removeItem(item: LandingGridItem): void {
        const preview: HTMLElement = document.getElementsByTagName('gridster-preview')[0] as any;
        this.renderer.setStyle(preview, 'display', 'none');

        this._dashboard.splice(this._dashboard.indexOf(item), 1);
    }

    /**
     * Adds a widget into the layout, it will place the widget as close to 0,0 as possible
     * @param detail The details for the widget to be added
     */
    private addWidgetToLayout(detail: WidgetDetail): void {
        const widget = detail.widget;

        const item: LandingGridItem = {
            cols: widget.minWidth || 2,
            rows: widget.minHeight || 2,
            minItemCols: widget.minWidth,
            maxItemCols: widget.maxWidth,
            minItemRows: widget.minHeight,
            maxItemRows: widget.maxHeight,
            x: 0,
            y: 0,
            widgetName: widget.name,
            detail,
            configurable: widget.configurable,
        };

        this._dashboard.push(item);
    }

    /**
     * Fetches a list of all the available widgets for a user to place in the layout
     */
    private getWidgets(): void {
        this.widgetService.getWidgets().subscribe((widgets) => {
            this.widgets = widgets;
        });
    }

    /**
     * Fetches all the user defined layouts. If a default layout is found it will be loaded, if not, nothing is loaded.
     */
    private getLayouts(): void {
        this.widgetService
            .loadLayouts()
            .pipe(delay(500))
            .subscribe((layouts) => {
                layouts.forEach((layout) =>
                    layout.userId !== undefined ? this._userLayouts.push(layout) : this._templateLayouts.push(layout)
                );

                const defaultLayout = this.getDefaultLayout();

                if (defaultLayout) {
                    this.loadLayout(defaultLayout);
                }
                this.hideMask();
            });
    }

    /**
     * Returns the default Layout for the selected type
     */
    private getDefaultLayout(): Layout {
        return (
            this._userLayouts.find((l) => l.defaultLayout && l.typeId === this.selectedType) ||
            this._templateLayouts.find((l) => l.defaultLayout && l.typeId === this.selectedType)
        );
    }

    /**
     * Loads a layout into the dashboard.
     * @param layout The layout that will be loaded, If no layout is passed in, it will use the default for the selected type.
     */
    public loadLayout(layout?: Layout): void {
        if (layout === undefined) {
            layout = this.getDefaultLayout();
        }

        this.currentLayout = layout;
        this._dashboard = layout?.details?.map((detail) => {
            return detail.toGridItem();
        });

        this.sortDashboard();
        this.closeCustomization();
    }

    /**
     * Opens the save modal to allow the user to name and set the new layout as default:
     */
    public saveLayout(): void {
        const defaultLayout = this._userLayouts.concat(this._templateLayouts).find((l) => l.defaultLayout);
        const isUserLayout = !!this.currentLayout ? this._userLayouts.findIndex((l) => l.id === this.currentLayout.id) !== -1 : false;

        const config: MatDialogConfig = {
            width: ModalConfig.getModalWidth(500),
            disableClose: true,
            data: {
                defaultLayout,
                isUserLayout,
                layout: this.currentLayout,
                userLayouts: this._userLayouts,
                hasDetails: this.dashboard.length !== 0,
            },
        };
        this.dialog
            .open(SaveLayoutModalComponent, config)
            .afterClosed()
            .pipe(filter((layout) => !!layout))
            .subscribe((layout: Layout) => {
                this.options.compactType = CompactType.CompactLeftAndUp;
                this.options.api.optionsChanged();

                this.options.compactType = CompactType.None;
                this.options.api.optionsChanged();

                layout.typeId = this.selectedType;
                layout.details = this.dashboard.map((item) => {
                    const widget = new WidgetDetail(item.detail);
                    widget.fromGridItem(item);
                    return widget;
                });

                const action = layout.id !== undefined ? 'updated' : 'saved';

                const success = (newLayout: Layout) => {
                    const index = this._userLayouts.findIndex((l) => l.id === newLayout.id);
                    if (index !== -1) {
                        this._userLayouts.splice(index, 1, newLayout);
                    } else {
                        this._userLayouts.push(newLayout);
                    }

                    this.currentLayout = newLayout;
                    if (newLayout.defaultLayout) {
                        this._userLayouts.forEach((l) => (l.defaultLayout = l.id === newLayout.id));
                    }
                    this.popup.open(`Your layout has been ${action}`, 'Okay', {duration: 5000, panelClass: 'dialog-success'});
                    this.closeCustomization();
                };

                if (layout.id !== undefined) {
                    this.widgetService.updateLayout(layout).subscribe(success);
                } else {
                    this.widgetService.saveLayout(layout).subscribe(success);
                }
            });
    }

    // Utility Methods
    public updateWidgets(): void {
        WidgetService.renderWidget.next();
    }

    public allowCustomization(): boolean {
        return this.selectedType === LandingType.ELECTRIC && !this.useMobileLayout();
    }

    public useMobileLayout(): boolean {
        return ApplicationConfig.useMobileLayout() && (ApplicationConfig.onPhone() || ApplicationConfig.onIosMobile());
    }

    /**
     * Configures and displays the loading mask
     */
    public showMask(): void {
        const config = new LoadingMaskOptions();
        config.showMask = true;
        config.text = 'Loading Your Layout.';
        ApplicationConfig.pageMask.next(config);
    }

    /**
     * Hides the loading mask.
     */
    public hideMask(): void {
        const config = ApplicationConfig.pageMask.getValue();
        config.showMask = false;
        ApplicationConfig.pageMask.next(config);
    }

    private getUserPreferences(): void {
        const preferences = ApplicationConfig.currentUserPreferences.getValue();

        const validPreferences =
            preferences &&
            preferences.getOutageAggregationLevel() !== undefined &&
            (!!preferences.getStates() || !!preferences.getFemaRegions());

        if (validPreferences) {
            this.filters.usePreferences = true;

            this.filters.aggregationLevel = preferences.getOutageAggregationLevel();

            this.filters.locations = preferences.getStates();
        }
    }

    /**
     * Opens a modal for the a widget to set all the user preferences
     * @param item The widget being configured
     */
    public openWidgetSettingModal(item: LandingGridItem) {
        const config: MatDialogConfig = {
            data: this._dashboard.find((d) => d.detail.id === item.detail.id && d.x === item.x && d.y === item.y),
            width: ModalConfig.getModalWidth(500),
            disableClose: true,
            autoFocus: false,
        };

        let modal;

        switch (item.widgetName) {
            case GenWidgetName.STATS:
                modal = this.dialog.open(ConfigureWidgetStatModalComponent, config);
                break;
            case GenWidgetName.OUTAGE_SUMMARY:
                modal = this.dialog.open(ConfigureWidgetSummaryModalComponent, config);
                break;
            case GenWidgetName.NG_NOTICES:
                modal = this.dialog.open(ConfigureWidgetNoticesModalComponent, config);
                break;
            case GenWidgetName.OIL_PLANNED_OUTAGES:
                modal = this.dialog.open(ConfigureWidgetOngPlannedOutagesModalComponent, config);
                break;
            default:
                alert(`Config modal for ${item.widgetName.toString()} Not Implemented Yet`);
                return;
        }

        modal
            .afterClosed()
            .pipe(filter((val) => !!val))
            .subscribe((widget) => WidgetService.updatedSettings.next(widget));
    }

    public openAddWidgetModal(): void {
        const filteredWidgets = this.widgets.filter((w) => w.types.map((t) => t.id).includes(this.selectedType));
        filteredWidgets.forEach((w) => (w.numberInLayout = 0));
        this._dashboard.forEach((ele) => {
            const found = filteredWidgets.find((w) => w.id === ele.detail.widget.id);
            if (found) {
                found.numberInLayout += 1;
            }
        });

        const config: MatDialogConfig = {
            data: filteredWidgets,
            width: ModalConfig.getModalWidth(),
            disableClose: true,
            autoFocus: false,
        };

        this.dialog
            .open(AddWidgetModalComponent, config)
            .afterClosed()
            .pipe(filter((val) => !!val))
            .subscribe((details: WidgetDetail[]) => {
                details.forEach((detail) => this.addWidgetToLayout(detail));
            });
    }

    public openLayoutMangerModal(event: MouseEvent): void {
        event.stopPropagation();
        const config: MatDialogConfig = {
            autoFocus: false,
            disableClose: true,
            width: ModalConfig.getModalWidth(500),
            data: this.userLayouts,
        };
        const currentDefault = this.getDefaultLayout();

        this.dialog
            .open(LayoutManagementModalComponent, config)
            .afterClosed()
            .subscribe((layouts: Layout[]) => {
                this._userLayouts = layouts.slice();
                const defaultLayout = this.getDefaultLayout();

                if (currentDefault.id !== defaultLayout.id) {
                    this.loadLayout(defaultLayout);
                }
            });
    }

    public showTemplateGroup(group: Layout[]): boolean {
        if (!this.currentLayout) {
            return group.length !== 0;
        }

        return group
            .filter((l) => l.typeId === this.selectedType)
            .some((l) => l.id !== this.currentLayout.id && l.typeId === this.selectedType);
    }

    public matchesCurrentLayout(layout: Layout): boolean {
        if (!this.currentLayout) {
            return false;
        }
        return this.currentLayout.id === layout.id;
    }

    /**
     * Triggered when the user selects a new layout
     * @param event Event From button click
     */
    public updateLayoutType(event: MatButtonToggleChange): void {
        this.selectedType = event.value;

        switch (this.selectedType) {
            case LandingType.ELECTRIC:
                BasePageComponent.pageTile = 'Dashboard';
                break;
            case LandingType.NATURAL_GAS:
                BasePageComponent.pageTile = 'Natural Gas Dashboard';
                break;
            case LandingType.OIL:
                BasePageComponent.pageTile = 'Oil Dashboard';
                break;
        }

        if (this.selectedType) {
            this.loadLayout();
        }
    }
}
