import {PopoverElement} from 'frontend/src/app/classes/popover-element';
import * as L from 'leaflet';
import {ClusterSource} from '../classes/cluster-source';
import {LeafletEagleiSource} from '../classes/leaflet-eaglei-source';
import {LeafletFeature} from '../classes/leaflet-feature';
import {LeafletMapLayer} from '../classes/leaflet-map-layer';
import {LayerStyleService} from '../services/layer-style.service';

export class ActiveFiresSource extends ClusterSource {
    constructor(layerInfo: LeafletMapLayer) {
        super(layerInfo);
    }

    processFeatures(features: any[]): void {
        this.leafletFeatures = features.map((v) => {
            const feature = new LeafletFeature<any>(v);

            const data = [
                new PopoverElement().setTitle().setValue('VIIRS Thermal Hotspot and Fire Activity'),
                new PopoverElement().setLabel('Satellite').setValue(feature.properties.satellite),
                new PopoverElement().setLabel('Confidence').setValue(feature.properties.confidence),
                new PopoverElement().setLabel('Scan').setValue(feature.properties.scan),
                new PopoverElement().setLabel('Track').setValue(feature.properties.track),
                new PopoverElement().setLabel('Fire Radiative Power').setValue(`${feature.properties.frp} MW`),
                new PopoverElement()
                    .setLabel('ACQ Date')
                    .setValue(LeafletEagleiSource.momentPipe.transform(feature.properties.acq_date, 'MM/DD/YYYY')),
                new PopoverElement().setLabel('ACQ Time').setValue(LeafletEagleiSource.momentPipe.transform(feature.properties.acq_time)),
            ];

            feature.popoverData = data;
            feature.subFeatures = [
                {
                    id: feature.properties.OBJECTID,
                    popoverData: data,
                },
            ];

            return feature;
        });

        const featureStyle = () => {
            const styleKey = 'active-fires';
            let styleFeat = LayerStyleService.layerStyles.get(styleKey);

            if (!styleFeat) {
                const opts = {
                    icon: 'circle',
                    backgroundColor: '#00AB78',
                    borderColor: '#00AB78',
                    innerIconStyle: `color:#00AB78; position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); margin-top: 0px;`,
                    iconSize: [10, 10],
                };

                styleFeat = (L as any).BeautifyIcon.icon(opts);

                LayerStyleService.layerStyles.set(styleKey, styleFeat);
            }

            return styleFeat;
        };

        const style = (feature: any, latlng: L.LatLng): L.Layer => {
            return L.marker(latlng, {
                icon: featureStyle(),
            });
        };

        const clusterStyle = (cluster: L.MarkerCluster): any => {
            const opts = {
                icon: 'circle',
                backgroundColor: '#00AB78',
                borderColor: '#00AB78',
                innerIconStyle: `position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); margin-top: 0px; text`,
                iconSize: [30, 30],
                isAlphaNumericIcon: true,
                text: `${cluster.getChildCount()}`,
                textColor: 'white',
            };

            return (L as any).BeautifyIcon.icon(opts);
        };

        const layerPopover = (f: LeafletFeature, l) => {
            this.initializePopoverInteractions(f, l, 'click', false, f.popoverData);
        };

        const config = {
            pointToLayer: style,
            onEachFeature: layerPopover,
            features: this.leafletFeatures,
        };

        const clusterConfig: L.MarkerClusterGroupOptions = {
            iconCreateFunction: clusterStyle,
            chunkedLoading: true,
        };

        const clusterGroup = new L.MarkerClusterGroup(clusterConfig);
        clusterGroup.addLayer(L.geoJSON(this.leafletFeatures as any, config as any));

        this.source = clusterGroup as any;
    }
}
