<div [matTooltipDisabled]="layer.mapLayer.queryable" matTooltipPosition="right" matTooltip="This layer is currently not available">
    <div class="layer-info" [class.disabled]="!layer.mapLayer.queryable">
        <span
            #layerName
            class="layer-name ellipsis"
            [matTooltip]="layer.mapLayer.displayName"
            matTooltipPosition="above"
            matTooltipShowDelay="250"
            [matTooltipDisabled]="!hasEllipsis(layerName)"
            >{{ layer.mapLayer.displayName }}</span
        >

        <div class="layer-actions">
            <mat-icon
                *ngIf="dateRangeUpdate.observers.length !== 0"
                fontSet="fas"
                fontIcon="fa-calendar"
                class="invisible"
                [class.visible]="layer.isActive"
                matTooltip="Data based on date range"
                matTooltipPosition="above"
                matTooltipShowDelay="250">
            </mat-icon>

            <mat-icon
                *ngIf="layer.mapLayer.servicetype === serviceType.WMS && layer.mapLayer.identifiable && !isOnMobile() && !onLiteMap"
                fontSet="fas"
                fontIcon="fa-info-circle"
                class="clickable invisible"
                [class.active-icon]="identifyActive"
                [class.visible]="layer.isActive"
                (click)="toggleIdentify()"></mat-icon>
            <mat-slide-toggle [checked]="layer.isActive" (change)="toggleLayer($event.checked)" *ngIf="!hideToggle"></mat-slide-toggle>
        </div>
    </div>

    <p class="popover-error failure-text hide" [class.show]="showPopoverError()">Popups disabled when identify tool is active</p>

    <ng-container *ngIf="!hideOpacityBar">
        <div class="hide" [class.show]="layer.isActive">
            <div
                class="opacity-slider"
                matTooltip="Unable to change opacity until the layer is fully loaded."
                matTooltipShowDelay="250"
                matTooltipPosition="above"
                [matTooltipDisabled]="layer.mapLayer.opacityEnabled">
                <span class="opacity-text">Opacity</span>

                <mat-slider color="primary" [disabled]="!layer.mapLayer.opacityEnabled">
                    <input matSliderThumb [value]="layer.opacity" (valueChange)="changeOpacity($event)" />
                </mat-slider>
                <span class="opacity-percent">{{ layer.opacity | number : '2.0-0' }}%</span>
            </div>
        </div>
    </ng-container>

    <div *ngIf="layer.isActive && !hideLegend">
        <eaglei-legend [legends]="layer.legend"></eaglei-legend>
    </div>
</div>
