<div class="dialog-header">
    <span>Reset Password</span>
    <button mat-icon-button mat-dialog-close>
        <mat-icon fontSet="fas" fontIcon="fa-times"></mat-icon>
    </button>
</div>
<div class="dialog-content" #content>
    <div class="password-requirements">
        <p>Password requirements</p>
        <ul>
            <li>Eight characters or more</li>
            <li>At least one number</li>
            <li>At least one special character</li>
            <li>At least one upper and lower-case character</li>
        </ul>
    </div>

    <form [formGroup]="passwordGroup">
        <mat-form-field class="highlight-focus raised-label wide" floatLabel="always">
            <mat-label>Password</mat-label>
            <input type="password" matInput formControlName="password" />
            <mat-error>
                <ng-container *ngIf="passwordGroup.controls['password'].errors">
                    <span *ngIf="passwordGroup.controls['password'].errors['required']">Password Required</span>
                    <span *ngIf="passwordGroup.controls['password'].errors['complexity']">
                        {{ passwordGroup.controls['password'].errors['complexity'] }}
                    </span>
                </ng-container>
            </mat-error>
        </mat-form-field>

        <mat-form-field class="highlight-focus raised-label wide" floatLabel="always">
            <mat-label>Confirm Password</mat-label>
            <input type="password" matInput formControlName="confirm" />
            <mat-error>
                <ng-container *ngIf="passwordGroup.controls['confirm'].errors">
                    <span *ngIf="passwordGroup.controls['confirm'].errors['required']">Required</span>
                    <span *ngIf="passwordGroup.controls['confirm'].errors['match']">
                        {{ passwordGroup.controls['confirm'].errors['match'] }}
                    </span>
                </ng-container>
            </mat-error>
        </mat-form-field>
    </form>
</div>

<div class="dialog-actions">
    <button mat-stroked-button mat-dialog-close>Cancel</button>
    <button mat-flat-button color="primary" (click)="resetPassword()" [disabled]="!isFormValid()">Reset Password</button>
</div>
