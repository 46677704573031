import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {AlertRoutingModule} from './alert-routing.module';
import {SharedModule} from '../../../shared/shared.module';
import {AlertService} from './services/alert.service';
import {UserDefinedAlertsComponent} from './components/user-defined-alerts/user-defined-alerts.component';
import {UserDefinedAlertModalComponent} from './modals/user-defined-alert-modal/user-defined-alert-modal.component';
import {SystemAlertModalComponent} from './modals/system-alert-modal/system-alert-modal.component';
import {IsFemaRegionPipe} from './modals/user-defined-alert-modal/is-fema-region.pipe';
import {SuffixCountPipe} from './modals/user-defined-alert-modal/suffix-count.pipe';

@NgModule({
    declarations: [
        IsFemaRegionPipe,
        UserDefinedAlertsComponent,
        UserDefinedAlertModalComponent,
        SuffixCountPipe,
        SystemAlertModalComponent,
    ],
    imports: [CommonModule, AlertRoutingModule, SharedModule],
    providers: [AlertService],
})
export class AlertModule {}
