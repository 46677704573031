import {format} from 'd3-format';
import {scaleQuantize} from 'd3-scale';
import {LayerStyleService} from '../services/layer-style.service';
import {LeafletVectorSource} from '../classes/leaflet-vector-source';
import {LeafletMapLayer} from '../classes/leaflet-map-layer';
import {GenPaddData} from '../../../../../generated/serverModels/GenPaddData';
import * as moment from 'moment';
import {LeafletFeature} from '../classes/leaflet-feature';
import {FeatureStyle} from '../interfaces/style.interface';
import {MapService} from '../../map/services/map.service';
import * as L from 'leaflet';

interface RegionGeometry {
    id: number;
    boundary: string;
}

class FlowData {
    get updateDate(): moment.Moment {
        return this._updateDate;
    }

    get productionData(): number {
        return this._productionData;
    }

    get importData(): number {
        return this._importData;
    }

    get exportData(): number {
        return this._exportData;
    }

    public boundary: string;
    public region: number;
    public resource: string;
    public dates: {[key: string]: moment.Moment};
    public values: {[key: string]: number};

    private _exportData: number;
    private _importData: number;
    private _productionData: number;
    private _updateDate: moment.Moment;

    public updatePopoverValues(type: string): void {
        this._updateDate = this.dates[type];
        this._exportData = this.values.export;
        this._importData = this.values.import;
        this._productionData = this.values.production;
    }
}

export class PetroleumFlowSource extends LeafletVectorSource {
    static regionGeometries: RegionGeometry[] = [];

    // Legend Properties
    private legendFormatter = format(',.1r'); // formatPrefix('.1', 1.3e3);
    public readonly legendRange = ['#d5d6ff', '#a6aaed', '#7d87d8', '#5a6ac9', '#3c55b8', '#2645a6', '#153794'];
    public legendDomain: [string, string];
    public legendScale: any;

    // Filter Properties
    private activeResource: string;
    private activeType: string;

    constructor(layerInfo: LeafletMapLayer) {
        super(layerInfo);
    }

    processFeatures(features: GenPaddData[]): void {
        const formattedFlowData = new Map<string, FlowData>();
        features.forEach((feature) => {
            let val = formattedFlowData.get(getKey(feature));
            if (!val) {
                val = new FlowData();
                val.boundary = PetroleumFlowSource.regionGeometries.find((g) => g.id === feature.regionId).boundary;
                val.region = feature.regionId;
                val.resource = feature.resource;
                val.dates = {};
                val.values = {};
            }
            val.dates[feature.dataType] = feature.dataDate;
            val.values[feature.dataType] = feature.value;
            formattedFlowData.set(getKey(feature), val);
        });

        this.leafletFeatures = Array.from(formattedFlowData.values()).map((f) => new LeafletFeature().convert(f));
        this.createLegendScale(this.leafletFeatures);

        const layerPopover = (f, l) => {
            this.initializePopoverInteractions(f, l, 'click', true);
        };

        const featureStyle = (feature: LeafletFeature<FlowData>) => {
            return this.getFeatureStyle(feature.properties);
        };

        const config = {
            pane: MapService.layerPaneName,
            style: featureStyle,
            onEachFeature: layerPopover,
            features: this.leafletFeatures,
            filter: (feature: any) => {
                const properties: FlowData = feature.properties;
                return this.activeResource ? properties.resource === this.activeResource : true;
            },
        };

        this.source = L.geoJSON(this.leafletFeatures as any, config as any);
    }

    private getFeatureStyle(data: FlowData): FeatureStyle {
        const val = data.values[this.activeType];
        const fillColor = this.legendScale(val);
        const key = `flow-${fillColor}`;
        let style: FeatureStyle = LayerStyleService.layerStyles.get(key);
        if (!style) {
            style = {
                fillColor: fillColor,
                fillOpacity: 1,
                weight: 1,
                color: '#000000',
            };
        }
        return style;
    }

    public createLegendScale(data: LeafletFeature<FlowData>[]): void {
        if (this.legendScale !== undefined) {
            return;
        }

        let min = Number.MAX_SAFE_INTEGER;
        let max = Number.MIN_SAFE_INTEGER;

        data.forEach((fd) => {
            Object.keys(fd.properties.values).forEach((key) => {
                min = Math.min(min, fd.properties.values[key]);
                max = Math.max(max, fd.properties.values[key]);
            });
        });

        this.legendDomain = [this.legendFormatter(min), this.legendFormatter(max)];

        this.legendScale = scaleQuantize()
            .domain([min, max])
            .range(this.legendRange as any);
    }

    public applyFilter(resource: string): void {
        const jsonObj = {
            type: 'FeatureCollection',
            features: this.source.options['features'],
        };

        this.source.clearLayers();
        this.activeResource = resource;
        this.source.addData(jsonObj as any);
        this.changeOpacity(this.layerInfo.opacity);

        this.getFeatures().forEach((feature: FlowData) => {
            feature.updatePopoverValues(this.activeType);
        });
    }

    public updateStyle(type: string): void {
        this.activeType = type;
        const styleFunction = (geoJsonPoint) => {
            const point: FlowData = geoJsonPoint.properties;
            return this.getFeatureStyle(point);
        };

        this.source.setStyle(styleFunction as any);
        this.changeOpacity(this.layerInfo.opacity);
        this.getFeatures().forEach((feature: FlowData) => feature.updatePopoverValues(this.activeType));
    }
}

function getKey(data: GenPaddData): string {
    return `${data.regionId}-${data.resource}`;
}
