import {GenMax24HourZip} from 'frontend/generated/serverModels/GenMax24HourZip';
import {GenOutageDataZip} from '../../../../../generated/serverModels/GenOutageDataZip';
import {HistoricalOutageData} from '../../layer/classes/historical-outage-data';

export class ZipOutage extends GenOutageDataZip {
    constructor(outage?: ZipOutage) {
        super(outage);
    }

    public toHistoricalOutage(): HistoricalOutageData {
        const historical = new HistoricalOutageData();
        historical.currentOutage = this.customersOut;
        historical.currentOutageHasOverrideData = this.hasOverrideData;
        historical.currentOutageRunStartTime = this.timeStamp;
        historical.stateName = this.stateAbbreviation;
        historical.modelCount = this.modelCount;

        historical.zipInfo = new GenMax24HourZip({zipCode: this.zipCode} as any);

        return historical.updateName();
    }
}
