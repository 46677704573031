<div class="dialog-header">
    <span>{{ report.advisory }}</span>
    <button mat-icon-button mat-dialog-close>
        <mat-icon fontSet="fas" fontIcon="fa-times"></mat-icon>
    </button>
</div>

<mat-dialog-content #content>
    <ng-container *ngIf="!parseError; else noData">
        <div class="section">
            <p class="metadata-label">Start Date:</p>
            <p class="metadata-text">{{ metadata.incident_start_date }}</p>
        </div>

        <div class="section">
            <p class="metadata-label">Description:</p>
            <p class="metadata-text">{{ metadata.description }}</p>
        </div>
    </ng-container>
    <ng-template #noData> Unable to get information for current report. </ng-template>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-flat-button mat-dialog-close color="primary">Close</button>
</mat-dialog-actions>
