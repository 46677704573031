
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

export class GenPopoverPipeType {
    static readonly STATE: GenPopoverPipeType = new GenPopoverPipeType('STATE');
    static readonly NUMERAL: GenPopoverPipeType = new GenPopoverPipeType('NUMERAL');
    static readonly SHORT_NUMBER: GenPopoverPipeType = new GenPopoverPipeType('SHORT_NUMBER');

    public readonly name: string;

    private constructor(name: string) {
        this.name = name;
    }

    static forName(name: string) {
        if (name === 'STATE') return GenPopoverPipeType.STATE;
        if (name === 'NUMERAL') return GenPopoverPipeType.NUMERAL;
        if (name === 'SHORT_NUMBER') return GenPopoverPipeType.SHORT_NUMBER;
        throw new Error('Unexpected value for GenPopoverPipeType:' + name);
    }

    static values(): GenPopoverPipeType[] {
        return [
            GenPopoverPipeType.STATE,
            GenPopoverPipeType.NUMERAL,
            GenPopoverPipeType.SHORT_NUMBER,
        ];
    }

    toJSON(): string {
        return this.name;
    }

    toString(): string {
        return this.name;
    }
}

