import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {EventSummaryFilters} from '../../../system-event/classes/event-summary-filters';
import {BaseWidget} from '../../classes/base-widget';
import {ManagementService} from '../../../management/services/management.service';
import {MatTableDataSource} from '@angular/material/table';
import {EilDocument} from '../../../management/classes/eil-document';
import {Subject} from 'rxjs';
import {distinctUntilChanged, switchMap, takeUntil} from 'rxjs/operators';
import {MatSort} from '@angular/material/sort';
import {FormControl, FormGroup} from '@angular/forms';
import {EilData} from '../../../../classes/eil-data';
import {FileDownload} from '../../../../classes/file-download';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'eaglei-document-widget',
    templateUrl: './document-widget.component.html',
    styleUrls: ['./document-widget.component.scss'],
})
export class DocumentWidgetComponent extends BaseWidget implements AfterViewInit {
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    public eventFilters: EventSummaryFilters = new EventSummaryFilters();
    public columns = ['name', 'expand-icon'];

    public dataSource = new MatTableDataSource<EilData>();
    private dataStream = new Subject<number>();
    private dataLoading = false;

    public selectedRow: EilData;

    public filterForm = new FormGroup({
        name: new FormControl<string>(''),
    });

    get noDocumentsText(): string {
        return this.dataLoading ? 'Loading Available Documents...' : 'No Documents Available';
    }

    constructor(private widgetElement: ElementRef, private managementService: ManagementService, private popup: MatSnackBar) {
        super(widgetElement);
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
            return data[sortHeaderId]?.toLocaleLowerCase();
        };

        this.dataSource.filterPredicate = (data, filter) => {
            const {name} = this.filterForm.getRawValue();
            const matchesName = new RegExp(name?.replace(' ', '_').toLowerCase()).test(data.name?.replace(' ', '_').toLowerCase());

            if (name) {
                return matchesName;
            }

            return true;
        };

        this.filterForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(({name}) => {
            this.dataSource.filter = 'manualUpdate';
        });

        this.dataStream
            .pipe(
                distinctUntilChanged(),
                switchMap((eventId) => {
                    this.dataSource.data = [];
                    this.dataLoading = true;

                    return this.managementService.getEilDocumentData(eventId);
                }),
                takeUntil(this.destroy$)
            )
            .subscribe((documents) => {
                this.dataSource.data = documents;
                this.paginator.firstPage();
                this.dataLoading = false;
                this.dataSource.filter = 'manualUpdate';
            });
    }

    protected handleEventFilterChange(eventFilters: EventSummaryFilters): void {
        this.eventFilters = eventFilters;

        if (eventFilters.event) {
            this.dataStream.next(eventFilters.event.id);
        }
    }

    public downloadDocument(doc: EilDocument) {
        const url = `/api/eil/${doc.parentId}/downloaddocument/${doc.id}`;
        const onError = () => {
            this.popup.open('There was and error downloading your file', 'Okay', {panelClass: ['dialog-failure']});
        };
        FileDownload.downloadDocument(url, doc.filename, onError);
    }

    public toggleExpandedRow(row: EilData): void {
        const isRowSelected = this.selectedRow?.id === row.id;
        this.selectedRow = isRowSelected ? undefined : row;
    }

    public destroyWidget(): void {}
}
