import * as moment from 'moment';
import {LeafletEagleiSource} from './leaflet-eaglei-source';
import * as L from 'leaflet';
import {LeafletMapLayer} from './leaflet-map-layer';
import {ApplicationConfig} from '../../../classes/application-config';
import {MapService} from '../../map/services/map.service';

export class LeafletWmsSource extends LeafletEagleiSource<L.TileLayer.WMS> {
    private failedTiles: number = 0;
    private currentFilters: string;
    private readonly MAX_RETRY_ATTEMPTS = 1;

    private retryTileMap: Map<string, number>;

    constructor(layerInfo: LeafletMapLayer) {
        super(layerInfo);
    }

    public fetchImages(style?: string): void {
        this.retryTileMap = new Map<string, number>();

        const url = `${ApplicationConfig.proxyPrefix}${this.layerInfo.serviceurl}`;

        // Using bounds to limit the WMS to one world.
        const leafletOptions: L.WMSOptions = {
            layers: this.layerInfo.servicelayer,
            transparent: true,
            format: 'image/png',
            pane: MapService.layerPaneName,
            attribution: this.layerInfo.getAttributionLink(),
            noWrap: true,
            bounds: [
                [-90, -180],
                [90, 180],
            ],
        };

        if (this.currentFilters) {
            leafletOptions['CQL_FILTER'] = this.currentFilters;
        }

        if (style) {
            leafletOptions['styles'] = style;
        }

        this.failedTiles = 0;

        const config = {
            ...leafletOptions,
            ...this.layerInfo.getParams(),
        };

        this.source = L.tileLayer.wms(url, config);

        this.source.on('loading', () => {
            this.layerInfo.startLoading();
        });

        this.source.on('load', () => {
            const message = this.failedTiles > 0 ? `Failed to load ${this.failedTiles} tiles` : undefined;
            this.layerInfo.endLoading(this.failedTiles !== 0, message);
        });

        this.source.on('tileerror', (event) => {
            this.failedTiles += 1;

            const tileUrl = this.source.getTileUrl(event.coords);
            let retryAttempts = this.retryTileMap.get(tileUrl) || 0;

            if (retryAttempts < this.MAX_RETRY_ATTEMPTS) {
                setTimeout(() => {
                    retryAttempts++;
                    event.tile.src = `${tileUrl}&cache-buster=${moment().valueOf()}`;
                    this.retryTileMap.set(tileUrl, retryAttempts);
                    console.info(`retry attempt #${retryAttempts}`, tileUrl);
                }, 1_000);
            }
        });
    }

    public changeOpacity(opacity: number): void {
        this.source.setOpacity(opacity);
    }

    public applyFilter(filter: string) {
        // TODO find a better way to remove the filter. I used this link as a work around.
        // https://github.com/Leaflet/Leaflet/issues/3441
        this.currentFilters = filter;
        this.source.setParams({
            CQL_FILTER: filter ? `${filter}` : 'INCLUDE', // there is no way to
        } as any);
    }
}
