import {
    AfterContentChecked,
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import {ActivatedRoute, NavigationCancel, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {UserFeedbackComponent} from '../../modules/help/modals/user-feedback/user-feedback.component';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MatMenu} from '@angular/material/menu';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AuthenticationService} from '../../services/authentication.service';
import {AppClassService} from '../../../shared/services/app-class.service';
import {HttpResponse} from '@angular/common/http';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {User} from '../../modules/user/classes/user';
import {GenRoleDefinition} from '../../../../generated/serverModels/GenRoleDefinition';
import {ApplicationConfig} from '../../classes/application-config';
import {HttpInterceptorService} from '../../services/http-interceptor.service';
import {filter} from 'rxjs/operators';
import {DataService} from '../../services/data.service';
import * as moment from 'moment';
import {Title} from '@angular/platform-browser';
import {SummaryUpdateModalComponent} from '../../modules/outage/modals/summary-update-modal/summary-update-modal.component';
import {CardFilters} from '../../../shared/classes/card-filters';
import {OutageService} from '../../modules/outage/services/outage.service';
import {CsvDownloadModalComponent} from '../../modules/outage/modals/csv-download-modal/csv-download-modal.component';
import {CommunityFile} from '../../modules/community/classes/community-file';
import {GenCommunityPermission} from '../../../../generated/serverModels/GenCommunityPermission';
import {OverlayContainer} from '@angular/cdk/overlay';
import {FileDownload} from '../../classes/file-download';
import {LoadingMaskOptions} from '../../classes/loading-mask-options';
import {MediaMatcher} from '@angular/cdk/layout';
import {LayoutType} from '../../enums/layout-type.enum';
import {SocketConnectionService} from '../../../shared/services/socket-connection.service';
import {SystemNotification} from '../../classes/system-notification';
import {KeyValue} from '@angular/common';
import {GenNotificationType} from '../../../../generated/serverModels/GenNotificationType';
import {SystemNewsService} from '../../modules/system-news/services/system-news.service';
import {SystemNewsInfoModalComponent} from '../../modules/system-news/modals/system-news-info-modal/system-news-info-modal.component';
import {EventService} from '../../modules/recent-events/services/event.service';
import {RecentEventModalComponent} from '../../modules/recent-events/modals/recent-event-modal/recent-event-modal.component';
import {EditPreferenceModalComponent} from '../../modules/user/modals/edit-preferences-modal/edit-preference-modal.component';
import {ModalConfig} from '../../classes/modal-config';
import {PasswordResetModalComponent} from '../../modules/user/modals/password-reset-modal/password-reset-modal.component';
import {PopoverConfig} from '../../classes/popover-config';
import {CustomPopover} from '../../modules/layer/enum/custom-popover';
import {PopoverElement} from '../../classes/popover-element';
import {ApiKeyManagementModalComponent} from '../../modules/user/modals/api-key-management-modal/api-key-management-modal.component';
import {ApiKeyService} from '../../modules/api-key/services/api-key.service';
import {LockTimeService} from '../../services/lock-time.service';
import {SystemEventService} from '../../modules/system-event/services/system-event.service';
import {ArchiveRequestModalComponent} from '../../../shared/modals/archive-request-modal/archive-request-modal.component';
import {Angulartics2GoogleAnalytics} from 'angulartics2';
import {TrainingAccessRequestComponent} from '../../../shared/modals/training-access-request/training-access-request.component';
import {AppEnvironment} from '../../enums/environment.enum';

interface AccountDropdown {
    access?: () => boolean;
    action?: () => void;
    url?: string;
    icon: string;
    name: string;
}

interface HeaderLink {
    name: string;
    url: string;
    menuName?: MatMenu;
    roleAccess?: boolean;
    iconName?: string;
}

interface PopoverData {
    state: string;
    counties: string[];
    expanded: boolean;
}

@Component({
    selector: 'eaglei-base-page',
    templateUrl: './base-page.component.html',
    styleUrls: ['./base-page.component.scss'],
})
export class BasePageComponent implements OnInit, AfterViewInit, AfterContentChecked, OnDestroy {
    static pageTile: string;
    static updatePageTitle = new BehaviorSubject<string>(undefined);

    public isTimeLocked$ = LockTimeService.currentApplicationTime;

    get hasEilAccess(): boolean {
        return this._hasEilAccess;
    }

    get isOutageAdmin(): boolean {
        return this._isOutageAdmin;
    }

    get showPageMask(): boolean {
        return this._showPageMask;
    }

    get overrideColor(): string {
        return this._overrideColor;
    }

    get overrideInfo(): PopoverData[] {
        return this._overrideInfo;
    }

    get overridesActive(): boolean {
        return this._overridesActive;
    }

    get generatedOutagesActive(): boolean {
        return this._generatedOutagesActive;
    }

    get headerLinks(): HeaderLink[] {
        return this._headerLinks;
    }

    get hasOverrideAccess(): boolean {
        return this._hasOverrideAccess;
    }

    get isOutageManager(): boolean {
        return this._isOutageManager;
    }

    public readonly environment: string = (window as any)?.eaglei?.env;

    constructor(
        angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics /* this has to be here even though it's not used */,
        public auth: AuthenticationService,
        private appService: AppClassService,
        private dataService: DataService,
        private outageService: OutageService,
        private change: ChangeDetectorRef,
        public router: Router,
        private activatedRoute: ActivatedRoute,
        private titleService: Title,
        private dialog: MatDialog,
        private popup: MatSnackBar,
        private oc: OverlayContainer,
        private media: MediaMatcher,
        public socketConnectionService: SocketConnectionService,
        private apiKeyService: ApiKeyService,
        private systemNewsService: SystemNewsService,
        private eventService: EventService,
        public systemEventService: SystemEventService,
        private viewContainerRef: ViewContainerRef
    ) {
        // Doing this to initialize the colorblind toggle.
        // tslint:disable-next-line:no-unused-expression
        new ApplicationConfig();
        ApplicationConfig.router = router;

        this.initializeLoadingMask();
        this.setUpMobileListener();
        this.getSystemNotifications();
        this.getRecentEventSystemNotifications();

        this.isSessionActive = auth.checkSession();
        this.isSessionActive.subscribe((v) => {
            if (router.url.includes('login')) {
                this.loginNeeded = false;
                return;
            }
            this.showLoginLink = false;
            if (!v.ok) {
                this.loginNeeded = true;
                if (v.status === 403) {
                    this.showLoginLink = true;
                    this.loginBannerText = 'Your session has expired.  You will need to login';
                } else if (v.status === 0) {
                    this.loginBannerText = 'Connection to the server lost. Check network settings.';
                }
            } else {
                this.loginNeeded = false;
            }
        });

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                PopoverConfig.hideNewPopover();
                HttpInterceptorService.unsubscribeToAll.next();

                if (event.url === '/app/events/feed') {
                    this.markNotificationsAsSeen(true);
                    this.recentEventNotifications = [];
                }
            }

            if (event instanceof NavigationEnd) {
                this.showMobileMenu = false;
                oc.getContainerElement().classList.add('new-design');

                // REMOVE after redesign
                // Traverse URL tree
                let routeSegment = this.activatedRoute.firstChild;
                let newDesignCheck = false;
                while (routeSegment) {
                    newDesignCheck = newDesignCheck || routeSegment.snapshot.data['useNewDesign'] !== undefined;
                    routeSegment = routeSegment.firstChild ? routeSegment.firstChild : undefined;
                }

                if (!newDesignCheck) {
                    oc.getContainerElement().classList.remove('new-design');
                }

                this.useNewDesign = newDesignCheck;
                ApplicationConfig.pageMask.next(new LoadingMaskOptions());

                const scrollEle = document.getElementsByClassName(ApplicationConfig.resetScrollClass).item(0);

                if (scrollEle) {
                    scrollEle.scrollIntoView();
                }

                this.currentRoute = event.url;
            }
        });

        ApplicationConfig.currentUserPreferences.subscribe((preferences) => {
            this._overrideColor = preferences.getOverrideColor();
            this.changeBannerColor();
        });

        this.socketConnectionService.newSystemNotification.pipe(filter((val) => !!val)).subscribe((notification) => {
            this.addNotification(notification);
        });

        this.socketConnectionService.recentEventNotifications.pipe(filter((val) => !!val)).subscribe((notification) => {
            this.recentEventNotifications.push(notification);
        });

        BasePageComponent.updatePageTitle.pipe(filter((val) => !!val)).subscribe((val) => this.updateTitle(val));

        this.systemEventService.getGroupedEvents().subscribe((groupedEvents) => {
            this.systemEventService.currentEvents.next(groupedEvents.active);
        });

        this.titleChange();
    }

    public popoverConfig = PopoverConfig;
    public readonly customPopover = CustomPopover;

    @ViewChild('accountBar', {static: true}) accountBar: ElementRef<HTMLElement>;
    @ViewChild('classificationBanner', {static: true}) classificationBanner: ElementRef<HTMLElement>;
    @ViewChild('eagleiPopover', {static: true}) popoverRoot: ElementRef<HTMLElement>;
    @ViewChild('eventPhotoFileUpload', {static: true}) eventPhotoFileUpload: ElementRef<HTMLInputElement>;

    public loadMaskConfig: LoadingMaskOptions = new LoadingMaskOptions();

    public showMobileMenu: boolean;
    public showDataMenu: boolean;
    public showHelpMenu: boolean;
    public showProfileMenu: boolean;

    public inOverridePopover: boolean;

    private readonly lastWeek = moment().subtract(1, 'week').startOf('day');

    public readonly navIcons = [
        {icon: 'fa-home', url: '/app/landing', name: 'Home'},
        {
            icon: 'fa-globe-americas',
            url: ApplicationConfig.onMobile() ? '/app/lite/map' : '/app/map/view',
            name: 'Mapper',
        },
        {icon: 'fa-file-alt', url: '/app/reports', name: 'Reports'},
        {icon: 'fa-users', url: '/app/communities', name: 'Communities'},
        {icon: 'fa-calendar-day', url: '/app/events/feed', name: 'Events'},
        {icon: 'fa-cloud-bolt', url: '/app/system-event/landing', name: 'Event Dashboard'},
    ];

    public readonly dataMenuLinks: AccountDropdown[] = [
        {
            access: () => this.isOutageAdmin,
            action: () => this.openSummaryUpdateModal(),
            icon: 'fa-file-alt',
            name: 'Outage Summary Update',
        },
        {
            access: () => !this.useMobileLayout(),
            action: () => this.openTrainingAccessRequestModal(),
            icon: 'fa-file-download',
            name: 'Training Access Request',
        },
        {
            access: () => !this.useMobileLayout(),
            action: () => this.openArchiveRequestFormModal(),
            icon: 'fa-file-download',
            name: 'Request Archive Data',
        },
        {
            access: () => !this.useMobileLayout(),
            action: () => this.openCsvDownloadModal(),
            icon: 'fa-file-download',
            name: 'CSV Download',
        },
        {
            access: () => this.isOutageAdmin,
            url: '/app/management/outage-summary-error',
            icon: 'fa-exclamation-circle',
            name: 'Outage Summary Error',
        },
        {
            access: () => this.hasOverrideAccess && !ApplicationConfig.useMobileLayout(),
            url: '/app/management/eoc-data-management',
            icon: 'fa-clipboard-list',
            name: 'Outage Override',
        },
        {
            access: () => this.isOutageManager && !ApplicationConfig.useMobileLayout(),
            url: '/app/management/state-outage',
            icon: 'fa-calendar-plus',
            name: 'State Outage Management',
        },
        {
            access: () => this.isOutageManager && !ApplicationConfig.useMobileLayout(),
            url: '/app/management/system-event',
            icon: 'fa-calendar-minus',
            name: 'System Event Management',
        },
        {
            access: () => this.hasEilAccess && !ApplicationConfig.useMobileLayout(),
            url: '/app/management/eil',
            icon: 'fa-industry',
            name: 'EIL Data',
        },
        {
            access: () => !ApplicationConfig.useMobileLayout(),
            url: '/app/management/rrcc-data',
            icon: 'fa-map-marker-alt',
            name: 'RRCC Deployment Locations',
        },
        {
            access: () => this.isOutageAdmin && !ApplicationConfig.useMobileLayout(),
            url: '/app/management/generate-outage',
            icon: 'fa-clipboard',
            name: 'Generate Outage',
        },
    ];

    public readonly helpMenuLinks: AccountDropdown[] = [
        {url: '/dist/images/pdfs/EAGLEI-QuickGuide.pdf', icon: 'fa-file-pdf', name: 'Quick Start Guide'},
        {url: '/dist/images/pdfs/EAGLEI-UserGuide.pdf', icon: 'fa-file-pdf', name: 'User Manual'},
        {url: '/dist/images/pdfs/EAGLEI-APIGuide.pdf', icon: 'fa-file-pdf', name: 'API User Guide'},
        {url: '/dist/images/pdfs/SLTT_Infosheet.pdf', icon: 'fa-file-pdf', name: 'Quick Reference'},
        {url: '/app/systemnews/faq', icon: 'fa-question-circle', name: 'FAQ'},
        {url: '/app/help/releasenotes', icon: 'fa-file-alt', name: 'Release Notes'},
        {url: '/app/help/partners', icon: 'fa-users', name: 'Partners'},
        {url: '/attribution', icon: 'fa-chart-line', name: 'Analytic Attributions'},
        {url: '/app/systemnews', icon: 'fa-file-alt', name: 'System News'},
        {url: '/app/help/about', icon: 'fa-file-alt', name: 'About'},
        {action: () => this.showFeedback(), icon: 'fa-comments', name: 'Submit Feedback'},
        {
            access: () =>
                ![AppEnvironment.PRODUCTION, AppEnvironment.TRAINING].includes((window as any)?.eaglei?.env) &&
                !ApplicationConfig.useMobileLayout(),
            url: '/swagger-ui.html',
            icon: 'fa-code',
            name: 'API Swagger Docs',
        },
    ];
    public currentRoute: string;

    @ViewChild('currentview') currentView: ElementRef;
    @ViewChild('header') header: ElementRef;
    @ViewChild('footer') footer: ElementRef;

    @ViewChild('overrideText') overrideText: ElementRef;
    @ViewChild('overridePopoverElement') overridePopoverElement: ElementRef<HTMLElement>;

    @ViewChild('helpMenu') helpMenu: MatMenu;
    @ViewChild('dataMenu') dataMenu: MatMenu;

    public user: User;

    private isSessionActive: Observable<HttpResponse<any>>;
    private _hasOverrideAccess = false;
    private _isOutageManager = false;
    private _isOutageAdmin = false;
    private _isUserManager: boolean;

    public username = '';
    public isAuthenticated = false;
    public isAdmin = false;
    public isImpersonating = false;
    public appClass = '';
    public loginNeeded = false;
    public loginBannerText = '';
    public showLoginLink = false;

    private _headerLinks: HeaderLink[] = [];
    private _overridesActive: boolean;
    private _generatedOutagesActive: boolean;
    private _overrideCountyCount: number;
    private _overrideInfo: PopoverData[] = [];
    private _overrideColor: string;

    private intervalHandle: any;

    private _subContainer = new Subscription();

    private situationReport: CommunityFile;

    private _showPageMask: boolean;
    private _hasEilAccess: boolean;
    public showSituationBanner: boolean;
    public useNewDesign: boolean;

    // Mobile checking properties
    private mobileQuery: MediaQueryList;
    private phoneQuery: MediaQueryList;
    private _mobileQueryListener: () => void;

    public systemNotifications: Map<number, SystemNotification[]>;
    public recentEventNotifications: SystemNotification[] = [];

    private debounceHandle: any;

    ngOnInit() {
        const self = this;

        this.appService.cssClass.subscribe((str) => (self.appClass = str));

        this.user = this.auth.authenticatedUser.getValue();
        this.auth.authenticatedUser.subscribe((user: User | undefined) => {
            if (user) {
                self.username = user.username;
                self.isAuthenticated = true;
                self.isImpersonating = user.impersonating;
                self.isAdmin = user.hasRole(GenRoleDefinition.ROLE_ADMIN);
                self._isUserManager = user.hasRole(GenRoleDefinition.ROLE_USER_MANAGER);

                if (!this.user.userPreferences) {
                    this.openPreferenceModal();
                }
            } else {
                self.username = '';
                self.isAdmin = false;
                self.isAuthenticated = false;
                self.isImpersonating = false;
            }

            this._hasOverrideAccess = user.hasAnyRole([
                GenRoleDefinition.ROLE_OUTAGE_ADMIN,
                GenRoleDefinition.ROLE_OUTAGE_REPORTER,
                GenRoleDefinition.ROLE_OUTAGE_MANAGER,
            ]);

            this._isOutageManager = user.hasAnyRole([GenRoleDefinition.ROLE_OUTAGE_ADMIN, GenRoleDefinition.ROLE_OUTAGE_MANAGER]);

            this._isOutageAdmin = user.hasRole(GenRoleDefinition.ROLE_OUTAGE_ADMIN);

            this._hasEilAccess = user.hasDataAccess(GenCommunityPermission.EIL);
        });

        this._headerLinks = [
            {url: '/app/landing', name: 'Home'},
            {url: ApplicationConfig.onMobile() ? '/app/lite/map' : '/app/map/view', name: 'Mapper'},
            {url: '/app/reports', name: 'Reports'},
            {url: '/app/communities', name: 'Communities'},
            {url: '/app/events/feed', name: 'Events'},
            {url: 'data', name: 'Data', menuName: null, iconName: 'fa-angle-down'},
            {url: 'help', name: 'Help', menuName: null, iconName: 'fa-angle-down'},
        ];

        window.addEventListener('resize', (event) => {
            ApplicationConfig.resizeEvent.next(event);
        });

        DataService.overwritesActive.subscribe((active) => {
            if (active) {
                this._overrideCountyCount = Array.from(DataService.countyOverwrites).length;

                this._overrideInfo = [];
                Array.from(DataService.countyOverwrites.keys()).forEach((key) => {
                    const words = key.split(':');
                    const state = words[1];
                    const county = words[0];

                    let found = this.overrideInfo.find((i) => i.state === state);

                    if (!found) {
                        found = {
                            state,
                            counties: [],
                            expanded: false,
                        };
                        this.overrideInfo.push(found);
                    }
                    found.counties.push(county);
                });

                this.overrideInfo.forEach((o) => o.counties.sort());
            }

            ApplicationConfig.pageMask.subscribe((maskConfig) => (this.loadMaskConfig = maskConfig));

            this._overridesActive = active;
            // this._generatedOutagesActive = active;
        });

        DataService.generatedOutageData.subscribe((active) => {
            this._generatedOutagesActive = active;
        });

        this.pollSituationReport();

        this.checkUtilityOverride();
    }

    ngAfterContentChecked(): void {
        this.headerLinks.find((l) => l.url === 'help').menuName = this.helpMenu;
        this.headerLinks.find((l) => l.url === 'data').menuName = this.dataMenu;
    }

    ngAfterViewInit(): void {
        PopoverConfig.rootElement = this.popoverRoot;

        this.changeBannerColor();
    }

    ngOnDestroy(): void {
        clearInterval(this.intervalHandle);
        this._subContainer.unsubscribe();
    }

    private setUpMobileListener(): void {
        this.mobileQuery = this.media.matchMedia(ApplicationConfig.mobileBreakpoint);
        this.phoneQuery = this.media.matchMedia(ApplicationConfig.phoneBreakpoint);

        const getLayoutType = () => {
            if (!this.phoneQuery.matches) {
                ApplicationConfig.layoutType.next(LayoutType.PHONE);
            } else if (!this.mobileQuery.matches) {
                ApplicationConfig.layoutType.next(LayoutType.MOBILE);
            } else if (ApplicationConfig.onPhone() || ApplicationConfig.onIosMobile()) {
                ApplicationConfig.layoutType.next(LayoutType.MOBILE);
            } else {
                ApplicationConfig.layoutType.next(LayoutType.DESKTOP);
            }
        };
        this._mobileQueryListener = () => {
            getLayoutType();
            this.change.detectChanges();
        };

        getLayoutType();

        // noinspection JSDeprecatedSymbols
        this.mobileQuery.addListener(this._mobileQueryListener);
        // noinspection JSDeprecatedSymbols
        this.phoneQuery.addListener(this._mobileQueryListener);
    }

    public useMobileLayout(): boolean {
        return ApplicationConfig.onPhone() || ApplicationConfig.onIosMobile();
    }

    private initializeLoadingMask() {
        let handle: any;
        const sub = this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                handle = setTimeout(() => (this._showPageMask = true), 0);
            } else if (event instanceof NavigationCancel || event instanceof NavigationEnd) {
                clearTimeout(handle);
                this._showPageMask = false;
            }
        });
        this._subContainer.add(sub);
    }

    private titleChange() {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                ApplicationConfig.showOverrideColor.next(true);
                const title = getTitle(this.router.routerState, this.router.routerState.root);
                const c = title
                    .filter((w, i) => {
                        return title.findIndex((f) => f === w) === i;
                    })
                    .join(':');

                BasePageComponent.pageTile = title.pop();

                this.titleService.setTitle(c);
            }
        });

        const getTitle = (state, parent) => {
            const data = [];
            if (parent && parent.snapshot.data && parent.snapshot.data.title) {
                data.push(parent.snapshot.data.title);
            }

            if (state && parent) {
                data.push(...getTitle(state, state.firstChild(parent)));
            }
            return data;
        };
    }

    private updateTitle(endText: string): void {
        BasePageComponent.pageTile = endText;
        this.titleService.setTitle(`${this.titleService.getTitle()}:${endText}`);
    }

    // Override Methods
    private checkUtilityOverride(): void {
        // The initial refresh should be a minute before the outage update is triggered to ensure we have the correct counties in the map
        const nextRefresh = 1000 * 60 * (15 - (moment().minute() % 15) - 1);
        const fifteenMinutes = 1000 * 60 * 15;

        setTimeout(() => {
            this.dataService.getCurrentOverwrites().subscribe(() => {});

            this.intervalHandle = setInterval(() => {
                this.dataService.getCurrentOverwrites().subscribe(() => {});
            }, fifteenMinutes);
        }, nextRefresh);
    }

    public openPopover(event: MouseEvent) {
        // this.change.detectChanges()

        const banner: HTMLElement = event.target as any;
        const rect = banner.getBoundingClientRect();

        this.overridePopoverElement.nativeElement.style.pointerEvents = 'all';
        this.overridePopoverElement.nativeElement.style.display = 'block';

        requestAnimationFrame(() => {
            const width = rect.left + rect.width / 2 - this.overridePopoverElement.nativeElement.getBoundingClientRect().width / 2;
            const height = rect.top + 8;

            this.overridePopoverElement.nativeElement.style.top = height + 'px';
            this.overridePopoverElement.nativeElement.style.left = width + 'px';
        });
    }

    public closePopover(setOutPopover: boolean = false) {
        if (setOutPopover) {
            this.inOverridePopover = false;
        }

        requestAnimationFrame(() => {
            if (!this.inOverridePopover) {
                this.overridePopoverElement.nativeElement.style.display = 'none';
                this.overridePopoverElement.nativeElement.style.pointerEvents = 'none';
            }
        });
    }

    private changeBannerColor(): void {
        if (this.overrideText) {
            (this.overrideText.nativeElement as HTMLElement).style.color = this._overrideColor;
        }
    }

    // Routing Methods
    public goToLogin(): void {
        this.loginNeeded = false;
        this.auth.ssoLogin();
    }

    // Header Bar Methods
    public showFeedback(): void {
        this.dialog.open(UserFeedbackComponent, {width: '500px'});
    }

    public openSummaryUpdateModal(): void {
        this.dialog
            .open(SummaryUpdateModalComponent)
            .afterClosed()
            .pipe(filter((dates) => !!dates))
            .subscribe((dates: CardFilters) => {
                this.popup.open('You will receive an email when the summary is complete.', '', {
                    duration: 3000,
                    panelClass: ['success'],
                });
                this.outageService.updateSummaryData(dates.startDate, dates.endDate).subscribe(() => {});
            });
    }

    public openCsvDownloadModal(): void {
        const opts: MatDialogConfig = {
            width: ModalConfig.getModalWidth(),
            disableClose: true,
            viewContainerRef: this.viewContainerRef,
        };
        this.dialog.open(CsvDownloadModalComponent, opts);
    }

    // Helper Methods
    public openArchiveRequestFormModal(): void {
        const opts: MatDialogConfig = {
            ...ModalConfig.defaultConfig,
            width: ModalConfig.getModalWidth(500),
            viewContainerRef: this.viewContainerRef,
        };
        this.dialog.open(ArchiveRequestModalComponent, opts);
    }

    public openTrainingAccessRequestModal(): void {
        const opts: MatDialogConfig = {
            ...ModalConfig.defaultConfig,
            width: ModalConfig.getModalWidth(500),
            viewContainerRef: this.viewContainerRef,
        };
        this.dialog.open(TrainingAccessRequestComponent, opts);
    }

    public showDataList(): boolean {
        return this.dataMenuLinks.some((link) => link.access());
    }

    // Situation Report Methods
    private pollSituationReport(): void {
        const pollTime = 1000 * 60 * 60;
        let intervalHandle: any;
        if (this.user.hasDataAccess(GenCommunityPermission.FEDERAL_SITUATION_REPORT)) {
            const getReport = () => {
                this.dataService
                    .getSituationReport()
                    .pipe(filter((res) => !!res))
                    .subscribe((res) => {
                        this.showSituationBanner = true;
                        this.situationReport = res;
                        clearInterval(intervalHandle);
                    });
            };

            intervalHandle = setInterval(() => {
                getReport();
            }, pollTime);

            getReport();
        }
    }

    public downloadSituationReport() {
        const url = `/api/permission/community/situationreport/download`;
        const onError = () => {
            this.popup.open('There was and error downloading your file', 'Okay', {panelClass: 'dialog-failure'});
        };
        FileDownload.downloadDocument(url, this.situationReport.filename, onError);
    }

    public preformAction(link: AccountDropdown) {
        if (link.action) {
            link.action();
        } else if (link.url.endsWith('.pdf')) {
            window.open(link.url, '_blank');
        } else if (['/swagger-ui.html', '/attribution'].includes(link.url)) {
            window.open(link.url, '_blank');
        } else {
            this.router.navigateByUrl(link.url).then(() => (this.showMobileMenu = false));
        }
    }

    public onPhone(): boolean {
        return ApplicationConfig.onPhone();
    }

    public getPageTitle(): string {
        return BasePageComponent.pageTile;
    }

    // Initial Preference Modal
    private openPreferenceModal(): void {
        const config: MatDialogConfig = {
            disableClose: true,
            width: ModalConfig.getModalWidth(),
            data: {
                user: this.user,
            },
        };
        this.dialog.open(EditPreferenceModalComponent, config);
    }

    /**
     * Fetches a list of the system notifications for the user.
     */
    private getSystemNotifications(): void {
        this.systemNotifications = new Map<number, SystemNotification[]>();
        this.dataService.getSystemNotifications().subscribe((notifications) => {
            notifications.forEach((n) => this.addNotification(n));
        });
    }

    /**
     * Fetches a list of the recent Event system notifications for the user.
     */
    private getRecentEventSystemNotifications(): void {
        this.dataService.getRecentEventSystemNotifications().subscribe((notifications) => {
            this.recentEventNotifications = this.recentEventNotifications.concat(notifications);
        });
    }

    /**
     * Adds a notification to received from web sockets to the notification list
     * @param notification The newly created notification
     */
    private addNotification(notification: SystemNotification) {
        const key = notification.date.valueOf();
        const val = this.systemNotifications.get(key) || [];

        val.push(notification);
        val.sort((a, b) => {
            if (a.date.isBefore(b.date)) {
                return 1;
            } else if (b.date.isBefore(a.date)) {
                return -1;
            }
            return 0;
        });
        this.systemNotifications.set(key, val);
    }

    /**
     * Returns the number of unseen notifications.
     */
    public getNumberOfNotifications(includeAll: boolean = false): number {
        const notifications = Array.from(this.systemNotifications.values()).reduce((prev, cur) => prev.concat(cur), []);
        return notifications.filter((n) => (includeAll ? true : !n.seen)).length;
    }

    /**
     * Returns the number of unseen recent events within the last week. The last week is used because it is the amount of time a user can look
     * back over.
     */
    public getRecentEventCount(): number {
        const notifications = this.recentEventNotifications.filter((n) => {
            return n.type === GenNotificationType.RECENT_EVENT && !n.seen && n.date.isAfter(this.lastWeek);
        });
        return notifications.length;
    }

    /**
     * Used by the model to sort the date of the system news
     * @param a First sort object
     * @param b Second sort object
     */
    public notificationSort = (a: KeyValue<string, SystemNotification>, b: KeyValue<string, SystemNotification>) => {
        if (a.key > b.key) {
            return -1;
        } else if (a.key < b.key) {
            return 1;
        }
        return 0;
        // tslint:disable-next-line:semicolon
    };

    /**
     * Helper method that is fired on menu close, marks all notifications and changes their color.
     */
    public markNotificationsAsSeen(markRecentEvents: boolean = false): void {
        let notifications = Array.from(this.systemNotifications.values()).reduce((prev, cur) => prev.concat(cur), []);

        if (markRecentEvents) {
            notifications = this.recentEventNotifications;
        }

        this.dataService
            .markNotificationsAsSeen(
                this.user.id,
                notifications.map((n) => n.id)
            )
            .subscribe(() => {
                notifications.forEach((n) => (n.seen = true));
            });
    }

    /**
     * Opens up a modal or navigates to the appropriate pages based off the notification type
     * @param notification The notification being clicked on
     */
    public viewNotification(notification: SystemNotification) {
        switch (notification.type) {
            case GenNotificationType.API_KEY:
                this.apiKeyService.getApiKey(this.user).subscribe((keys) => {
                    const key = keys.find((k) => k.id === notification.triggerId);
                    if (key) {
                        const dialogConfig: MatDialogConfig = {
                            disableClose: true,
                            width: ModalConfig.getModalWidth(),
                            data: key,
                        };
                        this.dialog.open(ApiKeyManagementModalComponent, dialogConfig);
                    }
                });
                break;
            case GenNotificationType.SYSTEM_NEWS:
                this.systemNewsService.getSystemNewsById(notification.triggerId).subscribe((news) => {
                    this.dialog.open(SystemNewsInfoModalComponent, {data: news});
                });
                break;
            case GenNotificationType.RECENT_EVENT:
                this.eventService.getEvent(notification.triggerId).subscribe((event) => {
                    this.dialog.open(RecentEventModalComponent, {data: event});
                });
                break;
            case GenNotificationType.COMMUNITY:
                // noinspection JSIgnoredPromiseFromCall
                this.router.navigate(['/app/communities/', notification.triggerId]);
                break;
            case GenNotificationType.PASSWORD:
                const config: MatDialogConfig = {
                    disableClose: true,
                    data: this.user,
                    width: ModalConfig.getModalWidth(500),
                };
                this.dialog
                    .open(PasswordResetModalComponent, config)
                    .afterClosed()
                    .pipe(filter((ret) => !!ret))
                    .subscribe(() => {
                        this.user.lastPasswordReset = moment();
                        this.user.updateDaysSincePasswordReset();
                    });
                break;
        }
    }

    expandOverrideState(info: PopoverData) {
        if (info.expanded) {
            info.expanded = false;
            return;
        }
        this.overrideInfo.forEach((oi) => (oi.expanded = oi.state === info.state));
    }

    public navigateTo(url: string) {
        if (this.debounceHandle) {
            clearTimeout(this.debounceHandle);
        }

        const timeout = 250;
        this.debounceHandle = setTimeout(() => {
            HttpInterceptorService.unsubscribeToAll.next();
            this.router.navigateByUrl(url).then(() => {
                this.currentRoute = url;
            });
        }, timeout);
    }

    public openPopoverModal(info: PopoverElement) {
        if (info.onClickListener) {
            info.onClickListener();
        } else {
            this.dialog.open(info.dialogRef, info.dialogConfig);
            PopoverConfig.hideNewPopover();
        }
    }

    setInPopover(b: boolean, event: MouseEvent) {
        PopoverConfig.inPopover = b;

        if (!b) {
            const ele: HTMLElement = event.relatedTarget as any;
            const parents = [];

            let p = ele;
            while (p) {
                parents.push(p.tagName);
                p = p.parentElement;
            }

            if (parents.includes('EAGLEI-LEAFLET-MAP')) {
                PopoverConfig.hideNewPopover();
            }

            PopoverConfig.hideNewPopover();
        }
    }
}
