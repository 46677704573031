import {GenRRCCDeployment} from 'frontend/generated/serverModels/GenRRCCDeployment';
import {RRCCPersonnel} from 'frontend/src/app/classes/rrcc-personnel';
import * as moment from 'moment';

export class RRCCDeployment extends GenRRCCDeployment {
    public personnel: RRCCPersonnel;

    public toBeEnded: boolean = false;

    constructor(deployment?: RRCCDeployment) {
        super(deployment);

        if (deployment) {
            this.personnel = new RRCCPersonnel(deployment.personnel);

            this.startTime = moment(this.startTime);
            this.endTime = moment(this.endTime);
        }
    }
}
