import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {SystemNews} from '../../classes/system-news';
import {SystemNewsType} from '../../classes/system-news-type';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SystemNewsService} from '../../services/system-news.service';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {BaseModal} from '../../../../classes/base-modal';

@Component({
    selector: 'eaglei-create-system-news',
    templateUrl: './create-system-news.component.html',
    styleUrls: ['./create-system-news.component.scss'],
})
export class CreateSystemNewsComponent extends BaseModal implements OnInit {
    // HTML Properties
    @ViewChild('content', {static: true}) content: ElementRef;

    // News Properties
    public news = new SystemNews();

    // Form Properties
    public newsGroup: UntypedFormGroup;

    constructor(
        public dialogRef: MatDialogRef<CreateSystemNewsComponent>,
        private popup: MatSnackBar,
        private newsService: SystemNewsService,
        @Inject(MAT_DIALOG_DATA) private type: SystemNewsType
    ) {
        super();
        this.news.title = '';
    }

    ngOnInit() {
        const controls = {
            title: new UntypedFormControl('', {
                validators: [Validators.required, Validators.maxLength(100), Validators.max(100)],
            }),
            message: new UntypedFormControl('', {
                validators: [Validators.required],
            }),
        };

        this.newsGroup = new UntypedFormGroup(controls);
    }

    /**
     * Posts the new system news and closes the modal so it is added to the System news UI
     */
    public createSystemNews(): void {
        this.news.source = 'EAGLE-I';
        this.news.canDelete = true;
        this.news.typeId = this.type.id;

        this.newsService.createSystemNews(this.news).subscribe(
            (res) => {
                this.popup.open(`Created ${this.type.name}`, 'Okay', {duration: 5000, panelClass: 'dialog-success'});
                this.dialogRef.close(res);
            },
            (error: any) => {
                const text = error.error.userMessage || `Error Creating ${this.type.name}`;
                this.popup.open(text, 'Okay', {panelClass: 'dialog-failure'});
                console.error(text, error);
            }
        );
    }

    afterInit() {}
}
