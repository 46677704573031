<div #header class="widget-header">
    <p class="title">RADR Flood Detection</p>
    <form>
        <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Image Layer</mat-label>

            <mat-select [formControl]="imageControl" [compareWith]="tileCompare" multiple placeholder="Image Layer">
                <mat-option [value]="image" *ngFor="let image of possibleImages">
                    {{ image.tileId }} - {{ image.collectedAt | momentDate }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </form>
</div>

<div #content class="widget-content table-container">
    <eaglei-leaflet-map
        class="tall wide"
        [class.invisible]="possibleImages.length === 0"
        (mapRef)="mapReady.next($event)"
        [mapOptions]="mapOptions">
    </eaglei-leaflet-map>

    <mat-card [class.invisible]="possibleImages.length === 0">
        <div class="radr-legend">
            <div class="color-box"></div>
            <div class="radr-legend-text">
                <span>No Flood</span>
                <span>Flood</span>
            </div>
        </div>
    </mat-card>

    <div class="auto-mask hide-mask" *ngIf="possibleImages.length === 0">
        <p class="mask-message">No Radr Imagery for the selected event</p>
    </div>
</div>
