import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSelectChange} from '@angular/material/select';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {ChartBounds} from 'frontend/src/app/classes/chart-bounds';
import {ModalConfig} from 'frontend/src/app/classes/modal-config';
import * as moment from 'moment';
import {CardFilters} from '../../../../../shared/classes/card-filters';
import {FileDownload} from '../../../../classes/file-download';
import {PopoverElement} from '../../../../classes/popover-element';
import {Report} from '../../classes/report';
import {UtilityErrorModalComponent} from '../../modals/utility-error-modal/utility-error-modal.component';
import {ReportService} from '../../services/report.service';
import {ApplicationConfig} from 'frontend/src/app/classes/application-config';
import {EagleiBaseChart} from '../../../../classes/charts/base-chart';
import {DataPoint} from '../../../../classes/data-point';
import {UtilityErrorParent} from '../../classes/utility-error-parent';

@Component({
    selector: 'eaglei-utility-error-count-report',
    templateUrl: './utility-error-count-report.component.html',
    styleUrls: ['../reports.scss', './utility-error-count-report.component.scss'],
})
export class UtilityErrorCountReportComponent extends Report<UtilityErrorParent> implements OnInit, AfterViewInit {
    // HTML Elements
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild('chartTarget') chartTarget: ElementRef;

    // Table Properties
    public readonly columnNames: string[] = [
        'eagle_id',
        'utility_name',
        'status',
        'customers',
        'connection',
        'database',
        'developer',
        'location',
        'scraper',
        'sub_utility',
        'system_failure',
        'informational',
        'unknown',
        'total',
    ];
    private readonly possibleErrorsOneDay = 96;
    public possibleErrorsInRange: number;
    private maxErrors = 0;

    // Chart Properties
    public baseChart = new EagleiBaseChart();
    private chartData: UtilityErrorParent[] = [];
    private chartBounds: ChartBounds<DataPoint, UtilityErrorParent>;

    // Filter Properties
    private startDate: moment.Moment = moment().subtract(1, 'days').startOf('day');
    private endDate: moment.Moment = moment();
    public selectedStatus = 'all';

    public showMask: boolean;
    public loading = true;

    constructor(public reportService: ReportService, private dialog: MatDialog) {
        super();
    }

    public ngOnInit(): void {
        this.reportService.getReportData().subscribe((r) => this.initializeReportInfo(r));
    }

    public ngAfterViewInit(): void {
        this.initializeChart();
        this.getUtilityErrors();
    }

    /**
     * API call to get Utility Errors
     */
    private getUtilityErrors(): void {
        this.loading = true;
        this.reportService.getUtilityStatusErrors(this.startDate, this.endDate).subscribe((status) => {
            this.initializeData(status);
        });
    }

    /**
     * Creates the datasource for the table
     * @param data Utility Error numbers
     */
    private initializeData(data: UtilityErrorParent[]): void {
        const trueEnd = !this.endDate.isBefore() ? this.endDate.add(15, 'minutes') : this.endDate;
        const secondsInDay = 60 * 60 * 24;
        this.possibleErrorsInRange = this.possibleErrorsOneDay * (trueEnd.diff(this.startDate, 'seconds') / secondsInDay);

        if (this.dataSource) {
            this.dataSource.data = data;
        } else {
            this.dataSource = new MatTableDataSource<UtilityErrorParent>(data);
            this.dataSource.sortingDataAccessor = this.dataAccessor.bind(this);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.dataSource.filterPredicate = this.filterPredicate.bind(this);
        }

        this.chartData = data;

        this.filterData(this.dataSource.filter);
        this.loading = false;
    }

    /**
     * Checks data to filter
     * @param data Utility to check
     * @param search Search string
     */
    private filterPredicate(data: UtilityErrorParent, search: string): boolean {
        const typeCheck: boolean = this.selectedStatus === 'all' ? true : data.utilityStatus === this.selectedStatus;
        const nameCheck: boolean =
            data.utilityName.trim().toLowerCase().includes(search.trim().toLowerCase()) && data.utilityName.trim().length > 0;
        return nameCheck && typeCheck;
    }

    /**
     * Sorts data based on column
     * @param data Utility to check
     * @param header Column to be sorted
     */
    public dataAccessor(data: UtilityErrorParent, header: string): string | number {
        switch (header) {
            case 'utility_name':
                return data.utilityName.toLowerCase();
            case 'utility_id':
                return data.utilityId;
            case 'customers':
                return data.totalCustomers;
            case 'connection':
                return data.connectionErrors;
            case 'database':
                return data.databaseErrors;
            case 'developer':
                return data.developerErrors;
            case 'location':
                return data.locationErrors;
            case 'scraper':
                return data.scraperErrors;
            case 'sub_utility':
                return data.subUtilityErrors;
            case 'system_failure':
                return data.systemFailureErrors;
            case 'unknown':
                return data.unknownErrors;
            case 'informational':
                return data.informationalErrors;
            case 'total':
                return (data.totalErrors / this.possibleErrorsInRange) * 100;
            default:
                return '';
        }
    }

    /**
     * Filters the data and renders the chart
     * @param value Search String
     */
    public filterData(value: string): void {
        this.dataSource.filter = value === '' ? ' ' : value;
        this.chartData = this.dataSource.filteredData;
        this.renderChart();
    }

    /**
     * Updates the selected status
     * @param event Newly selected status
     */
    public statusChange(event: MatSelectChange): void {
        this.selectedStatus = event.value;
        this.filterData(this.dataSource.filter);
    }

    /**
     * Updates dates and calls API
     * @param dates Dates to update to
     */
    public updateDates(dates: CardFilters): void {
        this.startDate = dates.startDate;
        this.endDate = dates.endDate;
        this.getUtilityErrors();
    }

    /**
     * Opens modal to show Utility Errors
     * @param util Utility selected
     */
    public viewUtilityErrors(util: UtilityErrorParent): void {
        this.reportService.getUtilityErrors(util.utilityId, this.startDate, this.endDate).subscribe((res) => {
            const config: MatDialogConfig = {
                width: ModalConfig.getModalWidth(),
                disableClose: true,
                data: {
                    name: util.utilityName,
                    errors: res.data,
                },
            };
            this.dialog.open(UtilityErrorModalComponent, config);
        });
    }

    public getCountyCustomerText(info: any): string {
        return !!info.totalCustomers ? `(${info.modelCount > 0 ? 'Modeled' : 'Collected'})` : '';
    }

    /**
     * Exports table as CSV
     */
    public exportTable(): void {
        const columns = [
            'Eagle-I ID',
            'Utility Name',
            'Status',
            'Total Customers',
            'Connection Errors',
            'Database Errors',
            'Developer Errors',
            'Location Errors',
            'Scraper Errors',
            'SubUtility Errors',
            'System Failure Errors',
            'Informational Errors',
            'Unknown Errors',
            'Total Errors',
        ];
        let data = `${this.startDate.format()} - ${this.endDate.format()}\n\n`;
        data += columns.join() + '\n';
        this.dataSource._orderData(this.dataSource.filteredData).forEach((utility) => {
            const totalPercent = utility.totalErrors / this.possibleErrorsInRange;
            data += [
                FileDownload.formatCsvCell(utility.utilityId),
                FileDownload.formatCsvCell(utility.utilityName),
                FileDownload.formatCsvCell(utility.utilityStatus),
                FileDownload.formatCsvCell(utility.totalCustomers),
                FileDownload.formatCsvCell(utility.connectionErrors),
                FileDownload.formatCsvCell(utility.databaseErrors),
                FileDownload.formatCsvCell(utility.developerErrors),
                FileDownload.formatCsvCell(utility.locationErrors),
                FileDownload.formatCsvCell(utility.scraperErrors),
                FileDownload.formatCsvCell(utility.subUtilityErrors),
                FileDownload.formatCsvCell(utility.systemFailureErrors),
                FileDownload.formatCsvCell(utility.informationalErrors),
                FileDownload.formatCsvCell(utility.unknownErrors),
                FileDownload.formatCsvCell(+totalPercent.toFixed(2) * 100) + '%',
            ].join();
            data += '\n';
        });
        FileDownload.downloadCSV('utilityErrorCount', data, this.attributionUrl);
    }

    /**
     * Exports Chart as PNG
     */
    public exportChart(): void {
        const title = `Utility Error Count for ${Report.momentPipe.transform(
            moment(this.startDate),
            'M/D/YYYY'
        )} to ${Report.momentPipe.transform(moment(this.endDate), 'M/D/YYYY')}`;

        FileDownload.exportChartAsPNG(this.baseChart, 'errorChart', title, this.attributionUrl);
    }

    // Chart Functions
    /**
     * Creates bar chart
     */
    private initializeChart(): void {
        this.baseChart.interactivePopover = true;
        this.baseChart.initializeEChart(this.chartTarget.nativeElement, true, 'Error', 'Error Count');

        this.baseChart.getYAxis().nameTextStyle = {
            padding: [0, 0, 0, 0],
        };

        this.baseChart.getXAxis().axisLabel.formatter = (label) => label;

        this.baseChart.setOptions();
    }

    /**
     * Summarizes data into given bars
     * @param bars Bars to be summarized
     */
    private summarizeChartData(bars: any): ChartBounds<DataPoint> {
        this.chartData.forEach((d) => {
            bars.Connection += d.connectionErrors;
            bars.Database += d.databaseErrors;
            bars.Developer += d.developerErrors;
            bars.Location += d.locationErrors;
            bars.Scraper += d.scraperErrors;
            bars.SubUtility += d.subUtilityErrors;
            bars.SystemFailure += d.systemFailureErrors;
            bars.Informational += d.informationalErrors;
            bars.Unknown += d.unknownErrors;
        });

        const data: DataPoint[] = [];

        Object.keys(bars).forEach((key) => {
            const d = {
                x: key.replace('_', ' '),
                y: bars[key],
            };
            const dp = new DataPoint(d);

            this.maxErrors = Math.max(this.maxErrors, bars[key]);
            data.push(dp);
        });

        this.chartBounds = new ChartBounds<DataPoint, UtilityErrorParent>();

        this.chartBounds.rawData = this.chartData;
        this.chartBounds.data = data;

        return this.chartBounds;
    }

    /**
     * Renders the Chart
     */
    private renderChart(): void {
        this.showMask = true;
        const bars = {
            Connection: 0,
            Database: 0,
            Developer: 0,
            Location: 0,
            Scraper: 0,
            SubUtility: 0,
            SystemFailure: 0,
            Informational: 0,
            Unknown: 0,
        };

        this.chartBounds = this.summarizeChartData(bars);

        const xValues = Object.keys(bars);
        const yValues = this.chartBounds.data.map((dp) => {
            return {
                value: dp.data.y,
                itemStyle: {color: ApplicationConfig.chartLineColor},
                popoverData: [
                    new PopoverElement('Title', dp.data.x),
                    new PopoverElement('Number of Errors', `${Report.numberPipe.transform(dp.data.y)}`),
                ],
                exportData: {
                    xValue: dp.data.x,
                    yValue: dp.data.y,
                },
            };
        });

        this.baseChart.eChartOptions.xAxis['data'] = xValues;
        this.baseChart.eChartOptions.xAxis['name'] = 'Error Type';

        this.baseChart.eChartOptions.series = [
            {
                type: 'bar',
                data: yValues,
            },
        ];

        this.baseChart.eChart.setOption(this.baseChart.eChartOptions);
        this.loading = false;
        this.showMask = false;
    }

    /**
     * Gets text for mask
     */
    public getMaskText(): string {
        if (this.loading) {
            return 'Loading...';
        } else if (this.maxErrors === 0) {
            return 'No Errors For Selected Utilities';
        }
        return 'No Utilities Selected';
    }

    public hasEllipsis(element: HTMLElement): boolean {
        return ApplicationConfig.hasEllipsis(element);
    }
}
