
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import * as moment from 'moment';

export class GenPaddData {

    dataDate: moment.Moment;
    dataType: string;
    region: string;
    regionId: number;
    resource: string;
    sourceId: number;
    value: number;

    constructor();
    constructor( jsonObj: any );
    constructor( genPaddData: GenPaddData);

    constructor( input?: GenPaddData ) {
        if ( input && typeof input !== 'number' ) {
            if ( input.dataDate && typeof input.dataDate === 'string' ) {
                this.dataDate = moment(input.dataDate).utc();
            } else {
                this.dataDate = input.dataDate;
            }
            this.dataType = input.dataType; // string [string]
            this.region = input.region; // string [string]
            this.regionId = input.regionId; // int [number]
            this.resource = input.resource; // string [string]
            this.sourceId = input.sourceId; // int [number]
            this.value = input.value; // int [number]
       }
    }

    static jsonResponseToList(arr: any[]): GenPaddData[] {
        if (arr && arr.map) {
          return arr.map(a => new GenPaddData(a));
        } else {
          return [];
        }
    }
}

