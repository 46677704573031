<div fxLayout="row" fxLayoutAlign="space-between center" class="dialog-header">
    <span class="filter-header">Add Widget</span>
    <mat-icon fontSet="fas" fontIcon="fa-times" inline class="clickable" (click)="ref.close()"> </mat-icon>
</div>

<div class="dialog-content" #content>
    <ng-container *ngIf="widgetList.length === 0; else addWidgets">
        <div class="auto-mask">
            <p class="mask-message">all the available widgets have been added to your layout.</p>
        </div>
    </ng-container>
    <ng-template #addWidgets>
        <div fxLayout="row wrap" fxLayoutAlign="center center">
            <div fxFlex="33%" *ngFor="let widget of widgetList">
                <mat-card (click)="toggleSelected(widget)" [class.active]="widget.selected">
                    <mat-icon class="widget-icon" fontSet="fas" [fontIcon]="widget.iconName"></mat-icon>
                    <p class="title">{{ widget.name | widgetName }}</p>
                    <p class="description">
                        {{ widget.description }}
                    </p>
                </mat-card>
                <div
                    fxLayout="row"
                    fxLayoutAlign="center center"
                    fxLayoutGap="15px"
                    [class.invisible]="!widget.selected"
                    class="widget-count">
                    <div [matTooltip]="getTooltipText(widget, 'remove')" [matTooltipDisabled]="widget.numberToAdd !== 1">
                        <mat-icon
                            class="clickable"
                            [class.disabled-icon]="widget.numberToAdd === 1"
                            fontSet="fas"
                            fontIcon="fa-minus-square"
                            inline
                            (click)="removeWidget(widget)"></mat-icon>
                    </div>
                    <div>{{ widget.numberToAdd }}</div>
                    <div [matTooltip]="getTooltipText(widget, 'add')" [matTooltipDisabled]="!limitReached(widget)">
                        <mat-icon
                            class="clickable"
                            [class.disabled-icon]="limitReached(widget)"
                            fontSet="fas"
                            fontIcon="fa-plus-square"
                            inline
                            (click)="addWidget(widget)"></mat-icon>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</div>

<div class="dialog-actions" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end">
    <button mat-flat-button color="primary" (click)="finalizeWidgets()" [disabled]="!isFormValid()">{{ getButtonText() }}</button>
</div>
