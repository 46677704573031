<div class="underground-storage">
    <div id="gdAreaTable" gdArea="table">
        <ng-container [ngTemplateOutlet]="table"></ng-container>
    </div>

    <div class="bar-chart" id="gdAreaBarChart" gdArea="barChart">
        <ng-container [ngTemplateOutlet]="barChart"></ng-container>
    </div>
</div>

<!--Table-->
<ng-template #table>
    <div class="underground-widget">
        <div #header class="widget-header large">
            <p class="title">
                <span>Natural Gas Daily Macro Supply & Demand</span>
                <eaglei-info-icon [attributionModalText]="attributionModalText" [widgetName]="widgetName"></eaglei-info-icon>
            </p>

            <div class="widget-filters">
                <mat-form-field class="filter-select type-filter" appearance="outline" [class.active]="!!selectedType">
                    <mat-label>Data Type</mat-label>
                    <mat-select placeholder="Data Type" [value]="selectedType" (selectionChange)="updateStorage($event.value)">
                        <mat-option *ngFor="let type of types" [value]="type">{{ type }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <eaglei-date-range
                    [start]="selectedDate"
                    [min]="ongService.minDate"
                    [startPlaceholder]="'Date'"
                    [singlePicker]="true"
                    (change)="updateDateRange($event)">
                </eaglei-date-range>

                <button
                    mat-icon-button
                    class="ei-export"
                    matTooltip="Export"
                    matTooltipPosition="above"
                    matTooltipShowDelay="250"
                    [matMenuTriggerFor]="menu">
                    <mat-icon fontSet="fas" fontIcon="fa-file-export"></mat-icon>
                </button>
            </div>
        </div>
        <div #content class="widget-content wide">
            <!--Table-->
            <div class="table-container">
                <table
                    mat-table
                    [dataSource]="storage"
                    multiTemplateDataRows
                    matSort
                    [matSortDisableClear]="true"
                    matSortActive="region"
                    matSortDirection="desc">
                    <ng-container matColumnDef="region">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Region
                            <eaglei-table-sort-header [columnName]="'region'" [sort]="sort"></eaglei-table-sort-header>
                        </th>

                        <td mat-cell *matCellDef="let info" class="region">
                            <p>{{ info.region }}</p>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="value">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Storage Capacity Change (Bcf)
                            <eaglei-table-sort-header [columnName]="'value'" [sort]="sort"></eaglei-table-sort-header>
                        </th>

                        <td mat-cell *matCellDef="let info" class="value">
                            <p>{{ info.value | number : '1.0-2' }}</p>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="columns"></tr>
                    <tr mat-row *matRowDef="let row; columns: columns; let odd = odd" class="hoverable"></tr>
                </table>
            </div>

            <div *ngIf="storage && storage.filteredData.length === 0" class="auto-mask hide-mask m-mask">
                <span class="mask-message">No storage matching your filters.</span>
            </div>
        </div>
        <div #footer class="widget-footer">
            <eaglei-honor-preference-indicator></eaglei-honor-preference-indicator>
        </div>
    </div>
</ng-template>

<!--Chart-->
<ng-template #barChart>
    <div class="chart-container">
        <p class="chart-title">Natural Gas Daily Macro Supply & Demand</p>
        <div class="wide">
            <div #chartTarget class="tall wide"></div>
        </div>
    </div>

    <div class="auto-mask hide-mask" *ngIf="showMask">
        <span class="mask-message">No Data Available</span>
    </div>
</ng-template>

<!--Export-->
<mat-menu #menu="matMenu" overlapTrigger>
    <button (click)="exportTable()" mat-menu-item>Export Table As CSV</button>
    <button (click)="exportChart()" mat-menu-item>Export Chart As PNG</button>
</mat-menu>
