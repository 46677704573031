
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import { GenReportRequestParameter } from './GenReportRequestParameter';
import * as moment from 'moment';

export class GenReportRequest {

    id: number;
    notificationSent: boolean;
    ownerId: number;
    parameters: GenReportRequestParameter[];
    reportDescription: string;
    reportName: string;
    reportRequestGroupId: number;
    runTime: moment.Moment;
    subscribers: string[];
    userDefinedReportId: number;
    userReportName: string;

    constructor();
    constructor( jsonObj: any );
    constructor( genReportRequest: GenReportRequest);
    constructor( id: number );

    constructor( input?: GenReportRequest|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            this.notificationSent = input.notificationSent; // boolean [boolean]
            this.ownerId = input.ownerId; // int [number]
            if ( input.parameters && input.parameters.map ) { // list [GenReportRequestParameter]
                this.parameters = input.parameters.map( ( x: GenReportRequestParameter ) => {
                    return new GenReportRequestParameter( x );
                } );
            } else {
                this.parameters = input.parameters;
            }
            this.reportDescription = input.reportDescription; // string [string]
            this.reportName = input.reportName; // string [string]
            this.reportRequestGroupId = input.reportRequestGroupId; // int [number]
            if ( input.runTime && typeof input.runTime === 'string' ) {
                this.runTime = moment(input.runTime).utc();
            } else {
                this.runTime = input.runTime;
            }
            this.subscribers = input.subscribers; // list [string]
            this.userDefinedReportId = input.userDefinedReportId; // int [number]
            this.userReportName = input.userReportName; // string [string]
       }
    }

    static jsonResponseToList(arr: any[]): GenReportRequest[] {
        if (arr && arr.map) {
          return arr.map(a => new GenReportRequest(a));
        } else {
          return [];
        }
    }
}

