import {Pipe, PipeTransform} from '@angular/core';
import {GenRoleDefinition} from '../../../generated/serverModels/GenRoleDefinition';

@Pipe({
    name: 'roleDefinition',
})
export class RoleDefinitionPipe implements PipeTransform {
    transform(role: GenRoleDefinition, returnOriginal: boolean = false): string {
        let userFriendlyName: string;

        if (role === GenRoleDefinition.ROLE_ADMIN) {
            userFriendlyName = 'Application Admin';
        } else if (role === GenRoleDefinition.ROLE_DOE) {
            userFriendlyName = 'Department of Energy';
        } else if (role === GenRoleDefinition.ROLE_FEDERAL) {
            userFriendlyName = 'Federal';
        } else {
            if (returnOriginal) {
                return role.toString();
            }

            return role.name
                .substr(5)
                .toLowerCase()
                .replace(/_/g, ' ')
                .replace(/\b[a-z]/g, function (l) {
                    return l.toUpperCase();
                });
        }

        return userFriendlyName;
    }
}
