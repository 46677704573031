import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApplicationConfig} from '../../../classes/application-config';
import {BehaviorSubject, forkJoin, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {LegendElement} from '../classes/legend-element';
import {LeafletLayerLegend} from '../classes/leaflet-layer-legend';

@Injectable({
    providedIn: 'root',
})
export class LayerLegendService {
    static legends = new BehaviorSubject<LeafletLayerLegend[]>([]);

    constructor(private http: HttpClient) {}

    public getNavigableWaterwayLegend(): Observable<any> {
        const url = `https://maps.bts.dot.gov/services/rest/services/NTAD/Navigable_Waterway_Lines/MapServer`;
        return this.http.get<any>(`${ApplicationConfig.proxyPrefix}${url}/0/legend?f=json`);
    }

    public getHurricaneOutlookLegend(): Observable<any> {
        const url = `https://idpgis.ncep.noaa.gov/arcgis/rest/services/NWS_Forecasts_Guidance_Warnings/NHC_E_Pac_trop_cyclones/MapServer/`;
        return this.http.get<any>(`${ApplicationConfig.proxyPrefix}${url}/3/legend?f=json`);
    }

    public getHurricaneTractLegend(): Observable<any> {
        const url = `https://idpgis.ncep.noaa.gov/arcgis/rest/services/NWS_Forecasts_Guidance_Warnings/NHC_E_Pac_trop_cyclones/MapServer/`;
        return this.http.get<any>(`${ApplicationConfig.proxyPrefix}${url}/legend?f=json`);
    }

    public getPortLegend(): Observable<any> {
        const url = `https://geo.dot.gov/server/rest/services/NTAD/Ports/MapServer`;
        return this.http.get<any>(`${ApplicationConfig.proxyPrefix}${url}/legend?f=json`);
    }

    public getDamageAssessmentTileLegend(layerUrl: string): Observable<LegendElement[]> {
        const url = `${ApplicationConfig.proxyPrefix}${layerUrl}/legend?f=json`;

        const mf = (json: any) => {
            const images: any[] = json.layers[0].legend;
            return images
                .map((image) => {
                    const element = new LegendElement();
                    const label = (image.label as string).split(':');
                    element.imageUrl = `data:${image.contentType};base64,${image.imageData}`;
                    element.name = label.length > 1 ? label[1].trim() : label[0].trim();
                    element.height = image.height;
                    element.width = image.width;
                    return element;
                })
                .reverse();
        };

        return this.http.get(url).pipe(map(mf));
    }

    public getWeatherWatchesLegend(layerIds: number[]): Observable<Map<string, LegendElement[]>> {
        const query = `query/?f=json&returnGeometry=false&spatialRel=esriSpatialRelIntersects&geometry=%7B%22xmin%22%3A-20037497%2C%22ymin%22%3A-30240334%2C%22xmax%22%3A20037497%2C%22ymax%22%3A30240334%2C%22spatialReference%22%3A%7B%22wkid%22%3A4269%7D%7D&geometryType=esriGeometryEnvelope&inSR=4269&outFields=prod_type&outSR=4269`;
        const legendUrl = `https://idpgis.ncep.noaa.gov/arcgis/rest/services/NWS_Forecasts_Guidance_Warnings/watch_warn_adv/MapServer`;
        const calls: any[] = [];

        const legendCall = this.http.get(`${legendUrl}/legend?f=json`);
        calls.push(legendCall);

        layerIds.forEach((id) => {
            const call = this.http.get(`${legendUrl}/${id}/${query}`);
            calls.push(call);
        });

        const mf = (res: any[]) => {
            const legends = res[0].layers;
            const active: Set<string> = new Set<string>();
            const legendMap: Map<string, LegendElement[]> = new Map<string, LegendElement[]>();

            for (let i = 1; i < res.length; i++) {
                if (res[i].features) {
                    res[i].features.forEach((f) => {
                        active.add(f.attributes.prod_type);
                    });
                }
            }

            legends.forEach((legendData) => {
                const key = legendData.layerName;

                if (!legendMap.has(key)) {
                    const values = legendData.legend
                        .filter((l) => active.has(l.label))
                        .map((l) => {
                            const element = new LegendElement();
                            element.name = l.label;
                            element.imageUrl = `data:${l.contentType};base64,${l.imageData}`;
                            element.width = l.width;
                            element.height = l.height;
                            return element;
                        });
                    legendMap.set(key, values);
                }
            });

            return legendMap;
        };

        return forkJoin([calls]).pipe(map(mf));
    }
}
