<div class="dialog-header">
    <span>Edit Admin Info</span>
    <button mat-icon-button mat-dialog-close>
        <mat-icon fontSet="fas" fontIcon="fa-times"></mat-icon>
    </button>
</div>
<div class="dialog-content" #content>
    <p class="section-header">Roles</p>
    <div #roleList class="roles">
        <mat-checkbox *ngFor="let role of allRoles" (change)="toggleRole($event, role)" [checked]="isChecked(role)">
            {{ role | roleDefinition }}
        </mat-checkbox>
    </div>
</div>

<div class="dialog-actions">
    <button mat-stroked-button mat-dialog-close>Close</button>
    <button mat-flat-button color="primary" (click)="save()">Save</button>
</div>
