
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import { GenSystemEvent } from './GenSystemEvent';
import * as moment from 'moment';

export class GenUploadedFile {

    id: number;
    additionalInformation: string;
    description: string;
    displayName: string;
    events: GenSystemEvent[];
    filename: string;
    parentId: number;
    path: string;
    solrId: string;
    uploadDate: moment.Moment;
    userId: number;

    constructor();
    constructor( jsonObj: any );
    constructor( genUploadedFile: GenUploadedFile);
    constructor( id: number );

    constructor( input?: GenUploadedFile|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            this.additionalInformation = input.additionalInformation; // string [string]
            this.description = input.description; // string [string]
            this.displayName = input.displayName; // string [string]
            if ( input.events && input.events.map ) { // list [GenSystemEvent]
                this.events = input.events.map( ( x: GenSystemEvent ) => {
                    return new GenSystemEvent( x );
                } );
            } else {
                this.events = input.events;
            }
            this.filename = input.filename; // string [string]
            this.parentId = input.parentId; // int [number]
            this.path = input.path; // string [string]
            this.solrId = input.solrId; // string [string]
            if ( input.uploadDate && typeof input.uploadDate === 'string' ) {
                this.uploadDate = moment(input.uploadDate).utc();
            } else {
                this.uploadDate = input.uploadDate;
            }
            this.userId = input.userId; // int [number]
       }
    }

    static jsonResponseToList(arr: any[]): GenUploadedFile[] {
        if (arr && arr.map) {
          return arr.map(a => new GenUploadedFile(a));
        } else {
          return [];
        }
    }
}

