import * as moment from 'moment';
import {GenRestoration} from '../../../../../generated/serverModels/GenRestoration';

export class OutageRestoration extends GenRestoration {
    public restorationData: any[];

    private restorationNumbers: RestorationNumbers[] = [];

    get restoration(): RestorationNumbers[] {
        return this.restorationNumbers ? this.restorationNumbers : [];
    }

    get trendIcon(): string {
        if (this.restorationNumbers.length <= 1) {
            return 'trending_flat';
        }

        const recent = this.restorationNumbers[this.restorationNumbers.length - 1].percentRestored || 0;
        const beforeRecent = this.restorationNumbers[this.restorationNumbers.length - 2].percentRestored || 0;
        if (recent > beforeRecent) {
            return 'trending_up';
        } else if (recent < beforeRecent) {
            return 'trending_down';
        } else {
            return 'trending_flat';
        }
    }

    get outageCountTotal(): number {
        return this.restorationNumbers[this.restorationNumbers.length - 1].customersOut;
    }

    get percentRestored(): number {
        return this.restorationNumbers[this.restorationNumbers.length - 1].percentRestored;
    }

    get knownPercent(): boolean {
        return this.restorationNumbers[this.restorationNumbers.length - 1].customersWereOut > 0;
    }

    get restorationTotal(): number {
        return this.restorationNumbers[this.restorationNumbers.length - 1].customersRestored;
    }

    get timestamp(): moment.Moment {
        return moment(this.restorationNumbers[this.restorationNumbers.length - 1].runTime);
    }

    constructor(restoration: any[]) {
        super(restoration[restoration.length - 1]);

        if (restoration.length > 0) {
            this.restorationData = restoration;

            this.createRestorationNumbers();
        }
    }

    private createRestorationNumbers(): void {
        const findBetween = (d) =>
            this.restorationNumbers.find((res) =>
                moment(d.outageRunStartTime).isBetween(moment(res.runTime).subtract(30, 'minutes'), moment(res.runTime).add(30, 'minutes'))
            );
        this.restorationData.forEach((d) => {
            const r = new RestorationNumbers({
                runTime: moment(d.outageRunStartTime),
                customersOut: d.outageCount,
                customersWereOut: d.previousOutageCount,
                customersRestored: d.restorationCount,
            } as any);

            if (findBetween(d)) {
                findBetween(d).summarizeNumbers(r);
            } else {
                this.restorationNumbers.push(r);
            }
        });

        this.restorationNumbers.sort((a, b) => {
            if (moment(a.runTime).valueOf() > moment(b.runTime).valueOf()) {
                return 1;
            }

            if (moment(a.runTime).valueOf() < moment(b.runTime).valueOf()) {
                return -1;
            }

            return 0;
        });

        this.restorationNumbers.forEach((res) => {
            this.maxOutageCount = Math.max(this.maxOutageCount, res.customersOut);
            this.maxRestorationCount = Math.max(this.maxRestorationCount, res.customersRestored);
        });
    }

    public getRestoration(): RestorationNumbers[] {
        return this.restorationNumbers || [];
    }
}

class RestorationNumbers {
    public runTime: moment.Moment;
    public customersOut: number;
    public customersRestored: number;
    public customersWereOut: number;

    public percentRestored: number;

    constructor(input?: RestorationNumbers) {
        this.runTime = input.runTime;
        this.customersOut = input.customersOut;
        this.customersWereOut = input.customersWereOut;
        this.customersRestored = input.customersRestored;

        this.percentRestored = this.customersWereOut > 0 ? this.clamp(this.customersRestored / this.customersWereOut, 0, 1) * 100 : 0;
    }

    public summarizeNumbers(r: RestorationNumbers): void {
        this.customersOut += r.customersOut;
        this.customersWereOut += r.customersWereOut;
        this.customersRestored += r.customersRestored;

        this.percentRestored = this.customersWereOut > 0 ? this.clamp(this.customersRestored / this.customersWereOut, 0, 1) * 100 : 0;
    }

    // noinspection JSMethodCanBeStatic
    private clamp(val: number, min: number, max: number): number {
        return val < min ? min : val > max ? max : val;
    }
}
