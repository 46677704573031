<div fxLayout="row" fxLayoutAlign="space-between center" class="dialog-header">
    <div>{{ data.name }} Info</div>
    <mat-icon [inline]="true" fontSet="fas" fontIcon="fa-times" class="clickable" (click)="dialogRef.close()"></mat-icon>
</div>
<div class="dialog-content" #content>
    <div *ngFor="let item of data.properties | keyvalue" class="data-property">
        <div class="section">
            <p class="metadata-label">{{ item.key.toString() | replaceCharacter }}</p>
            <p class="metadata-text">{{ item.value || 'Not Available' }}</p>
        </div>
    </div>
</div>
