<div class="dialog-header">
    <span>{{ title }}</span>
    <button mat-icon-button (click)="close()">
        <mat-icon fontSet="fas" fontIcon="fa-times"></mat-icon>
    </button>
</div>
<div class="dialog-content" #content>
    <!-- Aggregation Level -->
    <div>
        <p class="section-header">Default Aggregation Level</p>
        <mat-button-toggle-group
            class="button-group"
            [value]="preferences.getOutageAggregationLevel()"
            (change)="changeAggregationLevel($event.value)">
            <mat-button-toggle *ngFor="let level of aggregationLevels" [value]="level">
                {{ level | aggregationLevel }}
            </mat-button-toggle>
        </mat-button-toggle-group>

        <!--Mobile button-->
        <button
            mat-stroked-button
            class="wide button-group mobile"
            (click)="changeAggregationLevel(level)"
            [color]="preferences.getOutageAggregationLevel() === level ? 'primary' : undefined"
            *ngFor="let level of aggregationLevels">
            {{ level | aggregationLevel }}
        </button>
    </div>

    <!-- FEMA Region -->
    <div>
        <p class="section-header">FEMA Regions</p>
        <div class="chip-container small">
            <mat-chip *ngFor="let option of regionOptions" (click)="toggleRegion(option)" [class.selected]="option.selected">
                <div class="chip-content">
                    <mat-icon [class.invisible]="!option.selected" fontSet="fas" fontIcon="fa-check" inline></mat-icon>
                    <p>{{ option.regionId.toString() | numeral : true }}</p>
                </div>
            </mat-chip>
        </div>
    </div>

    <!-- States -->
    <div>
        <p class="section-header">Default States/Territories</p>
        <div class="select-clear">
            <mat-chip (click)="toggleAllStates(true)">Select All</mat-chip>
            <mat-chip (click)="toggleAllStates(false)">Clear All</mat-chip>
        </div>
        <div class="chip-container">
            <mat-chip *ngFor="let option of stateOptions" (click)="toggleState(option)" [class.selected]="option.selected">
                <div class="chip-content">
                    <mat-icon [class.invisible]="!option.selected" fontSet="fas" fontIcon="fa-check" inline></mat-icon>
                    <p
                        #stateName
                        class="ellipsis state-name"
                        [matTooltipDisabled]="!showEllipsis(stateName)"
                        [matTooltip]="option.state.name"
                        matTooltipPosition="above"
                        matTooltipShowDelay="250">
                        {{ option.state.name }}
                    </p>
                </div>
            </mat-chip>
        </div>
    </div>

    <!-- Legend Style -->
    <div>
        <p class="section-header">Legend Style</p>
        <mat-button-toggle-group class="button-group" [value]="preferences.legendStyle.id" (change)="changeLegendStyle($event.value)">
            <mat-button-toggle *ngFor="let style of legendStyles" [value]="style.id">{{ style.name | legendName }}</mat-button-toggle>
        </mat-button-toggle-group>

        <!--Mobile button-->
        <button
            mat-stroked-button
            class="wide button-group mobile"
            (click)="changeLegendStyle(style.id)"
            [color]="preferences.legendStyle.name === style.name ? 'primary' : undefined"
            *ngFor="let style of legendStyles">
            {{ style.name | legendName }}
        </button>
    </div>

    <!-- Legend Metric -->
    <div>
        <p class="section-header">Legend Metric</p>
        <mat-button-toggle-group class="button-group" [value]="preferences.legendMetric" (change)="changeLegendMetric($event.value)">
            <mat-button-toggle *ngFor="let metric of legendMetrics" [value]="metric">
                {{ metric | legendMetric }}
            </mat-button-toggle>
        </mat-button-toggle-group>

        <!--Mobile button-->
        <button
            mat-stroked-button
            class="wide button-group mobile"
            (click)="changeLegendMetric(metric)"
            [color]="preferences.legendMetric === metric ? 'primary' : undefined"
            *ngFor="let metric of legendMetrics">
            {{ metric | legendMetric }}
        </button>
    </div>

    <!-- Example Legend -->
    <div>
        <p class="section-header">Example Legend</p>
        <eaglei-nom-legend [multiLine]="true" [disableHideOverrides]="true"></eaglei-nom-legend>
    </div>
</div>

<div class="dialog-actions">
    <button mat-stroked-button (click)="close()">{{ initialPreferenceSelection ? 'Skip' : 'Close' }}</button>
    <button mat-flat-button color="primary" (click)="save()" [disabled]="!isFormValid()">Save</button>
</div>
