import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {ApplicationConfig} from 'frontend/src/app/classes/application-config';
import {Subscription} from 'rxjs';
import {LeafletSidebarComponent} from '../../../map/components/leaflet-sidebar/leaflet-sidebar.component';
import {MapService} from '../../../map/services/map.service';
import {MapLiteComponent} from '../map-lite/map-lite.component';

@Component({
    selector: 'eaglei-map-layout-lite',
    templateUrl: './map-layout-lite.component.html',
    styleUrls: ['./map-layout-lite.component.scss'],
})
export class MapLayoutLiteComponent implements AfterViewInit {
    // DOM Elements
    @ViewChild('mapTarget') liteMap: MapLiteComponent;
    @ViewChild('content') content: ElementRef<HTMLDivElement>;
    @ViewChild('mapContainer') mapContainer: ElementRef<HTMLDivElement>;
    @ViewChild('tableContainer') tableContainer: ElementRef<HTMLDivElement>;
    @ViewChild('sidebar') sidebar: LeafletSidebarComponent;
    @ViewChild(MatSort) sort: MatSort;

    // Table Datasource Properties
    public dataSource: MatTableDataSource<any>;
    public subDataSource: MatTableDataSource<any>;
    public columnNames: string[] = ['name', 'customers_out', 'total_customers', 'percent_out', 'last_hour', 'last_day'];

    // Subscription service
    private subs = new Subscription();

    constructor(private ele: ElementRef, private mapService: MapService) {
        (ele.nativeElement as HTMLElement).classList.add('no-padding');
    }

    public ngAfterViewInit(): void {
        this.subs.add(
            this.mapService.nomLoadedData.subscribe((data) => {
                if (this.dataSource) {
                    this.dataSource.data = data?.sort((a, b) => b.currentOutage - a.currentOutage);
                } else {
                    this.dataSource = new MatTableDataSource<any>(data?.sort((a, b) => b.currentOutage - a.currentOutage));
                    this.dataSource.sortingDataAccessor = this.dataAccessor;
                    this.dataSource.sort = this.sort;
                }
            })
        );

        this.subs.add(this.liteMap?.toggleSidebar.subscribe(() => this.sidebar?.toggleSidebar()));

        ApplicationConfig.resizeEvent.subscribe(() => this.setTableHeight());

        setTimeout(() => {
            if (this.onPhone()) {
                this.sidebar?.toggleSidebar();
            }
        }, 400);

        setTimeout(() => {
            this.setTableHeight();
        }, 1050);
    }

    public dataAccessor(data: any, column: string): string | number {
        switch (column) {
            case 'customers_out':
                return data.currentOutage;
            default:
                return '';
        }
    }

    /**
     * Checks mobile use
     */
    public onPhone(): boolean {
        return ApplicationConfig.useMobileLayout();
    }

    public validPercent(percent: any): boolean {
        return typeof percent === 'number' && Number.isFinite(percent);
    }

    /**
     * Updated the class list of the scale element when the sidebar changes
     * @param open Sidebar is opened
     */
    public sidebarChanged(open: boolean) {
        this.liteMap.sidebarOpen = open;

        if (open) {
            this.liteMap.scaleElement.classList.add('slide-out');
        } else {
            this.liteMap.scaleElement.classList.remove('slide-out');
        }
    }

    /**
     * Corrects the table height to ensure the elements of the table aren't cut off at the bottom of the screen
     */
    private setTableHeight(): void {
        // const height = document.body.getBoundingClientRect().height;
        // const width = document.body.getBoundingClientRect().width;
        const height = this.content.nativeElement.getBoundingClientRect().height;
        const width = this.content.nativeElement.getBoundingClientRect().width;

        // const heightOffset = Math.max(((width / height) / 2.5), .55);
        const heightOffset = width / height / 2;
        const maxOffset = 100 - heightOffset * 100;
        // const newHeight = Math.floor((height * heightOffset));
        const newHeight = height - this.mapContainer.nativeElement.getBoundingClientRect().height;

        // this.tableContainer.nativeElement.style.minHeight = `${newHeight}px`;
        this.tableContainer.nativeElement.style.maxHeight = newHeight > 0 ? `${newHeight}px` : `${height}px`;
        // this.tableContainer.nativeElement.style.maxHeight = `${newHeight + maxOffset}px`;
    }
}
