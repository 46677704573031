import {Injectable, Injector} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {FemaRegion} from '../modules/outage/classes/fema-region';
import {State} from '../modules/outage/classes/state';
import {AdditionalLayerInfo} from '../classes/additional-layer-info';
import * as moment from 'moment';
import {ResponseWrapper} from '../classes/response-wrapper';
import {OutageComparison} from '../modules/report/classes/outage-comparison';
import {map, tap} from 'rxjs/operators';
import {CommunityFile} from '../modules/community/classes/community-file';
import {GenFemaRegion} from '../../../generated/serverModels/GenFemaRegion';
import {SystemNotification} from '../classes/system-notification';
import {IStateOption} from '../interfaces/state-option.interface';
import {IRegionOption} from '../interfaces/region-option.interface';
import {GenOutageAggregationLevel} from '../../../generated/serverModels/GenOutageAggregationLevel';
import {ApplicationConfig} from '../classes/application-config';
import {LeafletFeature} from '../modules/layer/classes/leaflet-feature';
import * as L from 'leaflet';
import {GenCounty} from '../../../generated/serverModels/GenCounty';
import {County} from '../modules/outage/classes/county';

export let DataInjector: Injector;

@Injectable({
    providedIn: 'root',
})
export class DataService {
    constructor(private http: HttpClient, private injector: Injector) {
        this.stateNames = new BehaviorSubject<State[]>([]);
        this.femaNames = new BehaviorSubject<FemaRegion[]>([]);
        this.additionalLayerData = new BehaviorSubject<AdditionalLayerInfo | undefined>(undefined);
        this.showMapperChartMask = new BehaviorSubject<boolean>(false);
        DataInjector = this.injector;
    }

    static states: BehaviorSubject<State[]> = new BehaviorSubject<State[]>([]);
    static femaRegions: BehaviorSubject<FemaRegion[]> = new BehaviorSubject<FemaRegion[]>([]);
    static statesByFema: BehaviorSubject<Map<number, State[]>> = new BehaviorSubject<Map<number, State[]>>(undefined);
    static nonConusStateAbbreviations: string[] = ['AK', 'HI', 'PR', 'AS', 'MP', 'GU', 'VI'];

    static geometryNameSeparator: string = '::--::';

    static femaRegionOverwrites: Map<number, number>;
    static stateOverwrites: Map<string, number>;
    static countyOverwrites: Map<string, number>;
    static utilityOverwrites: Map<string, number>;
    static overwritesActive: BehaviorSubject<boolean> = new BehaviorSubject(false);
    static generatedOutageData: BehaviorSubject<boolean> = new BehaviorSubject(false);

    static getFemaRegionsFromStateNames(stateNames: string[]): number[] {
        const regionIds = stateNames
            .map((name) => {
                return DataService.states.value.find((state) => state.abbreviation === name || state.name === name).dotregion;
            })
            .reduce((prev, cur) => {
                return prev.add(cur);
            }, new Set<number>());
        return Array.from(regionIds.values()).sort((a, b) => (a < b ? -1 : 1));
    }

    static getStateAbbreviationsFromFemaRegionIds(femaRegions: number[]): string[] {
        const stateNames = new Set<string>();
        femaRegions.forEach((region) => {
            if (typeof region !== 'number') {
                console.warn(`cannot convert ${region} because it is not a number`);
            } else {
                DataService.states.value
                    .filter((state) => state.dotregion === region)
                    .forEach((state) => stateNames.add(state.abbreviation));
            }
        });

        return Array.from(stateNames).sort();
    }

    static getStatesFromFemaIds(regionIds: number[]): State[] {
        const femaRegions = regionIds.map((id) => new FemaRegion(new GenFemaRegion(id) as any));
        return DataService.getStatesFromFemaRegions(femaRegions);
    }

    static getStatesFromFemaRegions(regions: FemaRegion[]): State[] {
        let ret: State[] = [];
        regions.forEach((region) => {
            ret = DataService.states.value.filter((s) => s.dotregion === region.id).concat(ret);
        });
        return ret;
    }

    private nameUrl = `/api/location`;
    public stateNames: BehaviorSubject<State[]>;
    public femaNames: BehaviorSubject<FemaRegion[]>;

    public stateOptions: IStateOption[];
    public regionOptions: IRegionOption[];

    public additionalLayerData: BehaviorSubject<AdditionalLayerInfo | undefined>;
    public showMapperChartMask: BehaviorSubject<boolean>;

    public getStateGeometries(): Observable<State[]> {
        const tapSuccess = (states: State[]) => {
            const conusFeatures = [];
            const stateGeometries = states.map((state) => {
                const geom = {
                    name: state.abbreviation,
                    boundary: state.boundary,
                    fipsCode: state.geoid,
                    stateId: state.id,
                };

                if (!DataService.nonConusStateAbbreviations.includes(geom.name)) {
                    conusFeatures.push(new LeafletFeature().convert(geom, undefined, false, true));
                }

                return geom;
            });
            ApplicationConfig.geometries.set(GenOutageAggregationLevel.state, stateGeometries);
            ApplicationConfig.conus = L.geoJSON(conusFeatures as any);
        };

        return this.http.get<State[]>('api/location/all-states').pipe(
            map((states) => states.map((state) => new State(state))),
            tap(tapSuccess)
        );
    }

    public getCountyGeometries(): Observable<County[]> {
        const tapSuccess = (counties: County[]) => {
            const countyGeometries = counties.map((county) => {
                return {
                    fipsCode: county.geoid,
                    boundary: county.boundary,
                    name: `${county.stateAbbreviation}${DataService.geometryNameSeparator}${county.name}`,
                    stateId: county.stateId,
                    countyId: county.id,
                };
            });

            ApplicationConfig.geometries.set(GenOutageAggregationLevel.county, countyGeometries);
        };

        return this.http.get<any[]>('api/location/all-counties').pipe(
            map((counties) => counties.map((county) => new County(county))),
            tap(tapSuccess)
        );
    }

    public getStateNames(): Observable<State[]> {
        if (this.stateNames.value.length !== 0) {
            return this.stateNames.asObservable();
        }
        const url = `${this.nameUrl}/state?format=None`;
        return this.http.get<any[]>(url).pipe(map((states) => states.map((state) => new State(state))));
    }

    public getFemaNames(): Observable<FemaRegion[]> {
        if (this.femaNames.value.length !== 0) {
            return this.femaNames.asObservable();
        }
        const url = `${this.nameUrl}/fema?format=None`;
        return this.http.get<FemaRegion[]>(url).pipe(map((regions) => regions.map((region) => new FemaRegion(region))));
    }

    public getCountiesFromState(stateId: number): Observable<GenCounty[]> {
        return this.http.get<GenCounty[]>(`${this.nameUrl}/county?stateId=${stateId}&format=None`);
    }

    public getUtilities(): Observable<any> {
        return this.http.get('api/utility/utility-county-customer');
    }

    public getCurrentOverwrites(): Observable<any> {
        const url = `api/eocoverride/utility?start=${moment().format()}&end=${moment().format()}`;

        const mf = (ret: ResponseWrapper<OutageComparison>) => {
            DataService.femaRegionOverwrites = new Map<number, number>();
            DataService.stateOverwrites = new Map<string, number>();
            DataService.countyOverwrites = new Map<string, number>();
            DataService.utilityOverwrites = new Map<string, number>();

            const updateMap = (outageMap: Map<any, any>, key: any, c: OutageComparison) => {
                const value = outageMap.get(key) || 0;
                outageMap.set(key, value + c.overrideCount);
            };

            const res = ret.data.map((comparison) => new OutageComparison(comparison));

            res.forEach((c) => {
                updateMap(DataService.femaRegionOverwrites, c.femaRegion, c);
                updateMap(DataService.stateOverwrites, c.state, c);
                updateMap(DataService.countyOverwrites, `${c.countyName}:${c.state}`, c);
                updateMap(DataService.utilityOverwrites, c.id, c);
            });

            DataService.overwritesActive.next(ret.data.length !== 0);

            return res;
        };

        return this.http.get<ResponseWrapper<OutageComparison>>(url).pipe(map(mf));
    }

    public getSituationReport(): Observable<CommunityFile> {
        const url = `api/permission/community/situationreport`;
        return this.http.get<any>(url).pipe(map((cf) => (cf === null ? undefined : new CommunityFile(cf))));
    }

    public getSystemNotifications(limit: number = 10): Observable<SystemNotification[]> {
        const url = `api/notifications?limit=${limit}`;
        return this.http.get<any[]>(url).pipe(map((notifications) => notifications.map((n) => new SystemNotification(n))));
    }

    public markNotificationsAsSeen(userId: number, notificationIds: number[]): Observable<any> {
        const url = `api/notifications/markasseen/${userId}`;
        return this.http.put(url, notificationIds);
    }

    public getRecentEventSystemNotifications(): Observable<SystemNotification[]> {
        const startDate = moment().subtract(7, 'days');
        const url = `api/notifications/recentevents?start=${startDate.format()}`;
        return this.http.get<any[]>(url).pipe(map((notifications) => notifications.map((n) => new SystemNotification(n))));
    }

    public getGeneratedOutages(): Observable<any> {
        const url = `api/outages/generate/generated-outages`;
        return this.http.get(url).pipe(tap((res: any) => DataService.generatedOutageData.next(res.length !== 0)));
    }
}
