import {AfterViewInit, Directive, ElementRef, OnDestroy, ViewChild} from '@angular/core';
import {ModalConfig} from './modal-config';
import {ApplicationConfig} from './application-config';
import {filter, takeUntil, throttleTime} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Directive()
export abstract class BaseModal implements AfterViewInit, OnDestroy {
    private readonly maxPercent: number;
    private readonly ignoreResize: boolean = false;
    protected destroy$ = new Subject();

    @ViewChild('content', {static: true}) protected content: ElementRef<HTMLElement>;

    protected constructor(maxHeightPercent?: number, ignoreResize: boolean = false) {
        this.maxPercent = maxHeightPercent;
        this.ignoreResize = ignoreResize;
    }

    ngAfterViewInit(): void {
        if (!this.ignoreResize) {
            ModalConfig.setModalHeight(this.content, this.maxPercent);
        }
        ApplicationConfig.resizeEvent
            .pipe(
                throttleTime(250),
                filter(() => !this.ignoreResize),
                takeUntil(this.destroy$)
            )
            .subscribe(() => ModalConfig.setModalHeight(this.content, this.maxPercent));
        this.afterInit();
    }

    ngOnDestroy() {
        this.destroy$.complete();
        this.destroy$.unsubscribe();
    }

    abstract afterInit();
}
