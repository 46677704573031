import {MatSelectChange} from '@angular/material/select';
import {GenAlert} from 'frontend/generated/serverModels/GenAlert';
import {GenCounty} from 'frontend/generated/serverModels/GenCounty';
import {DataInjector, DataService} from 'frontend/src/app/services/data.service';
import {State} from '../../outage/classes/state';
import {OutageService} from '../../outage/services/outage.service';
import {NumeralPipe} from 'frontend/src/shared/pipes/numeral.pipe';

export class UserAlert extends GenAlert {
    get locationCustomers(): number {
        return this._locationCustomers;
    }

    set locationCustomers(value: number) {
        this._locationCustomers = value;
    }

    public id: number;
    public ownerId: number;

    public location: number | State;
    public locationName: string;
    private _locationCustomers: number;

    public county: GenCounty;
    public type: string;
    public threshold: number;
    public thresholdPercent: number;

    public percent: number;
    public detailIds: number[] = [];
    public thresholdType: string = 'percent';

    public editing: boolean = false;
    public selected: boolean = false;
    public applyToStates = false;
    public systemAlert: boolean = false;

    private counties: GenCounty[] = [];
    private disableCounty: boolean = true;

    private femaPipe: NumeralPipe = new NumeralPipe();

    constructor(alert: GenAlert, countyList: GenCounty[]) {
        super(alert);

        this.locationName = this.name;

        this.details?.forEach((detail) => {
            if (this.type === 'region') {
                this.location = DataService.femaRegions.value.find((fr) => fr.dotregion === detail).dotregion as any;
            } else if (this.type === 'state') {
                this.location = DataService.states.value.find((s) => s.id === detail);
            } else if (this.type === 'county') {
                this.county = countyList.find((c) => c.id === detail);
                this.location = DataService.states.value.find((s) => s.stateAbbreviation === this.county.stateAbbreviation);
                this.locationName = this.location.name;
            }
        });

        this.systemAlert = !this.ownerId;
    }

    public updateLocation(event: MatSelectChange): void {
        if (this.location !== event?.value) {
            this.county = undefined;
            this.description = '';
        }

        this.location = event?.value;

        if (typeof this.location !== 'number') {
            this.applyToStates = false;
            this.locationName = this.location.name;
            this.name = this.locationName;
            this.detailIds = [this.location.id];

            DataInjector.get(OutageService)
                .getCountiesForStates([this.location])
                .subscribe((res) => {
                    this.counties = res;
                    this.counties.sort((a, b) => {
                        if (a.name > b.name) {
                            return 1;
                        } else if (a.name < b.name) {
                            return -1;
                        }
                        return 0;
                    });
                    this.disableCounty = false;
                });
        } else {
            this.locationName = this.femaPipe.transform(this.location as any, true);
            this.name = `FEMA Region ${this.femaPipe.transform(this.location as any)}`;
            this.detailIds = [this.location];

            this.counties = [];
            this.county = undefined;
            this.description = '';
            this.disableCounty = true;
        }

        this.updateType();
    }

    public updateCounty(event: MatSelectChange): void {
        this.county = event.value;
        this.description = this.county.name;
        this.name = `${this.county.name} (${(this.location as State).abbreviation})`;
        this.detailIds = [this.county.id];

        this.updateType();
    }

    private updateType(): void {
        let type = 'region';
        if (typeof this.location !== 'number') {
            type = 'state';
        }

        if (!!this.county) {
            type = 'county';
        }

        this.type = type;
    }

    public isSelectedFema(): boolean {
        return typeof this.location === 'number';
    }
}
