import {Component, inject, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'eaglei-confirmation',
    templateUrl: './confirmation.component.html',
    styleUrls: ['../shared-modals.scss', './confirmation.component.scss'],
})
export class ConfirmationComponent {
    private data = inject(MAT_DIALOG_DATA);
    public confirmationMessage: string = this.data?.message || 'Are you sure you want to perform this action?';
    public submitComments = this.data?.submitComments || false;
    public comments = '';
}
