export const getFontColor = (color: string, lightColor: string = '#FFF', darkColor = '#000'): string => {
    // https://awik.io/determine-color-bright-dark-using-javascript/
    // Covert Hex to RGB
    const rgb = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'));
    const r = rgb >> 16;
    const g = (rgb >> 8) & 255;
    const b = rgb & 255;

    const brightness = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

    return brightness < 137.5 ? lightColor : darkColor;
};
