import {Component, Inject, ViewChild} from '@angular/core';
import {GenOutageAggregationLevel} from '../../../../../../generated/serverModels/GenOutageAggregationLevel';
import {DataService} from '../../../../services/data.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UserService} from '../../services/user.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {BaseModal} from '../../../../classes/base-modal';
import {LegendMetric} from '../../../../../shared/enums/legend-metric.enum';
import {State} from '../../../outage/classes/state';
import {UserPreference} from '../../../../classes/user-preferences';
import {LegendStyle} from '../../classes/legend-style';
import {NomLegendComponent} from '../../../../../shared/components/nom-legend/nom-legend.component';
import {GenStatePreference} from '../../../../../../generated/serverModels/GenStatePreference';
import {ApplicationConfig} from '../../../../classes/application-config';

interface StateOption {
    state: State;
    selected: boolean;
}

interface RegionOption {
    regionId: number;
    states: State[];
    selected: boolean;
}

@Component({
    selector: 'eaglei-edit-preference-modal',
    templateUrl: './edit-preference-modal.component.html',
    styleUrls: ['./edit-preference-modal.component.scss'],
})
export class EditPreferenceModalComponent extends BaseModal {
    @ViewChild(NomLegendComponent) legend: NomLegendComponent;

    public readonly aggregationLevels = GenOutageAggregationLevel.values().filter(
        (level) => ![GenOutageAggregationLevel.fema, GenOutageAggregationLevel.utility, GenOutageAggregationLevel.zip].includes(level)
    );

    public readonly regionOptions: RegionOption[] = [];
    public readonly stateOptions: StateOption[] = DataService.states
        .getValue()
        .map((state) => {
            return {
                state,
                selected: false,
            };
        })
        .sort((a, b) => (a.state.name > b.state.name ? 1 : -1));

    public legendMetrics = LegendMetric.values();
    public legendStyles: LegendStyle[] = [];

    public preferences: UserPreference;

    // Properties that change based off if a user is setting up there preferences for the first time
    public title = 'Edit Preferences';
    public buttonText = 'Save';
    public showCloseButton = true;
    public initialPreferenceSelection = false;
    private readonly userId: number;

    constructor(
        private dialogRef: MatDialogRef<EditPreferenceModalComponent>,
        @Inject(MAT_DIALOG_DATA) data: any,
        private userService: UserService,
        private popup: MatSnackBar
    ) {
        super();

        if (data && data.preferences) {
            this.preferences = new UserPreference(data.preferences);
            this.legendStyles = data.legendStyles;
        } else {
            this.userId = data.user.id;
            this.getDefaultPreferences();
        }

        // Setting up state filters
        const preferenceStateIds = this.preferences.states.map((s) => s.id);
        this.stateOptions.filter((so) => preferenceStateIds.includes(so.state.id)).forEach((so) => (so.selected = true));

        // Setting up region quick selects
        const regionMap: Map<number, State[]> = new Map<number, State[]>();
        this.stateOptions.forEach((so) => {
            const val = regionMap.get(so.state.dotregion) || [];
            val.push(so.state);
            regionMap.set(so.state.dotregion, val);
        });

        regionMap.forEach((val, key) => {
            const option: RegionOption = {
                regionId: key,
                states: val,
                selected: this.stateOptions.filter((so) => so.state.dotregion === key).every((so) => so.selected),
            };
            this.regionOptions.push(option);
        });

        this.regionOptions.sort((a, b) => (a.regionId > b.regionId ? 1 : -1));
    }

    afterInit() {}

    /**
     * This method is used when a user is selecting their preferences for the first time. It will set all the default values
     * and change the modal text to not be in an edit state.
     */
    private getDefaultPreferences(): void {
        this.title = 'Select Preferences';
        this.buttonText = 'skip';
        this.showCloseButton = false;
        this.initialPreferenceSelection = true;

        this.preferences = new UserPreference();

        this.preferences.userId = this.userId;

        this.stateOptions.forEach((so) => (so.selected = true));
        this.regionOptions.forEach((ro) => (ro.selected = true));
        this.changeAggregationLevel(GenOutageAggregationLevel.state);
        this.preferences.legendMetric = LegendMetric.CUSTOMERS_OUT;

        if (this.legendStyles.length === 0) {
            this.userService.getLegendOptions().subscribe((styles) => {
                this.legendStyles = styles;
                const legend = styles.find((s) => s.name.toLowerCase() === 'default');
                if (legend) {
                    this.preferences.legendStyle = legend;
                } else {
                    console.error('No Default Legend Style Found.');
                }
            });
        } else {
            this.preferences.legendStyle = this.legendStyles.find((s) => s.name.toLowerCase() === 'default');
        }
    }

    /**
     * Closes the modal with no actions taken, if the user is setting the preferences for the first time, it will save the
     * preferences to the default values, but not notify the user.
     */
    public close(): void {
        this.dialogRef.close();

        if (this.initialPreferenceSelection) {
            this.getDefaultPreferences();
            this.popup.open('Preferences can be updated anytime in your profile', 'Okay', {duration: 5_000});
            this.save(true);
        }
    }

    /**
     * Checks to see if all the preferences have valid values
     */
    public isFormValid(): boolean {
        return this.stateOptions.some((so) => so.selected);
    }

    /**
     * Saves the new preferences and closes the modal.
     * @param silent if true, a notification will not be send to the user
     */
    public save(silent: boolean = false): void {
        const success = () => {
            if (!silent) {
                this.popup.open('Preferences updated.', 'Okay', {duration: 5000, panelClass: 'dialog-success'});
            }
            ApplicationConfig.currentUserPreferences.next(this.preferences);
            this.dialogRef.close(this.preferences);
        };
        const failure = (error: any) => {
            const text = error.error.userMessage || 'Failed to update preferences.';
            this.popup.open(text, 'Okay', {duration: 5000, panelClass: 'dialog-failure'});
            this.dialogRef.close();
        };

        this.preferences.states = this.stateOptions
            .filter((so) => so.selected)
            .map((so) => {
                return new GenStatePreference(so.state);
            });

        this.userService.savePreferences(this.preferences).subscribe(success, failure);
    }

    /**
     * Updates the default aggregation level
     * @param aggregationLevel The aggregation level being set in the user preferences
     */
    public changeAggregationLevel(aggregationLevel: GenOutageAggregationLevel): void {
        this.preferences.setAggregationLevel(aggregationLevel);
    }

    /**
     * Updates the default legend style
     * @param styleId the id of the new preference style
     */
    public changeLegendStyle(styleId: number): void {
        const newLegendStyle = this.legendStyles.find((style) => style.id === styleId);
        this.preferences.legendStyle = newLegendStyle;
        this.legend.style = newLegendStyle;
    }

    /**
     * Updates the default legend metric
     * @param metric The new legend metric for the user preferences
     */
    public changeLegendMetric(metric: LegendMetric): void {
        const newLegendMetric = this.legendMetrics.find((m) => metric === m);
        this.preferences.legendMetric = newLegendMetric as number;
        this.legend.metric = newLegendMetric;
    }

    /**
     * Toggles a fema regions selected status and updates the selected status of all the states in that region.
     * @param option The fema region being updated
     */
    public toggleRegion(option: RegionOption) {
        option.selected = !option.selected;
        option.states.forEach((state) => {
            this.stateOptions.find((so) => so.state.id === state.id).selected = option.selected;
        });
    }

    /**
     * Toggles a states selected status and checks to see if the region selected status should be updated.
     * @param option The fema region being updated
     */
    public toggleState(option: StateOption): void {
        option.selected = !option.selected;

        const region = this.regionOptions.find((ro) => ro.regionId === option.state.dotregion);
        region.selected = region.states.every((state) => {
            return this.stateOptions.find((so) => so.state.id === state.id).selected;
        });
    }

    /**
     * Toggles all the state filters on or off
     * @param selected if true, select all states, otherwise deselect
     */
    public toggleAllStates(selected: boolean) {
        this.stateOptions.forEach((so) => (so.selected = selected));
        this.regionOptions.forEach((ro) => (ro.selected = selected));
    }

    showEllipsis(element: HTMLElement) {
        return element.offsetWidth < element.scrollWidth;
    }
}
