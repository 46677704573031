import {LegendElement} from './legend-element';

export class LeafletLayerLegend {
    get layerId() {
        return this._layerId;
    }

    private readonly _layerId;

    public layerName: string;
    public expanded: boolean;

    // Currently, only used for horizontal legends
    public gapSize: number = 15;

    /**
     * Used for manual legends to remove the boarders for the elements
     */
    public hideBorders: boolean = false;

    public roundEnds: boolean = false;

    public orientation: string = 'vertical';

    public imageUrl: string;

    public elements: LegendElement[];

    constructor(layerId: number, layerName?: string) {
        this._layerId = layerId;
        this.layerName = layerName;
        this.expanded = true;
        this.elements = [];
    }
}
