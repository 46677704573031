import {GenUrbanNetReport} from '../../../../generated/serverModels/GenUrbanNetReport';
import {UrbannetDisruption} from './urbannet-disruption';

export class UrbannetReport extends GenUrbanNetReport {
    public disruptions: UrbannetDisruption[] = [];

    constructor(report?: UrbannetReport) {
        super(report);
        if (report) {
            this.disruptions = report.disruptions.map((disruption) => new UrbannetDisruption(disruption));
        }
    }
}
