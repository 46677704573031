import {Component, Input} from '@angular/core';
import {RecentEvent} from '../../classes/recent-event';

@Component({
    selector: 'eaglei-recent-event-info',
    templateUrl: './recent-event-info.component.html',
    styleUrls: ['./recent-event-info.component.scss'],
})
export class RecentEventInfoComponent {
    @Input() event: RecentEvent;

    constructor() {}
}
