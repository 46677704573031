import {GenUtilityCoverageData} from '../../../../../generated/serverModels/GenUtilityCoverageData';

export class UtilityCoverageData extends GenUtilityCoverageData {
    public readonly coverageStatus: 'Covered' | 'Not Covered';

    constructor(data?: UtilityCoverageData) {
        super(data);

        if (data) {
            this.coverageStatus = this.etlScript ? 'Covered' : 'Not Covered';
        }
    }
}
