import {Component, inject, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {CompactType, DisplayGrid, GridsterConfig, GridType} from 'angular-gridster2';
import {CustomLandingLayoutComponent} from 'frontend/src/app/landing/components/custom-landing-layout/custom-landing-layout.component';
import {LandingGridItem} from '../../../../landing/classes/landing-grid-item';
import {AuthenticationService} from '../../../../services/authentication.service';
import {User} from '../../../user/classes/user';

@Component({
    selector: 'eaglei-widget-list',
    templateUrl: './widget-list.component.html',
    styleUrls: ['./widget-list.component.scss'],
})
export class WidgetListComponent implements OnInit, OnChanges {
    @Input() gridOptions: GridsterConfig;
    @Input() dashboard: LandingGridItem[];
    @Input() eventId: BigInteger | number | undefined;

    private authService = inject(AuthenticationService);

    public config: GridsterConfig;

    constructor() {
        this.config = {
            gridType: GridType.Fixed,
            itemResizeCallback: CustomLandingLayoutComponent.itemResize,
            compactType: CompactType.None,
            mobileBreakpoint: 600,
            fixedColWidth: 160,
            fixedRowHeight: 160,
            draggable: {
                enabled: true,
                dragHandleClass: 'draggable',
                ignoreContent: true,
                ignoreContentClass: 'ignore-content',
            },
            resizable: {
                enabled: true,
            },
            swap: true,
            pushItems: true,
            displayGrid: DisplayGrid.None,
        };
    }

    ngOnInit(): void {
        if (this.gridOptions) {
            this.config = {
                ...this.config,
                ...this.gridOptions,
            };
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.dashboard) {
            const widgets: LandingGridItem[] = changes.dashboard.currentValue;
            this.dashboard = widgets.filter((item) => {
                const user: User = this.authService.authenticatedUser.getValue();

                const roles = item.detail?.widget?.roles;
                const communities = item.detail?.widget?.communityPermissions;

                if (roles?.length && communities?.length) {
                    return user.hasAnyRole(roles) || user.hasAnyDataAccess(communities);
                } else if (roles) {
                    return user.hasAnyRole(roles);
                } else if (communities) {
                    return user.hasAnyDataAccess(communities);
                }

                return true;
            });
        }
    }
}
