import {Component, EventEmitter, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ApiKey} from '../../../api-key/classes/api-key';
import {BaseModal} from '../../../../classes/base-modal';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ApiKeyService} from '../../../api-key/services/api-key.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {forkJoin} from 'rxjs';

@Component({
    selector: 'eaglei-api-key-management-modal',
    templateUrl: './api-key-management-modal.component.html',
    styleUrls: ['./api-key-management-modal.component.scss'],
})
export class ApiKeyManagementModalComponent extends BaseModal {
    public requestGroup: UntypedFormGroup;
    public keyRenewed: EventEmitter<any> = new EventEmitter<any>();
    public formUpdated: boolean;

    constructor(
        public ref: MatDialogRef<ApiKeyManagementModalComponent>,
        private apiKeyService: ApiKeyService,
        private popup: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public apiKey: ApiKey
    ) {
        super();

        const textPattern = Validators.pattern(/[.*[\S].*/);
        const controls = {
            reason: new UntypedFormControl('', [Validators.required, Validators.maxLength(500), textPattern]),
            supportedUsers: new UntypedFormControl('', [Validators.required, Validators.min(1), Validators.max(10000)]),
            dataService: new UntypedFormControl(''),
            ogcService: new UntypedFormControl(''),
            system: new UntypedFormControl('', [Validators.required]),
        };

        this.requestGroup = new UntypedFormGroup(controls);

        this.requestGroup.controls.reason.setValue(apiKey.reason);
        this.requestGroup.controls.supportedUsers.setValue(apiKey.usersSupported);
        this.requestGroup.controls.dataService.setValue(apiKey.dataService);
        this.requestGroup.controls.ogcService.setValue(apiKey.ogcService);
        this.requestGroup.controls.system.setValue(apiKey.system);

        if (apiKey.reason) {
            this.requestGroup.controls.reason.disable();
        }

        if (apiKey.system) {
            this.requestGroup.controls.system.disable();
        }

        this.requestGroup.valueChanges.subscribe(() => {
            this.formUpdated = true;
        });
    }

    afterInit() {}

    /**
     * Calls the update API key endpoint and closes the modal on success.
     */
    public submit(): void {
        this.copyFormToKey();

        this.apiKeyService.updateKey(this.apiKey).subscribe((key) => {
            this.ref.close(key);
        });
    }

    /**
     * Updates the expiration date of the current Api-key
     */
    public renewKey(): void {
        const calls = [this.apiKeyService.renewApiKey(this.apiKey)];
        if (this.formUpdated) {
            this.copyFormToKey();
            calls.unshift(this.apiKeyService.updateKey(this.apiKey));
        }

        forkJoin(calls).subscribe(() => {
            this.popup.open('Your API-Key has been renewed for one year', 'Okay', {duration: 3_000, panelClass: 'dialog-success'});
            this.keyRenewed.emit();
        });
    }

    private copyFormToKey() {
        this.apiKey.reason = this.requestGroup.controls.reason.value;
        this.apiKey.usersSupported = this.requestGroup.controls.supportedUsers.value;
        this.apiKey.dataService = this.requestGroup.controls.dataService.value;
        this.apiKey.ogcService = this.requestGroup.controls.ogcService.value;
    }

    public isFormValid(): boolean {
        return this.requestGroup.valid && (this.requestGroup.controls.dataService.value || this.requestGroup.controls.ogcService.value);
    }
}
