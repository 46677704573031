import {LeafletFeature} from '../classes/leaflet-feature';
import {LeafletMapLayer} from '../classes/leaflet-map-layer';
import * as L from 'leaflet';
import {LatLng, Layer} from 'leaflet';
import {LayerStyleService} from '../services/layer-style.service';
import {PopoverElement} from '../../../classes/popover-element';
import {MomentDatePipe} from '../../../../shared/pipes/moment-date.pipe';
import {DecimalPipe} from '@angular/common';
import {OngOilOutage} from '../classes/ong-oil-outage';
import {ClusterSource} from '../classes/cluster-source';

export class OngOilOutageSource extends ClusterSource {
    private numberPipe = new DecimalPipe('en-us');
    private datePipe = new MomentDatePipe();

    constructor(layerInfo: LeafletMapLayer) {
        super(layerInfo);
    }

    processFeatures(features: any[] | any | undefined): void {
        const leafletFeatures = features.map((f) => {
            const feature = new LeafletFeature<OngOilOutage>(f).buildPopover(this.layerInfo);

            if (feature) {
                feature.subFeatures = feature?.properties?.planned_outages?.map((outage) => {
                    const data = [
                        new PopoverElement().setTitle().setValue(feature.properties.name),
                        new PopoverElement().setLabel('Unit Name').setValue(outage.unit_name),
                        new PopoverElement().setLabel('Outage Type').setValue(outage.outage_type),
                        new PopoverElement().setLabel('Status').setValue(outage.status),
                        new PopoverElement().setLabel('Expected Start Date').setValue(this.datePipe.transform(outage.expected_start_date)),
                        new PopoverElement().setLabel('Last Updated').setValue(this.datePipe.transform(outage.last_updated)),
                        new PopoverElement()
                            .setLabel('Confidence ID')
                            .setValue(`${this.numberPipe.transform(outage.confidence_id, '1.0-0')}`),
                        new PopoverElement().setLabel('Monitored').setValue(outage.monitored.toString()),
                        new PopoverElement()
                            .setLabel('Expected Days in Turnaround')
                            .setValue(`${this.numberPipe.transform(outage.expected_days_in_turnaround, '1.0-0')}`),
                        new PopoverElement().setLabel('Expected End Date').setValue(this.datePipe.transform(outage.expected_end_date)),
                    ];

                    return {
                        id: outage.id,
                        popoverData: data,
                    };
                });
            }

            return feature;
        });

        const style = (feature: any, latlng: LatLng): Layer => {
            return L.marker(latlng, {
                icon: this.getStyle(feature),
            });
        };

        const config = {
            pointToLayer: style,
            onEachFeature: this.initializePopoverInteractions.bind(this),
        };

        const clusterConfig: L.MarkerClusterGroupOptions = {
            iconCreateFunction: getClusterStyle,
            chunkedLoading: true,
        };

        const cluster = new L.MarkerClusterGroup(clusterConfig);
        cluster.addLayer(L.geoJSON(leafletFeatures as any, config as any));

        this.source = cluster as any;
    }

    getStyle(feature: LeafletFeature): any {
        const status = feature.properties.planned_outages[0]?.status;
        const key = `ongOutages-${status}`;
        let style = LayerStyleService.layerStyles.get(key);

        if (!style) {
            const colorKey = {
                'In Progress': {color: '#02bb75'}, // active
                Planned: {color: '#1633b5'}, // future
                Rescheduled: {color: '#1633b5'}, // future
                Specified: {color: '#1633b5'}, // future
                Complete: {color: '#5d5d5d'}, // past
                other: {color: '#5d5d5d'}, // default
            };
            const keyword = colorKey.hasOwnProperty(status);
            const color = keyword ? colorKey[status].color : colorKey['other'].color;

            const options = {
                icon: 'wrench',
                backgroundColor: color,
                borderColor: color,
                innerIconStyle: `color:white; font-size:20px; position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%);`,
                innerIconAnchor: [0, 0],
                iconSize: ['30', '30'],
            };

            style = (L as any).BeautifyIcon.icon(options);

            LayerStyleService.layerStyles.set(key, style);
        }
        return style;
    }
}

function getClusterStyle(cluster: L.MarkerCluster): any {
    const children = cluster.getAllChildMarkers();

    const statusMap = children.map((c) => {
        return c.feature.properties?.planned_outages[0]?.status;
    });

    // If all the status' are of a single type, color accordingly
    let color = '#5d5d5d';
    if (
        statusMap.every((s) => s === 'Planned') ||
        statusMap.every((s) => s === 'Rescheduled') ||
        statusMap.every((s) => s === 'Specified')
    ) {
        color = '#1633b5';
    } else if (statusMap.every((s) => s === 'In Progress')) {
        color = '#02bb75';
    } else if (statusMap.every((s) => s === 'Complete')) {
        color = '#5d5d5d';
    }

    const options = {
        icon: 'circle',
        backgroundColor: color,
        borderColor: color,
        innerIconStyle: `position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); margin-top: 0px; text`,
        iconSize: [30, 30],
        isAlphaNumericIcon: true,
        text: `${cluster.getChildCount()}`,
        textColor: 'white',
    };

    return (L as any).BeautifyIcon.icon(options);
}
