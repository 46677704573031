import {LeafletVectorSource} from '../classes/leaflet-vector-source';
import {LeafletMapLayer} from '../classes/leaflet-map-layer';
import {LeafletFeature} from '../classes/leaflet-feature';
import * as L from 'leaflet';
import {MapService} from '../../map/services/map.service';
import {FeatureStyle} from '../interfaces/style.interface';
import {OngPipeline} from '../classes/ong-pipeline';
import {PopoverConfig, ShowPopoverConfig} from '../../../classes/popover-config';
import {DataInjector} from '../../../services/data.service';
import {LocationSearchService} from '../../map/services/location-search.service';
import {PopoverElement} from '../../../classes/popover-element';
import {MomentDatePipe} from '../../../../shared/pipes/moment-date.pipe';
import {ReplaceCharacterPipe} from '../../../../shared/pipes/replace-character.pipe';
import {TitleCasePipe} from '@angular/common';

export class NgPipelineNoticeSource extends LeafletVectorSource<OngPipeline> {
    public nameFilter: string;
    private pointMarkers: L.Marker[] = [];
    private datePipe = new MomentDatePipe();
    private replacePipe = new ReplaceCharacterPipe();
    private titlePipe = new TitleCasePipe();

    constructor(layerInfo: LeafletMapLayer) {
        super(layerInfo);
    }

    processFeatures(features: any[]): void {
        this.leafletFeatures = features.map((f) => {
            const feature = new LeafletFeature<OngPipeline>(f);
            feature.subFeatures = feature.properties.notices.map((notice) => {
                const priority = this.titlePipe.transform(this.replacePipe.transform(notice.priority)) || 'N/A';

                const data = [
                    new PopoverElement().setTitle().setValue(feature.properties.name),
                    new PopoverElement().setLabel('Priority').setValue(priority),
                    new PopoverElement().setLabel('Subject').setValue(notice.subject),
                    new PopoverElement().setLabel('Effective Date').setValue(this.datePipe.transform(notice.effective_date)),
                ];

                return {
                    id: notice.id,
                    popoverData: data,
                };
            });
            return feature;
        });

        const featureStyle = (geoJsonPoint: LeafletFeature<OngPipeline>): L.Layer => {
            return getStyle(geoJsonPoint.properties) as any;
        };

        const layerPopover = (f: LeafletFeature<OngPipeline>, l) => {
            this.initializePopoverInteractions(f, l, 'click');

            const pointStyle = () => {
                const hasCriticalNotice = f.properties.notices.some((n) => n.priority === 'critical');
                const color = hasCriticalNotice ? '#DC190F' : '#707070';

                const options = {
                    icon: 'circle',
                    backgroundColor: color,
                    borderColor: color,
                    innerIconStyle: `position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); margin-top: 0px; text`,
                    iconSize: [20, 20],
                    isAlphaNumericIcon: true,
                    text: `${f.properties.notices.length}`,
                    textColor: 'white',
                };

                return (L as any).BeautifyIcon.icon(options);
            };

            if (f.properties.notices.length > 1) {
                const marker = L.marker(new L.LatLng(f.properties.longitude, f.properties.latitude), {
                    pane: MapService.layerPaneName,
                    icon: pointStyle(),
                });
                marker.addTo(MapService.mapRef);

                marker.on('click', (event: L.LeafletMouseEvent) => {
                    const showPopover = () => {
                        return (
                            DataInjector.get(MapService).activeIdentifyLayers.length === 0 &&
                            !DataInjector.get(LocationSearchService).drawing
                        );
                    };

                    if (showPopover()) {
                        const data = this.layerInfo.createPopover(f.properties, this.showAllPopoverData);

                        const isInteractive = true;

                        const modalConfig: ShowPopoverConfig = {
                            data,
                            rawData: f.properties,
                            x: event.originalEvent.x,
                            y: event.originalEvent.y,
                            interactive: isInteractive,
                            popoverId: this.layerInfo.customPopoverId,
                        };

                        PopoverConfig.showNewPopover(modalConfig);
                    }
                });

                this.pointMarkers.push(marker);
            }
        };

        this.pointMarkers = [];

        const config = {
            onEachFeature: layerPopover,
            style: featureStyle,
            filter: (feature: LeafletFeature<OngPipeline>) => {
                let ret = true;
                if (this.nameFilter) {
                    ret = feature.properties?.name.toLowerCase().includes(this.nameFilter.toLowerCase());
                }
                return ret;
            },
        };

        this.source = L.geoJSON(this.leafletFeatures as any, config as any);
    }

    /**
     * removes the added points on the map that show the numbers of notices per pipeline.
     */
    public clearPoints() {
        this.pointMarkers.forEach((marker) => {
            marker.removeFrom(MapService.mapRef);
        });
        this.pointMarkers = [];
    }
}

function getStyle(pipeline: OngPipeline): FeatureStyle {
    const hasCriticalNotice = pipeline.notices.some((n) => n.priority === 'critical');

    return {
        color: hasCriticalNotice ? '#DC190F' : '#707070',
        weight: 3,
        fillColor: '#000000',
        fillOpacity: 1,
    };
}
