<div class="container">
    <div #header class="widget-header">
        <p class="title">RRCC Deployment Locations</p>
    </div>

    <div #content class="widget-content">
        <div
            *ngFor="let location of deployedLocations | slice : startIndex : endIndex"
            class="rrcc-location clickable"
            (click)="openDetailModal(location)">
            <p
                #locationName
                class="ellipsis"
                [matTooltip]="location.name"
                [matTooltipDisabled]="!hasEllipsis(locationName)"
                matTooltipPosition="above"
                matTooltipShowDelay="250">
                {{ location.name }}
            </p>
            <mat-icon class="icon" fontSet="fas" fontIcon="fa-info-circle" inline></mat-icon>
        </div>
    </div>

    <div #footer class="action">
        <mat-paginator [pageSize]="pageSize" hidePageSize showFirstLastButtons></mat-paginator>
    </div>
</div>
