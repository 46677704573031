import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Report} from '../../classes/report';
import {ReportService} from '../../services/report.service';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {HttpInterceptorService} from 'frontend/src/app/services/http-interceptor.service';
import {tap} from 'rxjs/operators';
import {MapOptions} from '../../../map/classes/map-options';
import {LeafletMapLayer} from '../../../layer/classes/leaflet-map-layer';
import {LayerService} from '../../../layer/services/layer.service';
import {CustomPopover} from '../../../layer/enum/custom-popover';
import {OngNominationsSource} from '../../../layer/sources/ong-nominations-source';
import * as moment from 'moment';
import {ApplicationConfig} from 'frontend/src/app/classes/application-config';
import {State} from '../../../outage/classes/state';
import {Router} from '@angular/router';
import {IReportNavigationConfig} from '../../../../interfaces/report-navigation-config.interface';
import {ReportFilter} from '../../enums/report-filter.enum';
import {Nomination} from '../../../../classes/ong/nomination';
import {FileDownload} from 'frontend/src/app/classes/file-download';
import {OngService} from '../../../../services/ong.service';

interface CapacityInfo {
    value: string;
    display: string;
}

@Component({
    selector: 'eaglei-nomination-report',
    templateUrl: './nomination-report.component.html',
    styleUrls: ['../reports.scss', './nomination-report.component.scss'],
})
export class NominationReportComponent extends Report<Nomination> implements OnInit, AfterViewInit, OnDestroy {
    // Html Properties
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    // Table Properties
    public readonly columns: string[] = [
        'plantName',
        'pipeline',
        'facility',
        'role',
        'availableCapacity',
        'scheduledCapacity',
        'operationalCapacity',
        'designCapacity',
        'percentCapacity',
        'bestFlowLocation',
        'measurementDate',
    ];

    // Filter Properties
    // We were using other values, That is why the interface was created.
    public nomPercents: CapacityInfo[] = [
        {display: 'Low', value: 'low'},
        {display: 'Medium', value: 'medium'},
        {display: 'High', value: 'high'},
    ];
    public selectedPercents: CapacityInfo[] = this.nomPercents.slice();
    public selectedCapacity: boolean[] = [];
    public selectedStates: State[] = [];
    public selectedRoles: string[] = [];
    public filterRoleList: string[] = [];
    public selectedFacility: string[] = [];
    public filterFacilityList: string[] = [];
    public selectedDate: moment.Moment = moment().startOf('day');
    public minDate: moment.Moment;

    // Map Properties
    private mapLayer: LeafletMapLayer;
    public mapOptions: MapOptions;
    public layerSource: OngNominationsSource;
    public dataLoaded: boolean;
    public showMask: boolean = true;
    // noinspection JSMismatchedCollectionQueryUpdate
    private allFeatures: Nomination[];

    constructor(
        public reportService: ReportService,
        protected ongService: OngService,
        private layerService: LayerService,
        private router: Router
    ) {
        super();
        const routeFilters: IReportNavigationConfig[] = this.router?.getCurrentNavigation()?.extras?.state?.filters;

        this.mapOptions = new MapOptions().setZoom(4, 1).setCenter(37.2, -92);
        this.mapOptions.onlyManualZoom = true;
        this.mapOptions.show = {
            sidebar: false,
            refresh: false,
            export: false,
            coverage: false,
            coordinate: false,
            zoom: true,
            panControl: true,
        };

        let hasState: boolean;
        if (routeFilters) {
            routeFilters.forEach((filter) => {
                switch (filter.type) {
                    case ReportFilter.STATE:
                        this.selectedStates = filter.value as State[];
                        hasState = true;
                        break;
                    case ReportFilter.START_DATE:
                        this.selectedDate = moment(filter.value as string);
                        this.minDate = moment(this.selectedDate).subtract(7, 'days');
                        break;
                    case ReportFilter.CAPACITY:
                        this.selectedCapacity = [true];
                        break;
                }
            });
        }

        if (!hasState) {
            this.getPreferences();
        }
    }

    ngOnInit() {
        this.reportService.getReportData().subscribe((res) => this.initializeReportInfo(res));

        this.layerService.getLayerByHandle('ongNominations').subscribe((layer) => {
            this.mapLayer = layer;
            this.mapLayer.startLoading();
            this.mapLayer.customPopoverId = CustomPopover.ONG_NOMINATIONS;
            this.layerSource = new OngNominationsSource(layer);
            this.getNominations();
        });
    }

    ngAfterViewInit(): void {}

    ngOnDestroy() {
        this.layerSource.layerInfo.endLoading();
    }

    // API calls
    /**
     * turns on the loading message and fetches the relevant nomination status data.
     */
    private getNominations(): void {
        // We cannot run the method without the map layer being set, since we need to pull the url from the map layer
        if (!this.mapLayer) {
            return;
        }

        this.dataLoaded = false;
        this.showMask = true;

        HttpInterceptorService.clearInterceptor(this.uiHandle);
        HttpInterceptorService.pendingRequests[this.uiHandle] = this.ongService
            .getNominations(this.selectedDate)
            .pipe(tap(() => HttpInterceptorService.deleteFromInterceptor(this.uiHandle)))
            .subscribe((res) => {
                this.allFeatures = res;
                this.initializeData(res);

                this.dataLoaded = true;
                this.showMask = res.length === 0;

                // Update map
                this.filterNominationMap();
            });
    }

    // Table Methods
    /**
     * Sets the data used in the table and also creates all the interactions on the table
     * @param data A list of recent events to be in the table.
     */
    private initializeData(data: Nomination[]): void {
        if (this.dataSource) {
            this.dataSource.data = data;
        } else {
            this.dataSource = new MatTableDataSource(data);
            this.dataSource.filterPredicate = this.filterPredicate.bind(this);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sortingDataAccessor = this.dataAccessor.bind(this);
            this.dataSource.sort = this.sort;
        }

        // Get Known Roles and Facilities from data
        this.dataSource.data.forEach((d) => {
            if (!this.filterRoleList.includes(d.location.role)) {
                this.filterRoleList.push(d.location.role);
            }

            if (!this.filterFacilityList.includes(d.facility)) {
                this.filterFacilityList.push(d.facility);
            }
        });

        this.selectedFacility = [...this.filterFacilityList];
        this.selectedRoles = [...this.filterRoleList];

        this.filterNominations(this.dataSource.filter);
    }

    /**
     * The logic behind how the table is filtered, is called when the filter property on the dataSource is changed.
     * @param data The recent event being tested.
     * @param text The text being searched for.
     */
    private filterPredicate(data: Nomination, text: string): boolean {
        let nomPercentCapCheck = false;
        let nomStateCheck = false;
        let nomOverCapCheck = true;
        let nomRoleCheck = false;
        let nomFacilityCheck = false;
        let nomPipelineNameCheck = true;

        if (this.selectedPercents.length > 0) {
            const percentage = this.getPercentage(data);
            nomPercentCapCheck = this.selectedPercents.map((v) => v.value).includes(percentage);
        }

        if (this.selectedCapacity.length > 0 && !(data.location.role === 'RECEIPT' || data.location.role === 'DELIVERY')) {
            nomOverCapCheck = false;
        } else if (this.selectedCapacity.length > 0 && (data.location.role === 'RECEIPT' || data.location.role === 'DELIVERY')) {
            nomOverCapCheck = this.selectedCapacity.includes(data.overCapacity);
        }

        if (this.selectedStates.length > 0) {
            nomStateCheck = this.selectedStates.find((r) => r.name === data.location.state) !== undefined;
        }

        if (this.selectedRoles.length > 0) {
            nomRoleCheck = this.selectedRoles.find((r) => r === data.location.role) !== undefined;
        }

        if (this.selectedFacility.length > 0) {
            nomFacilityCheck = this.selectedFacility.find((f) => f === data.facility) !== undefined;
        }

        if (text.trim().length > 0) {
            nomPipelineNameCheck = data.pipeline_name.toLowerCase().includes(text.toLowerCase());
        }

        return nomPercentCapCheck && nomStateCheck && nomOverCapCheck && nomRoleCheck && nomFacilityCheck && nomPipelineNameCheck;
    }

    // noinspection JSMethodCanBeStatic
    /**
     * The logic behind how the columns are sorted.
     * @param data The recent event being sorted
     * @param header The column name being sorted on.
     */
    private dataAccessor(data: Nomination, header: string): string | number {
        switch (header) {
            case 'plantName':
                return data.location.name.toLowerCase();
            case 'pipeline':
                return data.pipeline_name.toLowerCase();
            case 'facility':
                return data.facility.toLowerCase();
            case 'role':
                return data.location.role.toLowerCase();
            case 'availableCapacity':
                return data.available_capacity;
            case 'scheduledCapacity':
                return data.scheduled_capacity;
            case 'operationalCapacity':
                return data.operational_capacity;
            case 'designCapacity':
                return data.design_capacity;
            case 'percentCapacity':
                return this.getPercentage(data);
            case 'measurementDate':
                return Report.momentPipe.transform(data.gas_day, 'M/D/YYYY');
            default:
                return '';
        }
    }

    // Filter Nominations
    /**
     * Called when the percentage dropdown is updated and fetches new data.
     * @param value The new nominations array
     */
    public updatePercentage(value: CapacityInfo[]): void {
        this.selectedPercents = value;
        this.filterNominations(this.dataSource.filter);
        this.filterNominationMap();
    }

    // Filter Nominations
    /**
     * Called when the percentage dropdown is updated and fetches new data.
     * @param value The new nominations array
     */
    public updateCapacity(value: boolean[]): void {
        this.selectedCapacity = value;
        this.filterNominations(this.dataSource.filter);
        this.filterNominationMap();
    }

    // Filter Nominations
    /**
     * Called when the locations dropdown is updated and fetches new data.
     * @param value The new nominations array
     */
    public updateLocations(value: State[]): void {
        this.selectedStates = value;
        this.filterNominations(this.dataSource.filter);
        this.filterNominationMap();
    }

    /**
     * Called when the role dropdown is updated and fetches new data.
     * @param value The new nominations array
     */
    public updateRoles(value: string[]): void {
        this.selectedRoles = value;
        this.filterNominations(this.dataSource.filter);
        this.filterNominationMap();
    }

    /**
     * Called when the facility dropdown is updated and fetches new data.
     * @param value The new nominations array
     */
    public updateFacilities(value: string[]): void {
        this.selectedFacility = value;
        this.filterNominations(this.dataSource.filter);
        this.filterNominationMap();
    }

    public updatePipelineSearch(value: string): void {
        this.filterNominations(value);
        this.filterNominationMap();
    }

    // Filter Nominations
    /**
     * Called when the end date is updated and fetches new data.
     * @param date The values that will be used to filter gas_day
     */
    public updateDateRange(date: moment.Moment): void {
        // reset ui
        this.dataSource.data = [];
        this.layerSource.clearMap();
        this.mapLayer.startLoading();

        this.selectedDate = date;
        this.getNominations();
    }

    private getPreferences() {
        const preferences = ApplicationConfig.currentUserPreferences.getValue();
        this.selectedStates = preferences.getStates();
    }

    // Filter Nominations
    /**
     * Called when the nominations dropdown is updated and fetches new data.
     */
    public filterNominationMap(): void {
        const ids = this.dataSource.filteredData.map((n) => n.id);
        const filtered = this.allFeatures.filter((n) => ids.includes(n.id));

        this.layerSource.clearMap();
        this.layerSource.processFeatures(filtered);
        this.layerSource.changeOpacity(0.8);
        this.layerSource.addToMap(true);
    }

    /**
     * sets the filter on the dataSource and triggers the table filter.
     * This should be called by all filter update methods.
     * @param text The text being passed to the dataSource filter.
     */
    public filterNominations(text: string): void {
        text = text === '' ? ' ' : text;

        this.dataSource.filter = text;
    }

    // Loading Text
    /**
     * Sets the loading or no data message
     */
    public getMaskText(): string {
        return this.dataLoaded ? 'No nominations found matching filters' : 'Loading...';
    }

    // Export Methods
    /**
     * Exports the table as a CSV File
     */
    public exportTable(): void {
        let data: string =
            [
                'Location Name',
                'Pipeline Name',
                'Facility Type',
                'Role',
                'Available Capacity (MMBTU)',
                'Scheduled Capacity (MMBTU)',
                'Operational Capacity (MMBTU)',
                'Design Capacity (MMBTU)',
                'Percent Capacity',
                'Best Flow Location',
                'Measurement Date',
            ].join() + '\n';

        // Each Region
        this.dataSource.filteredData.forEach((noms) => {
            const info = [
                FileDownload.formatCsvCell(noms.location.name),
                FileDownload.formatCsvCell(noms.pipeline_name),
                FileDownload.formatCsvCell(noms.facility),
                FileDownload.formatCsvCell(noms.location.role),
                FileDownload.formatCsvCell(noms.available_capacity),
                FileDownload.formatCsvCell(noms.scheduled_capacity),
                FileDownload.formatCsvCell(noms.operational_capacity),
                FileDownload.formatCsvCell(noms.design_capacity),
                FileDownload.formatCsvCell(this.getPercentage(noms)),
                FileDownload.formatCsvCell(noms.best_flow_location?.toString()),
                FileDownload.formatCsvCell(noms.gas_day.format()),
            ];
            data += `${info.join()}\n`;
        });

        FileDownload.downloadCSV('nominationReport', data, this.attributionUrl);
    }

    // Percentage Capacity
    /**
     * Calculates the percentage capacity for each nomination
     */
    public getPercentage(feature: Nomination): string {
        return feature.utilization_status;
    }
}
