import {LeafletVectorSource} from '../classes/leaflet-vector-source';
import {LeafletMapLayer} from '../classes/leaflet-map-layer';
import {LeafletFeature} from '../classes/leaflet-feature';
import * as L from 'leaflet';
import {LatLng, Layer} from 'leaflet';
import {LayerStyleService} from '../services/layer-style.service';
import {RefineryStatus} from '../../report/classes/refinery-status';
import {PopoverElement} from '../../../classes/popover-element';
import {DecimalPipe} from '@angular/common';

export class OngRefinerySource extends LeafletVectorSource<RefineryStatus> {
    private numberPipe = new DecimalPipe('en-us');

    constructor(layerInfo: LeafletMapLayer) {
        super(layerInfo);
    }

    processFeatures(features: any[] | any | undefined): void {
        this.leafletFeatures = features.map((f) => {
            const feature = new LeafletFeature<RefineryStatus>(f).buildPopover(this.layerInfo);

            const data = [
                new PopoverElement().setTitle().setValue(feature.properties.name),
                new PopoverElement()
                    .setLabel('Total Capacity')
                    .setValue(`${this.numberPipe.transform(feature.properties.total_capacity, '1.0-0')}  bpd`),
                new PopoverElement()
                    .setLabel('Impacted Capacity')
                    .setValue(`${this.numberPipe.transform(feature.properties.offline_capacity, '1.0-0')}  bpd`),
                new PopoverElement()
                    .setLabel('Impacted Percent')
                    .setValue(`${((feature.properties.offline_capacity / feature.properties.total_capacity) * 100).toFixed(2)} %`),
            ];

            feature.subFeatures = [
                {
                    id: feature.properties.facility_id,
                    popoverData: data,
                },
            ];

            return feature;
        });

        const style = (feature: any, latlng: LatLng): Layer => {
            return L.marker(latlng, {
                icon: getStyle(feature),
            });
        };

        const config = {
            pointToLayer: style,
            onEachFeature: this.initializePopoverInteractions.bind(this),
        };

        // const clusterConfig: L.MarkerClusterGroupOptions = {
        //   iconCreateFunction: getClusterStyle,
        //   chunkedLoading: true
        // };

        // const cluster = new L.MarkerClusterGroup(clusterConfig);
        // cluster.addLayer(L.geoJSON(this.leafletFeatures as any, config as any));

        // this.source = cluster as any;
        this.source = L.geoJSON(this.leafletFeatures as any, config as any);
    }

    public changeOpacity(opacity: number): void {
        if (!this.source) {
            return;
        }

        this.layerInfo.opacity = opacity;
        this.source.getLayers().forEach((l) => {
            (l as any).setOpacity(opacity);
        });
    }
}

function getStyle(feature: LeafletFeature): any {
    const status =
        feature.properties.total_units === feature.properties.online_units
            ? 'online'
            : feature.properties.total_units === feature.properties.offline_units
            ? 'offline'
            : 'impacted';
    const radius = getRadius(feature.properties.total_capacity);
    const key = `ogcRefinery-${radius.size}-${status}`;
    let style = LayerStyleService.layerStyles.get(key);

    if (!style) {
        const color = status === 'online' ? '#6dbd28' : status === 'offline' ? '#e72724' : '#e9dc00';
        const options = {
            icon: status === 'online' ? 'check' : status === 'offline' ? 'ban' : 'exclamation-triangle',
            backgroundColor: color,
            borderColor: color,
            innerIconStyle: `color:white; font-size:${radius.fontSize}px; position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%);`,
            innerIconAnchor: [0, 0],
            iconSize: [radius.size, radius.size],
        };

        style = (L as any).BeautifyIcon.icon(options);

        LayerStyleService.layerStyles.set(key, style);
    }
    return style;
}

function getRadius(capacity: number): any {
    if (capacity > 250_000) {
        return {
            size: 40,
            fontSize: 20,
        };
    } else if (capacity > 110_000) {
        return {
            size: 35,
            fontSize: 18,
        };
    } else if (capacity > 50_000) {
        return {
            size: 30,
            fontSize: 15,
        };
    } else {
        return {
            size: 25,
            fontSize: 12,
        };
    }
}

// TODO: Remove if certain it should not be clustered
// function getClusterStyle(cluster: L.MarkerCluster): any {
//   const children = cluster.getAllChildMarkers();
//   const statuses = children.map((c: any) => {
//     const data = (c.feature as LeafletFeature<RefineryStatus>).properties;
//     let stat = '';
//     if (data.online_units === data.total_units) {
//       stat = 'Online';
//     } else if (data.offline_units === data.total_units) {
//       stat = 'Offline';
//     } else {
//       stat = 'Impacted';
//     }

//     return stat;
//   });

//   // If all the status' are of a single type, color accordingly
//   let color = '#5d5d5d';
//   if (statuses.every(s => s === 'Online')) {
//     color = '#6dbd28';
//   } else if (statuses.every(s => s === 'Offline')) {
//     color = '#e72724';
//   } else if (statuses.every(s => s === 'Impacted')) {
//     color = '#e9dc00';
//   }

//   const options = {
//     icon: 'circle',
//     backgroundColor: color,
//     borderColor: color,
//     innerIconStyle: `position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); margin-top: 0px; text`,
//     iconSize: [30, 30],
//     isAlphaNumericIcon: true,
//     text: `${cluster.getChildCount()}`,
//     textColor: 'white'
//   };

//   return (L as any).BeautifyIcon.icon(options);
// }
