import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import * as esri from 'esri-leaflet';
import {ApplicationConfig} from 'frontend/src/app/classes/application-config';
import {ModalConfig} from 'frontend/src/app/classes/modal-config';
import {PopoverConfig} from 'frontend/src/app/classes/popover-config';
import {DataService} from 'frontend/src/app/services/data.service';
import {AutoUpdate} from 'frontend/src/shared/classes/auto-update';
import {DataInfoComponent} from 'frontend/src/shared/modals/data-info/data-info.component';
import * as L from 'leaflet';
import {BehaviorSubject} from 'rxjs';
import {filter} from 'rxjs/operators';
import {IPoint} from '../../../layer/interfaces/point.interface';
import {MapOptions} from '../../../map/classes/map-options';
import {CoveredUtilityModalComponent} from '../../../map/modals/covered-utility-modal/covered-utility-modal.component';
import {IdentifyService} from '../../../map/services/identify.service';
import {MapService} from '../../../map/services/map.service';

@Component({
    selector: 'eaglei-map-lite',
    templateUrl: './map-lite.component.html',
    styleUrls: ['./map-lite.component.scss'],
})
export class MapLiteComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('leafletMap') mapElement: ElementRef<HTMLElement>;

    @Input() hideFilter = false;
    @Input() hideBetaBanner = false;

    @Input() mapOptions: MapOptions = new MapOptions();
    @Output() mapClick: EventEmitter<any> = new EventEmitter<any>();

    // HTML Elements
    public scaleElement: HTMLDivElement;
    private layerPane: HTMLDivElement;

    // Map Properties
    private mapReference: L.Map;
    private baseLayer: any;
    public location: IPoint;
    public showMouseCoordinates: boolean = true;

    // AutoUpdate
    private autoUpdate = new AutoUpdate(this.updateDateRange.bind(this)).setAutoUpdate(true);

    // Lite Properties
    public sidebarOpen: boolean;
    public isFullscreen: boolean = false;
    public isLoading = false;
    public toggleSidebar: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);

    constructor(public mapService: MapService, public identifyService: IdentifyService, private dialog: MatDialog) {
        this.mapService.baseLayer.pipe(filter(() => !!this.mapReference)).subscribe((name) => {
            if (this.baseLayer) {
                this.mapReference.eachLayer((layer: any) => {
                    if (layer.options.baseLayerName) {
                        layer.removeFrom(this.mapReference);
                    }
                });
            }

            name.split(',').forEach((l) => {
                this.baseLayer = esri.basemapLayer(l as any, {
                    attribution: undefined,
                });

                this.baseLayer.options.baseLayerName = 1;

                this.baseLayer.addTo(this.mapReference);
                this.baseLayer.bringToBack();
            });
        });
    }

    public ngOnInit(): void {
        this.sidebarOpen = this.mapOptions.show.sidebar;
    }

    public ngAfterViewInit(): void {
        this.initializeMap();
        this.scaleElement = document.body.getElementsByClassName('leaflet-control-scale')[0] as HTMLDivElement;
        this.scaleElement.classList.add('moveable');

        if (this.sidebarOpen) {
            this.scaleElement.classList.add('slide-out');
        }
    }

    public ngOnDestroy(): void {
        PopoverConfig.hideNewPopover();
        this.autoUpdate.clear();
        this.identifyService.identifyGroup.clearLayers();
    }

    /**
     * Updates the date range and redraws the layers
     */
    private updateDateRange(): void {
        const dates = this.mapService.mapDateRange.getValue();
        const last = ApplicationConfig.roundMinute().subtract(15, 'minutes');

        if (dates.endDate.isSame(last, 'minute')) {
            dates.endDate = ApplicationConfig.roundMinute();
        }

        this.mapService.mapDateRange.next(dates);

        if (dates.endDate.isSameOrAfter(this.mapService.lastRefreshTime)) {
            this.mapService.lastRefreshTime = dates.endDate;
        }

        this.mapService.reloadLayerData.next(true);
    }

    /**
     * Update the Z-Index for panels
     */
    private updatePaneZIndex(): void {
        this.layerPane = this.mapReference.getPane(MapService.layerPaneName) as HTMLDivElement;
        this.layerPane.style.zIndex = '650';
        this.layerPane.style.height = '100% !important';
        this.mapReference.getPane('nomPane').style.zIndex = '649';
        this.mapReference.getPane('tools').style.zIndex = '800';
        this.mapReference.getPane('popupPane').style.zIndex = '900';
        this.mapReference.getPane('tooltipPane').style.zIndex = '1000';
    }

    /**
     * Initializes the Map
     */
    private initializeMap(): void {
        this.mapReference = L.map(this.mapElement.nativeElement, {
            center: this.mapOptions.defaultCenter,
            zoom: this.mapOptions.defaultZoom,
            crs: L.CRS.EPSG3857,
            zoomControl: false,
            attributionControl: false,
            minZoom: this.mapOptions.minZoom,
        });

        MapService.mapRef = this.mapReference;
        this.mapReference.createPane('nomPane');
        this.mapReference.createPane(MapService.layerPaneName);
        this.mapReference.createPane('tools');

        this.updatePaneZIndex();

        L.control.attribution({prefix: ''}).addTo(this.mapReference);

        this.mapService.baseLayer.next('Topographic');

        this.identifyService.identifyGroup.addTo(this.mapReference);
        this.identifyService.identifyGroup.setZIndex(800);

        if (this.mapOptions.onlyManualZoom) {
            this.mapReference.scrollWheelZoom.disable();
            this.mapReference.doubleClickZoom.disable();
        }

        if (this.mapOptions.show.panControl) {
            this.mapReference.dragging.disable();
        }

        this.initializeMapInteractions();
    }

    /**
     * Initializes the map interactions
     */
    private initializeMapInteractions(): void {
        this.mapReference.on('layeradd', () => {
            this.identifyService.identifyGroup.bringToFront();
        });

        this.mapReference.on('mousemove', (event: L.LeafletMouseEvent) => {
            this.location = {
                latitude: event.latlng.lat,
                longitude: event.latlng.lng,
            };
        });

        this.mapReference.on('click', (event: L.LeafletMouseEvent) => {
            this.mapClick.emit(event.latlng);
        });

        L.control.scale({metric: false}).addTo(this.mapReference);
    }

    /**
     * Zoom to CONUS
     */
    public zoomToHome(): void {
        this.mapReference.flyToBounds(ApplicationConfig.conus.getBounds(), {});
    }

    /**
     * Checks if using mobile devices
     */
    public useMobileLayout(): boolean {
        return ApplicationConfig.useMobileLayout();
    }

    /**
     * Opens the Data Info Modal
     */
    public getSourceData() {
        this.mapService.getSourceData().subscribe((res) => {
            const ref = this.dialog.open(DataInfoComponent, {
                data: {sources: res, hasType: true, name: 'Map'},
                maxWidth: '100vw',
                maxHeight: '100vh',
                height: '100vh',
                width: ModalConfig.getModalWidth(1000, true),
            });

            ref.afterOpened().subscribe(() => (this.showMouseCoordinates = false));
            ref.afterClosed().subscribe(() => (this.showMouseCoordinates = true));
        });
    }

    /**
     * Opens the Covered Utilities Modal
     */
    public getCoveredUtilities() {
        const opts = {
            data: this.mapService.nomActiveStates.getValue(),
            autoFocus: false,
            maxWidth: '100vw',
            maxHeight: '100vh',
            height: '100vh',
            width: ModalConfig.getModalWidth(1000, true),
        };

        this.dialog.open(CoveredUtilityModalComponent, opts);
    }

    /**
     * Toggles the sidebar through map control
     */
    public sidebarChanged(): void {
        this.toggleSidebar.next(undefined);
    }

    /**
     * Toggles the Map to fullscreen
     */
    public toggleFullscreen(): void {
        this.isFullscreen = !this.isFullscreen;
        setTimeout(() => {
            this.mapReference.invalidateSize();
            if (this.mapService.nomActiveStates.getValue().length === DataService.states.getValue().length) {
                this.zoomToHome();
            }
        }, 400);
    }
}
