import {GenMapLayerCategory} from '../../../../../generated/serverModels/GenMapLayerCategory';
import {MapLayerGroup} from './map-layer-group';

export class MapLayerCategory extends GenMapLayerCategory {
    public groups: MapLayerGroup[];

    constructor(category?: MapLayerCategory) {
        super(category);

        if (category) {
            this.groups = category.groups.map((group) => new MapLayerGroup(group));
        }
    }
}
