import {Component} from '@angular/core';
import {LayerService} from '../../services/layer.service';
import {GasPriceSource} from '../../sources/gas-price-source';
import {map} from 'rxjs/operators';
import {EagleILayer} from '../../classes/eagle-i-layer';
import * as moment from 'moment';
import {GasPrice} from '../../classes/gas-price';
import {IFilterChange} from '../../interfaces/filter-change.interface';
import {MapService} from '../../../map/services/map.service';
import {HttpInterceptorService} from '../../../../services/http-interceptor.service';

@Component({
    selector: 'eaglei-gas-price-layer',
    templateUrl: './gas-price-layer.component.html',
    styleUrls: ['./gas-price-layer.component.scss'],
})
export class GasPriceLayerComponent extends EagleILayer<GasPriceSource> {
    public showMask: boolean;
    public startDate: moment.Moment;
    public endDate: moment.Moment;

    constructor(private layerService: LayerService, private mapService: MapService) {
        super();
    }

    afterViewInit() {}

    toggleLayer(activeState: boolean): void {
        if (activeState) {
            if (!this.layerSource) {
                this.layerSource = new GasPriceSource(this.mapLayer);
            }

            this.getPrices();
        } else {
            this.layerSource.removeFromMap();
        }
    }

    private getPrices(): void {
        this.endDate = this.mapService.mapDateRange.getValue().endDate;
        this.startDate = this.endDate.clone().subtract(1, 'day');
        const url = `${this.mapLayer.serviceurl}?end=${this.endDate.utc().format()}`;

        HttpInterceptorService.clearInterceptor(this.mapLayer.interceptorKey);
        HttpInterceptorService.pendingRequests[this.mapLayer.interceptorKey] = this.layerService
            .getJsonFromUrl(url)
            .pipe(map((prices: any[]) => prices.map((price) => new GasPrice(price))))
            .subscribe((res) => {
                HttpInterceptorService.deleteFromInterceptor(this.mapLayer.interceptorKey);
                if (res.length === 0) {
                    this.showMask = true;
                    return;
                }
                this.showMask = false;
                this.layerSource.processFeatures(res);
                this.legend = this.layerSource.buildLegend();
                this.layerSource.addToMap();
            });
    }

    updateFilter(event: IFilterChange) {
        this.layerSource.updateStyle(event.value[0].value);
    }
}
