
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import { GenPopoverDataType } from './GenPopoverDataType';
import { GenPopoverPipeType } from './GenPopoverPipeType';

export class GenMapLayerPopover {

    id: number;
    display: string;
    layerId: number;
    ordering: number;
    pipe: GenPopoverPipeType;
    propertyName: string;
    suffix: string;
    textFormat: string;
    title: boolean;
    type: GenPopoverDataType;
    valueFormat: string;

    constructor();
    constructor( jsonObj: any );
    constructor( genMapLayerPopover: GenMapLayerPopover);
    constructor( id: number );

    constructor( input?: GenMapLayerPopover|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            this.display = input.display; // string [string]
            this.layerId = input.layerId; // int [number]
            this.ordering = input.ordering; // int [number]
            this.pipe = (typeof input.pipe === 'string') ? GenPopoverPipeType.forName(<string> input.pipe) : <any> input.pipe; // GenPopoverPipeType
            this.propertyName = input.propertyName; // string [string]
            this.suffix = input.suffix; // string [string]
            this.textFormat = input.textFormat; // string [string]
            this.title = input.title; // boolean [boolean]
            this.type = (typeof input.type === 'string') ? GenPopoverDataType.forName(<string> input.type) : <any> input.type; // GenPopoverDataType
            this.valueFormat = input.valueFormat; // string [string]
       }
    }

    static jsonResponseToList(arr: any[]): GenMapLayerPopover[] {
        if (arr && arr.map) {
          return arr.map(a => new GenMapLayerPopover(a));
        } else {
          return [];
        }
    }
}

