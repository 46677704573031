import {AfterViewInit, Component, ElementRef} from '@angular/core';
import {BaseWidget} from '../../classes/base-widget';
import {EventSummaryFilters} from '../../../system-event/classes/event-summary-filters';
import * as moment from 'moment';
import {RefineryStatus} from '../../../report/classes/refinery-status';
import {map} from 'rxjs/operators';
import {GenWidgetSettingType} from '../../../../../../generated/serverModels/GenWidgetSettingType';
import {IReportNavigationConfig} from '../../../../interfaces/report-navigation-config.interface';
import {ReportFilter} from '../../../report/enums/report-filter.enum';
import {Router} from '@angular/router';
import {OngService} from '../../../../services/ong.service';
import {State} from '../../../outage/classes/state';

type CapacityTypeSetting = 'total' | 'max';

@Component({
    selector: 'eaglei-oil-capacity-widget',
    templateUrl: './oil-capacity-widget.component.html',
    styleUrls: ['./oil-capacity-widget.component.scss'],
})
export class OilCapacityWidgetComponent extends BaseWidget implements AfterViewInit {
    public capacityLost: number;
    public maxLossRefinery: RefineryStatus;

    private measurementDate: moment.Moment;
    private aggregation: CapacityTypeSetting;
    private states: State[];

    constructor(private widgetElement: ElementRef, private router: Router, private ongService: OngService) {
        super(widgetElement);
    }

    ngAfterViewInit() {
        if (!this.eventDashboard) {
            this.states = this.userPreferences.states;
            this.getData();
        }
    }

    /**
     * this method is called as part of the base class ngOnDestroy to allow for each component to teardown any extra
     * properties needed.
     */
    destroyWidget() {}

    protected handleEventFilterChange(eventFilters: EventSummaryFilters) {
        this.measurementDate = eventFilters.date.clone();
        this.states = eventFilters.locations;
        this.aggregation = this.item.detail.getSettingByType(GenWidgetSettingType.ONG_REFINERY_CAPACITY, 'total')
            .value as CapacityTypeSetting;

        this.getData();
    }

    private getData(): void {
        const onSuccess = (res: RefineryStatus[]) => {
            let totalCapacity = 0;
            let capacityLoss = 0;
            let maxLoss = 0;
            let maxLossRefinery: RefineryStatus;

            res.forEach((refinery) => {
                if (this.aggregation === 'total') {
                    totalCapacity += refinery.total_capacity;
                    capacityLoss += refinery.offline_capacity ? refinery.offline_capacity : 0;
                } else if (this.aggregation === 'max') {
                    capacityLoss = refinery.total_capacity - refinery.online_capacity;
                    if (capacityLoss > maxLoss) {
                        maxLoss = capacityLoss;
                        maxLossRefinery = refinery;
                    }
                }
            });

            this.capacityLost = this.aggregation === 'total' ? capacityLoss : maxLoss;
            this.maxLossRefinery = maxLossRefinery;
        };

        this.ongService
            .getRefineryStatus(this.measurementDate, this.states)
            .pipe(map((res) => res.features.map((d) => new RefineryStatus(d.properties as any))))
            .subscribe(onSuccess);
    }

    /**
     * Navigates to the Oil Refinery status report and sets the date on the dashboard as the start date in the report.
     */
    public goToReport(): void {
        const filters: IReportNavigationConfig[] = [
            {
                type: ReportFilter.START_DATE,
                value: this.measurementDate.format(),
            },
        ];
        // noinspection JSIgnoredPromiseFromCall
        this.router.navigate(['app/reports/refineryStatus'], {state: filters});
    }

    /**
     * Checks the current settings for the widget and returns the title to be displayed
     */
    public getTitle(): string {
        return this.aggregation === 'total' ? 'Total Capacity Lost' : 'Max Capacity Lost';
    }
}
