import {FileDownload} from '../../../classes/file-download';
import {Report} from '../../../modules/report/classes/report';
import {HeadoutTableData} from '../interfaces/headout-table-data.interface';
import {StateNamePipe} from '../../../../shared/pipes/state-name.pipe';

export const exportHeadoutAsCsv = (tableData: HeadoutTableData[], fileName: string, attributionUrl: string) => {
    let data = '';
    const statePipe = new StateNamePipe();
    const headerColumns = ['State/Territory', 'County', 'Predicted Customers Out', 'Total Customers', 'Predicted Percent Out'];
    data += headerColumns.join() + '\n';

    tableData.forEach((val) => {
        const values = [
            FileDownload.formatCsvCell(statePipe.transform(val.state)),
            FileDownload.formatCsvCell(val.county),
            FileDownload.formatCsvCell(val.customersOut),
            FileDownload.formatCsvCell(val.totalCustomers),
            FileDownload.formatCsvCell(Report.percentPipe.transform(val.percentOut, '1.2-2')),
        ];
        data += values.join();
        data += '\n';
    });

    FileDownload.downloadCSV(fileName, data, attributionUrl);
};
