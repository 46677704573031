
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

export class GenSystemEventPhaseType {
    static readonly PRE: GenSystemEventPhaseType = new GenSystemEventPhaseType('PRE');
    static readonly DURING: GenSystemEventPhaseType = new GenSystemEventPhaseType('DURING');
    static readonly POST: GenSystemEventPhaseType = new GenSystemEventPhaseType('POST');

    public readonly name: string;

    private constructor(name: string) {
        this.name = name;
    }

    static forName(name: string) {
        if (name === 'PRE') return GenSystemEventPhaseType.PRE;
        if (name === 'DURING') return GenSystemEventPhaseType.DURING;
        if (name === 'POST') return GenSystemEventPhaseType.POST;
        throw new Error('Unexpected value for GenSystemEventPhaseType:' + name);
    }

    static values(): GenSystemEventPhaseType[] {
        return [
            GenSystemEventPhaseType.PRE,
            GenSystemEventPhaseType.DURING,
            GenSystemEventPhaseType.POST,
        ];
    }

    toJSON(): string {
        return this.name;
    }

    toString(): string {
        return this.name;
    }
}

