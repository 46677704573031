import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {SystemNews} from '../classes/system-news';
import {SystemNewsType} from '../classes/system-news-type';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root',
})
export class SystemNewsService {
    private readonly url = 'api/systemnews';
    private readonly emailUrl = '/api/emailtoallusers';
    private readonly headers = new HttpHeaders().set('Content-Type', 'application/json');
    private readonly emailHeaders = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    constructor(private http: HttpClient) {}

    public getSystemNews(limit?: number, onlyShowFaq?: boolean, startDate?: string, endDate?: string): Observable<SystemNews[]> {
        const endPoint = onlyShowFaq ? 'FAQ' : 'System News';
        let params = new HttpParams().append('type', endPoint);

        if (limit) {
            params = params.append('limit', limit);
        }
        if (startDate) {
            params = params.append('start', startDate);
        }
        if (endDate) {
            params = params.append('end', endDate);
        }

        return this.http.get<SystemNews[]>(this.url, {params}).pipe(map((news) => news.map((entry) => new SystemNews(entry))));
    }

    public getSystemNewsById(id: number): Observable<SystemNews> {
        const url = `${this.url}/${id}`;
        return this.http.get<SystemNews>(url).pipe(map((news) => new SystemNews(news)));
    }

    public getSystemNewsTypes(): Observable<SystemNewsType[]> {
        const url = 'api/systemnews/types';
        return this.http.get<any[]>(url).pipe(map((types) => types.map((type) => new SystemNewsType(type))));
    }

    public deleteSystemNews(id: number): Observable<any> {
        return this.http.delete<any>(`${this.url}/${id}`);
    }

    public updateSystemNews(pendingUpdates: SystemNews): Observable<SystemNews> {
        const url = `${this.url}/${pendingUpdates.id}`;
        return this.http.put<SystemNews>(url, pendingUpdates, {headers: this.headers}).pipe(map((res) => new SystemNews(res)));
    }

    public createSystemNews(news: SystemNews): Observable<SystemNews> {
        return this.http.post<SystemNews>(this.url, news, {headers: this.headers}).pipe(map((res) => new SystemNews(res)));
    }

    public emailUpdatedNews(news: SystemNews): void {
        const body = `subject=${news.title}&body=${news.value}`;
        this.http.post(this.emailUrl, body, {headers: this.emailHeaders});
    }
}
