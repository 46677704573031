
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import * as moment from 'moment';

export class GenGasPrice {

    id: number;
    boundary: string;
    collectionDate: moment.Moment;
    dieselCurrentAverage: number;
    dieselMonthAgoAverage: number;
    dieselWeekAgoAverage: number;
    dieselYearAgoAverage: number;
    dieselYesterdayAverage: number;
    midgradeCurrentAverage: number;
    midgradeMonthAgoAverage: number;
    midgradeWeekAgoAverage: number;
    midgradeYearAgoAverage: number;
    midgradeYesterdayAverage: number;
    premiumCurrentAverage: number;
    premiumMonthAgoAverage: number;
    premiumWeekAgoAverage: number;
    premiumYearAgoAverage: number;
    premiumYesterdayAverage: number;
    runId: number;
    state: string;
    unleadedCurrentAverage: number;
    unleadedMonthAgoAverage: number;
    unleadedWeekAgoAverage: number;
    unleadedYearAgoAverage: number;
    unleadedYesterdayAverage: number;

    constructor();
    constructor( jsonObj: any );
    constructor( genGasPrice: GenGasPrice);
    constructor( id: number );

    constructor( input?: GenGasPrice|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            this.boundary = input.boundary; // string [string]
            if ( input.collectionDate && typeof input.collectionDate === 'string' ) {
                this.collectionDate = moment(input.collectionDate).utc();
            } else {
                this.collectionDate = input.collectionDate;
            }
            this.dieselCurrentAverage = input.dieselCurrentAverage; // float [number]
            this.dieselMonthAgoAverage = input.dieselMonthAgoAverage; // float [number]
            this.dieselWeekAgoAverage = input.dieselWeekAgoAverage; // float [number]
            this.dieselYearAgoAverage = input.dieselYearAgoAverage; // float [number]
            this.dieselYesterdayAverage = input.dieselYesterdayAverage; // float [number]
            this.midgradeCurrentAverage = input.midgradeCurrentAverage; // float [number]
            this.midgradeMonthAgoAverage = input.midgradeMonthAgoAverage; // float [number]
            this.midgradeWeekAgoAverage = input.midgradeWeekAgoAverage; // float [number]
            this.midgradeYearAgoAverage = input.midgradeYearAgoAverage; // float [number]
            this.midgradeYesterdayAverage = input.midgradeYesterdayAverage; // float [number]
            this.premiumCurrentAverage = input.premiumCurrentAverage; // float [number]
            this.premiumMonthAgoAverage = input.premiumMonthAgoAverage; // float [number]
            this.premiumWeekAgoAverage = input.premiumWeekAgoAverage; // float [number]
            this.premiumYearAgoAverage = input.premiumYearAgoAverage; // float [number]
            this.premiumYesterdayAverage = input.premiumYesterdayAverage; // float [number]
            this.runId = input.runId; // int [number]
            this.state = input.state; // string [string]
            this.unleadedCurrentAverage = input.unleadedCurrentAverage; // float [number]
            this.unleadedMonthAgoAverage = input.unleadedMonthAgoAverage; // float [number]
            this.unleadedWeekAgoAverage = input.unleadedWeekAgoAverage; // float [number]
            this.unleadedYearAgoAverage = input.unleadedYearAgoAverage; // float [number]
            this.unleadedYesterdayAverage = input.unleadedYesterdayAverage; // float [number]
       }
    }

    static jsonResponseToList(arr: any[]): GenGasPrice[] {
        if (arr && arr.map) {
          return arr.map(a => new GenGasPrice(a));
        } else {
          return [];
        }
    }
}

