import {MomentDatePipe} from '../../../../shared/pipes/moment-date.pipe';
import {GenSourceType} from '../../../../../generated/serverModels/GenSourceType';
import {GenKeyword} from '../../../../../generated/serverModels/GenKeyword';
import {GenRecentEvent} from '../../../../../generated/serverModels/GenRecentEvent';

interface AdditionalInformation {
    name: string;
    display: string;
}

export class RecentEvent extends GenRecentEvent {
    static momentPipe = new MomentDatePipe();

    private _headerData: AdditionalInformation[] = [];
    private _bodyData: AdditionalInformation[] = [];

    get headerData(): AdditionalInformation[] {
        return this._headerData;
    }

    get bodyData(): AdditionalInformation[] {
        return this._bodyData;
    }

    constructor(event?: RecentEvent) {
        super(event);

        this.sourceType = new GenSourceType(this.sourceType);
        if (this.keywords) {
            this.keywords.forEach((k) => new GenKeyword(k));
        }

        if (this.additionalInfo && this.additionalInfo.value) {
            if (this.sourceType.id === 1) {
                this.getMediaInfo(this.additionalInfo.value);
            } else if (this.sourceType.id === 2) {
                this.getDisturbanceInfo(this.additionalInfo.value);
            } else if (this.sourceType.id === 3) {
                this.getNaturalGasInfo(this.additionalInfo.value);
            }
        }
    }

    private getDisturbanceInfo(value: string): void {
        const val = JSON.parse(value);
        this._headerData = [
            {name: 'Incident', display: `${val.IncidentTypes}`},
            {name: 'Status', display: `${val.AlertStatus}`},
            {name: 'Affected', display: `${val.NumCustomersAffected}`},
        ];
        this._bodyData = [
            {name: 'Begin Date', display: `${RecentEvent.momentPipe.transform(val.BeginDate)}`},
            {name: 'Emergency Type', display: `${val.EmergencyTypes}`},
            {name: 'Cause', display: `${val.IncidentCauses}`},
            {name: 'Affected Regions', display: `${val.AffectedRegions || 'None'}`},
            {name: 'Affected States', display: `${val.AffectedStates}`},
            {name: 'Organization Name', display: `${val.OrgName}`},
            {name: 'Megawatt (MW) lost', display: `${val.PeakMWLost}`},
            {name: 'Actions Taken', display: `${val.ActionsTaken}`},
        ];
    }

    private getNaturalGasInfo(value: string): void {
        const val = JSON.parse(value);

        this._headerData = [{name: 'Type', display: val.Type}];

        this._bodyData = [
            {name: 'Name', display: val.Name},
            {name: 'Effective Time', display: RecentEvent.momentPipe.transform(val.EffectiveDateTime)},
            {name: 'End Time', display: RecentEvent.momentPipe.transform(val.EndDateTime)},
        ];
    }

    private getMediaInfo(value: string): void {
        const val = JSON.parse(value);

        this._headerData = [
            {name: 'Revision', display: `${val.rev}`},
            {name: 'Reference', display: `${val.track}`},
        ];

        const info: any[] = val.compiler.split('_').filter(Boolean);

        if (info.length > 1) {
            this._bodyData = [
                {
                    name: 'Additional Information',
                    display: `<p> ${val.compiler.split('_').filter(Boolean)[0]} </p>`,
                },
            ];
        }
    }
}
