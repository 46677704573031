import {DashboardFilters} from 'frontend/src/shared/classes/dashboard-filters';
import {GenOutageAggregationLevel} from '../../../../generated/serverModels/GenOutageAggregationLevel';
import * as moment from 'moment';

export class LandingFilters extends DashboardFilters {
    public aggregationLevel: GenOutageAggregationLevel;
    public usePreferences: boolean;

    constructor(filters?: LandingFilters) {
        super(filters);

        this.aggregationLevel = GenOutageAggregationLevel.state;
        this.usePreferences = false;
    }

    public setDate(date: moment.Moment): LandingFilters {
        this.date = date;
        return this;
    }
}
