<div class="dialog-header">
    <span>System Alerts</span>
    <mat-icon inline class="clickable" fontSet="fas" fontIcon="fa-times" (click)="dialogRef.close()"></mat-icon>
</div>

<mat-dialog-content class="dialog-content" #content>
    <mat-card>
        <eaglei-location-filter
            [newDesign]="true"
            [allInitiallySelected]="true"
            [selectAllOption]="true"
            (locations)="filterAlerts($event)"></eaglei-location-filter>

        <table #tableSort="matSort" mat-table [dataSource]="dataSource" matSort [matSortDisableClear]="true">
            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef class="small-col">
                    <mat-checkbox
                        color="primary"
                        (change)="toggleAllRows()"
                        [checked]="!!selection.selected.length && $isAllSelected()"
                        [indeterminate]="!!selection.selected.length && !$isAllSelected()"></mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row" class="small-col">
                    <mat-checkbox
                        color="primary"
                        (click)="$event.stopPropagation()"
                        (change)="toggleRow($event, row)"
                        [checked]="selection.isSelected(row)"></mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Location Name
                    <eaglei-table-sort-header [columnName]="'name'" [sort]="tableSort"></eaglei-table-sort-header>
                </th>
                <td mat-cell *matCellDef="let alert">
                    {{ alert.locationName }}
                    <span class="customer-number">({{ alert.locationTotalCustomers | shortNumber }})</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="threshold">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Outage Threshold
                    <eaglei-table-sort-header [columnName]="'threshold'" [sort]="tableSort"></eaglei-table-sort-header>
                </th>
                <td mat-cell *matCellDef="let alert">
                    <div>
                        {{ alert.threshold | number }}
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="percent">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Percent Affected
                    <eaglei-table-sort-header [columnName]="'percent'" [sort]="tableSort"></eaglei-table-sort-header>
                </th>
                <td mat-cell *matCellDef="let alert">
                    <div *ngIf="alert.thresholdPercent !== undefined">{{ alert.thresholdPercent | percent : '1.2-2' }}</div>
                    <div *ngIf="alert.thresholdPercent === undefined">N/A</div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnNames"></tr>

            <tr mat-row *matRowDef="let row; columns: columnNames"></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[25, 50, 75, 100]" showFirstLastButtons></mat-paginator>
    </mat-card>
</mat-dialog-content>

<mat-dialog-actions align="end" class="dialog-actions">
    <button mat-flat-button color="primary" [disabled]="!selection.selected.length" (click)="subscribe()">Subscribe</button>
</mat-dialog-actions>
