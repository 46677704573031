import {Injectable} from '@angular/core';
import * as moment from 'moment';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {ApplicationConfig} from '../classes/application-config';
import {Nomination} from '../classes/ong/nomination';
import {map} from 'rxjs/operators';
import {NaturalGasPipelineNoticeSeverity, PlannedOutage} from '../types/ong.types';
import {State} from '../modules/outage/classes/state';
import {DataService} from 'frontend/src/app/services/data.service';
import {OilStorage} from '../classes/ong/oil-storage';
import {OilPipelineAlert} from '../modules/report/classes/oil-pipeline-alert';
import {RefineryAlert} from '../modules/widget/classes/oil-refinery-alert-widget';
import {RefineryIntel} from '../modules/report/classes/refinery-intel';
import {DieselIntel} from '../modules/report/classes/diesel-report';

@Injectable({
    providedIn: 'root',
})
export class OngService {
    public readonly minDate = moment().subtract(3, 'months');

    private readonly DATEFORMAT = 'MM-DD-YYYY';

    private getApiKeyHeader(): HttpHeaders {
        return new HttpHeaders().set('X-Api-Key', 'ONG_KEY');
    }

    constructor(private http: HttpClient) {}

    public getOilRefineryAlerts(start: moment.Moment, end: moment.Moment, states: State[]): Observable<RefineryAlert[]> {
        let params = new HttpParams().set('start_date', start.format(this.DATEFORMAT)).set('end_date', end.format(this.DATEFORMAT));

        const url = `${ApplicationConfig.proxyPrefix}ONG_URL/oil/refinery-alerts`;

        if (states?.length < DataService.states.getValue().length) {
            const stateAbbreviations = states
                .map((state) => state.stateAbbreviation)
                .join()
                .toUpperCase();
            params = params.append('state', stateAbbreviations);
        }

        return this.http
            .get<any[]>(url, {headers: this.getApiKeyHeader(), params})
            .pipe(map((alerts) => alerts.map((alert) => new RefineryAlert(alert))));
    }

    public getOilPipelineAlerts(start: moment.Moment, end: moment.Moment): Observable<OilPipelineAlert[]> {
        const url = `${ApplicationConfig.proxyPrefix}ONG_URL/oil/pipeline-alerts`;
        const query = new HttpParams().set('start_date', start.format(this.DATEFORMAT)).set('end_date', end.format(this.DATEFORMAT));

        return this.http
            .get<OilPipelineAlert[]>(url, {headers: this.getApiKeyHeader(), params: query})
            .pipe(map((alerts) => alerts.map((alert) => new OilPipelineAlert(alert))));
    }

    public getOilRefineryIntelligence(start: moment.Moment, end: moment.Moment): Observable<RefineryIntel> {
        const url = `${ApplicationConfig.proxyPrefix}ONG_URL/oil/refinery-utilization`;
        const query = new HttpParams().set('start_date', start.format(this.DATEFORMAT)).set('end_date', end.format(this.DATEFORMAT));

        return this.http
            .get<{data: RefineryIntel}>(url, {
                headers: this.getApiKeyHeader(),
                params: query,
            })
            .pipe(map((response) => response.data));
    }

    public getDieselStockReport(start: moment.Moment, end: moment.Moment): Observable<DieselIntel[]> {
        const url = `${ApplicationConfig.proxyPrefix}ONG_URL/oil/storage-utilization`;
        const query = new HttpParams().set('start_date', start.format(this.DATEFORMAT)).set('end_date', end.format(this.DATEFORMAT));

        return this.http.get<DieselIntel[]>(url, {headers: this.getApiKeyHeader(), params: query});
    }

    public getOilPlannedOutages(
        type?: PlannedOutage,
        states?: State[],
        startDate?: moment.Moment,
        endDate?: moment.Moment,
        returnGeom: boolean = false,
        returnCount: boolean = false
    ): Observable<any> {
        const url = `${ApplicationConfig.proxyPrefix}ONG_URL/oil/planned-outages`;
        let params = new HttpParams().set('return_geom', returnGeom).set('return_count', returnCount);

        if (states?.length < DataService.states.getValue().length) {
            const stateNames = states
                .map((state) => state.stateAbbreviation)
                .join()
                .toUpperCase();
            params = params.append('state', stateNames);
        }

        if (startDate) {
            params = params.append('start_date', startDate.format('MM-DD-YYYY'));
        }

        if (endDate) {
            params = params.append('end_date', endDate.format('MM-DD-YYYY'));
        }

        if (type) {
            params = params.append('type', type);
        }

        const options = {
            headers: this.getApiKeyHeader(),
            params,
        };

        return this.http.get(url, options);
    }

    public getDerivedStorage(returnGeom: boolean = false): Observable<any> {
        const url = `${ApplicationConfig.proxyPrefix}ONG_URL/natural-gas/derived-storage?return_geom=${returnGeom}`;
        return this.http.get(url, {headers: this.getApiKeyHeader()});
    }

    public getUndergroundStorage(start: moment.Moment): Observable<any> {
        const url = `${ApplicationConfig.proxyPrefix}ONG_URL/natural-gas/daily-macro`;

        const params = new HttpParams().set('start_date', start.format(this.DATEFORMAT)).set('end_date', start.format(this.DATEFORMAT));

        return this.http.get(url, {headers: this.getApiKeyHeader(), params});
    }

    public getOilStorageData(): Observable<OilStorage[]> {
        const url = `${ApplicationConfig.proxyPrefix}ONG_URL/oil/storage-weekly`;
        return this.http
            .get<any[]>(url, {headers: this.getApiKeyHeader()})
            .pipe(map((res) => res.map((storage) => OilStorage.fromApiResponse(storage))));
    }

    public getOilBarrelFlow(): Observable<any> {
        const url = `${ApplicationConfig.proxyPrefix}ONG_URL/oil/barrel-flow`;
        return this.http.get(url, {headers: this.getApiKeyHeader()});
    }

    public getRefineryStatus(measurementDate?: moment.Moment, states?: State[]): Observable<any> {
        // Adding this default date for the maplayer api call
        const date = measurementDate || ApplicationConfig.roundMinute().subtract(1, 'day');

        let params = new HttpParams().set('measurement_date', date.format(this.DATEFORMAT));

        if (states?.length < DataService.states.getValue().length) {
            const stateNames = states
                .map((state) => state.stateAbbreviation)
                .join()
                .toUpperCase();
            params = params.append('state', stateNames);
        }

        const url = `${ApplicationConfig.proxyPrefix}ONG_URL/oil/refinery-status`;
        return this.http.get(url, {headers: this.getApiKeyHeader(), params});
    }

    public getGasBurnData(getHistory: boolean = false): Observable<any> {
        const url = `${ApplicationConfig.proxyPrefix}ONG_URL/azure/gas-burn${getHistory ? '-history' : ''}`;
        return this.http.get(url, {
            headers: this.getApiKeyHeader(),
        });
    }

    public getPowerEstimate(date: moment.Moment): Observable<any> {
        const startDate = moment(date).subtract(6, 'days').format(this.DATEFORMAT);
        const endDate = date.format(this.DATEFORMAT);
        const url = `${ApplicationConfig.proxyPrefix}ONG_URL/natural-gas/daily-power?start_date=${startDate}&end_date=${endDate}`;
        return this.http.get(url, {headers: this.getApiKeyHeader()});
    }

    // TODO add map method to return the correct type of observable
    public getNgNotices(
        noticeTypes: NaturalGasPipelineNoticeSeverity | NaturalGasPipelineNoticeSeverity[],
        returnCountOnly: boolean = false,
        returnGeom: boolean = false,
        states?: State[],
        start?: moment.Moment,
        end?: moment.Moment
    ): Observable<any> {
        const url = `${ApplicationConfig.proxyPrefix}ONG_URL/natural-gas/pipeline-notices`;

        const typeStr = Array.isArray(noticeTypes) ? noticeTypes.join(',') : noticeTypes;
        let params = new HttpParams().set('type', typeStr).set('return_count', returnCountOnly).set('return_geom', returnGeom);

        if (start && !end) {
            params = params.set('start_date', start.format(this.DATEFORMAT));
            params = params.set('end_date', start.clone().add(1, 'day').format(this.DATEFORMAT));
        } else if (!start && end) {
            params = params.set('start_date', end.clone().subtract(1, 'day').format(this.DATEFORMAT));
            params = params.set('end_date', end.format(this.DATEFORMAT));
        } else if (start && end) {
            params = params.set('start_date', start.format(this.DATEFORMAT));
            params = params.set('end_date', end.format(this.DATEFORMAT));
        }

        if (states?.length < DataService.states.getValue().length) {
            const stateNames = states.map((state) => state.name).join();
            params = params.append('state', stateNames);
        }

        return this.http.get(url, {headers: this.getApiKeyHeader(), params});
    }

    public getTransportationWeekly(startDate?: moment.Moment, endDate?: moment.Moment): Observable<any[]> {
        let params = new HttpParams();

        if (startDate) {
            params = params.set('start_date', startDate.format(this.DATEFORMAT));
        }
        if (endDate) {
            params = params.set('end_date', endDate.format(this.DATEFORMAT));
        }

        const url = `${ApplicationConfig.proxyPrefix}ONG_URL/oil/transportation-weekly`;
        return this.http.get<any>(url, {headers: this.getApiKeyHeader(), params}).pipe(map((res) => res.data));
    }

    public getRailFlow(date: moment.Moment): Observable<any[]> {
        const startDate = date.format(this.DATEFORMAT);
        const url = `${ApplicationConfig.proxyPrefix}ONG_URL/oil/rail-flow?start_date=${startDate}&end_date=${startDate}`;
        return this.http.get<any>(url, {headers: this.getApiKeyHeader()});
    }

    public getNgReceiptDelivery(
        states?: State[],
        end?: moment.Moment,
        returnCount: boolean = true,
        returnGeometry: boolean = false
    ): Observable<any> {
        let params = new HttpParams().set('return_count', returnCount).set('return_geom', returnGeometry);

        const url = `${ApplicationConfig.proxyPrefix}ONG_URL/azure/receipt-delivery-over-capacity`;

        if (states?.length < DataService.states.getValue().length) {
            const stateNames = states.map((state) => state.name).join();
            params = params.append('state', stateNames);
        }

        if (end) {
            params = params.set('start_date', end.clone().subtract(1, 'day').format(this.DATEFORMAT));
            params = params.set('end_date', end.format(this.DATEFORMAT));
        }

        return this.http.get(url, {headers: this.getApiKeyHeader(), params});
    }

    public getNgOperationalFlow(
        count: boolean,
        geom: boolean,
        states?: State[],
        start?: moment.Moment,
        end?: moment.Moment
    ): Observable<any> {
        let params = new HttpParams().set('return_count', count).set('return_geom', geom);

        if (start && !end) {
            params = params.set('start_date', start.format(this.DATEFORMAT));
            params = params.set('end_date', start.clone().add(1, 'day').format(this.DATEFORMAT));
        } else if (!start && end) {
            params = params.set('start_date', end.clone().subtract(1, 'day').format(this.DATEFORMAT));
            params = params.set('end_date', end.format(this.DATEFORMAT));
        } else if (start && end) {
            params = params.set('start_date', start.format(this.DATEFORMAT));
            params = params.set('end_date', end.format(this.DATEFORMAT));
        }

        if (states?.length < DataService.states.getValue().length) {
            const stateNames = states.map((state) => state.name).join();
            params = params.append('state', stateNames);
        }

        const url = `${ApplicationConfig.proxyPrefix}ONG_URL/natural-gas/operational-flow-orders`;

        return this.http.get(url, {headers: this.getApiKeyHeader(), params});
    }

    public getPipelineMaintenance(start: moment.Moment, end: moment.Moment, returnGeom: boolean = false): Observable<any> {
        const url = `${ApplicationConfig.proxyPrefix}ONG_URL/natural-gas/pipeline-maintenance?return_geom=${returnGeom}&start_date=${moment(
            start
        ).format(this.DATEFORMAT)}&end_date=${moment(end).format(this.DATEFORMAT)}`;
        return this.http.get(url, {headers: this.getApiKeyHeader()});
    }

    public getPipelineNotices(
        start: moment.Moment,
        end: moment.Moment,
        returnGeom: boolean = false,
        returnCount: boolean = false
    ): Observable<any> {
        const url = `${
            ApplicationConfig.proxyPrefix
        }ONG_URL/natural-gas/pipeline-notices?return_count=${returnCount}&return_geom=${returnGeom}&start_date=${start.format(
            this.DATEFORMAT
        )}&end_date=${end.format(this.DATEFORMAT)}`;
        return this.http.get(url, {headers: this.getApiKeyHeader()});
    }

    public getDailyMacro(start: moment.Moment, end: moment.Moment): Observable<any> {
        const url = `${ApplicationConfig.proxyPrefix}ONG_URL/natural-gas/daily-macro?start_date=${start.format(
            this.DATEFORMAT
        )}&end_date=${end.format(this.DATEFORMAT)}`;
        return this.http.get(url, {headers: this.getApiKeyHeader()});
    }

    public getNominations(date?: moment.Moment): Observable<Nomination[]> {
        let url = `${ApplicationConfig.proxyPrefix}ONG_URL/azure/nominations`;
        if (date) {
            url += `?end_date=${date.format(this.DATEFORMAT)}`;
        }
        return this.http.get(url, {headers: this.getApiKeyHeader()}).pipe(map((res) => Nomination.fromApiResponse(res)));
    }
}
