import {Component, OnInit} from '@angular/core';
import {EagleILayer} from '../../classes/eagle-i-layer';
import {LeafletArcgisSource} from '../../classes/leaflet-arcgis-source';
import {LeafletLegendService} from '../../services/leaflet-legend.service';
import {GenLegendType} from '../../../../../../generated/serverModels/GenLegendType';

@Component({
    selector: 'eaglei-leaflet-arcgis-layer',
    templateUrl: './leaflet-arcgis-layer.component.html',
    styleUrls: ['./leaflet-arcgis-layer.component.scss'],
})
export class LeafletArcgisLayerComponent extends EagleILayer<LeafletArcgisSource> implements OnInit {
    constructor(private legendService: LeafletLegendService) {
        super();
    }

    ngOnInit() {}

    afterViewInit() {}

    public toggleLayer(activeState: boolean) {
        if (activeState) {
            if (!this.layerSource) {
                this.layerSource = new LeafletArcgisSource(this.mapLayer);

                if (this.mapLayer.uiHandle === 'weatherWatchesWarnings') {
                    this.legendService.getActiveWatchTypes(this.mapLayer.serviceurl, 1).subscribe((res) => {
                        const v = this.mapLayer.legend.parseValues();
                        v.filters = {
                            '1': res,
                        } as any;
                        this.mapLayer.legend.values = JSON.stringify(v);

                        this.legendService.getLegend(this).subscribe((legend) => (this.legend = legend));
                    });
                } else {
                    if (this.mapLayer.legend.type === GenLegendType.HARD_CODED) {
                        console.info(`No calling legendService since layer legend is HARD_CODED`);
                    } else {
                        this.legendService.getLegend(this).subscribe((legend) => (this.legend = legend));
                    }
                }
            }

            this.layerSource.fetchImages();
            this.layerSource.addToMap();
        } else {
            this.layerSource.removeFromMap();
        }
    }
}
