<!--Commenting this out because this layer is no longer in use, but we are trying to add
the scraper back in, once we do, We can review how to setup the popover-->
<mat-card>
    <span>Currently not available</span>
    <!--    <mat-card-title >-->
    <!--        <div>-->
    <!--            <mat-icon class="twitter-icon" fontSet="fab" fontIcon="fa-twitter" inline></mat-icon>-->
    <!--            <span>{{ data.utilityName }} </span>-->
    <!--        </div>-->
    <!--        <mat-icon inline class="clickable" fontSet="fas" fontIcon="fa-times" (click)="close()"></mat-icon>-->
    <!--    </mat-card-title>-->
    <!--    <mat-divider></mat-divider>-->
    <!--    <mat-card-content #content fxLayout="column" fxLayoutGap="15px">-->
    <!--        <ng-container *ngIf="currentTweetIndex !== undefined">-->
    <!--            <div class="tweet-image" *ngIf="currentTweet?.mediaUrl">-->
    <!--                <img [src]="currentTweet.mediaUrl" alt="" />-->
    <!--            </div>-->

    <!--            <div class="user-info" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">-->
    <!--                <div class="profile-icon">-->
    <!--                    <img [src]="currentTweet.profileImageUrl" alt="Profile Image" />-->
    <!--                </div>-->

    <!--                <div fxLayout="column" fxLayoutAlign="center start">-->
    <!--                    <span class="account-name" fxFlex="grow">{{ currentTweet.twitterName }}</span>-->
    <!--                    <span class="account-link"-->
    <!--                        >@{{ currentTweet.twitterName }} &#183;-->
    <!--                        {{ currentTweet.createdAt.toNow(true) }}-->
    <!--                        <mat-icon inline fontSet="fas" fontIcon="fa-external-link-alt" class="clickable" (click)="flowLink()"></mat-icon>-->
    <!--                    </span>-->
    <!--                </div>-->
    <!--            </div>-->

    <!--            <div class="tweet">-->
    <!--                {{ currentTweet.tweet }}-->
    <!--            </div>-->
    <!--        </ng-container>-->
    <!--    </mat-card-content>-->
    <!--    <mat-card-actions-->
    <!--        fxLayout="row"-->
    <!--        fxLayoutAlign="center center"-->
    <!--        fxLayoutGap="5px"-->
    <!--        *ngIf="currentTweetIndex !== undefined && data.tweets.length !== 1">-->
    <!--        <mat-icon-->
    <!--            inline-->
    <!--            fontSet="fas"-->
    <!--            fontIcon="fa-angle-left"-->
    <!--            (click)="previousTweet()"-->
    <!--            [class.disabled]="currentTweetIndex === 0"-->
    <!--            class="clickable"></mat-icon>-->

    <!--        <span>{{ currentTweetIndex + 1 }}</span>-->

    <!--        <mat-icon-->
    <!--            inline-->
    <!--            fontSet="fas"-->
    <!--            fontIcon="fa-angle-right"-->
    <!--            (click)="nextTweet()"-->
    <!--            [class.disabled]="currentTweetIndex === data.tweets.length - 1"-->
    <!--            class="clickable"></mat-icon>-->
    <!--    </mat-card-actions>-->
</mat-card>
