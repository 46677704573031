
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

export class GenRoleDefinition {
    static readonly ROLE_FEDERAL: GenRoleDefinition = new GenRoleDefinition('ROLE_FEDERAL');
    static readonly ROLE_DOE: GenRoleDefinition = new GenRoleDefinition('ROLE_DOE');
    static readonly ROLE_STATE: GenRoleDefinition = new GenRoleDefinition('ROLE_STATE');
    static readonly ROLE_SYSTEM_NEWS_REPORTER: GenRoleDefinition = new GenRoleDefinition('ROLE_SYSTEM_NEWS_REPORTER');
    static readonly ROLE_USER_MANAGER: GenRoleDefinition = new GenRoleDefinition('ROLE_USER_MANAGER');
    static readonly ROLE_CONTENT_MANAGER: GenRoleDefinition = new GenRoleDefinition('ROLE_CONTENT_MANAGER');
    static readonly ROLE_COMMUNITY_MANAGER: GenRoleDefinition = new GenRoleDefinition('ROLE_COMMUNITY_MANAGER');
    static readonly ROLE_OUTAGE_REPORTER: GenRoleDefinition = new GenRoleDefinition('ROLE_OUTAGE_REPORTER');
    static readonly ROLE_OUTAGE_MANAGER: GenRoleDefinition = new GenRoleDefinition('ROLE_OUTAGE_MANAGER');
    static readonly ROLE_OUTAGE_ADMIN: GenRoleDefinition = new GenRoleDefinition('ROLE_OUTAGE_ADMIN');
    static readonly ROLE_USER_REPORTER: GenRoleDefinition = new GenRoleDefinition('ROLE_USER_REPORTER');
    static readonly ROLE_EIL_MANAGER: GenRoleDefinition = new GenRoleDefinition('ROLE_EIL_MANAGER');
    static readonly ROLE_TRAINING: GenRoleDefinition = new GenRoleDefinition('ROLE_TRAINING');
    static readonly ROLE_ADMIN: GenRoleDefinition = new GenRoleDefinition('ROLE_ADMIN');
    static readonly ROLE_APPLICATION_MANAGER: GenRoleDefinition = new GenRoleDefinition('ROLE_APPLICATION_MANAGER');
    static readonly ROLE_APPROVED_USER: GenRoleDefinition = new GenRoleDefinition('ROLE_APPROVED_USER');
    static readonly ROLE_RESETTING_PASSWORD: GenRoleDefinition = new GenRoleDefinition('ROLE_RESETTING_PASSWORD');

    public readonly name: string;

    private constructor(name: string) {
        this.name = name;
    }

    static forName(name: string) {
        if (name === 'ROLE_FEDERAL') return GenRoleDefinition.ROLE_FEDERAL;
        if (name === 'ROLE_DOE') return GenRoleDefinition.ROLE_DOE;
        if (name === 'ROLE_STATE') return GenRoleDefinition.ROLE_STATE;
        if (name === 'ROLE_SYSTEM_NEWS_REPORTER') return GenRoleDefinition.ROLE_SYSTEM_NEWS_REPORTER;
        if (name === 'ROLE_USER_MANAGER') return GenRoleDefinition.ROLE_USER_MANAGER;
        if (name === 'ROLE_CONTENT_MANAGER') return GenRoleDefinition.ROLE_CONTENT_MANAGER;
        if (name === 'ROLE_COMMUNITY_MANAGER') return GenRoleDefinition.ROLE_COMMUNITY_MANAGER;
        if (name === 'ROLE_OUTAGE_REPORTER') return GenRoleDefinition.ROLE_OUTAGE_REPORTER;
        if (name === 'ROLE_OUTAGE_MANAGER') return GenRoleDefinition.ROLE_OUTAGE_MANAGER;
        if (name === 'ROLE_OUTAGE_ADMIN') return GenRoleDefinition.ROLE_OUTAGE_ADMIN;
        if (name === 'ROLE_USER_REPORTER') return GenRoleDefinition.ROLE_USER_REPORTER;
        if (name === 'ROLE_EIL_MANAGER') return GenRoleDefinition.ROLE_EIL_MANAGER;
        if (name === 'ROLE_TRAINING') return GenRoleDefinition.ROLE_TRAINING;
        if (name === 'ROLE_ADMIN') return GenRoleDefinition.ROLE_ADMIN;
        if (name === 'ROLE_APPLICATION_MANAGER') return GenRoleDefinition.ROLE_APPLICATION_MANAGER;
        if (name === 'ROLE_APPROVED_USER') return GenRoleDefinition.ROLE_APPROVED_USER;
        if (name === 'ROLE_RESETTING_PASSWORD') return GenRoleDefinition.ROLE_RESETTING_PASSWORD;
        throw new Error('Unexpected value for GenRoleDefinition:' + name);
    }

    static values(): GenRoleDefinition[] {
        return [
            GenRoleDefinition.ROLE_FEDERAL,
            GenRoleDefinition.ROLE_DOE,
            GenRoleDefinition.ROLE_STATE,
            GenRoleDefinition.ROLE_SYSTEM_NEWS_REPORTER,
            GenRoleDefinition.ROLE_USER_MANAGER,
            GenRoleDefinition.ROLE_CONTENT_MANAGER,
            GenRoleDefinition.ROLE_COMMUNITY_MANAGER,
            GenRoleDefinition.ROLE_OUTAGE_REPORTER,
            GenRoleDefinition.ROLE_OUTAGE_MANAGER,
            GenRoleDefinition.ROLE_OUTAGE_ADMIN,
            GenRoleDefinition.ROLE_USER_REPORTER,
            GenRoleDefinition.ROLE_EIL_MANAGER,
            GenRoleDefinition.ROLE_TRAINING,
            GenRoleDefinition.ROLE_ADMIN,
            GenRoleDefinition.ROLE_APPLICATION_MANAGER,
            GenRoleDefinition.ROLE_APPROVED_USER,
            GenRoleDefinition.ROLE_RESETTING_PASSWORD,
        ];
    }

    toJSON(): string {
        return this.name;
    }

    toString(): string {
        return this.name;
    }
}

