
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

export class GenExportFileType {
    static readonly CSV: GenExportFileType = new GenExportFileType('CSV');
    static readonly XLSX: GenExportFileType = new GenExportFileType('XLSX');

    public readonly name: string;

    private constructor(name: string) {
        this.name = name;
    }

    static forName(name: string) {
        if (name === 'CSV') return GenExportFileType.CSV;
        if (name === 'XLSX') return GenExportFileType.XLSX;
        throw new Error('Unexpected value for GenExportFileType:' + name);
    }

    static values(): GenExportFileType[] {
        return [
            GenExportFileType.CSV,
            GenExportFileType.XLSX,
        ];
    }

    toJSON(): string {
        return this.name;
    }

    toString(): string {
        return this.name;
    }
}

