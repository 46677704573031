import {AfterViewInit, Component, OnDestroy, ViewChild} from '@angular/core';
import {SystemNews} from '../../classes/system-news';
import {AuthenticationService} from '../../../../services/authentication.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SystemNewsService} from '../../services/system-news.service';
import {GenRoleDefinition} from '../../../../../../generated/serverModels/GenRoleDefinition';
import {filter, takeUntil} from 'rxjs/operators';
import {MatPaginator} from '@angular/material/paginator';
import {ConfirmationComponent} from '../../../../../shared/modals/confirmation/confirmation.component';
import {ModalConfig} from '../../../../classes/modal-config';
import {CreateSystemNewsComponent} from '../../modals/create-system-news/create-system-news.component';
import {ActivatedRoute} from '@angular/router';
import {SystemNewsType} from '../../classes/system-news-type';
import {UntypedFormControl, Validators} from '@angular/forms';
import {CardFilters} from '../../../../../shared/classes/card-filters';
import * as moment from 'moment';
import {Subject} from 'rxjs';
import {ApplicationConfig} from '../../../../classes/application-config';

@Component({
    selector: 'eaglei-system-news-feed',
    templateUrl: './system-news-feed.component.html',
    styleUrls: ['./system-news-feed.component.scss'],
})
export class SystemNewsFeedComponent implements AfterViewInit, OnDestroy {
    get isReporter(): boolean {
        return this._isReporter;
    }

    set selectedNews(val: SystemNews) {
        if (this._selectedNews) {
            this._selectedNews.editing = false;
        }
        this._selectedNews = val;
    }

    get selectedNews() {
        return this._selectedNews;
    }

    // Access Properties
    private readonly reporterRoles = [GenRoleDefinition.ROLE_SYSTEM_NEWS_REPORTER, GenRoleDefinition.ROLE_USER_MANAGER];
    private _isReporter: boolean;

    // HTML Properties
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

    // News Properties
    private allNews: SystemNews[];
    public filteredNews: SystemNews[] = [];
    private _selectedNews: SystemNews;
    public updatedNews: SystemNews;
    public onlyUseFaq: boolean;
    private type: SystemNewsType;
    private destroy$ = new Subject();

    // Filter Properties
    public searchText: string = '';
    public sortDirection = -1;
    public filterPlaceholder: string = 'Search News';
    public createText: string;
    public startDate = ApplicationConfig.roundMinute().subtract(1, 'days').startOf('day');
    public endDate = ApplicationConfig.roundMinute().endOf('day');
    public minDate = ApplicationConfig.roundMinute().subtract(6, 'months');

    // Form Controls
    public titleControl: UntypedFormControl;

    constructor(
        public auth: AuthenticationService,
        private popup: MatSnackBar,
        private dialog: MatDialog,
        private newsService: SystemNewsService,
        private route: ActivatedRoute
    ) {
        this.auth.authenticatedUser
            .pipe(
                takeUntil(this.destroy$),
                filter((user) => !!user)
            )
            .subscribe((user) => (this._isReporter = user.hasAnyRole(this.reporterRoles)));

        this.titleControl = new UntypedFormControl(undefined, {
            validators: [Validators.required, Validators.maxLength(100)],
        });
    }

    ngAfterViewInit(): void {
        this.onlyUseFaq = (this.route.snapshot.data as any).onlyFaq;
        this.filterPlaceholder = this.onlyUseFaq ? 'Search FAQ' : 'Search News';
        this.createText = this.onlyUseFaq ? 'Create FAQ' : 'Create System News';

        this.getSystemNews();
    }

    ngOnDestroy() {
        this.destroy$.complete();
        this.destroy$.unsubscribe();
    }

    // Data Methods
    /**
     * Fetches the system news and sorts it by most recent first.
     */
    private getSystemNews(): void {
        const success = (res: SystemNews[]) => {
            this.allNews = res;
            this.filteredNews = this.allNews.slice();
            this.paginator.length = this.filteredNews.length;
            this.filterNews();
        };

        const failure = (error: any) => {
            const text = error.error.userMessage || `Error in System news call`;
            console.error(text);
        };

        const typeSuccess = (types: SystemNewsType[]) => {
            this.type = types.find((type) => {
                return type.name === (this.onlyUseFaq ? 'FAQ' : 'System News');
            });
        };

        this.newsService.getSystemNewsTypes().subscribe(typeSuccess);

        this.newsService.getSystemNews(undefined, this.onlyUseFaq).subscribe(success, failure);
    }

    /**
     * Updates a system news entry with the new user entered text
     * @param news The news entry that is being updated.
     */
    public updateNews(news: SystemNews) {
        const me = this;

        if (news.emailUsers) {
            this.newsService.emailUpdatedNews(news);
        }

        this.newsService.updateSystemNews(me.updatedNews).subscribe(
            (updated) => {
                const index = this.allNews.findIndex((n) => updated.id === n.id);

                if (index !== -1) {
                    this.allNews.splice(index, 1, updated);
                }

                this.filterNews();
                this.sortSystemNews();

                me.popup.open(`Updated ${this.type.name}`, 'Okay', {duration: 5000, panelClass: 'dialog-success'});
            },
            () => {
                me.popup.open(`Failed to update ${this.type.name}`, 'Okay', {panelClass: 'dialog-failure'});
                console.error(`Error updating ${this.type.name} with id: ` + me.updatedNews.id);
            }
        );
    }

    /**
     * Removed a system news entry.
     * @param news the entry to be removed
     * @param event the mouse event to stop so the selected news tab does not close.
     */
    public deleteSystemNews(news: SystemNews, event: MouseEvent): void {
        const success = () => {
            const index = this.allNews.findIndex((sn) => sn.id === news.id);
            if (index !== -1) {
                this.allNews.splice(index, 1);
            }

            this.filteredNews = this.filterNews();
            this.sortSystemNews();
            this.popup.open(`Removed ${this.type.name}`, 'Okay', {duration: 5000, panelClass: 'dialog-success'});
        };
        const failure = (error: any) => {
            const text = error.error.userMessage || `Failed to Remove ${this.type.name}`;
            this.popup.open(text, 'Okay', {panelClass: 'dialog-failure'});
            console.error(text);
        };
        const config: MatDialogConfig = {
            autoFocus: false,
            disableClose: true,
            data: {
                message: `Are you sure you want to delete this ${this.type.name}?`,
            },
        };

        event.stopPropagation();

        this.dialog
            .open(ConfirmationComponent, config)
            .afterClosed()
            .pipe(filter((res) => !!res))
            .subscribe(() => this.newsService.deleteSystemNews(news.id).subscribe(success, failure));
    }

    // Filter Methods
    /**
     * Sorts the active system news by created date
     * @param direction The direction of the sort -1 for newest first and 1 for oldest first
     */
    public sortSystemNews(direction?: number): void {
        this.sortDirection = direction || this.sortDirection;
        this.filteredNews.sort((a, b) => {
            return b.createDate.isSameOrBefore(a.createDate) ? this.sortDirection : this.sortDirection * -1;
        });
    }

    /**
     * Filters all the system news entries based on the search bar text.
     */
    public filterNews(): SystemNews[] {
        this.filteredNews = this.allNews.filter((news) => {
            const fields = [news.title].join().toLowerCase().trim();
            const fieldCheck = !!this.searchText ? fields.includes(this.searchText.toLowerCase().trim()) : true;
            const dateCheck = news.createDate.isBetween(this.startDate, this.endDate, undefined, '[]');
            return this.onlyUseFaq ? fieldCheck : fieldCheck && dateCheck;
        });
        this.sortSystemNews();
        this.paginator.length = this.filteredNews.length;
        return this.filteredNews;
    }

    /**
     * Returns if the search bar is currently active.
     */
    public isSearchTextActive(): boolean {
        return this.searchText && this.searchText.trim().length > 0;
    }

    // Utility Methods
    /**
     * Sets a system news element to be selected so the content is expanded
     * @param news The news entry to be selected
     */
    public selectNews(news: SystemNews): void {
        if (this.selectedNews === news) {
            this.selectedNews = undefined;
        } else {
            this.selectedNews = news;
        }
    }

    /**
     * sets a news entry to be editable
     * @param news the entry that will be edited
     * @param event the mouse event to stop so the selected news tab does not close.
     */
    public editNews(news: SystemNews, event: any) {
        event.stopPropagation();
        this.updatedNews = new SystemNews(news);
        news.editing = true;
    }

    /**
     * cancels the current update of a system news entry
     * @param news The entry to be reset.
     * @param event the mouse event to stop so the selected news tab does not close.
     */
    public cancelUpdate(news: SystemNews, event: MouseEvent) {
        event.stopPropagation();
        news.editing = false;
        this.updatedNews = new SystemNews();
    }

    /**
     * Controls the paginator values
     */
    public getPaginatorValues(): SystemNews[] {
        if (!this.paginator) {
            console.warn('paginator not initialized');
        }

        const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
        const endIndex = Math.min(startIndex + this.paginator.pageSize, this.filteredNews.length);

        return this.filteredNews.slice(startIndex, endIndex);
    }

    /**
     * Returns the text used in the filter mask
     */
    public getMaskText(): string {
        if (this.onlyUseFaq) {
            return 'There are no questions matching the search text.';
        }
        return 'There is no news matching the search text.';
    }

    /**
     * Opens the create System news modal and adds the new entry to the list on close.
     */
    public createSystemNews(): void {
        const config: MatDialogConfig = {
            disableClose: true,
            width: ModalConfig.getModalWidth(500),
            data: this.type,
        };

        this.dialog
            .open(CreateSystemNewsComponent, config)
            .afterClosed()
            .pipe(filter((val) => !!val))
            .subscribe((news) => {
                this.allNews.unshift(news);
                this.filterNews();
            });
    }

    /**
     * Updates the date range for the visible system news
     * @param dates The start and end date passed from the datepicker
     */
    public updateDateRange(dates: CardFilters) {
        [this.startDate, this.endDate] = [dates.startDate, dates.endDate];
        this.filterNews();
    }
}
