<eaglei-leaflet-layer-header [layer]="this" (activeToggled)="toggleLayer($event)" [hideLegend]="true"></eaglei-leaflet-layer-header>

<ng-container *ngIf="isActive">
    <!--    legend-->
    <div #legendElement>
        <div class="radr-legend">
            <div class="color-box"></div>
            <div class="radr-legend-text">
                <span>No Flood</span>
                <span>Flood</span>
            </div>
        </div>
    </div>

    <ng-container *ngIf="tiles.length === 0 && filterGroup.get('event').value">
        <span class="error-message">There are no collected images for this event.</span>
    </ng-container>

    <ng-container *ngIf="radrEvents !== undefined; else noEvents">
        <form [formGroup]="filterGroup">
            <mat-form-field class="wide" appearance="outline" floatLabel="always">
                <mat-label>Event</mat-label>
                <mat-select [compareWith]="eventCompareFunction" placeholder="Event" formControlName="event">
                    <mat-optgroup *ngFor="let item of radrEvents | keyvalue" [label]="item.key | titlecase">
                        <mat-option *ngFor="let event of item.value" [value]="event">{{ event.name }}</mat-option>
                    </mat-optgroup>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="wide" appearance="outline" floatLabel="always">
                <mat-label>Image Layer</mat-label>
                <mat-select [compareWith]="RadrImageCompareFunction" multiple placeholder="Image Layer" formControlName="images">
                    <mat-option *ngFor="let tile of tiles" [value]="tile">
                        {{ tile.tileId }} - {{ tile.collectedAt | momentDate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </form>
    </ng-container>

    <ng-template #noEvents>
        <span class="error-message">There are no events collecting RADR data</span>
    </ng-template>
</ng-container>
