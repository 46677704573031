<form class="filters filter-bar" [formGroup]="filterForm">
    <eaglei-date-range (change)="updateDateRange($event)" [start]="startDate" [min]="ongService.minDate"> </eaglei-date-range>

    <button mat-stroked-button [matMenuTriggerFor]="menu" matTooltip="Export" matTooltipPosition="above" matTooltipShowDelay="250">
        <mat-icon inline fontSet="fas" fontIcon="fa-file-export"></mat-icon>
    </button>
</form>

<div>
    <div class="table-section">
        <mat-card>
            <mat-card-title> Diesel Stock </mat-card-title>

            <mat-card-content>
                <div class="table-container" [class.invisible]="!dataSource?.data?.length">
                    <table mat-table [dataSource]="dataSource" multiTemplateDataRows>
                        <ng-container matColumnDef="product">
                            <th mat-header-cell *matHeaderCellDef class="ei-md-column">Product</th>
                            <td mat-cell *matCellDef="let info" class="ei-md-column">
                                <mat-icon
                                    inline
                                    fontSet="fas"
                                    [fontIcon]="info === productSelectedRow ? 'fa-chevron-down' : 'fa-chevron-right'">
                                </mat-icon>

                                {{ info.product }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="previous_storage">
                            <th mat-header-cell *matHeaderCellDef class="ei-sm-column">
                                <div class="group">
                                    <span>Storage Average Start:</span>
                                    <span>(Barrels)</span>
                                </div>
                            </th>
                            <td mat-cell *matCellDef="let info" class="ei-sm-column">
                                {{ info.previous_storage | eagleiNumber }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="current_storage">
                            <th mat-header-cell *matHeaderCellDef class="ei-sm-column">
                                <div class="group">
                                    <span>Storage Average End:</span>
                                    <span>(Barrels)</span>
                                </div>
                            </th>
                            <td mat-cell *matCellDef="let info" class="ei-sm-column">
                                {{ info.current_storage | eagleiNumber }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="change">
                            <th mat-header-cell *matHeaderCellDef class="ei-sm-column">
                                <div class="group">
                                    <span>Week to Week Change</span>
                                    <span>(in barrels)</span>
                                </div>
                            </th>
                            <td mat-cell *matCellDef="let info" class="ei-sm-column">
                                {{ info.change | eagleiNumber }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="percent_change">
                            <th mat-header-cell *matHeaderCellDef class="ei-sm-column">Percent Change</th>
                            <td mat-cell *matCellDef="let info" class="ei-sm-column">
                                {{ info.percent_change | eagleiNumber : percentPipeArgs }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="utilization">
                            <th mat-header-cell *matHeaderCellDef class="ei-sm-column">Capacity Utilization</th>
                            <td mat-cell *matCellDef="let info" class="ei-sm-column">
                                {{ info.utilization | eagleiNumber : percentPipeArgs }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="expandedDetail">
                            <td mat-cell *matCellDef="let info" colspan="6">
                                <div class="hide-expanded-row" [class.show-expanded-row]="productSelectedRow === info">
                                    <table mat-table [dataSource]="expandedProductSelectedRow">
                                        <ng-container matColumnDef="product">
                                            <th mat-header-cell *matHeaderCellDef class="ei-md-column">Report Date</th>

                                            <td mat-cell *matCellDef="let info" class="ei-md-column report-date">
                                                {{ info.report_date | momentDate : momentPipeFormat }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="previous_storage">
                                            <th mat-header-cell *matHeaderCellDef class="ei-sm-column">Previous Storage</th>
                                            <td mat-cell *matCellDef="let info" class="ei-sm-column">
                                                {{ info.previous_storage | eagleiNumber }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="current_storage">
                                            <th mat-header-cell *matHeaderCellDef class="ei-sm-column">Current Storage</th>
                                            <td mat-cell *matCellDef="let info" class="ei-sm-column">
                                                {{ info.current_storage | eagleiNumber }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="change">
                                            <th mat-header-cell *matHeaderCellDef class="ei-sm-column">Week to Week Change</th>
                                            <td mat-cell *matCellDef="let info" class="ei-sm-column">
                                                {{ info.change | eagleiNumber }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="percent_change">
                                            <th mat-header-cell *matHeaderCellDef class="ei-sm-column">Percent Change</th>
                                            <td mat-cell *matCellDef="let info" class="ei-sm-column">
                                                {{ info.percent_change | eagleiNumber : percentPipeArgs }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="utilization">
                                            <th mat-header-cell *matHeaderCellDef class="ei-sm-column">Capacity Utilization</th>
                                            <td mat-cell *matCellDef="let info" class="ei-sm-column">
                                                {{ info.utilization | eagleiNumber : percentPipeArgs }}
                                            </td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="columnNames; sticky: true"></tr>

                                        <tr
                                            mat-row
                                            *matRowDef="let row; columns: columnNames; let odd = odd"
                                            [class.striped-row]="odd"></tr>
                                    </table>
                                </div>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
                        <tr
                            mat-row
                            *matRowDef="let row; columns: columnNames; let odd = odd"
                            class="clickable"
                            (click)="togglePrimaryExpandedRow(row)"></tr>
                        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="expandable-row"></tr>
                    </table>
                </div>

                <div class="table-attribution" [class.invisible]="dataSource?.data?.length === 0">
                    <a (click)="reportService.openAttribution(attributionUrl)" [innerHTML]="attributionTitle | sanitize"></a>
                </div>

                <div class="auto-mask" *ngIf="!dataSource?.data?.length">
                    <p class="mask-message">{{ maskText$ | async }}</p>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>

<!-- Export Menu -->
<mat-menu #menu="matMenu" overlapTrigger>
    <button (click)="exportTable()" mat-menu-item [disabled]="dataSource?.data?.length === 0">Export Table As CSV</button>
</mat-menu>

<div class="chart-group">
    <mat-card class="chart">
        <mat-card-title>Week to Week % Change</mat-card-title>

        <mat-card-content>
            <ng-container [ngTemplateOutlet]="chartLegend"></ng-container>

            <div class="chart-target">
                <div #chart id="percent_change" class="chart-target"></div>
            </div>
        </mat-card-content>
    </mat-card>

    <mat-card class="chart">
        <mat-card-title>Week to Week Change</mat-card-title>

        <mat-card-content>
            <ng-container [ngTemplateOutlet]="chartLegend"></ng-container>

            <div class="chart-target">
                <div #chart id="change" class="chart-target"></div>
            </div>
        </mat-card-content>
    </mat-card>
</div>

<div class="chart-group">
    <mat-card class="chart">
        <mat-card-title>Capacity Utilization</mat-card-title>

        <mat-card-content>
            <ng-container [ngTemplateOutlet]="chartLegend"></ng-container>

            <div class="chart-target">
                <div #chart id="utilization" class="chart-target"></div>
            </div>
        </mat-card-content>
    </mat-card>
</div>

<ng-template #chartLegend>
    <div class="chart-legend">
        <div class="item">
            <span>Ethanol:</span>
            <span class="color" [style.border-color]="chartColors.Ethanol"></span>
        </div>
        <div class="item">
            <span>Gasoline:</span>
            <span class="color" [style.border-color]="chartColors.Gasoline"></span>
        </div>
        <div class="item">
            <span>Heating Oil/Diesel:</span>
            <span class="color" [style.border-color]="chartColors.Heating_Oil_Diesel"></span>
        </div>
        <div class="item">
            <span>Jet Fuel:</span>
            <span class="color" [style.border-color]="chartColors.Jet_Fuel"></span>
        </div>
    </div>
</ng-template>
