
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import * as moment from 'moment';

export class GenAlert {

    id: number;
    description: string;
    details: number[];
    end: moment.Moment;
    name: string;
    ownerId: number;
    rollUp: boolean;
    start: moment.Moment;
    subscriberIds: number[];
    threshold: number;
    type: string;

    constructor();
    constructor( jsonObj: any );
    constructor( genAlert: GenAlert);
    constructor( id: number );

    constructor( input?: GenAlert|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            this.description = input.description; // string [string]
            this.details = input.details; // list [number]
            if ( input.end && typeof input.end === 'string' ) {
                this.end = moment(input.end).utc();
            } else {
                this.end = input.end;
            }
            this.name = input.name; // string [string]
            this.ownerId = input.ownerId; // int [number]
            this.rollUp = input.rollUp; // boolean [boolean]
            if ( input.start && typeof input.start === 'string' ) {
                this.start = moment(input.start).utc();
            } else {
                this.start = input.start;
            }
            this.subscriberIds = input.subscriberIds; // list [number]
            this.threshold = input.threshold; // int [number]
            this.type = input.type; // string [string]
       }
    }

    static jsonResponseToList(arr: any[]): GenAlert[] {
        if (arr && arr.map) {
          return arr.map(a => new GenAlert(a));
        } else {
          return [];
        }
    }
}

