import {GenPaddData} from '../../../../../generated/serverModels/GenPaddData';

export class PaddData extends GenPaddData {
    constructor(data?: PaddData) {
        super(data);

        if (data) {
            if (isNaN(data.regionId)) {
                this.regionId = parseInt(data.region.split(' ')[1]);
            }
        }
    }
}
