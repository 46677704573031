import {Component, Inject, OnInit} from '@angular/core';
import {BaseModal} from '../../../app/classes/base-modal';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {CardFilters} from '../../classes/card-filters';
import * as moment from 'moment';
import {ReportRequest} from '../../classes/report-request';
import {ReportService} from '../../../app/modules/report/services/report.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AuthenticationService} from 'frontend/src/app/services/authentication.service';
import {GenReportRequestGroup} from '../../../../generated/serverModels/GenReportRequestGroup';
import {ReportRequestParameter} from '../../classes/report-request-parameter';
import {GenExportFileType} from '../../../../generated/serverModels/GenExportFileType';

@Component({
    selector: 'eaglei-email-report-data-modal',
    templateUrl: './email-report-data-modal.component.html',
    styleUrls: ['../shared-modals.scss', './email-report-data-modal.component.scss'],
})
export class EmailReportDataModalComponent extends BaseModal implements OnInit {
    get emails(): UntypedFormArray {
        return this.exportGroup.controls.emails as UntypedFormArray;
    }

    public startDate = moment().add(1, 'day').startOf('day');
    public endDate = moment().add(2, 'day');
    public maxDate = moment().add(7, 'day');
    public minDate = this.startDate.clone();

    private readonly request: ReportRequest;

    public exportGroup: UntypedFormGroup;
    public fileTypes: GenExportFileType[] = GenExportFileType.values();
    public fileType: GenExportFileType;

    constructor(
        public dialogRef: MatDialogRef<EmailReportDataModalComponent>,
        @Inject(MAT_DIALOG_DATA) private data: any,
        private reportService: ReportService,
        private formBuilder: UntypedFormBuilder,
        private popup: MatSnackBar,
        private auth: AuthenticationService
    ) {
        super();
        this.request = data.request;
    }

    ngOnInit(): void {
        const controls = {
            name: new UntypedFormControl('', [Validators.required]),
            frequency: new UntypedFormControl(),
            emails: this.formBuilder.array([]),
            fileType: new UntypedFormControl('', [Validators.required]),
        };

        this.fileType = this.fileTypes[0];
        controls.fileType.setValue(this.fileTypes[0]);
        controls.frequency.setValue(1);
        controls.name.setValue(this.request.reportName);

        this.exportGroup = new UntypedFormGroup(controls);
    }

    afterInit() {
        this.addEmails();
        this.addEmails();
        this.addEmails();
    }

    public isFormValid(): boolean {
        const emailCheck = (this.exportGroup.controls.emails.value as string[]).filter((email) => !!email).length !== 0;

        return this.exportGroup.valid && emailCheck;
    }

    public sendData(): void {
        this.startDate = this.startDate.startOf('day');
        const tmpEnd = moment(this.endDate).add(1, 'day').startOf('day');

        this.request.subscribers = (this.exportGroup.controls.emails.value as string[]).filter((email) => !!email);

        this.request.ownerId = this.auth.authenticatedUser.getValue().id;

        const requests: ReportRequest[] = [];
        const hourIncrement = 24 / this.exportGroup.controls.frequency.value;

        const fileTypeParam = new ReportRequestParameter().setName('fileType').setValue(this.fileType.toString().toLowerCase());

        this.request.parameters.push(fileTypeParam);

        for (const i = this.startDate.clone(); i.isBefore(tmpEnd); i.add(hourIncrement, 'hours')) {
            const r = new ReportRequest(this.request);
            r.runTime = i.clone().utc();

            requests.push(r);
        }

        const group = new GenReportRequestGroup();
        group.startDate = this.startDate.utc();
        group.endDate = this.endDate.utc();
        group.frequency = this.exportGroup.controls.frequency.value;
        group.requests = requests;
        group.name = this.exportGroup.controls.name.value;
        group.requestOwnerId = this.auth.authenticatedUser.getValue().id;

        this.reportService.createReportEmailRequests(group).subscribe(
            () => {
                this.popup.open('Your request has been submitted', 'Okay', {duration: 3_000, panelClass: ['dialog-success']});
                this.dialogRef.close();
            },
            () => {
                this.popup.open('There was an issue submitting your request', 'Okay', {duration: 3_000, panelClass: ['dialog-failure']});
            }
        );
    }

    public updateDates(event: CardFilters) {
        this.startDate = event.startDate;
        this.endDate = event.endDate;
    }

    /**
     * Creates a new entry in the form array for a dynamic number of systems
     */
    public newEmail(): AbstractControl {
        const textPattern = Validators.pattern(/[.*[\S].*/);
        return new UntypedFormControl('', [Validators.email, textPattern]);
    }

    /**
     * Addes a new system text box that is required.
     */
    public addEmails(): void {
        this.emails.push(this.newEmail());
    }

    /**
     * Removes the system input at a given index
     * @param index The index in the form array to remove
     */
    public removeSystem(index: number) {
        this.emails.removeAt(index);
    }

    public hasError(control: AbstractControl, errorName: string): boolean {
        return control.hasError(errorName);
    }
}
