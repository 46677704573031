import {Component, inject} from '@angular/core';
import {EilSource} from '../../sources/eil-source';
import {LayerService} from '../../services/layer.service';
import {EilData} from '../../../../classes/eil-data';
import {map, tap} from 'rxjs/operators';
import {AuthenticationService} from '../../../../services/authentication.service';
import {EagleILayer} from '../../classes/eagle-i-layer';
import {IFilterChange} from '../../interfaces/filter-change.interface';
import {GenLayerFilterType} from '../../../../../../generated/serverModels/GenLayerFilterType';
import {GenDatapointType} from '../../../../../../generated/serverModels/GenDatapointType';
import {Observable} from 'rxjs';
import {LeafletLegendService} from '../../services/leaflet-legend.service';
import {GenRoleDefinition} from 'frontend/generated/serverModels/GenRoleDefinition';

@Component({
    selector: 'eaglei-eil-layer',
    templateUrl: './eil-layer.component.html',
    styleUrls: ['./eil-layer.component.scss'],
})
export class EilLayerComponent extends EagleILayer<EilSource> {
    private filterStatus: string;
    private layerService = inject(LayerService);
    private legendService = inject(LeafletLegendService);
    private auth = inject(AuthenticationService);
    public readonly isEilManager: boolean;

    constructor() {
        super();
        this.isEilManager = this.auth.authenticatedUser.getValue().hasRole(GenRoleDefinition.ROLE_EIL_MANAGER);
    }

    afterViewInit() {}

    public toggleLayer(activeState: boolean, zoomToFeatures?: boolean): void {
        if (activeState) {
            if (!this.layerSource) {
                this.filterStatus = this.mapLayer.filters.find((f) => f.type === GenLayerFilterType.SINGLE_SELECT).defaultValue;
                this.layerSource = new EilSource(this.mapLayer);
                this.legendService.getLegend(this).subscribe((legend) => (this.legend = legend));
            }

            this.getEilData().subscribe(() => this.layerSource.addToMap());
        } else {
            this.layerSource.removeFromMap();
        }
    }

    private getEilData(): Observable<any> {
        this.mapLayer.startLoading();
        const status = this.isEilManager ? this.filterStatus : 'active';
        const url = `${this.mapLayer.serviceurl}?status=${status}`;

        const mf = (res: any[]) => {
            const points = res.map((eilPoint) => new EilData(eilPoint));
            this.layerSource.processFeatures(points);
        };

        return this.layerService.getJsonFromUrl(url).pipe(
            map(mf),
            tap(() => this.mapLayer.endLoading())
        );
    }

    updateFilter(event: IFilterChange) {
        if (event.filter.filterProperty === 'type') {
            let pointType;
            try {
                pointType = GenDatapointType.forName(event.value[0].value as string);
            } catch (e) {
                pointType = undefined;
            }

            this.layerSource.applyFilter(pointType);
        } else if (event.filter.filterProperty === 'active') {
            this.filterStatus = event.value[0].value as string;
            this.getEilData().subscribe(() => {
                this.layerSource.removeFromMap();
                this.layerSource.addToMap();
            });
        }
    }
}
