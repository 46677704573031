import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ResizeService {
    constructor() {}

    public resizeElementOpen = new BehaviorSubject<boolean>(undefined);
    public openResizeElement = new Subject<any>();
    public closeResizeElement = new Subject<any>();
    public resizeEvent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
}
