<header>
    <span>Layers</span>

    <div (click)="clearAllLayers()" class="clear-all clickable">
        <mat-icon fontSet="fas" fontIcon="fa-layer-group"></mat-icon>
        <span>Clear All</span>
    </div>
</header>

<div class="base-layers">
    <div class="layer-category">
        <span>BASE LAYER</span>
        <eaglei-base-layer></eaglei-base-layer>
    </div>
</div>

<div class="layer-groups" *ngFor="let category of layerCategories">
    <ng-container *ngIf="hasLayers(category)">
        <div class="layer-category">
            <span>{{ category.displayName }}</span>
        </div>

        <div *ngFor="let group of category.groups">
            <ng-container *ngIf="group.layers.length > 0">
                <div class="layer-group" (click)="group.expanded = !group.expanded" [class.expanded]="group.expanded">
                    <img class="group-icon" [src]="group.iconUrl" alt="Layer icon" />
                    <span>{{ group.displayName }}</span>
                    <mat-icon inline fontSet="fas" [fontIcon]="group.expanded ? 'fa-chevron-up' : 'fa-chevron-down'"></mat-icon>
                </div>

                <div class="layers hide" [class.show]="group.expanded">
                    <div *ngFor="let layer of group.layers">
                        <div [ngSwitch]="layer.servicetype" class="layer">
                            <ng-container *ngSwitchCase="layerTypes.VECTOR">
                                <ng-container [ngSwitch]="layer.uiHandle">
                                    <eaglei-leaflet-nom-layer
                                        *ngSwitchCase="'nationalOutageMap'"
                                        [mapLayer]="layer"></eaglei-leaflet-nom-layer>
                                    <eaglei-gas-price-layer *ngSwitchCase="'gasPrices'" [mapLayer]="layer"></eaglei-gas-price-layer>
                                    <eaglei-eil-layer *ngSwitchCase="'eilData'" [mapLayer]="layer"></eaglei-eil-layer>
                                    <eaglei-wildfire-perimeter-layer
                                        *ngSwitchCase="'wildfirePerimeter'"
                                        [mapLayer]="layer"></eaglei-wildfire-perimeter-layer>
                                    <eaglei-treasuries-layer
                                        *ngSwitchCase="'treasuryFacility'"
                                        [mapLayer]="layer"></eaglei-treasuries-layer>
                                    <eaglei-doe-facility-layer *ngSwitchCase="'doeFacility'" [mapLayer]="layer"></eaglei-doe-facility-layer>
                                    <eaglei-newsfeed-layer *ngSwitchCase="'newsfeed'" [mapLayer]="layer"></eaglei-newsfeed-layer>
                                    <eaglei-earthquake-layer *ngSwitchCase="'earthquakes'" [mapLayer]="layer"></eaglei-earthquake-layer>
                                    <eaglei-petroleum-flow-layer
                                        *ngSwitchCase="'productionFlowStatus'"
                                        [mapLayer]="layer"></eaglei-petroleum-flow-layer>
                                    <eaglei-headout-layer
                                        *ngSwitchCase="'headoutHurricaneInformation'"
                                        [mapLayer]="layer"></eaglei-headout-layer>
                                    <eaglei-social-media-layer *ngSwitchCase="'socialMedia'" [mapLayer]="layer"></eaglei-social-media-layer>
                                    <eaglei-ong-refineries *ngSwitchCase="'ongRefineries'" [mapLayer]="layer"></eaglei-ong-refineries>
                                    <eaglei-ng-pipeline-notice-layer
                                        *ngSwitchCase="'ngPipelineNotices'"
                                        [mapLayer]="layer"></eaglei-ng-pipeline-notice-layer>
                                    <eaglei-ong-nominations *ngSwitchCase="'ongNominations'" [mapLayer]="layer"></eaglei-ong-nominations>
                                    <eaglei-rrcc-layer *ngSwitchCase="'rrccLocations'" [mapLayer]="layer"></eaglei-rrcc-layer>
                                    <eaglei-ong-oil-outage-layer
                                        *ngSwitchCase="'ongOilOutage'"
                                        [mapLayer]="layer"></eaglei-ong-oil-outage-layer>
                                    <eaglei-ong-gas-burn *ngSwitchCase="'ongGasBurn'" [mapLayer]="layer"></eaglei-ong-gas-burn>
                                    <eaglei-port-status-layer *ngSwitchCase="'portStatus'" [mapLayer]="layer"></eaglei-port-status-layer>
                                    <eaglei-ports-layer *ngSwitchCase="'ports'" [mapLayer]="layer"></eaglei-ports-layer>
                                    <eaglei-oil-barrel-flow *ngSwitchCase="'oilBarrelFlow'" [mapLayer]="layer"></eaglei-oil-barrel-flow>
                                    <eaglei-empower-layer *ngSwitchCase="'emPower'" [mapLayer]="layer"></eaglei-empower-layer>
                                    <eaglei-active-fires-layer *ngSwitchCase="'activeFire'" [mapLayer]="layer"></eaglei-active-fires-layer>
                                    <eaglei-wind-velocity-layer
                                        *ngSwitchCase="'windVelocity'"
                                        [mapLayer]="layer"></eaglei-wind-velocity-layer>
                                    <eaglei-quantitative-precip-layer
                                        *ngSwitchCase="'precipitation'"
                                        [mapLayer]="layer"></eaglei-quantitative-precip-layer>
                                    <eaglei-survey123-layer *ngSwitchCase="'survey123'" [mapLayer]="layer"></eaglei-survey123-layer>
                                    <eaglei-wastewater-treatment-layer
                                        *ngSwitchCase="'wastewaterPlant'"
                                        [mapLayer]="layer"></eaglei-wastewater-treatment-layer>
                                    <eaglei-river-flooding-layer
                                        *ngSwitchCase="'riverForecast'"
                                        [mapLayer]="layer"></eaglei-river-flooding-layer>
                                    <eaglei-pole-detection-layer
                                        *ngSwitchCase="'poleDetection'"
                                        [mapLayer]="layer"></eaglei-pole-detection-layer>
                                </ng-container>
                            </ng-container>

                            <ng-container *ngSwitchCase="layerTypes.VECTORTILE">
                                <eaglei-waoLights-layer *ngIf="layer.uiHandle === 'waoLights'" [mapLayer]="layer"></eaglei-waoLights-layer>
                                <eaglei-leaflet-vectortile-layer
                                    *ngIf="layer.uiHandle !== 'waoLights'"
                                    [mapLayer]="layer"></eaglei-leaflet-vectortile-layer>
                            </ng-container>

                            <ng-container *ngSwitchCase="layerTypes.WMS">
                                <eaglei-leaflet-wms-layer *ngIf="!isCustomWmsLayer(layer)" [mapLayer]="layer"></eaglei-leaflet-wms-layer>
                                <eaglei-pnnl-radr-layer *ngIf="layer.uiHandle === 'pnnlRadr'" [mapLayer]="layer"></eaglei-pnnl-radr-layer>
                            </ng-container>

                            <ng-container *ngSwitchCase="layerTypes.ARCGIS">
                                <eaglei-leaflet-arcgis-layer
                                    *ngIf="layer.uiHandle != 'faultLines'"
                                    [mapLayer]="layer"></eaglei-leaflet-arcgis-layer>
                                <eaglei-fault-lines-layer
                                    *ngIf="layer.uiHandle === 'faultLines'"
                                    [mapLayer]="layer"></eaglei-fault-lines-layer>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-container>
</div>
