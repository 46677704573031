import {AfterViewInit, Component, OnInit, ViewChild, computed, signal} from '@angular/core';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {GenAlert} from 'frontend/generated/serverModels/GenAlert';
import {GenCounty} from 'frontend/generated/serverModels/GenCounty';
import {ApplicationConfig} from 'frontend/src/app/classes/application-config';
import {ModalConfig} from 'frontend/src/app/classes/modal-config';
import {AuthenticationService} from 'frontend/src/app/services/authentication.service';
import {DataService} from 'frontend/src/app/services/data.service';
import {ConfirmationComponent} from 'frontend/src/shared/modals/confirmation/confirmation.component';
import {State} from '../../../outage/classes/state';
import {OutageService} from '../../../outage/services/outage.service';
import {LocationNumbers} from '../../classes/location-numbers';
import {UserAlert} from '../../classes/user-alert';
import {SystemAlertModalComponent} from '../../modals/system-alert-modal/system-alert-modal.component';
import {UserDefinedAlertModalComponent} from '../../modals/user-defined-alert-modal/user-defined-alert-modal.component';
import {AlertService} from '../../services/alert.service';
import {GenOutageAggregationLevel} from '../../../../../../generated/serverModels/GenOutageAggregationLevel';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {forkJoin} from 'rxjs';

@Component({
    selector: 'eaglei-user-defined-alerts',
    templateUrl: './user-defined-alerts.component.html',
    styleUrls: ['./user-defined-alerts.component.scss'],
})
export class UserDefinedAlertsComponent implements OnInit, AfterViewInit {
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    public readonly columnNames: string[] = ['select', 'location', 'county', 'threshold', 'percent', 'actions'];
    public dataSource: MatTableDataSource<UserAlert>;

    private femaRegions: number[] = [];
    private counties: GenCounty[] = [];
    private femaNumbers: LocationNumbers[] = [];
    private stateNumbers: LocationNumbers[] = [];
    private countyNumbers: LocationNumbers[] = [];

    private userId: number;

    protected $selected = signal<{[id: number]: boolean}>({});
    protected $allSelected = computed(() => {
        const selected = this.$selected();
        return this.dataSource?.filteredData?.every((data) => !!selected[data.id]);
    });
    protected $hasSelection = computed(() => {
        const selected = this.$selected();
        const selectedLength = this.dataSource?.filteredData?.filter((data) => !!selected[data.id]).length;
        return !!selectedLength && selectedLength !== this.dataSource?.filteredData?.length;
    });

    constructor(
        private alertService: AlertService,
        private auth: AuthenticationService,
        private outageService: OutageService,
        private dialog: MatDialog
    ) {}

    public ngOnInit(): void {
        this.auth.getAuthenticated().subscribe((u) => (this.userId = u.id));

        this.femaRegions = DataService.femaRegions.value.map((fr) => fr.dotregion);
        this.femaRegions.sort((a, b) => a - b);

        this.alertService.getCustomersByAggregation(GenOutageAggregationLevel.state).subscribe((res) => {
            this.stateNumbers = res.map((d) => new LocationNumbers(d.stateId, d.modelCount));
            this.calcFemaNumbers();
            this.findAlertPercent();
        });

        this.alertService.getCustomersByAggregation(GenOutageAggregationLevel.county).subscribe((res) => {
            this.countyNumbers = res.map((d) => new LocationNumbers(d.countyId, d.modelCount));
            this.findAlertPercent();
        });
    }

    public ngAfterViewInit(): void {
        this.outageService.getCountiesForStates([]).subscribe((res) => {
            this.counties = res;
            this.getAlerts(true);
        });
    }

    private getAlerts(initialSort?: boolean): void {
        this.alertService.getUserAlerts().subscribe((res) => {
            this.initData(res.filter((al) => al.subscriberIds.includes(this.userId)).map((al) => new UserAlert(al, this.counties)));

            if (initialSort) {
                // Sorting here to ensure the system alerts get included in the initial sort
                this.dataSource.sort.sort({id: 'location'} as any);
            }
        });
    }

    private initData(data: any) {
        this.$selected.set({});
        if (this.dataSource) {
            this.dataSource.data = data;
        } else {
            this.dataSource = new MatTableDataSource(data);
            this.dataSource.sortingDataAccessor = this.sortAccessor.bind(this);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
        }

        this.findAlertPercent();
    }

    // noinspection JSMethodCanBeStatic
    private sortAccessor(data: UserAlert, column: string): string | number {
        switch (column) {
            case 'location':
                return data.locationName.toLowerCase();
            case 'threshold':
                return data.threshold;
            case 'percent':
                return data.percent;
            default:
                return '';
        }
    }

    public createAlert(): void {
        const config: MatDialogConfig = {
            disableClose: true,
            width: ModalConfig.getModalWidth(1020),
            minHeight: '450px',
            data: {
                userAlerts: this.dataSource.data,
                countyNumbers: this.countyNumbers,
                femaNumbers: this.femaNumbers,
                stateNumbers: this.stateNumbers,
            },
        };

        this.dialog
            .open(UserDefinedAlertModalComponent, config)
            .afterClosed()
            .subscribe(() => {
                this.getAlerts();
            });
    }

    public toggleEdit(alert: UserAlert): void {
        alert.editing = !alert.editing;
    }

    public saveAlert(alert: UserAlert): void {
        if (alert.editing) {
            if (alert.threshold === undefined || alert.threshold <= 0) {
                return;
            }

            const userAlert = new GenAlert(alert);
            this.alertService.updateUserAlert(userAlert).subscribe(() => {
                this.getAlerts();
                alert.editing = false;
            });
        }
    }

    public toggleAll() {
        const selected: {[id: number]: boolean} = {};
        if (!this.$allSelected()) {
            this.dataSource.filteredData.forEach((data) => (selected[data.id] = !this.$allSelected()));
        }
        this.$selected.update(() => selected);
    }

    public toggleSelected(alert: UserAlert, change: MatCheckboxChange) {
        this.$selected.update((selected) => ({...selected, [alert.id]: change.checked}));
    }

    public unsubscribe(alert: UserAlert): void {
        this.openConfirmDialog()
            .afterClosed()
            .subscribe((confirmed) => {
                if (confirmed) {
                    this.alertService.unsubscribeUserAlert(alert.id, [this.userId]).subscribe(() => {
                        this.getAlerts();
                    });
                }
            });
    }

    public unsubscribeAlerts() {
        const toUnsubscribe = this.dataSource.filteredData.filter((d) => !!this.$selected()[d.id]);
        this.openConfirmDialog(toUnsubscribe.length)
            .afterClosed()
            .subscribe((confirmed) => {
                if (confirmed) {
                    forkJoin(toUnsubscribe.map((alert) => this.alertService.unsubscribeUserAlert(alert.id, [this.userId]))).subscribe(() =>
                        this.getAlerts()
                    );
                }
            });
    }

    public openConfirmDialog(count = 1): MatDialogRef<ConfirmationComponent, boolean> {
        return this.dialog.open(ConfirmationComponent, {
            data: {message: `Are you sure you wish to unsubscribe from ${count > 1 ? 'these Alerts' : 'this Alert'}?`},
        });
    }

    public openSystemAlertsModal(): void {
        const config: MatDialogConfig = {
            disableClose: true,
            width: ModalConfig.getModalWidth(),
            minHeight: '450px',
            maxHeight: ModalConfig.getMaxModalHeight(),
            data: {
                userId: this.userId,
                femaNumbers: this.femaNumbers,
                stateNumbers: this.stateNumbers,
                countyNumbers: this.countyNumbers,
                counties: this.counties,
            },
        };

        this.dialog
            .open(SystemAlertModalComponent, config)
            .afterClosed()
            .subscribe(() => this.getAlerts());
    }

    public updateThreshold(event: any, alert: UserAlert, type: 'outage' | 'percent'): void {
        const total = +(alert.type !== 'county' ? this.getTotalCustomers(alert.location) : this.getTotalCustomers(alert.county, 'county'));

        if (type === 'percent') {
            alert.percent = event;

            const percent = alert.percent / 100;

            alert.threshold = Math.floor(total * percent);
        } else if (type === 'outage') {
            alert.threshold = event;

            const flatThreshold = alert.threshold;

            const percent = flatThreshold / total;

            alert.percent = +(percent * 100).toFixed(2);
        }
    }

    private calcFemaNumbers(): void {
        const femaN = [];
        this.femaRegions.forEach((fr) => {
            const regionStates = DataService.getStatesFromFemaIds([fr]);
            let customers = 0;
            regionStates.forEach((rs) => {
                const stateN = this.stateNumbers.find((sn) => sn.locationId === rs.id);

                if (stateN) {
                    customers += stateN.coveredCustomers;
                }
            });

            femaN.push({region: fr, coveredCustomers: customers});
        });

        this.femaNumbers = femaN.map((d) => new LocationNumbers(d.region, d.coveredCustomers));
    }

    private findAlertPercent(): void {
        this.dataSource?.data.forEach((d) => {
            const total = d.type !== 'county' ? this.getTotalCustomers(d.location) : this.getTotalCustomers(d.county, 'county');
            const flatThreshold = d.threshold;

            if (+total <= 0) {
                d.percent = 'N/A' as any;
                return;
            }

            const percent = flatThreshold / +total;

            d.percent = +(percent * 100).toFixed(2);
        });
    }

    public getTotalCustomers(location: State | GenCounty | number, type?: 'region' | 'state' | 'county'): string {
        let retString = '';
        let locationN;

        if (!location) {
            return '';
        }

        if (!type) {
            type = typeof location !== 'number' ? 'state' : 'region';
        }

        if (type === 'state') {
            locationN = this.stateNumbers.find((sn) => sn.locationId === (location as State).id);
        } else if (type === 'county') {
            locationN = this.countyNumbers.find((cn) => cn.locationId === (location as GenCounty).id);
        } else if (type === 'region') {
            locationN = this.femaNumbers.find((fn) => fn.locationId === location);
        }

        retString = locationN ? `${locationN.coveredCustomers}` : '0';

        return retString;
    }

    public checkNumberInput(event: KeyboardEvent, allowDecimal: boolean = false) {
        ApplicationConfig.numberInputValidation(event, false, allowDecimal);
    }
}
