import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

interface MetadataInfo {
    display: string;
    value: any;
    order: number;
}

@Component({
    selector: 'eaglei-eil-metadata-modal',
    templateUrl: './eil-metadata-modal.component.html',
    styleUrls: ['./eil-metadata-modal.component.scss'],
})
export class EilMetadataModalComponent implements OnInit, AfterViewInit {
    // private readonly metadata: any;
    // public readonly pointType: GenDatapointType;
    //
    // public contentLoaded: boolean;
    // public mi: {[key: string]: MetadataInfo} = {};
    // public datapoint: EilData;
    // @ViewChild('content') content: ElementRef;
    //
    // public locationInfo: any;
    // public socialInfo: any;

    // Sections
    // public titleSection: EilMetadata[] = [];
    // public generalSection: EilMetadata[] = [];
    // public locationSection: EilMetadata[] = [];
    // public mediaSection: EilMetadata[] = [];
    // public linksSection: EilMetadata[] = [];
    // public dataSection: EilMetadata[] = [];

    // TODO replace with EIL service
    constructor(
        public dialogRef: MatDialogRef<EilMetadataModalComponent>,
        @Inject(MAT_DIALOG_DATA) private data: any // private http: HttpClient
    ) {
        // const url = `api/eil/metadata?type=${data.type}`;
        // this.metadata = data.metadata;
        // this.pointType = data.type;
        // this.datapoint = data.datapoint;
        // http.get<any[]>(url)
        //     .pipe(map((res) => res.map((d) => new EilMetadata(d))))
        //     .subscribe((res) => {
        //
        // const filtered = res
        //     .filter((metadata) => metadata.display.display)
        //     .sort((a, b) => (a.display.ordering > b.display.ordering ? 1 : -1))
        //     .map((metadata) => metadata.display.handle);
        //
        // Object.keys(this.metadata)
        //     .filter((key) => filtered.includes(key))
        //     .forEach((key) => {
        //         if (key === 'coordinate') {
        //             this.metadata[key].value = `${this.datapoint.latitude}/${this.datapoint.longitude}`;
        //         }
        //         this.mi[key] = this.metadata[key];
        //     });
        //
        // this.getLinks();
        // this.createLocationData();
        //
        // const f = res.filter((md) => md.display.display);
        //
        // this.titleSection = f.filter((md) => md.display.dataGroup === EilMetadataGroup.TITLE);
        // this.generalSection = f.filter((md) => md.display.dataGroup === EilMetadataGroup.GENERAL);
        // this.locationSection = f.filter((md) => md.display.dataGroup === EilMetadataGroup.LOCATION);
        // this.mediaSection = f.filter((md) => md.display.dataGroup === EilMetadataGroup.MEDIA);
        // this.linksSection = f.filter((md) => md.display.dataGroup === EilMetadataGroup.LINKS);
        // this.dataSection = f.filter((md) => md.display.dataGroup === EilMetadataGroup.DATA || md.display.dataGroup === undefined);
        //
        // this.contentLoaded = true;
        // });
    }

    ngOnInit() {}

    ngAfterViewInit() {
        // this.setModalHeight();
        // ApplicationConfig.resizeEvent.pipe(throttleTime(250)).subscribe(() => this.setModalHeight());
    }

    // private setModalHeight() {
    //     (this.content.nativeElement as HTMLElement).style.maxHeight = ModalConfig.getMaxModalHeight() + 'px';
    // }
    //
    // private getLinks(): void {
    //     const checkLink = (url: string) => {
    //         return url.startsWith('http:') || url.startsWith('https:');
    //     };
    //
    //     this.socialInfo = {
    //         facebook: {
    //             iconName: 'fa-facebook-square',
    //             iconType: 'fab',
    //             url: this.mi.facebook && checkLink(this.mi.facebook.value) ? this.mi.facebook.value : undefined,
    //         },
    //         twitter: {
    //             iconName: 'fa-twitter-square',
    //             iconType: 'fab',
    //             url: this.mi.twitter && checkLink(this.mi.twitter.value) ? this.mi.twitter.value : undefined,
    //         },
    //         website: {
    //             iconName: 'fa-globe',
    //             iconType: 'fas',
    //             url: this.mi.website && checkLink(this.mi.website.value) ? this.mi.website.value : undefined,
    //         },
    //         news: {
    //             iconName: 'fa-newspaper',
    //             iconType: 'fas',
    //             url: this.mi.news && checkLink(this.mi.news.value) ? this.mi.news.value : undefined,
    //         },
    //     };
    // }
    //
    // private createLocationData(): void {
    //     if (this.pointType === GenDatapointType.TEARSHEET) {
    //         this.locationInfo = {
    //             address: this.mi.address1.value,
    //             city: `${this.mi.address2.value}, ${this.mi.state.value} ${parseInt(this.mi.zipCode.value)}`,
    //             phone: `${parseInt(this.mi.phone.value)}-${this.mi.phoneNumber.value}`,
    //         };
    //     }
    // }
    //
    // public navigateToUrl(url: string): void {
    //     window.open(url, '_blank');
    // }
    //
    // public getDisplay(info: EilMetadata): string {
    //     const handle = info.display.handle;
    //     return this.mi[handle].display;
    // }
    //
    // public getValue(info: EilMetadata): string {
    //     const handle = info.display.handle;
    //     let value = this.mi[handle].value;
    //
    //     if (handle.includes('Date')) {
    //         value = moment(value).format('MMMM D, YYYY');
    //     } else if (!isNaN(parseFloat(value)) && handle !== 'coordinate') {
    //         value = parseFloat(value).toString();
    //     }
    //
    //     return !value || value === 'null' ? 'Not Available' : value;
    // }
}
