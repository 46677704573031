import {Directive, EventEmitter, HostBinding, HostListener, Input, Output} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';

@Directive({
    selector: '[eagleiFileDragAndDrop]',
})
export class FileDragAndDropDirective {
    @Input() multiple: boolean = true;
    @Input() highlight: boolean = true;
    @Input() allowedExtensions: string[];

    @Output() filesDropped = new EventEmitter<FileList>();

    @HostBinding('class.file-hover') fileHover: boolean;
    @HostBinding('class.file-not-allowed') notAllowed: boolean;

    constructor(private snackBar: MatSnackBar) {}

    @HostListener('dragleave', ['$event']) onDragLeave(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();
        this.fileHover = false;
    }

    @HostListener('dragover', ['$event']) onDragOver(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();

        if (this.highlight) {
            this.fileHover = true;
        }
    }

    @HostListener('drop', ['$event']) onDrop(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();
        const files = this.getFiles(event);

        this.fileHover = false;

        if (!this.multiple && files.length > 1) {
            console.error('Unable to select more than a single file');
            return;
        }

        const filesToEmit = [];

        if (this.allowedExtensions) {
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                const fileExt = file.name.split('.').pop();

                if (this.allowedExtensions.includes(fileExt)) {
                    filesToEmit.push(file);
                } else {
                    const message = `Unable to add ${file.name} because it does not have `;
                    const suffix =
                        this.allowedExtensions.length > 1
                            ? `the following extensions ${this.allowedExtensions.join()}`
                            : `an extension of .${this.allowedExtensions[0]}`;
                    this.snackBar.open(`${message}${suffix}`, '', {
                        verticalPosition: 'top',
                        duration: 3_000,
                    });
                }
            }
        } else {
            this.filesDropped.emit(files);
        }
    }

    // noinspection JSMethodCanBeStatic
    private getFiles(event: DragEvent): FileList {
        return event.dataTransfer.files;
    }
}
