
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!


export class GenOutageSummaryTrend {

    actualCustomers: number;
    collectedCount: number;
    countyId: number;
    countyName: string;
    coveredCustomers: number;
    endCount: number;
    femaId: number;
    femaName: string;
    modelCount: number;
    startCount: number;
    stateId: number;
    stateName: string;
    subUtilityId: number;
    utilityId: number;
    utilityName: string;

    constructor();
    constructor( jsonObj: any );
    constructor( genOutageSummaryTrend: GenOutageSummaryTrend);

    constructor( input?: GenOutageSummaryTrend ) {
        if ( input && typeof input !== 'number' ) {
            this.actualCustomers = input.actualCustomers; // int [number]
            this.collectedCount = input.collectedCount; // int [number]
            this.countyId = input.countyId; // int [number]
            this.countyName = input.countyName; // string [string]
            this.coveredCustomers = input.coveredCustomers; // int [number]
            this.endCount = input.endCount; // int [number]
            this.femaId = input.femaId; // int [number]
            this.femaName = input.femaName; // string [string]
            this.modelCount = input.modelCount; // int [number]
            this.startCount = input.startCount; // int [number]
            this.stateId = input.stateId; // int [number]
            this.stateName = input.stateName; // string [string]
            this.subUtilityId = input.subUtilityId; // int [number]
            this.utilityId = input.utilityId; // int [number]
            this.utilityName = input.utilityName; // string [string]
       }
    }

    static jsonResponseToList(arr: any[]): GenOutageSummaryTrend[] {
        if (arr && arr.map) {
          return arr.map(a => new GenOutageSummaryTrend(a));
        } else {
          return [];
        }
    }
}

