import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ReleaseNote} from '../classes/release-note';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Feedback} from '../classes/feedback';

@Injectable({
    providedIn: 'root',
})
export class HelpService {
    private readonly feedbackHeaders = new HttpHeaders().set('Content-Type', 'application/json');

    constructor(private http: HttpClient) {}

    public getReleaseNotes(): Observable<ReleaseNote[]> {
        const url = `api/release-note`;
        const mf = (res: any[]) => {
            return res.map((note) => {
                return new ReleaseNote(note);
            });
        };

        return this.http.get<any[]>(url).pipe(map(mf));
    }

    public submitUserFeedback(feedback: Feedback): Observable<any> {
        const url = '/api/feedback';
        return this.http.post(url, feedback, {headers: this.feedbackHeaders});
    }

    public getAboutPageText(): Observable<string> {
        const url = `api/about`;
        return this.http.get(url, {responseType: 'text'});
    }
}
