import {AfterViewInit, Directive, Input, OnDestroy, inject} from '@angular/core';
import {MatOption} from '@angular/material/core';
import {MatSelect} from '@angular/material/select';
import {Subject} from 'rxjs';
import {takeUntil, filter} from 'rxjs/operators';

function handleUndefined(value: unknown[] | null | undefined): unknown[] {
    return value ?? [];
}

/**
 * A directive to assist implementing a "Select All" option in a multiselect MatSelect.
 * @note This option's value will be included in & require filtering from the selected values
 */
@Directive({selector: 'mat-option[selectAll]', standalone: true})
export class SelectAllOptionDirective implements AfterViewInit, OnDestroy {
    private matSelect = inject(MatSelect);
    private matOption = inject(MatOption);
    private destroyed$ = new Subject<void>();

    /** All the available options */
    @Input({transform: handleUndefined}) allOptions: unknown[] = [];

    /** Comparison function for determining if the values of 2 options are equal. Useful for non-primitive values. */
    @Input() compareWith: (o1: unknown, o2: unknown) => boolean = (o1, o2) => o1 === o2;

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    ngAfterViewInit(): void {
        const parentSelect = this.matSelect;
        const parentControl = parentSelect.ngControl.control;

        // Handles interacting with the "Select All" option
        this.matOption.onSelectionChange
            .pipe(
                takeUntil(this.destroyed$),
                filter((event) => event.isUserInput)
            )
            .subscribe((event) => {
                if (event.source.selected) {
                    parentControl?.setValue(this.allOptions);
                    this.matOption.select();
                } else {
                    parentControl?.setValue([]);
                    this.matOption.deselect();
                }
            });

        // Handles interacting with other options
        parentSelect.optionSelectionChanges
            .pipe(
                takeUntil(this.destroyed$),
                filter((change) => change.isUserInput)
            )
            .subscribe((change) => {
                if (!this.compareWith(change.source.value, this.matOption.value)) {
                    if (!change.source.selected) {
                        this.matOption.deselect();
                    } else if (parentControl?.value.length === this.allOptions.length) {
                        this.matOption.select();
                    }
                }
            });

        // Handles when previous values are initialized
        setTimeout(() => {
            if (parentControl?.value && this.allOptions.length && parentControl.value.length === this.allOptions.length) {
                this.matOption.select(false);
            }
        });
    }
}
