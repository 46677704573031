
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

export class GenAggregationLevel {
    static readonly fema: GenAggregationLevel = new GenAggregationLevel('fema');
    static readonly state: GenAggregationLevel = new GenAggregationLevel('state');
    static readonly county: GenAggregationLevel = new GenAggregationLevel('county');
    static readonly utility: GenAggregationLevel = new GenAggregationLevel('utility');
    static readonly zip: GenAggregationLevel = new GenAggregationLevel('zip');

    public readonly name: string;

    private constructor(name: string) {
        this.name = name;
    }

    static forName(name: string) {
        if (name === 'fema') return GenAggregationLevel.fema;
        if (name === 'state') return GenAggregationLevel.state;
        if (name === 'county') return GenAggregationLevel.county;
        if (name === 'utility') return GenAggregationLevel.utility;
        if (name === 'zip') return GenAggregationLevel.zip;
        throw new Error('Unexpected value for GenAggregationLevel:' + name);
    }

    static values(): GenAggregationLevel[] {
        return [
            GenAggregationLevel.fema,
            GenAggregationLevel.state,
            GenAggregationLevel.county,
            GenAggregationLevel.utility,
            GenAggregationLevel.zip,
        ];
    }

    toJSON(): string {
        return this.name;
    }

    toString(): string {
        return this.name;
    }
}

