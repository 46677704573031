import {MomentDatePipe} from '../../shared/pipes/moment-date.pipe';
import * as moment from 'moment';
import * as echarts from 'echarts';
import {ApplicationConfig} from './application-config';
import {EagleiBaseChart} from './charts/base-chart';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

// Added because msSaveBlob wasnt recognized by the TS Compiler when testing
declare global {
    interface Navigator {
        msSaveBlob?: (blob: any, defaultName?: string) => boolean;
    }
}

export class FileDownload {
    static downloadFile(fileName: string, encodedData: string) {
        const downloadTag = document.createElement('a');

        downloadTag.href = encodedData;
        downloadTag.download = fileName;

        if (ApplicationConfig.onIosMobile()) {
            downloadTag.target = '_blank';
        }

        document.body.appendChild(downloadTag);
        downloadTag.click();
        document.body.removeChild(downloadTag);
    }

    static formatCsvCell(cellValue: string | number): string {
        if (cellValue !== 0 && (!cellValue || cellValue === 'null')) {
            return 'Not Available';
        } else {
            return `"${cellValue.toString().replace(/"/g, '""')}"`;
        }
    }

    /**
     * Adds generic information to a csv and exports the file.
     * @param filename The name if the file to be exported.
     * @param data The body of the file as a comma delimited string
     * @param [attributionUrl] Optional: adds the attribution url to the bottom of the file.
     * @param [generateCreationTime] Optional: if true, adds the date the report was created. if false, nothing is added. defaults to true.
     */
    static downloadCSV(filename: string, data: string, attributionUrl?: string, generateCreationTime: boolean = true) {
        const env = (window as any).eaglei.env;
        let exportFilename = `${filename}_${moment().format('YYYYMMDDhhmmss')}.csv`;

        if (env !== 'production') {
            data = `Data is from ${env} environment.\n\n` + data;
            exportFilename = `${env}_${exportFilename}`;
        }

        if (attributionUrl) {
            const attr = attributionUrl.split(',').join('\n');

            data += `\n\n Attribution URL: ${attr}`;
        }

        if (generateCreationTime) {
            data += `\n\n Report Created At ${moment().format('M/D/YYYY hh:mm A')}`;
        }

        const blob = new Blob([data], {type: 'text/csv'});

        if (navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, exportFilename);
        } else {
            const url = window.URL.createObjectURL(blob);
            FileDownload.downloadFile(exportFilename, url);
        }
    }

    static exportChartAsPNG(
        chart: EagleiBaseChart | echarts.ECharts,
        fileName: string,
        title: string,
        attributionUrl?: string,
        legendText?: string,
        legend?: SVGElement
    ) {
        const element = (chart as any).getDom
            ? (chart as echarts.ECharts).getDom().getElementsByTagName('canvas')[0]
            : (chart as EagleiBaseChart).getCanvasElement();

        const momentPipe = new MomentDatePipe();
        const canvas: HTMLCanvasElement = element;
        const newCanvas = document.createElement('canvas');
        const height = canvas.getAttribute('height');
        const width = canvas.getAttribute('width');
        const context: CanvasRenderingContext2D | null = newCanvas.getContext('2d');
        let legendDrawn = false;
        let intervalHandle: any;
        let newHeight = 0;

        const titleHeight = 50;
        const chartHeight = parseInt(height || '0') + 20;
        let attrHeight = 20;
        const attrArray: string[] = [];

        if (attributionUrl) {
            let currentLine = '';
            const maxWidth = width ? parseInt(width, 10) / 5 : 100;
            attributionUrl.split(',').forEach((attribution) => {
                if (currentLine.length + attribution.length > maxWidth) {
                    attrArray.push(currentLine.slice());
                    currentLine = ``;
                }
                currentLine += `${attribution}, `;
            });
            if (currentLine.length > 0) {
                attrArray.push(currentLine);
            }

            attrArray.sort((a, b) => (a.length < b.length ? 1 : -1));

            attrArray[0] = attrArray[0].slice(0, -2);
            attrHeight = Math.max(attrHeight, attrArray.length * 15);
        }

        if (width !== null && height !== null) {
            newHeight = titleHeight + chartHeight + attrHeight;

            if (legend) {
                newHeight += legend.getBoundingClientRect().height;
            }

            newCanvas.setAttribute('height', newHeight.toString());
            newCanvas.setAttribute('width', width);
        }

        if (context && height !== null && width !== null) {
            context.fillStyle = '#eee';
            context.fillRect(0, 0, parseInt(width, 10), newHeight);
            context.fillStyle = '#000';

            context.drawImage(canvas, 0, titleHeight);

            context.fillText(`Chart Created At ${momentPipe.transform(moment())}`, 10, newHeight - attrHeight);

            if (attributionUrl) {
                attrArray.forEach((attr, i) => {
                    (context as CanvasRenderingContext2D).fillText(attr, 10, newHeight - (5 * (i + 1) + i * 10));
                });
            }

            if (legendText) {
                const lines = legendText.split(',');
                lines.forEach((line: string, index: number) => {
                    if (width && context) {
                        context.fillText(line, parseInt(width, 10) - 150, newHeight - (5 * (index + 1) + index * 10));
                    }
                });
            }

            if (legend) {
                const originalHtml = legend.innerHTML.slice();
                legend.innerHTML = legend.innerHTML.replace(/['\u2122]/g, '(TM)');
                const xml = new XMLSerializer().serializeToString(legend);
                // TODO 5484 The issue is the trademark symbol
                // https://stackoverflow.com/questions/23223718/failed-to-execute-btoa-on-window-the-string-to-be-encoded-contains-characte

                const tmp = btoa(xml);
                // const tmp1 = btoa(unescape(encodeURIComponent(xml)));
                const image64 = `data:image/svg+xml;base64,${tmp}`;

                legend.innerHTML = originalHtml;

                const img = new Image();
                img.onload = () => {
                    const yPos = newHeight - legend.getBoundingClientRect().height - 10;
                    const xPos = parseInt(width) - legend.getBoundingClientRect().width - 10;
                    context.drawImage(img, xPos, yPos);
                    legendDrawn = true;
                };

                img.src = image64;
            }

            context.textAlign = 'center';
            context.font = '20pt Roboto Arial Sans-Serif';
            context.fillText(title, parseInt(width, 10) / 2, titleHeight / 2);
        }

        let filename = `${fileName}_${moment().format('YYYYMMDDhhmmss')}.png`;

        const env = (window as any).eaglei.env;
        if (env !== 'production') {
            filename = `${env}_${filename}`;
        }

        const save = () => {
            if (navigator.msSaveBlob) {
                navigator.msSaveBlob((newCanvas as any).msToBlob(), filename);
            } else {
                newCanvas.toBlob((blob: Blob | null) => {
                    const blobUrl = window.URL.createObjectURL(blob);
                    FileDownload.downloadFile(filename, blobUrl);
                }, 'image/png');
            }
        };

        if (legend) {
            intervalHandle = setInterval(() => {
                if (legendDrawn) {
                    clearInterval(intervalHandle);
                    save();
                }
            }, 100);
        } else {
            save();
        }
    }

    static downloadDocument(url: string, filename: string, onError: (error: any) => any) {
        const iframe: HTMLIFrameElement = document.querySelector('#eaglei-download-i-frame');
        iframe.onerror = onError;
        if (filename.endsWith('.pdf')) {
            window.open(url, '_blank');
        } else {
            if (ApplicationConfig.onIosMobile()) {
                FileDownload.downloadFile(filename, url);
            } else {
                iframe.src = url;
            }
        }
    }

    static exportExcel(data: any, fileName: string): void {
        const env = (window as any).eaglei.env;
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
        const wb: XLSX.WorkBook = {Sheets: {['data']: ws}, SheetNames: ['data']};
        const excelBuffer: any = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});

        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const blob: Blob = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(blob, `${env}_${fileName}_${moment().format('YYYYMMDDhhmmss')}.xlsx`);
    }
}
