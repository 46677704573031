import {Component} from '@angular/core';
import {GenLegendType} from 'frontend/generated/serverModels/GenLegendType';
import {HttpInterceptorService} from 'frontend/src/app/services/http-interceptor.service';
import {tap} from 'rxjs/operators';
import {EagleILayer} from '../../classes/eagle-i-layer';
import {LayerService} from '../../services/layer.service';
import {LeafletLegendService} from '../../services/leaflet-legend.service';
import {WastewaterTreatmentSource} from '../../sources/wastewater-treatment-source';

@Component({
    selector: 'eaglei-wastewater-treatment-layer',
    templateUrl: './wastewater-treatment-layer.component.html',
    styleUrls: ['../layers.scss', './wastewater-treatment-layer.component.scss'],
})
export class WastewaterTreatmentLayerComponent extends EagleILayer<WastewaterTreatmentSource> {
    constructor(private layerService: LayerService, private legendService: LeafletLegendService) {
        super();
    }

    afterViewInit(): void {
        this.mapLayer.legend.type = GenLegendType.HARD_CODED;
    }

    /**
     * Toggles the Layer on or off
     * @param activeState State of the layer
     * @param zoomToFeatures should zoom to feautes
     */
    public toggleLayer(activeState: boolean, zoomToFeatures?: boolean): void {
        this.isActive = activeState;
        if (activeState) {
            if (!this.layerSource) {
                this.layerSource = new WastewaterTreatmentSource(this.mapLayer);
            }

            this.getWastewaterTreatment();
        } else {
            HttpInterceptorService.clearInterceptor(this.mapLayer.interceptorKey);
            this.layerSource.removeFromMap();
        }
    }

    /**
     * Gets the Wastewater Treatment data
     */
    private getWastewaterTreatment(): void {
        this.mapLayer.startLoading();

        const params = {
            f: 'geojson',
            where: '1=1',
            outFields: '*',
        };

        const paramStr = Object.keys(params)
            .map((key) => `${key}=${params[key]}`)
            .join('&');
        const dataUrl = `${this.mapLayer.serviceurl}/1/query?${paramStr}`;

        HttpInterceptorService.clearInterceptor(this.mapLayer.interceptorKey);
        HttpInterceptorService.pendingRequests[this.mapLayer.interceptorKey] = this.layerService
            .getJsonFromUrl(dataUrl, true)
            .pipe(tap(() => HttpInterceptorService.deleteFromInterceptor(this.mapLayer.interceptorKey)))
            .subscribe((res) => {
                this.layerSource.processFeatures(res.features);
                this.layerSource.addToMap();
                this.mapLayer.endLoading();
            });
    }
}
