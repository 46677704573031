import {Component, Input, OnInit} from '@angular/core';
import {MapLayerCategory} from '../../classes/map-layer-category';
import {GenServiceType} from '../../../../../../generated/serverModels/GenServiceType';
import {MapService} from '../../../map/services/map.service';
import {ResizeService} from '../../../../../shared/services/resize.service';
import {LocationSearchService} from '../../../map/services/location-search.service';
import {IdentifyService} from '../../../map/services/identify.service';
import {ApplicationConfig} from 'frontend/src/app/classes/application-config';
import {LeafletMapLayer} from '../../classes/leaflet-map-layer';

@Component({
    selector: 'eaglei-layer-list',
    templateUrl: './layer-list.component.html',
    styleUrls: ['./layer-list.component.scss'],
})
export class LayerListComponent implements OnInit {
    @Input() layerCategories: MapLayerCategory[];

    public readonly layerTypes = GenServiceType;
    private readonly customWmsUiHandles = ['pnnlRadr'];

    constructor(
        private resizeService: ResizeService,
        private locationSearchService: LocationSearchService,
        private identifyService: IdentifyService
    ) {}

    ngOnInit() {}

    /**
     * Clears all layers from the map and resets the filters.
     */
    public clearAllLayers(): void {
        MapService.getEagleiLayers().forEach((l) => {
            // TODO: Find better way to write this check
            if (!ApplicationConfig.onLite()) {
                l.eagleiLayer.reset.next(undefined);
            } else if (ApplicationConfig.onLite() && (l as any)?.layerHandle !== 'nationalOutageMap') {
                l.eagleiLayer.reset.next(undefined);
            }
        });
        this.resizeService.closeResizeElement.next(undefined);
        this.identifyService.identifyGroup.clearLayers();
        this.locationSearchService.searchLayer.clearLayers();
        this.locationSearchService.routeLayer.clearLayers();
    }

    /**
     * Returns true if the category has any layers in it.
     * @param category The category being checked
     */
    public hasLayers(category: MapLayerCategory): boolean {
        return category.groups.some((g) => {
            return g.layers.length > 0;
        });
    }

    public isCustomWmsLayer(layer: LeafletMapLayer): boolean {
        return this.customWmsUiHandles.includes(layer.uiHandle);
    }
}
