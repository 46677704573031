import {inject, Injectable} from '@angular/core';
import * as moment from 'moment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ResponseWrapper} from '../../app/classes/response-wrapper';
import {DataService} from '../../app/services/data.service';
import {GenOutageAggregationLevel} from '../../../generated/serverModels/GenOutageAggregationLevel';
import {State} from '../../app/modules/outage/classes/state';
import {DataPoint} from '../../app/classes/data-point';
import {ApplicationConfig} from '../../app/classes/application-config';
import {OutageChartData} from '../../app/modules/outage/interfaces/outage-chart-data.interface';

@Injectable({
    providedIn: 'root',
})
export class ChartDataService {
    private httpClient = inject(HttpClient);

    constructor() {}

    public newGetNomChartData(
        type: GenOutageAggregationLevel,
        interval: number,
        filters: State[] = [],
        startDate: moment.Moment = moment().subtract(1, 'days'),
        endDate: moment.Moment = moment(),
        hourInterval?: number
    ): Observable<OutageChartData[]> {
        let params = new HttpParams()
            .set('start', ApplicationConfig.roundMinute(startDate).format())
            .set('end', ApplicationConfig.roundMinute(endDate).format());

        if (hourInterval) {
            params = params.append('hourInterval', hourInterval);
        }

        if (filters.length !== 0) {
            if (type === GenOutageAggregationLevel.fema) {
                const locations = DataService.getFemaRegionsFromStateNames(filters.map((s) => s.abbreviation)).join();
                params = params.append('region', locations);
            } else {
                params = params.append('state', filters.map((state) => state.abbreviation).join());
            }
        }

        if (type === GenOutageAggregationLevel.county) {
            params = params.append('allCounties', true);
        } else if (type === GenOutageAggregationLevel.zip) {
            params = params.append('allZips', true);
        }

        const url = `/api/outagesummary/${type.toString()}summary`;

        // Based on the api the data property could be a
        // GenOutageDataFemaSummary, GenOutageDataStateSummary, GenOutageDataCountySummary,
        // GenOutageDataUtilitySummary, or GenOutageDataZipSummary
        return this.httpClient.get<ResponseWrapper>(url, {params}).pipe(
            map((response) =>
                response.data.map((data) => {
                    return {
                        actualCustomers: data.actualCustomers,
                        coveredCustomers: data.coveredCustomers,
                        customersOut: data.customersOut,
                        fipsCode: data.fipsCode,
                        hasOverrideData: data.hasOverrideData,
                        startTimestampValue: moment(data.startTimeStamp).valueOf(),
                        startTimeStamp: moment(data.startTimeStamp),
                        stateAbbreviation: data.stateAbbreviation,
                        countyName: data.countyName,
                        zipcode: data.zipCode,
                        modelCount: data.modelCount,
                    };
                })
            )
        );
    }

    public getNomChartDataPoints(
        type: GenOutageAggregationLevel,
        states: State[] = [],
        startDate: moment.Moment = moment().subtract(1, 'days'),
        endDate: moment.Moment = moment()
    ): Observable<DataPoint<any>[]> {
        let params = new HttpParams()
            .set('start', ApplicationConfig.roundMinute(startDate).format())
            .set('end', ApplicationConfig.roundMinute(endDate).format())
            .set('type', type.toString());

        if (states.length !== 0) {
            params = params.append('state', states.map((state) => state.abbreviation).join());
        }

        const url = `/api/outagesummary/nom-chart-data-points`;

        return this.httpClient.get<ResponseWrapper>(url, {params}).pipe(
            map((response) =>
                response.data.map((data) => {
                    const timeStamp = moment(data.startTimeStamp);
                    const res = new DataPoint({
                        total: data.totalCustomers,
                        percent: data.percentOut,
                        customersModeled: data.customersModeled,
                        customersOut: data.customersOut,
                        x: timeStamp.valueOf(),
                        y: data.customersOut,
                        timeStamp,
                    });
                    res.x = res.data.x;
                    res.y = res.data.y;

                    return res;
                })
            )
        );
    }

    public getTimeBars(
        data: any[],
        getKey: (v: any) => number,
        createDataPoint: (v: any) => any,
        updateDatapoint: (cur: any, v: any) => void,
        stepSizeMs: number = 1000 * 60 * 15
    ): any[] {
        const dataMap = new Map<number, DataPoint>();

        data.forEach((element) => {
            const key = getKey(element);

            let val = dataMap.get(key);

            if (!val) {
                val = createDataPoint(element);
            }
            updateDatapoint(val, element);

            dataMap.set(key, val);
        });

        const keys = Array.from(dataMap.keys()).sort();
        const pointsToAdd = [];
        for (let i = 0; i < keys.length - 1; i++) {
            const dp1 = keys[i];
            const dp2 = keys[i + 1];

            const diffInMs = Math.abs(moment(dp1).diff(moment(dp2)));
            const numberOfSteps = diffInMs / stepSizeMs;

            if (numberOfSteps > 1) {
                let j = 1;
                while (j < numberOfSteps) {
                    const nk = dp1 + j * stepSizeMs;
                    const dp = new DataPoint({
                        x: nk,
                        y: 0,
                        generatedData: true,
                    });
                    pointsToAdd.push(dp);
                    j++;
                }
            }
        }

        return Array.from(dataMap.values())
            .concat(pointsToAdd)
            .sort((a, b) => (a.data.x > b.data.x ? 1 : -1));
    }

    public getTimeLine(
        data: any[],
        getKey: (v: any) => number,
        createDataPoint: (v: any) => DataPoint,
        updateDatapoint: (cur: DataPoint, v: any) => void
    ): DataPoint[] {
        const dataMap = new Map<number, DataPoint>();

        data.forEach((element) => {
            const key = getKey(element);

            let val = dataMap.get(key);

            if (!val) {
                val = createDataPoint(element);
            }
            updateDatapoint(val, element);

            dataMap.set(key, val);
        });

        const pointsToAdd = [];

        return Array.from(dataMap.values())
            .concat(pointsToAdd)
            .sort((a, b) => (a.data.x > b.data.x ? 1 : -1));
    }
}
