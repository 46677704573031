export enum AffectedAssetType {
    FDIC_INSURED_BANKS = 'FDIC_Insured_Banks',
    NCUA_INSURED_CREDIT_UNIONS = 'NCUA_Insured_Credit_Unions',
    ELECTRIC_SUBSTATIONS = 'Electric_Substations',
    NATURAL_GAS_PIPELINES = 'Natural_Gas_Pipelines',
    NATURAL_GAS_RECEIPT_DELIVERY_POINTS = 'Natural_Gas_Receipt_Delivery_Points',
    POWER_PLANTS = 'Power_Plants',
    TRANSMISSION_ELECTRIC_SUBSTATIONS = 'Transmission_Electric_Substations',
    TRANSMISSION_LINES = 'Transmission_Lines',
    WASTEWATER_TREATMENT_PLANTS = 'Wastewater_Treatment_Plants',
    EPA_ER_FACILITY_RESPONSE_PLAN_FACILITIES = 'EPA_ER_Facility_Response_Plan_Facilities',
    EPA_TOXIC_RELEASE_INVENTORY_FACILITIES = 'EPA_Toxic_Release_Inventory_Facilities',
    HOSPITALS = 'Hospitals',
    NURSING_HOMES = 'Nursing_Homes',
    PHARMACIES = 'Pharmacies',
    URGENT_CARE_FACILITIES = 'Urgent_Care_Facilities',
    CHILDCARE_CENTERS = 'ChildCare_Centers',
    COLLEGE_UNIVERSITY_CAMPUSES = 'College_University_Campuses',
    PUBLIC_SCHOOLS = 'Public_Schools',
    COURTHOUSES = 'Courthouses',
    FIRE_STATIONS = 'Fire_Stations',
    LOCAL_LAW_ENFORCEMENT_LOCATIONS = 'Local_Law_Enforcement_Locations',
    MILITARY_BASES = 'Military_Bases',
    AIRCRAFT_LANDING_FACILITIES = 'Aircraft_Landing_Facilities',
    RAIL_ROADS = 'Rail_Roads',
    PUBLIC_REFRIGERATED_WAREHOUSES = 'Public_Refrigerated_Warehouses',
}
