import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {RecentEvent} from '../../classes/recent-event';
import {BaseModal} from '../../../../classes/base-modal';

@Component({
    selector: 'eaglei-recent-event-modal',
    templateUrl: './recent-event-modal.component.html',
    styleUrls: ['./recent-event-modal.component.scss'],
})
export class RecentEventModalComponent extends BaseModal {
    public event: RecentEvent;

    constructor(public dialogRef: MatDialogRef<RecentEventModalComponent>, @Inject(MAT_DIALOG_DATA) data: RecentEvent) {
        super();
        this.event = data;
    }

    afterInit() {}
}
