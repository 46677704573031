<div class="container" #content [style.--direction]="layout" [style.--gap]="gap" [style.--basis]="basis">
    <mat-form-field
        class="filter-select ignore-icon-position"
        [appearance]="appearance"
        [ngClass]="{active: active, 'multiple-inputs': showTime}">
        <mat-label>{{ startPlaceholder }}</mat-label>
        <input
            class="date-selector"
            matInput
            [matDatepicker]="startDatepicker"
            [max]="endDate"
            [min]="minDate"
            [(ngModel)]="startDate"
            (dateChange)="updateDateRange('start', $event.value)"
            (click)="toggleStartDatepicker($event)"
            [formControl]="startControl"
            [disabled]="!active"
            readonly />

        <mat-error *ngIf="startControl?.hasError('invalid')"> Date can not be set in the future </mat-error>

        <mat-datepicker-toggle matSuffix [for]="startDatepicker"></mat-datepicker-toggle>
        <mat-datepicker #startDatepicker [startAt]="startDate" [disabled]="!active"></mat-datepicker>

        <mat-select
            *ngIf="showTime"
            class="time-selector"
            (selectionChange)="updateTime($event.value, startDate, true, 'start')"
            [(ngModel)]="startTime"
            [disabled]="!active">
            <mat-option *ngFor="let step of timeSteps" [value]="step" [disabled]="!isValidStartTime(step)">
                {{ step.display }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field
        *ngIf="!singlePicker"
        class="filter-select ignore-icon-position"
        [appearance]="appearance"
        [ngClass]="{active: active, 'multiple-inputs': showTime}">
        <mat-label>{{ endPlaceholder }}</mat-label>
        <input
            matInput
            [matDatepicker]="endDatepicker"
            class="date-selector"
            placeholder="End Date"
            [min]="startDate"
            [max]="maxEndDate"
            [(ngModel)]="endDate"
            (dateChange)="updateDateRange('end', $event.value)"
            (click)="toggleEndDatepicker($event)"
            [formControl]="endControl"
            [disabled]="!active"
            readonly />

        <mat-error>
            <ng-container *ngIf="endControl?.hasError('beforeStart')"> End date can not before start date </ng-container>

            <ng-container *ngIf="endControl?.hasError('invalid')"> Date can not be set in the future </ng-container>
        </mat-error>

        <mat-select
            *ngIf="showTime"
            class="time-selector"
            (selectionChange)="updateTime($event.value, endDate, true, 'end')"
            [(ngModel)]="endTime"
            [disabled]="!active">
            <mat-option *ngFor="let step of timeSteps" [value]="step" [disabled]="!isValidEndTime(step)">{{ step.display }} </mat-option>
        </mat-select>

        <mat-datepicker-toggle matSuffix [for]="endDatepicker"></mat-datepicker-toggle>
        <mat-datepicker #endDatepicker [startAt]="endDate" [disabled]="!active"></mat-datepicker>
    </mat-form-field>

    <button
        *ngIf="showApplyButton"
        [disabled]="!areDatesValid()"
        mat-raised-button
        color="primary"
        (click)="applyChanges()"
        class="ellipsis wide">
        {{ buttonText }}
    </button>
</div>
