import {GenCounty} from '../../../../../generated/serverModels/GenCounty';

export class County extends GenCounty {
    // A property used for when a county is in a dropdown to hold the selected status
    public selected: boolean;

    constructor(county?: County) {
        super(county);
    }
}
