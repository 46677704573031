
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!


export class GenUtilityUrl {

    id: number;
    subUtilityId: number;
    url: string;
    urlType: string;
    utilityId: number;

    constructor();
    constructor( jsonObj: any );
    constructor( genUtilityUrl: GenUtilityUrl);
    constructor( id: number );

    constructor( input?: GenUtilityUrl|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            this.subUtilityId = input.subUtilityId; // int [number]
            this.url = input.url; // string [string]
            this.urlType = input.urlType; // string [string]
            this.utilityId = input.utilityId; // int [number]
       }
    }

    static jsonResponseToList(arr: any[]): GenUtilityUrl[] {
        if (arr && arr.map) {
          return arr.map(a => new GenUtilityUrl(a));
        } else {
          return [];
        }
    }
}

