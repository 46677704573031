import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {EagleiBaseChart} from 'frontend/src/app/classes/charts/base-chart';
import {FileDownload} from 'frontend/src/app/classes/file-download';
import {PopoverElement} from 'frontend/src/app/classes/popover-element';
import * as moment from 'moment';
import {filter, takeUntil} from 'rxjs/operators';
import {BaseWidget} from '../../classes/base-widget';
import {WidgetService} from '../../services/widget.service';
import {OngService} from '../../../../services/ong.service';
import {ngUndergroundStorageWidget} from '../../../../../integrations/ong/classes/ong-attributions-text';

interface UndergroundStorage {
    id: number;
    post_date: moment.Moment;
    region: string;
    report_date: moment.Moment;
    type: string;
    value: number;
}

@Component({
    selector: 'eaglei-underground-storage-widget',
    templateUrl: './underground-storage-widget.component.html',
    styleUrls: ['./underground-storage-widget.component.scss'],
})
export class UndergroundStorageWidgetComponent extends BaseWidget implements AfterViewInit {
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild('chartTarget') chartTarget: ElementRef<HTMLDivElement>;

    private resData: UndergroundStorage;

    // Table Properties
    public readonly columns: string[] = ['region', 'value'];
    public storage: MatTableDataSource<any>;
    public selectedType: string = '';
    public types: string[] = [
        'Canadian Imports',
        'Implied Storage',
        'Industrial Demand',
        'LNG Sendouts',
        'Mexican Exports',
        'Other',
        'Power Demand',
        'Production',
        'Res/Com Demand',
    ];
    public selectedDate: moment.Moment = moment().startOf('day');

    // Chart Properties
    private barColors: Map<string, string> = new Map<string, string>();
    public baseChart = new EagleiBaseChart();
    public showMask: boolean = true;

    constructor(private widgetElement: ElementRef, protected ongService: OngService, private widgetService: WidgetService) {
        super(widgetElement);
        this.attributionUrl = 'https://www.woodmac.com/';
        this.attributionText = 'Genscape: A Wood Mackenzie Business';
    }

    ngAfterViewInit(): void {
        this.setBarColors();
        this.initializeChart();
        this.getStorage();
        this.attributionModalText = ngUndergroundStorageWidget;
        this.widgetName = 'Daily Macro Supply & Demand';

        WidgetService.resize
            .pipe(
                filter((widget) => this.item.x === widget.x && this.item.y === widget.y),
                takeUntil(this.destroy$)
            )
            .subscribe(() => {
                this.resize();
                this.baseChart.eChart.resize();
            });

        this.selectedType = 'Production';
    }

    getStorage() {
        this.ongService.getUndergroundStorage(this.selectedDate).subscribe((res) => {
            this.resData = res;
            const storageReduce = this.reduceData(res);
            this.initializeData(storageReduce);
        });
    }

    // Data formatting
    /**
     * This condenses the groups of region data. If there are more than 1 region, the values are added.
     * @param data The data response from the api.
     */
    reduceData(data) {
        const reduced = [];
        const resData = JSON.parse(JSON.stringify(data));

        resData.forEach((item) => {
            const exists = reduced.some((e) => e.region === item.region);

            if (this.selectedType.includes(item.type) && exists) {
                const index = reduced.map((e) => e.region).indexOf(item.region);
                reduced[index].value = reduced[index].value + item.value;
            }
            if (this.selectedType.includes(item.type) && !exists) {
                reduced.push({region: item.region, value: item.value});
            }
        });

        return reduced;
    }

    destroyWidget(): void {}

    // Table Methods
    /**
     * Sets the data used in the table and also creates all the interactions on the table
     * @param data A list of recent events to be in the table.
     */
    private initializeData(data: string[]): void {
        if (this.storage) {
            this.storage.data = data;
        } else {
            this.storage = new MatTableDataSource(data);
            this.storage.sortingDataAccessor = this.dataAccessor.bind(this);
            this.storage.sort = this.sort;
        }
        this.createChart();
    }

    // noinspection JSMethodCanBeStatic
    /**
     * The logic behind how the columns are sorted.
     * @param data The recent event being sorted
     * @param header The column name being sorted on.
     */
    private dataAccessor(data: UndergroundStorage, header: string): string | number {
        switch (header) {
            case 'region':
                return data.region.toLowerCase();
            case 'value':
                return data.value;
            default:
                return data.value;
        }
    }

    // Export Methods
    /**
     * Exports the table as a CSV File
     */
    public exportTable(): void {
        let data: string = ['Region', 'Storage Capacity Change (Bcf)'].join() + '\n';
        this.storage.filteredData.forEach((storage) => {
            const info = [FileDownload.formatCsvCell(storage.region), FileDownload.formatCsvCell(storage.value)];
            data += `${info.join()}\n`;
        });

        FileDownload.downloadCSV('undergroundStorageWidget', data, this.attributionUrl);
    }

    /**
     * Exports the chart as a PNG
     */
    public exportChart(): void {
        const title = 'Natural Gas Daily Macro Supply & Demand';

        FileDownload.exportChartAsPNG(this.baseChart, title, title, this.attributionUrl);
    }

    // Filter Methods
    /**
     * Called when the date range is updated, filters data.
     * @param date The new date.
     */
    public updateDateRange(date: moment.Moment): void {
        this.selectedDate = date;
        this.getStorage();
    }

    // Filter Methods
    /**
     * Called when the types are updated, filters data.
     * @param types The types array
     */
    public updateStorage(types: string): void {
        this.selectedType = types;
        const storageReduce = this.reduceData(this.resData);
        this.initializeData(storageReduce);
    }

    // Chart Methods
    /**
     * initializes the chart with some basic settings
     */
    private initializeChart() {
        this.baseChart.initializeEChart(this.chartTarget.nativeElement, true, 'Region', 'Storage Capacity Change (Bcf)');

        this.baseChart.getYAxis().nameTextStyle = {
            padding: [0, 0, 0, 100],
        };

        this.baseChart.getXAxis().axisLabel.interval = 0;
        this.baseChart.getXAxis().axisLabel.rotate = 30;
        this.baseChart.getXAxis().axisLabel.color = '#707070';
        this.baseChart.getXAxis().axisLabel.formatter = (label) => label;
        this.baseChart.getXAxis().nameTextStyle = {
            padding: [25, 0, 0, 0],
        };

        this.baseChart.getGrid().bottom = 55;

        this.baseChart.setOptions();
    }

    /**
     * createChart adds data, styles, and popup information to the initialized chart
     * This should be called each time the data is updated
     */
    private createChart(): void {
        const xValues: string[] = [];
        const yValues = [];

        this.storage.filteredData.forEach((dp) => {
            xValues.push(dp.region);

            const yData = {
                value: dp.value,
                itemStyle: {
                    color: this.barColors.get(dp.region),
                },
                popoverData: [
                    new PopoverElement().setTitle().setValue(dp.region),
                    new PopoverElement().setLabel('Storage Capacity Change (Bcf)').setValue(dp.value.toFixed(2).toString()),
                ],
                exportedData: {
                    x: dp.region,
                    y: dp.value,
                },
            };

            yValues.push(yData);
        });

        this.showMask = this.selectedType.length === 0 || this.storage.filteredData.length === 0;

        this.baseChart.eChartOptions.xAxis['data'] = xValues;
        this.baseChart.eChartOptions.series = [
            {
                type: 'bar',
                data: yValues,
            },
        ];

        this.baseChart.eChart.setOption(this.baseChart.eChartOptions);
    }

    private setBarColors(): void {
        this.barColors.set('US', '#9522ca');
        this.barColors.set('South Central', '#02aef3');
        this.barColors.set('Pacific', '#a1fcd8');
        this.barColors.set('Mountain', '#4100c1');
        this.barColors.set('Midwest', '#007794');
        this.barColors.set('East', '#940061');
    }
}
