<eaglei-leaflet-layer-header [layer]="this" (activeToggled)="toggleLayer($event)" [hideLegend]="true"></eaglei-leaflet-layer-header>

<ng-container *ngIf="isActive">
    <div #legendElement>
        <div class="vertical-legend">
            <div>
                <span class="triangle major">&#9650;</span>
                <span>Major</span>
            </div>
            <div>
                <span class="triangle minor">&#9650;</span>
                <span>Minor</span>
            </div>
            <div>
                <span class="triangle other">&#9650;</span>
                <span>Other/Nonclassified</span>
            </div>
        </div>
    </div>
</ng-container>
