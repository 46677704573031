import {GenWidgetName} from 'frontend/generated/serverModels/GenWidgetName';
import {WidgetDetail} from '../../../../widget/classes/widget-detail';
import {Widget} from '../../../../widget/classes/widget';
import {GenCommunityPermission} from '../../../../../../../generated/serverModels/GenCommunityPermission';

export const analyticWidgets = [
    {
        x: 0,
        y: 0,
        cols: 8,
        rows: 3,
        widgetName: GenWidgetName.EVENT_HEADOUT,
        detail: new WidgetDetail().setWidget(new Widget().setCommunityPermissions(GenCommunityPermission.HEADOUT)),
    },
    {
        x: 0,
        y: 3,
        cols: 3,
        rows: 3,
        widgetName: GenWidgetName.UAS_POLE_DETECTION,
        detail: new WidgetDetail().setWidget(new Widget().setCommunityPermissions(GenCommunityPermission.UAS)),
    },
    {
        x: 3,
        y: 3,
        cols: 3,
        rows: 3,
        widgetName: GenWidgetName.PNNL_RADR,
        detail: new WidgetDetail().setWidget(new Widget().setCommunityPermissions(GenCommunityPermission.RADR)),
    },
    {
        x: 6,
        y: 6,
        cols: 3,
        rows: 3,
        widgetName: GenWidgetName.WAO_TABLE,
        detail: new WidgetDetail().setWidget(new Widget().setCommunityPermissions(GenCommunityPermission.WAO)),
    },
    {
        x: 0,
        y: 9,
        cols: 9,
        rows: 3,
        widgetName: GenWidgetName.URBAN_NET,
        detail: new WidgetDetail().setWidget(new Widget().setCommunityPermissions(GenCommunityPermission.URBANNET)),
    },
];
