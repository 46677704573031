
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import * as moment from 'moment';

export class GenEOCStateOverride {

    id: number;
    endTime: moment.Moment;
    startTime: moment.Moment;
    stateCollectionId: number;
    stateId: number;
    stateName: string;

    constructor();
    constructor( jsonObj: any );
    constructor( genEOCStateOverride: GenEOCStateOverride);
    constructor( id: number );

    constructor( input?: GenEOCStateOverride|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            if ( input.endTime && typeof input.endTime === 'string' ) {
                this.endTime = moment(input.endTime).utc();
            } else {
                this.endTime = input.endTime;
            }
            if ( input.startTime && typeof input.startTime === 'string' ) {
                this.startTime = moment(input.startTime).utc();
            } else {
                this.startTime = input.startTime;
            }
            this.stateCollectionId = input.stateCollectionId; // int [number]
            this.stateId = input.stateId; // int [number]
            this.stateName = input.stateName; // string [string]
       }
    }

    static jsonResponseToList(arr: any[]): GenEOCStateOverride[] {
        if (arr && arr.map) {
          return arr.map(a => new GenEOCStateOverride(a));
        } else {
          return [];
        }
    }
}

