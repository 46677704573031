import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {EagleiBaseChart} from 'frontend/src/app/classes/charts/base-chart';
import {DataPoint} from 'frontend/src/app/classes/data-point';
import {FileDownload} from 'frontend/src/app/classes/file-download';
import {IPowerEstimate} from 'frontend/src/app/interfaces/ong/power-estimate.interface';
import * as moment from 'moment';
import {filter, takeUntil} from 'rxjs/operators';
import {Report} from '../../../report/classes/report';
import {BaseWidget} from '../../classes/base-widget';
import {WidgetService} from '../../services/widget.service';
import {ChartBounds} from 'frontend/src/app/classes/chart-bounds';
import {ChartDataService} from 'frontend/src/shared/services/chart-data.service';
import {OngService} from '../../../../services/ong.service';
import {EventSummaryFilters} from '../../../system-event/classes/event-summary-filters';
import {dailyPowerEstimateWidget} from '../../../../../integrations/ong/classes/ong-attributions-text';

@Component({
    selector: 'eaglei-power-estimate-widget',
    templateUrl: './power-estimate-widget.component.html',
    styleUrls: ['./power-estimate-widget.component.scss'],
})
export class PowerEstimateWidgetComponent extends BaseWidget implements AfterViewInit {
    @ViewChild('chartTarget', {static: true}) chartTarget: ElementRef;
    @ViewChild('content', {static: true}) content: ElementRef;

    public baseChart = new EagleiBaseChart<any, any, IPowerEstimate>();
    public barColors: Map<string, string> = new Map<string, string>();
    private chartData: any[] = [];
    private groupedData: Map<string, IPowerEstimate[]> = new Map<string, IPowerEstimate[]>();
    public selectedDate: moment.Moment = moment();

    public showMask: boolean = true;

    constructor(private widgetElement: ElementRef, protected ongService: OngService, private chartService: ChartDataService) {
        super(widgetElement);
        this.attributionUrl = 'https://www.woodmac.com/';
        this.attributionText = 'Genscape: A Wood Mackenzie Business';
    }

    protected handleEventFilterChange(eventFilters: EventSummaryFilters): void {
        this.selectedDate = moment(eventFilters.date);

        this.getDailyPowerEstimate();
    }

    public ngAfterViewInit(): void {
        this.setBarColors();
        this.getDailyPowerEstimate();
        this.initChart();
        this.attributionModalText = dailyPowerEstimateWidget;
        this.widgetName = 'Daily Power Estimate';

        const setChartHeight = () => {
            const chartHeight = this.getHeight(this.content) - 55;
            this.setHeight(this.chartTarget, chartHeight);
            this.baseChart.eChart.resize();
        };

        WidgetService.resize
            .pipe(
                filter((widget) => this.item.x === widget.x && this.item.y === widget.y),
                takeUntil(this.destroy$)
            )
            .subscribe(() => {
                this.resize();
                setChartHeight();
            });

        setChartHeight();
    }

    destroyWidget(): void {
        if (this.baseChart) {
            this.baseChart.hideAllPopovers();
        }
    }

    private getDailyPowerEstimate(): void {
        this.ongService.getPowerEstimate(this.selectedDate).subscribe((res) => {
            this.chartData = res;

            this.groupedData = new Map<string, IPowerEstimate[]>();
            this.chartData.forEach((d: IPowerEstimate) => {
                if (d.region === 'CONUS') {
                    d.region = 'US';
                }

                if (this.groupedData.has(d.region)) {
                    this.groupedData.get(d.region).push(d);
                } else {
                    this.groupedData.set(d.region, [d]);
                }
            });

            this.renderChart();
        });
    }

    private initChart(): void {
        this.baseChart.interactivePopover = false;
        this.baseChart.initializeEChart(this.chartTarget.nativeElement, true, 'Date', 'Gas Demand for Power Consumption (Mcf)');

        const opts: echarts.EChartOption = {
            tooltip: {
                trigger: 'axis',
                backgroundColor: '#FCFCFC',
                borderColor: '#A6A6A6',
                borderWidth: 1,
                textStyle: {
                    color: '#707070',
                },
            },
        };

        this.baseChart.getYAxis().nameTextStyle = {
            padding: [0, 0, 0, 150],
        };

        this.baseChart.getXAxis().axisLabel.formatter = (label: string) => {
            return moment(label).format('M/D/YYYY');
        };

        Object.assign(this.baseChart.eChartOptions, opts);
    }

    private summarizeChartData(estimate: IPowerEstimate[]): ChartBounds<any> {
        const getKey = (d: IPowerEstimate) => moment(d.gas_date).valueOf();

        const createDatapoint = (d: IPowerEstimate) => {
            return {
                x: moment(d.gas_date).valueOf(),
                y: 0,
                data: {
                    val: d.estimate,
                    region: d.region,
                    timestamp: moment(d.gas_date).valueOf(),
                },
            };
        };

        const updateDatapoint = (current: any, d: IPowerEstimate) => {
            current.y += d.estimate || 0;
        };

        const bounds = new ChartBounds<DataPoint, IPowerEstimate>();
        bounds.rawData = estimate;
        bounds.data = this.chartService.getTimeLine(estimate, getKey, createDatapoint, updateDatapoint);

        return bounds;
    }

    private renderChart(): void {
        const lineMap: Map<string, ChartBounds<any>> = new Map<string, ChartBounds<any>>();
        Array.from(this.groupedData.keys()).forEach((k) => {
            lineMap.set(k, this.summarizeChartData(this.groupedData.get(k)));
        });

        this.showMask = Array.from(lineMap.values()).every((d) => !d);

        const getxValues = (bounds: ChartBounds<any>) =>
            bounds.data.map((dp) => moment(dp.x).format('M/D/YYYY')).sort((a, b) => moment(a).valueOf() - moment(b).valueOf());
        const getyValues = (bounds: ChartBounds<any>) =>
            bounds.data
                .map((dp) => {
                    return {
                        value: dp.y,
                        itemStyle: {
                            color: this.barColors.get(dp.data.region),
                        },
                        // popoverData: getPopoverData(dp.data),
                        exportData: {
                            x: moment(dp.x).format(),
                            y: dp.y,
                            region: dp.data.region,
                        },
                    };
                })
                .sort((a, b) => moment(a.exportData.x).valueOf() - moment(b.exportData.x).valueOf());

        this.baseChart.eChartOptions.xAxis['data'] = getxValues(lineMap.get('US'));

        this.baseChart.eChartOptions.series = Array.from(lineMap.keys()).map((k) => {
            return {
                type: 'line',
                name: k,
                lineStyle: {
                    color: this.barColors.get(k),
                },
                data: getyValues(lineMap.get(k)),
            };
        });

        this.baseChart.eChart.setOption(this.baseChart.eChartOptions);
    }

    public updateDateRange(date: moment.Moment): void {
        this.selectedDate = moment(date);
        this.getDailyPowerEstimate();
    }

    public exportChartAsCsv(): void {
        let data: string = ['Region', 'Gas Demand for Power Consumption (Mcf)', 'Date'].join() + '\n';

        this.baseChart.eChart.getOption().series.forEach((series) => {
            series.data.forEach((d: any) => {
                data +=
                    [
                        FileDownload.formatCsvCell(d.exportData.region),
                        FileDownload.formatCsvCell(d.exportData.y),
                        FileDownload.formatCsvCell(Report.momentPipe.transform(moment(d.exportData.x))),
                    ].join() + '\n';
            });
        });

        FileDownload.downloadCSV('dailyPowerEstimate', data, this.attributionUrl);
    }

    public exportChart(): void {
        const title = 'Daily Power Estimate';

        FileDownload.exportChartAsPNG(this.baseChart, title, title, this.attributionUrl);
    }

    private setBarColors(): void {
        this.barColors.set('US', '#9522CA');
        this.barColors.set('East', '#940061');
        this.barColors.set('Mountain', '#4100C1');
        this.barColors.set('Midwest', '#007794');
        this.barColors.set('Pacific', '#A1FCD8');
        this.barColors.set('South Central', '#02AEF3');
    }

    public getLegendKeys(): string[] {
        return Array.from(this.groupedData.keys());
    }
}
