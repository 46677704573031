<div class="chart-container">
    <div class="chart-header">
        <p class="title">{{ chartTitle() }}</p>
        <mat-icon fontSet="fas" fontIcon="fa-cog" inline [matMenuTriggerFor]="managementMenu"></mat-icon>
    </div>

    <div class="chart" *ngIf="chartConfig() && isOpen()">
        <eaglei-nom-chart
            [aggregationLevel]="chartConfig().aggregationLevel"
            [startDate]="chartConfig().startDate"
            [endDate]="chartConfig().endDate"
            [states]="chartConfig().states">
        </eaglei-nom-chart>
    </div>

    <div class="attribution">
        <a [href]="attributionUrl()" target="_blank" [innerHTML]="attributionText() | sanitize"></a>
    </div>
</div>

<mat-menu #managementMenu="matMenu">
    <button mat-menu-item (click)="exportChart()">Export Chart</button>
    <button mat-menu-item (click)="exportTrendChart()">Export Trend Data</button>
</mat-menu>
