<eaglei-leaflet-layer-header [layer]="this" (activeToggled)="toggleLayer($event)" [hideLegend]="true"></eaglei-leaflet-layer-header>

<ng-container *ngIf="isActive">
    <div #legendElement>
        <div class="legends">
            <div class="icon-container">
                <div class="icon no-light"></div>
                <span class="label">No Lights</span>
            </div>
            <div class="icon-container">
                <div class="icon increased"></div>
                <span class="label">Increased Lights</span>
            </div>
            <div class="icon-container">
                <div class="icon cloud"></div>
                <span class="label">Cloud</span>
            </div>

            <div class="icon-container">
                <mat-icon inline fontSet="fas" fontIcon="fa-circle-info"></mat-icon>
                <span class="label info">Zoom in for better results</span>
            </div>
        </div>
    </div>

    <div class="filters">
        <div *ngIf="!!displayErrorMessage" class="error-message">
            <mat-icon inline fontSet="fas" fontIcon="fa-circle-exclamation" class="label warn"></mat-icon>
            <span class="label info warn">{{ displayErrorMessage }}</span>
        </div>

        <mat-form-field>
            <mat-label>Event</mat-label>
            <mat-select [formControl]="eventControl">
                <mat-optgroup *ngFor="let group of waoEvents | keyvalue" [label]="group.key | titlecase">
                    <mat-option *ngFor="let event of group.value" [value]="event">{{ event.name }}</mat-option>
                </mat-optgroup>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Date</mat-label>
            <mat-select [formControl]="dateControl" [compareWith]="dateCompare">
                <mat-option *ngFor="let date of dateList" [value]="date">
                    {{ date | momentDate : 'MM-DD-YYYY' : false : true }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Light Status</mat-label>
            <mat-select multiple [formControl]="lightControl">
                <mat-option [value]="-1">No Lights</mat-option>
                <mat-option [value]="1">Increased Lights</mat-option>
                <mat-option [value]="3">Cloud</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</ng-container>
