import {AfterViewInit, Component, Inject, ViewChild} from '@angular/core';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {DataService} from 'frontend/src/app/services/data.service';
import {LocationNumbers} from '../../classes/location-numbers';
import {AlertService} from '../../services/alert.service';
import {SelectionModel} from '@angular/cdk/collections';
import {SystemAlert} from '../../classes/system-alert';
import {SystemAlertType} from '../../enums/system-alert-type';
import {State} from '../../../outage/classes/state';
import {FemaRegion} from '../../../outage/classes/fema-region';
import {toSignal} from '@angular/core/rxjs-interop';
import {map} from 'rxjs/operators';

@Component({
    selector: 'eaglei-system-alert-modal',
    templateUrl: './system-alert-modal.component.html',
    styleUrls: ['./system-alert-modal.component.scss'],
})
export class SystemAlertModalComponent implements AfterViewInit {
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    public readonly columnNames: string[] = ['select', 'name', 'threshold', 'percent'];
    public dataSource: MatTableDataSource<SystemAlert>;
    public selection = new SelectionModel<SystemAlert>(true, [], true, (a, b) => a.id === b.id && a.threshold === b.threshold);

    private femaNumbers: {[id: string | number]: number} = {};
    private stateNumbers: {[id: string | number]: number} = {};

    private filterLocations: State[] = DataService.states.getValue();

    private userId: number;

    readonly $isAllSelected = toSignal(
        this.selection.changed.asObservable().pipe(map(() => this.selection.selected.length === this.dataSource.filteredData.length))
    );

    constructor(
        public dialogRef: MatDialogRef<SystemAlertModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private alertService: AlertService
    ) {
        this.userId = data.userId;

        data.femaNumbers.forEach((number) => (this.femaNumbers[number.locationId] = number.coveredCustomers));
        data.stateNumbers.forEach((number) => (this.stateNumbers[number.locationId] = number.coveredCustomers));
    }

    public ngAfterViewInit(): void {
        this.alertService.getUserAlerts().subscribe((res) => {
            const systemAlerts = res
                .filter((alert) => !alert.ownerId && !alert.subscriberIds.includes(this.userId))
                .map((alert) => {
                    const systemAlert = new SystemAlert(alert);

                    systemAlert.details?.forEach((detail) => {
                        let totalCustomers: number;
                        if (systemAlert.type === SystemAlertType.FEMA) {
                            const region = DataService.femaRegions.value.find((fr) => fr.dotregion === detail);
                            totalCustomers = this.femaNumbers[region.dotregion];

                            systemAlert.location = region;
                            systemAlert.locationName = region.name;
                        } else if (systemAlert.type === SystemAlertType.STATE) {
                            const state = DataService.states.value.find((s) => s.id === detail);
                            totalCustomers = this.stateNumbers[state.id];

                            systemAlert.locationName = state.name;
                            systemAlert.location = state;
                        }

                        systemAlert.locationTotalCustomers = totalCustomers;
                        const percent = systemAlert.threshold / systemAlert.locationTotalCustomers;
                        systemAlert.thresholdPercent = isFinite(percent) ? percent : undefined;
                    });

                    return systemAlert;
                });

            this.initData(systemAlerts);
        });
    }

    private initData(data: any) {
        if (this.dataSource) {
            this.dataSource.data = data;
        } else {
            this.dataSource = new MatTableDataSource(data);
            this.dataSource.sortingDataAccessor = this.sortAccessor.bind(this);
            this.dataSource.filterPredicate = this.filterPredicate.bind(this);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
        }
    }

    private filterPredicate(data: SystemAlert): boolean {
        if (this.filterLocations.length <= 0) {
            return true;
        }

        const stateNames = this.filterLocations.map((s) => s.name);
        const regions = DataService.getFemaRegionsFromStateNames(stateNames);

        if (data.type === SystemAlertType.FEMA) {
            return regions.includes((data.location as FemaRegion).dotregion);
        } else if (data.type === SystemAlertType.STATE) {
            return stateNames.includes((data.location as State).name);
        }
        return false;
    }

    // noinspection JSMethodCanBeStatic
    private sortAccessor(data: SystemAlert, column: string): string | number {
        switch (column) {
            case 'name':
                return data.locationName.toLowerCase();
            case 'threshold':
                return data.threshold;
            case 'percent':
                return data.thresholdPercent;
            default:
                return '';
        }
    }

    public filterAlerts(locations: State[]): void {
        this.filterLocations = locations;
        this.dataSource.filter = ' ';
        this.selection.clear();
    }

    public subscribe(): void {
        this.alertService.subscribeToAlertsByUserId(this.userId, this.selection.selected).subscribe(() => {
            this.dialogRef.close();
        });
    }

    /**
     * Toggles all the rows on or off
     */
    public toggleAllRows(): void {
        if (this.$isAllSelected()) {
            this.selection.clear();
        } else {
            this.selection.setSelection(...this.dataSource.filteredData);
        }
    }

    /**
     * Toggles a single row on or off.
     * @param event The checkbox event to see if the row should be checked or unchecked.
     * @param row The currently clicked row
     */
    public toggleRow(event: MatCheckboxChange, row: SystemAlert): void {
        event.checked ? this.selection.select(row) : this.selection.deselect(row);
    }
}
