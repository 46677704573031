<div class="dialog-header">
    <span>Utility Lookup</span>

    <mat-icon fontIcon="close" class="clickable" (click)="dialogRef.close()"></mat-icon>
</div>

<div class="dialog-content" #content>
    <p>
        Enter an Address you would like to see the utilities for. The address must have at least a zip code, state, or both, i.e. 123 State
        St, NY
    </p>

    <eaglei-address-lookup-input (addressEvent)="manualSearch($event)"></eaglei-address-lookup-input>

    <div class="table-container" [class.invisible]="(loading | async) || noResults">
        <table
            #materialTable
            mat-table
            [dataSource]="dataSource"
            matSort
            [matSortDisableClear]="true"
            matSortActive="utilityName"
            matSortDirection="asc">
            <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
            <tr mat-row *matRowDef="let row; columns: columnNames; let odd = odd"></tr>

            <ng-container matColumnDef="utilityName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Utility Name
                    <eaglei-table-sort-header [columnName]="'utilityName'" [sort]="sort"></eaglei-table-sort-header>
                </th>
                <td mat-cell *matCellDef="let info">{{ info.utilityName }}</td>
            </ng-container>

            <ng-container matColumnDef="countyName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    County
                    <eaglei-table-sort-header [columnName]="'countyName'" [sort]="sort"></eaglei-table-sort-header>
                </th>
                <td mat-cell *matCellDef="let info">{{ info.countyName }}</td>
            </ng-container>

            <ng-container matColumnDef="stateName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    State / Territory
                    <eaglei-table-sort-header [columnName]="'stateName'" [sort]="sort"></eaglei-table-sort-header>
                </th>
                <td mat-cell *matCellDef="let info">{{ info.stateName }}</td>
            </ng-container>

            <ng-container matColumnDef="utilityId">
                <th mat-header-cell *matHeaderCellDef>Utility Id</th>
                <td mat-cell *matCellDef="let info">{{ info.utilityId }}</td>
            </ng-container>

            <ng-container matColumnDef="subUtilityId">
                <th mat-header-cell *matHeaderCellDef>SubUtility Id</th>
                <td mat-cell *matCellDef="let info">{{ info.subUtilityId || 'N/A' }}</td>
            </ng-container>
        </table>
    </div>

    <mat-paginator
        [pageSizeOptions]="[10, 25, 50, 100]"
        showFirstLastButtons
        [class.invisible]="(loading | async) || noResults"></mat-paginator>

    <div *ngIf="loading | async" class="loading-mask">
        <span>Loading Utilities...</span>
    </div>

    <div *ngIf="!(loading | async) && noResults" class="loading-mask">
        <span *ngIf="!manualLookup">No utilities found...</span>
        <span *ngIf="manualLookup">Enter an Address to search...</span>
    </div>
</div>

<div class="dialog-actions">
    <button mat-raised-button color="primary" [disabled]="!dataSource.filteredData?.length || (loading | async)" (click)="exportCsv()">
        Export
    </button>
</div>
