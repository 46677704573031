
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import * as moment from 'moment';

export class GenMaxImpact {

    maxOutages: number;
    maxOutagesTime: moment.Moment;
    maxUtilitiesAffected: number;
    maxUtilitiesAffectedTime: moment.Moment;

    constructor();
    constructor( jsonObj: any );
    constructor( genMaxImpact: GenMaxImpact);

    constructor( input?: GenMaxImpact ) {
        if ( input && typeof input !== 'number' ) {
            this.maxOutages = input.maxOutages; // int [number]
            if ( input.maxOutagesTime && typeof input.maxOutagesTime === 'string' ) {
                this.maxOutagesTime = moment(input.maxOutagesTime).utc();
            } else {
                this.maxOutagesTime = input.maxOutagesTime;
            }
            this.maxUtilitiesAffected = input.maxUtilitiesAffected; // int [number]
            if ( input.maxUtilitiesAffectedTime && typeof input.maxUtilitiesAffectedTime === 'string' ) {
                this.maxUtilitiesAffectedTime = moment(input.maxUtilitiesAffectedTime).utc();
            } else {
                this.maxUtilitiesAffectedTime = input.maxUtilitiesAffectedTime;
            }
       }
    }

    static jsonResponseToList(arr: any[]): GenMaxImpact[] {
        if (arr && arr.map) {
          return arr.map(a => new GenMaxImpact(a));
        } else {
          return [];
        }
    }
}

