<eaglei-leaflet-layer-header [layer]="this" (activeToggled)="toggleLayer($event)" [hideLegend]="true"> </eaglei-leaflet-layer-header>

<ng-container *ngIf="isActive && layerSource?.legendScale; else loadingMask">
    <div #legendElement>
        <div class="legend-container">
            <div class="legend-values" *ngFor="let color of layerSource.legendRange" [style.background-color]="color"></div>
        </div>

        <div class="legend-values">
            <span>
                <b>{{ layerSource.legendDomain[0] }}</b>
            </span>
            <span class="right-align"
                ><b>{{ layerSource.legendDomain[1] }}</b>
            </span>
            <div class="text-right">All values in <i>thousands (MMbl/d)</i></div>
        </div>
    </div>
</ng-container>

<div class="hide" [class.show]="isActive">
    <eaglei-filter-container
        *ngIf="mapLayer.filters"
        [filters]="mapLayer.filters"
        (filterChange)="updateFilters($event)"></eaglei-filter-container>
</div>

<ng-template #loadingMask>
    <span *ngIf="isActive" class="loading-text">Loading Legend...</span>
</ng-template>
