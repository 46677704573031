import {Component, OnInit} from '@angular/core';
import {BasePopover} from '../base-popover';

@Component({
    selector: 'eaglei-oil-barrel-flow-popover',
    templateUrl: './oil-barrel-flow-popover.component.html',
    styleUrls: ['./oil-barrel-flow-popover.component.scss', '../layer-popover.scss'],
})
export class OilBarrelFlowPopoverComponent extends BasePopover {
    public flowData: any;

    constructor() {
        super();
    }

    onInit() {}

    updateData() {
        this.flowData = this.data.properties;
    }

    afterInit() {}
}
