import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IFilterComponentInterface} from '../../interfaces/filter.interface';
import {ISelectOption} from '../../interfaces/select-option.interface';
import {UntypedFormControl} from '@angular/forms';
import {debounce} from 'rxjs/operators';
import {interval} from 'rxjs';

@Component({
    selector: 'eaglei-select-filter',
    templateUrl: './select-filter.component.html',
    styleUrls: ['./select-filter.component.scss'],
})
export class SelectFilterComponent implements OnInit, IFilterComponentInterface<ISelectOption[]> {
    @Input() title: string = '';
    public options: ISelectOption[] = [];

    private _optionListJSON: string;

    @Input() set optionListJSON(input: string) {
        try {
            this.options = JSON.parse(input) as ISelectOption[];
        } catch (e) {
            console.error('ERROR: Failed to parse JSON string to get select options', e, input);
            this.options = [];
        }

        this.updateOptionListSelected();
        this.title.toString();
    }

    get optionListJSON() {
        return this._optionListJSON;
    }

    private _defaultOptionValue: string | string[];
    @Input() set defaultOptionValue(value: string | string[]) {
        const defaultOption = this.isMultiAllowed ? this.options.map((o) => o.value) : '';

        this._defaultOptionValue = value || (defaultOption as any);
        this.updateOptionListSelected();
    }

    get defaultOptionValue(): string | string[] {
        return this._defaultOptionValue;
    }

    @Input() isMultiAllowed: boolean = false;
    @Output() filterChange = new EventEmitter<ISelectOption[]>();

    @Input() debounceTime: number = 250;
    selectControl = new UntypedFormControl();

    constructor() {}

    private updateOptionListSelected() {
        const checkDefault = (value: string) => {
            if (this.defaultOptionValue === undefined) {
                return false;
            }

            if (this.isMultiAllowed) {
                return (this.defaultOptionValue as any[]).includes(value);
            }
            return value === this.defaultOptionValue;
        };

        if (this.options) {
            const defaultOptions = this.options.filter((option) => checkDefault(option.value as string));

            if (this.isMultiAllowed) {
                this.selectControl.setValue(defaultOptions, {emitModelToViewChange: true});
            } else if (defaultOptions.length) {
                this.selectControl.setValue(defaultOptions[0], {emitModelToViewChange: true});
            }
        }
    }

    private emit(): void {
        let options: ISelectOption[];
        options = Array.isArray(this.selectControl.value) ? this.selectControl.value : [this.selectControl.value];
        this.filterChange.emit(options);
    }

    ngOnInit(): void {
        // Emitting the initial values
        // this.emit();

        this.selectControl.valueChanges.pipe(debounce(() => interval(this.debounceTime))).subscribe(() => this.emit());
    }
}
