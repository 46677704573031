import {GenReportRequest} from '../../../generated/serverModels/GenReportRequest';
import {ReportRequestParameter} from './report-request-parameter';

export class ReportRequest extends GenReportRequest {
    public parameters: ReportRequestParameter[];

    constructor(request?: ReportRequest) {
        super(request);
    }
}
