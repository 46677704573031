<div class="dialog-header test-design">
    <span>Review User</span>
    <mat-chip-set *ngIf="!user.isValid()">
        <mat-chip>
            <div class="chip-content">
                <mat-icon fontSet="fas" fontIcon="fa-info-circle" class="invalid" inline></mat-icon>
                <span class="title">Invalid</span>
            </div>
        </mat-chip>
    </mat-chip-set>
    <div></div>
    <button mat-icon-button (click)="closeModal()">
        <mat-icon fontSet="fas" fontIcon="fa-times"></mat-icon>
    </button>
</div>

<div class="dialog-content" #content>
    <div class="section user-info" [class.with-poc]="user.needsPoc">
        <div>
            <p class="header sub-title">User Information</p>
            <p>
                <span>{{ user.fullName }}</span> <span class="sub-title"> ({{ user.username }})</span>
            </p>
            <p class="user-info">{{ user.organization }}</p>
            <p class="user-info">{{ user.email }}</p>
        </div>

        <div *ngIf="user.needsPoc">
            <p class="header sub-title">Point Of Contact</p>
            <p>{{ user.poc.name }}</p>
            <p class="user-info">{{ user.poc.organization }}</p>
            <p class="user-info">{{ user.poc.email }}</p>
        </div>
    </div>

    <div class="reason-container section">
        <p class="sub-title section-header">Reason For Account</p>
        <p>{{ user.reason }}</p>
    </div>

    <div class="role-container">
        <ng-container *ngIf="user.isValid(); else userNotValid">
            <p class="sub-title section-header">Roles</p>

            <div class="role-list">
                <mat-checkbox
                    *ngFor="let role of roles"
                    [checked]="user.hasRole(role, true)"
                    (change)="updateSelectedRoles($event.checked, role)">
                    {{ role | roleDefinition }}
                </mat-checkbox>
            </div>
        </ng-container>
        <ng-template #userNotValid>
            <p class="sub-title">Unable to edit information for a user with invalid fields</p>
        </ng-template>
    </div>
</div>

<div class="dialog-actions">
    <button mat-stroked-button (click)="gotoProfile()">Go To Profile</button>

    <div
        *ngIf="!user.enabled"
        matTooltip="Can not perform this action on an unapproved user"
        matTooltipPosition="above"
        [matTooltipDisabled]="user.approved"
        matTooltipShowDelay="250">
        <button mat-flat-button color="primary" (click)="enableUser()" [disabled]="!user.approved">Enable User</button>
    </div>
</div>
