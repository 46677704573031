import {AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {GenWidgetSettingType} from 'frontend/generated/serverModels/GenWidgetSettingType';
import {LandingGridItem} from 'frontend/src/app/landing/classes/landing-grid-item';
import {WidgetSetting} from '../../classes/widget-setting';
import {BaseModal} from '../../../../classes/base-modal';

@Component({
    selector: 'eaglei-configure-widget-ong-planned-outages-modal',
    templateUrl: './configure-widget-ong-planned-outages-modal.component.html',
    styleUrls: ['./configure-widget-ong-planned-outages-modal.component.scss'],
})
export class ConfigureWidgetOngPlannedOutagesModalComponent extends BaseModal implements OnInit, AfterViewInit {
    @ViewChild('content', {static: true}) content: ElementRef;

    public outageType = ['future', 'active', 'past'];

    public selectedOutage = 'active';

    constructor(
        public dialogRef: MatDialogRef<ConfigureWidgetOngPlannedOutagesModalComponent>,
        @Inject(MAT_DIALOG_DATA) private item: LandingGridItem
    ) {
        super();
    }

    public ngOnInit(): void {
        if (!!this.item.detail.settings.find((s) => s.type === GenWidgetSettingType.ONG_PLANNED_OUTAGE)) {
            this.selectedOutage = this.item.detail.settings.find((s) => s.type === GenWidgetSettingType.ONG_PLANNED_OUTAGE).value as any;
        }
    }

    public changeOutage(outages: any): void {
        this.selectedOutage = outages;
    }

    public disableUpdate(): boolean {
        return !this.outageType.includes(this.selectedOutage);
    }

    public configureWidget(): void {
        if (this.item.detail.settings.find((s) => s.type === GenWidgetSettingType.ONG_PLANNED_OUTAGE)) {
            this.item.detail.settings.find((s) => s.type === GenWidgetSettingType.ONG_PLANNED_OUTAGE).value = this.selectedOutage;
        } else {
            const setting = new WidgetSetting({
                type: GenWidgetSettingType.ONG_PLANNED_OUTAGE,
                value: this.selectedOutage,
            } as any);
            this.item.detail.settings.push(setting);
        }

        this.dialogRef.close(this.item);
    }

    // This is called after ngAfterViewInit
    afterInit() {}
}
