
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import { GenSystemEventPhaseType } from './GenSystemEventPhaseType';
import * as moment from 'moment';

export class GenSystemEventPhase {

    id: number;
    endTime: moment.Moment;
    eventId: number;
    startTime: moment.Moment;
    type: GenSystemEventPhaseType;

    constructor();
    constructor( jsonObj: any );
    constructor( genSystemEventPhase: GenSystemEventPhase);
    constructor( id: number );

    constructor( input?: GenSystemEventPhase|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            if ( input.endTime && typeof input.endTime === 'string' ) {
                this.endTime = moment(input.endTime).utc();
            } else {
                this.endTime = input.endTime;
            }
            this.eventId = input.eventId; // int [number]
            if ( input.startTime && typeof input.startTime === 'string' ) {
                this.startTime = moment(input.startTime).utc();
            } else {
                this.startTime = input.startTime;
            }
            this.type = (typeof input.type === 'string') ? GenSystemEventPhaseType.forName(<string> input.type) : <any> input.type; // GenSystemEventPhaseType
       }
    }

    static jsonResponseToList(arr: any[]): GenSystemEventPhase[] {
        if (arr && arr.map) {
          return arr.map(a => new GenSystemEventPhase(a));
        } else {
          return [];
        }
    }
}

