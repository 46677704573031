<div class="situation-widget">
    <div #header class="widget-header" fxLayout="row" fxLayoutAlign="space-between center">
        <p class="title">Situational Reports</p>
    </div>

    <div #content class="widget-content">
        <div *ngFor="let file of showingFiles" class="file clickable" (click)="downloadFile(file)">
            <p class="ellipsis">{{ file.displayName }}</p>
            <mat-icon class="icon" fontSet="fas" fontIcon="fa-file-export" inline></mat-icon>
        </div>
    </div>

    <div #footer class="widget-footer">
        <mat-paginator hidePageSize showFirstLastButtons (page)="pageFiles($event)"></mat-paginator>
    </div>
</div>
