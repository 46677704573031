import {Component, inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'eaglei-deny-options',
    templateUrl: './deny-options.component.html',
    styleUrls: ['../shared-modals.scss', './deny-options.component.scss'],
})
export class DenyOptionsComponent {
    private modalText: {
        header: string;
        button: string;
    } = inject(MAT_DIALOG_DATA);
    private dialogRef = inject(MatDialogRef);

    public reason = '';
    public headerText = this.modalText?.header || 'Deny';
    public buttonText = this.modalText?.button || 'Deny User';

    public submit(): void {
        this.dialogRef.close(this.reason);
    }

    public isFormValid(): boolean {
        return this.reason !== undefined && this.reason.trim().length > 0;
    }
}
