<div>
    <div class="dialog-header">
        <div>{{ info.title }}</div>
        <mat-icon class="clickable" (click)="close()">close</mat-icon>
    </div>

    <div #content class="dialog-content">
        <p>{{ info.content }}</p>
    </div>
</div>
