<eaglei-leaflet-layer-header
    [layer]="this"
    (activeToggled)="toggleLayer($event)"
    (dateRangeUpdate)="toggleLayer(isActive)"></eaglei-leaflet-layer-header>

<div class="layer-actions hide" [class.show]="isActive">
    <div *ngIf="errorMessage">
        <p class="error-message">{{ errorMessage }}</p>
    </div>

    <div class="filters">
        <div class="clickable" (click)="filtersExpanded = !filtersExpanded">
            <mat-icon fontSet="fas" fontIcon="fa-filter" inline></mat-icon>
            <span class="label">Filter Locations</span>
            <mat-icon fontSet="fas" [fontIcon]="filtersExpanded ? 'fa-chevron-up' : 'fa-chevron-down'" inline></mat-icon>
        </div>

        <div class="hide" [class.show]="filtersExpanded">
            <eaglei-location-chip-filter (change)="filterStates($event)"></eaglei-location-chip-filter>
        </div>
    </div>
</div>
