import * as moment from 'moment';
import {State} from '../../outage/classes/state';
import {DataService} from 'frontend/src/app/services/data.service';
import {ApplicationConfig} from 'frontend/src/app/classes/application-config';
import {GenOutageAggregationLevel} from 'frontend/generated/serverModels/GenOutageAggregationLevel';

export class WaoStructure {
    // TODO: Look into getting the data back in a simplified way
    public id: number;
    public eventId: number;
    public lightStatus: number;
    public structureCount: number;
    public date: moment.Moment;
    public fipsCode: string;
    public countyName: string;

    // Not given by response
    public state: State;

    constructor(data: any[]) {
        if (data.length > 0) {
            this.id = data[0];
            this.eventId = data[1];
            this.lightStatus = data[2];
            this.structureCount = data[3];
            this.date = moment(data[4]);
            this.fipsCode = data[5];
            this.countyName = data[6];

            // TODO: Make this into a service method
            const counties = ApplicationConfig.geometries.get(GenOutageAggregationLevel.county);
            const stateId = counties.find((c) => c.fipsCode === this.fipsCode)?.stateId;
            this.state = DataService.states.getValue().find((s) => s.id === stateId);

            // Setting as PR as that is what the data seems to be missing
            // TODO: mention to Aaron about missing values
            if (this.state === undefined) {
                this.state = DataService.states.getValue().find((s) => s.abbreviation === 'PR');
            }
        }
    }
}
