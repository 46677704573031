import {Component, inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Report} from '../../classes/report';
import {ReportService} from '../../services/report.service';
import {UrbannetService} from '../../../../../integrations/urbannet/services/urbannet.service';
import {UrbannetReport} from '../../../../../integrations/urbannet/classes/urbannet-report';
import {FormControl, UntypedFormControl} from '@angular/forms';
import {UrbannetDisruption} from '../../../../../integrations/urbannet/classes/urbannet-disruption';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {MapOptions} from '../../../map/classes/map-options';
import {UrbannetSource} from '../../../../../integrations/urbannet/classes/urbannet-source';
import {Map as LeafletMap, GeoJSON as GeoJsonLayer} from 'leaflet';
import {LeafletFeature} from '../../../layer/classes/leaflet-feature';
import {GenUrbanNetFemaType} from '../../../../../../generated/serverModels/GenUrbanNetFemaType';
import {ApplicationConfig} from '../../../../classes/application-config';
import {FileDownload} from '../../../../classes/file-download';
import {SystemEvent} from '../../../system-event/classes/system-event';
import {MapService} from '../../../map/services/map.service';
import {femaLifelineTooltipInfo} from './fema-lifeline-tooltip-text';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {ModalConfig} from '../../../../classes/modal-config';
import {UrbannetMetadataModalComponent} from '../../modals/urbannet-metadata-modal/urbannet-metadata-modal.component';
import {filter, map, switchMap, take, takeUntil, withLatestFrom} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {LoadingMaskOptions} from '../../../../classes/loading-mask-options';
import {ActivatedRoute} from '@angular/router';

interface PreZoomState {
    zoomedTo: LeafletFeature<UrbannetDisruption>;
    selected: LeafletFeature<UrbannetDisruption>[];
}

@Component({
    selector: 'eaglei-urbannet-report',
    templateUrl: './urbannet-report.component.html',
    styleUrls: ['../reports.scss', './urbannet-report.component.scss'],
})
export class UrbannetReportComponent extends Report<LeafletFeature<UrbannetDisruption>> implements OnInit, OnDestroy {
    private destroyed$ = new Subject();
    private route = inject(ActivatedRoute);
    public reports: UrbannetReport[] = [];
    public events: SystemEvent[] = [];
    public disruptionTypes: string[] = [];

    public reportControl: FormControl<UrbannetReport>;
    public eventControl: FormControl<SystemEvent>;
    public typeControl: FormControl<string[]>;

    public selectedRow: LeafletFeature<UrbannetDisruption>;
    public aoeOpacity: number = 100;

    public mapMask = new LoadingMaskOptions();

    // Table Properties
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatTable) table: MatTable<LeafletFeature<UrbannetDisruption>>;

    public selection = new SelectionModel<LeafletFeature<UrbannetDisruption>>(true, []);
    public femaTypes = GenUrbanNetFemaType;
    public readonly tooltipMap: {[key: string]: string} = femaLifelineTooltipInfo;
    public readonly columns = [
        'select',
        'name',
        'type',
        'total',
        'energy',
        'communications',
        'food_water_shelter',
        'safety_and_security',
        'health_and_medical',
        'transportation',
        'hazardous_material',
        'other',
    ];

    // Map Properties
    public mapOptions: MapOptions;
    public layerSource: UrbannetSource = new UrbannetSource();
    public mapRef: LeafletMap;
    private reportAreaLayer = new GeoJsonLayer(undefined, {
        pane: MapService.layerPaneName,
        style: {
            fillOpacity: 0.1,
            fillColor: '#707070',
            stroke: true,
            color: '#707070',
        },
    });

    public beforeZoomState: PreZoomState;

    constructor(
        public reportService: ReportService,
        private mapService: MapService,
        private urbannetService: UrbannetService,
        private dialog: MatDialog
    ) {
        super();

        this.urbannetService.assetTableClosed$
            .pipe(
                takeUntil(this.destroyed$),
                filter(() => !this.selectedRow)
            )
            .subscribe(() => {
                this.showDistrbanceLayer();
            });

        this.mapOptions = new MapOptions().setZoom(3, 1).setCenter(37.2, -92);
        this.mapOptions.onlyManualZoom = true;
        this.mapOptions.show = {
            sidebar: false,
            refresh: false,
            export: false,
            coverage: false,
            coordinate: false,
            zoom: true,
            panControl: true,
        };
    }

    ngOnInit(): void {
        this.mapMask.displayMask('Loading urbannet reports');
        this.reportService.getReportData().subscribe((r) => {
            this.initializeReportInfo(r);
            this.layerSource.layerInfo.attributionUrl = this.attributionUrl;
            this.layerSource.layerInfo.attributionTitle = this.attributionTitle;
        });

        this.initializeControls();

        this.getEvents();
    }

    ngOnDestroy() {
        this.destroyed$.complete();
        this.destroyed$.unsubscribe();
    }

    /**
     * Sets up the form control with any necessary validators and sets up logic for on value change.
     * @private
     */
    private initializeControls(): void {
        this.reportControl = new UntypedFormControl();
        this.eventControl = new UntypedFormControl();
        this.typeControl = new UntypedFormControl();

        this.eventControl.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((value: SystemEvent) => {
            this.getReportsByEvent(value.id);
        });

        this.reportControl.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((value: UrbannetReport) => {
            this.reportAreaLayer.clearLayers();

            this.clearSelectedAsset();

            if (value.boundary) {
                const reportInfo = {
                    name: value.advisory,
                    boundary: value.boundary,
                };

                this.reportAreaLayer.addData(new LeafletFeature().convert(reportInfo) as any);
                this.updateAoeOpacity(this.aoeOpacity);
            }

            if (this.dataSource) {
                this.dataSource.data = [];
            }

            this.getDisruptionsForReport(value);
        });

        this.typeControl.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
            this.dataSource.filter = ' ';

            this.toggleAllRows(true);

            this.layerSource.redraw(this.selection.selected);
        });

        UrbannetService.zoomToAsset
            .pipe(
                takeUntil(this.destroyed$),
                filter((asset) => !!asset)
            )
            .subscribe((asset) => {
                const assetIndex = this.dataSource.data.findIndex((row) => row.properties.id === asset.id);
                const found = this.dataSource.data[assetIndex];

                if (!this.beforeZoomState) {
                    this.beforeZoomState = {
                        zoomedTo: found,
                        selected: this.selection.selected,
                    };
                }

                this.dataSource.filter = ' ';

                this.selection.clear();
                this.selection.select(found);

                this.toggleExpandedRow(found, true);
            });
    }

    /**
     * Fetches all the system events that have URBAN-NET report tied to them and selects the most recent by default
     * @private
     */
    private getEvents(): void {
        let eventId;
        this.route.queryParamMap
            .pipe(
                switchMap((params) => {
                    eventId = params.get('eventId');

                    return this.urbannetService.getEvents();
                }),
                take(1)
            )
            .subscribe((events) => {
                this.events = events;
                // TODO add logic for most recent event;
                if (this.events.length) {
                    this.eventControl.setValue(this.events[0]);
                }

                if (eventId) {
                    const match = this.events.find((e) => e.id === parseInt(eventId));
                    this.eventControl.setValue(match);
                }
            });
    }

    /**
     * Fetches URBAN-NET reports that are tired to a system event
     * @param eventId The id of the system event to get URBAN-NET reports for.
     * @private
     */
    private getReportsByEvent(eventId: number) {
        this.urbannetService.getReportsByEvent(eventId).subscribe((reports) => {
            this.reports = reports;
            this.reportControl.setValue(reports[0]);
        });
    }

    private getDisruptionsForReport(report: UrbannetReport): void {
        this.mapMask.displayMask(`Loading disrupted assets`);
        this.urbannetService.getDisruptions(report.id).subscribe((disruptions) => {
            report.disruptions = disruptions;
            const uniqueTypes = Array.from(new Set(disruptions.map((disruption) => disruption.type)).values()).sort();
            this.disruptionTypes = uniqueTypes;

            this.layerSource.processFeatures(disruptions);
            this.initializeData(this.layerSource.getLeafletFeatures());

            this.typeControl.setValue(uniqueTypes);
            this.mapMask.resetMask();
        });
    }

    /**
     * Sets up the data to be down in the table, if the table already exist, the table source data is updated, otherwise
     * the table source is created and the sorting logic and paginator are created.
     * @param data A list of UrbannetDisruption objects to be shown in the table
     * @private
     */
    private initializeData(data: LeafletFeature<UrbannetDisruption>[]): void {
        if (this.dataSource) {
            this.dataSource.data = data;
        } else {
            this.dataSource = new MatTableDataSource<LeafletFeature<UrbannetDisruption>>(data);
            this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
            this.dataSource.sort = this.sort;
            this.dataSource.filterPredicate = this.filterPredicate.bind(this);
            this.dataSource.paginator = this.paginator;
        }

        setTimeout(() => {
            this.toggleAllRows(true);
        }, 0);

        this.layerSource.addToMap();
        this.layerSource.fitToFeatures();
    }

    /**
     * Table filter method, Checks to see if a row is the same type as the type filer
     * @param data A row from the table.
     * @private
     */
    private filterPredicate(data: LeafletFeature<UrbannetDisruption>): boolean {
        const typeCheck = this.typeControl.value.includes(data.properties.type);

        if (this.beforeZoomState) {
            return typeCheck && this.beforeZoomState.zoomedTo.properties.id === data.properties.id;
        }

        return typeCheck;
    }

    // noinspection JSMethodCanBeStatic
    /**
     * Sets up the logic for columns sorting
     * @param data The row that is being compared
     * @param columnName The current column that is being sorted
     * @private
     */
    private sortingDataAccessor(data: LeafletFeature<UrbannetDisruption>, columnName: string) {
        switch (columnName) {
            case 'name':
                return data.properties.name.toLowerCase();
            case 'type':
                return data.properties.type;
            case 'energy':
                return data.properties.counts[GenUrbanNetFemaType.ENERGY.toString()];
            case 'communications':
                return data.properties.counts[GenUrbanNetFemaType.COMMUNICATIONS.toString()];
            case 'food_water_shelter':
                return data.properties.counts[GenUrbanNetFemaType.FOOD_WATER_SHELTER.toString()];
            case 'safety_and_security':
                return data.properties.counts[GenUrbanNetFemaType.SAFETY_AND_SECURITY.toString()];
            case 'health_and_medical':
                return data.properties.counts[GenUrbanNetFemaType.HEALTH_AND_MEDICAL.toString()];
            case 'transportation':
                return data.properties.counts[GenUrbanNetFemaType.TRANSPORTATION.toString()];
            case 'hazardous_material':
                return data.properties.counts[GenUrbanNetFemaType.HAZARDOUS_MATERIAL.toString()];
            case 'other':
                return data.properties.counts[GenUrbanNetFemaType.OTHER.toString()];
            case 'total':
                return data.properties.counts['TOTAL'];
        }
    }

    /**
     * Returns if all rows are selected
     */
    public isAllSelected(): boolean {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.filteredData.length;
        return numSelected === numRows;
    }

    /**
     * Toggles all the rows on or off, if only some rows are selected then all the rows are set to selected
     * @param forceSelection [Optional] defaults to undefined, if set to false the whole table will be set as unselected,
     * if set to true, the whole table will be selected
     */
    public toggleAllRows(forceSelection?: boolean): void {
        if (forceSelection === true) {
            this.selection.clear();
            this.selection.select(...this.dataSource.filteredData);
            this.layerSource.redraw(this.selection.selected);
            return;
        }

        if (this.isAllSelected() || forceSelection === false) {
            this.selection.clear();
            this.layerSource.redraw([]);
        } else {
            this.selection.clear();
            this.selection.select(...this.dataSource.data);
            this.layerSource.redraw(this.selection.selected);
        }
    }

    /**
     * Toggles a single row on or off.
     * @param event The checkbox event to see if the row should be checked or unchecked.
     * @param row The currently clicked row
     */
    public toggleRow(event: MatCheckboxChange, row: LeafletFeature<UrbannetDisruption>): void {
        event.checked ? this.selection.select(row) : this.selection.deselect(row);
        this.layerSource.redraw(this.selection.selected);
    }

    /**
     * Checks to see if the text in the table is longer than the cell width, if it is, a tooltip is enabled.
     * @param element The Table cell HTML element
     */
    public isEllipsis(element: HTMLElement): boolean {
        return ApplicationConfig.hasEllipsis(element);
    }

    /**
     * Exports the given table rows as a CSV, if not tableRows are passed in, the whole table is exported
     * @param tableRows A list of rows to export from the table
     */
    public exportTable(tableRows?: LeafletFeature<UrbannetDisruption>[]): void {
        const columns: string[] = [
            'Name',
            'Type',
            'Total',
            'Energy',
            'Communications',
            'Food Water Shelter',
            'Safety and Security',
            'Health and Medical',
            'Transportation',
            'Hazardous Material',
            'Other',
        ];

        let data = `${columns.join()}\n`;

        tableRows = tableRows || this.dataSource._orderData(this.dataSource.filteredData);

        tableRows.forEach((val) => {
            const disruption = val.properties;

            const row = [
                FileDownload.formatCsvCell(disruption.name),
                FileDownload.formatCsvCell(disruption.type),
                FileDownload.formatCsvCell(disruption.counts['TOTAL']),
                FileDownload.formatCsvCell(disruption.counts[GenUrbanNetFemaType.ENERGY.toString()]),
                FileDownload.formatCsvCell(disruption.counts[GenUrbanNetFemaType.COMMUNICATIONS.toString()]),
                FileDownload.formatCsvCell(disruption.counts[GenUrbanNetFemaType.FOOD_WATER_SHELTER.toString()]),
                FileDownload.formatCsvCell(disruption.counts[GenUrbanNetFemaType.SAFETY_AND_SECURITY.toString()]),
                FileDownload.formatCsvCell(disruption.counts[GenUrbanNetFemaType.HEALTH_AND_MEDICAL.toString()]),
                FileDownload.formatCsvCell(disruption.counts[GenUrbanNetFemaType.TRANSPORTATION.toString()]),
                FileDownload.formatCsvCell(disruption.counts[GenUrbanNetFemaType.HAZARDOUS_MATERIAL.toString()]),
                FileDownload.formatCsvCell(disruption.counts[GenUrbanNetFemaType.OTHER.toString()]),
            ];

            data += `${row.join()}\n`;
        });

        FileDownload.downloadCSV('urbannet', data, this.attributionUrl);
    }

    /**
     * Exports only the selected table rows to a csv
     */
    public exportSelectedDisruptions(): void {
        this.exportTable(this.selection.selected);
    }

    /**
     * This is called by the map component when the mapref is created.
     * @param mapRef A reference to the map on the report.
     */
    public initializeMap(mapRef: LeafletMap): void {
        this.mapRef = mapRef;
        this.reportAreaLayer.addTo(this.mapRef);
        this.reportAreaLayer.bringToBack();
    }

    /**
     * Callback listener that updates the opacity and fill opacity of the AOE
     * layer, fires on slider change
     * @param opacity The new opacity value as a whole number.
     */
    public updateAoeOpacity(opacity: number): void {
        this.aoeOpacity = opacity;
        const percentOpacity = opacity / 100;
        this.reportAreaLayer.setStyle({opacity: percentOpacity, fillOpacity: percentOpacity / 10});
    }

    /**
     * Opens a modal to display all metadata information for the selected report.
     * @param event The mouse click event that trigger the callback function.
     */
    public openInputModal(event: MouseEvent): void {
        event.stopPropagation();

        const config: MatDialogConfig = {
            width: ModalConfig.getModalWidth(700),
            data: this.reportControl.value,
        };

        this.dialog.open(UrbannetMetadataModalComponent, config);
    }

    /**
     * Sets the expanded row to the row of the table that was clicked. If the row
     * was already expanded, the row will be closed.
     * @param row The table row that was clicked
     * @param forceSelection (optional) if set to true, the row will always be selected
     */
    public toggleExpandedRow(row: LeafletFeature<UrbannetDisruption>, forceSelection: boolean = false): void {
        const isRowSelected = this.selectedRow?.properties.id === row.properties.id;

        this.selectedRow = forceSelection || !isRowSelected ? row : undefined;

        if (this.selectedRow) {
            this.layerSource.redraw([row]);
            this.reportAreaLayer.removeFrom(MapService.mapRef);
        }
    }

    clearSelectedAsset(): void {
        if (!this.beforeZoomState) {
            return;
        }

        const selectedAsset = this.selection.selected[0];

        const ids = this.beforeZoomState.selected.map((data) => data.properties.id);

        this.beforeZoomState = undefined;

        this.dataSource.filter = ' ';

        const filtered = this.dataSource.filteredData.filter((data) => ids.includes(data.properties.id));

        this.selection.clear();
        this.selection.select(...filtered);

        if (selectedAsset) {
            this.toggleExpandedRow(selectedAsset);
        }

        this.showDistrbanceLayer();
    }

    showDistrbanceLayer(): void {
        this.reportAreaLayer.addTo(MapService.mapRef);
        this.layerSource.redraw(this.selection.selected);
        this.layerSource.addToMap(true);
        if (this.dataSource.filteredData.length === 0) {
            this.mapRef.fitBounds(this.reportAreaLayer.getBounds());
            return;
        }
        this.layerSource.fitToFeatures();
    }
}
