import * as moment from 'moment';

export class OngOilOutage {
    public collection_date: moment.Moment;
    public comments: string;
    public confidence_id: number;
    public expected_days_in_turnaround: number;
    public expected_end_date: moment.Moment;
    public expected_start_date: moment.Moment;
    public facility_capacity: number;
    public id: number;
    public last_updated: moment.Moment;
    public monitored: boolean;
    public name: string;
    public observed_end_date: string;
    public observed_start_date: string;
    public outage_type: string;
    public region: string;
    public status: string;
    public turnaround_id: number;
    public unit_capacity: number;
    public unit_id: string;
    public unit_name: string;
    public planned_outages?: any[];

    constructor(input?: OngOilOutage | number) {
        if (typeof input === 'number') {
            this.id = input;
        }

        if (input && typeof input !== 'number') {
            this.id = input.id;
            if (input.collection_date && typeof input.collection_date === 'string') {
                this.collection_date = moment(input.collection_date);
            } else {
                this.collection_date = input.collection_date;
            }

            if (input.expected_end_date && typeof input.expected_end_date === 'string') {
                this.expected_end_date = moment(input.expected_end_date);
            } else {
                this.expected_end_date = input.expected_end_date;
            }

            if (input.expected_start_date && typeof input.expected_start_date === 'string') {
                this.expected_start_date = moment(input.expected_start_date);
            } else {
                this.expected_start_date = input.expected_start_date;
            }

            if (input.last_updated && typeof input.last_updated === 'string') {
                this.last_updated = moment(input.last_updated);
            } else {
                this.last_updated = input.last_updated;
            }

            this.comments = input.comments;
            this.confidence_id = input.confidence_id;
            this.expected_days_in_turnaround = input.expected_days_in_turnaround;
            this.facility_capacity = input.facility_capacity;
            this.monitored = input.monitored;
            this.name = input.name;
            this.observed_end_date = input.observed_end_date;
            this.observed_start_date = input.observed_start_date;
            this.outage_type = input.outage_type;
            this.region = input.region;
            this.status = input.status;
            this.turnaround_id = input.turnaround_id;
            this.unit_capacity = input.unit_capacity;
            this.unit_id = input.unit_id;
            this.unit_name = input.unit_name;
        }
    }
}
