import {LeafletEagleiSource} from './leaflet-eaglei-source';
import * as esri from 'esri-leaflet';
import {LeafletMapLayer} from './leaflet-map-layer';
import {ApplicationConfig} from '../../../classes/application-config';

export class LeafletArcgisSource extends LeafletEagleiSource<esri.DynamicMapLayer> {
    private failedTiles: number = 0;

    constructor(layerInfo: LeafletMapLayer) {
        super(layerInfo);
    }

    public fetchImages(): void {
        const layerIds = this.layerInfo.servicelayer.split(',').map((val) => parseInt(val));

        const options = {
            url: this.layerInfo.serviceurl,
            layers: layerIds,
            format: 'PNG32',
            f: 'image',
            proxy: ApplicationConfig.proxyPrefix,
        };

        Object.assign(options, this.layerInfo.getParams());

        this.failedTiles = 0;

        this.source = esri.dynamicMapLayer(options);

        this.source.on('loading', () => {
            this.layerInfo.startLoading();
        });
        this.source.on('load', () => {
            const message = this.failedTiles > 0 ? `Failed to load ${this.failedTiles} tiles` : undefined;
            this.layerInfo.endLoading(this.failedTiles !== 0, message);
        });

        this.source.on('requesterror', () => {
            this.failedTiles += 1;
        });

        this.source.on('requeststart', () => {});

        this.source.on('requestend', (e) => {});
    }

    public changeOpacity(opacity: number): void {
        this.source.setOpacity(opacity);
    }
}
