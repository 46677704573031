import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {delay, map} from 'rxjs/operators';
import {EilData} from '../../../classes/eil-data';
import {EilDocument} from '../classes/eil-document';
import {EilSearchResult} from '../classes/eil-search-result';
import {GenOutageGenerationParam} from 'frontend/generated/serverModels/GenOutageGenerationParam';
import {GenOutageGenerationByStateParam} from 'frontend/generated/serverModels/GenOutageGenerationByStateParam';
import {ApplicationConfig} from 'frontend/src/app/classes/application-config';
import {User} from '../../user/classes/user';
import {ApiKey} from '../../api-key/classes/api-key';
import * as moment from 'moment';
import {ReportRequest} from '../../../../shared/classes/report-request';
import {State} from '../../outage/classes/state';
import {GenGeneratedDateRangeResult} from '../../../../../generated/serverModels/GenGeneratedDateRangeResult';
import {GenCreateTrainingUsersParam} from 'frontend/generated/serverModels/GenCreateTrainingUsersParam';
import {GenTrainingUser} from 'frontend/generated/serverModels/GenTrainingUser';
import {ResponseWrapper} from 'frontend/src/app/classes/response-wrapper';

@Injectable({
    providedIn: 'root',
})
export class ManagementService {
    constructor(private http: HttpClient) {}

    // Eil Endpoints
    public getEilDatapoints(status: string = 'all'): Observable<EilData[]> {
        const url = `api/eil?status=${status}`;
        return this.http.get<any[]>(url).pipe(map((points) => points.map((point) => new EilData(point))));
    }

    public createEilDatapoint(datapoint: EilData): Observable<EilData> {
        const url = 'api/eil';
        return this.http.post<EilData>(url, datapoint).pipe(map((res) => new EilData(res)));
    }

    public updateEilDatapoint(datapoint: EilData): Observable<EilData> {
        const url = `api/eil/${datapoint.id}`;
        return this.http.put<EilData>(url, datapoint).pipe(map((ret) => new EilData(ret)));
    }

    public deleteEilDatapoint(datapoint: EilData): Observable<void> {
        const url = `api/eil/${datapoint.id}`;
        return this.http.delete<void>(url);
    }

    public deleteEilDocument(documentId: number): Observable<EilData> {
        const url = `api/eil/document/${documentId}`;
        return this.http.delete<EilData>(url);
    }

    public addEilDocument(datapoint: Partial<EilData> & Required<Pick<EilData, 'id' | 'documents'>>): Observable<EilData> {
        const formData = new FormData();

        const d = [];

        datapoint.documents.forEach((document) => {
            formData.append('file', document.data);
            d.push(document);
        });

        formData.append('document', JSON.stringify(d));

        const url = `api/eil/${datapoint.id}/document`;
        return this.http.post<EilData>(url, formData).pipe(map((dp) => new EilData(dp)));
    }

    public getEilDocumentData(eventId: number): Observable<EilData[]> {
        const url = `api/eil`;
        const query = new HttpParams().append('eventId', eventId).append('status', 'active');
        return this.http.get<any>(url, {params: query}).pipe(map((docs) => docs.map((doc) => new EilData(doc))));
    }

    public checkDatapoint(datapoint: EilData): Observable<EilData> {
        const url = `api/eil/proposeddatapoint`;
        return this.http.post<any>(url, datapoint).pipe(map((point) => new EilData(point)));
    }

    public searchEilData(text: string): Observable<EilSearchResult[]> {
        const url = `api/eil/datapointkeywordsearch?keyword=${text}`;
        return this.http.get<any[]>(url).pipe(map((results) => results.map((result) => new EilSearchResult(result))));
    }

    public advanceSearch(params: any[], text: string): Observable<EilData[]> {
        let url = `api/eil/datapointkeywordsearch?keyword=${text}&`;
        url += params.map((p) => `${p.property}=${p.value}`).join('&');

        if (text) {
            return this.http.get<any[]>(url).pipe(map((res) => res.map((point) => new EilData(point))));
        } else {
            return of([]);
        }
    }

    public parseEilSpreadsheet(file: File): Observable<EilData[]> {
        const fd = new FormData();
        fd.append('file', file);
        return this.http.post<any[]>('api/eil/parsedocument', fd).pipe(map((points) => points.map((point) => new EilData(point))));
    }

    public removeTmpFiles(): Observable<any> {
        const url = `api/eil/removetmpfiles`;
        return this.http.delete(url);
    }

    public generateStateOutages(params: GenOutageGenerationByStateParam): Observable<any> {
        const url = `api/outages/generate/state`;
        return this.http.post(url, params);
    }

    public generateRunOutages(params: GenOutageGenerationParam[]): Observable<any> {
        const url = `api/outages/generate`;
        return this.http.post(url, params);
    }

    public deleteGeneratedOutages(params?: any): Observable<any> {
        const url = `api/outages/generate?${params}`;
        return this.http.delete(url);
    }

    public deleteBlueSkyOutages(params?: any): Observable<any> {
        const url = `api/outages/generate/delete-blue-sky?${params}`;
        return this.http.delete(url);
    }

    public setApiKeyExpirationDate(keys: ApiKey[], date: moment.Moment): Observable<any> {
        const url = `/api/qa/update-api-key-expiration?date=${date.format()}`;
        return this.http.put(url, keys);
    }

    public resetUserPasswordOrLoginDate(passwordOrLogin, selectedUser, selectedDate): Observable<any> {
        const url = `/api/qa/update-${passwordOrLogin}?id=${selectedUser}&date=${selectedDate.format()}`;
        return this.http.put(url, selectedUser);
    }

    public getEmailTrackByUser(selectedUser): Observable<any> {
        const url = `/api/qa/email-tracks?id=${selectedUser}`;
        return this.http.get(url);
    }

    public getOngApi(api): Observable<any> {
        const url = `${ApplicationConfig.proxyPrefix}ONG_URL/${api.replace(/^\/|\/$/g, '')}`; // removes leading and trailing slash
        const opts = {
            headers: new HttpHeaders().set('X-Api-Key', 'ONG_KEY'),
        };
        return this.http.get(url, opts);
    }

    public deleteUser(user: User): Observable<any> {
        const url = `api/security/user/${user.id}`;
        return this.http.delete<any>(url);
    }

    public deleteApiKey(key: ApiKey): Observable<any> {
        const url = `api/apikey/${key.id}`;
        return this.http.delete<any>(url);
    }

    public createUserDefinedReportRequest(request: ReportRequest): Observable<any> {
        const url = `api/systemdata/qa-user-defined-report`;
        return this.http.post(url, request);
    }

    // TODO: Maybe these to a different service
    public getStateHourlyMax(start: moment.Moment, states: State[]): Observable<any> {
        const stateStr = states.map((s) => `state=${s.abbreviation}`).join('&');
        const url = `api/outagesummary/state-hourly-max?start=${start.format()}&${stateStr}`;
        return this.http.get(url);
    }

    public getCountyHourlyMax(start: moment.Moment, states: State[]): Observable<any> {
        const stateStr = states.map((s) => `state=${s.abbreviation}`).join('&');
        const url = `api/outagesummary/county-hourly-max?start=${start.format()}&${stateStr}`;
        return this.http.get(url);
    }

    public getUtilityHourlyMax(start: moment.Moment): Observable<any> {
        const url = `api/outagesummary/utility-hourly-max?start=${start.format()}`;
        return this.http.get(url);
    }

    public generateBlueSky(
        start: moment.Moment,
        end: moment.Moment,
        states: State[] = [],
        lowerBound: number = 0,
        upperBound: number = 0.01,
        percentUtilities: number = 0.75
    ): Observable<any> {
        const url = `api/outages/generate/generate-blue-sky?start=${start.format()}&end=${end.format()}&state=${states.join(
            ','
        )}&lowerBound=${lowerBound}&upperBound=${upperBound}&percentUtilities=${percentUtilities}`;
        return this.http.get(url);
    }

    public checkIfHourMaxCalculationsRunning(): Observable<any> {
        const url = `api/outages/generate/check-calculations-running`;
        return this.http.get(url);
    }

    public getGeneratedDateRange(): Observable<GenGeneratedDateRangeResult> {
        const url = `api/outages/generate/get-generated-daterange`;
        return this.http.get<any>(url).pipe(map((res) => new GenGeneratedDateRangeResult(res.data)));
    }

    public createTrainingUsers(params: GenCreateTrainingUsersParam): Observable<GenTrainingUser[]> {
        const url = `api/training/create-training-users`;
        return this.http.post<ResponseWrapper<GenTrainingUser>>(url, params).pipe(map((res) => res.data));
    }

    public syncProdUsers(): Observable<any> {
        const url = `api/training/sync-prod-users`;
        return this.http.get(url);
    }
}
