import {MapService} from '../../map/services/map.service';
import {LeafletMapLayer} from './leaflet-map-layer';
import {LayerLegendService} from '../services/layer-legend.service';
import {PopoverElement} from '../../../classes/popover-element';
import {PopoverConfig} from '../../../classes/popover-config';
import {MomentDatePipe} from '../../../../shared/pipes/moment-date.pipe';
import {DecimalPipe, PercentPipe} from '@angular/common';
import * as L from 'leaflet';
import {GenServiceType} from '../../../../../generated/serverModels/GenServiceType';
import {HttpInterceptorService} from '../../../services/http-interceptor.service';

export abstract class LeafletEagleiSource<S = any> {
    get source(): S {
        return this._source;
    }

    set source(value: S) {
        this._source = value;
        this.sourceUpdated();
    }

    static momentPipe = new MomentDatePipe();
    static numberPipe = new DecimalPipe('en-us');
    static percentPipe = new PercentPipe('en-us');

    private _source: S;

    // If this is not set. the current active map will be used.
    get mapRef(): L.Map {
        return this._mapRef || MapService.mapRef;
    }

    set mapRef(value: L.Map) {
        this._mapRef = value;
    }

    private _mapRef: L.Map;

    public layerInfo: LeafletMapLayer;

    protected constructor(layerInfo: LeafletMapLayer) {
        this.layerInfo = layerInfo;
    }

    public addToMap(): void {
        const setSourceProperties = () => {
            (this._source as any).options.attribution = this.layerInfo.getAttributionLink();
            (this._source as any).setZIndex(MapService.globalLayerZIndex);
            (this._source as any).eagleiLayer = this.layerInfo;
            (this._source as any).layerHandle = this.layerInfo.uiHandle;
            (this._source as any).layerType = this.layerInfo.servicetype;

            if (this.layerInfo.servicetype === GenServiceType.VECTOR) {
                (this._source as any).setInteractive = this.setInteractive.bind(this);
            }

            MapService.globalLayerZIndex += 1;
        };

        this.removeFromMap();
        setSourceProperties();

        (this._source as any).addTo(this.mapRef);
        (this._source as any).bringToFront();
    }

    public setInteractive(isInteractive: boolean, source?: S) {
        const sourceToUse: any = source || this._source;
        sourceToUse.options['interactive'] = isInteractive;
        Object.values(sourceToUse._layers)
            .filter((renderedLayers: any) => !!renderedLayers._path)
            .forEach((l: any) => {
                const ele: HTMLElement = l._path;
                if (isInteractive) {
                    ele.classList.add('leaflet-interactive');
                } else {
                    ele.classList.remove('leaflet-interactive');
                }
            });
    }

    public removeFromMap(): void {
        HttpInterceptorService.clearInterceptor(this.layerInfo.interceptorKey);
        this.layerInfo.endLoading();
        this.mapRef.eachLayer((layer: any) => {
            if (layer.layerHandle && layer.layerHandle === this.layerInfo.uiHandle) {
                const legends = LayerLegendService.legends.getValue();
                const index = legends.findIndex((l) => l.layerName === this.layerInfo.displayName);
                if (index !== -1) {
                    legends.splice(index, 1);
                    LayerLegendService.legends.next(legends);
                }

                // 200 is the base for the pane the layers are in.
                if (MapService.globalLayerZIndex > 200) {
                    MapService.globalLayerZIndex -= 1;
                }

                layer.removeFrom(this.mapRef);

                if (PopoverConfig.handle === this.layerInfo.uiHandle) {
                    PopoverConfig.hideNewPopover();
                }
            }
        });
    }

    public addPopover(x: number, y: number, data: PopoverElement[], isInteractive: boolean = false): void {
        const config = {
            x,
            y,
            data,
            interactive: isInteractive,
        };
        PopoverConfig.showNewPopover(config);
    }

    public sourceUpdated(): void {}

    abstract changeOpacity(opacity: number): void;
}
