<div class="container">
    <div #header class="widget-header">
        <p class="title">National Outage Map</p>

        <div class="mapper-btn">
            <a [routerLink]="getMapperLink()">
                <button mat-stroked-button>
                    <span *ngIf="!mobileLayout">Launch Mapper</span>
                    <mat-icon
                        *ngIf="mobileLayout"
                        fontSet="fas"
                        fontIcon="fa-external-link-alt"
                        inline
                        matTooltip="Launch Mapper"></mat-icon>
                </button>
            </a>
        </div>
    </div>

    <div #content class="widget-content">
        <eaglei-leaflet-map (mapRef)="mapRef = $event" [mapOptions]="mapOptions"></eaglei-leaflet-map>
    </div>

    <div #footer class="action">
        <div class="footer">
            <eaglei-nom-legend class="map-legend" (hideOverrides)="this.nomSource.redraw()"></eaglei-nom-legend>

            <div class="aggregation">
                <span class="label">Show Outages By</span>
                <mat-button-toggle-group [value]="filters.aggregationLevel" (change)="updateAggregation($event)">
                    <mat-button-toggle [value]="level" *ngFor="let level of aggregationLevels">
                        {{ level | aggregationLevel }}
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </div>
        </div>
    </div>
</div>
