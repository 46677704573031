import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApplicationConfig} from '../classes/application-config';
import * as moment from 'moment';
import {WaoStructure} from '../modules/report/classes/wao-structure';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class WaoService {
    private readonly DATEFORMAT = 'MM-DD-YYYY';

    constructor(private http: HttpClient) {}

    public getWAOStructures(runDate: moment.Moment): Observable<WaoStructure[]> {
        const url = `${ApplicationConfig.proxyPrefix}WAO_URL/api/data/structures?run_date=${runDate.format(this.DATEFORMAT)}`;
        return this.http.get(url).pipe(map((d: any) => d.map((s) => new WaoStructure(s))));
    }

    public getWAODataDates(): Observable<moment.Moment[]> {
        const url = `${ApplicationConfig.proxyPrefix}WAO_URL/api/data/data-dates`;
        return this.http.get(url).pipe(map((d: any) => d.map((m) => moment(m))));
    }

    public getWAOFilterDates(start: moment.Moment, end: moment.Moment): Observable<moment.Moment[]> {
        const url = `${ApplicationConfig.proxyPrefix}WAO_URL/api/data/filter-data-dates?start=${start.format(
            'YYYY-MM-DD'
        )}&end=${end.format('YYYY-MM-DD')}`;
        return this.http.get(url).pipe(map((d: any) => d.map((m) => moment(...m))));
    }
}
