import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root',
})
export class CommunityGuard implements CanActivate {
    constructor(private http: HttpClient, private router: Router, private popup: MatSnackBar) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const communityId = route.params.id;
        const mf = (hasAccess: boolean): any => {
            if (hasAccess) {
                return true;
            }
            this.router.navigateByUrl('/app/communities').then(() => {
                this.popup.open('Unable to access community', 'Okay', {panelClass: 'dialog-failure', duration: 5000});
            });
            return false;
        };

        return this.http.get(`api/permission/community/checkaccess/${parseInt(communityId)}`).pipe(map(mf));
    }
}
