import {Component, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {EventSummaryFilters} from '../../classes/event-summary-filters';
import {State} from '../../../outage/classes/state';
import {SystemEventService} from '../../services/system-event.service';
import {ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {AuthenticationService} from '../../../../services/authentication.service';
import {User} from '../../../user/classes/user';
import {SystemEvent} from '../../classes/system-event';
import {EventDashboardFilter} from '../../enums/event-dashboard-filter.enum';
import {FormControl, FormGroup} from '@angular/forms';
import {ApplicationConfig} from '../../../../classes/application-config';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {TimeSliderEvent} from 'frontend/src/shared/interfaces/time-slider-event.interface';
import * as moment from 'moment';
import {LandingType} from '../../../../landing/enums/landing-type.enum';
import {adminWidgets} from './dashboard-layouts/admin-dashboard-widgets';
import {analyticWidgets} from './dashboard-layouts/analytic-dashboard-widgets';
import {oilWidgets} from './dashboard-layouts/oil-dashboard-widgets';
import {ngWidgets} from './dashboard-layouts/ng-dashboard-widgets';
import {electricWidgets} from './dashboard-layouts/electric-dashboard-widgets';
import {AppEnvironment} from '../../../../enums/environment.enum';

@Component({
    selector: 'eaglei-event-dashboard',
    templateUrl: './event-dashboard.component.html',
    styleUrls: ['./event-dashboard.component.scss'],
})
export class EventDashboardComponent implements OnInit, OnDestroy {
    static eventFilterChange = new BehaviorSubject<EventSummaryFilters>(undefined);

    public events: {[key: string]: SystemEvent[]} = {
        active: [],
        inactive: [],
    };

    public eventFilters: EventSummaryFilters = new EventSummaryFilters();

    public dashboards = {
        electric: {type: LandingType.ELECTRIC, widgets: electricWidgets},
        analytic: {type: LandingType.ANALYTIC, widgets: analyticWidgets},
        admin: {type: LandingType.ADMIN, widgets: adminWidgets},
        oil: {type: LandingType.OIL, widgets: oilWidgets},
        ng: {type: LandingType.NATURAL_GAS, widgets: ngWidgets},
    };

    private user: User;

    // New Properties

    public sections = EventDashboardFilter.values().reduce((prev, cur) => {
        prev[cur.toString()] = true;
        return prev;
    }, {});

    public filterGroup: FormGroup;

    private destroyed$ = new Subject();
    private filtersChanged = new Subject();
    public eventStates: State[] = [];
    public layoutTypes = LandingType;

    public readonly isTrainingEnvironment: boolean = (window as any)?.eaglei?.env === AppEnvironment.TRAINING;

    constructor(
        private systemEventService: SystemEventService,
        private activatedRoute: ActivatedRoute,
        private location: Location,
        private authService: AuthenticationService
    ) {
        this.user = this.authService.authenticatedUser.getValue();
        this.filterDashboardTypes();

        this.initializeControls();
    }

    private filterDashboardTypes() {
        Object.keys(this.dashboards).forEach((key) => {
            const widgets = this.dashboards[key].widgets;
            const numVisible = widgets.filter((item) => {
                const communities = item.detail?.widget?.communityPermissions;
                return communities ? this.user.hasAnyDataAccess(communities) : true;
            });

            if (numVisible.length === 0) {
                delete this.dashboards[key];
            }
        });
    }

    public ngOnInit(): void {
        this.getEvents();
    }

    public ngOnDestroy() {
        this.destroyed$.complete();
        this.destroyed$.unsubscribe();
    }

    private initializeControls(): void {
        this.filtersChanged.pipe(takeUntil(this.destroyed$), debounceTime(500)).subscribe(() => {
            const filterChange = new EventSummaryFilters();
            filterChange.event = this.filterGroup.controls.event.value;
            filterChange.locations = this.filterGroup.controls.locations.value;
            filterChange.date = this.filterGroup.controls.date.value;

            EventDashboardComponent.eventFilterChange.next(filterChange);
        });

        const defaultLandingTypes = Object.keys(this.dashboards)
            .map((key) => {
                return this.dashboards[key].type;
            })
            .sort();

        const controls = {
            event: new FormControl<SystemEvent>(null),
            type: new FormControl<LandingType[]>(defaultLandingTypes),
            locations: new FormControl<State[]>([]),
            date: new FormControl<moment.Moment>(null),
        };

        controls.event.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
            this.eventStates = value.states;
            controls.locations.setValue(value.states);
        });

        controls.locations.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
            this.filtersChanged.next(undefined);
        });

        controls.date.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
            this.filtersChanged.next(undefined);
        });

        controls.type.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
            // This is here to ensure the type order is preserved
            controls.type.value.sort();
        });

        this.filterGroup = new FormGroup(controls);
    }

    private getEvents(): void {
        this.systemEventService.getAllEvents().subscribe((res) => {
            res.sort((a, b) => {
                if (a.eventStart.isBefore(b.eventStart)) return 1;
                else if (a.eventStart.isAfter(b.eventStart)) return -1;
                return 0;
            }).forEach((event) => {
                const isActive = ApplicationConfig.roundMinute().isBetween(event.eventStart, event.eventEnd, 'minute', '[]');
                const key = isActive ? 'active' : 'inactive';
                this.events[key].push(event);
            });

            const selectedEvent = this.events.active[0] || this.events.inactive[0];
            if (selectedEvent) {
                this.filterGroup.controls.event.setValue(selectedEvent);
            }
        });
    }

    public updateDate(event: TimeSliderEvent): void {
        this.filterGroup.controls.date.setValue(event.current);
    }
}
