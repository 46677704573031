<!--suppress TypeScriptUnresolvedVariable -->
<mat-drawer-container class="new-design example-container">
    <mat-drawer mode="over" position="end" disableClose [class.wide]="useMobileLayout()">
        <eaglei-add-community-user [community]="community" (addedUsers)="addBulkUsers($event)"></eaglei-add-community-user>
    </mat-drawer>

    <mat-drawer-content>
        <div class="community-container">
            <div class="title-section">
                <header class="page-title">{{ community.name }}</header>
            </div>

            <div class="header-section">
                <div class="community-header">
                    <mat-card class="wide">
                        <mat-card-subtitle>
                            <div class="filter-option" (click)="goToLandingPage()">
                                <img class="menu-icon" src="/dist/images/icons/back-arrow.svg" alt="Back Arrow" />
                                <span class="tab-title"> Back</span>
                            </div>

                            <div class="filter-option" (click)="changeTab('document')" [class.current-status]="selectedTab === 'document'">
                                <mat-icon fontSet="fas" fontIcon="fa-file-alt" [inline]="true"></mat-icon>
                                <span class="tab-title"> Documents</span>
                            </div>
                            <div class="filter-option" (click)="changeTab('user')" [class.current-status]="selectedTab === 'user'">
                                <mat-icon fontSet="fas" fontIcon="fa-user-friends" [inline]="true"></mat-icon>
                                <span class="tab-title"> Members</span>
                            </div>

                            <div
                                class="filter-option no-border"
                                *ngIf="isManager && community?.publicAccess"
                                (click)="changeTab('request')"
                                [class.current-status]="selectedTab === 'request'">
                                <mat-icon fontSet="fas" fontIcon="fa-user-clock" [inline]="true"></mat-icon>
                                <span
                                    class="request-badge tab-title"
                                    [matBadge]="community.pendingUsers.length.toString()"
                                    [matBadgeHidden]="community.pendingUsers.length === 0"
                                    matBadgeColor="warn">
                                    Requests</span
                                >
                            </div>

                            <div class="filter-option no-border">
                                <button mat-stroked-button class="leave-community" (click)="leaveCommunity()">
                                    <span>Leave Community</span>
                                </button>
                            </div>
                        </mat-card-subtitle>
                    </mat-card>
                </div>
            </div>

            <div class="about-section">
                <mat-card class="about">
                    <mat-card-subtitle>
                        <span class="title"> About </span>

                        <ng-container *ngIf="editCommunity">
                            <div style="display: flex; justify-content: start; gap: 4px; align-items: center">
                                <mat-form-field appearance="outline">
                                    <mat-label>Community Name</mat-label>
                                    <input type="text" matInput [(ngModel)]="tmpCommunity.name" />
                                </mat-form-field>
                                <button mat-icon-button (click)="updateCommunity()">
                                    <mat-icon fontSet="fas" fontIcon="fa-check"></mat-icon>
                                </button>

                                <button mat-icon-button (click)="editCommunity = false">
                                    <mat-icon fontSet="fas" fontIcon="fa-times"></mat-icon>
                                </button>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="useMobileLayout(true)" [ngTemplateOutlet]="leaveCommunityButton"></ng-container>

                        <div *ngIf="isManager && !editCommunity">
                            <button
                                mat-icon-button
                                (click)="emailAllUsers()"
                                matTooltip="Email Community Members"
                                matTooltipPosition="above"
                                matTooltipShowDelay="250">
                                <mat-icon fontSet="fas" fontIcon="fa-envelope-open-text"></mat-icon>
                            </button>
                            <button mat-icon-button *ngIf="!editCommunity" (click)="toggleCommunityEdit()">
                                <mat-icon fontSet="fas" fontIcon="fa-edit"></mat-icon>
                            </button>
                        </div>
                    </mat-card-subtitle>

                    <mat-card-content>
                        <div class="description-info">
                            <div class="sub-title">
                                <span>Description</span>
                                <span class="create-date">Created {{ community.createDate | momentDate : 'MMM DD, YYYY' }}</span>
                            </div>

                            <p class="description" *ngIf="!editCommunity">{{ community.description }}</p>

                            <div *ngIf="editCommunity">
                                <mat-form-field appearance="outline" class="wide">
                                    <textarea
                                        matInput
                                        cdkTextareaAutosize
                                        cdkAutosizeMinRows="5"
                                        cdkAutosizeMaxRows="5"
                                        [(ngModel)]="tmpCommunity.description">
                                    </textarea>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="private-section" *ngIf="!community.publicAccess">
                            <mat-icon fontSet="fas" fontIcon="fa-lock"></mat-icon>
                            <div>
                                <p class="title">Private</p>
                                <p class="sub-title">Members join by invitation only</p>
                            </div>
                        </div>

                        <div class="manager-info">
                            <p class="sub-title">Managers</p>

                            <div *ngFor="let manager of managers; let last = last" class="community-manager">
                                <div class="name-circle">
                                    <p class="user-initials">{{ manager.initials }}</p>
                                </div>
                                <div class="manager-name" [class.no-border]="last">
                                    <p class="title">{{ manager.name }}</p>
                                    <p class="sub-title">Manager</p>
                                </div>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>

            <div class="content-section">
                <ng-container [ngSwitch]="selectedTab">
                    <ng-container *ngSwitchCase="'document'">
                        <ng-container [ngTemplateOutlet]="document"></ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="'user'">
                        <ng-container [ngTemplateOutlet]="users"></ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="'request'">
                        <ng-container [ngTemplateOutlet]="request"></ng-container>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </mat-drawer-content>
</mat-drawer-container>

<ng-container>
    <input #communityFileUpload type="file" multiple hidden (click)="communityFileUpload.value = null" (change)="uploadFile()" />
</ng-container>

<!--Document -->
<ng-template #document>
    <mat-card>
        <mat-card-subtitle class="content-tab">
            <span class="title"> Documents &#8226; {{ filteredDocuments.length | number }}</span>

            <div class="search-container">
                <button mat-icon-button *ngIf="isManager && !useMobileLayout()" (click)="clickFileUpload()">
                    <mat-icon
                        fontSet="fas"
                        fontIcon="fa-upload"
                        matTooltip="Upload File"
                        matTooltipPosition="above"
                        matTooltipShowDelay="250">
                    </mat-icon>
                </button>

                <mat-form-field appearance="outline">
                    <mat-label>Search Documents</mat-label>
                    <input matInput (keyup)="searchDocuments($event.target.value)" />
                    <mat-icon matSuffix fontSet="fas" fontIcon="fa-search"></mat-icon>
                </mat-form-field>
            </div>
        </mat-card-subtitle>

        <mat-card-content class="info-content">
            <div *ngIf="community.files.length === 0" class="info-message">
                <p>There are no documents for this community</p>
            </div>

            <div *ngIf="community.files.length !== 0 && filteredDocuments.length === 0" class="info-message">
                <p>There are no documents matching your search.</p>
            </div>

            <div *ngIf="documentPaginator" class="document-list">
                <div *ngFor="let file of getPaginatorValues(documentPaginator, filteredDocuments); let index = index" class="document">
                    <div class="community-file" [class.no-border]="index < 3" [class.first-row]="index < 3">
                        <div>
                            <a
                                *ngIf="!file.editing; else editDocumentName"
                                class="file-name ellipsis"
                                (click)="downloadFile(file)"
                                [matTooltip]="file.displayName"
                                matTooltipPosition="below">
                                {{ file.displayName }}
                            </a>

                            <ng-template #editDocumentName>
                                <mat-form-field appearance="outline" class="edit-filename">
                                    <mat-label>Document Name</mat-label>
                                    <input type="text" matInput class="small" [(ngModel)]="file.tmpName" />
                                </mat-form-field>

                                <div class="input-suffix">
                                    <mat-icon fontSet="fas" fontIcon="fa-check" (click)="changeFilename(file)"></mat-icon>
                                    <mat-icon fontSet="fas" fontIcon="fa-times" (click)="file.editing = false"></mat-icon>
                                </div>
                            </ng-template>

                            <button *ngIf="isManager && !file.editing" mat-icon-button [matMenuTriggerFor]="fileMenu">
                                <mat-icon fontSet="fas" fontIcon="fa-ellipsis-v"></mat-icon>
                            </button>
                        </div>

                        <p class="file-upload-date">{{ file.uploadDate | momentDate : 'MMM D, YYYY' }}</p>

                        <div class="file-event-list" *ngIf="file.events">
                            <mat-chip *ngFor="let event of file.events">{{ event.name }}</mat-chip>
                        </div>

                        <mat-menu #fileMenu="matMenu" [overlapTrigger]="true" xPosition="before">
                            <button mat-menu-item (click)="renameFile(file)">
                                <img class="menu-icon" src="/dist/images/icons/rename.svg" alt="Rename File" />
                                <span> Rename</span>
                            </button>
                            <button mat-menu-item (click)="deleteFile(file)">
                                <mat-icon fontSet="fas" fontIcon="fa-trash-alt" class="menu-icon"></mat-icon>
                                <span>Remove</span>
                            </button>
                        </mat-menu>
                    </div>
                </div>
            </div>
        </mat-card-content>

        <mat-card-footer>
            <mat-paginator #documentPaginator [pageSizeOptions]="[25, 50, 75, 100]" showFirstLastButtons></mat-paginator>
        </mat-card-footer>
    </mat-card>
</ng-template>

<!--User-->
<ng-template #users>
    <mat-card>
        <mat-card-subtitle class="content-tab">
            <span class="title"> Community Members &#8226; {{ filteredUsers.length | number }}</span>

            <div class="search-container">
                <button mat-icon-button *ngIf="isManager" (click)="openAddUserModel()">
                    <mat-icon
                        fontSet="fas"
                        fontIcon="fa-user-plus"
                        matTooltip="Add User(s)"
                        matTooltipPosition="above"
                        matTooltipShowDelay="250">
                    </mat-icon>
                </button>

                <mat-form-field appearance="outline">
                    <mat-label>Search User Name</mat-label>
                    <input matInput placeholder="Search User Name" (keyup)="searchUsers($event.target.value)" />
                    <mat-icon matSuffix fontSet="fas" fontIcon="fa-search"></mat-icon>
                </mat-form-field>
            </div>
        </mat-card-subtitle>

        <mat-card-content class="info-content">
            <div *ngIf="community.users.length === 0" class="info-message">
                <p>There are no users in this community.</p>
            </div>

            <div *ngIf="community.users.length !== 0 && filteredUsers.length === 0" class="info-message">
                <p>There are no users matching your search.</p>
            </div>

            <div *ngIf="userPaginator" class="document-list">
                <div *ngFor="let user of getPaginatorValues(userPaginator, filteredUsers)" class="document">
                    <div class="user-info">
                        <div class="user-name wide">
                            <p class="ellipsis">{{ user.lastName }}, {{ user.firstName }}</p>
                            <p class="ellipsis">{{ user.email }}</p>
                        </div>

                        <button mat-icon-button *ngIf="isManager && !useMobileLayout()" [matMenuTriggerFor]="userMenu">
                            <mat-icon fontSet="fas" fontIcon="fa-ellipsis-v"> </mat-icon>
                        </button>

                        <mat-menu #userMenu="matMenu" overlapTrigger xPosition="before">
                            <button mat-menu-item (click)="changeManagerPrivilege(user, true)" *ngIf="!user.manager && user.isApproved()">
                                <mat-icon fontSet="fas" fontIcon="fa-user-cog" class="menu-icon" [inline]="true"></mat-icon>
                                <span>Give Manager Access</span>
                            </button>

                            <button mat-menu-item (click)="changeManagerPrivilege(user, false)" *ngIf="user.manager && user.isApproved()">
                                <mat-icon fontSet="fas" fontIcon="fa-user-cog" class="menu-icon" [inline]="true"></mat-icon>
                                <span>Remove Manager Access</span>
                            </button>

                            <button mat-menu-item (click)="removeUser(user)">
                                <mat-icon fontSet="fas" fontIcon="fa-trash-alt" class="menu-icon" [inline]="true"></mat-icon>
                                <span>Remove</span>
                            </button>
                        </mat-menu>
                    </div>
                </div>
            </div>
        </mat-card-content>

        <mat-card-footer>
            <mat-paginator #userPaginator [pageSizeOptions]="[25, 50, 75, 100]" showFirstLastButtons></mat-paginator>
        </mat-card-footer>
    </mat-card>
</ng-template>

<!--Request-->
<ng-template #request>
    <mat-card>
        <mat-card-subtitle class="content-tab">
            <span class="title"> Requests &#8226; {{ community.pendingUsers.length | number }}</span>
        </mat-card-subtitle>

        <mat-card-content class="info-content">
            <div *ngIf="community.pendingUsers.length === 0" class="info-message">
                <p>There are no pending requests in this community</p>
            </div>

            <ng-container *ngIf="requestPaginator">
                <ng-container *ngFor="let user of getPaginatorValues(requestPaginator, community.pendingUsers)">
                    <div class="pending-user">
                        <div class="user-name">
                            <p class="ellipsis">{{ user.name }}</p>

                            <div class="hide" [class.show]="user.justificationVisible">
                                {{ user.justification }}
                            </div>

                            <u (click)="user.justificationVisible = !user.justificationVisible" class="clickable">
                                {{ user.justificationVisible ? 'Show Less' : 'Show Justification' }}
                            </u>
                        </div>

                        <div class="pending-user-action">
                            <button mat-stroked-button class="approve-icon" [matMenuTriggerFor]="denyOptionMenu">Reject</button>
                            <button mat-flat-button color="primary" (click)="approveUser(user)">Approve</button>

                            <mat-menu #denyOptionMenu="matMenu" [overlapTrigger]="true" xPosition="before">
                                <button
                                    mat-menu-item
                                    *ngFor="let option of denyOptions"
                                    (click)="denyUser(user, option)"
                                    [matTooltip]="option"
                                    matTooltipShowDelay="250"
                                    matTooltipPosition="above">
                                    <span>{{ option }}</span>
                                </button>
                                <button mat-menu-item (click)="denyUser(user, '', true)">
                                    <div class="deny-option">
                                        <mat-icon fontSet="fas" fontIcon="fa-plus"></mat-icon>
                                        <span> Other</span>
                                    </div>
                                </button>
                            </mat-menu>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </mat-card-content>

        <mat-card-footer>
            <mat-paginator #requestPaginator [pageSizeOptions]="[25, 50, 75, 100]" showFirstLastButtons></mat-paginator>
        </mat-card-footer>
    </mat-card>
</ng-template>
