export class PrincipalPort {
    static fromApi(data: any): any {
        const ret = new PrincipalPort();
        ret.portName = data.PORT_NAME;
        ret.type = data.TYPE;
        ret.imports = data.IMPORTS;
        ret.exports = data.EXPORTS;
        ret.domestic = data.DOMESTIC;
        ret.foreign = data.FOREIGN_;
        ret.total = data.TOTAL;
        return ret;
    }
    public portName: string;
    public type: string;
    public imports: number;
    public exports: number;
    public domestic: number;
    public foreign: number;
    public total: number;
}
