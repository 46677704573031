import {LeafletVectorSource} from '../../../app/modules/layer/classes/leaflet-vector-source';
import {LeafletFeature} from '../../../app/modules/layer/classes/leaflet-feature';
import * as L from 'leaflet';
import {MapService} from '../../../app/modules/map/services/map.service';
import {LayerStyleService} from '../../../app/modules/layer/services/layer-style.service';
import {LeafletMapLayer} from '../../../app/modules/layer/classes/leaflet-map-layer';
import {GenServiceType} from '../../../../generated/serverModels/GenServiceType';
import {UrbannetEffect} from './urbannet-effect';
import {UrbannetDisruption} from './urbannet-disruption';
import {GenUrbanNetFemaType} from '../../../../generated/serverModels/GenUrbanNetFemaType';

export class UrbannetEffectSource extends LeafletVectorSource<UrbannetEffect> {
    static URBANNET_EFFECT_COLOR = '#ff0000';

    constructor() {
        super(
            new LeafletMapLayer({
                displayName: 'URBAN-NET Effects',
                servicetype: GenServiceType.VECTOR,
                uiHandle: `urbannet-effect`,
                attributionTitle: '',
                attributionUrl: '',
            } as any)
        );
    }

    processFeatures(features: UrbannetEffect[]): void {
        this.leafletFeatures = features.map((f) => new LeafletFeature().convert(f, 'geometry')).filter((feature) => !!feature);

        const style = (feature: LeafletFeature<UrbannetEffect>, latlng: L.LatLng): L.Layer => {
            return L.circleMarker(latlng, this.getStyle());
        };

        const layerPopover = (feature: LeafletFeature<UrbannetEffect>, layer: L.GeoJSON) => {
            this.initializePopoverInteractions(feature, layer, 'click', false, feature.properties.popoverData);
            if (feature.geometry.type.toLowerCase().includes('line')) {
                layer.setStyle(this.getLineStyle());
            } else if (feature.geometry.type.toLowerCase().includes('polygon')) {
                layer.setStyle(this.getPolygonStyle());
            }
        };

        const config: any = {
            pane: MapService.layerPaneName,
            pointToLayer: style,
            onEachFeature: layerPopover,
            features: this.leafletFeatures,
        };

        this.source = L.geoJSON(this.leafletFeatures as any, config);
    }

    private getStyle(): any {
        const key = 'urbannet-effect-point';
        let style = LayerStyleService.layerStyles.get(key);
        if (!style) {
            style = {
                radius: 4,
                color: UrbannetEffectSource.URBANNET_EFFECT_COLOR,
                fillColor: UrbannetEffectSource.URBANNET_EFFECT_COLOR,
                weight: 1,
                opacity: 1,
                fillOpacity: 0.8,
            };
            LayerStyleService.layerStyles.set(key, style);
        }
        return style;
    }

    private getLineStyle(): any {
        const key = `urbannet-effect-line`;
        let style = LayerStyleService.layerStyles.get(key);
        if (!style) {
            style = {
                color: UrbannetEffectSource.URBANNET_EFFECT_COLOR,
                stroke: true,
                weight: 2,
                opacity: 1,
            };

            LayerStyleService.layerStyles.set(key, style);
        }
        return style;
    }

    private getPolygonStyle(): any {
        const key = `urbannet-effect-polygon`;
        let style = LayerStyleService.layerStyles.get(key);
        if (!style) {
            style = {
                fillColor: UrbannetEffectSource.URBANNET_EFFECT_COLOR,
                fillOpacity: 0.3,
                color: UrbannetEffectSource.URBANNET_EFFECT_COLOR,
                weight: 1,
            };

            LayerStyleService.layerStyles.set(key, style);
        }
        return style;
    }
}
