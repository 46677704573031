import * as moment from 'moment';

export class AutoUpdate {
    public nextUpdate: moment.Moment;
    public lastUpdate: moment.Moment;

    private updateTimeout: any;
    private updateInterval: any;

    public _minuteInterval = 15;
    public updateData: () => any;
    private _autoUpdate = false;

    get autoUpdate(): boolean {
        return this._autoUpdate;
    }

    set autoUpdate(val: boolean) {
        this._autoUpdate = val;

        if (this.autoUpdate) {
            this.nextUpdate = moment()
                .minute(Math.ceil(moment().minute() / this.minuteInterval) * this.minuteInterval)
                .second(30);
            this.updateTimeout = setTimeout(() => {
                this.updatePage();
                this.updateInterval = setInterval(() => {
                    this.updatePage();
                }, 1000 * 60 * this.minuteInterval);
            }, this.nextUpdate.valueOf() - moment().valueOf());
        } else {
            this.clear();
        }
    }

    get minuteInterval(): number {
        return this._minuteInterval;
    }

    set minuteInterval(val: number) {
        this._minuteInterval = val;

        this.autoUpdate = this.autoUpdate;
    }

    constructor(updateFunc: () => any) {
        this.updateData = updateFunc;

        this.lastUpdate = moment().second(0);
        this.nextUpdate = moment()
            .minute(Math.ceil(moment().add(1, 'minute').minute() / this.minuteInterval) * this.minuteInterval)
            .second(0);
    }

    private updatePage(): void {
        this.lastUpdate = moment().second(0);
        this.nextUpdate = moment()
            .minute(Math.ceil(moment().add(1, 'minute').minute() / this.minuteInterval) * this.minuteInterval)
            .second(0);

        this.updateData();
    }

    public clear(): void {
        clearTimeout(this.updateTimeout);
        clearInterval(this.updateInterval);
    }

    public setMinuteInterval(minute: number): AutoUpdate {
        this._minuteInterval = minute;
        this.autoUpdate = this.autoUpdate;
        return this;
    }

    public setAutoUpdate(auto: boolean): AutoUpdate {
        this.autoUpdate = auto;
        return this;
    }
}
