
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import { GenWidget } from './GenWidget';
import { GenWidgetSetting } from './GenWidgetSetting';

export class GenWidgetDetail {

    id: number;
    height: number;
    layoutId: number;
    settings: GenWidgetSetting[];
    widget: GenWidget;
    widgetId: number;
    width: number;
    xPos: number;
    yPos: number;

    constructor();
    constructor( jsonObj: any );
    constructor( genWidgetDetail: GenWidgetDetail);
    constructor( id: number );

    constructor( input?: GenWidgetDetail|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            this.height = input.height; // int [number]
            this.layoutId = input.layoutId; // int [number]
            if ( input.settings && input.settings.map ) { // list [GenWidgetSetting]
                this.settings = input.settings.map( ( x: GenWidgetSetting ) => {
                    return new GenWidgetSetting( x );
                } );
            } else {
                this.settings = input.settings;
            }
            if( input.widget ) {
              this.widget = new GenWidget( input.widget );
            }
            this.widgetId = input.widgetId; // int [number]
            this.width = input.width; // int [number]
            this.xPos = input.xPos; // int [number]
            this.yPos = input.yPos; // int [number]
       }
    }

    static jsonResponseToList(arr: any[]): GenWidgetDetail[] {
        if (arr && arr.map) {
          return arr.map(a => new GenWidgetDetail(a));
        } else {
          return [];
        }
    }
}

