<div class="legend-bar">
    <div *ngFor="let item of values; let index = index" [style.background-color]="colors[index]" class="legend-value">
        {{ item }}
    </div>
</div>

<div class="legend-info">
    <mat-icon
        *ngIf="!hideOverrideIcon"
        inline
        fontSet="far"
        [fontIcon]="showOverrides ? 'fa-eye' : 'fa-eye-slash'"
        [class.clickable]="!disableHideOverrides"
        (click)="toggleShowOverrides()"
        [matTooltip]="!showOverrides ? 'Show Overrides' : 'Hide Overrides'"
        matTooltipPosition="above"
        matTooltipShowDelay="250">
    </mat-icon>
    <span #overrideHash [style.border-color]="getOverrideColor()" class="outage-bg"></span>
    <span #overrideText class="override-text">Active Overrides</span>
</div>
