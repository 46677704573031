import {GenUserPreferences} from '../../../generated/serverModels/GenUserPreferences';
import {GenOutageAggregationLevel} from '../../../generated/serverModels/GenOutageAggregationLevel';
import {ApplicationConfig} from './application-config';
import {LegendStyle} from '../modules/user/classes/legend-style';
import {State} from '../modules/outage/classes/state';
import {DataService} from '../services/data.service';
import {FemaRegion} from '../modules/outage/classes/fema-region';
import {LegendMetric} from '../../shared/enums/legend-metric.enum';
import {GenUserPreferenceAggregationLevel} from '../../../generated/serverModels/GenUserPreferenceAggregationLevel';

export class UserPreference extends GenUserPreferences {
    constructor(preference?: UserPreference | GenUserPreferences) {
        super(preference);

        this.femaRegions = this.femaRegions || [];
        this.states = this.states || [];

        this.legendStyle = this.hasLegendStyle() ? preference.legendStyle : new LegendStyle();
        this.legendMetric = this.legendMetric === undefined || this.legendMetric === 0 ? LegendMetric.CUSTOMERS_OUT : this.legendMetric;

        if (!this.getOutageAggregationLevel()) {
            this.setAggregationLevel(GenOutageAggregationLevel.state);
        }
    }

    // TODO remove fema aggregation
    public hasRegion(regionId: number): boolean {
        return this.femaRegions.findIndex((f) => f === regionId) !== -1;
    }

    public hasState(stateId: number): boolean {
        return this.states.findIndex((s) => s.id === stateId) !== -1;
    }

    public hasLegendStyle(): boolean {
        return this.legendStyle && this.legendStyle.id !== undefined;
    }

    public getOverrideColor(): string {
        if (this.hasLegendStyle()) {
            return this.legendStyle.overrideColor;
        } else {
            return ApplicationConfig.overrideColor;
        }
    }

    public getOutageAggregationLevel(): GenOutageAggregationLevel {
        if (!this.aggregationLevel) {
            return undefined;
        }
        switch (this.aggregationLevel.id) {
            case 2:
                return GenOutageAggregationLevel.state;
            case 3:
                return GenOutageAggregationLevel.county;
            default:
                return undefined;
        }
    }

    public setAggregationLevel(level: GenOutageAggregationLevel): void {
        switch (level) {
            case GenOutageAggregationLevel.state:
                this.aggregationLevel = new GenUserPreferenceAggregationLevel(2);
                break;
            case GenOutageAggregationLevel.county:
                this.aggregationLevel = new GenUserPreferenceAggregationLevel(3);
                break;
            default:
                return undefined;
        }
    }

    public getStates(): State[] {
        const stateMap = new Map<string, State>();
        const regions = DataService.femaRegions.getValue().filter((r) => this.femaRegions.includes(r.id));
        const stateNames = this.states.map((state) => state.abbreviation);

        DataService.getStatesFromFemaRegions(regions).forEach((s) => stateMap.set(s.abbreviation, s));
        DataService.states
            .getValue()
            .filter((state) => stateNames.includes(state.abbreviation))
            .forEach((state) => stateMap.set(state.abbreviation, state));

        let ret = Array.from(stateMap.values());
        if (ret.length === 0) {
            ret = DataService.states.getValue();
        }

        return ret;
    }

    public getFemaRegions(): FemaRegion[] {
        const filtered = DataService.femaRegions.getValue().filter((fr) => this.femaRegions.includes(fr.id));
        return filtered.length === 0 ? DataService.femaRegions.getValue() : filtered;
    }

    public getLegendMetric(): LegendMetric {
        return LegendMetric.from(this.legendMetric);
    }
}
