import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {MapLayerGroup} from '../../layer/classes/map-layer-group';
import {map} from 'rxjs/operators';
import {LeafletMapLayer} from '../../layer/classes/leaflet-map-layer';

@Injectable({
    providedIn: 'root',
})
export class SidebarService {
    constructor(private http: HttpClient) {}

    public getLayerGroups(onlyShowActive: boolean = true): Observable<MapLayerGroup[]> {
        let groupUrl = 'api/maplayer';
        if (!onlyShowActive) {
            groupUrl += `?activeonly=${onlyShowActive}`;
        }
        return this.http.get<MapLayerGroup[]>(groupUrl).pipe(map((groups) => groups.map((group) => new MapLayerGroup(group))));
    }

    public saveMapLayers(layers: LeafletMapLayer[]): Observable<LeafletMapLayer[]> {
        return this.http.post<any[]>('api/maplayer', layers).pipe(map((mapLayers) => mapLayers.map((layer) => new LeafletMapLayer(layer))));
    }

    public updateOrder(groups: MapLayerGroup[]): Observable<boolean> {
        return this.http.post<boolean>(`api/maplayer/updateorder`, groups);
    }
}
