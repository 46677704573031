
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!


export class GenEventMedia {

    id: number;
    link: string;
    source: string;

    constructor();
    constructor( jsonObj: any );
    constructor( genEventMedia: GenEventMedia);
    constructor( id: number );

    constructor( input?: GenEventMedia|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            this.link = input.link; // string [string]
            this.source = input.source; // string [string]
       }
    }

    static jsonResponseToList(arr: any[]): GenEventMedia[] {
        if (arr && arr.map) {
          return arr.map(a => new GenEventMedia(a));
        } else {
          return [];
        }
    }
}

