
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!


export class GenState {

    id: number;
    abbreviation: string;
    affgeoid: string;
    aland: number;
    awater: number;
    boundary: string;
    dotregion: number;
    geoid: string;
    lsad: string;
    name: string;
    stateAbbreviation: string;
    statefp: string;
    statens: string;

    constructor();
    constructor( jsonObj: any );
    constructor( genState: GenState);
    constructor( id: number );

    constructor( input?: GenState|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            this.abbreviation = input.abbreviation; // string [string]
            this.affgeoid = input.affgeoid; // string [string]
            this.aland = input.aland; // float [number]
            this.awater = input.awater; // float [number]
            this.boundary = input.boundary; // string [string]
            this.dotregion = input.dotregion; // int [number]
            this.geoid = input.geoid; // string [string]
            this.lsad = input.lsad; // string [string]
            this.name = input.name; // string [string]
            this.stateAbbreviation = input.stateAbbreviation; // string [string]
            this.statefp = input.statefp; // string [string]
            this.statens = input.statens; // string [string]
       }
    }

    static jsonResponseToList(arr: any[]): GenState[] {
        if (arr && arr.map) {
          return arr.map(a => new GenState(a));
        } else {
          return [];
        }
    }
}

