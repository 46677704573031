import {PopoverElement} from '../../../classes/popover-element';
import {LayerStyleService} from '../services/layer-style.service';
import {HeadoutAdvisory} from '../classes/headout-advisory';
import {FeatureStyle} from '../interfaces/style.interface';
import {LeafletVectorSource} from '../classes/leaflet-vector-source';
import {LeafletMapLayer} from '../classes/leaflet-map-layer';
import {LeafletFeature} from '../classes/leaflet-feature';
import {MapService} from '../../map/services/map.service';
import * as L from 'leaflet';
import {LeafletEagleiSource} from '../classes/leaflet-eaglei-source';
import {GenEventResult} from '../../../../../generated/serverModels/GenEventResult';
import * as turf from '@turf/turf';
import {HeadoutEventResultCustomerDetail} from '../../../integrations/headout/classes/headout-event-result-customer-detail';
import {HeadoutEventDetail} from '../../../integrations/headout/classes/headout-event-detail';
import {HeadoutEventResult} from '../../../integrations/headout/classes/headout-event-result';
import {getCustomerDetailPopover} from '../../../integrations/headout/functions/create-popover';
import {GenServiceType} from '../../../../../generated/serverModels/GenServiceType';
import {inject} from '@angular/core';

export class HeadoutSource extends LeafletVectorSource {
    private styleService: LayerStyleService;
    public selectedAdvisoryId: number;

    private outageSource: L.GeoJSON;

    constructor(layerInfo: LeafletMapLayer, styleService: LayerStyleService) {
        super(layerInfo);
        this.styleService = styleService;
        LayerStyleService.layerStyles.set('headoutAdvisory', this.createAdvisoryStyle());
        LayerStyleService.layerStyles.set('selectedHeadoutAdvisory', this.createAdvisoryStyle('#003366', '#003366', 5));
    }

    processDetails(eventDetails: HeadoutEventDetail, features: HeadoutEventResultCustomerDetail[]): void {
        this.clearOutages();

        const leafletFeatures = features.map((f) => {
            const lf = new LeafletFeature().convert(f.county);
            lf.properties = f;

            lf.subFeatures = [
                {
                    id: f.id,
                    popoverData: this.detailPopover(eventDetails, f),
                },
            ];

            return lf;
        });

        const layerPopover = (f: LeafletFeature<HeadoutEventResultCustomerDetail>, l) => {
            this.initializePopoverInteractions(f, l, 'click', false, this.detailPopover(eventDetails, f.properties));
        };

        const styleFunction = (feature: LeafletFeature<HeadoutEventResultCustomerDetail>) => {
            return this.getOutageStyle(feature.properties.percent * 100);
        };

        const config = {
            pane: MapService.layerPaneName,
            style: styleFunction,
            onEachFeature: layerPopover,
        };

        this.outageSource = L.geoJSON(leafletFeatures as any, config as any);
    }

    processFeatures(eventDetails: HeadoutEventDetail): void {
        this.clearOutages();

        this.leafletFeatures = eventDetails.eventResults
            .filter((f) => !isNaN(f.latitude) && !isNaN(f.longitude))
            .map((f) => {
                (f as any).tmpBoundary = turf.point([f.longitude, f.latitude]).geometry;
                const feature = new LeafletFeature().convert(f, 'tmpBoundary');

                feature.subFeatures = [
                    {
                        id: f.id,
                        popoverData: this.advisoryPopover(eventDetails, feature.properties),
                    },
                ];

                return feature;
            });

        const layerPopover = (f: LeafletFeature<GenEventResult>, l) => {
            this.initializePopoverInteractions(f, l, 'click', false, this.advisoryPopover(eventDetails, f.properties));
        };

        const styleFunction = (point, latlng) => {
            return L.circleMarker(latlng, this.getAdvisoryStyle(point.properties));
        };

        const config = {
            pane: MapService.layerPaneName,
            pointToLayer: styleFunction,
            onEachFeature: layerPopover,
        };

        this.source = L.geoJSON(this.leafletFeatures as any, config as any);
    }

    // Style Functions
    // noinspection JSMethodCanBeStatic
    private createAdvisoryStyle(strokeColor: string = '#000000', color: string = '#ff0000', radius: number = 4): FeatureStyle {
        return {
            radius,
            color: strokeColor,
            fillColor: color,
            fillOpacity: 1,
            weight: 1,
        };
    }

    private getOutageStyle(percent: number): FeatureStyle {
        const color = this.styleService.colorByPercent(percent);

        const styleKey = `headout-outage-${color}`;
        let style: FeatureStyle = LayerStyleService.layerStyles.get(styleKey);

        if (!style) {
            style = {
                fillColor: color,
                fillOpacity: 0.8,
                color: '#808080',
                weight: 1,
            };
            LayerStyleService.layerStyles.set(styleKey, style);
        }
        return style;
    }

    private getAdvisoryStyle(advisory: HeadoutAdvisory) {
        const key = advisory.id === this.selectedAdvisoryId ? 'selectedHeadoutAdvisory' : 'headoutAdvisory';
        return LayerStyleService.layerStyles.get(key);
    }

    public restyleAdvisory(id?: number) {
        this.selectedAdvisoryId = id;

        this.source.eachLayer((layer: any) => {
            layer.setStyle(this.getAdvisoryStyle(layer.feature.properties));
        });
    }

    public updateOutageOpacity(opacity: number): void {
        this.outageSource.setStyle({opacity, fillOpacity: opacity});
    }

    // Popover functions
    // noinspection JSMethodCanBeStatic
    private advisoryPopover(eventDetails: HeadoutEventDetail, advisory: HeadoutEventResult): PopoverElement[] {
        const ret: PopoverElement[] = [];
        const windSpeed = advisory.windspeed === undefined ? 'Not Available' : `${advisory.windspeed} MPH`;
        const windGust = advisory.windgust === undefined ? 'Not Available' : `${advisory.windgust} MPH`;

        ret.push(new PopoverElement('title', eventDetails.name).setTitle());
        ret.push(new PopoverElement('NHC ID', advisory.resultId));
        ret.push(new PopoverElement('Wind Speed', windSpeed, false, false));
        ret.push(new PopoverElement('Wind Gusts', windGust, false, false));
        ret.push(new PopoverElement('Issued', `${LeafletEagleiSource.momentPipe.transform(advisory.advdate)}`, false, false));
        if (advisory.forecastText) {
            ret.push(new PopoverElement('Forecast', advisory.forecastText).setIsModal());
        }
        return ret;
    }

    // noinspection JSMethodCanBeStatic
    private detailPopover(eventDetails: HeadoutEventDetail, detail: HeadoutEventResultCustomerDetail): PopoverElement[] {
        const advisory = eventDetails.eventResults.find((r) => r.id === this.selectedAdvisoryId);
        return getCustomerDetailPopover(eventDetails, detail, advisory);
    }

    // Utility Methods and Overrides

    public addDetailsToMap() {
        (this.outageSource as any).eagleiLayer = new LeafletMapLayer({
            displayName: 'HEADOUT Details',
            servicetype: GenServiceType.VECTOR,
            uiHandle: `headout-details`,
            attributionTitle: '',
            attributionUrl: '',
        } as any);

        // Creating a local function to pass in the source for what data has the setInteractive flags set
        const detailSetInteractive = (isInteractive: boolean) => {
            this.setInteractive(isInteractive, this.outageSource);
        };

        (this.outageSource as any).layerType = this.layerInfo.servicetype;
        (this.outageSource as any).setInteractive = detailSetInteractive.bind(this);

        this.outageSource.addTo(MapService.mapRef);
        this.outageSource.bringToBack();
    }

    public clearOutages(): void {
        if (this.outageSource) {
            this.outageSource.removeFrom(MapService.mapRef);
        }
    }

    // Override
    public fitToFeatures(): void {
        const layers = this.outageSource.getLayers();

        if (layers.length === 0) {
            return;
        }

        const selectedAdvisory = this.source.getLayers().find((l: any) => l.feature.properties.id === this.selectedAdvisoryId);
        // If there is not a selected advisory matching the selected id, don't push undefined onto the feature list.
        if (selectedAdvisory) {
            layers.push(selectedAdvisory);
        }

        super.fitToFeatures(layers);
    }

    // Override
    public removeFromMap() {
        super.removeFromMap();
        this.clearOutages();
    }

    public changeOpacity(opacity: number) {
        super.changeOpacity(opacity);

        if (!this.outageSource) {
            return;
        }
        this.outageSource.setStyle({opacity, fillOpacity: opacity});
    }
}
