<gridster [options]="config" class="grid">
    <gridster-item [item]="item" *ngFor="let item of dashboard">
        <div [ngSwitch]="item.widgetName.name">
            <ng-container *ngSwitchCase="'COMMUNITY_LIST'">
                <eaglei-community-widget class="ignore-content" [item]="item" [eventDashboard]="true" (loading)="item.loading = $event">
                </eaglei-community-widget>
            </ng-container>

            <ng-container *ngSwitchCase="'OUTAGE_MAP'">
                <eaglei-outage-map-widget class="ignore-content" [item]="item" [eventDashboard]="true" (loading)="item.loading = $event">
                </eaglei-outage-map-widget>
            </ng-container>

            <ng-container *ngSwitchCase="'REPORT_LIST'">
                <eaglei-report-widget
                    class="ignore-content"
                    [item]="item"
                    [eventDashboard]="true"
                    (loading)="item.loading = $event"></eaglei-report-widget>
            </ng-container>

            <ng-container *ngSwitchCase="'STATS'">
                <eaglei-stat-widget
                    class="ignore-content"
                    [item]="item"
                    [eventDashboard]="true"
                    (loading)="item.loading = $event"></eaglei-stat-widget>
            </ng-container>

            <ng-container *ngSwitchCase="'UTILITIES_IMPACTED'">
                <eaglei-utilities-impacted-widget
                    class="ignore-content"
                    [item]="item"
                    [eventDashboard]="true"
                    (loading)="item.loading = $event"></eaglei-utilities-impacted-widget>
            </ng-container>

            <ng-container *ngSwitchCase="'OUTAGE_CHART'">
                <eaglei-chart-widget
                    class="ignore-content"
                    [item]="item"
                    [eventDashboard]="true"
                    (loading)="item.loading = $event"></eaglei-chart-widget>
            </ng-container>

            <ng-container *ngSwitchCase="'OUTAGE_SUMMARY'">
                <eaglei-summary-widget
                    class="ignore-content"
                    [item]="item"
                    [eventDashboard]="true"
                    (loading)="item.loading = $event"></eaglei-summary-widget>
            </ng-container>

            <ng-container *ngSwitchCase="'DAILY_POWER_ESTIMATE'">
                <eaglei-power-estimate-widget
                    class="ignore-content"
                    [item]="item"
                    [eventDashboard]="true"
                    (loading)="item.loading = $event"></eaglei-power-estimate-widget>
            </ng-container>

            <ng-container *ngSwitchCase="'GAS_BURN'">
                <eaglei-gas-burn-widget
                    class="ignore-content"
                    [item]="item"
                    [eventDashboard]="true"
                    (loading)="item.loading = $event"></eaglei-gas-burn-widget>
            </ng-container>

            <ng-container *ngSwitchCase="'OIL_STORAGE'">
                <eaglei-oil-storage-widget
                    class="ignore-content"
                    [item]="item"
                    [eventDashboard]="true"
                    (loading)="item.loading = $event"></eaglei-oil-storage-widget>
            </ng-container>

            <ng-container *ngSwitchCase="'NG_NOTICES'">
                <eaglei-ng-notices-widget
                    class="ignore-content"
                    [item]="item"
                    [eventDashboard]="true"
                    (loading)="item.loading = $event"></eaglei-ng-notices-widget>
            </ng-container>

            <ng-container *ngSwitchCase="'OIL_REFINERY_STATUS'">
                <eaglei-refinery-status-widget
                    class="ignore-content"
                    [item]="item"
                    [eventDashboard]="true"
                    (loading)="item.loading = $event"></eaglei-refinery-status-widget>
            </ng-container>

            <ng-container *ngSwitchCase="'OIL_BARREL_FLOW'">
                <eaglei-oil-barrel-flow-widget
                    class="ignore-content"
                    [item]="item"
                    [eventDashboard]="true"
                    (loading)="item.loading = $event"></eaglei-oil-barrel-flow-widget>
            </ng-container>

            <ng-container *ngSwitchCase="'OIL_REFINERY_ALERT'">
                <eaglei-oil-refinery-alert-widget
                    class="ignore-content"
                    [item]="item"
                    [eventDashboard]="true"
                    (loading)="item.loading = $event"></eaglei-oil-refinery-alert-widget>
            </ng-container>

            <ng-container *ngSwitchCase="'EIA_PETROLEUM_REPORT'">
                <eaglei-eia-weekly-petroleum-report-widget
                    class="ignore-content"
                    [item]="item"
                    [eventDashboard]="true"
                    (loading)="item.loading = $event"></eaglei-eia-weekly-petroleum-report-widget>
            </ng-container>

            <ng-container *ngSwitchCase="'OIL_PLANNED_OUTAGES'">
                <eaglei-oil-planned-outage-widget
                    class="ignore-content"
                    [item]="item"
                    [eventDashboard]="true"
                    (loading)="item.loading = $event"></eaglei-oil-planned-outage-widget>
            </ng-container>

            <ng-container *ngSwitchCase="'OIL_REFINERY_CAPACITY'">
                <eaglei-oil-capacity-widget
                    class="ignore-content"
                    [item]="item"
                    [eventDashboard]="true"
                    (loading)="item.loading = $event"></eaglei-oil-capacity-widget>
            </ng-container>

            <ng-container *ngSwitchCase="'DERIVED_STORAGE'">
                <eaglei-derived-storage-widget
                    class="ignore-content"
                    [item]="item"
                    [eventDashboard]="true"
                    (loading)="item.loading = $event"></eaglei-derived-storage-widget>
            </ng-container>

            <ng-container *ngSwitchCase="'UNDERGROUND_STORAGE'">
                <eaglei-underground-storage-widget
                    class="ignore-content"
                    [item]="item"
                    [eventDashboard]="true"
                    (loading)="item.loading = $event"></eaglei-underground-storage-widget>
            </ng-container>

            <ng-container *ngSwitchCase="'OIL_FLOW_TABLE'">
                <eaglei-oil-flow-table-widget
                    class="ignore-content"
                    [item]="item"
                    [eventDashboard]="true"
                    (loading)="item.loading = $event"></eaglei-oil-flow-table-widget>
            </ng-container>

            <ng-container *ngSwitchCase="'NG_NOMINATION'">
                <eaglei-ng-nomination-widget
                    class="ignore-content"
                    [item]="item"
                    [eventDashboard]="true"
                    (loading)="item.loading = $event"></eaglei-ng-nomination-widget>
            </ng-container>

            <ng-container *ngSwitchCase="'NG_RECEIPT_DELIVERY'">
                <eaglei-ng-receipt-delivery-widget
                    class="ignore-content"
                    [item]="item"
                    [eventDashboard]="true"
                    (loading)="item.loading = $event"></eaglei-ng-receipt-delivery-widget>
            </ng-container>

            <ng-container *ngSwitchCase="'NG_OPERATIONAL_FLOW'">
                <eaglei-ng-operational-flow-widget
                    class="ignore-content"
                    [item]="item"
                    [eventDashboard]="true"
                    (loading)="item.loading = $event"></eaglei-ng-operational-flow-widget>
            </ng-container>

            <ng-container *ngSwitchCase="'EVENT_RESTORATION'">
                <eaglei-restoration-widget
                    class="ignore-content"
                    [item]="item"
                    [eventDashboard]="true"
                    (loading)="item.loading = $event"></eaglei-restoration-widget>
            </ng-container>

            <ng-container *ngSwitchCase="'EVENT_HEADOUT'">
                <eaglei-headout-table-widget
                    class="ignore-content"
                    [item]="item"
                    [eventDashboard]="true"
                    (loading)="item.loading = $event"></eaglei-headout-table-widget>
            </ng-container>

            <ng-container *ngSwitchCase="'EVENT_SNAPSHOT'">
                <eaglei-snapshot-widget
                    class="ignore-content"
                    [item]="item"
                    [eventDashboard]="true"
                    (loading)="item.loading = $event"></eaglei-snapshot-widget>
            </ng-container>

            <ng-container *ngSwitchCase="'EVENT_PORTS'">
                <eaglei-port-widget
                    class="ignore-content"
                    [item]="item"
                    [eventDashboard]="true"
                    (loading)="item.loading = $event"></eaglei-port-widget>
            </ng-container>

            <ng-container *ngSwitchCase="'SITUATION_REPORTS'">
                <eaglei-situation-widget
                    class="ignore-content"
                    [item]="item"
                    [eventDashboard]="true"
                    (loading)="item.loading = $event"></eaglei-situation-widget>
            </ng-container>

            <ng-container *ngSwitchCase="'EVENT_RRCC'">
                <eaglei-rrcc-widget
                    class="ignore-content"
                    [item]="item"
                    [eventDashboard]="true"
                    (loading)="item.loading = $event"></eaglei-rrcc-widget>
            </ng-container>

            <ng-container *ngSwitchCase="'EIL_DOCUMENTS'">
                <eaglei-document-widget
                    class="ignore-content"
                    [item]="item"
                    [eventDashboard]="true"
                    (loading)="item.loading = $event"></eaglei-document-widget>
            </ng-container>

            <ng-container *ngSwitchCase="'UAS_POLE_DETECTION'">
                <eaglei-pole-detection-widget
                    class="ignore-content"
                    [item]="item"
                    [eventDashboard]="true"
                    (loading)="item.loading = $event">
                </eaglei-pole-detection-widget>
            </ng-container>

            <ng-container *ngSwitchCase="'SYSTEM_NEWS'">
                <eaglei-system-news-widget class="ignore-content" [item]="item" [eventDashboard]="true" (loading)="item.loading = $event">
                </eaglei-system-news-widget>
            </ng-container>

            <ng-container *ngSwitchCase="'URBAN_NET'">
                <eaglei-urbannet-widget class="ignore-content" [item]="item" [eventDashboard]="true" (loading)="item.loading = $event">
                </eaglei-urbannet-widget>
            </ng-container>
            <ng-container *ngSwitchCase="'PNNL_RADR'">
                <eaglei-pnnl-radr-widget class="ignore-content" [item]="item" [eventDashboard]="true" (loading)="item.loading = $event">
                </eaglei-pnnl-radr-widget>
            </ng-container>
            <ng-container *ngSwitchCase="'WAO_TABLE'">
                <eaglei-wao-widget class="ignore-content" [item]="item" [eventDashboard]="true" (loading)="item.loading = $event">
                </eaglei-wao-widget>
            </ng-container>
        </div>

        <div class="auto-mask" *ngIf="item.loading">
            <div class="mask-message">Loading...</div>
        </div>
    </gridster-item>
</gridster>
