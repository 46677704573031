import {LayerStyleService} from '../services/layer-style.service';
import {LeafletVectorSource} from '../classes/leaflet-vector-source';
import {LeafletMapLayer} from '../classes/leaflet-map-layer';
import {LeafletFeature} from '../classes/leaflet-feature';
import * as L from 'leaflet';
import {LatLng, Layer} from 'leaflet';
import {GenTreasuryFacility} from '../../../../../generated/serverModels/GenTreasuryFacility';
import {LegendElement} from '../classes/legend-element';
import {MapService} from '../../map/services/map.service';

export class TreasuriesSource extends LeafletVectorSource {
    constructor(layerInfo: LeafletMapLayer) {
        super(layerInfo);
    }

    private getStyle(): any {
        const key = `treasuryFacility`;
        let style = LayerStyleService.layerStyles.get(key);
        if (!style) {
            const fillColor = '#000000';

            style = {
                radius: 6,
                fillColor: fillColor,
                color: '#000',
                weight: 1,
                opacity: 1,
                fillOpacity: 0.8,
            };
            LayerStyleService.layerStyles.set(key, style);

            const ele = new LegendElement();
            ele.name = 'Treasury Facility';
            ele.fillColor = fillColor;
            ele.radius = 4;
            ele.width = 20;
            ele.height = 20;
        }
        return style;
    }

    public processFeatures(features: any[]): void {
        const leafletFeatures = features.map((v) => new LeafletFeature<GenTreasuryFacility>().convert(v).buildPopover(this.layerInfo));

        const style = (geoJsonPoint: any, latlng: LatLng): Layer => {
            return L.circleMarker(latlng, this.getStyle());
        };

        const config: any = {
            pane: MapService.layerPaneName,
            pointToLayer: style,
            onEachFeature: this.initializePopoverInteractions.bind(this),
            features: leafletFeatures,
        };

        this.source = L.geoJSON(leafletFeatures as any, config);
    }
}
