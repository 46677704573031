
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!


export class GenLayoutType {

    id: number;
    type: string;

    constructor();
    constructor( jsonObj: any );
    constructor( genLayoutType: GenLayoutType);
    constructor( id: number );

    constructor( input?: GenLayoutType|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            this.type = input.type; // string [string]
       }
    }

    static jsonResponseToList(arr: any[]): GenLayoutType[] {
        if (arr && arr.map) {
          return arr.map(a => new GenLayoutType(a));
        } else {
          return [];
        }
    }
}

