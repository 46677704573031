import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {ApplicationConfig} from 'frontend/src/app/classes/application-config';
import {EagleiBaseChart} from 'frontend/src/app/classes/charts/base-chart';
import {DataPoint} from 'frontend/src/app/classes/data-point';
import * as moment from 'moment';
import {filter, takeUntil} from 'rxjs/operators';
import {BaseWidget} from '../../classes/base-widget';
import {WidgetService} from '../../services/widget.service';
import * as echarts from 'echarts';
import {FileDownload} from 'frontend/src/app/classes/file-download';
import {IGasBurn} from 'frontend/src/app/interfaces/ong/gas-burn.interface';
import {PopoverElement} from 'frontend/src/app/classes/popover-element';
import {Report} from '../../../report/classes/report';
import {ShortNumberPipe} from '../../../../../shared/pipes/short-number.pipe';
import {LayerService} from '../../../layer/services/layer.service';
import {LeafletMapLayer} from '../../../layer/classes/leaflet-map-layer';
import {State} from '../../../outage/classes/state';
import {OngService} from '../../../../services/ong.service';
import {EventSummaryFilters} from '../../../system-event/classes/event-summary-filters';
import {gasBurnWidget} from '../../../../../integrations/ong/classes/ong-attributions-text';

interface LineChartData<T = any> {
    points: DataPoint<T>[];
}

@Component({
    selector: 'eaglei-gas-burn-widget',
    templateUrl: './gas-burn-widget.component.html',
    styleUrls: ['./gas-burn-widget.component.scss'],
})
export class GasBurnWidgetComponent extends BaseWidget implements AfterViewInit {
    // Table Elements
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    // Chart Elements
    @ViewChild('chartTarget', {static: true}) chartTarget: ElementRef;

    // Table Properties
    public readonly columnNames: string[] = ['name', 'flow', 'county', 'state'];
    public dataSource: MatTableDataSource<IGasBurn>;

    // Chart Properties
    public baseChart = new EagleiBaseChart<any, any, any>();
    private readonly chartColor = [
        {offset: 0, color: 'rgba(2, 136, 243, 0.25)'},
        {offset: 1, color: 'rgba(255, 255, 255)'},
    ];
    private readonly shortPipe = new ShortNumberPipe();

    // Shared Properties
    public selectedRow: IGasBurn;

    private mapLayer: LeafletMapLayer;

    public states: State[];

    public showMask: boolean = true;

    constructor(
        private widgetElement: ElementRef,
        private widgetService: WidgetService,
        private ongService: OngService,
        private layerService: LayerService
    ) {
        super(widgetElement);
        this.attributionUrl = 'https://www.woodmac.com/';
        this.attributionText = 'Genscape: A Wood Mackenzie Business';

        this.getPreferences();
    }

    protected handleEventFilterChange(eventFilters: EventSummaryFilters): void {
        this.updateState(eventFilters.locations);
    }

    public ngAfterViewInit(): void {
        this.getGasBurn();
        this.initChart();
        this.setSubscriptions();
        this.attributionModalText = gasBurnWidget;
        this.widgetName = 'Gas Burn';

        const chartHeight = this.getHeight(this.content) - 50;
        this.setHeight(this.chartTarget, chartHeight);
        this.baseChart.eChart.resize();
    }

    destroyWidget(): void {}

    private getGasBurn(): void {
        this.ongService.getGasBurnData().subscribe((res) => {
            this.initData((res.features as any[]).map((d) => d.properties).filter((d) => this.states.map((s) => s.name).includes(d.state)));
        });

        this.layerService.getLayerByHandle('ongGasBurn').subscribe((layer) => (this.mapLayer = layer));
    }

    private initData(data: IGasBurn[]): void {
        data.forEach((d) => {
            d.gas_burns.sort((a, b) => (moment(a.flow_timestamp).isBefore(moment(b.flow_timestamp)) ? 1 : -1));
        });

        if (this.dataSource) {
            this.dataSource.data = data;
        } else {
            this.dataSource = new MatTableDataSource(data);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sortingDataAccessor = this.dataAccessor.bind(this);
            this.dataSource.sort = this.sort;
        }

        this.selectedRow = this.dataSource.data[0];
        this.renderChart();
        this.showMask = this.dataSource.filteredData.length <= 0;
    }

    // noinspection JSMethodCanBeStatic
    private dataAccessor(data: IGasBurn, header: string): number | string {
        switch (header) {
            case 'name':
                return data.location_name.toLowerCase();
            case 'flow':
                return data.gas_burns[0].hourly_flow;
            case 'county':
                return data.county.toLowerCase();
            case 'state':
                return data.state.toLowerCase();
            default:
                return '';
        }
    }

    private initChart(): void {
        this.baseChart.interactivePopover = false;
        this.baseChart.initializeEChart(this.chartTarget.nativeElement, true, 'Date', 'Flow (Mcf)');

        this.baseChart.getXAxis().type = 'time';
        this.baseChart.getXAxis().boundaryGap = false;

        this.baseChart.getXAxis().axisLabel.formatter = (label) => {
            return moment(label).format('M/D - hA');
        };

        this.baseChart.getYAxis().nameTextStyle = {
            padding: [0, 0, 0, 0],
        };
    }

    private renderChart(): void {
        const details = this.selectedRow?.gas_burns;
        const line: LineChartData = {
            points: [],
        };

        const getPopoverData = (data: any) => {
            return [
                new PopoverElement('Title', moment(data.flow_timestamp).format('M/D/YY h A')),
                new PopoverElement('Flow (Mcf)', Report.numberPipe.transform(data.hourly_flow).toString()),
            ];
        };

        line.points = details
            ?.sort((a, b) => (moment(a.flow_timestamp).isBefore(moment(b.flow_timestamp)) ? 1 : -1))
            .map((d) => {
                const val = d.hourly_flow;

                return new DataPoint({
                    x: moment(d.flow_timestamp).valueOf(),
                    y: val,
                    popoverData: getPopoverData(d),
                    color: '#0288F3',
                });
            });

        this.baseChart.eChartOptions.xAxis['data'] = line.points?.map((dp) => moment(dp.data.x).valueOf());

        const lineData = line.points?.map((point) => {
            return {
                value: [point.data.x, point.data.y],
                popoverData: point.data.popoverData,
            };
        });
        this.baseChart.eChartOptions.series = [
            {
                name: 'Hourly Flow',
                type: 'line',
                smooth: 0.5,
                lineStyle: {
                    color: '#0288F3',
                },
                itemStyle: {
                    color: '#0288F3',
                },
                areaStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0.5, 0, 1, this.chartColor) as any,
                },
                data: lineData,
            },
        ];
        this.baseChart.eChart.setOption(this.baseChart.eChartOptions, true);
    }

    public updateSelectedRow(row: IGasBurn): void {
        this.selectedRow = row;
        this.renderChart();
    }

    private setSubscriptions(): void {
        WidgetService.resize
            .pipe(
                filter((widget) => this.item.x === widget.x && this.item.y === widget.y),
                takeUntil(this.destroy$)
            )
            .subscribe(() => {
                this.resize();

                const chartHeight = this.getHeight(this.content) - 50;
                this.setHeight(this.chartTarget, chartHeight);

                this.baseChart.eChart.resize();
            });
    }

    private getPreferences() {
        const preferences = ApplicationConfig.currentUserPreferences.getValue();
        this.states = preferences.getStates();
    }

    public updateState(states: State[]): void {
        this.states = states;
        this.getGasBurn();
    }

    public exportTable(): void {
        let data: string = ['Name', 'Hourly Flow (Mcf)', 'County', 'State'].join() + '\n';
        this.dataSource.filteredData.forEach((d) => {
            const values = [
                FileDownload.formatCsvCell(d.location_name),
                FileDownload.formatCsvCell(d.gas_burns[0].hourly_flow),
                FileDownload.formatCsvCell(d.county),
                FileDownload.formatCsvCell(d.state),
            ];
            data += `${values.join()}\n`;
        });

        FileDownload.downloadCSV('gasBurnWidget', data, this.attributionUrl);
    }

    public exportChart(): void {
        const title = `${this.selectedRow?.location_name} Hourly Flow`;

        FileDownload.exportChartAsPNG(this.baseChart, title, title, this.attributionUrl);
    }

    public getMapperLink(): void {
        this.layerService.navigateToMap([this.mapLayer]);
    }

    hasEllipsis(locationName: HTMLElement): boolean {
        return ApplicationConfig.hasEllipsis(locationName);
    }

    onMobile() {
        return ApplicationConfig.onMobile();
    }
}
