import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Filter} from '../../classes/filter';
import {GenLayerFilterType} from '../../../../../../generated/serverModels/GenLayerFilterType';
import {IFilterChange} from '../../interfaces/filter-change.interface';

@Component({
    selector: 'eaglei-filter-container',
    templateUrl: './filter-container.component.html',
    styleUrls: ['./filter-container.component.scss'],
})
export class FilterContainerComponent implements OnInit {
    private _filters: Filter[];
    @Input() set filters(val: Filter[]) {
        this._filters = val
            // .map(filter => new Filter(filter))
            .filter((f) => {
                const typeCheck = [this.filterTypes.SINGLE_SELECT, this.filterTypes.MULTI_SELECT].includes(f.type);
                return !typeCheck || (typeCheck && f.values);
            });
    }

    get filters() {
        return this._filters;
    }

    @Output() filterChange = new EventEmitter<IFilterChange>();

    public filterTypes = GenLayerFilterType;

    constructor() {}

    ngOnInit(): void {}

    public updateFilters(filters: Filter[]): void {
        this.filters = filters;
    }

    updateFilterQuery(event: any, filter: Filter) {
        this.filterChange.emit({value: event, filter});
    }
}
