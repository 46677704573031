<div class="container">
    <div #header class="widget-header">
        <p>{{ getHeader() }}</p>
    </div>

    <div #content class="widget-content">
        <div class="summary-list">
            <div class="summary-item" *ngFor="let summary of getPaginatorValues()">
                <div *ngIf="!isLoading" class="container">
                    <div class="header-info">
                        <div class="outage-title">
                            <span class="outage-dot" [style.background-color]="getOutageColor(summary)"></span>
                            <p class="title ellipsis" [matTooltip]="getSummaryTitle(summary)">{{ getSummaryTitle(summary) }}</p>
                        </div>

                        <div class="trend-container">
                            <mat-icon class="trend" [class.up]="checkTrend(summary, 'up')" [class.down]="checkTrend(summary, 'down')">{{
                                summary.trendLine
                            }}</mat-icon>
                            <span class="sub-percent">{{ summary.trendPercent | eagleiNumber }}</span>
                        </div>
                    </div>

                    <div>
                        <p class="sub-text">Total without power</p>
                        <p class="info-number">{{ summary.customersOut | eagleiNumber }}</p>
                    </div>

                    <div>
                        <p class="sub-text">Percent without power</p>
                        <p *ngIf="summary.coveredCustomers > 0" class="info-number">{{ summary.percentOut | percent : '1.2-2' }}</p>
                        <p *ngIf="summary.coveredCustomers <= 0" class="info-number">N/A</p>
                    </div>

                    <div>
                        <p class="sub-text">Total Customers</p>
                        <div clASS="total-customers">
                            <p class="info-number">{{ summary.coveredCustomers | eagleiNumber }}</p>
                            <span class="county-customer">{{ getCountyCustomerText(summary) }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="unknownSummaryType">
                <span>Configure Aggregation for Summary</span>
            </div>

            <div *ngIf="isLoading">
                <span>Loading ...</span>
            </div>

            <div *ngIf="getPaginatorValues().length <= 0 && !unknownSummaryType">
                <span>No Data Available</span>
            </div>
        </div>
    </div>

    <div #footer>
        <mat-paginator [pageSizeOptions]="[25, 50, 75, 100]" hidePageSize showFirstLastButtons></mat-paginator>
    </div>
</div>
