import * as moment from 'moment';
export class Survey123Feature {
    public objectid: number;
    public globalid: string;
    public incidentId: string;
    public incidentName: string;
    public incidentStartDate: moment.Moment;
    public incidentStateArray: any;
    public incidentStatus: any;
    public pdaId: any;
    public pdaDateTime: moment.Moment;
    public reviewFlag: string;
    public statusSurvey: string;
    public horAccuracy: number;
    public latitude: number;
    public longitude: number;
    public usng: any;
    public unitSuiteRevGeocode: any;
    public cityRevGeocode: any;
    public zipRevGeocode: any;
    public locationName: string;
    public unitSuite: any;
    public unitSuite2: any;
    public city: string;
    public unitSuiteDisplay: any;
    public cityDisplay: any;
    public stateTerritory: string;
    public county: string;
    public countyLabel: string;
    public zip: string;
    public zipDisplay: string;
    public incidentType: string;
    public applicantName: string;
    public applicantFips: string;
    public applicantPoc: string;
    public applicantPhone: string;
    public applicantEmail: string;
    public pdmgName: string;
    public pdmgPhone: string;
    public pdmgEmail: string;
    public priorGrants: any;
    public applicantPriority: any;
    public paType: string;
    public facilityName: string;
    public narrative: string;
    public cost: number;
    public completeWork: any;
    public workPct: any;
    public completeWorkLabor: any;
    public uncompleteWorkLabor: any;
    public epmCost: number;
    public epmCostCatB: number;
    public repairCost: string;
    public totalCost: number;
    public displayCost: number;
    public email: string;
    public userType: string;
    public surveyVersion: string;
    public assessmentDateTime: moment.Moment;
    public assessmentAreaDrawCount: any;
    public photoCount: number;
    public uriDeviceIdCalculate: string;
    public createdUserPrior: any;
    public createdDatePrior: moment.Moment;
    public lastEditedUserPrior: any;
    public lastEditedDatePrior: moment.Moment;
    public assessmentId: any;
    public displayAddrCoords: string;
    public displayUser: string;
    public category: string;
    public displayCat: string;
    public collectionType: string;
    public creationDate: moment.Moment;
    public creator: string;
    public editDate: moment.Moment;
    public editor: string;
    public esrignssSpeed: any;
    public esrignssDirection: any;
    public esrisnsrAzimuth: any;
    public esrignssPositionSourceType: any;
    public esrignssReceiver: any;
    public esrignssHRms: any;
    public esrignssVRms: any;
    public esrignssLatitude: any;
    public esrignssLongitude: any;
    public esrignssAltitude: any;
    public esrignssPdop: any;
    public esrignssHdop: any;
    public esrignssVdop: any;
    public esrignssFixtype: any;
    public esrignssCorrectionage: any;
    public esrignssStationid: any;
    public esrignssNumsats: any;
    public esrignssFixDateTime: moment.Moment;
    public esrignssAvgHRms: any;
    public esrignssAvgVRms: any;
    public esrignssAvgPositions: any;
    public esrignssHStdde: any;
    public dataYear: string;

    constructor(feature: any, dataYear: string) {
        this.dataYear = dataYear;
        if (feature) {
            this.globalid = feature['globalid'];
            this.objectid = feature['objectid'];
            this.incidentId = feature['incident_id'];
            this.incidentName = feature['incident_name'];
            this.incidentStartDate = moment(feature['incident_start_date']);
            this.incidentStateArray = feature['incident_state_array'];
            this.incidentStatus = feature['incident_status'];
            this.pdaId = feature['pda_id'];
            this.pdaDateTime = moment(feature['pda_datetime']);
            this.reviewFlag = feature['review_flag'];
            this.statusSurvey = feature['status_survey'];
            this.horAccuracy = feature['horaccuracy'];
            this.latitude = feature['lat'];
            this.longitude = feature['lon'];
            this.usng = feature['usng'];
            this.unitSuiteRevGeocode = feature['unit_suite_rev_geocode'];
            this.cityRevGeocode = feature['city_rev_geocode'];
            this.zipRevGeocode = feature['zip_rev_geocode'];
            this.locationName = feature['location_name'];
            this.unitSuite = feature['unit_suite'];
            this.unitSuite2 = feature['unit_suite_2'];
            this.city = feature['city'];
            this.unitSuiteDisplay = feature['unit_suite_display'];
            this.cityDisplay = feature['city_display'];
            this.stateTerritory = feature['state_territory'];
            this.county = feature['county'];
            this.countyLabel = feature['county_label'];
            this.zip = feature['zip'];
            this.zipDisplay = feature['zip_display'];
            this.incidentType = feature['incident_type'];
            this.applicantName = feature['applicant_name'];
            this.applicantFips = feature['applicant_fips'];
            this.applicantPoc = feature['applicant_poc'];
            this.applicantPhone = feature['applicant_phone'];
            this.applicantEmail = feature['applicant_email'];
            this.pdmgName = feature['pdmg_name'];
            this.pdmgPhone = feature['pdmg_phone'];
            this.pdmgEmail = feature['pdmg_email'];
            this.priorGrants = feature['prior_grants'];
            this.applicantPriority = feature['applicant_priority'];
            this.paType = feature['pa_type'];
            this.facilityName = feature['facility_name'];
            this.narrative = feature['narrative'];
            this.cost = feature['cost'];
            this.completeWork = feature['complete_work'];
            this.workPct = feature['work_pct'];
            this.completeWorkLabor = feature['complete_work_labor'];
            this.uncompleteWorkLabor = feature['uncomplete_work_labor'];
            this.epmCost = feature['epm_cost'];
            this.epmCostCatB = feature['epm_cost_cat_b'];
            this.repairCost = feature['repair_cost'];
            this.totalCost = feature['total_cost'];
            this.displayCost = feature['display_cost'];
            this.email = feature['email'];
            this.userType = feature['user_type'];
            this.surveyVersion = feature['survey_version'];
            this.assessmentDateTime = moment(feature['assessment_datetime']);
            this.assessmentAreaDrawCount = feature['assessment_area_draw_count'];
            this.photoCount = feature['photo_count'];
            this.uriDeviceIdCalculate = feature['uri_deviceid_calculate'];
            this.createdUserPrior = feature['created_user_prior'];
            this.createdDatePrior = moment(feature['created_date_prior']);
            this.lastEditedUserPrior = feature['last_edited_user_prior'];
            this.lastEditedDatePrior = moment(feature['last_edited_date_prior']);
            this.assessmentId = feature['assessment_id'];
            this.displayAddrCoords = feature['display_addr_coords'];
            this.displayUser = feature['display_user'];
            this.category = feature['category'];
            this.displayCat = feature['display_cat'];
            this.collectionType = feature['collection_type'];
            this.creationDate = moment(feature['CreationDate']);
            this.creator = feature['Creator'];
            this.editDate = moment(feature['EditDate']);
            this.editor = feature['Editor'];
            this.esrignssSpeed = feature['esrignss_speed'];
            this.esrignssDirection = feature['esrignss_direction'];
            this.esrisnsrAzimuth = feature['esrisnsr_azimuth'];
            this.esrignssPositionSourceType = feature['esrignss_positionsourcetype'];
            this.esrignssReceiver = feature['esrignss_receiver'];
            this.esrignssHRms = feature['esrignss_h_rms'];
            this.esrignssVRms = feature['esrignss_v_rms'];
            this.esrignssLatitude = feature['esrignss_latitude'];
            this.esrignssLongitude = feature['esrignss_longitude'];
            this.esrignssAltitude = feature['esrignss_altitude'];
            this.esrignssPdop = feature['esrignss_pdop'];
            this.esrignssHdop = feature['esrignss_hdop'];
            this.esrignssVdop = feature['esrignss_vdop'];
            this.esrignssFixtype = feature['esrignss_fixtype'];
            this.esrignssCorrectionage = feature['esrignss_correctionage'];
            this.esrignssStationid = feature['esrignss_stationid'];
            this.esrignssNumsats = feature['esrignss_numsats'];
            this.esrignssFixDateTime = moment(feature['esrignss_fixdatetime']);
            this.esrignssAvgHRms = feature['esrignss_avg_h_rms'];
            this.esrignssAvgVRms = feature['esrignss_avg_v_rms'];
            this.esrignssAvgPositions = feature['esrignss_avg_positions'];
            this.esrignssHStdde = feature['esrignss_h_stddev'];
        }
    }
}
