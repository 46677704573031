import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {State} from 'frontend/src/app/modules/outage/classes/state';
import {DataService} from 'frontend/src/app/services/data.service';
import {NumeralPipe} from '../../pipes/numeral.pipe';

@Component({
    selector: 'eaglei-region-filter',
    templateUrl: './region-filter.component.html',
    styleUrls: ['./region-filter.component.scss'],
})
export class RegionFilterComponent implements OnInit {
    @Input() multipleSelection = true;
    @Input() filterFema: () => void = this.femaFilter;
    @Input() filterState: () => void = this.statesFilter;

    @Input() showFemaFilter = true;
    @Input() showStateFilter = true;
    @Input() useUrlFilter = true;

    @Input() initialState: State[] | State;
    @Input() initialFema: number[] | number;

    @Output() change: EventEmitter<any> = new EventEmitter<any>();

    private allSelected = true;
    private femaRegions: number[] = [];
    private states: State[] = [];

    private activeFemaList: number[] = [];
    private activeStateList: State[] = [];
    private statesNotInSelectedFema: State[] = [];

    private _selectedFemas: number[] | number = [];
    private _selectedStates: State[] | State = [];

    get selectedStates(): State[] | State {
        return this._selectedStates;
    }

    get selectedFemas(): number[] | number {
        return this._selectedFemas;
    }

    @Input('activeListState')
    set activeListState(state: State[]) {
        this.activeStateList = state;

        if (this.multipleSelection) {
            this._selectedStates = this.activeStateList;
            this.checkAllSelected();
        } else {
            this._selectedStates = this.activeStateList.includes(this.selectedStates as State) ? this.selectedStates : undefined;
        }
    }

    @Input('activeListFema')
    set activeListFema(fema: number[]) {
        this.activeFemaList = fema;

        if (this.multipleSelection) {
            this._selectedFemas = this.activeFemaList;
            this.checkAllSelected();
        } else {
            this._selectedFemas = this.activeFemaList.includes(this.selectedFemas as number) ? this.selectedFemas : undefined;
        }
    }

    private _stateDisable: boolean;
    private _femaDisable: boolean;

    @Input('stateDisable')
    set stateDisable(val: boolean) {
        this._stateDisable = val;
    }

    @Input('femaDisable')
    set femaDisable(val: boolean) {
        this._femaDisable = val;
    }

    @Input('disabled')
    set disabled(val: boolean) {
        this.stateDisable = val;
        this.femaDisable = val;
    }

    constructor(private dataService: DataService, private route: ActivatedRoute) {}

    ngOnInit() {
        this.states = this.dataService.stateNames.value
            .map((s) => s)
            .sort((s1, s2) => {
                if (s1.name > s2.name) {
                    return 1;
                } else if (s1.name < s2.name) {
                    return -1;
                }

                return 0;
            });
        this.femaRegions = this.dataService.femaNames.value.map((f) => f.id);

        this.activeFemaList = [...this.femaRegions];
        this.activeStateList = [...this.states];

        this.setupURLFilter();
    }

    private statesFilterMultiple(): void {
        if (this.allSelected) {
            this.allSelected = false;
            this._selectedStates = (this._selectedStates as State[]).filter((s) => typeof s !== 'boolean');
        }

        if ((this._selectedStates as State[]).filter((s) => typeof s !== 'boolean').length === this.activeStateList.length) {
            this.allSelected = true;
            this._selectedStates = [this.allSelected, ...(this._selectedStates as State[])] as any;
        }

        if (this.allSelected) {
            this._selectedFemas = [this.allSelected, ...this.activeFemaList] as any;
        } else {
            this._selectedFemas = this.activeFemaList.filter(
                (f) =>
                    this.activeStateList.filter((s) => s.dotregion === f).length ===
                    (this._selectedStates as State[]).filter((s) => s.dotregion === f).length
            );
        }

        this.statesNotInSelectedFema = (this._selectedStates as State[]).filter(
            (s) => !(this._selectedFemas as number[]).includes(s.dotregion)
        );

        this.emitChanges();
    }

    private femaFilterMultiple(): void {
        if (this.allSelected) {
            this.allSelected = false;
            this._selectedFemas = (this._selectedFemas as number[]).filter((f) => typeof f !== 'boolean');
        }

        if ((this._selectedFemas as number[]).filter((f) => typeof f !== 'boolean').length === this.activeFemaList.length) {
            this.allSelected = true;
            this._selectedFemas = [this.allSelected, ...(this._selectedFemas as number[])] as any;
        }

        if (this.allSelected) {
            this._selectedStates = [this.allSelected, ...this.activeStateList] as any;
        } else {
            this._selectedStates = this.activeStateList.filter((s) => (this._selectedFemas as number[]).includes(s.dotregion));
            this.statesNotInSelectedFema.forEach((s) => {
                if (!(this._selectedStates as State[]).includes(s)) {
                    (this._selectedStates as State[]).push(s);
                }
            });
        }

        this.emitChanges();
    }

    private statesFilter(): void {
        if (this.multipleSelection) {
            this.statesFilterMultiple();
        }

        this.emitChanges();
    }

    private femaFilter(): void {
        if (this.multipleSelection) {
            this.femaFilterMultiple();
        } else {
            this.activeStateList = this.states.filter((s) => s.dotregion === (this.selectedFemas as number));
        }

        this.emitChanges();
    }

    private selectAll(): void {
        this.allSelected = !this.allSelected;

        if (this.allSelected) {
            this._selectedFemas = [this.allSelected, ...this.activeFemaList] as any;
            this._selectedStates = [this.allSelected, ...this.activeStateList] as any;
        } else {
            this._selectedFemas = [];
            this._selectedStates = [];
        }

        this.emitChanges();
    }

    private setupURLFilter(): void {
        if (this.initialFema) {
            this._selectedFemas = this.initialFema;
        } else {
            this._selectedFemas = this.multipleSelection ? ([this.allSelected, ...this.femaRegions] as any) : undefined;
        }

        if (this.initialState) {
            this._selectedStates = this.initialState;
        } else {
            this._selectedStates = this.multipleSelection ? ([this.allSelected, ...this.states] as any) : undefined;
        }

        if (this.useUrlFilter) {
            this.route.queryParams.subscribe((params) => {
                if (params['fema']) {
                    const fema: Set<number> = new Set(
                        typeof params['fema'] === 'string' ? [+params['fema']] : params['fema'].map((f) => +f)
                    );
                    this._selectedFemas = [...fema];
                    this._selectedStates = this.states.filter((s) => (this._selectedFemas as number[]).includes(s.dotregion));
                }

                if (params['state']) {
                    const state: Set<State> = new Set(this.states.filter((s) => params['state'].includes(s.abbreviation)));
                    this._selectedStates = [...new Set([...(this._selectedStates as State[]), ...state])];
                    this.statesNotInSelectedFema = [...state];
                    this.sortStates();
                }
            });
        }

        this.emitChanges();
    }

    private checkAllSelected(): void {
        this.allSelected =
            (this._selectedFemas as number[]).filter((f) => typeof f !== 'boolean').length === this.activeFemaList.length &&
            (this._selectedStates as State[]).filter((s) => typeof s !== 'boolean').length === this.activeStateList.length;

        if (this.allSelected) {
            this._selectedFemas = [this.allSelected, ...this.activeFemaList] as any;
            this._selectedStates = [this.allSelected, ...this.activeStateList] as any;
        }
    }

    private getStateNames(): string[] | string {
        let retString: string[] | string = 'Not Avaliable';

        if (this.multipleSelection) {
            retString = (this._selectedStates as State[]).filter((s) => typeof s !== 'boolean').map((s) => s.name);
        } else {
            retString = this.selectedStates ? (this.selectedStates as State).name : '';
        }

        return retString;
    }

    private getFemaNames(): string[] | string {
        const femaPipe = new NumeralPipe();
        let retString: string[] | string = 'Not Avaliable';

        if (this.multipleSelection) {
            retString = (this._selectedFemas as number[])
                .filter((f) => typeof f !== 'boolean')
                .map((f) => femaPipe.transform(f.toString(), true));
        } else {
            retString = femaPipe.transform(this.selectedFemas.toString(), true);
        }

        return retString;
    }

    private sortStates(): void {
        (this._selectedStates as State[]).sort((s1, s2) => {
            if (s1.name > s2.name) {
                return 1;
            } else if (s1.name < s2.name) {
                return -1;
            }

            return 0;
        });
    }

    private emitChanges(): void {
        this.change.emit({
            states: this.multipleSelection ? (this._selectedStates as State[]).filter((s) => typeof s !== 'boolean') : this.selectedStates,
            regions: this.multipleSelection ? (this._selectedFemas as number[]).filter((f) => typeof f !== 'boolean') : this.selectedFemas,
        });
    }
}
