import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SummaryUpdateModalComponent} from './modals/summary-update-modal/summary-update-modal.component';
import {MaterialModule} from '../../material.module';
import {SharedModule} from '../../../shared/shared.module';
import {CsvDownloadModalComponent} from './modals/csv-download-modal/csv-download-modal.component';

@NgModule({
    declarations: [SummaryUpdateModalComponent, CsvDownloadModalComponent],
    imports: [CommonModule, MaterialModule, SharedModule],
    exports: [SummaryUpdateModalComponent, CsvDownloadModalComponent],
})
export class OutageModule {}
