import * as moment from 'moment';

export class RefineryAlert {
    public alert_id: number;
    public description: string;
    public distributed_datetime: moment.Moment;
    public facility_capacity: number;
    public facility_id: string;
    public facility_name: string;
    public latitude: number;
    public longitude: number;
    public product_class: string;
    public state: string;
    public unit_capacity: number;
    public unit_category: string;
    public unit_id: string;
    public unit_name: string;

    constructor(input?: RefineryAlert | number) {
        if (typeof input === 'number') {
            this.alert_id = input;
        }

        if (input && typeof input !== 'number') {
            if (input.distributed_datetime && typeof input.distributed_datetime === 'string') {
                this.distributed_datetime = moment(input.distributed_datetime);
            } else {
                this.distributed_datetime = input.distributed_datetime;
            }

            this.alert_id = input.alert_id;
            this.description = input.description;
            this.facility_capacity = input.facility_capacity;
            this.facility_id = input.facility_id;
            this.facility_name = input.facility_name;
            this.latitude = input.latitude;
            this.longitude = input.longitude;
            this.product_class = input.product_class;
            this.state = input.state;
            this.unit_capacity = input.unit_capacity;
            this.unit_category = input.unit_category;
            this.unit_id = input.unit_id;
            this.unit_name = input.unit_name;
        }
    }
}
