
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import * as moment from 'moment';

export class GenSystemNews {

    id: number;
    createDate: moment.Moment;
    createdby: number;
    source: string;
    title: string;
    typeId: number;
    value: string;

    constructor();
    constructor( jsonObj: any );
    constructor( genSystemNews: GenSystemNews);
    constructor( id: number );

    constructor( input?: GenSystemNews|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            if ( input.createDate && typeof input.createDate === 'string' ) {
                this.createDate = moment(input.createDate).utc();
            } else {
                this.createDate = input.createDate;
            }
            this.createdby = input.createdby; // int [number]
            this.source = input.source; // string [string]
            this.title = input.title; // string [string]
            this.typeId = input.typeId; // int [number]
            this.value = input.value; // string [string]
       }
    }

    static jsonResponseToList(arr: any[]): GenSystemNews[] {
        if (arr && arr.map) {
          return arr.map(a => new GenSystemNews(a));
        } else {
          return [];
        }
    }
}

