import {AfterViewInit, Component, ElementRef, ViewChild, inject} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {Router} from '@angular/router';
import {HeadoutEventResultCustomerDetail} from 'frontend/src/app/integrations/headout/classes/headout-event-result-customer-detail';
import {IReportNavigationConfig} from 'frontend/src/app/interfaces/report-navigation-config.interface';
import {LayerService} from '../../../layer/services/layer.service';
import {EventSummaryFilters} from '../../../system-event/classes/event-summary-filters';
import {BaseWidget} from '../../classes/base-widget';
import {WidgetService} from '../../services/widget.service';
import {exportHeadoutAsCsv} from '../../../../integrations/headout/functions/export-headout-csv';
import {headoutTableColumnNames, HeadoutTableSort} from '../../../../integrations/headout/functions/table-utils';
import {HeadoutTableData} from '../../../../integrations/headout/interfaces/headout-table-data.interface';
import {HeadoutEventResult} from '../../../../integrations/headout/classes/headout-event-result';
import {LoadingMaskOptions} from '../../../../classes/loading-mask-options';
import {tap} from 'rxjs/operators';
import {HeadoutService} from '../../../../integrations/headout/headout.service';

@Component({
    selector: 'eaglei-headout-table-widget',
    templateUrl: './headout-table-widget.component.html',
    styleUrls: ['./headout-table-widget.component.scss'],
})
export class HeadoutTableWidgetComponent extends BaseWidget implements AfterViewInit {
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    private headoutService = inject(HeadoutService);

    public readonly columnNames: string[] = headoutTableColumnNames;
    public dataSource: MatTableDataSource<HeadoutTableData>;

    public selectedAdvisory: any;
    public advisories: HeadoutEventResult[] = [];

    public eventFilters: EventSummaryFilters = new EventSummaryFilters();

    public maskOptions: LoadingMaskOptions = new LoadingMaskOptions();

    constructor(
        private widgetElement: ElementRef,
        private widgetService: WidgetService,
        private layerService: LayerService,
        private router: Router
    ) {
        super(widgetElement);
    }

    public ngAfterViewInit(): void {
        this.getHeadoutData();
    }

    /**
     * On event filter change, if the selected event is a headout event, all advisories will be fetched and the most
     * recent is set to the active table data.
     * @param eventFilters New filter values emitted from the event dashboard.
     * @protected
     */
    protected handleEventFilterChange(eventFilters: EventSummaryFilters) {
        this.eventFilters = eventFilters;

        this.headoutService.getHeadoutEventDetails(this.eventFilters.event).subscribe((detail) => {
            if (detail.eventResults) {
                this.advisories = detail.eventResults;

                const mostRecent = this.advisories[this.advisories.length - 1];

                this.updateAdvisory(mostRecent);
                this.maskOptions.resetMask();
            } else {
                this.maskOptions.displayMask('No Advisories for ' + this.eventFilters.event.name);
                this.advisories = undefined;
            }
        });
    }

    /**
     * Abstract function that is called after ngOnDestroy
     */
    destroyWidget(): void {}

    /**
     * Fetches all the outage information for the selected Headout Event and Advisory and updates the table to show the
     * outage information
     * @private
     */
    private getHeadoutData(): void {
        if (!this.eventFilters.event || !this.selectedAdvisory?.id) {
            return;
        }

        this.maskOptions.displayMask();

        this.headoutService
            .getHeadoutCustomerInfo(this.eventFilters.event?.id, this.selectedAdvisory?.id)
            .pipe(
                tap((res) =>
                    res.length === 0 ? (this.maskOptions.text = 'No customer information available') : this.maskOptions.resetMask()
                )
            )
            .subscribe((res) => {
                this.initializeData(res.map((detail) => HeadoutEventResultCustomerDetail.ConvertToReportData(detail)));
            });
    }

    /**
     * Initialized the data source for the table, if the data source already exists, the data will be updated, otherwise
     * the source will be created and the paging, sorting, and sort logic will be applied.
     * @param data an array of @HeadoutTableData that will be displayed in the table.
     * @private
     */
    private initializeData(data: HeadoutTableData[]): void {
        if (this.dataSource) {
            this.dataSource.data = data;
        } else {
            this.dataSource = new MatTableDataSource<HeadoutTableData>(data);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sortingDataAccessor = HeadoutTableSort;
            this.dataSource.sort = this.sort;
        }
    }

    /**
     * Sets the currently selected advisory, then updates the table to show that advisory outage information.
     * @param advisory The advisory add get outage information for and add to the table.
     */
    public updateAdvisory(advisory: HeadoutEventResult): void {
        this.selectedAdvisory = advisory;
        this.getHeadoutData();
    }

    /**
     * Exports the current data in the table as a CSV file.
     */
    public exportTable() {
        exportHeadoutAsCsv(this.dataSource._orderData(this.dataSource.filteredData), 'HEADOUT_event', this.attributionUrl);
    }

    /**
     * Navigates to the headout report
     */
    public goToReport(): void {
        const filters: IReportNavigationConfig[] = [
            // {type: ReportFilter.PRIORITY, value: this.selectedStatus},
            // {type: ReportFilter.START_DATE, value: this.startDate.format()},
        ];
        // noinspection JSIgnoredPromiseFromCall
        this.router.navigate(['app/reports/headoutEvent'], {state: {filters}});
    }
}
