import {GenEOCOverrideTrainedUser} from '../../../../../generated/serverModels/GenEOCOverrideTrainedUser';

export class OverrideUser extends GenEOCOverrideTrainedUser {
    public readonly fullName: string;
    public readonly orderedName: string;

    constructor(props?: OverrideUser) {
        super(props);

        if (props) {
            this.fullName = `${this.firstName} ${this.lastName}`;
            this.orderedName = `${this.lastName}, ${this.firstName}`;
        }
    }
}
