<!-- Filters-->
<div class="filters filter-bar">
    <eaglei-date-range
        [newDesign]="true"
        [start]="startDate"
        [end]="endDate"
        [min]="minDate"
        (change)="changeDateRange($event)"></eaglei-date-range>

    <button mat-stroked-button matTooltip="Export" matTooltipPosition="above" matTooltipShowDelay="250" [matMenuTriggerFor]="menu">
        <mat-icon fontSet="fas" fontIcon="fa-file-export" inline></mat-icon>
    </button>
</div>

<div class="report-data">
    <ng-container [ngTemplateOutlet]="barChart"></ng-container>

    <ng-container [ngTemplateOutlet]="reportTable"></ng-container>
</div>

<!--Chart-->
<ng-template #barChart>
    <mat-card class="chart">
        <mat-card-content [class.invisible]="mask.show">
            <div class="chart-target">
                <div #chartTarget class="chart-target"></div>
            </div>
        </mat-card-content>

        <div class="auto-mask hide-mask" *ngIf="mask.show">
            <span class="mask-message">
                <span>{{ getMaskText() }}</span>
                <mat-progress-bar *ngIf="mask.progressBar" mode="indeterminate"></mat-progress-bar>
            </span>
        </div>
    </mat-card>
</ng-template>

<!--Table-->
<ng-template #reportTable>
    <mat-card>
        <mat-card-content [class.invisible]="dataSource && dataSource.filteredData.length === 0">
            <div class="table-container">
                <table
                    mat-table
                    [dataSource]="dataSource"
                    matSort
                    [matSortDisableClear]="true"
                    matSortActive="date"
                    matSortDirection="desc">
                    <ng-container matColumnDef="date">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Date
                            <eaglei-table-sort-header [columnName]="'date'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let coverage">{{ coverage.coverageDate | momentDate : null : false }}</td>
                    </ng-container>
                    <ng-container matColumnDef="coveredCustomers">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef>
                            Covered Customers
                            <eaglei-table-sort-header [columnName]="'coveredCustomers'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let coverage">
                            {{ coverage.coveredCustomers | eagleiNumber }}
                            <span class="county-customer"> {{ getCountyCustomerText(coverage) }}</span>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="totalCustomers">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef>
                            Total Customers
                            <eaglei-table-sort-header [columnName]="'totalCustomers'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let coverage">
                            {{ coverage.totalCustomers | eagleiNumber }}
                            <span class="county-customer"> {{ getCountyCustomerText(coverage) }}</span>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="coveragePercent">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef>
                            Coverage Percent
                            <eaglei-table-sort-header [columnName]="'coveragePercent'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let coverage">{{ coverage.percent | percent : '1.2-2' }}</td>
                    </ng-container>

                    <ng-container matColumnDef="dailyTrend">
                        <th mat-header-cell *matHeaderCellDef>Daily Trend</th>
                        <td mat-cell *matCellDef="let coverage" [style.color]="getTrendColor(coverage)">{{ coverage.trendDirection }}</td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="columns"></tr>
                    <tr mat-row *matRowDef="let row; columns: columns; let odd = odd"></tr>
                </table>
                <mat-paginator [pageSizeOptions]="[25, 50, 75, 100]" showFirstLastButtons></mat-paginator>
            </div>

            <div class="table-attribution">
                <a (click)="reportService.openAttribution(attributionUrl)" [innerHTML]="attributionTitle | sanitize"></a>
            </div>
        </mat-card-content>

        <div class="auto-mask hide-mask" *ngIf="dataSource && dataSource.filteredData.length === 0">
            <span class="mask-message">
                <span>No Data Matching Filters</span>
            </span>
        </div>
    </mat-card>
</ng-template>

<!--Export Menu-->
<mat-menu #menu="matMenu" overlapTrigger>
    <button (click)="exportTable()" mat-menu-item>Export Table As CSV</button>
    <button (click)="exportChartAsCsv()" mat-menu-item>Export Chart As CSV</button>
    <button (click)="exportChart()" mat-menu-item>Export Chart As PNG</button>
</mat-menu>
