<div mat-dialog-title class="header">
    <span class="title">Create {{ headerText }} Option</span>
    <button mat-icon-button matDialogClose>
        <mat-icon fontSet="fas" fontIcon="fa-times"></mat-icon>
    </button>
</div>

<mat-dialog-content>
    <mat-form-field>
        <textarea matInput matTextareaAutosize matAutosizeMaxRows="10" matAutosizeMinRows="5" [(ngModel)]="reason"> </textarea>
    </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-raised-button color="primary" (click)="submit()" [disabled]="!isFormValid()">
        {{ buttonText }}
    </button>
</mat-dialog-actions>
