
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import { GenUrbanNetDisruption } from './GenUrbanNetDisruption';

export class GenUrbanNetReport {

    id: number;
    advisory: string;
    boundary: string;
    disruptions: GenUrbanNetDisruption[];
    eventId: number;
    metadata: string;

    constructor();
    constructor( jsonObj: any );
    constructor( genUrbanNetReport: GenUrbanNetReport);
    constructor( id: number );

    constructor( input?: GenUrbanNetReport|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            this.advisory = input.advisory; // string [string]
            this.boundary = input.boundary; // string [string]
            if ( input.disruptions && input.disruptions.map ) { // list [GenUrbanNetDisruption]
                this.disruptions = input.disruptions.map( ( x: GenUrbanNetDisruption ) => {
                    return new GenUrbanNetDisruption( x );
                } );
            } else {
                this.disruptions = input.disruptions;
            }
            this.eventId = input.eventId; // int [number]
            this.metadata = input.metadata; // string [string]
       }
    }

    static jsonResponseToList(arr: any[]): GenUrbanNetReport[] {
        if (arr && arr.map) {
          return arr.map(a => new GenUrbanNetReport(a));
        } else {
          return [];
        }
    }
}

