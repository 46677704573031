<mat-card>
    <mat-card-subtitle>
        <div class="filter-option" (click)="changeTab('request')" [class.current-status]="selectedTab === 'request'">
            <img class="svg-icon menu-icon" src="/dist/images/icons/api-key.svg" alt="Requests" />
            <span
                [matBadge]="numberOfRequests.toString()"
                [matBadgeHidden]="numberOfRequests === 0"
                matBadgeOverlap="false"
                matBadgeColor="warn">
                API-KEY REQUESTS</span
            >
        </div>

        <div class="filter-option" (click)="changeTab('status')" [class.current-status]="selectedTab === 'status'">
            <mat-icon fontSet="fas" fontIcon="fa-file-contract" [inline]="true"></mat-icon>
            <span> API-KEY STATUS</span>
        </div>
    </mat-card-subtitle>
</mat-card>

<ng-container [ngSwitch]="selectedTab">
    <ng-container *ngSwitchCase="'request'">
        <eaglei-api-key-request-list (numberOfRequests)="numberOfRequests = $event"></eaglei-api-key-request-list>
    </ng-container>

    <ng-container *ngSwitchCase="'status'">
        <eaglei-api-key-info></eaglei-api-key-info>
    </ng-container>
</ng-container>
