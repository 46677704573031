import {AfterViewInit, Component, ElementRef, inject, Output, ViewChild} from '@angular/core';
import {BaseWidget} from '../../classes/base-widget';
import {MatTableDataSource} from '@angular/material/table';
import * as moment from 'moment';
import {State} from '../../../outage/classes/state';
import {OngNominationsSource} from '../../../layer/sources/ong-nominations-source';
import {MapOptions} from '../../../map/classes/map-options';
import {LeafletMapLayer} from '../../../layer/classes/leaflet-map-layer';
import {LayerService} from '../../../layer/services/layer.service';
import {WidgetService} from '../../services/widget.service';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {ApplicationConfig} from '../../../../classes/application-config';
import {filter, takeUntil} from 'rxjs/operators';
import {CustomLandingLayoutComponent} from '../../../../landing/components/custom-landing-layout/custom-landing-layout.component';
import {MapService} from '../../../map/services/map.service';
import {FileDownload} from '../../../../classes/file-download';
import {HttpInterceptorService} from '../../../../services/http-interceptor.service';
import {CustomPopover} from '../../../layer/enum/custom-popover';
import {Router} from '@angular/router';
import {Map as LeafletMap} from 'leaflet';
import {IReportNavigationConfig} from '../../../../interfaces/report-navigation-config.interface';
import {ReportFilter} from '../../../report/enums/report-filter.enum';
import {Nomination} from '../../../../classes/ong/nomination';
import {OngService} from '../../../../services/ong.service';
import {EventSummaryFilters} from '../../../system-event/classes/event-summary-filters';
import {nominationsWidget} from '../../../../../integrations/ong/classes/ong-attributions-text';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Component({
    selector: 'eaglei-ng-nomination-widget',
    templateUrl: './ng-nomination-widget.component.html',
    styleUrls: ['./ng-nomination-widget.component.scss'],
})
export class NgNominationWidgetComponent extends BaseWidget implements AfterViewInit {
    @ViewChild(MatSort, {static: false}) sort: MatSort;
    @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
    @Output() widgetName: string;

    // Filter Properties
    public startDate: moment.Moment = moment();
    public states: State[] = [];
    public pipelines: any[] = [];
    private renderTimeoutHandle: any;

    // Map Properties
    private readonly layerHandle = 'ongNominations';
    public mapReady = new BehaviorSubject<LeafletMap>(undefined);

    public mapOptions: MapOptions;
    private mapLayer: LeafletMapLayer;
    public layerSource: OngNominationsSource;
    // noinspection JSMismatchedCollectionQueryUpdate
    private allFeatures: Nomination[];

    // Table Properties
    public columnNames: string[] = ['name', 'id', 'scheduled_capacity', 'operational_capacity', 'role'];
    public dataSource: MatTableDataSource<Nomination>;

    public showMask: boolean = false;

    private layerService = inject(LayerService);
    protected ongService = inject(OngService);
    private router = inject(Router);

    constructor(private widgetElement: ElementRef) {
        super(widgetElement);
        this.attributionUrl = 'https://www.woodmac.com/';
        this.attributionText = 'Genscape: A Wood Mackenzie Business';
        this.setSubscriptions();

        this.mapOptions = new MapOptions().setZoom(3, 1).setCenter(37.2, -92);
        this.mapOptions.onlyManualZoom = true;
        this.mapOptions.show = {
            sidebar: false,
            refresh: false,
            export: false,
            coverage: false,
            coordinate: false,
            zoom: true,
            panControl: true,
        };

        const observers = {
            layer: this.layerService.getLayerByHandle(this.layerHandle),
            mapRef: this.mapReady,
        };

        combineLatest(observers)
            .pipe(takeUntilDestroyed())
            .subscribe((res: {layer: LeafletMapLayer; mapRef: LeafletMap}) => {
                this.mapLayer = res.layer;
                this.mapLayer.customPopoverId = CustomPopover.ONG_NOMINATIONS;
                this.layerSource = new OngNominationsSource(res.layer);
                this.layerSource.mapRef = res.mapRef;

                this.getNominationData();
            });

        this.getPreferences();
    }

    protected handleEventFilterChange(eventFilters: EventSummaryFilters): void {
        this.startDate = moment(eventFilters.date);
        this.states = [...eventFilters.locations];

        this.getNominationData();
    }

    ngAfterViewInit(): void {
        this.loading.emit(true);
        this.attributionModalText = nominationsWidget;
        this.widgetName = 'Nominations';
    }

    destroyWidget(): void {
        this.layerSource.layerInfo.endLoading();
    }

    private addNominationsToMap() {
        const ids = this.dataSource.filteredData.map((n) => n.location.location_id);
        const filtered = this.allFeatures.filter((n) => ids.includes(n.location.location_id));

        this.layerSource.removeFromMap();
        this.layerSource.processFeatures(filtered);
        this.layerSource.addToMap(true);
        this.layerSource.fitToFeatures();
    }

    public getNominationData(): void {
        this.mapLayer?.startLoading();
        HttpInterceptorService.clearInterceptor(this.widgetInterceptorKey);
        HttpInterceptorService.pendingRequests[this.widgetInterceptorKey] = this.ongService
            .getNominations(this.startDate)
            .subscribe((res: Nomination[]) => {
                HttpInterceptorService.deleteFromInterceptor(this.widgetInterceptorKey);

                this.allFeatures = res;
                this.initializeData(res);
                this.mapLayer.endLoading();
                this.loading.emit(false);
                this.mapReady.getValue().invalidateSize();
            });
    }

    // Map Methods

    // Table Methods
    private initializeData(data: Nomination[]): void {
        if (this.dataSource) {
            this.dataSource.data = data;
        } else {
            this.dataSource = new MatTableDataSource<any>(data);
            this.dataSource.sortingDataAccessor = this.dataAccessor;
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.dataSource.filterPredicate = this.filterPredicate.bind(this);
        }

        this.filterData(this.dataSource.filter);
    }

    // noinspection JSMethodCanBeStatic
    private dataAccessor(data: Nomination, columnName: string): string | number {
        switch (columnName) {
            case 'name':
                return data.location.name.toLowerCase().replace(/[()]/, '');
            case 'id':
                return data.location.location_id;
            case 'scheduled_capacity':
                return data.scheduled_capacity;
            case 'operational_capacity':
                return data.operational_capacity;
            case 'role':
                return data.location.role;
        }
    }

    // noinspection JSUnusedLocalSymbols
    private filterPredicate(data: Nomination, text: string): boolean {
        const stateCheck = this.states.find((s) => s.name === data.location.state) !== undefined;
        const nameCheck = data.location.name.toLowerCase().includes(text.toLowerCase().trim());
        return stateCheck && nameCheck;
    }

    /**
     * Navigates to the nomination report
     */
    public gotoReport(): void {
        const filters: IReportNavigationConfig[] = [
            {type: ReportFilter.STATE, value: this.states},
            {type: ReportFilter.TEXT_SEARCH, value: this.dataSource.filter},
            {type: ReportFilter.START_DATE, value: this.startDate.format()},
        ];

        // noinspection JSIgnoredPromiseFromCall
        this.router.navigate(['app/reports/nominations'], {state: {filters}});
    }

    /**
     * Opens the mapper with the Nominations layer selected
     */
    public openMapper(): void {
        this.layerService.navigateToMap([this.mapLayer]);
    }

    /**
     * Renders the Map after updating the map size
     */
    public renderMap(): void {
        MapService.mapRef.invalidateSize();
    }

    private setSubscriptions(): void {
        ApplicationConfig.resizeEvent.pipe(takeUntil(this.destroy$)).subscribe(() => this.renderMap());
        //
        WidgetService.redrawMap
            .pipe(
                filter(() => !!this.header && !!this.content),
                takeUntil(this.destroy$)
            )
            .subscribe(() => {
                this.resize();
                this.renderMap();
            });

        //
        WidgetService.renderWidget.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.renderMap();
        });

        //
        CustomLandingLayoutComponent.filterChange.pipe(takeUntil(this.destroy$)).subscribe(() => {});
    }

    public exportData(): void {
        let data =
            ['Pipeline Name', 'Pipeline ID', 'Gas Day', 'Scheduled Capacity (MMBTU)', 'Operational Capacity (MMBTU)', 'Role'].join() + '\n';

        this.dataSource._orderData(this.dataSource.filteredData).forEach((val) => {
            data +=
                [
                    FileDownload.formatCsvCell(val.location.name),
                    FileDownload.formatCsvCell(val.location.location_id),
                    FileDownload.formatCsvCell(val.gas_day.format()),
                    FileDownload.formatCsvCell(val.scheduled_capacity),
                    FileDownload.formatCsvCell(val.operational_capacity),
                    FileDownload.formatCsvCell(val.location.role),
                ].join() + '\n';
        });
        FileDownload.downloadCSV('ong_nominations', data, this.attributionUrl);
    }

    private getPreferences() {
        const preferences = ApplicationConfig.currentUserPreferences.getValue();
        this.states = preferences.getStates();
    }

    public updateStates(states: State[]): void {
        this.states = states;
        this.filterData(this.dataSource.filter);
    }

    public filterData(searchText: string): void {
        if (this.renderTimeoutHandle) {
            clearTimeout(this.renderTimeoutHandle);
            this.renderTimeoutHandle = undefined;
        }

        this.renderTimeoutHandle = setTimeout(() => {
            searchText = searchText === '' ? ' ' : searchText;
            this.dataSource.filter = searchText;
            this.addNominationsToMap();
            this.showMask = this.dataSource.filteredData.length <= 0;
        }, 250);
    }

    public updateDate(date: moment.Moment) {
        this.startDate = date;
        this.getNominationData();
    }

    public isFilterActive(): boolean {
        return this.dataSource?.filter && this.dataSource.filter.trim().length > 0;
    }

    public hasEllipsis(element: HTMLTableDataCellElement): boolean {
        return (window as any).elementHasEllipsis(element);
    }

    public onMobile(): boolean {
        return ApplicationConfig.onMobile();
    }
}
