import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LayerManagementComponent} from './components/layer-management/layer-management.component';
import {GenRoleDefinition} from '../../../../generated/serverModels/GenRoleDefinition';

export const layerRoutes: Routes = [
    {
        data: {
            requiredRoles: [GenRoleDefinition.ROLE_CONTENT_MANAGER],
            title: 'Layer Management',
            useNewDesign: true,
        },
        path: 'manage',
        component: LayerManagementComponent,
    },
];

@NgModule({
    imports: [RouterModule.forChild(layerRoutes)],
    exports: [RouterModule],
})
export class LayerRoutingModule {}
