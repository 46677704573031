<div class="hide-overflow">
    <div class="widget-container">
        <div #header class="widget-header large">
            <div class="column">
                <p class="title">
                    Storage
                    <eaglei-info-icon [attributionModalText]="attributionModalText" [widgetName]="widgetName"></eaglei-info-icon>
                </p>
                <eaglei-honor-preference-indicator></eaglei-honor-preference-indicator>
            </div>

            <div class="widget-filter">
                <mat-form-field class="filter-select active" appearance="outline">
                    <mat-label>Storage Type</mat-label>
                    <mat-select placeholder="Storage Type" [value]="selectedStorageType" (selectionChange)="updateStorage($event.value)">
                        <mat-option *ngFor="let type of storageTypes" [value]="type">{{ type }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <div class="center-export">
                    <button
                        mat-icon-button
                        matTooltip="Export"
                        matTooltipPosition="above"
                        matTooltipShowDelay="250"
                        [matMenuTriggerFor]="menu">
                        <mat-icon fontSet="fas" fontIcon="fa-file-export"></mat-icon>
                    </button>
                </div>
            </div>
        </div>

        <div class="table-container">
            <table
                mat-table
                [dataSource]="dataSource"
                matSort
                [matSortDisableClear]="true"
                matSortActive="capacity"
                matSortDirection="desc">
                <ng-container matColumnDef="region">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Region
                        <eaglei-table-sort-header [columnName]="'region'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let info">
                        {{ info.region }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="amount">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="small-col">
                        Amount (bbls)
                        <eaglei-table-sort-header [columnName]="'amount'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let info" class="small-col">
                        {{ info.amount | eagleiNumber }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="product">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Product
                        <eaglei-table-sort-header [columnName]="'product'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let info">
                        {{ info.product }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="capacity">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="small-col">
                        Capacity Utilization
                        <eaglei-table-sort-header [columnName]="'capacity'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let info" class="small-col">
                        {{ info.capacityUtilization / 100 | percent : '1.2-2' }}
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
                <tr mat-row *matRowDef="let row; columns: columnNames"></tr>
            </table>
            <mat-paginator [pageSize]="5" hidePageSize showFirstLastButtons></mat-paginator>
        </div>
    </div>

    <div #content class="chart-space">
        <div class="widget-header large shift-left">
            <p class="title">
                {{ getChartTitle() }}
                <br />
                <br />
            </p>

            <div>
                <mat-form-field class="filter-select active center" appearance="outline">
                    <mat-label *ngIf="!!selectedChart">Graph Type</mat-label>
                    <mat-select placeholder="Graph Type" [value]="selectedChart" (selectionChange)="updateGraphType($event.value)">
                        <mat-option [value]="'utilization'">Capacity Utilization</mat-option>
                        <mat-option [value]="'amount'">Amount</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div>
            <div #chartTarget class="chart" ngClass.lt-sm="mobile-outage-chart"></div>
        </div>
    </div>
</div>

<mat-menu #menu="matMenu" overlapTrigger>
    <button (click)="exportTable()" mat-menu-item>Export Table As CSV</button>
    <button (click)="exportChartAsCsv()" mat-menu-item>Export Chart As CSV</button>
    <button (click)="exportChart()" mat-menu-item>Export Chart As PNG</button>
</mat-menu>
