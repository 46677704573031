<div class="widget-container">
    <div #header class="widget-header large">
        <div class="column">
            <p class="title">
                Oil Barrel Daily Flows
                <eaglei-info-icon [attributionModalText]="attributionModalText" [widgetName]="widgetName"></eaglei-info-icon>
            </p>
            <eaglei-honor-preference-indicator></eaglei-honor-preference-indicator>
        </div>
        <div class="btn-container" *ngIf="!onMobile()">
            <a (click)="goToMap()">
                <button mat-stroked-button>Launch Mapper</button>
            </a>
            <a
                mat-icon-button
                class="ei-export"
                matTooltip="View Pipeline Alerts Report"
                matTooltipPosition="above"
                matTooltipShowDelay="250"
                [routerLink]="['../reports', 'pipelineAlerts']">
                <mat-icon fontSet="fas" fontIcon="fa-file-alt"></mat-icon>
            </a>
        </div>
    </div>

    <div #content class="widget-content map">
        <eaglei-leaflet-map (mapRef)="mapRef = $event" [mapOptions]="mapOptions"></eaglei-leaflet-map>
    </div>

    <div #footer class="widget-footer">
        <div class="footer">
            <div>
                <span class="attribution">Pipeline Capacity Utilization: </span>
            </div>
            <div class="flow">
                <div class="flow-low"></div>
                <span class="sml-text">High:<br />&gt; 90%</span>
            </div>
            <div class="flow">
                <div class="flow-med"></div>
                <span class="sml-text">Medium:<br />70% - 90%</span>
            </div>
            <div class="flow">
                <div class="flow-high"></div>
                <span class="sml-text">Low:<br />&lt; 70%</span>
            </div>
        </div>
    </div>
</div>
