import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {GenWidgetSettingType} from 'frontend/generated/serverModels/GenWidgetSettingType';
import {ApplicationConfig} from 'frontend/src/app/classes/application-config';
import {LandingFilters} from 'frontend/src/app/landing/classes/landing-filters';
import {CustomLandingLayoutComponent} from 'frontend/src/app/landing/components/custom-landing-layout/custom-landing-layout.component';
import {DataService} from 'frontend/src/app/services/data.service';
import {HttpInterceptorService} from 'frontend/src/app/services/http-interceptor.service';
import {LegendMetric} from 'frontend/src/shared/enums/legend-metric.enum';
import * as moment from 'moment';
import {filter, skip, takeUntil} from 'rxjs/operators';
import {LayerStyleService} from '../../../layer/services/layer-style.service';
import {Utility} from '../../../outage/classes/utility';
import {BaseWidget} from '../../classes/base-widget';
import {WidgetService} from '../../services/widget.service';
import {SummaryList} from '../../classes/summary-list';
import {EventSummaryFilters} from '../../../system-event/classes/event-summary-filters';
import {Subject} from 'rxjs';

@Component({
    selector: 'eaglei-summary-widget',
    templateUrl: './summary-widget.component.html',
    styleUrls: ['./summary-widget.component.scss'],
})
export class SummaryWidgetComponent extends BaseWidget implements AfterViewInit {
    // HTML Element Properties
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

    // Summary Options
    private type: 'state' | 'county' | 'utility' = 'state';
    public unknownSummaryType: boolean;

    // Summary List
    private summaryList: SummaryList[] = [];

    // Summary Items Configurations
    private limitOfItems: number = 0;
    private eventChange$ = new Subject<void>();

    private filters: LandingFilters;

    private alignment = 'space-around space-between';

    public isLoading = false;

    constructor(private widgetService: WidgetService, private widgetElement: ElementRef) {
        super(widgetElement, true);
    }

    protected handleEventFilterChange(eventFilters: EventSummaryFilters): void {
        if (!this.filters) {
            this.filters = new LandingFilters();
        }

        this.filters.locations = eventFilters.locations;
        this.filters.date = moment(eventFilters.date);

        // manually triggering the resize on event change because the number of affected locations changes per event
        WidgetService.resize.next(this.item);

        this.buildSummary();
    }

    public ngAfterViewInit(): void {
        WidgetService.updatedSettings
            .pipe(
                filter((widget) => !!widget),
                takeUntil(this.destroy$)
            )
            .subscribe((widget) => {
                if (this.item.x === widget.x && this.item.y === widget.y) {
                    this.type = widget.detail.settings.find((s) => s.type === GenWidgetSettingType.AGGREGATION_LEVEL).value;
                    this.unknownSummaryType = false;
                    this.buildSummary();
                }
            });

        if (!!this.item && !!this.item.detail.settings.find((s) => s.type === GenWidgetSettingType.AGGREGATION_LEVEL)) {
            this.type = this.item.detail.settings.find((s) => s.type === GenWidgetSettingType.AGGREGATION_LEVEL).value as any;
        }

        CustomLandingLayoutComponent.filterChange.pipe(takeUntil(this.destroy$)).subscribe((filters) => {
            this.filters = filters;
            this.updatePreferences();
            this.buildSummary();
        });

        WidgetService.resize
            .pipe(
                filter((widget) => this.item.x === widget.x && this.item.y === widget.y),
                takeUntil(this.destroy$)
            )
            .subscribe((item) => {
                this.resize();
                setTimeout(() => {
                    this.alignment =
                        item.cols - (ApplicationConfig.onPhone() ? 2 : 1) >= 2 ? 'space-around space-around' : 'space-around space-between';
                    // this.limitOfItems = Math.max((item.rows - (ApplicationConfig.onMobile() ? 2 : 1)), 1) * Math.max((item.cols - (ApplicationConfig.onMobile() ? 2 : 1)), 1);
                    this.limitOfItems = ApplicationConfig.onPhone() ? 2 : Math.max(item.rows - 1, 1) * Math.max(item.cols - 1, 1);
                    this.paginator.pageSize = ApplicationConfig.onPhone() ? 2 : this.limitOfItems;
                }, 0);
            });

        ApplicationConfig.currentUserPreferences.pipe(skip(1), takeUntil(this.destroy$)).subscribe(() => this.updatePreferences());

        WidgetService.renderWidget.pipe(takeUntil(this.destroy$)).subscribe(() => this.buildSummary());
    }

    destroyWidget(): void {}

    onApplicationTimeChange() {
        this.buildSummary();
    }

    private buildSummary(): void {
        this.summaryList = [];
        this.isLoading = true;
        if (this.type === 'state') {
            this.buildStateSummary();
        } else if (this.type === 'county') {
            this.buildCountySummary();
        } else if (this.type === 'utility') {
            this.buildUtilitySummary();
        } else {
            console.warn('Unknown Summary Type');
            this.unknownSummaryType = true;
            this.isLoading = false;
        }
    }

    // TODO: Remove this when we add better support for event dashboard
    private getDate(): moment.Moment {
        return this.eventDashboard ? moment(this.filters.date) : ApplicationConfig.roundMinute();
    }

    /**
     * Builds a State Summary List
     */
    private buildStateSummary(): void {
        HttpInterceptorService.clearInterceptor(this.widgetInterceptorKey);

        HttpInterceptorService.pendingRequests[this.widgetInterceptorKey] = this.widgetService
            .getWidgetOutageSummary('state', this.getDate())
            .subscribe((res) => {
                HttpInterceptorService.deleteFromInterceptor(this.widgetInterceptorKey);

                const stateData = res.filter((d) => this.filters.locations.map((s) => s.id).includes(d.stateId));

                stateData
                    .filter((s) => s.endCount > 0)
                    .forEach((s) => {
                        if (this.summaryList.find((sl) => sl.summaryId === s.stateId)) {
                            this.summaryList.find((sl) => sl.summaryId === s.stateId).addOutage(s);
                        } else {
                            this.summaryList.push(new SummaryList(s.stateId, [s as any]));
                        }
                    });

                this.summaryList.forEach((sl) => sl.finalizeList());

                this.summaryList.sort((a, b) => b.customersOut - a.customersOut);
                this.paginator.length = this.summaryList.length;

                this.isLoading = false;
            });
    }

    /**
     * Builds a County Summary List
     */
    private buildCountySummary(): void {
        HttpInterceptorService.clearInterceptor(this.widgetInterceptorKey);

        HttpInterceptorService.pendingRequests[this.widgetInterceptorKey] = this.widgetService
            .getWidgetOutageSummary('county', this.getDate())
            .subscribe((res) => {
                HttpInterceptorService.deleteFromInterceptor(this.widgetInterceptorKey);

                const countyData = res.filter((d) => this.filters.locations.map((s) => s.id).includes(d.stateId));

                countyData
                    .filter((c) => c.endCount > 0)
                    .forEach((c) => {
                        if (this.summaryList.find((sl) => sl.summaryId === c.countyId)) {
                            this.summaryList.find((sl) => sl.summaryId === c.countyId).addOutage(c);
                        } else {
                            this.summaryList.push(new SummaryList(c.countyId, [c]));
                        }
                    });

                this.summaryList.forEach((sl) => sl.finalizeList());

                this.summaryList.sort((a, b) => b.customersOut - a.customersOut);
                this.paginator.length = this.summaryList.length;

                this.isLoading = false;
            });
    }

    /**
     * Builds a Utility Summary List
     */
    private buildUtilitySummary(): void {
        HttpInterceptorService.clearInterceptor(this.widgetInterceptorKey);

        HttpInterceptorService.pendingRequests[this.widgetInterceptorKey] = this.widgetService
            .getWidgetOutageSummary('utility', this.getDate())
            .subscribe((res) => {
                HttpInterceptorService.deleteFromInterceptor(this.widgetInterceptorKey);

                const utilData = res.filter((d) => this.filters.locations.map((s) => s.id).includes(d.stateId) || !d.stateId);
                utilData
                    .filter((u) => u.endCount > 0)
                    .forEach((u) => {
                        const checkSummaryId = `${u.stateId}:${u.utilityId}${u.subUtilityId !== undefined ? ':' + u.subUtilityId : ''}`;
                        if (this.summaryList.find((sl) => sl.summaryId === checkSummaryId)) {
                            this.summaryList.find((sl) => sl.summaryId === checkSummaryId).addOutage(u);
                        } else {
                            this.summaryList.push(new SummaryList(checkSummaryId, [u]));
                        }
                    });

                this.summaryList.forEach((sl) => sl.finalizeList());

                this.summaryList = this.summaryList.sort((a, b) => b.customersOut - a.customersOut);
                this.paginator.length = this.summaryList.length;

                this.isLoading = false;
            });
    }

    /**
     * Checks the direction of the given list object trend
     * @param listObject The List object to check the trend of
     * @param direction The direction of the trend to check
     */
    public checkTrend(listObject: SummaryList, direction: 'up' | 'down'): boolean {
        if (direction === 'up') {
            return listObject.trendLine === 'trending_up';
        } else if (direction === 'down') {
            return listObject.trendLine === 'trending_down';
        }

        return false;
    }

    /**
     * Gets the correct outage color for the given list object
     * @param listObject The list object to check
     */
    public getOutageColor(listObject: SummaryList): string {
        const val = this.userPreferences.getLegendMetric() === LegendMetric.PERCENTAGE ? listObject.percentOut : listObject.customersOut;
        return LayerStyleService.colorByCount(val);
    }

    /**
     * Gets the Title of the given list object
     * @param listObject The list object to get the title of
     */
    public getSummaryTitle(listObject: SummaryList): string {
        switch (this.type) {
            case 'state':
                return DataService.states.value.find((s) => s.id === listObject.summaryId).name;
            case 'county':
                return `${(listObject.OutageData[0] as any).countyName} (${listObject.OutageData[0].stateName})`;
            case 'utility':
                return `${(listObject.OutageData[0] as Utility).utilityName} (${listObject.OutageData[0].stateName})`;
            default:
                return 'UNKNOWN SUMMARY OBJECT TYPE';
        }
    }

    /**
     * Gets the overall header for the group based off the type of summary
     */
    public getHeader(): string {
        switch (this.type) {
            // case 'fema':
            //   return 'FEMA Regions';
            case 'state':
                return 'States/Territories';
            case 'county':
                return 'Counties';
            case 'utility':
                return 'Electric Utilities';
        }
    }

    public getCountyCustomerText(summary: any): string {
        return !!summary.coveredCustomers ? `(${!summary.modelCount ? 'Collected' : 'Modeled'})` : '';
    }

    public getPaginatorValues(): any[] {
        if (!this.paginator) {
            console.warn('paginator not initialized');
        }

        const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
        const endIndex = Math.min(startIndex + this.paginator.pageSize, this.summaryList.length);

        return this.summaryList.slice(startIndex, endIndex);
    }

    private updatePreferences(): void {
        if (!this.filters) {
            this.filters = new LandingFilters();
        }

        if (!this.filters?.usePreferences) {
            this.filters.locations = DataService.states.value;
        } else {
            if (this.userPreferences) {
                this.filters.locations = this.userPreferences.getStates();
            } else {
                this.filters.locations = [];
            }
        }
    }
}

/**
 * An Object to hold and organize the data for the summary items
 */
