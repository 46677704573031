import {GenUploadedFile} from '../../../../../generated/serverModels/GenUploadedFile';

export class CommunityFile extends GenUploadedFile {
    public file: File;
    public editing: boolean;
    public tmpName: string;

    constructor(file?: CommunityFile) {
        super(file);
    }
}
