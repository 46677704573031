import {Pipe, PipeTransform} from '@angular/core';
import {format} from 'd3-format';

export interface EagleINumberPipeArgs {
    specifier?: string | ',.2f';
    postfix?: string;
    prefix?: string;
    notAvailable?: string;
    noZero?: boolean;
}

@Pipe({
    name: 'eagleiNumber',
})
export class EagleINumberPipe implements PipeTransform {
    transform(value: any, args?: EagleINumberPipeArgs): number | string {
        const specifier = args?.specifier || ',.0f';
        let result = value;

        if (result === 0 || (result && result !== -1)) {
            result = format(specifier)(result);
        }

        // For unknown reasons I occasionally get a "result?.split is not a function" error. This should help
        if (!result?.split) {
            return args?.notAvailable || 'Not Available';
        }

        const split = result?.split('.');
        const isZero = result === '0' || (split && /^0+\b/.test(split[0]) && /^0+\b/.test(split[1]));

        if (!result || (isZero && args?.noZero)) {
            return args?.notAvailable || 'Not Available';
        }

        return `${args?.prefix || ''}${result}${args?.postfix || ''}`;
    }
}
