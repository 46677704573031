<div *ngFor="let filter of filters" class="layer-filter">
    <ng-container [ngSwitch]="filter.type">
        <ng-container *ngSwitchCase="filterTypes.TEXT_SEARCH">
            <eaglei-text-search-filter
                [title]="filter.title"
                [filterText]="filter.defaultValue"
                (filterChange)="updateFilterQuery($event, filter)"></eaglei-text-search-filter>
        </ng-container>
        <ng-container *ngSwitchCase="filterTypes.NUMERIC_RANGE">
            <eaglei-numeric-range-filter
                [title]="filter.title"
                [params]="filter.getParams()"
                (filterChange)="updateFilterQuery($event, filter)"></eaglei-numeric-range-filter>
        </ng-container>
        <ng-container *ngSwitchCase="filterTypes.SINGLE_SELECT">
            <eaglei-select-filter
                [title]="filter.title"
                [isMultiAllowed]="false"
                [optionListJSON]="filter.values"
                [defaultOptionValue]="filter.defaultValue"
                (filterChange)="updateFilterQuery($event, filter)"></eaglei-select-filter>
        </ng-container>
        <ng-container *ngSwitchCase="filterTypes.MULTI_SELECT">
            <eaglei-select-filter
                [title]="filter.title"
                [isMultiAllowed]="true"
                [optionListJSON]="filter.values"
                [defaultOptionValue]="filter.defaultValue"
                (filterChange)="updateFilterQuery($event, filter)"></eaglei-select-filter>
        </ng-container>
    </ng-container>
</div>
