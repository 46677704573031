import {GenWidget} from '../../../../../generated/serverModels/GenWidget';
import {GenLayoutType} from '../../../../../generated/serverModels/GenLayoutType';
import {GenCommunityPermission} from '../../../../../generated/serverModels/GenCommunityPermission';
import {GenRoleDefinition} from '../../../../../generated/serverModels/GenRoleDefinition';

export class Widget extends GenWidget {
    public numberInLayout: number;
    public roles: GenRoleDefinition[];

    // Override for generated property

    constructor(widget?: Widget) {
        super(widget);

        if (widget) {
            this.numberInLayout = widget.numberInLayout || 0;
            this.types = widget.types.map((t) => new GenLayoutType(t));
        }
    }

    public setCommunityPermissions(permissions: GenCommunityPermission | GenCommunityPermission[]): Widget {
        this.communityPermissions = Array.isArray(permissions) ? permissions : [permissions];
        return this;
    }

    public setRoles(roles: GenRoleDefinition | GenRoleDefinition[]): Widget {
        this.roles = Array.isArray(roles) ? roles : [roles];
        return this;
    }
}
