<div class="pole-detection">
    <div #header class="widget-header large">
        <span class="title">
            <span>Daily Power Estimate</span>
            <eaglei-info-icon [attributionModalText]="attributionModalText" [widgetName]="widgetName"></eaglei-info-icon>
        </span>

        <div class="widget-filter">
            <eaglei-date-range
                [class.hide]="eventDashboard"
                [start]="selectedDate"
                [min]="ongService.minDate"
                [startPlaceholder]="'End Date'"
                [singlePicker]="true"
                (change)="updateDateRange($event)"></eaglei-date-range>

            <div class="export">
                <button
                    mat-icon-button
                    class="ei-export"
                    matTooltip="Export"
                    matTooltipPosition="above"
                    matTooltipShowDelay="250"
                    [matMenuTriggerFor]="menu">
                    <mat-icon fontSet="fas" fontIcon="fa-file-export"></mat-icon>
                </button>
            </div>
        </div>
    </div>

    <div #content class="widget-content">
        <div #chartTarget></div>

        <div class="chart-legend">
            <div *ngFor="let k of getLegendKeys()">
                <div class="legend">
                    <div class="color-block" [style.background-color]="barColors.get(k)"></div>
                    <span style="font-size: 12px">{{ k }}</span>
                </div>
            </div>
        </div>

        <div class="auto-mask hide-mask" *ngIf="showMask">
            <span class="mask-message">No Data Available</span>
        </div>
    </div>

    <div #footer class="widget-footer">
        <eaglei-honor-preference-indicator></eaglei-honor-preference-indicator>
    </div>
</div>
<mat-menu #menu="matMenu" overlapTrigger>
    <button (click)="exportChartAsCsv()" mat-menu-item>Export Chart As CSV</button>
    <button (click)="exportChart()" mat-menu-item>Export Chart As PNG</button>
</mat-menu>
