import {AfterViewInit, Component, OnDestroy, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import * as moment from 'moment';
import {LayerService} from '../../../layer/services/layer.service';
import {RefineryStatus} from '../../classes/refinery-status';
import {Report} from '../../classes/report';
import {ReportService} from '../../services/report.service';
import {Router} from '@angular/router';
import {OngService} from '../../../../services/ong.service';
import {OilPipelineAlert} from '../../classes/oil-pipeline-alert';
import {MomentDatePipe} from '../../../../../shared/pipes/moment-date.pipe';
import {FileDownload} from '../../../../classes/file-download';
import {CardFilters} from '../../../../../shared/classes/card-filters';
import {FormControl, FormGroup} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'eaglei-oil-pipeline-alerts-report',
    templateUrl: './oil-pipeline-alerts-report.component.html',
    styleUrls: ['../reports.scss', './oil-pipeline-alerts-report.component.scss'],
})
export class OilPipelineAlertsReportComponent extends Report<OilPipelineAlert> implements AfterViewInit {
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    public readonly columnNames: string[] = ['expand-icon', 'pipelineName', 'regionName', 'alertDate'];
    public regionOptions: string[] = [];

    public filterForm = new FormGroup({
        name: new FormControl<string>(''),
        regions: new FormControl<string[]>([]),
        startDate: new FormControl(moment().subtract(1, 'day')),
        endDate: new FormControl(moment()),
    });

    public selectedRow: OilPipelineAlert;
    private momentDatePipe = new MomentDatePipe();
    private loading = true;

    get noResultsText() {
        return this.loading ? 'Loading alerts...' : 'No alerts match your current filters.';
    }

    constructor(public reportService: ReportService, protected ongService: OngService) {
        super();
    }

    public ngAfterViewInit(): void {
        this.reportService
            .getReportData()
            .pipe(takeUntil(this.destroy$))
            .subscribe((r) => {
                this.initializeReportInfo(r);
            });

        this.getPipelineAlerts();

        this.filterForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(({name, regions}) => {
            this.dataSource.filter = 'manualUpdate';
        });
    }

    public getPipelineAlerts(): void {
        const {startDate, endDate} = this.filterForm.getRawValue();
        this.loading = true;

        this.ongService
            .getOilPipelineAlerts(startDate, endDate)
            .pipe(takeUntil(this.destroy$))
            .subscribe((res) => {
                this.loading = false;
                this.initData(res);
            });
    }

    private initData(data: OilPipelineAlert[]): void {
        if (this.dataSource) {
            this.dataSource.data = data;
        } else {
            this.dataSource = new MatTableDataSource<any>(data);
            this.dataSource.sortingDataAccessor = this.dataAccessor.bind(this);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.dataSource.data = data || [];
        }

        this.regionOptions = [
            ...new Set(
                data.map((result) => {
                    return result.regionName;
                })
            ),
        ];

        this.dataSource.filterPredicate = (filterData, filter) => {
            const {name, regions} = this.filterForm.getRawValue();
            const formattedName = name?.replace(' ', '_').replace(/\W/g, '').toLowerCase();
            const matchesName = new RegExp(formattedName).test(filterData.pipelineName?.replace(' ', '_').toLowerCase());
            const matchesRegion = regions?.includes(filterData.regionName);

            if (name && !regions.length) {
                return matchesName;
            } else if (!name && regions.length) {
                return matchesRegion;
            } else if (name && regions.length) {
                return matchesName && matchesRegion;
            }

            return true;
        };

        this.dataSource.filter = 'manualUpdate';
    }

    // noinspection JSMethodCanBeStatic
    private dataAccessor(data: RefineryStatus, column: string): string | number {
        switch (column) {
            case 'pipelineName':
            case 'regionName':
                return data[column].toLowerCase();

            default:
                return '';
        }
    }

    public updateDateRange(dates: CardFilters) {
        this.filterForm.patchValue({
            startDate: dates.startDate,
            endDate: dates.endDate,
        });

        this.dataSource.data = [];
        this.getPipelineAlerts();
    }

    public toggleExpandedRow(row: OilPipelineAlert): void {
        const isRowSelected = this.selectedRow?.id === row.id;
        this.selectedRow = isRowSelected ? undefined : row;
    }

    public exportTable(): void {
        let data = ['Pipeline', 'Region', 'Date', 'Description', '\n'].join();

        this.dataSource._orderData(this.dataSource.filteredData).forEach((val) => {
            const values = [
                FileDownload.formatCsvCell(val.pipelineName),
                FileDownload.formatCsvCell(val.regionName),
                FileDownload.formatCsvCell(this.momentDatePipe.transform(val.alertDate)),
                FileDownload.formatCsvCell(val.alertDescription),
            ];

            data += values.join();
            data += '\n';
        });

        FileDownload.downloadCSV('oil_pipeline_alert_report', data, this.attributionUrl);
    }
}
