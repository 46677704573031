import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MapLayoutLiteComponent} from './components/map-layout-lite/map-layout-lite.component';

export const liteRoutes: Routes = [
    {
        path: '',
        redirectTo: 'map',
        pathMatch: 'full',
    },
    {
        path: 'map',
        component: MapLayoutLiteComponent,
        data: {
            useNewDesign: true,
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(liteRoutes)],
    exports: [RouterModule],
})
export class LiteRoutingModule {}
