import {LeafletVectorSource} from '../classes/leaflet-vector-source';
import {LeafletMapLayer} from '../classes/leaflet-map-layer';
import * as L from 'leaflet';
import {Layer} from 'leaflet';
import {LeafletFeature} from '../classes/leaflet-feature';
import {MapService} from '../../map/services/map.service';
import {GasPrice} from '../classes/gas-price';
import {scaleQuantize} from 'd3-scale';
import {CurrencyPipe} from '@angular/common';
import {FeatureStyle} from '../interfaces/style.interface';
import {LeafletLayerLegend} from '../classes/leaflet-layer-legend';
import {LegendElement} from '../classes/legend-element';

export class GasPriceSource extends LeafletVectorSource<GasPrice> {
    get selectedFuelType(): string {
        return this._selectedFuelType;
    }

    set selectedFuelType(value: string) {
        this._selectedFuelType = value;
        const popoverRow = this.layerInfo.popoverData.find((row) => row.propertyName === 'currentValue');
        const formatted = value.charAt(0).toUpperCase() + value.slice(1);
        popoverRow.display = `Current ${formatted} Value`;
    }

    public readonly gasPriceUrl: string = 'api/gasprice/range';
    public legendRange = ['#004F92', '#01B5DA', '#FFFFFF', '#DD7A7A', '#CA3338'];
    public legendDomain: [string, string];
    public legendScale: any;

    private breakpoints = [];

    private currencyPipe = new CurrencyPipe('en-us');

    private _selectedFuelType: string;

    constructor(layerInfo: LeafletMapLayer) {
        super(layerInfo);
        this.selectedFuelType = 'unleaded';
    }

    public processFeatures(features: GasPrice[]): void {
        let max = Number.MIN_SAFE_INTEGER;
        let min = Number.MAX_SAFE_INTEGER;

        this.leafletFeatures = features.map((v) => {
            const feature = new LeafletFeature().convert(v);
            max = Math.max(max, v.unleadedCurrentAverage, v.midgradeCurrentAverage, v.premiumCurrentAverage, v.dieselCurrentAverage);
            min = Math.min(min, v.unleadedCurrentAverage, v.midgradeCurrentAverage, v.premiumCurrentAverage, v.dieselCurrentAverage);
            return feature;
        });

        this.legendDomain = [this.currencyPipe.transform(min), this.currencyPipe.transform(max)];

        this.legendScale = scaleQuantize()
            .domain([min, max])
            .range(this.legendRange as any);

        this.buildLegend();

        const layerPopover = (f: LeafletFeature, l) => {
            this.initializePopoverInteractions(f, l, 'click', true);
        };

        const featureStyle = (geoJsonPoint: LeafletFeature<GasPrice>): Layer => {
            return this.getFeatureStyle(geoJsonPoint.properties);
        };

        const config: any = {
            pane: MapService.layerPaneName,
            style: featureStyle,
            onEachFeature: layerPopover,
            features: this.leafletFeatures,
        };

        this.source = L.geoJSON(this.leafletFeatures as any, config);
    }

    public buildLegend(): LeafletLayerLegend[] {
        this.breakpoints = [];
        const legend = new LeafletLayerLegend(this.layerInfo.id, this.layerInfo.displayName);

        const domain = this.legendScale.domain();
        const diff = domain[1] - domain[0];
        const step = diff / this.legendRange.length;

        for (let i = 0; i < this.legendRange.length; i++) {
            const element = new LegendElement();
            let v1: number = domain[0] + i * step;
            const v2: number = v1 + step;

            if (i !== 0) {
                v1 += 0.01;
            }

            element.label = `${this.currencyPipe.transform(v1)} - ${this.currencyPipe.transform(v2)}`;
            element.shape = 'rect';
            element.fillColor = this.legendRange[i];
            element.width = 20;
            element.height = 10;
            legend.elements.push(element);

            this.breakpoints.push({
                min: parseFloat(v1.toFixed(2)),
                max: parseFloat(v2.toFixed(2)),
                color: this.legendRange[i],
            });
        }

        return [legend];
    }

    public updateStyle(type: string): void {
        this.selectedFuelType = type;
        const styleFunction = (geoJsonPoint) => {
            const point: GasPrice = geoJsonPoint.properties;
            return this.getFeatureStyle(point);
        };

        this.source.setStyle(styleFunction as any);
        this.changeOpacity(this.layerInfo.opacity);

        this.source.getLayers().forEach((l) => l.closePopup());
    }

    private getFeatureStyle(feature: GasPrice): any {
        let prop;

        switch (this.selectedFuelType) {
            case 'unleaded':
                prop = feature.unleadedCurrentAverage;
                break;
            case 'midgrade':
                prop = feature.midgradeCurrentAverage;
                break;
            case 'premium':
                prop = feature.premiumCurrentAverage;
                break;
            case 'diesel':
                prop = feature.dieselCurrentAverage;
                break;
        }

        const checkValue = (value: number): string => {
            let breakPoint;
            if (value < this.breakpoints[0].min) {
                breakPoint = this.breakpoints[0];
            } else if (value > this.breakpoints[this.breakpoints.length - 1]) {
                breakPoint = this.breakpoints[this.breakpoints.length - 1];
            } else {
                breakPoint = this.breakpoints.find((bp) => {
                    return value >= bp.min && value <= bp.max;
                });
            }

            return breakPoint ? breakPoint.color : '#000000';
        };

        feature.currentValue = prop;

        return {
            color: '#000000',
            weight: 2,
            fillColor: checkValue(parseFloat(feature.currentValue.toFixed(2))),
            fillOpacity: 1,
        } as FeatureStyle;
    }
}
