import {GenCommunityUser} from '../../../../../generated/serverModels/GenCommunityUser';
import {GenCommunityUserStatus} from '../../../../../generated/serverModels/GenCommunityUserStatus';
import {User} from '../../user/classes/user';

export class CommunityUser extends GenCommunityUser {
    static fromUser(user: User): CommunityUser {
        const ret = new CommunityUser();
        ret.firstName = user.firstName;
        ret.userId = user.id;
        ret.lastName = user.lastName;
        ret.email = user.email;
        ret.username = user.username;
        return ret;
    }

    public name: string;
    public initials: string;
    public justificationVisible: boolean;

    constructor(user?: CommunityUser) {
        super(user);

        if (user) {
            this.name = `${user.firstName} ${user.lastName}`;
            this.initials = `${user.firstName.charAt(0).toUpperCase()}${user.lastName.charAt(0).toUpperCase()}`;
        }
    }

    public isApproved(): boolean {
        return this.status === GenCommunityUserStatus.APPROVED;
    }

    public isDenied(): boolean {
        return this.status === GenCommunityUserStatus.DENIED;
    }

    public isPending(): boolean {
        return this.status === GenCommunityUserStatus.PENDING;
    }
}
