<div class="hide-overflow">
    <div class="widget-container">
        <div #header class="widget-header large">
            <p class="title">
                <span>Gas Burn</span>
                <eaglei-info-icon [attributionModalText]="attributionModalText" [widgetName]="widgetName"></eaglei-info-icon>
            </p>

            <div class="widget-filters">
                <eaglei-location-filter
                    [class.hide]="eventDashboard"
                    [newDesign]="true"
                    [defaultSelection]="states"
                    [selectAllOption]="true"
                    (locations)="updateState($event)"
                    [hideFemaRegions]="true">
                </eaglei-location-filter>

                <button *ngIf="!onMobile()" mat-stroked-button class="regular button-adjust" (click)="getMapperLink()">
                    Launch Mapper
                </button>

                <div class="btn-container">
                    <button
                        mat-icon-button
                        class="ei-export"
                        matTooltip="Export"
                        matTooltipPosition="above"
                        matTooltipShowDelay="250"
                        [matMenuTriggerFor]="menu">
                        <mat-icon fontSet="fas" fontIcon="fa-file-export"></mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div #content class="table-content">
        <div class="table-flex">
            <div class="table-container">
                <table mat-table [dataSource]="dataSource" matSort [matSortDisableClear]="true">
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="ei-md-column">
                            Name
                            <eaglei-table-sort-header [columnName]="'name'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td
                            #locationName
                            mat-cell
                            *matCellDef="let info"
                            class="ellipsis ei-md-column"
                            [matTooltip]="info.location_name"
                            [matTooltipDisabled]="!hasEllipsis(locationName)"
                            matTooltipShowDelay="250"
                            matTooltipPosition="above">
                            {{ info.location_name }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="flow">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Hourly Flow (Mcf)
                            <eaglei-table-sort-header [columnName]="'flow'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let info">{{ info.gas_burns[0].hourly_flow | eagleiNumber }}</td>
                    </ng-container>

                    <ng-container matColumnDef="county">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            County
                            <eaglei-table-sort-header [columnName]="'county'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let info">
                            {{ info.county }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="state">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="small-col">
                            State
                            <eaglei-table-sort-header [columnName]="'state'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let info" class="small-col">
                            {{ info.state }}
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
                    <tr
                        mat-row
                        *matRowDef="let row; columns: columnNames"
                        class="clickable hoverable"
                        [class.selected]="row === selectedRow"
                        (click)="updateSelectedRow(row)"></tr>
                </table>

                <mat-paginator [pageSize]="6" hidePageSize showFirstLastButtons></mat-paginator>
            </div>
        </div>

        <div class="chart-container">
            <div class="chart">
                <p class="chart-title">{{ selectedRow?.location_name }} Hourly Flow</p>
                <div class="wide">
                    <div #chartTarget class="tall wide"></div>
                </div>
            </div>
        </div>

        <div class="auto-mask hide-mask" *ngIf="showMask">
            <span class="mask-message">No Data Matches Filters</span>
        </div>
    </div>
</div>

<mat-menu #menu="matMenu" overlapTrigger>
    <button (click)="exportTable()" mat-menu-item>Export Table As CSV</button>
    <button (click)="exportChart()" mat-menu-item>Export Chart As PNG</button>
</mat-menu>
