<div class="community-widget">
    <div #header class="widget-header">
        <p class="title">My Communities</p>
    </div>

    <div #content class="widget-content">
        <ng-container *ngIf="communities.length !== 0; else noCommunities">
            <mat-card *ngFor="let community of communities" class="clickable community" (click)="gotoCommunity(community)">
                <p class="ellipsis">{{ community.name }}</p>
            </mat-card>
        </ng-container>

        <ng-template #noCommunities>
            <div class="no-community">
                <p>
                    You are not currently a member of any communities.
                    <br />
                    <br />
                    To request to be a member of a community, go to the <a routerLink="/app/communities">community page</a>.
                </p>
            </div>
        </ng-template>
    </div>

    <div #footer class="widget-footer">
        <div *ngIf="communities.length !== 0">
            <button mat-stroked-button (click)="gotoCommunity()" style="float: right">All Communities</button>
        </div>
    </div>
</div>
