export class EventDashboardFilter {
    static readonly ANALYTIC: EventDashboardFilter = new EventDashboardFilter('ANALYTIC');
    static readonly TIME_SENSITIVE: EventDashboardFilter = new EventDashboardFilter('TIME_SENSITIVE');
    static readonly STATIC: EventDashboardFilter = new EventDashboardFilter('STATIC');

    static forName(name: string) {
        if (name === 'ANALYTIC') return EventDashboardFilter.ANALYTIC;
        if (name === 'TIME_SENSITIVE') return EventDashboardFilter.TIME_SENSITIVE;
        if (name === 'STATIC') return EventDashboardFilter.STATIC;
        throw new Error('Unexpected value for EventDashboardFilter:' + name);
    }

    static values(): EventDashboardFilter[] {
        return [EventDashboardFilter.ANALYTIC, EventDashboardFilter.TIME_SENSITIVE, EventDashboardFilter.STATIC];
    }

    public readonly name: string;

    private constructor(name: string) {
        this.name = name;
    }

    toJSON(): string {
        return this.name;
    }

    toString(): string {
        return this.name;
    }
}
