import {AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Layout} from '../../../modules/widget/classes/layout';
import {ModalConfig} from '../../../classes/modal-config';
import {ApplicationConfig} from '../../../classes/application-config';
import {throttleTime} from 'rxjs/operators';
import {WidgetService} from '../../../modules/widget/services/widget.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'eaglei-layout-management-modal',
    templateUrl: './layout-management-modal.component.html',
    styleUrls: ['./layout-management-modal.component.scss'],
})
export class LayoutManagementModalComponent implements OnInit, AfterViewInit {
    // HTML Properties
    @ViewChild('content', {static: true}) content: ElementRef;

    // Layout Properties
    public layouts: Layout[] = [];
    public tmpName: string;

    constructor(
        private dialogRef: MatDialogRef<LayoutManagementModalComponent>,
        private widgetService: WidgetService,
        @Inject(MAT_DIALOG_DATA) layouts: Layout[],
        private popup: MatSnackBar
    ) {
        this.layouts = layouts.slice();
    }

    ngOnInit() {}

    ngAfterViewInit() {
        ModalConfig.setModalHeight(this.content);
        ApplicationConfig.resizeEvent.pipe(throttleTime(250)).subscribe(() => ModalConfig.setModalHeight(this.content));
    }

    /**
     * Removes a layout from the UI but does not delete the layout.
     * @param layout The layout to be removed.
     */
    public queueToRemove(layout: Layout): void {
        this.widgetService.bulkDelete([layout]).subscribe(() => {
            const index = this.layouts.findIndex((l) => l.id === layout.id);
            if (index !== -1) {
                this.layouts.splice(index, 1);
                this.popup.open(`Deleted ${layout.name}`, 'Okay', {duration: 5000, panelClass: 'dialog-success'});
            }
        });
    }

    // Renaming Methods
    /**
     * Triggers the edit title box for a layout.
     * @param layout the {@link Layout} that will be edited
     */
    public rename(layout: Layout): void {
        this.layouts.forEach((l) => (l.editing = layout.id === l.id));

        this.tmpName = layout.name;
    }

    public saveLayoutName(layout: Layout): void {
        const oldName = layout.name.slice();
        layout.name = this.tmpName;
        this.widgetService.updateLayout(layout).subscribe(() => {
            layout.editing = false;
            this.popup.open(`${oldName} was renamed to ${layout.name}`, 'Okay', {duration: 5000, panelClass: 'dialog-success'});
        });
    }

    public cancelRename(layout: Layout): void {
        layout.editing = false;
        this.tmpName = '';
    }

    // Default Layout Methods
    public clearDefaults() {
        this.widgetService.clearDefaultLayout().subscribe(() => {
            this.layouts.forEach((l) => (l.defaultLayout = false));
            this.popup.open(`Default layout was reset to System Default`, 'Okay', {duration: 5000, panelClass: 'dialog-success'});
        });
    }

    public setAsDefault(layout: Layout): void {
        this.widgetService.setLayoutAsDefault(layout).subscribe(() => {
            this.layouts.forEach((l) => (l.defaultLayout = layout.id === l.id));
            this.popup.open(`${layout.name} was set as default layout`, 'Okay', {duration: 5000, panelClass: 'dialog-success'});
        });
    }

    // Utility Methods
    public closeModal(): void {
        this.layouts.forEach((l) => (l.editing = false));
        this.dialogRef.close(this.layouts);
    }
}
