import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {EmailData} from '../classes/email-data';
import {CsvDownloadInfo} from '../modules/outage/classes/csv-download-info';
import {GenTrainingAccessRequest} from '../../../generated/serverModels/GenTrainingAccessRequest';

@Injectable({
    providedIn: 'root',
})
export class EmailService {
    private readonly emailUrl: string = '/api/email/allusers';

    constructor(private http: HttpClient) {}

    public sendEmail(subject: string, messageBody: string): Observable<any> {
        const emailData = new EmailData();
        emailData.subject = subject;
        emailData.body = messageBody;
        return this.http.post(this.emailUrl, emailData);
    }

    public requestArchiveData(requestData: CsvDownloadInfo): Observable<any> {
        const url = 'api/archive/request';
        return this.http.post(url, requestData);
    }

    public requestTrainingAccess(requestAccess: GenTrainingAccessRequest): Observable<any> {
        const url = 'api/training/request';
        return this.http.post(url, requestAccess);
    }
}
