export enum ReportFilter {
    STATE,
    AGGREGATION,
    START_DATE,
    END_DATE,
    TEXT_SEARCH,
    PRIORITY,
    CAPACITY,
    EVENT_ID,
}
