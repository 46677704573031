<mat-card>
    <mat-card-title class="header-bar">
        <span>{{ (data$ | async).facilityName }}</span>
        <button mat-icon-button (click)="close()">
            <mat-icon fontSet="fas" fontIcon="fa-times" (click)="close()"></mat-icon>
        </button>
    </mat-card-title>
    <mat-divider></mat-divider>
    <mat-card-content #content>
        <mat-tab-group>
            <mat-tab label="Site Information">
                <ng-template matTabContent>
                    <div class="metadata-container">
                        <div>
                            <span class="metadata-title">Date of Survey:</span>
                            <span class="metadata-value">{{ (data$ | async).assessmentDateTime | momentDate }}</span>
                        </div>
                        <div>
                            <span class="metadata-title">Incident Name: </span>
                            <span class="metadata-value">{{ (data$ | async).incidentName || 'N/A' }}</span>
                        </div>
                        <div>
                            <span class="metadata-title">Damaged Facility:</span>
                            <span class="metadata-value">{{ (data$ | async).facilityName || 'N/A' }} </span>
                        </div>
                        <div>
                            <span class="metadata-title">Description:</span>
                            <span class="metadata-value">{{ (data$ | async).narrative || 'N/A' }}</span>
                        </div>
                    </div>
                </ng-template>
            </mat-tab>
            <mat-tab label="Photos">
                <ng-template matTabContent>
                    <ng-container *ngIf="(data$ | async) && imageIds().length !== 0">
                        <div class="photo-container">
                            <button *ngIf="showArrows()" mat-icon-button (click)="previousPhoto()" [class.disabled]="currentIndex === 0">
                                <mat-icon fontSet="fas" fontIcon="fa-angle-left"></mat-icon>
                            </button>

                            <div>
                                <img
                                    [class.invisible]="loading()"
                                    (load)="loading.set(false)"
                                    src="api/survey123/{{ (data$ | async).objectid }}/{{ imageIds()[currentIndex] }}/jpeg?year={{
                                        (data$ | async).dataYear
                                    }}"
                                    alt="" />
                                <div *ngIf="loading()" class="dot-flashing"></div>
                            </div>

                            <button
                                *ngIf="showArrows()"
                                mat-icon-button
                                (click)="nextPhoto()"
                                [class.disabled]="currentIndex === imageIds().length - 1">
                                <mat-icon fontSet="fas" fontIcon="fa-angle-right"></mat-icon>
                            </button>
                        </div>
                    </ng-container>
                </ng-template>
            </mat-tab>
        </mat-tab-group>
    </mat-card-content>
</mat-card>
