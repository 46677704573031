<form class="filters filter-bar" [formGroup]="filterForm">
    <eaglei-date-range [min]="ongService.minDate" (change)="updateDateRange($event)"> </eaglei-date-range>

    <mat-form-field floatLabel="always" class="filter-select active">
        <mat-label>Pipeline Name</mat-label>
        <input matInput formControlName="name" />
    </mat-form-field>

    <mat-form-field floatLabel="always" class="filter-select active">
        <mat-label>Region</mat-label>
        <mat-select formControlName="regions" multiple>
            <mat-option *ngFor="let option of regionOptions" [value]="option">{{ option }}</mat-option>
        </mat-select>
    </mat-form-field>

    <button mat-stroked-button [matMenuTriggerFor]="menu" matTooltip="Export" matTooltipPosition="above" matTooltipShowDelay="250">
        <mat-icon inline fontSet="fas" fontIcon="fa-file-export"></mat-icon>
    </button>
</form>

<div>
    <div class="table-section">
        <ng-container [ngTemplateOutlet]="reportTable"></ng-container>
    </div>
</div>

<ng-template #reportTable>
    <mat-card>
        <mat-card-content>
            <div class="table-container" [class.invisible]="dataSource?.filteredData?.length === 0">
                <table mat-table [dataSource]="dataSource" matSort [matSortDisableClear]="true" multiTemplateDataRows>
                    <ng-container matColumnDef="expand-icon">
                        <th mat-header-cell *matHeaderCellDef class="ei-icon-column"></th>
                        <td mat-cell *matCellDef="let info" class="ei-icon-column">
                            <mat-icon
                                inline
                                fontSet="fas"
                                [fontIcon]="info === selectedRow ? 'fa-chevron-down' : 'fa-chevron-right'"></mat-icon>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="pipelineName">
                        <th mat-header-cell *matHeaderCellDef class="ei-sm-column">Pipeline</th>
                        <td mat-cell *matCellDef="let info" class="ei-sm-column">
                            {{ info.pipelineName }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="regionName">
                        <th mat-header-cell *matHeaderCellDef class="ei-sm-column">Region</th>
                        <td mat-cell *matCellDef="let info" class="ei-sm-column">
                            {{ info.regionName }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="alertDate">
                        <th mat-header-cell *matHeaderCellDef class="ei-sm-column">Date</th>
                        <td mat-cell *matCellDef="let info" class="ei-sm-column">
                            {{ info.alertDate | momentDate }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="expandedDetail">
                        <td mat-cell *matCellDef="let info" [attr.colspan]="10">
                            <div class="hide-expanded-row" [class.show-expanded-row]="selectedRow === info">
                                <b>Description:</b> {{ info.alertDescription }}
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
                    <tr
                        mat-row
                        *matRowDef="let row; columns: columnNames; let odd = odd"
                        class="clickable"
                        (click)="toggleExpandedRow(row)"></tr>
                    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="expandable-row"></tr>
                </table>

                <mat-paginator [pageSizeOptions]="[25, 50, 75, 100]" showFirstLastButtons></mat-paginator>
            </div>

            <div class="table-attribution" [class.invisible]="dataSource?.filteredData?.length === 0">
                <a (click)="reportService.openAttribution(attributionUrl)" [innerHTML]="attributionTitle | sanitize"></a>
            </div>

            <div class="auto-mask" *ngIf="dataSource?.filteredData?.length === 0">
                <p class="mask-message">{{ noResultsText }}</p>
            </div>
        </mat-card-content>
    </mat-card>
</ng-template>

<!-- Export Menu -->
<mat-menu #menu="matMenu" overlapTrigger>
    <button (click)="exportTable()" mat-menu-item [disabled]="dataSource?.filteredData?.length === 0">Export Table As CSV</button>
</mat-menu>
