<mat-card class="refinery-alert-widget">
    <mat-card-header #header class="widget-header large">
        <div class="title">
            <p>Oil Refinery Alerts</p>
            <eaglei-info-icon [attributionModalText]="attributionModalText" [widgetName]="widgetName"></eaglei-info-icon>
        </div>

        <div class="filters">
            <eaglei-location-filter *ngIf="!eventDashboard" [defaultSelection]="selectedStates" (locations)="updateState($event)">
            </eaglei-location-filter>

            <eaglei-date-range
                *ngIf="!eventDashboard"
                [start]="filters.date"
                [startPlaceholder]="'Date'"
                [singlePicker]="true"
                [min]="ongService.minDate"
                (change)="updateDateRange($event)">
            </eaglei-date-range>

            <button
                mat-icon-button
                class="ei-export"
                matTooltip="Export"
                matTooltipPosition="above"
                matTooltipShowDelay="250"
                [matMenuTriggerFor]="menu">
                <mat-icon fontSet="fas" fontIcon="fa-file-export"></mat-icon>
            </button>
        </div>
    </mat-card-header>

    <div #content class="widget-content">
        <div class="table-container">
            <table
                mat-table
                [dataSource]="alerts"
                multiTemplateDataRows
                matSort
                [matSortDisableClear]="true"
                matSortActive="date"
                matSortDirection="desc">
                <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Description
                        <eaglei-table-sort-header [columnName]="'description'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let info" class="description">
                        <p class="ellipsis">{{ info.description }}</p>
                    </td>
                </ng-container>

                <ng-container matColumnDef="facility">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Facility
                        <eaglei-table-sort-header [columnName]="'facility'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let info"
                        class="facility"
                        [matTooltip]="info.facility_name"
                        matTooltipPosition="right"
                        matTooltipShowDelay="250">
                        <p class="ellipsis">{{ info.facility_name }}</p>
                    </td>
                </ng-container>

                <ng-container matColumnDef="productClass">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Product Class
                        <eaglei-table-sort-header [columnName]="'productClass'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let info"
                        class="productClass"
                        [matTooltip]="info.product_class"
                        matTooltipPosition="right"
                        matTooltipShowDelay="250">
                        <p class="ellipsis">{{ info.product_class }}</p>
                    </td>
                </ng-container>

                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Date
                        <eaglei-table-sort-header [columnName]="'date'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let info"
                        class="date"
                        [matTooltip]="info.distributed_datetime"
                        matTooltipPosition="right"
                        matTooltipShowDelay="250">
                        <p class="ellipsis">{{ info.distributed_datetime | momentDate }}</p>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columns"></tr>
                <tr
                    mat-row
                    *matRowDef="let row; columns: columns; let odd = odd"
                    class="clickable hoverable"
                    (click)="openMaintenanceModal(row)"></tr>
            </table>
        </div>

        <div class="auto-mask hide-mask" *ngIf="alerts && alerts.filteredData.length === 0">
            <p class="mask-message">No Alerts matching your filters.</p>
        </div>
    </div>

    <mat-card-footer #footer class="widget-footer">
        <mat-paginator [pageSize]="5" hidePageSize showFirstLastButtons></mat-paginator>
    </mat-card-footer>

    <mat-menu #menu="matMenu" overlapTrigger>
        <button (click)="exportTable()" mat-menu-item>Export Table As CSV</button>
    </mat-menu>
</mat-card>
