import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {TimeStep} from '../../classes/time-step';
import * as moment from 'moment';
import {ApplicationConfig} from '../../../app/classes/application-config';
import {AbstractControl, AsyncValidatorFn, FormControl} from '@angular/forms';
import {of, Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {MatDatepicker} from '@angular/material/datepicker';
import {coerceBooleanProperty} from '@angular/cdk/coercion';

@Component({
    selector: 'eaglei-date-time-picker',
    templateUrl: './date-time-picker.component.html',
    styleUrls: ['./date-time-picker.component.scss'],
})
export class DateTimePickerComponent implements OnInit, OnChanges {
    @ViewChild('startDatepicker') datepicker: MatDatepicker<any>;

    @Input() start: moment.Moment = ApplicationConfig.roundMinute().subtract(1, 'days').startOf('day');
    @Input() min: moment.Moment;
    @Input() max: moment.Moment = ApplicationConfig.roundMinute();
    @Input({transform: coerceBooleanProperty}) allowFutureEndDate: boolean = false;
    @Input({transform: coerceBooleanProperty}) showTime: boolean = true;

    @Input() startPlaceholder = 'Start Date';

    @Output() dateChange: EventEmitter<moment.Moment> = new EventEmitter<moment.Moment>();
    @Output() invalidDate: EventEmitter<any> = new EventEmitter<any>();

    private debouncer$ = new Subject<void>();
    private lastEmittedValue: moment.Moment;

    // Dates used for the datepicker
    public minDate?: Date;
    public maxDate?: Date;

    // Form controls for input controls
    public dateControl: FormControl<Date>;
    public timeControl: FormControl<TimeStep>;

    public timeSteps = TimeStep.createTimeSteps();

    constructor() {
        this.debouncer$.pipe(debounceTime(500)).subscribe(() => {
            this.emitDate();
        });
    }

    ngOnInit(): void {
        this.minDate = this.min?.toDate();
        this.maxDate = this.allowFutureEndDate ? undefined : this.max?.toDate();

        this.initializeControls();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.hasOwnProperty('min')) {
            this.minDate = changes?.min?.currentValue?.toDate();
        }

        if (changes.hasOwnProperty('max')) {
            this.maxDate = changes?.max?.currentValue?.toDate();
        }

        if (changes.hasOwnProperty('start') && !changes.start.isFirstChange()) {
            const [updateDate, updateStep] = this.getPickerValuesFromDate(changes.start.currentValue);

            this.dateControl.setValue(updateDate);
            this.timeControl.setValue(updateStep);
        }

        if (this.dateControl?.invalid) {
            this.dateControl.updateValueAndValidity();
        }
    }

    /**
     * Converts a moment into the broken down format used by the date picker,
     * converts the Day month year to a date and the time to a 15 minute timeStep
     * @param date The date to be used bt the picker
     * @private
     */
    private getPickerValuesFromDate(date: moment.Moment): [Date, TimeStep] {
        const pickerDate = date.toDate();
        const pickerStep = this.timeSteps.find((step) => step.matchesTime(date));
        return [pickerDate, pickerStep];
    }

    private initializeControls() {
        const [startDate, startTime] = this.getPickerValuesFromDate(this.start.local());

        this.dateControl = new FormControl<any>(startDate, {
            asyncValidators: [this.isValidMaxDate(), this.isValidMinDate()],
        });

        this.timeControl = new FormControl<any>(startTime);

        this.lastEmittedValue = this.getMomentFromDateSegments(startDate, startTime);

        this.dateControl.valueChanges.subscribe(() => {
            this.debouncer$.next();
        });

        this.timeControl.valueChanges.subscribe(() => {
            this.debouncer$.next();
            this.dateControl.updateValueAndValidity();
        });
    }

    /**
     * Output emitter that fires when either the date or time is changed on the
     * picker.
     * @private
     */
    private emitDate(): void {
        if (this.dateControl.invalid) {
            this.invalidDate.emit(this.dateControl.errors);

            // Adding these checks in to force the validation, This needs to be looked into on why the UI is not
            // updating when the error is fired.
            this.dateControl.hasError('maxDate');
            this.dateControl.hasError('minDate');
            return;
        }

        this.invalidDate.emit(undefined);

        const dateToEmit = this.getMomentFromDateSegments(this.dateControl.value, this.timeControl.value);

        if (!dateToEmit.isSame(this.lastEmittedValue, 'minute')) {
            this.dateChange.emit(dateToEmit);
            this.lastEmittedValue = dateToEmit;
        }
    }

    // noinspection JSMethodCanBeStatic
    private getMomentFromDateSegments(date: Date, time: TimeStep): moment.Moment {
        const newMoment = moment(date);
        newMoment.hour(time.hour).minute(time.minute).second(0);
        return newMoment;
    }

    /**
     * Logic used to preform the comparison on the time step selector in the view,
     * since objects are used as the value, the ids will be used for comparison
     * instead of straight object comparison
     * @param o1 The value from the option
     * @param o2 The value being compared for the select.
     */
    public timeComparison(o1: TimeStep, o2: TimeStep): boolean {
        return o1.id === o2.id;
    }

    /**
     * Callback function to manually open and close the datepicker panel from a
     * click event
     * @param event The click event on the date.
     */
    public toggleDatepicker(event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();

        this.datepicker.opened ? this.datepicker.close() : this.datepicker.open();
    }

    public isValidStep(step: TimeStep): boolean {
        // Check to see if the datepicker day is the min date value
        if (this.minDate?.toDateString() === this.dateControl.value?.toDateString()) {
            // noinspection JSUnusedLocalSymbols
            const [date, time] = this.getPickerValuesFromDate(this.min);
            return step.id >= time.id;
        }

        if (this.maxDate?.toDateString() === this.dateControl.value?.toDateString()) {
            // noinspection JSUnusedLocalSymbols
            const [date, time] = this.getPickerValuesFromDate(this.max);
            return step.id <= time.id;
        }

        return true;
    }

    private isValidMinDate(): AsyncValidatorFn {
        return (control: AbstractControl<Date>) => {
            if (this.min && this.timeControl && control.value) {
                const comparisonDate = this.getMomentFromDateSegments(control.value, this.timeControl.value);
                const ret = comparisonDate.isSameOrAfter(this.min, 'minute') ? null : {minDate: 'Selected Date is before the min date'};
                return of(ret);
            }
            return of(null);
        };
    }

    private isValidMaxDate(): AsyncValidatorFn {
        return (control: AbstractControl<Date>) => {
            if (this.allowFutureEndDate) {
                return of(null);
            }

            if (this.max && this.timeControl && control.value) {
                const comparisonDate = this.getMomentFromDateSegments(control.value, this.timeControl.value);
                const ret = comparisonDate.isSameOrBefore(this.max, 'minute') ? null : {maxDate: 'Selected Date is after the max date'};
                return of(ret);
            }
            return of(null);
        };
    }
}
