import {GenOutageAggregationLevel} from '../../../../../generated/serverModels/GenOutageAggregationLevel';
import * as moment from 'moment';
import {EventEmitter} from '@angular/core';
import {State} from '../../outage/classes/state';
import {ApplicationConfig} from '../../../classes/application-config';

export class LeafletNomFilter {
    public filterChange: EventEmitter<any>;
    public aggregationLevel: GenOutageAggregationLevel;
    public runStartTime: moment.Moment = ApplicationConfig.roundMinute();
    public states: State[] = [];

    constructor() {}

    /**
     * @deprecated this method is a duplicate of ApplicationConfig.roundMinute()
     */
    public getRecentRunTime(): moment.Moment {
        const runTime = moment();
        const r = Math.floor(runTime.minute() / 15) * 15;
        runTime.minute(r).second(0);
        return runTime;
    }

    public getApiDate(): string {
        return this.runStartTime.utc().format();
    }
}
