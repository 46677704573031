<div class="dialog-header">
    <span>{{ utilityName }}</span>
    <button mat-icon-button mat-dialog-close>
        <mat-icon fontSet="fas" fontIcon="fa-times"></mat-icon>
    </button>
</div>

<mat-dialog-content #content>
    <div class="table-container">
        <table mat-table [dataSource]="ds" matSort [matSortDisableClear]="true" matSortActive="timestamp" matSortDirection="asc">
            <ng-container matColumnDef="hidden">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="hiddenColumn"></th>
                <td mat-cell *matCellDef="let source" class="hiddenColumn"></td>
            </ng-container>

            <ng-container matColumnDef="timestamp">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="med-col">
                    Timestamp
                    <eaglei-table-sort-header [columnName]="'timestamp'" [sort]="sort"></eaglei-table-sort-header>
                </th>
                <td mat-cell *matCellDef="let source" class="med-col">{{ source.timestamp | momentDate }}</td>
            </ng-container>

            <ng-container matColumnDef="parent_error_type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="med-col">
                    Parent Error
                    <eaglei-table-sort-header [columnName]="'parent_error_type'" [sort]="sort"></eaglei-table-sort-header>
                </th>
                <td mat-cell *matCellDef="let source" class="med-col">{{ source.parentErrorType }}</td>
            </ng-container>

            <ng-container matColumnDef="specific_error_type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="med-col">
                    Specific Error
                    <eaglei-table-sort-header [columnName]="'specific_error_type'" [sort]="sort"></eaglei-table-sort-header>
                </th>
                <td mat-cell *matCellDef="let source" class="med-col">{{ source.specificErrorType }}</td>
            </ng-container>

            <ng-container matColumnDef="error_text">
                <th mat-header-cell *matHeaderCellDef class="error-text wide-col">Error Text</th>
                <td mat-cell *matCellDef="let source" class="error-text wide-col">{{ source.errorText }}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
            <tr mat-row *matRowDef="let row; columns: columnNames; let odd = odd"></tr>
        </table>
    </div>

    <mat-paginator [pageSizeOptions]="[5, 10, 15]" showFirstLastButtons></mat-paginator>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-flat-button mat-dialog-close color="primary">Close</button>
</mat-dialog-actions>
