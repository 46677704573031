import {LeafletVectorSource} from './leaflet-vector-source';
import {LeafletMapLayer} from './leaflet-map-layer';
import * as L from 'leaflet';

export abstract class ClusterSource<X = any> extends LeafletVectorSource<X> {
    private icons: any[] = [];

    protected constructor(layerInfo: LeafletMapLayer) {
        super(layerInfo);
        this.layerInfo.opacityEnabled = false;
    }

    sourceUpdated() {
        this.source.on('unspiderfied', (event: any) => {
            event.cluster.setOpacity(this.layerInfo.opacity);
        });

        this.source.on('add', () => {
            // Setting the timeout to make the layer wait until all the clusters are added. Research is being done on a non arbitrary way to handle this
            setTimeout(() => {
                this.icons = [];
                this.getIcons((this.source as any)._topClusterLevel);
                this.layerInfo.opacityEnabled = true;

                this.changeOpacity(this.layerInfo.opacity);
            }, 2_000);
        });
    }

    private getIcons(level: any): any[] {
        const clusters: any[] = level._childClusters;
        if (clusters === undefined) {
            return;
        }

        clusters.forEach((cluster) => {
            this.getIcons(cluster);
            this.icons.push(cluster);
        });
    }

    changeOpacity(value?: number) {
        if (!this.source || !value) {
            return;
        }

        this.layerInfo.opacity = value;
        const source: L.MarkerClusterGroup = this.source as any;

        this.icons.forEach((cluster) => cluster.setOpacity(value));

        source.getLayers().forEach((l: L.Marker) => {
            l.setOpacity(value);
        });
    }
}
