<!--suppress TypeScriptUnresolvedVariable -->
<mat-card class="container">
    <mat-card-header #header class="widget-header large">
        <div class="map-header">
            <p class="title">
                <span>Nominations</span>
                <eaglei-info-icon [attributionModalText]="attributionModalText" [widgetName]="widgetName"></eaglei-info-icon>
            </p>
            <div class="btn-container">
                <button mat-stroked-button (click)="gotoReport()" class="regular report-link">
                    <mat-icon fontSet="fas" fontIcon="fa-file-invoice" inline></mat-icon>
                    View Report
                </button>
                <button mat-stroked-button class="regular" *ngIf="!onMobile()" (click)="openMapper()">Launch Mapper</button>
            </div>

            <eaglei-location-filter
                [class.hide]="eventDashboard"
                class="constraint"
                [newDesign]="true"
                [defaultSelection]="states"
                [selectAllOption]="true"
                (locations)="updateStates($event)"
                [hideFemaRegions]="true">
            </eaglei-location-filter>

            <mat-form-field
                class="filter-select"
                appearance="outline"
                floatLabel="always"
                (keyup)="filterData($event.target.value)"
                [class.active]="isFilterActive()">
                <mat-label>Location Name</mat-label>
                <input matInput type="text" />
            </mat-form-field>

            <eaglei-date-range
                [class.hide]="eventDashboard"
                class="constraint"
                [singlePicker]="true"
                [startPlaceholder]="'Date'"
                [start]="startDate"
                [min]="ongService.minDate"
                (change)="updateDate($event)"></eaglei-date-range>

            <button
                mat-icon-button
                class="ei-export"
                matTooltip="Export"
                matTooltipPosition="above"
                matTooltipShowDelay="250"
                [matMenuTriggerFor]="menu">
                <mat-icon fontSet="fas" fontIcon="fa-file-export"></mat-icon>
            </button>
        </div>
    </mat-card-header>

    <mat-card-content #content class="widget-content">
        <div class="map">
            <eaglei-leaflet-map class="wide tall" (mapRef)="mapReady.next($event)" [mapOptions]="mapOptions"></eaglei-leaflet-map>
        </div>

        <div class="table-container">
            <div class="table">
                <table mat-table [dataSource]="dataSource" matSort [matSortDisableClear]="true" matSortActive="operational_capacity">
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Location Name
                            <eaglei-table-sort-header [columnName]="'name'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let info"
                            class="ellipsis"
                            #pipelineName
                            [matTooltipDisabled]="!hasEllipsis(pipelineName)"
                            [matTooltip]="info.location.name"
                            matTooltipPosition="above"
                            matTooltipShowDelay="250">
                            {{ info.location.name }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="ei-sm-column">
                            Pipeline ID
                            <eaglei-table-sort-header [columnName]="'id'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let info" class="ei-sm-column">
                            {{ info.location.location_id }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="scheduled_capacity">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Scheduled Capacity
                            <eaglei-table-sort-header [columnName]="'scheduled_capacity'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let info" [class.over-capacity]="info.overCapacity">
                            {{ info.scheduled_capacity | eagleiNumber }} MMBTU
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="operational_capacity">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Operational Capacity
                            <eaglei-table-sort-header [columnName]="'operational_capacity'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let info">{{ info.operational_capacity | eagleiNumber }} MMBTU</td>
                    </ng-container>

                    <ng-container matColumnDef="role">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Role
                            <eaglei-table-sort-header [columnName]="'role'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let info" style="white-space: nowrap">
                            {{ info.location.role | replaceCharacter | titlecase }}

                            <ng-container *ngIf="info.location.role === 'FLOW_INCREASING'">
                                <img class="inverse" src="/dist/images/icons/trending-down-green.svg" alt="" />
                            </ng-container>
                            <ng-container *ngIf="info.location.role === 'FLOW_DECREASING'">
                                <img class="inverse" src="/dist/images/icons/trending-up-red.svg" alt="" />
                            </ng-container>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
                    <tr mat-row *matRowDef="let row; columns: columnNames"></tr>
                </table>
            </div>

            <div class="auto-mask hide-mask" *ngIf="showMask">
                <span class="mask-message">No Data Matches Filters</span>
            </div>
        </div>
    </mat-card-content>

    <mat-card-footer #footer class="footer">
        <div class="map-legend tall">
            <span class="label">Utilization Factor:</span>
            <div>
                <div class="nomination-legend-circle minor"></div>
                <span><50%</span>
            </div>
            <div>
                <div class="nomination-legend-circle moderate"></div>
                <span>50-74%</span>
            </div>
            <div>
                <div class="nomination-legend-circle severe"></div>
                <span>>74%</span>
            </div>
            <div>
                <div class="cluster-count-circle">28</div>
                <p>Multiple Utilization Factors Contained in Cluster</p>
            </div>
        </div>
        <div class="paginator">
            <mat-paginator [pageSize]="7" hidePageSize showFirstLastButtons></mat-paginator>
            <div class="table-legend">
                <div></div>
                <span>Operating over capacity</span>
            </div>
        </div>
    </mat-card-footer>
</mat-card>

<mat-menu #menu="matMenu" overlapTrigger>
    <button mat-menu-item (click)="exportData()">Export Data As CSV</button>
</mat-menu>
