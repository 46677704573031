<div class="sidebar-container" [class.lite-map]="isLiteSidebar" [class.hide-width]="!sidebarExpanded">
    <div class="header-bar">
        <mat-tab-group class="sidebar-icons" (selectedTabChange)="changeSelectedTab($event)">
            <ng-container *ngFor="let section of sections">
                <mat-tab *ngIf="section.show" [label]="section.name">
                    <ng-template mat-tab-label>
                        <mat-icon fontSet="fas" [fontIcon]="section.icon" class="sidebar-icon"></mat-icon>
                    </ng-template>
                </mat-tab>
            </ng-container>
        </mat-tab-group>

        <div
            (click)="toggleSidebar()"
            class="clickable expand-icon"
            [class.hide-expand]="!sidebarExpanded && isLiteSidebar && useMobileLayout()">
            <mat-icon
                *ngIf="!isLiteSidebar || !useMobileLayout()"
                fontSet="fas"
                [fontIcon]="sidebarExpanded ? 'fa-chevron-left' : 'fa-chevron-right'"></mat-icon>
            <mat-icon
                *ngIf="isLiteSidebar && useMobileLayout()"
                fontSet="fas"
                fontIcon="fa-times"
                [class.hide-icon]="!sidebarExpanded"></mat-icon>
        </div>
    </div>

    <div class="active-section">
        <div class="hide-section" [class.show]="activeSection === 'layers'">
            <eaglei-layer-list [layerCategories]="layerCategories"></eaglei-layer-list>
        </div>

        <!-- The wrapper class is used because HTML2Canvas converts angular tags to 'customhtml2canvaselement'    -->
        <!--    <div class="legend-list-wrapper">-->
        <div class="hide-section legend-list-wrapper" [class.show]="activeSection === 'legends'">
            <eaglei-legend-list></eaglei-legend-list>
        </div>

        <div class="hide-section" [class.show]="activeSection === 'tools'">
            <eaglei-tool-list [layerCategories]="layerCategories" [activeLayer]="activeSection"></eaglei-tool-list>
        </div>
    </div>
</div>
