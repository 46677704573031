<div class="filters">
    <button mat-flat-button (click)="createAlert()" color="primary">Create New Alert</button>
    <button mat-flat-button (click)="openSystemAlertsModal()" color="primary">System Alerts</button>
</div>

<mat-card>
    <mat-card-content>
        <div class="table-container">
            <table
                #materialTable
                mat-table
                [dataSource]="dataSource"
                matSort
                [matSortDisableClear]="true"
                [matSortActive]="'location'"
                direction="asc">
                <ng-container matColumnDef="select">
                    <th *matHeaderCellDef mat-header-cell>
                        <mat-checkbox
                            color="primary"
                            [checked]="$allSelected()"
                            [indeterminate]="$hasSelection()"
                            (change)="toggleAll()"></mat-checkbox>
                    </th>
                    <td *matCellDef="let alert" mat-cell>
                        <mat-checkbox
                            color="primary"
                            [checked]="!!$selected()[alert.id]"
                            (change)="toggleSelected(alert, $event)"></mat-checkbox>
                    </td>
                </ng-container>

                <ng-container matColumnDef="location">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Location Name
                        <eaglei-table-sort-header [columnName]="'location'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let alert">
                        <div>
                            {{ alert.locationName }}
                            <span class="customer-number">({{ getTotalCustomers(alert.location) | shortNumber }})</span>
                        </div>

                        <div *ngIf="!alert.rollUp" class="help-text">Applied to states individually in region</div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="county">
                    <th mat-header-cell *matHeaderCellDef>County (if applicable)</th>
                    <td mat-cell *matCellDef="let alert">
                        {{ alert.type === 'county' ? alert.description : '-' }}
                        <span *ngIf="alert.type === 'county'" class="customer-number"
                            >({{ getTotalCustomers(alert.county, 'county') | shortNumber }})</span
                        >
                    </td>
                </ng-container>

                <ng-container matColumnDef="threshold">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Outage Threshold
                        <eaglei-table-sort-header [columnName]="'threshold'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let alert">
                        <div *ngIf="!alert.editing">
                            {{ alert.threshold | shortNumber }}
                        </div>

                        <div *ngIf="alert.editing">
                            <mat-form-field class="filter-select">
                                <input
                                    matInput
                                    placeholder="Outage Threshold"
                                    [ngModel]="alert.threshold"
                                    (ngModelChange)="updateThreshold($event, alert, 'outage')"
                                    (keypress)="checkNumberInput($event)" />
                            </mat-form-field>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="percent">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Percent Affected
                        <eaglei-table-sort-header [columnName]="'percent'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let alert">
                        <div *ngIf="!alert.editing">{{ alert.percent }}<span *ngIf="alert.percent !== 'N/A'">%</span></div>

                        <div *ngIf="alert.editing">
                            <mat-form-field class="filter-select">
                                <input
                                    matInput
                                    placeholder="Percent Affected"
                                    [ngModel]="alert.percent"
                                    (ngModelChange)="updateThreshold($event, alert, 'percent')"
                                    (keypress)="checkNumberInput($event, true)" />
                                <span matSuffix>%</span>
                            </mat-form-field>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef class="small-col">
                        <button
                            mat-icon-button
                            matTooltip="Unsubscribe from selected alerts"
                            [disabled]="!$hasSelection()"
                            (click)="unsubscribeAlerts()">
                            <mat-icon>
                                <img class="unsub-img" src="/dist/images/icons/unsubscribe.svg" alt="" />
                            </mat-icon>
                        </button>
                    </th>
                    <td mat-cell *matCellDef="let alert" class="small-col">
                        <div class="actions">
                            <mat-icon inline fontSet="fas" class="clickable icon-unsub icon" (click)="unsubscribe(alert)">
                                <img class="resize-icon" src="/dist/images/icons/unsubscribe.svg" alt="" />
                            </mat-icon>
                            <mat-icon
                                *ngIf="!alert.systemAlert"
                                inline
                                fontSet="fas"
                                fontIcon="fa-edit"
                                (click)="toggleEdit(alert)"
                                class="clickable icon"
                                [class.selected-icon]="alert.editing"></mat-icon>
                            <mat-icon
                                *ngIf="alert.editing"
                                inline
                                fontSet="fas"
                                fontIcon="fa-save"
                                (click)="saveAlert(alert)"
                                class="clickable icon"></mat-icon>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columnNames"></tr>

                <tr mat-row *matRowDef="let row; columns: columnNames" class="hoverable"></tr>
            </table>

            <mat-paginator [pageSizeOptions]="[25, 50, 75, 100]" showFirstLastButtons></mat-paginator>
        </div>
    </mat-card-content>
</mat-card>
