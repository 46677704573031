<eaglei-leaflet-layer-header [layer]="this" (activeToggled)="toggleLayer($event)" [hideLegend]="true"></eaglei-leaflet-layer-header>

<ng-container *ngIf="isActive">
    <div #legendElement>
        <div class="vertical-legend map-legend tall">
            <span class="label">Utilization Factor:</span>
            <div>
                <div class="nomination-legend-circle minor"></div>
                <span><50%</span>
            </div>
            <div>
                <div class="nomination-legend-circle moderate"></div>
                <span>50-74%</span>
            </div>
            <div>
                <div class="nomination-legend-circle severe"></div>
                <span>>74%</span>
            </div>
            <div>
                <div class="cluster-count-circle">28</div>
                <p>Multiple Utilization Factors Contained in Cluster</p>
            </div>
        </div>
    </div>
</ng-container>
