import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'momentDate',
})
export class MomentDatePipe implements PipeTransform {
    transform(
        value: string | Date | moment.Moment | number,
        format?: string,
        showTimezone: boolean = false,
        utc: boolean = false,
        calendar: boolean = false
    ): any {
        try {
            if (value === undefined) {
                return 'Not Available';
            }

            format = format || 'M/D/YYYY hh:mm A';

            if (showTimezone) {
                format += ' z';
            }
            let res;
            if (utc) {
                res = moment(value).utc().format(format);
            } else {
                // @ts-ignore
                res = moment(value).tz(moment.tz.guess()).format(format);
            }

            if (res.toLowerCase() === 'invalid date') {
                return value;
            } else if (calendar) {
                return moment(res).calendar(null, {
                    sameDay: '[Today]',
                    nextDay: '[Tomorrow]',
                    nextWeek: 'dddd',
                    lastDay: '[Yesterday]',
                    lastWeek: '[Last] dddd',
                    sameElse: 'DD/MM/YYYY',
                });
            } else {
                return res;
            }
        } catch (e) {
            console.error('invalid date ' + value);
            console.error(e);
            return 'error';
        }
    }
}
