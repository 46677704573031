import {Injectable} from '@angular/core';
import * as moment from 'moment';
import {Observable, of} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {HttpClient, HttpParams} from '@angular/common/http';
import {CsvDownloadInfo} from '../classes/csv-download-info';
import {GenCounty} from '../../../../../generated/serverModels/GenCounty';
import {State} from '../classes/state';
import {GenAggregationLevel} from '../../../../../generated/serverModels/GenAggregationLevel';
import {ResponseWrapper} from '../../../classes/response-wrapper';
import {GenMax24HourCounty} from 'frontend/generated/serverModels/GenMax24HourCounty';
import {GenMax24HourState} from 'frontend/generated/serverModels/GenMax24HourState';
import {GenMax24HourZip} from 'frontend/generated/serverModels/GenMax24HourZip';

@Injectable({
    providedIn: 'root',
})
export class OutageService {
    /** Cache of states' counties, for immediate response to repeat calls.  */
    protected countyCache: {[stateId: number]: GenCounty[]} = {};
    constructor(private http: HttpClient) {}

    public updateSummaryData(start: moment.Moment, end: moment.Moment): Observable<any> {
        const url = `/api/outagesummary/converttooutagesummary?start=${start.format()}&end=${end.format()}`;
        return this.http.get(url);
    }

    /**
     * Retrieves the counties for the specified States.
     * @note This method uses caching, returns cached values for any states previously requested
     */
    public getCountiesForStates(states: State[]): Observable<GenCounty[]> {
        let query: number[] = [];
        let cache: GenCounty[] = [];
        if (states.length) {
            const {cached, newQuery} = states.reduce(
                (p: {cached: number[]; newQuery: number[]}, s) =>
                    // Adds State IDs to cache group if counties available, else to query group
                    !this.countyCache[s.id] ? {...p, newQuery: [...p.newQuery, s.id]} : {...p, cached: [...p.cached, s.id]},
                {cached: [], newQuery: []}
            );
            // Merge all cached counties together, to reflect api response
            cache = cached.reduce((p: GenCounty[], c) => p.concat(this.countyCache[c]), []);
            query = newQuery;
        }

        if (!query.length && !!states.length) {
            // If no states to be queried BUT specific states were requested, return them from cache
            return of(cache);
        }

        const url = `api/location/county?format=None&stateId=${query.join('&stateId=')}`;
        return this.http.get<GenCounty[]>(url).pipe(
            /** Add any cached state counties to the response & add the response to the cache. */
            map((counties) => {
                const newCache = {};
                counties.forEach((county) => {
                    const newState = newCache[county.stateId] ?? [];
                    newCache[county.stateId] = newState.concat(county);
                });
                this.countyCache = {...this.countyCache, ...newCache};
                return counties.concat(cache);
            })
        );
    }

    public sendCsvDownloadEmail(info: CsvDownloadInfo): Observable<any> {
        const url = 'api/outagesummary/csvdownload';
        return this.http.post(url, info);
    }

    public getPaginatedOutageData<T extends GenMax24HourCounty | GenMax24HourState | GenMax24HourZip>(
        aggregationLevel: GenAggregationLevel,
        runStartTime: moment.Moment,
        states?: State[],
        page: number = 1,
        itemsPerPage: number = 25,
        sort?: string
    ): Observable<ResponseWrapper<T>> {
        let endpoint: string;
        let params = new HttpParams().set('start', runStartTime.format());

        params = params.set('page', page);
        params = params.set('pageSize', itemsPerPage);
        params = params.set('sort', sort);

        if (states?.length !== 0) {
            params = params.append('state', states.map((state) => state.abbreviation).join());
        }

        switch (aggregationLevel.toString()) {
            case GenAggregationLevel.state.toString():
                endpoint = `statemax24hoursummary`;
                break;
            case GenAggregationLevel.county.toString():
                endpoint = `countymax24hoursummary`;
                params = params.set(`allCounties`, true);
                break;
            case GenAggregationLevel.zip.toString():
                endpoint = `zipmax24hoursummary`;
                break;
            default:
                console.warn('Trying to get paginated data for unknown aggregation level: ' + aggregationLevel.toString());
        }

        return this.http.get<ResponseWrapper>(`api/outagesummary/${endpoint}-paginated`, {params});
    }

    public getCsvDownloadRowCount(aggregationLevel: GenAggregationLevel, fipsCodes: string[]): Observable<number> {
        const url = 'api/outagesummary/csvdownload-row-count';
        const params = new HttpParams().set('aggregation', aggregationLevel.name).set('fips', fipsCodes.join(','));
        return this.http.get<number>(url, {params});
    }
}
