<eaglei-leaflet-layer-header [layer]="this" (activeToggled)="toggleLayer($event)" [hideLegend]="true"></eaglei-leaflet-layer-header>

<ng-container *ngIf="isActive">
    <div #legendElement>
        <div class="vertical-legend">
            <span class="header">Fault Lines</span>
            <div class="faultLines-container">
                <ng-container [ngTemplateOutlet]="solidLine"></ng-container>
                <span class="label">Well Constrained Location</span>
            </div>
            <div class="dash-container">
                <ng-container [ngTemplateOutlet]="dashedLine"></ng-container>
                <span class="label"> Moderately Constrained Location</span>
            </div>
            <div class="dots-container">
                <ng-container [ngTemplateOutlet]="dotsLine"></ng-container>
                <span class="label"> Inferred Location</span>
            </div>
        </div>

        <div>
            <span class="header">Fault Areas</span>
            <br />
            <div class="polygon-container">
                <ng-container [ngTemplateOutlet]="polygon"></ng-container>
            </div>

            <span class="header">Fault & Area Colors</span>
            <div class="color-container">
                <div *ngFor="let color of faultColors">
                    <svg #dashedLine width="250" height="250" [hexcode]="hexcode" [label]="label">
                        <line x1="5" y1="8" x2="30" y2="8" stroke-width="5" [style.stroke]="color.hexcode" />
                        <circle r="3" cx="40" cy="8" [style.fill]="color.hexcode" />
                    </svg>
                    <span class="label">{{ color.label }}</span>
                </div>
            </div>
        </div>

        <ng-template #solidLine let-size="size">
            <div class="line svg">
                <svg class="solidLine" height="100" width="100">
                    <line x1="5" y1="8" x2="40" y2="8" stroke-width="5" stroke="black" />
                </svg>
            </div>
        </ng-template>

        <ng-template #dashedLine let-size="size">
            <div class="dashDot svg">
                <svg class="dashedDot" width="250" height="250">
                    <line x1="5" y1="8" x2="30" y2="8" stroke-width="5" stroke="black" />
                    <circle r="3" cx="40" cy="8" fill="black" />
                </svg>
            </div>
        </ng-template>

        <ng-template #dotsLine let-size="size">
            <div class="dots svg">
                <svg class="dots" height="250" width="250">
                    <circle cx="5" cy="5" r="3" />
                    <circle cx="20" cy="5" r="3" />
                    <circle cx="35" cy="5" r="3" />
                </svg>
            </div>
        </ng-template>

        <ng-template #polygon let-size="size">
            <div class="polygon svg" id="pattern"></div>
        </ng-template>
    </div>
</ng-container>
