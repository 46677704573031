<mat-chip-listbox>
    <div class="list-container">
        <mat-chip-option [highlighted]="true" *ngFor="let state of states | slice : 0 : numberToShow" (click)="remove(state)">
            {{ getDisplayValue(state) }}
        </mat-chip-option>

        <button mat-stroked-button *ngIf="states.length > 0 && showAll" (click)="showLess()">Show Less</button>

        <button mat-stroked-button *ngIf="states.length > numberToShow && !showAll" (click)="showAllChips()">
            <span class="chip-text"> + {{ states.length - numberToShow }} </span>
        </button>

        <button mat-stroked-button *ngIf="states.length" (click)="clearAll()">Clear All</button>

        <button mat-icon-button *ngIf="!states.length" [disabled]="true" class="no-states">
            <mat-icon>not_interested</mat-icon>
        </button>
    </div>
</mat-chip-listbox>
