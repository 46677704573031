<div class="hide-overflow">
    <div #header class="widget-header large">
        <div class="column">
            <p class="title">
                {{ getTableTitle() }}
                <eaglei-info-icon [attributionModalText]="attributionModalText" [widgetName]="widgetName"></eaglei-info-icon>
            </p>
            <eaglei-honor-preference-indicator></eaglei-honor-preference-indicator>
        </div>

        <div class="filters">
            <mat-form-field class="filter-select active type-filter" appearance="outline">
                <mat-label *ngIf="!!selectedTable">Table Type</mat-label>
                <mat-select placeholder="Table Type" [value]="selectedTable" (selectionChange)="updateTable($event.value)">
                    <mat-option [value]="'transportation'">Transportation Weekly</mat-option>
                    <mat-option [value]="'rail'">Rail Flow</mat-option>
                </mat-select>
            </mat-form-field>

            <eaglei-date-range
                [start]="selectedDate"
                [min]="ongService.minDate"
                [startPlaceholder]="'Date'"
                [singlePicker]="true"
                [newDesign]="true"
                (change)="updateDate($event)">
            </eaglei-date-range>

            <button
                mat-icon-button
                class="ei-export"
                matTooltip="Export"
                matTooltipPosition="above"
                matTooltipShowDelay="250"
                [matMenuTriggerFor]="menu">
                <mat-icon fontSet="fas" fontIcon="fa-file-export"></mat-icon>
            </button>
        </div>
    </div>

    <div #content class="widget-content">
        <div class="table-container" [class.invisible]="showMask | async">
            <table mat-table [dataSource]="dataSource" matSort [matSortDisableClear]="true" matSortActive="date" matSortDirection="asc">
                <ng-container matColumnDef="region">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Region
                        <eaglei-table-sort-header [columnName]="'region'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let info">
                        {{ info.region }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="terminal">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Terminal
                        <eaglei-table-sort-header [columnName]="'terminal'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let info">
                        {{ info.terminal }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="flow">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Flow
                        <eaglei-table-sort-header [columnName]="'flow'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let info">{{ info.flow | eagleiNumber }} {{ info.flow !== null ? 'bpd' : '' }}</td>
                </ng-container>

                <ng-container matColumnDef="capacity">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="small-col">
                        Capacity
                        <eaglei-table-sort-header [columnName]="'capacity'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let info" class="small-col">{{ info.capacity | eagleiNumber }} bpd</td>
                </ng-container>

                <ng-container matColumnDef="loading_type">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Loading Type
                        <eaglei-table-sort-header [columnName]="'loading_type'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let info">
                        {{ info.loading_type }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="small-col">
                        Date
                        <eaglei-table-sort-header [columnName]="'date'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let info" class="small-col">
                        {{ info.report_date | momentDate : 'M/D/YYYY' }}
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
                <tr mat-row *matRowDef="let row; columns: columnNames"></tr>
            </table>
        </div>
        <div class="auto-mask hide-mask" *ngIf="showMask | async">
            <span class="mask-message">
                <span>No data found for selected filters</span>
            </span>
        </div>
    </div>

    <div #footer class="widget-footer">
        <mat-paginator [pageSize]="5" hidePageSize showFirstLastButtons></mat-paginator>
    </div>
</div>

<mat-menu #menu="matMenu" overlapTrigger>
    <button (click)="exportTable()" mat-menu-item>Export Table As CSV</button>
</mat-menu>
