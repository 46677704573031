
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

export class GenWidgetSettingType {
    static readonly AGGREGATION_LEVEL: GenWidgetSettingType = new GenWidgetSettingType('AGGREGATION_LEVEL');
    static readonly IMPACTED: GenWidgetSettingType = new GenWidgetSettingType('IMPACTED');
    static readonly MAX_STAT: GenWidgetSettingType = new GenWidgetSettingType('MAX_STAT');
    static readonly NOTICES: GenWidgetSettingType = new GenWidgetSettingType('NOTICES');
    static readonly ONG_PLANNED_OUTAGE: GenWidgetSettingType = new GenWidgetSettingType('ONG_PLANNED_OUTAGE');
    static readonly ONG_REFINERY_STATUS: GenWidgetSettingType = new GenWidgetSettingType('ONG_REFINERY_STATUS');
    static readonly ONG_REFINERY_CAPACITY: GenWidgetSettingType = new GenWidgetSettingType('ONG_REFINERY_CAPACITY');

    public readonly name: string;

    private constructor(name: string) {
        this.name = name;
    }

    static forName(name: string) {
        if (name === 'AGGREGATION_LEVEL') return GenWidgetSettingType.AGGREGATION_LEVEL;
        if (name === 'IMPACTED') return GenWidgetSettingType.IMPACTED;
        if (name === 'MAX_STAT') return GenWidgetSettingType.MAX_STAT;
        if (name === 'NOTICES') return GenWidgetSettingType.NOTICES;
        if (name === 'ONG_PLANNED_OUTAGE') return GenWidgetSettingType.ONG_PLANNED_OUTAGE;
        if (name === 'ONG_REFINERY_STATUS') return GenWidgetSettingType.ONG_REFINERY_STATUS;
        if (name === 'ONG_REFINERY_CAPACITY') return GenWidgetSettingType.ONG_REFINERY_CAPACITY;
        throw new Error('Unexpected value for GenWidgetSettingType:' + name);
    }

    static values(): GenWidgetSettingType[] {
        return [
            GenWidgetSettingType.AGGREGATION_LEVEL,
            GenWidgetSettingType.IMPACTED,
            GenWidgetSettingType.MAX_STAT,
            GenWidgetSettingType.NOTICES,
            GenWidgetSettingType.ONG_PLANNED_OUTAGE,
            GenWidgetSettingType.ONG_REFINERY_STATUS,
            GenWidgetSettingType.ONG_REFINERY_CAPACITY,
        ];
    }

    toJSON(): string {
        return this.name;
    }

    toString(): string {
        return this.name;
    }
}

