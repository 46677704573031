import {AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {GenWidgetSettingType} from 'frontend/generated/serverModels/GenWidgetSettingType';
import {ApplicationConfig} from 'frontend/src/app/classes/application-config';
import {ModalConfig} from 'frontend/src/app/classes/modal-config';
import {LandingGridItem} from 'frontend/src/app/landing/classes/landing-grid-item';
import {throttleTime} from 'rxjs/operators';
import {WidgetSetting} from '../../classes/widget-setting';

@Component({
    selector: 'eaglei-configure-widget-notices-modal',
    templateUrl: './configure-widget-notices-modal.component.html',
    styleUrls: ['./configure-widget-notices-modal.component.scss'],
})
export class ConfigureWidgetNoticesModalComponent implements OnInit, AfterViewInit {
    @ViewChild('content', {static: true}) content: ElementRef;

    public noticesLevels = ['critical', 'non-critical'];

    public selectedNotice: 'critical' | 'non-critical' = 'critical';

    constructor(
        public dialogRef: MatDialogRef<ConfigureWidgetNoticesModalComponent>,
        @Inject(MAT_DIALOG_DATA) private item: LandingGridItem
    ) {}

    public ngOnInit(): void {
        if (!!this.item.detail.settings.find((s) => s.type === GenWidgetSettingType.NOTICES)) {
            this.selectedNotice = this.item.detail.settings.find((s) => s.type === GenWidgetSettingType.NOTICES).value as any;
        }
    }

    public ngAfterViewInit(): void {
        ModalConfig.setModalHeight(this.content);
        ApplicationConfig.resizeEvent.pipe(throttleTime(250)).subscribe(() => ModalConfig.setModalHeight(this.content));
    }

    public changeNotice(notices: 'critical' | 'non-critical'): void {
        this.selectedNotice = notices;
    }

    public disableUpdate(): boolean {
        return !this.noticesLevels.includes(this.selectedNotice);
    }

    public configureWidget(): void {
        if (this.item.detail.settings.find((s) => s.type === GenWidgetSettingType.NOTICES)) {
            this.item.detail.settings.find((s) => s.type === GenWidgetSettingType.NOTICES).value = this.selectedNotice;
        } else {
            const setting = new WidgetSetting({
                type: GenWidgetSettingType.NOTICES,
                value: this.selectedNotice,
            } as any);
            this.item.detail.settings.push(setting);
        }

        this.dialogRef.close(this.item);
    }
}
