
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import * as moment from 'moment';

export class GenEmailChangeRequest {

    id: number;
    approved: boolean;
    deniedReason: string;
    email: string;
    reason: string;
    requestDate: moment.Moment;
    userId: number;

    constructor();
    constructor( jsonObj: any );
    constructor( genEmailChangeRequest: GenEmailChangeRequest);
    constructor( id: number );

    constructor( input?: GenEmailChangeRequest|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            this.approved = input.approved; // boolean [boolean]
            this.deniedReason = input.deniedReason; // string [string]
            this.email = input.email; // string [string]
            this.reason = input.reason; // string [string]
            if ( input.requestDate && typeof input.requestDate === 'string' ) {
                this.requestDate = moment(input.requestDate).utc();
            } else {
                this.requestDate = input.requestDate;
            }
            this.userId = input.userId; // int [number]
       }
    }

    static jsonResponseToList(arr: any[]): GenEmailChangeRequest[] {
        if (arr && arr.map) {
          return arr.map(a => new GenEmailChangeRequest(a));
        } else {
          return [];
        }
    }
}

