import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'eaglei-select-clear',
    template: `
        <div class="clear-container">
            <button mat-raised-button color="primary" (click)="selectAll()">Select All</button>
            <button mat-raised-button color="warn" (click)="clearAll()">Clear All</button>
        </div>
    `,
    styleUrls: ['./select-clear.component.scss'],
})
export class SelectClearComponent implements OnInit {
    @Input() activeFilters: any[];
    @Input() possibleFilters: any[];
    @Output() activeFilterChange = new EventEmitter();
    @Output() select = new EventEmitter();
    @Output() clear = new EventEmitter();

    constructor() {}

    ngOnInit() {}

    selectAll() {
        if (this.possibleFilters) {
            this.activeFilterChange.emit(this.possibleFilters);
        } else {
            this.select.emit();
        }
    }

    clearAll() {
        this.clear.emit();
        this.activeFilterChange.emit([]);
    }
}
