import {Pipe, PipeTransform} from '@angular/core';
import {GenWidgetName} from '../../../generated/serverModels/GenWidgetName';

@Pipe({
    name: 'widgetName',
})
export class WidgetNamePipe implements PipeTransform {
    transform(value: GenWidgetName): string {
        let userFriendlyName: string;

        switch (value) {
            case GenWidgetName.COMMUNITY_LIST:
                userFriendlyName = 'My Communities';
                break;
            case GenWidgetName.OUTAGE_CHART:
                userFriendlyName = 'Outage Trend Chart';
                break;
            case GenWidgetName.OUTAGE_MAP:
                userFriendlyName = 'National Outage Map';
                break;
            case GenWidgetName.REPORT_LIST:
                userFriendlyName = 'Pinned Reports';
                break;
            case GenWidgetName.OUTAGE_SUMMARY:
                userFriendlyName = 'Outage Summary';
                break;
            case GenWidgetName.STATS:
                userFriendlyName = 'Stats';
                break;
            case GenWidgetName.OIL_STORAGE:
                userFriendlyName = 'Oil Storage';
                break;
            case GenWidgetName.OIL_BARREL_FLOW:
                userFriendlyName = 'Oil Barrel Daily Flows';
                break;
            case GenWidgetName.OIL_REFINERY_STATUS:
                userFriendlyName = 'Oil Refinery Status';
                break;
            case GenWidgetName.DAILY_POWER_ESTIMATE:
                userFriendlyName = 'Daily Power Estimate';
                break;
            case GenWidgetName.NG_NOTICES:
                userFriendlyName = 'Natural Gas Notices';
                break;
            case GenWidgetName.OIL_REFINERY_ALERT:
                userFriendlyName = 'Oil Refinery Alerts';
                break;
            case GenWidgetName.EIA_PETROLEUM_REPORT:
                userFriendlyName = 'Petroleum Report';
                break;
            case GenWidgetName.DERIVED_STORAGE:
                userFriendlyName = 'Derived Storage';
                break;
            case GenWidgetName.OIL_PLANNED_OUTAGES:
                userFriendlyName = 'Oil Planned Outages';
                break;
            case GenWidgetName.GAS_BURN:
                userFriendlyName = 'Gas Burn';
                break;
            case GenWidgetName.OIL_FLOW_TABLE:
                userFriendlyName = 'Oil Flow Table';
                break;
            case GenWidgetName.NG_NOMINATION:
                userFriendlyName = 'Natural Gas Nominations';
                break;
            case GenWidgetName.NG_RECEIPT_DELIVERY:
                userFriendlyName = 'Natural Gas Receipt/Delivery';
                break;
            case GenWidgetName.NG_OPERATIONAL_FLOW:
                userFriendlyName = 'Natural Gas Operational Flow';
                break;
            case GenWidgetName.SYSTEM_NEWS:
                userFriendlyName = 'System News';
                break;
            default:
                userFriendlyName = value.name
                    .substr(5)
                    .toLowerCase()
                    .replace(/_/g, ' ')
                    .replace(/\b[a-z]/g, (l) => l.toUpperCase());
        }

        return userFriendlyName;
    }
}
