import {OngPipelineNotice} from './ong-pipeline-notice';

export class OngPipeline {
    public pipeline_id: number;
    public name: string;
    public latitude: number;
    public longitude: number;
    public notices: OngPipelineNotice[];

    constructor(pipeline?: OngPipeline) {
        if (pipeline) {
            this.pipeline_id = pipeline.pipeline_id;
            this.name = pipeline.name;
            this.latitude = pipeline.latitude;
            this.longitude = pipeline.longitude;
            this.notices = pipeline.notices?.map((n) => new OngPipelineNotice(n));
        }
    }
}
