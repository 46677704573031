import {AfterViewInit, Component, ElementRef, inject, ViewChild} from '@angular/core';
import {BaseWidget} from '../../classes/base-widget';
import {EventSummaryFilters} from '../../../system-event/classes/event-summary-filters';
import {Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {SystemEvent} from '../../../system-event/classes/system-event';
import {PoleDetectionService} from '../../../../integrations/pole-detection/pole-detection.service';
import {UASPole} from '../../../../integrations/pole-detection/classes/pole';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {Router} from '@angular/router';

@Component({
    selector: 'eaglei-pole-detection-widget',
    templateUrl: './pole-detection-widget.component.html',
    styleUrls: ['./pole-detection-widget.component.scss'],
})
export class PoleDetectionWidgetComponent extends BaseWidget implements AfterViewInit {
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    private poleDetectionService: PoleDetectionService = inject(PoleDetectionService);

    private filters: EventSummaryFilters = new EventSummaryFilters();
    private dataStream: Subject<SystemEvent> = new Subject<SystemEvent>();

    public dataSource: MatTableDataSource<UASPole>;
    public readonly columns: string[] = ['flightDate', 'damaged', 'confidence', 'detections'];

    constructor(ref: ElementRef, private router: Router) {
        super(ref);
    }

    ngAfterViewInit() {
        this.dataStream
            .pipe(
                filter((value) => !!value),
                takeUntil(this.destroy$)
            )
            .subscribe((value) => {
                this.getPoleData(value);
            });
    }

    protected handleEventFilterChange(eventFilters: EventSummaryFilters) {
        const event = eventFilters?.event;

        if (event.id === this.filters?.event?.id) {
            return;
        }

        this.filters = eventFilters;
        this.dataStream.next(this.filters.event);
    }

    private getPoleData(event: SystemEvent): void {
        this.poleDetectionService
            .getPoles(event.id)
            .pipe(takeUntil(this.destroy$))
            .subscribe((poles) => {
                this.initializeTable(poles);
            });
    }

    private initializeTable(data: UASPole[]): void {
        if (this.dataSource) {
            this.dataSource.data = data;
        } else {
            this.dataSource = new MatTableDataSource<UASPole>(data);
            this.dataSource.sortingDataAccessor = this.sortAccessor;
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
        }
    }

    // noinspection JSMethodCanBeStatic
    private sortAccessor(data: UASPole, columnName: string): string | number {
        switch (columnName) {
            case 'flightDate':
                return data.flightDate.valueOf();
            case 'damaged':
                return data.damaged ? 1 : -1;
            case 'confidence':
                return data.confidence;
            case 'detections':
                return data.detections;
        }
    }

    destroyWidget() {}

    public linkToReport(): void {
        // noinspection JSIgnoredPromiseFromCall
        this.router.navigate(['app/reports/pole-detection']);
    }
}
