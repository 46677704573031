import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';
import {Directive} from '@angular/core';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[minMax]',
    providers: [{provide: NG_VALIDATORS, useExisting: MinMaxValidatorDirective, multi: true}],
})
export class MinMaxValidatorDirective implements Validator {
    validate(control: AbstractControl): ValidationErrors {
        return minMaxValidator(control);
    }
}

export function minMaxValidator(control: AbstractControl): ValidationErrors | null {
    const minControl = control.get('minValue');
    const maxControl = control.get('maxValue');

    let hasError = false;
    if (minControl && maxControl) {
        // if we don't have them, we don't validate
        if (minControl.pristine || maxControl.pristine) {
            // if both havent been set, you cant compare
            hasError = false;
        } else if (minControl.value === null || maxControl.value === null) {
            // if the user cleared out the input
            hasError = false;
        } else if (maxControl.value < minControl.value) {
            // only compare when both are set
            hasError = true;
        }
    }

    return hasError ? {MinMaxError: true} : null;
}
