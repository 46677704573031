import * as moment from 'moment';
import {SubportStatus} from './subports-status';

export class PortStatus {
    public id: number;
    public name: string;
    public department: string;
    public address: string;
    public city: string;
    public state: string;
    public zip: string;
    public atu: string;
    public active: boolean;
    public phoneNumber: string;
    public emergencyPhone: string;
    public url: string;
    public ports: SubportStatus[];
    public geom: string;
    public status?: number[];

    constructor(input?: PortStatus | number) {
        if (typeof input === 'number') {
            this.id = input;
        }

        if (input && typeof input !== 'number') {
            this.id = input.id;

            for (const port of input.ports) {
                if (port.lastModDate && typeof port.lastModDate === 'string') {
                    port.lastModDate = moment(port.lastModDate);
                }
            }

            this.id = input.id;
            this.name = input.name;
            this.department = input.department;
            this.address = input.address;
            this.city = input.city;
            this.state = input.state;
            this.zip = input.zip;
            this.atu = input.atu;
            this.active = input.active;
            this.phoneNumber = input.phoneNumber;
            this.emergencyPhone = input.emergencyPhone;
            this.url = input.url;
            this.ports = input.ports;
            this.geom = input.geom;
            this.status = input.status;
        }
    }
}
