import {GenRRCCContact} from '../../../generated/serverModels/GenRRCCContact';

export class RRCCContact extends GenRRCCContact {
    public tmpValue: string;

    constructor(contact?: RRCCContact) {
        super(contact);

        if (contact) {
            this.tmpValue = contact.information;
        }
    }
}
