import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {ResizeService} from '../../services/resize.service';
import {ApplicationConfig} from '../../../app/classes/application-config';
import {filter} from 'rxjs/operators';

@Component({
    selector: 'eaglei-resize',
    templateUrl: './resize.component.html',
    styleUrls: ['./resize.component.scss'],
})
export class ResizeComponent implements OnInit, AfterViewInit, OnDestroy {
    private previousElement: HTMLElement;
    private nextElement: HTMLElement;
    private overlayElement: HTMLElement;
    private initialMousePosition: [number, number];

    private _isActive: boolean;
    get isActive(): boolean {
        return this._isActive;
    }

    private initialSize: number;

    constructor(private ele: ElementRef, private resizeService: ResizeService) {
        this.resizeService.closeResizeElement.subscribe(() => this.closeResize());
    }

    ngOnInit() {
        (this.ele.nativeElement as HTMLElement).addEventListener('mousedown', this.mouseDown.bind(this));
        this.overlayElement = ApplicationConfig.overlayElement;

        this.overlayElement.style.position = 'absolute';
        this.overlayElement.style.top = '0';
        this.overlayElement.style.bottom = '0';
        this.overlayElement.style.left = '0';
        this.overlayElement.style.right = '0';
        this.overlayElement.style.zIndex = '1000';
        this.overlayElement.style.pointerEvents = 'none';
        document.body.appendChild(this.overlayElement);
    }

    ngAfterViewInit() {
        this.previousElement = this.ele.nativeElement.previousElementSibling;
        this.nextElement = this.ele.nativeElement.nextElementSibling;
        ApplicationConfig.resizeEvent.pipe(filter(() => this.initialSize !== undefined && this.isActive)).subscribe(() => {
            this.closeResize();
            this.setInitialSize(this.initialSize);
            this.resizeService.resizeEvent.next(true);
        });
    }

    ngOnDestroy() {
        // document.body.removeChild(this.overlayElement);
    }

    mouseDown(event: MouseEvent) {
        const me = this;

        me.overlayElement.style.pointerEvents = 'all';
        me.initialMousePosition = [event.pageX, event.pageY];

        const startingPoint = me.ele.nativeElement.offsetTop;

        let lastPosition: number;

        function mouseMove(e: MouseEvent) {
            const difference = me.initialMousePosition[1] - e.pageY;
            let newPosition = startingPoint - difference;

            const toLowCheck = me.nextElement.getBoundingClientRect().height === 0 && e.offsetY < lastPosition;

            if (newPosition <= 0) {
                newPosition = 0;
            } else if (toLowCheck) {
                newPosition = me.previousElement.getBoundingClientRect().height;
            }

            me.ele.nativeElement.style.top = `${newPosition}px`;

            const h =
                me.nextElement.getBoundingClientRect().bottom -
                (me.ele.nativeElement.getBoundingClientRect().height + me.ele.nativeElement.getBoundingClientRect().top);
            me.nextElement.style.height = `${h}px`;

            me.previousElement.style.bottom = `${
                me.nextElement.getBoundingClientRect().height + me.ele.nativeElement.getBoundingClientRect().height
            }px`;

            me.resizeService.resizeEvent.next(true);

            lastPosition = e.offsetY;
        }

        function mouseUp() {
            me.overlayElement.style.pointerEvents = 'none';
            ApplicationConfig.overlayElement.removeEventListener('mousemove', mouseMove);
        }

        ApplicationConfig.overlayElement.addEventListener('mousemove', mouseMove);
        ApplicationConfig.overlayElement.addEventListener('mouseup', mouseUp);
    }

    public setInitialSize(pixel: number) {
        this.initialSize = pixel;
        this.ele.nativeElement.style.height = '20px';
        this.ele.nativeElement.style.bottom = `${pixel}px`;
        this.previousElement.style.bottom = `${pixel + this.ele.nativeElement.getBoundingClientRect().height}px`;
        this.nextElement.style.height = `${pixel}px`;
        this._isActive = true;
        this.resizeService.resizeEvent.next(true);
        this.resizeService.resizeElementOpen.next(true);
    }

    public closeResize() {
        this.ele.nativeElement.style.top = '';
        this.ele.nativeElement.style.height = '0';
        this.ele.nativeElement.style.bottom = `0`;
        this.previousElement.style.bottom = `0`;
        this.nextElement.style.height = `0`;
        this._isActive = false;
        this.resizeService.resizeEvent.next(true);
        this.resizeService.resizeElementOpen.next(false);
    }

    close(event: MouseEvent) {
        event.stopPropagation();
        this.closeResize();
    }
}
