import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LayerManagementComponent} from './components/layer-management/layer-management.component';
import {SharedModule} from '../../../shared/shared.module';
import {LayerRoutingModule} from './layer-routing.module';
import {BaseLayerComponent} from './layers/base-layer/base-layer.component';
import {DoeFacilityLayerComponent} from './layers/doe-facility-layer/doe-facility-layer.component';
import {GasPriceLayerComponent} from './layers/gas-price-layer/gas-price-layer.component';
import {NewsfeedLayerComponent} from './layers/newsfeed-layer/newsfeed-layer.component';
import {PetroleumFlowLayerComponent} from './layers/petroleum-flow-layer/petroleum-flow-layer.component';
import {EarthquakeLayerComponent} from './layers/earthquake-layer/earthquake-layer.component';
import {WildfirePerimeterLayerComponent} from './layers/wildfire-perimeter-layer/wildfire-perimeter-layer.component';
import {HeadoutLayerComponent} from './layers/headout-layer/headout-layer.component';
import {EilLayerComponent} from './layers/eil-layer/eil-layer.component';
import {TreasuriesLayerComponent} from './layers/treasuries-layer/treasuries-layer.component';
import {LayerListComponent} from './components/layer-list/layer-list.component';
import {LeafletArcgisLayerComponent} from './leaflet-layers/leaflet-arcgis-layer/leaflet-arcgis-layer.component';
import {LeafletLayerHeaderComponent} from './leaflet-layers/leaflet-layer-header/leaflet-layer-header.component';
import {LeafletWmsLayerComponent} from './leaflet-layers/leaflet-wms-layer/leaflet-wms-layer.component';
import {LegendListComponent} from './components/legend-list/legend-list.component';
import {LegendComponent} from './components/legend/legend.component';
import {ToolListComponent} from './components/tool-list/tool-list.component';
import {SelectFilterComponent} from './filters/select-filter/select-filter.component';
import {LeafletNomLayerComponent} from './layers/leaflet-nom-layer/leaflet-nom-layer.component';
import {FilterContainerComponent} from './leaflet-layers/filter-container/filter-container.component';
import {MinMaxValidatorDirective} from './filters/numeric-range-filter/min-max.validator';
import {NumericRangeFilterComponent} from './filters/numeric-range-filter/numeric-range-filter.component';
import {TextSearchFilterComponent} from './filters/text-search-filter/text-search-filter.component';
import {HurricaneConeSearchComponent} from './components/hurricane-cone-search/hurricane-cone-search.component';
import {SocialMediaLayerComponent} from './layers/social-media-layer/social-media-layer.component';
import {OngRefineriesComponent} from './layers/ong-refineries/ong-refineries.component';
import {NgPipelineNoticeLayerComponent} from './layers/ng-pipeline-notice-layer/ng-pipeline-notice-layer.component';
import {OngRefineryPopoverComponent} from './popovers/ong-refinery-popover/ong-refinery-popover.component';
import {RrccLayerComponent} from './layers/rrcc-layer/rrcc-layer.component';
import {RrccPopoverComponent} from './popovers/rrcc-popover/rrcc-popover.component';
import {SocialMediaPopoverComponent} from './popovers/social-media-popover/social-media-popover.component';
import {OngNominationsComponent} from './layers/ong-nominations/ong-nominations.component';
import {OngNominationsPopoverComponent} from './popovers/ong-nominations-popover/ong-nominations-popover.component';
import {OngOilOutageLayerComponent} from './layers/ong-oil-outage-layer/ong-oil-outage-layer.component';
import {OngOilOutagePopoverComponent} from './popovers/ong-oil-outage-popover/ong-oil-outage-popover.component';
import {OngPipelinePopoverComponent} from './popovers/ong-pipeline-popover/ong-pipeline-popover.component';
import {OngGasBurnComponent} from './layers/ong-gas-burn/ong-gas-burn.component';
import {OngGasBurnPopoverComponent} from './popovers/ong-gas-burn-popover/ong-gas-burn-popover.component';
import {PortStatusLayerComponent} from './layers/port-status-layer/port-status-layer.component';
import {PortStatusPopoverComponent} from './popovers/port-status-popover/port-status-popover.component';
import {OilBarrelFlowComponent} from './layers/oil-barrel-flow/oil-barrel-flow.component';
import {OilBarrelFlowPopoverComponent} from './popovers/oil-barrel-flow-popover/oil-barrel-flow-popover.component';
import {EmPowerLayerComponent} from './layers/emPowerLayer/emPowerLayer.component';
import {ActiveFiresLayerComponent} from './layers/active-fires-layer/active-fires-layer.component';
import {WindVelocityLayerComponent} from './layers/wind-velocity-layer/wind-velocity-layer.component';
import {QuantitativePrecipLayerComponent} from './layers/quantitative-precip-layer/quantitative-precip-layer.component';
import {FaultLinesLayerComponent} from './layers/fault-lines-layer/fault-lines-layer.component';
import {WastewaterTreatmentLayerComponent} from './layers/wastewater-treatment-layer/wastewater-treatment-layer.component';
import {RiverFloodingLayerComponent} from './layers/river-flooding-layer/river-flooding-layer.component';
import {PortsLayerComponent} from './layers/ports-layer/ports-layer.component';
import {PoleDetectionLayerComponent} from './layers/pole-detection-layer/pole-detection-layer.component';
import {WaoLightsLayerComponent} from './layers/wao-lights-layer/wao-lights-layer.component';
import {PnnlRadrLayerComponent} from './layers/pnnl-radr-layer/pnnl-radr-layer.component';
import {AddressLookupInputComponent} from './components/address-lookup-input/address-lookup-input.component';
import {LeafletVectortileLayerComponent} from './leaflet-layers/leaflet-vectortile-layer/leaflet-vectortile-layer.component';
import {Survey123LayerComponent} from './layers/survery123-layer/survey123-layer.component';

@NgModule({
    declarations: [
        LayerManagementComponent,
        BaseLayerComponent,
        DoeFacilityLayerComponent,
        TreasuriesLayerComponent,
        GasPriceLayerComponent,
        NewsfeedLayerComponent,
        PetroleumFlowLayerComponent,
        EarthquakeLayerComponent,
        WildfirePerimeterLayerComponent,
        HeadoutLayerComponent,
        EilLayerComponent,
        LayerListComponent,
        LeafletArcgisLayerComponent,
        LeafletLayerHeaderComponent,
        LeafletWmsLayerComponent,
        LegendListComponent,
        LegendComponent,
        ToolListComponent,
        SelectFilterComponent,
        LeafletNomLayerComponent,
        DoeFacilityLayerComponent,
        FilterContainerComponent,
        MinMaxValidatorDirective,
        NumericRangeFilterComponent,
        TextSearchFilterComponent,
        HurricaneConeSearchComponent,
        SocialMediaLayerComponent,
        HurricaneConeSearchComponent,
        OngRefineriesComponent,
        NgPipelineNoticeLayerComponent,
        OngRefineryPopoverComponent,
        RrccLayerComponent,
        RrccPopoverComponent,
        SocialMediaPopoverComponent,
        OngNominationsComponent,
        OngNominationsPopoverComponent,
        OngOilOutageLayerComponent,
        OngOilOutagePopoverComponent,
        OngPipelinePopoverComponent,
        OngGasBurnComponent,
        OngGasBurnPopoverComponent,
        PortStatusLayerComponent,
        PortStatusPopoverComponent,
        OilBarrelFlowComponent,
        OilBarrelFlowPopoverComponent,
        EmPowerLayerComponent,
        ActiveFiresLayerComponent,
        WindVelocityLayerComponent,
        QuantitativePrecipLayerComponent,
        FaultLinesLayerComponent,
        WastewaterTreatmentLayerComponent,
        RiverFloodingLayerComponent,
        PortsLayerComponent,
        PoleDetectionLayerComponent,
        WaoLightsLayerComponent,
        PnnlRadrLayerComponent,
        AddressLookupInputComponent,
        LeafletVectortileLayerComponent,
        Survey123LayerComponent,
    ],
    imports: [CommonModule, SharedModule, LayerRoutingModule],
    exports: [
        LeafletArcgisLayerComponent,
        LayerListComponent,
        LegendListComponent,
        ToolListComponent,
        OngRefineryPopoverComponent,
        RrccPopoverComponent,
        SocialMediaPopoverComponent,
        OngNominationsPopoverComponent,
        OngPipelinePopoverComponent,
        OngOilOutagePopoverComponent,
        OngGasBurnPopoverComponent,
        PortStatusPopoverComponent,
        OilBarrelFlowPopoverComponent,
        LegendComponent,
        MinMaxValidatorDirective,
        AddressLookupInputComponent,
        LeafletLayerHeaderComponent,
    ],
})
export class LayerModule {}
