import {LayerStyleService} from '../services/layer-style.service';
import {LeafletVectorSource} from '../classes/leaflet-vector-source';
import {LeafletMapLayer} from '../classes/leaflet-map-layer';
import {LeafletFeature} from '../classes/leaflet-feature';
import * as L from 'leaflet';
import {FeatureStyle} from '../interfaces/style.interface';

export class WildfirePerimeterSource extends LeafletVectorSource {
    constructor(layerInfo: LeafletMapLayer) {
        super(layerInfo);
    }

    processFeatures(features: any[]): void {
        const leafletFeatures = features.map((v) => {
            const feature = new LeafletFeature().convert(v, 'geometry', false);
            feature.properties = v.properties;
            feature.buildPopover(this.layerInfo);
            return feature;
        });

        const config = {
            style: this.getFeatureStyle(),
            onEachFeature: this.initializePopoverInteractions.bind(this),
            features: leafletFeatures,
        };

        this.source = L.geoJSON(leafletFeatures as any, config as any);
    }

    private getFeatureStyle(): FeatureStyle {
        const styleKey = `wildfire-perimeter`;
        let style: FeatureStyle = LayerStyleService.layerStyles.get(styleKey);

        if (!style) {
            style = {
                color: '#5B9DC2',
                opacity: 1,
                weight: 1,
                fillColor: `#5B9DC2`,
                fillOpacity: 0.9,
            };

            LayerStyleService.layerStyles.set(styleKey, style);
        }
        return style;
    }
}
