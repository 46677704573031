export class ResponseWrapper<T = any> {
    public metadata: any;
    public data: T[];
    public resolution: string;
    public totalNumRows: number;

    constructor(wrapper: ResponseWrapper<T>) {
        this.metadata = wrapper.metadata;
        this.data = wrapper.data;
        this.resolution = wrapper.resolution.toString().toLowerCase();
        this.totalNumRows = wrapper.totalNumRows;
    }
}
