
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import { GenWidgetDetail } from './GenWidgetDetail';

export class GenLayout {

    id: number;
    defaultLayout: boolean;
    details: GenWidgetDetail[];
    name: string;
    typeId: number;
    userId: number;

    constructor();
    constructor( jsonObj: any );
    constructor( genLayout: GenLayout);
    constructor( id: number );

    constructor( input?: GenLayout|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            this.defaultLayout = input.defaultLayout; // boolean [boolean]
            if ( input.details && input.details.map ) { // list [GenWidgetDetail]
                this.details = input.details.map( ( x: GenWidgetDetail ) => {
                    return new GenWidgetDetail( x );
                } );
            } else {
                this.details = input.details;
            }
            this.name = input.name; // string [string]
            this.typeId = input.typeId; // int [number]
            this.userId = input.userId; // int [number]
       }
    }

    static jsonResponseToList(arr: any[]): GenLayout[] {
        if (arr && arr.map) {
          return arr.map(a => new GenLayout(a));
        } else {
          return [];
        }
    }
}

