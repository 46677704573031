<div class="filters filter-bar">
    <mat-form-field class="filter-select">
        <mat-label>Alert Status</mat-label>
        <mat-select [(ngModel)]="activeAlerts" (selectionChange)="filterActive()">
            <mat-option value="all">All Alerts</mat-option>
            <mat-option value="active">Active</mat-option>
            <mat-option value="inactive">Inactive</mat-option>
        </mat-select>
    </mat-form-field>

    <div>
        <eaglei-date-range [start]="startDate" [end]="endDate" (change)="changeDateRange($event)"> </eaglei-date-range>
        <span class="hint-text">
            <mat-icon fontSet="fas" inline fontIcon="fa-info-circle"></mat-icon> Active events will always be displayed
        </span>
    </div>

    <button mat-stroked-button matTooltip="Export" matTooltipPosition="above" matTooltipShowDelay="250" [matMenuTriggerFor]="menu">
        <mat-icon fontSet="fas" fontIcon="fa-file-export" inline></mat-icon>
    </button>
</div>

<mat-card>
    <mat-card-content>
        <div class="table-container">
            <table
                mat-table
                [dataSource]="dataSource"
                matSort
                [matSortDisableClear]="true"
                matSortActive="alertStart"
                matSortDirection="desc">
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>Status</th>
                    <td mat-cell *matCellDef="let alert">{{ getActive(alert) }}</td>
                </ng-container>

                <ng-container matColumnDef="alertName">
                    <th mat-header-cell *matHeaderCellDef>Name</th>
                    <td mat-cell *matCellDef="let alert">
                        <div class="ellipsis" [matTooltip]="alert.alertName" matTooltipPosition="above" matTooltipShowDelay="250">
                            {{ alert.alertName }}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="alertType">
                    <th mat-header-cell *matHeaderCellDef>Alert Type</th>
                    <td mat-cell *matCellDef="let alert">{{ getAlertType(alert) }}</td>
                </ng-container>

                <ng-container matColumnDef="hoursActive">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Hours Active
                        <eaglei-table-sort-header [columnName]="'hoursActive'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let alert">{{ alert.hoursActive | eagleiNumber }}</td>
                </ng-container>

                <ng-container matColumnDef="customersOut">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Threshold
                        <eaglei-table-sort-header [columnName]="'customersOut'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let alert">{{ alert.customersOut | eagleiNumber }}</td>
                </ng-container>

                <ng-container matColumnDef="alertStart">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Alert Start
                        <eaglei-table-sort-header [columnName]="'alertStart'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let alert">{{ alert.alertStart | momentDate }}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columns"></tr>
                <tr mat-row *matRowDef="let row; columns: columns; let odd = odd"></tr>
            </table>
        </div>

        <div>
            <div *ngIf="dataSource && !(dataSource.filteredData.length > 0)" class="table-mask">
                <b>No Data Matching the Filters</b>
            </div>
            <mat-paginator [pageSizeOptions]="[25, 50, 75, 100]" showFirstLastButtons></mat-paginator>
        </div>

        <div class="table-attribution">
            <a (click)="reportService.openAttribution(attributionUrl)" [innerHTML]="attributionTitle | sanitize"></a>
        </div>
    </mat-card-content>
</mat-card>

<!--Export Menu-->
<mat-menu #menu="matMenu" overlapTrigger>
    <button (click)="exportTable()" mat-menu-item>Export Table As CSV</button>
</mat-menu>
