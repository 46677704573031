
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!


export class GenAlertSubscription {

    id: number;
    description: string;
    name: string;
    ownerId: number;
    rollUp: boolean;
    threshold: number;
    type: string;
    userEmail: string;
    userName: string;

    constructor();
    constructor( jsonObj: any );
    constructor( genAlertSubscription: GenAlertSubscription);
    constructor( id: number );

    constructor( input?: GenAlertSubscription|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            this.description = input.description; // string [string]
            this.name = input.name; // string [string]
            this.ownerId = input.ownerId; // int [number]
            this.rollUp = input.rollUp; // boolean [boolean]
            this.threshold = input.threshold; // int [number]
            this.type = input.type; // string [string]
            this.userEmail = input.userEmail; // string [string]
            this.userName = input.userName; // string [string]
       }
    }

    static jsonResponseToList(arr: any[]): GenAlertSubscription[] {
        if (arr && arr.map) {
          return arr.map(a => new GenAlertSubscription(a));
        } else {
          return [];
        }
    }
}

