
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!


export class GenSystemNewsType {

    id: number;
    iconColor: string;
    name: string;

    constructor();
    constructor( jsonObj: any );
    constructor( genSystemNewsType: GenSystemNewsType);
    constructor( id: number );

    constructor( input?: GenSystemNewsType|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            this.iconColor = input.iconColor; // string [string]
            this.name = input.name; // string [string]
       }
    }

    static jsonResponseToList(arr: any[]): GenSystemNewsType[] {
        if (arr && arr.map) {
          return arr.map(a => new GenSystemNewsType(a));
        } else {
          return [];
        }
    }
}

