import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {BaseWidget} from '../../classes/base-widget';
import {MatSort} from '@angular/material/sort';
import {WaoService} from 'frontend/src/app/services/wao.service';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import * as moment from 'moment';
import {Router} from '@angular/router';
import {WaoStructure} from '../../../report/classes/wao-structure';
import {FormControl} from '@angular/forms';
import {filter, takeUntil} from 'rxjs/operators';
import {EventSummaryFilters} from '../../../system-event/classes/event-summary-filters';
import {Subject} from 'rxjs';
import {SystemEvent} from '../../../system-event/classes/system-event';

@Component({
    selector: 'eaglei-wao-widget',
    templateUrl: './wao-widget.component.html',
    styleUrls: ['./wao-widget.component.scss'],
})
export class WaoWidgetComponent extends BaseWidget implements AfterViewInit {
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    public readonly columnNames: string[] = ['state', 'county', 'structure', 'light'];
    public dataSource: MatTableDataSource<WaoStructure>;

    private filters: EventSummaryFilters = new EventSummaryFilters();
    private dataStream: Subject<SystemEvent> = new Subject<SystemEvent>();

    constructor(private widgetElement: ElementRef, private waoService: WaoService, private router: Router) {
        super(widgetElement);
    }

    public ngAfterViewInit(): void {
        this.dataStream
            .pipe(
                filter((value) => !!value),
                takeUntil(this.destroy$)
            )
            .subscribe((value) => {
                this.getTableData();
            });
    }

    destroyWidget(): void {}

    protected handleEventFilterChange(eventFilters: EventSummaryFilters) {
        this.filters = eventFilters;
        this.dataStream.next(this.filters.event);
    }

    private getTableData(): void {
        this.waoService.getWAOStructures(moment(this.filters.date)).subscribe((res) => {
            this.initData(res);
        });
    }

    private initData(data: WaoStructure[]): void {
        if (this.dataSource) {
            this.dataSource.data = data;
        } else {
            this.dataSource = new MatTableDataSource<WaoStructure>(data);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sortingDataAccessor = this.dataAccessor.bind(this);
            this.dataSource.sort = this.sort;
        }
    }

    private dataAccessor(data: WaoStructure, header: string): string | number {
        switch (header) {
            case 'state':
                return data.state.name.toLowerCase();
            case 'county':
                return data.countyName.toLowerCase();
            case 'structure':
                return data.structureCount;
            case 'light':
                return data.lightStatus;
            default:
                return '';
        }
    }

    public getLightStatus(status: number): string {
        switch (status) {
            case -1:
                return 'No Lights';
            case 1:
                return 'Increased Light';
            case 3:
                return 'Clouds';
            default:
                return '';
        }
    }

    public linkToReport(): void {
        // noinspection JSIgnoredPromiseFromCall
        this.router.navigate(['app/reports/wao']);
    }
}
