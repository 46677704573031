import {Component, OnInit} from '@angular/core';
import {MatButtonToggleChange} from '@angular/material/button-toggle';
import {GenLegendType} from 'frontend/generated/serverModels/GenLegendType';
import {HttpInterceptorService} from 'frontend/src/app/services/http-interceptor.service';
import {EagleILayer} from '../../classes/eagle-i-layer';
import {LayerService} from '../../services/layer.service';
import {LeafletLegendService} from '../../services/leaflet-legend.service';
import {WindVelocitySource} from '../../sources/wind-velocity-source';

@Component({
    selector: 'eaglei-wind-velocity-layer',
    templateUrl: './wind-velocity-layer.component.html',
    styleUrls: ['./wind-velocity-layer.component.scss'],
})
export class WindVelocityLayerComponent extends EagleILayer<WindVelocitySource> {
    public windData: 'speed' | 'gust' = 'speed';
    public showDirection: boolean = false;

    constructor(private legendService: LeafletLegendService) {
        super();
    }

    afterViewInit(): void {}

    public toggleLayer(activeState: boolean): void {
        this.isActive = activeState;
        if (activeState) {
            if (!this.layerSource) {
                this.layerSource = new WindVelocitySource(this.mapLayer);

                // The legend uses the same colors for both Speed and Gust
                this.legendService.getLegend(this).subscribe((legend) => (this.legend = legend));
            }

            this.getLayerData();
        } else {
            this.layerSource.removeFromMap();
        }
    }

    private getLayerData(): void {
        // This layer is listed as Vector for functionality but is running as an ARCGis Layer
        // IDs are set based on filters. 47 is the id for wind speed and 51 for wind gust. 3 is for the direction arrows
        let ids: number[];
        this.windData === 'speed' ? (ids = [47]) : (ids = [51]);

        if (this.showDirection) {
            ids.push(3);
        }

        this.layerSource.fetchImage(ids);
        this.layerSource.addToMap();
    }

    public updateData(event: 'speed' | 'gust'): void {
        this.windData = event;

        if (!!this.layerSource) {
            this.getLayerData();
        }
    }
}
