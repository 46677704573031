import {GenNewsfeed} from '../../../../../generated/serverModels/GenNewsfeed';

export class Newsfeed extends GenNewsfeed {
    public formattedKeywords: string;

    constructor(newsfeed?: Newsfeed) {
        super(newsfeed);

        if (newsfeed) {
            this.formattedKeywords = newsfeed.keywords.map((kw) => kw.keyword).join();
        }
    }
}
