import {Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';
import {MatAutocomplete, MatAutocompleteActivatedEvent, MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {UntypedFormControl} from '@angular/forms';
import {map, startWith} from 'rxjs/operators';

@Component({
    selector: 'eaglei-autocomplete',
    templateUrl: './autocomplete.component.html',
    styleUrls: ['./autocomplete.component.scss'],
})
export class AutocompleteComponent implements OnInit, OnChanges {
    @ViewChild('filter', {read: MatAutocompleteTrigger}) filter: MatAutocompleteTrigger;
    @ViewChild(MatAutocomplete) autocomplete: MatAutocomplete;

    // Properties
    public control: UntypedFormControl = new UntypedFormControl();
    public filteredValues: Observable<string[]>;
    public selectedElements: any[] = [];

    // Outputs
    @Output() valueChange: EventEmitter<any[]> = new EventEmitter<any[]>();

    // Inputs
    @Input() helpHint: string;
    @Input() values: any[];
    @Input() placeholder: string;
    @Input() disabled: boolean;
    @Input() hideChips: boolean;
    @Input() addFunction: (val: string) => any;
    @Input() filterFunction: (val: any, searchString: string) => boolean;
    @Input() displayFunction: (val: any) => any = (val) => val;

    constructor() {}

    ngOnInit() {
        this.filteredValues = this.control.valueChanges.pipe(
            startWith(undefined),
            map((val: any) => (typeof val === 'string' ? this.values.filter((entry) => this.filterFunction(entry, val)) : this.values))
        );
    }

    ngOnChanges() {
        this.disabled ? this.control.disable() : this.control.enable();
    }

    public addToList(): void {
        if (this.addFunction && typeof this.control.value === 'string' && this.control.value.trim().length > 0) {
            let retIndex;
            const index = this.values.findIndex((val) => this.displayFunction(val) === this.control.value);

            if (index === -1) {
                retIndex = this.values.push(this.addFunction(this.control.value));
                this.selected(this.values[retIndex - 1]);

                this.filter.closePanel();
            }
        }
    }

    public selected(event: MatAutocompleteActivatedEvent): void {
        const element = event.option.value;
        const index = this.selectedElements.indexOf(element);
        if (index === -1) {
            this.selectedElements.push(element);
        }

        this.valueChange.emit(this.selectedElements);
        this.control.setValue('');
        (this.filter as any)._element.nativeElement.blur();
    }

    public removeChip(element: any): void {
        const index = this.selectedElements.indexOf(element);
        this.selectedElements.splice(index, 1);
        this.valueChange.emit(this.selectedElements);
    }
}
