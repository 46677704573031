import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {UASPole} from './classes/pole';
import {HttpClient, HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class PoleDetectionService {
    private http: HttpClient = inject(HttpClient);
    private readonly baseUrl = 'api/pole-detection';

    constructor() {}

    public getPoles(eventId?: number): Observable<UASPole[]> {
        const url = this.baseUrl;

        let params = new HttpParams();

        if (eventId) {
            params = params.set('eventId', eventId);
        }

        return this.http.get<any[]>(url, {params}).pipe(map((poles) => poles.map((pole) => new UASPole(pole))));
    }
}
