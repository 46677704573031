import {ChangeDetectionStrategy, Component, inject, TrackByFunction} from '@angular/core';
import {BaseModal} from '../../../app/classes/base-modal';
import {MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CardFilters} from '../../classes/card-filters';
import {State} from 'frontend/src/app/modules/outage/classes/state';
import {ApplicationConfig} from '../../../app/classes/application-config';
import {EmailService} from '../../../app/services/email.service';
import {CsvDownloadInfo} from '../../../app/modules/outage/classes/csv-download-info';
import {BehaviorSubject} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DataService} from '../../../app/services/data.service';
import * as moment from 'moment';
import {map, takeUntil} from 'rxjs/operators';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {SystemEvent} from 'frontend/src/app/modules/system-event/classes/system-event';
import {SystemEventService} from 'frontend/src/app/modules/system-event/services/system-event.service';
import {GroupedSystemEvents} from 'frontend/src/app/modules/system-event/classes/grouped-system-events';
import {GenOutageAggregationLevel} from 'frontend/generated/serverModels/GenOutageAggregationLevel';

interface County {
    fipsCode: string;
    name: string;
    stateId: number;
    countyId: number;
    selected: boolean;
}

@Component({
    selector: 'eaglei-archive-request-modal',
    templateUrl: './archive-request-modal.component.html',
    styleUrls: ['./archive-request-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'outline'}}],
})
export class ArchiveRequestModalComponent extends BaseModal {
    private emailService = inject(EmailService);
    private snackbar = inject(MatSnackBar);
    private eventService = inject(SystemEventService);

    public optionGroup: FormGroup;
    public states$ = new BehaviorSubject<State[]>([]);
    public countyByState = new Map<number, County[]>();
    public counties = new Map<State, any[]>();

    public readonly minDate = moment('2014-11-20').startOf('day');

    public aggregationLevels = GenOutageAggregationLevel.values().filter((level) => level !== GenOutageAggregationLevel.fema);
    public showCountySelector: boolean = false;

    public events$ = this.eventService.getGroupedEvents().pipe(map(this.onlyEventsBefore(moment().subtract(1, 'month'))));
    public systemEvent = new FormControl<SystemEvent | undefined>(undefined);

    constructor(public ref: MatDialogRef<ArchiveRequestModalComponent>) {
        super();
        this.initializeControls();
    }

    afterInit() {}

    private initializeControls(): void {
        ApplicationConfig.geometries
            .get(GenOutageAggregationLevel.county)
            .sort((a, b) => {
                if (a.name > b.name) return 1;
                else if (a.name < b.name) return -1;
                return 0;
            })
            .forEach((county) => {
                const value = this.countyByState.get(county.stateId) || [];
                const minimalCounty = {
                    fipsCode: county.fipsCode,
                    name: county.name.split(DataService.geometryNameSeparator)[1],
                    stateId: county.stateId,
                    countyId: county.countyId,
                    selected: false,
                };
                value.push(minimalCounty);
                this.countyByState.set(county.stateId, value);
            });

        const controls = {
            dates: new FormControl<CardFilters>(
                new CardFilters()
                    .setStartDate(ApplicationConfig.roundMinute().subtract(1, 'month').subtract(1, 'week').startOf('day'))
                    .setEndDate(ApplicationConfig.roundMinute().subtract(1, 'month').startOf('day')),
                {validators: [Validators.required]}
            ),
            aggregation: new FormControl<GenOutageAggregationLevel>(null, {validators: [Validators.required]}),
            locations: new FormControl<State[]>(null, {validators: [Validators.required]}),
            reason: new FormControl<string>(null, {validators: [Validators.required]}),
            hourInterval: new FormControl<number>(0, {validators: [Validators.required]}),
        };

        controls.aggregation.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
            const stateOnlySelections = [GenOutageAggregationLevel.state, GenOutageAggregationLevel.zip, GenOutageAggregationLevel.utility];
            this.showCountySelector = !stateOnlySelections.includes(value);
            if (this.showCountySelector && this.systemEvent.value) {
                this.updateCountySelections(this.systemEvent.value);
            }
        });

        this.systemEvent.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => this.selectEvent(value));

        this.optionGroup = new FormGroup(controls);
    }

    public selectEvent(event?: SystemEvent) {
        const isSystemEvent = (obj: unknown): obj is SystemEvent => {
            return event && typeof obj === 'object' && 'states' in obj;
        };

        if (isSystemEvent(event)) {
            const dates: CardFilters = {endDate: event.eventEnd, startDate: event.eventStart} as any;
            this.updateDates(dates);
            this.states$.next(event.states);
            this.updateLocations(event.states);
            if (this.showCountySelector) {
                this.updateCountySelections(event);
            }
        }
    }

    public updateDates(event: CardFilters): void {
        this.optionGroup.patchValue({
            dates: event,
        });
    }

    public updateLocations(event: State[]): void {
        this.optionGroup.patchValue({
            locations: event,
        });
    }

    public sendRequest(): void {
        // do location stuff here
        let fipsCodes: string[] = [];
        const states: State[] = this.optionGroup.controls.locations.value;

        if (this.showCountySelector) {
            const selectedStateId = states.map((state) => state.id);
            Array.from(this.countyByState.keys())
                .filter((key) => selectedStateId.includes(key))
                .forEach((key) => {
                    const countyFips = this.countyByState
                        .get(key)
                        .filter((county) => county.selected)
                        .map((county) => county.fipsCode);

                    fipsCodes.push(...countyFips);
                });
        } else {
            fipsCodes = (this.optionGroup.controls.locations.value as State[]).map((state) => state.statefp);
        }

        const formattedData: any = {
            startDate: this.optionGroup.controls.dates.value.startDate.format(),
            endDate: this.optionGroup.controls.dates.value.endDate.format(),
            aggregationLevel: this.optionGroup.controls.aggregation.value,
            hourInterval: this.optionGroup.controls.hourInterval.value,
            reason: this.optionGroup.controls.reason.value,
            fipsCodes,
        };

        const requestData = new CsvDownloadInfo(formattedData);

        const responseHandlers = {
            next: () => {
                this.snackbar.open('Your request has been sent', 'x', {panelClass: 'dialog-success', duration: 3_000});
                this.ref.close();
            },
            error: (error: any) => {
                console.error(error);
                this.snackbar.open('Failed to send your request', 'x', {panelClass: 'dialog-failure', duration: 3_000});
            },
        };

        this.emailService.requestArchiveData(requestData).subscribe(responseHandlers);
    }

    public selectAllCounties(stateId: number) {
        this.countyByState.get(stateId).forEach((county) => (county.selected = true));
    }

    public clearAllCounties(stateId: number) {
        this.countyByState.get(stateId).forEach((county) => (county.selected = false));
    }

    public _trackEventId: TrackByFunction<SystemEvent> = (index, event) => event.id;

    /** Returns a mapper function that removes system events more recent than the specified date. */
    private onlyEventsBefore(maxDate: moment.Moment): (events: GroupedSystemEvents) => Partial<GroupedSystemEvents> | undefined {
        return (events) => {
            const oldEvents = {};
            for (const key in events) {
                if (Object.prototype.hasOwnProperty.call(events, key)) {
                    const arr = events[key];
                    if (Array.isArray(arr)) {
                        const oldEnough = arr.filter((event: SystemEvent) => moment(event.eventEnd).isSameOrBefore(maxDate, 'day'));
                        if (oldEnough.length) {
                            oldEvents[key] = oldEnough;
                        }
                    }
                }
            }

            return Object.keys(oldEvents).length ? oldEvents : undefined;
        };
    }

    private updateCountySelections(event: SystemEvent) {
        event.states.forEach((state) => {
            const counties = this.countyByState.get(state.id).map((county) => {
                const selected = !!event.counties.find((c) => c.id === county.countyId);
                return {...county, selected};
            });
            this.countyByState.set(state.id, counties);
        });
    }
}
