import {Component, ElementRef, Input} from '@angular/core';
import {State} from '../../../outage/classes/state';
import {DataService} from '../../../../services/data.service';
import {RRCCLocation} from '../../../management/classes/rrcc-location';
import {BasePopover} from '../base-popover';
import {User} from '../../../user/classes/user';
import {RRCCDeployment} from '../../../management/classes/rrcc-deployment';

@Component({
    selector: 'eaglei-rrcc-popover',
    templateUrl: './rrcc-popover.component.html',
    styleUrls: ['./rrcc-popover.component.scss', '../layer-popover.scss'],
})
export class RrccPopoverComponent extends BasePopover<RRCCLocation> {
    @Input() user: User;

    public state: State;

    constructor(public ele: ElementRef) {
        super();
    }

    // Abstract from parent class
    onInit() {}

    // Abstract from parent class
    updateData() {
        this.data = new RRCCLocation(this.data);
        this.state = DataService.states.getValue().find((state) => this.data.stateId === state.id);
    }

    // Abstract from parent class
    afterInit() {}

    private getShiftLabel(shiftId: number): string {
        switch (shiftId) {
            case 1:
                return 'AM';
            case 2:
                return 'PM';
            case 3:
                return 'All Day';
        }
    }

    public isEllipsis(element: HTMLElement): boolean {
        return element.offsetWidth < element.scrollWidth;
    }

    public getPersonnelText(deployment: RRCCDeployment): string {
        return `${deployment.personnel.name} | ${this.getShiftLabel(deployment.shiftId)}`;
    }
}
