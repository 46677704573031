<eaglei-leaflet-layer-header
    [layer]="this"
    (activeToggled)="toggleLayer($event)"
    (dateRangeUpdate)="toggleLayer(isActive)"
    [hideLegend]="true"></eaglei-leaflet-layer-header>

<ng-container *ngIf="isActive">
    <ng-container *ngIf="showMask; else showLegend">
        <div class="wide auto-mask">
            <p class="mask-message">
                No gas prices available for <br />
                {{ startDate | momentDate: 'MM/DD/YYYY' }} - {{ endDate | momentDate: 'MM/DD/YYYY' }}
            </p>
        </div>
    </ng-container>
</ng-container>

<div class="hide" [class.show]="isActive">
    <eaglei-filter-container
        *ngIf="mapLayer.filters"
        [filters]="mapLayer.filters"
        (filterChange)="updateFilter($event)"></eaglei-filter-container>
</div>

<ng-template #showLegend>
    <eaglei-legend [legends]="legend"></eaglei-legend>
</ng-template>
