import {inject, Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {BehaviorSubject} from 'rxjs';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root',
})
export class LockTimeService {
    static currentApplicationTime: BehaviorSubject<moment.Moment> = new BehaviorSubject<moment.Moment>(undefined);

    private readonly apiUrl = `api/systemdata`;
    private http = inject(HttpClient);
    private popup = inject(MatSnackBar);

    /**
     * Locks the system application time
     * Implementation for subscribe logic is in the socket-connection.service.ts so it can be used with the backend websockets
     * @param date The initial date that the application time should be locked at.
     */
    public lockTime(date: moment.Moment) {
        const url = `${this.apiUrl}/stop-time`;
        const params = new HttpParams().set('date', date.format());
        const formattedDate = date.format('M/D/YYYY hh:mm A z');

        const responseHandler = {
            next: () => {
                this.popup.open(`Set application time to ${formattedDate}`, 'X', {panelClass: 'dialog-success'});
            },
            error: (error) => {
                this.popup.open(`Failed to set application time to ${formattedDate}`, 'X', {panelClass: 'dialog-success'});
                throw error;
            },
        };

        // the subscribe logic is handled by the socket service
        this.http.get(url, {params}).subscribe(responseHandler);
    }

    /**
     * Unlocks the system application time
     * (Implementation for subscribe logic is in the socket-connection.service.ts so it can be used with the backend websockets
     */
    public unlockTime(): void {
        const url = `${this.apiUrl}/resume-time`;
        this.http.get(url).subscribe(() => {
            this.popup.open(`Set system to real time`, 'X', {panelClass: 'dialog-success'});
        });
    }
}
