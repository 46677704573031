import {NgModule} from '@angular/core';
import {NgIf} from '@angular/common';
import {TextSearchComponent} from './components/text-search/text-search.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
    declarations: [TextSearchComponent],
    imports: [FormsModule, MatIconModule, MatInputModule, MatSelectModule, NgIf, ReactiveFormsModule],
    exports: [TextSearchComponent],
})
export class DesignModuleModule {}
