import {Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {GenMapPointUtility} from '../../../../../../generated/serverModels/GenMapPointUtility';
import {BaseModal} from '../../../../classes/base-modal';
import {FileDownload} from '../../../../classes/file-download';
import {IPoint} from '../../../layer/interfaces/point.interface';
import {State} from '../../../outage/classes/state';
import {UtilityLookupService} from '../../services/utility-lookup.service';

@Component({
    selector: 'eaglei-utility-lookup-modal',
    templateUrl: './utility-lookup-modal.component.html',
    styleUrls: ['./utility-lookup-modal.component.scss'],
})
export class UtilityLookupModalComponent extends BaseModal implements OnInit, OnDestroy {
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild('content') content: ElementRef;

    public filters: State[] = [];
    public loading: Observable<boolean> = this.utilityLookupService.searching$;
    public manualLookup: boolean = false;
    public get noResults(): boolean {
        return !this.dataSource.filteredData?.length;
    }
    public columnNames = ['utilityName', 'countyName', 'stateName', 'utilityId', 'subUtilityId'];
    public dataSource: MatTableDataSource<GenMapPointUtility> = new MatTableDataSource<GenMapPointUtility>([]);
    private destroyed$ = new Subject();

    constructor(
        public dialogRef: MatDialogRef<UtilityLookupModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        private data: {
            manualLookup: boolean;
        },
        private utilityLookupService: UtilityLookupService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.manualLookup = this.data?.manualLookup || false;
        this.utilityLookupService.utilityInformation$.pipe(takeUntil(this.destroyed$)).subscribe((points: GenMapPointUtility[]) => {
            if (points?.length) {
                this.dataSource.data = points;
            } else {
                this.dataSource.data = [];
            }
        });
    }

    public afterInit(): void {
        this.dataSource.filterPredicate = this.filterPredicate;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
    }

    public ngOnDestroy(): void {
        this.destroyed$.complete();
        this.destroyed$.unsubscribe();
    }

    private filterPredicate(data: GenMapPointUtility, text: string): boolean {
        return data.utilityName.trim().toLowerCase().includes(text.trim().toLowerCase());
    }

    public manualSearch(point: IPoint): void {
        this.utilityLookupService.getUtilityInformation(point);
        this.manualLookup = false;
    }

    public exportCsv(): void {
        let data = ['Utility Name', 'County', 'State / Territory', 'Utility Id', 'SubUtility Id'].join() + '\n';

        this.dataSource._orderData(this.dataSource.filteredData).forEach((u) => {
            const info = [
                FileDownload.formatCsvCell(u.utilityName),
                FileDownload.formatCsvCell(u.countyName),
                FileDownload.formatCsvCell(u.stateName),
                FileDownload.formatCsvCell(u.utilityId?.toString() || ''),
                FileDownload.formatCsvCell(u.subUtilityId?.toString() || ''),
            ];
            data += `${info.join()}\n`;
        });

        FileDownload.downloadCSV('utility_lookup', data);
    }
}
