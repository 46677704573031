import {Component, OnInit} from '@angular/core';
import {LeafletLegendService} from '../../services/leaflet-legend.service';
import {filter} from 'rxjs/operators';
import {ILegendList} from '../../interfaces/legend-list.interface';

@Component({
    selector: 'eaglei-legend-list',
    templateUrl: './legend-list.component.html',
    styleUrls: ['./legend-list.component.scss'],
})
export class LegendListComponent implements OnInit {
    public legendList: ILegendList[] = [];

    constructor() {
        LeafletLegendService.legends.pipe(filter((f) => f !== undefined)).subscribe((legends) => {
            this.legendList = legends;
        });
    }

    ngOnInit() {}
}
