export const ngCriticalNotices = `Count of natural gas pipeline-reported notices with a 'Critical' priority within the past 24 hours.`;

export const ngNonCriticalNotices = `Count of natural gas pipeline-reported notices with a 'Non-Critical' or 'Planned Service Outage' priority within the past 24 hours.`;

export const receiptDeliveryPointsOverCapacity = `Count of natural gas nominations with a role = "receipt" or "delivery" and a scheduled capacity that's greater than the pipeline's operational capacity.
  This highlights all the nominations that have been either purchased or delivered with an actual flow greater than the pipelines daily possible capacity.
  Deliveries represent molecules leaving the pipeline or storage system.
  Receipts represent molecules entering the pipeline or storage system.
  Operational capacity: Daily operational capacity limit in mmBtu as posted by the pipeline operator for the location or meter.
  Scheduled capacity:  Nominations in mmBtu of natural gas scheduled to flow during the 24-hour gas day.`;

export const operationalFlowWidget = `Count of natural gas “Force Majeure”, “Operational Flow Order”, and “Capacity Constraints” pipeline notices.
  Force Majeure Notice: A force majeure notice provides contractual relief related to events (such as war, a labor strike, or extreme weather) or effects that cannot be reasonably anticipated or controlled.
  Capacity Constraint Notice: When the pipeline must interrupt or reduce natural gas service to customers for operational or regulatory reasons.
  Interruptions may be due to actual or anticipated supply or capacity shortages.
  Operational Flow Order Notice: Requires shippers to balance their gas supply with their customers' usage daily.`;

export const nominationsWidget = `Provides meter-level pipeline flows and receipt/delivery nominations for roughly 180 natural gas interstate pipelines.`;

export const ngUndergroundStorageWidget = `Provides daily, regional estimates for the following data types:
  "Production" – Estimate of Dry gas production.
  "Other" – Estimate of aggregate natural gas Field Fuel Use, Pipeline use for compression, and pipeline LAUF (lost and unaccounted for gas).
  "LNG Sendouts" – Estimated Net LNG Demand for Natural gas. Net Aggregate Natural gas delivered to LNG Terminals.
  "Industrial Demand" – Estimated Demand for natural gas for industrial use.
  "Canadian Imports" – Estimated Net Imports of Natural Gas from Canada.
  "Implied Storage" – Inventories in the Natural Gas Daily Macro Supply and Demand Report/Dataset are the estimated daily net balances of the US natural gas market.
  "Power Demand" – Estimated Demand for natural gas for Power/electricity generation
  "Res/Com Demand" – Estimated Demand for natural gas for residential and commercial use.
  "Mexican Exports" – Estimated Net Exports of natural gas to Mexico.`;

export const dailyPowerEstimateWidget = `Provides estimates of natural gas demand for power consumption (Mcf) by EIA storage region over a 1-week period.`;

export const derivedStorageWidget = `Provides Wood Mackenzie’s best interpretation of pipeline level or complex level natural gas storage inventories based on reported daily “nominations” from said pipelines or storage systems.
  This widget shows the daily inventory, and change-in-inventory from the previous day, for various storage terminals across the United States.
  Each storage estimate receives a 1 to 3 rating based on how accurate Genscape's analysts feel this estimate is. 1 is most accurate, 3 is least accurate.`;

export const gasBurnWidget = `Provides natural gas consumption estimates that are derived by taking the MWh values at Wood Mackenzie proprietary electricity monitors, and demonstrated heat rates from CEMS data, to derive an hourly burn estimate for power plants.
  This is not pipeline-reported nomination data.
  The Widget table on the left provides hourly consumption estimates for various power plants, while the chart on the right provides a 24-hour hourly breakdown for a selected power plant.`;

export const futureOilPlannedOutages = `Count of refinery planned outages with an expected start date within the next month with a planned status.
  Planned outage counts include Wood Mackenzie’s monitored and unmonitored refineries, and their individual units, within the U.S. and Canada.`;

export const pastOilPlannedOutages = `This widget provides a count of refinery planned outages with an observed end date within the last month and a completed status.
  Planned outage counts include Wood Mackenzie’s monitored and unmonitored refineries, and their individual units, within the U.S. and Canada.`;

export const activeOilPlannedOutages = `This widget provides a count of refinery planned outages with an in-progress status.
  Planned outage counts include Wood Mackenzie’s monitored and unmonitored refineries, and their individual units, within the U.S. and Canada.`;

export const oilRefineryStatus = `Provides a list of refineries, their daily capacity (bpd), and their status (online, offline, reduced capacity). View the refinery status report to see the status of individual units of a refinery.`;

export const oilRefineryAlerts = `Provides a list of real-time refinery alerts at a unit level.
  In general, these alerts include status updates (shut down due to maintenance, a unit restart, or decreased/increased activity updates).`;

export const transportWeeklyRailFlowDaily = `Provides two datasets, Transportation Weekly and Rail Flow Daily, each selected through the table type filter.
  The Transportation Weekly dataset provides weekly oil flow (bpd) estimates of regionally aggregated oil pipelines.
  These flow values estimate the amount of oil flowing between regions.
  The Rail Flow Daily dataset provides rail terminal daily oil flow (bpd) estimates.
  This data covers roughly 42 rail terminals across the United States and Canada.
  Each Terminal indicates whether they are loading or unloading oil shipments, along with how much oil is being transported.
  If the flow value is 0 bpd, that indicates no shipments occurred at that terminal on the specified day.`;

export const oilBarrelDailyFlows = `Provides a map of daily oil pipeline flows (barrels per day) within the Mid-Continent and Bakken regions of the United States.
  Each pipeline is color coded based on its Pipeline Capacity Utilization, which is the estimated flow(bpd) divided by the reported pipeline capacity (bpd).`;

export const storageWidget = `Provides weekly, regional, storage facility and refinery crude oil & diluent inventory.
  The storage facilities and refineries are aggregated to a regional level.
  This widget also includes the “storage capacity utilization” per region, which is equivalent to regional inventories divided by operational capacity, presented as a percentage.`;
