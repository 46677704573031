/* tslint:disable:member-ordering */
import * as moment from 'moment';

export class TimeStep {
    public id: number;
    public hour: number;
    public minute: number;
    public period: 'AM' | 'PM';
    public display: string;

    public matches(step: moment.Moment): boolean {
        return true;
    }

    public matchesTime(date: moment.Moment): boolean {
        const hour = date.hour();
        const minute = date.minute();

        return this.hour === hour && this.minute === minute;
    }

    static createTimeSteps(): TimeStep[] {
        let index = 0;
        const steps: TimeStep[] = [];
        for (let i = 0; i <= 23; i++) {
            for (let j = 0; j < 4; j++) {
                index++;

                const step = new TimeStep();
                step.id = index;
                step.hour = i;
                step.minute = j * 15;
                step.period = i < 12 ? 'AM' : 'PM';
                step.display = `${[0, 12].includes(step.hour) ? '12' : step.hour % 12}:${step.minute === 0 ? '00' : step.minute} ${
                    step.period
                }`;

                steps.push(step);
            }
        }
        return steps;
    }
}
