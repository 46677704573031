<div
    [class.single-picker]="singlePicker"
    [class.dark-background]="hasDarkBackground"
    [class.live]="isCurrent"
    [class.tiny-apply-adjust]="showTinyApply"
    [style.--basis]="singlePicker ? (showTinyApply ? '205px' : '185px') : '225px'"
    class="datepicker">
    <mat-form-field>
        <div class="input-container">
            <input
                tabindex="-1"
                matInput
                [matDatepicker]="startDatepicker"
                [placeholder]="singlePicker ? pickerName : 'Start Date'"
                [(ngModel)]="startDate"
                disabled
                [class.true-disabled]="disabled"
                (dateChange)="updateDateRange('start', $event.value)"
                [min]="singlePicker && !isStartTime ? now.toDate() : minStart.toDate()"
                [max]="singlePicker && !isStartTime ? maxEnd.toDate() : now.toDate()" />

            <mat-select
                class="time-select"
                [disabled]="disabled"
                tabindex="-1"
                (selectionChange)="updateTime($event.value, startDate, true)"
                [(ngModel)]="startTime">
                <mat-option *ngFor="let step of startTimeSteps" [value]="step" [disabled]="!step.valid">{{ step.time }}</mat-option>
            </mat-select>
        </div>
        <mat-datepicker-toggle [disabled]="disabled" tabindex="-1" matSuffix [for]="startDatepicker"></mat-datepicker-toggle>
        <mat-datepicker #startDatepicker [startAt]="startDate" disabled="false"></mat-datepicker>
    </mat-form-field>

    <mat-form-field *ngIf="!singlePicker" [class.hide]="ongoing">
        <input
            tabindex="-1"
            matInput
            [matDatepicker]="endDatepicker"
            placeholder="End Date"
            [(ngModel)]="endDate"
            disabled
            class="date-selector"
            [class.true-disabled]="disabled"
            (dateChange)="updateDateRange('end', $event.value)"
            [min]="start.toDate()"
            [max]="maxEnd.toDate()" />

        <mat-select
            class="time-selector"
            [disabled]="disabled"
            tabindex="-1"
            (selectionChange)="updateTime($event.value, endDate, true)"
            [(ngModel)]="endTime">
            <mat-option *ngFor="let step of endTimeSteps" [value]="step" [disabled]="!step.valid">{{ step.time }}</mat-option>
        </mat-select>

        <mat-datepicker-toggle [disabled]="disabled" tabindex="-1" matSuffix [for]="endDatepicker"></mat-datepicker-toggle>
        <mat-datepicker #endDatepicker [startAt]="endDate" disabled="false"></mat-datepicker>
    </mat-form-field>

    <mat-icon
        *ngIf="showTinyApply"
        [inline]="true"
        fontSet="fas"
        fontIcon="fa-arrow-down"
        matTooltip="{{ tinyButtonText }}"
        class="clickable"
        (click)="applyChanges()"></mat-icon>
</div>
