<div class="container">
    <div #header class="widget-header">
        <p class="title">Power Outage Trend</p>
        <div class="last-updated">
            <p>last updated {{ chartEndDate() | momentDate : '' : true }}</p>
            <a [href]="attribution" target="_blank">EAGLE-I</a>
        </div>
    </div>

    <div #content class="widget-content" [class.invisible]="chartLocations()?.length === 0">
        <div class="coverage" [class.invisible]="chartLocations()?.length === 0">
            <div>
                <p class="coverage-percent">{{ coveragePercent() | percent : '1.2-2' }}</p>
                <span class="coverage-text">Data Coverage</span>
            </div>
        </div>

        <div class="outage-chart" [class.invisible]="chartLocations()?.length === 0">
            <eaglei-nom-chart
                [aggregationLevel]="signalFilters().aggregationLevel"
                [startDate]="chartStartDate()"
                [endDate]="chartEndDate()"
                [states]="chartLocations()">
            </eaglei-nom-chart>
        </div>
    </div>
</div>
