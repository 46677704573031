import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {ApplicationConfig} from 'frontend/src/app/classes/application-config';
import {FileDownload} from 'frontend/src/app/classes/file-download';
import {IBarrelFlow} from 'frontend/src/app/interfaces/ong/barrel-flow.interface';
import {filter, takeUntil} from 'rxjs/operators';
import {LeafletMapLayer} from '../../../layer/classes/leaflet-map-layer';
import {OilBarrelFlowSource} from '../../../layer/sources/oil-barrel-flow-source';
import {MapOptions} from '../../../map/classes/map-options';
import {MapService} from '../../../map/services/map.service';
import {BaseWidget} from '../../classes/base-widget';
import {WidgetService} from '../../services/widget.service';
import {Map as LeafletMap} from 'leaflet';
import {LayerService} from '../../../layer/services/layer.service';
import {CustomPopover} from '../../../layer/enum/custom-popover';
import {OngService} from '../../../../services/ong.service';
import {oilBarrelDailyFlows} from '../../../../../integrations/ong/classes/ong-attributions-text';

@Component({
    selector: 'eaglei-oil-barrel-flow-widget',
    templateUrl: './oil-barrel-flow-widget.component.html',
    styleUrls: ['./oil-barrel-flow-widget.component.scss'],
})
export class OilBarrelFlowWidgetComponent extends BaseWidget implements AfterViewInit {
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    // Map Properties
    public mapOptions: MapOptions;
    public flowSource: OilBarrelFlowSource;
    private mapLayer: LeafletMapLayer;

    // Table Properties
    public readonly columnNames: string[] = ['pipeline_name', 'id', 'capacity', 'flow', 'start_pump', 'end_pump'];
    public dataSource: MatTableDataSource<IBarrelFlow>;
    public mapRef: LeafletMap;

    constructor(
        private widgetElement: ElementRef,
        private widgetService: WidgetService,
        private ongService: OngService,
        private layerService: LayerService
    ) {
        super(widgetElement);
        this.setSubscriptions();

        this.mapOptions = new MapOptions().setZoom(3, 1).setCenter(37.2, -92);
        this.mapOptions.onlyManualZoom = true;
        this.mapOptions.show = {
            sidebar: false,
            refresh: false,
            export: false,
            coverage: false,
            coordinate: false,
            zoom: true,
            panControl: true,
        };
    }

    public ngAfterViewInit(): void {
        this.loading.emit(true);
        this.getLayer();
        this.attributionModalText = oilBarrelDailyFlows;
        this.widgetName = 'Oil Barrel Daily Flows';
    }

    public getLayer(): void {
        this.layerService.getLayerByHandle('oilBarrelFlow').subscribe((layer) => {
            this.mapLayer = layer;
            this.mapLayer.customPopoverId = CustomPopover.OIL_BARREL_FLOW;
            this.flowSource = new OilBarrelFlowSource(layer);
            this.flowSource.mapRef = this.mapRef;

            this.getBarrelFlow();
        });
    }

    private getBarrelFlow(): void {
        this.ongService.getOilBarrelFlow().subscribe((res) => {
            const features = res.features.filter((f) => !!f.geometry);
            this.flowSource.processFeatures(features);
            this.flowSource.addToMap();
            this.flowSource.fitToConus();
            this.flowSource.changeOpacity(0.8);
            this.loading.emit(false);
            this.initData(features);
        });
    }

    public renderMap(): void {
        MapService.mapRef.invalidateSize();
    }

    private initData(data: IBarrelFlow[]): void {
        if (this.dataSource) {
            this.dataSource.data = data;
        } else {
            this.dataSource = new MatTableDataSource(data);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sortingDataAccessor = this.dataAccessor.bind(this);
            this.dataSource.sort = this.sort;
        }
    }

    // noinspection JSMethodCanBeStatic
    private dataAccessor(data: IBarrelFlow, header: string): string | number {
        switch (header) {
            case 'pipeline_name':
                return data.properties.pipeline_name.toLowerCase();
            case 'id':
                return data.properties.pipeline_id;
            case 'capacity':
                return data.properties.barrel_flow[0].capacity;
            case 'flow':
                return data.properties.barrel_flow[0].flow;
            case 'start_pump':
                return data.properties.start_pump.toLowerCase();
            case 'end_pump':
                return data.properties.finish_pump.toLowerCase();
            default:
                return '';
        }
    }

    public exportTable(): void {
        let data: string = ['Pipeline Name', 'ID', 'Capacity', 'Flow', 'Start Pump', 'End Pump'].join() + '\n';

        this.dataSource.data.forEach((d) => {
            const values = [
                FileDownload.formatCsvCell(d.properties.pipeline_name),
                FileDownload.formatCsvCell(d.properties.pipeline_id),
                FileDownload.formatCsvCell(d.properties.barrel_flow[0].capacity),
                FileDownload.formatCsvCell(d.properties.barrel_flow[0].flow),
                FileDownload.formatCsvCell(d.properties.start_pump),
                FileDownload.formatCsvCell(d.properties.finish_pump),
            ];

            data += values.join();
            data += '\n';
        });

        FileDownload.downloadCSV('oilBarrelDailyFlow', data, 'https://www.genscape.com');
    }

    private setSubscriptions(): void {
        ApplicationConfig.resizeEvent.pipe(takeUntil(this.destroy$)).subscribe(() => this.renderMap());

        WidgetService.redrawMap
            .pipe(
                filter(() => !!this.header && !!this.content),
                takeUntil(this.destroy$)
            )
            .subscribe(() => {
                this.resize();
                this.renderMap();
            });

        WidgetService.renderWidget.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.renderMap();
        });
    }

    public goToMap(): void {
        this.layerService.navigateToMap([this.mapLayer]);
    }

    public onMobile(): boolean {
        return ApplicationConfig.onMobile();
    }

    destroyWidget(): void {}
}
