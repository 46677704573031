import {Injectable} from '@angular/core';
import * as L from 'leaflet';
import {LatLng, Layer, LeafletMouseEvent} from 'leaflet';
import {LeafletMapLayer} from '../../layer/classes/leaflet-map-layer';
import {PopoverConfig, ShowPopoverConfig} from '../../../classes/popover-config';
import {DataInjector} from '../../../services/data.service';
import {LocationSearchService} from './location-search.service';
import {ApplicationConfig} from '../../../classes/application-config';

@Injectable({
    providedIn: 'root',
})
export class IdentifyService {
    private readonly fillColor: string = 'rgba(255,255,255,0.1)';
    private readonly strokeColor: string = '#00ffff';
    private readonly strokeWidth: number = 4;

    public loadingFeatureInfo: boolean = false;
    private identifyLayerMap: Map<string, L.Layer[]> = new Map<string, L.Layer[]>();
    public identifyGroup = L.geoJSON(undefined, {
        pane: 'tools',
        pointToLayer: (geoJsonPoint: any, latlng: LatLng): Layer => {
            return L.circleMarker(latlng, {
                fillColor: this.fillColor,
                color: this.strokeColor,
                weight: 4,
                radius: 7,
            });
        },
        style: {
            color: this.strokeColor,
            weight: this.strokeWidth,
            fillColor: this.fillColor,
        },
        onEachFeature: (feature, layer) => {
            const key = feature['eagleiLayer'].uiHandle;
            const val = this.identifyLayerMap.get(key) || [];
            val.push(layer);
            this.identifyLayerMap.set(key, val);

            const data = (feature['eagleiLayer'] as LeafletMapLayer).createPopover(feature.properties);
            const isInteractive = data.some((pe) => pe.isLink || pe.isModal);

            layer.on('mouseover', (event: LeafletMouseEvent) => {
                if (!DataInjector.get(LocationSearchService).drawing && !ApplicationConfig.onLite()) {
                    const config: ShowPopoverConfig = {
                        data,
                        x: event.originalEvent.x,
                        y: event.originalEvent.y,
                        interactive: isInteractive,
                    };

                    PopoverConfig.showNewPopover(config);
                }
            });
            //
            layer.on('mouseout', () => {
                if (!isInteractive) {
                    PopoverConfig.hideNewPopover();
                }
            });
        },
    });

    constructor() {
        this.identifyGroup.setZIndex(1000);
    }

    public updateFeature(feature: any): void {
        const key = feature['eagleiLayer'].uiHandle;
        const val = this.identifyLayerMap.get(key) || [];

        const index = val.findIndex((layer: any) => layer.feature.id === feature.id);

        if (index === -1) {
            this.identifyGroup.addData(feature);
            this.identifyGroup.bringToFront();
        } else {
            this.identifyGroup.removeLayer(val[index]);
            val.splice(index, 1);
            this.identifyLayerMap.set(key, val);
        }
    }

    public removeFeatures(handle: string) {
        const val = this.identifyLayerMap.get(handle) || [];
        val.forEach((layer) => {
            this.identifyGroup.removeLayer(layer);
        });
        this.identifyLayerMap.delete(handle);
    }
}
