import {ApplicationConfig} from 'frontend/src/app/classes/application-config';
import {LeafletEagleiSource} from '../classes/leaflet-eaglei-source';
import {LeafletMapLayer} from '../classes/leaflet-map-layer';
import * as L from 'leaflet';
import 'leaflet.vectorgrid';
import {MapService} from '../../map/services/map.service';
import * as moment from 'moment';

export class WAOLightsSource extends LeafletEagleiSource {
    private failedTiles: number = 0;
    private readonly MAX_RETRY_ATTEMPTS = 1;

    private retryTileMap: Map<string, number>;

    // Filters
    public selectedDate: moment.Moment;
    public selectedLightStatus: number[];

    constructor(layerInfo: LeafletMapLayer) {
        super(layerInfo);
    }

    /**
     * Fetch the tiles from the tile server of the given layer service url
     */
    public fetchTiles(): void {
        this.retryTileMap = new Map<string, number>();
        const dateSelected = this.selectedDate.format('YYYYMMDD');
        // Check if date is selected
        if (!dateSelected || dateSelected.length < 8) {
            return;
        }

        this.layerInfo.serviceurl = 'WAO_TILESERVER/public.query_light_change/{z}/{x}/{y}';
        // Have to use the bracket uri encode for martin
        const lightStatus = `%5B${this.selectedLightStatus.join()}%5D`;
        // TODO: Fix date force
        const url = `${ApplicationConfig.proxyPrefix}${this.layerInfo.serviceurl}?dn=${lightStatus}&run_date=${this.selectedDate
            .add(1, 'day')
            .format('YYYY-MM-DD')}`;

        const vectorStyles = {
            'public.query_light_change': (props: any, zoom: number) => {
                let color;
                const dn = props.dn;
                switch (dn) {
                    case -1: // No Lights
                        color = '#404040';
                        break;
                    case 1: // Increased Light
                        color = '#FFFF00';
                        break;
                    case 3: // Cloud
                        color = '#D3D3D3';
                        break;
                    default:
                        color = 'rgba(0,0,0,0)';
                        break;
                }

                return {
                    color,
                    fillColor: color,
                    fillOpacity: 0.1,
                    fill: true,
                };
            },
        };

        const options = {
            vectorTileLayerStyles: vectorStyles,
            subdomains: '0123',
            interactive: true,
            pane: MapService.layerPaneName,
            // attribution: '<a target="_blank" href="/app/attribution/wao">WAO</a>',
            // fetchOptions: {
            //   headers: {
            //     Authorization: `Bearer ${AuthService.userToken}`
            //   }
            // }
        };

        this.source = (L as any).vectorGrid.protobuf(`${url}`, options);

        this.source.on('loading', () => {
            this.layerInfo.startLoading();
        });

        this.source.on('load', () => {
            this.layerInfo.endLoading();
        });

        // TODO: Figure out why this isn't triggering
        this.source.on('tileerror', (event) => {
            // console.log('event error', event);
            // this.failedTiles += 1;
            // const tileUrl = this.source.getTileUrl(event.coords);
            // let retryAttempts = this.retryTileMap.get(tileUrl) || 0;
            // if (retryAttempts < this.MAX_RETRY_ATTEMPTS) {
            //     setTimeout(() => {
            //         retryAttempts++;
            //         event.tile.src = `${tileUrl}`;
            //         this.retryTileMap.set(tileUrl, retryAttempts);
            //         console.info(`retry attempt #${retryAttempts}`, tileUrl);
            //     }, 1_000);
            // }
        });

        this.addToMap();
    }

    changeOpacity(opacity: number): void {
        this.source?.setOpacity(opacity);
    }
}
