<div fxLayout="row" fxLayoutAlign="space-between" class="dialog-header">
    <div class="filter-header">ONG Planned Outage Settings</div>
    <mat-icon inline class="clickable" fontSet="fas" fontIcon="fa-times" (click)="dialogRef.close()"></mat-icon>
</div>

<div #content class="dialog-content" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="25px">
    <span class="dialog-description"> View Future, Planned, or Past Outages provided by Genscape </span>

    <div class="filter-bar">
        <mat-button-toggle-group [value]="selectedOutage">
            <mat-button-toggle *ngFor="let outage of outageType" [value]="outage" (change)="changeOutage(outage)">
                {{ outage | uppercase }}
            </mat-button-toggle>
        </mat-button-toggle-group>
    </div>
</div>

<div class="dialog-actions" fxLayout="row" fxLayoutAlign="end center">
    <button mat-flat-button color="primary" (click)="configureWidget()" [disabled]="disableUpdate()">Update Widget</button>
</div>
