<mat-card>
    <mat-card-title>
        <span>Oil Barrel Daily Flows</span>
        <button mat-icon-button (click)="close()">
            <mat-icon fontSet="fas" fontIcon="fa-times"></mat-icon>
        </button>
    </mat-card-title>
    <mat-card-content #content>
        <span class="title">{{ flowData.pipeline_name }}</span>

        <div class="metric-container">
            <div>
                <div class="metric">
                    <span class="sub-title">Pipeline ID:</span>
                    <span class="value">{{ flowData.pipeline_id }}</span>
                </div>

                <div class="metric">
                    <span class="sub-title">Flow:</span>
                    <span class="value">{{ +flowData.barrel_flow[0].flow | shortNumber }} bpd</span>
                </div>

                <div class="metric">
                    <span class="sub-title">Start Pump:</span>
                    <span class="value">{{ flowData.start_pump }}</span>
                </div>

                <div class="metric">
                    <span class="sub-title">Confidence ID:</span>
                    <span class="value">{{ flowData.barrel_flow[0].confidence_id }}</span>
                </div>
            </div>

            <div>
                <div class="metric">
                    <span class="sub-title">Capacity:</span>
                    <span class="value">{{ flowData.barrel_flow[0].capacity | shortNumber }} bpd</span>
                </div>

                <div class="metric">
                    <span class="sub-title">Direction:</span>
                    <span class="value">{{ flowData.barrel_flow[0].direction }}</span>
                </div>

                <div class="metric">
                    <span class="sub-title">End Pump:</span>
                    <span class="value">{{ flowData.finish_pump }}</span>
                </div>

                <div class="metric">
                    <span class="sub-title">Date:</span>
                    <span class="value">{{ flowData.barrel_flow[0].report_date | momentDate : 'MM/DD/YYYY' }}</span>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>
