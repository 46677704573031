import {UrbannetDisruption} from '../classes/urbannet-disruption';
import {UrbannetEffect} from '../classes/urbannet-effect';
import {PopoverElement} from '../../../app/classes/popover-element';
import {getUrbannetAssetProperties} from './get-urbannet-properties';
import {AffectedAssetType} from '../../../app/integrations/urbannet/enums/urbannet-affected-asset-type';
import {DecimalPipe} from '@angular/common';
import {MatDialogConfig} from '@angular/material/dialog';
import {ModalConfig} from '../../../app/classes/modal-config';
import {UrbannetAssetInfoModalComponent} from '../../../app/integrations/urbannet/modals/urbannet-asset-info-modal/urbannet-asset-info-modal.component';
import {UrbannetService} from '../services/urbannet.service';

export const getUrbannetAssetPopover = (asset: UrbannetDisruption | UrbannetEffect): PopoverElement[] => {
    const properties = getUrbannetAssetProperties(asset);
    const layerName = asset.nodeId.split(':')[0];
    const name = properties.name;

    const decimalPipe = new DecimalPipe('en-us');

    const modalConfig: MatDialogConfig = {
        ...ModalConfig.defaultConfig,
        data: {
            name,
            properties,
        },
    };

    let ret: PopoverElement[];

    switch (layerName) {
        case AffectedAssetType.TRANSMISSION_LINES:
            ret = [
                new PopoverElement('Owner', properties['OWNER']).setTitle(),
                new PopoverElement('Status', properties['STATUS']),
                new PopoverElement('Voltage', `${decimalPipe.transform(properties['VOLTAGE'])}kV`),
                new PopoverElement('Sourcedate', properties['SOURCEDATE']),
            ];
            break;
        case AffectedAssetType.POWER_PLANTS:
            ret = [
                new PopoverElement(null, properties['NAME']).setTitle(),
                new PopoverElement('Operator', properties['OPERATOR']),
                new PopoverElement('Street Address', properties['ADDRESS']),
                new PopoverElement('City', properties['CITY']),
                new PopoverElement('State', properties['STATE']),
                new PopoverElement('Zip Code', properties['ZIP']),
                new PopoverElement('Phone Number', properties['TELEPHONE']),
                new PopoverElement('Date', properties['SOURCEDATE']),
                new PopoverElement('Website', properties['WEBSITE'], true),
            ];
            break;
        case AffectedAssetType.TRANSMISSION_ELECTRIC_SUBSTATIONS:
        case AffectedAssetType.ELECTRIC_SUBSTATIONS:
            ret = [
                new PopoverElement(null, properties['NAME']).setTitle(),
                new PopoverElement('Number Of Lines', properties['LINES']),
                new PopoverElement('City', properties['CITY']),
                new PopoverElement('State/Territory', properties['STATE']),
                new PopoverElement('Zip Code', properties['ZIP']),
                new PopoverElement('Date', properties['SOURCEDATE']),
                new PopoverElement('Min Voltage', `${decimalPipe.transform(properties['MIN_VOLT'])}kV`),
                new PopoverElement('Max Voltage', `${decimalPipe.transform(properties['MAX_VOLT'])}kV`),
            ];
            break;
        default:
            // console.warn(`No popover defined for ${layerName}`, asset);
            ret = [new PopoverElement(null, name).setTitle(), new PopoverElement('Asset Type', layerName.replace(/_/g, ' '))];
    }

    ret.push(new PopoverElement('More Info', name).setDialogRef(UrbannetAssetInfoModalComponent, modalConfig));

    // Only add the zoom to section on the disruptions
    if ('effects' in asset) {
        ret.push(
            new PopoverElement('Zoom To Asset', name).setIsModal().setOnClick(() => {
                UrbannetService.zoomToAsset.next(asset);
            })
        );
    }

    return ret;
};
