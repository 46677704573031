<eaglei-leaflet-layer-header [layer]="this" (activeToggled)="toggleLayer($event)" [hideLegend]="true"></eaglei-leaflet-layer-header>

<div class="layer-actions hide" [class.show]="isActive">
    <div class="aggregation">
        <mat-button-toggle-group [value]="windData" (change)="updateData($event.value)" class="wide">
            <mat-button-toggle [value]="'speed'">Wind Speed</mat-button-toggle>
            <mat-button-toggle [value]="'gust'">Wind Gusts</mat-button-toggle>
        </mat-button-toggle-group>
    </div>

    <div>
        <mat-checkbox [(ngModel)]="showDirection" (change)="updateData(windData)">Show Wind Direction</mat-checkbox>
    </div>

    <ng-container *ngIf="isActive">
        <div>
            <span>{{ windData === 'speed' ? 'Wind Speed' : 'Wind Gust' }} (knots)</span>
            <eaglei-legend [legends]="legend"></eaglei-legend>
        </div>
    </ng-container>
</div>
