import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BaseModal} from '../../../../classes/base-modal';

@Component({
    selector: 'eaglei-urbannet-asset-info-modal',
    templateUrl: './urbannet-asset-info-modal.component.html',
    styleUrls: ['./urbannet-asset-info-modal.component.scss'],
})
export class UrbannetAssetInfoModalComponent extends BaseModal implements OnInit {
    constructor(public dialogRef: MatDialogRef<UrbannetAssetInfoModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
        super();
    }

    ngOnInit(): void {}

    afterInit() {}
}
