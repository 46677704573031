import {AfterViewInit, Component, ElementRef, Input, ViewChild} from '@angular/core';
import {ApplicationConfig} from 'frontend/src/app/classes/application-config';
import {DataService} from 'frontend/src/app/services/data.service';
import {LayerService} from '../../../layer/services/layer.service';
import {BaseWidget} from '../../classes/base-widget';
import {MapService} from '../../../map/services/map.service';
import {NomLegendComponent} from '../../../../../shared/components/nom-legend/nom-legend.component';
import {MapOptions} from '../../../map/classes/map-options';
import {WidgetService} from '../../services/widget.service';
import {filter, takeUntil} from 'rxjs/operators';
import {CustomLandingLayoutComponent} from '../../../../landing/components/custom-landing-layout/custom-landing-layout.component';
import {LeafletNomFilter} from '../../../layer/filters/leaflet-nom-filter';
import {LeafletNomSource} from '../../../layer/sources/leaflet-nom-source';
import {GenOutageAggregationLevel} from '../../../../../../generated/serverModels/GenOutageAggregationLevel';
import {HistoricalOutageData} from '../../../layer/classes/historical-outage-data';
import {LeafletMapLayer} from '../../../layer/classes/leaflet-map-layer';
import {Map as LeafletMap} from 'leaflet';
import * as moment from 'moment';
import {EventSummaryFilters} from '../../../system-event/classes/event-summary-filters';
import {MatButtonToggleChange} from '@angular/material/button-toggle';
import {GenWidgetSettingType} from '../../../../../../generated/serverModels/GenWidgetSettingType';

@Component({
    selector: 'eaglei-outage-map-widget',
    templateUrl: './outage-map-widget.component.html',
    styleUrls: ['./outage-map-widget.component.scss'],
})
export class OutageMapWidgetComponent extends BaseWidget implements AfterViewInit {
    @Input() outages: HistoricalOutageData[];
    @Input() nomLayer: LeafletMapLayer;

    public nomSource: LeafletNomSource;

    get mobileLayout(): boolean {
        return ApplicationConfig.useMobileLayout();
    }

    // HTML Element Properties
    @ViewChild('mapTarget') mapTarget: ElementRef<HTMLElement>;
    @ViewChild(NomLegendComponent) mapLegend: NomLegendComponent;

    // Landing Page Filters
    public filters: LeafletNomFilter = new LeafletNomFilter();

    // Map Options
    public mapOptions: MapOptions;
    public mapRef: LeafletMap;

    public readonly aggregationLevels = GenOutageAggregationLevel.values().filter(
        (l) => ![GenOutageAggregationLevel.fema, GenOutageAggregationLevel.utility, GenOutageAggregationLevel.zip].includes(l)
    );

    public isLoading = false;

    constructor(
        private mapService: MapService,
        private layerService: LayerService,
        private dataService: DataService,
        private widgetElement: ElementRef
    ) {
        // noinspection DuplicatedCode
        super(widgetElement, true);

        this.mapOptions = new MapOptions().setZoom(3, 1).setCenter(37.2, -92);
        this.mapOptions.onlyManualZoom = true;
        this.mapOptions.show = {
            sidebar: false,
            refresh: false,
            export: false,
            coverage: false,
            coordinate: false,
            zoom: true,
            panControl: true,
        };
    }

    protected handleEventFilterChange(eventFilters: EventSummaryFilters): void {
        this.filters.runStartTime = moment(eventFilters.date);
        this.filters.states = eventFilters.locations;

        this.getLayerInfo();
    }

    ngAfterViewInit(): void {
        if (!this.eventDashboard) {
            this.getUserPreferences();
            this.filters.aggregationLevel = this.userPreferences.getOutageAggregationLevel();
            this.filters.states = this.userPreferences.getStates();
        } else {
            const defaultAggregation = this.item.detail.getSettingByType(GenWidgetSettingType.AGGREGATION_LEVEL, 'state');
            this.filters.aggregationLevel = GenOutageAggregationLevel.forName(defaultAggregation.value);
        }

        this.setSubscriptions();
    }

    destroyWidget(): void {
        this.nomSource?.removeFromMap();
    }

    onApplicationTimeChange() {
        this.getOutageData();
    }

    private getLayerInfo(): void {
        const handleLayer = (layer: LeafletMapLayer) => {
            if (!this.nomSource) {
                this.nomSource = new LeafletNomSource(layer);
                this.nomSource.mapRef = this.mapRef;
            }

            this.nomSource.updateFilter('location', this.locationFilter.bind(this));
            this.getOutageData();
        };

        if (this.nomLayer) {
            handleLayer(this.nomLayer);
        } else {
            this.layerService.getLayerByHandle().subscribe((layer) => handleLayer(layer));
        }
    }

    /**
     * Renders the Map after updating the map size
     */
    public renderMap(): void {
        this.mapRef.invalidateSize();
    }

    /**
     * Fetches the current outage data ana adds it to the map.
     */
    private getOutageData(): void {
        const handleOutages = (outages: HistoricalOutageData[]) => {
            this.nomSource.processFeatures(outages);
            this.nomSource.addToMap();
            this.nomSource.changeOpacity(0.8);
            this.nomSource.fitToFeatures();
        };

        if (this.outages) {
            handleOutages(this.outages);
        } else {
            const filterDate = this.eventDashboard ? moment(this.filters?.runStartTime) : undefined;
            this.filters.runStartTime = ApplicationConfig.roundMinute(filterDate);

            this.layerService.getHistoricalOutageData(this.filters).subscribe((outages) => {
                handleOutages(outages);
            });
        }
    }

    private locationFilter(f: any): boolean {
        const outage: HistoricalOutageData = f.properties;
        if (this.filters.aggregationLevel === GenOutageAggregationLevel.county) {
            return this.filters.states.map((s) => s.abbreviation).includes(outage.stateName);
        } else {
            return this.filters.states.map((s) => s.id).includes(outage.stateId);
        }
    }

    /**
     * Gets the user preferences
     */
    private updatePreferences(): void {
        if (!this.eventDashboard) {
            this.filters.states = this.userPreferences.getStates();
        }

        if (!this.filters.aggregationLevel) {
            this.filters.aggregationLevel = this.userPreferences.getOutageAggregationLevel();
        }

        this.mapLegend.updateLegend();
    }

    public updateAggregation(event: MatButtonToggleChange) {
        this.filters.aggregationLevel = event.value;

        this.getOutageData();
    }

    /**
     * Sets the subscriptions for the map
     */
    private setSubscriptions(): void {
        ApplicationConfig.resizeEvent.pipe(takeUntil(this.destroy$)).subscribe(() => this.renderMap());

        WidgetService.renderWidget.pipe(takeUntil(this.destroy$)).subscribe(() => {
            if (this.nomSource) {
                this.getLayerInfo();
            }
            this.renderMap();
        });

        CustomLandingLayoutComponent.filterChange.pipe(takeUntil(this.destroy$)).subscribe(() => {});

        WidgetService.redrawMap
            .pipe(
                filter(() => !!this.header && !!this.content),
                takeUntil(this.destroy$)
            )
            .subscribe(() => {
                this.resize();
                this.renderMap();
            });

        ApplicationConfig.currentUserPreferences.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.updatePreferences();
            if (!this.eventDashboard) {
                this.getLayerInfo();
            }
        });

        this.mapService.mapDateRange
            .pipe(
                filter(() => !!this.nomSource),
                takeUntil(this.destroy$)
            )
            .subscribe(() => this.getOutageData());
    }

    /**
     * Get Mapper Link based on Mobile Devices
     */
    public getMapperLink(): string {
        return ApplicationConfig.onMobile() ? '/app/lite/map' : '/app/map/view';
    }
}
