import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ApiKeyRequest} from '../classes/api-key-request';
import {ApiKey} from '../classes/api-key';
import {GenApiKeyRequestStatus} from '../../../../../generated/serverModels/GenApiKeyRequestStatus';
import {User} from '../../user/classes/user';

@Injectable({
    providedIn: 'root',
})
export class ApiKeyService {
    private readonly apiKeyUrl: string = '/api/apikey';

    constructor(private http: HttpClient) {}

    public requestApiKey(request: ApiKeyRequest): Observable<ApiKeyRequest> {
        const url = `${this.apiKeyUrl}/request`;
        return this.http.post<ApiKeyRequest>(url, request);
    }

    public approveApiKeyRequest(request: ApiKeyRequest): Observable<ApiKeyRequest> {
        const url = `${this.apiKeyUrl}/approve/${request.id}`;
        return this.http.put<ApiKeyRequest>(url, 'Approved').pipe(map((r) => new ApiKeyRequest(r)));
    }

    public denyApiKeyRequest(request: ApiKeyRequest, reason: string): Observable<ApiKeyRequest> {
        const url = `${this.apiKeyUrl}/deny/${request.id}`;
        return this.http.put<ApiKeyRequest>(url, reason).pipe(map((r) => new ApiKeyRequest(r)));
    }

    public expireAllUserApiKeys(userId: number, reason: string): Observable<ApiKey[]> {
        const url = `${this.apiKeyUrl}/expire/user/${userId}`;
        return this.http.put<any[]>(url, reason).pipe(map((keys) => keys.map((key) => new ApiKey(key))));
    }

    public getApiKeys(): Observable<ApiKey[]> {
        const url = `${this.apiKeyUrl}`;
        const mf = (keys: ApiKey[]) => {
            return keys.map((key) => new ApiKey(key));
        };
        return this.http.get<ApiKey[]>(url).pipe(map(mf));
    }

    public getApiKeyRequest(): Observable<ApiKeyRequest[]> {
        const url = `${this.apiKeyUrl}/request?status=${GenApiKeyRequestStatus.PENDING.toString()}`;
        const mf = (requests: ApiKeyRequest[]) => {
            return requests.map((request) => new ApiKeyRequest(request));
        };
        return this.http.get<ApiKeyRequest[]>(url).pipe(map(mf));
    }

    public getTermsOfUse(): Observable<any> {
        const opts: any = {responseType: 'text'};
        return this.http.get<any>(`${this.apiKeyUrl}/termsofuse`, opts);
    }

    public getApiKey(user: User): Observable<ApiKey[]> {
        const url = `${this.apiKeyUrl}/${user.id}`;
        return this.http.get<any>(url).pipe(map((res) => res.map((key) => new ApiKey(key))));
    }

    public renewApiKey(request: ApiKey): Observable<any> {
        const url = `${this.apiKeyUrl}/renew`;
        return this.http.put(url, request);
    }

    public updateKey(apiKey: ApiKey): Observable<ApiKey> {
        return this.http.put<any>(this.apiKeyUrl, apiKey).pipe(map((key) => new ApiKey(key)));
    }

    public validSystemName(systemName: string): Observable<boolean> {
        const url = `${this.apiKeyUrl}/valid-system-name`;
        return this.http.post<boolean>(url, systemName);
    }
}
