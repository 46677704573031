
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

export class GenCommunityPermission {
    static readonly EIL: GenCommunityPermission = new GenCommunityPermission('EIL');
    static readonly HEADOUT: GenCommunityPermission = new GenCommunityPermission('HEADOUT');
    static readonly FEDERAL_SITUATION_REPORT: GenCommunityPermission = new GenCommunityPermission('FEDERAL_SITUATION_REPORT');
    static readonly STATE_SITUATION_REPORT: GenCommunityPermission = new GenCommunityPermission('STATE_SITUATION_REPORT');
    static readonly EEAC: GenCommunityPermission = new GenCommunityPermission('EEAC');
    static readonly ISER: GenCommunityPermission = new GenCommunityPermission('ISER');
    static readonly TREASURY: GenCommunityPermission = new GenCommunityPermission('TREASURY');
    static readonly RRCC: GenCommunityPermission = new GenCommunityPermission('RRCC');
    static readonly URBANNET: GenCommunityPermission = new GenCommunityPermission('URBANNET');
    static readonly ESF_FIELD_PHOTO: GenCommunityPermission = new GenCommunityPermission('ESF_FIELD_PHOTO');
    static readonly UAS: GenCommunityPermission = new GenCommunityPermission('UAS');
    static readonly WAO: GenCommunityPermission = new GenCommunityPermission('WAO');
    static readonly RADR: GenCommunityPermission = new GenCommunityPermission('RADR');
    static readonly SLTT: GenCommunityPermission = new GenCommunityPermission('SLTT');

    public readonly name: string;

    private constructor(name: string) {
        this.name = name;
    }

    static forName(name: string) {
        if (name === 'EIL') return GenCommunityPermission.EIL;
        if (name === 'HEADOUT') return GenCommunityPermission.HEADOUT;
        if (name === 'FEDERAL_SITUATION_REPORT') return GenCommunityPermission.FEDERAL_SITUATION_REPORT;
        if (name === 'STATE_SITUATION_REPORT') return GenCommunityPermission.STATE_SITUATION_REPORT;
        if (name === 'EEAC') return GenCommunityPermission.EEAC;
        if (name === 'ISER') return GenCommunityPermission.ISER;
        if (name === 'TREASURY') return GenCommunityPermission.TREASURY;
        if (name === 'RRCC') return GenCommunityPermission.RRCC;
        if (name === 'URBANNET') return GenCommunityPermission.URBANNET;
        if (name === 'ESF_FIELD_PHOTO') return GenCommunityPermission.ESF_FIELD_PHOTO;
        if (name === 'UAS') return GenCommunityPermission.UAS;
        if (name === 'WAO') return GenCommunityPermission.WAO;
        if (name === 'RADR') return GenCommunityPermission.RADR;
        if (name === 'SLTT') return GenCommunityPermission.SLTT;
        throw new Error('Unexpected value for GenCommunityPermission:' + name);
    }

    static values(): GenCommunityPermission[] {
        return [
            GenCommunityPermission.EIL,
            GenCommunityPermission.HEADOUT,
            GenCommunityPermission.FEDERAL_SITUATION_REPORT,
            GenCommunityPermission.STATE_SITUATION_REPORT,
            GenCommunityPermission.EEAC,
            GenCommunityPermission.ISER,
            GenCommunityPermission.TREASURY,
            GenCommunityPermission.RRCC,
            GenCommunityPermission.URBANNET,
            GenCommunityPermission.ESF_FIELD_PHOTO,
            GenCommunityPermission.UAS,
            GenCommunityPermission.WAO,
            GenCommunityPermission.RADR,
            GenCommunityPermission.SLTT,
        ];
    }

    toJSON(): string {
        return this.name;
    }

    toString(): string {
        return this.name;
    }
}

