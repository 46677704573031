import {GenDatapoint} from '../../../generated/serverModels/GenDatapoint';
import * as moment from 'moment';
import {EilDocument} from '../modules/management/classes/eil-document';

export class EilData extends GenDatapoint {
    static newThresholdInHours = 4;

    public documents: EilDocument[];

    public errors: string[] = [];

    public editing: boolean;
    public expanded: boolean;

    public formattedMetadata: string;

    constructor(datapoint?: EilData) {
        super(datapoint);

        // this.coordinateConflicts = this.coordinateConflicts || [];
        // this.nameConflicts = this.nameConflicts || [];

        if (this.documents) {
            this.documents = this.documents.map((doc) => new EilDocument(doc, this));
        } else {
            this.documents = [];
        }
    }

    // public parseFromSpreadSheetData(data: any): EilData {
    //     const pointData = data['lat/lon'] || '/';
    //     const coordinates = (pointData as string).split('/').map((v) => parseFloat(v));
    //     this.name = data['facilityName'] || 'N/A';
    //     this.description = data['connections'] || 'N/A';
    //     this.latitude = coordinates[0];
    //     this.longitude = coordinates[1];
    //     this.active = true;
    //     this.documents = [];
    //
    //     return this;
    // }
    //
    // public hasConflicts(): boolean {
    //     return this.nameConflicts.length > 0 || this.coordinateConflicts.length > 0;
    // }
    //
    // public conflictCount(): number {
    //     if (this.hasConflicts()) {
    //         let conflicts = 0;
    //         conflicts += this.nameConflicts.length > 0 ? 1 : 0;
    //         conflicts += this.coordinateConflicts.length > 0 ? 1 : 0;
    //         return conflicts;
    //     }
    //     return 0;
    // }
    //
    // public documentCount(): number {
    //     return this.documents.length;
    // }
    //
    // public newDocumentCount(): number {
    //     return this.documents.filter((doc) => {
    //         return doc.uploadDate.isAfter(moment().subtract(EilData.newThresholdInHours, 'hours'));
    //     }).length;
    // }
    //
    // private formatMetadata(): void {
    //     const parsed = JSON.parse(this.metadata);
    //
    //     this.formattedMetadata = Object.keys(parsed)
    //         .map((key) => parsed[key])
    //         .sort((a: any, b: any) => {
    //             if (a.order > b.order) {
    //                 return 1;
    //             } else if (a.order < b.order) {
    //                 return -1;
    //             }
    //             return 0;
    //         })
    //         .map((v) => `${v.display}: ${v.value}`)
    //         .join('\n\n');
    // }
}
