<eaglei-leaflet-layer-header [layer]="this" (activeToggled)="toggleLayer($event)" [hideLegend]="true"></eaglei-leaflet-layer-header>

<ng-container *ngIf="isActive">
    <div #legendElement>
        <div>
            <svg width="150" height="20">
                <line x1="10" y1="15" x2="20" y2="5" style="stroke: #dc190f; stroke-width: 2" />
                <text x="28" y="15" fill="#707070" style="font-size: 14px; color: #707070">Critical Notices</text>
            </svg>
        </div>
        <div>
            <svg width="150" height="20">
                <line x1="10" y1="15" x2="20" y2="5" style="stroke: #707070; stroke-width: 2" />
                <text x="28" y="15" fill="#707070" style="font-size: 14px; color: #707070">Noncritical Notices</text>
            </svg>
        </div>
        <div>
            <svg width="300" height="20">
                <circle cx="15" cy="10" r="8" fill="#B5140C" />
                <text x="12.1" y="13.1" fill="white" style="font-size: 10px">2</text>
                <text x="28" y="15" fill="#707070" style="font-size: 14px; color: #707070">Multiple notices on a single pipeline</text>
            </svg>
        </div>
    </div>
</ng-container>

<div class="hide" [class.show]="isActive">
    <eaglei-filter-container
        *ngIf="mapLayer.filters"
        [filters]="mapLayer.filters"
        (filterChange)="updateFilterQuery($event)"></eaglei-filter-container>
</div>
