import {GenFilter} from '../../../../../generated/serverModels/GenFilter';

export class Filter extends GenFilter {
    constructor(filter?: Filter) {
        super(filter);
    }

    public getParams(): any[] {
        if (this.values === undefined) {
            return [];
        }
        let ret = [];
        try {
            ret = JSON.parse(this.values);
        } catch (e) {
            console.error(e);
        }
        return ret;
    }
}
