import {AfterViewInit, Directive, ElementRef, Input, OnDestroy, ViewChild} from '@angular/core';
import {LeafletMapLayer} from './leaflet-map-layer';
import {LeafletEagleiSource} from './leaflet-eaglei-source';
import {LeafletLayerLegend} from './leaflet-layer-legend';
import {Filter} from './filter';
import {LeafletLegendService} from '../services/leaflet-legend.service';
import {DataInjector} from '../../../services/data.service';
import {GenLegendType} from '../../../../../generated/serverModels/GenLegendType';
import {Subject} from 'rxjs';

@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class EagleILayer<S extends LeafletEagleiSource = any> implements AfterViewInit, OnDestroy {
    @ViewChild('legendElement') set legendElement(target: ElementRef<HTMLElement>) {
        if (target && this.mapLayer?.legend?.type === GenLegendType.HARD_CODED) {
            DataInjector.get(LeafletLegendService)
                .getLegend(this, target.nativeElement)
                .subscribe((legend) => (this.legend = legend));
        }
    }

    get legend(): LeafletLayerLegend[] {
        return this._legend;
    }

    set legend(value: LeafletLayerLegend[]) {
        this._legend = value;

        const allLegends = LeafletLegendService.legends.getValue();
        const index = allLegends.findIndex((l) => l.name === this.mapLayer.displayName);

        if (index !== -1) {
            allLegends[index].legends = value;
        } else {
            allLegends.push({name: this.mapLayer.displayName, legends: this._legend});
        }
    }

    @Input() mapLayer: LeafletMapLayer;

    private _legend: LeafletLayerLegend[] = [];
    public layerSource: S;
    public isActive: boolean = false;
    public opacity: number = 80;
    public hideLegend: boolean;
    public destroy$ = new Subject();

    protected constructor() {}

    ngAfterViewInit() {
        if (this.mapLayer.defaultActive) {
            this.isActive = true;

            if (this.mapLayer.userAdded) {
                this.toggleLayer(this.isActive);
            }
        }
        this.afterViewInit();

        this.hideLegend = ['hurricaneTract'].includes(this.mapLayer.uiHandle);
    }

    ngOnDestroy() {
        this.destroy$.complete();
        this.destroy$.unsubscribe();
    }

    public changeOpacity(value?: number): void {
        if (!this.layerSource) {
            return;
        }
        this.opacity = value || this.opacity;

        this.layerSource.changeOpacity(this.opacity / 100);
    }

    public getFilter(property: string): Filter {
        return this.mapLayer.filters.find((f) => f.filterProperty === property);
    }

    abstract toggleLayer(activeState: boolean, zoomToFeatures?: boolean): void;

    abstract afterViewInit(): void;
}
