<eaglei-leaflet-layer-header
    [layer]="this"
    [hideLegend]="true"
    (activeToggled)="toggleLayer($event)"
    (dateRangeUpdate)="toggleLayer(isActive)"
    [hideToggle]="onLite()">
</eaglei-leaflet-layer-header>

<div class="layer-actions hide" [class.show]="isActive">
    <div
        *ngIf="!useMobileLayout() && !onLite()"
        class="chart-toggle"
        matTooltip="Please refer to the Customers Out By Selected Region Report for granular zip code data"
        matTooltipShowDelay="250"
        matTooltipPosition="above"
        [matTooltipDisabled]="filters.aggregationLevel !== GenOutageAggregationLevel.zip">
        <button
            mat-stroked-button
            class="wide"
            [disabled]="filters.aggregationLevel === GenOutageAggregationLevel.zip"
            (click)="toggleChart()">
            Open Chart
        </button>
    </div>

    <div class="legend">
        <eaglei-nom-legend (hideOverrides)="layerSource.redraw()"></eaglei-nom-legend>
    </div>

    <div class="aggregation">
        <mat-form-field appearance="outline" class="wide">
            <mat-label>Show Outages By</mat-label>
            <mat-select [value]="filters.aggregationLevel" (selectionChange)="updateAggregation($event)">
                <mat-option *ngFor="let level of aggregationLevels" [value]="level">
                    {{ level | aggregationLevel }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="filters">
        <div class="clickable" (click)="filtersExpanded = !filtersExpanded">
            <mat-icon fontSet="fas" fontIcon="fa-filter" inline></mat-icon>
            <span class="label no-margin-bottom">Filter Locations</span>
            <mat-icon fontSet="fas" [fontIcon]="filtersExpanded ? 'fa-chevron-up' : 'fa-chevron-down'" inline></mat-icon>
        </div>

        <div class="hide" [class.show]="filtersExpanded">
            <eaglei-location-chip-filter (change)="filterStates($event)"></eaglei-location-chip-filter>
        </div>
    </div>
</div>
