import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '../../../../services/authentication.service';
import {GenRoleDefinition} from '../../../../../../generated/serverModels/GenRoleDefinition';
import {Community} from '../../classes/community';
import {CommunityService} from '../../services/community.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {filter, take} from 'rxjs/operators';
import {Router} from '@angular/router';
import {User} from '../../../user/classes/user';
import {GenCommunityUserStatus} from '../../../../../../generated/serverModels/GenCommunityUserStatus';
import {CommunityAccessModalComponent} from '../../modals/community-access-modal/community-access-modal.component';
import {CommunityUser} from '../../classes/community-user';
import {ModalConfig} from '../../../../classes/modal-config';
import {ApplicationConfig} from '../../../../classes/application-config';
import {HttpInterceptorService} from '../../../../services/http-interceptor.service';

interface CommunityState {
    display: string;
    icon: string;
    id: string;
}

@Component({
    selector: 'eaglei-community-landing',
    templateUrl: './community-landing.component.html',
    styleUrls: ['./community-landing.component.scss'],
})
export class CommunityLandingComponent implements OnInit {
    get isAdmin(): any {
        return this._isAdmin;
    }

    private readonly _isAdmin: boolean;
    public readonly user: User;
    private communities: Community[] = [];
    public filteredCommunities: Community[] = [];

    private searchText: string;

    public currentState: 'all' | 'member' | 'request' = 'all';
    public communityStates: CommunityState[] = [
        {id: 'all', icon: 'fa-users', display: 'All Communities'},
        {id: 'member', icon: 'fa-user-friends', display: 'My Communities'},
        {id: 'request', icon: 'fa-user-clock', display: 'Requests'},
    ];

    constructor(
        private auth: AuthenticationService,
        private communityService: CommunityService,
        private dialog: MatDialog,
        private popup: MatSnackBar,
        private router: Router
    ) {
        this.user = this.auth.authenticatedUser.getValue();
        this._isAdmin = this.user.hasRole(GenRoleDefinition.ROLE_COMMUNITY_MANAGER);

        this.getAllCommunities();
    }

    ngOnInit() {}

    // CRUD Methods
    private getAllCommunities(): void {
        HttpInterceptorService.clearInterceptor('community');
        HttpInterceptorService.pendingRequests['community'] = this.communityService.getAllCommunities().subscribe((res) => {
            HttpInterceptorService.deleteFromInterceptor('community');
            this.communities = res;
            this.searchCommunities(this.searchText);
        });
    }

    private getUserCommunities(status: GenCommunityUserStatus = GenCommunityUserStatus.PENDING): void {
        this.communityService.getUserCommunities(this.user.id, status).subscribe((res) => {
            this.communities = res;
            this.searchCommunities(this.searchText);
        });
    }

    // Filter Methods
    public searchCommunities(text: string): void {
        this.searchText = text;
        if (text) {
            this.filteredCommunities = this.communities.filter((c) => {
                return c.name.toLowerCase().includes(text.toLowerCase().trim());
            });
        } else {
            this.filteredCommunities = this.communities;
        }
    }

    // Modal Methods

    // Community Methods
    public requestAccess(community: Community, event: MouseEvent): void {
        event.stopPropagation();

        const config: MatDialogConfig = {
            disableClose: true,
            width: ModalConfig.getModalWidth(500),
            data: community,
        };

        this.dialog
            .open(CommunityAccessModalComponent, config)
            .afterClosed()
            .pipe(
                filter((res) => !!res),
                take(1)
            )
            .subscribe((res: CommunityUser) => {
                if (!community.isMember(res.userId)) {
                    community.users.push(res);
                }
            });
    }

    // Utility Methods
    public goToCommunity(community: Community): void {
        if (community.isActiveMember(this.user.id) || this.isAdmin) {
            // noinspection JSIgnoredPromiseFromCall
            this.router.navigate(['/app/communities/', community.id]);
        }
    }

    public setCurrentState(state: CommunityState): void {
        this.currentState = state.id as any;

        switch (state.id) {
            case 'all':
                this.getAllCommunities();
                break;
            case 'member':
                this.getUserCommunities(GenCommunityUserStatus.APPROVED);
                break;
            case 'request':
                this.getUserCommunities();
                break;
        }
    }

    public useMobileLayout(checkPhone: boolean = false): boolean {
        if (checkPhone) {
            return ApplicationConfig.onPhone();
        }
        return ApplicationConfig.useMobileLayout();
    }

    public isSearchTextActive(): boolean {
        return this.searchText && this.searchText.trim().length !== 0;
    }

    getMaskMessage() {
        if (this.communities.length !== 0) {
            return ' No community match search text.';
        } else if (this.currentState === 'all') {
            return 'There are currently no communities.';
        } else if (this.currentState === 'member') {
            return 'You are not a member of any communities.';
        } else if (this.currentState === 'request') {
            return 'You have no pending requests.';
        }
    }
}
