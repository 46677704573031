import {GenApiKeyRequest} from '../../../../../generated/serverModels/GenApiKeyRequest';
import {ApiKey} from './api-key';

export class ApiKeyRequest extends GenApiKeyRequest {
    get requestedDateValue(): number {
        return this._requestedDateValue;
    }

    private readonly _requestedDateValue: number;

    constructor(request?: ApiKeyRequest) {
        super(request);

        if (this.requestedSystems === undefined) {
            this.requestedSystems = [];
        }

        if (!request) {
            this.reason = '';
        } else {
            this._requestedDateValue = request.requestDate.valueOf();
        }
    }

    public isPending(): boolean {
        return this.status ? this.status.toLowerCase() === 'pending' : false;
    }

    public isApproved(): boolean {
        return this.status ? this.status.toLowerCase() === 'approved' : false;
    }

    public isDenied(): boolean {
        return this.status ? this.status.toLowerCase() === 'denied' : false;
    }

    public fromApiKey(apiKey: ApiKey): ApiKeyRequest {
        this.system = apiKey.system;
        this.dataService = apiKey.dataService;
        this.ogcService = apiKey.ogcService;
        return this;
    }
}
