import {GenWidgetName} from 'frontend/generated/serverModels/GenWidgetName';
import {WidgetDetail} from '../../../../widget/classes/widget-detail';
import {Widget} from '../../../../widget/classes/widget';
import {GenCommunityPermission} from '../../../../../../../generated/serverModels/GenCommunityPermission';

export const adminWidgets = [
    {
        x: 0,
        y: 0,
        cols: 2,
        rows: 3,
        widgetName: GenWidgetName.EVENT_RRCC,
        detail: new WidgetDetail(),
    },
    {
        x: 2,
        y: 0,
        cols: 4,
        rows: 3,
        widgetName: GenWidgetName.EIL_DOCUMENTS,
        detail: new WidgetDetail().setWidget(new Widget().setCommunityPermissions(GenCommunityPermission.EIL)),
    },
    {
        x: 8,
        y: 0,
        cols: 2,
        rows: 3,
        widgetName: GenWidgetName.SITUATION_REPORTS,
        detail: new WidgetDetail().setWidget(new Widget().setCommunityPermissions(GenCommunityPermission.FEDERAL_SITUATION_REPORT)),
    },
    {
        x: 5,
        y: 0,
        cols: 3,
        rows: 3,
        widgetName: GenWidgetName.EVENT_PORTS,
        detail: new WidgetDetail(),
    },
    {
        x: 4,
        y: 0,
        cols: 2,
        rows: 3,
        widgetName: GenWidgetName.SYSTEM_NEWS,
        detail: new WidgetDetail(),
    },
];
