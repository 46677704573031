<div class="container">
    <div class="content">
        <div class="widget-container">
            <div class="number-container">
                <div class="number-and-icon">
                    <span class="big-number">{{ notices }}</span>
                </div>
                <span class="sub-text">
                    <mat-icon inline fontSet="fas" [fontIcon]="getNoticeIcon()" class="icon" [class.red]="type === 'critical'"></mat-icon>
                    {{ getNoticeTitle() }} NOTICES
                </span>
                <br />
            </div>

            <div class="clickable invoice-icon" (click)="viewReport()">
                <mat-icon inline fontSet="fas" fontIcon="fa-file-invoice" class="icon big-icon"></mat-icon>
                <span class="sub-text center-spacing view-report">View Report</span>
            </div>
            <eaglei-info-icon [attributionModalText]="attributionModalText" [widgetName]="widgetName"></eaglei-info-icon>
        </div>
    </div>
</div>
