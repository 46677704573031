<mat-card>
    <mat-card-title>
        <span>{{ currentOutageType }} Oil Planned Outages</span>
        <button mat-icon-button (click)="close()">
            <mat-icon fontSet="fas" fontIcon="fa-times"></mat-icon>
        </button>
    </mat-card-title>
    <mat-card-content #content>
        <div>
            <span
                #noticeSubject
                class="notice-title ellipsis"
                [matTooltip]="data.name"
                [matTooltipDisabled]="!isEllipsis(noticeSubject)"
                matTooltipPosition="above"
                matTooltipShowDelay="250">
                {{ data.name }}
            </span>

            <div class="navigation-icons">
                <div *ngIf="!isOutageFirst()" (click)="previousOutage()" class="navigation-button clickable">
                    <mat-icon fontSet="fas" fontIcon="fa-chevron-left" inline></mat-icon>
                    <span>Previous Notice</span>
                </div>

                <div *ngIf="!isOutageLast()" (click)="nextOutage()" class="navigation-button clickable">
                    <span>Next Notice</span>
                    <mat-icon fontSet="fas" fontIcon="fa-chevron-right" inline></mat-icon>
                </div>
            </div>
        </div>

        <br />

        <div class="metric-container">
            <div>
                <p class="sub-title">Unit Name:</p>
                <p class="value">{{ currentOutage.unit_name }}</p>
            </div>

            <div>
                <p class="sub-title">Outage Type:</p>
                <p class="value">{{ currentOutage.outage_type }}</p>
            </div>

            <div>
                <p class="sub-title">Status:</p>
                <p class="value">{{ currentOutage.status }}</p>
            </div>

            <div>
                <p class="sub-title">Expected Start Date:</p>
                <p class="value">{{ currentOutage.expected_start_date | momentDate }}</p>
            </div>

            <div>
                <p class="sub-title">Last Updated:</p>
                <p class="value">{{ currentOutage.last_updated | momentDate }}</p>
            </div>

            <div>
                <p class="sub-title">Confidence ID:</p>
                <p class="value">{{ currentOutage.confidence_id }}</p>
            </div>

            <div>
                <p class="sub-title">Monitored:</p>
                <p class="value">{{ currentOutage.monitored }}</p>
            </div>

            <div>
                <p class="sub-title">Expected Days in Turnaround:</p>
                <p class="value">{{ currentOutage.expected_days_in_turnaround }}</p>
            </div>

            <div>
                <p class="sub-title">Expected End Date:</p>
                <p class="value">{{ currentOutage.expected_end_date | momentDate }}</p>
            </div>
        </div>

        <p class="sub-title">Comments:</p>
        <span class="comments value">{{ currentOutage.comments }}</span>
    </mat-card-content>
</mat-card>
