import {GenUrbanNetDisruption} from '../../../../generated/serverModels/GenUrbanNetDisruption';
import {UrbannetEffect} from './urbannet-effect';
import {GenUrbanNetFemaType} from '../../../../generated/serverModels/GenUrbanNetFemaType';
import {PopoverElement} from '../../../app/classes/popover-element';
import {getUrbannetAssetPopover} from '../functions/get-urbannet-asset-popover';
import {getUrbannetAssetProperties} from '../functions/get-urbannet-properties';

export class UrbannetDisruption extends GenUrbanNetDisruption {
    public effects: UrbannetEffect[] = [];
    public parsedProperties: any;
    public name: string = 'Not Available';
    public type: string = 'Not Available';

    public counts: {[key: string]: number} = {};

    public popoverData: PopoverElement[] = [];

    constructor(disruption?: UrbannetDisruption) {
        super(disruption);

        if (disruption) {
            this.parsedProperties = getUrbannetAssetProperties(disruption);
            this.name = this.parsedProperties.name;
            this.type = disruption.nodeId.split(':')[0];
            this.popoverData = getUrbannetAssetPopover(disruption);
            this.effects = disruption.effects.map((effect) => new UrbannetEffect(effect));

            let total = 0;
            GenUrbanNetFemaType.values().forEach((value) => {
                const count = disruption.effects.filter((effect) => effect.femaType.toString() === value.toString()).length;
                this.counts[value.name] = count;
                total += count;
            });

            this.counts['TOTAL'] = total;
        }
    }
}
