<div class="hide-overflow">
    <div class="widget-header">
        <div class="label">
            <p class="title">URBAN-NET Simulations</p>

            <mat-form-field class="filter-select center" appearance="outline">
                <mat-label>Report</mat-label>
                <mat-select placeholder="Report" [formControl]="reportControl">
                    <mat-option
                        *ngFor="let report of reports"
                        [matTooltip]="report.advisory"
                        matTooltipPosition="above"
                        matTooltipShowDelay="250"
                        [value]="report"
                        >{{ report.advisory }}</mat-option
                    >
                </mat-select>
            </mat-form-field>
        </div>

        <div class="actions">
            <form [formGroup]="filterForm">
                <mat-form-field appearance="outline">
                    <mat-label>Name</mat-label>
                    <input matInput formControlName="name" />
                </mat-form-field>

                <mat-form-field class="filter-select center" appearance="outline">
                    <mat-label>Disruption Type</mat-label>
                    <mat-select placeholder="Disruption Type" formControlName="type" multiple>
                        <mat-option *ngFor="let type of disruptionTypes" [value]="type">{{
                            type | replaceCharacter | titlecase
                        }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </form>
            <a
                *ngIf="dataSource.data?.length"
                class="report-link"
                mat-stroked-button
                [routerLink]="['/app/reports/urbannet']"
                [queryParams]="{eventId}"
                >View Report
            </a>
        </div>
    </div>

    <div class="widget-content table-container">
        <table mat-table [dataSource]="dataSource" matSort [matSortDisableClear]="true" matSortActive="total" matSortDirection="desc">
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    <span>Name</span>
                    <eaglei-table-sort-header [columnName]="'name'" [sort]="sort"></eaglei-table-sort-header>
                </th>
                <td
                    #disruptionName
                    mat-cell
                    *matCellDef="let disruption"
                    class="ellipsis"
                    [matTooltip]="disruption.name"
                    [matTooltipDisabled]="!isEllipsis(disruptionName)">
                    {{ disruption.name }}
                </td>
            </ng-container>

            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Disruption Type
                    <eaglei-table-sort-header [columnName]="'type'" [sort]="sort"></eaglei-table-sort-header>
                </th>
                <td mat-cell *matCellDef="let disruption">
                    <span> {{ disruption.type | replaceCharacter | titlecase }}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="communications">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="right-cell">
                    <div class="tooltip-header">
                        <mat-icon
                            inline
                            fontSet="fas"
                            fontIcon="fa-info-circle"
                            [matTooltip]="tooltipMap[femaTypes.COMMUNICATIONS.name]"
                            matTooltipPosition="above">
                        </mat-icon>
                        <span>Communications</span>
                        <eaglei-table-sort-header [columnName]="'communications'" [sort]="sort"></eaglei-table-sort-header>
                    </div>
                </th>
                <td mat-cell *matCellDef="let disruption" class="right-cell">
                    <span> {{ disruption.counts[femaTypes.COMMUNICATIONS.name] }}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="food_water_shelter">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="right-cell">
                    <div class="tooltip-header">
                        <mat-icon
                            inline
                            fontSet="fas"
                            fontIcon="fa-info-circle"
                            [matTooltip]="tooltipMap[femaTypes.FOOD_WATER_SHELTER.name]"
                            matTooltipPosition="above">
                        </mat-icon>
                        <span>Food Water Shelter</span>
                        <eaglei-table-sort-header [columnName]="'food_water_shelter'" [sort]="sort"></eaglei-table-sort-header>
                    </div>
                </th>
                <td mat-cell *matCellDef="let disruption" class="right-cell">
                    <span> {{ disruption.counts[femaTypes.FOOD_WATER_SHELTER.name] }}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="other">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="ei-sm-column right-cell">
                    <span>Other</span>
                    <eaglei-table-sort-header [columnName]="'other'" [sort]="sort"></eaglei-table-sort-header>
                </th>
                <td mat-cell *matCellDef="let disruption" class="ei-sm-column right-cell">
                    <span> {{ disruption.counts[femaTypes.OTHER.name] }}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="energy">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="ei-sm-column right-cell">
                    <div class="tooltip-header">
                        <mat-icon
                            inline
                            fontSet="fas"
                            fontIcon="fa-info-circle"
                            [matTooltip]="tooltipMap[femaTypes.ENERGY.name]"
                            matTooltipPosition="above">
                        </mat-icon>
                        <span>Energy</span>
                        <eaglei-table-sort-header [columnName]="'energy'" [sort]="sort"></eaglei-table-sort-header>
                    </div>
                </th>
                <td mat-cell *matCellDef="let disruption" class="ei-sm-column right-cell">
                    <span> {{ disruption.counts[femaTypes.ENERGY.name] }}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="safety_and_security">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="right-cell">
                    <div class="tooltip-header">
                        <mat-icon
                            inline
                            fontSet="fas"
                            fontIcon="fa-info-circle"
                            [matTooltip]="tooltipMap[femaTypes.SAFETY_AND_SECURITY.name]"
                            matTooltipPosition="above">
                        </mat-icon>
                        <span>Safety and Security</span>
                        <eaglei-table-sort-header [columnName]="'safety_and_security'" [sort]="sort"></eaglei-table-sort-header>
                    </div>
                </th>
                <td mat-cell *matCellDef="let disruption" class="right-cell">
                    <span> {{ disruption.counts[femaTypes.SAFETY_AND_SECURITY.name] }}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="health_and_medical">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="right-cell">
                    <div class="tooltip-header">
                        <mat-icon
                            inline
                            fontSet="fas"
                            fontIcon="fa-info-circle"
                            [matTooltip]="tooltipMap[femaTypes.HEALTH_AND_MEDICAL.name]"
                            matTooltipPosition="above">
                        </mat-icon>
                        <span>Health and Medical</span>
                        <eaglei-table-sort-header [columnName]="'health_and_medical'" [sort]="sort"></eaglei-table-sort-header>
                    </div>
                </th>
                <td mat-cell *matCellDef="let disruption" class="right-cell">
                    <span> {{ disruption.counts[femaTypes.HEALTH_AND_MEDICAL.name] }}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="transportation">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="right-cell">
                    <div class="tooltip-header">
                        <mat-icon
                            inline
                            fontSet="fas"
                            fontIcon="fa-info-circle"
                            [matTooltip]="tooltipMap[femaTypes.TRANSPORTATION.name]"
                            matTooltipPosition="above">
                        </mat-icon>
                        <span>Transportation</span>
                        <eaglei-table-sort-header [columnName]="'transportation'" [sort]="sort"></eaglei-table-sort-header>
                    </div>
                </th>
                <td mat-cell *matCellDef="let disruption" class="right-cell">
                    <span> {{ disruption.counts[femaTypes.TRANSPORTATION.name] }}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="hazardous_material">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="right-cell">
                    <div class="tooltip-header">
                        <mat-icon
                            inline
                            fontSet="fas"
                            fontIcon="fa-info-circle"
                            [matTooltip]="tooltipMap[femaTypes.HAZARDOUS_MATERIAL.name]"
                            matTooltipPosition="above">
                        </mat-icon>
                        <span>Hazardous Material</span>
                        <eaglei-table-sort-header [columnName]="'hazardous_material'" [sort]="sort"></eaglei-table-sort-header>
                    </div>
                </th>
                <td mat-cell *matCellDef="let disruption" class="right-cell">
                    <span> {{ disruption.counts[femaTypes.HAZARDOUS_MATERIAL.name] }}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="total">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="ei-sm-column right-cell">
                    <span>Total</span>
                    <eaglei-table-sort-header [columnName]="'total'" [sort]="sort"></eaglei-table-sort-header>
                </th>
                <td mat-cell *matCellDef="let disruption" class="ei-sm-column right-cell">
                    <span> {{ disruption.counts['TOTAL'] }}</span>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columns"></tr>
            <tr mat-row *matRowDef="let row; columns: columns; let odd = odd"></tr>
        </table>

        <div class="auto-mask hide-mask" *ngIf="dataSource?.filteredData?.length === 0">
            <p class="mask-message">There are no results that match the current filters</p>
        </div>
    </div>

    <div class="widget-footer">
        <mat-paginator hidePageSize [pageSizeOptions]="[7]" showFirstLastButtons></mat-paginator>
    </div>
</div>
