import {GenMapLayerGroup} from '../../../../../generated/serverModels/GenMapLayerGroup';
import {LeafletMapLayer} from './leaflet-map-layer';

export class MapLayerGroup extends GenMapLayerGroup {
    public expanded = true;
    public layers: LeafletMapLayer[];
    public orderChanged = false;
    public orderingLayers = false;

    constructor(group?: MapLayerGroup) {
        super(group);

        if (group && group.layers) {
            this.layers = group.layers.map((layer) => new LeafletMapLayer(layer));
        }
    }
}
