import {Component} from '@angular/core';
import {PetroleumFlowSource} from '../../sources/petroleum-flow-source';
import {LayerService} from '../../services/layer.service';
import {PetroleumFlowFilter} from '../../filters/petroleum-flow-filter';
import {map} from 'rxjs/operators';
import {EagleILayer} from '../../classes/eagle-i-layer';
import {forkJoin} from 'rxjs';
import {IFilterChange} from '../../interfaces/filter-change.interface';
import {PaddData} from '../../classes/padd-data';
import {HttpInterceptorService} from '../../../../services/http-interceptor.service';
import {LeafletLegendService} from '../../services/leaflet-legend.service';
import {GenLegendType} from '../../../../../../generated/serverModels/GenLegendType';

@Component({
    selector: 'eaglei-petroleum-flow-layer',
    templateUrl: './petroleum-flow-layer.component.html',
    styleUrls: ['./petroleum-flow-layer.component.scss'],
})
export class PetroleumFlowLayerComponent extends EagleILayer<PetroleumFlowSource> {
    private geometryUrl = 'api/eia/padd/geometry';
    private filters: PetroleumFlowFilter = new PetroleumFlowFilter();

    constructor(private layerService: LayerService, private legendService: LeafletLegendService) {
        super();
    }

    afterViewInit(): void {
        this.mapLayer.legend.type = GenLegendType.HARD_CODED;
    }

    toggleLayer(activeState: boolean): void {
        if (activeState) {
            if (!this.layerSource) {
                this.filters.resource = this.getFilter('resource').defaultValue;
                this.filters.dataType = this.getFilter('values').defaultValue;

                this.layerSource = new PetroleumFlowSource(this.mapLayer);
            }

            this.getFlowData();
        } else {
            this.layerSource.removeFromMap();
        }
    }

    private getFlowData(): void {
        const geometryCall = this.layerService.getJsonFromUrl(this.geometryUrl);
        const dataCall = this.layerService.getJsonFromUrl(this.mapLayer.serviceurl, true);

        const calls = [dataCall];

        if (PetroleumFlowSource.regionGeometries.length === 0) {
            calls.push(geometryCall);
        }

        HttpInterceptorService.clearInterceptor(this.mapLayer.interceptorKey);
        HttpInterceptorService.pendingRequests[this.mapLayer.interceptorKey] = forkJoin(calls)
            .pipe(
                map((res: any) => {
                    res[0] = res[0].map((data) => new PaddData(data));
                    return res;
                })
            )
            .subscribe((res) => {
                HttpInterceptorService.deleteFromInterceptor(this.mapLayer.interceptorKey);
                if (res[1]) {
                    PetroleumFlowSource.regionGeometries = res[1];
                }

                this.layerSource.processFeatures(res[0]);

                this.layerSource.applyFilter(this.filters.resource);
                this.layerSource.updateStyle(this.filters.dataType);

                this.layerSource.addToMap();
                this.mapLayer.endLoading();
            });
    }

    updateFilters(event: IFilterChange) {
        const value: string = event.value[0].value as string;
        if (event.filter.filterProperty === 'resource') {
            this.filters.resource = value;
            this.layerSource.applyFilter(value);
        } else if (event.filter.filterProperty === 'values') {
            this.filters.dataType = value;
            this.layerSource.updateStyle(value);
        }
    }
}
