
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

export class GenDatapointType {
    static readonly INSITE: GenDatapointType = new GenDatapointType('INSITE');
    static readonly TEARSHEET: GenDatapointType = new GenDatapointType('TEARSHEET');
    static readonly OTHER: GenDatapointType = new GenDatapointType('OTHER');

    public readonly name: string;

    private constructor(name: string) {
        this.name = name;
    }

    static forName(name: string) {
        if (name === 'INSITE') return GenDatapointType.INSITE;
        if (name === 'TEARSHEET') return GenDatapointType.TEARSHEET;
        if (name === 'OTHER') return GenDatapointType.OTHER;
        throw new Error('Unexpected value for GenDatapointType:' + name);
    }

    static values(): GenDatapointType[] {
        return [
            GenDatapointType.INSITE,
            GenDatapointType.TEARSHEET,
            GenDatapointType.OTHER,
        ];
    }

    toJSON(): string {
        return this.name;
    }

    toString(): string {
        return this.name;
    }
}

