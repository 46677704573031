<div class="new-design">
    <div class="page-title">
        <button mat-icon-button (click)="goBack()">
            <mat-icon>arrow_back</mat-icon>
        </button>
        <span>Reset Password</span>
    </div>

    <div class="request-form">
        <mat-form-field appearance="outline">
            <mat-label>Username or email</mat-label>
            <input type="text" matInput [formControl]="nameControl" />
            <mat-error>Required</mat-error>
        </mat-form-field>
    </div>

    <div class="actions">
        <button mat-flat-button color="primary" class="wide" (click)="requestPasswordReset()" [disabled]="nameControl.invalid">
            Reset Password
        </button>
    </div>

    <div class="response-message">
        <div *ngIf="passwordRequestSuccessful" class="success-text">
            <i class="fa fa-check"></i> Your password reset request was successful.
            <p>Check your email for a link to reset your password.</p>
        </div>

        <div *ngIf="responseCode === 401 || responseCode === 404" class="failure-text">
            <i class="fa fa-exclamation failure-text"></i> There was an error resetting your password.
            <p>If you entered the correct username or email,</p>
            <a href="mailto:eagle-i@ornl.gov">email the admin for assistance</a>.
        </div>

        <div *ngIf="responseCode === 403" class="failure-text">
            <i class="fa fa-exclamation failure-text"></i> Your account has been disabled.
            <a (click)="goToReactivation()">Request account reactivation</a>.
        </div>

        <div *ngIf="responseCode === 423" class="failure-text">
            Account Inactive -- Your account request is currently being reviewed contact
            <a href="mailto:eagle-i@ornl.gov">eagle-i@ornl.gov</a> for question
        </div>
    </div>
</div>
