import {Injectable, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Observable, of, Subject, throwError} from 'rxjs';
import {GenMapPointUtility} from '../../../../../generated/serverModels/GenMapPointUtility';
import {HttpClient, HttpParams} from '@angular/common/http';
import {IPoint} from '../../layer/interfaces/point.interface';
import {catchError, debounceTime, map, retry, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {ResponseWrapper} from '../../../classes/response-wrapper';
import {EsriResponse} from '../interfaces/esri-search-response';

@Injectable({
    providedIn: 'root',
})
export class UtilityLookupService {
    private utilityUrl = 'api/utility/map-point-utilities';
    private textSearchUrl = 'api/esri/geocode';

    private point$$: BehaviorSubject<IPoint> = new BehaviorSubject<IPoint>(null);
    public point$: Observable<IPoint> = this.point$$.asObservable();

    private searchTerm$$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    public searchTerm$: Observable<string> = this.searchTerm$$.asObservable();

    private utilityInformation$$: BehaviorSubject<GenMapPointUtility[]> = new BehaviorSubject<GenMapPointUtility[]>([]);
    public utilityInformation$: Observable<GenMapPointUtility[]> = this.utilityInformation$$.asObservable();

    private addressInformation$$: BehaviorSubject<EsriResponse> = new BehaviorSubject<EsriResponse>({candidates: []});
    public addressInformation$: Observable<EsriResponse> = this.addressInformation$$.asObservable();

    private searching$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public searching$: Observable<boolean> = this.searching$$.asObservable();

    /**
     * This is a bit wonky. In order to utilize switchMap we have to act on the same stream every time.
     * We first create a Subject to trigger an emit. Then we create an observable version to subscribe to.
     * Technically you can subscribe to a subject, but I had difficulty getting it to work.
     *
     * In the constructor we subscribe to the stream and then we call .next() on the subject to trigger the stream.
     * switchMap will cancel previous requests correctly this way. That way the user gets the data from the last
     * location clicked regardless.
     *
     * - JDillon
     */
    private utilityResStream$$: Subject<IPoint> = new Subject<IPoint>();
    private utilityResStream$: Observable<GenMapPointUtility[]> = this.utilityResStream$$.asObservable().pipe(
        switchMap((point: IPoint) => {
            this.searching$$.next(true);
            this.utilityInformation$$.next([]);

            const options = {
                params: new HttpParams().set('lat', point.latitude).set('lon', point.longitude),
            };

            return this.http.get<ResponseWrapper<GenMapPointUtility>>(this.utilityUrl, options).pipe(
                tap((value: ResponseWrapper<GenMapPointUtility>) => {
                    this.utilityInformation$$.next(value.data);
                    this.searching$$.next(false);
                }),
                map((data) => data.data),
                catchError((e) => {
                    this.utilityInformation$$.next([]);
                    this.searching$$.next(false);
                    return of([]);
                })
            );
        }),
        retry()
    );

    private textSearchResStream$$: Subject<any> = new Subject<any>();
    private textSearchResStream$: Observable<any> = this.searchTerm$$.asObservable().pipe(
        debounceTime(500),
        switchMap((term: string) => {
            if (!term) {
                return of(null);
            }

            this.searching$$.next(true);

            const options = {
                params: new HttpParams().set('address', term),
            };

            return this.http.get<EsriResponse>(this.textSearchUrl, options).pipe(
                tap((value: EsriResponse) => {
                    this.addressInformation$$.next(value);
                    this.searching$$.next(false);
                }),
                map((data) => data)
            );
        })
    );

    constructor(private http: HttpClient) {
        this.utilityResStream$.subscribe();
        this.textSearchResStream$.subscribe();
    }

    public getUtilityInformation(point: IPoint): void {
        this.utilityInformation$$.next([]);
        this.point$$.next(point);
        this.utilityResStream$$.next(point);
    }

    public textSearchForUtilities(term: string): void {
        this.searchTerm$$.next(term);
    }

    public clearPreviousState(): void {
        this.utilityInformation$$.next([]);
        this.point$$.next(null);
        this.searchTerm$$.next('');
        this.addressInformation$$.next(null);
    }
}
