
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import { GenDataProvider } from './GenDataProvider';

export class GenDataProviderGroup {

    id: number;
    dataProviders: GenDataProvider[];
    iconUrl: string;
    name: string;
    ordering: number;
    uiHandle: string;

    constructor();
    constructor( jsonObj: any );
    constructor( genDataProviderGroup: GenDataProviderGroup);
    constructor( id: number );

    constructor( input?: GenDataProviderGroup|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            if ( input.dataProviders && input.dataProviders.map ) { // list [GenDataProvider]
                this.dataProviders = input.dataProviders.map( ( x: GenDataProvider ) => {
                    return new GenDataProvider( x );
                } );
            } else {
                this.dataProviders = input.dataProviders;
            }
            this.iconUrl = input.iconUrl; // string [string]
            this.name = input.name; // string [string]
            this.ordering = input.ordering; // int [number]
            this.uiHandle = input.uiHandle; // string [string]
       }
    }

    static jsonResponseToList(arr: any[]): GenDataProviderGroup[] {
        if (arr && arr.map) {
          return arr.map(a => new GenDataProviderGroup(a));
        } else {
          return [];
        }
    }
}

