<div class="dialog-header">
    <span>Select a user to replace you as the community manager.</span>
    <button mat-icon-button (click)="dialogRef.close()">
        <mat-icon fontSet="fas" fontIcon="fa-times"></mat-icon>
    </button>
</div>
<div class="dialog-content" #content>
    <ng-container *ngIf="users.length === 0">
        <div class="no-user-message">
            There are no users with enabled accounts in the community.
            <p>contact <a href="mailto:eagle-i@ornl.gov">eagle-i@ornl.gov</a> for assistance</p>
        </div>
    </ng-container>

    from
    <mat-radio-group [formControl]="selectionControl">
        <mat-radio-button [value]="user" *ngFor="let user of users"> {{ user.lastName }}, {{ user.firstName }} </mat-radio-button>
    </mat-radio-group>
</div>

<div class="dialog-actions">
    <button mat-flat-button color="primary" [disabled]="selectionControl.invalid" (click)="submit()">Submit</button>
</div>
