import {AfterViewInit, Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SystemNews} from '../../classes/system-news';
import {BaseModal} from '../../../../classes/base-modal';

@Component({
    selector: 'eaglei-system-news-info-modal',
    templateUrl: './system-news-info-modal.component.html',
    styleUrls: ['./system-news-info-modal.component.scss'],
})
export class SystemNewsInfoModalComponent extends BaseModal implements AfterViewInit {
    public displayList: SystemNews;

    constructor(public dialogRef: MatDialogRef<SystemNewsInfoModalComponent>, @Inject(MAT_DIALOG_DATA) public data: SystemNews) {
        super();
        this.displayList = data;
    }

    afterInit() {}
}
