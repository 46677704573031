import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'phoneNumber',
})
export class PhoneNumberPipe implements PipeTransform {
    transform(value: string): string | undefined {
        if (value === undefined) {
            return value;
        }

        const area = value.substr(0, 3);
        const first = value.substr(3, 3);
        const second = value.substr(6, 4);

        const length = value.length || 0;
        let ret = '';
        if (length > 0 && length <= 3) {
            ret = `(${area}`;
        } else if (length > 3 && length <= 6) {
            ret = `(${area}) ${first}`;
        } else if (length > 6) {
            ret = `(${area}) ${first}-${second}`;
        }

        return length !== 0 ? ret : undefined;
    }
}
