import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {AppModule} from './app/app.module';

import 'core-js/client/shim.min.js';
import 'zone.js';
import 'reflect-metadata/Reflect.js';
import 'marked/lib/marked.js';
import './polyfills';
import * as moment from 'moment';
import 'moment-timezone';
import * as Draw from 'leaflet-draw';
import * as L from 'leaflet';
import * as Pattern from 'leaflet.pattern';
import * as BeautifyMarkers from 'beautifymarker';
import * as SyncMap from 'leaflet.sync';
import * as SvgMarkers from 'leaflet-svg-shape-markers';
import * as Projection from 'proj4leaflet';
import proj4 from 'proj4';
import 'leaflet.markercluster';
import {environment} from './environments/environment';

declare global {
    interface Navigator {
        msSaveBlob?: (blob: any, defaultName?: string) => boolean;
    }
}

// needed for leaflet draw to initialize.

const drawHack = Draw;
const patternHack = Pattern;
const markerHack = BeautifyMarkers;
const syncHack = SyncMap;
const svgHack = SvgMarkers;
const projectionHack = Projection;

if (environment.production) {
    enableProdMode();
}

(window as any).m = moment;
(window as any).L = L;
(moment as any).tz.setDefault((moment as any).tz.guess());

// https://github.com/ckan/ckanext-geoview/issues/51
proj4.defs['OGC:CRS84'] = proj4.defs['EPSG:4326'];

(window as any).elementHasEllipsis = (element: HTMLElement) => {
    return element.offsetWidth < element.scrollWidth;
};

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
