<div class="dialog-header">
    <span>EAGLE-I™ Feedback</span>
    <mat-icon inline fontSet="fas" fontIcon="fa-times" (click)="dialogRef.close()"></mat-icon>
</div>

<div #content class="dialog-content">
    <form>
        <mat-form-field>
            <mat-label>Feedback Type</mat-label>
            <mat-select name="type" required [(ngModel)]="feedback.feedbackType" placeholder="Feedback Type">
                <mat-option value="Feedback">Feedback</mat-option>
                <mat-option value="Feature Request">Feature Request</mat-option>
                <mat-option value="Bug">Bug</mat-option>
            </mat-select>
            <mat-error>Feedback Type is required</mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Feedback Summary</mat-label>
            <input matInput required name="summary" [(ngModel)]="feedback.summary" placeholder="Feedback Summary" />
            <mat-error>Summary is required</mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Feedback Description </mat-label>
            <textarea
                matInput
                required
                matTextareaAutosize
                matAutosizeMaxRows="5"
                matAutosizeMinRows="3"
                name="desc"
                [(ngModel)]="feedback.description"
                placeholder="Feedback Description"></textarea>
            <mat-error>Description is required</mat-error>
        </mat-form-field>
    </form>
</div>

<div class="dialog-actions">
    <button mat-flat-button (click)="submitFeedback()" color="primary" [disabled]="!checkInput()">{{ buttonText }}</button>
</div>
