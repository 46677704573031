import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {LockTimeService} from '../services/lock-time.service';
import {HttpClient} from '@angular/common/http';
import {filter, map} from 'rxjs/operators';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root',
})
export class StopTimeGuard implements Resolve<any> {
    constructor(private http: HttpClient) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        // This only returns true because the map function on the api call sets up all the data needed,
        // so if the call succeeds it can just return true to tell the route guard to load.
        const url = 'api/systemdata/get-stopped-time';
        return this.http
            .get(url, {responseType: 'text'})
            .pipe(
                filter((res) => !!res),
                map((res) => {
                    const date = moment(res);
                    LockTimeService.currentApplicationTime.next(date);
                })
            )
            .toPromise()
            .then(() => true);
    }
}
