
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import { GenLayerFilterType } from './GenLayerFilterType';

export class GenFilter {

    id: number;
    defaultValue: string;
    filterProperty: string;
    layerId: number;
    ordering: number;
    title: string;
    type: GenLayerFilterType;
    values: string;

    constructor();
    constructor( jsonObj: any );
    constructor( genFilter: GenFilter);
    constructor( id: number );

    constructor( input?: GenFilter|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            this.defaultValue = input.defaultValue; // string [string]
            this.filterProperty = input.filterProperty; // string [string]
            this.layerId = input.layerId; // int [number]
            this.ordering = input.ordering; // int [number]
            this.title = input.title; // string [string]
            this.type = (typeof input.type === 'string') ? GenLayerFilterType.forName(<string> input.type) : <any> input.type; // GenLayerFilterType
            this.values = input.values; // string [string]
       }
    }

    static jsonResponseToList(arr: any[]): GenFilter[] {
        if (arr && arr.map) {
          return arr.map(a => new GenFilter(a));
        } else {
          return [];
        }
    }
}

