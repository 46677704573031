<div class="map-container">
    <div class="map-frame">
        <div #leafletMap class="map" [class.point-selection]="locationSearchService.pointSelection"></div>
    </div>
</div>

<div class="loading-bar" *ngIf="mapService.layersLoading !== 0 || identifyService.loadingFeatureInfo">
    <p>Loading.</p>
</div>

<div class="map-controls">
    <div class="zoom-controls" *ngIf="mapOptions.show.zoom">
        <div (click)="zoomIn()" matTooltip="Zoom In" matTooltipPosition="left" matTooltipShowDelay="250">
            <mat-icon fontSet="fas" fontIcon="fa-plus" inline></mat-icon>
        </div>
        <div (click)="zoomOut()" matTooltip="Zoom Out" matTooltipPosition="left" matTooltipShowDelay="250">
            <mat-icon fontSet="fas" fontIcon="fa-minus" inline></mat-icon>
        </div>
        <div
            *ngIf="!mapOnly && mapOptions.show.zoomToHome"
            (click)="zoomToHome()"
            matTooltip="Zoom To Home"
            matTooltipPosition="left"
            matTooltipShowDelay="250">
            <mat-icon fontSet="fas" fontIcon="fa-sync" inline></mat-icon>
        </div>
        <div
            *ngIf="mapOptions.show.panControl"
            (click)="togglePan()"
            [class.selected]="canPan"
            [matTooltip]="canPan ? 'Disable Panning' : 'Enable Panning'"
            matTooltipPosition="left"
            matTooltipShowDelay="250">
            <mat-icon fontSet="fas" fontIcon="fa-arrows-alt" inline></mat-icon>
        </div>
    </div>

    <div class="export-controls" *ngIf="mapOptions.show.export">
        <!--    <div *ngIf="!useMobileLayout()" (click)="printMap()" matTooltip="Print Map" matTooltipPosition="left"-->
        <!--         matTooltipShowDelay="250">-->
        <!--      <mat-icon fontSet="fas" fontIcon="fa-print" inline></mat-icon>-->
        <!--    </div>-->
        <div
            (click)="exportMap()"
            matTooltip="Export as PNG"
            [matTooltipDisabled]="exportLoading"
            matTooltipPosition="left"
            matTooltipShowDelay="250">
            <mat-icon fontSet="fas" fontIcon="fa-file-export" inline></mat-icon>
        </div>

        <div (click)="getSourceData()" matTooltip="Where does the data come from?" matTooltipShowDelay="250" matTooltipPosition="left">
            <mat-icon fontSet="fas" fontIcon="fa-question"></mat-icon>
        </div>

        <div (click)="getCoveredUtilities()" matTooltip="View Covered Utilities" matTooltipShowDelay="250" matTooltipPosition="after">
            <mat-icon fontSet="fas" fontIcon="fa-industry"></mat-icon>
        </div>
    </div>
</div>

<div class="export-loading-mask" [class.hide-width]="!exportLoading">
    <i class="fa fa-spinner fa-spin fa-pulse"></i>
    <span>Generating PNG</span>
</div>

<div class="map-dates" *ngIf="mapOptions.show.refresh">
    <mat-chip class="date-range clickable bold-border" (click)="openDateRangeModal()">
        <div class="range-text">
            <mat-icon fontSet="fas" fontIcon="fa-calendar" inline></mat-icon>
            <span>
                {{ (mapService.mapDateRange | async).startDate | momentDate }} -
                {{ (mapService.mapDateRange | async).endDate | momentDate }}
            </span>
            <mat-icon fontSet="fas" fontIcon="fa-angle-right" inline></mat-icon>
        </div>
    </mat-chip>
    <mat-chip class="refresh-time">
        <div>
            <mat-icon fontSet="fas" fontIcon="fa-redo" inline></mat-icon>
            <span>{{ mapService.lastRefreshTime | momentDate : 'hh:mm A' }}</span>
        </div>
    </mat-chip>
</div>

<div class="coverage" *ngIf="mapOptions.show.coverage">
    <mat-chip class="location-chip bold-border" *ngIf="location && showMouseCoordinates">
        <div>
            <span>{{ location.latitude | number : '1.4-4' }},{{ location.longitude | number : '1.4-4' }}</span>
        </div>
    </mat-chip>
    <mat-chip class="bold-border">
        <span>
            Current Coverage:
            <span *ngIf="mapService.coveragePercent !== 0">{{ mapService.coveragePercent | percent : '1.2-2' }}</span>
            <span *ngIf="mapService.coveragePercent === 0">N/A</span>
        </span>
    </mat-chip>
</div>

<eaglei-leaflet-sidebar *ngIf="mapOptions.show.sidebar" class="sidebar" (isOpen)="sidebarChanged($event)"></eaglei-leaflet-sidebar>
