import {ElementRef} from '@angular/core';
import {filter, delay} from 'rxjs/operators';
import * as moment from 'moment';
import * as echarts from 'echarts';
import {ApplicationConfig} from '../application-config';
import {PopoverElement} from '../popover-element';
import {ChartBounds} from '../chart-bounds';
import {ShortNumberPipe} from '../../../shared/pipes/short-number.pipe';
import {PopoverConfig, ShowPopoverConfig} from '../popover-config';

// TODO make b initialize to ChartPoint class when created
export class EagleiBaseChart<C = any, B = any, R = any> {
    // New stuff
    public shortNumberPipe = new ShortNumberPipe();
    public eChart: echarts.ECharts;
    public eChartOptions: echarts.EChartOption = {};
    public chartBounds: ChartBounds<B, R> = new ChartBounds<B, R>();

    private _chart: C;

    constructor() {
        this._inPopover = false;

        // resize on timeout so that the grid layout can change first
        ApplicationConfig.resizeEvent
            .pipe(
                filter(() => !!this.eChart),
                delay(10)
            )
            .subscribe(() => {
                this.eChart.resize();
            });
    }

    get valueAtMouse(): PopoverElement[] {
        return this._valueAtMouse;
    }

    get htmlElement(): ElementRef {
        return this._htmlElement;
    }

    get chart(): C {
        return this._chart;
    }

    public interactivePopover = false;

    private _htmlElement: ElementRef;

    private _valueAtMouse: PopoverElement[] = [];
    private _inPopover: boolean;

    // Data Popover

    // noinspection JSMethodCanBeStatic
    private showPopover(config: ShowPopoverConfig) {
        PopoverConfig.showNewPopover(config);
    }

    public hidePopover() {
        this._inPopover = false;
        PopoverConfig.hideNewPopover();
    }

    public hideAllPopovers() {
        setTimeout(() => {
            this.hidePopover();
        }, 100);
    }

    public initializeEChart(element: HTMLDivElement, hasPopover: boolean = false, xAxisText?: string, yAxisText?: string): void {
        this.eChart = echarts.init(element);

        if (hasPopover) {
            const ef = (event) => {
                this._valueAtMouse = event.data.popoverData.map((ele) => new PopoverElement().copyFrom(ele));

                const config: ShowPopoverConfig = {
                    x: event.event.event.clientX,
                    y: event.event.event.clientY,
                    data: this._valueAtMouse,
                    interactive: this.interactivePopover,
                    popoverId: event.data.popoverId,
                    rawData: event.data.rawData,
                };

                this.showPopover(config);
            };
            const of = () => {
                requestAnimationFrame(() => {
                    if (!this.interactivePopover || !PopoverConfig.inPopover) {
                        this.hidePopover();
                    }
                });
            };

            (this.eChart as any).on('click', 'series', ef);
            (this.eChart as any).on('mouseout', 'series', of);
        }

        this.eChartOptions = {
            xAxis: {
                type: 'category',
                axisLine: {lineStyle: {color: '#707070'}},
                axisTick: {
                    alignWithLabel: true,
                },
                axisLabel: {
                    color: '#707070',
                    formatter: (date) => {
                        const value = parseInt(date);
                        return moment(value).format('M/DD h:mm A');
                    },
                },
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    color: '#707070',
                    formatter: (value) => {
                        return this.shortNumberPipe.transform(value);
                    },
                },
                axisLine: {
                    lineStyle: {color: '#707070'},
                },
            },
        };

        if (xAxisText) {
            const xAxis: echarts.EChartOption.XAxis = this.eChartOptions.xAxis as echarts.EChartOption.XAxis;

            xAxis.name = xAxisText;
            xAxis.nameLocation = 'middle';
            xAxis.nameTextStyle = {
                padding: [10, 0, 0, 0],
            };
        }

        if (yAxisText) {
            const yAxis: echarts.EChartOption.YAxis = this.eChartOptions.yAxis as echarts.EChartOption.YAxis;

            yAxis.name = yAxisText;
            yAxis.nameLocation = 'end';
            yAxis.nameTextStyle = {
                padding: [0, 0, 0, 55],
            };
        }

        if (xAxisText && yAxisText) {
            this.eChartOptions.grid = {
                top: 35,
                bottom: 40,
                left: 45,
                right: 35,
            };
        }
    }

    public getXAxis(): echarts.EChartOption.XAxis {
        return this.eChartOptions.xAxis as echarts.EChartOption.XAxis;
    }

    public getYAxis(): echarts.EChartOption.YAxis {
        return this.eChartOptions.yAxis as echarts.EChartOption.YAxis;
    }
    public getGrid(): echarts.EChartOption.Grid {
        return this.eChartOptions.grid as echarts.EChartOption.YAxis;
    }

    public setOptions(): void {
        this.eChart.setOption(this.eChartOptions);
    }

    public hasData(): boolean {
        return (
            this.eChart &&
            this.eChart.getOption() &&
            this.eChart.getOption().series &&
            this.eChart.getOption().series.some((s) => s.data.length !== 0)
        );
    }

    public getCanvasElement(): HTMLCanvasElement {
        if (!!this.eChart) {
            return this.eChart.getDom().getElementsByTagName('canvas')[0];
        }
        return this.htmlElement.nativeElement.getElementsByTagName('canvas')[0];
    }
}
