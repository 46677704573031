import {GenWidgetName} from 'frontend/generated/serverModels/GenWidgetName';
import {GenWidgetSettingType} from 'frontend/generated/serverModels/GenWidgetSettingType';
import {WidgetDetail} from 'frontend/src/app/modules/widget/classes/widget-detail';
import {GenOutageAggregationLevel} from '../../../../../../../generated/serverModels/GenOutageAggregationLevel';
import {Widget} from '../../../../widget/classes/widget';
import {GenCommunityPermission} from '../../../../../../../generated/serverModels/GenCommunityPermission';
import {GenRoleDefinition} from '../../../../../../../generated/serverModels/GenRoleDefinition';

export const electricWidgets = [
    {
        x: 0,
        y: 0,
        cols: 2,
        rows: 1,
        widgetName: GenWidgetName.STATS,
        detail: new WidgetDetail().setWidgetSettings({
            type: GenWidgetSettingType.IMPACTED,
            value: 'utility',
        } as any),
    },
    {
        x: 2,
        y: 0,
        cols: 2,
        rows: 1,
        widgetName: GenWidgetName.STATS,
        detail: new WidgetDetail().setWidgetSettings([
            {
                type: GenWidgetSettingType.IMPACTED,
                value: 'utility',
            } as any,
            {
                type: GenWidgetSettingType.MAX_STAT,
                value: true,
            } as any,
        ]),
    },
    {
        x: 4,
        y: 0,
        cols: 2,
        rows: 1,
        widgetName: GenWidgetName.STATS,
        detail: new WidgetDetail().setWidgetSettings({
            type: GenWidgetSettingType.IMPACTED,
            value: 'customers',
        } as any),
    },
    {
        x: 6,
        y: 0,
        cols: 2,
        rows: 1,
        widgetName: GenWidgetName.STATS,
        detail: new WidgetDetail().setWidgetSettings([
            {
                type: GenWidgetSettingType.IMPACTED,
                value: 'customers',
            } as any,
            {
                type: GenWidgetSettingType.MAX_STAT,
                value: true,
            } as any,
        ]),
    },
    {
        x: 0,
        y: 1,
        cols: 4,
        rows: 2,
        widgetName: GenWidgetName.OUTAGE_MAP,
        detail: new WidgetDetail().setWidgetSettings({
            type: GenWidgetSettingType.AGGREGATION_LEVEL,
            value: GenOutageAggregationLevel.county.toString(),
        } as any),
    },
    {
        x: 4,
        y: 1,
        cols: 4,
        rows: 2,
        widgetName: GenWidgetName.OUTAGE_CHART,
        detail: new WidgetDetail(),
    },

    {
        x: 0,
        y: 3,
        cols: 2,
        rows: 2,
        widgetName: GenWidgetName.OUTAGE_SUMMARY,
        detail: new WidgetDetail().setWidgetSettings({
            type: GenWidgetSettingType.AGGREGATION_LEVEL,
            value: 'state',
        } as any),
    },
    {
        x: 2,
        y: 3,
        cols: 4,
        rows: 2,
        widgetName: GenWidgetName.OUTAGE_SUMMARY,
        detail: new WidgetDetail().setWidgetSettings({
            type: GenWidgetSettingType.AGGREGATION_LEVEL,
            value: 'county',
        } as any),
    },
    {
        x: 6,
        y: 3,
        cols: 2,
        rows: 2,
        widgetName: GenWidgetName.OUTAGE_SUMMARY,
        detail: new WidgetDetail().setWidgetSettings({
            type: GenWidgetSettingType.AGGREGATION_LEVEL,
            value: 'utility',
        } as any),
    },
    {
        x: 0,
        y: 5,
        cols: 8,
        rows: 3,
        widgetName: GenWidgetName.EVENT_RESTORATION,
        detail: new WidgetDetail().setWidget(
            new Widget().setRoles([
                GenRoleDefinition.ROLE_OUTAGE_ADMIN,
                GenRoleDefinition.ROLE_OUTAGE_MANAGER,
                GenRoleDefinition.ROLE_FEDERAL,
                GenRoleDefinition.ROLE_DOE,
            ])
        ),
    },
];
