<div class="filter-bar ignore-last">
    <section class="filters">
        <mat-form-field class="filter-select">
            <input matInput (keyup)="filterData($event.target.value)" placeholder="Filter Utilities" />
            <mat-icon matSuffix class="icon input-search-icon" fontSet="fas" fontIcon="fa-search" inline></mat-icon>
        </mat-form-field>

        <mat-form-field class="filter-select">
            <mat-label>Status</mat-label>
            <mat-select (selectionChange)="statusChange($event)" [value]="selectedStatus" placeholder="Utility Status">
                <mat-option value="all">All</mat-option>
                <mat-option value="NORMAL">Normal</mat-option>
                <mat-option value="ERROR">Error</mat-option>
            </mat-select>
        </mat-form-field>

        <eaglei-date-range (change)="updateDates($event)" [newDesign]="true"> </eaglei-date-range>
    </section>

    <button mat-stroked-button matTooltip="Export" matTooltipPosition="above" matTooltipShowDelay="250" [matMenuTriggerFor]="menu">
        <mat-icon fontSet="fas" fontIcon="fa-file-export" inline></mat-icon>
    </button>
</div>

<div class="report-data">
    <ng-container [ngTemplateOutlet]="barChart"></ng-container>
    <ng-container [ngTemplateOutlet]="reportTable"></ng-container>
</div>

<ng-template #barChart>
    <mat-card class="chart">
        <mat-card-content [class.invisible]="showMask || loading">
            <div class="chart-target">
                <div #chartTarget class="chart-target"></div>
            </div>
        </mat-card-content>

        <div class="auto-mask hide-mask" *ngIf="showMask || loading">
            <span class="mask-message">
                <span>{{ getMaskText() }}</span>
                <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
            </span>
        </div>
    </mat-card>
</ng-template>

<ng-template #reportTable>
    <mat-card>
        <mat-card-content>
            <div class="table-container">
                <table
                    mat-table
                    [dataSource]="dataSource"
                    matSortActive="total"
                    matSortDirection="desc"
                    matSort
                    [matSortDisableClear]="true">
                    <ng-container matColumnDef="eagle_id">
                        <th mat-header-cell *matHeaderCellDef class="ei-sm-column">EAGLE-I&trade; ID</th>
                        <td mat-cell *matCellDef="let utility" class="ei-sm-column">{{ utility.utilityId }}</td>
                    </ng-container>

                    <ng-container matColumnDef="utility_name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Utility Name
                            <eaglei-table-sort-header [columnName]="'utility_name'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td
                            #utilityName
                            mat-cell
                            *matCellDef="let utility"
                            [matTooltip]="utility.utilityName"
                            [matTooltipDisabled]="!hasEllipsis(utilityName)"
                            matTooltipPosition="above"
                            matTooltipShowDelay="250"
                            class="ei-md-column ellipsis">
                            {{ utility.utilityName }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef class="ei-sm-column">Status</th>
                        <td
                            class="ei-sm-column"
                            mat-cell
                            *matCellDef="let utility"
                            [ngClass]="{
                                'success-text': utility.utilityStatus === 'NORMAL',
                                'failure-text': utility.utilityStatus === 'ERROR'
                            }">
                            {{ utility.utilityStatus }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="customers">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="small-col">
                            Total Customers
                            <eaglei-table-sort-header [columnName]="'customers'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let utility" class="small-col">
                            {{ utility.totalCustomers | number }}
                            <span class="county-customer"> {{ getCountyCustomerText(utility) }}</span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="connection">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="ei-sm-column">
                            Connection
                            <eaglei-table-sort-header [columnName]="'connection'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let utility" class="ei-sm-column">{{ utility.connectionErrors | number }}</td>
                    </ng-container>

                    <ng-container matColumnDef="database">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="ei-sm-column">
                            Database
                            <eaglei-table-sort-header [columnName]="'database'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let utility" class="ei-sm-column">{{ utility.databaseErrors | number }}</td>
                    </ng-container>

                    <ng-container matColumnDef="developer">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="ei-sm-column">
                            Developer
                            <eaglei-table-sort-header [columnName]="'developer'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let utility" class="ei-sm-column">{{ utility.developerErrors | number }}</td>
                    </ng-container>

                    <ng-container matColumnDef="location">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="ei-sm-column">
                            Location
                            <eaglei-table-sort-header [columnName]="'location'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let utility" class="ei-sm-column">{{ utility.locationErrors | number }}</td>
                    </ng-container>

                    <ng-container matColumnDef="scraper">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="ei-sm-column">
                            Scraper
                            <eaglei-table-sort-header [columnName]="'scraper'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let utility" class="ei-sm-column">{{ utility.scraperErrors | number }}</td>
                    </ng-container>

                    <ng-container matColumnDef="sub_utility">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="ei-sm-column">
                            SubUtility
                            <eaglei-table-sort-header [columnName]="'sub_utility'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let utility" class="ei-sm-column">{{ utility.subUtilityErrors | number }}</td>
                    </ng-container>

                    <ng-container matColumnDef="system_failure">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="ei-sm-column">
                            System Failure
                            <eaglei-table-sort-header [columnName]="'system_failure'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let utility" class="ei-sm-column">{{ utility.systemFailureErrors | number }}</td>
                    </ng-container>

                    <ng-container matColumnDef="informational">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="ei-sm-column">
                            Informational
                            <eaglei-table-sort-header [columnName]="'informational'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let utility" class="ei-sm-column">{{ utility.informationalErrors | number }}</td>
                    </ng-container>

                    <ng-container matColumnDef="unknown">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="ei-sm-column">
                            Unknown
                            <eaglei-table-sort-header [columnName]="'unknown'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let utility" class="ei-sm-column">{{ utility.unknownErrors | number }}</td>
                    </ng-container>

                    <ng-container matColumnDef="total">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="ei-sm-column">
                            Total Errors
                            <eaglei-table-sort-header [columnName]="'total'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let utility"
                            class="ei-sm-column"
                            [ngClass]="{
                                'success-text': utility.totalErrors < possibleErrorsInRange / 6,
                                'failure-text': utility.totalErrors > possibleErrorsInRange / 2,
                                'warn-text': utility.totalError <= possibleErrorsInRange / 2
                            }">
                            {{ utility.totalErrors / possibleErrorsInRange | percent }}
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
                    <tr mat-row *matRowDef="let row; columns: columnNames" (click)="viewUtilityErrors(row)" class="clickable"></tr>
                </table>

                <div>
                    <div *ngIf="dataSource && !(dataSource.filteredData.length > 0)" class="table-mask">
                        <b>No Data Matching the Filters</b>
                    </div>
                    <mat-paginator [pageSizeOptions]="[25, 50, 75, 100]" showFirstLastButtons></mat-paginator>
                </div>

                <div class="table-attribution">
                    <a (click)="reportService.openAttribution(attributionUrl)" [innerHTML]="attributionTitle | sanitize"></a>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</ng-template>

<mat-menu #menu="matMenu">
    <button (click)="exportTable()" mat-menu-item>Export Table As CSV</button>
    <button (click)="exportChart()" mat-menu-item>Export Chart As PNG</button>
</mat-menu>
