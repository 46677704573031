import {FormControl, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {HeadoutInfrastructureSource} from './headout-infrastructure-source';
import {HeadoutWeatherSource} from './headout-weather-source';
import {MapOptions} from '../../../modules/map/classes/map-options';
import {LeafletMapLayer} from '../../../modules/layer/classes/leaflet-map-layer';
import {HeadoutSource} from '../../../modules/layer/sources/headout-source';
import {DataInjector} from '../../../services/data.service';
import {MapService} from '../../../modules/map/services/map.service';
import {LayerService} from '../../../modules/layer/services/layer.service';
import {groupHeadoutEventByStatus} from '../functions/group-event-status';
import {GroupedHeadoutEvent} from '../interfaces/grouped-headout-event';
import {filter, takeUntil} from 'rxjs/operators';
import {HeadoutEventResultCustomerDetail} from './headout-event-result-customer-detail';
import {HeadoutEventDetail} from './headout-event-detail';
import {HeadoutWeatherSourceConfig} from '../interfaces/headout-weather-source-config.interface';
import {Subject} from 'rxjs';
import {HeadoutTableData} from '../interfaces/headout-table-data.interface';
import {HeadoutInfrastructureDisplay} from '../interfaces/headout-infrastructure-display.interface';
import {ApplicationConfig} from 'frontend/src/app/classes/application-config';
import {GenServiceType} from 'frontend/generated/serverModels/GenServiceType';
import {LeafletLegendService} from '../../../modules/layer/services/leaflet-legend.service';
import {LeafletLayerLegend} from '../../../modules/layer/classes/leaflet-layer-legend';
import {LegendElement} from '../../../modules/layer/classes/legend-element';
import {getFontColor} from '../functions/font-color';
import {SourceDisplayInfo} from '../interfaces/source-display-info.interface';
import {addActiveLegend} from '../../../modules/map/functions/add-active-legend';
import {removeActiveLegend} from '../../../modules/map/functions/remove-active-legend';
import {SystemEvent} from '../../../modules/system-event/classes/system-event';
import {HeadoutService} from '../headout.service';
import {Component, Directive, inject, OnDestroy} from '@angular/core';
import {SystemEventType} from '../../../modules/system-event/classes/system-event-type';
import {HeadoutEventResult} from './headout-event-result';

@Directive()
export class HeadoutMapControls implements OnDestroy {
    public mapService = DataInjector.get(MapService);
    public layerService = DataInjector.get(LayerService);
    public legendService = DataInjector.get(LeafletLegendService);

    private headoutService = inject(HeadoutService);

    public filterGroup: UntypedFormGroup;

    public updateTableData: Subject<HeadoutTableData[]> = new Subject<HeadoutTableData[]>();

    public mapOptions: MapOptions;
    public mapLayer: LeafletMapLayer;
    public layerSource: HeadoutSource;

    public selectedDetail: HeadoutEventDetail;

    public eventGroups: GroupedHeadoutEvent = {
        active: [],
        inactive: [],
    };

    public infrastructureSources: {[key: string]: HeadoutInfrastructureSource} = {};
    public eventInfrastructures: {[key: string]: HeadoutInfrastructureDisplay};

    public weatherSources: SourceDisplayInfo<HeadoutWeatherSource>[] = [
        {
            active: false,
            display: 'Precipitation',
            units: 'inches',
            type: 'weather',
            source: new HeadoutWeatherSource('QUANTITATIVE_PRECIPITATION'),
        },
        {
            active: false,
            display: 'Wind Gust',
            units: 'MPH',
            type: 'weather',
            source: new HeadoutWeatherSource('WIND_GUST'),
        },
        {
            active: false,
            display: 'Wind Speed',
            units: 'MPH',
            type: 'weather',
            source: new HeadoutWeatherSource('WIND_SPEED'),
        },
    ];

    initialEvent: any;

    private readonly horizontalLegends: boolean;

    private destory$ = new Subject();

    constructor(horizontalLegends: boolean = false) {
        this.horizontalLegends = horizontalLegends;
        this.initializeMapOptions();
        this.initializeControls();
    }

    ngOnDestroy() {
        this.destory$.next(undefined);
        this.destory$.complete();
    }

    public initializeMapOptions(): void {
        this.mapOptions = new MapOptions().setZoom(3, 1);
        this.mapOptions.onlyManualZoom = true;
        this.mapOptions.show = {
            sidebar: false,
            refresh: false,
            export: false,
            coverage: false,
            coordinate: false,
            zoom: true,
            panControl: true,
        };
    }

    private initializeControls() {
        const controls = {
            type: new FormControl<SystemEventType>(null),
            name: new FormControl<SystemEvent>(null),
            advisory: new FormControl<HeadoutEventResult>(null),
            weather: new UntypedFormControl(''),
            infrastructure: new UntypedFormControl(''),
        };

        // Change Handlers
        // Type Change
        controls.type.valueChanges.subscribe((value) => {
            this.eventGroups = {
                active: [],
                inactive: [],
            };

            this.resetForm(true, true);

            this.selectedDetail = undefined;
            this.eventInfrastructures = undefined;

            this.headoutService.getHeadoutEventsByType(value).subscribe((events) => {
                this.eventGroups = groupHeadoutEventByStatus(events);

                if (this.initialEvent) {
                    const selected = events.find((event) => this.initialEvent.id === event.id);
                    controls.name.setValue(selected);
                }
            });
        });

        // Event Change
        controls.name.valueChanges
            .pipe(
                filter((value) => !!value),
                takeUntil(this.destory$)
            )
            .subscribe((value) => {
                this.resetForm();

                this.headoutService.getHeadoutEventDetails(value).subscribe((detail) => {
                    this.selectedDetail = detail;

                    // this.maskOptions.resetMask();
                    this.mapLayer.startLoading();
                    this.layerSource.processFeatures(detail);
                    this.layerSource.addToMap(true);
                    this.mapLayer.endLoading();

                    if (this.initialEvent) {
                        const recent = detail.eventResults
                            .slice()
                            .sort((a, b) => (a.resultTimestamp.isAfter(b.resultTimestamp) ? 1 : -1))
                            .pop();

                        controls.advisory.setValue(recent);
                        this.initialEvent = undefined;
                    }
                });
            });

        // Advisory Change
        controls.advisory.valueChanges
            .pipe(
                filter((value) => !!value),
                takeUntil(this.destory$)
            )
            .subscribe((value) => {
                const event: SystemEvent = controls.name.value;

                this.resetForm(false, false);

                this.layerSource.restyleAdvisory(value.id);

                this.headoutService.getHeadoutCustomerInfo(event.id, value.id).subscribe((affected) => {
                    const tableData = affected.map((detail) => HeadoutEventResultCustomerDetail.ConvertToReportData(detail));
                    this.updateTableData.next(tableData);
                    this.updateMapLayer(affected);
                });

                this.headoutService
                    .getHeadoutInfrastructureInfo(event, value.id)
                    .pipe(filter((infrastructure) => !!infrastructure.map))
                    .subscribe((infrastructure) => {
                        const possibleLayers = JSON.parse(infrastructure.map);

                        this.eventInfrastructures = Object.keys(possibleLayers)
                            .filter((key) => possibleLayers[key].length !== 0)
                            .reduce((prev, cur) => {
                                prev[cur] = {
                                    active: false,
                                    data: possibleLayers[cur],
                                    display: cur.split(/(?=[A-Z])/).join(' '),
                                };
                                return prev;
                            }, {});

                        controls.infrastructure.setValue(controls.infrastructure.value);
                    });
            });

        controls.weather.valueChanges
            .pipe(
                filter((value) => !!value),
                takeUntil(this.destory$)
            )
            .subscribe((value: SourceDisplayInfo<HeadoutWeatherSource>[]) => {
                const config: HeadoutWeatherSourceConfig = {
                    details: this.selectedDetail,
                    advisory: controls.advisory.value as any,
                };

                // Setting the active flag for the correct sources so we know when to trigger the legend.
                const activeWeatherLayerNames = value.map((info) => info.display);
                this.weatherSources.forEach((info) => {
                    info.active = activeWeatherLayerNames.includes(info.display);

                    if (info.active) {
                        info.source.updateSource(config);

                        if (info.legend) {
                            addActiveLegend(info.source.layerInfo.displayName, [info.legend]);
                        } else {
                            this.getSubLayerLegend(info.source.layerInfo, info, info.source.styleName);
                        }
                    } else {
                        info.source.removeFromMap();
                        removeActiveLegend(info.source.layerInfo.displayName);
                    }
                });
            });

        controls.infrastructure.valueChanges
            .pipe(
                filter((value) => !!value),
                takeUntil(this.destory$)
            )
            .subscribe((values: any[]) => {
                const handleLayerInteraction = (source: HeadoutInfrastructureSource, features: HeadoutInfrastructureDisplay) => {
                    source.processFeatures(features.data);
                    source.addToMap();

                    if (features.legend) {
                        addActiveLegend(source.layerInfo.displayName, [features.legend]);
                    } else {
                        this.getSubLayerLegend(source.layerInfo, features);
                    }
                };

                this.clearInfrastructureLayers();

                const activeLayerKeys: string[] = values.map((obj) => obj.key);

                Object.keys(this.eventInfrastructures).forEach((key) => {
                    this.eventInfrastructures[key].active = activeLayerKeys.includes(key);
                });

                values.forEach((value) => {
                    let source = this.infrastructureSources[value.key];

                    if (source) {
                        handleLayerInteraction(source, value.value);
                    } else {
                        this.layerService.getLayerByHandle(value.key).subscribe((layer) => {
                            layer.displayName = `Headout ${layer.displayName}`;
                            layer.uiHandle = `headout_${layer.uiHandle}`;
                            layer.opacity = 0.8;
                            source = new HeadoutInfrastructureSource(layer);
                            this.infrastructureSources[value.key] = source;

                            handleLayerInteraction(source, value.value);
                        });
                    }
                });
            });

        this.filterGroup = new UntypedFormGroup(controls);
    }

    private updateMapLayer(features: HeadoutEventResultCustomerDetail[]): void {
        this.layerSource.processDetails(this.selectedDetail, features);
        this.layerSource.addDetailsToMap();

        this.layerSource.fitToFeatures();
    }

    public resetForm(clearEvent: boolean = false, clearAdvisory: boolean = true) {
        this.updateTableData.next([]);
        if (clearAdvisory) {
            this.filterGroup.controls.advisory.reset();
            this.layerSource.clearOutages();
            this.layerSource.removeFromMap();
        }

        this.filterGroup.controls.weather.reset();
        this.filterGroup.controls.infrastructure.reset();
        if (clearEvent) {
            this.filterGroup.controls.name.reset();
        }

        if (this.layerSource) {
            this.layerSource.selectedAdvisoryId = undefined;
            this.mapService.zoomToHome.next(undefined);
        }

        this.clearInfrastructureLayers();
        this.eventInfrastructures = undefined;

        this.weatherSources.forEach((info) => {
            info.active = false;
            info.source.removeFromMap();
            removeActiveLegend(info.source.layerInfo.displayName);
        });
    }

    public clearInfrastructureLayers() {
        Object.keys(this.infrastructureSources).forEach((key) => {
            const source = this.infrastructureSources[key];
            source.removeFromMap();
            removeActiveLegend(source.layerInfo.displayName);
        });
    }

    public setInitialEvent(event: SystemEvent) {
        this.initialEvent = event;
        this.filterGroup.controls.type.setValue(event.type);
    }

    private getSubLayerLegend(
        layerInfo: LeafletMapLayer,
        displayInfo: HeadoutInfrastructureDisplay | SourceDisplayInfo,
        styleName?: string
    ): void {
        const legend = new LeafletLayerLegend(layerInfo.id, `Headout ${layerInfo.displayName}`);
        if (this.horizontalLegends) {
            legend.orientation = 'horizontal';
        }

        if (styleName && this.horizontalLegends) {
            legend.orientation = 'gradient';
        }

        const params = {
            REQUEST: 'GetLegendGraphic',
            VERSION: '1.0.0',
            FORMAT: 'application/json',
            LAYER: layerInfo.servicelayer,
        };

        if (styleName) {
            params['STYLE'] = `headout_${styleName.toLowerCase()}`;
        }

        const paramStr = Object.keys(params)
            .map((key) => `${key}=${params[key]}`)
            .join('&');
        this.layerService.getJsonFromUrl(`${ApplicationConfig.proxyPrefix}${layerInfo.serviceurl}?${paramStr}`).subscribe((res) => {
            legend.elements = res.Legend[0]?.rules?.map((rule) => {
                const ret = new LegendElement();
                const symbol = rule?.symbolizers[0];

                ret.label = rule.name;

                if (layerInfo.servicetype === GenServiceType.WMS) {
                    let graphic: any;
                    // We are dealing with a point
                    if (symbol?.Point) {
                        graphic = symbol?.Point?.graphics[0];

                        ret.width = symbol?.Point.size;
                        ret.height = symbol?.Point.size;
                        ret.labelColor = '#707070';
                        ret.textSize = '12';

                        if (graphic) {
                            ret.fillColor = graphic.fill;
                            ret.strokeColor = graphic.stroke;
                            ret.strokeWidth = graphic['stroke-width'];

                            if (graphic.mark === 'square') {
                                ret.shape = 'rect';
                            }
                        }
                    } else if (symbol?.Polygon) {
                        graphic = symbol?.Polygon;
                        ret.fillColor = graphic.fill;
                        ret.strokeColor = graphic.stroke;

                        ret.shape = 'rect';
                        legend.gapSize = 0;

                        if (legend.orientation === 'gradient') {
                            legend.hideBorders = true;
                            ret.textSize = '8';
                            ret.width = 75;
                            ret.height = 30;
                            ret.labelPosition = 'inset';
                            ret.labelColor = getFontColor(ret.fillColor);
                        } else {
                            ret.textSize = '12';
                            ret.width = 20;
                            ret.height = 12;
                            ret.labelColor = '#707070';
                        }
                    }
                } else {
                    ret.fillColor = symbol?.Polygon?.fill;
                    ret.shape = 'rect';
                }
                return ret;
            });

            displayInfo.legend = legend;

            addActiveLegend(layerInfo.displayName, [legend]);
        });
    }
}
