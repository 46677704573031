import {Component} from '@angular/core';
import {BasePopover} from '../base-popover';
import {PlanetsenseLocation} from '../../classes/planetsense-location';
import {Planetsense} from '../../classes/planetsense';

@Component({
    selector: 'eaglei-social-media-popover',
    templateUrl: './social-media-popover.component.html',
    styleUrls: ['./social-media-popover.component.scss', '../base-popover.scss'],
})
export class SocialMediaPopoverComponent extends BasePopover<PlanetsenseLocation> {
    public currentTweetIndex: number;
    public currentTweet: Planetsense;

    constructor() {
        super();
    }

    onInit() {}

    updateData() {
        this.data = new PlanetsenseLocation(this.data);

        if (this.data.tweets.length > 0) {
            this.currentTweetIndex = 0;
            this.currentTweet = this.data.tweets[this.currentTweetIndex];
        }
    }

    public previousTweet(): void {
        this.currentTweetIndex -= 1;
        this.currentTweet = this.data.tweets[this.currentTweetIndex];
    }

    public nextTweet(): void {
        this.currentTweetIndex += 1;
        this.currentTweet = this.data.tweets[this.currentTweetIndex];
    }

    public flowLink(): void {
        const link = document.createElement('a');
        link.href = this.currentTweet.twitterUrl;
        link.target = '_blank';

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    afterInit() {}
}
