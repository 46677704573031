<div class="widthLimiter">
    <div class="version title">Version {{ version }}</div>

    <div class="title">
        <p>
            The Office of Cybersecurity, Energy Security, and Emergency Response (CESER), Infrastructure Security and Energy Restoration
            (ISER) division has the capability to monitor the Nation’s energy infrastructure in near real-time. Through its Energy Response
            Center (ERC), ISER presents EAGLE-I™ (Environment for Analysis of Geo-Located Energy Information), a system for headquarters and
            Regional Coordinators in the field to geospatially map energy assets and systems and tie together a variety of data sources into
            one visualization platform.
        </p>

        <p>
            The EAGLE-I™ system includes a <a (click)="navigateToPage('app/map/view')">map view</a>, an integrating and summarizing
            <a (click)="navigateToPage('app/landing')">dashboard</a>, various <a (click)="navigateToPage('app/reports')">reports</a>, custom
            <a (click)="navigateToPage('app/alerts')">alerts</a>, various
            <a (click)="navigateToPage('app/communities')">community features</a>, programmatic data access, and a host of other
            user-focused features, including being compatible for mobile-based browsers. An important component of EAGLE-I™ is the Map. The
            Map has an operational focus and pulls from dynamic datasets to provide near real-time coverage of the entire electric grid and
            the Nation’s petroleum and natural gas networks. Popular attributes include the National Outage Map (NOM), natural gas pipelines
            critical notices, as well as the petroleum supply and storage selection. All features are constantly being refined based on user
            feedback. As new data sources and types of information are identified, they are incorporated into the system, providing
            additional capabilities. Other features of the Map have a more static focus and display components of the Nation’s energy
            infrastructure. Electricity assets include power plants (non-nuclear), nuclear power plants, substations, transmission lines,
            electric service territories, and ISO/RTO regions. Coal assets include locations of coal regions and mines.
        </p>

        <p>
            Mapping within EAGLE-I™ includes the ability to add real-time weather hazards directly from the U.S. Geological Survey (USGS)
            and NOAA (National Oceanic and Atmospheric Administration), such as earthquakes, floods, hurricanes, volcanoes, wildfires and
            weather watches. Additionally, population data from Oak Ridge National Laboratory's (ORNL) LandScan can be added to the map.
        </p>

        <p>
            EAGLE-I™ provides ISER staff with automated alert notifications when abnormal conditions are detected. Through email, text
            messaging or other means, this built-in analytic capability notifies ISER staff directly when a potential abnormality is
            detected on the electric grid, within the petroleum network or the natural gas network.
        </p>

        <p>
            For security purposes, EAGLE-I™ is located within a protected area of the DOE network and is controlled to restrict access to
            the proprietary and sensitive data sources.
        </p>

        <p>
            Contact <a href="mailto:eagle-i@ornl.gov" target="_top">eagle-i@ornl.gov</a> if you have questions or comments regarding the
            EAGLE-I™ system.
        </p>
    </div>
</div>
