import {Component, inject} from '@angular/core';
import {BaseWidget} from '../../classes/base-widget';
import {LayerService} from '../../../layer/services/layer.service';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {filter} from 'rxjs/operators';
import {MapOptions} from '../../../map/classes/map-options';
import {LeafletMapLayer} from '../../../layer/classes/leaflet-map-layer';
import {GeoJSON, Map as LeafletMap} from 'leaflet';
import {SystemEventService} from '../../../system-event/services/system-event.service';
import {EventSummaryFilters} from '../../../system-event/classes/event-summary-filters';
import {FormControl} from '@angular/forms';
import {GenRadrUpdateNotice} from '../../../../../../generated/serverModels/GenRadrUpdateNotice';
import {LeafletWmsSource} from '../../../layer/classes/leaflet-wms-source';
import {ApplicationConfig} from '../../../../classes/application-config';
import {GenOutageAggregationLevel} from '../../../../../../generated/serverModels/GenOutageAggregationLevel';
import {LeafletFeature} from '../../../layer/classes/leaflet-feature';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Component({
    selector: 'eaglei-pnnl-radr-widget',
    templateUrl: './pnnl-radr-widget.component.html',
    styleUrls: ['./pnnl-radr-widget.component.scss'],
})
export class PnnlRadrWidgetComponent extends BaseWidget {
    private readonly layerHandle = 'pnnlRadr';

    private layerService = inject(LayerService);
    private systemEventService = inject(SystemEventService);
    private activeEventId: number;

    public mapReady = new BehaviorSubject<LeafletMap>(undefined);
    public mapOptions: MapOptions;

    public imageControl = new FormControl<GenRadrUpdateNotice[]>(null);

    public layerSource: LeafletWmsSource;
    public possibleImages: GenRadrUpdateNotice[] = [];

    constructor() {
        super();

        this.mapOptions = new MapOptions().setZoom(3, 1).setCenter(37.2, -92);
        this.mapOptions.onlyManualZoom = true;
        this.mapOptions.show = {
            sidebar: false,
            refresh: false,
            export: false,
            coverage: false,
            coordinate: false,
            zoom: true,
            panControl: true,
        };

        const observers = {
            layer: this.layerService.getLayerByHandle(this.layerHandle),
            mapRef: this.mapReady,
        };

        combineLatest(observers)
            .pipe(takeUntilDestroyed())
            .subscribe((res: {layer: LeafletMapLayer; mapRef: LeafletMap}) => {
                this.layerSource = new LeafletWmsSource(res.layer);
                this.layerSource.mapRef = res.mapRef;

                this.layerSource.fetchImages();
                this.layerSource.addToMap();
            });

        this.imageControl.valueChanges
            .pipe(
                filter((value) => !!value),
                takeUntilDestroyed()
            )
            .subscribe((value: GenRadrUpdateNotice[]) => {
                const url = `${ApplicationConfig.proxyPrefix}${value[0].wmsEndpoint}`;
                const layers = value.map((image) => image.wmsLayerId).join();

                this.layerSource.source.setUrl(url, true);
                this.layerSource.source.setParams({layers});
            });
    }

    protected handleEventFilterChange(eventFilters: EventSummaryFilters) {
        if (this.activeEventId === eventFilters?.event?.id) {
            return;
        }

        this.activeEventId = eventFilters.event.id;
        const map = this.mapReady.getValue();

        const eventStateIds = eventFilters.event.states.map((state) => state.id);
        const features = ApplicationConfig.geometries
            .get(GenOutageAggregationLevel.state)
            .filter((geometry) => eventStateIds.includes(geometry.stateId))
            .map((geometry) => new LeafletFeature().convert(geometry));

        map?.invalidateSize();
        map?.fitBounds(new GeoJSON(features as any).getBounds());

        this.systemEventService.getRadrImages(eventFilters.event).subscribe((images: GenRadrUpdateNotice[]) => {
            if (images.length === 0) {
                this.possibleImages = [];
                this.imageControl.reset();
                this.imageControl.disable();
                return;
            }

            this.possibleImages = images.sort((a, b) => {
                if (a.collectedAt.isBefore(b.collectedAt)) return 1;
                else if (a.collectedAt.isAfter(b.collectedAt)) return -1;
                return 0;
            });

            this.imageControl.enable();
            this.imageControl.setValue([this.possibleImages[0]]);
        });
    }

    public tileCompare(v1: GenRadrUpdateNotice, v2: GenRadrUpdateNotice): boolean {
        return v1.id === v2.id;
    }

    destroyWidget(): void {}
}
