export class LoadingMaskOptions {
    static LOADING_TEXT = 'Loading...';

    public text: string = LoadingMaskOptions.LOADING_TEXT;
    public showMask: boolean = false;
    public showSpinner: boolean = true;
    public showBackground: boolean = true;

    constructor() {}

    public displayMask(text?: string): void {
        if (text) {
            this.text = text;
        }
        this.showMask = true;
    }

    public resetMask(): void {
        this.showMask = false;
        this.text = 'Loading...';
    }
}
