<div class="filter-bar ignore-last">
    <section class="filters">
        <eaglei-location-filter [hideFemaRegions]="true" [defaultSelection]="selectedStates" (locations)="updateLocations($event)">
        </eaglei-location-filter>

        <mat-form-field class="filter-select" [class.active]="dataSource?.data.filter.length !== 0">
            <mat-label>Search Pipeline Name</mat-label>

            <input matInput placeholder="Search Pipeline Name" (keyup)="updatePipelineSearch($event.target.value)" />

            <mat-icon matSuffix class="icon input-search-icon" fontSet="fas" fontIcon="fa-search"></mat-icon>
        </mat-form-field>

        <mat-form-field class="filter-select" [class.active]="selectedFacility.length !== 0">
            <mat-label>Facility Type</mat-label>
            <mat-select multiple placeholder="Facility Type" [value]="selectedFacility" (selectionChange)="updateFacilities($event.value)">
                <mat-option *ngFor="let fac of filterFacilityList" [value]="fac">{{ fac | replaceCharacter }}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="filter-select" [class.active]="selectedRoles.length !== 0">
            <mat-label>Role</mat-label>
            <mat-select multiple placeholder="Role" [value]="selectedRoles" (selectionChange)="updateRoles($event.value)">
                <mat-option *ngFor="let role of filterRoleList" [value]="role">{{ role | replaceCharacter }}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="filter-select" [class.active]="selectedPercents.length !== 0">
            <mat-label>Percentage Capacity</mat-label>
            <mat-select
                multiple
                placeholder="Percentage Capacity"
                [value]="selectedPercents"
                (selectionChange)="updatePercentage($event.value)">
                <mat-option *ngFor="let percent of nomPercents" [value]="percent">
                    <span>{{ percent.display }}</span>
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="filter-select" [class.active]="selectedCapacity.length !== 0">
            <mat-label>Receipt/Delivery</mat-label>
            <mat-select multiple placeholder="Receipt/Delivery" [value]="selectedCapacity" (selectionChange)="updateCapacity($event.value)">
                <mat-option [value]="true">Over Capacity</mat-option>
                <mat-option [value]="false">Under Capacity</mat-option>
            </mat-select>
        </mat-form-field>

        <eaglei-date-range
            class="mr"
            [start]="selectedDate"
            [startPlaceholder]="'Date'"
            [singlePicker]="true"
            [newDesign]="true"
            [min]="ongService.minDate"
            (change)="updateDateRange($event)">
        </eaglei-date-range>
    </section>

    <button mat-stroked-button matTooltip="Export" matTooltipPosition="above" matTooltipShowDelay="250" [matMenuTriggerFor]="menu">
        <mat-icon fontSet="fas" fontIcon="fa-file-export" inline></mat-icon>
    </button>
</div>

<!--Map-->
<ng-template #nominationMap>
    <section class="map-section">
        <mat-card class="map">
            <mat-card-content>
                <eaglei-leaflet-map [mapOptions]="mapOptions"></eaglei-leaflet-map>
            </mat-card-content>
            <mat-card-footer>
                <span class="label"> Nomination Percentage Capacity: </span>

                <div class="circle-container">
                    <div class="status low"></div>
                    <span class="label"> < 50% (Low)</span>
                </div>
                <div class="circle-container">
                    <div class="status medium"></div>
                    <span class="label"> 50-74% (Medium)</span>
                </div>
                <div class="circle-container">
                    <div class="status high"></div>
                    <span class="label"> > 74% (High)</span>
                </div>
            </mat-card-footer>
        </mat-card>
    </section>
</ng-template>

<div class="report-data">
    <ng-container [ngTemplateOutlet]="nominationMap"></ng-container>

    <ng-container [ngTemplateOutlet]="table"></ng-container>
</div>

<!--Table-->
<ng-template #table>
    <section class="table-section">
        <mat-card [class.invisible]="!hasData()">
            <div class="table-container">
                <table
                    mat-table
                    #tableSort="matSort"
                    [dataSource]="dataSource"
                    multiTemplateDataRows
                    matSort
                    [matSortDisableClear]="true"
                    matSortActive="date"
                    matSortDirection="desc">
                    <ng-container matColumnDef="plantName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Location Name
                            <eaglei-table-sort-header [columnName]="'plantName'" [sort]="tableSort"></eaglei-table-sort-header>
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let info"
                            class="title"
                            [matTooltip]="info.location.name"
                            matTooltipPosition="right"
                            matTooltipShowDelay="250">
                            <p class="ellipsis">{{ info.location.name }}</p>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="pipeline">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Pipeline Name
                            <eaglei-table-sort-header [columnName]="'pipeline'" [sort]="tableSort"></eaglei-table-sort-header>
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let info"
                            class="title"
                            [matTooltip]="info.pipeline_name"
                            matTooltipPosition="right"
                            matTooltipShowDelay="250">
                            <p class="ellipsis">{{ info.pipeline_name }}</p>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="facility">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Facility Type
                            <eaglei-table-sort-header [columnName]="'facility'" [sort]="tableSort"></eaglei-table-sort-header>
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let info"
                            class="title"
                            [matTooltip]="info.facility"
                            matTooltipPosition="right"
                            matTooltipShowDelay="250">
                            <p class="ellipsis">{{ info.facility }}</p>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="role">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Role
                            <eaglei-table-sort-header [columnName]="'role'" [sort]="tableSort"></eaglei-table-sort-header>
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let info"
                            class="title"
                            [matTooltip]="info.location.role"
                            matTooltipPosition="right"
                            matTooltipShowDelay="250">
                            <p class="ellipsis">{{ info.location.role | replaceCharacter }}</p>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="availableCapacity">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Available Capacity (MMBTU)
                            <eaglei-table-sort-header [columnName]="'availableCapacity'" [sort]="tableSort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let info">{{ info.available_capacity | number }}</td>
                    </ng-container>

                    <ng-container matColumnDef="scheduledCapacity">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Scheduled Capacity (MMBTU)
                            <eaglei-table-sort-header [columnName]="'scheduledCapacity'" [sort]="tableSort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let info">{{ info.scheduled_capacity | number }}</td>
                    </ng-container>

                    <ng-container matColumnDef="operationalCapacity">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Operational Capacity (MMBTU)
                            <eaglei-table-sort-header [columnName]="'operationalCapacity'" [sort]="tableSort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let info">{{ info.operational_capacity | number }}</td>
                    </ng-container>

                    <ng-container matColumnDef="designCapacity">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Design Capacity (MMBTU)
                            <eaglei-table-sort-header [columnName]="'designCapacity'" [sort]="tableSort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let info">{{ info.design_capacity | number }}</td>
                    </ng-container>

                    <ng-container matColumnDef="percentCapacity">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Percent Capacity
                            <eaglei-table-sort-header [columnName]="'percentCapacity'" [sort]="tableSort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let info">
                            <span class="status" [class]="getPercentage(info) | lowercase"></span>
                            {{ getPercentage(info) }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="bestFlowLocation">
                        <th mat-header-cell *matHeaderCellDef>Best Flow Location</th>
                        <td mat-cell *matCellDef="let info">{{ info.best_flow_location }}</td>
                    </ng-container>

                    <ng-container matColumnDef="measurementDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Measurement Date
                            <eaglei-table-sort-header [columnName]="'measurementDate'" [sort]="tableSort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let info">{{ info.gas_day | momentDate : 'M/D/YYYY' }}</td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="columns"></tr>
                    <tr mat-row *matRowDef="let row; columns: columns; let odd = odd"></tr>
                </table>
                <mat-paginator [pageSizeOptions]="[25, 50, 75, 100]" showFirstLastButtons></mat-paginator>
            </div>
            <div class="table-attribution">
                <a (click)="reportService.openAttribution(attributionUrl)" [innerHTML]="attributionTitle | sanitize"></a>
            </div>
        </mat-card>

        <div class="auto-mask hide-mask" *ngIf="showMask">
            <span class="mask-message" [class.no-data]="dataLoaded">{{ getMaskText() }}</span>
        </div>
    </section>
</ng-template>

<mat-menu #menu="matMenu" overlapTrigger>
    <button (click)="exportTable()" mat-menu-item>Export Table As CSV</button>
</mat-menu>
