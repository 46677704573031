import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, booleanAttribute} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {FormControl} from '@angular/forms';
import {debounceTime, distinctUntilChanged, skipWhile} from 'rxjs/operators';

@Component({
    selector: 'eaglei-text-search',
    templateUrl: './text-search.component.html',
    styleUrls: ['./text-search.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextSearchComponent {
    @Input() type: 'text' | 'number' = 'text';
    /** Label for the mat-form-field */
    @Input() fieldLabel?: string;
    @Input() placeholder: '';
    @Input({transform: booleanAttribute}) showLabel = false;

    @Output() textChange: EventEmitter<string> = new EventEmitter();

    control = new FormControl('');

    constructor() {
        this.control.valueChanges
            .pipe(
                skipWhile(() => this.control.pristine),
                takeUntilDestroyed(),
                debounceTime(400),
                distinctUntilChanged()
            )
            .subscribe((searchText: string) => this.textChange.emit(searchText));
    }
}
