import {LeafletLegendService} from '../../layer/services/leaflet-legend.service';
import {LeafletLayerLegend} from '../../layer/classes/leaflet-layer-legend';

export const addActiveLegend = (name: string, legends: LeafletLayerLegend[]) => {
    const allLegends = LeafletLegendService.legends.getValue();
    const index = allLegends.findIndex((l) => l.name === name);

    if (index === -1) {
        allLegends.push({name, legends});
    } else {
        allLegends[index].legends = legends;
    }

    LeafletLegendService.legends.next(allLegends);
};
