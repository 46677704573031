<div #classificationBanner class="classification-banner">
    <p *ngIf="!useMobileLayout()">
        This page contains dynamic content -- Highest Possible Classification is UNCLASSIFIED//CUI//CRIT/EMGT//TLP:AMBER
    </p>
    <p *ngIf="useMobileLayout()">Highest Possible Classification is UNCLASSIFIED//CUI//CRIT/EMGT//TLP:AMBE</p>
</div>
<div #accountBar class="account-bar" [class.mobile-account-bar]="useMobileLayout()">
    <ng-container *ngIf="useMobileLayout()" [ngTemplateOutlet]="mobileAccountBar"></ng-container>
    <ng-container *ngIf="!useMobileLayout()" [ngTemplateOutlet]="baseAccountBar"></ng-container>

    <mat-menu #dataMenu="matMenu" yPosition="below" [overlapTrigger]="false">
        <ng-container *ngFor="let link of dataMenuLinks">
            <a mat-menu-item *ngIf="link.access()" (click)="preformAction(link)">
                <mat-icon fontSet="fas" [fontIcon]="link.icon"></mat-icon>
                {{ link.name }}
            </a>
        </ng-container>
    </mat-menu>

    <mat-menu #helpMenu="matMenu" yPosition="below" [overlapTrigger]="false">
        <ng-container *ngFor="let link of helpMenuLinks">
            <a mat-menu-item *ngIf="link.access === undefined || link.access()" (click)="preformAction(link)">
                <mat-icon fontSet="fas" [fontIcon]="link.icon"></mat-icon>
                {{ link.name }}
            </a>
        </ng-container>
    </mat-menu>
    <!--    SYSTEM NOTIFICATIONS styling can be found in custom-menu.scss-->
    <mat-menu
        #notificationMenu="matMenu"
        yPosition="below"
        [overlapTrigger]="false"
        (closed)="markNotificationsAsSeen()"
        class="base-page-system-notification">
        <header>NOTIFICATIONS</header>

        <div *ngFor="let item of systemNotifications | keyvalue : notificationSort | slice : 0 : 10">
            <p class="notification-date">{{ item.key | momentDate : 'M/D/YY' : false : true }}</p>

            <div
                class="notification clickable"
                *ngFor="let notification of item.value"
                [class.seen]="notification.seen"
                (click)="viewNotification(notification)">
                <div>
                    <div>
                        <mat-icon class="selected-icon" [fontSet]="notification.iconStyle" [fontIcon]="notification.iconName"></mat-icon>
                    </div>
                    <div>
                        <p class="type">{{ notification.type | notificationType }}</p>
                        <p class="message multiline-ellipsis">{{ notification.message }}</p>
                    </div>
                </div>
            </div>
        </div>
    </mat-menu>
</div>

<div class="navigation-bar" [class.mobile-nav-bar]="useMobileLayout()">
    <div>
        <a href="http://energy.gov/" target="_blank">
            <img class="doe-logo" src="/dist/images/logos/doe-logo.png" alt="Department of Energy Logo" />
        </a>
    </div>

    <div class="navigation-icons">
        <mat-icon
            *ngFor="let icon of navIcons"
            fontSet="fas"
            [fontIcon]="icon.icon"
            (click)="navigateTo(icon.url)"
            [class.active-link]="currentRoute.includes(icon.url)"
            [matBadge]="getRecentEventCount().toString()"
            [matBadgeHidden]="icon.name !== 'Events' || getRecentEventCount() === 0"
            matBadgeColor="primary"
            [matTooltip]="icon.name"
            matTooltipPosition="after"
            matTooltipShowDelay="250"></mat-icon>
    </div>
</div>

<div class="main-page-view appContainer" [ngClass]="appClass" [class.new-design]="useNewDesign" [class.mobile-content]="useMobileLayout()">
    <router-outlet></router-outlet>

    <div class="page-mask" *ngIf="loadMaskConfig.showMask" [class.transparent]="!loadMaskConfig.showBackground">
        <div class="page-mask-text">
            <span>
                <i *ngIf="loadMaskConfig.showSpinner" class="fa fa-spinner fa-spin fa-pulse"></i>
                {{ loadMaskConfig.text }}
            </span>
        </div>
    </div>
</div>

<!--Override popover-->
<div
    #overridePopoverElement
    class="override-popover"
    style="width: 250px; background-color: lightgrey"
    (mouseenter)="inOverridePopover = true"
    (mouseleave)="closePopover(true)">
    <div *ngFor="let info of overrideInfo" class="override-state" [style.background-color]="overrideColor">
        <div (click)="expandOverrideState(info)" class="clickable">
            <span>{{ info.state }} ({{ info.counties.length }})</span>
            <mat-icon fontSet="fas" [fontIcon]="info.expanded ? 'fa-angle-up' : 'fa-angle-down'" [inline]="true"></mat-icon>
        </div>
        <ol class="override-counties hide" [class.show]="info.expanded">
            <li *ngFor="let county of info.counties">{{ county }}</li>
        </ol>
    </div>
</div>

<!--Page Masks-->
<!--Server Error Mask-->
<div class="full-page-mask" *ngIf="loginNeeded">
    <div class="login-banner">
        <div>
            <p>
                <i class="mdi mdi-alert-circle"></i>
                {{ loginBannerText }}
                <i class="mdi mdi-alert-circle"></i>
            </p>

            <a *ngIf="showLoginLink" (click)="goToLogin()" class="login-navigation">Click Here To Login</a>
        </div>
    </div>
</div>

<!--Full Page Mask-->
<div class="full-page-mask clear" *ngIf="showPageMask">
    <div class="mask-banner">
        <i class="fa fa-spinner fa-spin fa-pulse accent-text"></i>
    </div>
</div>

<!--Mobile menu-->
<div class="mobile-menu" [class.mobile-show]="showMobileMenu">
    <div class="mobile-header">
        <span>EAGLE-I</span>
        <mat-icon inline fontSet="fas" fontIcon="fa-times" class="clickable" (click)="showMobileMenu = false"></mat-icon>
    </div>

    <div class="nav-item-container">
        <div *ngFor="let icon of navIcons" class="nav-item" (click)="preformAction(icon)">
            <mat-icon fontSet="fas" [fontIcon]="icon.icon" routerLinkActive="active-link"></mat-icon>
            <span>{{ icon.name }}</span>
        </div>
    </div>

    <div class="data-menu-container" *ngIf="showDataList()">
        <div (click)="showDataMenu = !showDataMenu" class="dropdown-trigger">
            <div>
                <mat-icon fontSet="fas" fontIcon="fa-database"></mat-icon>
                <span>Data</span>
            </div>
            <mat-icon fontSet="fas" fontIcon="fa-chevron-down" class="no-margin"></mat-icon>
        </div>

        <div class="hide" [class.show]="showDataMenu">
            <ng-container *ngFor="let link of dataMenuLinks">
                <div class="dropdown-link" *ngIf="link.access()" (click)="preformAction(link)">
                    <mat-icon fontSet="fas" [fontIcon]="link.icon"></mat-icon>
                    {{ link.name }}
                </div>
            </ng-container>
        </div>
    </div>

    <div class="help-menu-container">
        <div (click)="showHelpMenu = !showHelpMenu" class="dropdown-trigger">
            <div>
                <mat-icon fontSet="fas" fontIcon="fa-question-circle"></mat-icon>
                <span>Help</span>
            </div>
            <mat-icon fontSet="fas" fontIcon="fa-chevron-down" class="no-margin"></mat-icon>
        </div>

        <div class="hide" [class.show]="showHelpMenu">
            <ng-container *ngFor="let link of helpMenuLinks">
                <div class="dropdown-link" (click)="preformAction(link)">
                    <mat-icon fontSet="fas" [fontIcon]="link.icon"></mat-icon>
                    {{ link.name }}
                </div>
            </ng-container>
        </div>
    </div>

    <div class="profile-menu-container clickable">
        <div (click)="showProfileMenu = !showProfileMenu" class="dropdown-trigger">
            <div>
                <mat-icon fontSet="fas" fontIcon="fa-user-circle"></mat-icon>
                <span>Profile</span>
            </div>
            <mat-icon fontSet="fas" fontIcon="fa-chevron-down" class="no-margin"></mat-icon>
        </div>

        <div class="hide" [class.show]="showProfileMenu">
            <eaglei-account-dropdown [showAsList]="true"></eaglei-account-dropdown>
        </div>
    </div>
</div>

<!--Popover -->
<div #eagleiPopover class="eaglei-popover invisible">
    <ng-container [ngSwitch]="popoverConfig.customPopover">
        <ng-container *ngSwitchCase="customPopover.ONG_REFINERIES">
            <eaglei-ong-refinery-popover></eaglei-ong-refinery-popover>
        </ng-container>
        <ng-container *ngSwitchCase="customPopover.SOCIAL_MEDIA">
            <eaglei-social-media-popover></eaglei-social-media-popover>
        </ng-container>
        <ng-container *ngSwitchCase="customPopover.RRCC">
            <eaglei-rrcc-popover [user]="user"></eaglei-rrcc-popover>
        </ng-container>
        <ng-container *ngSwitchCase="customPopover.ONG_NOMINATIONS">
            <eaglei-ong-nominations-popover></eaglei-ong-nominations-popover>
        </ng-container>
        <ng-container *ngSwitchCase="customPopover.ONG_CRITICAL_NOTICES">
            <eaglei-ong-pipeline-popover></eaglei-ong-pipeline-popover>
        </ng-container>
        <ng-container *ngSwitchCase="customPopover.ONG_OIL_OUTAGES">
            <eaglei-ong-oil-outage-popover></eaglei-ong-oil-outage-popover>
        </ng-container>
        <ng-container *ngSwitchCase="customPopover.ONG_GAS_BURN">
            <eaglei-ong-gas-burn-popover></eaglei-ong-gas-burn-popover>
        </ng-container>
        <ng-container *ngSwitchCase="customPopover.PORT_STATUS">
            <eaglei-port-status-popover></eaglei-port-status-popover>
        </ng-container>
        <ng-container *ngSwitchCase="customPopover.OIL_BARREL_FLOW">
            <eaglei-oil-barrel-flow-popover></eaglei-oil-barrel-flow-popover>
        </ng-container>
        <ng-container *ngSwitchCase="customPopover.SURVEY_123">
            <eaglei-survey123-popover></eaglei-survey123-popover>
        </ng-container>
        <ng-container *ngSwitchDefault>
            <eaglei-popover
                *ngIf="popoverConfig.data?.length > 0"
                [data]="popoverConfig.data"
                (contentModal)="openPopoverModal($event)"
                (mouseenter)="setInPopover(true, $event)"
                (mouseleave)="setInPopover(false, $event)">
            </eaglei-popover>
        </ng-container>
    </ng-container>
</div>

<!-- templates -->

<ng-template #baseAccountBar>
    <p class="page-title title">{{ getPageTitle() }}</p>

    <ng-container [ngTemplateOutlet]="alertBanners"></ng-container>

    <ng-container>
        <div class="quick-links clickable">
            <mat-icon
                fontSet="fas"
                fontIcon="fa-carrot"
                (click)="navigateTo('/app/management/qa-data-management')"
                *ngIf="environment === 'development' || environment === 'test'"></mat-icon>
            <mat-icon fontSet="fas" fontIcon="fa-database" [matMenuTriggerFor]="dataMenu"></mat-icon>
            <mat-icon fontSet="fas" fontIcon="fa-question-circle" [matMenuTriggerFor]="helpMenu"></mat-icon>
            <mat-icon
                fontSet="far"
                fontIcon="fa-bell"
                [matBadge]="getNumberOfNotifications().toString()"
                [matBadgeHidden]="getNumberOfNotifications() === 0"
                matBadgeColor="primary"
                [matMenuTriggerFor]="notificationMenu"></mat-icon>
            <eaglei-account-dropdown></eaglei-account-dropdown>
        </div>
    </ng-container>
</ng-template>

<ng-template #mobileAccountBar>
    <div>
        <div>
            <p class="page-title title ellipsis">{{ getPageTitle() }}</p>

            <button mat-icon-button (click)="showMobileMenu = true">
                <mat-icon fontSet="fas" fontIcon="fa-bars"></mat-icon>
            </button>
        </div>

        <div style="overflow-x: scroll">
            <ng-container [ngTemplateOutlet]="alertBanners"></ng-container>
        </div>
    </div>
</ng-template>

<ng-template #alertBanners>
    <div class="notification">
        <eaglei-lock-time-banner *ngIf="isTimeLocked$ | async"></eaglei-lock-time-banner>

        <mat-chip *ngIf="overridesActive" (mouseenter)="openPopover($event)" (mouseleave)="closePopover()">
            <mat-icon matChipAvatar fontSet="fas" fontIcon="fa-info-circle"></mat-icon>
            <span>Outage Overrides</span>
        </mat-chip>

        <mat-chip *ngIf="generatedOutagesActive">
            <mat-icon matChipAvatar fontSet="fas" fontIcon="fa-info-circle"></mat-icon>
            <span>Outages Generated</span>
        </mat-chip>

        <mat-chip *ngIf="showSituationBanner" class="clickable" (click)="downloadSituationReport()">
            <mat-icon matChipAvatar fontSet="fas" fontIcon="fa-info-circle"></mat-icon>
            <span>Situation Report</span>
        </mat-chip>

        <mat-chip *ngIf="socketConnectionService.outageSummaryStatus | async">
            <mat-icon matChipAvatar fontSet="fas" fontIcon="fa-info-circle"></mat-icon>
            <span>{{ (socketConnectionService.outageSummaryStatus | async).valueOf() }}</span>
        </mat-chip>
    </div>
</ng-template>
