<div fxLayout="row" fxLayoutAlign="space-between" class="dialog-header">
    <span>Save Layout</span>
    <mat-icon inline class="clickable" fontSet="fas" fontIcon="fa-times" (click)="dialogRef.close()"> </mat-icon>
</div>

<div class="dialog-content" #content fxLayout="column" fxLayoutGap="20px">
    <p class="info-text">Save your customized layout as your default layout or access it later from the “My Templates” dropdown.</p>

    <form>
        <p class="input-label">Layout Name</p>
        <mat-form-field class="highlight-focus">
            <input type="text" name="layoutName" matInput [formControl]="nameControl" (blur)="nameControl.updateValueAndValidity()" />

            <mat-hint *ngIf="duplicateName">
                <span class="primary-text">Duplicate Layout name, your layout will be updated.</span>
            </mat-hint>
            <mat-error>
                <ng-container *ngIf="nameControl.invalid">
                    <span *ngIf="nameControl.errors['required']">A layout name is required</span>
                </ng-container>
            </mat-error>
        </mat-form-field>
    </form>

    <mat-checkbox fxFlexAlign="start" [checked]="isDefault" (change)="isDefault = $event.checked">Set as default layout</mat-checkbox>

    <div *ngIf="!!defaultLayout && isDefault" class="override-warning">
        <b>{{ nameControl.value }}</b> will overwrite <b>{{ defaultLayout.name }}</b> as your default layout.
    </div>
</div>

<div class="dialog-actions" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="15px">
    <button (click)="dialogRef.close()" mat-stroked-button>Cancel</button>

    <div
        matTooltip="Can not save layout with no widgets"
        matTooltipShowDelay="250"
        matTooltipPosition="above"
        [matTooltipDisabled]="data.hasDetails">
        <button
            mat-flat-button
            color="primary"
            (click)="saveLayout()"
            [disabled]="nameControl.value.trim().length === 0 || !data.hasDetails">
            Save
        </button>
    </div>
</div>
