import {GenUploadedFile} from '../../../../../generated/serverModels/GenUploadedFile';
import {EilData} from '../../../classes/eil-data';

export class EilDocument extends GenUploadedFile {
    public data: File;
    public isNew = false;
    public viewDescription: boolean;

    constructor(document?: EilDocument, parentData?: EilData) {
        super(document);

        this.parentId = parentData?.id;
    }
}
