
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import { GenApiKey } from './GenApiKey';
import { GenCommunityPermission } from './GenCommunityPermission';
import { GenPointOfContact } from './GenPointOfContact';
import { GenRoleDefinition } from './GenRoleDefinition';
import { GenUserPreferences } from './GenUserPreferences';
import * as moment from 'moment';

export class GenUser {

    id: number;
    accountEnabled: boolean;
    address1: string;
    address2: string;
    alternateEmail: string;
    apiKey: string;
    apiKeys: GenApiKey[];
    approved: boolean;
    city: string;
    dataAccess: GenCommunityPermission[];
    department: string;
    eeacState: string;
    email: string;
    emailVerificationCode: string;
    emailVerified: boolean;
    enabled: boolean;
    expirationDate: moment.Moment;
    firstName: string;
    impersonating: boolean;
    joinDate: moment.Moment;
    lastLoginDate: moment.Moment;
    lastName: string;
    lastPasswordReset: moment.Moment;
    name: string;
    office: string;
    oldPassword: string;
    organization: string;
    originatingSystem: string;
    password: string;
    passwordResetCode: string;
    passwordResetTime: moment.Moment;
    pendingAPIKeyRequest: boolean;
    phone: string;
    pinnedReports: number[];
    poc: GenPointOfContact;
    postalCode: string;
    reason: string;
    requestReason: string;
    resettingPassword: boolean;
    roles: GenRoleDefinition[];
    state: string;
    stateEmergencyManagementAgency: boolean;
    stateEmergencyOperationsAenter: boolean;
    stateEnergyOffice: boolean;
    stateGovernorsOffice: boolean;
    stateUtilityCommission: boolean;
    telephone: string;
    title: string;
    userPreferences: GenUserPreferences;
    username: string;
    usersSupported: number;

    constructor();
    constructor( jsonObj: any );
    constructor( genUser: GenUser);
    constructor( id: number );

    constructor( input?: GenUser|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            this.accountEnabled = input.accountEnabled; // boolean [boolean]
            this.address1 = input.address1; // string [string]
            this.address2 = input.address2; // string [string]
            this.alternateEmail = input.alternateEmail; // string [string]
            this.apiKey = input.apiKey; // string [string]
            if ( input.apiKeys && input.apiKeys.map ) { // list [GenApiKey]
                this.apiKeys = input.apiKeys.map( ( x: GenApiKey ) => {
                    return new GenApiKey( x );
                } );
            } else {
                this.apiKeys = input.apiKeys;
            }
            this.approved = input.approved; // boolean [boolean]
            this.city = input.city; // string [string]
            if ( input.dataAccess && input.dataAccess.map ) {
                this.dataAccess = input.dataAccess.map( ( x: GenCommunityPermission ) => {
                    return (typeof x === 'string') ? GenCommunityPermission.forName(<string> x) : <any> x; // GenCommunityPermission
                } );
            } else {
                this.dataAccess = input.dataAccess; // GenCommunityPermission
            }
            this.department = input.department; // string [string]
            this.eeacState = input.eeacState; // string [string]
            this.email = input.email; // string [string]
            this.emailVerificationCode = input.emailVerificationCode; // string [string]
            this.emailVerified = input.emailVerified; // boolean [boolean]
            this.enabled = input.enabled; // boolean [boolean]
            if ( input.expirationDate && typeof input.expirationDate === 'string' ) {
                this.expirationDate = moment(input.expirationDate).utc();
            } else {
                this.expirationDate = input.expirationDate;
            }
            this.firstName = input.firstName; // string [string]
            this.impersonating = input.impersonating; // boolean [boolean]
            if ( input.joinDate && typeof input.joinDate === 'string' ) {
                this.joinDate = moment(input.joinDate).utc();
            } else {
                this.joinDate = input.joinDate;
            }
            if ( input.lastLoginDate && typeof input.lastLoginDate === 'string' ) {
                this.lastLoginDate = moment(input.lastLoginDate).utc();
            } else {
                this.lastLoginDate = input.lastLoginDate;
            }
            this.lastName = input.lastName; // string [string]
            if ( input.lastPasswordReset && typeof input.lastPasswordReset === 'string' ) {
                this.lastPasswordReset = moment(input.lastPasswordReset).utc();
            } else {
                this.lastPasswordReset = input.lastPasswordReset;
            }
            this.name = input.name; // string [string]
            this.office = input.office; // string [string]
            this.oldPassword = input.oldPassword; // string [string]
            this.organization = input.organization; // string [string]
            this.originatingSystem = input.originatingSystem; // string [string]
            this.password = input.password; // string [string]
            this.passwordResetCode = input.passwordResetCode; // string [string]
            if ( input.passwordResetTime && typeof input.passwordResetTime === 'string' ) {
                this.passwordResetTime = moment(input.passwordResetTime).utc();
            } else {
                this.passwordResetTime = input.passwordResetTime;
            }
            this.pendingAPIKeyRequest = input.pendingAPIKeyRequest; // boolean [boolean]
            this.phone = input.phone; // string [string]
            this.pinnedReports = input.pinnedReports; // list [number]
            if( input.poc ) {
              this.poc = new GenPointOfContact( input.poc );
            }
            this.postalCode = input.postalCode; // string [string]
            this.reason = input.reason; // string [string]
            this.requestReason = input.requestReason; // string [string]
            this.resettingPassword = input.resettingPassword; // boolean [boolean]
            if ( input.roles && input.roles.map ) {
                this.roles = input.roles.map( ( x: GenRoleDefinition ) => {
                    return (typeof x === 'string') ? GenRoleDefinition.forName(<string> x) : <any> x; // GenRoleDefinition
                } );
            } else {
                this.roles = input.roles; // GenRoleDefinition
            }
            this.state = input.state; // string [string]
            this.stateEmergencyManagementAgency = input.stateEmergencyManagementAgency; // boolean [boolean]
            this.stateEmergencyOperationsAenter = input.stateEmergencyOperationsAenter; // boolean [boolean]
            this.stateEnergyOffice = input.stateEnergyOffice; // boolean [boolean]
            this.stateGovernorsOffice = input.stateGovernorsOffice; // boolean [boolean]
            this.stateUtilityCommission = input.stateUtilityCommission; // boolean [boolean]
            this.telephone = input.telephone; // string [string]
            this.title = input.title; // string [string]
            if( input.userPreferences ) {
              this.userPreferences = new GenUserPreferences( input.userPreferences );
            }
            this.username = input.username; // string [string]
            this.usersSupported = input.usersSupported; // int [number]
       }
    }

    static jsonResponseToList(arr: any[]): GenUser[] {
        if (arr && arr.map) {
          return arr.map(a => new GenUser(a));
        } else {
          return [];
        }
    }
}

