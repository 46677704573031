import {DecimalPipe} from '@angular/common';
import {PopoverElement} from 'frontend/src/app/classes/popover-element';
import {MomentDatePipe} from 'frontend/src/shared/pipes/moment-date.pipe';
import * as L from 'leaflet';
import {LatLng, Layer} from 'leaflet';
import * as moment from 'moment';
import {LeafletFeature} from '../classes/leaflet-feature';
import {LeafletMapLayer} from '../classes/leaflet-map-layer';
import {LayerStyleService} from '../services/layer-style.service';
import {ClusterSource} from '../classes/cluster-source';

export class OngGasBurnSource extends ClusterSource {
    private numberPipe = new DecimalPipe('en-us');
    private datePipe = new MomentDatePipe();

    constructor(layerInfo: LeafletMapLayer) {
        super(layerInfo);
    }

    processFeatures(features: any[] | any | undefined): void {
        // Group the features into a list based on the location id
        const featureList = features.reduce((result, obj) => {
            (result[obj.properties['location_id']] = result[obj.properties['location_id']] || []).push(obj);
            return result;
        }, {});

        // Sort the features and tie them together to build chart in popover
        const refinedFeatures = [];

        Object.values(featureList).forEach((value: any) => {
            value.sort((a, b) => moment(a.flow_timestamp).valueOf() - moment(b.flow_timestamp).valueOf());
            value[0].properties.details = value;
            refinedFeatures.push(value[0]);
        });

        const leafletFeatures = refinedFeatures.map((f) => {
            const feature = new LeafletFeature(f).buildPopover(this.layerInfo);

            const data = [
                new PopoverElement().setTitle().setValue(feature.properties.location_name),
                new PopoverElement().setLabel('Industry').setValue(feature.properties.industry_title),
                new PopoverElement()
                    .setLabel('Hourly Flow')
                    .setValue(`${this.numberPipe.transform(feature.properties?.gas_burns[0]?.hourly_flow, '1.0-0')} mcf`),
                new PopoverElement()
                    .setLabel('Timestamp')
                    .setValue(this.datePipe.transform(feature.properties?.gas_burns[0]?.flow_timestamp)),
                new PopoverElement().setLabel('County').setValue(feature.properties.county),
                new PopoverElement().setLabel('State').setValue(feature.properties.state),
            ];

            feature.subFeatures = [
                {
                    id: feature.properties.location_id,
                    popoverData: data,
                },
            ];

            return feature;
        });

        const style = (feature: any, latlng: LatLng): Layer => {
            return L.marker(latlng, {
                icon: getStyle(),
            });
        };

        const config = {
            pointToLayer: style,
            onEachFeature: this.initializePopoverInteractions.bind(this),
        };

        const clusterConfig: L.MarkerClusterGroupOptions = {
            iconCreateFunction: getClusterStyle,
            chunkedLoading: true,
        };

        const cluster = new L.MarkerClusterGroup(clusterConfig);
        cluster.addLayer(L.geoJSON(leafletFeatures as any, config as any));

        this.source = cluster as any;
    }
}

function getStyle(): any {
    const key = `ongGasBurn`;
    let style = LayerStyleService.layerStyles.get(key);

    if (!style) {
        const options = {
            icon: 'fire',
            backgroundColor: '#9D120B',
            borderColor: '#9D120B',
            innerIconStyle: `color:white; font-size:20px; position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%);`,
            innerIconAnchor: [0, 0],
            iconSize: [25, 25],
        };

        style = (L as any).BeautifyIcon.icon(options);

        LayerStyleService.layerStyles.set(key, style);
    }

    return style;
}

function getClusterStyle(cluster: L.MarkerCluster): any {
    const options = {
        icon: 'circle',
        backgroundColor: '#9D120B',
        borderColor: '#9D120B',
        innerIconStyle: `position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); margin-top: 0px; text`,
        iconSize: [25, 25],
        isAlphaNumericIcon: true,
        text: `${cluster.getChildCount()}`,
        textColor: 'white',
    };
    return (L as any).BeautifyIcon.icon(options);
}
