import {Component} from '@angular/core';
import {EagleILayer} from '../../classes/eagle-i-layer';
import {OngRefinerySource} from '../../sources/ong-refinery-source';
import {tap} from 'rxjs/operators';
import {LayerService} from '../../services/layer.service';
import {CustomPopover} from '../../enum/custom-popover';
import {GenLegendType} from '../../../../../../generated/serverModels/GenLegendType';
import {HttpInterceptorService} from 'frontend/src/app/services/http-interceptor.service';
import {OngService} from '../../../../services/ong.service';

@Component({
    selector: 'eaglei-ong-refineries',
    templateUrl: './ong-refineries.component.html',
    styleUrls: ['../layers.scss', './ong-refineries.component.scss'],
})
export class OngRefineriesComponent extends EagleILayer<OngRefinerySource> {
    constructor(private layerService: LayerService, private ongService: OngService) {
        super();
    }

    afterViewInit(): void {
        this.mapLayer.customPopoverId = CustomPopover.ONG_REFINERIES;
        this.mapLayer.legend.type = GenLegendType.HARD_CODED;
    }

    toggleLayer(activeState: boolean, zoomToFeatures?: boolean | undefined): void {
        if (activeState) {
            if (!this.layerSource) {
                this.layerSource = new OngRefinerySource(this.mapLayer);
            }

            this.getRefineries();
        } else {
            HttpInterceptorService.clearInterceptor(this.mapLayer.uiHandle);
            this.layerSource.removeFromMap();
        }
    }

    private getRefineries(): void {
        this.mapLayer.startLoading();
        HttpInterceptorService.clearInterceptor(this.mapLayer.uiHandle);
        HttpInterceptorService.pendingRequests[this.mapLayer.uiHandle] = this.ongService
            .getRefineryStatus()
            .pipe(
                tap(() => {
                    HttpInterceptorService.deleteFromInterceptor(this.mapLayer.uiHandle);
                    this.mapLayer.endLoading();
                })
            )
            .subscribe((res) => {
                this.layerSource.processFeatures(res.features);
                this.layerSource.removeFromMap();
                this.layerSource.addToMap();
            });
    }
}
