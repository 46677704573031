import {inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {SystemEvent} from '../classes/system-event';
import {HttpClient} from '@angular/common/http';
import {map, tap} from 'rxjs/operators';
import {SystemEventType} from '../classes/system-event-type';
import {ApplicationConfig} from '../../../classes/application-config';
import {GenRadrUpdateNotice} from '../../../../../generated/serverModels/GenRadrUpdateNotice';
import {GroupedSystemEvents} from '../classes/grouped-system-events';

@Injectable({
    providedIn: 'root',
})
export class SystemEventService {
    public http = inject(HttpClient);
    private readonly baseUrl = `api/event`;

    public currentEvents = new BehaviorSubject<SystemEvent[]>([]);
    public groupedEvents$ = new BehaviorSubject<GroupedSystemEvents>(undefined);

    constructor(private httpClient: HttpClient) {}

    /**
     * Comparison function used by the Event type select to compare ids instead of objects
     * @param v1 The value from the option
     * @param v2 The value being comared for the select.
     */
    public typeCompareFunction(v1: SystemEventType, v2: SystemEventType): boolean {
        if (!v2) return false;
        return v1.id === v2.id;
    }

    public getAllEvents(): Observable<SystemEvent[]> {
        const url = `api/event`;
        return this.httpClient.get<any[]>(url).pipe(map((events) => events.map((event) => new SystemEvent(event))));
    }

    public getGroupedEvents(): Observable<GroupedSystemEvents> {
        if (this.groupedEvents$.getValue() !== undefined) {
            return this.groupedEvents$.asObservable();
        }

        return this.getAllEvents().pipe(
            map((events) => {
                const groupedEvents = {active: [], inactive: []};
                const now = ApplicationConfig.roundMinute();
                events.forEach((event) => {
                    const isActive = now.isBetween(event.eventStart, event.eventEnd, undefined, '[]');
                    isActive ? groupedEvents.active.push(event) : groupedEvents.inactive.push(event);
                });
                return groupedEvents;
            }),
            tap((groupedEvents) => this.groupedEvents$.next(groupedEvents))
        );
    }

    public getEventById(id: string | number): Observable<SystemEvent> {
        const url = `api/event/${id}`;
        return this.httpClient.get<any>(url).pipe(map((res) => new SystemEvent(res)));
    }

    public getCurrentSystemEvents(): Observable<SystemEvent[]> {
        const url = `api/event/current`;
        return this.httpClient.get<SystemEvent[]>(url).pipe(map((res) => res.map((event) => new SystemEvent(event))));
    }

    public createEvent(event: SystemEvent): Observable<SystemEvent> {
        let url = `${this.baseUrl}`;
        let call: Observable<any> = this.httpClient.post(url, event);

        if (event.id) {
            url += `/${event.id}`;
            call = this.httpClient.put(url, event);
        }

        return call.pipe(
            tap(() => {
                this.getCurrentSystemEvents().subscribe((events) => this.currentEvents.next(events));
            }),
            map((res) => new SystemEvent(res))
        );
    }

    public getEventTypes(): Observable<SystemEventType[]> {
        return this.httpClient.get<any[]>(`${this.baseUrl}/types`).pipe(map((types) => types.map((type) => new SystemEventType(type))));
    }

    public getRadrImages(event: SystemEvent): Observable<GenRadrUpdateNotice[]> {
        const url = `api/event/radr/images/${event.id}`;
        return this.httpClient.get<any[]>(url).pipe(map((images) => images.map((image) => new GenRadrUpdateNotice(image))));
    }

    public sendRadrRequest(sendData: GenRadrUpdateNotice): Observable<any> {
        const url = 'api/event/radr';
        return this.http.post(url, sendData);
    }
}
