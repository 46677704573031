import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {FileDownload} from 'frontend/src/app/classes/file-download';
import {MomentDatePipe} from 'frontend/src/shared/pipes/moment-date.pipe';
import * as moment from 'moment';
import {filter, takeUntil} from 'rxjs/operators';
import {BaseWidget} from '../../classes/base-widget';
import {RefineryAlert} from '../../classes/oil-refinery-alert-widget';
import {WidgetService} from '../../services/widget.service';
import {OilRefineryAlertInfoModalComponent} from 'frontend/src/app/modules/widget/modals/oil-refinery-alert-info-modal/oil-refinery-alert-info-modal.component';
import {ModalConfig} from 'frontend/src/app/classes/modal-config';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {ApplicationConfig} from 'frontend/src/app/classes/application-config';
import {OngService} from '../../../../services/ong.service';
import {EventSummaryFilters} from '../../../system-event/classes/event-summary-filters';
import {LockTimeService} from '../../../../services/lock-time.service';
import {State} from '../../../outage/classes/state';
import {oilRefineryAlerts} from '../../../../../integrations/ong/classes/ong-attributions-text';

@Component({
    selector: 'eaglei-oil-refinery-alert-widget',
    templateUrl: './oil-refinery-alert-widget.component.html',
    styleUrls: ['./oil-refinery-alert-widget.component.scss'],
})
export class OilRefineryAlertWidgetComponent extends BaseWidget implements AfterViewInit {
    private momentPipe = new MomentDatePipe();
    public filters: EventSummaryFilters = new EventSummaryFilters().setDate(ApplicationConfig.roundMinute());

    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    // Table Properties
    public readonly columns: string[] = ['description', 'facility', 'productClass', 'date'];
    public alerts: MatTableDataSource<RefineryAlert>;
    public selectedStates: State[] = [];

    constructor(private widgetElement: ElementRef, public ongService: OngService, private dialog: MatDialog) {
        super(widgetElement, true);
        this.attributionUrl = 'https://www.woodmac.com/';
        this.attributionText = 'Genscape: A Wood Mackenzie Business';

        this.resizeWidget();
    }

    onApplicationTimeChange() {
        this.filters.setDate(LockTimeService.currentApplicationTime.getValue());
        this.getAlerts();
    }

    protected handleEventFilterChange(eventFilters: EventSummaryFilters) {
        this.filters = eventFilters;
        this.getAlerts();
    }

    ngAfterViewInit() {
        this.getUserPreferences();
        this.selectedStates = this.userPreferences.getStates();
        this.attributionModalText = oilRefineryAlerts;
        this.widgetName = 'Oil Refinery Alerts';
        this.getAlerts();
    }

    destroyWidget(): void {}

    private getAlerts() {
        const endDate = this.filters?.date.clone().add(1, 'day');
        const startDate = this.filters?.date;
        const locations = this.eventDashboard ? this.filters?.locations : this.selectedStates;

        this.ongService.getOilRefineryAlerts(startDate, endDate, locations).subscribe((res) => {
            this.initializeData(res);
        });
    }

    private resizeWidget() {
        WidgetService.redrawMap
            .pipe(
                filter(() => !!this.header && !!this.content),
                takeUntil(this.destroy$)
            )
            .subscribe(() => {
                this.resize();
            });
    }

    // Table Methods
    /**
     * Sets the data used in the table and also creates all the interactions on the table
     * @param data A list of recent events to be in the table.
     */
    private initializeData(data: RefineryAlert[]): void {
        // noinspection DuplicatedCode
        if (this.alerts) {
            this.alerts.data = data;
        } else {
            this.alerts = new MatTableDataSource(data);
            this.alerts.filterPredicate = this.filterPredicate.bind(this);
            this.alerts.paginator = this.paginator;
            this.alerts.sortingDataAccessor = this.dataAccessor.bind(this);
            this.alerts.sort = this.sort;
        }
        this.filterAlerts(this.alerts.filter);
    }

    // noinspection JSMethodCanBeStatic
    /**
     * The logic behind how the columns are sorted.
     * @param data The recent event being sorted
     * @param header The column name being sorted on.
     */
    private dataAccessor(data: RefineryAlert, header: string): string | number {
        switch (header) {
            case 'description':
                return data.description?.toLowerCase();
            case 'facility':
                return data.facility_name?.toLowerCase();
            case 'productClass':
                return data.product_class?.toLowerCase();
            case 'date':
                return data.distributed_datetime?.valueOf();
            default:
                return '';
        }
    }

    // Export Methods
    /**
     * Exports the table as a CSV File
     */
    public exportTable(): void {
        let data: string = ['Description', 'Facility', 'Product Class', 'Date'].join() + '\n';
        this.alerts.filteredData.forEach((alert) => {
            const info = [
                FileDownload.formatCsvCell(alert.description),
                FileDownload.formatCsvCell(alert.facility_name),
                FileDownload.formatCsvCell(alert.product_class),
                FileDownload.formatCsvCell(this.momentPipe.transform(alert.distributed_datetime)),
            ];
            data += `${info.join()}\n`;
        });

        FileDownload.downloadCSV('oilRefineryAlertsWidget', data, this.attributionUrl);
    }

    // Filter Methods
    /**
     * Called when the date range is updated, filters data.
     * @param date The new date.
     */
    public updateDateRange(date: moment.Moment): void {
        this.filters.date = date;
        this.getAlerts();
    }

    // Filter Methods
    /**
     * Called when the states are updated, filters data.
     * @param states The states array
     */
    public updateState(states: State[]): void {
        this.selectedStates = states;
        this.getAlerts();
    }

    /**
     * The logic behind how the table is filtered, is called when the filter property on the dataSource is changed.
     * @param data The recent event being tested.
     * @param text The text being searched for.
     */
    // noinspection JSUnusedLocalSymbols
    private filterPredicate(data: RefineryAlert, text: string): boolean {
        let dateCheck = false;
        let stateCheck = false;

        if (this.selectedStates.length > 0) {
            stateCheck = this.selectedStates.map((state) => state.abbreviation).includes(data.state);
        }

        if (this.filters?.date) {
            dateCheck = this.filters.date.isSame(data.distributed_datetime, 'day');
        }

        return stateCheck && dateCheck;
    }

    /**
     * sets the filter on the dataSource and triggers the table filter.
     * This should be called by all filter update methods.
     * @param text The text being passed to the dataSource filter.
     */
    public filterAlerts(text: string): void {
        text = text === '' ? ' ' : text;
        this.alerts.filter = text;
    }

    public openMaintenanceModal(data: any): void {
        const config: MatDialogConfig = {
            width: ModalConfig.getModalWidth(),
            disableClose: true,
            autoFocus: false,
            data: {
                event: data,
            },
        };

        this.dialog.open(OilRefineryAlertInfoModalComponent, config);
    }
}
