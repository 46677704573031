import {Component, ElementRef, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PopoverElement} from '../../../app/classes/popover-element';
import {PopoverDataModalComponent} from '../../../app/modules/map/modals/popover-data-modal/popover-data-modal.component';
import {ModalConfig} from '../../../app/classes/modal-config';
import {ApplicationConfig} from 'frontend/src/app/classes/application-config';
import {PopoverConfig} from 'frontend/src/app/classes/popover-config';

@Component({
    selector: 'eaglei-popover',
    templateUrl: './popover.component.html',
    styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent implements OnInit {
    constructor(public ele: ElementRef) {}

    @Input() data: PopoverElement[];
    @Output() contentModal = new EventEmitter<any>();

    ngOnInit() {
        let index = 0;
        if (this.data && this.data.forEach) {
            this.data.forEach((element: PopoverElement) => {
                if (element.label === 'title') {
                    index = this.data.indexOf(element);
                }
            });
            const removedElement = this.data.splice(index, 1);
            this.data.unshift(removedElement[0]);
        }
    }

    public onLite(): boolean {
        return ApplicationConfig.onLite();
    }

    public closePopover(): void {
        PopoverConfig.hideNewPopover();
    }

    openContentModal(index: number) {
        const info = this.data[index];

        if (!info.dialogRef) {
            const data = {
                title: this.data[0].value,
                content: this.data[index].value,
            };

            const config = {
                width: ModalConfig.getModalWidth(),
                data,
                autoFocus: false,
            };

            info.setDialogRef(PopoverDataModalComponent, config);
        }

        this.contentModal.emit(info);
    }
}
