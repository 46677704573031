import {LeafletVectorSource} from '../classes/leaflet-vector-source';
import {LeafletMapLayer} from '../classes/leaflet-map-layer';
import * as L from 'leaflet';
import {LatLng, Layer} from 'leaflet';
import {MapService} from '../../map/services/map.service';
import * as moment from 'moment';
import {LayerStyleService} from '../services/layer-style.service';
import {LeafletFeature} from '../classes/leaflet-feature';

export class EarthquakeSource extends LeafletVectorSource {
    private readonly legendValues = {
        '2.5': {magnitude: '2.5 - 2.9', radius: '5', id: 'earthquake1'},
        '2.9': {magnitude: '3.0 - 4.9', radius: '6', id: 'earthquake2'},
        '5.0': {magnitude: '5.0+', radius: '7', id: 'earthquake3'},
        '2.5lh': {magnitude: '2.5 - 2.9, past hour', radius: '5', id: 'earthquake4'},
        '2.9lh': {magnitude: '3.0 - 4.9, past hour', radius: '6', id: 'earthquake5'},
        '5.0lh': {magnitude: '5.0+, past hour', radius: '7', id: 'earthquake6'},
    };

    constructor(layerInfo: LeafletMapLayer) {
        super(layerInfo);
    }

    processFeatures(earthquakes: any[]): void {
        const leafletFeatures = earthquakes.map((f) => new LeafletFeature(f).buildPopover(this.layerInfo));

        const style = (feature: any, latlng: LatLng): Layer => {
            return L.circleMarker(latlng, this.getStyle(feature));
        };

        const config: any = {
            pane: MapService.layerPaneName,
            pointToLayer: style,
            onEachFeature: this.initializePopoverInteractions.bind(this),
            features: leafletFeatures,
        };

        this.source = L.geoJSON(leafletFeatures as any, config);
    }

    private getStyle(feature: any): any {
        const earthquake = feature.properties;
        const magnitude = earthquake.mag;
        const withinLastHour = moment(earthquake.time).isAfter(moment().subtract(1, 'hours'));
        let fillColor = 'rgb(255,170,0)';
        let key = '';

        if (magnitude <= 2.9) {
            key = '2.5';
        } else if (magnitude > 2.9 && magnitude <= 4.9) {
            key = '2.9';
        } else if (magnitude > 4.9) {
            key = '5.0';
        }
        if (withinLastHour) {
            key += 'lh';
            fillColor = 'rgb(230,0,0)';
        }

        const info = this.legendValues[key];
        let style = LayerStyleService.layerStyles.get(`earthquake-${key}`);

        if (!style) {
            style = {
                radius: info.radius,
                fillColor: fillColor,
                color: '#000',
                weight: 1,
                opacity: 1,
                fillOpacity: 0.8,
            };
        }
        LayerStyleService.layerStyles.set(key, style);
        return style;
    }
}
