
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

export class GenNotificationType {
    static readonly SYSTEM_NEWS: GenNotificationType = new GenNotificationType('SYSTEM_NEWS');
    static readonly RECENT_EVENT: GenNotificationType = new GenNotificationType('RECENT_EVENT');
    static readonly COMMUNITY: GenNotificationType = new GenNotificationType('COMMUNITY');
    static readonly PROFILE: GenNotificationType = new GenNotificationType('PROFILE');
    static readonly PASSWORD: GenNotificationType = new GenNotificationType('PASSWORD');
    static readonly API_KEY: GenNotificationType = new GenNotificationType('API_KEY');

    public readonly name: string;

    private constructor(name: string) {
        this.name = name;
    }

    static forName(name: string) {
        if (name === 'SYSTEM_NEWS') return GenNotificationType.SYSTEM_NEWS;
        if (name === 'RECENT_EVENT') return GenNotificationType.RECENT_EVENT;
        if (name === 'COMMUNITY') return GenNotificationType.COMMUNITY;
        if (name === 'PROFILE') return GenNotificationType.PROFILE;
        if (name === 'PASSWORD') return GenNotificationType.PASSWORD;
        if (name === 'API_KEY') return GenNotificationType.API_KEY;
        throw new Error('Unexpected value for GenNotificationType:' + name);
    }

    static values(): GenNotificationType[] {
        return [
            GenNotificationType.SYSTEM_NEWS,
            GenNotificationType.RECENT_EVENT,
            GenNotificationType.COMMUNITY,
            GenNotificationType.PROFILE,
            GenNotificationType.PASSWORD,
            GenNotificationType.API_KEY,
        ];
    }

    toJSON(): string {
        return this.name;
    }

    toString(): string {
        return this.name;
    }
}

