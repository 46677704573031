<div class="dialog-header">
    <span>{{ community.name }} Membership Request</span>
    <button mat-icon-button (click)="dialogRef.close()">
        <mat-icon fontSet="fas" fontIcon="fa-times"></mat-icon>
    </button>
</div>

<div class="dialog-content" #content>
    <form [formGroup]="requestGroup">
        <mat-form-field appearance="outline" class="wide">
            <mat-label>Membership Reason</mat-label>
            <textarea
                matInput
                cdkTextareaAutosize
                cdkAutosizeMaxRows="5"
                cdkAutosizeMinRows="5"
                name="justification"
                formControlName="justification"
                placeholder="Enter reason for membership request"
                maxlength="500">
            </textarea>

            <mat-hint align="end"> {{ requestGroup.controls.justification.value.length }}/500 </mat-hint>

            <mat-error> This field is required. </mat-error>
        </mat-form-field>
    </form>
</div>

<div class="dialog-actions">
    <button mat-flat-button color="primary" [disabled]="requestGroup.invalid" (click)="makeRequest()">Submit Request</button>
</div>
