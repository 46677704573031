import {ChangeDetectionStrategy, Component, computed, inject, Signal, ViewChild} from '@angular/core';
import {MapChartsComponent} from '../map-charts/map-charts.component';
import {debounceTime, filter, startWith} from 'rxjs/operators';
import {takeUntilDestroyed, toSignal} from '@angular/core/rxjs-interop';
import {DataService} from '../../../../services/data.service';
import {NomChartComponent} from '../../../../../shared/components/nom-chart/nom-chart.component';
import {ResizeService} from '../../../../../shared/services/resize.service';
import {IResizerData} from '../../interfaces/resizer-data.interface';
import {ColumnDef} from '../../../../../shared/classes/column-def';
import {NomChartConfig} from '../../../../../shared/interfaces/nom-chart-config.interface';
import * as moment from 'moment';
import {ApplicationConfig} from '../../../../classes/application-config';
import {GenAggregationLevel} from '../../../../../../generated/serverModels/GenAggregationLevel';

@Component({
    selector: 'eaglei-mapper-chart',
    templateUrl: './mapper-chart.component.html',
    styleUrls: ['./mapper-chart.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapperChartComponent {
    @ViewChild(NomChartComponent, {static: false}) nomChartComponent: NomChartComponent;

    private resizeService = inject(ResizeService);

    private info: Signal<IResizerData> = toSignal(
        MapChartsComponent.data.pipe(filter((d) => this.resizeService.resizeElementOpen.getValue() && !!d?.chartConfig)),
        {
            initialValue: {
                columnDefs: [],
                tableData: [],
                chartConfig: {
                    startDate: ApplicationConfig.roundMinute().subtract(1, 'day'),
                    endDate: ApplicationConfig.roundMinute(),
                    states: [],
                    aggregationLevel: GenAggregationLevel.state,
                },
            },
        }
    );

    public isOpen = toSignal(this.resizeService.resizeElementOpen, {initialValue: false});

    public chartConfig = computed(() => this.info().chartConfig);
    public attributionUrl = computed(() => this.info().attributionUrl);
    public attributionText = computed(() => this.info().attributionText);

    public chartTitle = computed(() => {
        const numberSelected = this.chartConfig().states.length;
        const allStates = numberSelected === DataService.states.getValue().length;
        return `Outages for ${allStates ? 'All' : 'Selected'} States`;
    });

    constructor() {
        this.resizeService.resizeEvent
            .pipe(
                filter(() => this.nomChartComponent?.baseChart?.hasData()),
                debounceTime(250),
                takeUntilDestroyed()
            )
            .subscribe(() => this.nomChartComponent.baseChart.eChart.resize());
    }

    public exportChart(): void {
        this.nomChartComponent.exportChartAsPng(this.chartTitle(), 'mapDataChart', this.attributionUrl());
    }

    public exportTrendChart(): void {
        this.nomChartComponent.exportChartAsCsv('mapDataTrend', this.attributionUrl());
    }
}
