import {LeafletLegendService} from '../../layer/services/leaflet-legend.service';

export const removeActiveLegend = (name: string) => {
    const allLegends = LeafletLegendService.legends.getValue();
    const index = allLegends.findIndex((l) => l.name === name);

    if (index !== -1) {
        allLegends.splice(index, 1);
        LeafletLegendService.legends.next(allLegends);
    }
};
