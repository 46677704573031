import * as moment from 'moment';

export class OilPipelineAlert {
    public alertDate: moment.Moment;
    public alertDescription: string;
    public collectionDate: moment.Moment;
    public direction: string;
    public id: number;
    public pipelineCapacity: number;
    public pipelineId: number;
    public pipelineName: string;
    public regionName: string;

    constructor(input?: any) {
        this.alertDate = moment(input?.alert_date);
        this.alertDescription = input?.alert_description;
        this.collectionDate = moment(input?.collection_date);
        this.direction = input?.direction;
        this.id = input?.id;
        this.pipelineCapacity = input?.pipeline_capacity;
        this.pipelineId = input?.pipeline_id;
        this.pipelineName = input?.pipeline_name;
        this.regionName = input?.region_name;
    }
}
