import {Component, ElementRef, ViewChild} from '@angular/core';
import {BasePopover} from '../base-popover';

@Component({
    selector: 'eaglei-port-status-popover',
    templateUrl: './port-status-popover.component.html',
    styleUrls: ['./port-status-popover.component.scss', '../layer-popover.scss'],
})
export class PortStatusPopoverComponent extends BasePopover {
    @ViewChild('content', {static: true}) protected content: ElementRef<HTMLElement>;

    public status: string;

    constructor() {
        super();
    }

    onInit() {}

    updateData() {}

    afterInit() {}
}
