
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!


export class GenUtilityCoverageData {

    coveredCustomersBestCase: number;
    eagleiId: number;
    eiaTotalCustomers: number;
    etlScript: boolean;
    femaRegion: number;
    state: string;
    utilityName: string;
    utilityNumber: number;

    constructor();
    constructor( jsonObj: any );
    constructor( genUtilityCoverageData: GenUtilityCoverageData);

    constructor( input?: GenUtilityCoverageData ) {
        if ( input && typeof input !== 'number' ) {
            this.coveredCustomersBestCase = input.coveredCustomersBestCase; // int [number]
            this.eagleiId = input.eagleiId; // int [number]
            this.eiaTotalCustomers = input.eiaTotalCustomers; // int [number]
            this.etlScript = input.etlScript; // boolean [boolean]
            this.femaRegion = input.femaRegion; // int [number]
            this.state = input.state; // string [string]
            this.utilityName = input.utilityName; // string [string]
            this.utilityNumber = input.utilityNumber; // int [number]
       }
    }

    static jsonResponseToList(arr: any[]): GenUtilityCoverageData[] {
        if (arr && arr.map) {
          return arr.map(a => new GenUtilityCoverageData(a));
        } else {
          return [];
        }
    }
}

