
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import * as moment from 'moment';

export class GenMax24HourCounty {

    actualCustomers: number;
    boundary: string;
    collectedCount: number;
    countyFIPSCode: string;
    countyId: number;
    countyName: string;
    coveredCustomers: number;
    coveredCustomersType: string;
    csvPostFields: string;
    csvPostHeaderFields: string;
    csvPreFields: string;
    csvPreHeaderFields: string;
    currentOutage: number;
    currentOutageHasOverrideData: boolean;
    currentOutageRunStartTime: moment.Moment;
    currentPercentOut: number;
    maxOutage1: number;
    maxOutage1HasOverrideData: boolean;
    maxOutage1RunStartTime: moment.Moment;
    maxOutage24: number;
    maxOutage24HasOverrideData: boolean;
    maxOutage24RunStartTime: moment.Moment;
    modelCount: number;
    nonCoveredCustomers: number;
    stateFIPSCode: string;
    stateFullName: string;
    stateId: number;
    stateName: string;
    totalCustomers: number;

    constructor();
    constructor( jsonObj: any );
    constructor( genMax24HourCounty: GenMax24HourCounty);

    constructor( input?: GenMax24HourCounty ) {
        if ( input && typeof input !== 'number' ) {
            this.actualCustomers = input.actualCustomers; // int [number]
            this.boundary = input.boundary; // string [string]
            this.collectedCount = input.collectedCount; // int [number]
            this.countyFIPSCode = input.countyFIPSCode; // string [string]
            this.countyId = input.countyId; // int [number]
            this.countyName = input.countyName; // string [string]
            this.coveredCustomers = input.coveredCustomers; // int [number]
            this.coveredCustomersType = input.coveredCustomersType; // string [string]
            this.csvPostFields = input.csvPostFields; // string [string]
            this.csvPostHeaderFields = input.csvPostHeaderFields; // string [string]
            this.csvPreFields = input.csvPreFields; // string [string]
            this.csvPreHeaderFields = input.csvPreHeaderFields; // string [string]
            this.currentOutage = input.currentOutage; // int [number]
            this.currentOutageHasOverrideData = input.currentOutageHasOverrideData; // boolean [boolean]
            if ( input.currentOutageRunStartTime && typeof input.currentOutageRunStartTime === 'string' ) {
                this.currentOutageRunStartTime = moment(input.currentOutageRunStartTime).utc();
            } else {
                this.currentOutageRunStartTime = input.currentOutageRunStartTime;
            }
            this.currentPercentOut = input.currentPercentOut; // float [number]
            this.maxOutage1 = input.maxOutage1; // int [number]
            this.maxOutage1HasOverrideData = input.maxOutage1HasOverrideData; // boolean [boolean]
            if ( input.maxOutage1RunStartTime && typeof input.maxOutage1RunStartTime === 'string' ) {
                this.maxOutage1RunStartTime = moment(input.maxOutage1RunStartTime).utc();
            } else {
                this.maxOutage1RunStartTime = input.maxOutage1RunStartTime;
            }
            this.maxOutage24 = input.maxOutage24; // int [number]
            this.maxOutage24HasOverrideData = input.maxOutage24HasOverrideData; // boolean [boolean]
            if ( input.maxOutage24RunStartTime && typeof input.maxOutage24RunStartTime === 'string' ) {
                this.maxOutage24RunStartTime = moment(input.maxOutage24RunStartTime).utc();
            } else {
                this.maxOutage24RunStartTime = input.maxOutage24RunStartTime;
            }
            this.modelCount = input.modelCount; // int [number]
            this.nonCoveredCustomers = input.nonCoveredCustomers; // int [number]
            this.stateFIPSCode = input.stateFIPSCode; // string [string]
            this.stateFullName = input.stateFullName; // string [string]
            this.stateId = input.stateId; // int [number]
            this.stateName = input.stateName; // string [string]
            this.totalCustomers = input.totalCustomers; // int [number]
       }
    }

    static jsonResponseToList(arr: any[]): GenMax24HourCounty[] {
        if (arr && arr.map) {
          return arr.map(a => new GenMax24HourCounty(a));
        } else {
          return [];
        }
    }
}

