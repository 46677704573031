import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import * as L from 'leaflet';
import * as esri from 'esri-leaflet';
import {MapService} from '../../services/map.service';
import {MapOptions} from '../../classes/map-options';
import {Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {PopoverConfig} from '../../../../classes/popover-config';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {DateRangeModalComponent} from '../date-range-modal/date-range-modal.component';
import {GenServiceType} from '../../../../../../generated/serverModels/GenServiceType';
import {HttpClient} from '@angular/common/http';
import {IdentifyService} from '../../services/identify.service';
import {LeafletMapLayer} from '../../../layer/classes/leaflet-map-layer';
import {LocationSearchService} from '../../services/location-search.service';
import {CoordinateDataModalComponent} from '../../modals/coordinate-data-modal/coordinate-data-modal.component';
import {ModalConfig} from '../../../../classes/modal-config';
import {AutoUpdate} from '../../../../../shared/classes/auto-update';
import {ApplicationConfig} from '../../../../classes/application-config';
import {IPoint} from '../../../layer/interfaces/point.interface';
import {DataInfoComponent} from '../../../../../shared/modals/data-info/data-info.component';
import {CoveredUtilityModalComponent} from '../../modals/covered-utility-modal/covered-utility-modal.component';
import {AuthenticationService} from '../../../../services/authentication.service';
import {User} from '../../../user/classes/user';
import {BehaviorSubject, Subscription} from 'rxjs';
import html2canvas from 'html2canvas';
import * as JSZip from 'jszip';
import {FileDownload} from '../../../../classes/file-download';
import * as moment from 'moment';
import {MatSnackBar} from '@angular/material/snack-bar';
import {BooleanInput, coerceBooleanProperty} from '@angular/cdk/coercion';
// Zoom In = higher number
// Zoom Out = lower number

@Component({
    selector: 'eaglei-leaflet-map',
    templateUrl: './leaflet-map.component.html',
    styleUrls: ['./leaflet-map.component.scss'],
})
export class LeafletMapComponent implements OnInit, AfterViewInit, OnDestroy {
    static ngAcceptInputType_mapOnly: BooleanInput;
    @ViewChild('leafletMap', {static: true}) mapElement: ElementRef<HTMLElement>;

    @Input() mapOptions: MapOptions = new MapOptions();
    @Input()
    get mapOnly() {
        return this._mapOnly;
    }
    set mapOnly(mapOnly: boolean) {
        this._mapOnly = coerceBooleanProperty(mapOnly);
    }
    private _mapOnly = false;
    @Input() singleBaseLayer: boolean = false;
    @Output() mapClick: EventEmitter<any> = new EventEmitter<any>();
    @Output() mapRef: EventEmitter<L.Map> = new EventEmitter<L.Map>();

    // HTML Elements
    private scaleElement: HTMLDivElement;
    private layerPane: HTMLDivElement;

    // Map Properties
    private mapReference: L.Map;
    private baseLayer: any;
    private baseLayerLoading = new BehaviorSubject(true);
    public exportLoading: boolean;

    // Control
    private drawControl: any;
    private currentDrawing: any;
    private scaleControl: L.Control.Scale;

    public location: IPoint;
    public showMouseCoordinates: boolean = true;

    private autoUpdate = new AutoUpdate(this.updateDateRange.bind(this)).setAutoUpdate(true);
    public canPan: boolean;
    public sidebarOpen: boolean;

    public readonly user: User;

    public subscriptions = new Subscription();

    constructor(
        private router: Router,
        public mapService: MapService,
        private dialog: MatDialog,
        private http: HttpClient,
        public identifyService: IdentifyService,
        public locationSearchService: LocationSearchService,
        private authenticationService: AuthenticationService,
        private popup: MatSnackBar
    ) {
        this.initializeBaselayerInteraction();

        const zoomSub = this.mapService.zoomToHome.subscribe(() => this.zoomToHome());

        const drawSub = this.locationSearchService.drawShape.pipe(filter(() => this.mapReference !== undefined)).subscribe((type) => {
            if (type === undefined) {
                if (this.currentDrawing) {
                    this.currentDrawing.disable();
                }
                this.locationSearchService.drawing = false;
                return;
            }

            if (this.currentDrawing) {
                this.currentDrawing.disable();
            }

            if (type === 'circle') {
                this.currentDrawing = new (L as any).Draw.Circle(this.mapReference, this.drawControl.options.circle);
            } else if (type === 'polygon') {
                this.currentDrawing = new (L as any).Draw.Polygon(this.mapReference, this.drawControl.options.polygon);
            }
            this.currentDrawing.enable();
            this.locationSearchService.searchLayer.bringToFront();
        });

        const drawPointSub = this.locationSearchService.drawPoint.pipe(filter(() => this.mapReference !== undefined)).subscribe((point) => {
            const markerOptions: L.MarkerOptions = {
                icon: L.icon({iconUrl: '/dist/images/icons/address.svg', iconSize: [40, 50]}),
                zIndexOffset: 1000,
            };

            if (point.marker) {
                this.mapReference.removeLayer(point.marker);
            }

            if (point.latitude !== undefined && point.longitude !== undefined) {
                point.marker = L.marker([point.latitude, point.longitude], markerOptions).addTo(this.mapReference);
            }
        });

        const updateSub = this.locationSearchService.updateMapInteractions.pipe(filter(() => !!this.layerPane)).subscribe(() => {
            if (this.locationSearchService.drawing || this.locationSearchService.pointSelection) {
                this.layerPane.classList.add('location-search-active');
            } else {
                this.layerPane.classList.remove('location-search-active');
            }
        });

        this.subscriptions.add(drawSub);
        this.subscriptions.add(updateSub);
        this.subscriptions.add(zoomSub);
        this.subscriptions.add(drawPointSub);

        this.user = this.authenticationService.authenticatedUser.getValue();
    }

    ngOnInit() {
        this.sidebarOpen = this.mapOptions.show.sidebar;
    }

    ngAfterViewInit() {
        this.initializeMap();
    }

    ngOnDestroy() {
        PopoverConfig.hideNewPopover();
        this.autoUpdate.clear();
        this.identifyService.identifyGroup.clearLayers();
        this.locationSearchService.searchLayer.clearLayers();
        this.locationSearchService.routeLayer.clearLayers();
        this.mapService.activeIdentifyLayers = [];
        this.subscriptions.unsubscribe();
    }

    /**
     * Sets up Subscription that will update the baselayer on the map when a new layer is selected in the UI
     */
    private initializeBaselayerInteraction(): void {
        const handleBaseLayerChange = (name: string) => {
            name.split(',').forEach((l) => {
                this.baseLayer = esri.basemapLayer(l as any, {
                    attribution: undefined,
                });

                this.baseLayer.on('loading', () => {
                    this.baseLayerLoading.next(true);
                });

                this.baseLayer.on('load', () => {
                    this.baseLayerLoading.next(false);
                });

                this.baseLayer.options.baseLayerName = l;

                this.baseLayer.addTo(this.mapReference);
                this.baseLayer.bringToBack();
            });
        };

        const baseSub = this.mapService.baseLayer.pipe(filter(() => !!this.mapReference)).subscribe((name) => {
            if (this.baseLayer && !this.singleBaseLayer) {
                this.mapReference.eachLayer((layer: any) => {
                    if (layer.options.baseLayerName) {
                        layer.removeFrom(this.mapReference);
                    }
                });
            }

            if (!this.baseLayer && this.singleBaseLayer) {
                handleBaseLayerChange(name);
            } else if (!this.singleBaseLayer) {
                handleBaseLayerChange(name);
            }
        });

        this.subscriptions.add(baseSub);
    }

    private updateDateRange(): void {
        const dates = this.mapService.mapDateRange.getValue();
        const last = ApplicationConfig.roundMinute().subtract(15, 'minutes');

        if (dates.endDate.isSame(last, 'minute')) {
            dates.endDate = ApplicationConfig.roundMinute();
        }

        this.mapService.mapDateRange.next(dates);

        if (dates.endDate.isSameOrAfter(this.mapService.lastRefreshTime)) {
            this.mapService.lastRefreshTime = dates.endDate;
        }
    }

    private updatePaneZIndex(): void {
        this.layerPane = this.mapReference.getPane(MapService.layerPaneName) as HTMLDivElement;
        this.layerPane.style.zIndex = '650';
        this.mapReference.getPane('nomPane').style.zIndex = '649';
        this.mapReference.getPane('tools').style.zIndex = '800';
        this.mapReference.getPane('popupPane').style.zIndex = '900';
        this.mapReference.getPane('tooltipPane').style.zIndex = '1000';
    }

    /**
     * Creates the initial leaflet map and sets up controls and interactions.
     */
    private initializeMap(): void {
        this.mapReference = new L.Map(this.mapElement.nativeElement, {
            center: this.mapOptions.defaultCenter,
            zoom: this.mapOptions.defaultZoom,
            crs: L.CRS.EPSG3857,
            zoomControl: false,
            attributionControl: false,
            minZoom: this.mapOptions.minZoom,
        });

        MapService.mapRef = this.mapReference;
        this.mapReference.createPane(MapService.layerPaneName);
        this.mapReference.createPane('nomPane');
        this.mapReference.createPane('tools');

        this.updatePaneZIndex();

        // Putting this here so the correct prefixes get loaded with the base layer
        L.control.attribution({prefix: ''}).addTo(this.mapReference);

        this.mapService.baseLayer.next('Topographic');

        this.identifyService.identifyGroup.addTo(this.mapReference);
        this.identifyService.identifyGroup.setZIndex(800);
        this.locationSearchService.searchLayer.addTo(this.mapReference);
        this.locationSearchService.routeLayer.addTo(this.mapReference);

        if (this.mapOptions.onlyManualZoom) {
            this.mapReference.scrollWheelZoom.disable();
            this.mapReference.doubleClickZoom.disable();
        }

        if (this.mapOptions.show.panControl) {
            this.mapReference.dragging.disable();
        }

        this.initializeMapInteractions();
        this.initializeIdentifyHandler();
        this.initializeLocationSearchHandler();
        this.initializeDrawControl();

        this.mapReference.attributionControl.setPrefix(
            '<a target="_blank" href="https://leafletjs.com">Leaflet</a> | ' +
                'Powered by <a target="_blank" href="https://www.esri.com/en-us/home">Esri</a>'
        );

        this.mapRef.emit(this.mapReference);
    }

    private initializeMapInteractions(): void {
        this.mapReference.on('click movestart', () => {
            PopoverConfig.hideNewPopover();
        });

        // This is to make sure the identify layer iters always on top.
        this.mapReference.on('layeradd', () => {
            this.identifyService.identifyGroup.bringToFront();
        });

        this.mapReference.on('mousemove', (event: L.LeafletMouseEvent) => {
            const adjusted = this.mapReference.wrapLatLng(event.latlng);

            this.location = {
                latitude: adjusted.lat,
                longitude: adjusted.lng,
            };
        });

        this.mapReference.on('click', (event: L.LeafletMouseEvent) => {
            PopoverConfig.hideNewPopover();

            if (!this.mapOnly) {
                return;
            }

            this.mapClick.emit(event.latlng);
        });

        this.scaleControl = L.control.scale({metric: false});
        this.scaleControl.addTo(this.mapReference);

        setTimeout(() => {
            this.scaleElement = this.scaleControl.getContainer() as HTMLDivElement;

            this.scaleElement.classList.add('moveable');

            if (this.sidebarOpen) {
                this.scaleElement.classList.add('slide-out');
            }
        }, 0);
    }

    private initializeIdentifyHandler(): void {
        const buildGetInfoCall = (layer: any, latlng: L.LatLng, params?: any) => {
            const lat = latlng.lat;
            const lng = latlng.lng;
            let boundBuffer = 0.1;

            const zoom = this.mapReference.getZoom();

            // Leave this in until the identify zoom level gets refined then switch to an if else
            switch (zoom) {
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                    boundBuffer = 0.5;
                    break;
                case 6:
                case 7:
                case 8:
                case 9:
                    boundBuffer = 0.1;
                    break;
                case 10:
                case 11:
                case 12:
                case 13:
                case 14:
                case 15:
                case 16:
                case 17:
                case 18:
                case 19:
                    boundBuffer = 0.01;
            }

            const minX: number = lng - boundBuffer;
            const minY: number = lat - boundBuffer;
            const maxX: number = lng + boundBuffer;
            const maxY: number = lat + boundBuffer;

            const retMapSize = 101;
            const searchPoint = Math.floor(retMapSize / 2);

            const defaultParams = {
                request: 'GetFeatureInfo',
                service: 'WMS',
                srs: 'EPSG:4326',
                styles: '',
                version: layer._wmsVersion,
                format: layer.options.format,
                bbox: minX + ',' + minY + ',' + maxX + ',' + maxY,
                height: retMapSize,
                width: retMapSize,
                x: searchPoint,
                y: searchPoint,
                layers: layer.options.layers,
                query_layers: layer.options.layers,
                info_format: 'application/json',
                buffer: searchPoint,
                feature_count: 1000,
            };

            if (layer.wmsParams['CQL_FILTER']) {
                defaultParams['cql_filter'] = layer.wmsParams['CQL_FILTER'];
            }

            params = L.Util.extend(defaultParams, params || {});

            return layer._url + L.Util.getParamString(params, layer._url, true);
        };

        const identifyEventHandler = (event: L.LeafletMouseEvent) => {
            if (this.mapService.activeIdentifyLayers.length === 0 || this.locationSearchService.drawing) {
                return;
            }

            this.identifyService.loadingFeatureInfo = true;
            let numCalls = 0;
            Object.values(event.sourceTarget._layers)
                .filter(
                    (layer: any) =>
                        layer.layerType === GenServiceType.WMS && this.mapService.activeIdentifyLayers.includes(layer.layerHandle)
                )
                .forEach((layer: any) => {
                    const mapLayer: LeafletMapLayer = layer.eagleiLayer;

                    numCalls += 1;
                    const url = buildGetInfoCall(layer, event.latlng);

                    this.http.get<any>(url).subscribe((res) => {
                        numCalls -= 1;
                        this.identifyService.loadingFeatureInfo = numCalls !== 0;

                        (res.features as any[]).forEach((feature) => {
                            feature['eagleiLayer'] = mapLayer;
                            this.identifyService.updateFeature(feature);
                        });
                    });
                });
        };

        // @ts-ignore
        this.mapReference.on('click', identifyEventHandler);
    }

    private initializeLocationSearchHandler(): void {
        const eventHandler = (event: L.LeafletMouseEvent) => {
            if (!this.locationSearchService.pointSelection) {
                return;
            }

            const point: IPoint = {
                latitude: event.latlng.lat,
                longitude: event.latlng.lng,
            };

            if (!this.locationSearchService.routingState) {
                this.locationSearchService.searchLayer.clearLayers();
            }

            this.locationSearchService.selectedPoint.next(point);

            if (this.locationSearchService.executeBuffer) {
                const feature = this.locationSearchService.convertBufferToPolygon(point.latitude, point.longitude);
                const addedLayer: any = this.locationSearchService.searchLayer.addData(feature);
                this.locationSearchService.getFeaturesIntersectingLayer(addedLayer);

                this.dialog.open(CoordinateDataModalComponent, {
                    width: ModalConfig.getModalWidth(),
                    data: {
                        headerText: 'Data In Buffer',
                    },
                });

                this.mapReference.fitBounds(addedLayer.getBounds());
            }
        };

        this.mapReference.on('click', eventHandler);
    }

    private initializeDrawControl(): void {
        const options = {
            position: 'bottomright',
            draw: {
                polygon: false,
                circlemarker: false,
                rectangle: false,
                marker: false,
                polyline: false,
                circle: {
                    showRadius: false,
                },
            },
            edit: {
                featureGroup: this.locationSearchService.searchLayer,
                remove: false,
                edit: false,
            },
            circle: {
                metric: false,
                feet: false,
            },
        };

        this.drawControl = new (L.Control as any).Draw(options);

        this.mapReference.addControl(this.drawControl);

        this.mapReference.on((L as any).Draw.Event.CREATED, (e: any) => {
            const type = e.layerType;
            const layer = e.layer;

            let searchFeature;
            if (type.toLowerCase() === 'circle') {
                searchFeature = this.locationSearchService.convertBufferToPolygon(
                    layer.getLatLng().lat,
                    layer.getLatLng().lng,
                    layer.getRadius(),
                    'meters'
                );
            } else if (type.toLowerCase() === 'polygon') {
                searchFeature = layer.toGeoJSON();
            }
            this.locationSearchService.searchLayer.addData(searchFeature);
        });
    }

    /**
     * Manually triggers the zoom in interaction on the map
     */
    public zoomIn(): void {
        const currentZoom = this.mapReference.getZoom();
        this.mapReference.setZoom(currentZoom + 1);
    }

    /**
     * Manually triggers the zoom in interaction on the map
     */
    public zoomOut(): void {
        const currentZoom = this.mapReference.getZoom();
        this.mapReference.setZoom(currentZoom - 1);
    }

    /**
     * Returns the map to the home zoom level and centers on CONUS
     */
    public zoomToHome(): void {
        this.mapReference.flyToBounds(ApplicationConfig.conus.getBounds(), {});
    }

    public zoomToBounds(feature: L.GeoJSON): void {
        this.mapReference.flyToBounds(feature.getBounds(), {});
    }

    /**
     * Exports the current map as a PNG
     */
    public exportMap(): void {
        this.exportLoading = true;

        const addCanvasToZip = (filename: string, canvasToAdd: HTMLCanvasElement) => {
            const imgData = canvasToAdd.toDataURL('image/png', 1.0);

            // Pulled from SO, we have to remove the base64 part for the img to be added to the zip directory correctly.
            const idx = imgData.indexOf('base64,') + 'base64,'.length; // or = 28 if you're sure about the prefix
            const content = imgData.substring(idx);

            dir.file(`${filename}`, content, {base64: true});
        };

        const addCall = (key: string, htmlElement: HTMLElement, onClone?: (document: Document, element: HTMLElement) => void) => {
            const options: any = {
                allowTaint: true,
                useCORS: true,
                logging: false,
            };

            if (onClone) {
                options.onclone = onClone;
            }

            calls.set(key, html2canvas(htmlElement, options));
        };

        // Sets the legend list to visible in the cloned element
        // ele param is need to match function definition from html2Canvas
        // noinspection JSUnusedLocalSymbols
        const legendClone: (document: Document, element: HTMLElement) => void = (doc, ele) => {
            const legendList: HTMLElement = doc.body.querySelector('.legend-list-wrapper');
            if (legendList != null) {
                // manually setting show properties because the 'show' class is not accessible from the cloned node
                legendList.style.display = 'block';
                legendList.style.opacity = '1';
                legendList.style.pointerEvents = 'all';
            } else {
                console.error('legendClone: The legendList element was null');
            }
        };

        // Checks for any svgs and fixes the offset in the rendered element
        const mapClone: (document: Document, element: HTMLElement) => void = (doc, ele) => {
            // console.log('in map clone');
            ele.querySelectorAll('svg').forEach((svg) => {
                const viewBox = svg.getAttribute('viewBox').split(' ');
                const newViewBox = `0 0 ${viewBox[2]} ${viewBox[3]}`;

                svg.style.transform = 'translate3d(0, 0, 0)';
                svg.setAttribute('viewBox', newViewBox);

                svg.setAttribute('width', window.innerWidth + 'px');
                svg.setAttribute('height', window.innerHeight + 'px');
            });
        };

        const exportMapAndLegends = () => {
            // console.log(' in export');
            const legends = document.body.querySelectorAll('eaglei-legend-list eaglei-legend');
            legends.forEach((node) => addCall(node.previousElementSibling.innerHTML, node as HTMLElement, legendClone));

            addCall('map', this.mapElement.nativeElement, mapClone);

            // console.log('calls', calls);

            Promise.all(Array.from(calls.values()))
                .then((res) => {
                    const mapExport: HTMLCanvasElement = res.pop();
                    const keys = Array.from(calls.keys());

                    res.forEach((responseCanvas, index) => {
                        addCanvasToZip(`${keys[index].toLowerCase().replace(/ /g, '_')}_legend.png`, responseCanvas);
                    });

                    addCanvasToZip('EAGLEI_map.png', mapExport);

                    zip.generateAsync({type: 'blob'}).then((blob) => {
                        if (navigator.msSaveBlob) {
                            navigator.msSaveBlob(blob, `${zipFileName}.zip`);
                        } else {
                            const url = window.URL.createObjectURL(blob);
                            FileDownload.downloadFile(`${zipFileName}.zip`, url);
                            this.exportLoading = false;
                        }
                    });
                })
                .catch((error) => {
                    this.popup.open('Failed to export map', '', {duration: 5_000, panelClass: 'dialog-failure'});
                    this.exportLoading = false;
                    console.error('Map Export:', error);
                })
                .finally(() => {
                    exportSub.unsubscribe();
                });
        };

        const calls: Map<string, Promise<any>> = new Map<string, Promise<any>>();

        const zip = new JSZip();

        // Sets the last modified date to be zipDate converted to local time
        const zipDate = moment();
        const currDate = zipDate.local().toDate();
        JSZip.defaults.date = new Date(currDate.getTime() - currDate.getTimezoneOffset() * 60_000);

        const zipFileName = `mapExport_${zipDate.format('YYYYMMDDhhmmss')}`;
        const dir = zip.folder(zipFileName);

        const exportSub = this.baseLayerLoading.pipe(filter((val) => !val)).subscribe(() => {
            // console.log('base layer has loaded');
            exportMapAndLegends();
        });
    }

    public openDateRangeModal(): void {
        const config: MatDialogConfig = {
            disableClose: true,
            autoFocus: false,
        };

        if (ApplicationConfig.onMobile()) {
            config.width = '100%';
            config.height = '100%';
            config.maxWidth = '100vw';
        }

        this.dialog.open(DateRangeModalComponent, config);
    }

    public useMobileLayout(): boolean {
        return ApplicationConfig.useMobileLayout();
    }

    public getSourceData() {
        this.mapService.getSourceData().subscribe((res) => {
            const ref = this.dialog.open(DataInfoComponent, {
                data: {sources: res, hasType: true, name: 'Map'},
                width: ModalConfig.getModalWidth(),
            });

            ref.afterOpened().subscribe(() => (this.showMouseCoordinates = false));
            ref.afterClosed().subscribe(() => (this.showMouseCoordinates = true));
        });
    }

    public createMapMarker(lat: number, lng: number, perviousMarker?: L.Marker): L.Marker {
        if (!this.mapOnly) {
            return undefined;
        }
        const markerOptions: L.MarkerOptions = {
            icon: L.icon({iconUrl: '/dist/images/icons/address.svg', iconSize: [40, 50]}),
            zIndexOffset: 1000,
        };

        if (perviousMarker) {
            this.mapReference.removeLayer(perviousMarker);
        }

        return L.marker([lat, lng], markerOptions).addTo(this.mapReference);
    }

    getCoveredUtilities() {
        const opts = {
            data: this.mapService.nomActiveStates.getValue(),
            autoFocus: false,
            width: ModalConfig.getModalWidth(),
        };

        this.dialog.open(CoveredUtilityModalComponent, opts);
    }

    public togglePan() {
        this.canPan = !this.canPan;
        if (this.canPan) {
            this.mapReference.dragging.enable();
        } else {
            this.mapReference.dragging.disable();
        }
    }

    /**
     * Called from the isOpen event in the sidebar to update the map chips
     * @param open The open state of the sidebar.
     */
    public sidebarChanged(open: boolean) {
        this.sidebarOpen = open;
        if (open) {
            this.scaleElement.classList.add('slide-out');
        } else {
            this.scaleElement.classList.remove('slide-out');
        }
    }
}
