import {GenDataProviderGroup} from '../../../../../generated/serverModels/GenDataProviderGroup';
import {Report} from './report';

export class ReportGroup extends GenDataProviderGroup {
    public dataProviders: Report[];

    constructor(group?: ReportGroup) {
        super(group);

        if (group.dataProviders) {
            this.dataProviders = group.dataProviders.map((dp) => {
                const res = new Report(dp);
                res.iconUrl = this.iconUrl;
                return res;
            });
        }
    }
}
