import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../../services/authentication.service';
import {ApplicationConfig} from 'frontend/src/app/classes/application-config';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'eaglei-login-form',
    templateUrl: './login-form.component.html',
    styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent {
    public formGroup: UntypedFormGroup;

    public errorCode: number;
    public showPassword: boolean;

    constructor(private router: Router, private auth: AuthenticationService) {
        const controls = {
            username: new UntypedFormControl('', [Validators.required]),
            password: new UntypedFormControl('', [Validators.required]),
        };
        this.formGroup = new UntypedFormGroup(controls);
    }

    /**
     * Tries to login the user with their username and password.
     * If the username and password are valid the user is redirected to the lite map on mobile and landing page otherwise. If the username
     * and password are not valid, an error message is shown.
     */
    public login(): void {
        const failure = (error?: any) => {
            this.errorCode = error.status;
        };

        const success = (valid: any) => {
            if (valid.status === 200) {
                if (ApplicationConfig.onMobile()) {
                    // noinspection JSIgnoredPromiseFromCall
                    this.router.navigateByUrl(
                        '/app/lite/map',
                        /* Removed unsupported properties by Angular migration: preserveQueryParams. */ {}
                    );
                } else {
                    // noinspection JSIgnoredPromiseFromCall

                    this.router.navigateByUrl(
                        '/app/landing',
                        /* Removed unsupported properties by Angular migration: preserveQueryParams. */ {}
                    );
                }
            } else {
                failure(valid);
            }
        };

        const username = this.formGroup.controls['username'].value;
        const password = this.formGroup.controls['password'].value;

        this.auth.login(username || ' ', password).subscribe(success, failure);
    }

    goToReactivationPage() {
        // noinspection JSIgnoredPromiseFromCall
        this.router.navigate(['login/account-reactivation'], {state: {username: this.formGroup.controls.username.value}});
    }
}
