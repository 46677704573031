import {filter} from 'rxjs/operators';
import {Component, ElementRef, OnDestroy, Renderer2, ViewChild} from '@angular/core';
import {ResizeService} from 'frontend/src/shared/services/resize.service';
import {ResizeComponent} from 'frontend/src/shared/components/resize/resize.component';
import {LeafletLegendService} from '../../../layer/services/leaflet-legend.service';

@Component({
    selector: 'eaglei-map-layout',
    templateUrl: './map-layout.component.html',
    styleUrls: ['./map-layout.component.scss'],
})
export class MapLayoutComponent implements OnDestroy {
    @ViewChild(ResizeComponent) resizeElement: ResizeComponent;
    private initialResizePercentage = 25;

    public showCharts = false;

    constructor(private ele: ElementRef<HTMLElement>, private resizeService: ResizeService, private renderer: Renderer2) {
        this.renderer.addClass(this.ele.nativeElement, 'no-padding');

        this.resizeService.openResizeElement.pipe(filter(() => !!this.resizeElement && !this.resizeElement.isActive)).subscribe(() => {
            this.showCharts = true;
            let tmpHeight: number = this.ele.nativeElement.getBoundingClientRect().height;
            tmpHeight = Math.floor(tmpHeight * (this.initialResizePercentage / 100));
            this.resizeElement.setInitialSize(tmpHeight);
        });

        this.resizeService.closeResizeElement.subscribe(() => {
            this.resizeElement.closeResize();
            this.showCharts = false;
        });
    }

    ngOnDestroy() {
        LeafletLegendService.legends.next([]);
    }
}
