<div #content class="widget-content high-level-metric">
    <div class="metrics">
        <p class="metric-value">{{ capacityLost | number }}</p>
        <span class="metric-text">{{ getTitle() | uppercase }} <span class="metric-unit">(bpd)</span></span>
        <span class="metric-subtext" *ngIf="maxLossRefinery">({{ maxLossRefinery.name }})</span>
    </div>
    <div class="link-section" *ngIf="!onMobile()">
        <a target="_blank" (click)="goToReport()">
            <mat-icon class="link-icon" fontSet="fas" fontIcon="fa-file-invoice" inline></mat-icon>
            <p>VIEW REPORT</p>
        </a>
    </div>
</div>
