import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import * as moment from 'moment';
import {UserPreference} from './user-preferences';
import {ElementRef} from '@angular/core';
import {LoadingMaskOptions} from './loading-mask-options';
import {LayoutType} from '../enums/layout-type.enum';
import {GenOutageAggregationLevel} from '../../../generated/serverModels/GenOutageAggregationLevel';
import * as L from 'leaflet';
import {Router} from '@angular/router';
import {LockTimeService} from '../services/lock-time.service';

export interface IGeometry {
    name: string;
    boundary: string;
    fipsCode: string;
    stateId?: number;
    countyId?: number;
    zipId?: number;
}

export class ApplicationConfig {
    static readonly apiKey: string = 'EI_API_KEY';
    static readonly proxyPrefix: string = '/api/mapservices/proxy?url=';
    static readonly pollDuration = 1000 * 60 * 15;
    static readonly outagePollDuration = 1000 * 60 * 15;
    static readonly legendPrintAttribute = 'data-printableLegend';
    static resizeEvent: Subject<Event> = new Subject<Event>();

    static resetScrollClass = 'scrollToTopOfPage';

    static geometries: Map<GenOutageAggregationLevel, IGeometry[]> = new Map<GenOutageAggregationLevel, IGeometry[]>();
    static conus: L.GeoJSON;

    static readonly mapPrintAttribute = 'data-printableMap';
    static readonly eagleiAttributionText = 'EAGLE-I';
    static readonly chartLineColor = '#074569';
    static overrideColor = '#6a51a3';
    static showOverrideColor = new BehaviorSubject(true);
    static currentUserPreferences = new BehaviorSubject<UserPreference>(undefined);
    static pageMask = new BehaviorSubject<LoadingMaskOptions>(new LoadingMaskOptions());

    static maxFileSizeInBytes: number = 50_000_000; // 50mb
    static emailBreakpointSizeInBytes: number = 5_000_000; // 5mb

    // Mobile Properties
    static readonly mobileBreakpoint = '(min-width: 960px)';
    static readonly phoneBreakpoint = '(min-width: 599px)';
    // static useMobileLayout: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    static layoutType: BehaviorSubject<LayoutType> = new BehaviorSubject<LayoutType>(LayoutType.DESKTOP);

    static overlayElement: HTMLElement = document.createElement('div');

    static activeLayerCalls: Map<string, Subscription> = new Map<string, Subscription>();

    static router: Router;

    // private readonly baseOverrideColor = '#6a51a3';
    // private readonly colorblindOverrideColor = '#e66101';

    constructor() {}

    static stringFormat(s: string | number, forCsv: boolean = false): string {
        const text = s || s === 0 ? `"${s.toString().replace(/"/g, '""')}"` : '"Not Available"';
        return forCsv ? text : text.replace(/"/g, '');
    }

    static numberInputValidation(event: KeyboardEvent, allowNegative: boolean = true, allowDecimal: boolean = true) {
        // Key codes are: Backspace, Tab, Enter, Arrow Keys Respectively
        // Using which for firefox support

        if (event === undefined) {
            return 0;
        }

        const validKeyCodes: number[] = [8, 9, 13, 37, 38, 39];

        if (allowNegative) {
            validKeyCodes.push(45);
        }
        if (allowDecimal) {
            validKeyCodes.push(46);
        }

        const isLetter: boolean = isNaN(parseInt(event.key));
        const validKey = validKeyCodes.some((k) => event.which === k);

        if (isLetter && !validKey) {
            event.preventDefault();
            event.stopPropagation();
            return -1;
        }

        return 0;
    }

    static updateOverrideColor(hashElement: ElementRef, textElement: ElementRef, overrideColor?: string): void {
        if (!overrideColor) {
            const preferences = ApplicationConfig.currentUserPreferences.getValue();
            if (preferences && preferences.legendStyle && preferences.legendStyle.id) {
                overrideColor = preferences.legendStyle.overrideColor;
            } else {
                overrideColor = ApplicationConfig.overrideColor;
            }
        }

        const backgroundImage = `linear-gradient(45deg, ${overrideColor} 25%, transparent 25%, transparent 50%, ${overrideColor} 50%, ${overrideColor} 75%, transparent 75%, #fff)`;

        (textElement.nativeElement as HTMLElement).style.color = overrideColor;
        (hashElement.nativeElement as HTMLElement).style.backgroundImage = backgroundImage;
    }

    static roundMinute(date?: moment.Moment): moment.Moment {
        if (!date) {
            const lockedTime = LockTimeService.currentApplicationTime.getValue();
            if (lockedTime) {
                date = lockedTime.clone();
                console.info(`roundMinute: Using locked time of ${date.format()}`);
            } else {
                date = moment();
            }
        }

        const minute = Math.floor(date.minute() / 15) * 15;
        date.minute(minute).second(0);
        return moment(date);
    }

    // use onMobile
    static useMobileLayout(): boolean {
        return ApplicationConfig.layoutType.getValue() !== LayoutType.DESKTOP;
    }

    static onMobile(): boolean {
        return window.orientation !== undefined;
    }

    static onPhone(): boolean {
        return ApplicationConfig.onMobile() && !this.phoneSizeCheck();
    }

    static onIosMobile(): boolean {
        return !!navigator.userAgent.match(/(iPhone|iPod|iPad)/i);
    }

    static onIE(): boolean {
        return !!navigator.userAgent.match(/(Trident|MSIE)/i);
    }

    static onLite(): boolean {
        return ApplicationConfig.router.url.includes('/lite/');
    }

    public static phoneSizeCheck(): boolean {
        if (window.innerHeight < window.innerWidth) {
            return window.innerWidth > 900 && window.innerHeight > 500;
        } else {
            return window.innerWidth > 500 && window.innerHeight > 900;
        }
    }

    static emailDocumentUpload(files: File[]): boolean {
        const totalSizeInBytes = files.reduce((prev, cur) => (prev += cur.size), 0);
        return totalSizeInBytes >= ApplicationConfig.emailBreakpointSizeInBytes;
    }

    static hasEllipsis(element: HTMLElement): boolean {
        return (window as any).elementHasEllipsis(element);
    }
}
