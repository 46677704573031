import {Component, inject, OnDestroy} from '@angular/core';
import {FormControl} from '@angular/forms';
import {GenLegendType} from 'frontend/generated/serverModels/GenLegendType';
import {GenSystemEventAnalytic} from 'frontend/generated/serverModels/GenSystemEventAnalytic';
import {GroupedHeadoutEvent} from 'frontend/src/app/integrations/headout/interfaces/grouped-headout-event';
import {HttpInterceptorService} from 'frontend/src/app/services/http-interceptor.service';
import {WaoService} from 'frontend/src/app/services/wao.service';
import * as moment from 'moment';
import {filter, takeUntil} from 'rxjs/operators';
import {SystemEventService} from '../../../system-event/services/system-event.service';
import {EagleILayer} from '../../classes/eagle-i-layer';
import {LayerService} from '../../services/layer.service';
import {WAOLightsSource} from '../../sources/wao-lights-source';

@Component({
    selector: 'eaglei-waoLights-layer',
    templateUrl: './wao-lights-layer.component.html',
    styleUrls: ['./wao-lights-layer.component.scss'],
})
export class WaoLightsLayerComponent extends EagleILayer<WAOLightsSource> implements OnDestroy {
    public layerService = inject(LayerService);
    public waoService = inject(WaoService);
    public systemEventService = inject(SystemEventService);

    // Filters
    public waoEvents: GroupedHeadoutEvent = {active: [], inactive: []};
    public dateList: moment.Moment[] = [];
    public dateControl: FormControl = new FormControl('');
    public eventControl: FormControl = new FormControl('');
    public lightControl: FormControl<number[]> = new FormControl<number[]>([-1, 1, 3]);
    public displayErrorMessage: string = undefined;

    constructor() {
        super();
    }

    afterViewInit(): void {
        this.mapLayer.legend.type = GenLegendType.HARD_CODED;

        this.dateControl.valueChanges
            .pipe(
                takeUntil(this.destroy$),
                filter((val) => !!val)
            )
            .subscribe((val) => {
                this.layerSource.selectedDate = moment(val);
                this.layerSource.removeFromMap();
                this.getWAOData();
            });

        this.eventControl.valueChanges
            .pipe(
                takeUntil(this.destroy$),
                filter((val) => !!val)
            )
            .subscribe((val) => {
                this.displayErrorMessage = undefined;
                this.dateList = [];

                const event = this.eventControl.value;
                this.waoService.getWAOFilterDates(event.eventStart, event.eventEnd).subscribe((res: moment.Moment[]) => {
                    this.dateList.push(...res.map((d) => moment(d)));
                    this.dateControl.setValue(moment(this.dateList[this.dateList.length - 1]));

                    if (!(this.dateList.length > 0)) {
                        this.displayErrorMessage = 'No Valid Run Dates for this Event';
                    }
                });
            });

        this.lightControl.valueChanges
            .pipe(
                takeUntil(this.destroy$),
                filter((val) => !!val)
            )
            .subscribe((val) => {
                this.updateFilter(moment(this.dateControl.value));
            });
    }

    ngOnDestroy() {
        this.destroy$.next(undefined);
        this.destroy$.unsubscribe();
    }

    /**
     * Toggles the Layer on or off
     * @param activeState The toggle state of the layer
     */
    public toggleLayer(activeState: boolean): void {
        this.isActive = activeState;
        this.displayErrorMessage = undefined;

        if (activeState) {
            if (!this.layerSource) {
                this.layerSource = new WAOLightsSource(this.mapLayer);

                if (this.layerSource.selectedDate !== undefined) {
                    this.dateControl.setValue(moment(this.layerSource.selectedDate));
                }

                // Setting the light status to defualt
                this.lightControl.setValue([-1, 1, 3]);
            }

            this.waoEvents.active = this.systemEventService.groupedEvents$.value.active.filter((e) =>
                e.analytics.includes(GenSystemEventAnalytic.WAO)
            );
            this.waoEvents.inactive = this.systemEventService.groupedEvents$.value.inactive.filter((e) =>
                e.analytics.includes(GenSystemEventAnalytic.WAO)
            );
            this.displayErrorMessage = undefined;

            if (this.waoEvents.active.length > 0) {
                this.eventControl.setValue(this.waoEvents.active[0]);
            } else if (this.waoEvents.inactive.length > 0) {
                this.eventControl.setValue(this.waoEvents.inactive[0]);
            } else {
                this.displayErrorMessage = 'No WAO Events';
            }
        } else {
            HttpInterceptorService.clearInterceptor(this.mapLayer.interceptorKey);
            this.layerSource.removeFromMap();
        }
    }

    /**
     * Fetches the WAO Data from the Tile server source
     */
    private getWAOData(): void {
        if (!(this.dateList.length > 0)) {
            return;
        }

        this.layerSource.fetchTiles();
    }

    /**
     * Update the filter and reload layer tiles
     * @param date Selected date to view
     */
    public updateFilter(date: moment.Moment): void {
        this.dateControl.setValue(moment(date));
        this.layerSource.selectedDate = moment(this.dateControl.value);
        this.layerSource.selectedLightStatus = this.lightControl.value;

        this.layerSource.removeFromMap();
        this.getWAOData();
    }

    /**
     * Compares two dates to see if they are the same date
     * @param date1 First Date
     * @param date2 Second Date
     * @returns if the given dates are the same
     */
    public dateCompare(date1: moment.Moment, date2: moment.Moment): boolean {
        return date1.isSame(date2);
    }
}
