import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {CommunityRoutingModule} from './community-routing.module';
import {SharedModule} from '../../../shared/shared.module';
import {DesignModuleModule} from '../../../design-module/design-module.module';
import {CommunityLandingComponent} from './components/community-landing/community-landing.component';
import {CommunityComponent} from './components/community/community.component';
import {CommunityAccessModalComponent} from './modals/community-access-modal/community-access-modal.component';
import {SingleManagerLeaveModalComponent} from './modals/single-manager-leave-modal/single-manager-leave-modal.component';
import {AddCommunityUserComponent} from './components/add-community-user/add-community-user.component';

@NgModule({
    declarations: [
        CommunityLandingComponent,
        CommunityComponent,
        CommunityAccessModalComponent,
        SingleManagerLeaveModalComponent,
        AddCommunityUserComponent,
    ],
    imports: [CommonModule, CommunityRoutingModule, SharedModule, DesignModuleModule],
    exports: [],
})
export class CommunityModule {}
