import {Pipe, PipeTransform} from '@angular/core';
import {GenOutageAggregationLevel} from '../../../generated/serverModels/GenOutageAggregationLevel';
import {GenAggregationLevel} from '../../../generated/serverModels/GenAggregationLevel';

@Pipe({
    name: 'aggregationLevel',
})
export class AggregationLevelPipe implements PipeTransform {
    transform(value: GenOutageAggregationLevel | GenAggregationLevel, args?: any): string {
        switch (value) {
            case GenOutageAggregationLevel.fema:
            case GenAggregationLevel.fema:
                return 'FEMA Region';
            case GenOutageAggregationLevel.state:
            case GenAggregationLevel.state:
                return 'State/Territory';
            case GenOutageAggregationLevel.county:
            case GenAggregationLevel.county:
                return 'County';
            case GenOutageAggregationLevel.utility:
            case GenAggregationLevel.utility:
                return 'Utility';
            case GenOutageAggregationLevel.zip:
            case GenAggregationLevel.zip:
                return 'Zip Code';
            default:
                return value.name;
        }
    }
}
