<mat-card>
    <mat-card-title>
        <div>
            <div
                class="status-icon"
                [class.online]="data.total_units === data.online_units"
                [class.impacted]="data.total_units !== data.online_units && data.total_units !== data.offline_units"></div>
            <span>Oil Refinery Status</span>
        </div>

        <button mat-icon-button (click)="close()">
            <mat-icon fontSet="fas" fontIcon="fa-times"></mat-icon>
        </button>
    </mat-card-title>

    <mat-card-content #content class="column-layout">
        <ng-container *ngIf="currentSelectionIndex !== undefined">
            <div *ngIf="currentSelectionIndex !== 0">
                <div class="unit-status">
                    <div class="status-icon" [class.online]="currentSelection.online"></div>
                    <span>Unit Status</span>
                </div>

                <div>
                    <span>Unit:</span>
                    <span>{{ currentSelection.unit_name }}</span>
                </div>

                <div>
                    <span>Capacity:</span>
                    <span>{{ currentSelection.capacity | number : '1.0-0' }} bpd</span>
                </div>

                <div *ngIf="!currentSelection.online">
                    <span>Outage Type:</span>
                    <span>{{ currentSelection.outage_type }}</span>
                </div>

                <div>
                    <span>Date:</span>
                    <span>{{ currentSelection.measurement_date | momentDate }}</span>
                </div>
            </div>

            <div *ngIf="currentSelectionIndex === 0">
                <span>{{ data.name }}</span>

                <div>
                    <span>Total Capacity:</span>
                    <span>{{ data.total_capacity | number : '1.0-0' }} bpd</span>
                </div>

                <div>
                    <span>Impacted Capacity:</span>
                    <span>{{ data.offline_capacity | number : '1.0-0' }} bpd</span>
                </div>

                <div>
                    <span>Impacted Percent:</span>
                    <span>{{ data.offline_capacity / data.total_capacity | percent : '0.0-2' }}</span>
                </div>
            </div>
        </ng-container>
    </mat-card-content>
    <mat-card-actions *ngIf="currentSelectionIndex !== undefined && data.units.length !== 2">
        <button mat-icon-button (click)="nextSelection()" [disabled]="currentSelectionIndex === 0">
            <mat-icon fontSet="fas" fontIcon="fa-angle-left"></mat-icon>
        </button>

        <span>{{ currentSelectionIndex + 1 }}</span>

        <button mat-icon-button (click)="nextSelection()" [disabled]="currentSelectionIndex === data.units.length">
            <mat-icon fontSet="fas" fontIcon="fa-angle-right"></mat-icon>
        </button>
    </mat-card-actions>
</mat-card>
