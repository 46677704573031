
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import * as moment from 'moment';

export class GenPole {

    id: number;
    boundary: string;
    callSign: string;
    confidence: number;
    damaged: boolean;
    detections: number;
    flightDate: moment.Moment;
    infrastructure: string;
    latitude: number;
    learningModel: string;
    longitude: number;
    poleId: string;
    type: string;

    constructor();
    constructor( jsonObj: any );
    constructor( genPole: GenPole);
    constructor( id: number );

    constructor( input?: GenPole|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            this.boundary = input.boundary; // string [string]
            this.callSign = input.callSign; // string [string]
            this.confidence = input.confidence; // float [number]
            this.damaged = input.damaged; // boolean [boolean]
            this.detections = input.detections; // int [number]
            if ( input.flightDate && typeof input.flightDate === 'string' ) {
                this.flightDate = moment(input.flightDate).utc();
            } else {
                this.flightDate = input.flightDate;
            }
            this.infrastructure = input.infrastructure; // string [string]
            this.latitude = input.latitude; // float [number]
            this.learningModel = input.learningModel; // string [string]
            this.longitude = input.longitude; // float [number]
            this.poleId = input.poleId; // string [string]
            this.type = input.type; // string [string]
       }
    }

    static jsonResponseToList(arr: any[]): GenPole[] {
        if (arr && arr.map) {
          return arr.map(a => new GenPole(a));
        } else {
          return [];
        }
    }
}

