<mat-drawer-container>
    <mat-drawer #edit [mode]="'over'" class="drawer edit-layer new-design" position="end">
        <ng-container [ngTemplateOutlet]="layerForm"></ng-container>
    </mat-drawer>

    <mat-drawer-content>
        <div class="new-design widthLimiter">
            <div class="filter-row">
                <mat-form-field>
                    <input matInput placeholder="Search Layers" (keyup)="searchLayers($event.target.value)" />
                    <mat-icon matSuffix class="icon input-search-icon" fontSet="fas" fontIcon="fa-search"></mat-icon>
                </mat-form-field>

                <div>
                    <button mat-stroked-button (click)="toggleExpansion()" class="expansion">
                        <mat-icon *ngIf="!areGroupsExpanded" fontSet="fas" fontIcon="fa-chevron-down" matTooltip="Expand Groups">
                        </mat-icon>
                        <mat-icon *ngIf="areGroupsExpanded" fontSet="fas" fontIcon="fa-chevron-up" matTooltip="Collapse Groups"> </mat-icon>
                    </button>

                    <button mat-stroked-button (click)="saveOrdering()">Save Ordering</button>
                </div>
            </div>

            <ng-container>
                <ng-container [ngTemplateOutlet]="layerList" [ngTemplateOutletContext]="{layers: filteredLayers}"></ng-container>
            </ng-container>
        </div>

        <ng-template #layerList let-layers="layers">
            <div class="layer-container">
                <ng-container *ngFor="let group of layerGroups">
                    <mat-accordion #groupList multi class="group" *ngIf="group.layers.length > 0">
                        <mat-expansion-panel expanded>
                            <mat-expansion-panel-header>
                                <mat-panel-title class="group-title">
                                    <div class="order-buttons">
                                        <mat-icon fontSet="fas" fontIcon="fa-chevron-up" (click)="moveGroup(group, 'up', $event)">
                                        </mat-icon>
                                        <mat-icon fontSet="fas" fontIcon="fa-chevron-down" (click)="moveGroup(group, 'down', $event)">
                                        </mat-icon>
                                    </div>
                                    <span>{{ group.displayName }}</span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>

                            <div *ngFor="let layer of filteredGroup(group); let i = index">
                                <div class="layer clickable" [class.show-divider]="i > 0" (click)="editLayer(layer, group)">
                                    <div class="order-buttons">
                                        <mat-icon
                                            fontSet="fas"
                                            fontIcon="fa-chevron-up"
                                            (click)="moveLayer($event, group, layer, 'up')"></mat-icon>
                                        <mat-icon
                                            fontSet="fas"
                                            fontIcon="fa-chevron-down"
                                            (click)="moveLayer($event, group, layer, 'down')"></mat-icon>
                                    </div>

                                    <div class="layer-info">
                                        <p class="layer-name ellipsis">{{ layer.displayName }}</p>
                                        <p class="sub-title ellipsis">{{ layer.description }}</p>
                                    </div>

                                    <ng-container>
                                        <mat-icon
                                            *ngIf="isEnabled(layer)"
                                            fontSet="far"
                                            fontIcon="fa-eye"
                                            inline
                                            matTooltip="Enabled"
                                            matTooltipPosition="above"
                                            matTooltipShowDelay="250"></mat-icon>
                                        <mat-icon
                                            *ngIf="!isEnabled(layer)"
                                            fontSet="far"
                                            fontIcon="fa-eye-slash"
                                            inline
                                            matTooltip="Disable"
                                            matTooltipPosition="above"
                                            matTooltipShowDelay="250"></mat-icon>
                                    </ng-container>
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </ng-container>
            </div>
        </ng-template>
    </mat-drawer-content>
</mat-drawer-container>

<ng-template #layerForm>
    <form class="drawer-form">
        <mat-form-field>
            <mat-label>Layer Name</mat-label>

            <input
                matInput
                name="displayName"
                placeholder="Layer Name"
                [(ngModel)]="tmpLayer.displayName"
                [formControl]="layerNameControl"
                maxlength="120" />
            <mat-hint class="hint-text" *ngIf="tmpLayer.displayName" align="end">{{ tmpLayer.displayName.length }} / 120 </mat-hint>
            <mat-error class="hint-text" *ngIf="layerNameControl.invalid">
                <span *ngIf="layerNameControl.hasError('required')">A layer name is required</span>
                <span *ngIf="layerNameControl.hasError('onlySpace')">Layer name can not only be a space</span>
                <span *ngIf="layerNameControl.hasError('nameExists')">Layer name already exists</span>
            </mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Layer Description</mat-label>
            <textarea
                matInput
                name="description"
                placeholder="Layer Description"
                [(ngModel)]="tmpLayer.description"
                maxlength="500"></textarea>
            <mat-hint class="hint-text" *ngIf="tmpLayer.description" align="end">{{ tmpLayer.description.length }} / 500 </mat-hint>
        </mat-form-field>

        <eaglei-autocomplete #autocompleteRoles [values]="roles" [placeholder]="'Layer Roles'"></eaglei-autocomplete>

        <eaglei-autocomplete #autocompleteCommunities [values]="communities" [placeholder]="'Layer Communities'"></eaglei-autocomplete>

        <mat-form-field>
            <mat-label>Layer Group</mat-label>
            <mat-select name="group" placeholder="Layer Group" [(ngModel)]="selectedGroup">
                <mat-option *ngFor="let group of layerGroups" [value]="group">{{ group.displayName }}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Attribution Title</mat-label>
            <input
                matInput
                name="attributionTitle"
                placeholder="Attribution Title"
                [(ngModel)]="tmpLayer.attributionTitle"
                maxlength="50" />
            <mat-hint class="hint-text" align="start">
                to place a &trade; symbol, input <b>{{ '{TRADEMARK}' }}</b>
            </mat-hint>

            <mat-hint class="hint-text" *ngIf="tmpLayer.attributionTitle" align="end">
                {{ tmpLayer.attributionTitle.length }}/ 50
            </mat-hint>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Attribution URL</mat-label>
            <input
                matInput
                name="attributionUrl"
                placeholder="Attribution URL"
                [(ngModel)]="tmpLayer.attributionUrl"
                [formControl]="attributionUrlControl" />
            <mat-error class="hint-text" *ngIf="attributionUrlControl.invalid">
                <span *ngIf="attributionUrlControl.hasError('required')">An Attribution URL is required</span>
                <span *ngIf="attributionUrlControl.hasError('pattern')">URL must begin with http:// or https://</span>
            </mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Legend URL</mat-label>
            <input matInput name="legendUrl" placeholder="Legend URL" [(ngModel)]="tmpLayer.legendUrl" />
        </mat-form-field>

        <mat-form-field>
            <mat-label>Service URL</mat-label>
            <input matInput name="serviceUrl" placeholder="Service URL" [(ngModel)]="tmpLayer.serviceurl" />
        </mat-form-field>

        <mat-form-field>
            <mat-label>Params</mat-label>
            <input matInput name="params" placeholder="Params" [(ngModel)]="tmpLayer.params" />
        </mat-form-field>

        <mat-form-field>
            <mat-label>Service Layer</mat-label>
            <input matInput name="serviceLayer" placeholder="Service Layer" [(ngModel)]="tmpLayer.servicelayer" />
        </mat-form-field>

        <mat-slide-toggle labelPosition="before" [checked]="tmpLayer.active" (change)="tmpLayer.active = $event.checked">
            Enabled
        </mat-slide-toggle>
        <p class="hint-text">Layer is visible on the Mapper</p>

        <div class="action-buttons">
            <button mat-stroked-button color="warn" (click)="deleteLayer()">Delete Layer</button>
            <button
                mat-flat-button
                color="primary"
                [disabled]="layerNameControl.invalid || attributionUrlControl.invalid"
                (click)="updateLayer()">
                Update Layer
            </button>
        </div>
    </form>
</ng-template>
