
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import * as moment from 'moment';

export class GenApiKeyRequest {

    id: number;
    acceptTermsOfUse: boolean;
    dataService: boolean;
    deniedReason: string;
    email: string;
    ogcService: boolean;
    reason: string;
    requestDate: moment.Moment;
    requestedSystems: string[];
    status: string;
    system: string;
    userEnabled: boolean;
    userId: number;
    userName: string;
    usersSupported: number;

    constructor();
    constructor( jsonObj: any );
    constructor( genApiKeyRequest: GenApiKeyRequest);
    constructor( id: number );

    constructor( input?: GenApiKeyRequest|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            this.acceptTermsOfUse = input.acceptTermsOfUse; // boolean [boolean]
            this.dataService = input.dataService; // boolean [boolean]
            this.deniedReason = input.deniedReason; // string [string]
            this.email = input.email; // string [string]
            this.ogcService = input.ogcService; // boolean [boolean]
            this.reason = input.reason; // string [string]
            if ( input.requestDate && typeof input.requestDate === 'string' ) {
                this.requestDate = moment(input.requestDate).utc();
            } else {
                this.requestDate = input.requestDate;
            }
            this.requestedSystems = input.requestedSystems; // list [string]
            this.status = input.status; // string [string]
            this.system = input.system; // string [string]
            this.userEnabled = input.userEnabled; // boolean [boolean]
            this.userId = input.userId; // int [number]
            this.userName = input.userName; // string [string]
            this.usersSupported = input.usersSupported; // int [number]
       }
    }

    static jsonResponseToList(arr: any[]): GenApiKeyRequest[] {
        if (arr && arr.map) {
          return arr.map(a => new GenApiKeyRequest(a));
        } else {
          return [];
        }
    }
}

