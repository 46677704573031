import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MapLayerGroup} from '../classes/map-layer-group';
import {BehaviorSubject, forkJoin, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {ApplicationConfig} from '../../../classes/application-config';
import {GenOutageAggregationLevel} from '../../../../../generated/serverModels/GenOutageAggregationLevel';
import {MapLayerCategory} from '../classes/map-layer-category';
import {GenServiceType} from '../../../../../generated/serverModels/GenServiceType';
import {LeafletNomFilter} from '../filters/leaflet-nom-filter';
import {HistoricalOutageData} from '../classes/historical-outage-data';
import {ResponseWrapper} from '../../../classes/response-wrapper';
import {LeafletMapLayer} from '../classes/leaflet-map-layer';
import {CountyOutage} from '../../outage/classes/county-outage';
import {LayerLegend} from '../classes/legend';
import {GenLegendType} from '../../../../../generated/serverModels/GenLegendType';
import {GenMax24HourState} from '../../../../../generated/serverModels/GenMax24HourState';
import {GenMax24HourCounty} from '../../../../../generated/serverModels/GenMax24HourCounty';
import {Router} from '@angular/router';
import {IMapNavigationConfig} from '../../../interfaces/map-navigation-config.interface';
import {DataService} from '../../../services/data.service';
import {GenMax24HourZip} from 'frontend/generated/serverModels/GenMax24HourZip';
import {ZipOutage} from '../../outage/classes/zip-outage';

@Injectable({
    providedIn: 'root',
})
export class LayerService {
    // For Now This will need to be cleared after each use each use
    public mapConfig: BehaviorSubject<IMapNavigationConfig> = new BehaviorSubject<IMapNavigationConfig>(undefined);

    constructor(private http: HttpClient, private router: Router) {}

    public navigateToMap(mapLayers: LeafletMapLayer[]): void {
        const options: IMapNavigationConfig = {
            layerInfo: mapLayers.map((layer) => {
                return {id: layer.id};
            }),
        };
        const link = ApplicationConfig.onMobile() ? '/app/lite/map' : '/app/map/view';

        this.mapConfig.next(options);
        this.router.navigate([link]);
    }

    public getLayerCategories(onlyShowActive: boolean = true): Observable<MapLayerCategory[]> {
        const url = `api/maplayer/categories?activeonly=${onlyShowActive}`;

        return this.http.get<any[]>(url).pipe(map((categories) => categories.map((cat) => new MapLayerCategory(cat))));
    }

    public getLayerByHandle(handle: string = 'nationalOutageMap'): Observable<LeafletMapLayer> {
        return this.http.get(`api/maplayer/layer?handle=${handle}`).pipe(map((layer: any) => new LeafletMapLayer(layer)));
    }

    public getLayerGroups(onlyShowActive: boolean = true): Observable<MapLayerGroup[]> {
        let groupUrl = 'api/maplayer';
        if (!onlyShowActive) {
            groupUrl += `?activeonly=${onlyShowActive}`;
        }

        const mf = (groups: MapLayerGroup[]) => {
            return groups.map((group) => new MapLayerGroup(group));
        };

        return this.http.get<MapLayerGroup[]>(groupUrl).pipe(map(mf));
    }

    public save(layers: LeafletMapLayer[]): Observable<LeafletMapLayer[]> {
        return this.http.post<any[]>('api/maplayer', layers).pipe(map((mapLayers) => mapLayers.map((layer) => new LeafletMapLayer(layer))));
    }

    public updateMapLayer(layer: LeafletMapLayer) {
        return this.http.put('api/maplayer', layer);
    }

    public deleteMapLayer(layer: LeafletMapLayer) {
        return this.http.delete(`api/maplayer/${layer.id}`);
    }

    public getJsonFromUrl<X = any>(url: string, shouldCache?: boolean, proxyRequest?: boolean, additionalOptions: any = {}): Observable<X> {
        if (shouldCache) {
            // FIX THIS
            // opts['headers'] = (url.includes('/legend')) ? EagleiSource.legendHeader : EagleiSource.layerHeader;
        }

        if (proxyRequest) {
            url = ApplicationConfig.proxyPrefix + url;
        }

        return this.http.get<X>(url, additionalOptions) as Observable<X>;
    }

    public getHistoricalOutageData(filters: LeafletNomFilter): Observable<HistoricalOutageData[]> {
        let url: string;

        const stateMap = (res: ResponseWrapper<GenMax24HourState>): HistoricalOutageData[] => {
            return res.data.map((data) => {
                const outage = new HistoricalOutageData(data as any);
                outage.stateFIPSCode = data.fipsCode;
                const key = outage.geometryKey();
                outage.boundary = ApplicationConfig.geometries
                    .get(GenOutageAggregationLevel.state)
                    .find((g) => g.fipsCode === key).boundary;
                return outage;
            });
        };

        const countyMap = (res: any[]): HistoricalOutageData[] => {
            const counties = {};
            const geometries = ApplicationConfig.geometries.get(GenOutageAggregationLevel.county);

            // 24 hour max (historical)
            (res[0] as ResponseWrapper<any>).data.forEach((outage) => {
                const val = new HistoricalOutageData(outage);
                const geom = geometries.find((g) => g.fipsCode === val.geometryKey());

                val.boundary = geom.boundary;
                counties[val.geometryKey()] = val;
            });

            // county
            (res[1] as ResponseWrapper<GenMax24HourCounty>).data
                .map((county) => new CountyOutage(county as any).toHistoricalOutage())
                .filter((historicalCounty) => !counties.hasOwnProperty(historicalCounty.geometryKey()))
                .forEach((val) => {
                    const geom = geometries.find((g) => g.fipsCode === val.geometryKey());

                    val.maxOutage1 = 0;
                    val.maxOutage24 = 0;
                    val.maxOutage1RunStartTime = val.currentOutageRunStartTime;
                    val.maxOutage24RunStartTime = val.currentOutageRunStartTime;

                    val.boundary = geom.boundary;
                    counties[val.geometryKey()] = val;
                });

            geometries
                .filter((geom) => !counties.hasOwnProperty(geom.fipsCode))
                .forEach((geom) => {
                    const split = geom.name.split(DataService.geometryNameSeparator);
                    const val = new HistoricalOutageData();
                    val.stateName = split[0];
                    val.countyName = split[1];
                    val.boundary = geom.boundary;
                    counties[geom.fipsCode] = val.updateName();
                });

            return Object.values(counties);
        };

        const zipMap = (res: any[]): HistoricalOutageData[] => {
            const zips = {};

            // 24 hour max (historical)
            (res[0] as ResponseWrapper<any>).data.forEach((outage) => {
                const val = new HistoricalOutageData(outage);
                val.zipInfo = outage;

                val.updateName();
                zips[val.geometryKey()] = val;
            });

            // zip
            (res[1] as ResponseWrapper<GenMax24HourZip>).data
                .map((zip) => new ZipOutage(zip as any).toHistoricalOutage())
                .filter((historicalZip) => !zips.hasOwnProperty(historicalZip.geometryKey()))
                .forEach((val) => {
                    val.maxOutage1 = 0;
                    val.maxOutage24 = 0;
                    val.maxOutage1RunStartTime = val.currentOutageRunStartTime;
                    val.maxOutage24RunStartTime = val.currentOutageRunStartTime;

                    zips[val.geometryKey()] = val;
                });

            return Object.values(zips);
        };

        if (filters.aggregationLevel === GenOutageAggregationLevel.state) {
            url = `/api/outagesummary/statemax24hoursummary?start=${filters.getApiDate()}`;
            return this.http.get<any>(url).pipe(map((res: any) => stateMap(res)));
        } else if (filters.aggregationLevel === GenOutageAggregationLevel.county) {
            url = `/api/outagesummary/countymax24hoursummary?start=${filters.getApiDate()}`;
            const countyUrl = `api/outagesummary/county?start=${filters.getApiDate()}`;

            const historical = this.http.get(url);
            const county = this.http.get(countyUrl);

            return forkJoin([historical, county]).pipe(map(countyMap));
        } else if (filters.aggregationLevel === GenOutageAggregationLevel.zip) {
            url = `/api/outagesummary/zipmax24hoursummary?start=${filters.getApiDate()}`;
            const zipUrl = `api/outagesummary/zip?start=${filters.getApiDate()}`;

            const historical = this.http.get(url);
            const zip = this.http.get(zipUrl);

            return forkJoin([historical, zip]).pipe(map(zipMap));
        }

        return of(null);
    }

    public getWmsCapabilities(url: string): Observable<LeafletMapLayer[]> {
        const fullUrl = `${url}?service=wms&version=1.3.0&request=GetCapabilities`;
        // Map Function
        const mf = (res: any) => {
            const doc = new DOMParser().parseFromString(res, 'text/xml');
            const layerTags: HTMLElement[] = Array.from(doc.getElementsByTagName('Layer') as any);
            const wmsUrl: string = (doc.querySelector('HTTP > Get > OnlineResource') as HTMLElement).getAttribute('xlink:href') as string;

            const createDynamicLayer = (node: HTMLElement, namePrefix?: string) => {
                const legendElement = node.querySelector('Style OnlineResource');
                const legendUrl: string = legendElement === null ? '' : legendElement.getAttribute('xlink:href') || '';
                const layerName: string = node.getElementsByTagName('Title')[0].childNodes[0].nodeValue || '';
                const current = new LeafletMapLayer();
                current.serviceurl = wmsUrl;
                current.displayName = namePrefix ? `${namePrefix} - ${layerName}` : layerName;
                current.tmpLayerName = current.displayName.slice();
                current.servicelayer = node.getElementsByTagName('Name')[0].childNodes[0].nodeValue as any;
                current.uiHandle = current.displayName.replace(/ /g, '');
                current.servicetype = GenServiceType.WMS;

                if (legendUrl.length > 0) {
                    const legend = new LayerLegend();
                    legend.type = GenLegendType.IMAGE;
                    legend.urls = [legendUrl];
                    current.legend = legend;
                }

                return current;
            };

            const ret: LeafletMapLayer[] = [];
            const parentNodes: HTMLElement[] = [];
            const childNodes: HTMLElement[] = [];

            layerTags.forEach((tag) => {
                const children = Array.from(tag.childNodes).filter((node) => node.nodeType === 1);
                const parentName: string = tag.parentNode ? tag.parentNode.nodeName : '';
                const hasLayerChild = children.some((c) => c.nodeName === 'Layer');
                if (hasLayerChild && parentName !== 'Capability') {
                    parentNodes.push(tag);
                } else if (!hasLayerChild && parentName === 'Layer') {
                    childNodes.push(tag);
                }
            });

            parentNodes.forEach((node) => {
                const parentName = node.getElementsByTagName('Title')[0].childNodes[0].nodeValue || '';
                Array.from(node.children)
                    .filter((child) => child.nodeName === 'Layer')
                    .forEach((child) => {
                        child.setAttribute('parentName', parentName);
                    });
            });

            childNodes.forEach((node) => {
                ret.push(createDynamicLayer(node as HTMLElement, node.getAttribute('parentName') || undefined));
            });
            return ret;
        };

        return this.http.get(fullUrl, {responseType: 'text'}).pipe(map(mf));
    }

    public getArcgisLayers(url: string): Observable<any[]> {
        const mf = (res: any) => {
            const layers: LeafletMapLayer[] = [];
            const parentNames = new Map<number, string>();
            (res.layers as any[]).forEach((layer) => {
                if (layer.subLayerIds) {
                    parentNames.set(layer.id, layer.name);
                } else {
                    const parentName = parentNames.get(layer.parentLayerId) || '';
                    const current = new LeafletMapLayer();
                    current.servicetype = GenServiceType.ARCGIS;
                    current.displayName = parentName ? `${parentName} - ${layer.name}` : layer.name;
                    current.tmpLayerName = current.displayName;
                    current.uiHandle = current.displayName.replace(/ /g, '');
                    current.params = `show:${layer.id}`;
                    current.servicelayer = layer.id.toString();
                    current.serviceurl = url;

                    const legend = new LayerLegend();
                    legend.type = GenLegendType.ARCGIS;
                    legend.urls = [url + '/legend?f=pjson'];
                    legend.values = JSON.stringify({
                        layerIds: layer.id.toString(),
                    });

                    current.legend = legend;

                    layers.push(current);
                }
            });
            return layers;
        };

        return this.http.get<any>(url + '?f=pjson').pipe(map(mf));
    }
}
