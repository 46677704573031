import {LeafletFeature} from '../classes/leaflet-feature';
import {LeafletMapLayer} from '../classes/leaflet-map-layer';
import {LeafletVectorSource} from '../classes/leaflet-vector-source';
import * as L from 'leaflet';
import {IBarrelFlow} from 'frontend/src/app/interfaces/ong/barrel-flow.interface';
import {LayerStyleService} from '../services/layer-style.service';
import {FeatureStyle} from '../interfaces/style.interface';
import {PopoverElement} from 'frontend/src/app/classes/popover-element';
import {Report} from '../../report/classes/report';

export class OilBarrelFlowSource extends LeafletVectorSource<IBarrelFlow> {
    constructor(layerInfo: LeafletMapLayer) {
        super(layerInfo);
    }

    public processFeatures(features: any[]): void {
        this.leafletFeatures = features.map((v) => {
            const feature = new LeafletFeature<IBarrelFlow>().convert(v, 'geometry').buildPopover(this.layerInfo);

            const properties = feature.properties.properties;
            const data = [
                new PopoverElement().setTitle().setValue(properties.pipeline_name),
                new PopoverElement().setLabel('Pipeline ID').setValue(properties.pipeline_id.toString()),
                new PopoverElement().setLabel('Capacity').setValue(properties.barrel_flow[0].capacity.toString()),
                new PopoverElement().setLabel('Flow').setValue(properties.barrel_flow[0].flow.toString()),
                new PopoverElement().setLabel('Direction').setValue(properties.barrel_flow[0].direction),
                new PopoverElement().setLabel('Start Pump').setValue(properties.start_pump),
                new PopoverElement().setLabel('End Pump').setValue(properties.finish_pump),
                new PopoverElement().setLabel('Confidence ID').setValue(properties.barrel_flow[0].confidence_id.toString()),
                new PopoverElement()
                    .setLabel('Date')
                    .setValue(Report.momentPipe.transform(properties.barrel_flow[0].report_date, 'MM/DD/YYYY')),
            ];

            feature.subFeatures = [
                {
                    id: feature.properties.properties.pipeline_id,
                    popoverData: data,
                },
            ];

            return feature;
        });

        const featureStyle = (feature: LeafletFeature<IBarrelFlow>) => {
            const barrelSettings = this.getBarrelFlowStyleSettings(feature.properties);
            const key = `barrel-flow-${barrelSettings.color}`;
            let style = LayerStyleService.layerStyles.get(key);

            if (!style) {
                style = {
                    color: barrelSettings.color,
                    weight: barrelSettings.weight,
                } as FeatureStyle;
            }

            return style;
        };

        const config = {
            style: featureStyle,
            onEachFeature: this.initializePopoverInteractions.bind(this),
            features: this.leafletFeatures,
            smoothFactor: 0.8,
        };

        this.source = L.geoJSON(this.leafletFeatures as any, config as any);
    }

    // noinspection JSMethodCanBeStatic
    private getBarrelFlowStyleSettings(feature: IBarrelFlow): any {
        const utilization = +feature.properties.barrel_flow[0].flow / feature.properties.barrel_flow[0].capacity;

        if (utilization > 0.9) {
            return {color: '#079B80', weight: 1};
        } else if (utilization >= 0.7 && utilization <= 0.9) {
            return {color: '#F2814C', weight: 2};
        } else if (utilization < 0.7) {
            return {color: '#9B1BC5', weight: 3};
        }

        return {color: '#3388ff', weight: 0.5};
    }
}
