import {GenEventDetail} from '../../../../../generated/serverModels/GenEventDetail';
import {HeadoutEventResult} from './headout-event-result';

export class HeadoutEventDetail extends GenEventDetail {
    public eventResults: HeadoutEventResult[];

    constructor(details?: HeadoutEventDetail) {
        super(details);

        if (details) {
            this.eventResults = details.eventResults.map((r) => new HeadoutEventResult(r));
        }
    }
}
