import {Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {LandingGridItem} from '../../../landing/classes/landing-grid-item';
import {Subject} from 'rxjs';
import {LockTimeService} from '../../../services/lock-time.service';
import {filter, skip, takeUntil} from 'rxjs/operators';
import {UserPreference} from 'frontend/src/app/classes/user-preferences';
import {ApplicationConfig} from 'frontend/src/app/classes/application-config';
import {EventDashboardComponent} from '../../system-event/components/event-dashboard/event-dashboard.component';
import {EventSummaryFilters} from '../../system-event/classes/event-summary-filters';

@Directive()
export abstract class BaseWidget implements OnInit, OnDestroy {
    @ViewChild('header', {static: true}) header: ElementRef;
    @ViewChild('content', {static: true}) content: ElementRef;
    @ViewChild('footer', {static: true}) footer: ElementRef;

    // Inputs
    @Input() item: LandingGridItem;
    @Input() eventDashboard: boolean = false;

    // Outputs
    @Output() loading: EventEmitter<boolean> = new EventEmitter<boolean>();

    set updateOnSystemTime(value: boolean) {
        if (value) {
            LockTimeService.currentApplicationTime
                .pipe(skip(1), takeUntil(this.destroy$)) // Skipping the initialization of the Behaviour subject
                .subscribe(() => this.onApplicationTimeChange());
        }
    }

    protected attributionUrl: string;
    protected attributionText: string;
    protected attributionModalText: string;
    public widgetName: string;

    protected ele: ElementRef<HTMLElement>;

    protected widgetInterceptorKey: string;

    protected userPreferences: UserPreference;

    protected destroy$ = new Subject();

    protected constructor(widgetElement?: ElementRef, updateOnApplicationTimeChange: boolean = false) {
        if (widgetElement) {
            this.ele = widgetElement;
        }
        this.updateOnSystemTime = updateOnApplicationTimeChange;

        this.getUserPreferences();
    }

    ngOnInit() {
        const dashboardType = this.eventDashboard ? 'event' : 'landing';

        if (this.item) {
            this.widgetInterceptorKey = `${dashboardType}-${this.item.widgetName.toString()}-widget-${this.item.x}-${this.item.y}`;
        }

        if (this.eventDashboard) {
            EventDashboardComponent.eventFilterChange
                .pipe(
                    filter((eventFilter) => !!eventFilter),
                    takeUntil(this.destroy$)
                )
                .subscribe(this.handleEventFilterChange.bind(this));
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next(undefined);
        this.destroy$.complete();
        this.destroyWidget();
    }

    protected getHeight(ref: ElementRef): number {
        return (ref.nativeElement as HTMLElement).getBoundingClientRect().height;
    }

    protected setHeight(ref: ElementRef, height: number): void {
        (ref.nativeElement as HTMLElement).style.height = `${height}px`;
    }

    protected resize(): void {
        if (!this.ele) {
            console.warn('unable to resize because widget element was not defined, please pass the widget elementRef into the constructor');
            return;
        }

        if (!this.content) {
            console.warn('unable to resize because content is not defined');
            return;
        }

        const widgetHeight = this.getHeight(this.ele);
        const headerHeight = this.header ? this.getHeight(this.header) : 0;
        const footerHeight = this.footer ? this.getHeight(this.footer) : 0;

        const contentHeight = widgetHeight - headerHeight - footerHeight;
        this.setHeight(this.content, contentHeight);
    }

    protected getUserPreferences(): void {
        this.userPreferences = ApplicationConfig.currentUserPreferences.getValue();
    }

    /**
     * This method should be overwritten in any component that will try to use it. It will only be fired from the lock time update
     */
    onApplicationTimeChange(): void {
        alert(`Override 'onApplicationTimeChange'`);
    }

    /**
     * A method to be overridden by the calling component to correctly handle the filter change
     * @param eventFilters The updated event filters value.
     * @protected
     */
    protected handleEventFilterChange(eventFilters: EventSummaryFilters): void {
        console.warn(`Override 'handleEventFilterChange' for ${this.item.widgetName.toString()}`);
    }

    /**
     * returns true if the current device is a mobile device. false otherwise.
     */
    public onMobile(): boolean {
        return ApplicationConfig.onMobile();
    }

    abstract destroyWidget(): void;
}
