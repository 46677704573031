
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import { GenCommunityPermission } from './GenCommunityPermission';
import { GenCommunityUser } from './GenCommunityUser';
import { GenUploadedFile } from './GenUploadedFile';
import * as moment from 'moment';

export class GenCommunity {

    id: number;
    createDate: moment.Moment;
    dataAccessHandle: GenCommunityPermission;
    description: string;
    files: GenUploadedFile[];
    name: string;
    publicAccess: boolean;
    users: GenCommunityUser[];

    constructor();
    constructor( jsonObj: any );
    constructor( genCommunity: GenCommunity);
    constructor( id: number );

    constructor( input?: GenCommunity|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            if ( input.createDate && typeof input.createDate === 'string' ) {
                this.createDate = moment(input.createDate).utc();
            } else {
                this.createDate = input.createDate;
            }
            this.dataAccessHandle = (typeof input.dataAccessHandle === 'string') ? GenCommunityPermission.forName(<string> input.dataAccessHandle) : <any> input.dataAccessHandle; // GenCommunityPermission
            this.description = input.description; // string [string]
            if ( input.files && input.files.map ) { // list [GenUploadedFile]
                this.files = input.files.map( ( x: GenUploadedFile ) => {
                    return new GenUploadedFile( x );
                } );
            } else {
                this.files = input.files;
            }
            this.name = input.name; // string [string]
            this.publicAccess = input.publicAccess; // boolean [boolean]
            if ( input.users && input.users.map ) { // list [GenCommunityUser]
                this.users = input.users.map( ( x: GenCommunityUser ) => {
                    return new GenCommunityUser( x );
                } );
            } else {
                this.users = input.users;
            }
       }
    }

    static jsonResponseToList(arr: any[]): GenCommunity[] {
        if (arr && arr.map) {
          return arr.map(a => new GenCommunity(a));
        } else {
          return [];
        }
    }
}

