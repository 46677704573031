import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {State} from '../../../app/modules/outage/classes/state';

@Component({
    selector: 'eaglei-filter-chip-list',
    templateUrl: './filter-chip-list.component.html',
    styleUrls: ['./filter-chip-list.component.scss'],
})
export class FilterChipListComponent implements OnInit {
    @Input() states: State[];
    @Input() numberVisible: number = 5;
    @Input() showAbbreviation: boolean;

    @Output() change = new EventEmitter<State[]>();

    public numberToShow: number;
    public showAll: boolean;

    ngOnInit() {
        this.numberToShow = this.numberVisible;
    }

    /**
     * Removes a single state from the list and emits a new value to the parent component.
     * @param state The state to be removed.
     */
    public remove(state: State): void {
        const index = this.states.findIndex((s) => s.id === state.id);
        if (index !== -1) {
            this.states.splice(index, 1);
            this.change.emit(this.states);
        }
    }

    /**
     * Shows all chips instead of {numberVisible} chips
     */
    public showAllChips(): void {
        this.showAll = true;
        this.numberToShow = this.states.length;
    }

    /**
     * reverts the list back to show only {numberVisible} chips
     */
    public showLess(): void {
        this.showAll = false;
        this.numberToShow = this.numberVisible;
    }

    /**
     * Clears all states in the filter list and emits the change
     */
    public clearAll(): void {
        this.states = [];
        this.change.emit(this.states);
    }

    /**
     * Gets the correct display value from the state object.
     * @param state The state to be displayed
     */
    public getDisplayValue(state: State): string {
        return this.showAbbreviation ? state.abbreviation : state.name;
    }
}
