
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import { GenLegendStyle } from './GenLegendStyle';
import { GenStatePreference } from './GenStatePreference';
import { GenUserPreferenceAggregationLevel } from './GenUserPreferenceAggregationLevel';

export class GenUserPreferences {

    aggregationLevel: GenUserPreferenceAggregationLevel;
    femaRegions: number[];
    legendMetric: number;
    legendStyle: GenLegendStyle;
    states: GenStatePreference[];
    userId: number;

    constructor();
    constructor( jsonObj: any );
    constructor( genUserPreferences: GenUserPreferences);

    constructor( input?: GenUserPreferences ) {
        if ( input && typeof input !== 'number' ) {
            if( input.aggregationLevel ) {
              this.aggregationLevel = new GenUserPreferenceAggregationLevel( input.aggregationLevel );
            }
            this.femaRegions = input.femaRegions; // list [number]
            this.legendMetric = input.legendMetric; // int [number]
            if( input.legendStyle ) {
              this.legendStyle = new GenLegendStyle( input.legendStyle );
            }
            if ( input.states && input.states.map ) { // list [GenStatePreference]
                this.states = input.states.map( ( x: GenStatePreference ) => {
                    return new GenStatePreference( x );
                } );
            } else {
                this.states = input.states;
            }
            this.userId = input.userId; // int [number]
       }
    }

    static jsonResponseToList(arr: any[]): GenUserPreferences[] {
        if (arr && arr.map) {
          return arr.map(a => new GenUserPreferences(a));
        } else {
          return [];
        }
    }
}

