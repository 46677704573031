<div class="dialog-header">
    <span>Create Training Users</span>
    <button mat-icon-button (click)="close()">
        <mat-icon fontSet="fas" fontIcon="fa-times"></mat-icon>
    </button>
</div>

<mat-container *ngIf="generatedUsers.length < 1">
    <div class="dialog-content" #content>
        <form [formGroup]="trainingUsersGroup">
            <mat-form-field appearance="outline">
                <mat-label>Number of Users</mat-label>
                <input type="number" matInput formControlName="numUsers" min="1" max="10" eagleiNumberInput />
                <mat-error>
                    <ng-container *ngIf="trainingUsersGroup.get('numUsers').errors">
                        <span *ngIf="trainingUsersGroup.get('numUsers').errors['required']">Number of Users is Required</span>
                        <span *ngIf="trainingUsersGroup.get('numUsers').errors['min']">Number of Users must be greater than 0</span>
                        <span *ngIf="trainingUsersGroup.get('numUsers').errors['max']">Number of Users must be less than 10</span>
                    </ng-container>
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Username Prefix</mat-label>
                <input type="text" matInput formControlName="prefix" />
                <mat-error>
                    <ng-container *ngIf="trainingUsersGroup.get('prefix').errors"> Prefix cannot have spaces</ng-container>
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Roles to Grant</mat-label>
                <mat-select multiple formControlName="roles">
                    <mat-option *ngFor="let role of allRoles" [value]="role">{{ role | roleDefinition }}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Training End Date</mat-label>
                <input matInput [min]="minDate" [matDatepicker]="picker" formControlName="expirationDate" disabled />
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </form>
    </div>

    <div class="dialog-actions">
        <button mat-flat-button color="primary" (click)="createTrainingUsers()" [disabled]="trainingUsersGroup.invalid">
            Create Training Users
        </button>
    </div>
</mat-container>

<mat-container *ngIf="generatedUsers.length > 0">
    <mat-card>
        <mat-card-content>
            <div class="table-container">
                <table #materialTable mat-table [dataSource]="generatedUsers" multiTemplateDataRows>
                    <ng-container matColumnDef="username">
                        <th mat-header-cell *matHeaderCellDef>Username</th>
                        <td mat-cell *matCellDef="let user">
                            <p class="ellipsis">{{ user.username }}</p>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="password">
                        <th mat-header-cell *matHeaderCellDef>Password</th>
                        <td mat-cell *matCellDef="let user">
                            <p class="ellipsis">{{ user.password }}</p>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="apiKey">
                        <th mat-header-cell *matHeaderCellDef>API Key</th>
                        <td mat-cell *matCellDef="let user">
                            <p class="ellipsis">{{ user.apiKey }}</p>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
                    <tr mat-row *matRowDef="let row; columns: columnNames" class="hoverable"></tr>
                </table>
            </div>
        </mat-card-content>
    </mat-card>
</mat-container>
