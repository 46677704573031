<eaglei-leaflet-layer-header [layer]="this" (activeToggled)="toggleLayer($event)" [hideLegend]="true"></eaglei-leaflet-layer-header>

<ng-container *ngIf="mapControls.filterGroup && isActive">
    <form [formGroup]="mapControls.filterGroup">
        <mat-form-field [class.active]="mapControls.filterGroup.controls.type.value">
            <mat-label>Event Type</mat-label>
            <mat-select [compareWith]="systemEventService.typeCompareFunction" placeholder="Event Type" formControlName="type">
                <mat-option *ngFor="let eventType of eventTypes" [value]="eventType">
                    {{ eventType.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field [class.active]="mapControls.filterGroup.controls.name.value">
            <mat-label>Event Name</mat-label>
            <mat-select placeholder="Event name" formControlName="name">
                <mat-optgroup *ngFor="let group of mapControls.eventGroups | keyvalue" [label]="group.key | titlecase">
                    <mat-option *ngFor="let event of mapControls.eventGroups[group.key]" [value]="event">{{ event.name }} </mat-option>
                </mat-optgroup>
            </mat-select>
        </mat-form-field>

        <mat-form-field [class.active]="mapControls.filterGroup.controls.advisory.value">
            <mat-label>Advisory</mat-label>
            <mat-select placeholder="Advisory" formControlName="advisory">
                <mat-option *ngFor="let result of mapControls.selectedDetail?.eventResults" [value]="result">
                    {{ result.resultId }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </form>

    <div #legendElement>
        <div class="legends">
            <span class="legend-title">Predicted Outage Percentages:</span>
            <div class="customer-out-legend">
                <div
                    class="color-blocks"
                    *ngFor="let percentage of styleService.percentOutDomain; let first = first; let last = last"
                    [class.first-block]="first"
                    [class.last-block]="last"
                    [style.background-color]="percentage.color">
                    <span class="text">{{ percentage.value }}</span>
                </div>
            </div>
        </div>

        <div class="legends advisory-legend">
            <div>
                <div class="color-dot" style="background-color: #ff0000"></div>
                <span class="legend-title"> Advisory</span>
            </div>

            <div>
                <div class="color-dot" style="background-color: #003366"></div>
                <span class="legend-title"> Selected Advisory</span>
            </div>
        </div>
    </div>

    <div class="sub-layer-section" *ngIf="mapControls.selectedDetail && mapControls.filterGroup.controls.advisory.value">
        <div *ngFor="let item of mapControls.weatherSources">
            <div class="sub-layer">
                <span class="layer-name ellipsis">{{ item.display | titlecase }}</span>
                <mat-slide-toggle [checked]="item.active" (change)="toggleWeatherLayer($event.checked, item)"></mat-slide-toggle>
            </div>

            <ng-container>
                <div class="opacity-slider">
                    <span class="opacity-text">Opacity</span>

                    <mat-slider color="primary" [disabled]="!item.active">
                        <input
                            matSliderThumb
                            [value]="item.source.layerInfo.opacity"
                            (valueChange)="changeWeatherLayerOpacity($event, item)" />
                    </mat-slider>
                    <span class="opacity-percent">{{ item.source.layerInfo.opacity }}%</span>
                </div>
            </ng-container>

            <div *ngIf="item.legend && item.active">
                <p class="legend-title">
                    {{ item.display }}
                    <i *ngIf="item.units">({{ item.units }})</i>
                </p>
                <!--suppress TypeScriptValidateTypes -->
                <eaglei-legend [legends]="[item.legend]"></eaglei-legend>
            </div>
        </div>
    </div>

    <!-- Infrastructure Controls -->
    <div class="sub-layer-section">
        <!--suppress TypeScriptValidateTypes -->
        <div *ngFor="let item of mapControls.eventInfrastructures | keyvalue">
            <div class="sub-layer">
                <span class="layer-name ellipsis">{{ item.value.display | titlecase }}</span>
                <mat-slide-toggle (change)="toggleInfrastructureLayer($event.checked, item)"></mat-slide-toggle>
            </div>

            <ng-container>
                <div class="opacity-slider">
                    <span class="opacity-text">Opacity</span>

                    <mat-slider color="primary" [disabled]="!item.value.active">
                        <input
                            matSliderThumb
                            [value]="subLayerOpacity(item.key) * 100"
                            (valueChange)="changeSubLayerOpacity($event, item.key)" />
                    </mat-slider>
                    <span class="opacity-percent">{{ subLayerOpacity(item.key) * 100 | number : '2.0-0' }}%</span>
                </div>
            </ng-container>

            <div *ngIf="item.value.legend && item.value.active">
                <!--suppress TypeScriptValidateTypes -->
                <eaglei-legend [legends]="[item.value.legend]"></eaglei-legend>
            </div>
        </div>
    </div>
</ng-container>
