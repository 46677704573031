import {AfterViewInit, Component, ElementRef, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ApplicationConfig} from 'frontend/src/app/classes/application-config';
import {ModalConfig} from 'frontend/src/app/classes/modal-config';
import {throttleTime} from 'rxjs/operators';

@Component({
    selector: 'eaglei-oil-refinery-alert-info-modal',
    templateUrl: './oil-refinery-alert-info-modal.component.html',
    styleUrls: ['./oil-refinery-alert-info-modal.component.scss'],
})
export class OilRefineryAlertInfoModalComponent implements AfterViewInit {
    @ViewChild('content', {static: true}) content: ElementRef;

    public alertData: any;
    public locations: any;
    public entities: any;

    constructor(public dialogRef: MatDialogRef<OilRefineryAlertInfoModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
        this.alertData = data.event;
    }

    public ngAfterViewInit(): void {
        this.setModalHeight();
        ApplicationConfig.resizeEvent.pipe(throttleTime(250)).subscribe(() => this.setModalHeight());
    }

    private setModalHeight() {
        (this.content.nativeElement as HTMLElement).style.maxHeight = ModalConfig.getMaxModalHeight() + 'px';
    }
}
