
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import { GenMapLayer } from './GenMapLayer';

export class GenMapLayerGroup {

    id: number;
    categoryId: number;
    displayName: string;
    iconUrl: string;
    layers: GenMapLayer[];
    ordering: number;
    uiHandle: string;

    constructor();
    constructor( jsonObj: any );
    constructor( genMapLayerGroup: GenMapLayerGroup);
    constructor( id: number );

    constructor( input?: GenMapLayerGroup|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            this.categoryId = input.categoryId; // int [number]
            this.displayName = input.displayName; // string [string]
            this.iconUrl = input.iconUrl; // string [string]
            if ( input.layers && input.layers.map ) { // list [GenMapLayer]
                this.layers = input.layers.map( ( x: GenMapLayer ) => {
                    return new GenMapLayer( x );
                } );
            } else {
                this.layers = input.layers;
            }
            this.ordering = input.ordering; // int [number]
            this.uiHandle = input.uiHandle; // string [string]
       }
    }

    static jsonResponseToList(arr: any[]): GenMapLayerGroup[] {
        if (arr && arr.map) {
          return arr.map(a => new GenMapLayerGroup(a));
        } else {
          return [];
        }
    }
}

