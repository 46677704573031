<div class="dialog-header">
    <span class="filter-header">{{ displayList.title }}</span>
    <mat-icon fontSet="fas" fontIcon="fa-times" inline class="clickable" (click)="dialogRef.close()"> </mat-icon>
</div>

<div #content class="dialog-content">
    <div class="news-info">
        <div>
            Posted on
            <time>{{ displayList.createDate | momentDate }}</time>
        </div>
    </div>

    <p class="news-content">{{ displayList.value }}</p>
</div>
