
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import * as moment from 'moment';

export class GenGeneratedDateRangeResult {

    maxGenerated: moment.Moment;
    minGenerated: moment.Moment;

    constructor();
    constructor( jsonObj: any );
    constructor( genGeneratedDateRangeResult: GenGeneratedDateRangeResult);

    constructor( input?: GenGeneratedDateRangeResult ) {
        if ( input && typeof input !== 'number' ) {
            if ( input.maxGenerated && typeof input.maxGenerated === 'string' ) {
                this.maxGenerated = moment(input.maxGenerated).utc();
            } else {
                this.maxGenerated = input.maxGenerated;
            }
            if ( input.minGenerated && typeof input.minGenerated === 'string' ) {
                this.minGenerated = moment(input.minGenerated).utc();
            } else {
                this.minGenerated = input.minGenerated;
            }
       }
    }

    static jsonResponseToList(arr: any[]): GenGeneratedDateRangeResult[] {
        if (arr && arr.map) {
          return arr.map(a => new GenGeneratedDateRangeResult(a));
        } else {
          return [];
        }
    }
}

