
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import { GenMapLayerGroup } from './GenMapLayerGroup';

export class GenMapLayerCategory {

    id: number;
    displayName: string;
    groups: GenMapLayerGroup[];
    ordering: number;
    uiHandle: string;

    constructor();
    constructor( jsonObj: any );
    constructor( genMapLayerCategory: GenMapLayerCategory);
    constructor( id: number );

    constructor( input?: GenMapLayerCategory|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            this.displayName = input.displayName; // string [string]
            if ( input.groups && input.groups.map ) { // list [GenMapLayerGroup]
                this.groups = input.groups.map( ( x: GenMapLayerGroup ) => {
                    return new GenMapLayerGroup( x );
                } );
            } else {
                this.groups = input.groups;
            }
            this.ordering = input.ordering; // int [number]
            this.uiHandle = input.uiHandle; // string [string]
       }
    }

    static jsonResponseToList(arr: any[]): GenMapLayerCategory[] {
        if (arr && arr.map) {
          return arr.map(a => new GenMapLayerCategory(a));
        } else {
          return [];
        }
    }
}

