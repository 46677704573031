<div mat-dialog-title class="header">
    <span class="title">Email Table Data</span>
    <button mat-icon-button matDialogClose>
        <mat-icon fontSet="fas" fontIcon="fa-times"></mat-icon>
    </button>
</div>

<mat-dialog-content #content>
    <p class="export-info">
        Send data to emails below for the current filter selection. Data will be sent starting at
        {{ startDate | momentDate : 'MM/DD/YYYY h:mm A' }}
        every
        <span *ngIf="exportGroup.controls.frequency?.value === 24"> hour</span>
        <span *ngIf="exportGroup.controls.frequency?.value !== 24"> {{ 24 / exportGroup.controls.frequency?.value }} hours</span>
        until midnight {{ endDate | momentDate : 'MM/DD/YYYY' }}.
    </p>
    <!--  <p class="export-info">
      Select a start date, end date, frequency of emails per day, and enter the email addresses of the recipients. There
      is a maximum of 7 consecutive days that you can receive table data.
    </p>-->

    <form [formGroup]="exportGroup">
        <!-- class="wide ignore-style system-input"-->
        <mat-form-field
            [class.active]="exportGroup.controls.name.value"
            class="name filter-select"
            appearance="outline"
            floatLabel="always">
            <mat-label>Name</mat-label>
            <input matInput formControlName="name" maxlength="100" />
            <mat-hint align="end">{{ exportGroup.controls.name.value?.length || 0 }}/100</mat-hint>
            <mat-error>
                <span>A name is required.</span>
            </mat-error>
        </mat-form-field>

        <div class="first-row">
            <eaglei-date-range
                [gap]="'2rem'"
                [start]="startDate"
                [end]="endDate"
                [newDesign]="true"
                [showTime]="false"
                [min]="minDate"
                [max]="maxDate"
                [allowFutureEndDate]="true"
                (change)="updateDates($event)">
            </eaglei-date-range>
            <mat-form-field appearance="outline">
                <mat-label>Email Frequency Per Day</mat-label>
                <mat-select formControlName="frequency">
                    <mat-option [value]="1">1</mat-option>
                    <mat-option [value]="2">2</mat-option>
                    <mat-option [value]="3">3</mat-option>
                    <mat-option [value]="4">4</mat-option>
                    <mat-option [value]="6">6</mat-option>
                    <mat-option [value]="8">8</mat-option>
                    <mat-option [value]="12">12</mat-option>
                    <mat-option [value]="24">24</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div formArrayName="emails" class="emails">
            <div class="email-inputs">
                <mat-form-field
                    *ngFor="let email of emails.controls; let i = index"
                    appearance="outline"
                    class="wide filter-select"
                    [class.active]="email.value">
                    <input matInput [formControl]="email" placeholder="Enter Email" />
                    <mat-icon
                        *ngIf="i !== 0"
                        matSuffix
                        fontSet="fas"
                        fontIcon="fa-times"
                        class="close-icon"
                        (click)="removeSystem(i)"></mat-icon>
                    <mat-error>
                        <span *ngIf="hasError(email, 'required')">An Email Is Required</span>
                        <span *ngIf="hasError(email, 'email')">Email is not in proper format</span>
                        <span *ngIf="hasError(email, 'pattern')">Email must consist of more than a space.</span>
                    </mat-error>
                </mat-form-field>
            </div>
            <button mat-button class="add-button" (click)="addEmails()">
                <mat-icon style="margin-right: 5px" fontSet="fas" fontIcon="fa-plus" inline></mat-icon>
                <span>Add Another Email Address</span>
            </button>
        </div>

        <div class="file-type-section">
            <span> Preferred file type: </span>
            <mat-radio-group [value]="fileType" (change)="fileType = $event.value">
                <mat-radio-button *ngFor="let type of fileTypes" [value]="type">{{ type.toString() | lowercase }}</mat-radio-button>
            </mat-radio-group>
        </div>
    </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-stroked-button matDialogClose class="close-button">Cancel</button>
    <button mat-flat-button (click)="sendData()" color="primary" [disabled]="!isFormValid()">Send Data</button>
</mat-dialog-actions>
