
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import { GenAdditionalInfo } from './GenAdditionalInfo';
import { GenEventMedia } from './GenEventMedia';
import { GenKeyword } from './GenKeyword';
import { GenSourceType } from './GenSourceType';
import * as moment from 'moment';

export class GenRecentEvent {

    id: number;
    additionalInfo: GenAdditionalInfo;
    collectionDate: moment.Moment;
    collectionTime: number;
    content: string;
    eventId: number;
    geom: Object;
    keywords: GenKeyword[];
    lat: number;
    location: string;
    lon: number;
    media: GenEventMedia[];
    newsDate: moment.Moment;
    source: number;
    sourceId: string;
    sourceType: GenSourceType;
    stamp: number;
    title: string;

    constructor();
    constructor( jsonObj: any );
    constructor( genRecentEvent: GenRecentEvent);
    constructor( id: number );

    constructor( input?: GenRecentEvent|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            if( input.additionalInfo ) {
              this.additionalInfo = new GenAdditionalInfo( input.additionalInfo );
            }
            if ( input.collectionDate && typeof input.collectionDate === 'string' ) {
                this.collectionDate = moment(input.collectionDate).utc();
            } else {
                this.collectionDate = input.collectionDate;
            }
            this.collectionTime = input.collectionTime; // int [number]
            this.content = input.content; // string [string]
            this.eventId = input.eventId; // int [number]
            if( input.geom ) {
              this.geom = new Object( input.geom );
            }
            if ( input.keywords && input.keywords.map ) { // list [GenKeyword]
                this.keywords = input.keywords.map( ( x: GenKeyword ) => {
                    return new GenKeyword( x );
                } );
            } else {
                this.keywords = input.keywords;
            }
            this.lat = input.lat; // float [number]
            this.location = input.location; // string [string]
            this.lon = input.lon; // float [number]
            if ( input.media && input.media.map ) { // list [GenEventMedia]
                this.media = input.media.map( ( x: GenEventMedia ) => {
                    return new GenEventMedia( x );
                } );
            } else {
                this.media = input.media;
            }
            if ( input.newsDate && typeof input.newsDate === 'string' ) {
                this.newsDate = moment(input.newsDate).utc();
            } else {
                this.newsDate = input.newsDate;
            }
            this.source = input.source; // int [number]
            this.sourceId = input.sourceId; // string [string]
            if( input.sourceType ) {
              this.sourceType = new GenSourceType( input.sourceType );
            }
            this.stamp = input.stamp; // int [number]
            this.title = input.title; // string [string]
       }
    }

    static jsonResponseToList(arr: any[]): GenRecentEvent[] {
        if (arr && arr.map) {
          return arr.map(a => new GenRecentEvent(a));
        } else {
          return [];
        }
    }
}

