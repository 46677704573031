import {OverlayContainer} from '@angular/cdk/overlay';
import {Component, ElementRef, ViewChild} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Feedback} from '../../classes/feedback';
import {HelpService} from '../../services/help.service';
import {BaseModal} from '../../../../classes/base-modal';

@Component({
    selector: 'eaglei-user-feedback',
    templateUrl: './user-feedback.component.html',
    styleUrls: ['./user-feedback.component.scss'],
})
export class UserFeedbackComponent extends BaseModal {
    // HTML Properties
    @ViewChild('content') content: ElementRef;

    // Modal Properties
    public readonly buttonText: string = 'Submit';
    private successText = 'Feedback Submitted';
    private failedText = 'Feedback Submission Failed';

    // Feedback Properties
    public feedback: Feedback = new Feedback();

    constructor(
        public dialogRef: MatDialogRef<UserFeedbackComponent>,
        private helpService: HelpService,
        private popup: MatSnackBar,
        private oc: OverlayContainer
    ) {
        super();
        // TODO remove this when redesign is finished
        this.oc.getContainerElement().classList.add('new-design');
    }

    /**
     * Submits feedback if all inputs are valid
     */
    public submitFeedback(): void {
        const me = this;
        if (me.checkInput()) {
            this.helpService.submitUserFeedback(this.feedback).subscribe(
                () => {
                    me.popup.open(me.successText, 'Close', {duration: 5000, panelClass: ['success']});
                    me.dialogRef.close();
                },
                () => {
                    me.popup.open(me.failedText, 'Close', {panelClass: ['failure']});
                }
            );
        } else {
            me.popup.open('Check required inputs', 'Close', {panelClass: ['failure']});
        }
    }

    /**
     * Checks if inputs are filled out
     */
    public checkInput(): boolean {
        return !!(
            this.feedback.feedbackType &&
            this.feedback.description &&
            this.feedback.description.trim().length > 0 &&
            this.feedback.summary &&
            this.feedback.summary.trim().length > 0
        );
    }

    afterInit() {}
}
