import {PercentPipe} from '@angular/common';
import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
// tslint:disable-next-line:no-implicit-dependencies
import {scaleLinear} from 'd3-scale';

import * as moment from 'moment';
import {filter, takeUntil} from 'rxjs/operators';
import {Report} from '../../../report/classes/report';
import {BaseWidget} from '../../classes/base-widget';
import {WidgetService} from '../../services/widget.service';
import {OngService} from '../../../../services/ong.service';
import {EagleiBaseChart} from 'frontend/src/app/classes/charts/base-chart';
import {ShortNumberPipe} from 'frontend/src/shared/pipes/short-number.pipe';
import {FileDownload} from '../../../../classes/file-download';
import {PopoverElement} from '../../../../classes/popover-element';
import {DataPoint} from '../../../../classes/data-point';
import {OilStorage} from '../../../../classes/ong/oil-storage';
import {storageWidget} from '../../../../../integrations/ong/classes/ong-attributions-text';
import {MatPaginator} from '@angular/material/paginator';

@Component({
    selector: 'eaglei-oil-storage-widget',
    templateUrl: './oil-storage-widget.component.html',
    styleUrls: ['./oil-storage-widget.component.scss'],
})
export class OilStorageWidgetComponent extends BaseWidget implements AfterViewInit {
    // Table Elements
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    // Chart Elements
    @ViewChild('chartTarget', {static: true}) chartTarget: ElementRef;
    @ViewChild('content', {static: true}) content: ElementRef;

    // Table Properties
    public readonly columnNames: string[] = ['region', 'amount', 'product', 'capacity'];
    public dataSource: MatTableDataSource<OilStorage>;

    // Chart Properties
    public baseChart = new EagleiBaseChart<any, any, any>();
    private readonly barColors;
    private percentPipe: PercentPipe = new PercentPipe('en_us');
    private shortNumberPipe: ShortNumberPipe = new ShortNumberPipe();

    // Table Filters
    public selectedStorageType: string;
    public storageTypes: string[] = [];
    public selectedDate: moment.Moment = moment();

    // Chart Filters
    public selectedChart: 'utilization' | 'amount' = 'utilization';
    constructor(private widgetElement: ElementRef, private ongService: OngService) {
        super(widgetElement);

        this.barColors = scaleLinear()
            .domain([0, 10])
            .range(['#005294', '#CBEBFC'] as any);
        this.attributionUrl = 'https://www.woodmac.com/';
    }

    public ngAfterViewInit(): void {
        this.getStorageData();
        this.initChart();
        this.setSubscriptions();
        this.attributionModalText = storageWidget;
        this.widgetName = 'Storage';
    }

    destroyWidget(): void {}

    private getStorageData(): void {
        this.ongService.getOilStorageData().subscribe((res) => {
            // Get Available Storage Types
            this.storageTypes = [];
            res.forEach((d) => {
                if (!this.storageTypes.includes(d.fieldType)) {
                    this.storageTypes.push(d.fieldType);
                }
            });

            if (!this.selectedStorageType) {
                this.selectedStorageType = this.storageTypes[0];
            }

            this.initData(res);
        });
    }

    private initData(data: OilStorage[]): void {
        if (this.dataSource) {
            this.dataSource.data = data;
        } else {
            this.dataSource = new MatTableDataSource(data);
            this.dataSource.paginator = this.paginator;
            this.dataSource.filterPredicate = this.filterPredicate.bind(this);
            this.dataSource.sortingDataAccessor = this.dataAccessor.bind(this);
            this.dataSource.sort = this.sort;
        }

        this.dataSource.filter = ' ';
        this.summarizeChartData();
        this.renderChart();
    }

    // noinspection JSMethodCanBeStatic
    private dataAccessor(data: OilStorage, header: string): number | string {
        switch (header) {
            case 'region':
                return data.region.toLowerCase();
            case 'amount':
                return data.amount;
            case 'product':
                return data.product.toLowerCase();
            case 'capacity':
                return data.capacityUtilization;
            default:
                return '';
        }
    }

    private filterPredicate(data: OilStorage): boolean {
        return data.fieldType === this.selectedStorageType;
    }

    private initChart(): void {
        this.baseChart.interactivePopover = false;
        this.baseChart.initializeEChart(this.chartTarget.nativeElement, true, 'Regions', this.getYAxisLabel());

        this.baseChart.getXAxis().axisLabel.rotate = 35;
        this.baseChart.getXAxis().axisLabel.fontSize = 10;
        this.baseChart.getXAxis().axisLabel.formatter = (label) => label;
        this.baseChart.getXAxis().nameTextStyle = {
            padding: [50, 0, 0, 0],
        };

        this.baseChart.getYAxis().axisLabel.formatter = (label) => {
            return this.selectedChart === 'utilization'
                ? this.percentPipe.transform(label / 100, '1.0-0')
                : this.shortNumberPipe.transform(label);
        };

        this.baseChart.getYAxis().nameTextStyle = {
            padding: [0, 0, 0, 15],
        };

        this.baseChart.getGrid().bottom = 85;
    }

    private summarizeChartData(): void {
        const isUtilizationChart: boolean = this.selectedChart === 'utilization';
        const filteredData = this.dataSource.filteredData;

        const getKey = (d: OilStorage) => `${d.region}-${d.product}`;

        const createDatapoint = (d: OilStorage) => {
            const xVal = this.isRegionDup(d.region) ? `${d.region}  \n(${d.product})` : d.region;
            return {
                x: xVal,
                y: 0,
                data: {
                    utilization: d.capacityUtilization,
                    amount: d.amount,
                },
            };
        };

        const updateDatapoint = (current: any, d: OilStorage) => {
            if (isUtilizationChart) {
                current.y = Math.max(current.data.utilization, d.capacityUtilization);
            } else {
                current.y += d.amount || 0;
            }
        };

        const dataMap = new Map<string, DataPoint>();

        filteredData.forEach((ele) => {
            const key = getKey(ele);

            let val = dataMap.get(key);

            if (!val) {
                val = createDatapoint(ele);
            }
            updateDatapoint(val, ele);

            dataMap.set(key, val);
        });

        this.baseChart.chartBounds.data = Array.from(dataMap.values());
        this.baseChart.chartBounds.rawData = filteredData;
    }

    private renderChart(): void {
        const isUtilizationChart: boolean = this.selectedChart === 'utilization';
        const xValues: string[] = [];
        const yValues: any[] = [];

        const getPopoverData = (bar: any) => {
            return [
                new PopoverElement('Title', bar.x),
                new PopoverElement(
                    !isUtilizationChart ? 'Amount of Barrels' : 'Capacity Utilization',
                    !isUtilizationChart
                        ? Report.numberPipe.transform(bar.y).toString()
                        : Report.percentPipe.transform(bar.y / 100, '1.2-2').toString()
                ),
            ];
        };

        this.baseChart.chartBounds.data.forEach((d, i) => {
            xValues.push(d.x);

            const yData = {
                value: d.y,
                itemStyle: {
                    color: this.barColors(i),
                },
                exportData: {
                    x: (d.x as string).replace('\n', ' '),
                    y: d.y,
                },
                popoverData: getPopoverData(d),
            };

            yValues.push(yData);
        });

        this.baseChart.eChartOptions.xAxis['data'] = xValues;
        this.baseChart.eChartOptions.series = [
            {
                type: 'bar',
                data: yValues,
            },
        ];

        this.baseChart.getYAxis().name = this.getYAxisLabel();

        this.baseChart.eChart.setOption(this.baseChart.eChartOptions);
    }

    public updateStorage(type: string): void {
        this.selectedStorageType = type;

        this.dataSource.filter = ' ';
        this.summarizeChartData();
        this.renderChart();
    }

    public updateDate(date: moment.Moment): void {
        this.selectedDate = moment(date);

        this.dataSource.filter = ' ';
        this.summarizeChartData();
        this.renderChart();
    }

    public updateGraphType(type: 'utilization' | 'amount'): void {
        this.selectedChart = type;
        this.initChart();
        this.summarizeChartData();
        this.renderChart();
    }

    public getChartTitle(): string {
        return this.selectedChart === 'utilization'
            ? `${this.selectedStorageType} Capacity Utilization`
            : `${this.selectedStorageType} Storage Amount`;
    }

    public getYAxisLabel(): string {
        return this.selectedChart === 'utilization' ? 'Capacity Utilization' : 'Amount of Barrels';
    }

    public isRegionDup(val: string): boolean {
        const regionName = this.dataSource.filteredData.map((d) => d.region);
        const count = regionName.reduce(
            (a, b) => ({
                ...a,
                [b]: (a[b] || 0) + 1,
            }),
            {}
        );

        const dups = Object.keys(count).filter((a) => count[a] > 1);

        return dups.includes(val);
    }

    private setSubscriptions(): void {
        WidgetService.resize
            .pipe(
                filter((widget) => this.item.x === widget.x && this.item.y === widget.y),
                takeUntil(this.destroy$)
            )
            .subscribe(() => {
                this.resize();

                const chartHeight = this.getHeight(this.content);
                this.setHeight(this.chartTarget, chartHeight);

                this.baseChart.eChart.resize();
            });
    }

    public exportTable(): void {
        let data: string = ['Region', 'Amount (bbls)', 'Product', 'Capacity Utilization'].join() + '\n';
        this.dataSource.filteredData.forEach((d) => {
            const values = [
                FileDownload.formatCsvCell(this.isRegionDup(d.region) ? `${d.region}  \n(${d.product})` : d.region),
                FileDownload.formatCsvCell(Report.numberPipe.transform(d.amount)),
                FileDownload.formatCsvCell(d.product),
                FileDownload.formatCsvCell(this.percentPipe.transform(d.capacityUtilization / 100, '1.2-2')),
            ];
            data += `${values.join()}\n`;
        });

        FileDownload.downloadCSV('oilStorageWidget', data, this.attributionUrl);
    }

    public exportChartAsCsv(): void {
        const isUtilizationChart: boolean = this.selectedChart === 'utilization';
        let data: string = ['Region', !isUtilizationChart ? 'Amount (bbls)' : 'Capacity Utilization'].join() + '\n';

        const getYValue = (d: any) => {
            if (isUtilizationChart) {
                return this.percentPipe.transform(d.exportData.y / 100, '1.2-2');
            } else {
                return Report.numberPipe.transform(d.exportData.y);
            }
        };

        this.baseChart.eChart.getOption().series.forEach((series) => {
            series.data.forEach((d) => {
                data += [FileDownload.formatCsvCell(d.exportData.x), FileDownload.formatCsvCell(getYValue(d))].join() + '\n';
            });
        });

        FileDownload.downloadCSV('oilStorageChart', data, this.attributionUrl);
    }

    public exportChart(): void {
        const title = this.getChartTitle();

        FileDownload.exportChartAsPNG(this.baseChart, title, title, this.attributionUrl);
    }
}
