import {LeafletFeature} from '../classes/leaflet-feature';
import {LeafletMapLayer} from '../classes/leaflet-map-layer';
import {LeafletVectorSource} from '../classes/leaflet-vector-source';
import * as L from 'leaflet';
import {LatLng, Layer} from 'leaflet';
import {LayerStyleService} from '../services/layer-style.service';
import {Nomination} from '../../../classes/ong/nomination';
import {PopoverElement} from '../../../classes/popover-element';
import {LeafletEagleiSource} from '../classes/leaflet-eaglei-source';
import {ClusterSource} from '../classes/cluster-source';

export class OngNominationsSource extends ClusterSource<Nomination> {
    constructor(layerInfo: LeafletMapLayer) {
        super(layerInfo);
    }

    processFeatures(features: Nomination[]): void {
        this.leafletFeatures = features.map((f) => {
            const feature = new LeafletFeature<Nomination>().convert(f).buildPopover(this.layerInfo);

            const data = [
                new PopoverElement().setTitle().setValue(feature.properties.location.name),
                new PopoverElement()
                    .setLabel('Available Capacity')
                    .setValue(LeafletEagleiSource.numberPipe.transform(feature.properties.available_capacity, '1.0-0') + ' (MMBTU)'),
                new PopoverElement()
                    .setLabel('Scheduled Capacity')
                    .setValue(LeafletEagleiSource.numberPipe.transform(feature.properties.scheduled_capacity, '1.0-0') + ' (MMBTU)'),
                new PopoverElement()
                    .setLabel('Operational Capacity')
                    .setValue(LeafletEagleiSource.numberPipe.transform(feature.properties.operational_capacity, '1.0-0') + ' (MMBTU)'),
                new PopoverElement()
                    .setLabel('Design Capacity')
                    .setValue(LeafletEagleiSource.numberPipe.transform(feature.properties.design_capacity, '1.0-0') + ' (MMBTU)'),
                new PopoverElement().setLabel('Best Flow Location').setValue(feature.properties.best_flow_location as any),
                new PopoverElement()
                    .setLabel('Measurement Date')
                    .setValue(LeafletEagleiSource.momentPipe.transform(feature.properties.gas_day)),
            ];

            feature.subFeatures = [
                {
                    id: feature.properties.id,
                    popoverData: data,
                },
            ];

            return feature;
        });

        const style = (feature: any, latlng: LatLng): Layer => {
            return L.marker(latlng, {
                icon: getStyle(feature),
            });
        };

        const config = {
            pointToLayer: style,
            onEachFeature: this.initializePopoverInteractions.bind(this),
        };

        const clusterConfig: L.MarkerClusterGroupOptions = {
            iconCreateFunction: getClusterStyle,
            chunkedLoading: true,
        };

        const cluster = new L.MarkerClusterGroup(clusterConfig);
        cluster.addLayer(L.geoJSON(this.leafletFeatures as any, config as any));

        this.source = cluster as any;
    }

    public clearMap(): void {
        if (this.source) {
            this.source.clearLayers();
        }
    }
}

function getStyle(feature: LeafletFeature<Nomination>): any {
    const status = feature.properties.utilization_status;
    const key = `ongNomination-${status}`;
    let style = LayerStyleService.layerStyles.get(key);

    if (!style) {
        let color = '#70CC27';

        if (status === 'low') {
            color = '#70CC27';
        } else if (status === 'medium') {
            color = '#F0E300';
        } else if (status === 'high') {
            color = '#A80800';
        }

        const options = {
            icon: 'circle',
            backgroundColor: color,
            borderColor: color,
            innerIconStyle: `color:${color}; position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); margin-top: 0px;`,
            iconSize: [10, 10],
        };

        style = (L as any).BeautifyIcon.icon(options);

        LayerStyleService.layerStyles.set(key, style);
    }
    return style;
}

function getClusterStyle(cluster: L.MarkerCluster): any {
    const children = cluster.getAllChildMarkers();

    let clusterStatus = 'unknown';
    const statusMap = children.map((c) => c.feature.properties.utilization_status);

    if (statusMap.every((s) => s === 'low')) {
        clusterStatus = 'low';
    } else if (statusMap.every((s) => s === 'medium')) {
        clusterStatus = 'medium';
    } else if (statusMap.every((s) => s === 'high')) {
        clusterStatus = 'high';
    }

    let color = '#707070';
    if (clusterStatus === 'low') {
        color = '#70CC27';
    } else if (clusterStatus === 'medium') {
        color = '#F0E300';
    } else if (clusterStatus === 'high') {
        color = '#A80800';
    }

    const options = {
        icon: 'circle',
        backgroundColor: color,
        borderColor: color,
        innerIconStyle: `position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); margin-top: 0px; text`,
        iconSize: [30, 30],
        isAlphaNumericIcon: true,
        text: `${cluster.getChildCount()}`,
        textColor: 'white',
    };

    return (L as any).BeautifyIcon.icon(options);
}
