export class SummaryList {
    // The ID to group the data by
    public summaryId: number | string;

    // Information Numbers
    public coveredCustomers: number;
    public actualCustomers: number;
    public customersOut: number;
    public percentOut: number;
    public modelCount: number;
    public collectedCount: number;

    // Trend Properties
    public trendPercent: number;
    public trendLine: 'trending_up' | 'trending_down' | 'trending_flat' = 'trending_flat';

    // List of organized grouped data
    private readonly outageData: any[];

    get OutageData(): any[] {
        return this.outageData;
    }

    constructor(id: number | string, outages: any[]) {
        this.summaryId = id;
        this.outageData = outages;
    }

    /**
     * Adds given data to the outage data list
     * @param outage Outage to push the complete list of outage data
     */
    public addOutage(outage: any) {
        this.outageData.push(outage);
    }

    /**
     * Finalizes the list by sorting the data into descending order on time and creates the info numbers and trend line properities
     */
    public finalizeList(): void {
        this.coveredCustomers = this.outageData[0].coveredCustomers;
        this.actualCustomers = this.outageData[0].actualCustomers;
        this.customersOut = this.outageData[0].endCount;
        this.modelCount = this.outageData[0].modelCount;
        this.collectedCount = this.outageData[0].collectedCount;
        this.percentOut = !!this.coveredCustomers ? this.customersOut / this.coveredCustomers : undefined;

        const pervCustomersOut = this.outageData[0].startCount;

        const trendChange = pervCustomersOut - this.customersOut;

        this.trendPercent = Math.abs(trendChange);

        if (trendChange < 0) {
            this.trendLine = 'trending_up';
        } else if (trendChange > 0) {
            this.trendLine = 'trending_down';
        } else {
            this.trendLine = 'trending_flat';
        }
    }
}
