import {GenOutageAggregationLevel} from 'frontend/generated/serverModels/GenOutageAggregationLevel';
import {ApplicationConfig} from 'frontend/src/app/classes/application-config';
import {PopoverElement} from 'frontend/src/app/classes/popover-element';
import * as L from 'leaflet';
import {Report} from '../../report/classes/report';
import {LeafletFeature} from '../classes/leaflet-feature';
import {LeafletMapLayer} from '../classes/leaflet-map-layer';
import {LeafletVectorSource} from '../classes/leaflet-vector-source';
import {LayerStyleService} from '../services/layer-style.service';
import {MapService} from '../../map/services/map.service';
import {HssData} from '../../report/classes/HssData';
import {State} from '../../outage/classes/state';
import {DataService} from '../../../services/data.service';

export class EmPowerSource extends LeafletVectorSource {
    public readonly COUNTY_MASK_TEXT = 'County data is only selectable by FEMA region';
    public readonly ZIP_MASK_TEXT = 'Zip Code data is only selectable by a single state.';

    constructor(layerInfo: LeafletMapLayer) {
        super(layerInfo);

        this.layerInfo.attributionUrl = 'https://geohealth.hhs.gov/dataaccess/rest/services/HHS_emPOWER_REST_Service_Public/MapServer';
    }

    processFeatures(features: any[], aggLevel?: GenOutageAggregationLevel): void {
        const geometries = ApplicationConfig.geometries.get(aggLevel);

        features?.forEach((f: {properties: HssData; geometry: any; type: 'Feature'}) => {
            // The dataset has the county named something different with a different fips code as well. Just for this one county so far
            if (aggLevel === GenOutageAggregationLevel.county && f.properties.fipsCode === '46113') {
                f.properties.fipsCode = '46102';
            }

            if (aggLevel !== GenOutageAggregationLevel.zip) {
                const boundary = geometries.find((g) => g.fipsCode === f.properties.fipsCode)?.boundary;

                if (boundary) {
                    f.geometry = JSON.parse(boundary);
                }
            }
        });

        this.leafletFeatures = features
            ?.filter((f) => !!f.geometry)
            .map((f) => {
                const feature = new LeafletFeature<HssData>(f);

                const data = [
                    new PopoverElement().setTitle().setValue(feature.properties.name),
                    new PopoverElement()
                        .setLabel('Electricity-Dependent Beneficiaries')
                        .setValue(Report.numberPipe.transform(feature.properties.energyDependant).toString()),
                    new PopoverElement()
                        .setLabel('Total Beneficiaries')
                        .setValue(Report.numberPipe.transform(feature.properties.beneficiaries).toString()),
                ];

                feature.popoverData = data;
                feature.subFeatures = [
                    {
                        id: feature.properties.fipsCode,
                        popoverData: data,
                    },
                ];

                return feature;
            });

        const featureStyle = (feature: LeafletFeature<HssData>) => {
            const colorSettings = this.getDependentColor(feature, aggLevel);
            const key = `emPower-${aggLevel}-${colorSettings.color}`;
            let style = LayerStyleService.layerStyles.get(key);

            if (!style) {
                style = {
                    stroke: true,
                    color: '#6E6E6E',
                    weight: 0.5,
                    fill: true,
                    fillColor: colorSettings.color,
                } as any;

                LayerStyleService.layerStyles.set(key, style);
            }

            return style;
        };

        const layerPopover = (f: LeafletFeature, l) => {
            this.initializePopoverInteractions(f, l, 'click', false, f.popoverData);
        };

        const config = {
            pane: MapService.layerPaneName,
            style: featureStyle,
            onEachFeature: layerPopover,
            features: this.leafletFeatures,
            smoothFactor: 0.8,
        };

        this.source = L.geoJSON(this.leafletFeatures as any, config as any);
    }

    // noinspection JSMethodCanBeStatic
    private getDependentColor(feature: LeafletFeature<HssData>, aggLevel: GenOutageAggregationLevel): any {
        const dependent = feature.properties.energyDependant;

        if (aggLevel === GenOutageAggregationLevel.county) {
            if (!(dependent > 0)) {
                return {color: '#F0ECAA'};
            } else if (dependent >= 1 && dependent <= 255) {
                return {color: '#C9BC87'};
            } else if (dependent >= 256 && dependent <= 976) {
                return {color: '#A69165'};
            } else if (dependent >= 977 && dependent <= 7571) {
                return {color: '#856B49'};
            } else if (dependent >= 7572) {
                return {color: '#664830'};
            }
        } else if (aggLevel === GenOutageAggregationLevel.state) {
            if (dependent > 26 && dependent < 20553) {
                return {color: '#F0ECAA'};
            } else if (dependent >= 20554 && dependent <= 45704) {
                return {color: '#C9BC87'};
            } else if (dependent >= 45705 && dependent <= 77199) {
                return {color: '#A69165'};
            } else if (dependent >= 77200 && dependent <= 122538) {
                return {color: '#856B49'};
            } else if (dependent >= 122539) {
                return {color: '#664830'};
            }
        } else if (aggLevel === GenOutageAggregationLevel.zip) {
            if (!(dependent > 0)) {
                return {color: '#F0ECAA'};
            } else if (dependent >= 1 && dependent <= 133) {
                return {color: '#C9BC87'};
            } else if (dependent >= 134 && dependent <= 237) {
                return {color: '#A69165'};
            } else if (dependent >= 238 && dependent <= 396) {
                return {color: '#856B49'};
            } else if (dependent >= 397) {
                return {color: '#664830'};
            }
        }

        return {color: '#f0ecaa'};
    }

    public buildUrl(aggregation: GenOutageAggregationLevel, states: State[]): string {
        let layerId: number;
        switch (aggregation) {
            case GenOutageAggregationLevel.zip:
                layerId = 1;
                break;
            case GenOutageAggregationLevel.county:
                layerId = 2;
                break;
            case GenOutageAggregationLevel.state:
                layerId = 3;
                break;
        }

        if (!layerId) {
            console.error(`${aggregation.toString()} is not a valid Aggregation level`);
            return;
        }

        const stateQuery: string = states.map((s) => `State='${s.abbreviation}'`).join(' OR ');

        const urlOptions = {
            // If there are no states selected the stateQuery will be empty. In that case we set the where clause to something that will always return false
            where: !stateQuery ? '1=2' : stateQuery,
            outFields: '*',
            returnGeometry: aggregation === GenOutageAggregationLevel.zip,
            returnExceededLimitFeatures: true,
            f: 'geojson',
        };

        const query = Object.keys(urlOptions)
            .map((key) => `${key}=${urlOptions[key]}`)
            .join('&');

        return `${this.layerInfo.serviceurl}/${layerId}/query?${query}`;
    }

    // This function is also used in the NOM layer. we may want to look at abstracting to a function
    public fitToFeatures(): void {
        // get features
        const layers = this.source.getLayers();
        const states = new Set<string>();
        layers.forEach((layer: any) => states.add(layer.feature.properties.state));
        let zoomTo: any[] = [];
        // if all go to conus
        if (DataService.states.getValue().length === states.size) {
            zoomTo = layers.filter((layer: any) => {
                const feature: LeafletFeature<HssData> = layer.feature;
                return !DataService.nonConusStateAbbreviations.includes(feature.properties.state);
            });
        } else if (states.size === 1 && states.has('AK')) {
            // if only ak zoom to main land
            (this.mapRef || MapService.mapRef).fitBounds(MapService.alaskaBounds);
            return;
        } else if (states.size > 1 && (states.has('AK') || states.has('HI') || states.has('AS') || states.has('GU') || states.has('MP'))) {
            // if more states and contains bounds over IDL - zoom to conus
            zoomTo = layers.filter((layer: any) => {
                const feature: LeafletFeature<HssData> = layer.feature;
                return !DataService.nonConusStateAbbreviations.includes(feature.properties.state);
            });
        } else {
            // if not, go to features
            zoomTo = layers;
        }

        if (zoomTo.length > 0) {
            const group = new L.FeatureGroup(zoomTo);
            (this.mapRef || MapService.mapRef).fitBounds(group.getBounds());
        }
    }
}
