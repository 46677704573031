
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import { GenRRCCContact } from './GenRRCCContact';

export class GenRRCCDesk {

    id: number;
    contactInfo: GenRRCCContact[];
    description: string;
    deskNumber: number;
    locationId: number;

    constructor();
    constructor( jsonObj: any );
    constructor( genRRCCDesk: GenRRCCDesk);
    constructor( id: number );

    constructor( input?: GenRRCCDesk|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            if ( input.contactInfo && input.contactInfo.map ) { // list [GenRRCCContact]
                this.contactInfo = input.contactInfo.map( ( x: GenRRCCContact ) => {
                    return new GenRRCCContact( x );
                } );
            } else {
                this.contactInfo = input.contactInfo;
            }
            this.description = input.description; // string [string]
            this.deskNumber = input.deskNumber; // int [number]
            this.locationId = input.locationId; // int [number]
       }
    }

    static jsonResponseToList(arr: any[]): GenRRCCDesk[] {
        if (arr && arr.map) {
          return arr.map(a => new GenRRCCDesk(a));
        } else {
          return [];
        }
    }
}

