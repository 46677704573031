<div>
    <form [formGroup]="addressForm">
        <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Address</mat-label>
            <input matInput formControlName="address" placeholder="ex: 123 State St, NY" />
            <mat-hint *ngIf="errMessage.length > 0" class="warn">{{ errMessage }}</mat-hint>
        </mat-form-field>

        <button mat-flat-button color="primary" [disabled]="addressForm.invalid || (loading | async)" (click)="submitAddress()">
            Search
        </button>
    </form>
</div>
