import {LeafletVectorSource} from '../classes/leaflet-vector-source';
import {LeafletMapLayer} from '../classes/leaflet-map-layer';
import {PlanetsenseLocation} from '../classes/planetsense-location';
import {LeafletFeature} from '../classes/leaflet-feature';
import * as L from 'leaflet';
import {MapService} from '../../map/services/map.service';
import {LayerStyleService} from '../services/layer-style.service';
import {FeatureStyle} from '../interfaces/style.interface';
import {State} from '../../outage/classes/state';
import {PopoverElement} from '../../../classes/popover-element';
import {MomentDatePipe} from '../../../../shared/pipes/moment-date.pipe';

export class SocialMediaSource extends LeafletVectorSource {
    private filterMap = new Map<string, (feature: LeafletFeature<PlanetsenseLocation>) => boolean>();
    private momentPipe = new MomentDatePipe();

    constructor(layerInfo: LeafletMapLayer) {
        super(layerInfo);
    }

    public processFeatures(features: PlanetsenseLocation[]): void {
        this.leafletFeatures = features
            .map((f) => {
                const feature = new LeafletFeature<PlanetsenseLocation>().convert(f);

                if (feature) {
                    feature.subFeatures = feature?.properties?.tweets?.map((tweetInfo) => {
                        const data = [
                            new PopoverElement().setTitle().setValue(feature.properties.utilityName),
                            new PopoverElement().setLabel('Twitter Handle').setValue(tweetInfo.twitterName),
                            new PopoverElement().setLabel('Created').setValue(this.momentPipe.transform(tweetInfo.createdAt)),
                            new PopoverElement().setLabel('Full Tweet').setValue(tweetInfo.fullTweet || 'N/A'),
                        ];

                        return {
                            id: tweetInfo.tweetId,
                            popoverData: data,
                        };
                    });
                }

                return feature;
            })
            .filter((feature) => !!feature);

        const style = (feature: any, latlng: L.LatLng): L.Layer => {
            return L.circleMarker(latlng, this.getStyle(feature));
        };

        const layerPopover = (feature: LeafletFeature, layer: L.Layer) => {
            this.initializePopoverInteractions(feature, layer, 'click', false);
        };

        const config: any = {
            pane: MapService.layerPaneName,
            pointToLayer: style,
            onEachFeature: layerPopover,
            filter: (feature: any) => {
                if (this.filterMap.size > 0) {
                    return Array.from(this.filterMap.values()).every((func) => func(feature));
                }
                return true;
            },
        };

        this.source = L.geoJSON(this.leafletFeatures as any, config);
    }

    private getStyle(feature: LeafletFeature<PlanetsenseLocation>): any {
        const key = this.layerInfo.uiHandle;
        let style = LayerStyleService.layerStyles.get(key);

        const color = feature.properties.tweets.length > 0 ? '#0288F3' : '#A6A6A6';

        if (!style) {
            style = {
                radius: 4,
                fillColor: color,
                color: '#696969',
                weight: 1,
                opacity: 1,
                fillOpacity: 0.8,
            };
        }
        LayerStyleService.layerStyles.set(key, style);
        return style;
    }

    public updateLocationFilter(states: State[]): void {
        const locationFilterForSource = (f: LeafletFeature<PlanetsenseLocation>): boolean => {
            return states.map((s) => s.id).includes(f.properties.stateId);
        };

        this.updateFilter('location', locationFilterForSource);
    }

    private updateFilter(type: string, ff: (f: any) => boolean) {
        this.filterMap.set(type, ff);

        if (this.source) {
            const jsonObj = {
                type: 'FeatureCollection',
                features: this.leafletFeatures,
            };

            this.source.clearLayers();
            this.source.addData(jsonObj as any);
            this.changeOpacity(this.layerInfo.opacity);
        }
    }
}
