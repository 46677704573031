import {AfterViewInit, Component, ElementRef} from '@angular/core';
import {GenWidgetSettingType} from 'frontend/generated/serverModels/GenWidgetSettingType';
import {LandingFilters} from 'frontend/src/app/landing/classes/landing-filters';
import {HttpInterceptorService} from 'frontend/src/app/services/http-interceptor.service';
import * as moment from 'moment';
import {filter, takeUntil} from 'rxjs/operators';
import {BaseWidget} from '../../../classes/base-widget';
import {WidgetService} from '../../../services/widget.service';
import {EventSummaryFilters} from 'frontend/src/app/modules/system-event/classes/event-summary-filters';
import {SystemEvent} from 'frontend/src/app/modules/system-event/classes/system-event';
import {LockTimeService} from '../../../../../services/lock-time.service';
import {ApplicationConfig} from '../../../../../classes/application-config';

enum ImpactedType {
    CUSTOMERS = 'customers',
    UTILITY = 'utility',
    COUNTY = 'county',
}

@Component({
    selector: 'eaglei-stat-widget',
    templateUrl: './stat-widget.component.html',
    styleUrls: ['./stat-widget.component.scss'],
})
export class StatWidgetComponent extends BaseWidget implements AfterViewInit {
    // Stat Options
    private type: ImpactedType = ImpactedType.UTILITY;
    public unknownStatType: boolean;
    public maxStat: boolean = false;

    // Landing Page Filters
    private filters: LandingFilters = new LandingFilters().setDate(ApplicationConfig.roundMinute());
    private event: SystemEvent;

    // Loading Toggle
    public isLoading = false;

    // Information Numbers
    public statImpacted = 0;
    public trendPercent: number;
    public trendLine: 'trending_up' | 'trending_down' | 'trending_flat' = 'trending_flat';

    constructor(private widgetElement: ElementRef, private widgetService: WidgetService) {
        super(widgetElement, true);
    }

    protected handleEventFilterChange(eventFilters: EventSummaryFilters): void {
        this.filters = new LandingFilters();

        this.filters.date = moment(eventFilters.date);
        this.filters.locations = [...eventFilters.locations];

        this.event = eventFilters.event;

        setTimeout(() => {
            this.getImpactedStat();
        }, 500);
    }

    /**
     * This method is fired when the lock time service updates its value. The date for the data is then set to the new
     * locked time and the data is re-fetched
     */
    onApplicationTimeChange() {
        const currentTime = LockTimeService.currentApplicationTime.getValue();
        this.filters.date = currentTime ? currentTime : ApplicationConfig.roundMinute();
        this.getImpactedStat();
    }

    public ngAfterViewInit(): void {
        WidgetService.updatedSettings
            .pipe(
                filter((widget) => !!widget),
                takeUntil(this.destroy$)
            )
            .subscribe((widget) => {
                if (this.item.x === widget.x && this.item.y === widget.y) {
                    this.type = widget.detail.getSettingByType(GenWidgetSettingType.IMPACTED, ImpactedType.UTILITY).value;
                    this.maxStat = widget.detail.getSettingByType(GenWidgetSettingType.MAX_STAT, false).value;
                    this.unknownStatType = false;

                    this.getImpactedStat();
                }
            });

        this.type = this.item?.detail?.getSettingByType(GenWidgetSettingType.IMPACTED, ImpactedType.CUSTOMERS).value as any;
        this.maxStat = this.item?.detail?.getSettingByType(GenWidgetSettingType.MAX_STAT, false).value as any;

        WidgetService.renderWidget.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.filters.date = ApplicationConfig.roundMinute();
            this.getImpactedStat();
        });

        if (!this.eventDashboard) {
            this.getUserPreferences();
            this.filters.locations = this.userPreferences.getStates();
            this.getImpactedStat();
        }
    }

    destroyWidget(): void {}

    /**
     * Gets the Impacted Stat based on the Stat Type
     */
    private getImpactedStat(): void {
        if (!!this.maxStat && this.maxStat) {
            this.getMaxImpacted();
            return;
        }

        // NOTE: For all non max impacted stats:
        //  that /outagesummary trend returns the last completed outage counts before the end time given, so in order to get the outages at a given time,
        //   such as 04:00, you must add 15 minutes to the time passed to the API
        this.getImpacted();
    }

    private getImpacted(): void {
        this.isLoading = true;

        const aggregationLevel = this.type === ImpactedType.CUSTOMERS ? 'state' : this.type.valueOf();

        HttpInterceptorService.clearInterceptor(this.widgetInterceptorKey);

        HttpInterceptorService.pendingRequests[this.widgetInterceptorKey] = this.widgetService
            .getWidgetOutageSummary(aggregationLevel, this.filters.date)
            .subscribe((res) => {
                delete HttpInterceptorService.pendingRequests[this.widgetInterceptorKey];

                let startCountTotal = 0;
                let endCountTotal = 0;

                const filteredData = res.filter((trend) => this.filters.locations.map((s) => s.id).includes(trend.stateId));

                if (this.type === 'customers') {
                    filteredData.forEach((trend) => {
                        startCountTotal += trend.startCount;
                        endCountTotal += trend.endCount;
                    });
                } else {
                    endCountTotal = filteredData.filter((u) => u.endCount > 0).length;
                    startCountTotal = filteredData.filter((u) => u.startCount > 0).length;
                }

                this.statImpacted = endCountTotal;
                const prevImpacted = startCountTotal;

                const trendChange = prevImpacted - this.statImpacted;

                this.trendPercent = Math.abs(trendChange);

                if (trendChange < 0) {
                    this.trendLine = 'trending_up';
                } else if (trendChange > 0) {
                    this.trendLine = 'trending_down';
                } else {
                    this.trendLine = 'trending_flat';
                }

                this.isLoading = false;
            });
    }

    /**
     * Gets Max Impacted stat for Utility and Customers
     */
    private getMaxImpacted(): void {
        this.isLoading = true;

        if (this.filters.locations.length <= 0) {
            this.statImpacted = 0;
            this.isLoading = false;
            return;
        }

        HttpInterceptorService.clearInterceptor(this.widgetInterceptorKey);
        HttpInterceptorService.pendingRequests[this.widgetInterceptorKey] = this.widgetService
            .getWidgetMaxImpacted(this.event.id, this.filters.date, this.filters.locations)
            .subscribe((res) => {
                delete HttpInterceptorService.pendingRequests[this.widgetInterceptorKey];

                if (!res) {
                    this.statImpacted = 0;
                    this.isLoading = false;
                    return;
                }

                if (this.type === ImpactedType.UTILITY) {
                    this.statImpacted = res.maxUtilitiesAffected;
                } else if (this.type === ImpactedType.CUSTOMERS) {
                    this.statImpacted = res.maxOutages;
                }

                this.isLoading = false;
            });
    }

    /**
     * Gets the Impacted title based on type
     */
    public getImpactedTitle(): string {
        let retString = this.maxStat ? 'MAX ' : '';
        switch (this.type) {
            case ImpactedType.CUSTOMERS:
                retString += 'CUSTOMERS IMPACTED';
                break;
            case ImpactedType.UTILITY:
                retString += 'UTILITIES IMPACTED';
                break;
            case ImpactedType.COUNTY:
                retString += 'COUNTIES IMPACTED';
                break;
            default:
                retString += 'UNKNOWN STAT';
                break;
        }

        return retString;
    }
}
