import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {RRCCLocation} from '../../../management/classes/rrcc-location';
import {BaseWidget} from '../../classes/base-widget';
import {RRCCDeployment} from '../../../management/classes/rrcc-deployment';
import * as moment from 'moment';
import {ApplicationConfig} from 'frontend/src/app/classes/application-config';

@Component({
    selector: 'eaglei-rrcc-location-info-modal',
    templateUrl: './rrcc-location-info-modal.component.html',
    styleUrls: ['./rrcc-location-info-modal.component.scss'],
})
export class RrccLocationInfoModalComponent extends BaseWidget implements OnInit {
    public location: RRCCLocation;
    public deployments: RRCCDeployment[];

    constructor(public ref: MatDialogRef<RrccLocationInfoModalComponent>, @Inject(MAT_DIALOG_DATA) private data: any) {
        super();
        this.location = data.location;

        if (data.dateForDeployments !== undefined) {
            this.deployments = this.location.deployments.filter((d) =>
                moment(data.dateForDeployments).isBetween(moment(d.startTime), moment(d.endTime), 'day', '[]')
            );
        } else {
            this.deployments = this.location.deployments;
        }
    }

    ngOnInit(): void {}

    destroyWidget(): void {}

    public getShiftLabel(shiftId: number): string {
        switch (shiftId) {
            case 1:
                return 'AM';
            case 2:
                return 'PM';
            case 3:
                return 'All Day';
        }
    }

    public hasEllipsis(element: HTMLElement): boolean {
        return ApplicationConfig.hasEllipsis(element);
    }
}
