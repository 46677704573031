import {Component} from '@angular/core';
import {GenLegendType} from 'frontend/generated/serverModels/GenLegendType';
import {LeafletArcgisLayerComponent} from '../../leaflet-layers/leaflet-arcgis-layer/leaflet-arcgis-layer.component';
import {LayerService} from '../../services/layer.service';
import {LeafletLegendService} from '../../services/leaflet-legend.service';

@Component({
    selector: 'eaglei-fault-lines-layer',
    templateUrl: './fault-lines-layer.component.html',
    styleUrls: ['../layers.scss', './fault-lines-layer.component.scss'],
})
export class FaultLinesLayerComponent extends LeafletArcgisLayerComponent {
    public faultColors = [
        {
            hexcode: '#ff3a31',
            label: 'Historic (<150 years)',
        },
        {
            hexcode: '#ffb539',
            label: 'Latest Quaternary (<15,000 years)',
        },
        {
            hexcode: '#70ff42',
            label: 'Late Quaternary (<130,000 years)',
        },
        {
            hexcode: '#3135ff',
            label: 'Middle & Late Quaternary (<750,000 years)',
        },
        {
            hexcode: '#202020',
            label: 'Undifferentiated Quaternary (<1.6 million years)',
        },
        {
            hexcode: '#fdff43',
            label: 'Unspecified Age',
        },
        {
            hexcode: '#a8a8a8',
            label: 'Class B (various age)',
        },
    ];

    constructor(layerService: LayerService, legendService: LeafletLegendService) {
        super(legendService);
    }

    afterViewInit(): void {
        this.mapLayer.legend.type = GenLegendType.HARD_CODED;
    }
}
