
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import * as moment from 'moment';

export class GenOutageSnapshot {

    actualCustomers: number;
    collectedCount: number;
    countyFIPSCode: string;
    countyId: number;
    countyName: string;
    coveredCustomers: number;
    hasOverrideData: boolean;
    modelCount: number;
    outages: number;
    runStartTime: moment.Moment;
    stateAbbreviation: string;
    stateFIPSCode: string;
    stateId: number;
    stateName: string;

    constructor();
    constructor( jsonObj: any );
    constructor( genOutageSnapshot: GenOutageSnapshot);

    constructor( input?: GenOutageSnapshot ) {
        if ( input && typeof input !== 'number' ) {
            this.actualCustomers = input.actualCustomers; // int [number]
            this.collectedCount = input.collectedCount; // int [number]
            this.countyFIPSCode = input.countyFIPSCode; // string [string]
            this.countyId = input.countyId; // int [number]
            this.countyName = input.countyName; // string [string]
            this.coveredCustomers = input.coveredCustomers; // int [number]
            this.hasOverrideData = input.hasOverrideData; // boolean [boolean]
            this.modelCount = input.modelCount; // int [number]
            this.outages = input.outages; // int [number]
            if ( input.runStartTime && typeof input.runStartTime === 'string' ) {
                this.runStartTime = moment(input.runStartTime).utc();
            } else {
                this.runStartTime = input.runStartTime;
            }
            this.stateAbbreviation = input.stateAbbreviation; // string [string]
            this.stateFIPSCode = input.stateFIPSCode; // string [string]
            this.stateId = input.stateId; // int [number]
            this.stateName = input.stateName; // string [string]
       }
    }

    static jsonResponseToList(arr: any[]): GenOutageSnapshot[] {
        if (arr && arr.map) {
          return arr.map(a => new GenOutageSnapshot(a));
        } else {
          return [];
        }
    }
}

