import {GenSystemNotification} from '../../../generated/serverModels/GenSystemNotification';
import {GenNotificationType} from '../../../generated/serverModels/GenNotificationType';

export class SystemNotification extends GenSystemNotification {
    public readonly iconName: string;
    public readonly iconStyle: string = 'fas';

    constructor(notification?: SystemNotification) {
        super(notification);

        if (notification) {
            switch (this.type) {
                case GenNotificationType.SYSTEM_NEWS:
                    this.iconName = 'fa-bell';
                    this.iconStyle = 'far';
                    break;
                case GenNotificationType.COMMUNITY:
                    this.iconName = 'fa-user-shield';
                    break;
                case GenNotificationType.RECENT_EVENT:
                    this.iconName = 'fa-calendar';
                    break;
            }
        }
    }
}
