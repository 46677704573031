import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {Router} from '@angular/router';
import {GenOutageAggregationLevel} from 'frontend/generated/serverModels/GenOutageAggregationLevel';
import {FileDownload} from 'frontend/src/app/classes/file-download';
import {IReportNavigationConfig} from 'frontend/src/app/interfaces/report-navigation-config.interface';
import {HttpInterceptorService} from 'frontend/src/app/services/http-interceptor.service';
import {AggregationLevelPipe} from 'frontend/src/shared/pipes/aggregation-level.pipe';
import * as moment from 'moment';
import {EventSummaryFilters} from '../../../system-event/classes/event-summary-filters';
import {OutageRestoration} from '../../../report/classes/outage-restoration';
import {ReportService} from '../../../report/services/report.service';
import {BaseWidget} from '../../classes/base-widget';
import {WidgetService} from '../../services/widget.service';
import {ReportFilter} from '../../../report/enums/report-filter.enum';

@Component({
    selector: 'eaglei-restoration-widget',
    templateUrl: './restoration-widget.component.html',
    styleUrls: ['./restoration-widget.component.scss'],
})
export class RestorationWidgetComponent extends BaseWidget implements AfterViewInit {
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    public columnNames: string[] = [
        'state',
        'county',
        'outageCount',
        'restorationCount',
        'percentRestored',
        'trend',
        'maxOutage',
        'maxRestoration',
        'lastTimestamp',
    ];
    public dataSource: MatTableDataSource<OutageRestoration>;

    public filterBy: 'state' | 'county' = 'county';

    public eventFilters: EventSummaryFilters = new EventSummaryFilters();

    constructor(
        private widgetElement: ElementRef,
        private widgetService: WidgetService,
        private reportService: ReportService,
        private router: Router
    ) {
        super(widgetElement);
    }

    protected handleEventFilterChange(eventFilters: EventSummaryFilters): void {
        this.eventFilters = eventFilters;

        this.getRestorationData();
    }

    public ngAfterViewInit(): void {
        this.getRestorationData();
    }

    destroyWidget(): void {}

    private getRestorationData(): void {
        this.clearInterceptor();

        const filterList = this.eventFilters.locations.map((s) => s.abbreviation);
        const filterLevel = 'state';

        const endDate = moment(this.eventFilters.date).endOf('day').add(1, 'second');
        const startDate = moment(endDate).subtract(7, 'days').startOf('day');

        HttpInterceptorService.pendingRequests['restoration'] = this.reportService
            .getRestorationData(startDate, endDate, filterLevel, filterList, 6)
            .subscribe((res) => {
                delete HttpInterceptorService.pendingRequests['restoration'];

                const outageData: any[] = [];
                res.data.forEach((o) => moment(o.outageRunStartTime));
                res.data.forEach((d) => {
                    const findEvery =
                        this.filterBy === 'county'
                            ? (o) => o.every((f) => f.countyId === d.countyId)
                            : this.filterBy === 'state'
                            ? (o) => o.every((f) => f.state === d.state)
                            : (o) => o.every((f) => f.femaRegion === d.femaRegion);
                    if (outageData.some((o) => findEvery(o))) {
                        outageData.find((o) => findEvery(o)).push(d);
                    } else {
                        outageData.push([d]);
                    }
                });

                outageData.forEach((d, index) => {
                    outageData.splice(index, 1, new OutageRestoration(d));
                });
                this.initData(outageData);
            });
    }

    private initData(data: OutageRestoration[]): void {
        if (this.dataSource) {
            this.dataSource.data = data;
        } else {
            this.dataSource = new MatTableDataSource<OutageRestoration>(data);
            this.dataSource.filterPredicate = this.filterPredicate.bind(this);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sortingDataAccessor = this.dataAccessor.bind(this);
            this.dataSource.sort = this.sort;
        }

        this.dataSource.filter = ' ';
    }

    // noinspection JSMethodCanBeStatic
    private dataAccessor(data: OutageRestoration, header: string): string | number {
        switch (header) {
            case 'fema':
                return data.femaRegion;
            case 'state':
                return data.state;
            case 'county':
                return data.countyName;
            case 'outageCount':
                return data.outageCountTotal;
            case 'restorationCount':
                return data.restorationTotal;
            case 'percentRestored':
                return data.percentRestored;
            case 'maxOutage':
                return data.maxOutageCount;
            case 'maxRestoration':
                return data.maxRestorationCount;
            case 'lastTimestamp':
                return data.timestamp.valueOf();
            default:
                return '';
        }
    }

    private filterPredicate(data: OutageRestoration): boolean {
        return this.eventFilters.locations.map((s) => s.abbreviation).includes(data.state);
    }

    public updateLevel(level: 'state' | 'county'): void {
        this.filterBy = level;
        if (this.filterBy === 'state') {
            this.columnNames = [
                'state',
                'outageCount',
                'restorationCount',
                'percentRestored',
                'trend',
                'maxOutage',
                'maxRestoration',
                'lastTimestamp',
            ];
        } else {
            this.columnNames = [
                'state',
                'county',
                'outageCount',
                'restorationCount',
                'percentRestored',
                'trend',
                'maxOutage',
                'maxRestoration',
                'lastTimestamp',
            ];
        }

        this.getRestorationData();
    }

    public exportTable(): void {
        const aggPipe = new AggregationLevelPipe();
        let data = '';
        const headerColumns = this.columnNames.slice();

        data += headerColumns
            .map((val) => {
                val =
                    val === 'fema' || val === 'state'
                        ? aggPipe.transform(val === 'fema' ? GenOutageAggregationLevel.fema : GenOutageAggregationLevel.state)
                        : val.replace(/[A-Z]/g, ' $&');
                return val[0].toUpperCase() + val.slice(1);
            })
            .join();
        data += '\n';

        this.dataSource._orderData(this.dataSource.filteredData).forEach((val) => {
            const levelVal =
                this.filterBy === 'state'
                    ? FileDownload.formatCsvCell(val.state)
                    : `${FileDownload.formatCsvCell(val.state)},${FileDownload.formatCsvCell(val.countyName)}`;

            const values = [
                FileDownload.formatCsvCell(val.outageCountTotal),
                FileDownload.formatCsvCell(val.restorationTotal),
                FileDownload.formatCsvCell(val.percentRestored + '%'),
                FileDownload.formatCsvCell(val.trendIcon),
                FileDownload.formatCsvCell(val.maxOutageCount),
                FileDownload.formatCsvCell(val.maxRestorationCount),
                FileDownload.formatCsvCell(moment(val.timestamp).format('M/D/YYYY')),
            ];

            data += `${levelVal},${values.join()}`;
            data += '\n';
        });

        FileDownload.downloadCSV('restorationDataTable', data);
    }

    public goToReport(): void {
        const filters: IReportNavigationConfig[] = [
            {type: ReportFilter.AGGREGATION, value: this.filterBy},
            {type: ReportFilter.END_DATE, value: this.eventFilters.date.format()},
            {type: ReportFilter.STATE, value: this.eventFilters.locations},
        ];
        // noinspection JSIgnoredPromiseFromCall
        this.router.navigate(['app/reports/outagerestoration'], {state: {filters}});
    }

    public getCountyCustomerText(info: any): string {
        return !!info.coveredCustomers ? `(${info.modelCount > 0 ? 'Modeled' : 'Collected'})` : '';
    }

    public clearInterceptor(): void {
        if (HttpInterceptorService.pendingRequests['restoration']) {
            HttpInterceptorService.pendingRequests['restoration'].unsubscribe();
        }
    }
}
