export const femaLifelineTooltipInfo = {
    COMMUNICATIONS: 'Infrastructure, Responder Communications, Alerts Warnings and Messages, Finance, 911 and Dispatch',
    FOOD_WATER_SHELTER: 'Food, Water, Shelter, Agriculture',
    OTHER: 'Other',
    ENERGY: 'Power Grid, Fuel',
    SAFETY_AND_SECURITY: 'Law Enforcement/Security, Fire Service, Search and Rescue, Government Service, Community Safety',
    HEALTH_AND_MEDICAL: 'Medical Care, Public Health, Patient Movement, Medical Supply Chain, Fatality Management',
    TRANSPORTATION: 'Highway/Roadway/Motor Vehicle, Mass Transit, Railway, Aviation, Maritime',
    HAZARDOUS_MATERIAL: 'Facilities, HAZMAT, Pollutants, Contaminants',
};
