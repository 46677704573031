
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import * as moment from 'moment';

export class GenOutageDataCounty {

    actualCustomers: number;
    collectedCount: number;
    countyName: string;
    coveredCustomers: number;
    coveredCustomersType: string;
    customersOut: number;
    femaRegion: number;
    fipsCode: string;
    hasOverrideData: boolean;
    modelCount: number;
    stateAbbreviation: string;
    stateId: number;
    timeStamp: moment.Moment;
    utilityName: string;

    constructor();
    constructor( jsonObj: any );
    constructor( genOutageDataCounty: GenOutageDataCounty);

    constructor( input?: GenOutageDataCounty ) {
        if ( input && typeof input !== 'number' ) {
            this.actualCustomers = input.actualCustomers; // int [number]
            this.collectedCount = input.collectedCount; // int [number]
            this.countyName = input.countyName; // string [string]
            this.coveredCustomers = input.coveredCustomers; // int [number]
            this.coveredCustomersType = input.coveredCustomersType; // string [string]
            this.customersOut = input.customersOut; // int [number]
            this.femaRegion = input.femaRegion; // int [number]
            this.fipsCode = input.fipsCode; // string [string]
            this.hasOverrideData = input.hasOverrideData; // boolean [boolean]
            this.modelCount = input.modelCount; // int [number]
            this.stateAbbreviation = input.stateAbbreviation; // string [string]
            this.stateId = input.stateId; // int [number]
            if ( input.timeStamp && typeof input.timeStamp === 'string' ) {
                this.timeStamp = moment(input.timeStamp).utc();
            } else {
                this.timeStamp = input.timeStamp;
            }
            this.utilityName = input.utilityName; // string [string]
       }
    }

    static jsonResponseToList(arr: any[]): GenOutageDataCounty[] {
        if (arr && arr.map) {
          return arr.map(a => new GenOutageDataCounty(a));
        } else {
          return [];
        }
    }
}

