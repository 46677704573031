<header>
    <span>Tools</span>
</header>

<!--Search-->
<section>
    <header>SEARCH</header>

    <!--  Geometry Search-->
    <div class="expand-header clickable" [class.selected]="expanded.geom" (click)="toggleSection('geom')">
        <img src="/dist/images/icons/geometry-search.svg" alt="" />
        <span>Geometry Search</span>
        <mat-icon fontSet="fas" [fontIcon]="expanded.geom ? 'fa-chevron-up' : 'fa-chevron-down'" inline></mat-icon>
    </div>

    <div class="hide" [class.show]="expanded.geom">
        <div class="geometry-search">
            <div>
                <p class="label">Choose Shape</p>
                <mat-button-toggle-group [value]="selectedShape" (change)="updateDrawShape($event)" class="wide">
                    <mat-button-toggle value="polygon">Polygon</mat-button-toggle>
                    <mat-button-toggle value="circle">Circle</mat-button-toggle>
                </mat-button-toggle-group>
            </div>

            <p class="instruction-text">
                Draw a shape on the map over the area you would like to search. Search applies to the last element you drew.
            </p>

            <div class="action-buttons">
                <button
                    *ngIf="locationSearchService.hasSearchFeatures() || locationSearchService.drawing; else draw"
                    mat-stroked-button
                    (click)="clearGeometrySearch()">
                    Clear
                </button>
                <ng-template #draw>
                    <button color="primary" mat-flat-button (click)="enableGeometrySearch()">Draw Shape</button>
                </ng-template>

                <button mat-flat-button color="primary" [disabled]="!canSearchGeometry()" (click)="searchGeometry()">Search</button>
            </div>
        </div>
    </div>

    <!--  Buffer Search-->
    <div class="expand-header clickable" [class.selected]="expanded.buffer" (click)="toggleSection('buffer')">
        <img src="/dist/images/icons/buffer-search.svg" alt="" />
        <span>Buffer Search</span>
        <mat-icon fontSet="fas" [fontIcon]="expanded.buffer ? 'fa-chevron-up' : 'fa-chevron-down'" inline></mat-icon>
    </div>

    <div class="hide" [class.show]="expanded.buffer">
        <div class="geometry-search">
            <p class="instruction-text">Buffer Search lets you search active layers for features near your selected coordinates.</p>

            <form class="coordinate" [formGroup]="coordinateGroup">
                <mat-form-field>
                    <mat-label>Latitude</mat-label>
                    <input
                        matInput
                        type="text"
                        name="latitude"
                        eagleiNumberInput
                        [allowDecimal]="true"
                        [allowNegative]="true"
                        formControlName="latitude" />
                    <mat-hint>Values between +/-90</mat-hint>
                    <mat-error>Value must be between +/-90</mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Longitude</mat-label>
                    <input
                        matInput
                        type="text"
                        name="longitude"
                        eagleiNumberInput
                        [allowDecimal]="true"
                        [allowNegative]="true"
                        formControlName="longitude" />
                    <mat-hint>Values between +/-180</mat-hint>
                    <mat-error>Value must be between +/-180</mat-error>
                </mat-form-field>
            </form>

            <p class="instruction-text">Values in the contiguous US will have positive latitudes and negative longitudes.</p>

            <p class="instruction-text">
                If you do not know the exact coordinates you would like to search, click the locator toggle button and select a point on the
                map to search the surrounding area.
            </p>

            <div class="point-select" (click)="togglePointSelection()">
                <div class="location-icon" [class.selected]="locationSearchService.pointSelection">
                    <mat-icon fontSet="fas" fontIcon="fa-map-marker-alt" inline></mat-icon>
                </div>
                <span class="label normal-color">Toggle Lon/Lat Selection</span>
            </div>

            <mat-form-field class="wide">
                <mat-label>Search Within</mat-label>
                <mat-select [(ngModel)]="locationSearchService.bufferInMiles">
                    <mat-option [value]="10">10 miles</mat-option>
                    <mat-option [value]="25">25 miles</mat-option>
                    <mat-option [value]="50">50 miles</mat-option>
                    <mat-option [value]="100">100 miles</mat-option>
                </mat-select>
            </mat-form-field>

            <div class="action-buttons">
                <button mat-stroked-button (click)="clearBufferOptions()">Clear</button>
                <button mat-flat-button color="primary" [disabled]="!bufferSearchValid()" (click)="searchBuffer()">Search</button>
            </div>
        </div>
    </div>

    <!--  Utility Search-->
    <div class="expand-header clickable" [class.selected]="expanded.utility" (click)="toggleSection('utility')">
        <img src="/dist/images/layers/electric-icon.svg" alt="" />
        <span>Utility Search</span>
        <mat-icon fontSet="fas" [fontIcon]="expanded.utility ? 'fa-chevron-up' : 'fa-chevron-down'" inline></mat-icon>
    </div>

    <div class="hide" [class.show]="expanded.utility">
        <div class="geometry-search">
            <p class="instruction-text">
                Select a point on the map to see what utilities service that area, or manually search by Address or latitude/longitude.
            </p>

            <p class="instruction-text">Values in the contiguous US will have positive latitudes and negative longitudes.</p>

            <form class="coordinate" [formGroup]="coordinateGroup">
                <mat-form-field>
                    <mat-label>Latitude</mat-label>
                    <input
                        matInput
                        type="text"
                        name="latitude"
                        eagleiNumberInput
                        [allowDecimal]="true"
                        [allowNegative]="true"
                        formControlName="latitude" />
                    <mat-hint>Values between +/-90</mat-hint>
                    <mat-error>Value must be between +/-90</mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Longitude</mat-label>
                    <input
                        matInput
                        type="text"
                        name="longitude"
                        eagleiNumberInput
                        [allowDecimal]="true"
                        [allowNegative]="true"
                        formControlName="longitude" />
                    <mat-hint>Values between +/-180</mat-hint>
                    <mat-error>Value must be between +/-180</mat-error>
                </mat-form-field>
            </form>

            <p class="instruction-text">
                If you do not know the exact coordinates you would like to search, click the locator toggle button and select a point on the
                map to search the surrounding area.
            </p>

            <div class="point-select" (click)="togglePointSelection()">
                <div class="location-icon" [class.selected]="locationSearchService.pointSelection">
                    <mat-icon fontSet="fas" fontIcon="fa-map-marker-alt" inline></mat-icon>
                </div>
                <span class="label normal-color">Toggle Lon/Lat Selection</span>
            </div>

            <button class="wide" mat-flat-button color="primary" (click)="manualUtilitySearch()">Manually Search</button>
            <div class="action-buttons">
                <button mat-stroked-button (click)="clearBufferOptions()">Clear</button>
                <button mat-flat-button color="primary" [disabled]="!bufferSearchValid()" (click)="getUtilityInformation()">Search</button>
            </div>
        </div>
    </div>

    <!-- ESRI Routing -->
    <div class="expand-header clickable" [class.selected]="expanded.routing" (click)="toggleSection('routing')">
        <mat-icon class="icon-override" fontSet="fas" fontIcon="fa-route" inline></mat-icon>
        <span>ESRI Routing</span>
        <mat-icon fontSet="fas" [fontIcon]="expanded.utility ? 'fa-chevron-up' : 'fa-chevron-down'" inline></mat-icon>
    </div>

    <div class="hide" [class.show]="expanded.routing">
        <div class="geometry-search">
            <p class="instruction-text">Select a Start and Stop Point on the map to get a route between the points</p>

            <p class="label">Start Point</p>
            <form class="coordinate" [formGroup]="routingcoordinateGroup.get('start')">
                <mat-form-field>
                    <mat-label>Latitude</mat-label>
                    <input
                        matInput
                        type="text"
                        name="latitude"
                        eagleiNumberInput
                        [allowDecimal]="true"
                        [allowNegative]="true"
                        formControlName="latitude" />
                    <mat-hint>Values between +/-90</mat-hint>
                    <mat-error>Value must be between +/-90</mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Longitude</mat-label>
                    <input
                        matInput
                        type="text"
                        name="longitude"
                        eagleiNumberInput
                        [allowDecimal]="true"
                        [allowNegative]="true"
                        formControlName="longitude" />
                    <mat-hint>Values between +/-180</mat-hint>
                    <mat-error>Value must be between +/-180</mat-error>
                </mat-form-field>
            </form>

            <div class="point-select" (click)="togglePointSelection(true)">
                <div class="location-icon small" [class.selected]="locationSearchService.pointSelection && isSelectingStart">
                    <mat-icon fontSet="fas" fontIcon="fa-map-marker-alt" inline></mat-icon>
                </div>

                <span class="label normal-color">Start Point Lon/Lat Selection</span>
            </div>

            <p class="label">Stop Point</p>
            <form class="coordinate" [formGroup]="routingcoordinateGroup.get('stop')">
                <mat-form-field>
                    <mat-label>Latitude</mat-label>
                    <input
                        matInput
                        type="text"
                        name="latitude"
                        eagleiNumberInput
                        [allowDecimal]="true"
                        [allowNegative]="true"
                        formControlName="latitude" />
                    <mat-hint>Values between +/-90</mat-hint>
                    <mat-error>Value must be between +/-90</mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Longitude</mat-label>
                    <input
                        matInput
                        type="text"
                        name="longitude"
                        eagleiNumberInput
                        [allowDecimal]="true"
                        [allowNegative]="true"
                        formControlName="longitude" />
                    <mat-hint>Values between +/-180</mat-hint>
                    <mat-error>Value must be between +/-180</mat-error>
                </mat-form-field>
            </form>

            <div class="point-select" (click)="togglePointSelection(false)">
                <div class="location-icon small" [class.selected]="locationSearchService.pointSelection && !isSelectingStart">
                    <mat-icon fontSet="fas" fontIcon="fa-map-marker-alt" inline></mat-icon>
                </div>

                <span class="label normal-color">Stop Point Lon/Lat Selection</span>
            </div>

            <p class="instruction-text">
                You can add an obstacle to have the routing avoid. Use the drawing tools below to add the obstacle.
            </p>

            <p class="instruction-text">
                NOTE: Obstacles have a limit on how many objects can be in them, limiting to 2000 objects. This includes roads and
                buildings.
            </p>

            <div>
                <p class="label">Choose Shape</p>
                <mat-button-toggle-group [value]="selectedShape" (change)="updateDrawShape($event)" class="wide">
                    <mat-button-toggle value="polygon">Polygon</mat-button-toggle>
                    <mat-button-toggle value="circle">Circle</mat-button-toggle>
                </mat-button-toggle-group>
            </div>

            <div class="action-buttons">
                <button
                    *ngIf="locationSearchService.hasSearchFeatures() || locationSearchService.drawing; else draw"
                    mat-stroked-button
                    (click)="clearGeometrySearch()">
                    Clear Shape
                </button>
                <ng-template #draw>
                    <button color="primary" mat-flat-button (click)="enableGeometrySearch()">Draw Shape</button>
                </ng-template>
            </div>

            <div class="action-buttons">
                <button mat-stroked-button (click)="clearRouteSelections()">Clear Route</button>

                <button mat-flat-button color="primary" [disabled]="!canDrawRoute() || searchingRoute" (click)="findRoute()">
                    {{ searchingRoute ? 'Loading...' : 'Search' }}
                </button>
            </div>

            <div *ngIf="!!errorMessage">
                <p class="error">There was an error in finding the route.</p>
                <p class="error info">{{ errorMessage }}</p>
            </div>
        </div>
    </div>
</section>
