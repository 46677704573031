import {Component, OnDestroy, OnInit} from '@angular/core';
import {MapService} from '../../../map/services/map.service';

interface BaseLayerInfo {
    displayName: string;
    layerName: string;
    icon: string;
    selected: boolean;
}

@Component({
    selector: 'eaglei-base-layer',
    templateUrl: './base-layer.component.html',
    styleUrls: ['./base-layer.component.scss'],
})
export class BaseLayerComponent implements OnInit, OnDestroy {
    constructor(private mapService: MapService) {}

    public baseLayers: BaseLayerInfo[] = [
        {displayName: 'Topographic', layerName: 'Topographic', icon: 'dist/images/base-layers/topo.png', selected: true},
        {displayName: 'Dark Gray', layerName: 'DarkGray', icon: 'dist/images/base-layers/dark-gray.png', selected: false},
        {
            displayName: 'Imagery',
            layerName: 'Imagery,ImageryLabels',
            icon: 'dist/images/base-layers/imagery.png',
            selected: false,
        },
        {displayName: 'Streets', layerName: 'Streets', icon: 'dist/images/base-layers/street.png', selected: false},
    ];

    ngOnInit() {}

    ngOnDestroy(): void {}

    /**
     * Changes the base layer by emitting.
     * @param base The layer being changed.
     */
    public changeBaseLayer(base: BaseLayerInfo) {
        this.baseLayers.forEach((layer) => (layer.selected = false));
        base.selected = true;

        this.mapService.baseLayer.next(base.layerName);
    }
}
