import {GenReportRequestParameter} from '../../../generated/serverModels/GenReportRequestParameter';

export class ReportRequestParameter extends GenReportRequestParameter {
    constructor(parameter?: ReportRequestParameter) {
        super(parameter);
    }

    public setName(name: string): ReportRequestParameter {
        this.name = name;
        return this;
    }

    public setValue(value: any): ReportRequestParameter {
        this.value = value;
        return this;
    }
}
