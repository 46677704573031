import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {LoginRoutingModule} from './login-routing.module';
import {LoginFormComponent} from './components/login-form/login-form.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SharedModule} from '../../shared/shared.module';
import {LoginLayoutComponent} from './components/login-layout/login-layout.component';
import {AccountRequestComponent} from './components/account-request/account-request.component';
import {ChangePasswordComponent} from './components/change-password/change-password.component';
import {ResetPasswordComponent} from './components/reset-password/reset-password.component';
import {VerifyEmailComponent} from './components/verify-email/verify-email.component';
import {AccountReactivationComponent} from './components/account-reactivation/account-reactivation.component';
import {A11yModule} from '@angular/cdk/a11y';

@NgModule({
    declarations: [
        LoginFormComponent,
        LoginLayoutComponent,
        AccountRequestComponent,
        ChangePasswordComponent,
        ResetPasswordComponent,
        VerifyEmailComponent,
        AccountReactivationComponent,
    ],
    imports: [A11yModule, BrowserAnimationsModule, CommonModule, LoginRoutingModule, SharedModule],
})
export class LoginModule {}
