import {Component, ElementRef, AfterViewInit} from '@angular/core';
import {Router} from '@angular/router';
import {IReportNavigationConfig} from 'frontend/src/app/interfaces/report-navigation-config.interface';
import {ReportFilter} from '../../../report/enums/report-filter.enum';
import {BaseWidget} from '../../classes/base-widget';
import {WidgetService} from '../../services/widget.service';
import {OngService} from '../../../../services/ong.service';
import {EventSummaryFilters} from '../../../system-event/classes/event-summary-filters';
import {receiptDeliveryPointsOverCapacity} from '../../../../../integrations/ong/classes/ong-attributions-text';

// import {ApplicationConfig} from '../../../../classes/application-config';

@Component({
    selector: 'eaglei-ng-receipt-delivery-widget',
    templateUrl: './ng-receipt-delivery-widget.component.html',
    styleUrls: ['./ng-receipt-delivery-widget.component.scss'],
})
export class NgReceiptDeliveryWidgetComponent extends BaseWidget implements AfterViewInit {
    public points: number = 0;

    private filters: EventSummaryFilters;

    constructor(
        private widgetElement: ElementRef,
        private widgetService: WidgetService,
        private ongService: OngService,
        private router: Router
    ) {
        super(widgetElement);
    }

    ngAfterViewInit(): void {
        if (!this.eventDashboard) {
            this.filters = new EventSummaryFilters();
            this.filters.locations = this.userPreferences.getStates();
            this.getNgReceiptDelivery();
        }
        this.attributionModalText = receiptDeliveryPointsOverCapacity;
        this.widgetName = 'Receipt/Delivery Points';
    }

    protected handleEventFilterChange(eventFilters: EventSummaryFilters) {
        this.filters = eventFilters;
        this.getNgReceiptDelivery();
    }

    private getNgReceiptDelivery() {
        const locations = this.filters.locations;
        const endDate = this.filters?.date;
        this.ongService.getNgReceiptDelivery(locations, endDate).subscribe((res) => {
            this.points = res.count;
        });
    }

    destroyWidget(): void {}

    public viewReport() {
        const filters: IReportNavigationConfig[] = [
            {type: ReportFilter.CAPACITY, value: 'over'},
            {type: ReportFilter.STATE, value: this.filters.locations},
            {type: ReportFilter.START_DATE, value: this.filters.date.valueOf()},
        ];
        // noinspection JSIgnoredPromiseFromCall
        this.router.navigate(['app/reports/nominations'], {state: {filters}});
    }
}
