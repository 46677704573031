
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import * as moment from 'moment';

export class GenOutageDataUtility {

    actualCustomers: number;
    countyId: number;
    countyName: string;
    coveredCustomers: number;
    customersOut: number;
    eagleiId: number;
    eiaId: string;
    hasOverrideData: boolean;
    maxCustomersOut: number;
    parentUtilityName: string;
    stateAbbreviation: string;
    subUtilityId: number;
    subutilityName: string;
    timeStamp: moment.Moment;
    utilityAcronym: string;
    utilityName: string;

    constructor();
    constructor( jsonObj: any );
    constructor( genOutageDataUtility: GenOutageDataUtility);

    constructor( input?: GenOutageDataUtility ) {
        if ( input && typeof input !== 'number' ) {
            this.actualCustomers = input.actualCustomers; // int [number]
            this.countyId = input.countyId; // int [number]
            this.countyName = input.countyName; // string [string]
            this.coveredCustomers = input.coveredCustomers; // int [number]
            this.customersOut = input.customersOut; // int [number]
            this.eagleiId = input.eagleiId; // int [number]
            this.eiaId = input.eiaId; // string [string]
            this.hasOverrideData = input.hasOverrideData; // boolean [boolean]
            this.maxCustomersOut = input.maxCustomersOut; // int [number]
            this.parentUtilityName = input.parentUtilityName; // string [string]
            this.stateAbbreviation = input.stateAbbreviation; // string [string]
            this.subUtilityId = input.subUtilityId; // int [number]
            this.subutilityName = input.subutilityName; // string [string]
            if ( input.timeStamp && typeof input.timeStamp === 'string' ) {
                this.timeStamp = moment(input.timeStamp).utc();
            } else {
                this.timeStamp = input.timeStamp;
            }
            this.utilityAcronym = input.utilityAcronym; // string [string]
            this.utilityName = input.utilityName; // string [string]
       }
    }

    static jsonResponseToList(arr: any[]): GenOutageDataUtility[] {
        if (arr && arr.map) {
          return arr.map(a => new GenOutageDataUtility(a));
        } else {
          return [];
        }
    }
}

