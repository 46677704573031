import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {NavigationEnd, Router} from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class AppClassService {
    public cssClass = new BehaviorSubject<string>('');
    private setterURL: string | undefined;

    constructor(router: Router) {
        let currentRoute = '';
        const me = this;

        router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                currentRoute = event.urlAfterRedirects;
                if (me.setterURL && !me.setterURL.startsWith(currentRoute)) {
                    me.cssClass.next('');
                }
            }
        });
        this.cssClass.subscribe((str) => {
            me.setterURL = str.length ? currentRoute : undefined;
        });
    }
}
