
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import { GenCommunityUserStatus } from './GenCommunityUserStatus';

export class GenCommunityUser {

    accountEnabled: boolean;
    communityId: number;
    email: string;
    firstName: string;
    justification: string;
    lastName: string;
    manager: boolean;
    status: GenCommunityUserStatus;
    userId: number;
    username: string;

    constructor();
    constructor( jsonObj: any );
    constructor( genCommunityUser: GenCommunityUser);

    constructor( input?: GenCommunityUser ) {
        if ( input && typeof input !== 'number' ) {
            this.accountEnabled = input.accountEnabled; // boolean [boolean]
            this.communityId = input.communityId; // int [number]
            this.email = input.email; // string [string]
            this.firstName = input.firstName; // string [string]
            this.justification = input.justification; // string [string]
            this.lastName = input.lastName; // string [string]
            this.manager = input.manager; // boolean [boolean]
            this.status = (typeof input.status === 'string') ? GenCommunityUserStatus.forName(<string> input.status) : <any> input.status; // GenCommunityUserStatus
            this.userId = input.userId; // int [number]
            this.username = input.username; // string [string]
       }
    }

    static jsonResponseToList(arr: any[]): GenCommunityUser[] {
        if (arr && arr.map) {
          return arr.map(a => new GenCommunityUser(a));
        } else {
          return [];
        }
    }
}

