import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {BaseModal} from '../../../app/classes/base-modal';
import {MatSelectChange} from '@angular/material/select';
import {SystemEventService} from '../../../app/modules/system-event/services/system-event.service';
import {FileUpload} from '../../classes/file-upload';

interface ModalConfig {
    linkToEvents: boolean;
    allowDescription: boolean;
    headerText: string;
    invalidNames?: string[];
    files: FileList;
}

@Component({
    selector: 'eaglei-file-upload-modal',
    templateUrl: './file-upload-modal.component.html',
    styleUrls: ['../shared-modals.scss', './file-upload-modal.component.scss'],
})
export class FileUploadModalComponent extends BaseModal implements OnInit {
    public dataSource: MatTableDataSource<FileUpload>;
    public readonly columnNames = ['expand', 'filename', 'displayName', 'remove'];
    public expandedDocument: FileUpload;

    private files: FileUpload[] = [];
    private invalidNames: string[] = [];

    constructor(
        public dialogRef: MatDialogRef<FileUploadModalComponent>,
        @Inject(MAT_DIALOG_DATA) private data: ModalConfig,
        public systemEventService: SystemEventService
    ) {
        super();
        for (let i = 0; i < this.data.files.length; i++) {
            this.files.push(new FileUpload(i, this.data.files[i]));
        }

        if (data.linkToEvents) {
            this.columnNames = ['expand', 'filename', 'displayName', 'event', 'remove'];
        }

        this.invalidNames = data.invalidNames || [];
    }

    ngOnInit() {
        this.initializeData();
    }

    // IInherited from base class
    afterInit() {}

    private initializeData(): void {
        this.dataSource = new MatTableDataSource<FileUpload>(this.files);
    }

    public isExpanded(row: FileUpload): boolean {
        return this.expandedDocument && this.expandedDocument.id === row.id;
    }

    public toggleExpansion(row: FileUpload): void {
        this.expandedDocument = this.expandedDocument === row ? undefined : row;
    }

    public saveDocuments(): void {
        this.dialogRef.close(this.dataSource.data);
    }

    public removeDocument(file: FileUpload): void {
        const index = this.dataSource.data.findIndex((f) => f.id === file.id);
        this.dataSource.data.splice(index, 1);
        this.dataSource._updateChangeSubscription();
    }

    public validateFileName(file: FileUpload): void {
        const name = file.displayName || '';
        file.hasErrors = name.trim().length === 0 || this.invalidNames.includes(name.trim());
    }

    public getErrorMessage(file: FileUpload): string {
        if (file.sizeError) {
            return 'File exceeds maximum allowed size of 50MB';
        } else if (file.displayName.trim().length === 0) {
            return 'A name is required';
        } else if (this.invalidNames.includes(file.displayName.trim())) {
            return 'A file already exists with that name.';
        }
    }

    public areFilesValid(): boolean {
        const hasError = this.dataSource.data.some((file) => {
            return file.sizeError || file.hasErrors;
        });

        return this.dataSource.data.length > 0 && !hasError;
    }

    public setEvents(file: FileUpload, event: MatSelectChange) {
        file.events = event.value;
    }
}
