
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import { GenNotificationType } from './GenNotificationType';
import * as moment from 'moment';

export class GenSystemNotification {

    id: number;
    date: moment.Moment;
    info: Object;
    message: string;
    seen: boolean;
    triggerId: number;
    type: GenNotificationType;

    constructor();
    constructor( jsonObj: any );
    constructor( genSystemNotification: GenSystemNotification);
    constructor( id: number );

    constructor( input?: GenSystemNotification|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            if ( input.date && typeof input.date === 'string' ) {
                this.date = moment(input.date).utc();
            } else {
                this.date = input.date;
            }
            if( input.info ) {
              this.info = new Object( input.info );
            }
            this.message = input.message; // string [string]
            this.seen = input.seen; // boolean [boolean]
            this.triggerId = input.triggerId; // int [number]
            this.type = (typeof input.type === 'string') ? GenNotificationType.forName(<string> input.type) : <any> input.type; // GenNotificationType
       }
    }

    static jsonResponseToList(arr: any[]): GenSystemNotification[] {
        if (arr && arr.map) {
          return arr.map(a => new GenSystemNotification(a));
        } else {
          return [];
        }
    }
}

