import {LeafletVectorSource} from '../classes/leaflet-vector-source';
import {LeafletMapLayer} from '../classes/leaflet-map-layer';
import {LeafletFeature} from '../classes/leaflet-feature';
import * as L from 'leaflet';
import {MapService} from '../../map/services/map.service';
import {PrincipalPort} from '../classes/ports';

export class PortsSource extends LeafletVectorSource {
    constructor(layerInfo: LeafletMapLayer) {
        super(layerInfo);
    }
    public processFeatures(features: any[]): void {
        this.leafletFeatures = features.map((v) => {
            const data = new LeafletFeature<PrincipalPort>(v);
            data.properties = PrincipalPort.fromApi(data.properties);
            return data.buildPopover(this.layerInfo);
        });

        const style = (geoJsonPoint: any, latlng: L.LatLng): L.Layer => {
            return L.circleMarker(latlng, {
                radius: 4,
                fillColor: '#408ABF',
                color: '#000',
                weight: 1,
                opacity: 1,
                fillOpacity: 1,
            });
        };
        const config: any = {
            pane: MapService.layerPaneName,
            pointToLayer: style,
            onEachFeature: this.initializePopoverInteractions.bind(this),
            features: this.leafletFeatures,
        };

        this.source = L.geoJSON(this.leafletFeatures as any, config);
    }
}
