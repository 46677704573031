
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import { GenRRCCDeployment } from './GenRRCCDeployment';
import { GenRRCCDesk } from './GenRRCCDesk';
import { GenSystemEvent } from './GenSystemEvent';

export class GenRRCCLocation {

    id: number;
    boundary: string;
    city: string;
    createdBy: number;
    deployed: boolean;
    deployments: GenRRCCDeployment[];
    desks: GenRRCCDesk[];
    events: GenSystemEvent[];
    latitude: number;
    longitude: number;
    name: string;
    notes: string;
    pastDeployments: GenRRCCDeployment[];
    stateId: number;
    stateName: string;

    constructor();
    constructor( jsonObj: any );
    constructor( genRRCCLocation: GenRRCCLocation);
    constructor( id: number );

    constructor( input?: GenRRCCLocation|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            this.boundary = input.boundary; // string [string]
            this.city = input.city; // string [string]
            this.createdBy = input.createdBy; // int [number]
            this.deployed = input.deployed; // boolean [boolean]
            if ( input.deployments && input.deployments.map ) { // list [GenRRCCDeployment]
                this.deployments = input.deployments.map( ( x: GenRRCCDeployment ) => {
                    return new GenRRCCDeployment( x );
                } );
            } else {
                this.deployments = input.deployments;
            }
            if ( input.desks && input.desks.map ) { // list [GenRRCCDesk]
                this.desks = input.desks.map( ( x: GenRRCCDesk ) => {
                    return new GenRRCCDesk( x );
                } );
            } else {
                this.desks = input.desks;
            }
            if ( input.events && input.events.map ) { // list [GenSystemEvent]
                this.events = input.events.map( ( x: GenSystemEvent ) => {
                    return new GenSystemEvent( x );
                } );
            } else {
                this.events = input.events;
            }
            this.latitude = input.latitude; // float [number]
            this.longitude = input.longitude; // float [number]
            this.name = input.name; // string [string]
            this.notes = input.notes; // string [string]
            if ( input.pastDeployments && input.pastDeployments.map ) { // list [GenRRCCDeployment]
                this.pastDeployments = input.pastDeployments.map( ( x: GenRRCCDeployment ) => {
                    return new GenRRCCDeployment( x );
                } );
            } else {
                this.pastDeployments = input.pastDeployments;
            }
            this.stateId = input.stateId; // int [number]
            this.stateName = input.stateName; // string [string]
       }
    }

    static jsonResponseToList(arr: any[]): GenRRCCLocation[] {
        if (arr && arr.map) {
          return arr.map(a => new GenRRCCLocation(a));
        } else {
          return [];
        }
    }
}

