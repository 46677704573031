
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import * as moment from 'moment';

export class GenStateCoverage {

    id: number;
    coverageDate: moment.Moment;
    coveredCustomersBestCase: number;
    eiaTotalCustomers: number;
    femaRegion: number;
    state: string;
    stateName: string;

    constructor();
    constructor( jsonObj: any );
    constructor( genStateCoverage: GenStateCoverage);
    constructor( id: number );

    constructor( input?: GenStateCoverage|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            if ( input.coverageDate && typeof input.coverageDate === 'string' ) {
                this.coverageDate = moment(input.coverageDate).utc();
            } else {
                this.coverageDate = input.coverageDate;
            }
            this.coveredCustomersBestCase = input.coveredCustomersBestCase; // int [number]
            this.eiaTotalCustomers = input.eiaTotalCustomers; // int [number]
            this.femaRegion = input.femaRegion; // int [number]
            this.state = input.state; // string [string]
            this.stateName = input.stateName; // string [string]
       }
    }

    static jsonResponseToList(arr: any[]): GenStateCoverage[] {
        if (arr && arr.map) {
          return arr.map(a => new GenStateCoverage(a));
        } else {
          return [];
        }
    }
}

