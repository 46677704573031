import {AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {User} from '../../classes/user';
import {AuthenticationService} from '../../../../services/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {GenRoleDefinition} from '../../../../../../generated/serverModels/GenRoleDefinition';
import {UserService} from '../../services/user.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DenyOptionsComponent} from '../../../../../shared/modals/deny-options/deny-options.component';
import {filter} from 'rxjs/operators';
import {UserPreference} from '../../../../classes/user-preferences';
import {ApplicationConfig} from '../../../../classes/application-config';
import {LegendStyle} from '../../classes/legend-style';
import {GenOutageAggregationLevel} from '../../../../../../generated/serverModels/GenOutageAggregationLevel';
import {UserInfoModalComponent} from '../../modals/user-info-modal/user-info-modal.component';
import {ModalConfig} from '../../../../classes/modal-config';
import {PasswordResetModalComponent} from '../../modals/password-reset-modal/password-reset-modal.component';
import * as moment from 'moment';
import {EditPocModalComponent} from '../../modals/edit-poc-modal/edit-poc-modal.component';
import {EditAdminInfoModalComponent} from '../../modals/edit-admin-info-modal/edit-admin-info-modal.component';
import {EditPreferenceModalComponent} from '../../modals/edit-preferences-modal/edit-preference-modal.component';
import {NomLegendComponent} from '../../../../../shared/components/nom-legend/nom-legend.component';
import {GenEmailChangeRequest} from '../../../../../../generated/serverModels/GenEmailChangeRequest';
import {ApiKeyService} from '../../../api-key/services/api-key.service';
import {ApiKey} from '../../../api-key/classes/api-key';
import {ApiKeyManagementModalComponent} from '../../modals/api-key-management-modal/api-key-management-modal.component';
import {Subscription} from 'rxjs';

interface PreferenceDisplay {
    aggregation: GenOutageAggregationLevel;
    states: string;
    metric: number;
    legendStyle: string;
}

@Component({
    selector: 'eaglei-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss', './profile-grid.component.scss'],
})
export class ProfileComponent implements OnInit, AfterViewInit {
    get user(): User {
        return this._user;
    }

    @ViewChild(NomLegendComponent) legend: NomLegendComponent;
    @ViewChild('gridContainer', {static: true}) gridContainer: ElementRef<HTMLDivElement>;

    private _user: User;
    public gridLayout: string;

    // Role display properties
    public visibleRoles: GenRoleDefinition[] = [];
    private readonly excludedRoles = [GenRoleDefinition.ROLE_RESETTING_PASSWORD, GenRoleDefinition.ROLE_APPROVED_USER];

    // Access properties
    public readonly isLoggedInAsAdmin: boolean;
    public canEditPreferences: boolean;
    public apiKeys: ApiKey[] = [];

    // User preference properties
    private legendStyles: LegendStyle[] = [];
    public userPreferences = ApplicationConfig.currentUserPreferences.getValue();
    public preferenceValues: PreferenceDisplay;

    // Account Action properties
    public readonly denialReason: string[] = [
        'Missing Point Of Contact information (required unless .gov email address is used)',
        'Federal Point Of Contact information needed',
        'Missing or invalid contact information',
        'Insufficient Justification: Account must indicate connection to emergency response or preparedness',
        'Access Restrictions: Access to EAGLE-I is limited to federal government staff and their support contractors' +
            ' that are Emergency Support Function (ESF) mission partners of the Department of Energy.',
        'Duplicate account',
    ];

    public changeRequest: GenEmailChangeRequest;

    constructor(
        private auth: AuthenticationService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private userService: UserService,
        private apiKeyService: ApiKeyService,
        private popup: MatSnackBar,
        private dialog: MatDialog,
        private renderer: Renderer2
    ) {
        if (this.auth.authenticatedUser.value) {
            this.isLoggedInAsAdmin = this.auth.authenticatedUser.value.hasAnyRole([
                GenRoleDefinition.ROLE_ADMIN,
                GenRoleDefinition.ROLE_USER_MANAGER,
            ]);
        }

        this._user = new User(this.activatedRoute.snapshot.data['user']);
        this.apiKeys = this.user.apiKeys;

        const apiKeySystem = this.router?.getCurrentNavigation()?.extras?.state?.apiKeySystem;
        if (apiKeySystem) {
            const key = this.apiKeys.find((k) => k.system === apiKeySystem);
            this.manageApiKey(key);
        }

        this.initializeLegendStyle();
    }

    ngOnInit() {
        this.visibleRoles = this._user.roles.filter((role) => !this.excludedRoles.includes(role));
        this._user.oldPassword = '';

        this.canEditPreferences = this.auth.authenticatedUser.getValue().id === this.user.id;

        if (this.isLoggedInAsAdmin) {
            this.checkEmailChangeRequest();
        }

        this.userPreferences = new UserPreference(this.user.userPreferences);

        if (this.isLoggedInAsAdmin) {
            if (this.user.needsPoc) {
                // tested
                this.gridLayout = 'admin-and-poc-layout';
            } else {
                // tested
                this.gridLayout = 'admin-layout';
            }
        } else {
            if (this.user.needsPoc) {
                // tested
                this.gridLayout = 'poc-layout';
            } else {
                this.gridLayout = 'default-layout';
            }
        }

        this.renderer.addClass(this.gridContainer.nativeElement, this.gridLayout);
    }

    ngAfterViewInit(): void {
        this.getPreferenceValues();
    }

    /**
     * Enables a user by setting their approved and enabled fields to true.
     */
    public enableUser(): void {
        this.userService.enableUser(this._user).subscribe(
            (user) => {
                this.popup.open('User Enabled Successfully', '', {duration: 1000, panelClass: ['success']});
                this._user = user;
                // this.userEnabled = true;
            },
            (error: string) => {
                console.error(error);
                this.popup.open('User Enable Failed', 'OK', {panelClass: ['failure']});
            }
        );
    }

    /**
     * Disables the current user profile.
     */
    public disableUser(): void {
        this.userService.disableUser(this._user).subscribe(
            (user) => {
                this._user = user;

                this.popup.open('User Disabled Successfully', '', {duration: 1000, panelClass: ['success']});
                // this.userEnabled = false;
            },
            (error: string) => {
                console.error(error);
                this.popup.open('User Disable Failed', 'OK', {panelClass: ['failure']});
            }
        );
    }

    /**
     * Approves The current user
     */
    public approveUser(): void {
        this.userService.approveUser(this._user).subscribe(
            (user) => {
                this._user = user;
                this._user.approved = true;
                this._user.enabled = true;
                this._user.accountEnabled = true;
                // this.userEnabled = true;
                this.popup.open('User Approved!', '', {duration: 1000, panelClass: ['success']});
            },
            (error: string) => {
                console.error(error);
                this.popup.open('Approval failed.', 'OK', {panelClass: ['failure']});
            }
        );
    }

    /**
     * Denies the users account request
     * @param reason The reason for denial that will be sent in an email
     */
    public denyUser(reason: string): void {
        this.userService.denyUser(this._user, reason).subscribe(
            () => {
                this.popup.open('User Rejected!', '', {duration: 1000, panelClass: ['success']});
                // noinspection JSIgnoredPromiseFromCall
                this.router.navigateByUrl('/app/user/manage');
            },
            (error: string) => {
                console.error(error);
                this.popup.open('Rejection failed.', 'OK', {panelClass: ['failure']});
            }
        );
    }

    /**
     * Opens a modal to allow the admin to create a custom denial option
     */
    public createDenyOption() {
        this.dialog
            .open(DenyOptionsComponent, {width: '400px'})
            .afterClosed()
            .pipe(filter((reason) => !!reason))
            .subscribe((reason) => this.denyUser(reason));
    }

    /**
     * Resends the verification email to the users email
     */
    public resendEmailVerification(): void {
        this.userService.resendVerificationEmail(this._user.id).subscribe(
            () => {
                this.popup.open('Verification Email Sent', '', {duration: 1000, panelClass: ['success']});
            },
            (error) => {
                this.popup.open('Verification Email Failed', '', {duration: 1000, panelClass: ['failure']});
                throw error;
            }
        );
    }

    /**
     * Verifies the users email as an external source so no verification email is necessary
     */
    public forceEmailVerification(): void {
        this.userService.forceVerificationEmail(this._user.id).subscribe(
            (res) => {
                this.popup.open('Email Verified', '', {duration: 1000, panelClass: ['success']});
                this._user.emailVerified = res.emailVerified;
            },
            (error) => {
                this.popup.open('Email Verification Failed', '', {duration: 1000, panelClass: ['failure']});
                throw error;
            }
        );
    }

    /**
     * Sends an email to the user to allow them to reset their password
     */
    public sendPasswordReset(): void {
        this.auth.requestPasswordReset(this._user.username).subscribe(
            () => {
                this.popup.open('Password Reset Sent', '', {duration: 1000, panelClass: ['success']});
            },
            () => {
                this.popup.open('Password Reset Failed.', 'OK', {panelClass: ['failure']});
            }
        );
    }

    /**
     * Impersonates the user, redirects to the landing page and refreshes the page
     */
    public impersonateUser(): void {
        const me = this;
        me.auth.impersonate(me._user).subscribe(() => {});
    }

    /**
     * Gets the possible legend styles from the DB and updates the user preferences to the correct legend.
     */
    private initializeLegendStyle(): void {
        this.userService.getLegendOptions().subscribe((styles) => {
            this.legendStyles = styles;
            const preferences = ApplicationConfig.currentUserPreferences.getValue();
            if (!preferences.hasLegendStyle()) {
                const legend = styles.find((s) => s.name.toLowerCase() === 'default');
                if (legend) {
                    this.userPreferences.legendStyle = legend;
                }
            }
        });
    }

    // Email Change Methods
    public approveEmailRequest(): void {
        this.userService.approveEmailChangeRequest(this.changeRequest).subscribe(
            () => {
                this.popup.open('Request Approved', '', {duration: 5000, panelClass: 'dialog-success'});
                this._user.email = this.changeRequest.email;
                this.changeRequest = undefined;
            },
            () => {
                this.popup.open('Failed To Approve Request', 'Okay', {panelClass: 'dialog-failure'});
            }
        );
    }

    public denyEmailRequest(): void {
        const config: MatDialogConfig = {
            disableClose: true,
            width: ModalConfig.getModalWidth(500),
        };

        this.dialog
            .open(DenyOptionsComponent, config)
            .afterClosed()
            .pipe(filter((val) => !!val))
            .subscribe((reason) => {
                this.userService.denyEmailChangeRequest(this.changeRequest, reason).subscribe(
                    () => {
                        this.popup.open('Request Denied', '', {duration: 5000, panelClass: 'dialog-success'});
                        this.changeRequest = undefined;
                    },
                    () => {
                        this.popup.open('Failed To Deny Request', 'Okay', {panelClass: 'dialog-failure'});
                    }
                );
            });
    }

    /**
     * Gets the users preferences and transform them for display.
     */
    private getPreferenceValues(): void {
        if (!this.userPreferences) {
            console.info('No Preferences set for ' + this._user.username);
            return;
        }

        this.preferenceValues = {
            aggregation: this.userPreferences.getOutageAggregationLevel(),
            states: this.userPreferences.states
                .map((s) => ` ${s.name}`)
                .sort()
                .join()
                .trim(),
            metric: this.userPreferences.legendMetric,
            legendStyle: this.userPreferences.legendStyle.name,
        };

        if (this.legend) {
            this.legend.metric = this.userPreferences.legendMetric;
            this.legend.range = this.userPreferences.legendStyle.colors;
        }
    }

    /**
     * Opens a modal to allow users to update their current personal info.
     */
    public openPersonalInfoModal(): void {
        const config: MatDialogConfig = {
            disableClose: true,
            data: this._user,
            width: ModalConfig.getModalWidth(750),
        };
        this.dialog
            .open(UserInfoModalComponent, config)
            .afterClosed()
            .pipe(filter((user) => !!user))
            .subscribe((updatedUser) => this._user.setPersonalInfo(updatedUser));
    }

    /**
     * Opens a modal to allow the user to reset their current password.
     */
    public openPasswordResetModal(): void {
        const config: MatDialogConfig = {
            disableClose: true,
            data: this._user,
            width: ModalConfig.getModalWidth(500),
        };
        this.dialog
            .open(PasswordResetModalComponent, config)
            .afterClosed()
            .pipe(filter((ret) => !!ret))
            .subscribe(() => {
                this._user.lastPasswordReset = moment();
                this._user.updateDaysSincePasswordReset();
            });
    }

    /**
     * Opens a modal to allow users to update their current federal point of contact info.
     */
    public openPointOfContactModal(): void {
        const config: MatDialogConfig = {
            disableClose: true,
            data: this._user,
            width: ModalConfig.getModalWidth(750),
        };
        this.dialog
            .open(EditPocModalComponent, config)
            .afterClosed()
            .pipe(filter((res) => !!res))
            .subscribe((updatedUser) => this._user.setFederalPoc(updatedUser));
    }

    /**
     * Opens a modal that will allow admin users to edit roles
     */
    public openAdminInfoModal(): void {
        const config: MatDialogConfig = {
            disableClose: true,
            data: {
                user: this._user,
                excludedRoles: this.excludedRoles,
            },
            width: ModalConfig.getModalWidth(750),
        };
        this.dialog
            .open(EditAdminInfoModalComponent, config)
            .afterClosed()
            .pipe(filter((res) => !!res))
            .subscribe((roles: GenRoleDefinition[]) => {
                this.visibleRoles = roles.filter((role) => !this.excludedRoles.includes(role));
            });
    }

    /**
     * Opens a modal that will allow users to update their preferences
     */
    public openUserPreferenceModal(): void {
        const config: MatDialogConfig = {
            disableClose: true,
            data: {
                user: this._user,
                preferences: this.userPreferences,
                legendStyles: this.legendStyles,
            },
            width: ModalConfig.getModalWidth(750),
        };
        this.dialog
            .open(EditPreferenceModalComponent, config)
            .afterClosed()
            .pipe(filter((res) => !!res))
            .subscribe((preferences: UserPreference) => {
                this.userPreferences = preferences;
                this.getPreferenceValues();
            });
    }

    private checkEmailChangeRequest() {
        this.userService.getEmailChangeRequest(this._user.id).subscribe((res) => {
            this.changeRequest = res;
        });
    }

    public onPhone(): boolean {
        return ApplicationConfig.onPhone();
    }

    /**
     * Opens a modal to manage a specific api-key. The key can be renewed from the modal without closing or the key
     * information can be updated on modal close.
     * @param key The API-Key being updated.
     */
    public manageApiKey(key: ApiKey): void {
        const config: MatDialogConfig = {
            disableClose: true,
            width: ModalConfig.getModalWidth(),
            data: key,
        };

        const ref = this.dialog.open(ApiKeyManagementModalComponent, config);
        const eventSub: Subscription = ref.componentInstance.keyRenewed.subscribe(() => {
            key.expirationDate = moment().add(1, 'year');
            key.expired = false;
        });

        ref.afterClosed().subscribe((updatedKey) => {
            if (updatedKey) {
                key = new ApiKey(updatedKey);
            }
            eventSub.unsubscribe();
        });
    }

    public hasEllipsis(element: HTMLElement): boolean {
        return (window as any).elementHasEllipsis(element);
    }
}
