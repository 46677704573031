import {AfterViewInit, Component, ElementRef, inject, OnInit, ViewChild} from '@angular/core';
import {Report} from '../../classes/report';
import * as moment from 'moment';
import {CardFilters} from '../../../../../shared/classes/card-filters';
import {ReportService} from '../../services/report.service';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {HttpInterceptorService} from 'frontend/src/app/services/http-interceptor.service';
import {tap} from 'rxjs/operators';
import {NaturalGas} from '../../classes/natural-gas';
import {IReportNavigationConfig} from '../../../../interfaces/report-navigation-config.interface';
import {Router} from '@angular/router';
import {TitleCasePipe} from '@angular/common';
import {ReportFilter} from '../../enums/report-filter.enum';
import {FileDownload} from '../../../../classes/file-download';
import {State} from '../../../outage/classes/state';
import {OngService} from '../../../../services/ong.service';

interface Chip {
    type: 'COMPANY' | 'PRIORITY' | 'STATE';
    display?: string;
    value: string | number;
}

@Component({
    selector: 'eaglei-natural-gas-report',
    templateUrl: './natural-gas-report.component.html',
    styleUrls: ['../reports.scss', './natural-gas-report.component.scss'],
})
export class NaturalGasReportComponent extends Report<NaturalGas> implements OnInit, AfterViewInit {
    // Html Properties
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild('chartTarget') chartTarget: ElementRef;

    // Table Properties
    public readonly columns: string[] = ['title', 'company', 'priority', 'startDate', 'endDate'];
    private activeEvent: NaturalGas;

    // Filter Properties
    public pipelineStatuses = [];
    public pipelineCompanies = [];
    public selectedPriorities: string[] = [];
    public selectedCompanies: string[] = [];
    public selectedStates: State[] = [];
    public startDate: moment.Moment = moment().subtract(1, 'days').startOf('day');
    public endDate: moment.Moment = moment();
    public defaultPriorities: string[];
    private initialLoad: boolean = true;
    private titlePipe = new TitleCasePipe();

    public maxDate: moment.Moment;

    // Custom chip list
    public chipList: Chip[] = [];
    public maxChipLength: number;
    public showAll: boolean = false;
    private numberVisible: number = 5;

    // Loading properties
    public dataLoaded: boolean;
    public pipelines: NaturalGas[] = [];

    protected ongService = inject(OngService);
    public reportService = inject(ReportService);
    private router = inject(Router);

    constructor() {
        super();
        const routeFilters: IReportNavigationConfig[] = this.router?.getCurrentNavigation()?.extras?.state?.filters;
        if (routeFilters) {
            routeFilters.forEach((routeFilter) => {
                switch (routeFilter.type) {
                    case ReportFilter.PRIORITY:
                        this.defaultPriorities = (routeFilter.value as string).split(',').map((p) => this.titlePipe.transform(p.trim()));
                        break;
                    case ReportFilter.START_DATE:
                        this.startDate = moment(routeFilter.value as number);
                        break;
                    case ReportFilter.END_DATE:
                        this.endDate = moment(routeFilter.value as number);
                        this.maxDate = this.endDate.clone();
                        break;
                }
            });
        }
    }

    ngOnInit() {
        this.reportService.getReportData().subscribe((res) => this.initializeReportInfo(res));
    }

    ngAfterViewInit(): void {
        this.getNotices();
    }

    // API calls
    /**
     * turns on the loading mask for the chart and fetches the relevant natural gas data.
     */
    private getNotices(): void {
        HttpInterceptorService.clearInterceptor(this.uiHandle);
        HttpInterceptorService.pendingRequests[this.uiHandle] = this.reportService
            .naturalGasData(this.startDate, this.endDate)
            .pipe(tap(() => HttpInterceptorService.deleteFromInterceptor(this.uiHandle)))
            .subscribe((pipelines) => {
                this.dataLoaded = true;
                this.pipelines = pipelines;

                const formattedPipelines = this.formatPriority(pipelines); // removes snake case

                this.pipelineCompanies = [...new Set(formattedPipelines.map((item) => item.name))]; // map all options
                this.pipelineStatuses = [...new Set(formattedPipelines.map((item) => item.priority))]; // map all options

                // Sort list alphabetically
                this.pipelineCompanies.sort((a, b) => {
                    return a.toLowerCase().localeCompare(b.toLowerCase());
                });

                if (this.initialLoad) {
                    if (this.defaultPriorities) {
                        this.selectedPriorities = this.defaultPriorities;
                    } else {
                        this.selectedPriorities = this.pipelineStatuses.slice();
                    }
                    this.selectedCompanies = this.pipelineCompanies.slice();
                }

                this.initializeData(formattedPipelines);
                this.initialLoad = false;
            });
    }

    // Table Methods
    /**
     * Sets the data used in the table and also creates all the interactions on the table
     * @param data A list of recent events to be in the table.
     */
    private initializeData(data: NaturalGas[]): void {
        if (this.dataSource) {
            this.dataSource.data = data;
        } else {
            this.dataSource = new MatTableDataSource(data);
            this.dataSource.filterPredicate = this.filterPredicate.bind(this);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sortingDataAccessor = this.dataAccessor.bind(this);
            this.dataSource.sort = this.sort;
            this.showLess();
        }
        this.filterPipelines(this.dataSource.filter);
    }

    /**
     * The logic behind how the table is filtered, is called when the filter property on the dataSource is changed.
     * @param data The recent event being tested.
     * @param text The text being searched for.
     */
    private filterPredicate(data: NaturalGas, text: string): boolean {
        let companyCheck = false;
        let priorityCheck = false;
        let stateCheck = true;

        const subjectCheck = data.subject.toLowerCase().includes(text.trim().toLowerCase());

        if (this.selectedCompanies.length > 0) {
            companyCheck = this.selectedCompanies.includes(data.name);
        }

        if (this.selectedPriorities.length > 0) {
            priorityCheck = this.selectedPriorities.includes(data.priority);
        }

        if (this.selectedStates.length) {
            stateCheck = this.selectedStates.some((state) => data.states?.includes(state.name));
        }

        return subjectCheck && priorityCheck && companyCheck && stateCheck;
    }

    // noinspection JSMethodCanBeStatic
    /**
     * The logic behind how the columns are sorted.
     * @param data The recent event being sorted
     * @param header The column name being sorted on.
     */
    private dataAccessor(data: NaturalGas, header: string): string | number {
        switch (header) {
            case 'title':
                return data.subject.toLowerCase();
            case 'company':
                return data.name.toLowerCase();
            case 'priority':
                return data.priority.valueOf();
            case 'startDate':
                return data.effective_date.valueOf();
            case 'endDate':
                return data.end_date.valueOf();
            default:
                return '';
        }
    }

    /**
     * Adds states to be included in the filtered data.
     * @param states the states selected for filtering
     */
    public updateLocations(states: State[]) {
        this.selectedStates = states;
        this.filterPipelines(this.dataSource.filter);
    }

    // Filter Priorities
    /**
     * Called when the priority dropdown is updated and fetches new data.
     * @param value The new priority array
     */
    public updatePriority(value: string[]): void {
        this.selectedPriorities = value;
        this.filterPipelines(this.dataSource.filter);
    }

    // Filter Companies
    /**
     * Called when the company dropdown is updated and fetches new data.
     * @param value The new company array
     */
    public updateCompany(value: string[]): void {
        this.selectedCompanies = value;
        this.filterPipelines(this.dataSource.filter);
    }

    // Filter Methods
    /**
     * Called when the date range is updated, changed the dates for the API and fetches new data.
     * @param dates The new start and end date.
     */
    public updateDateRange(dates: CardFilters): void {
        this.startDate = dates.startDate;
        this.endDate = dates.endDate;

        this.getNotices();
    }

    /**
     * Called when the searched bar is changed. Updates the filter property on the dataSource
     * @param text The text being searched for.
     */
    public filterTitles(text: string): void {
        text = text === '' ? ' ' : text;
        this.dataSource.filter = text;
    }

    /**
     * sets the filter on the dataSource and triggers the table filter.
     * This should be called by all filter update methods.
     * @param text The text being passed to the dataSource filter.
     */
    public filterPipelines(text: string): void {
        this.pipelines = this.dataSource.data.filter((e) => this.selectedPriorities.some((r) => e.priority.includes(r)));

        text = text === '' ? ' ' : text;

        this.dataSource.filter = text;
        this.chipList = [
            ...this.selectedPriorities.map<Chip>((value) => ({value, display: value, type: 'PRIORITY'})),
            ...this.selectedCompanies.map<Chip>((value) => ({value, display: value, type: 'COMPANY'})),
            ...this.selectedStates.map<Chip>((s) => ({display: s.abbreviation, value: s.id, type: 'STATE'})),
        ];
    }

    /**
     * Checks to see if there is text in the search field
     */
    public isSearchTextActive(): boolean {
        return this.dataSource && this.dataSource.filter && this.dataSource.filter.trim().length > 0;
    }

    // Export Methods
    /**
     * Exports the table as a CSV File
     */
    public exportTable(): void {
        let data: string = ['Title', 'Company', 'Priority', 'Effective Date', 'End Date'].join() + '\n';
        this.dataSource.filteredData.forEach((notice) => {
            const info = [
                FileDownload.formatCsvCell(notice.subject),
                FileDownload.formatCsvCell(notice.name),
                FileDownload.formatCsvCell(notice.priority),
                FileDownload.formatCsvCell(Report.momentPipe.transform(notice.effective_date)),
                FileDownload.formatCsvCell(Report.momentPipe.transform(notice.end_date)),
            ];
            data += `${info.join()}\n`;
        });

        FileDownload.downloadCSV('naturalGasNotices', data, this.attributionUrl);
    }

    /**
     * Formats priority string by removing underscores and converts to title case
     * * @param pipelines The objects to be formatted
     */
    formatPriority(pipelines: NaturalGas[]) {
        pipelines.forEach((pipeline) => {
            let priority = pipeline.priority;
            priority = priority.replace(new RegExp('_', 'g'), ' ');
            pipeline.priority = this.titlePipe.transform(priority);
        });

        return pipelines;
    }

    /**
     * Changes the expanded row in the table, if the row is already expanded, it will close it
     * @param row The row to be expanded
     */
    public toggleActiveReport(row: NaturalGas): void {
        if (this.activeEvent && this.activeEvent.id === row.id) {
            this.activeEvent = undefined;
        } else {
            this.activeEvent = row;
        }
    }

    /**
     * Checks to see if a row is expanded
     * @param event the row that is being checked for expansion.
     */
    public isActiveEvent(event: NaturalGas): boolean {
        return this.activeEvent && this.activeEvent.id === event.id;
    }

    // Chip List Methods
    /**
     * removes a filter from the chip list & triggers updating the related filter.
     * @param chip The filter to be removed
     */
    public removeFilter(chip: Chip): void {
        switch (chip.type) {
            case 'PRIORITY': {
                this.updatePriority(this.selectedPriorities.filter((p) => p !== chip.value));
                return;
            }
            case 'COMPANY': {
                this.updateCompany(this.selectedCompanies.filter((c) => c !== chip.value));
                return;
            }
            case 'STATE': {
                this.updateLocations(this.selectedStates.filter((s) => s.id !== chip.value));
                return;
            }
        }
    }

    /**
     * Removes all chips from the filter list and clears the table
     */
    public clearChipList(): void {
        this.selectedCompanies = [];
        this.selectedPriorities = [];
        this.selectedStates = [];
        this.chipList = [];
        this.filterPipelines(this.dataSource.filter);
    }

    /**
     * reverts the list back to show only {numberVisible} chips
     */
    private showLess(): void {
        this.showAll = false;
        this.maxChipLength = this.numberVisible;
    }

    // Loading Text
    /**
     * Sets the loading or no data message
     */
    public getMaskText(): string {
        return this.dataLoaded && this.pipelines.length === 0 ? 'No notices found matching filters' : 'Loading...';
    }
}
