
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import * as moment from 'moment';

export class GenRadrUpdateNotice {

    id: number;
    algorithm: string;
    collectedAt: moment.Moment;
    eventId: number;
    source: string;
    tileId: string;
    wmsEndpoint: string;
    wmsLayerId: string;

    constructor();
    constructor( jsonObj: any );
    constructor( genRadrUpdateNotice: GenRadrUpdateNotice);
    constructor( id: number );

    constructor( input?: GenRadrUpdateNotice|number ) {
        if ( typeof input === 'number') {
            this.id = input;
        }

        if ( input && typeof input !== 'number' ) {
            this.id = input.id; // int [number]
            this.algorithm = input.algorithm; // string [string]
            if ( input.collectedAt && typeof input.collectedAt === 'string' ) {
                this.collectedAt = moment(input.collectedAt).utc();
            } else {
                this.collectedAt = input.collectedAt;
            }
            this.eventId = input.eventId; // int [number]
            this.source = input.source; // string [string]
            this.tileId = input.tileId; // string [string]
            this.wmsEndpoint = input.wmsEndpoint; // string [string]
            this.wmsLayerId = input.wmsLayerId; // string [string]
       }
    }

    static jsonResponseToList(arr: any[]): GenRadrUpdateNotice[] {
        if (arr && arr.map) {
          return arr.map(a => new GenRadrUpdateNotice(a));
        } else {
          return [];
        }
    }
}

