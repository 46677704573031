import {Component, inject, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {EilData} from '../../../../classes/eil-data';
import {EilDocument} from '../../../management/classes/eil-document';
import {FileDownload} from '../../../../classes/file-download';
import {BaseModal} from '../../../../classes/base-modal';

@Component({
    selector: 'eaglei-eil-download-modal',
    templateUrl: './eil-download-modal.component.html',
    styleUrls: ['./eil-download-modal.component.scss'],
})
export class EilDownloadModalComponent extends BaseModal {
    public dialogRef = inject(MatDialogRef<EilDownloadModalComponent>);

    constructor(@Inject(MAT_DIALOG_DATA) public datapoint: EilData) {
        super();
    }

    // From parent class
    afterInit() {}

    public downloadDocument(doc: EilDocument, event: Event) {
        event.stopPropagation();
        const url = `/api/eil/${this.datapoint.id}/downloaddocument/${doc.id}`;
        const onError = () => {
            console.error('there was a problem');
        };
        FileDownload.downloadDocument(url, doc.filename, onError);
    }

    /**
     * Downloads all documents in the datapoint as a zip file
     */
    public downloadAll(): void {
        const filename = this.datapoint.name.replace(/ /g, '_') + '_documents.zip';
        const url = `api/eil/downloadall/${this.datapoint.id}`;
        const onError = () => {
            console.error('there was a problem');
        };

        FileDownload.downloadDocument(url, filename, onError);
    }
}
