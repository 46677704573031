
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!


export class GenUtilityCountyCustomer {

    active: boolean;
    collectedCount: number;
    countyId: number;
    countyName: string;
    femaRegion: string;
    modelCount: number;
    stateAbbreviation: string;
    stateId: number;
    subUtilityId: number;
    systemInclude: boolean;
    utilityId: number;
    utilityName: string;

    constructor();
    constructor( jsonObj: any );
    constructor( genUtilityCountyCustomer: GenUtilityCountyCustomer);

    constructor( input?: GenUtilityCountyCustomer ) {
        if ( input && typeof input !== 'number' ) {
            this.active = input.active; // boolean [boolean]
            this.collectedCount = input.collectedCount; // int [number]
            this.countyId = input.countyId; // int [number]
            this.countyName = input.countyName; // string [string]
            this.femaRegion = input.femaRegion; // string [string]
            this.modelCount = input.modelCount; // int [number]
            this.stateAbbreviation = input.stateAbbreviation; // string [string]
            this.stateId = input.stateId; // int [number]
            this.subUtilityId = input.subUtilityId; // int [number]
            this.systemInclude = input.systemInclude; // boolean [boolean]
            this.utilityId = input.utilityId; // int [number]
            this.utilityName = input.utilityName; // string [string]
       }
    }

    static jsonResponseToList(arr: any[]): GenUtilityCountyCustomer[] {
        if (arr && arr.map) {
          return arr.map(a => new GenUtilityCountyCustomer(a));
        } else {
          return [];
        }
    }
}

