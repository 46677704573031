import {GenSystemEventPhase} from '../../../../../generated/serverModels/GenSystemEventPhase';
import {TimeSliderSection} from '../../../../shared/interfaces/time-slider-section.interface';
import {GenSystemEventPhaseType} from '../../../../../generated/serverModels/GenSystemEventPhaseType';
import * as moment from 'moment';

export class SystemEventPhase extends GenSystemEventPhase implements TimeSliderSection {
    /**
     * added to implement the TimeSliderSection interface
     */
    public display: string;

    /**
     * added to implement the TimeSliderSection interface
     */
    public name: string;

    /**
     * added to implement the TimeSliderSection interface
     */
    public color: string;

    /**
     * Used for event creation only
     */
    public minDate?: moment.Moment;
    public maxDate?: moment.Moment;

    constructor(phase?: SystemEventPhase) {
        super(phase);

        if (phase) {
            this.name = phase.type.toString();
            this.minDate = phase.minDate;
            this.maxDate = phase.maxDate;

            switch (phase.type.toString()) {
                case GenSystemEventPhaseType.PRE.toString():
                    this.color = '#1A9641';
                    this.display = 'Preparation';
                    break;
                case GenSystemEventPhaseType.DURING.toString():
                    this.color = '#FDAE61';
                    this.display = 'Active';
                    break;
                case GenSystemEventPhaseType.POST.toString():
                    this.color = '#2C7BB6';
                    this.display = 'Recovery';
                    break;
            }
        }
    }
}
