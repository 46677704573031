import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {EventSummaryFilters} from '../../../system-event/classes/event-summary-filters';
import {BaseWidget} from '../../classes/base-widget';
import {MatTableDataSource} from '@angular/material/table';
import {Subject} from 'rxjs';
import {filter, map, skip, switchMap, takeUntil} from 'rxjs/operators';
import {SystemNewsService} from '../../../system-news/services/system-news.service';
import {SystemNews} from '../../../system-news/classes/system-news';
import * as moment from 'moment';
import {SystemEvent} from '../../../system-event/classes/system-event';
import {WidgetService} from '../../services/widget.service';
import {ApplicationConfig} from '../../../../classes/application-config';

@Component({
    selector: 'eaglei-system-news-widget',
    templateUrl: './system-news-widget.component.html',
    styleUrls: ['./system-news-widget.component.scss'],
})
export class SystemNewsWidgetComponent extends BaseWidget implements AfterViewInit {
    @ViewChild(MatPaginator) paginator: MatPaginator;

    public eventFilters: EventSummaryFilters = new EventSummaryFilters();
    public columns = ['title', 'createDate', 'expand-icon'];

    public dataSource = new MatTableDataSource<SystemNews>();
    private dataStream = new Subject<SystemEvent>();
    private dataLoading = false;
    public selectedRow: SystemNews;

    get noDocumentsText(): string {
        return this.dataLoading ? 'Loading recent news...' : 'No News Available';
    }

    constructor(private systemNews: SystemNewsService, private widgetElement: ElementRef) {
        super(widgetElement);
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;

        this.dataStream
            .pipe(
                switchMap((event) => {
                    const start = event ? event.eventStart.format() : moment().subtract(7, 'months').format();
                    const end = event ? event.eventEnd.format() : moment().format();
                    return this.systemNews.getSystemNews(null, false, start, end);
                }),
                map((news) => {
                    news.sort((a, b) => {
                        return b.createDate.isSameOrBefore(a.createDate) ? -1 : 1;
                    });
                    return news;
                }),
                takeUntil(this.destroy$)
            )
            .subscribe((news) => {
                this.dataSource.data = news;
                this.paginator.firstPage();
                this.dataLoading = false;
            });

        if (!this.eventDashboard) {
            this.dataLoading = true;
            this.dataStream.next(undefined);
        }

        WidgetService.resize
            .pipe(
                filter((widget) => this.item.x === widget.x && this.item.y === widget.y),
                skip(1),
                takeUntil(this.destroy$)
            )
            .subscribe(() => {
                this.resize();
                this.updateTableSize(true);
            });

        this.updateTableSize();
    }

    /**
     * @param resize When the event is fired it does not send the new size, but the old one.
     * So we have to manually add 1 to the size if the event is fired
     */
    private updateTableSize(resize?: boolean) {
        // Paginator page size
        const rows = this.item.rows + (resize ? 1 : 0);
        if (rows === 2) {
            this.paginator.pageSize = 4;
        } else if (rows === 3) {
            this.paginator.pageSize = 8;
        } else if (rows === 4) {
            this.paginator.pageSize = 12;
        } else {
            this.paginator.pageSize = 16;
        }
        this.dataSource.paginator = this.paginator;
    }

    protected handleEventFilterChange(eventFilters: EventSummaryFilters): void {
        this.eventFilters = eventFilters;

        if (eventFilters.event) {
            this.dataSource.data = [];
            this.dataLoading = true;
            this.dataStream.next(eventFilters.event);
        }
    }

    public toggleExpandedRow(row: SystemNews): void {
        const isRowSelected = this.selectedRow?.id === row.id;
        this.selectedRow = isRowSelected ? undefined : row;
    }

    public isEllipsis(element: HTMLElement): boolean {
        return ApplicationConfig.hasEllipsis(element);
    }

    public destroyWidget(): void {}
}
