import {RefineryStatusUnit} from '../../../../integrations/ong/classes/refinery-status-unit';

export class RefineryStatus {
    public facility_id;
    public name;
    public offline_capacity;
    public offline_units;
    public online_capacity;
    public online_units;
    public total_capacity;
    public total_units;
    public units: RefineryStatusUnit[];

    public status: 'Online' | 'Reduced Capacity' | 'Offline';
    public expanded: boolean = false;

    constructor(input?: RefineryStatus) {
        this.facility_id = input.facility_id;
        this.name = input.name;
        this.offline_capacity = input.offline_capacity;
        this.offline_units = input.offline_units;
        this.online_capacity = input.online_capacity;
        this.online_units = input.online_units;
        this.total_capacity = input.total_capacity;
        this.total_units = input.total_units;

        this.units = input.units.map((unit) => RefineryStatusUnit.fromApi(unit));

        if (this.online_units === this.total_units) {
            this.status = 'Online';
        } else if (this.online_units >= 1 && this.offline_units >= 1) {
            this.status = 'Reduced Capacity';
        } else if (this.offline_units === this.total_units) {
            this.status = 'Offline';
        }
    }
}
