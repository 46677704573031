<mat-card>
    <mat-card-title>
        <span>Gas Burn - {{ data.location_name }}</span>
        <button mat-icon-button (click)="close()">
            <mat-icon fontSet="fas" fontIcon="fa-times"></mat-icon>
        </button>
    </mat-card-title>
    <mat-card-content #content>
        <span class="title">{{ data.industry_title }}</span>

        <div class="metric-container">
            <div class="metric">
                <span class="sub-title">Hourly Flow: </span>
                <span class="value">{{ data.gas_burns[data.gas_burns.length - 1].hourly_flow }} mcf</span>
            </div>

            <div class="metric">
                <span class="sub-title">Timestamp: </span>
                <span class="value">{{ data.gas_burns[data.gas_burns.length - 1].flow_timestamp | momentDate }}</span>
            </div>

            <div class="metric">
                <span class="sub-title">County: </span>
                <span class="value">{{ data.county }}</span>
            </div>

            <div class="metric">
                <span class="sub-title">State: </span>
                <span class="value">{{ data.state }}</span>
            </div>
        </div>

        <span class="sub-title">Hourly Flow</span>

        <div class="chart" #gasChartTarget></div>
    </mat-card-content>
</mat-card>

<div #popoverContainerElement class="eaglei-popover invisible">
    <eaglei-popover *ngIf="popoverData.length > 0" [data]="popoverData"></eaglei-popover>
</div>
