<div *ngFor="let legend of legends" class="legend">
    <p class="layer-name" *ngIf="legend.layerName && legends.length > 1">{{ legend.layerName }}</p>

    <div class="legend-body">
        <ng-container *ngIf="legend.orientation === 'vertical'; else horizontalLegend">
            <div *ngFor="let element of legend.elements" class="vertical-legend-element">
                <ng-container [ngTemplateOutlet]="legendBody" [ngTemplateOutletContext]="{element: element, legend: legend}"></ng-container>
            </div>
        </ng-container>
        <ng-template #horizontalLegend>
            <div class="horizontal-legend">
                <ng-container
                    *ngFor="let element of legend.elements"
                    [ngTemplateOutlet]="legendBody"
                    [ngTemplateOutletContext]="{element: element, legend: legend}"></ng-container>
            </div>
        </ng-template>
    </div>
</div>

<!--Legend Body-->
<ng-template #legendBody let-element="element" let-legend="legend">
    <ng-container *ngIf="element.imageUrl" [ngTemplateOutlet]="image" [ngTemplateOutletContext]="{element: element}"></ng-container>
    <ng-container
        *ngIf="element.fillColor"
        [ngTemplateOutlet]="shape"
        [ngTemplateOutletContext]="{element: element, legend: legend}"></ng-container>
    <ng-container *ngIf="element.html" [ngTemplateOutlet]="html" [ngTemplateOutletContext]="{element: element}"></ng-container>
</ng-template>

<!--Image Legend-->
<ng-template #image let-element="element">
    <ng-container *ngIf="!element.error; else legendFailure">
        <img [src]="element.imageUrl" alt="Fetching Legend Again" (error)="element.retryImgLegend($event)" />
        <p
            *ngIf="element.label"
            #legendLabel
            [matTooltipDisabled]="!hasEllipsis(legendLabel)"
            [matTooltip]="element.label"
            matTooltipPosition="above"
            matTooltipShowDelay="250"
            class="ellipsis"
            style="font-size: 11px">
            {{ element.label }}
        </p>
    </ng-container>
    <ng-template #legendFailure>
        <span class="failure-text">Error Loading Legend</span>
    </ng-template>
</ng-template>

<!--HTML Legend-->
<ng-template #html let-element="element">
    <div class="wide" [innerHTML]="element.html | sanitize"></div>
</ng-template>

<!--Manual Legend-->
<ng-template #shape let-element="element" let-legend="legend">
    <div class="shape-container">
        <div
            [ngClass]="{
                rectangle: element.shape === 'rect',
                circle: element.shape === 'circle',
                center: element.labelPosition === 'inset',
                'no-border': legend.hideBorders
            }"
            [ngStyle]="{
                'background-color': element.fillColor,
                width: element.width + 'px',
                height: element.height + 'px',
                border: element.strokeColor ? element.strokeWidth + 'px solid ' + element.strokeColor : 'none'
            }">
            <span *ngIf="element.labelPosition === 'inset'" [style.font-size]="element.textSize + 'px'" [style.color]="element.labelColor">
                {{ element.label }}
            </span>
        </div>
        <span *ngIf="element.labelPosition !== 'inset'" [style.font-size]="element.textSize + 'px'" [style.color]="element.labelColor">
            {{ element.label }}
        </span>
    </div>
</ng-template>
