import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, Renderer2} from '@angular/core';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {ApplicationConfig} from 'frontend/src/app/classes/application-config';
import {MapLayerCategory} from '../../../layer/classes/map-layer-category';
import {LayerService} from '../../../layer/services/layer.service';
import {tap} from 'rxjs/operators';
import {HttpInterceptorService} from '../../../../services/http-interceptor.service';
import {LocationSearchService} from '../../services/location-search.service';

@Component({
    selector: 'eaglei-leaflet-sidebar',
    templateUrl: './leaflet-sidebar.component.html',
    styleUrls: ['./leaflet-sidebar.component.scss'],
})
export class LeafletSidebarComponent implements AfterViewInit, OnDestroy {
    @Input() isLiteSidebar: boolean = false;
    @Output() isOpen = new EventEmitter<boolean>();

    public sidebarExpanded: boolean = true;

    public activeSection: string = 'layers';
    public sections = [
        {name: 'layers', icon: 'fa-layer-group', show: true},
        {name: 'legends', icon: 'fa-shapes', show: true},
        {name: 'tools', icon: 'fa-tools', show: !(this.useMobileLayout() || this.isLiteSidebar)},
    ];
    public layerCategories: MapLayerCategory[] = [];

    constructor(
        private layerService: LayerService,
        private ele: ElementRef<HTMLElement>,
        private renderer: Renderer2,
        private locationSearchService: LocationSearchService
    ) {
        this.getLayerGroups();
    }

    public ngAfterViewInit(): void {
        this.sections.find((s) => s.name === 'tools').show = !(this.useMobileLayout() || this.isLiteSidebar);
    }

    ngOnDestroy() {
        this.clearLatLonSelection();
    }

    /**
     * Toggles the open state of the sidebar
     */
    public toggleSidebar(): void {
        this.sidebarExpanded = !this.sidebarExpanded;
        this.isOpen.emit(this.sidebarExpanded);
    }

    public changeSelectedTab(event: MatTabChangeEvent) {
        this.activeSection = event.tab.textLabel;
        this.clearLatLonSelection();
    }

    public useMobileLayout(): boolean {
        return ApplicationConfig.useMobileLayout();
    }

    /**
     * Fetches all the layer groups and layers that the user can see.
     */
    private getLayerGroups(): void {
        const success = (categories: MapLayerCategory[]) => {
            // TODO talk about caching layers here and then using the list on every page route

            const activeLayers = this.layerService.mapConfig.getValue();
            if (activeLayers) {
                // We clear the observable here so on next reload, the map acts as normal.
                this.layerService.mapConfig.next(undefined);
            }

            categories.forEach((cat) => {
                cat.groups.forEach((g) => {
                    g.expanded = ['electricity', 'imported'].includes(g.uiHandle);

                    if (activeLayers) {
                        const ids = activeLayers.layerInfo.map((l) => l.id);
                        g.layers.forEach((layer) => {
                            layer.defaultActive = ids.includes(layer.id);
                            layer.userAdded = ids.includes(layer.id);
                        });

                        g.expanded = g.layers.some((layer) => layer.defaultActive);
                    }
                });
            });
            this.layerCategories = categories;
        };

        const failure = (error: any) => {
            console.error(error);
        };

        HttpInterceptorService.clearInterceptor('sidebarLayerGroup');
        HttpInterceptorService['sidebarLayerGroup'] = this.layerService
            .getLayerCategories()
            .pipe(tap(() => HttpInterceptorService.deleteFromInterceptor('sidebarLayerGroup')))
            .subscribe(success, failure);
    }

    private clearLatLonSelection() {
        this.locationSearchService.pointSelection = false;
        this.locationSearchService.updateMapInteractions.next(undefined);
    }
}
