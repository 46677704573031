import {PopoverElement} from '../../../classes/popover-element';
import {LeafletMapLayer} from './leaflet-map-layer';

interface SubFeature {
    id: number | string;
    popoverData: PopoverElement[];
}

export class LeafletFeature<P = any> {
    get geometry(): Geometry {
        return this._geometry;
    }

    private type: string;
    private _geometry: Geometry;
    public properties: P;
    public popoverData: PopoverElement[] = [];

    public subFeatures: SubFeature[];

    constructor(geoJsonFeature?: any) {
        this.type = 'Feature';

        if (geoJsonFeature) {
            this._geometry = geoJsonFeature.geometry;
            this.properties = geoJsonFeature.properties;
            this.type = geoJsonFeature.type;
        }
    }

    public getGeometryType(): string {
        return this._geometry.type;
    }

    public convert(
        json: P,
        boundaryColumn: string = 'boundary',
        setProperties: boolean = true,
        keepBoundary: boolean = true
    ): LeafletFeature<P> {
        if (!json[boundaryColumn]) {
            console.warn('No boundary field for ', json);
            return undefined;
        }

        const geom = typeof json[boundaryColumn] === 'string' ? JSON.parse(json[boundaryColumn]) : json[boundaryColumn];

        if (!keepBoundary) {
            delete json[boundaryColumn];
        }

        if (setProperties) {
            this.properties = json;
        }

        this._geometry = geom;
        return this;
    }

    public buildPopover(layer: LeafletMapLayer): LeafletFeature<P> {
        this.popoverData = layer?.createPopover(this.properties);
        return this;
    }
}

interface Geometry {
    type: string;
    // TODO Add other type
    coordinates: [number, number][] | [number, number][][];
}
