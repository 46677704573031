import {Component, OnInit} from '@angular/core';
import {BaseWidget} from '../../classes/base-widget';

@Component({
    selector: 'eaglei-eia-weekly-petroleum-report-widget',
    templateUrl: './eia-weekly-petroleum-report-widget.component.html',
    styleUrls: ['./eia-weekly-petroleum-report-widget.component.scss'],
})
export class EiaWeeklyPetroleumReportWidgetComponent extends BaseWidget implements OnInit {
    constructor() {
        super();
    }

    ngOnInit(): void {}

    destroyWidget(): void {}
}
