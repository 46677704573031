
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

export class GenCommunityUserStatus {
    static readonly APPROVED: GenCommunityUserStatus = new GenCommunityUserStatus('APPROVED');
    static readonly PENDING: GenCommunityUserStatus = new GenCommunityUserStatus('PENDING');
    static readonly DENIED: GenCommunityUserStatus = new GenCommunityUserStatus('DENIED');

    public readonly name: string;

    private constructor(name: string) {
        this.name = name;
    }

    static forName(name: string) {
        if (name === 'APPROVED') return GenCommunityUserStatus.APPROVED;
        if (name === 'PENDING') return GenCommunityUserStatus.PENDING;
        if (name === 'DENIED') return GenCommunityUserStatus.DENIED;
        throw new Error('Unexpected value for GenCommunityUserStatus:' + name);
    }

    static values(): GenCommunityUserStatus[] {
        return [
            GenCommunityUserStatus.APPROVED,
            GenCommunityUserStatus.PENDING,
            GenCommunityUserStatus.DENIED,
        ];
    }

    toJSON(): string {
        return this.name;
    }

    toString(): string {
        return this.name;
    }
}

