import {GenMax24HourUtility} from '../../../../../generated/serverModels/GenMax24HourUtility';
import * as moment from 'moment';
import {DataService} from '../../../services/data.service';
import {State} from '../../outage/classes/state';
import {FemaRegion} from '../../outage/classes/fema-region';

export class MaxUtility extends GenMax24HourUtility {
    get lastReportDateValue(): number {
        return this._lastReportDateValue;
    }

    get lastHourMaxDateValue(): number {
        return this._lastHourMaxDateValue;
    }

    get lastDayMaxDateValue(): number {
        return this._lastDayMaxDateValue;
    }

    get reportUtilityId(): string {
        return this._reportUtilityId;
    }

    get region(): FemaRegion {
        return this._region;
    }

    get state(): State {
        return this._state;
    }

    private readonly _lastDayMaxDateValue: number;
    private readonly _lastHourMaxDateValue: number;
    private readonly _lastReportDateValue: number;

    private readonly _region: FemaRegion;
    private readonly _state: State;

    // This should match the same property in UtilityOutageData class
    private readonly _reportUtilityId: string;

    public percentOut: number;

    constructor(utility?: MaxUtility) {
        super(utility);

        if (utility) {
            this._lastReportDateValue = moment(utility.lastReportedOutageRunStartTime).valueOf();

            this._reportUtilityId = `${utility.utilityId}-${utility.stateName}-${utility.subUtilityId}-${utility.countyId}`;

            if (
                this.lastReportedOutageRunStartTime === undefined ||
                this.lastReportedOutageRunStartTime.isBefore(this.maxOutage1RunStartTime)
            ) {
                this.maxOutage1 = undefined as any;
                this.maxOutage1RunStartTime = undefined as any;
            } else {
                this._lastHourMaxDateValue = moment(utility.maxOutage1RunStartTime).valueOf();
            }

            if (
                this.lastReportedOutageRunStartTime === undefined ||
                this.lastReportedOutageRunStartTime.isBefore(this.maxOutage24RunStartTime)
            ) {
                this.maxOutage24 = undefined as any;
                this.maxOutage24RunStartTime = undefined as any;
            } else {
                this._lastDayMaxDateValue = moment(utility.maxOutage24RunStartTime).valueOf();
            }

            if (this.url && (this.url.toLowerCase() === 'n/a' || this.url.toLowerCase() === 'not available')) {
                this.url = undefined;
            }

            this._state = DataService.states.getValue().find((s) => s.abbreviation === this.stateName);
            this._region = DataService.femaRegions.getValue().find((r) => r.id === this.state.dotregion);

            const percent = utility.currentOutage / utility.coveredCustomers;
            this.percentOut = isFinite(percent) ? percent : undefined;
        }
    }
}
