import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Utility} from '../../modules/outage/classes/utility';
import {ResponseWrapper} from '../../classes/response-wrapper';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LandingService {
    constructor(private http: HttpClient) {}

    public getSummaryUtilities(): Observable<Utility[]> {
        const url = '/api/outagesummary/utility';
        const mf = (res: ResponseWrapper<Utility>) => {
            return res.data.map((u) => new Utility(u));
        };
        return this.http.get<ResponseWrapper<Utility>>(url).pipe(map(mf));
    }
}
